import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import moment from "moment";

import "moment/locale/id";

const DialogDateRangeMultiple = (props) => {
  const {
    index,
    classes,
    userTokenState,
    isShowDateRangeComponent,
    setShowDateRangeComponent,
    setShowDueDateComponent,
    handleCloseTimeFrame,
    onStartDate,
    onEndDate,
    onPeriod,
  } = props;

  /*
        ````````````````````````
        HANDLE DATE RANGE VALUE  

        ```````````````````````
    */
  const [startDateNow, setStartDateNow] = useState([]);
  const [endDateNow, setEndDateNow] = useState([]);

  const [startDateInDateRange, setStartDateInDateRange] = useState(new Date());
  const [endDateInDateRange, setEndDateInDateRange] = useState(new Date());

  // const [ startDateInDateRange, setStartDateInDateRange ] = useState([] );
  // const [ endDateInDateRange, setEndDateInDateRange ] = useState([]);

  const handleSelectDateRange = (e, i) => {
    setStartDateInDateRange(e.selection.startDate);
    setEndDateInDateRange(e.selection.endDate);

    setStartDateNow({
      ...startDateNow,
      [i]: moment(e.selection.startDate).format("YYYY-MM-DD"),
    });
    setEndDateNow({
      ...endDateNow,
      [i]: moment(e.selection.endDate).format("YYYY-MM-DD"),
    });

    // // console.log("Start Date Now : ", {
    //   ...startDateNow,
    //   [i]: moment(e.selection.startDate).format("YYYY-MM-DD"),
    // });
    // // console.log("End Date Now : ", {
    //   ...endDateNow,
    //   [i]: moment(e.selection.endDate).format("YYYY-MM-DD"),
    // });

    // // console.log("E Selection", e.selection);
    // // console.log("I Selection", i);

    // setStartDateInDateRange({
    //   ...startDateInDateRange,
    //   [i]: e.selection.startDate,
    // });
    // setEndDateInDateRange({ ...endDateInDateRange, [i]: e.selection.endDate });
  };

  const handleClickDateRange = (evt, i) => {
    handleCloseTimeFrame();

    // // console.log("Start date : ", moment(startDateInDateRange).format('YYYY-MM-DD'));
    // // console.log("End date : ", moment(endDateInDateRange).format('YYYY-MM-DD'));

    localStorage.setItem(
      "start_date",
      moment(startDateInDateRange).format("YYYY-MM-DD")
    );
    localStorage.setItem(
      "end_date",
      moment(endDateInDateRange).format("YYYY-MM-DD")
    );

    onStartDate(moment(startDateInDateRange).format("YYYY-MM-DD"));
    onEndDate(moment(endDateInDateRange).format("YYYY-MM-DD"));

    setShowDateRangeComponent(false);
    setShowDueDateComponent(false);

    onPeriod(null);
  };

  return (
    <Dialog
      key={index}
      aria-labelledby="simple-dialog-title"
      open={isShowDateRangeComponent}
      onClose={() => setShowDateRangeComponent(false)}
      fullWidth
    >
      <DialogTitle
        style={{ textAlign: "center" }}
        id="customized-dialog-title"
        onClose={() => setShowDateRangeComponent(false)}
      >
        <Typography variant="h6" className={classes.title}>
          <b>Pilih Mulai dan Berakhir</b>
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                marginLeft: "-250px",
                transform: "scale(0.80, 0.80)",
                paddingTop: 0,
                marginTop: 0,
              }}
            >
              <DateRangePicker
                ranges={[
                  {
                    startDate: startDateInDateRange,
                    endDate: endDateInDateRange,
                    key: "selection",
                  },
                ]}
                rangedCalendars={true}
                // onInit={ handleSelectDateRange }
                // onChange={ handleSelectDateRange }
                onChange={(e) => handleSelectDateRange(e, index)}
                // ranges={}
                months={2}
                direction={"horizontal"}
                staticRanges={[]}
                inputRanges={[]}
                style={{ fontFamily: "Roboto" }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={5}>
            <Button
              className={classes.buttonOutlined}
              variant="outlined"
              // onClick={ handleClickDateRange }
              onClick={() => setShowDateRangeComponent(false)}
              fullWidth
            >
              Batal
            </Button>
          </Grid>
          <Grid item xs={2}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Button
              className={classes.button}
              variant="contained"
              // color="secondary"
              onClick={(e) => handleClickDateRange(e, index)}
              fullWidth
            >
              Simpan
            </Button>
          </Grid>
        </Grid>

        {
          // <!-- /span --?
        }
        <Grid container>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
        </Grid>
        {
          // <!-- /span -->
        }
      </DialogContent>
    </Dialog>
  );
};

export default DialogDateRangeMultiple;
