import React from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import numeral from 'numeral';

import ProgressBarItem from '../../../ComponentsDashboard/ProgressBarItem';

const ListItemYourGoal = ({ classes, item, origin }) => {
  let color = '';
  let bgColor = '';

  if (item.achievement_category == 'Achieved') {
    color = '#34cc80';
    bgColor = '#D3F3E8';
  } else if (item.achievement_category == 'Warning') {
    color = '#fab445';
    bgColor = '#F8E4B0';
  } else {
    color = '#e15367';
    bgColor = '#FDDBD8';
  }

  return (
    <Paper className={classes.cardList}>
      <Box mb={1.5} p={2}>
        <Box
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography className={classes.title555} variant="subtitle1">
            <b>
              Performa {origin === 'goal' ? 'Goal' : 'Measured Activity'} Anda
            </b>
          </Typography>
          <Typography
            className={classes.title888}
            variant="subtitle2"
            style={{
              background: bgColor,
              color: color,
              padding: '2px 8px',
              borderRadius: 25
            }}
          >
            {numeral(item.value).format('0.00')}%
          </Typography>
        </Box>

        <ProgressBarItem
          item={item.value}
          achievementCategory={item.achievement_category}
        />
      </Box>
    </Paper>
  );
};

export default ListItemYourGoal;
