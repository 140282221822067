import React, { Fragment } from "react";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@material-ui/core";

const IconStructureName = (props) => {
  const { classes, goalDetailState } = props;

  return (
    <Fragment>
      <ListItemIcon style={{ marginLeft: 8 }}>
        <IconButton style={{ backgroundColor: "#aed9ff", padding: "6px" }}>
          <i className="material-icons" style={{ color: "#4aa9fb" }}>
            people
          </i>
        </IconButton>
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}>
            Structure Unit
          </Typography>
        }
        secondary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}>
            {goalDetailState.owner.structure_unit_type_name !== null
              ? goalDetailState.owner.structure_unit_type_name
              : "-"}
            &nbsp;
            {goalDetailState.owner.structure_unit_name}
          </Typography>
        }
      />
    </Fragment>
  );
};

export default IconStructureName;
