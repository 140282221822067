import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  InputBase,
  Tooltip,
  Chip,
} from "@material-ui/core";

import { useIndexedDB } from "react-indexed-db";
import moment from "moment";
import clsx from "clsx";

import { navigate, usePath } from "hookrouter";

import SearchIcon from "@material-ui/icons/Search";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";

import ViewGoalMoreDetailOverviewGOAL from "./ViewGoalMoreDetailOverviewGOAL";

import DialogDeleteGoalMaster from "./Components/DialogDeleteDetail";

import DialogEditGoalActiveUnUsed from "./Components/DialogEditGoalActiveUnUsed";

import Snackbeer from "../../../components/Snackbeer";
import SnackbeerRED from "../../../components/SnackbeerRED";

import getID from "../../../utilities/getIDs";

import {
  ToGoal,
  ToGoalDetail,
  ToSubGoalDetail,
  ToMeasuredActivity,
} from "../../../constants/config-redirect-url";
// import { RouteToOverviewGoal, RouteToMeasureActivities } from '../../../constants/config-redirect-url-with-router';

import ContextGoalDetail from "./Context/ContextGoalDetail";

import axiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";

import DialogConfirmWeight from "../MeasureActivities/Components/DialogConfirmWeight";
import ViewMA from "../MeasureActivities/ViewMA";
import {
  STATUS_COMPLETED,
  STATUS_APPROVED,
  STATUS_OVERDUE,
  STATUS_ACTIVE,
} from "../../../constants/config-status-goal";

import { styles } from "./Style/StyleGoalMoreDetail";

import DialogError from "../../../components/DialogError";
import DialogSetWeight from "../MeasureActivities/Components/DialogSetWeight";
import AnchorFilterWeight from "./Components/AnchorFilterWeight";
import DialogWeightMA from "./Components/DialogWeightMA";
import Capitalize from "../../../utilities/Capitalize";
import DialogCreateMAv2 from "../MeasureActivities/Components/DialogCreateMAv2";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewGoalMoreDetail = (props) => {
  const { classes } = props;
  const currentLoc = window.location.pathname;

  const context = useContext(ContextGoalDetail);
  const path = usePath();

  const { clear } = useIndexedDB("video");

  const currentLocation = window.location.pathname;

  const userToken = localStorage.getItem("userToken");
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse?.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse?.member_position !== null
      ? statusUserLoginAfterParse?.member_position.is_superior
      : false;

  const [userTokenState, setUserTokenState] = useState("");

  const [fotoQuery, setFotoQuery] = useState("");
  const [userLoginName, setUserLoginName] = useState("");

  const [pathLoc, setPathLoc] = useState(undefined);

  // const goalDetail = localStorage.getItem("goal_detail");
  // const goalDetailAfterParse = JSON.parse(goalDetail);
  const [goalDetailState, setGoalDetailState] = useState({
    id: null,
    period: {
      id: null,
      name: null,
      start_date: null,
      end_date: null,
    },
    start_date: null,
    end_date: null,
    created_by: "",
    target_type_id: "",

    calculatedValue: {
      start_value: "",
      target_value: "",
      input_value: "",
      target_value_achievement: "",
      target_range_value: "",
      resultOriented: {
        result_value: "",
        result_value_achievement: "",
        expected_result_value: "",
        expected_result_value_achievement: "",
        gap_result_value: "",
        gap_result_value_achievement: "",
        overall_result_achievement: "",
      },
      actualOriented: {
        actual_value: "",
        actual_value_achievement: "",
        expected_actual_value: "",
        expected_actual_value_achievement: "",
        gap_actual_value: "",
        gap_actual_value_achievement: "",
        overall_actual_achievement: "",
      },
    },
    policy: [
      { actionName: "", result: null },
      { actionName: "", result: null },
      { actionName: "", result: null },
      { actionName: "", result: null },
      { actionName: "", result: null },
      { actionName: "", result: null },
      { actionName: "", result: null },
    ],
    valueType: {
      code: null,
    },
    targetType: {
      code: null,
      id: "",
      name: "",
    },
    name: null,
    owner: {
      structure_unit_type_name: null,
      structure_unit_name: null,
    },
    calculationMethod: {
      id: null,
    },
    inputMethod: {
      id: null,
    },
    time: {
      remaining_days: null,
    },
    start_value: null,
    status_id: null,
    member: {
      id: "",
      first_name: "",
      last_name: "",
      photo_url: null,
      self: {
        rel: {
          photo_url: null,
        },
      },
    },
    status: {
      code: null,
    },
    is_used: false,
    target_range_value: null,
    direct_link_main_goal_id: null,
    directLinkMainGoal: {
      id: null,
      name: "",
    },
    targetValueSyncStatus: {
      name: "", //'sync'
    },
  });

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // const [ collectionGoalResultList, setCollectionGoalResultList ] = useState([]); //*Moved to Global States

  const [isUsedGoal, setUsedGoal] = useState(false);

  // const [ loader, setLoader ] = useState(false); //*Moved to Global State
  const [showButtonGoalResult, setShowButtonGoalResult] = useState(false);

  const [isStatusApproved, setStatusApproved] = useState(false); //*Di gunakan untuk component based on status of Goal !
  const [isStatusActive, setStatusActive] = useState(false);
  const [isStatusCompleted, setStatusCompleted] = useState(false);

  // CONFIRM WEIGHT
  const [openDialogConfirmWeight, setOpenDialogConfirmWeight] = useState(false);
  // SET WEIGHT
  const [openDialogSetWeight, setOpenDialogSetWeight] = useState(false);
  // Anchor cretae ma
  const [anchorAddMa, setAnchorAddMa] = useState(null);
  // Set Weight MA
  const [openDialogWeightMA, setOpenDialogWeightMA] = useState(false);

  /*
        `````````````````````
        HANDLE GROW, FADE,etc

        `````````````````````
    */
  const [growPaper, setGrowPaper] = useState(false);
  const [mainGoal, setMainGoal] = useState({
    id: null,
    name: null,
  });

  /*  
        ``````````````````
        TIME FRAME PERIODE

        ``````````````````
    */
  const [timeFrame, setTimeFrame] = useState(null);
  const [timeFramePeriod, setTimeFramePeriod] = useState(false);
  const [timeFrameDateRange, setTimeFrameDateRange] = useState(false);
  const [timeFrameDueDate, setTimeFrameDueDate] = useState(false);

  const [periodName, setPeriodName] = useState("");

  const locationPathGoalId = window.location.pathname.split("/")[2];
  const locationPathGoal = window.location.pathname.split("/")[1];

  // GET Id Folder from pahtname
  useEffect(() => {
    const pathArray = currentLoc.split("/");
    const goalId = pathArray[pathArray.length - 1];

    if (goalId !== undefined && goalId !== "") {
      setPathLoc(goalId);
    }
  }, [currentLoc, goalDetailState]);

  useEffect(() => {
    //*
    const id = getID(); //*Actual ==> null
    context.setGoalId({ id: id });

    if (pathLoc !== null) {
      context.setGoalId({
        id: pathLoc,
      });
    }

    //*
    if (currentLocation === ToGoalDetail) {
      setShowButtonGoalResult(true);
      setGrowPaper(true);
      context.setMaListShow(false);
    }

    if (currentLocation === path) {
      setShowButtonGoalResult(true);
      setGrowPaper(true);
      context.setMaListShow(false);
    }

    if (currentLocation === ToMeasuredActivity) {
      setShowButtonGoalResult(false);
      context.setMaListShow(true);

      setDataActiveOrComplete({
        id: 2,
        label: "Measure Activities",
      });
    }

    if (currentLocation === "/ma/draft") {
      setShowButtonGoalResult(false);
      context.setMaListShow(true);

      setDataActiveOrComplete({
        id: 2,
        label: "Measure Activities",
      });
    }

    if (currentLocation === ToSubGoalDetail) {
      // // console.log("path : ", path);
      setShowButtonGoalResult(true);
      setGrowPaper(true);
    }

    //*
    setUserTokenState(userToken);
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      setUserLoginName(
        statusUserLoginAfterParse.member_first_name +
          " " +
          statusUserLoginAfterParse.member_last_name
      );
      setFotoQuery(statusUserLoginAfterParse.member_photo_url);

      if (statusUserLoginAfterParse.member_position !== null) {
        context.setMemberPositionList(
          statusUserLoginAfterParse.member_position
        );
      }

      //* Goal Result
      if (userToken !== undefined) {
        context.setLoader(true);
        axiosConfig
          .get(
            `${URL_API}/swd/goal/${locationPathGoalId}/result?options[paging][limit]=8&options[paging][offset]=0`
          )
          .then(function (response) {
            // console.log("Response Original GR : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setCollectionGoalResultList(response.data.data);
                context.setGoalResultLength(response.data.info.count);
              }
            }
            context.setLoader(false);
          })
          .catch(function (error) {
            // console.log("Error : ", error.response);
            context.setLoader(false);
          });

        //*GOL DETAIL
        axiosConfig
          .get(`${URL_API}/swd/goal/${locationPathGoalId}`)
          .then(function (response) {
            // console.log("Response Original GOAL DETAIL : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                //*Get foto
                if (response.data.data.member !== null) {
                  if (response.data.data.member.self.rel !== null) {
                    if (response.data.data.member.self.rel.photo_url !== null) {
                      setFotoQuery(
                        response.data.data.member.self.rel.photo_url
                      );
                    }
                  }
                }

                //*Get ID
                setGoalDetailState(response.data.data);
                context.setGoalDetailStateGlobal(response.data.data);

                setUsedGoal(response.data.data.is_used);

                if (response.data.data.direct_link_main_goal_id !== null) {
                  setMainGoal({
                    id: response.data.data.directLinkMainGoal.id,
                    name: response.data.data.directLinkMainGoal.name,
                  });
                }

                //*Start Date from Goal Detail
                if (response.data.data.start_date !== null) {
                  context.setStartDatePojokKanan(response.data.data.start_date);
                }

                if (response.data.data.end_date !== null) {
                  context.setEndDatePojokKanan(response.data.data.end_date);
                }

                /*
                      ```````````````````````````
                      TO KNOW CURRENT SHOW BUTTON
          
                      ```````````````````````````
                  */

                if (response.data.data.status.code === STATUS_APPROVED) {
                  setStatusApproved(true);
                }

                if (response.data.data.status.code === STATUS_ACTIVE) {
                  setStatusActive(true);
                }

                if (response.data.data.status.code === STATUS_COMPLETED) {
                  setStatusCompleted(true);
                }

                if (response.data.data.time_frame_id === "1") {
                  setTimeFramePeriod(true);
                  setTimeFrameDueDate(false);
                  setTimeFrameDateRange(false);
                  setPeriodName(response.data.data.period.name);
                }

                if (response.data.data.time_frame_id === "2") {
                  setTimeFrameDateRange(true);
                  setTimeFramePeriod(false);
                  setTimeFrameDueDate(false);
                }

                if (response.data.data.time_frame_id === "3") {
                  setTimeFrameDueDate(true);
                  setTimeFrameDateRange(false);
                  setTimeFramePeriod(false);
                }
              }
            }
            context.setLoader(false);
          })
          .catch(function (error) {
            // console.log("Error : ", error.response);
            context.setLoader(false);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [currentLoc]);

  // =====================================
  // ======= GOAL RESULT ============
  useEffect(() => {
    if (context.loaderForPaging === true) {
      // context.setLoaderForPaging(true);
      context.setLoader(true);
      axiosConfig
        .get(
          URL_API +
            `/swd/goal/${locationPathGoalId}/result?options[paging][offset]=${
              context.offset === -8 ? 0 : context.offset
            }&options[paging][limit]=${context.rowsPerPage}`
        )
        .then(function (response) {
          // console.log("Response Original GR : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionGoalResultList(response.data.data);
              context.setGoalResultLength(response.data.info.count);
            }
          }
          context.setLoaderForPaging(false);
          context.setLoader(false);
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          context.setLoaderForPaging(false);
          context.setLoader(false);
        });
    }
  }, [
    context.offset,
    context.rowsPerPage,
    context.page,
    context.loaderForPaging,
    context.isSuccessAddEvidence,
  ]);

  // Handle status code 403 from create Goal Reesult For Disableing Crate Result
  const [disableCreateResult, setDisableCreateResult] = useState(false);

  useEffect(() => {
    if (goalDetailState.id !== null) {
      axiosConfig
        .get(`${URL_API}/swd/goal/${locationPathGoalId}/result/create`)
        .then((response) => {
          // // console.log("Response create GR", response);
        })
        .catch((error) => {
          // console.log("Error : ", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setDisableCreateResult(true);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    context.setLoaderForPaging(true);
    context.setPage(newPage);
    context.setOffset((parseInt(newPage + 1) - 1) * context.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    context.setLoaderForPaging(true);

    // // console.log("Rows perpage : ", event.target.value);
    context.setRowsPerPage(parseInt(event.target.value, 8));
    context.setPage(0);
  };

  /*
        ``````````````````````````````````````````````````
        HANDLE UPDATE GOAL DETAIL AFTER CREATE GOAL RESULT

        ``````````````````````````````````````````````````
    */
  // const [ isFireGoalDetail, setFireGoalDetail ] = useState(false);//*Moved to Global State ! *State ini utk update data Goal Detail

  const [isForce, setForce] = useState(false);
  useEffect(() => {
    if (context.isFireGoalDetail === true) {
      context.setLoader(true);

      if (userTokenState !== undefined) {
        axiosConfig
          .get(URL_API + `/swd/goal/${locationPathGoalId}`)
          .then(function (response) {
            setForce(true);
            context.setModalResponse200(false);

            console.log(
              "Response Original GOAL DETAIL OTOMATIS UPDATE: ",
              response
            );

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setGoalDetailState(response.data.data);

                if (response.data.data.time_frame_id === "1") {
                  setTimeFramePeriod(true);
                  setTimeFrameDueDate(false);
                  setTimeFrameDateRange(false);
                  setPeriodName(response.data.data.period.name);
                }

                if (response.data.data.time_frame_id === "2") {
                  setTimeFrameDateRange(true);
                  setTimeFramePeriod(false);
                  setTimeFrameDueDate(false);
                }

                if (response.data.data.time_frame_id === "3") {
                  setTimeFrameDueDate(true);
                  setTimeFrameDateRange(false);
                  setTimeFramePeriod(false);
                }
              }
              context.setFireGoalDetail(false);
            }
          })
          .catch(function (error) {
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [context.isFireGoalDetail]);

  useEffect(() => {
    if (isForce === true) {
      //*Mesti di hide kalau tidak upload file bukti, dia muncul juga. Harusnya tidak !
      // context.setModalResponse200(true);
      // context.setMessages("Masih proses upload file bukti...");

      //*GOL RESULT LIST SELF - ONLY OWNER GOAL USER LOGIN
      axiosConfig
        .get(
          URL_API +
            `/swd/goal/${locationPathGoalId}/result?options[paging][limit]=8&options[paging][offset]=0`
        )
        .then(function (response) {
          setForce(false);
          //   context.setLoaderEvidance(false);

          console.log(
            "Response Original COLLECTION GOAL RESULT FORCE: ",
            response
          );

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionGoalResultList(response.data.data);
              context.setGoalResultLength(response.data.info.count);
            }
          }
        })
        .catch(function (error) {
          // context.setLoaderEvidance(false);
          // console.log("Error : ", error.response);
        });
    }
  }, [goalDetailState, isForce]);

  useLayoutEffect(() => {
    if (isForce === true) {
      // context.setLoaderEvidance(true);
      //*GOL RESULT LIST SELF - ONLY OWNER GOAL USER LOGIN
      context.setLoader(true);
      axiosConfig
        .get(URL_API + `/swd/goal/${locationPathGoalId}/result`)
        .then(function (response) {
          //   context.setLoaderEvidance(false);
          console.log(
            "Response Original COLLECTION GOAL RESULT FORCE: ",
            response
          );

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionGoalResultList(response.data.data);
            }
          }
          context.setLoader(false);
        })
        .catch(function (error) {
          context.setLoader(false);
          // context.setLoaderEvidance(false);
          // console.log("Error : ", error.response);
        });
    }
  }, [goalDetailState, isForce]);

  /*
        `````````````````````````
        DROPDOWN GOAL RESULT LIST
        `````````````````````````
    */
  const [goalResultId, setGoalResultId] = useState("");
  const [goalResultNameDescription, setGoalResultNameDescription] =
    useState("");
  const [goalResultDataDetail, setGoalResultDataDetail] = useState("");

  const [anchorElListGoalResult, setAnchorElListGoalResult] = useState(null);

  const handleDropdownOpenGoalResult = (event, item) => {
    event.preventDefault();
    setAnchorElListGoalResult(event.currentTarget);
    // console.log("handleDropdownOpenGoalResult : ", item);

    setGoalResultId(item.id);
    setGoalResultNameDescription(item.description);
    setGoalResultDataDetail(item);
  };

  function handleDropdownCloseGoalResult() {
    setAnchorElListGoalResult(null);
  }

  /*
        ```````````````````
        DROPDOWN TIME FRAME //*Ini sepertinya bika dropdown Time frame, tapi dropdown untuk munculin "OPTION EDIT & DELETE GOAL MASTER"
        ```````````````````
    */
  const [anchorEl, setAnchorEl] = useState(null);

  function handleDropdownOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleDropdownClose() {
    setAnchorEl(null);
  }

  function handleShowAnchorMa(event) {
    setAnchorAddMa(event.currentTarget);
  }

  function handleCloseAnchorMa() {
    setAnchorAddMa(null);
  }

  /*
        ``````````````````````````````````````
        HANDLE DIALOG MODAL DELETE GOAL MASTER

        ``````````````````````````````````````
    */
  const [isModalDeleteGoalMaster, setModalDeleteGoalMaster] = useState(false);
  const handleDialogDeleteGoalMaster = () => {
    handleDropdownClose();
    setModalDeleteGoalMaster(true);
  };

  /*
        ``````````````````````````````````````
        HANDLE DIALOG MODAL EDIT GOAL MASTER

        ``````````````````````````````````````
    */
  const [isModalEditGoalMaster, setModalEditGoalMaster] = useState(false); //* ==> Active used
  const [isModalEditGoalUnUSED, setModalEditGoalUnUSED] = useState(false);

  const handleDialogEditGoalMaster = () => {
    handleDropdownClose();

    if (isUsedGoal === true) {
      //*Active used === form body lebih sedikit, else lebih banyak

      // // console.log("Edit Active USED !"); //*Active USED

      if (goalDetailState.status.code === "approved") {
        setModalEditGoalUnUSED(true);
      } else {
        setModalEditGoalMaster(true);
      }
    } else {
      // // console.log("Edit Active UnUSED !"); //*Active un_used
      setModalEditGoalUnUSED(true);
    }
  };

  /*
        ```````````````
        HANDLE COLLAPSE

        ```````````````
    */
  const [openCollapse, setOpenCollapse] = useState(true);

  function handleCollapse() {
    setOpenCollapse(!openCollapse);
  }

  /*
        ``````````````````````````````````````
        HANDLE DIALOG MODAL CREATE GOAL RESULT

        ``````````````````````````````````````
    */
  const [isModalCreate, setModalCreate] = useState(false);

  const handleDialogModalGoalResult = () => {
    setOpenCollapse(false);
    setModalCreate(true);

    //*
    clear().then(() => {
      // console.log("Clear all inside IndexedDB !");
    });
  };

  /*
        ````````````````````````````````
        HANDLE TAB ACTIVE & COMPLETE GOAL
        
        `````````````````````````````````
    */
  const [dataActiveOrComplete, setDataActiveOrComplete] = useState({
    id: 1,
    label: "Overview Goal",
  });

  const handleClickListChoose = (e, data) => {
    e.preventDefault();
    setDataActiveOrComplete(data);

    if (data.id === 1) {
      //*Overview Goal

      setShowButtonGoalResult(true);
      setGrowPaper(true);

      context.setMaListShow(false);
      navigate(`/goal-detail/${locationPathGoalId}`);
    } else {
      setShowButtonGoalResult(false);
      context.setMaListShow(true);
      navigate(`/ma/${locationPathGoalId}`);
      // // console.log("CONTEXT IS MA LIST SHOW", context.isMaListShow);
    }
  };

  // ========================================================
  // Force Tab to move to MA List when user clicekd it one time
  // ========================================================
  useEffect(() => {
    if (locationPathGoal === "ma") {
      context.setMaListShow(true);
      setShowButtonGoalResult(false);
      setDataActiveOrComplete({
        id: 2,
        label: "Measure Activities",
      });
    }
  }, [locationPathGoal]);

  useEffect(() => {
    if (context.isSubGoalFire === true) {
      if (userTokenState !== undefined) {
        axiosConfig
          .get(URL_API + `/swd/goal/${locationPathGoalId}`)
          .then(function (response) {
            console.log(
              "Response Original GOAL DETAIL BACK AFTER FROM SUB-GOAL: ",
              response
            );

            if (response.status === 200) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setGoalDetailState(response.data.data);

                if (response.data.data.is_used !== undefined) {
                  setUsedGoal(response.data.data.is_used);
                }

                localStorage.setItem(
                  "goal_detail",
                  JSON.stringify(response.data.data)
                );
                window.location.reload();
              }
            }
          })
          .catch(function (error) {
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [context.isSubGoalFire]);

  /*
        ````````````````````````````````
                FEATURE MEASURE ACTIVITY
        ````````````````````````````````
    */

  /*
        `````````````````````
        HANDLE TAB OERVIEW MA
        
        `````````````````````
    */
  const [isActiveOverviewMA, setActiveOverviewMA] = useState({
    id: 1,
    label: "Overview MA",
  });

  const handleClickListChooseActiveOverviewMA = (e, data) => {
    e.preventDefault();
    setActiveOverviewMA(data);
  };

  /*
        `````````````````````````````````````
        HANDLE DIALOG CREATE MEASURE ACTIVITY

        `````````````````````````````````````
    */
  const [isModalCreateMA, setModalCreateMA] = useState(false);
  const [openCreateMA, setOpenCreateMA] = useState(false);

  const handleDialogCreateMAv2 = () => {
    // setModalCreateMA(true);
    setOpenCreateMA(true);
  };

  /*
        `````````````````````
        HANDLE SHOW MA DETAIL

        `````````````````````
    */
  // const [isMeasuredActivityDetailShow, setMeasuredActivityDetailShow ] = useState(true);//*Moved to Global State
  const handleBackToCollectionMA = () => {
    context.setMeasuredActivityDetailShow(true);
    localStorage.removeItem("MA_detail");

    context.setStartDatePojokKanan(goalDetailState.start_date);
    context.setEndDatePojokKanan(goalDetailState.end_date);
  };

  /*
        ``````````````````````````````````````
        HANDLE DIALOG MODAL CREATE GOAL RESULT

        ``````````````````````````````````````
    */
  // const [ isModalCreateMAResult, setModalCreateMAResult ] = useState(false); //*Moved to Global State

  const handleDialogModalResultMA = () => {
    context.setModalCreateMAResult(true);

    //*
    clear().then(() => {
      // console.log("Clear all inside IndexedDB !");
    });

    localStorage.removeItem("video_count");
  };

  /*
        ```````````````````````````
        HANDLE COLLECTION MA RESULT

        ```````````````````````````
    */

  const [periodDetail, setPeriodDetail] = useState(null);

  useEffect(() => {
    if (context.isMeasuredActivityDetailShow === false) {
      const maDetail = localStorage.getItem("MA_detail");
      const maDetailAfterParse = JSON.parse(maDetail);

      if (maDetailAfterParse !== null && maDetailAfterParse !== undefined) {
        if (maDetailAfterParse.id !== null) {
          context.setStatusMaId(maDetailAfterParse.status_id);
          context.setMaId(maDetailAfterParse.id);

          if (maDetailAfterParse.start_date !== null) {
            context.setStartDatePojokKanan(maDetailAfterParse.start_date);
          }

          if (maDetailAfterParse.end_date !== null) {
            context.setEndDatePojokKanan(maDetailAfterParse.end_date);
          }

          if (maDetailAfterParse.time_frame_id === "1") {
            setTimeFramePeriod(true);
            setPeriodDetail(maDetailAfterParse.period);
            setTimeFrameDateRange(false);
            setTimeFrameDueDate(false);
          }

          if (maDetailAfterParse.time_frame_id === "2") {
            setTimeFramePeriod(false);
            setTimeFrameDateRange(true);
            setTimeFrameDueDate(false);
          }

          if (maDetailAfterParse.time_frame_id === "3") {
            setTimeFramePeriod(false);
            setTimeFrameDateRange(false);
            setTimeFrameDueDate(true);
          }
        }
      }

      context.setLoader(true);

      if (userToken !== undefined) {
        axiosConfig
          .get(
            URL_API +
              `/swd/measured-activity/${maDetailAfterParse.id}/result?options[paging][limit]=8&options[paging][offset]=0`
          )
          .then(function (response) {
            context.setLoader(false);

            // console.log("Response Original MA COLLECTION RESULT: ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                context.setCollectionMaResultList(response.data.data);
                context.setMAResultLength(response.data.info.count);
              }
            }
          })
          .catch(function (error) {
            context.setLoader(false);
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }

    /*
        ````````````````````````````````````
        HANDLE COMPONENT VIEW MA MORE DETAIL

        ````````````````````````````````````
    */
  }, [
    context.isMeasuredActivityDetailShow,
    context.startDatePojokKanan,
    context.endDatePojokKanan,
  ]);

  // ======= MA RESULT ============
  useEffect(() => {
    if (context.loaderForPagingMAR === true) {
      context.setLoader(true);
      axiosConfig
        .get(
          URL_API +
            `/swd/measured-activity/${
              context.maId
            }/result?options[paging][offset]=${
              context.offsetMAR === -8 ? 0 : context.offsetMAR
            }&options[paging][limit]=${context.rowsPerPageMAR}`
        )
        .then(function (response) {
          // console.log("Response Original MAR : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionMaResultList(response.data.data);
              context.setMAResultLength(response.data.info.count);
            }
          }
          context.setLoaderForPagingMAR(false);
          context.setLoader(false);
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          context.setLoaderForPagingMAR(false);
          context.setLoader(false);
        });
    }
  }, [
    context.offsetMAR,
    context.rowsPerPageMAR,
    context.pageMAR,
    context.loaderForPagingMAR,
  ]);

  /*
        ````````````````````````````
        context.isSuccessUploadFile

        ````````````````````````````
   */
  useEffect(() => {
    if (
      context.isSuccessUploadFile === true &&
      context.isMeasuredActivityDetailShow === false
    ) {
      context.setLoader(true);

      axiosConfig
        .get(
          URL_API +
            `/swd/measured-activity/${context.maId}/result?options[paging][limit]=8&options[paging][offset]=0`
        )
        .then(function (response) {
          context.setLoader(false);

          console.log(
            "Response Original MA COLLECTION RESULT AFTER UPLOAD IMAGE/VIDEO : ",
            response
          );

          if (response.status === 200) {
            if (response.data.data !== null) {
              // context.setSuccessUploadFile(false)
              context.setCollectionMaResultList(response.data.data);
              context.setMAResultLength(response.data.info.count);
            }
          }
        })
        .catch(function (error) {
          context.setLoader(false);
          // console.log("Error : ", error.response);
        });
    }
  }, [context.isSuccessUploadFile, context.isMeasuredActivityDetailShow]);

  /* =======================================================
    Handle Disable Create Goal depending on status code 403
  ======================================================= */
  const [disableCreateGoal, setDisableCreateGoal] = useState(false);

  useEffect(() => {
    if (userToken !== undefined) {
      axiosConfig
        .get(`${URL_API}/swd/goal/create`)
        .then((response) => {
          // // console.log("Response Create Goal", response);
        })
        .catch((error) => {
          // console.log("Error : ", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setDisableCreateGoal(true);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    } else {
      // console.log("User Token Not Exist");
    }
  }, []);

  /*
       ```````````````````
       DROPDOWN ALL STATUS
       ```````````````````
   */
  const [anchorElDirectInDirect, setAnchorElDirectInDirect] = useState(null);

  function handleMenuDirectInDirect(event) {
    setAnchorElDirectInDirect(event.currentTarget);
  }

  function handleCloseMenuDirectInDirect() {
    setAnchorElDirectInDirect(null);
  }

  /*
        ````````````````````````
        HANDLE DIALOG PILIH USER

        ````````````````````````
    */

  /*
        ```````````````````````````````````````
        HANDLE DIALOG CONFIRM BEFORE PILIH USER

        ```````````````````````````````````````
    */

  /*
        ````````````````
        HANDLE MENU BACK

        ````````````````        
    */

  const [anchorElMenuBack, setAnchorElMenuBack] = useState(null);

  const handleDropdownOpenMenuBack = (event, item) => {
    event.preventDefault();
    setAnchorElMenuBack(event.currentTarget);
  };

  function handleDropdownCloseMenuBack() {
    setAnchorElMenuBack(null);
  }

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
=============================================================================================== */

  let directLinkCreateAllowed = false;

  goalDetailState.policy.forEach((item) => {
    if (item.actionName === "directLinkCreate") {
      directLinkCreateAllowed = item.result;
    }
  });

  /**
   ==================================================================================
    Show Goal Counter Status
   ==================================================================================
   */
  const [maCounter, setMACounter] = useState([]);

  useEffect(() => {
    axiosConfig
      .get(
        `${URL_API}/swd/measured-activity/count-status?options[filter][goal_id]=${locationPathGoalId}`
      )
      .then((response) => {
        // // console.log("Response ma Counter", response);

        if (response.status === 200) {
          setMACounter(response.data.data);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });
  }, []);

  // Set Counter for Tab Measured Activity
  const counter = maCounter.reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);

  /**
   ==================================================================================
    Show Update Frequency
   ==================================================================================
   */
  let FREQ_LABEL = "";
  const frequency = goalDetailState?.update_frequency;

  if (frequency !== null) {
    if (frequency?.interval === "hourly") {
      FREQ_LABEL = `Update frequency: 🔄 ${Capitalize(frequency?.interval)} (${
        frequency?.scheduled_at
      } min)`;
    }
    if (frequency?.interval === "daily") {
      FREQ_LABEL = `Update frequency: 🔄 ${Capitalize(frequency?.interval)} (${
        frequency?.scheduled_at
      } o'clock)`;
    }
    if (frequency?.interval === "weekly") {
      FREQ_LABEL = `Update frequency: 🔄 ${Capitalize(frequency?.interval)} (${
        frequency?.scheduled_at
      } o'clock)`;
    }
    if (frequency?.interval === "monthly") {
      FREQ_LABEL = `Update frequency: 🔄 ${Capitalize(frequency?.interval)} (${
        frequency?.scheduled_at
      } o'clock)`;
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Fragment>
        <Box mt={8} mb={-3}>
          <Paper elevation={0} square>
            <Box display="flex" justifyContent="space-between">
              <Box px={2} ml={8} mt={2}>
                {context.isMeasuredActivityDetailShow === true ? (
                  <IconButton onClick={() => window.history.back()}>
                    <KeyboardBackspaceIcon style={{ cursor: "pointer" }} />
                  </IconButton>
                ) : (
                  <Tooltip
                    title="Kembali untuk melihat semua Measured Activities"
                    placement="right"
                  >
                    <IconButton onClick={handleBackToCollectionMA}>
                      <KeyboardBackspaceIcon style={{ cursor: "pointer" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>

              <Box pr={2} display="flex" alignItems="center">
                {goalDetailState?.update_frequency !== null && (
                  <Chip
                    label={FREQ_LABEL}
                    size="small"
                    variant="outlined"
                    style={{ marginRight: 16, color: "#666" }}
                  />
                )}
                {
                  //*DATE RANGE
                  timeFrameDateRange && (
                    <Button
                      style={{ borderWidth: 0, marginLeft: 16 }}
                      variant="outlined"
                      size="small"
                      className={classes.timeFrameIcontDateRangeText}
                    >
                      <b>
                        {moment(goalDetailState.start_date).format(
                          "DD MMMM YYYY"
                        ) +
                          " - " +
                          moment(goalDetailState.end_date).format(
                            "DD MMMM YYYY"
                          )}
                      </b>
                    </Button>
                  )
                }

                {
                  // *DUE DATE
                  timeFrameDueDate && (
                    <Button
                      style={{ borderWidth: 0, marginLeft: 16 }}
                      variant="outlined"
                      size="small"
                      className={classes.timeFrameIcontDateRangeText}
                    >
                      <b>
                        {moment(goalDetailState.end_date).format(
                          "DD MMMM YYYY"
                        )}
                      </b>
                    </Button>
                  )
                }

                {
                  // *PERIOD
                  timeFramePeriod && (
                    <Fragment>
                      <Chip
                        label={periodName}
                        style={{
                          backgroundColor: "#f4f7fc",
                          color: "grey",
                          fontFamily: "Roboto",
                        }}
                        size="small"
                      />
                      <Button
                        style={{
                          borderWidth: 0,
                          marginLeft: 16,
                        }}
                        variant="outlined"
                        size="small"
                        className={classes.timeFrameIcontDateRangeText}
                      >
                        <b>
                          {periodDetail !== null
                            ? periodDetail.name
                            : moment(
                                goalDetailState.period !== null
                                  ? goalDetailState.period.start_date
                                  : goalDetailState.end_date
                              ).format("DD MMMM YYYY") +
                              " - " +
                              moment(
                                goalDetailState.period !== null
                                  ? goalDetailState.period.end_date
                                  : goalDetailState.start_date
                              ).format("DD MMMM YYYY")}
                        </b>
                      </Button>
                    </Fragment>
                  )
                }
              </Box>
            </Box>
          </Paper>
        </Box>

        {/* 
            ```````````````````````````````````````
            TAB OVERVIEW GOAL & MEASURE ACTIVITIES

            ```````````````````````````````````````
        */}
        {context.isMeasuredActivityDetailShow === true && (
          // <Grid container className={classes.fixedLayout}>
          <Box ml={12} display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              {listChoose.length > 0 &&
                listChoose.map((item, i) => {
                  return (
                    <Box key={i} mr={4}>
                      <Button
                        variant="outlined"
                        onClick={(e) => handleClickListChoose(e, item)}
                        size="small"
                        className={
                          dataActiveOrComplete.id === item.id
                            ? clsx(
                                classes.titleListChoose,
                                classes.layoutMarginLeftListChooseTab
                              )
                            : clsx(
                                classes.titleListChooseAktif,
                                classes.layoutMarginLeftListChooseTab
                              )
                        }
                        style={{
                          borderColor: "none",
                          borderWidth: 0,
                          textTransform: "Capitalize",
                          marginLeft: "-16px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b
                            className={
                              dataActiveOrComplete.id === item.id
                                ? classes.bottomBorderActive
                                : null
                            }
                          >
                            <span
                              style={{
                                textDecorationLine: "none",
                                color: `${
                                  dataActiveOrComplete.id === item.id
                                    ? "black"
                                    : "grey"
                                }`,
                              }}
                            >
                              {item.label}
                            </span>
                          </b>
                          {item.id === 2 && (
                            <span
                              style={{
                                color: "#1D80DB",
                                backgroundColor: "#D7E4F7",
                                fontWeight: "700",
                                paddingLeft: 4,
                                paddingRight: 4,
                                fontSize: 14,
                                marginLeft: 5,
                                borderRadius: 5,
                              }}
                            >
                              {counter}
                            </span>
                          )}
                        </Typography>
                      </Button>
                    </Box>
                  );
                })}
            </Box>

            <Box display="flex" alignItems="center">
              {showButtonGoalResult === false && (
                <Box marginTop={theme.spacing(0.5)}>
                  <Button
                    onClick={() => context.setModalExportMA(true)}
                    variant="outlined"
                    className={classes.btnFilter}
                    style={{ borderWidth: 1 }}
                  >
                    Export
                  </Button>
                </Box>
              )}

              {/* <Grid
              item
              sm={showButtonGoalResult ? 0 : 2}
              md={showButtonGoalResult ? 0 : 2}
              style={{ textAlign: "right" }}
            >
              {showButtonGoalResult === false && (
                <div className={classes.search}>
                  <InputBase
                    // onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search MA..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{
                      className: classes.title,
                    }}
                    startAdornment={
                      <SearchIcon
                        style={{
                          color: "#C4C4C4",
                          fontSize: 18,
                          marginLeft: 12,
                        }}
                      />
                    }
                  />
                </div>
              )}
            </Grid> */}

              <Box
                item
                sm={showButtonGoalResult ? 5 : 5}
                md={showButtonGoalResult ? 5 : 5}
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {!showButtonGoalResult && context.hideCreateMABtn === false ? (
                  <>
                    <Box
                      style={{
                        marginRight: !showButtonGoalResult && 12,
                        marginTop: 32,
                        marginLeft: !showButtonGoalResult && 16,
                      }}
                    >
                      <AnchorFilterWeight classes={classes} />
                    </Box>
                    {superior && (
                      <Button
                        disabled={
                          isStatusCompleted && showButtonGoalResult
                            ? true
                            : !showButtonGoalResult
                            ? context.disableCreateMAResult
                            : false
                        }
                        onClick={!showButtonGoalResult && handleShowAnchorMa}
                        variant="outlined"
                        className={!showButtonGoalResult && classes.button}
                        size="small"
                        style={{
                          marginRight: !showButtonGoalResult && 16,
                          marginTop: 32,
                          marginLeft: !showButtonGoalResult && 16,
                        }}
                        startIcon={<AddIcon />}
                      >
                        {!showButtonGoalResult && "Buat Measure Activity"}
                      </Button>
                    )}
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorAddMa}
                      keepMounted
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      getContentAnchorEl={null}
                      open={Boolean(anchorAddMa)}
                      onClose={handleCloseAnchorMa}
                    >
                      <MenuItem
                        onClick={() => {
                          handleDialogCreateMAv2();
                          handleCloseAnchorMa();
                        }}
                        style={{ width: 150 }}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          Add New MA v2
                        </Typography>
                      </MenuItem>
                      {superior && (
                        <MenuItem
                          onClick={() => {
                            setOpenDialogWeightMA(true);
                            handleCloseAnchorMa();
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            Set Weight MA
                          </Typography>
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
        )}

        {/* 
            ````````````````````````````````````````````
            TAB OVERVIEW MEASURE ACTIVITIES (TAB PART-02)

            ````````````````````````````````````````````
        */}

        {context.isMeasuredActivityDetailShow === false && (
          <Grid container className={classes.fixedLayout}>
            {listChooseOverviewMA.length > 0 &&
              listChooseOverviewMA.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Grid item sm={2} md={2} style={{ textAlign: "right" }}>
                      <Button
                        variant="outlined"
                        onClick={(e) =>
                          handleClickListChooseActiveOverviewMA(e, item)
                        }
                        size="small"
                        className={
                          isActiveOverviewMA.id === item.id
                            ? clsx(
                                classes.titleListChoose,
                                classes.layoutMarginLeftListChooseTab
                              )
                            : clsx(
                                classes.titleListChooseAktif,
                                classes.layoutMarginLeftListChooseTab
                              )
                        }
                        style={{
                          borderColor: "none",
                          borderWidth: 0,
                          textTransform: "Capitalize",
                          marginLeft: "-16px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b
                            className={
                              isActiveOverviewMA.id === item.id
                                ? classes.bottomBorderActive
                                : null
                            }
                          >
                            <span
                              style={{
                                textDecorationLine: "none",
                                color: `${
                                  isActiveOverviewMA.id === item.id
                                    ? "black"
                                    : "grey"
                                }`,
                              }}
                            >
                              {item.label}
                            </span>
                          </b>
                        </Typography>
                      </Button>
                    </Grid>
                  </Fragment>
                );
              })}

            <Grid item sm={6} md={6}></Grid>

            {/* <Grid item sm={2} md={2} style={{ textAlign: "right" }}>
              <Button
                onClick={handleDialogModalResultMA}
                variant="contained"
                className={classes.button}
                style={{
                  marginRight: 16,
                  marginTop: 32,
                }}
                size="small"
                disabled={
                  context.statusMaId === "2" //*If Status MA is Approved //*If Status MA is Completed
                    ? true
                    : context.statusMaId === "4"
                    ? true
                    : context.disableCreateMAResult
                }>
                <IconButton style={{ padding: 7 }}>
                  <AddIcon style={{ fontSize: 14, color: "white" }} />
                </IconButton>
                Buat MA Result baru
              </Button>
            </Grid> */}
          </Grid>
        )}

        <br />

        {context.isMaListShow === false && (
          <ViewGoalMoreDetailOverviewGOAL
            {...props}
            classes={classes}
            goalDetailState={goalDetailState}
            setFotoQuery={setFotoQuery}
            fotoQuery={fotoQuery}
            userToken={userToken}
            userLoginName={userLoginName}
            memberPositionList={context.memberPositionList}
            handleDropdownOpen={handleDropdownOpen}
            handleDropdownClose={handleDropdownClose}
            anchorEl={anchorEl}
            handleDialogEditGoalMaster={handleDialogEditGoalMaster}
            handleDialogDeleteGoalMaster={handleDialogDeleteGoalMaster}
            collectionGoalResultList={context.collectionGoalResultList}
            goalResultLength={context.goalResultLength}
            handleCollapse={handleCollapse}
            openCollapse={openCollapse}
            handleDropdownCloseGoalResult={handleDropdownCloseGoalResult}
            handleDropdownOpenGoalResult={handleDropdownOpenGoalResult}
            anchorElListGoalResult={anchorElListGoalResult}
            growPaper={growPaper}
            isModalCreate={isModalCreate}
            handleDialogModalGoalResult={handleDialogModalGoalResult}
            handleCloseMenuDirectInDirect={handleCloseMenuDirectInDirect}
            setFireGoalDetail={context.setFireGoalDetail}
            isSuccessUploadFile={context.isSuccessUploadFile}
            // loaderEvidence = { context.loaderEvidance}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            loader={context.loader}
            page={context.page}
            rowsPerPage={context.rowsPerPage}
            maCount={counter}
          />
        )}

        {context.isMaListShow === true && <ViewMA />}

        {/* 
                    ``````````````````````
                    DIALOG for GOAL RESULT

                    ``````````````````````        
                */}

        <Snackbeer
          isModalResponse200={context.isModalResponse200}
          setModalResponse200={context.setModalResponse200}
          messages={context.messages}
        />

        <SnackbeerRED
          isOpenSnackbeer={context.isOpenSnackbeer}
          setOpenSnackbeer={context.setOpenSnackbeer}
          messages={context.messages}
        />

        <DialogDeleteGoalMaster
          classes={classes}
          isModalDeleteGoalMaster={isModalDeleteGoalMaster}
          setModalDeleteGoalMaster={setModalDeleteGoalMaster}
          goalDetailState={goalDetailState}
        />

        <DialogEditGoalActiveUnUsed
          classes={classes}
          setModalEditGoalUnUSED={setModalEditGoalUnUSED}
          isModalEditGoalUnUSED={isModalEditGoalUnUSED}
          goalDetailState={goalDetailState}
          fotoQuery={fotoQuery}
          setFotoQuery={setFotoQuery}
        />

        {/* 
                    ````````````````````````````
                    DIALOG for MEASURED ACTIVITY

                    ````````````````````````````  
                */}

        <DialogCreateMAv2
          classes={classes}
          openCreateMA={openCreateMA}
          setOpenCreateMA={setOpenCreateMA}
          goalDetailState={goalDetailState}
        />

        {/* <DialogConfirmWeight
          classes={classes}
          openDialogConfirmWeight={openDialogConfirmWeight}
          setOpenDialogConfirmWeight={setOpenDialogConfirmWeight}
          setModalContinue={setModalCreateMA}
        /> */}

        <DialogSetWeight
          classes={classes}
          openDialogSetWeight={openDialogSetWeight}
          setOpenDialogSetWeight={setOpenDialogSetWeight}
        />

        <DialogWeightMA
          classes={classes}
          openDialogWeightMA={openDialogWeightMA}
          setOpenDialogWeightMA={setOpenDialogWeightMA}
        />

        <DialogError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation={textErrorMessage}
          errorStatus={errorStatus}
          listError={listError}
        />
      </Fragment>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewGoalMoreDetail);

const listChoose = [
  {
    id: 1,
    label: "Overview Goal",
  },

  {
    id: 2,
    label: "Measure Activities",
  },
];

const listChooseOverviewMA = [
  {
    id: 1,
    label: "Overview MA",
  },

  {
    id: 2,
    label: "",
  },
];
