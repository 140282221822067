const order_by = [
  {
    field: "name",
    name: "Name",
    method: "ASC",
    selected: false,
  },
  {
    field: "shift",
    name: "Shift",
    method: "ASC",
    selected: false,
  },
  {
    field: "staff_count",
    name: "Staff Count",
    method: "ASC",
    selected: false,
  },
  {
    field: "duration",
    name: "Duration",
    method: "ASC",
    selected: false,
  },
  {
    field: "presence",
    name: "Presence",
    method: "ASC",
    selected: false,
  },
  {
    field: "absence",
    name: "Absence",
    method: "ASC",
    selected: false,
  },
  {
    field: "sick",
    name: "Sick",
    method: "ASC",
    selected: false,
  },
  {
    field: "annual_leave",
    name: "Annual Leave",
    method: "ASC",
    selected: false,
  },
  {
    field: "special_leave",
    name: "Special Leave",
    method: "ASC",
    selected: false,
  },
  {
    field: "permission",
    name: "Permission",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_in_late",
    name: "Clock In Late",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_in_on_time",
    name: "Clock In On Time",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_in_early",
    name: "Clock In Early",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_out_late",
    name: "Clock Out Late",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_out_on_time",
    name: "Clock Out On Time",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_out_early",
    name: "Clock Out Early",
    method: "ASC",
    selected: false,
  },
];

const order_by_report_detail = [
  {
    field: "user_name",
    name: "User Name",
    method: "ASC",
    selected: false,
  },
  {
    field: "unit_type",
    name: "Unit Type",
    method: "ASC",
    selected: false,
  },
  {
    field: "date",
    name: "Date",
    method: "ASC",
    selected: false,
  },
  {
    field: "location_name",
    name: "Location Name",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_in",
    name: "Clock In",
    method: "ASC",
    selected: false,
  },
  {
    field: "clock_out",
    name: "Clock Out",
    method: "ASC",
    selected: false,
  },
];

export { order_by, order_by_report_detail };
