import React, { useState } from "react";
import { Paper, Typography, Grid } from "@material-ui/core";

import IdentitasSubInfoDasar from "./IdentitasSubInfoDasar";
import IdentitasSubAlamat from "./IdentitasSubAlamat";
import IdentitasSubInfoTambahan from "./IdentitasSubInfoTambahan";

const Identitas = (props) => {
  const { classes, identitasInformasiDasar, setFotoQueryEnabled } = props;

  /*
        ````````````````````````
        HANDLE TAB INFO PROFILE

        ````````````````````````
    */

  let informationProfileLet = {
    value: "informasi-dasar",
    label: "Informasi Dasar",
    // value: 'informasi-tambahan',
    // label: 'Informasi Tambahan'
  };

  const [isChoosedProfile, setChoosedProfile] = useState(informationProfileLet);

  const [identitasDasar, setIdentitasDasar] = useState(true);
  const [alamat, setAlamat] = useState(false);
  const [informasiTambahan, setInformasiTambahan] = useState(false);

  const handleChoosrTabProfile = (item) => {
    // console.log("Item : ", item);
    setChoosedProfile(item);

    if (item.value == "informasi-dasar") {
      setIdentitasDasar(true);
      setAlamat(false);
      setInformasiTambahan(false);
    }

    if (item.value == "alamat") {
      setAlamat(true);
      setIdentitasDasar(false);
      setInformasiTambahan(false);
    }

    if (item.value == "informasi-tambahan") {
      setInformasiTambahan(true);
      setAlamat(false);
      setIdentitasDasar(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={4} style={{ textAlign: "left" }}>
        <Paper
          elevation={1}
          className={classes.paperInfoIdentitas}
          square={true}
        >
          <br />
          <Typography variant="subtitle2" className={classes.titleHeader}>
            <b>Informasi Profil</b>
          </Typography>
          <ul>
            {informationProfile.length > 0 &&
              informationProfile.map((item, i) => (
                <li
                  key={i}
                  className={
                    isChoosedProfile.value === item.value
                      ? classes.titleActive
                      : classes.titleNotActive
                  }
                  style={{ margin: 8, cursor: "pointer" }}
                  onClick={() => handleChoosrTabProfile(item)}
                >
                  <Typography
                    variant="subtitle2"
                    className={
                      isChoosedProfile.value == item.value
                        ? classes.titleActive
                        : classes.titleNotActive
                    }
                  >
                    <b>{item.label}</b>
                  </Typography>
                </li>
              ))}
          </ul>

          <br />
          {/* <Typography variant='subtitle2' className={classes.titleHeader}>
                            <b>Eksport ke PDF</b> 
                        </Typography>
                        <Button variant='outlined' size='large' className={classes.buttonEksportPDF}>
                            <b>Eksport</b>
                        </Button>               
                        <br />   */}
        </Paper>
      </Grid>

      <Grid item xs={8} style={{ textAlign: "left " }}>
        {/* 
                        ```````````````````````
                        CONTENT INFORMASI DASAR

                        ```````````````````````
                    
                    */}
        {identitasDasar && (
          <IdentitasSubInfoDasar
            classes={classes}
            identitasInformasiDasar={identitasInformasiDasar}
            setFotoQueryEnabled={setFotoQueryEnabled}
          />
        )}

        {/* 
                        ``````````````
                        CONTENT ALAMAT

                        ``````````````
                    */}

        {alamat && <IdentitasSubAlamat classes={classes} />}

        {/* 
                        ```````````````````````````
                        CONTENT INFORMASI TAMBAHAN

                        ```````````````````````````
                    */}

        {informasiTambahan && <IdentitasSubInfoTambahan classes={classes} />}

        <br />
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

// export default withStyles(styles)(IdentitasDasar);
export default Identitas;

const informationProfile = [
  {
    value: "informasi-dasar",
    label: "Informasi Dasar",
  },
  // {
  //     value: 'alamat',
  //     label: 'Alamat'
  // },
  // {
  //     value: 'informasi-tambahan',
  //     label: 'Informasi Tambahan'
  // },
];
