import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import SettingsRoundedIcon from "@material-ui/icons/SettingsOutlined";
import ViewQuiltRoundedIcon from "@material-ui/icons/ViewQuiltRounded";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

import classNames from "classnames";

import DialogCustomPhase from "../dialogs/DialogCustomPhase";
import DialogTaskGroup from "../dialogs/DialogTaskGroup";

import iconNewGroup from "../../../../assets/images/icon_new_group.png";
import { FILTER_TASK, SET_GROUP_BY } from "../../context/types";
import ContextProject from "../../context/ContextProject";

const AnchorKanbanSetting = ({ classes }) => {
  const { projectTDispatch, setTriggerTask, setTriggerKanban, projectTState } =
    useContext(ContextProject);

  const [anchorEl, setAnchorEl] = useState(null);

  const [openCustomPhase, setOpenCustomPhase] = useState(false);
  const [openTaskGroup, setOpenTaskGroup] = useState(false);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleSelectPhase = (e) => {
    const value = e.target.value;

    if (value === "add") {
      setOpenCustomPhase(true);
      handleClose();
    }
  };

  const handleCustomPhase = () => {
    setOpenCustomPhase(true);
    handleClose();
  };

  const handleTaskGroup = () => {
    setOpenTaskGroup(true);
    handleClose();
  };

  const handleSTatus = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: null,
        filterPriority: null,
        filterComplete: null,
        filterPhase: null,
      },
    });

    projectTDispatch({
      type: SET_GROUP_BY,
      payload: {
        groupBy: "",
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));

    handleClose();
  };

  return (
    <div>
      <Tooltip title="Filter Kanban">
        <Button
          size="small"
          variant="outlined"
          className={classes.btnIcon}
          onClick={handleShow}
        >
          <SettingsRoundedIcon style={{ color: "#555" }} />
        </Button>
      </Tooltip>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                <MenuList role="menu" className={classes.menuList}>
                  <Box pl={2} p={1} mb={1} minWidth={285}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                    >
                      <b>Kanban Settings</b>
                    </Typography>
                  </Box>

                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleCustomPhase}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <ViewQuiltRoundedIcon
                          style={{
                            color: "#999",
                            fontSize: 15,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#555" }}>Custom Phase</span>
                      </Box>

                      {projectTState.groupBy === "custom_phase" && (
                        <CheckCircleOutlineRoundedIcon
                          style={{ color: "#e15367", fontSize: 15 }}
                        />
                      )}
                    </Box>
                  </MenuItem>

                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleTaskGroup}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <img
                          src={iconNewGroup}
                          height={14}
                          style={{ marginRight: 7 }}
                        />
                        <span style={{ color: "#555" }}>Task Group</span>
                      </Box>

                      {projectTState.groupBy === "task_group" && (
                        <CheckCircleOutlineRoundedIcon
                          style={{ color: "#e15367", fontSize: 15 }}
                        />
                      )}
                    </Box>
                  </MenuItem>

                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleSTatus}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" alignItems="center">
                        <EqualizerIcon
                          style={{
                            color: "#999",
                            fontSize: 15,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#555" }}>Status</span>
                      </Box>

                      {projectTState.groupBy === "" && (
                        <CheckCircleOutlineRoundedIcon
                          style={{ color: "#e15367", fontSize: 15 }}
                        />
                      )}
                    </Box>
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>

      <DialogCustomPhase
        classes={classes}
        openCustomPhase={openCustomPhase}
        setOpenCustomPhase={setOpenCustomPhase}
      />
      <DialogTaskGroup
        classes={classes}
        openTaskGroup={openTaskGroup}
        setOpenTaskGroup={setOpenTaskGroup}
      />
    </div>
  );
};

export default AnchorKanbanSetting;
