import React from "react";
import { Gluejar } from "@charliewilco/gluejar";

const PasteClipboard = ({ onFiles }) => {
  // ======================= Paste From Clipboard =======================
  const getImgClipboard = async (files) => {
    const fileImg =
      files.length > 0 &&
      files.map(async (file, i) => {
        const images = await fetch(file, {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        })
          .then((r) => r.blob())
          .then(
            (blobFile) =>
              new File([blobFile], `img_clipboard_${[i]}.png`, {
                type: "image/png",
              })
          );

        const img = Object.assign(images, { preview: file });

        // console.log("satuan img", img);

        return img;
      });

    // // console.log("fileImg img", fileImg);

    // fileImg.length > 0 && Promise.all(fileImg).then((res) => onFiles(res));

    if (fileImg.length > 0) {
      return Promise.all(fileImg).then((res) => onFiles(res));
    }
  };

  return <Gluejar onPaste={(filez) => getImgClipboard(filez.images)} />;
};

export default PasteClipboard;
