import React, { useContext } from "react";
import { MenuItem, FormControl, Select } from "@material-ui/core";

import { BootstrapInput } from "../GroupBy/BootstrapInput";

import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";

function MenuSelect({ classes, OrderByList, isDisable, onSelected }) {
  const [order, setOrder] = React.useState("name");
  const context = useContext(ContextAttendanceReportAdmin);

  React.useEffect(() => {
    if (OrderByList.length === 0) {
      setOrder("");
    }
  }, [OrderByList]);

  const handleChange = (e) => {
    const val = e.target.value;

    const filterSelectOrder =
      OrderByList !== undefined &&
      OrderByList.length > 0 &&
      OrderByList.filter((item) => item.field === val);

    // // console.log("SELECT filterSelectOrder :", filterSelectOrder);
    onSelected(filterSelectOrder[0]);
    setOrder(val);
  };

  return (
    <FormControl>
      <Select
        value={order}
        onChange={handleChange}
        input={<BootstrapInput />}
        disabled={isDisable}
      >
        {OrderByList !== undefined &&
          OrderByList.length > 0 &&
          OrderByList.map((item, i) => {
            if (item.field === "name") {
              if (context.dataSettingSummary.group_selected === "staff_name") {
                item.name = "Name";
              } else if (
                context.dataSettingSummary.group_selected === "position"
              ) {
                item.name = "Position";
              } else if (context.dataSettingSummary.group_selected === "unit") {
                item.name = "Unit";
              }
            }

            if (
              !(
                context.dataSettingSummary.group_selected === "staff_name" &&
                item.field === "staff_count"
              )
            ) {
              return (
                <MenuItem
                  key={i}
                  dense
                  value={item.field}
                  className={classes.hoverItem}
                >
                  {item.name}
                </MenuItem>
              );
            }
          })}
      </Select>
    </FormControl>
  );
}

export default MenuSelect;
