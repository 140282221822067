import React, { useContext, useState, useEffect } from "react";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DialogError from "../../../../components/DialogError";

import moment from "moment";

import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";
import ContextProject from "../../context/ContextProject";

import InputContainer from "./InputContainer";
import List from "./List";
import handleError from "../../../Report/components/global/handleError";
import { GET_KANBAN } from "../../context/types";

const Kanban = ({ classes }) => {
  const project_id = window.location.pathname.split("/")[2];
  const {
    dataKanban,
    setDataKanban,
    setTriggerKanban,
    setTriggerTask,
    projectTState,
  } = useContext(ContextProject);

  const [listID, setListID] = useState("");
  const [loading, setLoading] = useState(false);

  const [taskGroupList, setTaskGroupList] = useState([]);
  const [phaseCollection, setPhaseCollection] = useState([]);

  const [triggerPhase, setTriggerPhase] = useState(null);
  const [triggertaskGoup, setTriggerTaskGroup] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    AxiosConfig.get(`${URL_API}/project/${project_id}/task-group`)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setTaskGroupList(result);
        }
      })
      .catch((error) => {
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    AxiosConfig.get(`${URL_API}/project/${project_id}/custom-phase`)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setPhaseCollection(result);
        }
      })
      .catch((error) => {
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  }, [triggerPhase, triggertaskGoup]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (type === "list") {
      const listKanban = Object.values(dataKanban.lists);
      const newListIds = listKanban;
      // const newListIds = dataKanban.listIds;

      // Sort based on drag list
      const [reorderedItem] = newListIds.splice(source.index, 1);
      newListIds.splice(destination.index, 0, reorderedItem);

      // // console.log("newListIds phs", newListIds);
      // // console.log("listKanban phs", listKanban);
      // // console.log("toObj phs", toObj);

      // insert to State
      const newState = {
        ...dataKanban,
        listIds: newListIds.map((el) => el.id),
      };
      setDataKanban(newState);

      // remove default list
      const removeDefault = listKanban
        .filter((el) => el.title !== "Default")
        .map((el) => el.title);

      // sort phase based on title list, and GET ids phase
      const sortByTitle = removeDefault
        .map((i) => phaseCollection.find((j) => j.title === i))
        .map((el) => el.id);

      // // console.log("removeDefault phs", removeDefault);
      // // console.log("c phs", sortByTitle);

      // payload
      const data = {
        custom_phases: sortByTitle,
      };

      // // console.log("data", data);

      AxiosConfig.put(
        `${URL_API}/project/${project_id}/custom-phase/sort-order`,
        data
      )
        .then((res) => {
          // // console.log("RES phase sort", res);

          if (res.status === 200) {
            setTriggerTask(Math.floor(Math.random() * 100));
            setTriggerKanban(Math.floor(Math.random() * 100));
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("ERROR", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });

      // return;
    } else {
      const sourceList = dataKanban.lists[source.droppableId];
      const destinationList = dataKanban.lists[destination.droppableId];
      const draggingCard = sourceList.cards.filter(
        (card) => card.id === draggableId
      )[0];

      // details
      const destinationTitle = destinationList?.title;
      const statusId = destinationTitle.toUpperCase();

      const updateCard = {
        ...draggingCard,
        status: {
          id: destinationList.title.toUpperCase(),
          name: destinationList?.title,
          code: destinationList.title.toUpperCase(),
        },
      };

      // GET id form phase or task group
      const objTaskGroup = taskGroupList?.find(
        (el) => el.name === destinationTitle
      );
      const objCustomPhase = phaseCollection?.find(
        (el) => el.title === destinationTitle
      );

      // // console.log("obgTaskGroup", objTaskGroup);
      // // console.log("objCustomPhase", objCustomPhase);
      // // console.log("sourceList", sourceList);
      // // console.log("destinationList", destinationList);
      // // console.log("draggingCard", draggingCard);
      // // console.log("updateCard", updateCard);
      // // console.log("projectTState", projectTState);
      // // console.log("projectTState.groupBy", projectTState.groupBy);

      // setListID
      setListID(destinationList.id);

      if (source.droppableId === destination.droppableId) {
        sourceList.cards.splice(source.index, 1);
        destinationList.cards.splice(destination.index, 0, draggingCard);

        const newState = {
          ...dataKanban,
          lists: {
            ...dataKanban.lists,
            [sourceList.id]: destinationList,
          },
        };
        setDataKanban(newState);
      } else {
        sourceList.cards.splice(source.index, 1);
        destinationList.cards.splice(destination.index, 0, updateCard);

        const newState = {
          ...dataKanban,
          lists: {
            ...dataKanban.lists,
            [sourceList.id]: sourceList,
            [destinationList.id]: destinationList,
          },
        };

        setDataKanban(newState);

        setLoading(true);

        let data = {
          Todolist: {
            title: draggingCard.title,
            description: draggingCard.description,
            remarks: draggingCard.description,
            owner_id: draggingCard.owner_id,
            status:
              projectTState.groupBy === "" ? statusId : draggingCard.status_id,
            priority: draggingCard.priority?.id,
            due_date: moment(draggingCard.due_date).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            start_date: moment(draggingCard.start_date).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end_date:
              draggingCard.end_date === null
                ? null
                : moment(draggingCard.end_date).format("YYYY-MM-DD HH:mm:ss"),
            tags: draggingCard.tags,
            actual_result: Number(draggingCard.actual_result),
            target_result: Number(draggingCard.target_result),
            project_id: draggingCard.project_id,
            predecessor_id: draggingCard.dependencies,
            task_group_id: draggingCard.task_group_id,
            custom_phase_id:
              projectTState.groupBy === "custom_phase"
                ? objCustomPhase?.id
                : draggingCard?.custom_phase_id,
          },
        };

        if (projectTState.groupBy === "") {
          if (
            draggingCard?.status?.id !== updateCard?.status?.id &&
            draggingCard?.due_date !== updateCard?.due_date
          ) {
            data.Todolist["update_value"] = ["status_id", "due_date"];
          }
          if (draggingCard?.status?.id !== updateCard?.status?.id) {
            data.Todolist["update_value"] = ["status_id"];
          }
          if (draggingCard?.due_date !== updateCard?.due_date) {
            data.Todolist["update_value"] = ["due_date"];
          }
        }
        // if (projectTState.groupBy === "custom_phase") {
        //   if (draggingCard?.custom_phase_id !== updateCard?.custom_phase_id) {
        //     data.Todolist["update_value"] = ["custom_phase_id"];
        //   }
        // }
        // if (projectTState.groupBy === "task_group") {
        //   if (draggingCard?.task_group_id !== updateCard?.task_group_id) {
        //     data.Todolist["update_value"] = ["task_group_id"];
        //   }
        // }

        // // console.log("DRAG UPDATE D", data);

        if (projectTState.groupBy !== "task_group") {
          AxiosConfig.put(`${URL_API}/todolist/${draggableId}`, data)
            .then((res) => {
              // // console.log("Res edit title", res);
              if (res.status === 200) {
                setTriggerKanban(Math.floor(Math.random() * 100));
                setTriggerTask(Math.floor(Math.random() * 100));

                setLoading(false);
              }
            })
            .catch((error) => {
              // console.log("error", error);
              setLoading(false);
              setTriggerKanban(Math.floor(Math.random() * 100));

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);
            });
        } else {
          const dataTaskGroup = {
            todos: [draggingCard?.id],
          };

          // // console.log("dataTaskGroup", dataTaskGroup);
          // // console.log("draggingCard", draggingCard.id);

          AxiosConfig.post(
            `${URL_API}/project/${project_id}/task-group/${objTaskGroup?.id}/move`,
            dataTaskGroup
          )
            .then((res) => {
              if (res.status === 200) {
                setTriggerTask(Math.floor(Math.random() * 100));
                setTriggerKanban(Math.floor(Math.random() * 100));
                setLoading(false);
              }
            })
            .catch((error) => {
              setLoading(false);
              // console.log("error", error);

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);
            });
        }
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="app" type="list" direction="horizontal">
        {(provided) => (
          <div
            className="wrapper"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {dataKanban.listIds.map((listId, index) => {
              const list = dataKanban.lists[listId];
              const currentLoad = list.id === listID ? loading : false;

              return (
                <List
                  list={list}
                  key={listId}
                  index={index}
                  classes={classes}
                  loadList={currentLoad}
                  phaseCollection={phaseCollection}
                  taskGroupList={taskGroupList}
                  onTriggerPhase={setTriggerPhase}
                  onTriggerTaskGroup={setTriggerTaskGroup}
                />
              );
            })}
            <div>
              <InputContainer type="list" />
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </DragDropContext>
  );
};

export default Kanban;
