import React from "react";

import { Avatar, Chip, Typography } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import TruncateTextShortSuperPendek from "../../../../../utilities/TruncateTextShortSuperPendek";

import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../../assets/images/icons_file/Text.png";
import image_icon from "../../../../../assets/images/icons_file/Image.png";

function FileDriveSelected({ item, handleDeleteSelectedDrive }) {
  let source = "";
  if (item.mime_type !== null) {
    if (item.mime_type.includes("image")) {
      source = image_icon;
    } else if (item.mime_type === "application/pdf") {
      source = pdf_icon;
    } else if (item.mime_type.includes("video")) {
      source = video_icon;
    } else if (
      item.mime_type === "application/msword" ||
      item.mime_type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      source = word_icon;
    } else if (
      item.mime_type === "application/vnd.ms-powerpoint" ||
      item.mime_type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      source = ppt_icon;
    } else if (
      item.mime_type === "application/vnd.ms-excel" ||
      item.mime_type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      source = excel_icon;
    } else if (item.mime_type === "text/plain") {
      source = text_icon;
    }
  }

  return (
    <Chip
      size="small"
      avatar={
        <Avatar
          alt={item.name}
          src={source}
          style={{
            borderRadius: 5,
            width: 32,
            border: "2px solid #f2f2f2",
            margin: 3,
          }}
        />
      }
      label={
        <Typography variant="caption">
          {TruncateTextShortSuperPendek(item.name)}
        </Typography>
      }
      onDelete={() => handleDeleteSelectedDrive(item)}
      deleteIcon={
        <HighlightOffIcon fontSize="small" style={{ color: "#d1354a" }} />
      }
      style={{ marginRight: 5, marginBottom: 5 }}
    />
  );
}

export default FileDriveSelected;
