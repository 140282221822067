import React, { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  IconButton,
} from "@material-ui/core";
import ReactPlayer from "react-player";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { URL_API } from "../../../../constants/config-api";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    fontFamily: "Roboto",
  },
  img: {
    // height: 255,
    height: "auto",
    display: "block",
    //   maxWidth: 400,
    overflow: "hidden",
    width: "100%",
  },
  title: {
    fontFamily: "Roboto",
  },
  paperTransparentWithMd: {
    background: "transparent",
    backgroundColor: "transparent",
    // borderRadius: '30px',
    border: 0,
    boxShadow: "0px 0px 0px 0px",
  },
}));

const DialogVideoPlayer = (props) => {
  const {
    // classes,
    isOpenDialogVideoPlayer,
    setOpenDialogVideoPlayer,
    userToken,
    dataStringTile,
    videoName,

    pictureInPicture,
    setPictureInPicture,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [maxWidth, setMaxWidth] = useState("md");

  const [isPlayed, setPlayed] = useState(false);

  return (
    <Dialog
      open={isOpenDialogVideoPlayer}
      onClose={() => setOpenDialogVideoPlayer(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      style={{ height: "auto", width: "100%" }}
      maxWidth={maxWidth}
      // fullScreen
      classes={{
        paperWidthMd: classes.paperTransparentWithMd,
        // root: classes.paperTransparent
      }}
      // PaperProps={{
      //     root: classes.paperTransparent
      // }}
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start">
          <IconButton
            size="small"
            onClick={() => {
              setOpenDialogVideoPlayer(false);
              setPictureInPicture(true);
            }}>
            <HighlightOffIcon style={{ color: "white", fontSize: "17px" }} />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <Box display="flex" justifyContent="center">
          <ReactPlayer
            url={URL_API + "/" + dataStringTile + "?token=" + userToken}
            playing={true}
            controls={true}
            // pip={false}
          />
        </Box>
      </DialogContent>
      {/* <DialogActions style={{alignItems: "center", justifyContent:'center'}}>
            
              
        </DialogActions> */}
      <br />
    </Dialog>
  );
};

export default DialogVideoPlayer;
