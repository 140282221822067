import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

import { navigate } from "hookrouter";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";

import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";

const DialogDeleteProject = ({
  classes,
  origin,
  project,
  openDeleteProject,
  setOpenDeleteProject,
}) => {
  const { setTriggerProject } = useContext(ContextProject);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteProject = () => {
    setLoading(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    AxiosConfig.delete(`${URL_API}/swd/goal/${project.id}`)
      .then((res) => {
        if (res.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: "project",
              event: "delete",
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ""
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: "",
              device_version: OSName,
              long: "",
              lat: "",
              entity_id: project?.id,
              entity_name: project?.name,
              remarks: "Delete Project 1",
              comment: "",
            },
          };

          setDataRemarks(dataLog);

          // setTriggerProject(Math.floor(Math.random() * 100));
          // setOpenDeleteProject(false);
          setLoading(false);

          setOpenRemarksLog(true);

          if (origin === "task") {
            navigate("/project");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDeleteProject}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Delete Project</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Apakah Anda yakin ingin hapus <b>{project?.name}</b> ?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenDeleteProject(false)}
        >
          Cancel
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleDeleteProject}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks={`Menghapus Project - ${
          dataRemarks !== null ? dataRemarks.user_activity_log?.entity_name : ""
        }`}
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenDeleteProject}
        onTriggerRemarks={setTriggerProject}
        origin="back-project"
        editRemarks={true}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDeleteProject;
