import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useCallback,
} from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Box,
  IconButton,
  Paper,
  InputAdornment,
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { useDebouncedCallback } from "use-debounce";

import moment from "moment";

// import DialogCreateDirectSubGoal from '../ComponentSubGoal/DialogCreateDirectSubGoal';
import SearchIcon from "@material-ui/icons/Search";
import CircleInPercentNEW from "../../../../components/CircleInPercentNEW";
import ContextGoalDetail from "../Context/ContextGoalDetail";
import { styles } from "../Style/StyleSubGoal";

import Snackbeer from "../../../../components/Snackbeer";
import DialogError from "../../../../components/DialogError";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const useStyles = makeStyles(styles);

const DialogCreateAddDirectLink = (props) => {
  const {
    isOpenDialogAddDirectLink,
    setOpenDialogAddDirectLink,
    setFireGoalDetail,
    collectionGoalResultList,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const context = useContext(ContextGoalDetail);
  const locationPathGoalId = window.location.pathname.split("/")[2];
  /*
        ```````````````````
        COMPONENT DID MOUNT
        
        ```````````````````
    */

  const [listGoal, setListGoal] = useState([]);
  const [loader, setLoader] = useState(false);

  const [goalIdLocal, setGoalIdLocal] = useState("");

  useEffect(() => {
    if (isOpenDialogAddDirectLink === true) {
      setGoalIdLocal(locationPathGoalId);
      // context.setGoalId(goalDetailAfterParse.id);

      //*
      context.setSuccessCreateSubGoal(false);

      //*
      setSelected([]);

      //*
      setFireGoalDetail(false);

      axiosConfig
        .get(URL_API + `/swd/goal/${locationPathGoalId}/direct-link:add/batch`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          if (response.data.data !== null && response.data.data !== undefined) {
            if (response.data.data.goalCollections !== undefined) {
              setListGoal(response.data.data.goalCollections);
            }
            // context.setPeriodCollections(response.data.data.periodCollections);
          }
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
        });
    }
  }, [isOpenDialogAddDirectLink]);

  /*
        ```````````````````````````````
        HANDLE CHOOSE USER IN CHECKBOX

        ```````````````````````````````
    */

  const [selected, setSelected] = useState([]);

  const handleClickCheckbox = (e, item) => {
    // console.log("Item checkbox - single choose: ", item);

    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // console.log("Item checkbox - munltiple choose:: : ", newSelected);

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /*
        ````````````````````````````````````````
        HANDLE SIMPAN DATA & MODAL LANJUT DIALOG

        ````````````````````````````````````````
    */
  const [isModalLanjut, setModalLanjut] = useState(false);

  const handleTambahSebagaiDirectLink = () => {
    // console.log("selected checbox oke", selected);

    setLoader(true);

    let data = {
      Goal: {
        goal_id: selected,
      },
    };

    // console.log("Data submit : ", data);

    //*
    axiosConfig
      .post(
        URL_API + `/swd/goal/${locationPathGoalId}/direct-link:add/batch`,
        data
      )
      .then(function (response) {
        setLoader(false);
        // console.log("Response Original ADD DIRECT LINK : ", response);

        setFireGoalDetail(true);
        setModalResponse200(true);
        setMessages("Berhasil menambahkan Direct Link !");
        context.setSuccessCreateSubGoal(true);
        setOpenDialogAddDirectLink(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("Error : ", error.response);

        if (error.response !== undefined) {
          setErrorStatus(undefined);
          setOpenDialogError(true);
        }
      });
  };

  /*  
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  /*
        `````````````````
        Search MAIN GOAL

        ````````````
    */

  const [loaderEmpty, setLoaderEmpty] = useState(false);
  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      setLoaderEmpty(true);

      // console.log("I want to know Goal : ", value);
      const userToken = localStorage.getItem("userToken");

      if (userToken !== undefined) {
        axiosConfig
          .get(
            URL_API +
              `/swd/goal/${locationPathGoalId}/direct-link:add/batch?options[filter][goal_id_search]=${value}`
          )
          .then(function (response) {
            setLoaderEmpty(false);
            // console.log("Response Original AFTER SEARCH : ", response);

            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.goalCollections !== undefined) {
                setListGoal(response.data.data.goalCollections);
              }
            }
          })
          .catch(function (error) {
            setLoaderEmpty(false);
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }, []),
    1000,

    { maxWait: 5000 }
  );

  return (
    <Fragment>
      <Dialog
        open={isOpenDialogAddDirectLink}
        onClose={() => setOpenDialogAddDirectLink(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6" className={classes.title}>
                <b>
                  Add <i> Direct Link</i>
                </b>
              </Typography>
            </Box>
            <Box marginTop={-2} marginRight={-2}>
              <IconButton
                size="small"
                onClick={() => {
                  // context.setOpenDialogCreateDirectSubGoal(false)
                  setOpenDialogAddDirectLink(false);
                }}
              >
                <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ textAlign: "left" }}>
          <Grid container>
            <Grid item md={12}>
              <TextField
                id="standard-basic"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Telusuri..."
                fullWidth
                // className={classes.textField}
                // variant="outlined"
                inputProps={{
                  className: classes.title,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton style={{ marginLeft: "-16px" }}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <br />
              <br />

              {loaderEmpty === true && (
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <CircularProgress size={24} style={{ color: "red" }} />
                </Box>
              )}

              <List className={classes.listRoot}>
                {listGoal.length > 0 ? (
                  listGoal.map((item, i) => {
                    const isItemSelected = isSelected(item.id);

                    return (
                      <Fragment key={i}>
                        <Paper elevation={1}>
                          <ListItem
                            style={{ paddingLeft: 0, marginRight: 24 }}
                            // button
                            aria-checked={isItemSelected}
                            selected={isItemSelected}
                            onClick={(e) => handleClickCheckbox(e, item)}
                          >
                            <Checkbox checked={isItemSelected} />

                            <Box width={56} marginRight={2}>
                              <CircleInPercentNEW
                                classes={classes}
                                item={item}
                              />
                            </Box>
                            {/* <ListItemAvatar>
                                                            <Avatar>
                                                                <PersonIcon />
                                                            </Avatar>
                                                        </ListItemAvatar> */}

                            <ListItemText
                              className={classes.listItemtext}
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  className={classes.title}
                                >
                                  <b>{item.name}</b>
                                </Typography>
                              }
                              secondary={
                                item.timeFrame.code === "date_range" && (
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    {moment(item.start_date).format(
                                      "DD MMMM YYYY"
                                    )}
                                    -
                                    {moment(item.end_date).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </Typography>
                                )
                              }
                            />
                          </ListItem>
                          <Box display="flex" marginTop={-1}>
                            <Box flexGrow={1} marginLeft={4}>
                              <ListItem style={{ padding: 0 }}>
                                <IconButton size="small">
                                  <PersonIcon />
                                </IconButton>

                                <ListItemText
                                  className={classes.listItemtext}
                                  primary={
                                    <Typography
                                      variant="caption"
                                      className={classes.title}
                                    >
                                      <b>
                                        {item.owner.member.first_name +
                                          " " +
                                          item.owner.member.last_name}
                                      </b>
                                      &nbsp;| &nbsp;
                                      {item.owner.structure_position_title_name}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </Box>
                          </Box>
                        </Paper>
                        <br />
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      Tidak ada <i>Goal</i> yang dapat di tambahkan sebagai{" "}
                      <i>Sub-Goal</i>!
                    </b>
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center " }}
        >
          <Button
            onClick={handleTambahSebagaiDirectLink}
            // onClick={() => setModalLanjut(true)}
            variant="contained"
            className={classes.button}
            fullWidth
            disabled={
              listGoal.length === 0 || selected.length === 0 ? true : false
            }
          >
            {loader !== true ? (
              "Tambah sebagai Direct Link"
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      {/* <DialogCreateDirectSubGoal 
                setOpenDialogAddDirectLink = { setOpenDialogAddDirectLink }
                listGoal = { listGoal }
                selected = { selected }
                setFireGoalDetail = { setFireGoalDetail }
            /> */}

      <DialogError
        classes={classes}
        setOpenDialogError={setOpenDialogError}
        isOpenDialogError={isOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={messages}
      />
    </Fragment>
  );
};

export default DialogCreateAddDirectLink;
