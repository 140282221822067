import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";
import ContextProject from "../../context/ContextProject";

const DialogConfirmChecklist = ({
  classes,
  openConfirmChecklist,
  setOpenConfirmChecklist,
  onClose,
  onResultSource,
}) => {
  const { projectTState } = useContext(ContextProject);

  const handleConfirmChecklist = () => {
    setOpenConfirmChecklist(false);
    onClose();
  };
  const handleClose = () => {
    const { scope_result_source } = projectTState.projectDetails;

    setOpenConfirmChecklist(false);
    onResultSource(scope_result_source);
  };
  return (
    <Dialog
      open={openConfirmChecklist}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Konfirmasi Update Progress Result</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Semua Target akan ke (jumlah checklist)
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleConfirmChecklist}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmChecklist;
