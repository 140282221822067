import React from "react";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";

const FilterListChecnbox = ({
  classes,
  collections,
  isSelected,
  moduls,
  onModuls,
}) => {
  return (
    <Box pt={1} pb={2} px={3} display="flex" flexDirection="column">
      {collections.map((item, i) => {
        const isItemSelected = isSelected(item.code);

        const handleChangeCheckbox = (item) => {
          const selectedIndex = moduls.indexOf(item.code);

          let newSelected = [];

          if (selectedIndex === -1) {
            newSelected = newSelected.concat(moduls, item.code);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(moduls.slice(1));
          } else if (selectedIndex === moduls.length - 1) {
            newSelected = newSelected.concat(moduls.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              moduls.slice(0, selectedIndex),
              moduls.slice(selectedIndex + 1)
            );
          }

          onModuls(newSelected);
        };

        return (
          <div key={i}>
            <FormControlLabel
              style={{ margin: 0, padding: 0 }}
              label={
                <Box display="flex" alignItems="center">
                  <p
                    style={{
                      color: "#555",
                      fontSize: 14,
                      marginLeft: 3,
                    }}
                    className={classes.title}
                  >
                    {item.name}
                  </p>
                </Box>
              }
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  disableRipple
                  color="default"
                  checked={isItemSelected}
                  onChange={() => handleChangeCheckbox(item)}
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                />
              }
            />
          </div>
        );
      })}
    </Box>
  );
};

export default FilterListChecnbox;
