import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  TextField,
  Link,
} from "@material-ui/core";

import { navigate } from "hookrouter";

import AxiosConfig from "../constants/config-axios";
import { URL_API } from "../constants/config-api";
import handleError from "../screen/Report/components/global/handleError";
import DialogError from "./DialogError";

const DialogRemarksLog = ({
  classes,
  openRemarksLog,
  setOpenRemarksLog,
  defaultRemarks,
  dataRemarks,
  onCloseRemarks,
  onTriggerRemarks,
  onBack,
  origin,
  editRemarks,
}) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openRemarksLog) {
      setName(defaultRemarks);
    }
  }, [openRemarksLog, defaultRemarks]);

  // useEffect(() => {
  //   setOpenRemarksLog(true);
  // }, []);

  const handleName = (e) => setName(e.target.value);

  const handleSkipRemarks = () => {
    // setLoading(true);

    const data = {
      user_activity_log: {
        ...dataRemarks.user_activity_log,
        comment: "-",
      },
    };

    AxiosConfig.post(`${URL_API}/account-management/user/activity-log`, data)
      .then((resLog) => {
        // console.log("res resLog", resLog);

        setLoading(false);
        onCloseRemarks(false);
        onTriggerRemarks(Math.floor(Math.random() * 1000 + 1));
        setOpenRemarksLog(false);

        if (origin === "need-back") {
          onBack(true);
        }

        if (origin === "back-project") {
          navigate("/project");
        }

        if (origin === "ma-all") {
          navigate("/all-ma");
        }

        if (origin === "goal") {
          navigate("/goal");
        }
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleRemarksLog = () => {
    setLoading(true);

    const data = {
      user_activity_log: {
        ...dataRemarks.user_activity_log,
        comment: name,
      },
    };

    AxiosConfig.post(`${URL_API}/account-management/user/activity-log`, data)
      .then((resLog) => {
        // console.log("res resLog", resLog);

        setLoading(false);
        onCloseRemarks(false);
        onTriggerRemarks(Math.floor(Math.random() * 1000 + 1));
        setOpenRemarksLog(false);

        if (origin === "need-back") {
          onBack(true);
        }

        if (origin === "back-project") {
          navigate("/project");
        }
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openRemarksLog}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Tambah Komentar</b>
          {/* <b>Komentar Sementara Ditutup</b> */}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box>
          <Typography variant="subtitle2" className={classes.title888}>
            Komentar
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={3}
            value={name}
            onChange={handleName}
            // disabled
            // onKeyPress={(e) => handeleKeyPress(e)}
          />
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link component="button" variant="body2" onClick={handleSkipRemarks}>
          Lewati
        </Link>
        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleRemarksLog}
        >
          {loading ? "Mengirim..." : "Kirim"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogRemarksLog;
