import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  HighlightOff as HighlightOffIcon,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";

import moment from "moment";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../../../Report/components/global/handleError";
import SnackbarError from "../../../../components/SnackbarError";
import ContextProject from "../../context/ContextProject";
import { DRAWER_OPEN, SET_TASK_DETAILS } from "../../context/types";

const DialogDuplicateTask = ({
  classes,
  openDuplicateTask,
  setOpenDuplicateTask,
  task,
}) => {
  const { setTriggerTask, projectTDispatch } = useContext(ContextProject);

  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenSnackbarError, setOpenSnackbarError] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDetails = () => {
    projectTDispatch({
      type: DRAWER_OPEN,
      payload: { drawerTask: true, actionTask: "duplicate" },
    });
    projectTDispatch({
      type: SET_TASK_DETAILS,
      payload: { taskDetails: task },
    });

    const tasks = JSON.stringify(task);

    localStorage.setItem("task_details", tasks);

    setOpenDuplicateTask(false);
  };

  const handleDuplicateTask = () => {
    setLoading(true);

    const data = {
      taskData: {
        custom_phase_id: task.custom_phase_id,
        description: task.description,
        due_date: moment(task.due_date).format("YYYY-MM-DD"),
        owner_id: task.owner_id,
        predecessor_id: task.dependencies,
        priority: task.priority?.id,
        project_id: task.project_id,
        remarks: task.remarks,
        start_date: moment(task.start_date).format("YYYY-MM-DD"),
        status: task.status?.id,
        tags: task.tags,
        target_result:
          task.target_result === null ? 0 : Number(task.target_result),
        task_group_id: task.task_group_id,
        title: task.title,
      },
      taskId: task?.id,
    };

    AxiosConfig.post(`${URL_API}/todolist/duplicate`, data)
      .then((res) => {
        if (res.status === 200) {
          setOpenDuplicateTask(false);
          setTriggerTask(Math.floor(Math.random() * 100));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error.response);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);

        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog open={openDuplicateTask} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Duplicate Task</Typography>

          <IconButton onClick={() => setOpenDuplicateTask(false)}>
            <HighlightOffIcon fontSize="default" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box bgcolor="#eee" borderRadius={5} p={2} px={3}>
          <Grid container>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Nama Task</b>
              </Typography>
              <Typography variant="caption">{task.title}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Owner</b>
              </Typography>
              <Typography variant="caption">{`${task.owner?.member?.first_name} ${task.owner?.member?.last_name}`}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Created By</b>
              </Typography>
              <Typography variant="caption">
                <Typography variant="caption">{`${task.createdBy?.first_name} ${task.createdBy?.last_name}`}</Typography>
              </Typography>
            </Grid>
          </Grid>
          <Box my={2} />
          <Grid container>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Deskripsi</b>
              </Typography>
              <Typography variant="caption">
                {task.description === null ? "-" : task.description}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Status</b>
              </Typography>
              <Typography variant="caption">{task.status?.name}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Priority</b>
              </Typography>
              <Typography variant="caption">{task.priority?.name}</Typography>
            </Grid>
          </Grid>
          <Box my={2} />
          <Grid container>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Start Date</b>
              </Typography>
              <Typography variant="caption">
                {moment(task.start_date).format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Due Date</b>
              </Typography>
              <Typography variant="caption">
                {moment(task.due_date).format("DD MMMM YYYY")}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>End Date</b>
              </Typography>
              <Typography variant="caption">
                {task?.end_date !== null
                  ? moment(task.end_date).format("DD MMMM YYYY")
                  : "Not Set"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Button
            size="small"
            startIcon={<EditOutlinedIcon />}
            style={{ textTransform: "capitalize", fontWeight: "600" }}
            onClick={handleDetails}
          >
            Edit Details
          </Button>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 16,
        }}
      >
        <Box display="flex" alignItems="center">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={() => setOpenDuplicateTask(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            size="small"
            variant={loading ? "contained" : "outlined"}
            className={classes.button1}
            disabled={loading}
            onClick={handleDuplicateTask}
          >
            {loading ? "Duplicating Task..." : "Duplicate"}
          </Button>
        </Box>
      </DialogActions>

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenSnackbarError}
        setOpenDialogError={setOpenSnackbarError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDuplicateTask;
