import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Menu,
  Typography,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuSelects from "./MenuSelects";
import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";

const GroupBy = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  const [anchorGroupBy, setAnchorGroupBy] = useState(null);

  const handleShowAnchorGroupBy = (event) => {
    setAnchorGroupBy(event.currentTarget);
  };

  const handleCloseAnchorGroupBy = () => {
    setAnchorGroupBy(null);
  };

  const handleShowResult = () => {
    context.getReportSummaryList();
    handleCloseAnchorGroupBy();
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        endIcon={<ExpandMoreIcon />}
        className={classes.actionBtn}
        disabled={context.loader}
        onClick={handleShowAnchorGroupBy}
      >
        Group By
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorGroupBy}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorGroupBy)}
        onClose={handleCloseAnchorGroupBy}
      >
        <Box
          minWidth={350}
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}
        >
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Group Table</b>
          </Typography>
        </Box>
        <Box
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}
          display="flex"
          alignItems="center"
        >
          <Typography variant="caption" className={classes.title333}>
            Group by
          </Typography>
          <MenuSelects classes={classes} listGroup={context.masterGroupByReportList} />
        </Box>
        <Box margin={2}>
          <Divider />
        </Box>
        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorGroupBy}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="outlined"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResult}
          >
            Show results
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default GroupBy;

// const listGroup = [
//   {
//     id: 1,
//     name: "User",
//     val: "user",
//   },
//   {
//     id: 2,
//     name: "Position Level",
//     val: "position_level",
//   },
//   {
//     id: 3,
//     name: "Unit",
//     val: "unit",
//   },
//   {
//     id: 4,
//     name: "Shift & Schedule",
//     val: "shift_and_schedule",
//   },
//   {
//     id: 5,
//     name: "Work Location",
//     val: "work_location",
//   },
// ];
