import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Typography,
  Avatar,
} from "@material-ui/core";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import TruncateTextShortNameGoal from "../../../../../utilities/TruncateTextShortNameGoal";

import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../../assets/images/icons_file/Text.png";

function ListFileDrive({
  item,
  classes,
  selectedIndex,
  onListItemClick,
  i,
  openDialogAddEvidence,
  triggerNavigate,
}) {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (openDialogAddEvidence) {
      axiosConfig
        .get(`${URL_API}/drive/file/${item.id}?mode=thumbnail`, {
          responseType: "blob",
        })
        .then((response) => {
          // // console.log("Response Download", response);

          if (response.status === 200) {
            setImage(response.data);
          }
        })
        .catch((error) => {
          // console.log("Error", error);
        });
    }
  }, [openDialogAddEvidence, triggerNavigate]);

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([image], { type: item.mime_type });

  return (
    <ListItem
      button
      selected={selectedIndex === i}
      onClick={(event) => onListItemClick(event, i, item)}
    >
      <ListItemIcon>
        {item.mime_type.includes("image") ? (
          <Avatar
            variant="rounded"
            style={{ width: 20, height: 20 }}
            src={image !== "" && URL.createObjectURL(blobUrlImage)}
          />
        ) : item.mime_type === "application/pdf" ? (
          <img
            src={pdf_icon}
            style={{
              width: 19,
            }}
          />
        ) : item.mime_type === "application/msword" ||
          item.mime_type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
          <img
            src={word_icon}
            style={{
              width: 19,
            }}
          />
        ) : item.mime_type === "application/vnd.ms-powerpoint" ||
          item.mime_type ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
          <img
            src={ppt_icon}
            style={{
              width: 19,
            }}
          />
        ) : item.mime_type === "application/vnd.ms-excel" ||
          item.mime_type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
          <img
            src={excel_icon}
            style={{
              width: 19,
            }}
          />
        ) : item.mime_type.includes("video") ? (
          <img
            src={video_icon}
            style={{
              width: 19,
            }}
          />
        ) : item.mime_type === "text/plain" ? (
          <img
            src={text_icon}
            style={{
              width: 19,
            }}
          />
        ) : (
          <InsertDriveFileIcon fontSize="small" color="#555" />
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="subtitle2" className={classes.title}>
            {TruncateTextShortNameGoal(item.name)}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        {selectedIndex === i && (
          <CheckCircleRoundedIcon style={{ fontSize: 15, color: "#d1354a" }} />
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default ListFileDrive;
