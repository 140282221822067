import React from 'react';
import {
  CircularProgressbar,
  buildStyles,
} from 'react-circular-progressbar';
import { easeQuadInOut } from 'd3-ease';
import numeral from 'numeral';

import AnimatedProgressProvider from '../../../../../../components/AnimatedProgressProvider';

const CircularProgress = ({ average }) => {
  return (
    <>
      {average < 85 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={average}
          duration={3}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const newVal = numeral(value).format('0.00');

            return (
              <CircularProgressbar
                value={newVal}
                text={`${newVal}%`}
                styles={buildStyles({
                  pathColor: '#CF364A',
                  textColor: 'black',
                  pathTransition: 'none',
                  textSize: 14,
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {average > 85 && average < 100 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={average}
          duration={3}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const newVal = numeral(value).format('0.00');

            return (
              <CircularProgressbar
                value={newVal}
                text={`${newVal}%`}
                styles={buildStyles({
                  pathColor: '#E8A700',
                  textColor: 'black',
                  pathTransition: 'none',
                  textSize: 14,
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}

      {average >= 100 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={average}
          duration={3}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const newVal = numeral(value).format('0.00');

            return (
              <CircularProgressbar
                value={newVal}
                text={`${newVal}%`}
                styles={buildStyles({
                  pathColor: '#35B970',
                  textColor: 'black',
                  pathTransition: 'none',
                  textSize: 14,
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}
    </>
  );
};

export default CircularProgress;
