import { fade } from "@material-ui/core/styles";

export const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  tabContainer: {
    flexGrow: 1,
    height: 'auto',
    marginRight: '20px',
  },
  table: {
    minWidth: '100%',
    borderSpacing: "0px"
  },
  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    backgroundColor: "#d64253",
    border: 0,
    fontFamily: "'Lato', sans-serif",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  buttonDisabled: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    color: "grey",
    fontWeight: "bold",
  },
  buttonOutlined: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },
  titleTextField: {
    fontFamily: "'Lato', sans-serif",
    width: 72,
  },
  titleTextFieldDisabled: {
    background:"#80808012"
  },
  tabFilterContainer: {
    flexGrow: 1,
    height: 'auto',
    marginRight: '20px',
    marginBottom: '25.6px',
  },
  datepicker: {
    fontFamily: "'Lato', sans-serif",
    marginTop: "0px",
    marginBottom: "0px",
  },
  gridItemFilter: {
    paddingLeft: "15px",
    paddingRight: "15px"
  },
  gridMiddle: {
    borderLeft: "2px solid #d7cbcb",
    // borderRight: "2px solid #d7cbcb"
  },
  grid12Center: {
    paddingRight: "10%",
    "@media (min-width: 576px)": {
      paddingRight: "0",
    },
  },
  noteContent: {
    "@media (min-width: 768px)": {
      paddingRight: "75px",
    },
  },
  shadowSectionLight: {
    borderRadius: 8.5,
    boxShadow: "0px 10px 10px -4px rgba(0,0,0,0.05)",
  },
  
  /*
      ```````
      SEARCH

      ```````
  */
  search: {
    borderRadius: 12,
    backgroundColor: "#fff",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
    fontSize: 15,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: 300,
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& .MuiInput-underline:after": {
      display: "none"
    }
  },
  searchAutocomplete: {
    borderRadius: 12,
    backgroundColor: "#fff",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
    fontSize: 15,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: 300,
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& .MuiInput-underline:after": {
      display: "none"
    },
    "& fieldset": {
      border: "0"
    },
  },
  autocompleteTextField: {
    '& [class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      paddingTop: "0.75px",
      paddingBottom: "0.75px",
    },
    "& input": {
      fontFamily: "'Lato', sans-serif",
      fontSize: "15px"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
    fontFamily: "'Lato', sans-serif",
    fontSize: 15,
    width: 300,
  },
  inputInput: {
    fontSize: 15,
    width: 300,
    padding: "4px 8px",
    transition: theme.transitions.create("width"),
    color: "grey",
  },
  title: {
    fontFamily: "'Lato', sans-serif",
    color: "#282560",
  },
  titleSub: {
    fontFamily: "'Lato', sans-serif",
    color: "#333",
  },
  title333: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    color: "#333",
  },
  title555: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    color: "#555",
  },
  title888: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    color: "#888",
  },
  boxTable: {
    width: "100%",
    justifyContent: "inherit"
  },
  listCardItem: {
    paddingBottom: 0,
    paddingTop: 7,
  },
  shadowSection: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  boxTableTr: {
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  }
});
