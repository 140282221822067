import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import * as constants from '../../../../context/constants';
import ContextReports from "../../../../context/ContextReports";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { styles } from '../../../../../Global/Style';
import * as UrlHelper from '../../../../../../utilities/UrlHelper';

const chipColor = {
  "Not achieved": {
    color: 'white',
    backgroundColor: '#c72928'
  },
  "Not Achieved": {
    color: 'white',
    backgroundColor: '#c72928'
  },
  "Achieved": {
    color: 'white',
    backgroundColor: '#4caf51'
  },
  "Warning": {
    color: 'black',
    backgroundColor: '#ffef58'
  },
};

const maColumns = [ 'ma_average_overall_result_achievement' ];
const goalColumns = [ 'goal_average_overall_result_achievement' ];

const useStyle = makeStyles(styles);

const TableSection = ({ styling }) => {
  const rowsPerPageOptions = [10, 50, 100];
  const classes = useStyle();
  const columns = [
    {
      id: 'owner_name',
      label: 'Owner Name',
      minWidth: 170,
      render: (item) => (
        <div style={{textAlign: 'left'}}>
          {item.owner_name}
          <br />
          {item.user_email}
        </div>
      )
    },
    { id: 'structure_unit', label: 'Unit', minWidth: 100 },
    {
      id: 'goal_average_overall_result_achievement',
      label: 'Goal ORA',
      minWidth: 170,
      align: 'right',
      render: (item) => (
        <div style={{textAlign: 'right'}}>
          {
            item.goal_exists ? (
              <Chip
                label={item.goal_average_overall_result_achievement + '%'}
                style={chipColor[item.goal_achievement_category]}
              />
            ) : (
              <Chip label={'N/A'} style={{color: 'gray'}} />
            )
          }
        </div>
      )
    },
    {
      id: 'ma_average_overall_result_achievement',
      label: 'MA ORA',
      minWidth: 170,
      align: 'right',
      render: (item) => (
        <div style={{textAlign: 'right'}}>
          {
            item.ma_exists ? (
              <Chip
                label={item.ma_average_overall_result_achievement + '%'}
                style={chipColor[item.ma_achievement_category]}
              />
            ) : (
              <Chip label={'N/A'} style={{color: 'gray'}} />
            )
          }
        </div>
      )
    },
    {
      id: 'action',
      label: '',
      minWidth: 100,
      align: 'center',
      render: (item) => (
        <div style={{textAlign: 'center'}}>
          <Button className={classes.menuButton} variant="containted" onClick={() => handleOpenDetail(item)}>View Details</Button>
        </div>
      )
    }
  ];
  const {
    liveSummary,
    liveSummaryDispatch,
    liveSummaryFilter,
    liveSummaryFilterDispatch,
    liveSummaryDetailDispatch
  } = useContext(ContextReports);

  const {
    data,
    rawData
  } = liveSummary;

  useEffect(() => {
    handleStatusFilter();
    handleColumnLength();
  }, [liveSummary.rawData, liveSummaryFilter]);

  const handleStatusFilter = () => {
    let filteredData = rawData.filter((row) => {
      let includeStatus = liveSummaryFilter.goalAchStatus.includes(row.goal_achievement_category)
              || liveSummaryFilter.maAchStatus.includes(row.ma_achievement_category);

      let nodePaths = row.node_path.split('/');
      let searchFilterData = liveSummaryFilter.search;
      let searchFilter = true;

      let includePosition = false;
      if ('All' in liveSummaryFilter.units) {
        includePosition = true;
      } else {
        for (let i in nodePaths) {
          if (nodePaths[i] in liveSummaryFilter.units) {
            includePosition = true;
            break;
          }
        }
      }
      if (searchFilterData != '' && searchFilterData != undefined && searchFilterData != null) {
        searchFilterData = searchFilterData.toUpperCase();
        if (typeof row.structure_unit === 'string') {
          searchFilter = row.structure_unit.toUpperCase().includes(searchFilterData);
        }
        if (!searchFilter && typeof row.owner_name === 'string') {
          searchFilter = row.owner_name.toUpperCase().includes(searchFilterData);
        }
        if (!searchFilter && typeof row.user_email === 'string') {
          searchFilter = row.user_email.toUpperCase().includes(searchFilterData);
        }
      }

      return includeStatus && includePosition && searchFilter;
    }).sort((rowA, rowB) => {
      let fieldSort = liveSummaryFilter.order.field;
      let valueA = rowA[fieldSort];
      let valueB = rowB[fieldSort];

      if (!isNaN(parseFloat(valueA)) && isFinite(valueA)) {
        valueA = parseFloat(valueA);
      } else if (valueA) {
        valueA = valueA.toUpperCase();
      } else {
        valueA = 'ZZZZZZZZZZ';
      }

      if (!isNaN(parseFloat(valueB)) && isFinite(valueB)) {
        valueB = parseFloat(valueB);
      } else if (valueB) {
        valueB = valueB.toUpperCase();
      } else {
        valueB = 'ZZZZZZZZZZ';
      }

      if (valueA < valueB) {
        return liveSummaryFilter.order.direction == 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return liveSummaryFilter.order.direction == 'asc' ? 1 : -1;
      }

      return 0;
    });
    liveSummaryDispatch({ 
      dataType: constants.LIVE_SUM_DATA,
      payload: filteredData
    });
  }

  const handleChangePage = (event, newPage) => {
    liveSummaryFilterDispatch({
      filterType: constants.FILTER_PAGE,
      payload: newPage
    });
    UrlHelper.updateQueryParams('page', newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    UrlHelper.updateQueryParams('perPage', +event.target.value);
    UrlHelper.updateQueryParams('page', '0');

    liveSummaryFilterDispatch({
      filterType: constants.FILTER_PAGE,
      payload: 0
    });

    liveSummaryFilterDispatch({
      filterType: constants.FILTER_PER_PAGE,
      payload: +event.target.value
    });
  };

  const [columnLength, setColumnLength] = React.useState(columns.length);

  const handleColumnLength = () => {
    let length = columns.length;

    if (!liveSummaryFilter.viewColumns.includes('Goal')) {
      length -= goalColumns.length;
    }

    if (!liveSummaryFilter.viewColumns.includes('MA')) {
      length -= maColumns.length;
    }
    setColumnLength(length);
  }

  const renderColumn = (column) => {
    let render = true;
    let columnId = column.id;

    if (goalColumns.includes(columnId)) {
      render = liveSummaryFilter.viewColumns.includes('Goal') ? true : false;
    }

    if (maColumns.includes(columnId)) {
      render = liveSummaryFilter.viewColumns.includes('MA') ? true : false;
    }

    return render;
  }

  const handleOpenDetail = (item) => {
    UrlHelper.updateQueryParams('id', item.id);
    liveSummaryDetailDispatch({
      type: constants.LIVE_SUMMARY_DETAIL_ITEM,
      payload: item
    });
  }

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (renderColumn(column)) {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.length === 0 && (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell colSpan={columnLength} align={'center'}>No Data Available</TableCell>
                </TableRow>
              )
            }
            {data.slice(liveSummaryFilter.page * liveSummaryFilter.perPage, liveSummaryFilter.page * liveSummaryFilter.perPage + liveSummaryFilter.perPage).map((row, index) => {
              return (
                <>
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      if (renderColumn(column)) {
                        return (
                          <TableCell key={column.id}>
                            {column.render ? column.render(row) : row[column.id]}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={data.length}
        rowsPerPage={liveSummaryFilter.perPage}
        page={liveSummaryFilter.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableSection;
