import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, CircularProgress } from '@material-ui/core';
import numeral from 'numeral';

import ListItemYourGoal from './ListItemYourGoal';

import PictNewDashboard from '../../../../../../assets/images/new_dashboard.png';

const YourGoal = ({
  classes,
  structurePositionTitleId,
  period,
  period200,
  dashboardData
}) => {
  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const [myAchievement, setMyAchievement] = useState(null);

  useEffect(() => {
    if (
      dashboardData !== undefined &&
      dashboardData.myAchievement !== undefined
    ) {
      setMyAchievement(dashboardData.myAchievement);
    }
  }, [dashboardData]);

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        <Box display="flex" justifyContent="center" mb={2}>
          <img src={PictNewDashboard} width={104} height={52} alt="dashboard" />
        </Box>

        <Box mb={2} display="flex" justifyContent="center">
          <Typography className={classes.title333} variant="subtitle1">
            <b>
              Anda telah mencapai{' '}
              {myAchievement !== null
                ? numeral(myAchievement.goal_overall_result_achievement).format(
                    '0.00'
                  )
                : '0,00'}
              % dari target Anda !
            </b>
          </Typography>
        </Box>

        {myAchievement == null && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              size={14}
              style={{ color: '#d64253', marginRight: 5 }}
            />
            <Typography className={classes.title888} variant="caption">
              Loading...
            </Typography>
          </Box>
        )}

        {myAchievement !== null && (
          <ListItemYourGoal
            classes={classes}
            item={{
              value: myAchievement.goal_overall_result_achievement,
              achievement_category: myAchievement.goal_achievement_category
            }}
            origin="goal"
          />
        )}

        {myAchievement !== null && (
          <ListItemYourGoal
            classes={classes}
            item={{
              value: myAchievement.ma_overall_result_achievement,
              achievement_category: myAchievement.ma_achievement_category
            }}
            origin="ma"
          />
        )}
      </Box>
    </Paper>
  );
};

export default YourGoal;
