/*
     ------------------------------------------------------------------------------
    |                                                                                |       
    |   
    |                                                                                |
    |   * Ini adalah Page Second namanya-nya untuk sementara                                  
    |                                                                                |
     -------------------------------------------------------------------------------
*/

import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import axios from "axios";

import DialogError from "../../../components/DialogError";

import Redirect from "../../../utilities/Redirect";
import { URL_API } from "../../../constants/config-api";

import {
  ToCompletionCompanyStructureFormulirKosong,
  ToNewSO,
  ToSOMaster,
  ToDashboard,
} from "../../../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    // padding: theme.spacing(5, 2),
    // marginTop: theme.spacing(4),
    // width: 575,
    borderRadius: 2,
  },
  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },
  breadcrumb: {
    marginLeft: theme.spacing(5),
  },
  title: {
    fontFamily: "Roboto",
  },
  titleLater: {
    fontFamily: "Roboto",
    cursor: "pointer",
    marginLeft: theme.spacing(5),
    color: "grey",
  },
});

const ViewCompletionCompanyStructure = (props) => {
  const { classes } = props;

  const [listPreviewSo, setListPreviewSo] = useState([
    {
      masterStructurePositionTitle: {
        name: null,
      },
    },
  ]);

  const [loader, setLoader] = useState(false);

  const [isOpenDialogError, setOpenDialogError] = useState(false);

  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  useEffect(() => {
    const dataPreviewSo = localStorage.getItem("data_preview_so");
    const dataPreviewSoAfterParse = JSON.parse(dataPreviewSo);

    setListPreviewSo(dataPreviewSoAfterParse);
  }, []);

  const handleBantuSaya = () => {
    localStorage.setItem("question_bantu_generate", "bantu");
    // localStorage.setItem('data_preview_so', JSON.stringify(response.data.data))
    Redirect(ToNewSO);

    //*
    // setLoader(true)
    // const userToken = localStorage.getItem('userToken');

    // const structurePositionTitleName = localStorage.getItem('data_preview_so');
    // const structurePositionTitleNameAfterParse = JSON.parse(structurePositionTitleName);

    // //*
    // if(userToken !== undefined){

    //     const header =  {
    //         'Accept': "application/json",
    //         'Content-Type' : "application/json",
    //         'Authorization' : "bearer " + userToken,
    //     };

    //     axios.defaults.headers.common = header;

    //     axios
    //         .get(`${URL_API}/human-resource/structure-wizard/create?StructurePosition[top_leader_name]=${structurePositionTitleNameAfterParse.structure_position_title_name}`)
    //         .then((response) => {

    //             setLoader(false)
    //             // console.log("Response Original : ", response)
    //             // orgChartParsed.push(response.data.data)
    //             // setListPreview(response.data.data)

    //             localStorage.setItem('question_bantu_generate', 'bantu');
    //             localStorage.setItem('data_preview_so', JSON.stringify(response.data.data))
    //             Redirect(ToNewSO);

    //         })
    //         .catch((error) => {

    //             setLoader(false);
    //             // console.log("Error : ", error.response);

    //         })

    // }
  };

  const handleBikinSendiri = () => {
    // setLoader(true);
    // const userToken = localStorage.getItem('userToken');

    localStorage.setItem("question_bantu_generate", "sendiri");

    Redirect(ToNewSO);

    // const data = {

    //     StructurePosition : {

    //         top_leader_name: listPreviewSo[0].masterStructurePositionTitle.name
    //     }
    // };

    // if(userToken !== undefined){

    //     const header =  {
    //         'Accept': "application/json",
    //         'Content-Type' : "application/json",
    //         'Authorization' : "bearer " + userToken,
    //     };

    //     axios.defaults.headers.common = header;

    //     axios
    //         .post(URL_API + `/human-resource/structure-position/root`, data)
    //         .then((response) => {

    //             // console.log("Response Original : ", response)
    //             setLoader(false);
    //             Redirect(ToNewSO);

    //         })
    //         .catch((error) => {

    //             setLoader(false);
    //             // console.log("Error : ", error.response);

    //             if(error.response.status == 422){

    //                 setErrorStatus(422)
    //                 if(error.response.data.info.errors !== null){

    //                     if(error.response.data.info.errors.length > 0){

    //                         setListError(error.response.data.info.errors)
    //                         setTextErrorInformation(".");
    //                         setOpenDialogError(true);

    //                         // if(error.response.data.info.errors[0].code == "POSITION_EXISTS"){

    //                         //     setTextErrorInformation(error.response.data.info.errors[0].description);
    //                         //     setOpenDialogError(true);
    //                         // };
    //                     };
    //                 };

    //             } else {

    //                 setTextErrorInformation('Whoops something went wrong !');
    //                 setOpenDialogError(true);
    //             }

    //         })

    // } else {

    //     alert('Whoops, something went wrong !');
    // }
  };
  //

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Paper elevation={0}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={4} style={{ textAlign: "center" }}></Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Breadcrumbs
              className={classes.breadcrumb}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="Breadcrumb"
            >
              <Typography color="inherit" className={classes.title}>
                Keanggotaan
              </Typography>
              <Typography color="inherit" className={classes.title}>
                Profil
              </Typography>
              <Typography color="primary" className={classes.title}>
                <b>Struktur Perusahaan</b>
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}></Grid>
        </Grid>
      </Paper>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Paper className={classes.root} elevation={0}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h5" className={classes.title}>
              <b>
                Apakah anda ingin kami bantu untuk mengisi data{" "}
                <i>Struktur organisasi</i>?
              </b>
            </Typography>
            <br />

            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}
            >
              Kami akan memberikan petunjuk pengisian & contohnya
            </Typography>
            <br />
            <br />
            <Button
              // onClick={() => Redirect(ToNewSO)}
              onClick={handleBantuSaya}
              variant="contained"
              size="medium"
              className={classes.button}
            >
              {loader !== true ? (
                "Ya, bantu saya"
              ) : (
                <CircularProgress size={16} style={{ color: "white" }} />
              )}
            </Button>

            <br />
            <br />
            <br />
            <span
              className={classes.titleLater}
              // onClick={() => Redirect(ToSOMaster)}
              onClick={handleBikinSendiri}
            >
              <u>
                Tidak, saya akan buat <i>Struktur organisasi</i> sendiri
              </u>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </Grid>
        </Grid>
      </Paper>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewCompletionCompanyStructure);
