import React, { useState, useEffect } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Box,
  Typography,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import classNames from "classnames";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import DialogMoveTaskMultiple from "../dialogs/DialogMoveTaskMultiple";

const AnchorMoveGroup = ({ classes }) => {
  const project_id = window.location.pathname.split("/")[2];

  const [openMoveMultiple, setOpenMoveMultiple] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [listGroup, setListGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (anchorEl !== null) {
      AxiosConfig.get(`${URL_API}/project/${project_id}/task-group`)
        .then((res) => {
          const result = res.data.data;
          if (res.status === 200) {
            setListGroup(result);
          }
        })
        .catch((error) => {
          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [anchorEl]);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (e) => setSelectedGroup(e.target.value);

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={handleShow}
      >
        Move To
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <Box p={2} width={300}>
                <Typography variant="subtitle2" className={classes.title555}>
                  <b>Group Selected Task</b>
                </Typography>
                <Divider />

                <Box mt={3}>
                  <Select
                    variant="outlined"
                    fullWidth
                    placeholder="Select priority"
                    value={selectedGroup}
                    style={{ height: 40 }}
                    onChange={handleSelect}
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{
                      classes: {
                        icon: classes.iconSelect,
                      },
                    }}
                  >
                    {listGroup.length > 0 ? (
                      listGroup.map((group, i) => {
                        return (
                          <MenuItem key={i} value={group.id}>
                            <Typography
                              variant="subtitle2"
                              className={classes.title555}
                            >
                              {group.name}
                            </Typography>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Group is empty</MenuItem>
                    )}
                  </Select>
                </Box>

                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    className={classes.button}
                    style={{ fontSize: 12 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Box mx={0.5} />
                  <Button
                    size="small"
                    className={classes.button1}
                    variant="outlined"
                    disabled={selectedGroup === "" ? true : false}
                    style={{ fontSize: 12 }}
                    onClick={() => {
                      handleClose();
                      setOpenMoveMultiple(true);
                    }}
                  >
                    Move
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogMoveTaskMultiple
        classes={classes}
        openMoveMultiple={openMoveMultiple}
        setOpenMoveMultiple={setOpenMoveMultiple}
        selectedGroup={selectedGroup}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default AnchorMoveGroup;
