import { Box, Card, CardContent, Grid, LinearProgress, makeStyles, Typography, withStyles } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { URL_API } from '../../../../constants/config-api';
import AxiosConfig from '../../../../constants/config-axios';
import { styles } from './Style/StylePresence';

const useStyles = makeStyles(styles);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && <Box style={{ padding: '0px 25px' }}>{children}</Box>}
		</div>
	);
}

function ViewPresence() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});

	const BorderLinearProgress = withStyles(theme => ({
		root: {
			height: 10,
			borderRadius: 5,
		},
		colorPrimary: {
			backgroundColor: '#9B1B2D',
		},
		bar: {
			borderRadius: 5,
			backgroundColor: '#EC5267',
		},
	}))(LinearProgress);

	const fetchPresence = () => {
		setLoading(true);
		AxiosConfig.get(`${URL_API}/human-resource/report-account/presence`)
			.then(res => {
				const result = res.data.data;
				console.log('RES Presence ', result);
				if (res.status === 200) {
					setData(result);
				}
			})
			.catch(error => console.log('Error', error))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		fetchPresence();
	}, []);

	return (
		<div className={classes.root}>
			<Typography variant="h4" align="center" style={{ color: '#221E5B', marginBottom: 20 }}>
				<b>Presence</b>
			</Typography>
			<Card className={classes.topCard}>
				<CardContent style={{ flex: '1 0 auto' }}>
					<div
						style={{
							color: 'white',
							display: 'flex',
							gap: '10px',
							marginBottom: '10px',
							fontWeight: 'bold',
						}}
					>
						<DateRange />
						<Typography variant="h6">
							<b>Kehadiran anda bulan ini</b>
						</Typography>
					</div>
					<Grid container spacing={1}>
						<Grid item xs={4}>
							<img src={require('../../../../assets/images/note.png')} alt="attendance" width={120} />
						</Grid>
						<Grid item xs={8}>
							<Grid container spacing={1} style={{ marginTop: 20, marginBottom: 5 }}>
								<Grid item xs={9}>
									<Typography variant="h6" style={{ color: 'white' }}>
										{data.kehadiran ? (
											<>
												<b>{data.kehadiran}</b>/{moment().daysInMonth()} Kehadiran
											</>
										) : (
											<>-</>
										)}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography variant="h6" style={{ color: 'white', textAlign: 'right' }}>
										<b>
											{data.kehadiran ? <b>{Math.floor((data.kehadiran / moment().daysInMonth()) * 100)}%</b> : <>-</>}
										</b>
									</Typography>
								</Grid>
							</Grid>
							<BorderLinearProgress
								variant="determinate"
								value={data.kehadiran ? Math.floor((data.kehadiran / moment().daysInMonth()) * 100) : 0}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<div>
				<Typography variant="h5" style={{ color: '#221E5B', margin: '20px 0px' }}>
					<b>Presence Detail</b>
				</Typography>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
					{data.list?.map((item, index) => (
						<Card key={index} style={{ padding: '10px', color: '#221E5B', borderRadius: '10px' }}>
							<CardContent>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										flexWrap: 'wrap',
										gap: '10px',
										marginBottom: '10px',
										fontWeight: 'bold',
									}}
								>
									<DateRange />
									<Typography variant="h6">
										<b>{item.tanggal}</b>
									</Typography>
								</div>
								<Typography variant="subtitle1" style={{ color: '#656D78' }}>
									Pukul : {item.pukul}
								</Typography>
								<Typography variant="subtitle2">
									<b>Lokasi : {item.lokasi}</b>
								</Typography>
								<Typography variant="subtitle2">
									<b>Status : Hadir</b>
								</Typography>
							</CardContent>
						</Card>
					))}
				</div>
			</div>
		</div>
	);
}

export default ViewPresence;
