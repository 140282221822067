import React, { useEffect, useState } from "react";
import { Box, List, Typography, Button } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import Pagination from "@material-ui/lab/Pagination";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ListItemPosition from "./ListItemPosition";
import DialogCreatePosition from "./DialogCreatePosition";

const PositionSettings = ({ classes }) => {
  const [structurePosition, setStructurePosition] = useState([]);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const [openCreatePosition, setOpenCreatePosition] = useState(false);
  const [triggerPosition, setTriggerPosition] = useState(false);

  useEffect(() => {
    AxiosConfig.get(
      `${URL_API}/human-resource/master-structure-position-title?options[paging][offset]=${offset}&options[paging][limit]=${perPage}`
    )
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setStructurePosition(result);
          setCount(res.data?.info?.count);
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
      });
  }, [page, triggerPosition]);

  const handlePagination = (e, value) => {
    setPage(value);
    const offsetPage = (value - 1) * perPage;

    setOffset(offsetPage);
  };

  return (
    <Box>
      <Box mb={1} px={1}>
        <List dense>
          {structurePosition.length > 0 &&
            structurePosition.map((item, i) => {
              return (
                <ListItemPosition
                  key={i}
                  item={item}
                  classes={classes}
                  onTriggerPosition={setTriggerPosition}
                />
              );
            })}
        </List>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          size="small"
          variant="outlined"
          className={classes.button2}
          startIcon={<AddRoundedIcon />}
          onClick={() => setOpenCreatePosition(true)}
        >
          Tambah Jabatan
        </Button>
      </Box>

      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className={classes.title888} variant="caption">
          Records{" "}
          <span
            style={{
              backgroundColor: "#aaa",
              color: "#fff",
              padding: "0 5px",
              borderRadius: 4,
            }}
          >
            <b>{count}</b>
          </span>
        </Typography>

        <Pagination
          size="small"
          variant="text"
          shape="rounded"
          hideNextButton
          hidePrevButton
          page={page}
          count={Math.ceil(count / perPage)}
          onChange={handlePagination}
        />
      </Box>

      <DialogCreatePosition
        classes={classes}
        openCreatePosition={openCreatePosition}
        setOpenCreatePosition={setOpenCreatePosition}
        onTriggerPosition={setTriggerPosition}
      />
    </Box>
  );
};

export default PositionSettings;
