import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";

const ListCardHeaderItem = ({ classes, origin, entity }) => {
  return (
    <Paper className={classes.shadowSectionLight}>
      <Box
        p={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box width={50}></Box>
          <Box width={70} ml={6}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>Achievement</b>
            </Typography>
          </Box>
          <Box ml={5} minWidth={350}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>{origin}</b>
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <Box minWidth={250} maxWidth={350}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>User</b>
            </Typography>
          </Box>

          <Box width={175} ml={4}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>Result Value</b>
            </Typography>
          </Box>

          <Box width={150} ml={4}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>Last Update</b>
            </Typography>
          </Box>

          {entity !== "1" && <Box width={20} mx={4} />}
        </Box>
      </Box>
    </Paper>
  );
};

export default ListCardHeaderItem;
