import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Checkbox,
  Divider,
  Link,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import StarsIcon from "@material-ui/icons/Stars";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import PersonIcon from "@material-ui/icons/Person";
import InfoIcon from "@material-ui/icons/Info";

import clsx from "clsx";
import useSWR from "swr";
import { navigate } from "hookrouter";

import {
  ACCOUNT_TYPE_BISNIS,
  ACCOUNT_TYPE_PERSONAL,
  USER_LICENSE,
  DEPLOYMENT,
  PMO_VISIT,
  PACKAGE_PERSONAL,
} from "../../../MembershipManagement/constant-data";

import { URL_API } from "../../../../../constants/config-api";
import Axios from "../../../../../constants/config-axios";

import Card3Items from "./Card3Items";
import DialogChoosePackage from "./DialogChoosePackage";
import DialogCheckoutUpdate from "./DialogCheckoutUpdate";
import DialogError from "../../../../../components/DialogError";
import { FormatThousand } from "../../../../../utilities/FormatDecimal";

const DialogUpdate = ({
  classes,
  openDialogUpdate,
  setOpenDialogUpdate,
  subscription,
  licenseUser,
  pmoTicket,
  deployTicket,
  statusMember,
}) => {
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const groupName = user.membership_account_type_name;
  const pckgName = user.membership_package_name;
  const levelName = user.membership_level_name;
  const accTypeId = user.membership_account_type_id;
  const pckgId = user.membership_package_id;
  let level_color;
  let userL;
  let pmo;
  let deploy;

  const [loading, setLoading] = useState(false);
  // const [loadPrice, setLoadPrice] = useState(false);
  const [extend, setExtend] = useState(true);
  const [extendVal, setExtendVal] = useState(1);
  const [subscribeDetail, setSubscribeDetail] = useState({
    start_date: "",
    finish_date: "",
    duration: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    used: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    left: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
  });
  const [codeVoucher, setCodeVoucher] = useState("");
  const [price, setPrice] = useState("");
  const [checkoutFee, setCheckoutFee] = useState("");

  const [deploymentPrice, setDeploymentPrice] = useState("");
  const [pmoPrice, setPmoPrice] = useState("");
  const [userPrice, setUserPrice] = useState({
    updateFinalPrice: 0,
    additionalFinalPrice: 0,
    lapsedTotalPrice: 0,
    additionalDescription: "-",
    lapsedDescription: "-",
    submittedQty: 0,
    updateQty: 0,
  });

  const [openDialogChoosePackage, setOpenDialogChoosePackage] = useState(false);
  const [openDialogCheckoutUpdate, setOpenDialogCheckoutUpdate] =
    useState(false);

  const [userLicense, setUserLicense] = useState(0);
  const [pmoVisit, setPmoVisit] = useState(0);
  const [deployment, setDeployment] = useState(0);
  const [additionalUser, setAdditionalUser] = useState(0);
  const [additionalPmo, setAdditionalPmo] = useState(0);
  const [differenceQtyPmo, setDifferenceQtyPmo] = useState(0);
  const [differenceQtyUser, setDifferenceQtyUser] = useState(0);
  // const [additionalDeploy, setAdditionalDeploy] = useState(0);

  const [accountTypeId, setAccountTypeId] = useState("");
  const [packageId, setPackageId] = useState("");
  const [voucherErrorsList, setVoucherErrorsList] = useState([]);
  const [statusRes, setStatusRes] = useState(null);
  const [chooseOrNot, setChooseOrNot] = useState(false);
  const [samePackage, setSamePackage] = useState(false);

  const [packageCollections, setPackageCollections] = useState([]);
  const [accountTypeCollections, setAccountTypeCollections] = useState([]);
  const [itemCollections, setItemCollections] = useState([]);
  const [paymentMethodCollections, setPaymentMethodCollections] = useState([]);

  const [packageCollections2, setPackageCollections2] = useState([]);
  const [accountTypeCollections2, setAccountTypeCollections2] = useState([]);
  const [itemCollections2, setItemCollections2] = useState([]);
  const [paymentMethodCollections2, setPaymentMethodCollections2] = useState(
    []
  );

  const [selectedPackage, setSelectedPackage] = useState({});

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ====================================================
  // Side Effects
  // ====================================================
  if (chooseOrNot) {
    userL = userLicense;
    pmo = pmoVisit;
    deploy = deployment;
  } else if (!chooseOrNot) {
    userL = 0;
    pmo = 0;
    deploy = 0;
  }

  useEffect(() => {
    // if (openDialogUpdate && statusMember !== "Lapsed") {
    if (openDialogUpdate) {
      setSubscribeDetail(subscription);
      // setExtendVal(subscription.duration);
      // setAccountTypeId(accTypeId);
      // setPackageId(pckgId);
      // setUserLicense(licenseUser);
      // setPmoVisit(pmoTicket);
      // setDeployment(deployTicket);

      Axios.get(`${URL_API}/account-management/membership/update`)
        .then((res) => {
          // // console.log("Update Subs", res);
          const result = res.data.data;

          if (res.status === 200) {
            setAccountTypeCollections(result.membershipAccountTypeCollections);
            setPackageCollections(result.membershipPackageCollections);
            setItemCollections(result.membershipItemCollections);
            setPaymentMethodCollections(
              result.membershipPaymentMethodCollections
            );
          }
        })
        .catch((error) => {
          // setLoading(false);
          // console.log("Error : ", error.response);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  // // console.log("Err InFO", error.response.data.info.errors[0]);
                  setListError(error.response.data.info.errors[0]);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.info.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` +
                    error.response.data.info.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  }, [openDialogUpdate]);

  useEffect(() => {
    if (openDialogUpdate && extend) {
      setSubscribeDetail(subscription);
      // setExtendVal(subscription.duration);
      // setAccountTypeId(accTypeId);
      // setPackageId(pckgId);
      // setUserLicense(licenseUser);
      // setPmoVisit(pmoTicket);
      // setDeployment(deployTicket);

      Axios.get(`${URL_API}/account-management/membership:update-and-extend`)
        .then((res) => {
          // // console.log("Update Extd", res);
          const result = res.data.data;

          if (res.status === 200) {
            setAccountTypeCollections2(result.membershipAccountTypeCollections);
            setPackageCollections2(result.membershipPackageCollections);
            setItemCollections2(result.membershipItemCollections);
            setPaymentMethodCollections2(
              result.membershipPaymentMethodCollections
            );
          }
        })
        .catch((error) => {
          // setLoading(false);
          // console.log("Error : ", error);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  }, [openDialogUpdate, extend]);

  const root_url = `account-management/membership-payment:calculate`;
  const act_type = `MembershipPayment[membership_account_type_id]=${accountTypeId}`;
  const pckg_id = `MembershipPayment[membership_package_id]=${packageId}`;
  const dur_val = `MembershipPayment[membership_duration_value]=${
    statusMember === "Lapsed" ? extendVal : 0
  }`;
  const user_license_id = `MembershipPayment[membership_item][0][id]=${USER_LICENSE}`;
  const user_license_count = `MembershipPayment[membership_item][0][quantity]=${userL}`;
  const deploy_id = `MembershipPayment[membership_item][1][id]=${DEPLOYMENT}`;
  const deploy_count = `MembershipPayment[membership_item][1][quantity]=${
    pckgId === packageId ? deployTicket : deploy
    // pckgId === packageId ? deployTicket : deployTicket
  }`;
  const pmo_id = `MembershipPayment[membership_item][2][id]=${PMO_VISIT}`;
  const pmo_count = `MembershipPayment[membership_item][2][quantity]=${pmo}`;
  const voucher = `MembershipPayment[membership_voucher_code]=${codeVoucher}`;
  const update = "recalculate=update";

  let FINAL_URL;
  const C_URL1 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${voucher}&${update}`;
  const C_URL2 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${deploy_id}&${deploy_count}&${voucher}&${update}`;
  const C_URL3 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${pmo_id}&${pmo_count}&${voucher}&${update}`;
  const C_URL4 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${deploy_id}&${deploy_count}&${user_license_id}&${user_license_count}&${pmo_id}&${pmo_count}&${voucher}&${update}`;

  if (accountTypeId === ACCOUNT_TYPE_BISNIS) {
    FINAL_URL = C_URL4;
  } else if (
    accountTypeId === ACCOUNT_TYPE_PERSONAL &&
    userL > 0 &&
    deploy === 0 &&
    pmo === 0
  ) {
    FINAL_URL = C_URL1;
  } else if (
    accountTypeId === ACCOUNT_TYPE_PERSONAL &&
    userL > 0 &&
    deploy > 0 &&
    pmo === 0
  ) {
    FINAL_URL = C_URL2;
  } else if (
    accountTypeId === ACCOUNT_TYPE_PERSONAL &&
    userL > 0 &&
    deploy === 0 &&
    pmo > 0
  ) {
    FINAL_URL = C_URL3;
  } else if (
    accountTypeId === ACCOUNT_TYPE_PERSONAL &&
    userL > 0 &&
    deploy > 0 &&
    pmo > 0
  ) {
    FINAL_URL = C_URL4;
  }

  const fetcher = (url) =>
    Axios.get(url).then((r) => {
      setStatusRes(r.status);

      return r.data.data;
    });

  const {} = useSWR(FINAL_URL, fetcher, {
    onSuccess: (result) => {
      // console.log("Res -Calc", result);
      setPrice(result.total_item_price_after_discount);
      setCheckoutFee(result);
      // setStatusRes(res.status);

      result.pricePerItem.forEach((item) => {
        if (item.id === DEPLOYMENT) {
          // setDeploymentPrice(item.total_price_after_duration);
          setDeploymentPrice(item.total_price_after_duration);
          // setAdditionalDeploy(item.recalculate.additional_qty);
        } else if (item.id === PMO_VISIT) {
          // setPmoPrice(item.total_price_after_duration);
          setPmoPrice(item.total_price_after_duration);
          setAdditionalPmo(item.recalculate.additional_qty);
          setDifferenceQtyPmo(item.recalculate.difference_qty);
        } else if (item.id === USER_LICENSE) {
          // setUserPrice(item.total_price_after_duration);
          setDifferenceQtyUser(item.recalculate.difference_qty);
          setAdditionalUser(item.recalculate.additional_qty);
          setUserPrice({
            updateFinalPrice: item.recalculate.update_final_price,
            additionalFinalPrice: item.recalculate.additional_final_price,
            lapsedTotalPrice: item.recalculate.lapsed_total_price,
            additionalDescription: item.recalculate.additional_description,
            lapsedDescription: item.recalculate.lapsed_description,
            submittedQty: item.recalculate.submitted_qty,
            updateQty: item.recalculate.update_qty,
          });
        }
      });

      if (result.pricePerItem.some((dep) => dep.id === PMO_VISIT)) {
      } else {
        setPmoPrice("0");
        setDifferenceQtyPmo(-pmoTicket);
      }
      if (result.pricePerItem.some((dep) => dep.id === DEPLOYMENT)) {
      } else {
        setDeploymentPrice("0");
      }
      if (result.pricePerItem.some((dep) => dep.id === USER_LICENSE)) {
      } else {
        setUserPrice({
          updateFinalPrice: 0,
          additionalFinalPrice: 0,
          lapsedTotalPrice: 0,
          additionalDescription: "",
          lapsedDescription: "",
        });
      }
    },
    onError: (error) => {
      // console.log("Calc Err~", error.response);

      if (error.response !== undefined) {
        if (error.response.status === 400) {
          setStatusRes(error.response.status);
          setVoucherErrorsList(error.response.data.info.errors);
        }

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      }
    },
  });

  useEffect(() => {
    if (
      pckgId === packageId &&
      licenseUser === Number(userL) &&
      pmoTicket === pmoVisit &&
      deployTicket === deployment
    ) {
      setSamePackage(true);
      // // console.log("samePckg", true);
    } else {
      setSamePackage(false);
      // // console.log("NotsamePckg", false);
    }
  }, [packageId, userLicense, pmoVisit, deployment, userL]);

  if (levelName === "Basic") {
    level_color = "#cd6133";
  } else if (levelName === "Silver") {
    level_color = "#a5b1c2";
  } else if (levelName === "Gold") {
    level_color = "#f7b731";
  } else if (levelName === "Platinum") {
    level_color = "#84817a";
  }

  // ====================================================
  // METHODS / FUNC
  // ====================================================

  const handleChangePackage = (data) => {
    // // console.log("USR DT", licenseUser);

    if (data !== null) {
      // // console.log("PCKG DT", data);

      setSelectedPackage(data);

      setAccountTypeId(data.accountType.id);
      setPackageId(data.id);

      if (data.id === PACKAGE_PERSONAL) {
        setAdditionalPmo(0);
      }

      // If User Choose same package -> fill with current value
      if (data.id === pckgId) {
        setUserLicense(licenseUser);
        setPmoVisit(pmoTicket);
        setDeployment(deployTicket);
        // If Not ? Fill with minimum Qty
      } else {
        data.membershipPackageItem.forEach((item) => {
          const itmId = item.membership_item_id;
          const itmMinQty = item.membership_item_minimum_quantity;

          if (itmId === USER_LICENSE) {
            setUserLicense(itmMinQty);
          }
          if (itmId === PMO_VISIT) {
            setPmoVisit(itmMinQty);
          }
          if (itmId === DEPLOYMENT) {
            setDeployment(itmMinQty);
          }
        });
      }
    } else {
      setAccountTypeId(accTypeId);
      setPackageId(pckgId);
      setSelectedPackage({});
      setAdditionalUser(0);
      setAdditionalPmo(0);
      setDifferenceQtyPmo(0);
      setPrice("");
      setDeploymentPrice("");
      setPmoPrice("");
      setUserPrice({
        updateFinalPrice: 0,
        additionalFinalPrice: 0,
        lapsedTotalPrice: 0,
        additionalDescription: "",
        lapsedDescription: "",
      });
      setCheckoutFee("");
    }
  };
  // // console.log("Ben Ben", benefits);

  // const handleCheckExtend = (e) => {
  //   setExtend(e.target.checked);

  //   if (e.target.checked === true) {
  //     setExtendVal(1);
  //   } else {
  //     setExtendVal(0);
  //   }
  // };

  // // console.log("hadeuhsame", samePackage);

  return (
    <Dialog
      open={openDialogUpdate}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" className={classes.title333}>
            <b>Update Membership Form</b>
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpenDialogUpdate(false)}
          >
            <HighlightOffIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 1000, marginBottom: 30 }}>
        <Grid container>
          <Grid item md={8}>
            <Box width={600}>
              <Box marginBottom={3} className={classes.boxDesc}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle1"
                    className={classes.title333}
                    style={{ marginRight: 10 }}
                  >
                    <b>{groupName} Account</b>
                  </Typography>
                  <>
                    <StarRoundedIcon
                      fontSize="small"
                      style={{ color: level_color, marginRight: 3 }}
                    />
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: level_color }}
                    >
                      <b>{levelName}</b>
                    </Typography>
                  </>
                </Box>

                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "#555", marginTop: 5 }}
                >
                  Paket membership terakhir anda adalah{" "}
                  <span className={classes.badgePaket}>{pckgName}</span> dengan
                  siklus durasi{" "}
                  <span>
                    {subscribeDetail.duration.months > 0 &&
                      `${subscribeDetail.duration.months} bulan`}{" "}
                    {subscribeDetail.duration.days > 0 &&
                      `${subscribeDetail.duration.days} hari`}
                  </span>
                  , {licenseUser} lisensi user{" "}
                  {pmoTicket != 0 && (
                    <span>dan {pmoTicket} consultant visit</span>
                  )}
                </Typography>
              </Box>

              <Typography variant="h6" className={classes.title333}>
                <b>To</b>
              </Typography>

              <Box marginTop={1} marginBottom={1}>
                <Card3Items
                  classes={classes}
                  account={
                    selectedPackage.accountType === undefined
                      ? groupName
                      : selectedPackage.accountType.name
                  }
                  pckgName={
                    selectedPackage.name === undefined
                      ? pckgName
                      : selectedPackage.name
                  }
                  prop1="User"
                  prop2="Consultant Visit"
                  prop3="Paket Membership"
                  val1={userL}
                  val2={pmo}
                  val3={
                    selectedPackage.name === undefined
                      ? pckgName
                      : selectedPackage.name
                  }
                  icon1={
                    <PersonIcon fontSize="small" style={{ color: "#aaa" }} />
                  }
                  icon2={
                    <ConfirmationNumberIcon
                      fontSize="small"
                      style={{ color: "#aaa" }}
                    />
                  }
                  icon3={
                    <StarsIcon fontSize="small" style={{ color: "#aaa" }} />
                  }
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                <div>
                  {statusMember === "Lapsed" && (
                    <>
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                          inputProps={{ "aria-label": "decorative checkbox" }}
                          checked={extend}
                          disabled
                          // onChange={(e) => handleCheckExtend(e)}
                          // disabled={chooseOrNot}
                        />
                        <Typography
                          variant="subtitle2"
                          className={classes.title333}
                        >
                          <b>Extend duration</b>
                        </Typography>
                      </Box>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#555", marginLeft: 35 }}
                      >
                        (Diperpanjang 1 bulan ketika status <i>Overdue</i>)
                      </Typography>

                      <br />
                      <br />
                    </>
                  )}
                </div>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.buttonOutlined}
                  // disabled={
                  //   packageCollections.length === 0 ||
                  //   packageCollections2.length === 0
                  // }
                  // disabled={extend}
                  onClick={() => setOpenDialogChoosePackage(true)}
                >
                  Update Paket
                </Button>
              </Box>

              {statusMember === "Lapsed" &&
                pmoTicket !== pmoVisit &&
                chooseOrNot && (
                  <Box display="flex">
                    <InfoIcon
                      style={{ fontSize: 15, color: "#888", marginRight: 5 }}
                    />
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                      style={{ textAlign: "justify" }}
                    >
                      Anda melakukan perubahan jumlah sesi consultant visit di
                      masa overdue. Anda akan di charge sesi terbesar dengan
                      asumsi semua sesi sudah/akan digunakan di periode ini.
                      Harap hubungi{" "}
                      <Link
                        onClick={() => navigate("/contact")}
                        className={classes.linkCancel}
                      >
                        CS/Konsultan
                      </Link>{" "}
                      kami jika terdapat data yang tidak sesuai.
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>
          <Grid item md={4}>
            <Paper variant="outlined">
              <Box padding={2}>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ marginBottom: 5 }}
                >
                  <b>Perubahan paket</b>
                </Typography>
                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item md={7}>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Perubahan lisensi
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Total lisensi
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Perubahan consultant visit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Total consultant visit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Perubahan durasi
                    </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{
                        color:
                          differenceQtyUser === 0
                            ? "#555"
                            : differenceQtyUser < 0
                            ? "#EA2027"
                            : "#009432",
                      }}
                    >
                      :{" "}
                      {differenceQtyUser > 0
                        ? `+${differenceQtyUser}`
                        : differenceQtyUser}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : {userPrice.submittedQty}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                      style={{
                        color:
                          differenceQtyPmo === 0
                            ? "#555"
                            : differenceQtyPmo < 0
                            ? "#EA2027"
                            : "#009432",
                      }}
                    >
                      :{" "}
                      {differenceQtyPmo > 0
                        ? `+${differenceQtyPmo}`
                        : differenceQtyPmo}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      {/* : {pmo} */}: {additionalPmo}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : {statusMember === "Lapsed" ? extendVal : 0}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ marginBottom: 5 }}
                >
                  <b>Biaya Update</b>
                </Typography>
                <Grid container>
                  <Grid item md={7}>
                    {statusMember === "Lapsed" ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          Lisensi{" "}
                          <span style={{ fontSize: 10 }}>
                            ({userPrice.lapsedDescription})
                          </span>
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          Lisensi{" "}
                          <span style={{ fontSize: 10 }}>
                            ({userPrice.additionalDescription})
                          </span>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          Lisensi user (Penyesuaian)
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          Lisensi user (Tambahan)
                        </Typography>
                      </>
                    )}
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Consultant visit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Deployment
                    </Typography>
                  </Grid>
                  <Grid item md={5}>
                    {statusMember === "Lapsed" ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          : Rp {FormatThousand(userPrice.lapsedTotalPrice)}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          : Rp {FormatThousand(userPrice.additionalFinalPrice)}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          : Rp {FormatThousand(userPrice.updateFinalPrice)}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.txtSummary}
                        >
                          : Rp {FormatThousand(userPrice.additionalFinalPrice)}
                        </Typography>
                      </>
                    )}
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : Rp {new Intl.NumberFormat("ID-id").format(pmoPrice)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : Rp{" "}
                      {new Intl.NumberFormat("ID-id").format(deploymentPrice)}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider orientation="horizontal" />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  marginTop={2}
                  marginBottom={2}
                >
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>Biaya Update</b>
                  </Typography>
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>
                      Rp{" "}
                      {new Intl.NumberFormat("ID-id").format(
                        isNaN(checkoutFee.total_item_price_after_discount)
                          ? 0
                          : checkoutFee.total_item_price_after_discount
                      )}
                    </b>
                  </Typography>
                </Box>

                <Button
                  variant="contained"
                  fullWidth
                  className={classes.button2}
                  disabled={price === "" || samePackage}
                  onClick={() => setOpenDialogCheckoutUpdate(true)}
                >
                  {statusMember === "Active" && "Update"}
                  {statusMember === "Lapsed" && "Update & Extend"}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogChoosePackage
        classes={classes}
        openDialogChoosePackage={openDialogChoosePackage}
        setOpenDialogChoosePackage={setOpenDialogChoosePackage}
        packageCollections={extend ? packageCollections2 : packageCollections}
        selectedPackage={selectedPackage}
        onChoosePackage={handleChangePackage}
        userLicense={userLicense}
        licenseUser={licenseUser}
        pmoVisit={pmoVisit}
        pmoTicket={pmoTicket}
        deployment={deployment}
        setUserLicense={setUserLicense}
        setPmoVisit={setPmoVisit}
        setDeployment={setDeployment}
        setChooseOrNot={setChooseOrNot}
        // onBenefits={handleBenefits}
      />

      <DialogCheckoutUpdate
        classes={classes}
        openDialogCheckoutUpdate={openDialogCheckoutUpdate}
        setOpenDialogCheckoutUpdate={setOpenDialogCheckoutUpdate}
        setOpenDialogUpdate={setOpenDialogUpdate}
        paymentCollections={
          extend ? paymentMethodCollections2 : paymentMethodCollections
        }
        calculation={checkoutFee}
        packageId={packageId}
        duration={extendVal}
        userLicense={userL}
        pmoVisit={pmo}
        deployment={pckgId === packageId ? deployTicket : deploy}
        accType={
          selectedPackage.accountType === undefined
            ? groupName
            : selectedPackage.accountType.name
        }
        pckgName={
          selectedPackage.name === undefined ? pckgName : selectedPackage.name
        }
        onVoucher={setCodeVoucher}
        voucherErrorsList={voucherErrorsList}
        statusRes={statusRes}
        statusMember={statusMember}
        differenceQtyPmo={differenceQtyPmo}
        differenceQtyUser={differenceQtyUser}
        origin="update-only"
        // origin={extend ? "update-extend" : "update-only"}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogUpdate;
