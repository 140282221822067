import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import moment from "moment";

import axiosConfig from "../../../constants/config-axios";

import { styles } from "./Style/styleDashboard";
import { URL_API } from "../../../constants/config-api";
import {
  STATUS_ACTIVE,
  STATUS_LAPSED,
} from "../../AM/MembershipManagement/constant-data";

import "./Style/Bar.css";
// import CardTDL from "./ComponentsDashboard/CardTDL";
import DialogLapsed from "./ComponentsDashboard/DialogLapsed";
import DialogActive from "./ComponentsDashboard/DialogActive";
import ViewCompany from "./ViewCompany";
import DialogWhatsNew from "./ComponentsDashboard/DialogWhatsNew";

const useStyle = makeStyles(styles);

// Fetcher

const ViewNewDashboard = () => {
  const classes = useStyle();
  const status_user_login = localStorage.getItem("status_user_login");
  const userLogin = JSON.parse(status_user_login);
  const status_id = userLogin?.membership_status_id;
  const userRole = userLogin?.userRole[0]?.name;

  // const buildTime = moment(1631603304925).format("DD MMM YYYY HH:mm");
  // const buildTime = "";

  const [subscription, setSubscription] = useState({
    start_date: "",
    finish_date: "",
    duration: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    used: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    left: {
      year: 0,
      months: 0,
      days: 0,
      hours: 0,
      minute: 0,
    },
    trial: {
      duration: 0,
      end_date: "",
      start_date: "",
      used: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
      left: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
    },
    lapsed: {
      duration: 0,
      end_date: "",
      start_date: "",
      used: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
      left: {
        year: 0,
        months: 0,
        days: 0,
        hours: 0,
        minute: 0,
      },
    },
  });
  const [days, setDays] = useState(null);
  const [day, setDay] = useState(null);
  const [detailPckg, setDetailPckg] = useState({
    membership_items: [],
    package_detail: {
      account_type: { id: "", name: "" },
      name: "",
    },
    subscription,
  });

  const [openDialogLapsed, setOpenDialogLapsed] = useState(false);
  const [openDialogActive, setOpenDialogActive] = useState(false);

  // Dialog Whats New
  const [openDialogWhatsNew, setOpenDialogWhatsNew] = useState(false);

  useEffect(() => {
    //*SPECIAL FOR TAB STATUS YOU in PAGE GOAL
    localStorage.setItem("location_tab", "You");

    setOpenDialogWhatsNew(true);

    /*
        ```````````````````````````
        GET Membership

        ```````````````````````````
    */
    // // console.log("diem aja lah");

    axiosConfig
      .get(`${URL_API}/account-management/group-membership-item`)
      .then((response) => {
        const result = response.data.data;

        if (response.status === 200) {
          // console.log("on-result Memship", result);

          setDetailPckg(result);

          const endDate =
            result.subscription.lapsed && result.subscription.lapsed.end_date;
          const finishDate = subscription.finish_date;
          const convertDays = moment().diff(endDate, "days");
          const convertDay = moment().diff(finishDate, "days");

          // // console.log("endDate", endDate);
          // // console.log("finishDate", finishDate);
          // // console.log("convertDays", convertDays);
          // // console.log("convertDay", convertDay);

          setDays(convertDays);
          setDay(convertDay);
          setSubscription(result.subscription);

          if (status_id === STATUS_LAPSED && userRole === "superadmin") {
            setOpenDialogLapsed(true);
          }

          if (status_id === STATUS_ACTIVE) {
            if (convertDay >= -3) {
              setOpenDialogActive(true);
            }
          }
        }
      })
      .catch((error) => {
        // console.log("Error : ", error.response);
      });
  }, []);

  const [isShowEmptyAllContentDashboard, setShowEmptyAllContentDashboard] =
    useState(false);

  const [dashboardTab, setDashboardTab] = useState(0);

  const handleDashboardTab = (e, newValue) => {
    setDashboardTab(newValue);
  };

  return (
    <Box mt={8.3} ml={7} flexGrow={1}>
      {/* <Paper elevation={1}>
        <StyledTabs
          elevation={1}
          value={dashboardTab}
          onChange={handleDashboardTab}
          aria-label="styled tabs example">
          <StyledTab label="Perusahaan" />
          <StyledTab label="Divisi" />
          <StyledTab label="Personal" />
        </StyledTabs>
      </Paper> */}

      {/* {dashboardTab === 0 && <ViewCompany classes={classes} />} */}
      <ViewCompany classes={classes} />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <DialogLapsed
        classes={classes}
        openDialogLapsed={openDialogLapsed}
        setOpenDialogLapsed={setOpenDialogLapsed}
        subscription={subscription}
        days={days}
        detailPckg={detailPckg}
      />

      <DialogActive
        classes={classes}
        openDialogActive={openDialogActive}
        setOpenDialogActive={setOpenDialogActive}
        subscription={subscription}
        day={day}
      />

      {/* <DialogWhatsNew
        openDialogWhatsNew={openDialogWhatsNew}
        setOpenDialogWhatsNew={setOpenDialogWhatsNew}
      /> */}
    </Box>
  );
};

export default ViewNewDashboard;
