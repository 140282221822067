import React, { useState } from "react";
import { Fade, IconButton, Chip, Typography, Tooltip } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import TruncateTextShortSuperPendek from "../../../../../utilities/TruncateTextShortSuperPendek";

import LightTooltip from "../../../../../components/LightTooltip";
import DialogVideoPlayer from "../../../Goal/ComponentsGoalResult/DialogVideoPlayer";
import DialogDeleteEvidenceMA from "../DialogDeleteEvidenceMA";

const VideoItem = (props) => {
  const {
    i,
    tile,
    classes,

    showFade,
    userToken,
    deleteAllowed,
    listPictState,
    setListPictState,
  } = props;

  /*
        `````````````````````````
        HANDLE DIALOG MODAL VIDEO

        `````````````````````````
    */
  const [isOpenDialogVideoPlayer, setOpenDialogVideoPlayer] = useState(false);
  const [openDialogDeleteEvidence, setOpenDialogDeleteEvidence] =
    useState(false);
  const [dataStringTile, setDataStringTile] = useState("");
  const [videoName, setVideoName] = useState("");

  const handleOpenDialogVideo = (e, tile) => {
    // console.log("Data video : ", tile);

    e.preventDefault();
    setOpenDialogVideoPlayer(true);
    setDataStringTile(tile.self.rel.content);
    setVideoName(tile.label);
  };

  /*
        ```````
        CONFIG 

        ```````
    */
  const [pictureInPicture, setPictureInPicture] = useState(false);

  return (
    <span key={i}>
      <Fade in={showFade}>
        <Tooltip title={tile.label}>
          <Chip
            style={{ marginRight: 4 }}
            onClick={(e) => handleOpenDialogVideo(e, tile)}
            icon={
              <LightTooltip arrow title="Putar video sekarang" placement="left">
                <IconButton
                  onClick={(e) => handleOpenDialogVideo(e, tile)}
                  size="small"
                >
                  <PlayCircleFilledIcon style={{ color: "#d1354a" }} />
                </IconButton>
              </LightTooltip>
            }
            label={
              <Typography variant="caption" className={classes.title}>
                {TruncateTextShortSuperPendek(tile.label)}
              </Typography>
            }
            onDelete={
              deleteAllowed ? () => setOpenDialogDeleteEvidence(true) : null
            }
            deleteIcon={
              deleteAllowed ? (
                <HighlightOffIcon
                  fontSize="small"
                  style={{ color: "#d1354a" }}
                />
              ) : null
            }
          />
        </Tooltip>
      </Fade>

      <DialogVideoPlayer
        isOpenDialogVideoPlayer={isOpenDialogVideoPlayer}
        setOpenDialogVideoPlayer={setOpenDialogVideoPlayer}
        userToken={userToken}
        dataStringTile={dataStringTile}
        videoName={videoName}
        pictureInPicture={pictureInPicture}
        setPictureInPicture={setPictureInPicture}
      />

      <DialogDeleteEvidenceMA
        openDialogDeleteEvidence={openDialogDeleteEvidence}
        setOpenDialogDeleteEvidence={setOpenDialogDeleteEvidence}
        item={tile}
        classes={classes}
        listPictState={listPictState}
        setListPictState={setListPictState}
      />
    </span>
  );
};

export default VideoItem;
