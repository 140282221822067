import React, { useState, Fragment, useContext } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import RestorePageOutlinedIcon from "@material-ui/icons/RestorePageOutlined";
import FolderIcon from "@material-ui/icons/Folder";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PeopleOutlineRoundedIcon from "@material-ui/icons/PeopleOutlineRounded";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

import moment from "moment";
import clsx from "clsx";
import { navigate } from "hookrouter";

import DialogInfoFolder from "../components/DialogInfoFolder";
import DialogRenameFolder from "../components/DialogRenameFolder";
import DialogDeleteFolder from "../components/DialogDeleteFolder";
import DialogMoveFolder from "../components/DialogMoveFolder";
import DialogFolderSetting2 from "../components/DialogFolderSetting2";
import DialogChangeOwnerFolder from "../components/DialogChangeOwnerFolder";

import ContextGlobalDrive from "../../context/ContextDrive";
import FormatBytes from "../../../../utilities/FormatBytes";

function FoldersList({ classes, item, isFolderSelected }) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [anchorElFolder, setAnchorElFolder] = useState(null);
  const [openDialogInfoFolder, setOpenDialogInfoFolder] = useState(false);
  const [openDialogRenameFolder, setOpenDialogRenameFolder] = useState(false);
  const [openDialogDeleteFolder, setOpenDialogDeleteFolder] = useState(false);
  const [openDialogMoveFolder, setOpenDialogMoveFolder] = useState(false);
  const [openDialogFolderSetting, setOpenDialogFolderSetting] = useState(false);
  const [openDialogChangeOwner, setOpenDialogChangeOwner] = useState(false);

  const handleShowAnchorFolder = (event) => {
    setAnchorElFolder(event.currentTarget);
  };

  const handleCloseAnchorFolder = () => {
    setAnchorElFolder(null);
  };

  const handleDialogInfo = () => {
    setAnchorElFolder(null);
    setOpenDialogInfoFolder(true);
  };

  const handleDialogRenameFolder = () => {
    setAnchorElFolder(null);
    setOpenDialogRenameFolder(true);
  };

  const handleDialogDeleteFolder = () => {
    setAnchorElFolder(null);
    setOpenDialogDeleteFolder(true);
  };

  const handleDialogMoveFolder = () => {
    setAnchorElFolder(null);
    setOpenDialogMoveFolder(true);
  };

  const handleDialogFolderSetting = () => {
    setAnchorElFolder(null);
    setOpenDialogFolderSetting(true);
  };

  const handleDialogChangeOwner = () => {
    setAnchorElFolder(null);
    setOpenDialogChangeOwner(true);
  };

  const handleFolderLocation = () => {
    if (item.folder_parent_id === null) {
      navigate("/drive");
      setAnchorElFolder(null);
    } else {
      let breadcrumb = localStorage.getItem("breadcrumb");
      breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
      breadcrumb.push(item.folder_parent_name);
      localStorage.setItem("breadcrumb", breadcrumb.toString());

      let directory_id = localStorage.getItem("directory_id");
      directory_id = directory_id ? directory_id.split("/") : [];
      directory_id.push(item.folder_parent_id);
      localStorage.setItem("directory_id", directory_id.toString());

      navigate(`/drive/${item.folder_parent_id}`);
      setAnchorElFolder(null);
    }
  };

  const handleNavigateFolder = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    breadcrumb.push(data.name);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    context.setDrivePath(data.id);

    // Set URL Path
    navigate(`/drive/${data.id}`);
  };

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFolder.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFolder, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFolder.slice(1));
    } else if (selectedIndex === context.selectedFolder.length - 1) {
      newSelected = newSelected.concat(context.selectedFolder.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFolder.slice(0, selectedIndex),
        context.selectedFolder.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected Folders", newSelected);

    context.setSelectedFolder(newSelected);
  };

  // Check is Folder selected or not
  const isItemFolderSelected = isFolderSelected(item.id);

  // ================ Folders Policy ================
  let deleteAllowed = false;
  let updateAllowed = false;
  let viewDetailAllowed = false;
  let manageAllowed = false;

  item.policy &&
    item.policy.forEach((isAllowed) => {
      if (isAllowed.actionName === "delete") {
        deleteAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "update") {
        updateAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "viewDetail") {
        viewDetailAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "manage") {
        manageAllowed = isAllowed.result;
      }
    });

  return (
    <Fragment>
      <TableRow>
        <TableCell padding="checkbox">
          {context.toggleSelect && (
            <Checkbox
              className={classes.checkboxRoot}
              onClick={() => handleChangeCheckbox(item)}
              checked={isItemFolderSelected}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
          )}
        </TableCell>

        <TableCell>
          <Box display="flex" alignItems="center">
            <Avatar
              style={{
                width: 34,
                height: 34,
              }}
            >
              <FolderIcon style={{ fontSize: 22 }} />
            </Avatar>

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15 }}
              onClick={() => handleNavigateFolder(item)}
            >
              {item.name}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {moment(item.modified_at).format("DD MMM YYYY, HH:mm")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            Folder
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {FormatBytes(Number(item.size))}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {item.owner !== undefined &&
              item.owner !== null &&
              `${item.owner.member.first_name} ${item.owner.member.last_name}`}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={handleShowAnchorFolder}>
            <MoreVertIcon />
          </IconButton>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElFolder}
            keepMounted
            open={Boolean(anchorElFolder)}
            onClose={handleCloseAnchorFolder}
          >
            <StyledMenuItem style={{ width: 200 }} onClick={handleDialogInfo}>
              <ListItemIcon>
                <VisibilityOutlinedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    View Info
                  </Typography>
                }
              />
            </StyledMenuItem>
            {updateAllowed && (
              <StyledMenuItem onClick={handleDialogRenameFolder}>
                <ListItemIcon>
                  <EditOutlinedIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Rename
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            <StyledMenuItem onClick={handleFolderLocation}>
              <ListItemIcon>
                <FolderOpenIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Show folder location
                  </Typography>
                }
              />
            </StyledMenuItem>
            {deleteAllowed && (
              <StyledMenuItem onClick={handleDialogDeleteFolder}>
                <ListItemIcon>
                  <DeleteOutlinedIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Delete Folder
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            {deleteAllowed && (
              <StyledMenuItem onClick={handleDialogMoveFolder}>
                <ListItemIcon>
                  <SyncAltIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Move Folder
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            {manageAllowed && (
              <StyledMenuItem onClick={handleDialogFolderSetting}>
                <ListItemIcon>
                  <VerifiedUserOutlinedIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Set Permission
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            {manageAllowed && (
              <StyledMenuItem onClick={handleDialogChangeOwner}>
                <ListItemIcon>
                  <PeopleOutlineRoundedIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Change Owner
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            {/* <StyledMenuItem>
              <ListItemIcon>
                <RestorePageOutlinedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}>
                    Restore Files
                  </Typography>
                }
              />
            </StyledMenuItem> */}
          </StyledMenu>
        </TableCell>
      </TableRow>

      <DialogInfoFolder
        openDialogInfoFolder={openDialogInfoFolder}
        setOpenDialogInfoFolder={setOpenDialogInfoFolder}
        item={item}
        classes={classes}
      />

      <DialogRenameFolder
        openDialogRenameFolder={openDialogRenameFolder}
        setOpenDialogRenameFolder={setOpenDialogRenameFolder}
        item={item}
        classes={classes}
      />

      <DialogDeleteFolder
        openDialogDeleteFolder={openDialogDeleteFolder}
        setOpenDialogDeleteFolder={setOpenDialogDeleteFolder}
        item={item}
        classes={classes}
      />

      <DialogMoveFolder
        openDialogMoveFolder={openDialogMoveFolder}
        setOpenDialogMoveFolder={setOpenDialogMoveFolder}
        classes={classes}
        item={item}
      />

      <DialogFolderSetting2
        openDialogFolderSetting={openDialogFolderSetting}
        setOpenDialogFolderSetting={setOpenDialogFolderSetting}
        classes={classes}
        item={item}
      />

      <DialogChangeOwnerFolder
        openDialogChangeOwner={openDialogChangeOwner}
        setOpenDialogChangeOwner={setOpenDialogChangeOwner}
        classes={classes}
        item={item}
      />
    </Fragment>
  );
}

export default FoldersList;
