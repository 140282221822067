import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { navigate } from "hookrouter";
import React, { useContext, useEffect, useState } from "react";
import {
  ToProfileAbsence,
  ToProfilePresence,
  ToProfileChangePin,
} from "../../../constants/config-redirect-url";
import ContextAttendanceDashboard from "../Context/ContextAttendanceDashboard";
import PrerequestAttendance from "../HOC/PrerequestAttendance";
import { styles } from "./Style/StyleProfile";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box style={{ padding: "0px 25px" }}>{children}</Box>}
    </div>
  );
}

function ViewProfile() {
  const classes = useStyles();
  const [authData, setAuthData] = useState(
    JSON.parse(localStorage.getItem("status_user_login"))
  );
  const { attendanceDashboardState, attendanceDashboardDispatch } = useContext(
    ContextAttendanceDashboard
  );

  useEffect(
    () => {
      let jsonAuthData = JSON.parse(localStorage.getItem("status_user_login"));
      setAuthData(jsonAuthData);
    } /* eslint-disable-line */,
    []
  );

  return (
    <PrerequestAttendance>
      <div className={classes.root}>
        <Card
          style={{
            margin: "0 auto",
            background: "#221E5B",
            borderRadius: 24,
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 40px",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography variant="h5" style={{ color: "white", marginTop: 20 }}>
              Hai,{" "}
              <b>
                {authData?.member_first_name + " " + authData?.member_last_name}
              </b>
            </Typography>
            <Typography
              variant="subtitle1"
              style={{ color: "white", fontWeight: "bold" }}
            >
              {authData?.member_position.structure_position_title_name}
            </Typography>
            <Typography variant="body2" style={{ color: "white" }}>
              Di <b>{authData?.group_name}</b>
            </Typography>
          </CardContent>
          <CardContent>
            <div className={classes.divPicture}>
              <img
                src={require("../../../assets/images/user-white.png")}
                className={classes.picture}
                alt=""
              />
            </div>
          </CardContent>
        </Card>

        <Typography
          variant="h4"
          style={{
            color: "#221E5B",
            textAlign: "left",
            margin: "20px 0",
            marginLeft: "10px",
          }}
          decoration="bold"
        >
          <b>Attendance Summary</b>
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0px 10px",
            gap: "10px",
          }}
        >
          <div
            style={{ width: "100%", padding: "20px 0px" }}
            onClick={() => navigate(ToProfilePresence)}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={require("../../../assets/images/SVG/location.svg")}
                    style={{ width: 40 }}
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" style={{ color: "#221E5B" }}>
                  <b>Presence</b>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={require("../../../assets/images/SVG/right-arrow.svg")}
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div
            style={{ width: "100%", borderBottom: `2px solid #cfd0d1` }}
          ></div>
          <div
            style={{ width: "100%", padding: "20px 0px" }}
            onClick={() => navigate(ToProfileAbsence)}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={require("../../../assets/images/SVG/absence.svg")}
                    style={{ width: 40 }}
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" style={{ color: "#221E5B" }}>
                  <b>Absence</b>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={require("../../../assets/images/SVG/right-arrow.svg")}
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div
            style={{ width: "100%", borderBottom: `2px solid #cfd0d1` }}
          ></div>
          <div
            style={{ width: "100%", padding: "20px 0px" }}
            onClick={() => navigate(ToProfileChangePin)}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={require("../../../assets/images/SVG/absence.svg")}
                    style={{ width: 40 }}
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" style={{ color: "#221E5B" }}>
                  <b>PIN</b>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={require("../../../assets/images/SVG/right-arrow.svg")}
                    alt=""
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </PrerequestAttendance>
  );
}

export default ViewProfile;
