import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, CircularProgress } from '@material-ui/core';

import AxiosConfig from '../../../../../../constants/config-axios';
import { URL_API } from '../../../../../../constants/config-api';
import handleError from '../../../../../Report/components/global/handleError';

import BarChartDashboard from './BarChartDashboard';
import DialogError from '../../../../../../components/DialogError';

const BarChart = ({
  classes,
  structureUnitTypeId,
  structureUnitTypeName,
  unitType,
  is_admin,
  is_full_access,
  is_superior,
  divisionData,
  dashboardData,
  selectDashboardUnitLabel
}) => {
  const [barChartData, setBarChartData] = useState([]);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  // // console.log("UNIT TYPE", unitType);

  let unit_type_id;
  let unit_type_name;
  const units = divisionData.length > 0 ? divisionData.map((el) => el.id) : [];
  const unitName =
    divisionData.length > 0
      ? divisionData.map((el) => el.structure_unit_name)[0]
      : '';

  if (is_admin || is_full_access) {
    // // console.log("Ini Admin");
    unit_type_id = unitType !== undefined ? unitType.id : '';
    unit_type_name = unitType !== undefined ? unitType.name : '';
  }

  if (is_superior && !is_admin && !is_full_access) {
    // // console.log("Ini Superior");
    unit_type_id = '';
    unit_type_name = unitName;
  }

  if (!is_superior && !is_admin && !is_full_access) {
    // // console.log("Ini User");
    unit_type_id = structureUnitTypeId;
    unit_type_name = structureUnitTypeName;
  }

  useEffect(() => {
    if (dashboardData !== undefined && dashboardData.barchart !== undefined) {
      setBarChartData(dashboardData.barchart);
    }
  }, [dashboardData]);

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Typography className={classes.title333} variant="subtitle1">
          <b>Pencapaian {selectDashboardUnitLabel}</b>
        </Typography>

        <Box>
          {dashboardData !== undefined && (
            <BarChartDashboard
              classes={classes}
              data={barChartData}
              is_all_access={is_admin || is_full_access}
            />
          )}
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default BarChart;
