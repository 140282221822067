import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useContext } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ContextAttendanceSetting from '../../Context/ContextAttendanceSetting';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Autocomplete } from '@material-ui/lab';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0F68D2'
    }
  }
});

const DialogCreate = (props) => {
  const {
    classes,
    isModalCreate,
    setModalCreate,
    masterDataScheduleDetail,
    dataScheduleDetail,
    setDataScheduleDetail,
    listError,
    createScheduleDetail,
    handleChangeWorkPattern,
    addShift,
    deleteShift,
    handleChangeShift,
    addSchedule,
    deleteSchedule,
    handleChangeScedule,
    addDataCheckIn,
    deleteDataCheckIn,
    addStruktur,
    deleteStruktur,
    generateWeekly
  } = props;

  const context = useContext(ContextAttendanceSetting);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalCreate}
        onClose={() => setModalCreate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: '#fff',
            backgroundSize: 'cover',
            backgroundColor: '#282560'
          }}
          disableTypography={true}
        >
          <Typography variant="h4">Tambah Daftar Jadwal Detail</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <Box p={1} my={1}>
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                Pilih Tipe Struktur
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                options={masterDataScheduleDetail.masterStructureUseCollections}
                getOptionLabel={(option) => {
                  if (option && option.name) {
                    return option.name;
                  } else {
                    return '';
                  }
                }}
                value={dataScheduleDetail.structure_use_obj}
                onChange={(event, newValue) => {
                  let dataScheduleDetailTemporary = dataScheduleDetail;
                  dataScheduleDetailTemporary.structure_use_obj = newValue;
                  dataScheduleDetailTemporary.structure_use = newValue
                    ? newValue.id
                    : '';
                  if (newValue && newValue.id === 'group') {
                    dataScheduleDetailTemporary.structure_obj = [
                      masterDataScheduleDetail.masterGroupCollection[0]
                    ];
                    dataScheduleDetailTemporary.structure_id = [
                      masterDataScheduleDetail.masterGroupCollection[0].id
                    ];
                  } else {
                    dataScheduleDetailTemporary.structure_obj = [{}];
                    dataScheduleDetailTemporary.structure_id = [];
                  }
                  setDataScheduleDetail({ ...dataScheduleDetailTemporary });
                }}
                noOptionsText="Tidak ada pilihan"
                popupIcon={<ArrowDropDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Pilih Tipe Struktur"
                    error={listError.some(
                      (item) => 'structure_use' === item.key
                    )}
                    helperText={listError.map((item) => {
                      if (item.key === 'structure_use') {
                        return item.description;
                      }
                    })}
                  />
                )}
              />
            </div>
            <Box my={2.5} />
            {dataScheduleDetail &&
              dataScheduleDetail.structure_use === 'member' && (
                <div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={6}
                        style={{ textAlign: 'left' }}
                        className={classes.girdMiddle}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title333}
                        >
                          <b>Daftar Member</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} style={{ textAlign: 'right' }}>
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => {
                            addStruktur();
                          }}
                        >
                          Tambah Member
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <Box my={2.5} />
                  <div>
                    {dataScheduleDetail.structure_obj.map((item, i) => {
                      return (
                        <Box key={i}>
                          <Grid container>
                            <Grid item md={11} style={{ textAlign: 'left' }}>
                              <Autocomplete
                                options={
                                  masterDataScheduleDetail.masterMemberCollections
                                }
                                getOptionLabel={(option) => {
                                  if (option && option.email) {
                                    if (option.member) {
                                      return (
                                        option.member.first_name +
                                        ' ' +
                                        option.member.last_name +
                                        '(' +
                                        option.email +
                                        ')'
                                      );
                                    } else {
                                      return '-' + '(' + option.email + ')';
                                    }
                                  } else {
                                    return '';
                                  }
                                }}
                                value={item}
                                onChange={(event, newValue) => {
                                  let dataScheduleDetailTemporary =
                                    dataScheduleDetail;
                                  dataScheduleDetailTemporary.structure_obj[i] =
                                    newValue;
                                  dataScheduleDetailTemporary.structure_id[i] =
                                    newValue ? newValue.id : '';
                                  setDataScheduleDetail({
                                    ...dataScheduleDetailTemporary
                                  });
                                }}
                                noOptionsText="Tidak ada pilihan"
                                popupIcon={<ArrowDropDownIcon />}
                                renderInput={(params) => (
                                  <TextField
                                    style={{ fontSize: 13 }}
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label=""
                                    error={listError.some(
                                      (item) => 'structure_id.' + i === item.key
                                    )}
                                    helperText={listError.map((item) => {
                                      if (item.key === 'structure_id.' + i) {
                                        return item.description;
                                      }
                                    })}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item md={1} style={{ textAlign: 'center' }}>
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                style={{ marginTop: '5px' }}
                                size="small"
                                onClick={() => {
                                  deleteStruktur(i);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Box my={2.5} />
                        </Box>
                      );
                    })}
                  </div>
                </div>
              )}
            {dataScheduleDetail &&
              dataScheduleDetail.structure_use === 'position' && (
                <div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={6}
                        style={{ textAlign: 'left' }}
                        className={classes.girdMiddle}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title333}
                        >
                          <b>Daftar Posisi</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} style={{ textAlign: 'right' }}>
                        <Button
                          variant="outlined"
                          className={
                            masterDataScheduleDetail.masterPositionCollection
                              .length <= 0 ||
                            dataScheduleDetail.structure_obj.length >=
                              masterDataScheduleDetail.masterPositionCollection
                                .length
                              ? classes.buttonDisabled
                              : classes.button
                          }
                          disabled={
                            masterDataScheduleDetail.masterPositionCollection
                              .length <= 0 ||
                            dataScheduleDetail.structure_obj.length >=
                              masterDataScheduleDetail.masterPositionCollection
                                .length
                          }
                          onClick={() => {
                            addStruktur();
                          }}
                        >
                          Tambah Posisi
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <Box my={2.5} />
                  <div>
                    {dataScheduleDetail.structure_obj.map((item, i) => {
                      return (
                        <Box key={i}>
                          <Grid container>
                            <Grid item md={11} style={{ textAlign: 'left' }}>
                              <Autocomplete
                                options={
                                  masterDataScheduleDetail.masterPositionCollection
                                }
                                getOptionLabel={(option) => {
                                  if (option && option.name) {
                                    return option.name;
                                  } else {
                                    return '';
                                  }
                                }}
                                value={item}
                                onChange={(event, newValue) => {
                                  let listErrorTemp = context.listError;
                                  let dataScheduleDetailTemporary =
                                    dataScheduleDetail;
                                  listErrorTemp = [];

                                  if (
                                    newValue &&
                                    !dataScheduleDetail.structure_id.includes(
                                      newValue.id
                                    )
                                  ) {
                                    dataScheduleDetailTemporary.structure_obj[
                                      i
                                    ] = newValue;
                                    dataScheduleDetailTemporary.structure_id[
                                      i
                                    ] = newValue ? newValue.id : '';
                                    setDataScheduleDetail({
                                      ...dataScheduleDetailTemporary
                                    });
                                  } else {
                                    listErrorTemp.push({
                                      key: 'structure_id.' + i,
                                      description:
                                        'Posisi telah terpakai, Silahkan Pilih Posisi Lain'
                                    });
                                  }
                                  context.setListError(listErrorTemp);
                                }}
                                noOptionsText="Tidak ada pilihan"
                                popupIcon={<ArrowDropDownIcon />}
                                renderInput={(params) => (
                                  <TextField
                                    style={{ fontSize: 13 }}
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label=""
                                    error={listError.some(
                                      (item) => 'structure_id.' + i === item.key
                                    )}
                                    helperText={listError.map((item) => {
                                      if (item.key === 'structure_id.' + i) {
                                        return item.description;
                                      }
                                    })}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item md={1} style={{ textAlign: 'left' }}>
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                style={{ marginTop: '5px' }}
                                size="small"
                                onClick={() => {
                                  deleteStruktur(i);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Box my={2.5} />
                        </Box>
                      );
                    })}
                  </div>
                </div>
              )}
            {dataScheduleDetail &&
              dataScheduleDetail.structure_use === 'unit' && (
                <div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={6}
                        style={{ textAlign: 'left' }}
                        className={classes.girdMiddle}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title333}
                        >
                          <b>Daftar Unit</b>
                        </Typography>
                      </Grid>
                      <Grid item md={6} style={{ textAlign: 'right' }}>
                        <Button
                          variant="outlined"
                          className={
                            masterDataScheduleDetail.masterUnitCollection
                              .length <= 0 ||
                            dataScheduleDetail.structure_obj.length >=
                              masterDataScheduleDetail.masterUnitCollection
                                .length
                              ? classes.buttonDisabled
                              : classes.button
                          }
                          disabled={
                            masterDataScheduleDetail.masterUnitCollection
                              .length <= 0 ||
                            dataScheduleDetail.structure_obj.length >=
                              masterDataScheduleDetail.masterUnitCollection
                                .length
                          }
                          onClick={() => {
                            addStruktur();
                          }}
                        >
                          Tambah Unit
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <Box my={2.5} />
                  <div>
                    {dataScheduleDetail.structure_obj.map((item, i) => {
                      return (
                        <Box key={i}>
                          <Grid container>
                            <Grid item md={11} style={{ textAlign: 'left' }}>
                              <Autocomplete
                                options={
                                  masterDataScheduleDetail.masterUnitCollection
                                }
                                getOptionLabel={(option) => {
                                  if (option && option.name) {
                                    return option.name;
                                  } else {
                                    return '';
                                  }
                                }}
                                value={item}
                                onChange={(event, newValue) => {
                                  let listErrorTemp = context.listError;
                                  let dataScheduleDetailTemporary =
                                    dataScheduleDetail;
                                  listErrorTemp = [];

                                  if (
                                    newValue &&
                                    !dataScheduleDetail.structure_id.includes(
                                      newValue.id
                                    )
                                  ) {
                                    dataScheduleDetailTemporary.structure_obj[
                                      i
                                    ] = newValue;
                                    dataScheduleDetailTemporary.structure_id[
                                      i
                                    ] = newValue ? newValue.id : '';
                                    setDataScheduleDetail({
                                      ...dataScheduleDetailTemporary
                                    });
                                  } else {
                                    listErrorTemp.push({
                                      key: 'structure_id.' + i,
                                      description:
                                        'Unit telah terpakai, Silahkan Pilih Unit Lain'
                                    });
                                  }
                                  context.setListError(listErrorTemp);
                                }}
                                noOptionsText="Tidak ada pilihan"
                                popupIcon={<ArrowDropDownIcon />}
                                renderInput={(params) => (
                                  <TextField
                                    style={{ fontSize: 13 }}
                                    {...params}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    label=""
                                    error={listError.some(
                                      (item) => 'structure_id.' + i === item.key
                                    )}
                                    helperText={listError.map((item) => {
                                      if (item.key === 'structure_id.' + i) {
                                        return item.description;
                                      }
                                    })}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              md={1}
                              xs={2}
                              style={{ textAlign: 'left' }}
                            >
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                style={{ marginTop: '5px' }}
                                size="small"
                                onClick={() => {
                                  deleteStruktur(i);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Box my={2.5} />
                        </Box>
                      );
                    })}
                  </div>
                </div>
              )}
            <div>
              <div>
                <Typography variant="subtitle2" className={classes.title333}>
                  <b>Pilh Work Pattern</b>
                </Typography>
                <Box my={0.5} />
                <Autocomplete
                  options={
                    masterDataScheduleDetail.masterWorkPatternCollections
                  }
                  getOptionLabel={(option) => {
                    if (option && option.name) {
                      return option.name;
                    } else {
                      return '';
                    }
                  }}
                  value={dataScheduleDetail.work_pattern_obj}
                  onChange={(event, newValue) => {
                    handleChangeWorkPattern(newValue);
                  }}
                  noOptionsText="Tidak ada pilihan"
                  popupIcon={<ArrowDropDownIcon />}
                  renderInput={(params) => (
                    <TextField
                      style={{ fontSize: 13 }}
                      {...params}
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Pilih Work Pattern"
                      error={listError.some(
                        (item) => 'work_pattern_id' === item.key
                      )}
                      helperText={listError.map((item) => {
                        if (item.key === 'work_pattern_id') {
                          return item.description;
                        }
                      })}
                    />
                  )}
                />
              </div>
              <Box my={2.5} />
              {dataScheduleDetail.work_pattern_obj &&
              dataScheduleDetail.work_pattern_obj.id ? (
                <Box>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <Grid container>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            Interval
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            : {}
                            {dataScheduleDetail.work_pattern_obj.interval
                              ? dataScheduleDetail.work_pattern_obj.interval
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            Total Duration
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            : {}
                            {dataScheduleDetail.work_pattern_obj.duration
                              ? dataScheduleDetail.work_pattern_obj.duration +
                                ' Hari'
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            Work Duration
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            : {}
                            {dataScheduleDetail.work_pattern_obj.work_duration
                              ? dataScheduleDetail.work_pattern_obj
                                  .work_duration + ' Hari'
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            Off Duration
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            : {}
                            {dataScheduleDetail.work_pattern_obj.off_duration
                              ? dataScheduleDetail.work_pattern_obj
                                  .off_duration + ' Hari'
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box my={2.5} />
                </Box>
              ) : (
                ''
              )}
            </div>
            <div>
              <div>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={6}
                    style={{ textAlign: 'left' }}
                    className={classes.girdMiddle}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      <b>Daftar Shift</b>
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: 'right' }}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        addShift();
                      }}
                    >
                      Tambah Shift
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <Box my={2.5} />
              <div>
                {dataScheduleDetail.shift_obj.map((item, i) => {
                  return (
                    <Box key={i}>
                      <Grid container>
                        <Grid item md={11} style={{ textAlign: 'left' }}>
                          <Autocomplete
                            options={
                              masterDataScheduleDetail.masterShiftCollections
                            }
                            getOptionLabel={(option) => {
                              if (option && option.title) {
                                return (
                                  option.title +
                                  ' (' +
                                  option.schedule_in +
                                  '-' +
                                  option.schedule_out +
                                  ')'
                                );
                              } else {
                                return '';
                              }
                            }}
                            value={dataScheduleDetail.shift_obj[i]}
                            onChange={(event, newValue) => {
                              handleChangeShift(newValue, i);
                            }}
                            noOptionsText="Tidak ada pilihan"
                            popupIcon={<ArrowDropDownIcon />}
                            renderInput={(params) => (
                              <TextField
                                style={{ fontSize: 13 }}
                                {...params}
                                fullWidth
                                variant="outlined"
                                size="small"
                                label=""
                                error={listError.some(
                                  (item) => 'shift_id.' + i === item.key
                                )}
                                helperText={listError.map((item) => {
                                  if (item.key === 'shift_id.' + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={1} style={{ textAlign: 'center' }}>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{ marginTop: '5px' }}
                            size="small"
                            onClick={() => {
                              deleteShift(i);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        {dataScheduleDetail.shift_obj[i] &&
                        dataScheduleDetail.shift_obj[i].id ? (
                          <Grid item xs={12}>
                            <Box mt={2.5}>
                              <Grid container spacing={2}>
                                <Grid item md={6}>
                                  <Grid container>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        Jadwal Masuk
                                      </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        : {}
                                        {dataScheduleDetail.shift_obj[i]
                                          .schedule_in
                                          ? dataScheduleDetail.shift_obj[i]
                                              .schedule_in
                                          : ''}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item md={6}>
                                  <Grid container>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        Jadwal Keluar
                                      </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        : {}
                                        {dataScheduleDetail.shift_obj[i]
                                          .schedule_out
                                          ? dataScheduleDetail.shift_obj[i]
                                              .schedule_out
                                          : ''}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item md={6}>
                                  <Grid container>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        GMT
                                      </Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        : {}
                                        {dataScheduleDetail.shift_obj[i].gmt
                                          ? dataScheduleDetail.shift_obj[i].gmt
                                          : ''}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {dataScheduleDetail.shift_obj[i]
                                  .late_tolerance_status == 1 ? (
                                  <Grid item md={6}>
                                    <Grid container>
                                      <Grid item md={6}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.title333}
                                        >
                                          Toleransi Terlambat
                                        </Typography>
                                      </Grid>
                                      <Grid item md={6}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.title333}
                                        >
                                          : {}
                                          {dataScheduleDetail.shift_obj[i]
                                            .late_tolerance
                                            ? dataScheduleDetail.shift_obj[i]
                                                .late_tolerance + ' Menit'
                                            : ''}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Box my={2.5} />
                    </Box>
                  );
                })}
              </div>
            </div>
            <div>
              <div>
                <Grid container>
                  <Grid
                    item
                    md={6}
                    style={{ textAlign: 'left' }}
                    className={classes.girdMiddle}
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      <b>Daftar Jadwal</b>
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: 'right' }}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        addSchedule();
                      }}
                    >
                      Tambah Jadwal
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <Box my={2.5} />
              <div>
                {dataScheduleDetail.schedule_obj.map((item, i) => {
                  return (
                    <Box key={i}>
                      <Grid container key={i}>
                        <Grid item md={11} style={{ textAlign: 'left' }}>
                          <Autocomplete
                            options={
                              masterDataScheduleDetail.masterScheduleCollections
                            }
                            getOptionLabel={(option) => {
                              if (option && option.name) {
                                return option.name;
                              } else {
                                return '';
                              }
                            }}
                            value={dataScheduleDetail.schedule_obj[i]}
                            onChange={(event, newValue) => {
                              handleChangeScedule(newValue, i);
                            }}
                            noOptionsText="Tidak ada pilihan"
                            popupIcon={<ArrowDropDownIcon />}
                            renderInput={(params) => (
                              <TextField
                                style={{ fontSize: 13 }}
                                {...params}
                                fullWidth
                                variant="outlined"
                                size="small"
                                label=""
                                error={listError.some(
                                  (item) => 'schedule_id.' + i === item.key
                                )}
                                helperText={listError.map((item) => {
                                  if (item.key === 'schedule_id.' + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={1} style={{ textAlign: 'center' }}>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{ marginTop: '5px' }}
                            size="small"
                            onClick={() => {
                              deleteSchedule(i);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        {dataScheduleDetail.schedule_obj[i] &&
                        dataScheduleDetail.schedule_obj[i].id ? (
                          <Grid item xs={12}>
                            <Box mt={2.5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Grid container>
                                    <Grid item xs={4} md={4}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        Tanggal/Hari
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={8} md={8}>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.title333}
                                      >
                                        : {}
                                        {dataScheduleDetail.schedule_obj[i]
                                          .shift &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .shift.workPattern &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .shift.workPattern.interval ===
                                            'Weekly' &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .day_in &&
                                          generateWeekly(
                                            dataScheduleDetail.schedule_obj[i]
                                          )}
                                        {dataScheduleDetail.schedule_obj[i]
                                          .shift &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .shift.workPattern &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .shift.workPattern.interval ===
                                            'Monthly' &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .day_in &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .day_in}
                                        {dataScheduleDetail.schedule_obj[i]
                                          .shift &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .shift.workPattern &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .shift.workPattern.interval ===
                                            'Custom' &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .day_in &&
                                          dataScheduleDetail.schedule_obj[i]
                                            .day_in}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Box my={2.5} />
                    </Box>
                  );
                })}
              </div>
            </div>
            <div>
              <Typography
                variant="subtitle2"
                component={'span'}
                className={classes.title333}
              >
                <b>Aktifkan Check In/Check Out</b>
              </Typography>
              <Switch
                checked={dataScheduleDetail.with_checkin === 1}
                onChange={(event) => {
                  let dataTemporary = dataScheduleDetail;
                  dataTemporary.with_checkin = event.target.checked ? 1 : 0;
                  setDataScheduleDetail({ ...dataTemporary });
                }}
                name="with_checkin"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            <Box my={2.5} />
            {dataScheduleDetail.with_checkin === 1 &&
              dataScheduleDetail.schedule_obj.map((itemSchedule, iSchedule) => {
                if (itemSchedule && itemSchedule.activities) {
                  return (
                    <div>
                      <Grid key={iSchedule} container>
                        <Grid item md={12} xs={12}>
                          <div>
                            <Grid container>
                              <Grid
                                item
                                md={6}
                                xs={12}
                                style={{
                                  textAlign: 'left',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  className={classes.title333}
                                >
                                  <b>Data Aktifitas {itemSchedule.name}</b>
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                                style={{ textAlign: 'right' }}
                              >
                                <Button
                                  variant="outlined"
                                  className={classes.button}
                                  onClick={() => {
                                    addDataCheckIn(iSchedule);
                                  }}
                                >
                                  Tambah Aktifitas
                                </Button>
                              </Grid>
                            </Grid>
                            <Box my={2.5} />
                            <div>
                              {itemSchedule.activities.map(
                                (itemActivities, iActivities) => {
                                  return (
                                    <div
                                      key={iActivities}
                                      className={classes.itemActivity}
                                    >
                                      <Box my={1.5} />
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          md={6}
                                          style={{ textAlign: 'left' }}
                                          className={classes.girdMiddle}
                                        >
                                          <div>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.title333}
                                            >
                                              <b>
                                                Data Check In {iActivities + 1}
                                              </b>
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          style={{ textAlign: 'left' }}
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            color="secondary"
                                            onClick={() => {
                                              deleteDataCheckIn(
                                                iSchedule,
                                                iActivities
                                              );
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                      <Box my={0.5} />
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          md={6}
                                          style={{ textAlign: 'left' }}
                                        >
                                          <div>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.title333}
                                            >
                                              <b>Jadwal Check In</b>
                                            </Typography>
                                            <Box my={0.5} />
                                            <MuiPickersUtilsProvider
                                              utils={LocalizedUtils}
                                            >
                                              <TimePicker
                                                placeholder="Jadwal Check In"
                                                value={
                                                  itemActivities.check_in_date
                                                }
                                                onChange={(date) => {
                                                  let dataScheduleDetailTemporary =
                                                    dataScheduleDetail;
                                                  dataScheduleDetailTemporary.schedule_obj[
                                                    iSchedule
                                                  ].activities[
                                                    iActivities
                                                  ].check_in_date = date;
                                                  dataScheduleDetailTemporary.schedule_obj[
                                                    iSchedule
                                                  ].activities[
                                                    iActivities
                                                  ].check_in =
                                                    moment(date).format(
                                                      'HH:mm'
                                                    );
                                                  setDataScheduleDetail({
                                                    ...dataScheduleDetailTemporary
                                                  });
                                                }}
                                                autoOk={true}
                                                ampm={false}
                                                inputVariant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                format="HH:mm"
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <IconButton size="small">
                                                        <EventAvailableIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  )
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          md={6}
                                          xs={12}
                                          style={{ textAlign: 'left' }}
                                        >
                                          <div>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.title333}
                                            >
                                              <b>Jadwal Check Out</b>
                                            </Typography>
                                            <Box my={0.5} />
                                            <MuiPickersUtilsProvider
                                              utils={LocalizedUtils}
                                            >
                                              <TimePicker
                                                placeholder="Jadwal Check Out"
                                                value={
                                                  itemActivities.check_out_date
                                                }
                                                onChange={(date) => {
                                                  let dataScheduleDetailTemporary =
                                                    dataScheduleDetail;
                                                  dataScheduleDetailTemporary.schedule_obj[
                                                    iSchedule
                                                  ].activities[
                                                    iActivities
                                                  ].check_out_date = date;
                                                  dataScheduleDetailTemporary.schedule_obj[
                                                    iSchedule
                                                  ].activities[
                                                    iActivities
                                                  ].check_out =
                                                    moment(date).format(
                                                      'HH:mm'
                                                    );
                                                  setDataScheduleDetail({
                                                    ...dataScheduleDetailTemporary
                                                  });
                                                }}
                                                autoOk={true}
                                                ampm={false}
                                                inputVariant="outlined"
                                                size="small"
                                                className={classes.textField}
                                                format="HH:mm"
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <IconButton size="small">
                                                        <EventAvailableIcon />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  )
                                                }}
                                              />
                                            </MuiPickersUtilsProvider>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <Box my={2.5} />
                                      <Grid container>
                                        <Grid item md={12} xs={12}>
                                          <div>
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.title333}
                                            >
                                              <b>Pilih Lokasi</b>
                                            </Typography>
                                            <Box my={0.5} />
                                            <Autocomplete
                                              options={
                                                masterDataScheduleDetail.masterLocationCollections
                                              }
                                              getOptionLabel={(option) => {
                                                if (
                                                  option &&
                                                  option.location_name
                                                ) {
                                                  return option.location_name;
                                                } else {
                                                  return '';
                                                }
                                              }}
                                              value={itemActivities.location}
                                              onChange={(event, newValue) => {
                                                let dataScheduleDetailTemporary =
                                                  dataScheduleDetail;
                                                dataScheduleDetailTemporary.schedule_obj[
                                                  iSchedule
                                                ].activities[
                                                  iActivities
                                                ].location = newValue;
                                                dataScheduleDetailTemporary.schedule_obj[
                                                  iSchedule
                                                ].activities[
                                                  iActivities
                                                ].location_id = newValue
                                                  ? newValue.id
                                                  : '';
                                                setDataScheduleDetail({
                                                  ...dataScheduleDetailTemporary
                                                });
                                              }}
                                              noOptionsText="Tidak ada pilihan"
                                              popupIcon={<ArrowDropDownIcon />}
                                              renderInput={(params) => (
                                                <TextField
                                                  style={{ fontSize: 13 }}
                                                  {...params}
                                                  fullWidth
                                                  variant="outlined"
                                                  size="small"
                                                  label="Pilih Lokasi"
                                                  // error={listError.some(item => 'location_id' === item.key)}
                                                  // helperText={listError.map((item) => {
                                                  //   if (item.key === 'location_id') {
                                                  //     return item.description;
                                                  //   }
                                                  // })}
                                                />
                                              )}
                                            />
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <Box my={2.5} />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Box my={2.5} />
                    </div>
                  );
                }
              })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setModalCreate(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant="outlined"
              className={classes.button1}
              onClick={createScheduleDetail}
            >
              Simpan !
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogCreate;
