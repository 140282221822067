function TruncateSubstring(input) {

   if(typeof number && input !== null && input !== undefined){

      return input.toString().substring(0,4);

       

   } else {

      return input;
   };
 };

 export default TruncateSubstring;