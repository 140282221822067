import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { useRoutes, useRedirect, navigate } from "hookrouter";

import Redirect from "../../../utilities/Redirect";
import {
  ToStructureOrganization,
  ToCompletionCompanyStructure,
  ToCompletionCompanyStructureFormulirKosong,
  ToCompletionCompanyStructureFormBantuSaya,
  ToDashboard,
  ToNewSO,
} from "../../../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    // padding: theme.spacing(5, 2),
    // marginTop: theme.spacing(4),
    // width: 575,
    borderRadius: 2,
  },
  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },
  breadcrumb: {
    marginLeft: theme.spacing(5),
  },
  title: {
    fontFamily: "Roboto",
  },
  titleLater: {
    fontFamily: "Roboto",
    cursor: "pointer",
    marginLeft: theme.spacing(5),
    color: "grey",
  },
  titleLaterSecond: {
    fontFamily: "Roboto",
    cursor: "pointer",
    // marginLeft: theme.spacing(5),
    // color: 'grey'
  },
});

const ViewCompletionCompanyStructureQuestionFirst = (props) => {
  const { classes } = props;

  const handleNantiSaja = () => {
    // Redirect(ToDashboard)
    // localStorage.setItem('question_buat_so', 'nanti');

    Redirect(ToNewSO);
  };

  const handleSekarangSaja = () => {
    // Redirect(ToCompletionCompanyStructureFormBantuSaya);
    // localStorage.setItem('question_buat_so', 'sekarang');
    Redirect(ToCompletionCompanyStructure); //*Di arahkan ke page 'Second'
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Paper elevation={0}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={4} style={{ textAlign: "center" }}></Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Breadcrumbs
              className={classes.breadcrumb}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="Breadcrumb">
              <Typography color="inherit" className={classes.title}>
                Keanggotaan
              </Typography>
              <Typography color="inherit" className={classes.title}>
                Profil
              </Typography>
              <Typography color="primary" className={classes.title}>
                <b>Struktur Perusahaan</b>
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}></Grid>
        </Grid>
      </Paper>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Paper className={classes.root} elevation={0}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h5" className={classes.title}>
              <b>Buat Struktur Organisasi Anda sekarang ?</b>
            </Typography>
            <br />

            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              Kawal performance organisasi Anda untuk meraih goal maksimal
              dengan membuat Struktur Organisasi sekarang
            </Typography>
            <br />
            <br />
            <span
              // variant='subtitle1'
              className={classes.titleLaterSecond}
              // onClick={() => Redirect(ToCompletionCompanyStructureFormulirKosong) }
              onClick={handleNantiSaja}
              // onClick={() => navigate()}
            >
              <b>Nanti</b>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <Button
              // onClick={() => Redirect(ToCompletionCompanyStructure)}
              onClick={handleSekarangSaja}
              variant="contained"
              size="medium"
              className={classes.button}>
              Buat organisasi
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewCompletionCompanyStructureQuestionFirst);
