import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { navigate } from "hookrouter";

import ContextTimeOff from "../Context/ContextTimeOff";
import { ToTimeOffSetting } from "../../../../constants/config-redirect-url";

const DialogDefaultNotFound = (props) => {
  const {
    classes,
    isModalDefaultNotFound,
    setModalDefaultNotFound
  } = props;

  const context = useContext(ContextTimeOff);

  const [loader, setLoader] = useState(false);

  return (
    <Dialog
      open={isModalDefaultNotFound}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: "left" }}></DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <div id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}>
            <b>
              Untuk menggunakan fitur Time Off, silahkan Anda setting terlebih dahulu.
            </b>
          </Typography>
        </div>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => navigate(ToTimeOffSetting)}
          // fullWidth
        >
          {loader !== true ? (
            "Setting Time Off"
          ) : (
            <CircularProgress size={20} style={{ color: "white" }} />
          )}
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
};

export default DialogDefaultNotFound;
