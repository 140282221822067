import React from "react";
import { createMuiTheme, withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "yellow",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Roboto",
    fontWeight: "bold",
    maxWidth: 400,
  },

  arrow: {
    color: "yellow",
  },
}))(Tooltip);

export default LightTooltip;
