import React, { useState } from "react";
import { Fade, Chip, Typography, Tooltip } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";

import TruncateTextShort from "../../../../../utilities/TruncateTextShort";
import DialogDeleteEvidenceMA from "../DialogDeleteEvidenceMA";

function LinkItem({
  i,
  tile,
  classes,
  showFade,
  deleteAllowed,
  listPictState,
  setListPictState,
  setSuccessCreateMA,
}) {
  const [openDialogDeleteEvidence, setOpenDialogDeleteEvidence] = useState(
    false
  );

  return (
    <span key={i}>
      <Fade in={showFade}>
        <Tooltip title={tile.content}>
          <Chip
            clickable
            icon={<LinkRoundedIcon />}
            label={
              <Typography variant="caption" className={classes.title}>
                {TruncateTextShort(tile.content)}
              </Typography>
            }
            style={{ margin: 3 }}
            onClick={() => window.open(tile.content, "_blank")}
            onDelete={
              deleteAllowed ? () => setOpenDialogDeleteEvidence(true) : null
            }
            deleteIcon={
              deleteAllowed ? (
                <HighlightOffIcon
                  fontSize="small"
                  style={{ color: "#d1354a" }}
                />
              ) : null
            }
          />
        </Tooltip>
      </Fade>

      <DialogDeleteEvidenceMA
        openDialogDeleteEvidence={openDialogDeleteEvidence}
        setOpenDialogDeleteEvidence={setOpenDialogDeleteEvidence}
        item={tile}
        classes={classes}
        listPictState={listPictState}
        setListPictState={setListPictState}
        setSuccessCreateMA={setSuccessCreateMA}
      />
    </span>
  );
}

export default LinkItem;
