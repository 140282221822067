import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Chip,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";
import StyledIconPlusButton from "./StyledIconPlusButton";

import ContextNewSO from "../Context/ContextNewSO";

import {
  STRUCTURE_POSITION_CLASSIFICATION_ID_TOP_LEADER,
  STRUCTURE_POSITION_CLASSIFICATION_ID_HEAD,
  STRUCTURE_POSITION_CLASSIFICATION_ID_DEPUTY_HEAD,
  STRUCTURE_POSITION_CLASSIFICATION_ID_ASSISTANT,
  STRUCTURE_POSITION_CLASSIFICATION_ID_STAFF,
} from "../Constants/config-classification-so-master";

import { styles } from "../Style/StyleNewSO";

import { URL_API } from "../../../constants/config-api";

const useStyles = makeStyles(styles);

const StyledTreeItemDetail = (treeItemData) => {
  /**
   *
   *
   * ```````````````````````````````````````
   *  context : {
   *
   *      setOpenDialogTambahHead : Boolean,
   *      isOpenDialogTambahHead : Boolean,
   *      isOpenDialogDeleteNodeSO: Boolean,
   *      setOpenDialogDeleteNodeSO : Boolean,
   *
   *  }
   *
   * ```````````````````````````````````````
   */

  const context = useContext(ContextNewSO);
  const classes = useStyles();

  /* 
        ------------------------------------------------- 
      |                                                  |
      | STATE FOR DROPDOWN IN STYLED MENU ITEM COMPONENT | 
      |                                                  |
        -------------------------------------------------
    */

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElIconPlus, setAnchorElIconPlus] = useState(null);

  /*
            -------------------------------------------------------
        |                                                         |
        | HANDLE DROPDOWN ICON MORE VERT FOR OPTION EDIT & DELETE |
        |                                                         |
            -------------------------------------------------------
    */
  const handleOpenDropdownIconMoreVert = (e, data) => {
    setAnchorEl(e.currentTarget);
    // console.log("Data from 'more_vert' : ", data);
    context.setDataDetail(data); //*Next ini di ganti yak, pakai "context.setDataDetailIconMoreHorizon"

    context.setDataDetailIconMoreHorizon(data);

    /*
            ``````````````````
            To set empty state
            ``````````````````

        */
    context.setPositionTitleChoose(false);
    context.setNamaJenisUnitChoosed("");
    context.setNamaUnitChoosed("");
    context.setPositionTitleName("");
    context.setFirstName("");
  };

  const handleCloseDropdownIconMoreVert = () => {
    setAnchorEl(null);
  };

  /*
        ``````````````````````````````````````````````````
        HANDLE SCENARIO KETIKA CLOSE DIALOG DELETE NODE SO

        ``````````````````````````````````````````````````
      */

  useEffect(() => {
    if (
      context.isOpenDialogDeleteNodeSO == false &&
      context.isOpenDialogEditNodeSOInRootTop == false
    ) {
      handleCloseDropdownIconMoreVert();
    }
  }, [
    context.isOpenDialogDeleteNodeSO,
    context.isOpenDialogEditNodeSOInRootTop,
  ]);

  /*
          -----------------------------------------------------------
        |                                                             |
        | HANDLE DROPDOWN HEAD, DEPUTY HEAD, ADD ASSISTANT, ADD STAFF | 
        |                                                             |
          -----------------------------------------------------------
          
      */
  const handleOpenDropdownIconPlus = (e, data) => {
    setAnchorElIconPlus(e.currentTarget);

    // console.log("Data from 'icon_plus' : ", data);
    context.setDataDetail(data);

    /*
            ``````````````````
            To set empty state
            ``````````````````

        */

    context.setPositionTitleChoose(false);
    context.setNamaJenisUnitChoosed("");
    context.setNamaUnitChoosed("");
    context.setPositionTitleName("");
    context.setFirstName("");
  };

  const handleCloseDropdownIconPlus = () => {
    setAnchorElIconPlus(null);
  };

  useEffect(() => {
    if (
      context.isOpenDialogTambahHead == false &&
      context.isOpenDialogTambahDeputyHead == false &&
      context.isOpenDialogTambahAssistant == false &&
      context.isOpenDialogTambahStaff == false
    ) {
      handleCloseDropdownIconPlus();
    }
  }, [
    context.isOpenDialogTambahHead,
    context.isOpenDialogTambahDeputyHead,
    context.isOpenDialogTambahAssistant,
    context.isOpenDialogTambahStaff,
  ]);

  /* 
        ---------------------------
      |                            |
      | STATE FOR DIALOG COMPONENT |
      |                            |
        ---------------------------
    */
  const handleOpenDialogTambahHead = () => {
    context.setOpenDialogTambahHead(true);
  };

  /*
        ```````````````````````
        HANDLE EDIT CARD POSISI

        ```````````````````````
    */
  const handleEditCardPosisi = () => {
    if (
      context.dataDetail.classification.code ===
      STRUCTURE_POSITION_CLASSIFICATION_ID_TOP_LEADER
    ) {
      context.setOpenDialogEditNodeSOInRootTop(true);
      console.info(
        `Buka modal EDIT NODE SO ${STRUCTURE_POSITION_CLASSIFICATION_ID_TOP_LEADER} !`
      );
    }

    if (
      context.dataDetail.classification.code ===
      STRUCTURE_POSITION_CLASSIFICATION_ID_HEAD
    ) {
      context.setOpenDialogEditNodeSO(true);
      console.info(
        `Buka modal EDIT NODE SO ${STRUCTURE_POSITION_CLASSIFICATION_ID_HEAD}!`
      );
    }

    if (
      context.dataDetail.classification.code ===
      STRUCTURE_POSITION_CLASSIFICATION_ID_DEPUTY_HEAD
    ) {
      context.setOpenDialogEditNodeSOInDeputyHEAD(true);
      console.info(
        `Buka modal EDIT NODE SO ${STRUCTURE_POSITION_CLASSIFICATION_ID_DEPUTY_HEAD} !`
      );
    }

    if (
      context.dataDetail.classification.code ===
      STRUCTURE_POSITION_CLASSIFICATION_ID_ASSISTANT
    ) {
      context.setOpenDialogEditNodeSOInASSISTANT(true);
      console.info(
        `Buka modal EDIT NODE SO ${STRUCTURE_POSITION_CLASSIFICATION_ID_ASSISTANT} !`
      );
    }

    if (
      context.dataDetail.classification.code ===
      STRUCTURE_POSITION_CLASSIFICATION_ID_STAFF
    ) {
      context.setOpenDialogEditNodeSOInSTAFF(true);
      console.info(
        `Buka modal EDIT NODE SO ${STRUCTURE_POSITION_CLASSIFICATION_ID_STAFF} !`
      );
    }
  };

  /*
        `````````````````````````````
        HANDLE ICON BUTTON SUBORDINAT

        `````````````````````````````
    */
  const handleClickSubordinat = (e, data) => {
    // console.log("Data from subordinat : ", data);

    // context.setDataDetail(data)

    if (data.parent_id == null) {
      context.setShowBackButton(false);
    }

    if (data.parent_id !== null) {
      context.setDataDetail(data);

      //*
      localStorage.setItem("parent_so", JSON.stringify(context.treeItems));

      //*
      let parent = undefined;
      parent = { ...data };

      // console.log("Data subordinat choosed : ", data);

      // e.preventDefault();

      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/structure-position?options[filter][parent_id]=${
                data.id
              }&options[filter][classification_id]=${""}`
          )
          // .get(URL_API + `/human-resource/structure-position/${data.id}/with-child?options[filter][parent_id]=${data.parent_id}`)
          .then((response) => {
            // console.log("Response Original from Subordinat : ", response);

            const listChild = [...response.data.data];

            let listAfterSort = listChild.sort((data1, data2) => {
              if (data1.classification_id < data2.classification_id) {
                return -1;
              }

              if (data1.classification_id > data2.classification_id) {
                return 1;
              }

              return 0;
            });

            let parentWithChildren = {
              ...parent,
              child: listAfterSort,
            };

            let listReadyData = [];
            listReadyData.push(parentWithChildren);

            context.setTreeItems(listReadyData);
            context.setShowBackButton(true);
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });

        /*
                        `````````````````````````
                        HANDLE LIST SUB

                    */
      }
    }
  };

  return (
    <Fragment>
      <Paper
        style={{
          marginLeft: "-24px",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: "#f7d9dd",
          borderLeft: "0.4em solid #d64253",
          cursor: "default",
        }}
      >
        {treeItemData.structure_unit_type_name !== null ? (
          <Typography
            variant="subtitle2"
            style={{ marginLeft: 8, fontFamily: "Roboto", color: "red" }}
          >
            <Chip
              //    avatar = {

              //         <Avatar>{treeItemData.classification_name.charAt(0)}</Avatar>
              //    }
              size="small"
              label={
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey", textTransform: "uppercase" }}
                >
                  <b>{treeItemData.classification_name}</b>
                </Typography>
              }
              variant="outlined"
              style={{
                opacity: 0.7,
                marginRight: 8,
                marginTop: 4,
                marginBottom: 4,
              }}
            />

            {treeItemData.structure_unit_type_name +
              " " +
              treeItemData.structure_unit_name}
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            style={{ marginLeft: 8, fontFamily: "Roboto", color: "#edcfd8" }}
          >
            <Chip
              size="small"
              label={
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "grey", textTransform: "uppercase" }}
                >
                  <b>{treeItemData.classification_name}</b>
                </Typography>
              }
              variant="outlined"
              style={{
                opacity: 0.7,
                marginRight: 8,
                marginTop: 4,
                marginBottom: 4,
              }}
            />
            .
          </Typography>
        )}
      </Paper>
      <Paper
        style={{
          marginLeft: "-24px",
          borderLeft: "0.4em solid #d64253",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          padding: 16,
          marginBottom: 16,
          paddingBottom: 0,
          paddingTop: 0,
          cursor: "default",
        }}
      >
        <List>
          <ListItem style={{ paddingBottom: 0, paddingTop: 0 }}>
            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "grey",
                    fontFamily: "Roboto",
                    marginLeft: 16,
                    marginBottom: 20,
                  }}
                >
                  <i>
                    {treeItemData.member_first_name !== null
                      ? treeItemData.member_first_name +
                        " " +
                        treeItemData.member_last_name
                      : "Vacancy"}
                  </i>
                </Typography>
              }
              secondary={
                <Typography
                  variant="subtitle2"
                  style={{
                    fontFamily: "Roboto",
                    marginLeft: 16,
                    marginTop: "-24px",
                  }}
                >
                  {treeItemData.id !== null
                    ? treeItemData.structure_position_title_name
                    : "-"}
                </Typography>
              }
            />
          </ListItem>

          <ListItemSecondaryAction>
            {treeItemData !== undefined && (
              <Fragment>
                {treeItemData.user !== null && treeItemData.user !== undefined && (
                  <Fragment>
                    {treeItemData.user.status.code ===
                      "user_status_id_enum_pending" && (
                      <Button
                        size="small"
                        variant="outlined"
                        className={classes.buttonOutlined}
                        style={{
                          backgroundColor: "#ffd34f", //*yellow
                          padding: 0,
                          borderColor: "none",
                          borderWidth: 0,
                        }}
                      >
                        <Typography
                          variant="caption"
                          className={classes.title}
                          style={{ color: "white", marginLeft: 0 }}
                        >
                          {treeItemData.user.status.name}
                        </Typography>
                      </Button>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}

            <IconButton
              style={{ marginRight: "-32px" }}
              onClick={(e) => handleOpenDropdownIconMoreVert(e, treeItemData)}
            >
              <i className="material-icons">more_vert</i>
            </IconButton>

            <StyledMenu
              id="customized-menu-goal-result"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseDropdownIconMoreVert}
            >
              <StyledMenuItem onClick={handleEditCardPosisi}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" className={classes.title}>
                      <b>Edit</b>
                    </Typography>
                  }
                />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => context.setOpenDialogDeleteNodeSO(true)}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle2" className={classes.title}>
                      <b>Delete</b>
                    </Typography>
                  }
                />
              </StyledMenuItem>
            </StyledMenu>
          </ListItemSecondaryAction>
        </List>
        <Divider />

        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          <Box marginBottom={1}>
            <Typography
              variant="caption"
              style={{ fontFamily: "Roboto", color: "grey" }}
            >
              <i>
                {treeItemData !== undefined
                  ? treeItemData.descendant_count
                  : " 0"}{" "}
                Subordinat{" "}
              </i>

              <IconButton
                size="small"
                onClick={(e) => handleClickSubordinat(e, treeItemData)}
              >
                <ExpandMoreIcon style={{ color: "grey" }} />
              </IconButton>
            </Typography>
          </Box>
        </Grid>
      </Paper>

      <Box marginRight={4}>
        <Grid container direction="row" justify="center" alignItems="center">
          <StyledIconPlusButton
            onClick={(e) => handleOpenDropdownIconPlus(e, treeItemData)}
          >
            <AddCircleIcon style={{ color: "#d64253" }} />
          </StyledIconPlusButton>

          <StyledMenu
            id="customized"
            anchorEl={anchorElIconPlus}
            keepMounted
            open={Boolean(anchorElIconPlus)}
            onClose={handleCloseDropdownIconPlus}
          >
            <StyledMenuItem
              // onClick = { () => setOpenDialogTambahHead(true)}
              onClick={handleOpenDialogTambahHead}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      Tambah <i>Head</i>
                    </b>
                  </Typography>
                }
              />
            </StyledMenuItem>
            <StyledMenuItem
              // onClick ={ (e) => handleDialogDelete(e, item)}
              onClick={() => context.setOpenDialogTambahDeputyHead(true)}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      Tambah <i>Deputy Head</i>
                    </b>
                  </Typography>
                }
              />
            </StyledMenuItem>

            <StyledMenuItem
              onClick={() => context.setOpenDialogTambahAssistant(true)}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      Tambah <i>Assistant</i>
                    </b>
                  </Typography>
                }
              />
            </StyledMenuItem>

            <StyledMenuItem
              // onClick ={ (e) => handleDialogDelete(e, item)}
              onClick={() => context.setOpenDialogTambahStaff(true)}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b>
                      Tambah <i>Staff</i>
                    </b>
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default StyledTreeItemDetail;
