import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styles } from "./Style/StyleAttendanceSetting";
import { navigate } from "hookrouter";

import ContextAttendanceSetting from "./Context/ContextAttendanceSetting";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core";

import ViewTabLocation from "./Components/TabLocation/ViewTabLocation";
import ViewTabBreak from "./Components/TabBreak/ViewTabBreak";
import ViewTabWorkPattern from "./Components/TabWorkPattern/ViewTabWorkPattern";
import ViewTabSchedule from "./Components/TabSchedule/ViewTabSchedule";
import ViewTabScheduleDetail from "./Components/TabScheduleDetail/ViewTabScheduleDetail";
import WorkIcon from "@material-ui/icons/Work";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import UpdateIcon from "@material-ui/icons/Update";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import DialogProgress from "./Components/DialogProgress";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";
import ViewTabShift from "./Components/TabShift/ViewTabShift";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ViewAttendanceSetting = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const [loader, setLoader] = useState(false);

  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      // Work Pattern
      getWorkPatternList();
    } else if (newValue === 1) {
      // Shift
      getShiftList();
    } else if (newValue === 2) {
      // Schedule
      getScheduleList();
    } else if (newValue === 3) {
      // ScheduleDetail
      getScheduleDetailList();
    } else if (newValue === 4) {
      // Location
      getLocationList();
    } else if (newValue === 5) {
      // Break
      getBreakList();
    }
  };

  useEffect(() => {
    getWorkPatternList();
  }, []);

  // work pattern list
  const [dataWorkPatternList, setDataWorkPatternList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
  });
  const getWorkPatternList = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/work-pattern` +
          `?options[filter][search]=${dataWorkPatternList.options.filter.search}` +
          `&options[paging][limit]=${dataWorkPatternList.options.paging.limit}` +
          `&options[paging][offset]=${dataWorkPatternList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataWorkPatternListTemp = dataWorkPatternList;
        dataWorkPatternListTemp.list = response.data.data;
        dataWorkPatternListTemp.options.paging.count = response.data.info.count;
        setDataWorkPatternList({ ...dataWorkPatternListTemp });
        console.log(dataWorkPatternList);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  // location list
  const [dataLocationList, setDataLocationList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
  });
  const getLocationList = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/location-static` +
          `?options[filter][search]=${dataLocationList.options.filter.search}` +
          `&options[paging][limit]=${dataLocationList.options.paging.limit}` +
          `&options[paging][offset]=${dataLocationList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataLocationListTemp = dataLocationList;
        dataLocationListTemp.list = response.data.data;
        dataLocationListTemp.options.paging.count = response.data.info.count;
        setDataLocationList({ ...dataLocationListTemp });
        console.log(dataLocationList);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  // break list
  const [dataBreakList, setDataBreakList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
  });
  const getBreakList = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/breaks` +
          `?options[filter][search]=${dataBreakList.options.filter.search}` +
          `&options[paging][limit]=${dataBreakList.options.paging.limit}` +
          `&options[paging][offset]=${dataBreakList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataBreakListTemp = dataBreakList;
        dataBreakListTemp.list = response.data.data;
        dataBreakListTemp.options.paging.count = response.data.info.count;
        setDataBreakList({ ...dataBreakListTemp });
        console.log(dataBreakList);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  // schedule list
  const [dataScheduleList, setDataScheduleList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
  });
  const getScheduleList = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/schedule-pattern` +
          `?options[filter][search]=${dataScheduleList.options.filter.search}` +
          `&options[paging][limit]=${dataScheduleList.options.paging.limit}` +
          `&options[paging][offset]=${dataScheduleList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataScheduleListTemp = dataScheduleList;
        dataScheduleListTemp.list = response.data.data;
        dataScheduleListTemp.options.paging.count = response.data.info.count;
        setDataScheduleList({ ...dataScheduleListTemp });
        console.log(dataScheduleList);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  // schedule list
  const [dataScheduleDetailList, setDataScheduleDetailList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
  });
  const getScheduleDetailList = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/schedule-pattern-detail` +
          `?options[filter][search]=${dataScheduleDetailList.options.filter.search}` +
          `&options[paging][limit]=${dataScheduleDetailList.options.paging.limit}` +
          `&options[paging][offset]=${dataScheduleDetailList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataScheduleDetailListTemp = dataScheduleDetailList;
        dataScheduleDetailListTemp.list = response.data.data;
        dataScheduleDetailListTemp.options.paging.count =
          response.data.info.count;
        setDataScheduleDetailList({ ...dataScheduleDetailListTemp });
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  // shift list
  const [dataShiftList, setDataShiftList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 0,
      },
    },
    list: [],
  });
  const getShiftList = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/shift` +
          `?options[filter][search]=${dataShiftList.options.filter.search}` +
          `&options[paging][limit]=${dataShiftList.options.paging.limit}` +
          `&options[paging][offset]=${dataShiftList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        let dataShiftListTemp = dataShiftList;
        dataShiftListTemp.list = response.data.data;
        dataShiftListTemp.options.paging.count = response.data.info.count;
        setDataShiftList({ ...dataShiftListTemp });
        console.log(dataShiftList);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        <div className={classes.tabContainer}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab
              id="vertical-tab-work-pattern"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-work-pattern`}
              label={
                <React.Fragment>
                  <WorkIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Work Pattern
                  </span>
                </React.Fragment>
              }
            />
            <Tab
              id="vertical-tab-shift"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-shift`}
              label={
                <React.Fragment>
                  <UpdateIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Shift
                  </span>
                </React.Fragment>
              }
            />
            <Tab
              id="vertical-tab-schedule"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-schedule`}
              label={
                <React.Fragment>
                  <ScheduleIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Schedule
                  </span>
                </React.Fragment>
              }
            />
            <Tab
              id="vertical-tab-schedule-detail"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-schedule`}
              label={
                <React.Fragment>
                  <AddAlarmIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Schedule Detail
                  </span>
                </React.Fragment>
              }
            />
            <Tab
              id="vertical-tab-location"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-location`}
              label={
                <React.Fragment>
                  <LocationOnIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Lokasi
                  </span>
                </React.Fragment>
              }
            />
            <Tab
              id="vertical-tab-break"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-break`}
              label={
                <React.Fragment>
                  <FreeBreakfastIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Break
                  </span>
                </React.Fragment>
              }
            />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            className={classes.tabPanelContainer}
          >
            <ViewTabWorkPattern
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataWorkPatternList={dataWorkPatternList}
              setDataWorkPatternList={setDataWorkPatternList}
              getWorkPatternList={getWorkPatternList}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className={classes.tabPanelContainer}
          >
            <ViewTabShift
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataShiftList={dataShiftList}
              setDataShiftList={setDataShiftList}
              getShiftList={getShiftList}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            className={classes.tabPanelContainer}
          >
            <ViewTabSchedule
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataScheduleList={dataScheduleList}
              setDataScheduleList={setDataScheduleList}
              getScheduleList={getScheduleList}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            className={classes.tabPanelContainer}
          >
            <ViewTabScheduleDetail
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataScheduleDetailList={dataScheduleDetailList}
              setDataScheduleDetailList={setDataScheduleDetailList}
              getScheduleDetailList={getScheduleDetailList}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={4}
            className={classes.tabPanelContainer}
          >
            <ViewTabLocation
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataLocationList={dataLocationList}
              setDataLocationList={setDataLocationList}
              getLocationList={getLocationList}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={5}
            className={classes.tabPanelContainer}
          >
            <ViewTabBreak
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataBreakList={dataBreakList}
              setDataBreakList={setDataBreakList}
              getBreakList={getBreakList}
            />
          </TabPanel>
        </div>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
    </MuiThemeProvider>
  );
};

export default ViewAttendanceSetting;
