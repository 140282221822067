import React, { useContext } from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import TableSection from './table-section';
import ToolbarSection from './toolbar-section';
import Details from './details';
import ContextReports from '../../../context/ContextReports';

const Summary = ({ classes }) => {
  const {
    liveSummary
  } = useContext(ContextReports);

  return (
    <Box>
      <Details />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{backgroundColor: 'white', marginTop: '5px', marginBottom: '5px', padding: '10px'}}>
            <h3>Live Reporting { liveSummary?.options?.defaultPeriod?.name || '' }</h3>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{backgroundColor: 'white', marginTop: '5px', marginBottom: '5px', padding: '10px'}}>
            <ToolbarSection />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableSection classes={classes}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Summary;
