import React, { useContext, useEffect, useState } from 'react';
import { styles } from './Style/StyleAttendanceReport';
import { navigate } from 'hookrouter';

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  Box,
  LinearProgress,
  Tabs,
  Tab
} from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/id';
import Moment from 'react-moment';
import { isEmpty } from 'lodash';

import DialogProgress from '../Account/Components/DialogProgress';

import ContextAttendanceReport from './Context/ContextAttendanceReport';

import { URL_API } from '../../../constants/config-api';
import axiosConfig from '../../../constants/config-axios';

import { isAuthenticated } from '../MyAttendance/Hook/isAuthenticated';

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cc0707', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#262261',
      light: '#6e6ecf',
      dark: '#0f0c29',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: 'Lato, sans-serif'
  }
});

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: '#9B1B2D'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#EC5267'
  }
}))(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box style={{ padding: '0px 25px' }}>{children}</Box>}
    </div>
  );
}

const ViewAttendanceReport = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceReport);
  const userToken = localStorage.getItem('userToken');

  const [loader, setLoader] = useState(false);
  const [verificationMethod, setVerificationMethod] = useState([]);
  const [value, setValue] = useState(0);
  const [data, setData] = useState({ absence: {}, presence: {} });

  const [totalTimeOffAllowance, setTotalTimeOffAllowance] = useState(0);
  const [totalTimeOffTaken, setTotalTimeOffTaken] = useState(0);
  const [totalTimeOffRemaining, setTotalTimeOffRemaining] = useState(0);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    }
    fetchAbsence();
    fetchPresence();
    getTimeOffQuota();
  }, []);

  const fetchAbsence = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/report-account/absence`
      );
      setData((prevValue) => ({ ...prevValue, absence: response.data.data }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const fetchPresence = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/report-account/presence`
      );
      setData((prevValue) => ({ ...prevValue, presence: response.data.data }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const getTimeOffQuota = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/request-timeoff`
      );

      if (response.status === 200) {
        console.log(response.data.data.cuti_list[0].hasUser.allowance);
        setTotalTimeOffAllowance(
          response.data.data.cuti_list[0].hasUser.allowance
        );
        setTotalTimeOffTaken(
          response.data.data.cuti_list[0].hasUser.allowance -
            response.data.data.cuti_list[0].hasUser.remaining
        );
        setTotalTimeOffRemaining(
          response.data.data.cuti_list[0].hasUser.remaining
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCurrentDateNumber = () => {
    let date = new Date();
    return date.getDate();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography
                variant="h5"
                style={{ fontWeight: 'bold', color: '#262261' }}
              >
                Report
              </Typography>

              <div
                style={{
                  position: 'fixed',
                  width: '37%',
                  maxWidth: 'inherit'
                }}
              >
                <Card className={classes.presenceCard}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <div
                      style={{
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        marginBottom: '10px',
                        fontWeight: 'bold'
                      }}
                    >
                      <DateRange />
                      <Typography variant="subtitle1">
                        <b>
                          Absensi per hari ini dibulan{' '}
                          {moment().locale('id').format('MMMM')}{' '}
                        </b>
                      </Typography>
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <img
                          src={require('../../../assets/images/note.png')}
                          alt="attendance"
                          width={75}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Grid
                          container
                          spacing={1}
                          style={{ marginTop: 10, marginBottom: 5 }}
                        >
                          <Grid item xs={9}>
                            <Typography
                              variant="subtitle1"
                              style={{ color: 'white' }}
                            >
                              <>
                                <b>{data.presence.kehadiran}</b>/
                                {getCurrentDateNumber()} Kehadiran
                              </>
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              variant="subtitle1"
                              style={{ color: 'white', textAlign: 'right' }}
                            >
                              {data.presence.kehadiran ? (
                                <b>
                                  {Math.floor(
                                    (data.presence.kehadiran /
                                      getCurrentDateNumber()) *
                                      100
                                  )}
                                  %
                                </b>
                              ) : (
                                <>0%</>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            data.presence.kehadiran
                              ? Math.floor(
                                  (data.presence.kehadiran /
                                    getCurrentDateNumber()) *
                                    100
                                )
                              : 0
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card className={classes.absenceCard}>
                  <CardContent style={{ flex: '1 0 auto' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <div
                          style={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                          }}
                        >
                          <span>Total Jatah Cuti Tahunan</span>
                        </div>
                        <Typography variant="h4" style={{ color: 'white' }}>
                          <b>{totalTimeOffAllowance} Hari</b>
                        </Typography>

                        <div
                          style={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginTop: 10
                          }}
                        >
                          <span>Cuti Tahunan Terpakai</span>
                        </div>
                        <Typography variant="h4" style={{ color: 'white' }}>
                          <b>{totalTimeOffTaken} Hari</b>
                        </Typography>

                        <div
                          style={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            marginTop: 10
                          }}
                        >
                          <span>Sisa Jatah Cuti Tahunan</span>
                        </div>
                        <Typography variant="h4" style={{ color: 'white' }}>
                          <b>{totalTimeOffRemaining} Hari</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          src={require('../../../assets/images/calender.png')}
                          alt="calender"
                          width={200}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <a
                  className={classes.exportButton}
                  href={
                    value === 0
                      ? data.presence.export_url
                      : data.absence.export_url
                  }
                  download={value === 0 ? 'presence.pdf' : 'absence.pdf'}
                  style={{ textDecoration: 'none' }}
                >
                  <img
                    src={require('../../../assets/images/SVG/export.svg')}
                    style={{ width: '1.5em', marginBottom: '-3px' }}
                    alt=""
                  />
                  <Typography
                    variant="h5"
                    style={{
                      color: 'purple',
                      marginBottom: '-4px',
                      fontSize: '1em'
                    }}
                  >
                    <b>
                      EXPORT REPORT DATA {value === 0 ? 'KEHADIRAN' : 'CUTI'}
                    </b>
                  </Typography>
                </a>
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                style={{ fontWeight: 'bold', color: '#262261' }}
              >
                Report Detail
              </Typography>

              <Box sx={{ width: '100%' }}>
                <Box>
                  <Tabs
                    style={{ textColor: 'grey' }}
                    textColor="primary"
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    aria-label="report-tab"
                    variant="fullWidth"
                    TabIndicatorProps={{
                      style: {
                        borderBottom: `3px solid purple`,
                        borderRadius: '20px'
                      }
                    }}
                  >
                    <Tab label="Kehadiran" />
                    <Tab label="Cuti" />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                      margin: '20px 0px'
                    }}
                  >
                    {data.presence.list?.map((item, index) => (
                      <Card
                        style={{
                          padding: '10px',
                          color: '#221E5B',
                          borderRadius: '10px'
                        }}
                        key={index}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              gap: '10px',
                              marginBottom: '10px',
                              fontWeight: 'bold'
                            }}
                          >
                            <DateRange />
                            <Typography variant="h6">
                              <b>{item.tanggal}</b>
                            </Typography>
                          </div>
                          <Typography
                            variant="subtitle1"
                            style={{ color: 'grey' }}
                          >
                            Pukul : {item.pukul}
                          </Typography>
                          <Typography variant="subtitle2">
                            <b>Lokasi : {item.lokasi}</b>
                          </Typography>
                          <Typography variant="subtitle2">
                            <b>Status : Hadir</b>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                      margin: '20px 0px'
                    }}
                  >
                    {data.absence.list?.map((item) => (
                      <Card
                        style={{
                          padding: '10px',
                          color: '#221E5B',
                          borderRadius: '10px'
                        }}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              gap: '10px',
                              marginBottom: '10px',
                              fontWeight: 'bold'
                            }}
                          >
                            <DateRange />
                            <Typography variant="h6">
                              <b>{item.tanggal}</b>
                            </Typography>
                          </div>
                          <Typography
                            variant="subtitle1"
                            style={{ color: 'grey' }}
                          >
                            Pukul : {item.pukul}
                          </Typography>
                          <Typography variant="subtitle2">
                            <b>Status : {item.status}</b>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </div>

        <DialogProgress
          classes={classes}
          isModalProgress={loader}
          setModalProgress={setLoader}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewAttendanceReport;
