import React, { useState, useMemo } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";

import MicRoundedIcon from "@material-ui/icons/MicRounded";
import StopRoundedIcon from "@material-ui/icons/StopRounded";

import MicRecorder from "mic-recorder-to-mp3";

const DialogAudio = ({
  classes,
  files,
  setFiles,
  openVoiceRecorder,
  setOpenVoiceRecorder,
}) => {
  // New instance
  const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);

  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mp3File, setMp3File] = useState(null);

  const startRecording = () => {
    recorder
      .start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) => {
        // console.log("ERROR", e);
      });
  };

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        // // console.log("buffer", buffer);
        // // console.log("blob", blob);

        const file = new File(buffer, `${fileName}.mp3`, {
          // type: blob.type,
          type: "audio/mpeg",
          name: fileName,
          lastModified: Date.now(),
        });

        const player = new Audio(URL.createObjectURL(file));
        player.controls = true;
        // player.play();

        const span = document.createElement("span");
        span.classList = "audio";
        span.appendChild(player);
        document.querySelector("#playlist").appendChild(span);

        // setMp3Player("done");
        setMp3File(file);
        setIsRecording(false);
      })
      .catch((e) => {
        // console.log("ERROR", e);
      });
  };

  const handleClear = () => {
    const parent = document.querySelector("#playlist");
    const child = document.querySelector(".audio");
    if (parent.hasChildNodes()) {
      document.querySelector("#playlist").removeChild(child);
    }
    // mp3Player(null);
    setMp3File(null);
    setIsRecording(false);
    setFileName("");
  };

  const handleAudioOK = () => {
    setFiles([...files, mp3File]);

    setOpenVoiceRecorder(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={openVoiceRecorder}
      onClose={() => setOpenVoiceRecorder(false)}
    >
      <DialogTitle
        style={{
          color: "#fff",
          backgroundSize: "cover",
          backgroundColor: "#282560",
        }}
      >
        <b>Voice Recorder</b>
      </DialogTitle>

      <TextField
        fullWidth
        size="small"
        variant="standard"
        placeholder="Input audio title..."
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        InputProps={{
          style: {
            color: "#fff",
            background: "#282560",
            padding: "20px 25px 10px",
          },
          disableUnderline: true,
          endAdornment: ".mp3",
        }}
      />

      <Box
        bgcolor="#282560"
        height={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box mt={2} px={2} mb={1}>
          <div id="playlist"></div>
        </Box>

        {!isRecording && (
          <IconButton onClick={startRecording} style={{ background: "#fff" }}>
            <MicRoundedIcon style={{ color: "#d1354a" }} />
          </IconButton>
        )}

        {isRecording && (
          <IconButton onClick={stopRecording} style={{ background: "#fff" }}>
            <StopRoundedIcon style={{ color: "#d1354a" }} />
          </IconButton>
        )}

        <Box my={0.5} />
        <Typography variant="caption" style={{ color: "#fff" }}>
          {isRecording ? "Stop" : "Record"}
        </Typography>
      </Box>

      <Box
        bgcolor="#282560"
        pt={5}
        pb={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="outlined"
          size="small"
          disabled={loading || mp3File === null}
          onClick={handleClear}
          className={classes.uploadBtn}
        >
          Clear
        </Button>
        <Box mx={0.5} />
        <Button
          variant="outlined"
          size="small"
          disabled={loading || mp3File === null}
          onClick={handleAudioOK}
          className={classes.uploadBtn}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  );
};

export default DialogAudio;
