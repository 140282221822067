import React from 'react';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';

const ButtonMaHaveConnectedWithGoal = props => {

    const { classes } = props;

    return (

        <Button 
            variant='outlined' 
            size='small' 
            className={classes.connectMAToGoal}
            // onClick={handleConnectGoalToggle}
        >        
            <LinkIcon style={{marginRight: 8 }}/>
            <b><i> Measured Activity</i> ini sudah di hubungkan dengan Goal</b>
        </Button>        
    )
};

export default ButtonMaHaveConnectedWithGoal;