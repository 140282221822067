import React, { useEffect, useContext } from "react";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { Grid, Typography, Box, Button, Container } from "@material-ui/core";

import ContextAllMA from "./context/AllMaContext";
import { theme, styles } from "./style/StyleAllMA";

import ViewAll from "./All/ViewAll";
import DialogCreate from "./components/DialogCreate";
import DialogExportAllMA from "./components/DialogExportAllMA";
import Snackbeer from "../../../components/Snackbeer";

const useStyles = makeStyles(styles);

function ViewAllMA() {
  const context = useContext(ContextAllMA);
  const classes = useStyles();

  // Handle Switch Tab (All & You)
  const handleChooseTab = (e, data) => {
    //   Remove Value Search in Local Storage
    localStorage.removeItem("value_search");
    context.setDataTabActive(data);
  };

  // useEffect(() => {
  //   const getMeasuredActivity = async () => {
  //     try {
  //       const response = await axiosConfig.get(
  //         `${URL_API}/swd/measured-activity/create`
  //       );
  //       console.log(response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getMeasuredActivity();
  // }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        bgcolor="#fafafa"
        position="fixed"
        zIndex={110}
        width="100%"
        pb={3}
      ></Box>

      <Box ml={7} marginTop={theme.spacing(0.1)}>
        <ViewAll />
      </Box>

      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        userLoginName={context.userLoginName}
        memberPositionList={context.memberPositionList}
        setCollectionMAList={context.setCollectionMAList}
        collectionMAList={context.collectionMAList}
      />

      <Snackbeer
        setModalResponse200={context.setModalResponse200}
        isModalResponse200={context.isModalResponse200}
        messages={context.messages}
      />

      <DialogExportAllMA
        isModalExportMA={context.isModalExportMA}
        setModalExportMA={context.setModalExportMA}
      />
      {/* </Container> */}
    </MuiThemeProvider>
  );
}

export default ViewAllMA;
