import React from "react";
import { Grid } from "@material-ui/core";

import CircleGoalDetailInPercentNEW from "../../../../components/CircleGoalDetailInPercentNEW";
import CircleGoalDetailForExsponent from "../../../../components/CircleGoalDetailForExsponent";

const CircleProgressORA = (props) => {
  const { classes, isExponentOra, goalDetailState } = props;

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <div className={classes.circularProgressBarInMoreDetail}>
        {isExponentOra === true ? (
          <CircleGoalDetailForExsponent
            classes={classes}
            goalDetailState={goalDetailState}
          />
        ) : (
          <CircleGoalDetailInPercentNEW
            classes={classes}
            goalDetailState={goalDetailState}
          />
        )}
      </div>
    </Grid>
  );
};

export default CircleProgressORA;
