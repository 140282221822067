import React from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

import DateFnsUtils from "@date-io/date-fns";

const ListTimeRange = ({
  classes,
  item,
  index,
  lastIdx,
  onSelectedTimes,
  onAddTime,
  onRemoveTime,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          margin="normal"
          size="small"
          name="start"
          value={item.start}
          onChange={(e) => onSelectedTimes(index, "start", e)}
          keyboardIcon={<WatchLaterIcon style={{ fontSize: 17 }} />}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
          style={{ width: 150 }}
        />
      </MuiPickersUtilsProvider>

      <Typography
        variant="subtitle1"
        className={classes.title}
        style={{ margin: "0 20px" }}
      >
        <b>to</b>
      </Typography>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          margin="normal"
          size="small"
          name="finish"
          value={item.finish}
          onChange={(e) => onSelectedTimes(index, "finish", e)}
          keyboardIcon={<WatchLaterIcon style={{ fontSize: 17 }} />}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
          style={{ width: 150 }}
        />
      </MuiPickersUtilsProvider>

      {lastIdx === index && (
        <IconButton
          size="small"
          style={{ marginLeft: 7.5 }}
          onClick={onAddTime}
        >
          <AddCircleOutlineIcon style={{ fontSize: 20, color: "#282560" }} />
        </IconButton>
      )}

      {/* {index !== 0 && lastIdx === index && ( */}
      <IconButton
        size="small"
        style={{ marginLeft: 7.5 }}
        onClick={() => onRemoveTime(index)}
      >
        <RemoveCircleOutlineIcon style={{ fontSize: 20, color: "#282560" }} />
      </IconButton>
      {/* )} */}
    </Box>
  );
};

export default ListTimeRange;
