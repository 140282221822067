import React from "react";
import { Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { title } from "../../Style/styleKIt";

import supportFree from "../../../../assets/images/support-free.png";
import supportSeminar from "../../../../assets/images/support-seminar.png";
import supportGuide from "../../../../assets/images/support-guide.png";
import supportContact from "../../../../assets/images/support-contact.png";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  titleLink: {
    ...title,
    textDecoration: "none",
    color: "#555",
    "&:hover": {
      color: "#10266F",
      cursor: "pointer",
    },
  },
});

const useStyle = makeStyles(styles);

const FastSolution = () => {
  const classes = useStyle();

  return (
    <Box py={15} bgcolor="#F2F4F7">
      <Container>
        <Box mb={10} display="flex" justifyContent="center">
          <Typography variant="h4" className={classes.title333}>
            <b>Solusi Cepat</b>
          </Typography>
        </Box>
        <Grid container direction="row" spacing={4}>
          {listFastSolution.map((item, i) => (
            <Grid key={i} item xs={12} sm={12} md={3}>
              <Paper elevation={2}>
                <Box
                  p={3}
                  display="flex"
                  flexDirection="column"
                  alignItems="center">
                  <img src={item.icon} alt={item.label} height={75} />
                  <Box mt={3}>
                    <Typography varian="subtitle1" className={classes.title333}>
                      <b>{item.label}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FastSolution;

const listFastSolution = [
  {
    label: "Coba Gratis",
    icon: supportFree,
  },
  {
    label: "Ikut Seminar",
    icon: supportSeminar,
  },
  {
    label: "Panduan",
    icon: supportGuide,
  },
  {
    label: "hubungi Kami",
    icon: supportContact,
  },
];
