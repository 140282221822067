/*
    `````````````````````````````````````````````
    NOW, 05 Desember 2019, 13.30. 
    
    Term "Employee" di ubah menjadi "User"
    
    `````````````````````````````````````````````
*/

import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  IconButton,
  Divider,
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import axios from "axios";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import ContextNewSO from "../Context/ContextNewSO";

import DialogError from "../../../components/DialogError";

import { styles } from "../Style/StyleDialog";
import { VISIBILITY_DISABLED } from "../Constants/config-classification-so-master";
import { URL_API } from "../../../constants/config-api";

/*
    ````````````````````````````````````````````
    GLOBAL STATE : 

        context : {

            dataDetail : String,
            userTokenState: String,
            setOpenDialogTambahHead: Boolean,
            isOpenDialogEditNodeSOInRootTop: Boolean,
            setOpenDialogEditNodeSOInRootTop : Boolean
            
        };

    ````````````````````````````````````````````    
*/

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogEditNodeSoClassificationTopLeader = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  // const [ userTokenState, setUserTokenState ] = useState('');

  const [listJenisUnit, setListJenisUnit] = useState([]);
  const [listNamaUnit, setListNamaUnit] = useState([]);

  const [listPositionTitle, setListPositionTitle] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);

  const [updatedAt, setUpdatedAt] = useState("");
  const [isDisabledStructureUnitType, setDisabledStructureUnitType] =
    useState(false);
  const [isDisabledStructureUnit, setDisabledStructureUnit] = useState(false);
  const [isDisabledStructurePositionTitle, setDisabledStructurePositionTitle] =
    useState(false);
  const [isDisabledEmployee, setDisabledEmployee] = useState(false);

  useEffect(() => {
    if (context.isOpenDialogEditNodeSOInRootTop == true) {
      context.setTreeItems([
        {
          id: null,
          parent_id: null,
          structure_position_title_id: null,
          structure_position_title_name: null,

          structure_unit_id: null,
          structure_unit_name: null,

          structure_unit_type_id: null,
          structure_unit_type_name: null,

          member_first_name: null,
          member_last_name: null,
          self: null,

          classification: {
            id: null,
          },

          child: [],
        },
      ]);

      /*
                ``````````````````````````````````````````````
                if "STRUCTURE_POSITION_CLASSIFICATION_ID_HEAD"

                ``````````````````````````````````````````````
            */

      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        /*
                    ````````````````````````````````````````````````````
                    if "STRUCTURE_POSITION_CLASSIFICATION_ID_TOP_LEADER"

                    ````````````````````````````````````````````````````
                */
        axios
          .get(URL_API + `/human-resource/structure-position/root/update`)
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data.updated_at !== null) {
                setUpdatedAt(response.data.data.updated_at);
              }

              // if(response.data.data.fields.structure_unit_type_id.visibility == VISIBILITY_DISABLED){

              //     setDisabledStructureUnitType(true);
              // };

              if (
                response.data.data.fields.user_id.visibility ==
                VISIBILITY_DISABLED
              ) {
                setDisabledEmployee(true);
              }

              if (
                response.data.data.fields.structure_position_title_id
                  .visibility == VISIBILITY_DISABLED
              ) {
                setDisabledStructurePositionTitle(true);
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }
    }
  }, [context.isOpenDialogEditNodeSOInRootTop]);

  /*  
        `````````````````````````````````````````````````````````````````````````````````
           HANDLE DELETE EMPLOYEE MEMBER && HANDLE DROPDOWN EDIT & DELETE EMPLOYEE MEMBER

        `````````````````````````````````````````````````````````````````````````````````
    */

  //    const [ isOpenDialogDeleteMemberEmployee, setOpenDialogDeleteMemberEmployee ] = useState(false);
  //    const [ isSuccessDeleteMemberEmployee, setSuccessDeleteMemberEmployee ] = useState(false);

  const [anchorElMemberEmployee, setAnchorElMemberEmployee] = useState(null);

  const handleOpenDropdownEditAndDeleteMemberEmployee = (e, data) => {
    setAnchorElMemberEmployee(e.currentTarget);
    // console.log("Data from 'more_horizon' Member Employee : ", data);

    context.setDataDetail(data);
  };

  const handleCloseDropdownEditAndDeleteMemberEmployee = () => {
    setAnchorElMemberEmployee(null);
  };

  /*
        ```````
        EXPAND

        ```````
    */
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  useEffect(() => {
    if (
      expanded !== "" ||
      context.isSuccessCreatePositionTitle == true ||
      context.isSuccessEditMemberEmployee == true

      // context.firstName !== ''
      // context.valueJenisUnitName !== ''
      // context.namaUnitChoosed !== ''
    ) {
      let data = {
        structure_unit_type_id: "",
      };

      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/structure-position/create?classification=1&options[filter][structure_unit_type_id]=${data.structure_unit_type_id}`
          )
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (
                response.data.data.structurePositionTitleCollections !== null
              ) {
                if (
                  response.data.data.structurePositionTitleCollections !==
                  undefined
                ) {
                  if (
                    response.data.data.structurePositionTitleCollections
                      .length > 0
                  ) {
                    setListPositionTitle(
                      response.data.data.structurePositionTitleCollections
                    );
                  }
                }
              }

              if (response.data.data.userCollections !== null) {
                if (response.data.data.userCollections !== undefined) {
                  if (response.data.data.userCollections.length > 0) {
                    setListEmployee(response.data.data.userCollections);
                  }
                }
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }

      //*
      handleCloseDropdownEditAndDeleteMemberEmployee();
    }
  }, [
    expanded,
    context.isSuccessCreatePositionTitle,
    context.isSuccessEditMemberEmployee,
  ]); //, context.firstName, context.valueJenisUnitName, context.namaUnitChoosed

  /*
        ````````````````````````````
        DIALOG EDIT POSITION TITLE

        ````````````````````````````
    */
  // const [ isOpenDialogCreatePositionTitle, setOpenDialogCreatePositionTitle ] = useState(false); //*Moved to Global State

  const handleTambahJabatan = () => {
    context.setOpenDialogCreatePositionTitleInEditRootTop(true);
    context.setOpenDialogEditNodeSOInRootTop(false);
  };

  const handleChoosePositionTitle = (e, data) => {
    e.preventDefault();

    // console.log("Wew Position Title : ", data);
    context.setPositionTitleId(data.id);
    context.setPositionTitleName(data.name);
    context.setPositionTitleChoose(true);

    //*Set next panel
    setExpanded("panel2");
    handleChange("panel2");
  };

  /*
        ````````````````````
        DIALOG EDIT KARYAWAN

        ````````````````````
    */
  const handleChooseEmployee = (e, data) => {
    e.preventDefault();

    // console.log("Wew Employee : ", data);
    context.setEmployeeId(data.id);
    context.setFirstName(data.member?.first_name);
    context.setLastName(data.member?.last_name);

    //*Set next panel
    setExpanded("panelX");
    handleChange("panelX");
  };
  /*
        ````````````````````
        HANDLE DIALOG ERROR

        ````````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [listError, setListError] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);

  const [loader, seLoader] = useState(false);

  const handleSimpanPerubahanHead = () => {
    //*// console.log("goalResultNameDescription : ", goalResultNameDescription);

    let data = {
      StructurePosition: {
        updated_at: updatedAt,
        structure_position_title_id:
          context.positionTitleId !== ""
            ? context.positionTitleId
            : context.dataDetailIconMoreHorizon.structure_position_title_id,
        user_id:
          context.employeeId !== ""
            ? context.employeeId
            : context.dataDetailIconMoreHorizon.user_id,
      },
    };

    // console.log("Data : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .put(URL_API + `/human-resource/structure-position/root?`, data)
        .then(function (response) {
          // console.log("Response Original : ", response);
          context.setOpenDialogEditNodeSOInRootTop(false);
          context.setOpenDialogTambahHead(false);
        })
        .catch(function (error) {
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              if (error.response.data.info.errors !== null) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              // setTextErrorInformation("Internal server error !")
              setOpenDialogError(true);
            }

            if (error.response.status == 422) {
              setErrorStatus(422);
              if (error.response.data.info.errors !== null) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                  // if(error.response.data.info.errors[0].code === "HAS_CHILD"){
                  //     setTextErrorInformation(error.response.data.info.errors[0].description)
                  //     setOpenDialogError(true);
                  // };
                }
              }
            }

            if (error.response.status == 404) {
              setErrorStatus(404);
              setTextErrorInformation(
                "Error 404 : Whoops, something went wrong !"
              );
              setOpenDialogError(true);
            }
          } else {
            setTextErrorInformation("Whoops, something went wrong !");
            setOpenDialogError(true);

            // context.setOpenDialogEditNodeSOInRootTop(false);
            // context.setOpenDialogTambahHead(false);
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogEditNodeSOInRootTop}
        onClose={() => context.setOpenDialogEditNodeSOInRootTop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Edit <i>Posisi</i>
            </b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <div className={classes.root}>
            {/* 
                        ``````````````````
                        EDIT NAMA JABATAN

                        ``````````````````
                    */}
            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              disabled={
                isDisabledStructureUnitType == true ||
                isDisabledStructurePositionTitle == true ||
                isDisabledEmployee == true
                  ? true
                  : false
                // context.dataDetail.structure_unit_type_name == null
              }
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <List style={{ padding: 0 }} className={classes.root}>
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <IconButton size="small">
                      {expanded !== "panel1" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>

                    <ListItemText
                      id="ubah-jabatan"
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {context.isPositionTitleChoosed == true ? (
                            <Fragment>
                              <b>Terpilih {context.positionTitleName} </b>
                              <IconButton size="small">
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 14 }}
                                />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>
                              {context.dataDetailIconMoreHorizon
                                .structure_position_title_name !== null ? (
                                <b>
                                  Jabatan ({" "}
                                  <i>
                                    {
                                      context.dataDetailIconMoreHorizon
                                        .structure_position_title_name
                                    }{" "}
                                  </i>{" "}
                                  )
                                </b>
                              ) : (
                                "-"
                              )}
                            </Fragment>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#d64253" }}
                      >
                        Ubah Jabatan
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <div
                  className={classes.columnSeratusPersen}
                  style={{ textAlign: "left" }}
                >
                  {listPositionTitle.length == 0 && (
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "red" }}
                    >
                      <IconButton size="small" style={{ marginBottom: 1 }}>
                        <ErrorIcon style={{ color: "red" }} />
                      </IconButton>
                      <b>
                        Belum ada <i>Nama Jabatan</i>
                      </b>
                    </Typography>
                  )}

                  {listPositionTitle.length > 0 &&
                    listPositionTitle.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          onClick={(e) => handleChoosePositionTitle(e, item)}
                          variant="outlined"
                          className={classes.buttonOutlined}
                        >
                          <b>{item.name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}
                </div>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  onClick={handleTambahJabatan}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp;<i>Jabatan</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>

            {/* 
                        `````````
                        EDIT USER

                        `````````
                    */}
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              disabled={
                isDisabledStructureUnitType == true ||
                // context.dataDetail.structure_unit_type_name == null
                isDisabledStructurePositionTitle == true ||
                isDisabledEmployee == true
                  ? true
                  : false
              }
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-control
                s="panel1bh-content"
                id="panel1bh-header"
              >
                <List style={{ padding: 0 }} className={classes.root}>
                  <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <IconButton size="small">
                      {expanded !== "panel2" ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </IconButton>

                    <ListItemText
                      id="ubah-user"
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          {context.firstName !== "" ? (
                            <Fragment>
                              <b>
                                Terpilih {context.firstName + " "}{" "}
                                {context.lastName}
                              </b>
                              <IconButton size="small">
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 14 }}
                                />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <b>
                                <i>
                                  {context.dataDetailIconMoreHorizon
                                    .member_first_name !== null
                                    ? context.dataDetailIconMoreHorizon
                                        .member_first_name +
                                      " " +
                                      context.dataDetailIconMoreHorizon
                                        .member_last_name
                                    : "-"}
                                </i>
                              </b>
                            </Fragment>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: "#d64253" }}
                      >
                        Ubah
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <List className={classes.rootList}>
                  {listEmployee.length > 0 &&
                    listEmployee.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem
                          button
                          onClick={(e) => handleChooseEmployee(e, item)}
                          style={{ padding: 0 }}
                        >
                          <IconButton>
                            <AccountCircleIcon fontSize="large" />
                          </IconButton>

                          <ListItemText
                            id="label-employee"
                            primary={
                              // <Button
                              //     onClick={(e) => handleChooseEmployee(e, item)}
                              //     variant='outlined'
                              //     className={classes.buttonOutlined}
                              // >
                              //     <b>{item.member.first_name + " "}  {item.member.last_name}</b>
                              // </Button>
                              <Typography
                                variant="subtitle1"
                                className={classes.title}
                              >
                                <b>
                                  {item.member?.first_name + " "}{" "}
                                  {item.member?.last_name}
                                </b>
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                                style={{ color: "grey" }}
                              >
                                {item.email}
                              </Typography>
                            }
                          />

                          <ListItemSecondaryAction>
                            <Button
                              size="small"
                              variant="outlined"
                              className={classes.buttonOutlined}
                              style={
                                item.status.code ===
                                "user_status_id_enum_pending"
                                  ? {
                                      backgroundColor: "#ffd34f", //*yellow
                                      padding: 0,
                                    }
                                  : {
                                      backgroundColor: "#96ff96", //*green
                                      padding: 0,
                                    }
                              }
                            >
                              <Typography
                                variant="caption"
                                className={classes.title}
                                style={{ color: "white", marginLeft: 0 }}
                              >
                                {item.status.name}
                              </Typography>
                            </Button>
                          </ListItemSecondaryAction>

                          {/* <ListItemSecondaryAction>
                                                        <IconButton 
                                                            size='small'
                                                            onClick={(e) => handleOpenDropdownEditAndDeleteMemberEmployee(e, item)}
                                                        >
                                                            <MoreHorizIcon/>
                                                        </IconButton>
                                                            <StyledMenu
                                                                id="customized-menu-goal-result"
                                                                anchorEl={anchorElMemberEmployee} //*Position Title
                                                                keepMounted
                                                                open={Boolean(anchorElMemberEmployee)}
                                                                onClose={handleCloseDropdownEditAndDeleteMemberEmployee}
                                                            >
                                                                    <StyledMenuItem 
                                                                        onClick= {() => context.setOpenDialogEditMemberEmployeeInEditRootTOP(true)}
                                                                    >                         
                                                                        <ListItemText 
                                                                            
                                                                            primary = {
                                                                                <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                                    <IconButton  size='small'>
                                                                                        <EditIcon style={{color: 'green'}} />
                                                                                    </IconButton>
                                                                                    <b>Edit</b>
                                                                                </Typography>
                                                                            }  
                                                                        />

                                                                    </StyledMenuItem>
                                                                    <StyledMenuItem
                                                                        onClick= {() => context.setOpenDialogDeleteEmployeeMember(true)}
                                                                    >                         
                                                                        <ListItemText 
                                                                            primary = {

                                                                                <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                                    <IconButton size='small'>
                                                                                        <DeleteForeverIcon style={{color: 'red'}} />
                                                                                    </IconButton>
                                                                                    <b>Delete</b>
                                                                                </Typography>
                                                                            }  
                                                                        />
                                                                    </StyledMenuItem>
                                                                </StyledMenu>

                                                            </ListItemSecondaryAction> */}
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}

                  {listEmployee.length == 0 && (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        id="label-employee"
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: "red" }}
                          >
                            <IconButton
                              size="small"
                              style={{ marginBottom: 1 }}
                            >
                              <ErrorIcon style={{ color: "red" }} />
                            </IconButton>
                            <b>
                              Belum ada <i>User</i>
                            </b>
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  onClick={() => {
                    handleChange("panelX");
                    setExpanded("panelX");

                    context.setFirstName("Vacant");
                    context.setLastName("");
                    context.setEmployeeId(null);
                  }}
                  variant="outlined"
                  size="small"
                  className={classes.buttonOutlined}
                >
                  <i style={{ color: "black" }}>Set as vacant</i>
                </Button>

                <Button
                  onClick={() => {
                    context.setOpenDialogEditNodeSOInRootTop(false);
                    context.setOpenDialogCreateEmployeeInEditRootTOP(true);
                  }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah User
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </div>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleSimpanPerubahanHead}
            variant="contained"
            className={classes.buttonDialog}
          >
            {loader !== true ? (
              "Simpan perubahan"
            ) : (
              <CircularProgress size={24} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        listError={listError}
        errorStatus={errorStatus}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogEditNodeSoClassificationTopLeader);
