import React from "react";
import { Snackbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const styles = () => ({
  title: {
    fontFamily: "Lato",
  },
});

const useStyles = makeStyles(styles);

const SnackbarError = ({
  isOpenDialogError,
  setOpenDialogError,
  textErrorInformation,
  errorStatus,
  listError,
  objError,
}) => {
  const errList =
    listError?.length > 0 &&
    listError?.map((err) => err.description).join(", ");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenDialogError(false);
  };

  return (
    <Snackbar
      open={isOpenDialogError}
      message={errList}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};

export default SnackbarError;
