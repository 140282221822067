import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Switch,
  Chip,
  Checkbox,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import moment from 'moment';
import classNames from 'classnames';
import MomentUtils from '@date-io/moment';

import axiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';
import DialogError from '../../../../components/DialogError';
import handleError from '../../../Report/components/global/handleError';
import ContextProject from '../../context/ContextProject';

import icon_high from '../../../../assets/images/SVG/icon_high.svg';
import icon_medium from '../../../../assets/images/SVG/icon_medium.svg';
import icon_low from '../../../../assets/images/SVG/icon_low.svg';
import SnackbarError from '../../../../components/SnackbarError';
import TruncateTextCustom from '../../../../utilities/TruncateTextCustom';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const DialogCreateTask = ({
  classes,
  openCreateTask,
  setOpenCreateTask,
}) => {
  const { setTriggerTask } = useContext(ContextProject);
  const project_id = window.location.pathname.split('/')[2];
  const projects = JSON.parse(
    localStorage.getItem('project_details')
  );

  const [todo, setTodo] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(projects?.end_date);
  const [duration, setDuration] = useState(1);
  const [target, setTarget] = useState(0);
  const [status, setStatus] = useState('TO DO');
  const [ownerId, setOwnerId] = useState('');
  const [priority, setPriority] = useState('LOW');
  const [remarks, setRemarks] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [taskGroupId, setTaskGroupId] = useState('');
  const [notifStartDate, setNotifStartDate] = useState('');
  const [customPhaseId, setCustomPhaseId] = useState('');

  //collection
  const [priorities, setPriorities] = useState([]);
  const [statusCollections, setStatusCollections] = useState([]);
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [predecessorList, setPredecessorList] = useState([]);
  const [taskGroupList, setTaskGroupList] = useState([]);
  const [phaseCollection, setPhaseCollection] = useState([]);
  const [selected, setSelected] = useState([]);

  // Conditional
  const [loading, setLoading] = useState(false);
  const [loadCreate, setLoadCreate] = useState(false);
  const [loadDuration, setLoadDuration] = useState(false);
  const [lessMore, setLessMore] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [status403, setStatus403] = useState(false);
  const [anchor, setAnchor] = useState(null);
  // ERROR STATE
  const [isOpenSnackbarError, setOpenSnackbarError] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  useEffect(() => {
    if (openCreateTask) {
      setLoadCreate(true);

      axiosConfig
        .get(`${URL_API}/todolist/create?project_id=${project_id}`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setPriorities(result.priorityCollections);
            setStatusCollections(result.statusCollections);
            setOwnerCollections(result.ownerCollections);
            setPredecessorList(result.predecessor);
          }

          setLoadCreate(false);
        })
        .catch((error) => {
          // console.log("error", error);
          setLoadCreate(false);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setStatus403(true);
            }
          }

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });

      axiosConfig
        .get(`${URL_API}/project/${project_id}/task-group`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setTaskGroupList(result);
          }
        })
        .catch((error) => {
          // console.log("error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });

      axiosConfig
        .get(`${URL_API}/project/${project_id}/custom-phase`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setPhaseCollection(result);
          }
        })
        .catch((error) => {
          // console.log("error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openCreateTask]);

  useEffect(() => {
    axiosConfig
      .get(
        `${URL_API}/project/general/get-duration?start_date=${moment(
          startDate
        ).format('YYYY-MM-DD')}&end_date=${moment(endDate).format(
          'YYYY-MM-DD'
        )}&exclude_weekend=${projects?.project?.exclude_weekend}`
      )
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setDuration(result.duration);
        }
        // setLoadDuration(false);
      })
      .catch((err) => {
        // console.log("error", err);
        // setLoadDuration(false);
      });
  }, []);

  const handleDuration = (e) => {
    const durationVal = Number(e.target.value);
    setDuration(durationVal);

    axiosConfig
      .get(
        `${URL_API}/project/general/get-due-date?start_date=${moment(
          startDate
        ).format('YYYY-MM-DD')}&duration=${
          durationVal + 1
        }&exclude_weekend=${projects?.project?.exclude_weekend}`
      )
      .then((res) => {
        const result = res.data.data;

        // // console.log("SET DUED", result);

        if (res.status === 200) {
          setEndDate(result.due_date);
        }
        setLoadDuration(false);
      })
      .catch((err) => {
        // console.log("error", err);
        setLoadDuration(false);
      });
  };

  const handleStartDate = (date) => {
    setStartDate(date);

    // const isAfter = moment(date).isAfter(endDate, "days");

    // if (isAfter) {
    //   setEndDate(moment(date).add(1, "days"));
    // } else {
    axiosConfig
      .get(
        `${URL_API}/project/general/get-due-date?start_date=${moment(
          date
        ).format(
          'YYYY-MM-DD'
        )}&duration=${duration}&exclude_weekend=${
          projects?.project?.exclude_weekend
        }`
      )
      .then((res) => {
        const result = res.data.data;

        // // console.log("SET DUED", result);

        if (res.status === 200) {
          setEndDate(result.due_date);
        }
        setLoadDuration(false);
      })
      .catch((err) => {
        // console.log("error", err);
        setLoadDuration(false);
      });
    // }
  };

  const handleEndDate = (date) => {
    setEndDate(date);

    axiosConfig
      .get(
        `${URL_API}/project/general/get-duration?start_date=${moment(
          startDate
        ).format('YYYY-MM-DD')}&end_date=${moment(date).format(
          'YYYY-MM-DD'
        )}&exclude_weekend=${projects?.project?.exclude_weekend}`
      )
      .then((res) => {
        const result = res.data.data;

        // // console.log("SET DUED", result);

        if (res.status === 200) {
          setDuration(result.duration);
        }
        // setLoadDuration(false);
      })
      .catch((err) => {
        // console.log("error", err);
        // setLoadDuration(false);
      });
  };

  const handleStatus = (e) => setStatus(e.target.value);
  const handlePriority = (e) => setPriority(e.target.value);
  const handleTaskGroup = (e) => setTaskGroupId(e.target.value);
  const handleCustomPhase = (e) => setCustomPhaseId(e.target.value);

  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setOwnerId(data.id);
    }
  };

  const filterPedecessor = predecessorList
    ?.filter((item) => selected?.some((el) => el.id === item.id))
    ?.reduce((a, b) => (a.due_date > b.due_date ? a : b), []);

  const checkDueDate = moment(startDate).diff(
    moment(filterPedecessor?.due_date),
    'days'
  );

  useEffect(() => {
    if (filterPedecessor?.length !== 0 && checkDueDate < 1) {
      const getStartDate = moment(filterPedecessor?.due_date)
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss');

      setStartDate(getStartDate);
      setNotifStartDate(
        'Start date disesuaikan dengan due date dependency'
      );

      axiosConfig
        .get(
          `${URL_API}/project/general/get-due-date?start_date=${moment(
            getStartDate
          ).format(
            'YYYY-MM-DD'
          )}&duration=${duration}&exclude_weekend=${
            projects?.project?.exclude_weekend
          }`
        )
        .then((res) => {
          const result = res.data.data;

          // // console.log("SET DUED", result);

          if (res.status === 200) {
            setEndDate(result.due_date);
          }
        })
        .catch((err) => {
          // console.log("error", err);
        });
    } else {
      setNotifStartDate('');
    }
  }, [filterPedecessor]);

  const handleShow = (event) => {
    if (anchor && anchor.contains(event.target)) {
      setAnchor(null);
    } else {
      setAnchor(event.currentTarget);
    }
  };

  const handleCloseAway = (event) => {
    if (anchor.contains(event.target)) {
      return;
    }
    setAnchor(null);
  };

  const handleChooseDependency = (e, data) => {
    // // console.log("DEP DAta", data);
    if (data !== null) {
      setSelected((prev) => [...prev, data]);
    }
  };

  const handleDeleteDependency = (id) => {
    setSelected((prev) => prev.filter((el) => el.id !== id));
  };

  const handleCloseCreateTask = () => {
    setOpenCreateTask(false);

    setTodo('');
    setStartDate(new Date());
    setEndDate(projects.end_date);
    setTarget(0);
    setStatus('TO DO');
    setOwnerId('');
    setPriority('LOW');
    setRemarks('');
    setDescription('');
    setTags('');
    setSelected([]);
    setTaskGroupId('');
  };

  const handleAddTodo = () => {
    setLoading(true);

    const predecessor_id =
      selected.length > 0 ? selected.map((item) => item.id) : [];

    const data = {
      Todolist: {
        title: todo,
        target_result: target,
        start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        due_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        owner_id: ownerId,
        status,
        priority,
        remarks,
        description,
        tags,
        project_id,
        // actual_result: "0",
        predecessor_id,
        task_group_id: taskGroupId,
        custom_phase_id: customPhaseId,
      },
    };

    // // console.log("DADA", data);

    axiosConfig
      .post(`${URL_API}/todolist`, data)
      .then((res) => {
        if (res.status === 200) {
          setOpenCreateTask(false);
          setLoading(false);

          setTriggerTask(Math.floor(Math.random() * 100));

          setTodo('');
          setStartDate(new Date());
          setEndDate(projects.end_date);
          setTarget(0);
          setStatus('TO DO');
          setOwnerId('');
          setPriority('LOW');
          setRemarks('');
          setDescription('');
          setTags('');
          setSelected([]);
          setTaskGroupId('');
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error.response);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);

        const errorStartDate =
          listError.length > 0
            ? listError.filter((el) => el.key === 'start_date')
            : [];

        // Error State
        if (errorStartDate?.length > 0) {
          setOpenSnackbarError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        } else {
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        }
      });
  };

  return (
    <Dialog
      open={openCreateTask}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}
      >
        <Typography variant="h6" className={classes.title333}>
          <b>Create Task</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box p={2}>
          <Typography
            variant="subtitle2"
            className={classes.title333}
          >
            Title
          </Typography>
          <Box my={0.5} />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Add a title"
            onChange={(e) => setTodo(e.target.value)}
            disabled={status403}
          />

          <Box my={2} />

          <Typography
            variant="subtitle2"
            className={classes.title333}
          >
            Description
          </Typography>
          <Box my={0.5} />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="What is your task description"
            onChange={(e) => setDescription(e.target.value)}
            disabled={status403}
          />

          <Box my={2} />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography
                variant="subtitle2"
                className={classes.title333}
              >
                Owner
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                id="combo-box-demo"
                options={ownerCollections}
                getOptionLabel={(option) =>
                  `${option.member.first_name} ${option.member.last_name}`
                }
                onChange={(e, owner) => handleChooseOwner(e, owner)}
                noOptionsText="Tidak ada owner"
                popupIcon={
                  loadCreate ? (
                    <CircularProgress
                      style={{ color: '#C8203C' }}
                      size={20}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{ color: '#C8203C' }}
                    />
                  )
                }
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    label="Select owner"
                    variant="outlined"
                    size="small"
                  />
                )}
                disabled={status403}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="subtitle2"
                className={classes.title333}
              >
                Group To
              </Typography>
              <Box my={0.5} />
              <Select
                variant="outlined"
                fullWidth
                placeholder="Select status"
                value={taskGroupId}
                style={{ height: 40 }}
                onChange={handleTaskGroup}
                IconComponent={KeyboardArrowDownIcon}
                inputProps={{
                  classes: {
                    icon: classes.iconSelect,
                  },
                }}
              >
                {taskGroupList.length > 0 ? (
                  taskGroupList.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        <Typography
                          variant="subtitle2"
                          className={classes.title555}
                          style={{ marginLeft: 8 }}
                        >
                          {item.name}
                        </Typography>
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>Task Group is empty</MenuItem>
                )}
              </Select>
            </Grid>
          </Grid>

          <Box my={2} />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography
                variant="subtitle2"
                className={classes.title333}
              >
                Start Date
              </Typography>
              <Box my={0.5} />
              <MuiPickersUtilsProvider
                utils={LocalizedUtils}
                locale="id"
              >
                <DatePicker
                  autoOk
                  value={startDate}
                  onChange={handleStartDate}
                  animateYearScrolling
                  variant="inline" // dialog, static, inline
                  disableToolbar={false}
                  format="DD MMMM YYYY"
                  placeholder="Pick your end date"
                  inputVariant="outlined"
                  size="small"
                  error={isOpenSnackbarError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small">
                          <EventAvailableIcon
                            style={{ color: '#C8203C' }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={status403}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  className={classes.title333}
                >
                  {toggle ? (
                    'Due Date'
                  ) : (
                    <span>
                      Set Duration{' '}
                      <span style={{ fontSize: 10 }}>
                        {projects?.project?.exclude_weekend === 1
                          ? '(exclude weekend)'
                          : '(include weekend)'}
                      </span>
                    </span>
                  )}
                </Typography>
                <Switch
                  size="small"
                  checked={toggle}
                  onChange={(e) => setToggle(e.target.checked)}
                />
              </Box>
              <Box my={0.5} />
              {toggle ? (
                <MuiPickersUtilsProvider
                  utils={LocalizedUtils}
                  locale="id"
                >
                  <DatePicker
                    autoOk
                    value={endDate}
                    onChange={handleEndDate}
                    animateYearScrolling
                    variant="inline" // dialog, static, inline
                    disableToolbar={false}
                    format="DD MMMM YYYY"
                    placeholder="Pick your end date"
                    inputVariant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small">
                            <EventAvailableIcon
                              style={{ color: '#C8203C' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled={status403}
                  />
                </MuiPickersUtilsProvider>
              ) : (
                <TextField
                  type="number"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={duration}
                  onChange={handleDuration}
                  error={duration <= 0}
                  helperText={
                    duration <= 0 &&
                    'duration tidak boleh kurang dari 1'
                  }
                  disabled={status403}
                />
              )}
            </Grid>
          </Grid>

          <Box my={1} />

          {!lessMore && (
            <Box mt={4} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button2}
                onClick={() => setLessMore(true)}
                disabled={status403}
              >
                Show More
              </Button>
            </Box>
          )}

          {lessMore && (
            <>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                  >
                    Priority
                  </Typography>
                  <Box my={0.5} />
                  <Select
                    variant="outlined"
                    fullWidth
                    placeholder="Select priority"
                    value={priority}
                    style={{ height: 40 }}
                    onChange={handlePriority}
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{
                      classes: {
                        icon: classes.iconSelect,
                      },
                    }}
                  >
                    {priorities.length > 0 ? (
                      priorities.map((item, i) => {
                        let icon;

                        switch (item && item.id) {
                          case 'HIGH':
                            icon = icon_high;
                            break;
                          case 'MEDIUM':
                            icon = icon_medium;
                            break;
                          case 'LOW':
                            icon = icon_low;
                            break;
                          default:
                            icon = null;
                        }

                        return (
                          <MenuItem key={i} value={item.id}>
                            <Box display="flex" alignItems="center">
                              <img src={icon} height={16} />
                              <Typography
                                variant="subtitle2"
                                className={classes.title555}
                                style={{ marginLeft: 8 }}
                              >
                                {item.name}
                              </Typography>
                            </Box>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Priority is empty</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                  >
                    Status
                  </Typography>
                  <Box my={0.5} />
                  <Select
                    variant="outlined"
                    fullWidth
                    placeholder="Select status"
                    value={status}
                    style={{ height: 40 }}
                    onChange={handleStatus}
                    IconComponent={KeyboardArrowDownIcon}
                    disabled={projects?.status_id === '2'}
                    inputProps={{
                      classes: {
                        icon: classes.iconSelect,
                      },
                    }}
                  >
                    {statusCollections.length > 0 ? (
                      statusCollections.map((status, i) => {
                        let bgColor;
                        let color;

                        switch (status.id) {
                          case 'TO DO':
                            bgColor = '#DFDFDF';
                            color = '#777777';
                            break;
                          case 'DOING':
                            bgColor = '#CFEAF1';
                            color = '#4194AC';
                            break;
                          case 'DONE':
                            bgColor = '#D9EBDD';
                            color = '#1E874D';
                            break;
                          case 'ON HOLD':
                            bgColor = '#F6EDC3';
                            color = '#B68812';
                            break;
                          case 'WAITING':
                            bgColor = '#E2DAE5';
                            color = '#92739F';
                            break;
                          default:
                            bgColor = '#555555';
                            color = '#fff';
                        }

                        return (
                          <MenuItem key={i} value={status.id}>
                            <Typography
                              variant="subtitle2"
                              className={classes.title555}
                              style={{
                                color,
                                backgroundColor: bgColor,
                                fontSize: 12,
                                textAlign: 'center',
                                borderRadius: 15,
                                padding: '3px 7px',
                                width: 75,
                              }}
                            >
                              {status.name}
                            </Typography>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Status is empty</MenuItem>
                    )}
                  </Select>
                </Grid>
              </Grid>

              <Box my={1} />

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                  >
                    Target
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="insert amount of target"
                    onChange={(e) => setTarget(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                  >
                    Tags
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    size="small"
                    fullWidth
                    onChange={(e) => setTags(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Box my={1.5} />

              <Typography
                variant="subtitle2"
                className={classes.title333}
              >
                Notes
              </Typography>
              <Box my={0.5} />
              <TextField
                type="text"
                size="small"
                variant="outlined"
                placeholder="What is your task notes"
                fullWidth
                multiline
                onChange={(e) => setRemarks(e.target.value)}
              />

              <Box my={1.5} />

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                  >
                    Phase
                  </Typography>
                  <Box my={0.5} />
                  <Select
                    variant="outlined"
                    fullWidth
                    placeholder="Select status"
                    value={customPhaseId}
                    style={{ height: 40 }}
                    onChange={handleCustomPhase}
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{
                      classes: {
                        icon: classes.iconSelect,
                      },
                    }}
                  >
                    {phaseCollection.length > 0 ? (
                      phaseCollection.map((item, i) => {
                        return (
                          <MenuItem key={i} value={item.id}>
                            <Typography
                              variant="subtitle2"
                              className={classes.title555}
                              style={{ marginLeft: 8 }}
                            >
                              {item.title}
                            </Typography>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Custom phase is empty</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                  >
                    Task Dependency
                  </Typography>
                  <Box my={0.5} />
                  <Box
                    width="100%"
                    minHeight={40}
                    border="1.5px solid #C4C4C4"
                    borderRadius={5}
                    padding={1}
                    display="flex"
                    justifyContent={
                      selected.length === 0
                        ? 'flex-end'
                        : 'space-between'
                    }
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexWrap="wrap"
                    >
                      {selected.length > 0 &&
                        selected.map((item, i) => (
                          <Chip
                            key={i}
                            label={TruncateTextCustom(item.title, 20)}
                            size="small"
                            style={{ margin: '0 5px 5px 0' }}
                            onDelete={() =>
                              handleDeleteDependency(item.id)
                            }
                          />
                        ))}
                    </Box>
                    <IconButton size="small" onClick={handleShow}>
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Box>

                  <Popper
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className={classNames({
                      [classes.popperClose]: !anchor,
                      [classes.popperResponsive]: true,
                    })}
                  >
                    {({ placement }) => (
                      <Grow
                        in={Boolean(anchor)}
                        id="menu-list"
                        tyle={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom',
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={handleCloseAway}
                        >
                          <Paper className={classes.dropdown}>
                            <Box p={1.5} width={400}>
                              <Typography
                                variant="subtitle2"
                                className={classes.title333}
                              >
                                Dependencies
                              </Typography>
                              <Box my={1} />
                              <Autocomplete
                                id="combo-box-demo"
                                options={predecessorList}
                                getOptionLabel={(option) =>
                                  `${option.title}`
                                }
                                onChange={(e, owner) =>
                                  handleChooseDependency(e, owner)
                                }
                                noOptionsText="Tidak ada dependency"
                                popupIcon={
                                  <KeyboardArrowDownIcon
                                    style={{ color: '#C8203C' }}
                                  />
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Search"
                                    variant="outlined"
                                    size="small"
                                  />
                                )}
                              />
                            </Box>
                          </Paper>
                        </ClickAwayListener>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>

              <Box mt={4} display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button2}
                  onClick={() => setLessMore(false)}
                >
                  Show Less
                </Button>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            <Typography
              variant="caption"
              style={{ color: '#ffa502' }}
            >
              {notifStartDate}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              size="small"
              varian="outlined"
              className={classes.button}
              onClick={handleCloseCreateTask}
            >
              Cancel
            </Button>
            <Box mx={1} />
            <Button
              size="small"
              variant={
                loading || status403 ? 'contained' : 'outlined'
              }
              className={classes.button1}
              disabled={loading || status403}
              // disabled
              onClick={handleAddTodo}
            >
              {loading ? 'Creating Task...' : 'Create'}
            </Button>
          </Box>
        </Box>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenSnackbarError}
        setOpenDialogError={setOpenSnackbarError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogCreateTask;
