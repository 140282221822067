import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import FolderIcon from "@material-ui/icons/Folder";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import BlockIcon from "@material-ui/icons/Block";
import WorkRoundedIcon from "@material-ui/icons/WorkRounded";

import axiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";

import ContextGlobalDrive from "../context/ContextDrive";
import FormatBytes from "../../../utilities/FormatBytes";
import {
  SUBJECT_CATEGORY_ID_MEMBER_UNIT,
  SUBJECT_CATEGORY_ID_MEMBER_USER,
} from "../../../constants/config-drive";

function SidebarSection({ classes }) {
  const context = useContext(ContextGlobalDrive);
  const pathName = window.location.pathname;

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const pathArray = pathName.split("/");
    const getLastPathArr = pathArray[pathArray.length - 1];

    if (getLastPathArr !== "drive") {
      setLoading(true);
      // context.setIdFolder(getLastPathArr);
      // // console.log("getLastPathArr", getLastPathArr);

      axiosConfig
        .get(
          `${URL_API}/drive/folder/${getLastPathArr}?options[embedded][]=withAncestorPermission&options[embedded][]=policy&options[embedded][]=owner`
        )
        .then((response) => {
          // console.log("Detail Folder", response);

          if (response.status === 200) {
            context.setListMemberUser(
              response.data.data.withAncestorPermission.filter(
                (item) =>
                  item.subject_category_id === SUBJECT_CATEGORY_ID_MEMBER_USER
              )
            );
            context.setListMemberUnit(
              response.data.data.withAncestorPermission.filter(
                (item) =>
                  item.subject_category_id === SUBJECT_CATEGORY_ID_MEMBER_UNIT
              )
            );

            context.setDetailFolder(response.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);
        });
    }
  }, [pathName, context.successTriggerPermission]);

  useEffect(() => {
    const pathArray = pathName.split("/");
    const getLastPathArr = pathArray[pathArray.length - 1];

    if (getLastPathArr !== undefined) {
      setLoading(true);

      axiosConfig
        .get(`${URL_API}/drive/file`)
        .then((response) => {
          if (response.status === 200) {
            // total size
            const driveSize = response.data.data.reduce((acc, curr) => {
              return acc + Number(curr.size);
            }, 0);

            // latest modified date
            const latestDate = response.data.data
              .filter((item) => item.modified_at !== null)
              .map((item) => item.modified_at)
              .sort()
              .reverse()[0];

            context.setTotalSize(driveSize);
            context.setLatestModifiedDate(latestDate);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);
        });
    }
  }, [pathName, context.successTriggerFile, context.successTriggerFolder]);

  // // console.log("DET FOLSZ", context.detailFolder.name);

  return (
    <div
      style={{
        margin: `20px 30px`,
      }}
    >
      <Box display="flex" alignItems="center">
        <Avatar>
          <FolderIcon />
        </Avatar>

        <Typography
          variant="subtitle2"
          style={{
            marginLeft: 15,
            color: "#333",
          }}
        >
          <b>
            {pathName !== "/drive" ? context.detailFolder.name : "My Drive"}
          </b>
        </Typography>
      </Box>

      {/* Date Modified & Storage Size */}
      <Box display="flex" justifyContent="space-between" marginTop={3}>
        {/* Date Modiifed */}
        <Box>
          <Typography variant="subtitle1" className={classes.titleSidebar}>
            Date Modified
          </Typography>

          {loading ? (
            <CircularProgress size={12} />
          ) : (
            <Typography variant="subtitle2" className={classes.textSidebar}>
              {pathName !== "/drive"
                ? moment(context.detailFolder.modified_at).format(
                    "DD MMM YYYY, HH:mm"
                  )
                : moment(context.latestModifiedDate).format(
                    "DD MMM YYYY, HH:mm"
                  )}
            </Typography>
          )}
        </Box>

        <div className={classes.dateStorageDivider} />

        {/* Sorage Size */}
        <Box>
          <Typography variant="subtitle1" className={classes.titleSidebar}>
            Size
          </Typography>

          {loading ? (
            <CircularProgress size={12} />
          ) : (
            <Typography variant="subtitle2" className={classes.textSidebar}>
              {pathName !== "/drive"
                ? FormatBytes(Number(context.detailFolder.size))
                : FormatBytes(Number(context.totalSize))}
            </Typography>
          )}
        </Box>
      </Box>

      {/* Owner avatar + name */}
      <Box display="flex" alignItems="center" marginTop={2}>
        <AccountCircleRoundedIcon fontSize="small" style={{ color: "#ccc" }} />

        {pathName !== "/drive" ? (
          <Typography variant="subtitle2" className={classes.textOwner}>
            {context.detailFolder.owner &&
              `${context.detailFolder.owner.member.first_name} ${context.detailFolder.owner.member.last_name}`}
            {/* Aceng */}
          </Typography>
        ) : (
          <Typography variant="subtitle2" className={classes.textOwner}>
            -
          </Typography>
        )}
      </Box>

      {/* Member User List */}
      <Box marginTop={4}>
        {pathName !== "/drive" && (
          <Typography variant="subtitle1" className={classes.titleAdmin}>
            <b>Member User</b>
          </Typography>
        )}

        {loading ? (
          <CircularProgress size={14} />
        ) : pathName !== "/drive" && context.listMemberUser.length > 0 ? (
          context.listMemberUser.map((item, i) => (
            <Box display="flex" alignItems="center" marginTop={1} key={i}>
              <Avatar style={{ backgroundColor: item.color }}>
                {item.subject !== null ? (
                  item.subject.member.first_name.substring(0, 1)
                ) : (
                  <BlockIcon fontSize="small" />
                )}
              </Avatar>

              <Tooltip
                title={
                  context.detailFolder.id === item.object_id ? "" : "Inherited"
                }
              >
                <Typography
                  variant="subtitle2"
                  className={classes.textAdmin}
                  style={{
                    color:
                      context.detailFolder.id !== item.object_id ||
                      item.subject === null
                        ? "#999"
                        : "#333",
                  }}
                >
                  {item.subject !== null
                    ? `${item.subject.member.first_name} ${
                        item.subject.member.last_name
                      } ${
                        context.detailFolder.id === item.object_id ? "" : "*"
                      }`
                    : "User has been deleted"}
                </Typography>
              </Tooltip>
            </Box>
          ))
        ) : (
          "-"
        )}
      </Box>

      {/* Member Unit List */}
      <Box marginTop={4}>
        {pathName !== "/drive" && (
          <Typography variant="subtitle1" className={classes.titleAdmin}>
            <b>Member Unit</b>
          </Typography>
        )}

        {loading ? (
          <CircularProgress size={14} />
        ) : pathName !== "/drive" && context.listMemberUnit.length > 0 ? (
          context.listMemberUnit.map((item, i) => (
            <Box display="flex" alignItems="center" marginTop={1} key={i}>
              <Avatar style={{ backgroundColor: item.color }}>
                {item.subject !== null ? (
                  <WorkRoundedIcon fontSize="small" />
                ) : (
                  <BlockIcon fontSize="small" />
                )}
              </Avatar>

              <Tooltip
                title={
                  context.detailFolder.id === item.object_id ? "" : "Inherited"
                }
              >
                <Typography
                  variant="subtitle2"
                  className={classes.textAdmin}
                  style={{
                    color:
                      context.detailFolder.id !== item.object_id ||
                      item.subject === null
                        ? "#999"
                        : "#333",
                  }}
                >
                  {item.subject !== null
                    ? `${item.subject.structure_unit_type_name} - ${
                        item.subject.structure_unit_name
                      } ${
                        context.detailFolder.id === item.object_id ? "" : "*"
                      }`
                    : "Unit has been deleted"}
                </Typography>
              </Tooltip>
            </Box>
          ))
        ) : (
          "-"
        )}
      </Box>
    </div>
  );
}

export default SidebarSection;
