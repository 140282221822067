import React, { forwardRef, useContext, useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Grid,
  List,
  CircularProgress,
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FolderIcon from "@material-ui/icons/Folder";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";

import moment from "moment";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import FormatBytes from "../../../../utilities/FormatBytes";
import ContextGlobalDrive from "../../context/ContextDrive";
import DialogSetPermission2 from "./DialogSetPermission2";
import {
  SUBJECT_CATEGORY_ID_MEMBER_UNIT,
  SUBJECT_CATEGORY_ID_MEMBER_USER,
} from "../../../../constants/config-drive";
import ListMemberUser from "./ListMemberUser";
import ListMemberUnit from "./ListMemberUnit";
import DialogSetPermissionUnit2 from "./DialogSetPermissionUnit2";

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogFolderSetting2({
  item,
  classes,
  openDialogFolderSetting,
  setOpenDialogFolderSetting,
}) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [openDialogSetPermission, setOpenDialogSetPermission] = useState(false);
  const [openDialogSetPermissionUnit2, setOpenDialogSetPermissionUnit2] =
    useState(false);
  const [loading, setLoading] = useState(false);

  /* ====================================================
    Life Cycle
  ==================================================== */

  useEffect(() => {
    if (openDialogFolderSetting) {
      setLoading(true);
      // context.setIdFolder(getLastPathArr);
      // // console.log("getLastPathArr", getLastPathArr);

      axiosConfig
        .get(
          `${URL_API}/drive/folder/${item.id}?&options[embedded][]=withAncestorPermission`
        )
        .then((response) => {
          if (response.status === 200) {
            context.setListMemberUser(
              response.data.data.withAncestorPermission.filter(
                (item) =>
                  item.subject_category_id === SUBJECT_CATEGORY_ID_MEMBER_USER
              )
            );
            context.setListMemberUnit(
              response.data.data.withAncestorPermission.filter(
                (item) =>
                  item.subject_category_id === SUBJECT_CATEGORY_ID_MEMBER_UNIT
              )
            );

            context.setDetailFolder(response.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);
        });
    }
  }, [openDialogFolderSetting, context.successTriggerPermission]);

  return (
    <Dialog
      fullScreen
      open={openDialogFolderSetting}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}
      >
        <Toolbar>
          <Button
            className={classes.backButton}
            onClick={() => setOpenDialogFolderSetting(false)}
            startIcon={<NavigateBeforeIcon style={{ color: "#888" }} />}
          >
            Back
          </Button>
        </Toolbar>
      </AppBar>

      <Box marginLeft={8} marginBottom={2}>
        <Typography
          variant="h6"
          className={classes.title}
          style={{ color: "#333" }}
        >
          <b>Folder Settings</b>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" marginLeft={8} marginBottom={4}>
        <Avatar
          style={{
            width: 34,
            height: 34,
          }}
        >
          <FolderIcon style={{ fontSize: 22 }} />
        </Avatar>

        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "#333", marginLeft: 15 }}
        >
          <b>{item.name}</b>
        </Typography>
      </Box>

      <Box marginLeft={8} marginRight={8}>
        <Divider />
      </Box>

      <Box
        marginLeft={8}
        marginTop={4}
        marginBottom={4}
        display="flex"
        justifyContent="space-between"
        width={350}
      >
        {/* Date Modiifed */}
        <Box>
          <Typography variant="subtitle1" className={classes.titleSidebar}>
            Date Modified
          </Typography>

          <Typography variant="subtitle2" className={classes.textSidebar}>
            {item !== undefined
              ? moment(item.modified_at).format("DD MMM YYYY, HH:mm")
              : moment(context.latestModifiedDate).format("DD MMM YYYY, HH:mm")}
          </Typography>
        </Box>

        <div className={classes.dateStorageDivider} />

        {/* Sorage Size */}
        <Box>
          <Typography variant="subtitle1" className={classes.titleSidebar}>
            Size
          </Typography>

          <Typography variant="subtitle2" className={classes.textSidebar}>
            {item !== undefined
              ? FormatBytes(Number(item.size))
              : FormatBytes(Number(context.totalSize))}
          </Typography>
        </Box>
      </Box>

      <Box marginLeft={8} marginRight={8}>
        <Divider />
      </Box>

      <Box marginLeft={8} marginTop={4} width={800}>
        <Grid container>
          <Grid item md={6}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#333", marginRight: 5 }}
              >
                <b>Member User</b>
              </Typography>

              <IconButton
                size="small"
                onClick={() => setOpenDialogSetPermission(true)}
              >
                <AddCircleOutlineRoundedIcon
                  style={{ color: "#d1354a" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>

            <Box width={300}>
              {loading ? (
                <CircularProgress
                  size={16}
                  style={{ color: "#d1354a", marginTop: 15 }}
                />
              ) : (
                <List style={{ marginLeft: -15 }}>
                  {context.listMemberUser.length > 0 &&
                    context.listMemberUser.map((user, i) => (
                      <ListMemberUser key={i} item={user} classes={classes} />
                    ))}
                </List>
              )}
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#333", marginRight: 5 }}
              >
                <b>Member Unit</b>
              </Typography>

              <IconButton
                size="small"
                onClick={() => setOpenDialogSetPermissionUnit2(true)}
              >
                <AddCircleOutlineRoundedIcon
                  style={{ color: "#d1354a" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>

            <Box width={350}>
              {loading ? (
                <CircularProgress
                  size={16}
                  style={{ color: "#d1354a", marginTop: 15 }}
                />
              ) : (
                <List style={{ marginLeft: -15 }}>
                  {context.listMemberUnit.length > 0 &&
                    context.listMemberUnit.map((unit, i) => (
                      <ListMemberUnit key={i} item={unit} classes={classes} />
                    ))}
                </List>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <DialogSetPermission2
        item={item}
        classes={classes}
        openDialogSetPermission={openDialogSetPermission}
        setOpenDialogSetPermission={setOpenDialogSetPermission}
      />

      <DialogSetPermissionUnit2
        item={item}
        classes={classes}
        openDialogSetPermissionUnit2={openDialogSetPermissionUnit2}
        setOpenDialogSetPermissionUnit2={setOpenDialogSetPermissionUnit2}
      />
    </Dialog>
  );
}

export default DialogFolderSetting2;
