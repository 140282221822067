import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Card,
} from "@material-ui/core";

import * as d3 from "d3";
import Tree from "react-hierarchy-tree-graph";

import PieHooks from "./ComponentOrganigram/PieHooks";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

import OrgChart from "react-orgchart";
// import 'react-orgchart/index.css';
import "./Style/style_org_chart.css";
// import './Style/index.css';
import { log } from "util";

const svgSquare = {
  shape: "rect",
  shapeProps: {
    width: 72,
    height: 30,
    x: -10,
    y: -10,
  },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    // width: '503px',
    // height: '42px',s
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "white",
    textTransform: "capitalize",
  },
  title: {
    fontFamily: "Roboto",
  },
  card: {
    width: 300,
    height: 50,
  },

  // orgChart: {
  //     marginTop: theme.spacing(7)
  // }
});

const ViewOrganigram = (props) => {
  const { classes } = props;

  const MyNodeComponent = ({ node }) => {
    // console.log("node : ", node);

    return (
      <Card style={{ padding: 15 }}>
        <div
          className="initechNode"
          onClick={() => alert("Hi my real name is: " + node.actor)}
        >
          {node.name}
          <p>CEO</p>
        </div>
      </Card>
    );
  };

  /*

        `````````
        PIE HOOKS

        `````````
    */
  const generateData = (value, length = 5) =>
    d3.range(length).map((item, index) => ({
      date: index,
      value:
        value === null || value === undefined ? Math.random() * 100 : value,
    }));

  const [data, setData] = useState(generateData(0));

  const changeData = () => {
    setData(generateData());
  };

  useEffect(() => {
    setData(generateData());
  }, [!data]);

  /*
        ````````
        

        ````````
    */

  return (
    <Container>
      {/* <PinchZoomPan position='center'> */}
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center", height: 1000 }}>
          <br />
          <br />
          <br />
          <br />
          {/* <Card> */}
          <OrgChart
            className={classes.orgChart}
            tree={initechOrg}
            NodeComponent={MyNodeComponent}
          />
          {/* </Card>   */}
        </Grid>
      </Grid>
      {/* </PinchZoomPan> */}
    </Container>
  );
};

export default withStyles(styles)(ViewOrganigram);

/*
    ``````````````````````````
    SIMPLE ORG CHART FOR REACT

    ``````````````````````````

*/

const initechOrg = {
  name: "Bill Lumbergh",
  actor: "Gary Cole",
  children: [
    {
      name: "Peter Gibbons",
      actor: "Ron Livingston",
      children: [
        {
          name: "And More!!",
          actor:
            "This is just to show how to build a complex tree with multiple levels of children. Enjoy!",
        },
      ],
    },
    {
      name: "Milton Waddams",
      actor: "Stephen Root",
    },
    {
      name: "Bob Slydell",
      actor: "John C. McGi...",
    },
  ],
};

/*
    ````````````````
    REACT ORG GOOGLE CHART
    
    ````````````````
*/

// const datas =   [

//     ['Name', 'Manager', 'ToolTip'],

//     [
//         {
//             v: 'Mike',
//             f: 'Mike<div style="color:red; font-style:italic">President</div>',
//         },
//         '',
//         'The President',
//     ],
//     [
//         {
//             v: 'Jim',
//             f:'Jim<div style="color:red; font-style:italic">Vice President</div>',
//         },
//         'Mike',
//         'VP',
//     ],
//     ['Alice', 'Mike', ''],
//     ['Bob', 'Jim', 'Bob Sponge'],
//     ['Carol', 'Bob', ''],

// ];

/* <Typography variant='subtitle1' className={classes.title}>
    <PieHooks
        data={data}
        width={200}
        height={200}
        innerRadius={60}
        outerRadius={100}
    />
</Typography> 
<Tree 
    data={myTreeData2} 
    nodeSvgShape={svgSquare}
    orientation='vertical'
/> 

    <Chart
    width={'100%'}
    height={350}
    chartType="OrgChart"
    loader={<div>Loading Chart</div>}
    data={datas}
    options={{
        allowHtml: true,
    }}
    rootProps={{ 'data-testid': '1' }}
/> */
