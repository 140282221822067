import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Grid,
  CircularProgress,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  InputBase,
} from "@material-ui/core";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import DateRangeIcon from "@material-ui/icons/DateRange";

import moment from "moment";

import PercentagesSlider from "react-percentages-slider";
// import "react-percentages-slider/dist/index.css";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import DialogError from "../../../../../components/DialogError";
import handleError from "../../../../Report/components/global/handleError";
import ListItemPeriod from "./ListItemPeriod";
import ContextGoal from "../../Context/ContextGoal";
import ListItemUser from "./ListItemUser";

const DialogSetWeightGoal2 = ({
  classes,
  openDialogSetWeightGoal,
  setOpenDialogSetWeightGoal,
}) => {
  const context = useContext(ContextGoal);

  const [loading, setLoading] = useState(false);
  const [loadWeight, setLoadWeight] = useState(false);
  const [loadUser, setLoadUser] = useState(false);
  const [anchorPeriod, setAnchorPeriod] = useState(null);
  const [user, setUser] = useState(varUser);
  const [period, setPeriod] = useState(varPeriod);
  const [search, setSearch] = useState("");
  const [collectionUsers, setCollectionUsers] = useState([]);
  const [collectionPeriod, setCollectionPeriod] = useState([]);
  const [collectionGoal, setCollectionGoal] = useState([]);
  const [triggerPeriod, setTriggerPeriod] = useState(null);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogSetWeightGoal) {
      setLoadUser(true);

      Axios.get(`${URL_API}/swd/goal/weighting/owner-list`)
        .then((res) => {
          const result = res.data.data;
          // // console.log("RES, URS COLL", result);

          if (res.status === 200) {
            setCollectionUsers(result);
            localStorage.setItem("weight_users", JSON.stringify(result));
          }
          setLoadUser(false);
        })
        .catch((error) => {
          setLoadUser(false);
          // console.log("ERROR", error);
        });
    }
  }, [openDialogSetWeightGoal]);

  useEffect(() => {
    if (user.id !== "") {
      setLoadWeight(true);

      Axios.get(`${URL_API}/swd/master-period`)
        .then((response) => {
          const periods = response.data.data;
          const lastPeriod = periods.length > 0 && periods[periods.length - 1];
          const periodId = lastPeriod.id !== undefined ? lastPeriod.id : "";
          const selectedPeriod =
            periods.length > 0 &&
            periods.filter((el) => el.selected === true)[0];

          if (response.status === 200) {
            Axios.get(
              `${URL_API}/swd/goal/weighting/${user.id}?period_id=${
                period.id === "" ? periodId : period.id
              }`
            )
              .then((res) => {
                const result = res.data.data;
                // // console.log("RES Gg", result);
                const periodList = result.fields.period_id.default_value;
                const goalList = result.fields.goal_id.default_value;
                const goalLength = goalList.length;

                if (period.id === "") {
                  setPeriod(
                    selectedPeriod !== false
                      ? selectedPeriod
                      : lastPeriod === false
                      ? varPeriod
                      : lastPeriod
                  );
                }

                if (goalLength === 0) {
                  setMaxWidth("lg");
                } else if (goalLength > 0 && goalLength <= 8) {
                  setMaxWidth("lg");
                  setFullWidth(true);
                } else if (goalLength > 8 && goalLength <= 15) {
                  setMaxWidth("xl");
                } else if (goalLength > 15) {
                  setMaxWidth("xl");
                  setFullWidth(true);
                }

                // FOR MAKE SURE 100
                const to100 = (arr) => {
                  let lists = arr.map((list) => (list === null ? 0 : list));

                  if (lists.reduce((acc, curr) => acc + curr, 0) <= 100) {
                    lists[lists.length - 1] += Math.abs(
                      100 - lists.reduce((acc, curr) => acc + curr, 0)
                    );
                  } else if (
                    lists.reduce((acc, curr) => acc + curr, 0) >= 100
                  ) {
                    lists[lists.length - 1] -= Math.abs(
                      100 - lists.reduce((acc, curr) => acc + curr, 0)
                    );
                  }

                  return lists;
                };

                // CONDITION TO 100
                const toaverage = (arr) => {
                  const lists = arr.map((list) => Number(list.weighting));
                  const origin = lists.map((ori) => (ori === null ? 0 : ori));
                  const is0 = origin.reduce((acc, curr) => acc + curr, 0);
                  const avg = 100 / arr.length;
                  const reborn = arr.map((born) => Number(avg.toFixed()));

                  if (is0 === 0) {
                    // console.log("REBORN wght", reborn);
                    return to100(reborn);
                  } else {
                    // console.log("ORIGIN wght", origin);
                    return to100(origin);
                  }
                };

                // // console.log("SUM wght", toaverage(result));
                // =========== THE ARR WEIGHT AFTER CALCULATION ===========
                const ARRAY_WEIGHT = toaverage(goalList);
                // =========== THE ARR WEIGHT AFTER CALCULATION ===========

                if (res.status === 200) {
                  setCollectionPeriod(periodList);

                  const addPer = goalList.map((el, i) => {
                    return {
                      ...el,
                      text: `Goal ${i + 1}`,
                      percentage: ARRAY_WEIGHT[i],
                      color: colors[i],
                    };
                  });

                  setCollectionGoal(addPer.length > 0 ? addPer : []);
                }
                setLoadWeight(false);
              })
              .catch((error) => {
                setLoadWeight(false);
                // console.log("Error", error);
              });
          }
        })
        .catch((error) => {
          setLoadWeight(false);
          // console.log("Error", error);
        });
    }
  }, [openDialogSetWeightGoal, triggerPeriod, user]);

  useEffect(() => {
    if (openDialogSetWeightGoal && collectionUsers.length > 0) {
      const getUser = localStorage.getItem("weight_users");
      const getUserParsed = JSON.parse(getUser);

      const filterUser =
        getUserParsed.length > 0 &&
        getUserParsed.filter((item) => {
          const fullName = `${item.member_first_name} ${item.member_last_name}`;
          const userName =
            fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1;

          return userName;
        });

      // // console.log("USER PARSED", getUserParsed);
      setCollectionUsers(filterUser);
    }
  }, [openDialogSetWeightGoal, search]);

  //   // console.log("USR", user);

  const handleShowAnchorPriod = (event) => {
    setAnchorPeriod(event.currentTarget);
  };

  const handleCloseAnchorPriod = () => {
    setAnchorPeriod(null);
  };

  const handlePeriod = (data) => {
    setPeriod(data);
    handleCloseAnchorPriod();
    setTriggerPeriod(Math.floor(Math.random() * 1000 + 1));
    // // console.log("PerR", data);
  };

  const handleSelectUser = (data) => {
    // // console.log("Data Userx", data);
    setSearch("");
    setUser(data);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCancelWeight = () => {
    setOpenDialogSetWeightGoal(false);
    setSearch("");
    setUser(varUser);
    setPeriod(varPeriod);
    setCollectionGoal([]);
  };

  const handleWeightGoal = () => {
    setLoading(true);

    const mapGoal =
      collectionGoal.length > 0 &&
      collectionGoal.map((el) => {
        return {
          goal_id: el.id,
          weight: Number(el.percentage).toFixed(),
        };
      });
    const data = {
      period_id: period.id,
      date_start: moment(period.start_date).format("YYYY-MM-DD"),
      date_finish: moment(period.end_date).format("YYYY-MM-DD"),
      entity: "user",
      weightings: mapGoal,
    };

    // console.log("WEIGHT GOLZ", data);

    Axios.post(`${URL_API}/swd/goal/weighting/${user.id}/store`, data)
      .then((res) => {
        // console.log("RES WEIGHT", res);

        if (res.status === 200) {
          setLoading(false);
          setOpenDialogSetWeightGoal(false);
          context.setSuccessCreateGoal(Math.floor(Math.random() * 1000 + 1));
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogSetWeightGoal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid container>
        <Grid item md={4}>
          <DialogTitle>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Add Weight Goal For</b>
            </Typography>
          </DialogTitle>

          <DialogContent style={{ maxHeight: 600 }}>
            <InputBase
              placeholder="Type user's name"
              variant="outlined"
              fullWidth
              value={search}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{
                border: "1px solid #aaa",
                borderRadius: 5,
                marginTop: 8,
              }}
              onChange={handleSearch}
            />
            {loadUser && (
              <Box mt={3}>
                <Typography variant="caption" className={classes.title888}>
                  Loading...
                </Typography>
              </Box>
            )}

            <Box mt={3}>
              {!loadUser &&
                collectionUsers.length > 0 &&
                collectionUsers.map((item, i) => (
                  <MenuItem key={i} style={{ margin: 0, padding: 0 }} dense>
                    <ListItemUser
                      classes={classes}
                      item={item}
                      user={user}
                      onHandleUser={handleSelectUser}
                    />
                  </MenuItem>
                ))}
            </Box>
          </DialogContent>
        </Grid>
        <Grid item md={8}>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1" className={classes.title}>
                <b>{`${user.member_first_name} ${user.member_last_name}`}</b>
              </Typography>

              <Button
                className={classes.btnPeriod}
                variant="outlined"
                size="small"
                disabled={loadWeight}
                endIcon={<DateRangeIcon />}
                onClick={handleShowAnchorPriod}
              >
                {loadWeight ? (
                  <CircularProgress size={14} style={{ color: "#1976D2" }} />
                ) : (
                  period?.name
                )}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorPeriod}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                getContentAnchorEl={null}
                open={Boolean(anchorPeriod)}
                onClose={handleCloseAnchorPriod}
              >
                {collectionPeriod.length > 0 &&
                  collectionPeriod.map((item, i) => (
                    <MenuItem
                      key={i}
                      style={{ margin: 0, padding: "0 10px" }}
                      dense
                    >
                      <ListItemPeriod
                        classes={classes}
                        item={item}
                        period={period}
                        onPeriod={handlePeriod}
                      />
                    </MenuItem>
                  ))}
                {collectionPeriod.length === 0 && (
                  <MenuItem style={{ margin: 0, padding: "0 10px" }} dense>
                    <Typography
                      variant="subtitle2"
                      className={classes.fieldPreset}
                    >
                      Tidak ada Period
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </DialogTitle>

          <DialogContent style={{ maxHeight: 600 }}>
            {loadWeight ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress
                  size={15}
                  style={{ color: "#d1354a", marginRight: 5 }}
                />
                <Typography variant="subtitle2" className={classes.title888}>
                  Load Goal...
                </Typography>
              </Box>
            ) : (
              <PercentagesSlider
                divisions={collectionGoal}
                setDivisions={setCollectionGoal}
              />
            )}

            {user.id === "" ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="subtitle2" className={classes.title333}>
                  Silhakan pilih <b>User</b>
                </Typography>
              </Box>
            ) : !loadWeight && collectionGoal.length === 0 ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Typography variant="subtitle2" className={classes.title333}>
                  {`${user.member_first_name} Tidak Memiliki Goal,`}
                </Typography>
                <Typography variant="subtitle2" className={classes.title333}>
                  Silhakan pilih <b>Period</b>
                </Typography>
              </Box>
            ) : (
              ""
            )}

            <Box my={4}>
              <Divider />
            </Box>

            {loadWeight && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress
                  size={15}
                  style={{ color: "#d1354a", marginRight: 5 }}
                />
                <Typography variant="subtitle2" className={classes.title888}>
                  Load Weight list ...
                </Typography>
              </Box>
            )}

            {!loadWeight && collectionGoal.length > 0 && (
              <Grid container>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title888}>
                    Goal
                  </Typography>

                  <Box mt={3}>
                    {collectionGoal.length > 0 &&
                      collectionGoal.map((item, i) => (
                        <Typography
                          key={i}
                          variant="subtitle2"
                          className={classes.title888}
                          style={{ marginBottom: 10 }}
                        >
                          <b>
                            ({item?.text}) {item?.name}
                          </b>
                        </Typography>
                      ))}
                    {collectionGoal.length === 0 && (
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        ---
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item md={2}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginRight: 5 }}
                    >
                      Weight
                    </Typography>
                    <Tooltip
                      title="Maksimal nilai keseluruhan weight pada Goal adalah 100% . Setiap ada perubahan Goal maka akan diwajibkan untuk menyesuaikan kembali nilai weight."
                      placement="bottom"
                    >
                      <InfoRoundedIcon
                        style={{ fontSize: 13, color: "#333" }}
                      />
                    </Tooltip>
                  </Box>
                  <Box mt={3}>
                    {collectionGoal.length > 0 &&
                      collectionGoal.map((item, i) => (
                        <Typography
                          key={i}
                          variant="subtitle2"
                          className={classes.title888}
                          style={{ marginBottom: 10 }}
                        >
                          <b>{Number(item.percentage).toFixed()} %</b>
                        </Typography>
                      ))}
                    {collectionGoal.length === 0 && (
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        ---
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item md={2}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginRight: 5 }}
                    >
                      Current Weight
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    {collectionGoal.length > 0 &&
                      collectionGoal.map((item, i) => (
                        <Typography
                          key={i}
                          variant="subtitle2"
                          className={classes.title888}
                          style={{ marginBottom: 10 }}
                        >
                          <b>
                            {item.weighting === null
                              ? "Not set"
                              : `${Number(item.weighting).toFixed()}%`}
                          </b>
                        </Typography>
                      ))}
                    {collectionGoal.length === 0 && (
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        ---
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item md={2}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginRight: 5 }}
                    >
                      Status
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    {collectionGoal.length > 0 &&
                      collectionGoal.map((item, i) => (
                        <Typography
                          key={i}
                          variant="subtitle2"
                          className={classes.title888}
                          style={{ marginBottom: 10 }}
                        >
                          <b>
                            {item.status === "2"
                              ? "Approved"
                              : item.status === "3"
                              ? "Active"
                              : item.status === "6"
                              ? "Overdue"
                              : item.status === "4"
                              ? "Completed"
                              : item.status === "5"
                              ? "Archive"
                              : "---"}
                          </b>
                        </Typography>
                      ))}
                    {collectionGoal.length === 0 && (
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        ---
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Grid>
      </Grid>

      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.btnWhite}
          onClick={handleCancelWeight}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          size="medium"
          className={classes.button}
          disabled={collectionGoal.length === 0}
          onClick={handleWeightGoal}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogSetWeightGoal2;

const colors = [
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
];

let varUser = {
  id: "",
  member_id: "",
  member_first_name: "",
  member_last_name: "-",
  user_id: "",
  user_email: "",
  structure_position_title_id: "",
  structure_position_title_name: "",
  structure_unit_id: null,
  structure_unit_name: null,
  structure_unit_type_id: null,
  structure_unit_type_name: null,
  self: {
    rel: null,
  },
  member: {
    id: "",
    first_name: "",
    last_name: "-",
    email: "",
    photo_url: "",
    self: {
      rel: {
        photo_url: "",
        photo_url_thumbnail: "",
      },
    },
  },
  user: {
    id: "",
    email: "",
    self: {
      rel: null,
    },
  },
};

const varPeriod = {
  id: "",
  name: "Pilih Period",
  period_group_id: null,
  sequence: null,
  prev_id: null,
  next_id: null,
  interval_id: "",
  start_date: "",
  end_date: "",
  status_id: "",
  created_by: "",
  updated_by: null,
  deleted_by: null,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  prev: null,
  next: null,
  status: {
    id: "",
    name: "",
  },
  periodGroup: null,
  interval: {
    id: "",
    name: "",
    code: "",
    value: "3",
    unit: "month",
    sort_order: 2,
  },
  accessRule: [
    {
      action: "update",
      result: true,
      reason: [],
    },
    {
      action: "view",
      result: true,
      reason: [],
    },
    {
      action: "delete",
      result: true,
      reason: [],
    },
  ],
  self: {
    rel: {
      prev: null,
      next: null,
      periodGroup: null,
    },
  },
};
