import React, { useEffect, useState, useContext, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  Button,
  Checkbox,
  Box,
  createMuiTheme,
} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import ContextGoal from "../../Context/ContextGoal";
import { styles } from "../../Style/StyleGoal";

import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import PictEmptyStateGoal from "../../Components/PictEmptyStateGoal";
import ListCardItemGoal from "../../Components/ListCardItemGoalv2";
import DialogDeleteMultiple from "../../Components/DialogDeleteMultiple";
import DialogArchiveMultiple from "../../Components/DialogArchiveMultiple";
import ListCardHeaderItem from "../../Components/ListCardHeaderItem";

const useStyle = makeStyles(styles);

const ListStatusGoalComplete = ({ mt }) => {
  const context = useContext(ContextGoal);
  const classes = useStyle();
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  const [selected, setSelected] = useState([]);
  const [isOpenDialogDeleteMultiple, setOpenDialogDeleteMultiple] =
    useState(false);
  const [isOpenDialogArchiveMultiple, setOpenDialogArchiveMultiple] =
    useState(false);
  const [checkValidDelete, setCheckValidDelete] = useState([]);

  const handleGetValidGoalDelete = () => {
    return Promise.all(
      selected.length > 0 &&
        selected.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`Goal[${index}][id]`, item);

          return axiosConfig.delete(
            `${URL_API}/swd/goal/batch?validateOnly=true`,
            { data: params }
          );
        })
    )
      .then((response) => {
        // // console.log("ORI Response DEL", response);

        setCheckValidDelete(response);
        setOpenDialogDeleteMultiple(true);
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });
  };

  /*  
        ``````````````````
        HANDLE GOAL DETAIL TAB COMPLETE

        ``````````````````
    */
  const handleDetail = (e, data) => {
    e.preventDefault();
    console.log(data);

    // localStorage.setItem("goal_detail", JSON.stringify(data));
    // navigate(ToGoalDetail, false, { id: data.id });
  };

  const handleOpenArchiveMultiple = () => {
    setOpenDialogArchiveMultiple(true);
  };

  /*
        ==============================================
        HANDLE PAGINATION //*Will be to global state
        ==============================================
    */

  const handleChangePage = (event, newPage) => {
    context.setLoaderForPaging(true);
    context.setPage(newPage);
    context.setOffset((parseInt(newPage + 1) - 1) * context.rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    context.setLoaderForPaging(true);

    // // console.log("Rows perpage : ", event.target.value);
    context.setRowsPerPage(parseInt(event.target.value, 10));
    context.setPage(0);
  };

  // Length Of Collection Goal
  const itemCount = context.collectionGoalList.length;

  /* =========================
      selected ID
      ============================= */
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /* =========================
      selected All List Item
      ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.collectionGoalList.map((n) => n.id);
      setSelected(newSelecteds);
      // // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Fragment>
      <Grid container style={{ backgroundColor: "#fafafa" }}>
        <Grid item xs={12} md={12}>
          <Box position="fixed" width="100%" zIndex={10} pr={13}>
            {superior && selected.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                bgcolor="#fafafa"
                py={2}
                pl={1}
              >
                <Checkbox
                  size="small"
                  indeterminate={
                    selected.length > 0 && selected.length < itemCount
                  }
                  checked={itemCount > 0 && selected.length === itemCount}
                  onChange={handleSelectAllClick}
                  inputProps={{ "aria-label": "select all desserts" }}
                  checkedIcon={
                    <CheckBoxRoundedIcon
                      style={{ color: "#0F68D2", fontSize: 18 }}
                    />
                  }
                  icon={
                    <CheckBoxOutlineBlankRoundedIcon
                      style={{ color: "#0F68D2", fontSize: 18 }}
                    />
                  }
                />
                <Typography variant="subtitle2" className={classes.title}>
                  <b>{selected.length}</b> Goal ditandai
                </Typography>
                {selected.length > 0 && (
                  <Box style={{ marginLeft: 24 }}>
                    <Button
                      onClick={handleOpenArchiveMultiple}
                      disableElevation
                      variant="contained"
                      className={classes.button6}
                      startIcon={<ArchiveIcon style={{ fontSize: 18 }} />}
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      <b>Archive</b>
                    </Button>

                    <Button
                      onClick={handleGetValidGoalDelete}
                      disableElevation
                      variant="contained"
                      className={classes.button5}
                      startIcon={<DeleteRoundedIcon style={{ fontSize: 18 }} />}
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      <b>Delete</b>
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            {context.collectionGoalList.length > 0 && (
              <ListCardHeaderItem classes={classes} origin="Goal" />
            )}
          </Box>
          {context.loader ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={15}
            >
              <CircularProgress
                size={20}
                style={{ marginRight: 10, color: "#d1354a" }}
              />
              <Typography className={classes.title555} variant="subtitle1">
                Loading...
              </Typography>
            </Box>
          ) : (
            <Table
              className={classes.table}
              aria-label="enhanced table"
              style={{ marginTop: selected.length > 0 ? 90 : 30 }}
            >
              <Box my={3} />
              <TableBody>
                {context.collectionGoalList.length > 0 &&
                  context.collectionGoalList.map((item, i) => {
                    return (
                      <ListCardItemGoal
                        key={i}
                        classes={classes}
                        item={item}
                        selected={selected}
                        setSelected={setSelected}
                        handleDetail={handleDetail}
                        userTokenState={context.userTokenStateGlobal}
                        isSelected={isSelected}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          )}

          {context.collectionGoalList.length === 0 && context.loader !== true && (
            <Box width="100%">
              <Paper elevation={1} className={classes.paperList}>
                <Typography
                  variant="subtitle2"
                  className={classes.title555}
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                    textAlign: "center",
                  }}
                >
                  <PictEmptyStateGoal />
                  <b>Tidak ada Goal yang dapat di tampilkan saat ini ;(</b>
                </Typography>
              </Paper>
            </Box>
          )}

          {/* {context.collectionGoalList.length > 0 &&
            context.dataTabActive.id !== 2 && ( //*Bukan YOU maka muncul
            )} */}
        </Grid>
      </Grid>

      <DialogDeleteMultiple
        classes={classes}
        isOpenDialogDeleteMultiple={isOpenDialogDeleteMultiple}
        setOpenDialogDeleteMultiple={setOpenDialogDeleteMultiple}
        selected={selected}
        checkValidDelete={checkValidDelete}
      />

      <DialogArchiveMultiple
        classes={classes}
        isOpenDialogArchiveMultiple={isOpenDialogArchiveMultiple}
        setOpenDialogArchiveMultiple={setOpenDialogArchiveMultiple}
        selected={selected}
      />
    </Fragment>
  );
};

export default ListStatusGoalComplete;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
