import React, { useState } from "react";
import {
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Paper,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import DialogError from "../../../../../components/DialogError";
import handleError from "../../../../Report/components/global/handleError";

const ListItemGroup = ({
  classes,
  item,
  selected,
  onTriggerGroup,
  setSelected,
  isSelected,
  loading,
  setLoading,
}) => {
  const project_id = window.location.pathname.split("/")[2];
  const isItemSelected = isSelected(item.id);

  const [title, setTitle] = useState(item.name);
  const [openEdit, setOpenEdit] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(value);
  };

  const handleRenameTaskGroup = () => {
    setLoading(true);

    const data = {
      name: title,
      weighting: item.weighting,
    };

    AxiosConfig.put(
      `${URL_API}/project/${project_id}/task-group/${item.id}`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          onTriggerGroup(Math.floor(Math.random() * 100));
          setLoading(false);
          setOpenEdit(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRenameTaskGroup();
    }
  };

  const handleDeleteGroup = () => {
    AxiosConfig.delete(`${URL_API}/project/${project_id}/task-group/${item.id}`)
      .then((res) => {
        // // console.log("Del phase", res);

        if (res.status === 200) {
          onTriggerGroup(Math.floor(Math.random() * 100));
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <Paper elevation={0} variant="outlined">
      <ListItem>
        <ListItemIcon>
          <Checkbox
            size="small"
            disableRipple
            checked={isItemSelected}
            disabled={loading}
            onChange={() => handleChangeCheckbox(item)}
          />
        </ListItemIcon>
        {!openEdit && (
          <ListItemText
            primary={
              <Typography variant="subtitle2" className={classes.title555}>
                {item.name}
              </Typography>
            }
            onClick={() => setOpenEdit(true)}
          />
        )}
        {openEdit && (
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            value={title}
            onChange={handleTitle}
            disabled={loading}
            onKeyPress={(e) => handleKeyPress(e)}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={() => setOpenEdit(false)}>
                  <CloseRoundedIcon style={{ color: "#888", fontSize: 18 }} />
                </IconButton>
              ),
            }}
          />
        )}
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            disabled={loading}
            onClick={handleDeleteGroup}
          >
            <DeleteIcon style={{ color: "#888", fontSize: 18 }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default ListItemGroup;
