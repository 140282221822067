import React, { useEffect, useState, forwardRef } from 'react';
import {
  Dialog,
  Box,
  Typography,
  Button,
  Grid,
  Slide,
  AppBar,
  Container,
  CircularProgress,
  DialogContent,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PersonIcon from '@material-ui/icons/Person';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import { navigate } from 'hookrouter';
import moment from 'moment';

import { ToLogin } from '../../../../../constants/config-redirect-url';
import Card3Items from './Card3Items';

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPaidTrial = ({
  openDialogPaidTrial,
  setOpenDialogPaidTrial,
  setOpenDialogPayment,
  setOpenDialogCheckoutReg,
  setOpenDialogPackage,
  dataPay,
  classes,
  paket,
  membership,
  duration,
  totalUser,
  totalDeployment,
  totalPmo,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (openDialogPaidTrial) {
      setData(dataPay);
    }
  }, [openDialogPaidTrial]);

  const handleToLogin = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('intro_enabled');

    localStorage.removeItem('verifyToken');
    localStorage.removeItem('status_user_login');
    localStorage.removeItem('goal_detail');
    localStorage.removeItem('employee_id');
    localStorage.removeItem('response_employee_detail');
    localStorage.removeItem('project_details');

    localStorage.removeItem('value_search');
    localStorage.removeItem('value_search_ma');
    localStorage.removeItem('status_goal_id');
    localStorage.removeItem('email');
    localStorage.removeItem('appVersion');
    localStorage.removeItem('location_tab');
    localStorage.removeItem('main_goal_name');
    localStorage.removeItem('data_identitas');

    localStorage.removeItem('breadcrumb');
    localStorage.removeItem('directory_id');
    localStorage.removeItem('status_allma_id');
    localStorage.removeItem('setting_tab');
    localStorage.removeItem('org_tab');
    localStorage.removeItem('data_preview_so');

    localStorage.removeItem('goal_category');
    localStorage.removeItem('project_type');
    localStorage.removeItem('question_bantu_generate');

    localStorage.removeItem('summary_range');
    localStorage.removeItem('summary_user');
    localStorage.removeItem('filter_user');

    localStorage.removeItem('parent_so');
    localStorage.removeItem('task_gantt_due');
    localStorage.removeItem('task_gantt_id');
    localStorage.removeItem('task_gantt_start');
    localStorage.removeItem('MA_detail');
    localStorage.removeItem('theme');
    localStorage.removeItem('auth_data');
    localStorage.removeItem('trial');

    navigate(ToLogin);
    window.location.reload();
  };

  return (
    <Dialog
      fullScreen
      open={openDialogPaidTrial}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{ position: 'relative', backgroundColor: '#fff' }}
        elevation={0}
      />

      <Container maxWidth="md" style={{ marginTop: 50 }}>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CheckCircleIcon
              style={{
                fontSize: 36,
                color: '#10de77',
                marginBottom: 12,
              }}
            />
            <Typography variant="h6" className={classes.title}>
              <b>Transaksi anda berhasil</b>
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{
                color: '#888',
                width: 550,
                marginTop: 5,
                textAlign: 'center',
              }}
            >
              Pembayaran anda telah kami terima, paket membership
              telah aktif dan dapat segera anda gunakan setelah
              registrasi selesai
            </Typography>
          </Box>
          <Box
            marginBottom={6}
            marginTop={5}
            display="flex"
            justifyContent="center"
          >
            <Box width={600}>
              <Card3Items
                classes={classes}
                account={paket}
                pckgName={membership}
                prop1="User"
                prop2="Consultant Visit"
                prop3="Durasi Berhasil Ditambah"
                val1={totalUser}
                val2={totalPmo}
                val3={duration}
                icon1={
                  <PersonIcon
                    fontSize="small"
                    style={{ color: '#aaa' }}
                  />
                }
                icon2={
                  <ConfirmationNumberIcon
                    fontSize="small"
                    style={{ color: '#aaa' }}
                  />
                }
                icon3={
                  <WatchLaterIcon
                    fontSize="small"
                    style={{ color: '#aaa' }}
                  />
                }
              />
            </Box>
          </Box>

          <Grid container>
            <Grid
              item
              md={6}
              style={{ textAlign: 'right', paddingRight: 15 }}
            >
              <Typography
                variant="subtitle2"
                className={classes.txtSummary2}
              >
                Meetode Pembayaran
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.txtSummary2}
              >
                Jumlah Transfer
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.txtSummary2}
              >
                Tanggal Pembayaran
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              style={{ textAlign: 'left', paddingLeft: 7.5 }}
            >
              <Typography
                variant="subtitle2"
                className={classes.txtSummary2}
              >
                : {data.payment_method_name} Virtual Account
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.txtSummary2}
              >
                : Rp{' '}
                {new Intl.NumberFormat('id-ID').format(
                  data.total_price_after_discount
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.txtSummary2}
              >
                : {moment(data.order_date).format('DD/MM/YYY hh:mm')}
              </Typography>
            </Grid>
          </Grid>

          <Box marginTop={5} display="flex" justifyContent="center">
            <Button
              variant="contained"
              className={classes.button2}
              onClick={handleToLogin}
              style={{ width: 200 }}
            >
              {loading ? (
                <CircularProgress color="#fff" size={15} />
              ) : (
                'Silahkan Login Kembali'
              )}
            </Button>
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default DialogPaidTrial;
