import React, { useEffect, useState } from "react";
import { styles } from "./Style/StyleChangePin";

import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";

import {
  MuiThemeProvider,
  createMuiTheme,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import PinInput from "react-pin-input";
import ErrorDialog from "../../MyAttendance/Components/ErrorDialog";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
});

const ViewChangePin = () => {
  const classes = useStyles();
  const [currentPin, setCurrentPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  const changePin = async () => {
    if (currentPin === "" || newPin === "" || confirmPin === "") {
      setErrorDialog(true);
      setErrorMessage("Please fill all fields");
      setErrorStatus("error");
      return;
    } else if (newPin !== confirmPin) {
      setErrorDialog(true);
      setErrorMessage("New pin and confirm pin must be same");
    } else {
      try {
        const data = {
          User: {
            last_pin: currentPin,
            new_pin: newPin,
          },
        };
        const response = await AxiosConfig.post(
          `${URL_API}/account-management/user/pin/update`,
          data
        );
        console.log(response);
      } catch (error) {
        setErrorDialog(true);
        setErrorMessage(error.response.data.info.message);
      }
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5" component="h2">
            Current Pin:
          </Typography>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{ borderRadius: "10px", border: "2px solid grey" }}
            onComplete={(value, index) => {
              setCurrentPin(value);
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setCurrentPin("");
              }
            }}
            type="numeric"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5" component="h2">
            New Pin:
          </Typography>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{ borderRadius: "10px", border: "2px solid grey" }}
            onComplete={(value, index) => {
              setNewPin(value);
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setNewPin("");
              }
            }}
            type="numeric"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h5" component="h2">
            Confirm New Pin:
          </Typography>
          <PinInput
            length={6}
            focus
            secret
            inputStyle={{ borderRadius: "10px", border: "2px solid grey" }}
            onComplete={(value, index) => {
              setConfirmPin(value);
            }}
            onChange={(value, index) => {
              if (value.length < 6) {
                setConfirmPin("");
              }
            }}
            type="numeric"
          />
        </div>

        <Button
          fullWidth
          variant="contained"
          style={{
            backgroundColor: "#262261",
            color: "#ffffff",
            marginTop: "20px",
            marginBottom: "20px",
            height: "5rem",
          }}
          onClick={changePin}
        >
          Change Pin
        </Button>

        <ErrorDialog
          open={errorDialog}
          handleClose={handleErrorDialogClose}
          status={errorStatus}
          message={errorMessage}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewChangePin;
