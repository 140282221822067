import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import ContextAllMA from "../context/AllMaContext";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import { ToAllMA } from "../../../../constants/config-redirect-url";
import { navigate } from "hookrouter";
import DialogSetWeight from "./DialogSetWeightDel";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDeleteMA,
    setModalDeleteMA,
    maDetailState,
    setMeasuredActivityDetailShow,
  } = props;
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const context = useContext(ContextAllMA);

  const [userTokenState, setUserTokenState] = useState("");
  const [loader, setLoader] = useState(false);
  const [openDialogSetWeight, setOpenDialogSetWeight] = useState(false);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  /*
          ````````````````
          HANDLE SNACKBEER
  
          ````````````````
      */
  // const [ isModalResponse200, setModalResponse200 ] = useState(false);//*Moved To Global State

  useEffect(() => {
    if (isModalDeleteMA === true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isModalDeleteMA]);

  const handleDelete = () => {
    setLoader(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    if (userTokenState !== "") {
      axiosConfig
        .delete(`${URL_API}/swd/measured-activity/${maDetailState.id}`)
        .then(function (response) {
          // // console.log("Response Original : ", response);
          if (response.status === 200) {
            const dataLog = {
              user_activity_log: {
                module: "ma",
                event: "create",
                user_id: user?.user_id,
                user_email: user?.user_email,
                nama: `${user?.member_first_name} ${user?.member_last_name}`,
                position:
                  user?.member_position?.structure_position_title_name ===
                  undefined
                    ? ""
                    : user?.member_position?.structure_position_title_name,
                device_type: device_os,
                device_model: "",
                device_version: OSName,
                long: "",
                lat: "",
                entity_id: maDetailState?.id,
                entity_name: maDetailState?.name,
                remarks: `MA (${maDetailState?.name}) 1`,
                comment: "",
              },
            };

            setDataRemarks(dataLog);

            setOpenRemarksLog(true);

            // axiosConfig
            //   .get(
            //     `${URL_API}/swd/measured-activity/weighting/${maDetailState.goal_id}`
            //   )
            //   .then((res) => {
            //     const result = res.data.data;
            //     const listMA = result.ma_id.default_value;

            //     if (listMA.length === 0) {
            //       context.setModalResponse200(true);
            //       context.setDeleteSuccess(
            //         Math.floor(Math.random() * 1000 + 1)
            //       );
            //       context.setMessages("Measure Activity berhasil dihapus!");
            //       setModalDeleteMA(false);
            //     } else {
            //       setOpenDialogSetWeight(true);
            //     }
            //   });
          }
          setLoader(false);
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          setLoader(false);

          setModalDeleteMA(false);
          alert("Whoops, something went wrong !");
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <Fragment>
      <Dialog
        open={isModalDeleteMA}
        onClose={() => setModalDeleteMA(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}
        ></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}
            >
              <b>
                Apakah Anda yakin ingin menghapus <i>MA</i>:{" "}
                <i>{maDetailState.name}</i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader === true ? (
              <CircularProgress size={17} color="#d1354a" />
            ) : (
              "Hapus"
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setModalDeleteMA}
        onTriggerRemarks={context.setDeleteSuccess}
        editRemarks={true}
        origin="ma-all"
      />

      <DialogSetWeight
        classes={classes}
        openDialogSetWeight={openDialogSetWeight}
        setOpenDialogSetWeight={setOpenDialogSetWeight}
        goalId={maDetailState.goal_id}
      />
    </Fragment>
  );
};

export default DialogDelete;
