import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const GoalOwnerName = (props) => {
  const { classes, goalDetailState } = props;

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Typography
        variant="subtitle1"
        className={classes.title}
        style={{ color: 'white', textAlign: 'center' }}
      >
        {/* {FormatTitleCase(goalDetailState.name)} */}
        {goalDetailState.name}
      </Typography>
    </Grid>
  );
};

export default GoalOwnerName;
