import React, { useEffect, useState } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropdownIcon from "@material-ui/icons/ArrowDropDown";
import FilterListIcon from "@material-ui/icons/FilterList";

import classNames from "classnames";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import FilterQuick from "./FilterQuick";
import FilterCustom from "./FilterCustom";

const AnchorFilter = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [quickFilters, setQuickFilters] = useState(true);
  const [ownerCollections, setOwnerCollections] = useState([]);

  useEffect(() => {
    if (anchorEl !== null) {
      AxiosConfig.get(`${URL_API}/todolist/create`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
          }
        })
        .catch((error) => {
          // console.log("Error", error);
        });
    }
  }, [anchorEl]);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setQuickFilters(true);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
    setQuickFilters(true);
  };

  const handleCustomFilter = () => {
    setQuickFilters(false);
  };

  return (
    <div>
      <Button
        size="small"
        varian="outlined"
        className={classes.button2}
        startIcon={<FilterListIcon />}
        endIcon={<ArrowDropdownIcon />}
        onClick={handleShow}
      >
        Filter
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                {quickFilters && (
                  <FilterQuick
                    classes={classes}
                    onCustomFilter={handleCustomFilter}
                    onClose={handleClose}
                  />
                )}
                {!quickFilters && (
                  <FilterCustom
                    classes={classes}
                    onClose={handleClose}
                    ownerCollections={ownerCollections}
                  />
                )}
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AnchorFilter;
