import React, { useEffect, useState, Fragment } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Button,
  Tooltip,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Tabs } from "react-tabs";

import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

import axios from "axios";

import DialogModalInviteAnggota from "./ComponentViewUser/DialogModalInviteAnggota";
import EnhancedTableToolbar from "./ComponentViewUser/EnhancedTableToolbar";

// import ModalDetail from './ComponentViewUser/ModalDetail';
import DialogModalDetail from "./ComponentViewUser/DialogModalDetail";
import DialogDelete from "./ComponentViewUser/DialogDelete";
import DialogError from "../../../components/DialogError";

import ImageSmallUser from "../../../components/ImageSmallUser";

import PictDefaultDashboard from "../../../assets/images/Mask_Group_2.png";

import { URL_API } from "../../../constants/config-api";

import { styles } from "./Style/StyleUser";
import {
  DEPLOYMENT,
  PMO_VISIT,
  USER_LICENSE,
} from "../MembershipManagement/constant-data";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiList: {
      root: {
        width: 54,
      },
    },
    MuiListItemText: {
      root: {
        fontSize: 17,
      },
    },
    MuiSwitch: {
      "&$checked": {
        color: "grey",
      },
      iconChecked: {
        color: "#c1322d",
      },
      checked: {},
    },
  },
});

// IOS Switch Style
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#55dc87",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#55dc87",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#d9e0e4",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

/*

    ```````````````````````````````````
    functions utilities TABLE COMPONENT

    ```````````````````````````````````
*/

let counter = 0;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function stableSort(array, cmp) {
  // // console.log("stableSort : ", array);

  if (array !== null && array !== undefined) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);

      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  return [];
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "name", align: "center", disablePadding: true, label: "Name" },
  { id: "role", align: "center", disablePadding: false, label: "Role" },
  { id: "enable", align: "right", disablePadding: false, label: "Enable" },
  {
    id: "job-title",
    align: "center",
    disablePadding: false,
    label: "Job Title",
  },
  { id: "buttondetail", align: "center", disablePadding: false, label: "" },
  // { id: 'editdanhapus', align: 'center', disablePadding: false, label: '' },
];

const ViewUser = (props) => {
  const { classes, onAcccountTab } = props;

  /* 
     ``````````````````````````
     - Get List status USER

     - GET LIST STATUS INVITED
     
     - Table functional

     ``````````````````````````
   */

  const [data, setData] = useState([]);

  const [dataUserInvited, setDataUserInvited] = useState([]);
  const [summary, setSummary] = useState({
    user_license_total: 0,
    user_status_id_enum_disabled: 0,
    user_status_id_enum_enabled: 0,
    user_status_id_enum_otp_verified: 0,
    user_status_id_enum_pending: 0,
  });

  const [enable, setEnable] = useState(true);
  const [loading, setLoading] = useState(false);

  const [triggerEnableDisableUser, setTriggerEnableDisableUser] =
    useState(null);

  const hendleEnable = (e) => setEnable(e.target.checked);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (userToken !== null) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        crossorigin: true,
        crossDomain: true,
        Authorization: "bearer " + userToken,
      };

      setLoading(true);

      axios.defaults.headers.common = headers;

      /* USERS READY */
      axios
        .get(URL_API + `/account-management/user`)
        .then((response) => {
          // console.log("Original response - USERS : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              if (response.data.data.length > 0) {
                setData(response.data.data);

                const userSummary = response.data.info.summary;
                setSummary(userSummary);
              }
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Error response : ", error.response);
        });

      setLoading(true);
      /* USERS INVITED */
      axios
        .get(
          URL_API +
            `/account-management/user?options[filter][0][0]=status_id&options[filter][0][1]=eq&options[filter][0][2]=1`
        )
        .then((response) => {
          // // console.log("Original response - USERS INVITED : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              if (response.data.data.length > 0) {
                setDataUserInvited(response.data.data);
              }
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Error response : ", error.response);
        });
    } else {
      // console.log("Ga dapet User Token !");
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    axios
      .get(URL_API + `/account-management/user`)
      .then((response) => {
        // // console.log("Original response - USERS : ", response);

        if (response.status === 200) {
          if (response.data.data !== null) {
            const userSummary = response.data.info.summary;
            setSummary(userSummary);

            if (response.data.data.length > 0) {
              setData(response.data.data);
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("Error response : ", error.response);
      });
  }, [triggerEnableDisableUser]);

  //*

  const listChooseTab = [
    {
      id: 1,
      label: "User",
      totalUser:
        summary.user_status_id_enum_enabled +
        summary.user_status_id_enum_disabled,
    },

    {
      id: 2,
      label: "Pending",
      totalUser: summary.user_status_id_enum_pending,
    },
  ];

  //*
  const [dataTabActive, setDataTabActive] = useState({
    id: 1,
    label: "User",
    totalUser:
      summary.user_status_id_enum_enabled +
      summary.user_status_id_enum_disabled,
  });

  const handleClickChooseTab = (e, data) => {
    e.preventDefault();

    setDataTabActive(data);
  };

  //    const [loading, data, setFetchedData ] = useGetHttp(URL_API + `/account-management/user`, []);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  }

  function handleClick(event, id) {
    const selectedIndex = selected.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (data !== null && data !== undefined ? data.length : 0) -
        page * rowsPerPage
    );

  /*

       ````
       TAB

       ````
   */
  const [tabIndex, setTabIndex] = useState(0);
  const handleTab = (tabIndex) => setTabIndex(tabIndex);

  /*
        ``````````````````````````````````
        MODAL DETAIL USER HAS BEEN AN USER  

        ```````````````````````````````````
    */
  const [isChooseDetail, setChooseDetail] = useState(false);
  const [dataDetailUser, setDataDetailuser] = useState(null);

  const handleModalDetailUpdateUser = (e, data) => {
    e.preventDefault();

    setDataDetailuser(data);

    setChooseDetail(true);
    // // console.log("Data User : ", data);
  };

  /*

       ````````````
       MODAL INVITE

       ````````````
    */
  const [isModalInvite, setModalInvite] = useState();

  /*
       ````````````
       MODAL DELETE

       ````````````
    */

  const [isModalDelete, setModalDelete] = useState(false);
  const [detailState, setDetailState] = useState({
    id: null,
    member: {
      first_name: null,
      last_name: null,
    },
  });

  /*

        ``````
        Delete

        ``````
    */
  const handleDelete = (e, item) => {
    e.preventDefault();

    setDetailState(item);
    setModalDelete(true);
  };

  const handleDeletePending = (e, item) => {
    e.preventDefault();

    // console.log("DApet");

    setDetailState(item);
    setModalDelete(true);
  };

  /*
        `````````````````````
        HANDLE DELETE SUCCESS
        
        `````````````````````
    */
  const [isSuccesDeleteUser, setSuccessDeleteUser] = useState(false);

  useEffect(() => {
    if (isSuccesDeleteUser === true) {
      const userToken = localStorage.getItem("userToken");

      if (userToken !== null) {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          crossorigin: true,
          crossDomain: true,
          Authorization: "bearer " + userToken,
        };

        setLoading(true);

        axios.defaults.headers.common = headers;

        /* USERS READY */
        axios
          .get(
            URL_API +
              `/account-management/user?options[filter][0][0]=status_id&options[filter][0][1]=eq&options[filter][0][2]=3`
          )
          .then((response) => {
            // // console.log("Original response - USERS : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                if (response.data.data.length > 0) {
                  setData(response.data.data);
                }
              }
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            // console.log("Error response : ", error.response);
          });

        setLoading(true);
        /* USERS INVITED */
        axios
          .get(
            URL_API +
              `/account-management/user?options[filter][0][0]=status_id&options[filter][0][1]=eq&options[filter][0][2]=1`
          )
          .then((response) => {
            // // console.log("Original response - USERS INVITED : ", response);

            if (response.status === 200) {
              if (response.data.data !== null) {
                setDataUserInvited(response.data.data);

                // if(response.data.data.length > 0){

                // };
              }
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            // console.log("Error response : ", error.response);
          });
      } else {
        // console.log("Ga dapet User Token !");
      }
    }
  }, [isSuccesDeleteUser]);

  // ERROR HANDLE STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  // STATE HANDLE TOGGLE ENABLE / DISABLE
  const [userToggleSwitchState, setUserToggleSwitchState] = useState({});
  const [userToggleCheck, setUserToggleCheck] = useState("");

  // ============================== HANDLE DISABLE USER ==============================
  // ============================== HANDLE DISABLE USER ==============================
  const handleDisableUser = (e, user) => {
    setUserToggleCheck(user.id);

    // get user id
    const id = user.id;

    // get credential token
    const userToken = localStorage.getItem("userToken");

    setLoading(true);

    // set selected filter status
    if (userToken !== undefined) {
      axios
        .post(`${URL_API}/account-management/user/${id}:disable`)
        .then((response) => {
          // // console.log("Response Original Disable User : ", response);

          if (response.status === 200) {
            setTriggerEnableDisableUser(Math.floor(Math.random() * 1000 + 1));
          }

          // setUserToggleSwitchState({
          //   ...userToggleSwitchState,
          //   [e.target.name]: e.target.checked,
          // });
          setUserToggleCheck("");
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setUserToggleCheck("");
          // console.log("Error : ", error);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  };

  // ============================== HANDLE ENABLE USER ==============================
  // ============================== HANDLE ENABLE USER ==============================
  const handleEnableUser = (e, user) => {
    setUserToggleCheck(user.id);

    // get user id
    let id = user.id;

    // get credential token
    let userToken = localStorage.getItem("userToken");

    setLoading(true);

    // set selected filter status
    if (userToken !== undefined) {
      axios
        .post(`${URL_API}/account-management/user/${id}:enable`)
        .then((response) => {
          // // console.log("Response Original Save User To Enable : ", response);

          if (response.status === 200) {
            setTriggerEnableDisableUser(Math.floor(Math.random() * 1000 + 1));
          }

          // setUserToggleSwitchState({
          //   ...userToggleSwitchState,
          //   [e.target.name]: e.target.checked,
          // });
          setLoading(false);
          setUserToggleCheck("");
        })
        .catch((error) => {
          setLoading(false);
          setUserToggleCheck("");
          // console.log("Error : ", error.response);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  };

  const toManageMembership = (e) => {
    onAcccountTab({ id: 1, code: "membership", name: "Membership" });
    localStorage.setItem("org_tab", "1");
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        marginLeft={theme.spacing(1.6)}
        // marginTop={theme.spacing(1.5)}
      >
        <Paper
          className={classes.root}
          elevation={0}
          style={{ backgroundColor: "transparent" }}
        >
          <Grid
            container
            // spacing={10}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item sm={9} style={{ paddingTop: 0 }}>
              {/* <Typography
                variant="h6"
                className={classes.title}
              >
                User Setting
              </Typography> */}
            </Grid>
            <Grid item sm={3} style={{ textAlign: "right" }}>
              {/* <img src={IconPreviewList} className={classes.iconPreviewList} />
              <img src={IconPreviewGrid} className={classes.iconPreviewGrid} /> */}
            </Grid>
          </Grid>
          <br />

          {/* 
                    
                        ``````````````````````````
                            Search, Button Invite

                        ``````````````````````````
                    */}

          <Grid
            container
            spacing={8}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item sm={3}>
              <Typography variant="h6" className={classes.title}>
                User Setting
              </Typography>
            </Grid>
            <Grid item sm={4} style={{ textAlign: "right" }}>
              {/* <Paper className={classes.paper} elevation={1}>
                <IconButton className={classes.iconButton} aria-label="Search">
                  <SearchIcon />
                </IconButton>
                <InputBase className={classes.input} placeholder="Cari ..." />
                <Divider className={classes.divider} />
              </Paper> */}
            </Grid>
            <Grid item sm={1}></Grid>

            <Grid item sm={2} style={{ textAlign: "right" }}>
              {/* <Tooltip title="Filter" placement="right-start">
                <img src={IconFilterNew} className={classes.iconFilter} />
              </Tooltip> */}
            </Grid>
            <Grid item sm={2} style={{ textAlign: "left" }}>
              <Button
                onClick={() => setModalInvite(true)}
                variant="contained"
                color="secondary"
                className={classes.button}
                style={{ marginRight: 24 }}
              >
                Invite User
              </Button>
            </Grid>
          </Grid>

          <Box marginTop={1} marginBottom={4}>
            <div className={classes.warningPasswordBg}>
              <Typography
                variant="subtitle2"
                className={classes.warningPasswordTxt}
              >
                You have used{" "}
                <b>
                  {summary.user_status_id_enum_enabled +
                    summary.user_status_id_enum_pending}
                </b>{" "}
                users out of <b>{summary.user_license_total}</b> avaliable.{" "}
                <span style={{ color: "#333" }}>
                  Upgrade to increase more users.
                </span>
                <Button
                  variant="outlined"
                  className={classes.buttonUpgrade}
                  onClick={toManageMembership}
                >
                  Upgrade
                </Button>
              </Typography>
            </div>
          </Box>

          {/* 
                    
                        `````````````````````````````````
                            Row Tab, List, & Grid Image, Table

                        ``````````````````````````````````
                    */}
          <Grid container spacing={2}>
            {listChooseTab.length > 0 &&
              listChooseTab.map((item, i) => {
                return (
                  <Grid item sm={1.5} md={1.5}>
                    <Box>
                      <Button
                        startIcon={
                          <Avatar
                            variant="rounded"
                            style={{
                              color: "#d1354a",
                              backgroundColor: "#ffa9c1",
                              fontWeight: "700",
                              fontSize: 15,
                              height: 24,
                              width: 24,
                              textAlign: "right",
                            }}
                          >
                            {item.totalUser}
                          </Avatar>
                        }
                        variant="outlined"
                        classes={
                          dataTabActive.id === item.id
                            ? {
                                outlined: classes.outlinedTabActive,
                              }
                            : {
                                outlined: classes.outlinedTabNotActive,
                              }
                        }
                      >
                        <Typography
                          style={{
                            marginBottom: 1,
                            textTransform: "capitalize",
                          }}
                          variant="subtitle2"
                          className={classes.title}
                          onClick={(e) => handleClickChooseTab(e, item)}
                          noWrap
                        >
                          <b>{item.label}</b>
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                );
              })}

            <Grid item sm={9} md={9}></Grid>

            <Grid item sm={12} md={12}>
              <Tabs
                style={{ backgroundColor: "transparent", paddingLeft: 0 }}
                selectedIndex={tabIndex}
                onSelect={(tabIndex) => handleTab(tabIndex)}
              >
                {dataTabActive.id === 1 ? (
                  /*  
                                        `````````````````
                                        Panel, User Content Table

                                        `````````````````
                                    */

                  <div className={classes.tableWrapper}>
                    {loading ? (
                      <Grid
                        container
                        spacing={40}
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item sm={12} style={{ textAlign: "center" }}>
                          <CircularProgress
                            size={32}
                            style={{ marginTop: 64, color: "red" }}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Table
                        style={{ backgroundColor: "transparent" }}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                      >
                        {data !== null &&
                        data !== undefined &&
                        data.length > 0 ? (
                          <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={data !== null ? data.length : 0}
                          />
                        ) : null}

                        <TableBody>
                          {stableSort(data, getSorting(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .filter((usr) => usr.status_id !== "1")
                            .map((n) => {
                              const isItemSelected = isSelected(n.id);
                              // // console.log("NNN", n);

                              return (
                                <TableRow
                                  style={{ backgroundColor: "transparent" }}
                                  hover
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={n.id}
                                  selected={isItemSelected}
                                  // onClick={event => handleClick(event, n.id)}
                                >
                                  <TableCell
                                    className={classes.title}
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    {n.member !== null ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <ImageSmallUser
                                          item={
                                            n.member.self !== undefined &&
                                            n.member.self.rel.photo_url
                                          }
                                          index={1}
                                        />
                                        <div style={{ marginLeft: 10 }}>
                                          <p
                                            style={{
                                              color: "#2194e1",
                                              fontWeight: "600",
                                              fontSize: 15,
                                              margin: 0,
                                            }}
                                          >
                                            {n.member.first_name}{" "}
                                            {n.member.last_name}
                                          </p>
                                          <p
                                            style={{
                                              color: "#707070",
                                              fontWeight: "600",
                                              margin: 0,
                                            }}
                                          >
                                            {n.email}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <p>-</p>
                                    )}
                                  </TableCell>
                                  <TableCell className={classes.title}>
                                    {n.masterRole.length > 0 ? (
                                      n.masterRole.map((item, i) => {
                                        return (
                                          <Chip
                                            key={i}
                                            style={{
                                              backgroundColor:
                                                item.name === "USER"
                                                  ? "#ffd391"
                                                  : "#afdafb",
                                              color:
                                                item.name === "USER"
                                                  ? "#bc6c1b"
                                                  : "#6173cb",
                                            }}
                                            label={item.name}
                                            size="small"
                                          />
                                        );
                                      })
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </TableCell>
                                  <TableCell className={classes.title}>
                                    {n.id === userToggleCheck ? (
                                      <CircularProgress
                                        size={17}
                                        style={{ color: "#d1354a" }}
                                      />
                                    ) : (
                                      <Fragment>
                                        {n.status === null && <div>-</div>}
                                        {n.status_id === "3" && (
                                          <FormControlLabel
                                            control={
                                              <IOSSwitch
                                                checked={true}
                                                name={n.id}
                                                onClick={(e) =>
                                                  handleDisableUser(e, n)
                                                }
                                              />
                                            }
                                          />
                                        )}

                                        {n.status_id === "4" && (
                                          <FormControlLabel
                                            control={
                                              <IOSSwitch
                                                checked={false}
                                                name={n.id}
                                                onClick={(e) =>
                                                  handleEnableUser(e, n)
                                                }
                                              />
                                            }
                                          />
                                        )}

                                        {n.status_id === "1" && (
                                          <FormControlLabel
                                            control={
                                              <IOSSwitch
                                                checked={false}
                                                name={n.id}
                                                disabled
                                              />
                                            }
                                          />
                                        )}
                                      </Fragment>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {n.position === null ? (
                                      <p
                                        style={{
                                          color: "#ffbb34",
                                          fontWeight: "600",
                                        }}
                                      >
                                        - not assigned
                                      </p>
                                    ) : (
                                      <p
                                        style={{
                                          color: "#707070",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {
                                          n.position
                                            .structure_position_title_name
                                        }
                                      </p>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={classes.title}
                                    align="left"
                                  >
                                    <Button
                                      onClick={(e) => handleDelete(e, n)}
                                      className={classes.buttonDeleteUser}
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    )}

                    {/* ROW OF DELETE  */}
                    <EnhancedTableToolbar numSelected={selected.length} />

                    <TablePagination
                      classes={{ toolbar: classes.toolbar }}
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={
                        data !== null && data !== undefined
                          ? data.filter((n) => n.status_id !== "1").length
                          : 0
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                      }}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "Jumlah baris",
                        },
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <i>{`${from} - ${
                            to === -1 ? count : to
                          } baris di tampilkan dari total semua ${count} baris.`}</i>
                        </Typography>
                      )}
                      labelRowsPerPage={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>
                            <i>Jumlah baris yang mau di tampilkan : </i>
                          </b>
                        </Typography>
                      }
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  /*  
                                            `````````````````````````````
                                            Panel, Invited Content Table

                                            `````````````````````````````
                                        */
                  <div className={classes.tableWrapper}>
                    {data === null && (
                      <Grid container>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <img
                            src={PictDefaultDashboard}
                            alt="Belum ada data yang bisa di tampilkan !"
                            className={classes.imagePictDefault}
                          />

                          <br />
                          <br />
                          <Typography variant="h6" className={classes.title}>
                            Belum ada data yang bisa ditampilkan
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                    >
                      {dataUserInvited !== null &&
                        dataUserInvited !== undefined &&
                        dataUserInvited.length === 0 && (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="center"
                          >
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                            >
                              <b>
                                Belum ada <i>user yang di undang !</i>
                              </b>
                            </Typography>
                          </Grid>
                        )}
                      {dataUserInvited !== null &&
                      dataUserInvited !== undefined &&
                      dataUserInvited.length > 0 ? (
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={
                            dataUserInvited !== null
                              ? dataUserInvited.length
                              : 0
                          }
                        />
                      ) : null}

                      <TableBody>
                        {stableSort(dataUserInvited, getSorting(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((n) => {
                            const isItemSelected = isSelected(n.id);

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={n.id}
                                selected={isItemSelected}
                              >
                                {/* <TableCell 
                                                                            padding="checkbox"
                                                                            onClick={event => handleClick(event, n.id)}
                                                                        >
                                                                            <Checkbox checked={isItemSelected} />
                                                                        </TableCell> */}

                                <TableCell
                                  className={classes.title}
                                  component="th"
                                  scope="row"
                                  padding="none"
                                >
                                  {n.member !== null ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <ImageSmallUser
                                        item={n.member.self.rel.photo_url}
                                        index={1}
                                      />
                                      <div style={{ marginLeft: 10 }}>
                                        <p
                                          style={{
                                            color: "#2194e1",
                                            fontWeight: "600",
                                            fontSize: 15,
                                            margin: 0,
                                          }}
                                        >
                                          {n.member.first_name}{" "}
                                          {n.member.last_name}
                                        </p>
                                        <p
                                          style={{
                                            color: "#707070",
                                            fontWeight: "600",
                                            margin: 0,
                                          }}
                                        >
                                          {n.email}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </TableCell>
                                <TableCell className={classes.title}>
                                  {n.masterRole.length > 0 ? (
                                    n.masterRole.map((item, i) => {
                                      return (
                                        <Chip
                                          key={i}
                                          style={{
                                            backgroundColor:
                                              item.name === "USER"
                                                ? "#ffd391"
                                                : "#afdafb",
                                            color:
                                              item.name === "USER"
                                                ? "#bc6c1b"
                                                : "#6173cb",
                                          }}
                                          label={item.name}
                                          size="small"
                                        />
                                      );
                                    })
                                  ) : (
                                    <span>-</span>
                                  )}
                                </TableCell>
                                <TableCell className={classes.title}>
                                  {n.status_id === "3" ? (
                                    <Chip
                                      size="small"
                                      icon={
                                        <DoneIcon style={{ color: "white" }} />
                                      }
                                      label="Aktif"
                                      className={classes.chipVerifified}
                                      style={{
                                        backgroundColor: "#55dc87",
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      size="small"
                                      icon={
                                        <CloseIcon
                                          style={{
                                            color: "white",
                                            fontSize: 17,
                                          }}
                                        />
                                      }
                                      label="Belum di konfirmasi"
                                      className={classes.chipVerifified}
                                      style={{
                                        backgroundColor: "#c1272d",
                                        color: "white",
                                      }}
                                    />
                                  )}
                                </TableCell>
                                <TableCell className={classes.title}>
                                  {n.position === null ? (
                                    <p
                                      style={{
                                        color: "#ffbb34",
                                        fontWeight: "600",
                                      }}
                                    >
                                      - not assigned
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        color: "#707070",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {n.position.structure_position_title_name}
                                    </p>
                                  )}
                                </TableCell>
                                <TableCell
                                  className={classes.title}
                                  align="left"
                                >
                                  {/* 
                                      `````````````
                                      OKE

                                      `````````````
                                  */}
                                  <Button
                                    className={classes.buttonDeleteUser}
                                    onClick={(e) => handleDeletePending(e, n)}
                                  >
                                    Delete
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>

                    {/* ROW OF DELETE  */}
                    <EnhancedTableToolbar
                      numSelected={selected.length}
                      selected={selected}
                    />

                    {dataUserInvited !== null &&
                      dataUserInvited !== undefined &&
                      dataUserInvited.length > 0 && (
                        <TablePagination
                          classes={{ toolbar: classes.toolbar }}
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={
                            data !== null && data !== undefined
                              ? data.length
                              : 0
                          }
                          rowsPerPage={rowsPerPage}
                          page={page}
                          backIconButtonProps={{
                            "aria-label": "Previous Page",
                          }}
                          nextIconButtonProps={{
                            "aria-label": "Next Page",
                          }}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "Jumlah baris",
                            },
                          }}
                          labelDisplayedRows={({ from, to, count }) => (
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                            >
                              <i>{`${from} - ${
                                to === -1 ? count : to
                              } baris di tampilkan dari total semua ${count} baris.`}</i>
                            </Typography>
                          )}
                          labelRowsPerPage={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                            >
                              <b>
                                <i>Jumlah baris yang mau di tampilkan : </i>
                              </b>
                            </Typography>
                          }
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      )}
                  </div>
                )}

                {/* </TabPanel> */}

                {/* <TabPanel style={{backgroundColor: 'transparent'}}> 
                                    <br />
                                    <br /> */}

                {/* </TabPanel> */}
              </Tabs>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* DIALOG MODAL DETAIL */}
      <DialogModalDetail
        classes={classes}
        isChooseDetail={isChooseDetail}
        setChooseDetail={setChooseDetail}
        dataDetailUser={dataDetailUser}
      />

      {/* MODAL INVITE */}
      <DialogModalInviteAnggota
        classes={classes}
        isModalInvite={isModalInvite}
        setModalInvite={setModalInvite}
        // listAnggota={listAnggota}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogDelete
        classes={classes}
        isModalDelete={isModalDelete}
        setModalDelete={setModalDelete}
        detailState={detailState}
        setSuccessDeleteUser={setSuccessDeleteUser}
        // isSuccesDeleteUser = { isSuccesDeleteUser }
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewUser);

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell> */}
        {rows.map(
          (row) => (
            <TableCell
              key={row.id}
              numeric={row.numeric}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
              style={{ fontFamily: "Roboto", fontWeight: "bold" }}
            >
              <Tooltip
                title="Sort"
                placement={row.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
}
