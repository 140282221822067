import { Button, Grid, Typography } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import UpdateIcon from '@material-ui/icons/Update';
import PollRoundedIcon from "@material-ui/icons/PollRounded";
import React, { useEffect, useState } from "react";
import { navigate } from "hookrouter";
import {
  ToAttendanceListRequest,
  ToAttendanceReportAdmin,
  ToAttendanceSetting
} from "../../../../constants/config-redirect-url";

function AttendanceListMenu({
  classes,
  roleName,
  numSelected,
  rowCount,
  onSelectAllClick,
}) {

  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ width: "100%", marginBottom : "25px" }}>
        {roleName === "superadmin" && (
          <Grid item>
            <Button
              onClick={() => navigate(ToAttendanceSetting)}
              variant="contained"
              color="default"
              className={classes.button}
              style={{ marginLeft: "0px", width : "100%" }}
            >
              <div style={{padding : "5px"}}>
                <div>
                  <SettingsIcon style={{ color: "#ffffff" }} />
                </div>
                <div style={{paddingRight: "16px", paddingLeft: "16px"}}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color: "#fff"}}>
                    <b>ATTENDANCE SETTING</b>
                  </Typography>
                </div>
              </div>
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            onClick={() => navigate(ToAttendanceListRequest)}
            variant="contained"
            color="default"
            className={classes.button}
            style={{ marginLeft: "0px", width : "100%" }}
          >
            <div style={{padding : "5px"}}>
              <div>
                <UpdateIcon style={{ color: "#ffffff" }} />
              </div>
              <div style={{paddingRight: "16px", paddingLeft: "16px"}}>
                <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color: "#fff"}}>
                  <b>LIST REQUEST ATTENDANCE</b>
                </Typography>
              </div>
            </div>
          </Button>
        </Grid>
        {(roleName === "manage" || roleName === "superadmin") && (
          <Grid item>
            <Button
              onClick={() => navigate(ToAttendanceReportAdmin)}
              variant="contained"
              color="default"
              className={classes.button}
              style={{ marginLeft: "0px", width : "100%" }}
            >
              <div style={{padding : "5px"}}>
                <div>
                  <PollRoundedIcon style={{ color: "#ffffff" }} />
                </div>
                <div style={{paddingRight: "16px", paddingLeft: "16px"}}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color: "#fff"}}>
                    <b>REPORT ATTENDANCE</b>
                  </Typography>
                </div>
              </div>
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default AttendanceListMenu;
