import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, LinearProgress, MenuItem, Paper, TableBody, TableCell, TableFooter, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import ContextAttendance from "../Context/ContextAttendance";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Autocomplete } from "@material-ui/lab";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { Table } from "react-bootstrap";
import EnhancedTableHeadCheckInCheckOut from "./EnhancedTableHeadCheckInCheckOut";
import { URL_API } from "../../../../constants/config-api";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogDetail = (props) => {
  const {
    classes,
    isModalDetail,
    setModalDetail,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    dataAttendanceDetail,
    dataAttendanceCheckIn,
  } = props;

  const context = useContext(ContextAttendance);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );
  const [loader, setLoader] = useState(false);

  return (
    <Fragment>
      <Dialog
        open={isModalDetail}
        onClose={() => setModalDetail(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth='lg'
        scroll='paper'
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
        >
          <Typography variant="h4">Detail Attendance</Typography>
        </DialogTitle>
        <Grid container style={{marginBottom: "10px", marginTop: "10px"}}>
          <Grid item md={4}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Nama Lengkap</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.member && dataAttendanceDetail.member.member ?
                            dataAttendanceDetail.member.member.first_name + " " + dataAttendanceDetail.member.member.last_name:
                            "" 
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={4} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Tanggal</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.workday ? 
                            moment(dataAttendanceDetail.workday).format("DD-MM-YYYY") :
                            "" 
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={4}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Nama Jadwal</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.schedule ? dataAttendanceDetail.schedule.name : ""
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={4}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Schedule In</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.schedule && dataAttendanceDetail.schedule.shift ? 
                            dataAttendanceDetail.schedule.shift.schedule_in : ""
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={4} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Duration</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.schedule && dataAttendanceDetail.schedule.shift ? 
                            dataAttendanceDetail.schedule.shift.duration + " Jam" : ""
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={4}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Schedule Out</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.schedule && dataAttendanceDetail.schedule.shift ? 
                            dataAttendanceDetail.schedule.shift.schedule_out : ""
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={4}>
            <DialogContent style={{ textAlign: "left", marginBottom: "10px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Clock In</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.clock_in ? 
                            moment(dataAttendanceDetail.clock_in).format("DD-MM-YYYY HH:mm") : ""
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: "left", marginBottom: "10px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Status Clock In</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{textTransform: "capitalize"}}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                          {dataAttendanceDetail.clock_in_status === "1"
                              ? "Success"
                              : (
                                  dataAttendanceDetail.clock_in_status === "0" ? 
                                      "Failed" 
                                      : ""
                              )
                          }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Clock In Remarks</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{textTransform: "capitalize"}}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}  style={{wordBreak: "break-all"}}>
                      <b>: { }
                        {dataAttendanceDetail.clock_in_schedule_status  ? dataAttendanceDetail.clock_in_schedule_status  : ""}
                        {dataAttendanceDetail.clock_in_location_status === "1"
                            ? ", Lokasi Sesuai"
                            : (
                                dataAttendanceDetail.clock_in_location_status === "0" ? 
                                    ", Lokasi Tidak Sesuai" 
                                    : ""
                            )
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={4} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left", marginBottom: "10px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Clock Out</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                        {
                          dataAttendanceDetail.clock_out ? 
                            moment(dataAttendanceDetail.clock_out).format("DD-MM-YYYY HH:mm") : ""
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: "left", marginBottom: "10px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Status Clock Out</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{textTransform: "capitalize"}}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                      <b>: { }
                          {dataAttendanceDetail.clock_out_status === "1"
                              ? "Success"
                              : (
                                  dataAttendanceDetail.clock_out_status === "0" ? 
                                      "Failed" 
                                      : ""
                              )
                          }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Clock Out Remarks</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{textTransform: "capitalize"}}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title} style={{wordBreak: "break-all"}}>
                      <b>: { }
                        {dataAttendanceDetail.clock_out_schedule_status ? dataAttendanceDetail.clock_out_schedule_status : ""}
                        {dataAttendanceDetail.clock_out_location_status === "1"
                            ? ", Lokasi Sesuai"
                            : (
                                dataAttendanceDetail.clock_out_location_status === "0" ? 
                                    ", Lokasi Tidak Sesuai" 
                                    : ""
                            )
                        }
                      </b>
                    </Typography>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={4}>
          </Grid>
        </Grid>
        <Grid container style={{marginBottom: "10px"}}>
          <Grid item md={4}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Clock In Photo</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{display: "flex", alignItems: "center"}}>
                  <b>:</b>
                  {
                    dataAttendanceDetail.clock_in_photo_path &&
                    <a href={encodeURI(`${URL_API}` + dataAttendanceDetail.clock_in_photo_path_url)} style={{
                      display: "flex",
                      alignItems: "center",
                      background: "rgb(215, 203, 203)",
                      borderRadius: "10px",
                      padding: "1px 10px",
                      cursor: "pointer",
                      textDecoration: "none",
                      marginLeft: "5px"
                    }} title={dataAttendanceDetail.clock_in_photo_path} target={"_blank"}>
                      <InsertDriveFileIcon style={{ color: "#000000de" }} />
                      <Typography variant="subtitle1" component={'span'} className={classes.title} style={{
                        width: "150px",
                        marginLeft: "7px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "#000000de"
                      }}>
                        {dataAttendanceDetail.clock_in_photo_path}
                      </Typography>
                    </a>
                  }
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Grid item md={4} className={classes.gridMiddle}>
            <DialogContent style={{ textAlign: "left" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title}>
                    <b>Clock Out Photo</b>
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{display: "flex", alignItems: "center"}}>
                  <b>:</b>
                  {
                    dataAttendanceDetail.clock_out_photo_path &&
                    <a href={encodeURI(`${URL_API}` + dataAttendanceDetail.clock_out_photo_path_url.replace("clockout/", "clockin/"))} style={{
                      display: "flex",
                      alignItems: "center",
                      background: "rgb(215, 203, 203)",
                      borderRadius: "10px",
                      padding: "1px 10px",
                      cursor: "pointer",
                      textDecoration: "none",
                      marginLeft: "5px"
                    }} title={dataAttendanceDetail.clock_out_photo_path} target={"_blank"}>
                      <InsertDriveFileIcon style={{ color: "#000000de" }} />
                      <Typography variant="subtitle1" component={'span'} className={classes.title} style={{
                        width: "150px",
                        marginLeft: "7px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "#000000de"
                      }}>
                        {dataAttendanceDetail.clock_out_photo_path}
                      </Typography>
                    </a>
                  }
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: "center", width: "100%" }}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h6" component="h6" className={classes.title}>
                    <b style={{
                      paddingTop: "10px",
                      display:" inline-block",
                      paddingBottom: "10px",
                      borderBottom: "2px solid #e0e0e0",
                      paddingLeft: "25px",
                      paddingRight: "25px"
                    }}>List Check In/Check Out</b>
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent style={{ textAlign: "left", marginBottom: "25px" }}>
          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHeadCheckInCheckOut
              classes={classes}
            />

            {
              dataAttendanceCheckIn && dataAttendanceCheckIn.list &&
                dataAttendanceCheckIn.list.length > 0 &&  
                ( 
                  <TableBody>
                    {
                      dataAttendanceCheckIn.list.map((item, i) => {
                        return (
                          <TableRow  key={item.id}>
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                inputProps={{ "aria-label": "select all desserts" }}
                              /> */}
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2" className={classes.title}>
                                { i + 1}
                              </Typography>
                            </TableCell>
      
                            <TableCell align="left" >
                              <Typography variant="subtitle2" className={classes.title} >
                                {item.workday ? moment(item.workday).format("DD-MM-YYYY") : ""}
                              </Typography>
                            </TableCell>

                            {/* <TableCell align="left" >
                              <Typography variant="subtitle2" className={classes.title} >
                                {item.schedule ? item.schedule.name : ""}
                              </Typography>
                            </TableCell> */}
      
                            {/* <TableCell align="left" >
                              <Typography variant="subtitle2" className={classes.title} >
                                {item.schedule && item.schedule.shift ? item.schedule.shift.schedule_in : ""}
                              </Typography>
                            </TableCell> */}
      
                            {/* <TableCell align="left" >
                              <Typography variant="subtitle2" className={classes.title} >
                                {item.schedule && item.schedule.shift ? item.schedule.shift.duration + " Jam" : ""}
                              </Typography>
                            </TableCell> */}
                            
                            {/* <TableCell align="left" >
                              <Typography variant="subtitle2" className={classes.title} >
                                {item.schedule && item.schedule.shift ? item.schedule.shift.schedule_out : ""}
                              </Typography>
                            </TableCell> */}
                            
                            <TableCell align="left" >
                              <Typography variant="subtitle2" className={classes.title} >
                                {item.clock_in ? moment(item.clock_in).format("DD-MM-YYYY HH:mm") : ""}
                              </Typography>
                            </TableCell>
                            
                            <TableCell align="left" >
                              <Typography variant="subtitle2" className={classes.title} >
                                {item.clock_out ? moment(item.clock_out).format("DD-MM-YYYY HH:mm") : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                )
            }
            

            {
              dataAttendanceCheckIn && dataAttendanceCheckIn.list &&
                dataAttendanceCheckIn.list.length <= 0 &&  
                (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={9}>
                        <Box style={{ textAlign: "center" }}>
                          <Typography
                            variant="subtitle1"
                            className={classes.title}
                            style={{
                              color: "grey",
                              marginTop: 16,
                              marginBottom: 16,
                            }}>
                            <b>Data check in/check out tidak tersedia</b>
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
            }
            
            <TableFooter>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default DialogDetail;