import React from "react";
import { IconButton } from "@material-ui/core";

import PictIconGoalResultWhenEmptyState from "../../../../assets/images/Group_3044.png";

const PictEmptyStateGoalResult = (props) => {
  return (
    <IconButton disabled>
      <img
        src={PictIconGoalResultWhenEmptyState}
        style={{ width: 16, height: 16 }}
      />
    </IconButton>
  );
};

export default PictEmptyStateGoalResult;
