import React from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

function DialogSuccessDemoSchedule({
  dialogSuccessDemo,
  setDialogSuccessDemo,
  classes,
}) {
  return (
    <Dialog
      open={dialogSuccessDemo}
      onClose={() => setDialogSuccessDemo(false)}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle></DialogTitle>

      <DialogContent style={{ marginTop: 15, textAlign: "center" }}>
        <CheckCircleIcon
          style={{ fontSize: 36, color: "#10de77", marginBottom: 12 }}
        />

        <Typography
          variant="h5"
          className={classes.title}
          style={{ marginBottom: 12 }}>
          <b>Terimakasih</b>
        </Typography>

        <Typography
          variant="subtitle2"
          className={classes.textPro1}
          style={{ marginBottom: 15, marginTop: 0 }}>
          Permintaan anda akan segera di proses, tim kami akan menghubungi anda
          dalam waktu maksimal 24 jam
        </Typography>

        <Typography
          variant="subtitle2"
          className={classes.textPro2}
          style={{ marginBottom: 10 }}>
          Anda bisa menggunakan Performate secara gratis selama 1 bulan
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setDialogSuccessDemo(false)}
          variant="contained"
          className={classes.buttonNext}
          fullWidth>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSuccessDemoSchedule;
