import React, { useContext, useState } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import clsx from "clsx";

import ContextTask from "../../Context/ContextTask";
import { SET_SORT_NAME, SET_SORT_DATE } from "../../Context/types";

const TableHeaderTDL = ({ classes }) => {
  const { todoState, todoDispatch, setTriggerTodo, setSelected, selected } =
    useContext(ContextTask);

  const [sortName, setSortName] = useState(false);
  const [sortDate, setSortDate] = useState(false);

  // Length Of Collection Goal
  const itemCount = todoState.todoData.length;

  /* =========================
  selected All List Item
  ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = todoState.todoData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Handle Sort Name
  const handleSortName = () => {
    setSortName((e) => !e);
    if (sortDate === true) setSortDate(false);

    todoDispatch({
      type: SET_SORT_NAME,
      payload: { sortName: !sortName },
    });
    todoDispatch({
      type: SET_SORT_DATE,
      payload: { sortDate: false },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  // Handle Sort Date
  const handleSortDate = () => {
    setSortDate((e) => !e);
    if (sortName === true) setSortName(false);

    todoDispatch({
      type: SET_SORT_DATE,
      payload: { sortDate: !sortDate },
    });
    todoDispatch({
      type: SET_SORT_NAME,
      payload: { sortName: false },
    });
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            borderTopLeftRadius: 8.5,
            backgroundColor: "#fff",
          }}
        >
          <Checkbox
            size="small"
            className={classes.checkboxRoot}
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            indeterminate={selected.length > 0 && selected.length < itemCount}
            checked={itemCount > 0 && selected.length === itemCount}
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />{" "}
          To Do List{" "}
          <IconButton size="small" onClick={handleSortName}>
            <ImportExportIcon
              style={{ color: sortName ? "#d64253" : "#333", fontSize: 15 }}
            />
          </IconButton>
        </TableCell>
        <TableCell
          align="center"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
          }}
        >
          Assignee
        </TableCell>
        <TableCell
          align="center"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
          }}
        >
          Status
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
          }}
        >
          Priority
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
          }}
        >
          Due Date{" "}
          <IconButton size="small" onClick={handleSortDate}>
            <ImportExportIcon
              style={{ color: sortDate ? "#d64253" : "#333", fontSize: 15 }}
            />
          </IconButton>
        </TableCell>
        <TableCell
          align="center"
          className={classes.titleHead}
          style={{
            backgroundColor: "#fff",
          }}
        >
          Created By
        </TableCell>
        <TableCell
          align="center"
          className={classes.titleHead}
          style={{
            borderTopRightRadius: 8.5,
            backgroundColor: "#fff",
          }}
        >
          Result
        </TableCell>
        <TableCell
          align="right"
          className={classes.titleHead}
          style={{
            borderTopRightRadius: 8.5,
            backgroundColor: "#fff",
          }}
        />
      </TableRow>
    </TableHead>
  );
};

export default TableHeaderTDL;
