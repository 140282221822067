import React from "react";
import { IconButton } from "@material-ui/core";

import PictIconMAWhenEmptyState from "../../../../assets/images/Group_2968.png";

const PictEmptyStateMA = (props) => {
  return (
    <IconButton disabled>
      <img src={PictIconMAWhenEmptyState} style={{ width: 32, height: 24 }} />
    </IconButton>
  );
};

export default PictEmptyStateMA;
