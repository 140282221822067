import React, { useContext, useEffect, useState } from 'react';
import { styles } from './Style/StyleAttandanceOvertime';
import { navigate } from 'hookrouter';

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  Box,
  Button,
  Tabs,
  Tab
} from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/id';
import Moment from 'react-moment';
import { isEmpty, now } from 'lodash';

import DialogProgress from '../Account/Components/DialogProgress';

import ContextAttendanceOvertime from './Context/ContextAttendanceOvertime';

import { URL_API } from '../../../constants/config-api';
import axiosConfig from '../../../constants/config-axios';

import { isAuthenticated } from '../MyAttendance/Hook/isAuthenticated';
import OvertimeRequestDialog from './Components/OvertimeRequestDialog';
import ErrorDialog from '../MyAttendance/Components/ErrorDialog';
import SuccessDialog from '../Account/Components/SuccessDialog';

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cc0707', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#262261',
      light: '#6e6ecf',
      dark: '#0f0c29',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: 'Lato, sans-serif'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const ViewAttendanceOvertime = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceOvertime);

  const [value, setValue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [dataRaw, setDataRaw] = useState([]);
  const [dataPending, setDataPending] = useState([]);
  const [dataApproved, setDataApproved] = useState([]);

  const [overtimeRequestDialog, setOvertimeRequestDialog] = useState(false);
  const [overtimeRequestValue, setOvertimeRequestValue] = useState({
    overtimeType: 'after_shift',
    overtimeWorkday: new Date(now()),
    overtimeDuration: 0,
    overtimeRestDuration: 0,
    overtimeDescription: ''
  });

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorStatus, setErrorStatus] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [successStatus, setSuccessStatus] = useState('');

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    }
    fetchOvertime();
  }, []);

  const fetchOvertime = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/overtime/`
      );
      setDataRaw(response.data.data);
      setDataPending(
        response.data.data.filter((item) => {
          return item.status_approve[0].status === '1';
        })
      );
      setDataApproved(
        response.data.data.filter((item) => {
          return item.status_approve[0].status === '99';
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    if (newValue === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === '99';
      });
      setDataApproved(data);
      console.log(data);
    } else if (newValue === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === '1';
      });
      setDataPending(data);
      console.log(data);
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    console.log(index);
    if (index === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === '99';
      });
      setDataApproved(data);
      console.log(data);
    } else if (index === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve[0].status === '1';
      });
      setDataPending(data);
      console.log(data);
    }
  };

  const handleRequestDialogClose = () => {
    setOvertimeRequestDialog(false);
    setOvertimeRequestValue({
      overtimeType: 'after_shift',
      overtimeWorkday: new Date(now()),
      overtimeDuration: 0,
      overtimeRestDuration: 0,
      overtimeDescription: ''
    });
  };

  const verificationRequest = async () => {
    try {
      setLoader(true);
      if (!isEmpty(overtimeRequestValue.overtimeDescription)) {
        const data = {
          Overtime: {
            category: overtimeRequestValue.overtimeType,
            workday: moment(overtimeRequestValue.overtimeWorkday).format(
              'YYYY-MM-DD'
            ),
            overtime_duration: overtimeRequestValue.overtimeDuration,
            rest_duration: overtimeRequestValue.overtimeRestDuration,
            description: overtimeRequestValue.overtimeDescription
          }
        };
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/overtime/create`,
          data
        );

        if (response.status === 200) {
          setDataRaw([response.data.data, ...dataRaw]);
          setDataPending([response.data.data, ...dataPending]);
          setOvertimeRequestDialog(false);
          setSuccessStatus('Berhasil melakukan request overtime');
          setSuccessDialog(true);
        }
      } else {
        setErrorDialog(true);
        setErrorMessage('Deskripsi harus diisi');
        setErrorStatus('Gagal melakukan request overtime');
      }
    } catch (error) {
      setErrorDialog(true);
      setErrorMessage(error.response.data.info.message);
      setErrorStatus('Gagal melakukan request overtime');
    } finally {
      setLoader(false);
    }
  };

  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? ' Jam, ' : ' Jam, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' Menit, ' : ' Menit, ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' Detik' : ' Detik') : '';
    return hDisplay + mDisplay + sDisplay;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <div
                style={{
                  position: 'fixed',
                  width: '37%',
                  maxWidth: 'inherit'
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: '#262261',
                    color: '#ffffff',
                    marginTop: '1rem',
                    height: '5rem'
                  }}
                  onClick={() => {
                    setOvertimeRequestDialog(true);
                  }}
                >
                  <b>AJUKAN OVERTIME</b>
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={6}>
              <Typography
                component={'span'}
                variant="h5"
                style={{ fontWeight: 'bold', color: '#262261' }}
              >
                History Overtime
              </Typography>

              <Box sx={{ width: '100%' }}>
                <Box>
                  <Tabs
                    style={{ textColor: 'grey' }}
                    textColor="primary"
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    aria-label="report-tab"
                    variant="fullWidth"
                    TabIndicatorProps={{
                      style: {
                        borderBottom: `3px solid purple`,
                        borderRadius: '20px'
                      }
                    }}
                  >
                    <Tab label="Disetujui" />
                    <Tab label="Perlu Persetujuan" />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                      margin: '20px 0px'
                    }}
                  >
                    {dataApproved?.map((item, index) => (
                      <Card
                        style={{
                          padding: '10px',
                          color: '#221E5B',
                          borderRadius: '10px'
                        }}
                        key={index}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              gap: '10px',
                              marginBottom: '10px',
                              fontWeight: 'bold'
                            }}
                          >
                            <DateRange />
                            <Typography component={'span'} variant="h6">
                              <Moment
                                format="dddd, D MMMM YYYY"
                                interval={1}
                                unit="seconds"
                              >
                                {item.workday}
                              </Moment>
                            </Typography>
                          </div>
                          <Typography variant="subtitle1">
                            Durasi Lembur:{' '}
                            <b>{secondsToHms(item.overtime_duration)}</b>
                          </Typography>
                          <Typography variant="subtitle1">
                            Durasi Istirahat:{' '}
                            <b>{secondsToHms(item.rest_duration)}</b>
                          </Typography>
                          <Typography variant="subtitle1">
                            Deskripsi: <b>{item.description}</b>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: '#221E5B'
                            }}
                          >
                            <b>Status: Telah Disetujui</b>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 15,
                      margin: '20px 0px'
                    }}
                  >
                    {dataPending?.map((item, index) => (
                      <Card
                        style={{
                          padding: '10px',
                          color: '#221E5B',
                          borderRadius: '10px'
                        }}
                        key={index}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              gap: '10px',
                              marginBottom: '10px',
                              fontWeight: 'bold'
                            }}
                          >
                            <DateRange />
                            <Typography component={'span'} variant="h6">
                              <Moment
                                format="dddd, D MMMM YYYY"
                                interval={1}
                                unit="seconds"
                              >
                                {item.workday}
                              </Moment>
                            </Typography>
                          </div>
                          <Typography variant="subtitle1">
                            Durasi Lembur:{' '}
                            <b>{secondsToHms(item.overtime_duration)}</b>
                          </Typography>
                          <Typography variant="subtitle1">
                            Durasi Istirahat:{' '}
                            <b>{secondsToHms(item.rest_duration)}</b>
                          </Typography>
                          <Typography variant="subtitle1">
                            Deskripsi: <b>{item.description}</b>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: '#CE354A'
                            }}
                          >
                            <b>Status: Menunggu Persetujuan HR/Admin</b>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>

          <OvertimeRequestDialog
            handleClose={handleRequestDialogClose}
            open={overtimeRequestDialog}
            verification={verificationRequest}
            value={overtimeRequestValue}
            setValue={setOvertimeRequestValue}
          />

          <ErrorDialog
            open={errorDialog}
            handleClose={handleErrorDialogClose}
            status={errorStatus}
            message={errorMessage}
          />

          <SuccessDialog
            open={successDialog}
            handleClose={handleSuccessDialogClose}
            status={successStatus}
          />

          <DialogProgress
            classes={classes}
            isModalProgress={loader}
            setModalProgress={setLoader}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default ViewAttendanceOvertime;
