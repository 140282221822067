import React, { useContext, useEffect } from 'react';
import ContextReports from '../../../../context/ContextReports';
import * as constants from '../../../../context/constants';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Toolbar
} from '@material-ui/core';
import { styles } from '../../../../../Global/Style';
import {
  findQueryString,
  updateQueryParams,
  updateQueryCommaToArr
} from '../../../../../../utilities/UrlHelper';
import AxiosConfig from '../../../../../../constants/config-axios';
import { URL_API } from '../../../../../../constants/config-api';

const moment = require('moment');

const fileDownload = require('js-file-download');

const useStyle = makeStyles(styles);

const ToolbarSection = () => {
  const classes = useStyle();
  const statusOptions = ['Achieved', 'Warning', 'Not achieved'];

  const viewColumList = ['Goal', 'MA'];

  const orderOptions = [
    {
      label: 'Owner Name',
      value: 'owner_name'
    },
    {
      label: 'Unit',
      value: 'structure_unit'
    },
    {
      label: 'Goal',
      value: 'goal_average_overall_result_achievement'
    },
    {
      label: 'MA',
      value: 'ma_average_overall_result_achievement'
    }
  ];
  const { liveSummary, liveSummaryFilter, liveSummaryFilterDispatch } =
    useContext(ContextReports);

  const { options } = liveSummary;

  const handleFilterUnit = (event, item) => {
    let isChecked = event.target.checked;

    if (item.id == 'All') {
      if (isChecked) {
        let updatePayload = liveSummary.options.units.reduce((acc, row) => {
          acc[row.id] = row;
          return acc;
        }, {});
        updatePayload[item.id] = item;

        liveSummaryFilterDispatch({
          filterType: constants.FILTER_UNIT,
          payload: updatePayload
        });
      } else {
        liveSummaryFilterDispatch({
          filterType: constants.FILTER_UNIT,
          payload: {}
        });
      }
    } else {
      if (isChecked) {
        let updatePayload = liveSummaryFilter.units;
        updatePayload[item.id] = item;

        liveSummaryFilterDispatch({
          filterType: constants.FILTER_UNIT,
          payload: updatePayload
        });
      } else {
        let updatePayload = liveSummaryFilter.units;
        delete updatePayload[item.id];

        liveSummaryFilterDispatch({
          filterType: constants.FILTER_UNIT,
          payload: updatePayload
        });
      }
    }
  };

  const handleFilterStatus = (event, type) => {
    let value = event.target.value;
    let isChecked = event.target.checked;

    if (isChecked) {
      if (type == 'goal') {
        updateQueryCommaToArr('goalStatus', [
          ...liveSummaryFilter.goalAchStatus,
          value
        ]);
        liveSummaryFilterDispatch({
          filterType: constants.FILTER_GOAL_ACH_STATUS,
          payload: [...liveSummaryFilter.goalAchStatus, value]
        });
      } else {
        updateQueryCommaToArr('maStatus', [
          ...liveSummaryFilter.maAchStatus,
          value
        ]);
        liveSummaryFilterDispatch({
          filterType: constants.FILTER_MA_ACH_STATUS,
          payload: [...liveSummaryFilter.maAchStatus, value]
        });
      }
    } else {
      if (type == 'goal') {
        let excluded = liveSummaryFilter.goalAchStatus.filter(
          (row) => row != value
        );
        updateQueryCommaToArr('goalStatus', excluded);
        liveSummaryFilterDispatch({
          filterType: constants.FILTER_GOAL_ACH_STATUS,
          payload: excluded
        });
      } else {
        let excluded = liveSummaryFilter.maAchStatus.filter(
          (row) => row != value
        );
        updateQueryCommaToArr('maStatus', excluded);
        liveSummaryFilterDispatch({
          filterType: constants.FILTER_MA_ACH_STATUS,
          payload: excluded
        });
      }
    }
  };

  const handleOrder = (evt, type) => {
    let value = evt.target.value;

    switch (type) {
      case 'field':
        updateQueryParams(
          'orderByDirection',
          liveSummaryFilter.order.direction
        );
        liveSummaryFilterDispatch({
          filterType: value,
          payload: {
            field: value,
            direction: liveSummaryFilter.order.direction
          }
        });
        break;
      case 'direction':
        updateQueryParams('orderBy', liveSummaryFilter.order.field);
        liveSummaryFilterDispatch({
          filterType: liveSummaryFilter.order.field,
          payload: {
            field: liveSummaryFilter.order.field,
            direction: value
          }
        });
        break;
      default:
        break;
    }
  };

  const [anchorStatus, setAnchorStatus] = React.useState(null);
  const [anchorUnit, setAnchorUnit] = React.useState(null);
  const [anchorOra, setAnchorOra] = React.useState(null);
  const [anchorOrder, setAnchorOrder] = React.useState(null);
  const [anchorViewColumns, setAnchorViewColumns] = React.useState(null);
  const [anchorExport, setAnchorExport] = React.useState(null);

  const handleOpenMenu = (event, type) => {
    switch (type) {
      case 'status':
        setAnchorStatus(event.currentTarget);
        break;
      case 'unit':
        setAnchorUnit(event.currentTarget);
        break;
      case 'order':
        setAnchorOrder(event.currentTarget);
        break;
      case 'viewColumns':
        setAnchorViewColumns(event.currentTarget);
        break;
      case 'export':
        setAnchorExport(event.currentTarget);
        break;
      default:
        break;
    }
  };

  const handleCloseMenu = (type) => {
    switch (type) {
      case 'status':
        setAnchorStatus(null);
        break;
      case 'unit':
        setAnchorUnit(null);
        break;
      case 'order':
        setAnchorOrder(null);
        break;
      case 'viewColumns':
        setAnchorViewColumns(null);
        break;
      case 'export':
        setAnchorExport(null);
        break;
      default:
        break;
    }
  };

  const [goalOra, setGoalOra] = React.useState({ from: 0, to: 200 });
  const [maOra, setMaOra] = React.useState({ from: 0, to: 200 });
  const handleInputFieldOra = (evt, type) => {
    let value = evt.target.value;

    switch (type) {
      case 'goalOraFrom':
        setGoalOra({
          ...goalOra,
          from: value
        });
        break;
      case 'goalOraTo':
        setGoalOra({
          ...goalOra,
          to: value
        });
      case 'maOraFrom':
        setMaOra({
          ...maOra,
          from: value
        });
        break;
      case 'maOraTo':
        setMaOra({
          ...maOra,
          to: value
        });
      default:
        break;
    }
  };
  const handleFilterOra = () => {
    liveSummaryFilterDispatch({
      filterType: constants.FILTER_GOAL_ORA,
      payload: goalOra
    });
    liveSummaryFilterDispatch({
      filterType: constants.FILTER_MA_ORA,
      payload: maOra
    });
    setAnchorOra(null);
  };

  const handleViewColumns = (evt) => {
    let value = evt.target.value;
    let isChecked = evt.target.checked;

    if (isChecked) {
      updateQueryCommaToArr('viewColumns', [
        ...liveSummaryFilter.viewColumns,
        value
      ]);
      liveSummaryFilterDispatch({
        filterType: constants.VIEW_COLUMNS,
        payload: [...liveSummaryFilter.viewColumns, value]
      });
    } else {
      updateQueryCommaToArr(
        'viewColumns',
        liveSummaryFilter.viewColumns.filter((row) => row != value)
      );
      liveSummaryFilterDispatch({
        filterType: constants.VIEW_COLUMNS,
        payload: liveSummaryFilter.viewColumns.filter((row) => row != value)
      });
    }
  };

  const [searchTerm, setSearchTerm] = React.useState('');
  let timeoutId, querySearchTimeoutId;

  useEffect(() => {
    let searchParams = findQueryString('search');
    if (searchParams) {
      setSearchTerm(findQueryString('search'));
    }
  }, []);

  const handleSearch = (event) => {
    clearTimeout(timeoutId);
    let value = event.target.value;
    setSearchTerm(value);

    timeoutId = setTimeout(() => {
      clearTimeout(querySearchTimeoutId);
      querySearchTimeoutId = setTimeout(() => {
        updateQueryParams('search', value);
        updateQueryParams('page', '0');
      }, 1000);

      liveSummaryFilterDispatch({
        filterType: constants.FILTER_SEARCH,
        payload: value
      });
    }, 1000);
  };

  const [delimiter, setDelimiter] = React.useState('comma');

  const handleExportState = (event, type) => {
    setDelimiter(event.target.value);
  };

  const handleExport = () => {
    AxiosConfig.post(
      `${URL_API}/reporting/live/summary/export?options[period_id]=${
        liveSummary?.options?.defaultPeriod?.id || ''
      }`,
      { delimiter: delimiter }
    )
      .then((res) => {
        let formattedCurrentDateTime = moment().format('YYYYMMDD-HHmmss');

        fileDownload(
          res.data,
          `PF_LIVE_SUMMARY_REPORTING_${formattedCurrentDateTime}.csv`
        );
      })
      .catch(function (error) {
        alert('whoops something went wrong');
      });
  };

  const renderUnitLeaf = (depth, parent, childs) => {
    return (
      <div>
        {childs.length > 0 &&
          childs.map((item) => (
            <>
              <MenuItem
                key={item.id}
                className={classes.menuItemLarge}
                style={{ marginLeft: 40 * depth }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.id in liveSummaryFilter.units}
                      onClick={(evt) => handleFilterUnit(evt, item)}
                    />
                  }
                  label={item.structure_unit}
                />
              </MenuItem>
              {renderUnitLeaf(
                depth + 1,
                item,
                liveSummary.options.units.filter(
                  (row) => row.parent_structure_unit_id == item.id
                )
              )}
            </>
          ))}
      </div>
    );
  };

  const renderUnitMenu = () => {
    return (
      <div>
        {liveSummary.options.highestStructurePosition && (
          <>
            {
              <MenuItem
                key={liveSummary.options.highestStructurePosition.id}
                className={classes.menuItemLarge}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        liveSummary.options.highestStructurePosition.id in
                        liveSummaryFilter.units
                      }
                      onClick={(evt) =>
                        handleFilterUnit(
                          evt,
                          liveSummary.options.highestStructurePosition
                        )
                      }
                    />
                  }
                  label={
                    liveSummary.options.highestStructurePosition.structure_unit
                  }
                />
              </MenuItem>
            }
            {renderUnitLeaf(
              1,
              liveSummary.options.highestStructurePosition,
              liveSummary.options.units.filter(
                (row) =>
                  row.parent_structure_unit_id ==
                  liveSummary.options.highestStructurePosition.id
              )
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <Toolbar style={{ paddingLeft: 2 }}>
        <TextField
          label="search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Divider orientation="vertical" />
        <Button
          variant="outlined"
          className={classes.menuButton}
          onClick={(evt) => handleOpenMenu(evt, 'status')}
        >
          Status
        </Button>

        <Button
          variant="outlined"
          className={classes.menuButton}
          onClick={(evt) => handleOpenMenu(evt, 'unit')}
        >
          Unit
        </Button>

        <Button
          variant="outlined"
          className={classes.menuButton}
          onClick={(evt) => handleOpenMenu(evt, 'viewColumns')}
        >
          View Columns
        </Button>

        <Button
          variant="outlined"
          className={classes.menuButton}
          onClick={(evt) => handleOpenMenu(evt, 'order')}
        >
          Order By
        </Button>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="outlined"
            className={classes.menuButtonSecondary}
            onClick={(evt) => handleOpenMenu(evt, 'export')}
          >
            Export All
          </Button>
        </div>
      </Toolbar>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorStatus}
        keepMounted
        open={Boolean(anchorStatus)}
        onClose={(evt) => handleCloseMenu('status')}
      >
        <ListItem className={classes.listItemHeader}>
          <ListItemText primary={'Goal'} />
        </ListItem>
        <FormGroup>
          {statusOptions.map((option) => (
            <MenuItem key={option} className={classes.menuItem}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={liveSummaryFilter.goalAchStatus.includes(option)}
                    onClick={(evt) => handleFilterStatus(evt, 'goal')}
                    value={option}
                  />
                }
                label={option}
              />
            </MenuItem>
          ))}
        </FormGroup>
        <Divider />
        <ListItem className={classes.listItemHeader}>
          <ListItemText primary={'MA'} />
        </ListItem>
        <FormGroup>
          {statusOptions.map((option) => (
            <MenuItem key={option} className={classes.menuItem}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={liveSummaryFilter.maAchStatus.includes(option)}
                    onClick={(evt) => handleFilterStatus(evt, 'ma')}
                    value={option}
                  />
                }
                label={option}
              />
            </MenuItem>
          ))}
        </FormGroup>
      </Menu>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorUnit}
        keepMounted
        open={Boolean(anchorUnit)}
        onClose={(evt) => handleCloseMenu('unit')}
      >
        <FormGroup>
          <MenuItem className={classes.menuItemLarge}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={'All' in liveSummaryFilter.units}
                  onClick={(evt) =>
                    handleFilterUnit(evt, { id: 'All', structure_unit: 'All' })
                  }
                />
              }
              label={'All'}
            />
          </MenuItem>
        </FormGroup>
        <Divider />

        {renderUnitMenu()}
      </Menu>

      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorViewColumns}
        keepMounted
        open={Boolean(anchorViewColumns)}
        onClose={(evt) => handleCloseMenu('viewColumns')}
      >
        <FormGroup>
          {viewColumList.map((item) => (
            <MenuItem className={classes.menuItem} key={item}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={liveSummaryFilter.viewColumns.includes(item)}
                    onClick={handleViewColumns}
                    value={item}
                  />
                }
                label={item}
              />
            </MenuItem>
          ))}
        </FormGroup>
      </Menu>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorOrder}
        keepMounted
        open={Boolean(anchorOrder)}
        onClose={(evt) => handleCloseMenu('order')}
      >
        <ListItem className={classes.listItemHeader}>
          <ListItemText primary={'Field'} />
        </ListItem>
        <ListItem>
          <RadioGroup
            aria-label="field"
            value={liveSummaryFilter.order.field}
            onChange={(evt) => handleOrder(evt, 'field')}
          >
            {orderOptions.map((item) => (
              <div key={item.value}>
                <FormControlLabel
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              </div>
            ))}
          </RadioGroup>
        </ListItem>
        <Divider />
        <ListItem className={classes.listItemHeader}>
          <ListItemText primary={'Direction'} />
        </ListItem>
        <ListItem>
          <RadioGroup
            aria-label="direction"
            value={liveSummaryFilter.order.direction}
            onChange={(evt) => handleOrder(evt, 'direction')}
          >
            <FormControlLabel
              value="asc"
              control={<Radio />}
              label="Ascending"
            />
            <FormControlLabel
              value="desc"
              control={<Radio />}
              label="Descending"
            />
          </RadioGroup>
        </ListItem>
      </Menu>

      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorExport}
        keepMounted
        open={Boolean(anchorExport)}
        onClose={(evt) => handleCloseMenu('export')}
      >
        <ListItem className={classes.listItemHeader}>
          <ListItemText primary={'Delimiter'} />
        </ListItem>
        <ListItem>
          <RadioGroup
            aria-label="delimiter"
            value={delimiter}
            onChange={(evt) => handleExportState(evt, 'delimiter')}
          >
            <FormControlLabel
              value="comma"
              control={<Radio />}
              label="Comma ,"
            />
            <FormControlLabel
              value="semicolon"
              control={<Radio />}
              label="Semicolon ;"
            />
          </RadioGroup>
        </ListItem>
        <Divider />
        <ListItem>
          <Button onClick={(evt) => handleCloseMenu('export')}>Cancel</Button>
          &nbsp;
          <Button color="primary" onClick={handleExport}>
            Export
          </Button>
        </ListItem>
      </Menu>
    </div>
  );
};

export default ToolbarSection;
