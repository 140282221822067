import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { styles } from "../Style/StyleMyAttendance";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import ContextMyAttendance from "../Context/ContextMyAttendance";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const PasswordDialog = ({
  handleClose,
  open,
  verification,
  passwordValue,
  setPasswordValue,
  handleChange,
  handleClickShowPassword,
  handleMouseDownPassword,
  loader,
}) => {
  const classes = useStyles();
  const context = useContext(ContextMyAttendance);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {"Masukkan Password Anda"}
        </DialogTitle>
        <DialogContent>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              type={passwordValue.showPassword ? "text" : "password"}
              value={passwordValue.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwordValue.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            className={classes.fixedBottomButton}
            style={{
              backgroundColor:
                passwordValue.password === "" ? "#808080" : "#262261",
              color: "#ffffff",
            }}
            onClick={() => passwordValue.password !== "" && verification()}
            disabled={loader || passwordValue.password === ""}
          >
            <b>Input</b>
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default PasswordDialog;
