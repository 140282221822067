import React, { useContext, useState } from "react";
import { createMuiTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";

import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";
import ChipGroupBy from "./ChipGroupBy";
import { Box } from "@material-ui/core";
import ChipUser from "./ChipUser";
import ChipUnit from "./ChipUnit";
import ChipPosition from "./ChipPosition";
import ChipWorkPattern from "./ChipWorkPattern";
import ChipShift from "./ChipShift";
import ChipSchedule from "./ChipSchedule";
import ChipOrderBy from "./ChipOrderBy";

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      clickableColorSecondary: {
        "&:hover, &:focus": {
          backgroundColor: "#9b9baf",
        },
        "&:active": {
          backgroundColor: "#83829C",
        },
      },
    },
  },
});

const ChipFilter = () => {
  const context = useContext(ContextAttendanceReportAdmin);

  return (
    <MuiThemeProvider theme={theme}>
      <Box>
        <ChipGroupBy />
      </Box>
      {
        context.dataSettingSummary.filter.user_name.length > 0 && (
          <Box ml={1}>
            <ChipUser />
          </Box>
        )
      }
      {
        context.dataSettingSummary.filter.unit_name.length > 0 && (
          <Box ml={1}>
            <ChipUnit />
          </Box>
        )
      }
      {
        context.dataSettingSummary.filter.position_name.length > 0 && (
          <Box ml={1}>
            <ChipPosition />
          </Box>
        )
      }
      {
        context.dataSettingSummary.filter.work_pattern_name.length > 0 && (
          <Box ml={1}>
            <ChipWorkPattern />
          </Box>
        )
      }
      {
        context.dataSettingSummary.filter.shift_name.length > 0 && (
          <Box ml={1}>
            <ChipShift />
          </Box>
        )
      }
      {
        context.dataSettingSummary.filter.schedule_name.length > 0 && (
          <Box ml={1}>
            <ChipSchedule />
          </Box>
        )
      }
      <Box ml={1}>
        <ChipOrderBy />
      </Box>
    </MuiThemeProvider>
  );
};

export default ChipFilter;
