import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  Checkbox,
  Badge,
  TextField,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";

import clsx from "clsx";
import ListItemUser from "./ListItemUser";
import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const UserFilter = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  // ===========================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ | STATE | ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================================================
  const [selected, setSelected] = useState([]);
  const [anchorUser, setAnchorUser] = useState(null);
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);

  const itemCount = context.masterFilterReportDetail.users.length;
  const isSelected = (id) => selected.indexOf(id) !== -1;
  
  const handleShowAnchorUser = (event) => {
    setAnchorUser(event.currentTarget);
  };

  const handleCloseAnchorUser = () => {
    setAnchorUser(null);
  };
  
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.masterFilterReportDetail.users.map(
        (n) => n.id
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterUser = (e) => {
    setUser(e.target.value);
  };

  const handleShowResult = () => {
    let dataTemporary         = context.dataSettingDetail;
    dataTemporary.filter.user = selected;
    let userName              = [];
    context.masterFilterReportDetail.users.map((item) => {
      if (selected.includes(item.id)) {
        const fullName = `${item.member && item.member.first_name ? item.member.first_name : ""} ${item.member && item.member.last_name ? item.member.last_name : ""}`;
        userName.push(fullName);
      }
      return 0;
    })
    dataTemporary.filter.user_name  = userName;
    dataTemporary.paging.offset     = 0;
    dataTemporary.paging.page       = 1;
    context.setDataSettingDetail({...dataTemporary});
    context.getReportDetailList();
    handleCloseAnchorUser();
  };

  // ===========================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ | Side Effects | ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================================================
  React.useEffect(() => {
    if (anchorUser !== null) {
      const getUser = context.masterFilterReportDetail.users;

      const filterUser =
        getUser.length > 0 &&
        getUser.filter((item) => {
          const fullName = `${item.member && item.member.first_name ? item.member.first_name : ""} ${ item.member && item.member.last_name ? item.member.last_name : ""}`;
          const userName =
            fullName.toLowerCase().indexOf(user.toLowerCase()) !== -1;

          return userName;
        });
      setUsers(filterUser);
    }
  }, [anchorUser, user]);

  // ===========================================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ | RENDER | ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ===========================================================================================
  return (
    <>
      <StyledBadge
        badgeContent={selected.length}
        children={
          <Button
            variant="outlined"
            size="small"
            startIcon={<PersonIcon style={{ fontSize: 15 }} />}
            className={classes.actionBtn}
            disabled={context.loader}
            onClick={handleShowAnchorUser}
          >
            User
          </Button>
        }
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorUser}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorUser)}
        style={{ marginBottom: 8, width: 320 }}
        onClose={handleCloseAnchorUser}>
        <Box mb={1.5} mt={1.5} ml={2} mr={2}>
          <InputBase
            placeholder="Type user's name"
            variant="outlined"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            style={{ width: 250 }}
            onChange={handleFilterUser}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginLeft={1}
          marginRight={6}>
          <Box display="flex" alignItems="center">
            <Checkbox
              disableRipple
              indeterminate={selected.length > 0 && selected.length < itemCount}
              checked={itemCount > 0 && selected.length === itemCount}
              onChange={handleSelectAllClick}
              className={classes.checkboxRoot}
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              color="default"
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
            <Typography variant="subtitle2" className={classes.title555}>
              All
            </Typography>
          </Box>
          <Typography
            className={classes.btnTxt}
            onClick={() => setSelected([])}>
            None
          </Typography>
        </Box>
        {users.length > 0 &&
          users.slice(0, 10).map((item, i) => (
            <div key={i} className={classes.listItemMenu}>
              <ListItemUser
                classes={classes}
                index={i}
                user={item}
                selected={selected}
                setSelected={setSelected}
                isSelected={isSelected}
              />
            </div>
          ))}
        <Box margin={1.5} mt={1}>
          <Divider />
        </Box>

        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorUser}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResult}>
            {context.loader ? "Showing..." : "Show results"}
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default UserFilter;
