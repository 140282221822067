import { grey, deepPurple, green, cyan } from "@material-ui/core/colors";

export const styles = (theme) => ({
  /*
        `````````````````````
        STYLING AVATAR HEADER

        `````````````````````
    */

  avatarMyCompany: {
    // backgroundSize: 'cover',
    // backgroundPosition: 'center center',
    // backgroundRepeat: 'no-repeat',
    height: 100,
    width: 100,
    color: "white",
    backgroundColor: cyan[500],
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(-32),
    zIndex: 1100,
    // position: 'absolute'
  },
  titleMyCompanyLogo: {
    fontFamily: "Roboto",
    color: "white",
  },

  titleEmployeeHeader: {
    fontFamily: "Roboto",
    color: "white",
    zIndex: 1200,
    position: "absolute",
    // marginTop: theme.spacing(16),
    // marginTop: theme.spacing(-16),
  },
  titleEmployeeHeaderJabatan: {
    fontFamily: "Roboto",
    color: "white",
    zIndex: 1200,
    position: "absolute",
    marginLeft: theme.spacing(2),
    // marginTop: theme.spacing(-16),
    marginTop: theme.spacing(4),
  },
  titleTambahAnggotaKeluarga: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: "#cc0707",
    cursor: "pointer",
  },
  buttonInvite: {
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    zIndex: 1200,
    // position: 'absolute',
    // marginLeft: theme.spacing(-18),
    marginTop: theme.spacing(-10),
    marginRight: theme.spacing(3),
    "&:hover": {
      backgroundColor: "#55dc87",
      borderWidth: 1,
      borderColor: "#55dc87",
    },
  },

  buttonEksportPDF: {
    fontFamily: "Roboto",
    color: "#cc0707",
    textTransform: "capitalize",
    fontWeight: "bold",
    borderColor: "#cc0707",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // marginRight:theme.spacing(7)
  },

  /*
        ```````````````````````
        STYLING IDENTITAS DASAR

        ```````````````````````        
    */

  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    // width: '503px',
    // height: '42px',s
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "white",
    textTransform: "capitalize",
    "&:disabled": {
      background: "grey",
      color: "white",
    },
  },
  buttonOutlined: {
    borderRadius: 5,
    // background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "grey",
    // color: '#d2d2d2',
    textTransform: "capitalize",
    "&:hover": {
      color: "grey",
      background: "#d2d2d2",
      borderWidth: 0,
    },
  },
  title: {
    fontFamily: "Roboto",
  },
  titleTextField: {
    fontFamily: "Roboto",
    color: grey[900],
    // fontWeight: 'bold'
  },
  titleHeader: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop:  theme.spacing(2),
  },
  titleForm: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop: theme.spacing(2),
  },
  titleActive: {
    color: "#cc0707",
    fontFamily: "Roboto",
  },
  titleNotActive: {
    fontFamily: "Roboto",
    color: "grey",
  },
  paperInfoIdentitas: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  paperInfoDasar: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  paperProfileDetail: {
    width: "95%",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
  },

  /*
        ````````
        FORM

        ````````
    */
  textField: {
    minWidth: 425,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
    // fontFamily: 'Roboto'
  },
  list: {
    // padding: theme.spacing(5, 2),
    // marginTop: theme.spacing(4),
    // width: 475,
    width: 675,
    borderRadius: 7,
    fontFamily: "Roboto",
  },
  textFieldAvatar: {
    minWidth: 125,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  realAvatar: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(25),
  },
  bigAvatar: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(25),
    cursor: "pointer",
  },
  bigAvatarSpan: {
    color: "#cc0707",
    cursor: "pointer",
    marginRight: theme.spacing(15),
  },

  /*
        ````````````````
        INFO ERROR PAPER

        ````````````````
    */
  paperStyle: {
    // marginLeft : theme.spacing(3),
    // marginRight : theme.spacing(3),
    // marginBottom : theme.spacing(3),
    backgroundColor: "#ffcbcb",
  },
  titlePaperStyle: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    // marginLeft: theme.spacing(3),
    fontFamily: "Roboto",
    color: "#a50016",
  },
  closePaperStyle: {
    color: "#a50016",
  },

  /*
        DIALOG EDIT

    */

  iconEdit: {
    marginRight: theme.spacing(5),
  },
  textField: {
    minWidth: 425,
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  buttonModal: {
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
  },
  buttonModalCancel: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  buttonOutlined: {
    borderRadius: 5,
    // background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "grey",
    // color: '#d2d2d2',
    textTransform: "capitalize",
    "&:hover": {
      color: "grey",
      background: "#d2d2d2",
      borderWidth: 0,
    },
  },

  /*
        `````````
        IDENTITAS

        `````````
    */
  //    'input': {
  //         '&::placeholder': {
  //         textOverflow: 'ellipsis !important',
  //         color: 'cyan'
  //         }
  //     }
  // '&::-webkit-input-placeholder': { color: 'blue' }
});
