
import React,{Component, useState, useEffect, useContext, useRef} from 'react';
import { Container, Paper, Typography, Grid, TextField, Checkbox, FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core';
// import axios from 'axios';
// import { URL_API } from '../../../../constants/config-api';

const DialogDeleteUrgentContact = (props) => {
    
    const { 

            classes, 
            modalDelete, 
            setModalDelete, 
            dataDetailDelete, 
            listData, 
            setListData 
        
        } = props;

    const [ namaAnggotaKeluarga, setNamaAnggotaKeluarga ] = useState('');

    useEffect(() => {

        if(modalDelete == true){

            setNamaAnggotaKeluarga(dataDetailDelete.name_anggota_keluarga);
        };
        
    }, [modalDelete]);
    
    const [ isDeletedWorked, setDeleteWorked ] = useState(false); 

    const handleDelete = () => {
        
        setDeleteWorked(true);

        console.log('dataDetailDelete : ', dataDetailDelete);

        // props.setListTingkatJabatan(props.listTingkatJabatan.filter(item => item.id !== idTingkatJabatan));
        setListData(listData.filter(item => item.id !== dataDetailDelete.id));
        setModalDelete(false);
    };

    useEffect(() => {

        if(isDeletedWorked == true ){

            localStorage.setItem('employee_data_kontak_darurat', JSON.stringify(listData))
        };
        
    }, [isDeletedWorked])
    
    return (
        <Dialog
            open={modalDelete}
            onClose={() => setModalDelete(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            
        >
            <DialogTitle id="alert-dialog-title" style={{textAlign: "left"}}>
              
            </DialogTitle>
            <DialogContent style={{textAlign: "center"}}>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant='subtitle1' className={classes.title} style={{color: 'black'}}>
                        <b>Apakah Anda yakin ingin menghapus <i>{ namaAnggotaKeluarga } </i> ?</b>
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{alignItems: "center", justifyContent:'center'}}>
                <Button 
                    onClick={handleDelete}
                    variant='contained' 
                    className={classes.buttonModalDelete}
                    // fullWidth
                >  
                    Yakin
                </Button>
            </DialogActions>
            <br />
        </Dialog>
    )
};

export default DialogDeleteUrgentContact;
