import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Box,
  Typography,
  LinearProgress,
} from "@material-ui/core";

import TablePersonalHeader from "./TablePersonalHeader";
import ContextProject from "../../context/ContextProject";
import RowPersonals from "./RowPersonals";
import RowPersonalItem from "./RowPersonalItem";
import PaginateProject from "./PaginateProject";

const TablePersonal = ({ classes }) => {
  const { projectTState } = useContext(ContextProject);
  // const statusUserLogin = localStorage.getItem("status_user_login");
  // const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  // const role = statusUserLoginAfterParse.userRole[0].name;
  // const superior =
  //   role === "superadmin"
  //     ? true
  //     : statusUserLoginAfterParse?.member_position !== null
  //     ? statusUserLoginAfterParse?.member_position.is_superior
  //     : false;

  const personals =
    projectTState?.projectData?.length > 0 &&
    projectTState.projectData.map((el) => {
      return {
        name: el.name.name,
        member: el.name.member,
        personal_project: el.personal_project,
      };
    });

  const mypersonal = projectTState?.personalData;

  // // console.log("PERSONS", personals);

  return (
    <div>
      <TableContainer>
        {projectTState.loadProject && <LinearProgress />}

        <Table size="small">
          <Box p={2} />
          <TablePersonalHeader />
          {personals.length > 0 &&
            personals.map((personal, i) => (
              <TableBody key={i}>
                <RowPersonals row={personal} origin="personal" />
                {personal.personal_project.length > 0
                  ? personal.personal_project.map((cell, j) => (
                      <RowPersonalItem key={j} cell={cell} origin="personal" />
                    ))
                  : null}
              </TableBody>
            ))}

          {mypersonal?.length > 0 ? (
            <TableBody>
              <RowPersonals row={mypersonal[0]} origin="mypersonal" />
              {mypersonal.map((personal, i) => (
                <RowPersonalItem key={i} cell={personal} origin="mypersonal" />
              ))}
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
        {mypersonal?.length === 0 &&
          projectTState.projectData.length === 0 &&
          !projectTState.loadProject && (
            <Typography variant="subtitle2" className={classes.title888}>
              Data not found
            </Typography>
          )}
      </Box>

      <PaginateProject classes={classes} />
    </div>
  );
};

export default TablePersonal;
