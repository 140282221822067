import React from "react";
import { Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { title } from "../../Style/styleKIt";

import supportCircle from "../../../../assets/images/support-circle.png";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  titleLink: {
    ...title,
    textDecoration: "none",
    color: "#555",
    "&:hover": {
      color: "#10266F",
      cursor: "pointer",
    },
  },
});

const useStyle = makeStyles(styles);

const MapLinks = () => {
  const classes = useStyle();

  return (
    <Box mb={15} mt={-30}>
      <Box
        display="flex"
        justifyContent="space-between"
        maxWidth={1400}
        width="100%"
        position="relative"
        style={{ margin: "0 auto" }}
        bottom={-350}
        zIndex={-1}>
        <img src={supportCircle} height={200} />
        <div />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        maxWidth={1400}
        width="100%"
        position="relative"
        style={{ margin: "0 auto" }}
        bottom={-280}
        zIndex={-1}>
        <div />
        <img src={supportCircle} height={200} />
      </Box>
      <Container>
        <Grid container direction="row" spacing={6}>
          {listMapLinks.map((item, i) => {
            return (
              <Grid key={i} item xs={12} sm={12} md={4}>
                <Paper elevation={2}>
                  <Box p={3}>
                    <Typography
                      variant="subtitle1"
                      className={classes.title333}>
                      <b>{item.name}</b>
                    </Typography>

                    <Box mt={2}>
                      {item.links.map((link, j) => (
                        <Box key={j} mb={0.8}>
                          <Typography
                            variant="subtitle2"
                            className={classes.titleLink}>
                            {link.label}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default MapLinks;

const listMapLinks = [
  {
    name: "FAQ",
    links: [
      {
        label: "Apa itu Performate.id ?",
      },
      {
        label: "Berapa lama durasi coba gratis..",
      },
      {
        label: "Apakah performate ada versi M..",
      },
      {
        label: "Apa yang dimaksud dengan M..",
      },
      {
        label: "Mengapa saya harus pilih Perf..",
      },
      {
        label: "Apa saja layanan performate ?",
      },
    ],
  },
  {
    name: "Tutorial",
    links: [
      {
        label: "Pembuatan akun Performate",
      },
      {
        label: "Membuat Struktur Organisasi",
      },
      {
        label: "Pembuatan Goal",
      },
      {
        label: "Input Goal result",
      },
      {
        label: "Pembuatan Measure Activity",
      },
      {
        label: "Input Measure Activity result",
      },
    ],
  },
  {
    name: "Solusi Bisnis",
    links: [
      {
        label: "Sales Tracking",
      },
      {
        label: "Marketing Goals",
      },
      {
        label: "Finance",
      },
      {
        label: "Human Resource",
      },
      {
        label: "Customer Service",
      },
      {
        label: "Operational",
      },
    ],
  },
];
