import React, { useState, useContext, Fragment } from "react";
import {
  Typography,
  Checkbox,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TableRow,
  TableCell,
  ListItemIcon,
} from "@material-ui/core";

import { navigate } from "hookrouter";

import ErrorIcon from "@material-ui/icons/Error";
import PersonIcon from "@material-ui/icons/Person";

import moment from "moment";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import LightTooltip from "../../../../components/LightTooltip";
import CircleInPercentNEW from "../../../../components/CircleInPercentNEW";

import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";
import TruncateTextShort from "../../../../utilities/TruncateTextShort";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import PictResultValue from "../../../../assets/images/Group_2620.png";
import IconRedirect from "../../../../assets/images/icon_redirect.png";
import {
  FormatDecimal,
  FormatThousand,
} from "../../../../utilities/FormatDecimal";

const numeral = require("numeral");

const ListItemSubGoal = (props) => {
  const { classes, row, setSelected, selected, isSelected, key } = props;

  const context = useContext(ContextGoalDetail);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  /*
        ```````````````````````
        HANDLE DELETE MULTIPLE 

        ```````````````````````
    */

  const handleChangeCheckbox = (e, row) => {
    //*
    context.setSuccessDeleteSubGoal(false);
    context.setSuccessSetAsComplete(null);

    // context.setListSubGoalId(newIds);

    const selectedIndex = selected.indexOf(row.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // // console.log("Item checkbox - munltiple choose:: : ", newSelected);

    setSelected(newSelected);

    context.setListSubGoalId(newSelected);
  };

  /*
        `````````````
        HANDLE DETAIL

        ````````````
    */

  const handleDetail = (e, data) => {
    e.preventDefault();
    // localStorage.setItem("goal_detail", JSON.stringify(data));

    navigate(`/sub-goal-detail/${data.id}`);
    // navigate(ToGoalDetail, true, {id: data.id});
    window.location.reload();
  };

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
    =============================================================================================== */

  let viewDetailAllowed = false;
  let setCompleteAllowed = false;

  row.policy &&
    row.policy.map((isAllowed) => {
      if (isAllowed.actionName === "viewDetail") {
        viewDetailAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "completed") {
        setCompleteAllowed = isAllowed.result;
      }
    });

  // isTemSelected & Label Id
  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${key}`;

  // console.log("ITM selected", isItemSelected);

  return (
    <Fragment>
      <TableRow
        key={row.id}
        selected={isItemSelected}
        aria-checked={isItemSelected}
        tabIndex={-1}
        role="checkbox"
      >
        {superior && (
          <TableCell
            onClick={(e) => handleChangeCheckbox(e, row)}
            component="th"
            scope="row"
            size="small"
            padding="checkbox"
          >
            <Checkbox
              inputProps={{ "aria-labelledby": labelId }}
              disabled={!setCompleteAllowed}
              checked={isItemSelected}
            />
          </TableCell>
        )}

        <TableCell component="th" scope="row" size="small" padding="none">
          <Box width={56}>
            <div className={classes.circularProgressBar}>
              <CircleInPercentNEW classes={classes} item={row} />
            </div>
          </Box>
        </TableCell>

        <TableCell component="th" scope="row" size="small" padding="none">
          <List>
            <ListItem
            // style={{paddingLeft: 0, marginRight: 24}}
            >
              <ListItemText
                className={classes.listItemtext}
                primary={
                  <LightTooltip title={row.name} placement="right-start">
                    <Typography variant="subtitle2" className={classes.title}>
                      <img src={IconRedirect} alt="Icon Redirect" /> &nbsp;
                      <b
                        style={{
                          color: row.status_id === "4" ? "#aaa" : "#000",
                        }}
                      >
                        {TruncateTextShortNameGoal(row.name)}
                      </b>
                      {row.targetValueSyncStatus.name !== "sync" ||
                      row.startValueSyncStatus.name !== "sync" ? (
                        <LightTooltip
                          title="Value subgoal dan main goal tidak sama"
                          placement="bottom-start"
                        >
                          <ErrorIcon
                            fontSize="small"
                            style={{
                              color: "red",
                              marginLeft: 2,
                              marginTop: 2,
                            }}
                          />
                        </LightTooltip>
                      ) : null}
                    </Typography>
                  </LightTooltip>
                }
                secondary={
                  <Typography variant="subtitle2" className={classes.title}>
                    {
                      // *PERIOD
                      row.period !== null && row.period.name
                    }

                    {
                      // *DATE RANGE
                      row.start_date !== null &&
                        row.end_date !== null &&
                        row.period === null && (
                          <LightTooltip
                            title={
                              moment(row.start_date).format("DD MMMM YYYY") +
                              " - " +
                              moment(row.end_date).format("DD MMMM YYYY")
                            }
                            placement="right-start"
                          >
                            <span>
                              {TruncateTextShort(
                                moment(row.start_date).format("DD MMMM YYYY") +
                                  " - " +
                                  moment(row.end_date).format("DD MMMM YYYY")
                              )}
                            </span>
                          </LightTooltip>
                        )
                    }

                    {
                      // *DUE DATE
                      row.start_date === null &&
                        row.end_date !== null &&
                        row.period === null &&
                        moment(row.end_date).format("DD MMMM YYYY")
                    }
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </TableCell>

        <TableCell component="th" scope="row" size="small" padding="none">
          <List
          // style={{width: 208}}
          >
            <ListItem
              style={{
                // marginRight: 24,
                paddingLeft: 0,
              }}
            >
              <ListItemIcon
                style={{
                  marginLeft: 8,
                }}
              >
                <IconButton size="small" style={{ backgroundColor: "#dbdbdb" }}>
                  {row.member !== null &&
                  row.member.photo_url !== null &&
                  row.member.self.rel.photo_url !== null ? (
                    <ImageSmallUser
                      item={row.member.self.rel.photo_url}
                      index={1}
                    />
                  ) : (
                    <PersonIcon color="#c4c5c4" />
                  )}
                </IconButton>
              </ListItemIcon>

              <ListItemText
                className={classes.listItemtext}
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b
                      style={{
                        color: row.status_id === "4" ? "#aaa" : "#000",
                      }}
                    >
                      {row.member !== null
                        ? row.member.first_name + " "
                        : row.owner.member_first_name}
                      {row.member !== null
                        ? row.member.last_name
                        : row.owner.member_first_name}
                    </b>
                  </Typography>
                }
                secondary={
                  <LightTooltip
                    title={
                      row.owner ? row.owner.structure_position_title_name : "-"
                    }
                    placement="left-start"
                  >
                    <Typography variant="subtitle2" className={classes.title}>
                      {row.owner
                        ? TruncateTextShort(
                            row.owner.structure_position_title_name
                          )
                        : "-"}
                    </Typography>
                  </LightTooltip>
                }
              />
            </ListItem>
          </List>
        </TableCell>

        <TableCell component="th" scope="row" size="small" padding="none">
          <List>
            <ListItem
            // style={{
            //     marginRight: 24,
            //     paddingLeft: 0
            // }}
            >
              <ListItemIcon style={{ marginLeft: 8 }}>
                <img
                  src={PictResultValue}
                  style={{ width: 32, height: 32 }}
                  alt="Pict"
                />
              </ListItemIcon>

              <ListItemText
                className={classes.listItemtext}
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    <b
                      style={{
                        color: row.status_id === "4" ? "#aaa" : "#000",
                      }}
                    >
                      Result Value
                    </b>
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2" className={classes.title}>
                    {FormatDecimal(row.result_value)}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </TableCell>

        <TableCell component="th" scope="row" size="small" padding="none">
          <List
          // className={classes.layoutMarginLeftList}
          >
            <ListItem
              alignItems="center"
              // style={{paddingLeft: 0, marginRight: 24}}
            >
              <ListItemText
                // noWrap
                className={classes.listItemtext}
                primary={
                  <Typography variant="subtitle2" className={classes.title}>
                    Terakhir update &nbsp;
                    <Button
                      onClick={(e) => handleDetail(e, row)}
                      disabled={!viewDetailAllowed}
                      variant="outlined"
                      size="small"
                      className={classes.moreDetail}
                    >
                      <b>More Detail</b>
                    </Button>
                  </Typography>
                }
                secondary={
                  <LightTooltip
                    title={`Terakhir update ${moment(row.created_at).format(
                      "DD MMMM YYYY h:mm:ss a"
                    )}`}
                    placement="left-start"
                  >
                    <Typography variant="subtitle2" className={classes.title}>
                      {row.last_goal_result_date !== null
                        ? TruncateTextShort(
                            moment(row.last_goal_result_date).format(
                              "DD MMMM YYYY h:mm:ss a"
                            )
                          )
                        : // moment(row.last_goal_result_date, 'YYYYMMDD').fromNow()
                          "-"}
                    </Typography>
                  </LightTooltip>
                }
              />
            </ListItem>
          </List>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default ListItemSubGoal;
