import React, { Fragment } from "react";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const IconSisaWaktu = (props) => {
  const { classes, goalDetailState } = props;

  return (
    <Fragment>
      <ListItemIcon
        style={{
          marginLeft: 8,
        }}>
        <IconButton
          style={{ backgroundColor: "#aed9ff", padding: "7px", opacity: 0.5 }}>
          <CalendarTodayIcon style={{ color: "orange" }} />
        </IconButton>
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}>
            Sisa waktu
          </Typography>
        }
        secondary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}>
            {goalDetailState.time.remaining_days} hari
          </Typography>
        }
      />
    </Fragment>
  );
};

export default IconSisaWaktu;
