import React, { Fragment, useContext } from "react";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

const IconStartValue = (props) => {
  const { classes, goalDetailState } = props;
  const context = useContext(ContextGoalDetail);

  return (
    <Fragment>
      <ListItemIcon
        style={{
          marginLeft: 8,
        }}>
        <IconButton style={{ backgroundColor: "#bdffde", padding: "6px" }}>
          <PlayArrowIcon color="#36d686" />
        </IconButton>
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}>
            Start Value
          </Typography>
        }
        secondary={
          <LightTooltip
            title={
              goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE
                ? "Start Value : " +
                  FormatDecimal(goalDetailState.calculatedValue.start_value) +
                  " %"
                : context.modeRupiahOn === true
                ? `Start Value : Rp ${FormatDecimal(
                    goalDetailState.calculatedValue.start_value
                  )}`
                : "Start Value : " +
                  FormatDecimal(goalDetailState.calculatedValue.start_value)
            }
            placement="bottom-start">
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "white" }}>
              <b>
                {goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE
                  ? FormatDecimal(goalDetailState.calculatedValue.start_value) +
                    " %"
                  : context.modeRupiahOn === true
                  ? `Rp ${FormatDecimal(
                      goalDetailState.calculatedValue.start_value
                    )}`
                  : FormatDecimal(goalDetailState.calculatedValue.start_value)}
              </b>
            </Typography>
          </LightTooltip>
        }
      />
    </Fragment>
  );
};

export default IconStartValue;
