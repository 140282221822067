import React, { useState, useContext, useEffect } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  withStyles,
  InputBase,
  Box,
  Typography,
  Divider,
  IconButton,
  Menu,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

import { AntTabs, AntTab } from "./tabStyle";
import moment from "moment";

import ContextAttendanceReportAdmin from "../../Context/ContextAttendanceReportAdmin";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    position: "relative",
    fontSize: 19,
    padding: "2px 16px 4px 8px",
    fontFamily: ["Roboto", '"Segoe UI"', "Roboto"].join(","),
  },
}))(InputBase);

const ReportType = ({ classes }) => {
    const context = useContext(ContextAttendanceReportAdmin);
    const [anchorConfig, setAnchorConfig] = useState(null);

    const handleShowAnchorConfig = (event) => {
      setAnchorConfig(event.currentTarget);
    };

    const handleCloseAnchorConfig = () => {
      setAnchorConfig(null);
    };

    const handleValueTab = (e, newVal) => {
      let reportState = context.reportState;
      reportState.valueTab = newVal;
      if (newVal === 0) {
        let data            = context.dataSettingSummary;
        data.date_range     = {
          start_date  : moment().startOf('month').format('YYYY-MM-DD'),
          end_date    : moment().format('YYYY-MM-DD')
        };
        data.date_range_obj = {
          title: "This Month",
          code: "this_month",
          start_date: new Date(moment().startOf("months").format('YYYY-MM-DD HH:mm:ss')),
          end_date: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
        };
        data.group          = "staff_name";
        data.group_selected = "staff_name";
        data.filter         = {
            unit: [],
            user: [],
            position: [],
            work_pattern: [],
            schedule: [],
            shift: [],
            unit_name: [],
            user_name: [],
            position_name: [],
            work_pattern_name: [],
            schedule_name: [],
            shift_name: []
        };
        data.order_by       = {
            field : "name",
            method : "ASC"
        };
        data.paging         = {
          limit: 5,
          offset: 0,
          count: 0,
          page: 1
        };
        context.setDataSettingSummary({...data}); 
        context.getReportSummaryList();
      } else if (newVal === 1) {
        let data            = context.dataSettingDetail;
        data.date_range     = {
          start_date  : moment().startOf('month').format('YYYY-MM-DD'),
          end_date    : moment().format('YYYY-MM-DD')
        };
        data.date_range_obj = {
          title: "This Month",
          code: "this_month",
          start_date: new Date(moment().startOf("months").format('YYYY-MM-DD HH:mm:ss')),
          end_date: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
        };
        data.filter         = {
          user: [],
          unit: [],
          position: [],
          work_pattern: [],
          schedule: [],
          shift: [],
          unit_name: [],
          user_name: [],
          position_name: [],
          work_pattern_name: [],
          schedule_name: [],
          shift_name: []
        };
        data.order_by       = {
            field : "user_name",
            method : "ASC"
        };
        data.paging         = {
          limit: 5,
          offset: 0,
          count: 0,
          page: 1
        };
        context.setDataSettingDetail({...data}); 
        context.getReportDetailList();
      }
      context.setReportState({...reportState});
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={3}>
                <Box display="flex">
                    <FormControl style={{ marginRight: 18 }}>
                        <MenuItem dense value="live_report" className={classes.hoverItem}>
                            <Typography variant="h6">Report</Typography>
                        </MenuItem>
                    </FormControl>
                    <Divider orientation="vertical" flexItem />
                    <Box marginLeft={3}>
                        <AntTabs
                            value={context.reportState.valueTab}
                            onChange={handleValueTab}
                            aria-label="ant example"
                        >
                            {context.reportState.reportCategories.length > 0 &&
                                context.reportState.reportCategories
                                .map((tab, i) => <AntTab key={i} label={tab.name} />)}
                        </AntTabs>
                    </Box>
                </Box>
                <Box pr={5}>
                  <Box display="flex" gridGap={6}>
                    <IconButton
                      className={classes.btnConfigMini}
                      size="small"
                      onClick={handleShowAnchorConfig}>
                      <SettingsIcon fontSize="small" style={{ color: "#8F8F8F" }} />
                    </IconButton>

                    <Menu
                      id="simple-menu"
                      anchorEl={anchorConfig}
                      keepMounted
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      getContentAnchorEl={null}
                      open={Boolean(anchorConfig)}
                      onClose={handleCloseAnchorConfig}>
                        { 
                          context.reportState.valueTab === 0 &&
                          <MenuItem
                            style={{ width: 220 }}
                            onClick={context.exportSummary}
                          >
                            <ExitToAppOutlinedIcon
                              fontSize="small"
                              style={{ color: "#8F8F8F", marginRight: 10 }}
                            />
                            <Typography variant="subtitle2" className={classes.title}>
                              Export Summary
                            </Typography>
                          </MenuItem>
                        }
                        { 
                          context.reportState.valueTab === 1 &&
                          <MenuItem
                            style={{ width: 220 }}
                            onClick={context.exportDetailed}
                          >
                            <ExitToAppOutlinedIcon
                              fontSize="small"
                              style={{ color: "#8F8F8F", marginRight: 10 }}
                            />
                            <Typography variant="subtitle2" className={classes.title}>
                              Export Detailed
                            </Typography>
                          </MenuItem>
                        }
                    </Menu>
                  </Box>
                </Box>
            </Box>
        </>
    );
};

export default ReportType;
