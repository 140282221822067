const mapTable = (arr) => {
  // // console.log("ARR DATS", arr);

  const data =
    arr !== undefined &&
    arr.length > 0 &&
    arr.map((item) => {
      return {
        name: item,
        status: item.status,
        owner: {
          full_name:
            item.member !== null
              ? `${item.member?.first_name} ${item.member?.last_name}`
              : "-",
          photo_url: item.member?.self?.rel?.photo_url_thumbnail,
        },
        start_date: item.start_date,
        end_date: item.end_date,
        // progress: {
        //   start: item.start_value,
        //   target: item.target_value,
        // },
        achievement: item.project,
        progress: item.project.progress,
        scope: item.project,
        schedule: item.project,
        action: item,
        personal_project: item.personal_project,
      };
    });

  return data.length > 0 ? data : [];
};

export default mapTable;
