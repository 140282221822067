import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import { URL_API } from "../../../../constants/config-api";

const DialogPlayAudio = ({
  classes,
  tile,
  dataStringTile,
  userToken,
  isOpenDialogAudioPlayer,
  setOpenDialogAudioPlayer,
}) => {
  const URL = URL_API + "/" + dataStringTile + "?token=" + userToken;

  return (
    <Dialog
      open={isOpenDialogAudioPlayer}
      onClose={() => setOpenDialogAudioPlayer(false)}
    >
      <DialogTitle>{tile.label}</DialogTitle>
      <DialogContent>
        <audio controls>
          <source src={URL} type="audio/mpeg" />
        </audio>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPlayAudio;
