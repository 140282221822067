import React, { useState, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useIndexedDB } from "react-indexed-db";
import DialogError from "../../../../components/DialogError";

const DialogDeleteBuktiGR = (props) => {
  const {
    classes,
    isOpenDialogDeleteBukti,
    setOpenDialogDeleteBukti,
    dataDetailDelete,
    listPictState,
    setListPictState,
  } = props;

  const { deleteRecord } = useIndexedDB("video");

  const [name, setName] = useState("");

  useEffect(() => {
    if (isOpenDialogDeleteBukti == true) {
      if (dataDetailDelete !== "") {
        // console.log("Data detail delete : ", dataDetailDelete);
        setName(dataDetailDelete.title);
      }
    }
  }, [isOpenDialogDeleteBukti]);

  const [loader, setLoader] = useState(false);
  const handleDelete = () => {
    setLoader(true);
    // setDeleteWorked(true);

    // console.log("dataDetailDelete : ", dataDetailDelete);

    deleteRecord(dataDetailDelete.id).then(
      (event) => {
        // console.log("Berhasil delete dari IndexedDB dengan ID : ", event);
        setListPictState(
          listPictState.filter((item) => item.id !== dataDetailDelete.id)
        );
        setOpenDialogDeleteBukti(false);

        setLoader(false);
      },
      (error) => {
        setLoader(false);
        console.log(error);
        // localStorage.removeItem("video_count")
      }
    );
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Dialog
      open={isOpenDialogDeleteBukti}
      onClose={() => setOpenDialogDeleteBukti(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "black" }}
        ></Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Apakah Anda yakin mau menghapus bukti <i>{name} </i> ?
            </b>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          onClick={handleDelete}
          variant="contained"
          className={classes.buttonModalDelete}
          // fullWidth
        >
          {loader !== true ? (
            "Yakin"
          ) : (
            <CircularProgress size={16} style={{ color: "white" }} />
          )}
        </Button>
      </DialogActions>
      <br />

      <DialogError
        classes={classes}
        textErrorInformation={textErrorInformation}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

// export default withStyles(styles)(DialogDeleteBuktiGR);
export default DialogDeleteBuktiGR;
