import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { navigate } from "hookrouter";

import { ToLogin } from "../../../../constants/config-redirect-url";

import PictInfo from "../../../../assets/images/icon-info-24px.svg";

const DialogModal422 = (props) => {
  const { classes, isResponse422, setResponse422, infoResponseError422 } =
    props;

  const handleBackToLogin = () => {
    navigate(ToLogin);
    setResponse422(false);
    window.location.reload();
  };

  return (
    <Dialog
      open={isResponse422}
      aria-labelledby="alert-dialog-title-422"
      aria-describedby="alert-dialog-description-422"
    >
      <DialogTitle id="alert-dialog-title-422" style={{ textAlign: "center" }}>
        <img
          src={PictInfo}
          alt="info-icon-pict-info"
          className={classes.media}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description-422">
          <Typography variant="h6">
            {infoResponseError422 != ""
              ? infoResponseError422
              : "Oops, something went wrong !"}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={handleBackToLogin}
          color="primary"
          size="small"
          className={classes.buttonModal}
        >
          Kembali ke Login
        </Button>
      </DialogActions>
      <br />
      <br />
    </Dialog>
  );
};

export default DialogModal422;
