import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Chip } from '@material-ui/core';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import SpeedIcon from '@material-ui/icons/Speed';

import numeral from 'numeral';

import CircularProgress from '../../../../../Report/components/report-type/summary-type/business-profile/CircularProgress';

const OverallProgress = ({
  classes,
  dashboardData,
  selectDashboardUnitLabel
}) => {
  const [overviewData, setOverviewData] = useState(null);

  useEffect(() => {
    if (dashboardData) {
      setOverviewData(dashboardData.overview);
    }
  }, [dashboardData]);

  let colorGoal;
  let colorMA;

  if (overviewData) {
    if (overviewData.goal_achievement_category === 'Achieved') {
      colorGoal = '#34cc80';
    } else if (overviewData.goal_achievement_category === 'Warning') {
      colorGoal = '#fab445';
    } else {
      colorGoal = '#e15367';
    }

    if (overviewData.ma_achievement_category === 'Achieved') {
      colorMA = '#34cc80';
    } else if (overviewData.ma_achievement_category === 'Warning') {
      colorMA = '#fab445';
    } else {
      colorMA = '#e15367';
    }
  }
  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Typography
          className={classes.title333}
          variant="subtitle1"
          style={{ textAlign: 'center' }}
        >
          <b>Progress {selectDashboardUnitLabel}</b>
        </Typography>

        <Box
          mt={4}
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ width: 120, height: 120 }}>
            {overviewData && (
              <CircularProgress average={overviewData.overview} />
            )}
          </div>
        </Box>

        <Typography
          className={classes.title555}
          variant="subtitle2"
          style={{ textAlign: 'center' }}
        >
          Anda telah mencapai{' '}
          {overviewData && (
            <b>
              {numeral(overviewData.goal_overall_result_achievement).format(
                '0.00'
              )}
              %
            </b>
          )}
          dari keseluruhan goal anda
        </Typography>

        <Box
          mt={3}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {overviewData && (
              <Chip
                size="small"
                variant="outlined"
                label={`${numeral(
                  overviewData.goal_overall_result_achievement
                ).format('0.00')}%`}
                icon={<TrackChangesIcon style={{ color: colorGoal }} />}
                style={{
                  marginRight: 5,
                  fontWeight: '600',
                  color: colorGoal
                }}
              />
            )}
            <Typography className={classes.title555} variant="caption">
              Rata-rata Goal
            </Typography>
          </Box>
          <Box m={2} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {overviewData && (
              <Chip
                size="small"
                variant="outlined"
                label={`${numeral(
                  overviewData.ma_overall_result_achievement
                ).format('0.00')}%`}
                icon={<SpeedIcon style={{ color: colorMA }} />}
                style={{
                  marginRight: 5,
                  fontWeight: '600',
                  color: colorMA
                }}
              />
            )}
            <Typography className={classes.title555} variant="caption">
              Rata-rata MA
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default OverallProgress;
