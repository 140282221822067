import React, { useEffect } from "react";
import { styles } from "../Style/StyleAttendanceAccount";
import { navigate } from "hookrouter";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button,
} from "@material-ui/core";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";
import {
  ToCompanySetting,
  ToLogin,
} from "../../../../constants/config-redirect-url";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ProfileCard = ({ data, handlePinDialogOpen }) => {
  const classes = useStyles();
  const [userImage, setUserImage] = React.useState(null);

  function handleLogOut() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("intro_enabled");

    localStorage.removeItem("verifyToken");
    localStorage.removeItem("status_user_login");
    localStorage.removeItem("goal_detail");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("response_employee_detail");
    localStorage.removeItem("project_details");

    localStorage.removeItem("value_search");
    localStorage.removeItem("value_search_ma");
    localStorage.removeItem("status_goal_id");
    localStorage.removeItem("email");
    localStorage.removeItem("appVersion");
    localStorage.removeItem("location_tab");
    localStorage.removeItem("main_goal_name");
    localStorage.removeItem("data_identitas");

    localStorage.removeItem("breadcrumb");
    localStorage.removeItem("directory_id");
    localStorage.removeItem("status_allma_id");
    localStorage.removeItem("setting_tab");
    localStorage.removeItem("org_tab");
    localStorage.removeItem("data_preview_so");

    localStorage.removeItem("goal_category");
    localStorage.removeItem("project_type");
    localStorage.removeItem("question_bantu_generate");

    localStorage.removeItem("summary_range");
    localStorage.removeItem("summary_user");
    localStorage.removeItem("filter_user");

    // localStorage.clear();
    navigate(ToLogin);
    window.location.reload();
  }

  useEffect(() => {
    axiosConfig
      .get(
        `${URL_API}/account-management/member/${data?.member_id}/photo?mode=thumbnail`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const image = new Blob([res.data], { type: "image/jpeg" });
        const imageUrl = URL.createObjectURL(image);
        setUserImage(imageUrl);
      });
  }, [data]);

  return (
    <MuiThemeProvider theme={theme}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background:
            "linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)",
          borderRadius: 11,
          padding: "10px 20px",
          marginTop: 100,
          marginLeft: 100,
          marginRight: 100,
          marginBottom: 20,
        }}
      >
        <CardContent
          style={{
            flex: "1 0 auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Avatar
                    src={userImage}
                    style={{
                      marginTop: 20,
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="h5"
                    style={{ color: "white", marginTop: 20 }}
                  >
                    Hai,{" "}
                    <b>
                      {data?.member_first_name + " " + data?.member_last_name}
                    </b>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    {data?.member_position?.structure_position_title_name}
                  </Typography>
                  <Typography variant="body2" style={{ color: "white" }}>
                    Di <b>{data?.group_name}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{
                  background: "#ffffff",
                  color: "#221E5B",
                  height: "50px",
                }}
                fullWidth
                onClick={handlePinDialogOpen}
              >
                <b>Change PIN</b>
              </Button>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    style={{
                      marginTop: "1rem",
                      width: "100%",
                      height: "3rem",
                      background: "#F5F5F5",
                      color: "#221E5B",
                    }}
                    onClick={() => navigate(ToCompanySetting)}
                  >
                    <b>Pengaturan</b>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    style={{
                      marginTop: "1rem",
                      width: "100%",
                      height: "3rem",
                      background: "#CE354A",
                      color: "#ffffff",
                    }}
                    onClick={handleLogOut}
                  >
                    <b>Sign Out</b>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
};

export default ProfileCard;
