import React from "react";
import { Box, CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const AnchorFilterGoal = ({
  classes,
  onGoalId,
  goalCollections,
  goalValue,
  onGoalValue,
  loadGoal,
  onClose,
}) => {
  const handleChooseGoal = (e, data) => {
    // // console.log("Period data", data);
    if (data !== null) {
      onGoalId({ id: data.id, name: data.name });
      //   onClose();
    } else {
      onGoalId({ id: "", name: "" });
    }
  };

  return (
    <Box minWidth={200}>
      <Autocomplete
        id="combo-box-demo"
        options={goalCollections}
        getOptionLabel={(option) => `${option.name}`}
        onChange={(e, period) => handleChooseGoal(e, period)}
        noOptionsText="Tidak ada goal"
        popupIcon={<ArrowDropDownIcon />}
        renderInput={(params) => (
          <TextField
            style={{ fontSize: 13 }}
            {...params}
            // fullWidth
            value={goalValue}
            onChange={(e) => onGoalValue(e.target.value)}
            variant="outlined"
            size="small"
            label="Cari Goal"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadGoal ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default AnchorFilterGoal;
