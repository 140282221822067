import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Grid,
  Divider,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  CircularProgress,
  Chip,
  Switch,
  Checkbox,
  Button,
  Popper,
  ClickAwayListener,
  Grow
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import classNames from 'classnames';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import { URL_API } from '../../../../../constants/config-api';
import axiosConfig from '../../../../../constants/config-axios';

import icon_high from '../../../../../assets/images/SVG/icon_high.svg';
import icon_medium from '../../../../../assets/images/SVG/icon_medium.svg';
import icon_low from '../../../../../assets/images/SVG/icon_low.svg';
import TruncateTextCustom from '../../../../../utilities/TruncateTextCustom';
import moment from 'moment';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const TaskInformation = ({
  classes,
  origin,
  icon,
  statusColor,
  ownerCollection,
  dependencies,
  loading,
  ownerId,
  statusId,
  priorityId,
  selectedDueDate,
  startDate,
  endDate,
  tags,
  actual,
  target,
  setOwnerId,
  setStatusId,
  setPriorityId,
  setSelectedDueDate,
  setStartDate,
  setEndDate,
  setTags,
  setActual,
  setTarget,
  duration,
  setDuration,
  loadDuration,
  toggle,
  setToggle,
  project,
  statusCollections,
  priorityCollections,
  status403,
  projectType,
  projectOwner,
  checkList,
  selected,
  setSelected,
  resultSource,
  completed
}) => {
  let colorStatus;
  let iconPrio;
  const statusUserLogin = localStorage.getItem('status_user_login');
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;

  const superior = role === 'superadmin' ? true : false;

  // // console.log("PD", projectDetailsfterParse);
  // // console.log("EXCL Wknd", project);

  const [toggleInfo, setToggleInfo] = useState(true);
  const [toggleSimple, setToggleSimple] = useState(true);
  const [anchor, setAnchor] = useState(null);

  switch (statusId) {
    case 'TO DO':
      colorStatus = '#888888';
      break;
    case 'DOING':
      colorStatus = '#73CDEE';
      break;
    case 'DONE':
      colorStatus = '#74CB80';
      break;
    case 'ON HOLD':
      colorStatus = '#EFCB0F';
      break;
    case 'WAITING':
      colorStatus = '#92739F';
      break;
    default:
      colorStatus = statusColor;
  }

  switch (priorityId) {
    case 'HIGH':
      iconPrio = icon_high;
      break;
    case 'MEDIUM':
      iconPrio = icon_medium;
      break;
    case 'LOW':
      iconPrio = icon_low;
      break;
    default:
      iconPrio = icon;
  }

  useEffect(() => {
    if (origin === 'task') {
      // // console.log("Riweuhh");

      axiosConfig
        .get(
          `${URL_API}/project/general/get-duration?start_date=${moment(
            startDate
          ).format('YYYY-MM-DD')}&end_date=${moment(selectedDueDate).format(
            'YYYY-MM-DD'
          )}&exclude_weekend=${project?.project?.exclude_weekend}`
        )
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setDuration(result.duration);
          }
          // setLoadDuration(false);
        })
        .catch((err) => {
          // console.log("error", err);
          // setLoadDuration(false);
        });
    }
  }, []);

  // const handleOwner = (e) => setOwnerId(e.target.value);
  const handleStatus = (e) => setStatusId(e.target.value);
  const handlePriority = (e) => setPriorityId(e.target.value);
  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setOwnerId(data);
    }
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleDueDate = (date) => {
    setSelectedDueDate(date);

    // // console.log("Due DT", moment(date).format("YYYY-MM-DD"));

    if (origin === 'task') {
      axiosConfig
        .get(
          `${URL_API}/project/general/get-duration?start_date=${moment(
            startDate
          ).format('YYYY-MM-DD')}&end_date=${moment(date).format(
            'YYYY-MM-DD'
          )}&exclude_weekend=${project?.project?.exclude_weekend}`
        )
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setDuration(result.duration);
          }
          // setLoadDuration(false);
        })
        .catch((err) => {
          // console.log("error", err);
          // setLoadDuration(false);
        });
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);

    // const isAfter = moment(date).isAfter(endDate, "days");

    // if (isAfter) {
    //   setSelectedDueDate(moment(date).add(1, "days"));
    // } else {
    if (origin === 'task') {
      axiosConfig
        .get(
          `${URL_API}/project/general/get-due-date?start_date=${moment(
            date
          ).format('YYYY-MM-DD')}&duration=${duration}&exclude_weekend=${
            project?.project?.exclude_weekend
          }`
        )
        .then((res) => {
          const result = res.data.data;

          // // console.log("SET DUED", result);

          if (res.status === 200) {
            setSelectedDueDate(result.due_date);
          }
        })
        .catch((err) => {
          // console.log("error", err);
        });
    }
    // }
  };

  const handleDuration = (e) => {
    const durationVal = e.target.value;
    setDuration(durationVal);

    // // console.log("Durat", durationVal);

    if (origin === 'task') {
      axiosConfig
        .get(
          `${URL_API}/project/general/get-due-date?start_date=${moment(
            startDate
          ).format('YYYY-MM-DD')}&duration=${
            Number(durationVal) + 1
          }&exclude_weekend=${project?.project?.exclude_weekend}`
        )
        .then((res) => {
          const result = res.data.data;

          // // console.log("SET DUED", result);

          if (res.status === 200) {
            setSelectedDueDate(result.due_date);
          }
        })
        .catch((err) => {
          // console.log("error", err);
        });
    }
  };

  const handleShow = (event) => {
    if (anchor && anchor.contains(event.target)) {
      setAnchor(null);
    } else {
      setAnchor(event.currentTarget);
    }
  };

  const handleCloseAway = (event) => {
    if (anchor.contains(event.target)) {
      return;
    }
    setAnchor(null);
  };

  const handleChooseDependency = (e, data) => {
    // // console.log("DEP DAta", data);
    if (data !== null) {
      setSelected((prev) => [...prev, data]);
    }
  };

  const handleDeleteDependency = (id) => {
    setSelected((prev) => prev.filter((el) => el.id !== id));
  };

  const isbefore = moment(new Date()).isBefore(startDate, 'day');

  // handle Dissabled for actual input
  let DISABLED_ACTUAL = false;
  let DISABLED_TARGET = false;

  // Condition For Actual
  if (project?.status_id === '2') {
    DISABLED_ACTUAL = true;
  }
  if (isbefore) {
    DISABLED_ACTUAL = true;
  }

  // Condition For Actual and Target
  if (status403) {
    DISABLED_ACTUAL = true;
    DISABLED_TARGET = true;
  }
  if (checkList?.length > 0) {
    DISABLED_ACTUAL = true;
    DISABLED_TARGET = true;
  }
  if (resultSource === 'phase' || resultSource === 'checklist') {
    DISABLED_ACTUAL = true;
    DISABLED_TARGET = true;
  }

  // Condition For Target
  if (projectType === 'PERSONAL') {
    DISABLED_TARGET = true;
  }

  return (
    <Paper className={classes.dataArea}>
      <Box p={2} pt={1} display="flex" alignItems="center">
        <Typography
          variant="subtitile2"
          className={classes.title333}
          style={{ marginRight: 5 }}
        >
          <b>Task Information</b>
        </Typography>
        {toggleInfo ? (
          <IconButton size="small" onClick={() => setToggleInfo(!toggleInfo)}>
            <KeyboardArrowUpIcon style={{ color: '#C8203C' }} />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={() => setToggleInfo(!toggleInfo)}>
            <KeyboardArrowDownIcon style={{ color: '#C8203C' }} />
          </IconButton>
        )}
      </Box>

      {toggleInfo && (
        <Box p={2} pt={0}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitile2"
                  className={classes.title555}
                  style={{ marginRight: 15 }}
                >
                  Assignee
                </Typography>
                <Autocomplete
                  id="combo-box-demo"
                  options={ownerCollection}
                  getOptionLabel={(option) =>
                    `${option.member.first_name} ${option.member.last_name}`
                  }
                  value={ownerId}
                  onChange={(e, owner) => handleChooseOwner(e, owner)}
                  noOptionsText="Tidak ada owner"
                  popupIcon={
                    <KeyboardArrowDownIcon style={{ color: '#C8203C' }} />
                  }
                  size="small"
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select owner"
                      variant="standard"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        disableUnderline: true
                      }}
                    />
                  )}
                  disabled={
                    loading ||
                    status403 ||
                    projectType === 'PERSONAL' ||
                    completed
                  }
                />
              </Box>

              <Box mb={2}>
                <Divider />
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitile2"
                  className={classes.title555}
                  style={{ marginRight: 15 }}
                >
                  Status
                </Typography>
                <FiberManualRecordIcon
                  style={{
                    fontSize: 16,
                    color: colorStatus,
                    marginRight: 5
                  }}
                />
                <Select
                  fullWidth
                  value={statusId}
                  onChange={handleStatus}
                  IconComponent={KeyboardArrowDownIcon}
                  disableUnderline={true}
                  disabled={
                    project?.status_id === '2' ||
                    isbefore ||
                    status403 ||
                    completed
                  }
                >
                  {statusCollections.map((status, i) => (
                    <MenuItem value={status.id} key={i}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box my={2}>
                <Divider />
              </Box>
            </Grid>
          </Grid>

          {toggleSimple === false && (
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitile2"
                    className={classes.title555}
                    style={{ marginRight: 15 }}
                  >
                    Tags
                  </Typography>
                  <TextField
                    type="text"
                    size="small"
                    variant="standard"
                    fullWidth
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small">
                            <LocalOfferIcon
                              style={{ color: '#555', fontSize: 16 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                      disableUnderline: true
                    }}
                    disabled={
                      status403 || projectType === 'PERSONAL' || completed
                    }
                  />
                </Box>

                <Box mt={2}>
                  <Divider />
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitile2"
                    className={classes.title555}
                    style={{ marginRight: 15 }}
                  >
                    Priority
                  </Typography>
                  <img src={iconPrio} height={16} style={{ marginRight: 5 }} />
                  <Select
                    fullWidth
                    value={priorityId}
                    onChange={handlePriority}
                    IconComponent={KeyboardArrowDownIcon}
                    disableUnderline={true}
                    disabled={
                      status403 || projectType === 'PERSONAL' || completed
                    }
                  >
                    {priorityCollections.map((priority, i) => (
                      <MenuItem value={priority.id} key={i}>
                        {priority.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box mt={2}>
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          )}

          {toggleSimple === false && origin === 'task' && (
            <Grid container spacing={3}>
              <Grid item md={6}></Grid>
              <Grid item md={6}>
                <Box display="flex" alignItems="center">
                  <Switch
                    size="small"
                    checked={toggle}
                    onChange={(e) => setToggle(e.target.checked)}
                    disabled={projectType === 'PERSONAL' || completed}
                  />
                  <Typography variant="subtitle2" className={classes.title333}>
                    {toggle ? (
                      'Set Due Date'
                    ) : (
                      <span>
                        Set Duration{' '}
                        <span style={{ fontSize: 10 }}>
                          {project?.project?.exclude_weekend === 1
                            ? '(exclude weekend)'
                            : '(include weekend)'}
                        </span>
                      </span>
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}

          {toggleSimple === false && (
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitile2"
                    className={classes.title555}
                    style={{ marginRight: 15 }}
                  >
                    StartDate
                  </Typography>
                  <MuiPickersUtilsProvider utils={LocalizedUtils}>
                    <DatePicker
                      autoOk
                      value={startDate}
                      onChange={handleStartDate}
                      animateYearScrolling
                      variant="dialog" // dialog, static, inline
                      disableToolbar={false}
                      format="DD-MM-YYYY"
                      inputVariant="standard"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small">
                              <DateRangeIcon
                                style={{
                                  color: '#555',
                                  fontSize: 16
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        disableUnderline: true
                      }}
                      disabled={
                        status403 || projectType === 'PERSONAL' || completed
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box my={2}>
                  <Divider />
                </Box>
              </Grid>

              <Grid item md={6}>
                {!toggle && (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitile2"
                      className={classes.title555}
                      style={{ marginRight: 15 }}
                    >
                      DueDate
                    </Typography>
                    <MuiPickersUtilsProvider utils={LocalizedUtils}>
                      <DatePicker
                        autoOk
                        value={selectedDueDate}
                        onChange={handleDueDate}
                        animateYearScrolling
                        variant="dialog" // dialog, static, inline
                        disableToolbar={false}
                        format="DD-MM-YYYY"
                        inputVariant="standard"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton size="small">
                                <DateRangeIcon
                                  style={{
                                    color: '#555',
                                    fontSize: 16
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                          disableUnderline: true
                        }}
                        disabled={
                          status403 || projectType === 'PERSONAL' || completed
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                )}

                {toggle && origin === 'task' && (
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="subtitile2"
                      className={classes.title555}
                      style={{ marginRight: 15 }}
                    >
                      Duration
                    </Typography>
                    <TextField
                      type="number"
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={duration}
                      onChange={handleDuration}
                      error={duration <= 0}
                      helperText={
                        duration <= 0 && 'duration tidak boleh kurang dari 1'
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {loadDuration && (
                              <CircularProgress
                                size={14}
                                style={{ color: '#C8203C' }}
                              />
                            )}
                          </InputAdornment>
                        )
                      }}
                      disabled={
                        status403 || projectType === 'PERSONAL' || completed
                      }
                    />
                  </Box>
                )}

                <Box my={2}>
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          )}

          {toggleSimple === false && origin === 'task'
            ? (superior || projectOwner) && (
                <Grid container>
                  <Grid item md={6}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="subtitile2"
                        className={classes.title555}
                        style={{ marginRight: 15 }}
                      >
                        FinishedDate
                      </Typography>

                      <MuiPickersUtilsProvider utils={LocalizedUtils}>
                        <DatePicker
                          autoOk
                          value={endDate}
                          onChange={handleEndDate}
                          animateYearScrolling
                          variant="dialog" // dialog, static, inline
                          disableToolbar={false}
                          format="DD-MM-YYYY"
                          inputVariant="standard"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton size="small">
                                  <DateRangeIcon
                                    style={{
                                      color: '#555',
                                      fontSize: 16
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                            disableUnderline: true
                          }}
                          disabled={status403 || completed}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>

                    <Box my={2}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    {/* <Box my={2}>
                      <Divider />
                    </Box> */}
                  </Grid>
                </Grid>
              )
            : null}

          <Grid container spacing={3}>
            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitile2"
                  className={classes.title555}
                  style={{ marginRight: 15 }}
                >
                  Actual
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={actual}
                  onChange={(e) => setActual(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small">
                          <CheckCircleOutlineIcon
                            style={{ color: '#555', fontSize: 16 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true
                  }}
                  disabled={DISABLED_ACTUAL || completed}
                />
              </Box>

              <Box mt={2} mb={checkList?.length > 0 ? 0 : isbefore ? 0 : 2}>
                <Divider />
              </Box>
              {checkList?.length > 0 && (
                <Box mb={1.5}>
                  <Typography variant="caption" className={classes.title888}>
                    * Nilai yang sedang dikerjakan adalah checklist
                  </Typography>
                </Box>
              )}
              {isbefore && (
                <Box mb={1.5}>
                  <Typography variant="caption" className={classes.title888}>
                    * Nilai actual tidak bisa diubah sebelum memasuki start date
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitile2"
                  className={classes.title555}
                  style={{ marginRight: 15 }}
                >
                  Target
                </Typography>
                <TextField
                  type="number"
                  size="small"
                  variant="standard"
                  fullWidth
                  value={target}
                  onChange={(e) => setTarget(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small">
                          <TrackChangesOutlinedIcon
                            style={{ color: '#555', fontSize: 16 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                    disableUnderline: true
                  }}
                  disabled={DISABLED_TARGET || completed}
                />
              </Box>

              <Box my={2}>
                <Divider />
              </Box>
            </Grid>
          </Grid>

          {toggleSimple === false && origin === 'task' && (
            <>
              <Typography
                variant="subtitile2"
                className={classes.title555}
                style={{ marginRight: 15 }}
              >
                Task Dependency
              </Typography>

              <Box
                width="100%"
                minHeight={40}
                border="1.5px solid #C4C4C4"
                borderRadius={5}
                padding={1}
                display="flex"
                justifyContent={
                  selected.length === 0 ? 'flex-end' : 'space-between'
                }
                alignItems="center"
              >
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {selected.length > 0 &&
                    selected.map((item, i) => (
                      <Chip
                        key={i}
                        label={TruncateTextCustom(item.title, 20)}
                        size="small"
                        style={{ margin: '0 5px 5px 0' }}
                        onDelete={() => handleDeleteDependency(item.id)}
                        disabled={completed}
                      />
                    ))}
                </Box>
                <IconButton size="small" onClick={handleShow}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Box>

              <Popper
                open={Boolean(anchor)}
                anchorEl={anchor}
                transition
                disablePortal
                placement="bottom-end"
                className={classNames({
                  [classes.popperClose]: !anchor,
                  [classes.popperResponsive]: true
                })}
              >
                {({ placement }) => (
                  <Grow
                    in={Boolean(anchor)}
                    id="menu-list"
                    tyle={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <ClickAwayListener onClickAway={handleCloseAway}>
                      <Paper className={classes.dropdown}>
                        <Box p={1.5} width={400}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            Dependencies
                          </Typography>
                          <Box my={1} />
                          <Autocomplete
                            id="combo-box-demo"
                            options={dependencies}
                            getOptionLabel={(option) => `${option.title}`}
                            onChange={(e, owner) =>
                              handleChooseDependency(e, owner)
                            }
                            noOptionsText="Tidak ada dependency"
                            popupIcon={
                              <KeyboardArrowDownIcon
                                style={{ color: '#C8203C' }}
                              />
                            }
                            disabled={completed}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Search"
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Paper>
                    </ClickAwayListener>
                  </Grow>
                )}
              </Popper>

              <Box my={2}>
                <Divider />
              </Box>
            </>
          )}

          <Box display="flex" justifyContent="center" mb={2}>
            <Button
              size="small"
              variant="outlined"
              className={classes.btnSmallTrans}
              endIcon={
                toggleSimple ? (
                  <ExpandMoreRoundedIcon />
                ) : (
                  <ExpandLessRoundedIcon />
                )
              }
              onClick={() => setToggleSimple((v) => !v)}
            >
              <Typography className={classes.title888} variant="caption">
                Show {toggleSimple ? 'More' : 'Less'}
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default TaskInformation;
