import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import FormEditCompanySetting from "./Components/FormEditCompanySetting";
import ViewManageMembership from "./ManageMembership/ViewManageMembership";
import ViewUser from "../UserManagement/ViewUser";

import axios from "axios";
import { URL_API } from "../../../constants/config-api";

import ContextCompanySetting from "./Context/ContextCompanySetting";

const ViewOrganizationSetting = ({ classes }) => {
  const context = useContext(ContextCompanySetting);
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const statusUser = user?.membership_status_id;

  const [memberCountCollections, setMemberCountCollections] = useState([]);
  const [updatedAt, setUpdatedAt] = useState("");
  const [selectOrg, setSelectOrg] = useState({
    code: "company_profile",
    name: "Company Profile",
    id: 0,
  });

  const [fields, setFields] = useState({
    name: "",
    photo_logo_icon: "",
    tax_id_number: "", //*NPWP
    member_count: 0,
    alamat_detail: "",
    country_id: "",
    country_name: "",
    province_id: "",
    province_name: "",
    city_id: "",
    city_name: "",
    postal_code_id: "",
    postal_code_name: "",
    vision_and_mission: "",
  });

  useEffect(() => {
    //*
    const userToken = localStorage.getItem("userToken");
    const tabOrg = localStorage.getItem("org_tab");
    const tabOrgVal = parseInt(tabOrg);

    if (tabOrg === null || tabOrg === undefined) {
      setSelectOrg({ id: 0 });
    } else {
      setSelectOrg({ id: tabOrgVal });
    }

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .get(URL_API + `/group/profile/update`)
        .then(function (response) {
          // console.log("Response Original : ", response);

          if (response.status === 200) {
            if (response.data.data.fields !== null) {
              setFields({
                name: response.data.data.fields.name.value,
                photo_logo_icon:
                  response.data.data.fields.photo_logo_icon.value,
                tax_id_number: response.data.data.fields.tax_id_number.value, //*NPWP
                member_count: response.data.data.fields.member_count.value,
                alamat_detail: response.data.data.fields.detail.value,
                country_id: response.data.data.fields.country_id.value,
                country_name: "",
                province_id: response.data.data.fields.province_id.value,
                province_name: "",
                city_id: response.data.data.fields.city_id.value,
                city_name: "",
                postal_code_id: response.data.data.fields.postal_code_id.value,
                postal_code_name: "",
                vision_and_mission:
                  response.data.data.fields.vision_and_mission.value,
              });

              context.setCityCollections(response.data.data.cityCollections);
              context.setCountryCollections(
                response.data.data.countryCollections
              );
              setMemberCountCollections(
                response.data.data.memberCountCollections
              );
              context.setPostalCodeCollections(
                response.data.data.postalCodeCollections
              );
              context.setProvinceCollections(
                response.data.data.provinceCollections
              );
              setUpdatedAt(response.data.data.updated_at);
            }
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  }, []);

  useEffect(() => {
    // const userToken = localStorage.getItem('userToken');
    if (context.fireUpdate === true) {
      if (context.userTokenStateGlobal !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenStateGlobal,
        };

        axios.defaults.headers.common = header;

        axios
          .get(URL_API + `/group/profile/update`)
          .then(function (response) {
            // console.log("Response Original : ", response);

            if (response.status === 200) {
              if (response.data.data.fields !== null) {
                setFields({
                  name: response.data.data.fields.name.value,
                  photo_logo_icon:
                    response.data.data.fields.photo_logo_icon.value,
                  tax_id_number: response.data.data.fields.tax_id_number.value, //*NPWP
                  member_count: response.data.data.fields.member_count.value,
                  alamat_detail: response.data.data.fields.detail.value,
                  country_id: response.data.data.fields.country_id.value,
                  country_name: "",
                  province_id: response.data.data.fields.province_id.value,
                  province_name: "",
                  city_id: response.data.data.fields.city_id.value,
                  city_name: "",
                  postal_code_id:
                    response.data.data.fields.postal_code_id.value,
                  postal_code_name: "",
                  vision_and_mission:
                    response.data.data.fields.vision_and_mission.value,
                });

                context.setCityCollections(response.data.data.cityCollections);
                context.setCountryCollections(
                  response.data.data.countryCollections
                );
                setMemberCountCollections(
                  response.data.data.memberCountCollections
                );
                context.setPostalCodeCollections(
                  response.data.data.postalCodeCollections
                );
                context.setProvinceCollections(
                  response.data.data.provinceCollections
                );
                setUpdatedAt(response.data.data.updated_at);
              }
            }
          })
          .catch(function (error) {
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [context.fireUpdate]);

  const [fieldsMemberCount, setFieldsMemberCount] = useState({
    employee: `${fields.member_count}`,
  });

  useEffect(() => {
    //*
    memberCountCollections.filter((element) => {
      if (element.id === `${fields.member_count}`) {
        // // console.log("Member Count : ", element)
        setFieldsMemberCount({
          employee: element.name,
          // id: element.id
        });
        return element;
      }
    });
  }, [memberCountCollections]);

  const handlSeclectOrg = (data) => {
    setSelectOrg(data);
    localStorage.setItem("org_tab", data.id);
  };

  return (
    <Grid container style={{ marginTop: 0 }}>
      <Grid item md={2} style={{ backgroundColor: "#f8f8fc" }}>
        <Box flexGrow={1} mt={4} pl={3}>
          {listOrganization.map((organization, i) => {
            const isCurrent = selectOrg.id === organization.id;

            return (
              <Box
                key={i}
                p={2}
                className={classes.settingItem}
                onClick={() => handlSeclectOrg(organization)}
              >
                <Typography
                  variant="subtitle2"
                  className={isCurrent ? classes.title333 : classes.title888}
                >
                  {organization.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid item md={10}>
        <div style={{ backgroundColor: "#fff", height: "100vh" }}>
          {selectOrg.id === 0 ? (
            <FormEditCompanySetting
              classes={classes}
              fields={fields}
              memberCountCollections={memberCountCollections}
              fieldsMemberCount={fieldsMemberCount}
              setFieldsMemberCount={setFieldsMemberCount}
              updatedAt={updatedAt}
            />
          ) : selectOrg.id === 1 ? (
            <ViewManageMembership classes={classes} />
          ) : (
            <ViewUser onAcccountTab={handlSeclectOrg} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ViewOrganizationSetting;

const listOrganization = [
  {
    code: "company_profile",
    name: "Company Profile",
    id: 0,
  },
  {
    code: "membership",
    name: "Membership",
    id: 1,
  },
  {
    code: "user",
    name: "User",
    id: 2,
  },
];
