import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Paper } from "@material-ui/core";

//client
import logoMadinahCare from "../../../../assets/images/landing/logo-madinah-care.PNG";
import logoMadinahFlash from "../../../../assets/images/landing/logo-madinah-flash.PNG";
import logoMadinahGroup from "../../../../assets/images/landing/logo-madinah-group.PNG";
import logoMadinahMultimedia from "../../../../assets/images/landing/logo-madinah-multimedia.PNG";
import logoMadinahStore from "../../../../assets/images/landing/logo-madinah-store.PNG";
import logoSpr45 from "../../../../assets/images/landing/SPR45.png";
import logoMuslimLifeShop from "../../../../assets/images/landing/muslim_life_shop.jpg";
import logoOkeOceID from "../../../../assets/images/landing/okocelogo.png";
import logoMadinahReload from "../../../../assets/images/landing/madinah_reload.png";
import logoLimaIntiSinergi from "../../../../assets/images/landing/lima-inti_sinergi.png";
import logoAspenku from "../../../../assets/images/landing/aspenku.png";
import logoGreenery from "../../../../assets/images/landing/greenery.png";
import logoNuraria from "../../../../assets/images/landing/nuraria.jpg";
import logoWMart from "../../../../assets/images/landing/wmart.png";
import logoAlfatihQuran from "../../../../assets/images/landing/alfatihquran.png";
import logoCvBanjarIndah from "../../../../assets/images/landing/cv_banjar_indah.png";
import logoDmcComm from "../../../../assets/images/landing/dmc_comm.png";

import logoAqiqah from "../../../../assets/images/aqiqah.jpg";
import logoBamcargo from "../../../../assets/images/bamcargo.png";
import logoBizare from "../../../../assets/images/bizhare.png";
import logoBoluAmor from "../../../../assets/images/bolu_amor.png";
import logoBursaMuslim from "../../../../assets/images/bursaMuslim.jpg";
import logoCahaya31 from "../../../../assets/images/cahaya31.png";
import logoDrmetz from "../../../../assets/images/drmetz.jpg";
import logoMaskanul from "../../../../assets/images/maskanul_huffadz.png";
import logoMetro from "../../../../assets/images/metroReload.png";
import logoPBJ from "../../../../assets/images/PBJ.png";
import logoSoto from "../../../../assets/images/sotoseger.png";
// partner
import logoHibraConsul from "../../../../assets/images/landing/hibra_consulting.png";
import logoZahir from "../../../../assets/images/landing/zahir.png";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#888",
    textDecoration: "none",
  },
  bgImage: {
    filter: "grayscale(100%)",
    "&:hover": {
      filter: "grayscale(0%)",
    },
  },
  img: {
    // opacity: 0.4,
    // "&:hover": {
    //   opacity: 1,
    // },
  },
});

const useStyle = makeStyles(styles);

const OurClient = () => {
  const classes = useStyle();

  return (
    <Box bgcolor="#F2F4F7" my={15} py={7.5}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h5" className={classes.title333}>
          <b>Klien kami</b>
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {listClients.map((item, i) => (
          <Box key={i} m={2}>
            <Paper variant="outlined">
              <Box borderRadius={5}>
                <img
                  src={item.img}
                  height={50}
                  width="100%"
                  className={classes.img}
                />
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OurClient;

const listClients = [
  {
    img: logoMadinahCare,
  },
  {
    img: logoMadinahFlash,
  },
  {
    img: logoMadinahGroup,
  },
  {
    img: logoMadinahMultimedia,
  },
  {
    img: logoMadinahStore,
  },
  {
    img: logoSpr45,
  },
  {
    img: logoMuslimLifeShop,
  },
  {
    img: logoOkeOceID,
  },
  {
    img: logoMadinahReload,
  },
  {
    img: logoLimaIntiSinergi,
  },
  {
    img: logoAspenku,
  },
  {
    img: logoGreenery,
  },
  {
    img: logoNuraria,
  },
  {
    img: logoWMart,
  },
  {
    img: logoAlfatihQuran,
  },
  {
    img: logoCvBanjarIndah,
  },
  {
    img: logoDmcComm,
  },
  {
    img: logoAqiqah,
  },
  {
    img: logoBamcargo,
  },
  {
    img: logoBizare,
  },
  {
    img: logoBoluAmor,
  },
  {
    img: logoBursaMuslim,
  },
  {
    img: logoCahaya31,
  },
  {
    img: logoDrmetz,
  },
  {
    img: logoMaskanul,
  },
  {
    img: logoMetro,
  },
  {
    img: logoPBJ,
  },
  {
    img: logoSoto,
  },
];
