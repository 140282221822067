import React, { useState, useContext } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Checkbox,
} from "@material-ui/core";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import FolderIcon from "@material-ui/icons/Folder";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import moment from "moment";
import clsx from "clsx";

import DialogInfoFolder from "../components/DialogInfoFolder";
import DialogRenameFolder from "../components/DialogRenameFolder";
import DialogDeleteFolder from "../components/DialogDeleteFolder";
import DialogMoveFolder from "../components/DialogMoveFolder";

import ContextGlobalDrive from "../../context/ContextDrive";
import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";

const RecoveredFoldersGrid = ({ item, classes, isFolderSelected }) => {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [anchorElFolder, setAnchorElFolder] = useState(null);
  const [openDialogInfoFolder, setOpenDialogInfoFolder] = useState(false);
  const [openDialogRenameFolder, setOpenDialogRenameFolder] = useState(false);
  const [openDialogDeleteFolder, setOpenDialogDeleteFolder] = useState(false);
  const [openDialogMoveFolder, setOpenDialogMoveFolder] = useState(false);

  const handleShowAnchorFolder = (event) => {
    setAnchorElFolder(event.currentTarget);
  };

  const handleCloseAnchorFolder = () => {
    setAnchorElFolder(null);
  };

  const handleDialogInfoFolder = () => {
    setAnchorElFolder(null);
    setOpenDialogInfoFolder(true);
  };

  const handleDialogRenameFolder = () => {
    setAnchorElFolder(null);
    setOpenDialogRenameFolder(true);
  };

  const handleDialogDeleteFolder = () => {
    setAnchorElFolder(null);
    setOpenDialogDeleteFolder(true);
  };

  const handleDialogMoveFolder = () => {
    setAnchorElFolder(null);
    setOpenDialogMoveFolder(true);
  };

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFolder.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFolder, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFolder.slice(1));
    } else if (selectedIndex === context.selectedFolder.length - 1) {
      newSelected = newSelected.concat(context.selectedFolder.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFolder.slice(0, selectedIndex),
        context.selectedFolder.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected Folders", newSelected);

    context.setSelectedFolder(newSelected);
  };

  // Check is Folder selected or not
  const isItemFolderSelected = isFolderSelected(item.id);

  return (
    <Grid item md={3}>
      {context.toggleSelect && (
        <Checkbox
          style={{ marginTop: !context.toggleSelect ? 0 : -10 }}
          onClick={() => handleChangeCheckbox(item)}
          className={classes.checkboxRoot}
          checked={isItemFolderSelected}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "decorative checkbox" }}
        />
      )}
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : -20,
          backgroundColor: "#eafaf1",
        }}
      >
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#7bed9f" }}>
              <FolderIcon style={{ color: "#209550" }} />
            </Avatar>
          }
          action={
            <div>
              <IconButton onClick={handleShowAnchorFolder}>
                <MoreVertIcon />
              </IconButton>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElFolder}
                keepMounted
                open={Boolean(anchorElFolder)}
                onClose={handleCloseAnchorFolder}
              >
                <StyledMenuItem
                  style={{ width: 200 }}
                  onClick={handleDialogInfoFolder}
                >
                  <ListItemIcon>
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        View Info
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDialogRenameFolder}>
                  <ListItemIcon>
                    <EditOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        Rename
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDialogDeleteFolder}>
                  <ListItemIcon>
                    <DeleteOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        Delete Folder
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDialogMoveFolder}>
                  <ListItemIcon>
                    <SyncAltIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        Move Folder
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          }
          title={
            <Typography className={classes.folderNameText} variant="subtitle2">
              {TruncateTextShortNameGoal(item.name)}
            </Typography>
          }
          subheader={moment(item.modified_at).format("DD MMM YYYY, HH:mm")}
        />
      </Card>

      <DialogInfoFolder
        openDialogInfoFolder={openDialogInfoFolder}
        setOpenDialogInfoFolder={setOpenDialogInfoFolder}
        item={item}
        classes={classes}
      />

      <DialogRenameFolder
        openDialogRenameFolder={openDialogRenameFolder}
        setOpenDialogRenameFolder={setOpenDialogRenameFolder}
        item={item}
        classes={classes}
      />

      <DialogDeleteFolder
        openDialogDeleteFolder={openDialogDeleteFolder}
        setOpenDialogDeleteFolder={setOpenDialogDeleteFolder}
        item={item}
        classes={classes}
      />

      <DialogMoveFolder
        openDialogMoveFolder={openDialogMoveFolder}
        setOpenDialogMoveFolder={setOpenDialogMoveFolder}
        classes={classes}
        item={item}
      />
    </Grid>
  );
};

export default RecoveredFoldersGrid;
