import React from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { easeQuadInOut } from "d3-ease";
// import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import AnimatedProgressProvider from "../../../../components/AnimatedProgressProvider";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const AchievementBar = ({ classes, percentage, origin }) => {
  const valEnd = Number(percentage) * 100;
  let color;
  let bgColor;

  if (valEnd === 0) {
    color = "#c4c7c7";
    bgColor = "#c4c7c7";
  }
  if (valEnd > 0 && valEnd < 85) {
    color = "#F16C7D";
    bgColor = "#c4c7c7";
  }
  if (valEnd >= 85 && valEnd < 100) {
    color = "#ffc000";
    bgColor = "#c4c7c7";
  }
  if (valEnd === 100) {
    color = "#74CB80";
    bgColor = "#c4c7c7";
  }
  if (valEnd > 100 && valEnd < 200) {
    color = "#518e59";
    bgColor = "#74CB80";
  }
  if (valEnd >= 200) {
    color = "#518e59";
    bgColor = "#518e59";
  }

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: origin === "head" ? 20 : 15,
      borderRadius: 5,
      color: "#333",
      width: "100%",
    },
    colorPrimary: {
      backgroundColor: origin === "head" ? "#c4c7c7" : bgColor,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: origin === "head" ? "#3498db" : color,
    },
  }))(LinearProgress);

  return (
    <>
      {origin === "head" ? (
        <Box display="flex" alignItems="center">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={valEnd}
            duration={3}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const newVal = FormatDecimal(value);

              return (
                <BorderLinearProgress
                  variant="determinate"
                  value={value > 100 ? value - 100 : value}
                />
              );
            }}
          </AnimatedProgressProvider>

          <Typography
            variant="caption"
            className={classes.title555}
            style={{ marginLeft: 10 }}
          >
            {FormatDecimal(valEnd)}%
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography
            variant="caption"
            className={classes.title555}
            style={{ marginLeft: 10 }}
          >
            {FormatDecimal(valEnd)}%
          </Typography>
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={valEnd}
            duration={3}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const newVal = FormatDecimal(value);

              return (
                <BorderLinearProgress
                  variant="determinate"
                  value={value > 100 ? value - 100 : value}
                />
              );
            }}
          </AnimatedProgressProvider>
        </Box>
      )}
    </>
  );
};

export default AchievementBar;
