import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";

import DialogAddEvidenceAllMA from "./DialogAddEvidenceAllMA";
import DialogCreateText from "./DialogCreateText";

import ContextAllMA from "../context/AllMaContext";

import {
  FORMAT_TYPE_IMAGE_IN_MA_RESULT,
  FORMAT_TYPE_VIDEO_IN_MA_RESULT,
  FORMAT_TYPE_DOC_IN_MA_RESULT,
  FORMAT_TYPE_AUDIO_IN_MA_RESULT,
} from "../../../../constants/config-type-file";
import {
  MARE_CATEGORY_DRIVE,
  MARE_CATEGORY_LINK,
} from "../../../../constants/config-drive";
import DriveItem from "./DriveComponent/DriveItem";
import LinkItem from "./DriveComponent/LinkItem";
import DocItem from "./DriveComponent/DocItem";
import ImageItem from "./DriveComponent/ImageItem";
import VideoItem from "./DriveComponent/VideoItem";
import DialogNavImgMA from "./DialogNavImgMA";
import DialogVoiceRecorder from "./DialogVoiceRecorder";
import AudioItem from "./DriveComponent/AudioItem";

const DialogEvidences = ({
  classes,
  item,
  openEvidences,
  setOpenEvidences,
  maDetailState,
  collectionMaResultList,
  userToken,
}) => {
  const context = useContext(ContextAllMA);

  const [listPictState, setListPictState] = useState([]);
  const [dataDetailDelete, setDataDetailDelete] = useState("");

  const [showFade, setShowFade] = useState(false);
  const [isOpenDialogDeleteBuktiInDB, setOpenDialogDeleteBuktiInDB] =
    useState(false);

  const [openDialogAddEvidence, setOpenDialogAddEvidence] = useState(false);
  const [openDialogPlainText, setOpenDialogPlainText] = useState(false);
  const [openDialogVoiceRecord, setOpenDialogVoiceRecord] = useState(false);
  const [openDialogNavImg, setOpenDialogNavImg] = useState(false);

  useEffect(() => {
    if (
      item.measuredActivityResultEvidence !== null &&
      item.measuredActivityResultEvidence.length > 0
    ) {
      setListPictState(item.measuredActivityResultEvidence);
      setShowFade(true);
    } else {
      setListPictState([]);
    }
  }, [collectionMaResultList, item]);

  const handleDeletePictBuktiGRInDatabase = (e, data) => {
    e.preventDefault();
    // // console.log("Data Delete PICT in DATABASE: ", data);
    setDataDetailDelete(data);

    setOpenDialogDeleteBuktiInDB(true);
  };

  return (
    <Dialog
      open={openEvidences}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box p={1} pb={0}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>Evidence</b>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box display="flex">
          <Button
            size="large"
            variant="outlined"
            fullWidth
            className={classes.uploadBtn}
            onClick={() => setOpenDialogAddEvidence(true)}
          >
            Add Evidence
          </Button>
          <Box mx={1} />
          <Button
            size="large"
            variant="outlined"
            fullWidth
            className={classes.uploadBtn}
            onClick={() => setOpenDialogPlainText(true)}
          >
            Plain Document
          </Button>
          <Box mx={1} />
          <Button
            size="large"
            variant="outlined"
            fullWidth
            className={classes.uploadBtn}
            onClick={() => setOpenDialogVoiceRecord(true)}
          >
            Voice Recorder
          </Button>
        </Box>

        <Box mt={3}>
          <Box
            mb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2" className={classes.title888}>
              List evidence ({listPictState.length})
            </Typography>

            <Tooltip title="Image Slide Show">
              <IconButton
                size="small"
                onClick={() => setOpenDialogNavImg(true)}
              >
                <PhotoLibraryRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {listPictState.length > 0 &&
            listPictState.map((tile, i) => {
              // DELETE / VIEW POLICY (HAK AKSES)
              let viewAllowed = false;
              let deleteAllowed = false;

              tile.policy.forEach((premission) => {
                // // console.log("Polisi GOAL R", premission);

                if (premission.actionName === "viewDetail") {
                  viewAllowed = premission.result;
                } else if (premission.actionName === "delete") {
                  deleteAllowed = premission.result;
                }
              });

              if (
                viewAllowed &&
                tile.format_type_id === FORMAT_TYPE_IMAGE_IN_MA_RESULT
              ) {
                return (
                  <ImageItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    userToken={userToken}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.format_type_id === FORMAT_TYPE_VIDEO_IN_MA_RESULT
              ) {
                return (
                  <VideoItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    userToken={userToken}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.format_type_id === FORMAT_TYPE_AUDIO_IN_MA_RESULT
              ) {
                return (
                  <AudioItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    userToken={userToken}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.format_type_id === FORMAT_TYPE_DOC_IN_MA_RESULT
              ) {
                return (
                  <DocItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                    userToken={userToken}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.evidence_category_id === MARE_CATEGORY_DRIVE
              ) {
                return (
                  <DriveItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }

              if (
                viewAllowed &&
                tile.evidence_category_id === MARE_CATEGORY_LINK
              ) {
                return (
                  <LinkItem
                    i={i}
                    tile={tile}
                    classes={classes}
                    showFade={showFade}
                    deleteAllowed={deleteAllowed}
                    listPictState={listPictState}
                    setListPictState={setListPictState}
                  />
                );
              }
            })}
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={() => setOpenEvidences(false)}
        >
          OK
        </Button>
      </DialogActions>

      <DialogAddEvidenceAllMA
        openDialogAddEvidence={openDialogAddEvidence}
        setOpenDialogAddEvidence={setOpenDialogAddEvidence}
        classes={classes}
        mareLength={
          item.measuredActivityResultEvidence.length > 0
            ? item.measuredActivityResultEvidence.length
            : []
        }
        item={item}
        maDetailState={maDetailState}
        // setFireGoalDetail={setFireGoalDetail}
      />

      <DialogCreateText
        classes={classes}
        openDialogPlainText={openDialogPlainText}
        setOpenDialogPlainText={setOpenDialogPlainText}
        item={item}
        triggerSuccess={context.setSuccessCreateMAR}
      />

      <DialogVoiceRecorder
        classes={classes}
        openDialogVoiceRecord={openDialogVoiceRecord}
        setOpenDialogVoiceRecord={setOpenDialogVoiceRecord}
        triggerSuccess={context.setSuccessCreateMAR}
        item={item}
      />

      <DialogNavImgMA
        openDialogNavImg={openDialogNavImg}
        setOpenDialogNavImg={setOpenDialogNavImg}
        listPictState={listPictState}
        userToken={userToken}
      />

      {/* <DialogDeleteBuktiGRInDatabase
        classes={classes}
        isOpenDialogDeleteBuktiInDB={isOpenDialogDeleteBuktiInDB}
        setOpenDialogDeleteBuktiInDB={setOpenDialogDeleteBuktiInDB}
        dataDetailDelete={dataDetailDelete}
        setFireGoalDetail={setFireGoalDetail}
        setListPictState={setListPictState}
        listPictState={listPictState}
      />

      <DialogAddEvidence
        openDialogAddEvidence={openDialogAddEvidence}
        setOpenDialogAddEvidence={setOpenDialogAddEvidence}
        classes={classes}
        item={item}
        greLength={
          item.goalResultEvidence.length > 0 ? item.goalResultEvidence : []
        }
        setFireGoalDetail={setFireGoalDetail}
        goalDetailState={goalDetailState}
      />

      <DialogCreateText
        classes={classes}
        openDialogPlainText={openDialogPlainText}
        setOpenDialogPlainText={setOpenDialogPlainText}
        setFireGoalDetail={setFireGoalDetail}
        item={item}
      /> */}
    </Dialog>
  );
};

export default DialogEvidences;
