import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, CircularProgress } from '@material-ui/core';

import { navigate } from 'hookrouter';

import AxiosConfig from '../../../../../../constants/config-axios';

import ListItemTodoList from './ListItemTodoList';

const fetcher = (url) => AxiosConfig.get(url).then((r) => r.data.data);

const TodoList = ({ classes, dashboardData, selectDashboardUnitLabel }) => {
  const [todolists, setTodolists] = useState([]);

  useEffect(() => {
    if (dashboardData !== undefined && dashboardData.todolists !== undefined) {
      setTodolists(dashboardData.todolists);
    }
  }, [dashboardData]);

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.title333} variant="subtitle1">
            <b>To Do List</b>
          </Typography>
        </Box>

        <Box mt={2}>
          {dashboardData !== undefined &&
          dashboardData.todolists !== undefined ? (
            <>
              {todolists.length > 0 &&
                todolists.map((item, i) => (
                  <ListItemTodoList key={i} item={item} classes={classes} />
                ))}
            </>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress
                size={14}
                style={{ color: '#d64253', marginRight: 5 }}
              />
              <Typography className={classes.title888} variant="caption">
                Loading...
              </Typography>
            </Box>
          )}
          <Box mt={2} display="flex" justifyContent="center">
            <Typography
              className={classes.txtViewMore}
              variant="subtitle2"
              onClick={() => navigate('/task')}
            >
              View More
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default TodoList;
