import React from "react";
import { Card, CardMedia, Box, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import StarsIcon from "@material-ui/icons/Stars";

const CardChoosenPackage = ({
  classes,
  paket,
  membership,
  totalUser,
  totalDeployment,
  totalPmo,
  duration,
}) => {
  return (
    <Card className={classes.cardCheckout} variant="outlined">
      <Box className={classes.cardImgCont} width={200}>
        <Typography variant="subtitle1" className={classes.textBadge}>
          {membership}
        </Typography>
        <CardMedia
          src="https://picsum.photos/200/300/?blur=4"
          component="img"
          height="150"
          width="150"
        />
        <Typography
          variant="h4"
          className={
            paket === "Personal"
              ? classes.txtCenterCard1
              : classes.txtCenterCard
          }>
          <b>{paket}</b>
        </Typography>
      </Box>
      <Box
        padding={2}
        width={380}
        display="flex"
        justifyContent="space-between">
        <Box width={90}>
          <Box>
            <Typography variant="caption" className={classes.txtCartItem}>
              <b>User</b>
            </Typography>
            <Box display="flex" alignItems="center">
              <PersonIcon fontSize="small" style={{ color: "#aaa" }} />
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#eb4d4b", marginLeft: 5 }}>
                {totalUser}
              </Typography>
            </Box>
          </Box>
          <Box marginTop={3} width={140}>
            <Typography variant="caption" className={classes.txtCartItem}>
              <b>Consultant Visit</b>
            </Typography>
            <Box display="flex" alignItems="center">
              <ConfirmationNumberIcon
                fontSize="small"
                style={{ color: "#aaa" }}
              />
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#eb4d4b", marginLeft: 5 }}>
                {totalPmo}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box width={120}>
          <Box>
            <Typography variant="caption" className={classes.txtCartItem}>
              <b>Tiket Deployment</b>
            </Typography>
            <Box display="flex" alignItems="center">
              <ConfirmationNumberIcon
                fontSize="small"
                style={{ color: "#aaa" }}
              />
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#eb4d4b", marginLeft: 5 }}>
                {totalDeployment}
              </Typography>
            </Box>
          </Box>
          <Box marginTop={3} width={400}>
            <Typography variant="caption" className={classes.txtCartItem}>
              <b>Durasi</b>
            </Typography>
            <Box display="flex" alignItems="center">
              <WatchLaterIcon fontSize="small" style={{ color: "#aaa" }} />
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#eb4d4b", marginLeft: 5 }}>
                {duration} bulan
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box width={120}>
          <Typography variant="caption" className={classes.txtCartItem}>
            <b>Paket Membership</b>
          </Typography>
          <Box display="flex" alignItems="center">
            <StarsIcon fontSize="small" style={{ color: "#aaa" }} />
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#eb4d4b", marginLeft: 5 }}>
              {membership}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CardChoosenPackage;
