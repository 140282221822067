import React from "react";
import { Radio, Typography, Box } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const ListItemUser = ({ classes, item, user, onHandleUser }) => {
  const isCurrent = item.id === user.id;

  return (
    <label style={{ margin: 0, padding: 0 }}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.fieldPreset}
        py={1}
        px={3}
        minWidth={200}
        style={{
          backgroundColor: isCurrent && "#FDDBD8",
          color: isCurrent ? "#CF364A" : "#333",
        }}>
        <AccountCircleIcon style={{ color: "#aaa", fontSize: 20 }} />
        <Typography
          variant="subtitle2"
          style={{
            fontWeight: "normal",
            marginLeft: 10,
          }}>
          {`${item.member_first_name} ${item.member_last_name}`}
        </Typography>
        <Radio
          onChange={() => onHandleUser(item)}
          checked={item.id === user.id}
          value={item.id}
          style={{
            visibility: "hidden",
            height: 0,
            width: 0,
            opacity: 0,
          }}
        />
      </Box>
    </label>
  );
};

export default ListItemUser;
