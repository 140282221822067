import React from "react";
import { Button, Box } from "@material-ui/core";

const ActionSettings = ({
  classes,
  setOpenDialogConfirmApply,
  disabledApply,
}) => {
  return (
    <Box mb={4} display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        size="medium"
        className={classes.button1}
        disabled={disabledApply}
        onClick={() => setOpenDialogConfirmApply(true)}
      >
        Apply
      </Button>
    </Box>
  );
};

export default ActionSettings;
