import React, { useState, useContext } from "react";
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import clsx from "clsx";

import ContextProject from "../../context/ContextProject";
import { ASSIGNEE_CONTENT_FILTER, FILTER_TASK } from "../../context/types";
import FilterAssignee from "./filterItem/FilterAssignee";

import icon_high from "../../../../assets/images/SVG/icon_high.svg";
import icon_medium from "../../../../assets/images/SVG/icon_medium.svg";
import icon_low from "../../../../assets/images/SVG/icon_low.svg";

const AnchorFilterTask = ({
  classes,
  onClose,
  ownerCollections,
  listGroup,
}) => {
  const { projectTDispatch, setTriggerTask, projectTState } =
    useContext(ContextProject);

  const [periods, setPeriods] = useState([]);
  const [achievement, setAchievement] = useState([]);
  const [ownerId, setOwnerId] = useState([]);
  const [status, setStatus] = useState([]);
  const [priority, setPriority] = useState([]);
  const [complete, setComplete] = useState([]);
  const [groups, setGroups] = useState([]);

  const [openPeriod, setOpenPeriod] = useState(false);
  const [openEnddate, setOpenEnddate] = useState(false);
  const [openAssignee, setOpenAssignee] = useState(false);
  const [openAchievement, setOpenAchievement] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  // const [openOwner, setOpenOwner] = useState(false);
  // const [openPriority, setOpenPriority] = useState(false);
  // const [openCompleted, setOpenCompleted] = useState(false);

  const isSelectedPeriods = (id) => periods.indexOf(id) !== -1;
  const isSelectedAchievement = (id) => achievement.indexOf(id) !== -1;
  const isSelectedStatus = (id) => status.indexOf(id) !== -1;
  const isSelectedPriority = (id) => priority.indexOf(id) !== -1;
  const isSelectedComplete = (id) => complete.indexOf(id) !== -1;
  const isSelectedGroups = (id) => groups.indexOf(id) !== -1;

  const ExpandTrue = () => (
    <ExpandMore style={{ color: "#8F8F8F" }} fontSize="small" />
  );
  const ExpandFalse = ({ color }) => (
    <ExpandLess style={{ color }} fontSize="small" />
  );

  const handlePeriod = () => setOpenPeriod((s) => !s);
  const handleEndDate = () => setOpenEnddate((s) => !s);
  const handleAssignee = () => setOpenAssignee((s) => !s);
  const handleAchievement = () => setOpenAchievement((s) => !s);
  const handleStatus = () => setOpenStatus((s) => !s);
  const handlePriority = () => setOpenPriority((s) => !s);
  const handleComplete = () => setOpenComplete((s) => !s);
  const handleGroup = () => setOpenGroup((s) => !s);

  const handleFilterTask = () => {
    const owners =
      ownerId.length > 0
        ? ownerId
            .map((el, i) => {
              return `options[filter][fields][owner_id][${i}]=${el.id}`;
            })
            .join("&")
        : null;

    const newPeriods =
      periods.length > 0
        ? periods
            .map((el, i) => {
              return `options[filter][periods][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newAchievement =
      achievement.length > 0
        ? achievement
            .map((el, i) => {
              return `options[filter][achievements][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newStatus =
      status.length > 0
        ? status
            .map((el, i) => {
              return `options[filter][fields][status][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newPriority =
      priority.length > 0
        ? priority
            .map((el, i) => {
              return `options[filter][fields][priority][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newComplete =
      complete.length > 0
        ? complete
            .map((el, i) => {
              return `options[filter][fields][is_complete]=${el}`;
            })
            .join("&")
        : null;

    const newGroups =
      groups.length > 0
        ? groups
            .map((el, i) => {
              return `options[filter][fields][task_group_id][${i}]=${el}`;
            })
            .join("&")
        : null;

    const asignees =
      ownerId.length > 0
        ? ownerId.map((el, i) => {
            return `${el?.member?.first_name} ${el?.member?.last_name}`;
          })
        : null;

    // // console.log("ownerss", owners);
    // // console.log("newPeriods", newPeriods);
    // // console.log("newPriority", newPriority);
    // // console.log("newStatus", newStatus);

    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: newPeriods,
        filterAssignee: owners,
        filterAchievement: newAchievement,
        filterStatus: newStatus,
        filterPriority: newPriority,
        filterComplete: newComplete,
        filterGroup: newGroups,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: asignees,
      },
    });
    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  return (
    <div>
      <Box minWidth={250} maxWidth={330}>
        <Box m={2}>
          <Typography variant="caption" className={classes.title555}>
            <b>Custom Filters</b>
          </Typography>
        </Box>
        <List dense>
          <ListItem
            button
            onClick={handlePeriod}
            style={{
              backgroundColor: openPeriod && "rgba(251, 234, 234, 0.5)",
              color: openPeriod && "#C8203C",
            }}
          >
            <ListItemText primary="Start Date" />
            {openPeriod ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openPeriod} timeout="auto" unmountOnExit>
            {/* <FilterPeriod onPeriods={setPeriods} /> */}
            <Box pt={1} pb={2} px={3} display="flex" flexWrap="wrap">
              {periodCollections.slice(0, 3).map((item, i) => {
                const isItemSelected = isSelectedPeriods(item.code);

                const handleChangeCheckbox = (item) => {
                  const selectedIndex = periods.indexOf(item.code);

                  let newSelected = [];

                  if (selectedIndex === -1) {
                    newSelected = newSelected.concat(periods, item.code);
                  } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(periods.slice(1));
                  } else if (selectedIndex === periods.length - 1) {
                    newSelected = newSelected.concat(periods.slice(0, -1));
                  } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                      periods.slice(0, selectedIndex),
                      periods.slice(selectedIndex + 1)
                    );
                  }

                  setPeriods(newSelected);
                };

                return (
                  <div key={i}>
                    <FormControlLabel
                      style={{ margin: 0, padding: 0 }}
                      label={
                        <Box display="flex" alignItems="center">
                          <p
                            style={{
                              color: "#555",
                              fontSize: 14,
                              marginLeft: 3,
                            }}
                            className={classes.title}
                          >
                            {item.name}
                          </p>
                        </Box>
                      }
                      control={
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checked={isItemSelected}
                          onChange={() => handleChangeCheckbox(item)}
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>

          <ListItem
            button
            onClick={handleEndDate}
            style={{
              backgroundColor: openEnddate && "rgba(251, 234, 234, 0.5)",
              color: openEnddate && "#C8203C",
            }}
          >
            <ListItemText primary="End Date" />
            {openEnddate ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openEnddate} timeout="auto" unmountOnExit>
            {/* <FilterPeriod onPeriods={setPeriods} /> */}
            <Box pt={1} pb={2} px={3}>
              {periodCollections.slice(3, 8).map((item, i) => {
                const isItemSelected = isSelectedPeriods(item.code);

                const handleChangeCheckbox = (item) => {
                  const selectedIndex = periods.indexOf(item.code);

                  let newSelected = [];

                  if (selectedIndex === -1) {
                    newSelected = newSelected.concat(periods, item.code);
                  } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(periods.slice(1));
                  } else if (selectedIndex === periods.length - 1) {
                    newSelected = newSelected.concat(periods.slice(0, -1));
                  } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                      periods.slice(0, selectedIndex),
                      periods.slice(selectedIndex + 1)
                    );
                  }

                  setPeriods(newSelected);
                };

                return (
                  <div key={i}>
                    <FormControlLabel
                      style={{ margin: 0, padding: 0 }}
                      label={
                        <Box display="flex" alignItems="center">
                          <p
                            style={{
                              color: "#555",
                              fontSize: 14,
                              marginLeft: 3,
                            }}
                            className={classes.title}
                          >
                            {item.name}
                          </p>
                        </Box>
                      }
                      control={
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checked={isItemSelected}
                          onChange={() => handleChangeCheckbox(item)}
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>

          <ListItem
            button
            onClick={handleAssignee}
            style={{
              backgroundColor: openAssignee && "rgba(251, 234, 234, 0.5)",
              color: openAssignee && "#C8203C",
            }}
          >
            <ListItemText primary="Asignee" />
            {openAssignee ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openAssignee} timeout="auto" unmountOnExit>
            <FilterAssignee
              classes={classes}
              ownerCollections={ownerCollections}
              onOwnerId={setOwnerId}
            />
          </Collapse>

          <ListItem
            button
            onClick={handleAchievement}
            style={{
              backgroundColor: openAchievement && "rgba(251, 234, 234, 0.5)",
              color: openAchievement && "#C8203C",
            }}
          >
            <ListItemText primary="Achievement" />
            {openAchievement ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openAchievement} timeout="auto" unmountOnExit>
            <Box pt={1} pb={2} px={3} display="flex" flexDirection="column">
              {achievementCollections.map((item, i) => {
                const isItemSelected = isSelectedAchievement(item.code);

                const handleChangeCheckbox = (item) => {
                  const selectedIndex = achievement.indexOf(item.code);

                  let newSelected = [];

                  if (selectedIndex === -1) {
                    newSelected = newSelected.concat(achievement, item.code);
                  } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(achievement.slice(1));
                  } else if (selectedIndex === achievement.length - 1) {
                    newSelected = newSelected.concat(achievement.slice(0, -1));
                  } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                      achievement.slice(0, selectedIndex),
                      achievement.slice(selectedIndex + 1)
                    );
                  }

                  setAchievement(newSelected);
                };

                return (
                  <div key={i}>
                    <FormControlLabel
                      style={{ margin: 0, padding: 0 }}
                      label={
                        <Box display="flex" alignItems="center">
                          <p
                            style={{
                              color: "#555",
                              fontSize: 14,
                              marginLeft: 3,
                            }}
                            className={classes.title}
                          >
                            {item.name}
                          </p>
                        </Box>
                      }
                      control={
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checked={isItemSelected}
                          onChange={() => handleChangeCheckbox(item)}
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>

          <ListItem
            button
            onClick={handleStatus}
            style={{
              backgroundColor: openStatus && "rgba(251, 234, 234, 0.5)",
              color: openStatus && "#C8203C",
            }}
          >
            <ListItemText primary="Status" />
            {openStatus ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openStatus} timeout="auto" unmountOnExit>
            <Box pt={1} pb={2} px={3} display="flex" flexWrap="wrap">
              {statusCollections.map((item, i) => {
                const isItemSelected = isSelectedStatus(item.code);

                const handleChangeCheckbox = (item) => {
                  const selectedIndex = status.indexOf(item.code);

                  let newSelected = [];

                  if (selectedIndex === -1) {
                    newSelected = newSelected.concat(status, item.code);
                  } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(status.slice(1));
                  } else if (selectedIndex === status.length - 1) {
                    newSelected = newSelected.concat(status.slice(0, -1));
                  } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                      status.slice(0, selectedIndex),
                      status.slice(selectedIndex + 1)
                    );
                  }

                  setStatus(newSelected);
                };

                return (
                  <div key={i}>
                    <FormControlLabel
                      style={{ margin: 0, padding: 0 }}
                      label={
                        <p
                          style={{
                            backgroundColor: item.color,
                            fontSize: 14,
                            color: "#fff",
                          }}
                          className={classes.statusItem}
                        >
                          {item.name}
                        </p>
                      }
                      control={
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checked={isItemSelected}
                          onChange={() => handleChangeCheckbox(item)}
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>

          <ListItem
            button
            onClick={handlePriority}
            style={{
              backgroundColor: openPriority && "rgba(251, 234, 234, 0.5)",
              color: openPriority && "#C8203C",
            }}
          >
            <ListItemText primary="Priority" />
            {openPriority ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openPriority} timeout="auto" unmountOnExit>
            <Box pt={1} pb={2} px={3} display="flex" flexDirection="column">
              {priorityCollections.map((item, i) => {
                const isItemSelected = isSelectedPriority(item.code);

                const handleChangeCheckbox = (item) => {
                  const selectedIndex = priority.indexOf(item.code);

                  let newSelected = [];

                  if (selectedIndex === -1) {
                    newSelected = newSelected.concat(priority, item.code);
                  } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(priority.slice(1));
                  } else if (selectedIndex === priority.length - 1) {
                    newSelected = newSelected.concat(priority.slice(0, -1));
                  } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                      priority.slice(0, selectedIndex),
                      priority.slice(selectedIndex + 1)
                    );
                  }

                  setPriority(newSelected);
                };

                return (
                  <div key={i}>
                    <FormControlLabel
                      style={{ margin: 0, padding: 0 }}
                      label={
                        <Box display="flex" alignItems="center">
                          <img src={item.icon} height={14} />
                          <p
                            style={{
                              color: "#555",
                              fontSize: 14,
                              marginLeft: 3,
                            }}
                            className={classes.title}
                          >
                            {item.name}
                          </p>
                        </Box>
                      }
                      control={
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checked={isItemSelected}
                          onChange={() => handleChangeCheckbox(item)}
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>

          <ListItem
            button
            onClick={handleComplete}
            style={{
              backgroundColor: openComplete && "rgba(251, 234, 234, 0.5)",
              color: openComplete && "#C8203C",
            }}
          >
            <ListItemText primary="Complete" />
            {openComplete ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openComplete} timeout="auto" unmountOnExit>
            <Box pt={1} pb={2} px={3} display="flex" flexDirection="column">
              {completedCollections.map((item, i) => {
                const isItemSelected = isSelectedComplete(item.code);

                const handleChangeCheckbox = (item) => {
                  const selectedIndex = complete.indexOf(item.code);

                  let newSelected = [];

                  if (selectedIndex === -1) {
                    newSelected = newSelected.concat(complete, item.code);
                  } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(complete.slice(1));
                  } else if (selectedIndex === complete.length - 1) {
                    newSelected = newSelected.concat(complete.slice(0, -1));
                  } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                      complete.slice(0, selectedIndex),
                      complete.slice(selectedIndex + 1)
                    );
                  }

                  setComplete(newSelected);
                };

                return (
                  <div key={i}>
                    <FormControlLabel
                      style={{ margin: 0, padding: 0 }}
                      label={
                        <p
                          style={{ color: "#555", fontSize: 14 }}
                          className={classes.title}
                        >
                          {item.name}
                        </p>
                      }
                      control={
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checked={isItemSelected}
                          onChange={() => handleChangeCheckbox(item)}
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>

          <ListItem
            button
            onClick={handleGroup}
            style={{
              backgroundColor: openGroup && "rgba(251, 234, 234, 0.5)",
              color: openGroup && "#C8203C",
            }}
          >
            <ListItemText primary="Task Group" />
            {openGroup ? <ExpandFalse color="#C8203C" /> : <ExpandTrue />}
          </ListItem>
          <Collapse in={openGroup} timeout="auto" unmountOnExit>
            <Box pt={1} pb={2} px={3} display="flex" flexDirection="column">
              {listGroup.map((item, i) => {
                const isItemSelected = isSelectedGroups(item.id);

                const handleChangeCheckbox = (item) => {
                  const selectedIndex = groups.indexOf(item.id);

                  let newSelected = [];

                  if (selectedIndex === -1) {
                    newSelected = newSelected.concat(groups, item.id);
                  } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(groups.slice(1));
                  } else if (selectedIndex === groups.length - 1) {
                    newSelected = newSelected.concat(groups.slice(0, -1));
                  } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                      groups.slice(0, selectedIndex),
                      groups.slice(selectedIndex + 1)
                    );
                  }

                  setGroups(newSelected);
                };

                return (
                  <div key={i}>
                    <FormControlLabel
                      style={{ margin: 0, padding: 0 }}
                      label={
                        <p
                          style={{ color: "#555", fontSize: 14 }}
                          className={classes.title}
                        >
                          {item.name}
                        </p>
                      }
                      control={
                        <Checkbox
                          className={classes.checkboxRoot}
                          disableRipple
                          color="default"
                          checked={isItemSelected}
                          onChange={() => handleChangeCheckbox(item)}
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                    />
                  </div>
                );
              })}
            </Box>
          </Collapse>
        </List>
      </Box>
      <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          size="small"
          varian="outlined"
          className={classes.button}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Box mx={0.5} />
        <Button
          size="small"
          variant="outlined"
          className={classes.button1}
          onClick={handleFilterTask}
        >
          Show Result
        </Button>
      </Box>
    </div>
  );
};

export default AnchorFilterTask;

const periodCollections = [
  {
    id: "0",
    code: "not_started",
    name: "Not Started",
  },
  {
    id: "1",
    code: "about_to_start",
    name: "About to start",
  },
  {
    id: "2",
    code: "started",
    name: "Started",
  },
  {
    id: "3",
    code: "early",
    name: "Completed (Early)",
  },
  {
    id: "4",
    code: "late",
    name: "Completed (Late)",
  },
  {
    id: "5",
    code: "on_time",
    name: "Completed (On Time)",
  },
  {
    id: "6",
    code: "overdue",
    name: "Overdue",
  },
  {
    id: "7",
    code: "overdue_soon",
    name: "Overdue Soon",
  },
];

const achievementCollections = [
  {
    id: "1",
    code: "scope_on_target",
    name: "Scope On Target",
  },
  {
    id: "2",
    code: "scope_behind_target",
    name: "Scope Behind Target",
  },
  {
    id: "3",
    code: "schedule_on_target",
    name: "Schedule On Target",
  },
  {
    id: "4",
    code: "schedule_behind_target",
    name: "Schedule Behind Target",
  },
];

const statusCollections = [
  {
    id: "TO DO",
    code: "TO DO",
    name: "To Do",
    color: "#888888",
  },
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    color: "#73CDEE",
  },
  {
    id: "DONE",
    code: "DONE",
    name: "Done",
    color: "#74CB80",
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    color: "#EFCB0F",
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    color: "#92739F",
  },
];

const priorityCollections = [
  {
    id: "HIGH",
    code: "HIGH",
    name: "High",
    sort_order: 0,
    icon: icon_high,
  },
  {
    id: "MEDIUM",
    code: "MEDIUM",
    name: "Medium",
    sort_order: 1,
    icon: icon_medium,
  },
  {
    id: "LOW",
    code: "LOW",
    name: "Low",
    sort_order: 2,
    icon: icon_low,
  },
];

const completedCollections = [
  {
    id: "1",
    code: "1",
    name: "incompleted",
    sort_order: 0,
  },
  {
    id: "2",
    code: "2",
    name: "completed",
    sort_order: 1,
  },
];
