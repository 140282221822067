import React, { useEffect, useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  Button
} from '@material-ui/core';
import CompareArrowIcon from '@material-ui/icons/CompareArrows';
import { navigate } from 'hookrouter';

import ListItemTopGoals from './ListItemTopGoals';
import ListItemTopMas from './ListItemTopMas';

const TopGoals = ({ classes, dashboardData, selectDashboardUnitLabel }) => {
  const [top, setTop] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dashboardData) {
      setLoading(false);
      if (top === '') {
        setTop('goal');
      }
    }
  }, [dashboardData]);

  const handleTop = () => {
    if (top === 'goal') {
      setTop('ma');
    }
    if (top === 'ma') {
      setTop('goal');
    }
  };

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={classes.title333} variant="subtitle1">
            <b>
              {top === 'goal' ? 'Goal Teratas' : 'Measured Activity Teratas'}
              <br />
              {selectDashboardUnitLabel}
            </b>
          </Typography>

          <Button
            variant="outlined"
            size="small"
            className={classes.button0}
            endIcon={<CompareArrowIcon style={{ color: '#d64253' }} />}
            onClick={handleTop}
          >
            {top === 'goal' ? 'MA' : 'Goal'}
          </Button>
        </Box>

        {top === 'goal' && loading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              size={14}
              style={{ color: '#d64253', marginRight: 5 }}
            />
            <Typography className={classes.title888} variant="caption">
              Loading...
            </Typography>
          </Box>
        )}
        {top === 'goal' &&
          !loading &&
          dashboardData.topGoalDashboards !== undefined &&
          dashboardData.topGoalDashboards.length > 0 &&
          dashboardData.topGoalDashboards
            .slice(0, 5)
            .map((item, i) => (
              <ListItemTopGoals key={i} classes={classes} item={item} />
            ))}

        {top === 'ma' && loading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
              size={14}
              style={{ color: '#d64253', marginRight: 5 }}
            />
            <Typography className={classes.title888} variant="caption">
              Loading...
            </Typography>
          </Box>
        )}
        {top === 'ma' &&
          !loading &&
          dashboardData.topMeasuredActivities.length > 0 &&
          dashboardData.topMeasuredActivities
            .slice(0, 5)
            .map((item, i) => (
              <ListItemTopMas key={i} classes={classes} item={item} />
            ))}

        {top === 'goal' &&
          !loading &&
          dashboardData.topGoalDashboards !== undefined &&
          dashboardData.topGoalDashboards.length === 0 && (
            <Typography className={classes.title333} variant="subtitle2">
              Anda tidak memliki Goal
            </Typography>
          )}

        {top === 'ma' &&
          !loading &&
          dashboardData &&
          dashboardData.topMeasuredActivities.length === 0 && (
            <Typography className={classes.title333} variant="subtitle2">
              Anda tidak memliki Measured Activity
            </Typography>
          )}

        <Box mt={2} display="flex" justifyContent="center">
          <Typography
            className={classes.txtViewMore}
            variant="subtitle2"
            onClick={() => navigate(top === 'goal' ? '/goal' : '/all-ma')}
          >
            View More
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default TopGoals;
