import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { IconButton, Snackbar } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  /*
        ````````````````
        INFO ERROR PAPER

        ````````````````
    */
  paperStyle: {
    // marginLeft : theme.spacing(3),
    // marginRight : theme.spacing(3),
    // marginBottom : theme.spacing(3),
    backgroundColor: "#ffcbcb",
  },
  titlePaperStyle: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    // marginLeft: theme.spacing(3),
    fontFamily: "Roboto",
    color: "#a50016",
  },
  closePaperStyle: {
    color: "#a50016",
  },
});

const Snackber = (props) => {
  const { classes, isFormFilledAll, setFormFilledAll } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isFormFilledAll}
        autoHideDuration={6000}
        onClose={() => setFormFilledAll(false)}
        ContentProps={{
          "aria-describedby": "message-id",
          classes: {
            root: classes.paperStyle,
          },
        }}
        message={
          <span id="message-id" className={classes.titlePaperStyle}>
            <b>Anda belum mengisi semua data yang wajib di isi !</b>
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            // color="inherit"
            className={classes.closePaperStyle}
            onClick={() => setFormFilledAll(false)}>
            <i className="material-icons">close</i>
          </IconButton>,
        ]}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(Snackber);
