// @ts-nocheck
import React from "react";

import { useRoutes, useRedirect } from "hookrouter";

import {
  ToNewSoTreeViewChartPreview,
  ToNewSoTreeViewOrgChart,
  ToHomeOfNewSO,
} from "./Constants/config-navigate-url-with-hookrouter";

import ContextGlobalNewSO from "./Context/ContextGlobalNewSO";

import NotFoundPage from "./NotFoundPage";
import ViewNewSOTreeview from "./ViewNewSOTreeview";
import ViewOrgChart from "./ViewOrgChart";

const routes = {
  [ToNewSoTreeViewChartPreview]: () => <ViewNewSOTreeview />,
  [ToNewSoTreeViewOrgChart]: () => (
    <ContextGlobalNewSO>
      <ViewOrgChart />
    </ContextGlobalNewSO>
  ),
};

const ViewNewSO = (props) => {
  /*
  
     -------------------------------
    |                                 |
    | HANDLE ROUTER WITH HOOK ROUTER  |
    |                                 |
      -------------------------------
   */

  const statusBantuSaya = localStorage.getItem("question_bantu_generate");

  useRedirect(
    ToHomeOfNewSO,
    statusBantuSaya === "bantu"
      ? ToNewSoTreeViewChartPreview
      : ToNewSoTreeViewOrgChart
  );

  const routeResult = useRoutes(routes);

  return routeResult || <NotFoundPage />;
};

export default ViewNewSO;
