import React from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import moment from "moment";
import TruncateTextShort from "../../../../utilities/TruncateTextShort";

const DialogInfoRecycleBin = ({
  item,
  classes,
  origin,
  openDialogInfoRecycleBin,
  setOpenDialogInfoRecycleBin,
}) => {
  return (
    <Dialog
      open={openDialogInfoRecycleBin}
      onClose={() => setOpenDialogInfoRecycleBin(false)}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent style={{ width: 380, paddingBottom: 30, marginTop: 20 }}>
        <Box style={{ textAlign: "center" }}>
          <InfoOutlinedIcon
            style={{ fontSize: 30, color: "#888", marginBottom: 12 }}
          />
        </Box>

        <Grid container justify="flex-end">
          <Grid item md={5}>
            <List className={classes.title}>
              <ListItem>
                <ListItemText
                  secondary={
                    <b>{origin === "file" ? "File name" : "Folder name"} : </b>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  secondary={
                    <b>
                      {origin === "file" ? "File owner" : "Folder owner"} :{" "}
                    </b>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  secondary={
                    <b>{origin === "file" ? "File path" : "Folder path"} : </b>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText secondary={<b>Date Deleted : </b>} />
              </ListItem>

              <ListItem>
                <ListItemText secondary={<b>Deleted By : </b>} />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6}>
            <List className={classes.title}>
              <ListItem>
                {origin === "file" && (
                  <Tooltip title={item.file_name} placement="top-end">
                    <ListItemText
                      secondary={
                        item.file_name !== undefined &&
                        TruncateTextShort(item.file_name)
                      }
                    />
                  </Tooltip>
                )}
                {origin === "folder" && (
                  <Tooltip title={item.folder_name} placement="top-end">
                    <ListItemText
                      secondary={
                        item.folder_name !== undefined &&
                        TruncateTextShort(item.folder_name)
                      }
                    />
                  </Tooltip>
                )}
              </ListItem>

              <ListItem>
                {origin === "file" && (
                  <ListItemText
                    secondary={
                      item.fileOwner !== undefined && item.fileOwner !== null
                        ? `${item.fileOwner.member.first_name} ${item.fileOwner.member.last_name}`
                        : "User has been deleted"
                    }
                  />
                )}
                {origin === "folder" && (
                  <ListItemText
                    secondary={
                      item.folderOwner !== undefined &&
                      item.folderOwner !== null
                        ? `${item.folderOwner.member.first_name} ${item.folderOwner.member.last_name}`
                        : "User has been deleted"
                    }
                  />
                )}
              </ListItem>

              <ListItem>
                {origin === "file" && (
                  <Tooltip title={item.file_directory_name} placement="top-end">
                    <ListItemText
                      secondary={
                        item.file_directory_name !== undefined &&
                        TruncateTextShort(item.file_directory_name)
                      }
                    />
                  </Tooltip>
                )}
                {origin === "folder" && (
                  <Tooltip
                    title={item.folder_directory_name}
                    placement="top-end">
                    <ListItemText
                      secondary={
                        item.folder_directory_name !== undefined &&
                        TruncateTextShort(item.folder_directory_name)
                      }
                    />
                  </Tooltip>
                )}
              </ListItem>

              <ListItem>
                <ListItemText
                  secondary={moment(item.executed_at).format(
                    "DD MMM YYYY, HH:mm"
                  )}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  secondary={
                    item.executedBy === null
                      ? "User has been deleted"
                      : `${item.executedBy.member.first_name} ${item.executedBy.member.last_name}`
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DialogInfoRecycleBin;
