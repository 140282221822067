import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  TextField,
} from "@material-ui/core";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

const DialogEditUnitType = ({
  classes,
  item,
  openEditUnitType,
  setOpenEditUnitType,
  onTriggerType,
}) => {
  const [name, setName] = useState(item.name);
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleName = (e) => setName(e.target.value);

  const handleEditUnitType = () => {
    setLoading(true);

    const data = {
      MasterStructureUnitType: {
        name,
      },
    };

    AxiosConfig.put(
      `${URL_API}/human-resource/master-structure-unit-type/${item.id}`,
      data
    )
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setLoading(false);
          setOpenEditUnitType(false);
          onTriggerType(Math.floor(Math.random() * 100));
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openEditUnitType}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Ubah Nama Tipe Unit</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box>
          <Typography variant="subtitle2" className={classes.title888}>
            Nama Unit Type
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={name}
            onChange={handleName}
          />
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenEditUnitType(false)}
        >
          Batal
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleEditUnitType}
        >
          {loading ? "Mengubah..." : "Ubah"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogEditUnitType;
