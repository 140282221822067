export const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "white",
    textTransform: "capitalize",
    "&:disabled": {
      background: "grey",
      color: "white",
    },
  },
  buttonModal: {
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
  },
  buttonModalDelete: {
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
  },
  buttonModalCancel: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  buttonOutlined: {
    borderRadius: 5,
    // background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "grey",
    // color: '#d2d2d2',
    textTransform: "capitalize",
    "&:hover": {
      color: "grey",
      background: "#d2d2d2",
      borderWidth: 0,
    },
  },
  paperKeluarga: {
    width: "95%",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
  titleHeader: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop:  theme.spacing(2),
  },
  titleTambahAnggotaKeluarga: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: "#cc0707",
    cursor: "pointer",
  },
  title: {
    fontFamily: "Roboto",
  },
  titleForm: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    // marginTop: theme.spacing(2),
  },
  textField: {
    minWidth: 425,
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(1),
  },
  titlenomorHandphone: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
});
