import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styles } from "./Style/StyleOvertimeSetting";
import { navigate } from "hookrouter";

import ContextOvertimeSetting from "./Context/ContextOvertimeSetting";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core";

import ViewOvertimeCompany from "./Components/TabOvertimeCompany/ViewOvertimeCompany";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DialogProgress from "./Components/DialogProgress";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ViewOvertimeSetting = () => {
  const classes = useStyles();
  const context = useContext(ContextOvertimeSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const [loader, setLoader] = useState(false);

  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      // Category
      getOvertimeCompany();
    } else if (newValue === 1) {
      //
    }
  };

  useEffect(() => {
    getOvertimeCompany();
  }, []);

  // Overtime Company
  const [dataOvertimeCompany, setDataOvertimeCompany] = useState({
    id: "",
    is_default_compensation_overtime: 0,
    nominal_compensation_overtime_per_hour: "",
    overtime_multiplier: {
      working_days: [],
      holiday: [],
    },
    rounding_setting: [],
  });

  const getOvertimeCompany = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/overtime-setting/group`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        let dataOvertime = response.data.data;
        let dataTemp = dataOvertimeCompany;
        dataTemp.id = dataOvertime.id;
        dataTemp.is_default_compensation_overtime =
          dataOvertime.is_default_compensation_overtime
            ? parseInt(dataOvertime.is_default_compensation_overtime)
            : 0;
        dataTemp.nominal_compensation_overtime_per_hour =
          dataOvertime.nominal_compensation_overtime_per_hour;
        let working_days = [];
        if (
          dataOvertime.overtime_multiplier &&
          dataOvertime.overtime_multiplier[0] &&
          dataOvertime.overtime_multiplier[0].working_days &&
          dataOvertime.overtime_multiplier[0].working_days[0]
        ) {
          for (const key in dataOvertime.overtime_multiplier[0]
            .working_days[0]) {
            if (
              dataOvertime.overtime_multiplier[0].working_days[0].hasOwnProperty(
                key
              )
            ) {
              let hour = key;
              let hourArray = hour.split("-");
              working_days.push({
                from_hour: hourArray[0].replace("h", ""),
                to_hour: hourArray[1].replace("h", ""),
                multiply: dataOvertime.overtime_multiplier[0].working_days[0][
                  key
                ].replace("x", ""),
              });
            }
          }
        }
        let holiday = [];
        if (
          dataOvertime.overtime_multiplier &&
          dataOvertime.overtime_multiplier[0] &&
          dataOvertime.overtime_multiplier[0].holiday &&
          dataOvertime.overtime_multiplier[0].holiday[0]
        ) {
          for (const key in dataOvertime.overtime_multiplier[0].holiday[0]) {
            if (
              dataOvertime.overtime_multiplier[0].holiday[0].hasOwnProperty(key)
            ) {
              let hour = key;
              let hourArray = hour.split("-");
              holiday.push({
                from_hour: hourArray[0].replace("h", ""),
                to_hour: hourArray[1].replace("h", ""),
                multiply: dataOvertime.overtime_multiplier[0].holiday[0][
                  key
                ].replace("x", ""),
              });
            }
          }
        }
        let rounding_setting = [];
        if (dataOvertime.rounding_setting && dataOvertime.rounding_setting[0]) {
          for (const key in dataOvertime.rounding_setting[0]) {
            if (dataOvertime.rounding_setting[0].hasOwnProperty(key)) {
              let hour = key;
              let hourArray = hour.split("-");
              rounding_setting.push({
                from_minute: hourArray[0].replace("m", ""),
                to_minute: hourArray[1].replace("m", ""),
                round_minute: dataOvertime.rounding_setting[0][key].replace(
                  "m",
                  ""
                ),
              });
            }
          }
        }
        dataTemp.rounding_setting = rounding_setting;
        dataTemp.overtime_multiplier.working_days = working_days;
        dataTemp.overtime_multiplier.holiday = holiday;
        setDataOvertimeCompany({ ...dataTemp });
        setLoader(false);
        context.setModalProgress(false);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        <div className={classes.tabContainer}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab
              id="vertical-tab-work-pattern"
              className={classes.tabTitle}
              aria-controls={`vertical-tabpanel-work-pattern`}
              label={
                <React.Fragment>
                  <AccessTimeIcon />
                  <span
                    className={classes.title333}
                    style={{ marginTop: "7px", fontWeight: "bold" }}
                  >
                    Overtime Company
                  </span>
                </React.Fragment>
              }
            />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            className={classes.tabPanelContainer}
          >
            <ViewOvertimeCompany
              classes={classes}
              isModalProgress={context.isModalProgress}
              setModalProgress={context.setModalProgress}
              dataOvertimeCompany={dataOvertimeCompany}
              setDataOvertimeCompany={setDataOvertimeCompany}
              getOvertimeCompany={getOvertimeCompany}
            />
          </TabPanel>
        </div>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
    </MuiThemeProvider>
  );
};

export default ViewOvertimeSetting;
