export const FormatDecimal = (num) => {
  const toNumb = Number(num);

  return toNumb
    .toFixed(2) // always two decimal digits
    .replace(".", ",") // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
};

export const FormatThousand = (num) => {
  const toNumb = Number(num);

  return toNumb
    .toFixed(0) // always two decimal digits
    .replace(".", ",") // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
};

export const FormatFloat = (num, float) => {
  const str = `${num}`;

  return str.slice(0, str.indexOf(".") + float + 1).replace(".", ",");
};
