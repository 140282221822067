export const parseQueryString = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsObject = {};

  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  return queryParamsObject;
};

export const findQueryString = (key) => {
  const queryParams = parseQueryString();

  return queryParams?.[key] || '';
}

export const updateQueryParams = (key, value) => {
  const params = new URLSearchParams(window.location.search);

  if (value) {
    params.set(key, value);
  } else {
    params.delete(key);
  }

  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
}

export const findQueryCommaToArr = (key) => {
  const queryParams = parseQueryString();

  let value = queryParams?.key || '';

  if (value !== '' && value !== undefined && value !== null) {
    value = value.split(',');
  }

  return value;
}

export const updateQueryCommaToArr = (key, value) => {
  const params = new URLSearchParams(window.location.search);

  if (value && value !== undefined && value !== null) {
    params.set(key, value.join(','));
  } else {
    params.delete(key);
  }

  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
}

export const parseCommaToArr = (input) => {
  return input.split(',');
}
