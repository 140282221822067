import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import moment from "moment";

import Axios from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextGoalDetail from "../Context/ContextGoalDetail";

import ListFreqItem from "./frequency/ListFreqItem";
import FreqWeekly from "./frequency/FreqWeekly";
import FreqMonthly from "./frequency/FreqMonthly";
import FreqDaily from "./frequency/FreqDaily";
import FreqHourly from "./frequency/FreqHourly";
import SnackbarError from "../../../../components/SnackbarError";
import handleError from "../../../Report/components/global/handleError";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogUpdateFrequency = ({
  classes,
  openDialogUpdateFreq,
  setOpenDialogUpdateFreq,
  goalDetailState,
  setFireGoalDetail,
}) => {
  const context = useContext(ContextGoalDetail);
  const locationPathGoalId = window.location.pathname.split("/")[2];
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  // ================================================================
  // ~~~~~~~~~~~~~~ STATE ~~~~~~~~~~~~~~
  // ================================================================
  const [freq, setFreq] = useState({
    selected: null,
    code: "daily",
    name: "Daily",
    selected: true,
  });
  const [loading, setLoading] = useState(false);
  const [freqDetails, setFreqDetails] = useState(defaultFreqDetails);
  const [hourlyFreq, setHourlyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "hourly",
    schedule_type: "minute",
    scheduled_at: "00",
    input_start: "-",
    input_finish: "-",
    is_locked: false,
    reminder: "",
    auto_insert_result: "0",
    hourly_details: {
      selected_times: [],
      selected_days: [],
    },
  });
  const [dailyFreq, setDailyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "daily",
    schedule_type: "hour",
    scheduled_at: "00:00",
    input_start: "-",
    input_finish: "-",
    is_locked: false,
    reminder: "",
    auto_insert_result: "0",
    daily_details: {
      selected_days: [],
    },
  });
  const [weeklyFreq, setWeeklyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "weekly",
    schedule_type: "day",
    scheduled_at: "friday-00:00",
    input_start: "",
    input_finish: "",
    is_locked: false,
    reminder: "",
    auto_insert_result: "0",
    weekly_details: {
      selected_weeks: [],
    },
  });

  const [monthlyFreq, setMonthlyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "monthly",
    schedule_type: "date",
    scheduled_at: "25-00:00",
    input_start: "-",
    input_finish: "-",
    result_locked: 0,
    reminder: "",
    auto_insert_result: "0",
    is_locked: false,
    monthly_details: {
      selected_months: [],
    },
  });
  const [triggerCreateForm, setTriggerCreateForm] = useState(null);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);
  const [remaksText, setRemarksText] = useState("Update Interval");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );
  const [timeErrMessage, setTimeErrMessage] = useState("");

  // change freq interval
  const [changeFreq, setChangeFreq] = useState("");
  const [changeSchedule, setChangeSchedule] = useState("");
  const [lengthInterval, setLengthInterval] = useState(0);

  // ================================================================
  // ~~~~~~~~~~~~~~ Side Effect ~~~~~~~~~~~~~~
  // ================================================================

  useEffect(() => {
    if (openDialogUpdateFreq) {
      const schdl_hourly =
        goalDetailState?.update_frequency === null
          ? "00"
          : goalDetailState?.update_frequency?.interval !== "hourly"
          ? "00"
          : goalDetailState?.update_frequency?.scheduled_at;

      const schdl_daily =
        goalDetailState?.update_frequency === null
          ? "17:00"
          : goalDetailState?.update_frequency?.interval !== "daily"
          ? "17:00"
          : goalDetailState?.update_frequency?.scheduled_at;

      const schdl_weekly =
        goalDetailState?.update_frequency === null
          ? "friday-17:00"
          : goalDetailState?.update_frequency?.interval !== "weekly"
          ? "friday-17:00"
          : goalDetailState?.update_frequency?.scheduled_at;

      const schdl_monthly =
        goalDetailState?.update_frequency === null
          ? "25-17:00"
          : goalDetailState?.update_frequency?.interval !== "monthly"
          ? "25-17:00"
          : goalDetailState?.update_frequency?.scheduled_at;

      setHourlyFreq({
        ...hourlyFreq,
        scheduled_at: schdl_hourly,
      });

      setDailyFreq({
        ...dailyFreq,
        scheduled_at: schdl_daily,
      });

      setWeeklyFreq({
        ...weeklyFreq,
        scheduled_at: schdl_weekly,
      });

      setMonthlyFreq({
        ...monthlyFreq,
        scheduled_at: schdl_monthly,
      });
    }
  }, [openDialogUpdateFreq, goalDetailState]);

  useEffect(() => {
    if (openDialogUpdateFreq && goalDetailState?.update_frequency === null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      Axios.get(
        `${URL_API}/swd/update-frequency/create?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goalDetailState.start_date}&input_finish=${goalDetailState.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Cret Freq", res);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openDialogUpdateFreq]);

  useEffect(() => {
    if (openDialogUpdateFreq && goalDetailState?.update_frequency !== null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      // // console.log("sched_at", sched_at);

      Axios.get(
        `${URL_API}/swd/update-frequency/edit/${locationPathGoalId}?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goalDetailState.start_date}&input_finish=${goalDetailState.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const freqExist = goalDetailState?.update_frequency;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Updte Freq", res);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openDialogUpdateFreq]);

  useEffect(() => {
    if (openDialogUpdateFreq && goalDetailState?.update_frequency === null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      Axios.get(
        `${URL_API}/swd/update-frequency/create?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goalDetailState.start_date}&input_finish=${goalDetailState.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Cret Freq", res);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [freq, triggerCreateForm]);

  useEffect(() => {
    if (openDialogUpdateFreq && goalDetailState?.update_frequency !== null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      // // console.log("sched_at", sched_at);

      Axios.get(
        `${URL_API}/swd/update-frequency/edit/${locationPathGoalId}?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goalDetailState.start_date}&input_finish=${goalDetailState.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const freqExist = goalDetailState?.update_frequency;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Updte Freq", result);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [freq, triggerCreateForm]);

  useEffect(() => {
    if (openDialogUpdateFreq && goalDetailState?.update_frequency !== null) {
      const interval = goalDetailState?.update_frequency?.interval;

      setFreq({
        ...freq,
        code: interval,
        name: interval.charAt(0).toUpperCase() + interval.slice(1),
      });
    }
  }, [openDialogUpdateFreq]);

  // changging config intervals
  useEffect(() => {
    const frequency = goalDetailState?.update_frequency;
    const interval = frequency?.interval;
    const scheduledAt = frequency?.scheduled_at;

    if (interval !== freq.code) {
      setChangeFreq(freq.code);
    } else {
      setChangeFreq("");
    }

    switch (freq.code) {
      case "hourly":
        if (hourlyFreq.scheduled_at !== scheduledAt) {
          setChangeSchedule(hourlyFreq.scheduled_at);
        } else {
          setChangeSchedule("");
        }
        setLengthInterval(0);
        break;
      case "daily":
        if (dailyFreq.scheduled_at !== scheduledAt) {
          setChangeSchedule(dailyFreq.scheduled_at);
        } else {
          setChangeSchedule("");
        }
        setLengthInterval(dailyFreq.daily_details?.selected_days?.length);
        break;
      case "weekly":
        if (weeklyFreq.scheduled_at !== scheduledAt) {
          setChangeSchedule(weeklyFreq.scheduled_at);
        } else {
          setChangeSchedule("");
        }
        setLengthInterval(weeklyFreq.weekly_details?.selected_weeks?.length);
        break;
      case "monthly":
        if (monthlyFreq.scheduled_at !== scheduledAt) {
          setChangeSchedule(monthlyFreq.scheduled_at);
        } else {
          setChangeSchedule("");
        }
        setLengthInterval(monthlyFreq.monthly_details?.selected_months?.length);
        break;
      default:
        setChangeSchedule("");
    }
  }, [freq, hourlyFreq, dailyFreq, weeklyFreq, monthlyFreq]);

  // catch changging for log
  useEffect(() => {
    let TXT_LENGTH;
    const frequency = goalDetailState?.update_frequency;
    const interval = frequency?.interval;
    const scheduledAt = frequency?.scheduled_at;

    if (freq.code === "hourly") TXT_LENGTH = "Waktu";
    if (freq.code === "daily") TXT_LENGTH = "Hari";
    if (freq.code === "weekly") TXT_LENGTH = "Minggu";
    if (freq.code === "monthly") TXT_LENGTH = "Bulan";

    let _1 = `Interval dari ${interval} ke ${changeFreq}`;
    let _2 = `Jadwal dari ${scheduledAt} ke ${changeSchedule}`;
    let _3 = `Dipilih ${lengthInterval} ${TXT_LENGTH}`;

    const arrContent = [_1, _2, _3];

    const arrRemarks = [changeFreq, changeSchedule, lengthInterval];

    const selectArr = arrRemarks
      .map((item, i) => {
        let content;

        if (item !== "") {
          content = `${arrContent[i]}`;
        }

        return content;
      })
      .filter((el) => el !== undefined);

    const contentRem = `${selectArr.join(", ")} ${selectArr.length}`;

    setRemarksText(contentRem);
  }, [changeFreq, changeSchedule, lengthInterval]);

  // ================================================================
  // ~~~~~~~~~~~~~~ METHODs ~~~~~~~~~~~~~~
  // ================================================================
  const handleFreq = (data) => {
    setFreq(data);
  };

  const handleSaveFreq = () => {
    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data =
      freq.code === "daily"
        ? dailyFreq
        : freq.code === "hourly"
        ? hourlyFreq
        : freq.code === "weekly"
        ? weeklyFreq
        : monthlyFreq;

    // console.log("SAVE FREQ", data);
    // // console.log("Extist FREQ", freq.code);

    setLoading(true);

    Axios.post(`${URL_API}/swd/update-frequency/store`, data)
      .then((res) => {
        const dataLog = {
          user_activity_log: {
            module: "goal",
            event: "update",
            user_id: user?.user_id,
            user_email: user?.user_email,
            nama: `${user?.member_first_name} ${user?.member_last_name}`,
            position:
              user?.member_position?.structure_position_title_name === undefined
                ? ""
                : user?.member_position?.structure_position_title_name,
            device_type: device_os,
            device_model: "",
            device_version: OSName,
            long: "",
            lat: "",
            entity_id: locationPathGoalId,
            entity_name: goalDetailState?.name,
            remarks: remaksText,
            comment: "",
          },
        };

        setDataRemarks(dataLog);

        setOpenRemarksLog(true);

        // setOpenDialogUpdateFreq(false);
        setLoading(false);
        setTimeErrMessage("");
        setFireGoalDetail(true);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("EROR POST FREQ", error);

        // error response
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);

        if (
          listError.length === 1 &&
          listError[0]["key"] === "hourly_details"
        ) {
          setTimeErrMessage(listError[0]["description"]);
        } else {
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        }
      });
  };

  const handleUpdateFreq = () => {
    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data =
      freq.code === "daily"
        ? dailyFreq
        : freq.code === "hourly"
        ? hourlyFreq
        : freq.code === "weekly"
        ? weeklyFreq
        : monthlyFreq;

    // console.log("UPDATE FREQ Data", data);
    // // console.log("Extist FREQ", freq.code);

    setLoading(true);

    Axios.post(
      `${URL_API}/swd/update-frequency/update/${locationPathGoalId}`,
      data
    )
      .then((res) => {
        const dataLog = {
          user_activity_log: {
            module: "goal",
            event: "update",
            user_id: user?.user_id,
            user_email: user?.user_email,
            nama: `${user?.member_first_name} ${user?.member_last_name}`,
            position:
              user?.member_position?.structure_position_title_name === undefined
                ? ""
                : user?.member_position?.structure_position_title_name,
            device_type: device_os,
            device_model: "",
            device_version: OSName,
            long: "",
            lat: "",
            entity_id: locationPathGoalId,
            entity_name: goalDetailState?.name,
            remarks: remaksText,
            comment: "",
          },
        };

        setDataRemarks(dataLog);

        setOpenRemarksLog(true);

        // setOpenDialogUpdateFreq(false);
        setLoading(false);
        setTimeErrMessage("");
        setFireGoalDetail(true);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("EROR POST FREQ", error);

        // error response
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);

        if (
          listError.length === 1 &&
          listError[0]["key"] === "hourly_details"
        ) {
          setTimeErrMessage(listError[0]["description"]);
        } else {
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openDialogUpdateFreq}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            // backgroundImage: `url(${bgTitle})`,
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
        >
          <Typography variant="h4" style={{ fontFamily: "Lato" }}>
            Update Frequency
          </Typography>
        </DialogTitle>

        <DialogContent style={{ minWidth: 550 }}>
          {/* =========== Frequency =========== */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Frequency</b>
            </Typography>

            <Box ml={10}>
              {freq.selected !== null &&
                freqDetails.interval.default_value.length > 0 &&
                freqDetails.interval.default_value.map((item, i) => (
                  <ListFreqItem
                    key={i}
                    classes={classes}
                    item={item}
                    freq={freq}
                    onFreq={handleFreq}
                    onTrigger={setTriggerCreateForm}
                  />
                ))}
            </Box>
          </Box>

          {/* =========== Scheduled =========== */}
          {freq.code === "monthly" && (
            <FreqMonthly
              classes={classes}
              goalDetail={goalDetailState}
              onMonthlyFreq={setMonthlyFreq}
              monthlyFreq={monthlyFreq}
              monthList={freqDetails.monthly_details.default_value}
              remindList={freqDetails.reminder.default_value}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
          {freq.code === "hourly" && (
            <FreqHourly
              classes={classes}
              goalDetail={goalDetailState}
              onHourlyFreq={setHourlyFreq}
              hourlyFreq={hourlyFreq}
              remindList={freqDetails.reminder?.default_value}
              dayList={
                freqDetails?.hourly_details?.default_value?.selected_days
              }
              timeList={
                freqDetails?.hourly_details?.default_value?.selected_times
              }
              timeErrMessage={timeErrMessage}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
          {freq.code === "weekly" && (
            <FreqWeekly
              classes={classes}
              onWeeklyFreq={setWeeklyFreq}
              weeklyFreq={weeklyFreq}
              dailyList={freqDetails.daily_details.default_value}
              weeklyList={freqDetails.weekly_details.default_value}
              remindList={freqDetails.reminder.default_value}
              goalDetailState={goalDetailState}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
          {freq.code === "daily" && (
            <FreqDaily
              classes={classes}
              onDailyFreq={setDailyFreq}
              dailyFreq={dailyFreq}
              goalDetail={goalDetailState}
              dayList={freqDetails?.daily_details?.default_value}
              remindList={freqDetails.reminder?.default_value}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 15,
            marginTop:
              freq.code === "weekly" ? 100 : freq.code === "monthly" ? 100 : 50,
          }}
        >
          <Box>
            {listError.length > 0
              ? listError.map((item, i) => {
                  return (
                    <p key={i} style={{ color: "#cc0707", fontSize: 13 }}>
                      ⚠️ {item?.description}
                    </p>
                  );
                })
              : null}
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              disableElevation
              size="medium"
              className={classes.button0}
              onClick={() => {
                setOpenDialogUpdateFreq(false);
                setTimeErrMessage("");
              }}
            >
              Cancel
            </Button>
            <Box mx={1} />
            <Button
              variant="contained"
              size="medium"
              className={classes.button}
              disabled={loading}
              onClick={
                goalDetailState?.update_frequency === null
                  ? handleSaveFreq
                  : handleUpdateFreq
              }
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </DialogActions>

        <SnackbarError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation={textErrorMessage}
          errorStatus={errorStatus}
          listError={listError}
        />

        <DialogRemarksLog
          classes={classes}
          openRemarksLog={openRemarksLog}
          setOpenRemarksLog={setOpenRemarksLog}
          defaultRemarks=""
          dataRemarks={dataRemarks}
          onCloseRemarks={setOpenDialogUpdateFreq}
          onTriggerRemarks={context.setTriggerLog}
          editRemarks={false}
        />
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogUpdateFrequency;

const defaultFreqDetails = {
  auto_insert_result: {
    default_value: "1",
    label: "Auto Insert Result",
  },
  goal_ma_id: {
    label: "Goal/ MA Id",
    default_value: null,
  },
  category: {
    label: "Categori",
    default_value: [],
  },
  interval: {
    label: "Interval",
    default_value: [],
  },
  schedule_type: {
    label: "Schedule Type",
    default_value: [],
  },
  scheduled_at: {
    label: "Scheduled At",
    default_value: null,
  },
  input_start: {
    label: "Start",
    default_value: null,
  },
  input_finish: {
    label: "Finish",
    default_value: null,
  },
  hourly_details: {
    label: "Hourly Details",
    default_value: {
      break_times: [],
    },
  },
  daily_details: {
    label: "Daily Details",
    default_value: [],
  },
  weekly_details: {
    label: "Weekly Details",
    default_value: [],
  },
  monthly_details: {
    label: "Monthly Details",
    default_value: [],
  },
  reminder: {
    label: "Reminder",
    default_value: [],
  },
};
