/**
 * Utilities
 */

import React, { useEffect, useState } from "react";
import axios from "axios";

/**
 * Core
 */
import { CircularProgress, Grid } from "@material-ui/core";

/**
 * Form
 */
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { URL_API } from "../../../../constants/config-api";

/**
 * Dialog
 */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DeletePeriod = (props) => {
  /*
		```````````````````````````````````
		INIT

		```````````````````````````````````
    */
  const {
    classes,
    selectedPeriod,
    setSelectedDeletePeriod,
    openDeleteForm,
    setOpenDeleteForm,
    setListPeriode,
    listPeriode,
    openViewPeriodMoreMenu,
    setOpenViewPeriodMoreMenu,
  } = props;
  const userToken = localStorage.getItem("userToken");

  const [loader, setLoader] = useState(true);
  const [isDeletable, setIsDeletable] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // checking is the data can be deleted or not
    if (userToken !== undefined && selectedPeriod) {
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      // GET LIST LABEL
      axios
        .get(URL_API + `/swd/master-period/${selectedPeriod["id"]}/delete`)
        .then((response) => {
          setLoader(false);
          setIsDeletable(true);
          // console.log("Response Delete status of PERIOD   : ", response);
        })
        .catch((error) => {
          setLoader(false);
          setIsDeletable(false);

          if (error.response.status == 404) {
            removeItem(selectedPeriod);
            setErrorMessage("Data tidak tersedia atau sudah dihapus");
          } else if (error.response.status == 422) {
            setErrorMessage("Data tidak dapat dihapus");
          } else {
            setErrorMessage("Whoops something went wrong");
          }
          // console.log("Error : ", error.response)
        });
    }
  }, []);

  /**
   * Handling when click submit button.
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const handleSubmit = (evt) => {
    // deleted data
    if (userToken !== undefined && selectedPeriod) {
      let itemId = selectedPeriod["id"];
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + `/swd/master-period/${itemId}`, {
          _method: "delete",
        })
        .then((response) => {
          removeItem(selectedPeriod);
          setOpenDeleteForm(false);
          setSelectedDeletePeriod(null);
          // console.log("Response Delete status of PERIOD : ", response);
        })
        .catch((error) => {
          setOpenDeleteForm(false);
          setSelectedDeletePeriod(null);

          if (error.response.status == 404) {
            removeItem(selectedPeriod);
            setErrorMessage("Data tidak tersedia atau sudah dihapus");
          } else if (error.response.status == 422) {
            setErrorMessage("Data tidak dapat dihapus");
          } else {
            setErrorMessage("Whoops something went wrong");
          }

          // console.log("Error : ", error.response)
        });
    }
  };

  /**
   * Remove item from list
   *
   * @param {object} item object that want to remove from list.
   * @return {void}
   *
   */
  const removeItem = (src) => {
    if (listPeriode.length > 0) {
      setListPeriode(
        listPeriode.filter((item, i) => {
          return item["id"] !== src["id"];
        })
      );
    }
  };

  /**
   * Handling when closing form.
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const handleClose = (evt) => {
    setOpenDeleteForm(false);
    setOpenViewPeriodMoreMenu(false);
  };

  return (
    <div>
      <Dialog
        open={openDeleteForm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography variant="h6" className={classes.title}></Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center", minWidth: "400px" }}>
          {
            // open loader
            loader == true ? (
              <Grid container>
                <Grid item xs={5}></Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <CircularProgress />
                </Grid>
                <Grid item xs={5}></Grid>
              </Grid>
            ) : (
              <DialogContentText id="alert-dialog-description">
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ color: "black" }}
                >
                  {isDeletable == true ? (
                    <b>
                      Apakah Anda yakin ingin menghapus{" "}
                      <i>
                        {selectedPeriod !== null ? selectedPeriod.name : ""}
                      </i>{" "}
                      ?
                    </b>
                  ) : isDeletable == false ? (
                    <b>{errorMessage}</b>
                  ) : null}
                </Typography>
              </DialogContentText>
            )
          }
        </DialogContent>
        <DialogActions
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isDeletable !== null ? (
            isDeletable === true ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                className={classes.button}
              >
                Yakin
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                variant="contained"
                className={classes.button}
                style={{ backgroundColor: "gray" }}
              >
                Cancel
              </Button>
            )
          ) : null}
        </DialogActions>
        <br />
      </Dialog>
    </div>
  );
};

export default DeletePeriod;
