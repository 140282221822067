import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  List,
  ListItem,
  Badge,
  Switch,
} from "@material-ui/core";

import axios from "axios";
import { URL_API } from "../../../../constants/config-api";

const DialogTambahPosisiNEW = (props) => {
  const {
    classes,
    modalTambahPosition,
    setModalTambahPosition,
    // isChecked,
    // handleChangeCheckbox,
    handleSimpanData,

    //******* */
    handleClickCheckbox,
    // selected,
    isSelected,
  } = props;

  /*
        ``````````````````````````
        GET LIST AVAILABLE FROM DB

        ``````````````````````````
    */
  const [listPosition, setListPosition] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      if (modalTambahPosition == true) {
        axios
          .get(URL_API + `/human-resource/structure-position`)
          .then(function (response) {
            // console.log("Response Original : ", response);
            setListPosition(response.data.data);
          })
          .catch(function (error) {
            if (error.response.status == 422) {
              alert("Error : Unprocesity entity !");
            }

            // console.log("Error : ", error.response);
          });
      }
    } else {
      // console.log("No Access Token available!");
    }
  }, [modalTambahPosition]);

  /*
        ``````````````
        HANDLE SWITCH

        ``````````````
    */

  const [switchState, setSwitchState] = useState({
    checked: true,
  });

  const handleChangeSwitch = (name) => (event) => {
    setSwitchState({ ...switchState, [name]: event.target.checked });

    console.log(`Value checked ${event.target.checked}`);
  };

  return (
    <Dialog
      open={modalTambahPosition}
      onClose={() => setModalTambahPosition(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullWidth
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        {/* <Typography variant='subtitle1' className={classes.title}>
                    <b>Tambah Posisi</b>
                </Typography> */}

        <Grid container>
          <Grid item sm={6}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Tambah Posisi</b>
            </Typography>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "right" }}>
            <Tooltip
              title={
                switchState.checked !== true
                  ? "Lihat Posisi yang masih kosong"
                  : ""
              }
              placement="bottom-left"
            >
              <Switch
                // checked={isLockedStatusState == 0 ? true : false}
                checked={switchState.checked}
                className={classes.switch}
                onChange={handleChangeSwitch("checked")}
              />
            </Tooltip>
            {/* <b>Izinkan Modifikasi</b> */}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <List style={{ width: 300 }}>
          {
            // positions.length > 0 ? positions.map((item, i) => (
            listPosition.length > 0 ? (
              listPosition.map((item, i) => {
                // const isItemSelected = isSelected(item.id);
                const isItemSelected = isSelected(item);

                if (switchState.checked == true) {
                  if (item.member_id == null) {
                    return (
                      <ListItem
                        key={i}
                        // button
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        onClick={(e) => handleClickCheckbox(e, item)}
                      >
                        <Grid container>
                          <Grid item sm={10} style={{ textAlign: "left" }}>
                            <FormControlLabel
                              className={classes.checkboxStyle}
                              control={
                                // <Checkbox
                                //     checked={isChecked.id == item.id ? true : false}
                                //     onChange={(e) => handleChangeCheckbox(e, item)}
                                //     value={item}
                                // />
                                <Checkbox checked={isItemSelected} />
                              }
                              label={
                                <Tooltip
                                  title={
                                    item.member_id == null
                                      ? "Available Position"
                                      : "Posisi sudah di gunakan"
                                  }
                                  placement="right-start"
                                >
                                  <Badge color="primary" variant="dot">
                                    <Typography
                                      variant="subtitle1"
                                      className={classes.title}
                                    >
                                      {item.structure_position_title_name}
                                    </Typography>
                                  </Badge>
                                </Tooltip>
                              }
                            />
                          </Grid>

                          <Grid item sm={2}>
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                              style={{
                                color: "#2194e1",
                                marginTop: 8,
                                cursor: "pointer",
                              }}
                            >
                              Detail
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  }
                } else {
                  return (
                    <ListItem key={i}>
                      <Grid container>
                        <Grid item sm={10} style={{ textAlign: "left" }}>
                          <Typography
                            variant="subtitle1"
                            className={classes.title}
                          >
                            {item.structure_position_title_name}
                          </Typography>
                        </Grid>

                        <Grid item sm={2}>
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{
                              color: "#2194e1",
                              marginTop: 8,
                              cursor: "pointer",
                            }}
                          >
                            Detail
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                }
              })
            ) : (
              <Grid container>
                <Grid item sm={12} style={{ textAlign: "center" }}>
                  <CircularProgress size={32} style={{ color: "#cc0707" }} />
                </Grid>
              </Grid>
            )
          }
        </List>

        <DialogContentText id="alert-dialog-description"></DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ alignItems: "right", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => setModalTambahPosition(false)}
          variant="outlined"
          size="small"
          className={classes.buttonModalCancel}
        >
          Batal
        </Button>

        <Button
          // onClick={() => Redirect(ToCompletionProfile)}
          onClick={handleSimpanData}
          variant="contained"
          color="primary"
          size="small"
          className={classes.buttonModal}
        >
          Simpan
        </Button>
      </DialogActions>
      <br />
      <br />
    </Dialog>
  );
};

export default DialogTambahPosisiNEW;

// <ListItem key={i}>
//     <Grid container>
//         <Grid item sm={10} style={{textAlign: 'left'}}>
//             <FormControlLabel
//                 className={classes.checkboxStyle}
//                 control={
//                     <Checkbox
//                         checked={isChecked.id == item.id ? true : false}
//                         onChange={(e) => handleChangeCheckbox(e, item)}
//                         value={item}
//                     />
//                 }
//                 label = {

//                     <Tooltip title={item.member_id == null ? "Available Position" : 'Posisi sudah di gunakan' } placement="right-start">
//                         <Badge

//                             color="primary"
//                             variant='dot'

//                         >
//                             <Typography variant='subtitle1' className={classes.title}>
//                                 { item.structure_position_title_name }

//                             </Typography>
//                         </Badge>
//                     </Tooltip>
//                 }
//             />
//         </Grid>

//         <Grid item sm={2} >
//             <Typography variant='subtitle2' className={classes.title} style={{color: '#2194e1', marginTop: 8, cursor: 'pointer'}}>
//                 Detail
//             </Typography>
//         </Grid>
//     </Grid>
// </ListItem>
