/**
 * Utilities
 */
import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import axios from "axios";
import { URL_API } from "../../../../constants/config-api";
import { getDateFormat } from "../Utilities/PeriodUtil";
/**
 * Core
 */
import { Typography, Grid, CircularProgress } from "@material-ui/core";

/**
 * Form
 */
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

/**
 * Dialog
 */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

/**
 * DateRange
 */
//import { DateRangePicker, DateRange } from "@matharumanpreet00/react-daterange-picker";
//import { Calendar } from 'react-date-range';

// https://github.com/Adphorus/react-date-range
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

/**
 * Custom component
 */
import ErrorIconDialog from "../../../../assets/images/icon-info-24px.svg";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";

const UpdatePeriod = (props) => {
  /*
		```````````````````````````````````
		INIT

		```````````````````````````````````
    */
  const {
    classes,
    item,
    setItem,
    open,
    setOpen,
    openViewPeriodMoreMenu,
    setOpenViewPeriodMoreMenu,
  } = props;

  let { list, setList } = props;

  const userToken = localStorage.getItem("userToken");
  const [masterIntervalCollections, setMasterIntervalCollections] = useState(
    []
  );
  const [loader, setLoader] = useState(true);
  const [componentKey, setComponentKey] = useState(0);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    setOpenViewPeriodMoreMenu(false);
    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      // GET LIST LABEL
      axios
        .get(URL_API + `/swd/master-period/${item["id"]}/update`)
        .then((response) => {
          let responseData = response.data.data;
          setInputName(responseData.fields.name.value);
          setInputIntervalId(responseData.fields.interval_id.value);

          setInputStartDate(responseData.fields.start_date.value);
          setInputEndDate(responseData.fields.end_date.value);

          setFakeInputStartDate(responseData.fields.start_date.value);
          setFakeInputEndDate(responseData.fields.end_date.value);

          setInputName(responseData.fields.name.value);
          setInputUpdatedAt(responseData.updated_at);

          // set date range
          setStartDateInDateRange(
            new Date(responseData.fields.start_date.value)
          );
          setEndDateInDateRange(new Date(responseData.fields.end_date.value));

          setLoader(false);
          setMasterIntervalCollections(responseData.masterIntervalCollections);
          // console.log("Response Original GET LIST LABEL   : ", responseData);
        })
        .catch((error) => {
          setLoader(false);
          // console.log("Error : ", error);
          if (error.response !== undefined) {
            let resData = error.response.data;

            // when bad request
            // if (resData.info.status == 400) {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Validation Error");
            //   setErrorContent(resData.info.errors);
            //   console.log(resData.info.errors);
            // } else if (resData.info.status == 401) {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Unauthorized Action");
            //   setErrorContent({ description: "This action is unauthorized" });
            // } else if (resData.info.status == 404) {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Page Not Found");
            //   setErrorContent({
            //     description: "Data not found or has been deleted",
            //   });
            // } else {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Internal Server Error");
            //   setErrorContent({ description: "whoops, something went wrong!" });
            // }

            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);
          } else {
            // setErrorStatus(-1);
            // setOpenErrorDialog(true);
            // setErrorTitle("Cannot connect to server");
            // setErrorContent({
            //   description:
            //     "Sorry, we cannot reach the server. Please check your connection",
            // });
          }
        });
    }
  }, [componentKey]);

  /*
		```````````````````````````````````
		HANDLE FORM 

		```````````````````````````````````
    */

  /**
   * Handling for input name
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const [inputName, setInputName] = useState("");
  const formInputName = (evt) => {
    setInputName(evt.target.value);
  };

  /**
   * Handling for input interval_id
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const [inputIntervalId, setInputIntervalId] = useState("");

  /**
   * Handling for input start_date
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const [inputStartDate, setInputStartDate] = useState(new Date());
  const [fakeInputStartDate, setFakeInputStartDate] = useState(new Date());

  /**
   * Handling for input end_date
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const [inputEndDate, setInputEndDate] = useState(new Date());
  const [fakeInputEndDate, setFakeInputEndDate] = useState(new Date());

  /**
   * Handling for input updated_at
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const [inputUpdatedAt, setInputUpdatedAt] = useState(null);

  /**
   * Handling when click submit button
   *
   * @param {object} evt event object
   * @return {void}
   *
   */
  const handleSubmit = (evt) => {
    // formatting
    let bodyInputName = inputName;
    let bodyIntervalId = inputIntervalId;
    let bodyStartDate = inputStartDate;
    let bodyEndDate = inputEndDate;
    let bodyUpdatedAt = inputUpdatedAt;

    // ASSIGN TO BODY
    let body = {
      MasterPeriod: {
        name: bodyInputName,
        interval_id: bodyIntervalId,
        start_date: bodyStartDate,
        end_date: bodyEndDate,
        updated_at: bodyUpdatedAt,
      },
      _method: "put",
    };
    let isValid = true;

    if (isValid && userToken) {
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      // GET LIST PERIODE
      axios
        .post(URL_API + `/swd/master-period/${item["id"]}`, body)
        .then((response) => {
          // renew listPeriode
          if (list.length > 0) {
            let copy = [];
            for (let i in list) {
              if (list[i]["id"] == item["id"]) {
                copy.push(response.data.data);
              } else {
                copy.push(list[i]);
              }
            }
            list = copy;
          }
          setList(list);
          setOpen(false);
          setItem(null);
          // console.log("Response Original UPDATE PERIODE   : ", response);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          if (error.response !== undefined) {
            let resData = error.response.data;

            // when bad request
            // if (resData.info.status == 400) {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Validation Error");
            //   setErrorContent(resData.info.errors);
            //   console.log(resData.info.errors);
            // } else if (resData.info.status == 403) {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Unauthorized Action");
            //   setErrorContent({ description: "This action is unauthorized" });
            // } else if (resData.info.status == 404) {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Page Not Found");
            //   setErrorContent({
            //     description: "Data not found or has been deleted",
            //   });
            // } else if (resData.info.status == 409) {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Resource state has been change");
            //   setErrorContent({
            //     description: "Resource state has been change",
            //   });
            // } else {
            //   setErrorStatus(resData.info.status);
            //   setOpenErrorDialog(true);
            //   setErrorTitle("Internal Server Error");
            //   setErrorContent({ description: "whoops, something went wrong!" });
            // }

            const { listError, dialogErr, resStatus, errorMessage } =
              handleError(error);
            // Error State
            setOpenDialogError(dialogErr);
            setTextErrorMessage(errorMessage);
            setListError(listError);
            setErrorStatus(resStatus);
          } else {
            // setErrorStatus(-1);
            // setOpenErrorDialog(true);
            // setErrorTitle("Cannot connect to server");
            // setErrorContent({
            //   description:
            //     "Sorry, we cannot reach the server. Please check your connection",
            // });
          }
        });
    } else {
      alert("whoops something went wrong!");
    }
  };

  /**
   * Handling when closing create form
   *
   * @param {object} evt event object
   * @return {void}
   *
   */
  const handleClose = (evt) => {
    setOpen(false);
  };

  /*
		```````````````````````````````````
		HANDLE DATE RANGE 

		```````````````````````````````````
    */
  const [openDateRange, setOpenDateRange] = useState(false);
  const [startDateInDateRange, setStartDateInDateRange] = useState(new Date());
  const [endDateInDateRange, setEndDateInDateRange] = useState(new Date());

  /**
   * Handling click open date range picker
   *
   * @param {object} evt event object
   * @return {void}
   *
   */
  const handleOpenDateRange = (evt) => {
    setOpenDateRange(true);
  };

  /**
   * Handling close date range picker
   *
   * @param {object} evt event object
   * @return {void}
   *
   */
  const handleCloseDateRange = (evt) => {
    setOpenDateRange(false);
  };

  /**
   * Handling select calendar in date range picker
   *
   * @param {object} evt event object
   * @return {void}
   *
   */
  const handleSelectDateRange = (evt) => {
    setStartDateInDateRange(evt.selection.startDate);
    setEndDateInDateRange(evt.selection.endDate);

    // console.log("EVT", evt);

    // update its interval
    let newInterval = getIntervalFromStartDateAndEndDate(
      evt.selection.startDate,
      evt.selection.endDate
    );
    if (newInterval) {
      // set selected interval id
      setInputIntervalId(newInterval["id"]);
    }
  };

  /**
   * Handling click date range picker.
   *
   * @param {object} evt event object.
   * @return {void}
   *
   */
  const handleClickDateRange = () => {
    setInputStartDate(moment(startDateInDateRange).format("YYYY-MM-DD"));
    setInputEndDate(moment(endDateInDateRange).format("YYYY-MM-DD"));

    setFakeInputStartDate(moment(startDateInDateRange).format("DD MMMM YYYY"));
    setFakeInputEndDate(moment(endDateInDateRange).format("DD MMMM YYYY"));

    setOpenDateRange(false);
  };

  /**
   * Handling click interval id button to update end date.
   *
   * @param {object} evt event object.
   * @param {object} interval consist of interval rule.
   * @return {void}.
   *
   */
  const handleClickIntervalId = (evt, interval) => {
    // prevent
    evt.preventDefault();
    // change start date and end date
    let newEndDate = getIntervalEndDate(
      startDateInDateRange.format("YYYY-MM-DD"),
      interval
    );
    // set input interval id
    setInputIntervalId(interval["id"]);
    // set form input end date
    setInputEndDate(startDateInDateRange);
    // set selected end date in date range
    setEndDateInDateRange(newEndDate);
  };

  /**
   * Getting end date from start date based on interval.
   *
   * @param {string} startDate start date.
   * @param {object} interval consist of interval rule.
   * @return {string} outp end date.
   *
   */
  const getIntervalEndDate = (startDate, interval) => {
    // define default output
    let outp = moment(startDate).format("YYYY-MM-DD");

    // define default end date
    let momentEndDate = moment(startDate);
    // when interval is defined and not null
    if (interval && interval.unit !== null && interval.value !== null) {
      if (interval.unit.toLowerCase() == "year") {
        // when interval unit rule is year
        outp = momentEndDate
          .add(interval.value, "year")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (interval.unit.toLowerCase() == "month") {
        // when interval unit rule is month
        outp = momentEndDate
          .add(interval.value, "month")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else {
        // set default value when there is no rule in interval
        outp = momentEndDate.format("YYYY-MM-DD");
      }
    } else {
      // set default value when interval is undefined or null
      outp = momentEndDate.format("YYYY-MM-DD");
    }

    return outp;
  };

  /**
   * Getting interval from given start date and and end date.
   *
   * @param {string} srcStartDate string value of start date.
   * @param {string} srcEndDate string value of end date.
   * @return {object|null} outp contains interval object.
   *
   */
  const getIntervalFromStartDateAndEndDate = (srcStartDate, srcEndDate) => {
    // define default output
    let outp = null;
    // define moment from given startDate and endDate
    let momentSrcStartDate = moment(srcStartDate);
    let momentSrcEndDate = moment(srcEndDate);

    // finding the item
    if (masterIntervalCollections.length > 0) {
      for (let i in masterIntervalCollections) {
        let newEndDate = moment(
          getIntervalEndDate(
            momentSrcStartDate.format("YYYY-MM-DD"),
            masterIntervalCollections[i]
          )
        );
        if (momentSrcEndDate.diff(newEndDate, "days") == 0) {
          outp = masterIntervalCollections[i];
          break;
        } else if (masterIntervalCollections[i].code == "custom") {
          // when there is no interval matching then it must be a custom
          outp = masterIntervalCollections[i];
        }
      }
    }

    return outp;
  };

  /**
   * Handling error dialog
   *
   */
  const [errorStatus, setErrorStatus] = useState(0);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorContent, setErrorContent] = useState({});
  const handleCloseErrorDialog = (evt) => {
    // when status equals to conflict
    if (errorStatus == 409) {
      setComponentKey(Math.floor(Math.random() * 1000000) + 1);
    }
    setErrorStatus(0);
    setOpenErrorDialog(false);
    setErrorTitle("");
    setErrorContent({});
  };

  return (
    <Fragment key={componentKey}>
      {
        // <!-- Error Dialog -->
        errorStatus !== 0 && (
          <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
            <DialogTitle style={{ textAlign: "center" }}>
              <img
                src={ErrorIconDialog}
                style={{ height: "96px" }}
                alt="Pict Info "
              />
              <br />
              <br />
              <Typography className={classes.title} style={{ color: "black" }}>
                <b>{errorTitle}</b>
              </Typography>

              {errorContent !== null &&
                Object.keys(errorContent).length > 0 &&
                Object.keys(errorContent).map((row, i) => (
                  <div key={"error-lists-" + row} className={classes.title}>
                    {errorContent[row]}
                  </div>
                ))}
            </DialogTitle>

            <DialogContent style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleCloseErrorDialog}
                    size="medium"
                    className={classes.button}
                  >
                    {errorStatus == 409 ? (
                      <div>Refresh Data</div>
                    ) : (
                      <div>Saya Mengerti</div>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>

            <br />
            <br />
          </Dialog>
        )
        // <!-- /Error Dialog -->
      }

      <Dialog
        className={`${classes.dialog}`}
        open={open}
        onClose={handleClose}
        style={{ zIndex: 100 }}
      >
        <DialogTitle
          className={`${classes.title}`}
          style={{ textAlign: "center" }}
        >
          Update Periodezzz
        </DialogTitle>
        <DialogContent style={{ minWidth: "500px" }}>
          {loader == true ? (
            <Grid container>
              <Grid item xs={5}></Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          ) : (
            <Fragment>
              <Grid container>
                <Typography className={`${classes.title}`}>Title</Typography>
                <TextField
                  margin="normal"
                  variant="outlined"
                  value={inputName}
                  onChange={formInputName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>

              <Grid container>
                <Grid item xs={5}>
                  <Typography className={`${classes.title}`}>Mulai</Typography>
                  <TextField
                    style={{ borderRight: "" }}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={fakeInputStartDate}
                    onClick={handleOpenDateRange}
                    fullWidth
                  />
                  <input type="hidden" value={inputStartDate} />
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <br />
                  &nbsp;-&nbsp;
                </Grid>
                <Grid item xs={5}>
                  <Typography className={`${classes.title}`}>
                    Berakhir
                  </Typography>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={fakeInputEndDate}
                    onClick={handleOpenDateRange}
                    fullWidth
                  />
                  <input type="hidden" value={inputEndDate} />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Button
                    className={`${classes.button} ${classes.title}`}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Ubah Periode
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </DialogContent>

        {
          // <!-- date range -->
        }
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={openDateRange}
          onClose={handleCloseDateRange}
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDateRange}
          >
            <Typography>Pilih Mulai dan Berakhir</Typography>
          </DialogTitle>

          <DialogContent style={{ minWidth: "500px" }}>
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    marginLeft: "-250px",
                    transform: "scale(0.80, 0.80)",
                    paddingTop: 0,
                    marginTop: 0,
                  }}
                >
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: startDateInDateRange,
                        endDate: endDateInDateRange,
                        key: "selection",
                      },
                    ]}
                    rangedCalendars={true}
                    onInit={handleSelectDateRange}
                    onChange={handleSelectDateRange}
                    months={2}
                    direction={"horizontal"}
                    staticRanges={[]}
                    inputRanges={[]}
                  />
                </div>
              </Grid>
            </Grid>

            {
              // <!-- interval selector -->
            }
            <Grid container>
              <Grid item xs={12}>
                {masterIntervalCollections.length > 0 &&
                  masterIntervalCollections.map((item, i) => (
                    <Fragment key={item["id"]}>
                      <Button
                        // itemId={item["id"]}
                        size="small"
                        variant="contained"
                        color="secondary"
                        className={classes.title}
                        onClick={(e) => handleClickIntervalId(e, item)}
                        style={
                          item["id"] == inputIntervalId
                            ? {
                                textTransform: "capitalize",
                                color: "white",
                              }
                            : {
                                textTransform: "capitalize",
                                backgroundColor: "rgba(0,0,0,0.1)",
                                color: "gray",
                              }
                        }
                      >
                        {item.name}
                      </Button>
                      &nbsp;
                    </Fragment>
                  ))}
              </Grid>
            </Grid>
            {
              // <!-- /interval selector -->
            }

            {
              // <!-- span -->
            }
            <Grid container>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>
            {
              // <!-- /span -->
            }

            <Grid container>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  onClick={handleClickDateRange}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={2}>
                &nbsp;
              </Grid>
              <Grid item xs={5}>
                <Button
                  className={`${classes.title} ${classes.button}`}
                  variant="contained"
                  color="secondary"
                  onClick={handleClickDateRange}
                  fullWidth
                >
                  Pilih Daterange
                </Button>
              </Grid>
            </Grid>

            {
              // <!-- /span --?
            }
            <Grid container>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
            </Grid>
            {
              // <!-- /span -->
            }
          </DialogContent>
        </Dialog>
        {
          // <!-- /date range -->
        }
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default UpdatePeriod;
