import React, { useEffect, useState, forwardRef } from "react";
import {
  Dialog,
  Box,
  Typography,
  Button,
  Grid,
  Slide,
  AppBar,
  Container,
  CircularProgress,
  DialogContent,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { navigate } from "hookrouter";
import moment from "moment";

import { ToCompletionProfile } from "../../../../constants/config-redirect-url";
import CardChoosenPackage from "./CardChoosenPackage";
import { FormatThousand } from "../../../../utilities/FormatDecimal";

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogPaidPayment = ({
  openDialogPaidPayment,
  setOpenDialogPaidPayment,
  setOpenDialogPayment,
  setOpenDialogCheckoutReg,
  setOpenDialogPackage,
  dataPay,
  classes,
  paket,
  membership,
  duration,
  totalUser,
  totalDeployment,
  totalPmo,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (openDialogPaidPayment) {
      setData(dataPay);
    }
  }, [openDialogPaidPayment]);

  const handleToCompleteProfile = () => {
    setLoading(true);

    setTimeout(() => {
      navigate(ToCompletionProfile);
      setLoading(false);
      setOpenDialogPaidPayment(false);
      setOpenDialogPayment(false);
      setOpenDialogCheckoutReg(false);
      setOpenDialogPackage(false);
    }, 1500);
  };

  return (
    <Dialog
      fullScreen
      open={openDialogPaidPayment}
      TransitionComponent={Transition}>
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}
      />

      <Container maxWidth="md" style={{ marginTop: 50 }}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircleIcon
              style={{ fontSize: 36, color: "#10de77", marginBottom: 12 }}
            />
            <Typography variant="h6" className={classes.title}>
              <b>Transaksi anda berhasil</b>
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{
                color: "#888",
                width: 550,
                marginTop: 5,
                textAlign: "center",
              }}>
              Pembayaran anda telah kami terima, paket membership telah aktif
              dan dapat segera anda gunakan setelah registrasi selesai
            </Typography>
          </Box>
          <Box
            marginBottom={6}
            marginTop={5}
            display="flex"
            justifyContent="center">
            <Box width={600}>
              <CardChoosenPackage
                classes={classes}
                paket={paket}
                membership={membership}
                duration={duration}
                totalUser={totalUser}
                totalDeployment={totalDeployment}
                totalPmo={totalPmo}
              />
            </Box>
          </Box>

          <Grid container>
            <Grid item md={6} style={{ textAlign: "right", paddingRight: 15 }}>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                Meetode Pembayaran
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                Jumlah Transfer
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                Tanggal Pembayaran
              </Typography>
            </Grid>
            <Grid item md={6} style={{ textAlign: "left", paddingLeft: 7.5 }}>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                : {data.payment_method_name} Virtual Account
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                : Rp {FormatThousand(data.total_price)}
              </Typography>
              <Typography variant="subtitle2" className={classes.txtSummary2}>
                : {moment(data.order_date).format("DD/MM/YYY hh:mm")}
              </Typography>
            </Grid>
          </Grid>

          <Box marginTop={5} display="flex" justifyContent="center">
            <Button
              variant="contained"
              className={classes.button1}
              onClick={handleToCompleteProfile}
              style={{ width: 200 }}>
              {loading ? (
                <CircularProgress color="#fff" size={15} />
              ) : (
                "Lanjutkan Registrasi"
              )}
            </Button>
          </Box>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default DialogPaidPayment;
