import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleAttendanceAccount";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Grid,
  Typography,
  Divider,
  LinearProgress,
  withStyles,
  Card,
  CardContent,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";

import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";

import ContextAttendanceAccount from "./Context/ContextAttendanceAccount";

import { isAuthenticated } from "../MyAttendance/Hook/isAuthenticated";
import ProfileCard from "./Components/ProfileCard";
import ChangePinDialog from "./Components/ChangePinDialog";
import ErrorDialog from "../MyAttendance/Components/ErrorDialog";
import SuccessDialog from "./Components/SuccessDialog";
import DialogProgress from "./Components/DialogProgress";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#282560",
      light: "#2F3B6B",
      dark: "#0f0f0f",
      contrastText: "#ffffff",
      text: "#333333",
    },
    secondary: {
      main: "#d64253",
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
      text: "#B1B1B1",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ViewAttendanceAccount = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceAccount);
  const [loader, setLoader] = useState(false);

  const authData = JSON.parse(localStorage.getItem("status_user_login"));
  const [presenceData, setPresenceData] = useState({});
  const [absenceData, setAbsenceData] = useState({});

  const [pinDialog, setPinDialog] = useState(false);
  const [pinValue, setPinValue] = useState({
    oldPin: "",
    newPin: "",
    confirmPin: "",
  });

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [successStatus, setSuccessStatus] = useState("");

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
  };

  const handlePinDialogClose = () => {
    setPinDialog(false);
    setPinValue({
      oldPin: "",
      newPin: "",
      confirmPin: "",
    });
  };

  const handlePinDialogOpen = () => {
    setPinDialog(true);
  };

  const verificationPin = async () => {
    setLoader(true);
    if (
      pinValue.newPin === "" ||
      pinValue.confirmPin === ""
    ) {
      setErrorDialog(true);
      setErrorMessage("Please fill all fields");
      setErrorStatus("error");
      setLoader(false);
    } else if (pinValue.newPin !== pinValue.confirmPin) {
      setErrorDialog(true);
      setErrorMessage("New pin and confirm pin must be same");
      setErrorStatus("error");
      setLoader(false);
    } else {
      try {
        const data = {
          User: {
            last_pin: pinValue.oldPin,
            new_pin: pinValue.newPin,
          },
        };
        const response = await axiosConfig.post(
          `${URL_API}/account-management/user/pin/update`,
          data
        );

        handlePinDialogClose();
        setSuccessStatus("Success change pin");
        setSuccessDialog(true);
      } catch (error) {
        setErrorDialog(true);
        setErrorMessage(error.response.data.info.message);
      } finally {
        setLoader(false);
      }
    }
  };

  const PresenceLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#9B1B2D",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#EC5267",
    },
  }))(LinearProgress);

  const AbsenceLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#221E5B",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#A19DE5",
    },
  }))(LinearProgress);

  const fetchPresence = async () => {
    try {
      setLoader(true);
      const res = await axiosConfig.get(
        `${URL_API}/human-resource/report-account/presence`
      );
      if (res.status === 200) {
        setPresenceData(res.data.data);
      }
    } catch (error) {
      setErrorDialog(true);
      setErrorMessage(error.response.data.info.message);
    } finally {
      setLoader(false);
    }
  };

  const fetchAbsence = async () => {
    try {
      setLoader(true);
      const res = await axiosConfig.get(
        `${URL_API}/human-resource/report-account/absence`
      );
      if (res.status === 200) {
        setAbsenceData(res.data.data);
      }
    } catch (error) {
      setErrorDialog(true);
      setErrorMessage(error.response.data.info.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      fetchPresence();
      fetchAbsence();
    } /* eslint-disable-line */,
    []
  );

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <ProfileCard
          data={authData}
          handlePinDialogOpen={handlePinDialogOpen}
        />

        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Presence
              </Typography>
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background:
                    "linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)",
                  borderRadius: 11,
                  padding: "0px 20px",
                  marginTop: "1rem",
                }}
              >
                <CardContent style={{ flex: "1 0 auto" }}>
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    <DateRange />
                    <Typography variant="h6">
                      <b>Kehadiran anda bulan ini</b>
                    </Typography>
                  </div>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <img
                        src={require("../../../assets/images/note.png")}
                        alt="attendance"
                        width={120}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: 20, marginBottom: 5 }}
                      >
                        <Grid item xs={9}>
                          <Typography variant="h6" style={{ color: "white" }}>
                            {presenceData.kehadiran ? (
                              <>
                                <b>{presenceData.kehadiran}</b>/
                                {moment().daysInMonth()} Kehadiran
                              </>
                            ) : (
                              <>-</>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="h6"
                            style={{ color: "white", textAlign: "right" }}
                          >
                            <b>
                              {presenceData.kehadiran ? (
                                <b>
                                  {Math.floor(
                                    (presenceData.kehadiran /
                                      moment().daysInMonth()) *
                                      100
                                  )}
                                  %
                                </b>
                              ) : (
                                <>-</>
                              )}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                      <PresenceLinearProgress
                        variant="determinate"
                        value={
                          presenceData.kehadiran
                            ? Math.floor(
                                (presenceData.kehadiran /
                                  moment().daysInMonth()) *
                                  100
                              )
                            : 0
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                Presence Detail
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {presenceData.list?.map((item, index) => (
                  <Card
                    key={index}
                    style={{
                      padding: "10px",
                      color: "#221E5B",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        <DateRange />
                        <Typography variant="h6">
                          <b>{item.tanggal}</b>
                        </Typography>
                      </div>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#656D78" }}
                      >
                        Pukul : {item.pukul}
                      </Typography>
                      <Typography variant="subtitle2">
                        <b>Lokasi : {item.lokasi}</b>
                      </Typography>
                      <Typography variant="subtitle2">
                        <b>Status : Hadir</b>
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Absence
              </Typography>

              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background:
                    "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
                  borderRadius: 11,
                  padding: "0px 20px",
                  marginTop: "1rem",
                }}
              >
                <CardContent style={{ flex: "1 0 auto" }}>
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    <DateRange />
                    <Typography variant="h6">
                      <b>Cuti anda bulan ini</b>
                    </Typography>
                  </div>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <img
                        src={require("../../../assets/images/calender.png")}
                        alt="attendance"
                        width={120}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid
                        container
                        spacing={1}
                        style={{ marginTop: 20, marginBottom: 5 }}
                      >
                        <Grid item xs={9}>
                          <Typography variant="h6" style={{ color: "white" }}>
                            {absenceData.allowance ? (
                              <>
                                <b>
                                  {absenceData.allowance -
                                    absenceData.remaining}
                                </b>
                                /{absenceData.allowance} Jatah Cuti
                              </>
                            ) : (
                              <>-</>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="h6"
                            style={{ color: "white", textAlign: "right" }}
                          >
                            {absenceData.allowance ? (
                              <b>
                                {Math.floor(
                                  ((absenceData.allowance -
                                    absenceData.remaining) /
                                    absenceData.allowance) *
                                    100
                                )}
                                %
                              </b>
                            ) : (
                              <>-</>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <AbsenceLinearProgress
                        variant="determinate"
                        value={
                          absenceData.allowance
                            ? Math.floor(
                                ((absenceData.allowance -
                                  absenceData.remaining) /
                                  absenceData.allowance) *
                                  100
                              )
                            : 0
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                Absence Detail
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {absenceData.list?.map((item, index) => (
                  <Card
                    key={index}
                    style={{
                      padding: "10px",
                      color: "#221E5B",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "10px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        <DateRange />
                        <Typography variant="h6">
                          <b>{item.tanggal}</b>
                        </Typography>
                      </div>
                      <Typography
                        variant="subtitle1"
                        style={{ color: "#656D78" }}
                      >
                        Pukul : {item.pukul}
                      </Typography>
                      <Typography variant="subtitle2">
                        <b>Status : {item.status}</b>
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>

        <ChangePinDialog
          open={pinDialog}
          handleClose={handlePinDialogClose}
          verification={verificationPin}
          pinValue={pinValue}
          setPinValue={setPinValue}
        />

        <ErrorDialog
          open={errorDialog}
          handleClose={handleErrorDialogClose}
          status={errorStatus}
          message={errorMessage}
        />

        <SuccessDialog
          open={successDialog}
          handleClose={handleSuccessDialogClose}
          status={successStatus}
        />

        <DialogProgress
          classes={classes}
          isModalProgress={loader}
          setModalProgress={setLoader}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewAttendanceAccount;
