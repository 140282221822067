import React, { useContext, useMemo, useState } from "react";
import {
  Table as Tables,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircleOutlined";

import { useTable, useExpanded } from "react-table";
import { navigate, A } from "hookrouter";

import {
  ASSIGNEE_CONTENT_FILTER,
  FILTER_TASK,
  PROJECT_TEAM_DETAILS,
  SORT_TASK,
} from "../../context/types";
import ContextProject from "../../context/ContextProject";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import FormatTitleCase from "../../../../utilities/FormatTitleCase";
import ProgressBar from "../global/ProgressBar";
import AnchorProject from "./AnchorProject";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import PaginateProject from "./PaginateProject";
import ProjectBar from "../global/ProjectBar";

import ApprovedLogo from "../../../../assets/images/Approved.png";
import ActiveLogo from "../../../../assets/images/Active.png";
import CompletedLogo from "../../../../assets/images/Completed.png";
import OverdueLogo from "../../../../assets/images/Overdue.png";
import AchievementBar from "../global/AchievementBar";

const TableTodos = ({ classes }) => {
  const { projectTState, projectTDispatch } = useContext(ContextProject);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);
  const member_position = user?.member_position;
  const userRole = user?.userRole[0]?.name;
  const its_me = `${user?.member_first_name} ${user?.member_last_name}`;

  const NameCell = ({ value }) => {
    let INTERVAL_COLOR;
    let INTERVAL_BG;

    if (value?.update_frequency?.interval === "hourly") {
      INTERVAL_BG = "#D7E4F7";
      INTERVAL_COLOR = "#576FC2";
    } else if (value?.update_frequency?.interval === "daily") {
      INTERVAL_BG = "#D7F3F4";
      INTERVAL_COLOR = "#009345";
    } else if (value?.update_frequency?.interval === "weekly") {
      INTERVAL_BG = "#FBE0B3";
      INTERVAL_COLOR = "#FAA922";
    } else if (value?.update_frequency?.interval === "monthly") {
      INTERVAL_BG = "#FDDBD8";
      INTERVAL_COLOR = "#C8203C";
    }

    const handleDetail = () => {
      const taskgroup_count = value?.project?.taskgroup_count;

      navigate(`/project/${value.id}?kanban=list`, false, { task: "list" });
      localStorage.setItem("project_details", JSON.stringify(value));
      projectTDispatch({
        type: PROJECT_TEAM_DETAILS,
        payload: { projectDetails: value },
      });

      let OWNERID = null;
      let MYSELF = null;
      const owner_id = value?.owner_id;

      if (owner_id === member_position?.id || userRole === "superadmin") {
        OWNERID = null;
        MYSELF = null;
      } else {
        OWNERID = `options[filter][fields][owner_id][0]=${user?.user_id}`;
        MYSELF = [its_me];
      }

      projectTDispatch({
        type: FILTER_TASK,
        payload: {
          periods: null,
          filterAssignee: OWNERID,
          filterAchievement: null,
          filterStatus: null,
          filterPriority: null,
          filterComplete: null,
          filterGroup: null,
        },
      });

      projectTDispatch({
        type: ASSIGNEE_CONTENT_FILTER,
        payload: {
          assigneeFilterContent: MYSELF,
        },
      });

      if (taskgroup_count > 0) {
        const sortTitle = `options[sort][0][field]=title&options[sort][0][method]=asc`;

        projectTDispatch({
          type: SORT_TASK,
          payload: {
            sortByDate: null,
            sortTitleTask: sortTitle,
          },
        });
      }
    };

    const handleRightClick = (data) => {
      // // console.log("Data goal", data);
      const taskgroup_count = data?.project?.taskgroup_count;

      localStorage.setItem("project_details", JSON.stringify(data));
      projectTDispatch({
        type: PROJECT_TEAM_DETAILS,
        payload: { projectDetails: data },
      });

      let OWNERID = null;
      let MYSELF = null;
      const owner_id = data?.owner_id;

      if (owner_id === member_position?.id || userRole === "superadmin") {
        OWNERID = null;
        MYSELF = null;
      } else {
        OWNERID = `options[filter][fields][owner_id][0]=${user?.user_id}`;
        MYSELF = [its_me];
      }

      projectTDispatch({
        type: FILTER_TASK,
        payload: {
          periods: null,
          filterAssignee: OWNERID,
          filterAchievement: null,
          filterStatus: null,
          filterPriority: null,
          filterComplete: null,
          filterGroup: null,
        },
      });

      projectTDispatch({
        type: ASSIGNEE_CONTENT_FILTER,
        payload: {
          assigneeFilterContent: MYSELF,
        },
      });

      if (taskgroup_count > 0) {
        const sortTitle = `options[sort][0][field]=title&options[sort][0][method]=asc`;

        projectTDispatch({
          type: SORT_TASK,
          payload: {
            sortByDate: null,
            sortTitleTask: sortTitle,
          },
        });
      }
    };

    return (
      <Tooltip title={value.description}>
        <Box display="flex" alignContent="center" justifyContent="space-between">
        <Typography
          variant="subtitle1"
          className={classes.projectName}
          onClick={handleDetail}
          onContextMenu={() => handleRightClick(value)}
        >
          {/* {FormatTitleCase(value.name)} */}
          <A
            href={`/project/${value.id}`}
            className={classes.toDetails}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            {value.name}
          </A>
        </Typography>

        <span
          style={{
            color: INTERVAL_COLOR,
            backgroundColor: INTERVAL_BG,
            borderRadius: 5,
            padding: "2px 10px",
            marginLeft: 10,
            fontSize: 13,
          }}
        >
          {value?.update_frequency?.interval}
        </span>
      </Box>
      </Tooltip>
    );
  };

  const OwnerCell = ({ value }) => {
    // // console.log("owner", value);

    return (
      <Tooltip title={value.full_name}>
        {value.photo_url === null ? (
          <Box className={classes.justHover} display="flex" alignItems="center">
            <AccountCircleIcon
              className={classes.justHover}
              style={{ color: "#888" }}
            />
            <span style={{ color: "#555", fontSize: 14, marginLeft: 5 }}>
              {TruncateTextCustom(value.full_name, 5)}
            </span>
          </Box>
        ) : (
          <Box className={classes.justHover} display="flex" alignItems="center">
            <ImageSmallUser item={value.photo_url} index={1} />
            <span style={{ color: "#555", fontSize: 14, marginLeft: 5 }}>
              {TruncateTextCustom(value.full_name, 5)}
            </span>
          </Box>
        )}
      </Tooltip>
    );
  };

  const StatusCell = ({ value }) => {
    let imgLogo;

    switch (value.id) {
      case "2":
        imgLogo = ApprovedLogo;
        break;
      case "3":
        imgLogo = ActiveLogo;
        break;
      case "6":
        imgLogo = OverdueLogo;
        break;
      case "4":
        imgLogo = CompletedLogo;
        break;
      default:
        imgLogo = "";
    }

    return (
      <Box display="flex" alignItems="center">
        <img src={imgLogo} height={20} />
        <Box mx={0.5} />
        <Typography
          variant="subtitle2"
          className={classes.title555}
          style={{ textTransform: "capitalize" }}
        >
          {value.name}
        </Typography>
      </Box>
    );
  };

  const ProgressCell = ({ value }) => {
    return (
      <Box width={100}>
        <ProgressBar progress={value} />
      </Box>
    );
  };

  const AchievementCell = ({ value }) => {
    // ================================================================
    // Conditional SCOPE
    // ================================================================
    let ACHIEVEMENT;

    switch (value?.achievement_calculation_method) {
      case "SCOPE":
        ACHIEVEMENT = Number(value?.project_scope_results);
        break;
      case "SCHEDULE":
        ACHIEVEMENT = Number(value?.project_schedule_results);
        break;
      case "SCOPE SCHEDULE":
        ACHIEVEMENT = Number(value?.project_progress_results);
        break;
      default:
        ACHIEVEMENT = 0;
    }

    return (
      <Box width={100}>
        <AchievementBar
          classes={classes}
          percentage={ACHIEVEMENT}
          origin="list"
        />
      </Box>
    );
  };

  const ScopeCell = ({ value }) => {
    const method = value.achievement_calculation_method;

    const _scope_res =
      value.project_scope_results !== null
        ? Number(value.project_scope_results)
        : 0;

    let PROGRESS_SCOPE;
    let GAP_SCOPE;
    let TEXT_SCOPE;
    let COLOR_SCOPE_BOLD;
    let COLOR_SCOPE_LIGHT;
    let BG_SCOPE_BOLD;
    let BG_SCOPE_LIGHT;

    if (_scope_res === 0) {
      COLOR_SCOPE_BOLD = "#c4c7c7";
      COLOR_SCOPE_LIGHT = "#c4c7c7";
      BG_SCOPE_BOLD = "#c4c7c7";
      BG_SCOPE_LIGHT = "#c4c7c7";
      PROGRESS_SCOPE = _scope_res;
      TEXT_SCOPE = _scope_res;
      GAP_SCOPE = 1;
    } else if (_scope_res > 0 && _scope_res < 0.85) {
      COLOR_SCOPE_BOLD = "#F16C7D";
      COLOR_SCOPE_LIGHT = "#c4c7c7";
      BG_SCOPE_BOLD = "#F16C7D";
      BG_SCOPE_LIGHT = "#c4c7c7";
      PROGRESS_SCOPE = _scope_res;
      TEXT_SCOPE = _scope_res;
      GAP_SCOPE = 1;
    } else if (_scope_res >= 0.85 && _scope_res < 1) {
      COLOR_SCOPE_BOLD = "#ffc000";
      COLOR_SCOPE_LIGHT = "#c4c7c7";
      BG_SCOPE_BOLD = "#ffc000";
      BG_SCOPE_LIGHT = "#c4c7c7";
      PROGRESS_SCOPE = _scope_res;
      TEXT_SCOPE = _scope_res;
      GAP_SCOPE = 1;
    } else if (_scope_res === 1) {
      COLOR_SCOPE_BOLD = "#74CB80";
      COLOR_SCOPE_LIGHT = "#c4c7c7";
      BG_SCOPE_BOLD = "#74CB80";
      BG_SCOPE_LIGHT = "#c4c7c7";
      PROGRESS_SCOPE = _scope_res;
      TEXT_SCOPE = _scope_res;
      GAP_SCOPE = 1;
    } else if (_scope_res > 1 && _scope_res < 2) {
      COLOR_SCOPE_BOLD = "#518e59";
      COLOR_SCOPE_LIGHT = "#c4c7c7";
      BG_SCOPE_BOLD = "#518e59";
      BG_SCOPE_LIGHT = "#74CB80";
      PROGRESS_SCOPE = _scope_res > 1 ? _scope_res - 1 : _scope_res;
      TEXT_SCOPE = _scope_res;
      GAP_SCOPE = 1;
    } else if (_scope_res > 2) {
      COLOR_SCOPE_BOLD = "#518e59";
      COLOR_SCOPE_LIGHT = "#c4c7c7";
      BG_SCOPE_BOLD = "#518e59";
      BG_SCOPE_LIGHT = "#c4c7c7";
      PROGRESS_SCOPE = _scope_res > 1 ? _scope_res - 1 : _scope_res;
      TEXT_SCOPE = _scope_res;
      GAP_SCOPE = 1;
    }

    return (
      <Box width={100}>
        {method !== "SCHEDULE" && (
          <ProjectBar
            percentage={PROGRESS_SCOPE}
            gap={GAP_SCOPE}
            text={TEXT_SCOPE}
            bold={COLOR_SCOPE_BOLD}
            light={COLOR_SCOPE_LIGHT}
            bg={BG_SCOPE_BOLD}
            dash={BG_SCOPE_LIGHT}
          />
        )}
      </Box>
    );
  };

  const SchedulaCell = ({ value }) => {
    const method = value.achievement_calculation_method;

    const _schedule_res =
      value.project_schedule_results !== null
        ? Number(value.project_schedule_results)
        : 0;

    let PROGRESS_SCHEDULE;
    let GAP_SCHEDULE;
    let TEXT_SCHEDULE;
    let COLOR_SCHEDULE_BOLD;
    let COLOR_SCHEDULE_LIGHT;
    let BG_SCHEDULE_BOLD;
    let BG_SCHEDULE_LIGHT;

    if (_schedule_res === 0) {
      COLOR_SCHEDULE_BOLD = "#c4c7c7";
      COLOR_SCHEDULE_LIGHT = "#c4c7c7";
      BG_SCHEDULE_BOLD = "#c4c7c7";
      BG_SCHEDULE_LIGHT = "#c4c7c7";
      PROGRESS_SCHEDULE = _schedule_res;
      TEXT_SCHEDULE = _schedule_res;
      GAP_SCHEDULE = 1;
    } else if (_schedule_res > 0 && _schedule_res < 0.85) {
      COLOR_SCHEDULE_BOLD = "#F16C7D";
      COLOR_SCHEDULE_LIGHT = "#c4c7c7";
      BG_SCHEDULE_BOLD = "#F16C7D";
      BG_SCHEDULE_LIGHT = "#c4c7c7";
      PROGRESS_SCHEDULE = _schedule_res;
      TEXT_SCHEDULE = _schedule_res;
      GAP_SCHEDULE = 1;
    } else if (_schedule_res >= 0.85 && _schedule_res < 1) {
      COLOR_SCHEDULE_BOLD = "#ffc000";
      COLOR_SCHEDULE_LIGHT = "#c4c7c7";
      BG_SCHEDULE_BOLD = "#ffc000";
      BG_SCHEDULE_LIGHT = "#c4c7c7";
      PROGRESS_SCHEDULE = _schedule_res;
      TEXT_SCHEDULE = _schedule_res;
      GAP_SCHEDULE = 1;
    } else if (_schedule_res === 1) {
      COLOR_SCHEDULE_BOLD = "#74CB80";
      COLOR_SCHEDULE_LIGHT = "#c4c7c7";
      BG_SCHEDULE_BOLD = "#74CB80";
      BG_SCHEDULE_LIGHT = "#c4c7c7";
      PROGRESS_SCHEDULE = _schedule_res;
      TEXT_SCHEDULE = _schedule_res;
      GAP_SCHEDULE = 1;
    } else if (_schedule_res > 1 && _schedule_res < 2) {
      COLOR_SCHEDULE_BOLD = "#518e59";
      COLOR_SCHEDULE_LIGHT = "#74CB80";
      BG_SCHEDULE_BOLD = "#518e59";
      BG_SCHEDULE_LIGHT = "#74CB80";
      PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
      TEXT_SCHEDULE = _schedule_res;
      GAP_SCHEDULE = 1;
    } else if (_schedule_res > 2) {
      COLOR_SCHEDULE_BOLD = "#518e59";
      COLOR_SCHEDULE_LIGHT = "#518e59";
      BG_SCHEDULE_BOLD = "#518e59";
      BG_SCHEDULE_LIGHT = "#518e59";
      PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
      TEXT_SCHEDULE = _schedule_res;
      GAP_SCHEDULE = 1;
    }

    return (
      <Box width={100}>
        {method !== "SCOPE" && (
          <ProjectBar
            percentage={PROGRESS_SCHEDULE}
            gap={GAP_SCHEDULE}
            text={TEXT_SCHEDULE}
            bold={COLOR_SCHEDULE_BOLD}
            light={COLOR_SCHEDULE_LIGHT}
            bg={BG_SCHEDULE_BOLD}
            dash={BG_SCHEDULE_LIGHT}
          />
        )}
      </Box>
    );
  };

  const COLUMN = [
    {
      Header: "Project Name",
      accessor: "name",
      Cell: NameCell,
      style: {
        minWidth: 500,
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: StatusCell,
      style: {
        width: 100,
        borderLeft: "1px solid #D9D9D9",
      },
    },
    {
      Header: "Owner",
      accessor: "owner",
      Cell: OwnerCell,
      style: {
        width: 130,
      },
    },
    {
      Header: "Progress",
      accessor: "progress",
      Cell: ProgressCell,
      style: {
        width: 100,
      },
    },
    {
      Header: "Achievement",
      accessor: "achievement",
      Cell: AchievementCell,
      style: {
        width: 100,
      },
    },
    // {
    //   Header: "End Date",
    //   accessor: "end_date",
    //   Cell: ({ value }) => (
    //     <span style={{ fontSize: 14 }}>
    //       {moment(value).format("DD-MM-YYYY")}
    //     </span>
    //   ),
    //   style: {
    //     width: 120,
    //   },
    // },
    {
      Header: "Scope",
      accessor: "scope",
      Cell: ScopeCell,
      style: {
        width: 100,
      },
    },
    {
      Header: "Schedule",
      accessor: "schedule",
      Cell: SchedulaCell,
      style: {
        width: 100,
      },
    },
    {
      Header: "",
      accessor: "action",
      Cell: ({ value }) => <AnchorProject classes={classes} project={value} />,
      style: {
        width: 30,
      },
    },
  ];

  const columns = useMemo(() => COLUMN, [projectTState]);
  const data = useMemo(
    () => projectTState.projectData,
    [projectTState.projectData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({ columns, data }, useExpanded);

  return (
    <div>
      <TableContainer>
        {projectTState.loadProject && <LinearProgress />}
        <Tables size="small" aria-label="a dense table" {...getTableProps}>
          <TableHead className={classes.tableHeader}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    className={classes.txtHeader}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps({
                          style: cell.column.style,
                        })}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Tables>
      </TableContainer>

      <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
        {projectTState.projectData.length === 0 && !projectTState.loadProject && (
          <Typography variant="subtitle2" className={classes.title888}>
            Data not found
          </Typography>
        )}
      </Box>

      <PaginateProject classes={classes} />
    </div>
  );
};

export default TableTodos;
