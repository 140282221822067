import React, { Fragment, useContext, useState } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import WorkRoundedIcon from "@material-ui/icons/WorkRounded";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import BlockIcon from "@material-ui/icons/Block";

import DialogRemoveUnit from "./DialogRemoveUnit";
import ContextGlobalDrive from "../../context/ContextDrive";

function ListMemberUnit({ item, classes }) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [openDialogRemoveUnit, setOpenDialogRemoveUnit] = useState(false);
  return (
    <Fragment>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ width: 30, height: 30 }}>
            {item.subject !== null ? (
              <WorkRoundedIcon fontSize="inherit" />
            ) : (
              <BlockIcon fontSize="inherit" />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Tooltip
              title={
                context.detailFolder.id === item.object_id ? "" : "Inherited"
              }>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{
                  color:
                    context.detailFolder.id !== item.object_id ||
                    item.subject === null
                      ? "#999"
                      : "#333",
                }}>
                {item.subject !== null
                  ? `${item.subject.structure_unit_type_name} - ${item.subject.structure_unit_name}`
                  : "Unit has been deleted"}
              </Typography>
            </Tooltip>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            disabled={context.detailFolder.id !== item.object_id ? true : false}
            onClick={() => setOpenDialogRemoveUnit(true)}>
            <RemoveCircleOutlineIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <DialogRemoveUnit
        item={item}
        classes={classes}
        openDialogRemoveUnit={openDialogRemoveUnit}
        setOpenDialogRemoveUnit={setOpenDialogRemoveUnit}
      />
    </Fragment>
  );
}

export default ListMemberUnit;
