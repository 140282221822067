import React, { useContext, useEffect } from "react";
import ContextReports from "../../../../context/ContextReports"
import AxiosConfig from "../../../../../../constants/config-axios";
import * as constants from "../../../../context/constants";
import { URL_API } from "../../../../../../constants/config-api";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import * as UrlHelper from "../../../../../../utilities/UrlHelper";

const Details = () => {
  const {
    liveSummary,
    liveSummaryDetail,
    liveSummaryDetailDispatch
  } = useContext(ContextReports);

  useEffect(() => {
    if (liveSummaryDetail.item !== null) {
      AxiosConfig.get(
        `${URL_API}/reporting/live/summary/${liveSummaryDetail.item.id}?options[period_id]=${liveSummary?.options?.defaultPeriod?.id || ''}`
      ).then((res) => {
        const result = res.data.data;
        console.log("test", result);
        liveSummaryDetailDispatch({
          type: constants.LIVE_SUMMARY_DETAIL_DATA,
          payload: result
        });
      });
    }
  }, [liveSummaryDetail.item]);

  const handleClose = () => {
    UrlHelper.updateQueryParams('id', '');

    liveSummaryDetailDispatch({
      type: constants.LIVE_SUMMARY_DETAIL_DATA,
      payload: null
    });
    liveSummaryDetailDispatch({
      type: constants.LIVE_SUMMARY_DETAIL_ITEM,
      payload: null
    });
  }

  return (
    <>
      {
        liveSummaryDetail.item !== null && (
          <Dialog onClose={handleClose} style={{minWidth: 500}} open={true}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {'Report Detail: '}{liveSummaryDetail.item.owner_name}
          </DialogTitle>
          <DialogContent style={{minWidth: 500}}>
            <Paper elevation={0}>
              <Grid container>
                <Grid item xs={12}>
                <Typography variant="p"><b>Goal List</b></Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                        <TableCell style={{minWidth: 350}}>
                          Goal Name
                        </TableCell>
                        <TableCell>
                          ORA
                        </TableCell>
                        <TableCell>
                          Expected
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        liveSummaryDetail.data !== null && liveSummaryDetail.data.goals.length === 0 && (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell colSpan={3} align={'center'}>No Data Available</TableCell>
                          </TableRow>
                        )
                      }
                      {
                        liveSummaryDetail.data !== null && liveSummaryDetail.data.goals.map((row, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell style={{minWidth: 350}}>
                                <a target="_blank" href={`/goal-detail/${row.id}`}>{ row.name }</a>
                              </TableCell>
                              <TableCell>
                                { row.overall_result_achievement }%
                              </TableCell>
                              <TableCell>
                                { row.expected_result_value_achievement }%
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <br />
              <Grid container>
                <Grid item xs={12}>
                <Typography variant="p"><b>Measured Activity List</b></Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  &nbsp;
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                        <TableCell style={{minWidth: 350}}>
                          Measured Activity Name
                        </TableCell>
                        <TableCell>
                          ORA
                        </TableCell>
                        <TableCell>
                          Expected
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        liveSummaryDetail.data !== null && liveSummaryDetail.data.measuredActivities.length === 0 && (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell colSpan={3} align={'center'}>No Data Available</TableCell>
                          </TableRow>
                        )
                      }
                      {
                        liveSummaryDetail.data !== null && liveSummaryDetail.data.measuredActivities.map((row, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell style={{minWidth: 350}}>
                                <a target="_blank" href={`/allma-detail/${row.id}`}>{ row.name }</a>
                              </TableCell>
                              <TableCell>
                                { row.overall_result_achievement }%
                              </TableCell>
                              <TableCell>
                                { row.expected_result_value_achievement }%
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        )
      }
    </>
  );
};

export default Details;
