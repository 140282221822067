import React, { useState, useContext, useEffect, Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box, Tabs, Tab } from "@material-ui/core";

import axiosConfig from "../../../constants/config-axios";

import ViewMAMoreDetail from "./ViewMAMoreDetail";

import ListStatusGoalDraft from "./ComponentsStatusMA/Draft/ListStatusMADraft";
import ListStatusMAApproved from "./ComponentsStatusMA/Approved/ListStatusMAApproved";
import ListStatusMAActive from "./ComponentsStatusMA/Active/ListStatusMAActive";
import ListStatusMAOverdue from "./ComponentsStatusMA/Overdue/ListStatusMAOverdue";
import ListStatusMAComplete from "./ComponentsStatusMA/Complete/ListStatusMAComplete";
import DialogExportMA from "./Components/DialogExportMA";

import DialogError from "../../../components/DialogError";
import Snackbeer from "../../../components/Snackbeer";

import ContextGoalDetail from "../Goal/Context/ContextGoalDetail";
import { styleSpecial } from "./Style/StyleMaSPECIAL";

import {
  STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_ACTIVE,
  STATUS_OVERDUE,
  STATUS_COMPLETED,
} from "../../../constants/config-status-goal";
import { URL_API } from "../../../constants/config-api";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#282560",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const useStyles = makeStyles(styleSpecial);

const ViewMA = () => {
  const context = useContext(ContextGoalDetail);
  const classes = useStyles();
  const locationPathGoalId = window.location.pathname.split("/")[2];

  /*
        ````````````````````````````````````
        HANDLE FRESH DATA MA LIST COLLECTION

        ````````````````````````````````````
    */
  useEffect(() => {
    if (context.isMeasuredActivityDetailShow === true) {
      context.setLoaderSpecialMa(true);

      // ============================= MIN_MAX WEIGHT =============================
      let URL = "";
      const targetURL = `${URL_API}/swd/measured-activity?options[filter][goal_id]=${locationPathGoalId}`;
      const minWeight = `&options[filter][weighting][from]=${context.weightValue.min}`;
      const maxWeight = `&options[filter][weighting][to]=${context.weightValue.max}`;

      if (context.weightValue.min === 0 && context.weightValue.max === 100) {
        URL = targetURL;
      } else {
        URL = `${targetURL}${minWeight}${maxWeight}`;
      }

      if (
        context.userTokenStateGlobal !== undefined ||
        context.userTokenStateGlobal !== null
      ) {
        axiosConfig
          .get(URL)
          .then(function (response) {
            context.setLoaderSpecialMa(false);
            // console.log("Response Original MA Collection : ", response);
            // // console.log("Json sringify : ", JSON.stringify(response.data.data))

            if (response.status === 200) {
              context.setColletionMA(response.data.data);
            }
          })
          .catch(function (error) {
            context.setLoaderSpecialMa(false);
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
        setOpenDialogError(true);
      }
    }
  }, [context.isMeasuredActivityDetailShow, context.triggerMAList]);

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);

  /*
        ``````````````
        HANDLE NEW TAB

        ``````````````
    */

  /**
   ==================================================================================
    Show Goal Counter Status
   ==================================================================================
   */
  const [maCounter, setMACounter] = useState([]);

  useEffect(() => {
    if (context.isMeasuredActivityDetailShow === true) {
      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity/count-status?options[filter][goal_id]=${locationPathGoalId}`
        )
        .then((response) => {
          // // console.log("Response Status Counter", response);
          const result = response.data.data;

          if (response.status === 200) {
            const filterstatusDraft = result.filter((item) => item.id !== "1");
            setMACounter(filterstatusDraft);
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
        });
    }
  }, [context.isMeasuredActivityDetailShow]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [valueTab, setValueTab] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);

    // if (newValue === 0) {
    //   context.setStatusMaId(1); //*Draft
    //   // localStorage.setItem("status_goal_id", "1")
    //   context.setHideCreateMABtn(false);
    // }

    if (newValue === 0) {
      context.setStatusMaId(2); //*Approved
      // localStorage.setItem("status_goal_id", "2")
      context.setHideCreateMABtn(false);
    }

    if (newValue === 1) {
      context.setStatusMaId(3); //*Active
      // localStorage.setItem("status_goal_id", "3")
      context.setHideCreateMABtn(false);
    }

    if (newValue === 2) {
      context.setStatusMaId(6); //*Overdue
      // localStorage.setItem("status_goal_id", "6")
      context.setHideCreateMABtn(true);
    }

    if (newValue === 3) {
      context.setStatusMaId(4); //*Completed
      // localStorage.setItem("status_goal_id", "4")
      context.setHideCreateMABtn(true);
    }
  };

  return (
    // <Grid container className={classes.fixedLayout}>
    <Box className={classes.layoutMarginLeftAndTop}>
      <Fragment>
        {context.isMeasuredActivityDetailShow === true && (
          <Box display="flex" alignItems="center" pl={2}>
            <StyledTabs
              indicatorColor="primary"
              value={valueTab}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
            >
              {listTabs.length > 0 &&
                listTabs.map((item, i) => {
                  let counter = 0;

                  if (maCounter.length > 0) {
                    if (maCounter[i].code === item.code) {
                      counter = maCounter[i].count;
                    }
                  }

                  return (
                    <Tab
                      classes={{ root: classes.widthTab }}
                      label={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{
                            textTransform: "capitalize",
                            marginBottom: -10,
                          }}
                        >
                          <b>{item.label}</b>
                          <span
                            style={{
                              color: "#1D80DB",
                              backgroundColor: "#D7E4F7",
                              fontWeight: "700",
                              paddingLeft: 4,
                              paddingRight: 4,
                              fontSize: 14,
                              marginLeft: 5,
                              borderRadius: 5,
                            }}
                          >
                            {counter}
                          </span>
                        </Typography>
                      }
                      {...a11yProps(i)}
                    />
                  );
                })}
            </StyledTabs>
          </Box>
        )}

        {context.isMeasuredActivityDetailShow === true && (
          <Box bgcolor="#fafafa">
            {/* <TabPanel value={valueTab} index={0}>
                  <ListStatusGoalDraft />
                </TabPanel> */}
            <TabPanel value={valueTab} index={0}>
              <ListStatusMAApproved />
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <ListStatusMAActive />
            </TabPanel>
            <TabPanel value={valueTab} index={2}>
              <ListStatusMAOverdue />
            </TabPanel>
            <TabPanel value={valueTab} index={3}>
              <ListStatusMAComplete />
            </TabPanel>
          </Box>
        )}

        <DialogError
          classes={classes}
          isOpenDialogError={isOpenDialogError}
          setOpenDialogError={setOpenDialogError}
          textErrorInformation="Feature of Measured Activity is still under Development"
        />

        {context.isMeasuredActivityDetailShow === false && (
          <ViewMAMoreDetail
            classes={classes}
            isMeasuredActivityDetailShow={context.isMeasuredActivityDetailShow}
            setMeasuredActivityDetailShow={
              context.setMeasuredActivityDetailShow
            }
            memberPositionList={context.memberPositionList}
            userTokenState={context.userTokenStateGlobal}
            collectionMaResultList={context.collectionMaResultList}
            maResultLength={context.maResultLength}
            setCollectionMaResultList={context.setCollectionMaResultList}
            isModalCreateMAResult={context.isModalCreateMAResult}
            setModalCreateMAResult={context.setModalCreateMAResult}
            loader={context.loader}
            setLoader={context.setLoader}
          />
        )}

        {/* 
                        ``````````````````
                        COMPONENT SNACKBAR

                        ``````````````````
                    */}

        <Snackbeer
          setModalResponse200={context.setModalResponse200}
          isModalResponse200={context.isModalResponse200}
          messages="MA berhasil di hapus !"
        />

        {/* 
                    ```````````````````````````````````
                    DIALOG EXPORT for MEASURED ACTIVITY

                    ```````````````````````````````````
                */}

        <DialogExportMA
          isModalExportMA={context.isModalExportMA}
          setModalExportMA={context.setModalExportMA}
        />
      </Fragment>
    </Box>
    // </Grid>
  );
};

export default ViewMA;

const listTabs = [
  {
    id: 2,
    label: "Approved",
    code: STATUS_APPROVED,
    href: "/ma/approved",
  },

  {
    id: 3,
    label: "Active",
    code: STATUS_ACTIVE,
    href: "/ma",
  },

  {
    id: 4,
    label: "Overdue",
    code: STATUS_OVERDUE,
    href: "/ma/overdue",
  },

  {
    id: 5,
    label: "Complete",
    code: STATUS_COMPLETED,
    href: "/ma/compeleted",
  },
];
