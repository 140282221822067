export const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "white",
    textTransform: "capitalize",
    "&:disabled": {
      background: "grey",
      color: "white",
    },
  },

  buttonModal: {
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
  },
  buttonModalCancel: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  buttonOutlined: {
    borderRadius: 5,
    // background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "grey",
    // color: '#d2d2d2',
    textTransform: "capitalize",
    "&:hover": {
      color: "grey",
      background: "#d2d2d2",
      borderWidth: 0,
    },
  },
  title: {
    fontFamily: "Roboto",
  },
  titleHeader: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // marginTop:  theme.spacing(2),
  },
  titleForm: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(3),
  },
  titleActive: {
    color: "#cc0707",
    fontFamily: "Roboto",
  },
  titleNotActive: {
    fontFamily: "Roboto",
    color: "grey",
  },
  titleHaveDone: {
    fontFamily: "Roboto",
    color: "green",
  },
  titleTambahAnggotaKeluarga: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: "#cc0707",
    cursor: "pointer",
  },
  paperInfoIdentitas: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  paperInfoDasar: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },

  /*
        ````````
        FORM

        ````````
    */
  textField: {
    minWidth: 350,
    // minWidth: 450,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  textFieldDropDown: {
    minWidth: 135,
    // minWidth: 125,
    // marginLeft: theme.spacing(2),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  list: {
    // padding: theme.spacing(5, 2),
    // marginTop: theme.spacing(4),
    width: 675,
    borderRadius: 7,
    fontFamily: "Roboto",
  },

  /*
        ````````````````
        INFO ERROR PAPER

        ````````````````
    */
  paperStyle: {
    // marginLeft : theme.spacing(3),
    // marginRight : theme.spacing(3),
    // marginBottom : theme.spacing(3),
    backgroundColor: "#ffcbcb",
  },
  titlePaperStyle: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    // marginLeft: theme.spacing(3),
    fontFamily: "Roboto",
    color: "#a50016",
  },
  closePaperStyle: {
    color: "#a50016",
  },
  checkboxStyle: {
    marginRight: theme.spacing(34),
    fontFamily: "Roboto",
  },
  pictUpload: {
    marginRight: theme.spacing(37),
    cursor: "pointer",
    height: 48,
    width: 48,
  },
});
