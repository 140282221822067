import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import axios from "axios";

import useScrollTop from "react-hook-scrolltop";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Identitas from "./ComponentProfileDetail/Identitas";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";
import Snackbeer from "../../../components/Snackbeer";

import { URL_API } from "../../../constants/config-api";

import { styles } from "./Style/StyleProfileDetail";

import "./Style/react-tab-custom-style.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewProfileDetail = (props) => {
  const { classes } = props;

  /*
        ``````````````````
        USE SCROLL TOP

        ``````````````````
    */
  const isTopOfPage = useScrollTop();

  /* 
        ````````````````````
        COMPONENT DID MOUNT
        
        ````````````````````
    */

  // const dataIdentitasInfoDasar = localStorage.getItem('data_identitas');
  // const dataIdentitasInfoDasarAfterParse = JSON.parse(dataIdentitasInfoDasar);

  const userToken = localStorage.getItem("userToken");
  const [userTokenState, setUserTokenState] = useState("");

  const employeeId = localStorage.getItem("employee_id");

  const dataEmployeeDetail = localStorage.getItem("response_employee_detail");
  const dataEmployeeDetailAfterParse = JSON.parse(dataEmployeeDetail);

  const [namaDepan, setNamaDepan] = useState("");
  const [namaBelakang, setNamaBelakang] = useState("");
  const [namaPanggilan, setNamaPanggilan] = useState("");
  const [namaPosisi, setPosisi] = useState("");

  const [fotoQuery, setFotoQuery] = useState("");

  const [fotoQueryEnabled, setFotoQueryEnabled] = useState(false);

  const [listRoleNow, setListRoleNow] = useState([]);

  useEffect(() => {
    setUserTokenState(userToken);

    // // console.log("dataEmployeeDetailAfterParse : ", dataEmployeeDetailAfterParse);
    if (dataEmployeeDetailAfterParse !== null) {
      // if(dataEmployeeDetailAfterParse.member.self !== null){
      //     if(dataEmployeeDetailAfterParse.member.self.rel !== null){
      //         if(dataEmployeeDetailAfterParse.member.self.rel.photo_url !== null){

      //                 setFotoQuery(dataEmployeeDetailAfterParse.member.self.rel.photo_url);
      //         };
      //     };
      // };

      // setNamaDepan(dataEmployeeDetailAfterParse.member.first_name);
      // setNamaBelakang(dataEmployeeDetailAfterParse.member.last_name);
      // setNamaPanggilan(dataEmployeeDetailAfterParse.member.nickname);

      if (dataEmployeeDetailAfterParse.position !== null) {
        if (dataEmployeeDetailAfterParse.position.length > 0) {
          setPosisi(
            dataEmployeeDetailAfterParse.position[0]
              .structure_position_title_name
          );
        }
      }
    }

    /* 
            `````````
            GET ROLE 

            `````````
        */

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userToken,
    };

    axios.defaults.headers.common = header;

    axios
      .get(URL_API + `/account-management/user/batch/create`)
      .then((response) => {
        // console.log("Original response ROLE : ", response);

        if (response.status == 200) {
          const templateListRole = [];

          if (response.data.data !== undefined) {
            Object.getOwnPropertyNames(response.data.data.roleOptions).forEach(
              (val, idx, array) => {
                const data = {
                  key: val,
                  value: response.data.data.roleOptions[val],
                };
                templateListRole.push(data);
              }
            );
          }

          setListRoleNow(templateListRole);
        }
      })
      .catch((error) => {
        // console.log("Error response : ", error.response);
      });

    /*
                ``````````````
                FOTO

                ````
            */

    axios
      .get(URL_API + `/human-resource/employee/batch/${employeeId}`)
      .then(function (response) {
        // console.log("Response Original DETAIL EMPLOYEE : ", response);

        if (response.status == 200) {
          setFotoQuery(response.data.data.member.self.rel.photo_url);
          setNamaDepan(response.data.data.member.first_name);
          setNamaBelakang(response.data.data.member.last_name);
          setNamaPanggilan(response.data.data.member.nickname);
          // window.location.reload();
        }
      })
      .catch(function (error) {
        // console.log("Error : ", error.response);
      });
  }, []);

  useEffect(() => {
    if (fotoQueryEnabled == true) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .get(URL_API + `/human-resource/employee/batch/${employeeId}`)
        .then(function (response) {
          // console.log("Response Original DETAIL EMPLOYEE : ", response);

          if (response.status == 200) {
            setFotoQuery(response.data.data.member.self.rel.photo_url);
            // window.location.reload();
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
        });

      /* GET ROLE */
      axios
        .get(URL_API + `/account-management/user/batch/create`)
        .then((response) => {
          // console.log("Original response ROLE : ", response);

          if (response.status == 200) {
            const templateListRole = [];

            // if(response.data.data !== undefined) {

            //     Object.getOwnPropertyNames(response.data.data.roleOptions).forEach((val, idx, array) => {
            //             const data = {
            //                 key: val,
            //                 value: response.data.data.roleOptions[val]
            //             };
            //             templateListRole.push(data);
            //         }
            //     )
            // };

            // setListRoleNow(templateListRole)
          }
        })
        .catch((error) => {
          // console.log("Error response : ", error.response);
        });
    }
  }, [fotoQueryEnabled, fotoQuery]);

  /*
        ``````````
        HANDLE TAB

        ``````````
    */

  const [tabIndex, setTabIndex] = useState(0);
  const handleTab = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  /*
        ```````````````````
        DROPDOWN ROLE
        ```````````````````
    */
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClickInviteUser(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseInviteUser() {
    setAnchorEl(null);
  }

  /*
        `````````````````````````````
        HANDLE CHOOSE ROLE UNTUK USER

            - Inspired from <DialogLanjutInviteAnggota />

        `````````````````````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);

  const [roleChoosed, setRoleChoosed] = useState("");
  const handleChoose = (e, item) => {
    e.preventDefault();
    //*console.log(data);
    setRoleChoosed(item.value);
    handleCloseInviteUser();

    /*
                `````````````````````````````
                FIRE INVITE AND SEND TO EMAIL 

                `````````````````````````````
            */

    let data = {
      User: {
        member_id: employeeId,
        role_id: item.key,
      },
    };

    // console.log("Data FIRE : ", data);

    if (userTokenState !== undefined && userTokenState !== null) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + `/account-management/user/batch`, data)
        .then(function (response) {
          setModalResponse200(true);

          // console.log("Response Original AFTER INVITE : ", response);

          if (response.status == 200) {
            // setModalLanjut(false);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          setRoleChoosed("");

          if (error.response == 500) {
            alert(
              error.response.data.message !== undefined
                ? error.response.data.message
                : "500"
            );
          } else if (error.response.status == 400) {
            alert(
              error.response.data.info !== undefined
                ? error.response.data.info.developer_message
                : "400"
            );
          } else {
            alert("Ooops, something went wrong !");
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Paper elevation={0}>
        <Grid container alignItems="center" justify="center">
          <Avatar alt="My Company Profile" className={classes.avatarMyCompany}>
            {fotoQuery !== null ? (
              <img
                src={URL_API + "/" + fotoQuery + "&token=" + userToken}
                className={classes.bigRealFoto}
                alt="."
              />
            ) : (
              <Typography variant="h4" className={classes.titleMyCompanyLogo}>
                P
              </Typography>
            )}
          </Avatar>

          <Typography variant="h5" className={classes.titleEmployeeHeader}>
            <b>{namaDepan !== "" ? namaDepan + " " + namaBelakang : ""} </b>
          </Typography>

          <Typography
            variant="subtitle1"
            className={classes.titleEmployeeHeaderJabatan}
          >
            <b>{namaPosisi !== "" ? namaPosisi : "-"}</b>
          </Typography>
        </Grid>

        <Grid container alignItems="center" justify="center">
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseInviteUser}
          >
            {listRoleNow.length > 0 &&
              listRoleNow.map((item, i) => {
                return (
                  <StyledMenuItem
                    key={i}
                    onClick={(e) => handleChoose(e, item)}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>{item.value}</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                );
              })}
          </StyledMenu>
        </Grid>
      </Paper>

      {!isTopOfPage ? (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : null}

      <br />
      <br />
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => handleTab(tabIndex)}
        style={{
          backgroundColor: "white",
          marginTop: "-48px",
          textAlign: "center",
        }}
      >
        <TabList>
          <Tab>
            <Typography variant="subtitle2" className={classes.title}>
              <b>Identitas</b>
            </Typography>
          </Tab>
          {/* <Tab>
            <Typography variant="subtitle2" className={classes.title}>
              <b>Keluarga</b>
            </Typography>
          </Tab>
          <Tab>*/}
        </TabList>
        <TabPanel style={{ backgroundColor: "#white" }}>
          <Identitas
            classes={classes}
            setFotoQueryEnabled={setFotoQueryEnabled}
            setFotoQuery={setFotoQuery}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </TabPanel>
      </Tabs>

      <Snackbeer
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Berhasil menentukan Role untuk User "
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewProfileDetail);
