import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  TextField,
  Grid,
} from "@material-ui/core";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

const DialogCreateUser = ({
  classes,
  openCreateUser,
  setOpenCreateUser,
  onTriggerUser,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");

  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openCreateUser) {
      AxiosConfig.get(`${URL_API}/account-management/user/quick/create`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setRoleId(result.roleCollections[0]?.id);
          }
        })
        .catch((error) => {
          // console.log("ERROR", error);
        });
    }
  }, [openCreateUser]);

  const handleFirstName = (e) => setFirstName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);

  const handleCreateUser = () => {
    setLoading(true);

    const data = {
      User: {
        first_name: firstName,
        last_name: lastName,
        email,
        role_id: [roleId],
      },
    };

    AxiosConfig.post(`${URL_API}/account-management/user/quick`, data)
      .then((res) => {
        // const result = res.data.data;

        if (res.status === 200) {
          window.open(res.data.data, "_blank").focus();

          setLoading(false);
          setOpenCreateUser(false);
          onTriggerUser(Math.floor(Math.random() * 100));
          setFirstName("");
          setLastName("");
          setEmail("");
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openCreateUser}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Buat User</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title888}>
                Nama Depan
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={firstName}
                onChange={handleFirstName}
              />
            </Grid>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title888}>
                Nama Belakang
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={lastName}
                onChange={handleLastName}
              />
            </Grid>
          </Grid>
          <Box my={1} />
          <Typography variant="subtitle2" className={classes.title888}>
            Email
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            type="email"
            value={email}
            onChange={handleEmail}
          />
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenCreateUser(false)}
        >
          Batal
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleCreateUser}
        >
          {loading ? "Membuat..." : "Buat"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogCreateUser;
