import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContentText,
  DialogContent,
  Box,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogDeleteFile({
  openDialogDeleteFile,
  setOpenDialogDeleteFile,
  item,
  classes,
}) {
  const context = useContext(ContextGlobalDrive);
  let textEvidence = "";

  const [loading, setLoading] = useState(false);
  const [goalEvidence, setGoalEvidence] = useState(false);
  const [maEvidence, setMaEvidence] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogDeleteFile === true) {
      // Check if there is goal evidence or not
      if (
        item.goalResultEvidence !== undefined &&
        item.goalResultEvidence.length > 0
      ) {
        setGoalEvidence(true);
      }

      // Check if there is MA evidence or not
      if (
        item.measuredActivityResultEvidence !== undefined &&
        item.measuredActivityResultEvidence.length > 0
      ) {
        setMaEvidence(true);
      }
    }
  }, [openDialogDeleteFile]);

  // Make Conditional For showing the Text
  if (goalEvidence && maEvidence) {
    textEvidence = "Goal & MA";
  } else if (goalEvidence && !maEvidence) {
    textEvidence = "Goal";
  } else if (!goalEvidence && maEvidence) {
    textEvidence = "MA";
  }

  // Hit Delete File Method
  const handleDeleteFile = () => {
    // console.log("Item File Del", item);

    setLoading(true);

    const params = new URLSearchParams();

    params.append(`DriveFile[0][id]`, item.id);

    axiosConfig
      .delete(`${URL_API}/drive/file/batch`, {
        data: params,
      })
      .then((response) => {
        // console.log("Response Delete File", response);

        if (response.status === 200) {
          context.setSuccessTriggerFile(Math.floor(Math.random() * 1000 + 1));
          context.setSuccessTriggerRecoveredFile(
            Math.floor(Math.random() * 1000 + 1)
          );
          context.setModalResponse200(true);
          context.setMessages("File deleted successfully");

          context.setSelectedFolder([]); // Empty Selected Folder
          context.setSelectedFile([]); // Empty Selected File
          setOpenDialogDeleteFile(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogDeleteFile}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ textAlign: "center" }}>
        <Box marginTop={2} marginBottom={2}>
          <HelpOutlineRoundedIcon
            style={{ fontSize: 30, color: "#d64253", marginBottom: 12 }}
          />
        </Box>
        <DialogContentText id="alert-dialog-description" style={{ width: 375 }}>
          {goalEvidence || maEvidence ? (
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#333" }}
            >
              File ini (<strong>{item.name}</strong>){" "}
              <b style={{ color: "#d64253" }}>
                sudah dijadikan bukti untuk {textEvidence}
              </b>{" "}
              Apakah anda akan tetap menghapus file ini?
              <br />
              <span style={{ color: "#555", fontSize: 13 }}>
                File yang dihapus akan tersimpan di folder <b>Recycle Bin</b>
              </span>
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#333" }}
            >
              Apakah Anda yakin ingin menghapus File '
              <strong>{item.name}</strong>' ini ?
              <br />
              <span style={{ color: "#555", fontSize: 13 }}>
                File yang dihapus akan tersimpan di folder <b>Recycle Bin</b>
              </span>
            </Typography>
          )}

          <Box>
            <List dense>
              {item.goalResultEvidence !== undefined &&
                item.goalResultEvidence.length > 0 &&
                item.goalResultEvidence.map((evidence, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "#333", fontSize: 14 }}
                        >
                          Goal: {evidence.goal !== null && evidence.goal.name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "#999", fontSize: 11 }}
                        >
                          Owner:{" "}
                          {evidence.goal !== null &&
                            evidence.goal.owner.member_first_name}{" "}
                          {evidence.goal !== null &&
                            evidence.goal.owner.member_last_name}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}

              {item.measuredActivityResultEvidence !== undefined &&
                item.measuredActivityResultEvidence.length > 0 &&
                item.measuredActivityResultEvidence.map((evidence, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "#333", fontSize: 14 }}
                        >
                          MA:{" "}
                          {evidence.measuredActivity !== null &&
                            evidence.measuredActivity.name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "#999", fontSize: 11 }}
                        >
                          Owner:{" "}
                          {evidence.measuredActivity !== null &&
                            evidence.measuredActivity.owner
                              .member_first_name}{" "}
                          {evidence.measuredActivity !== null &&
                            evidence.measuredActivity.owner.member_last_name}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          marginTop: 20,
          marginBottom: 25,
        }}
      >
        <Button
          variant="contained"
          disableElevation
          className={classes.button2}
          onClick={() => setOpenDialogDeleteFile(false)}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className={classes.button}
          onClick={handleDeleteFile}
        >
          {loading ? <CircularProgress color="#fff" size={15} /> : "Delete"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogDeleteFile;
