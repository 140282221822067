export const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  timeOffQuotaCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
    borderRadius: 11,
    padding: "10px 20px",
    marginTop: "1rem",
  },
});
