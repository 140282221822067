import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Snackbar,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import styleTable from "./style/task-list-table.module.css";

import moment from "moment";

import { styles } from "./style/styleProject";
import AxiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import ContextProject from "./context/ContextProject";
import { getStartEndDateForProject } from "./components/global/helperGantt";
import handleError from "../Report/components/global/handleError";

import SettingsGantt from "./components/gantt/SettingsGantt";
// import DialogError from "../../components/DialogError";
import GroupedGantt from "./components/gantt/GroupedGantt";
import TaskListTable from "./components/gantt/TaskListTable";
import TaskListHeader from "./components/gantt/TaskListHeader";
import SnackbarError from "../../components/SnackbarError";

const useStyles = makeStyles(styles);

const ViewTimeLine = ({ project }) => {
  const classes = useStyles();
  const { projectTState, projectTDispatch, setTriggerTask } =
    useContext(ContextProject);
  const groups = useMemo(
    () => projectTState.groupTaskData,
    [projectTState.groupTaskData]
  );

  // ============= STATE =============
  const [view, setView] = useState(ViewMode.Month);
  const [isChecked, setIsChecked] = useState(true);
  const [tasks, setTasks] = useState([
    ...projectTState.projectForm,
    ...projectTState.taskForm,
  ]);
  // conditional
  const [openBar, setOpenBar] = useState(false);
  const [loadUndo, setLoadUndo] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 120;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    setTasks([...projectTState.projectForm, ...projectTState.taskForm]);
  }, [projectTState.taskForm]);

  // Change Task Name
  const tasksName = tasks?.map((el) => {
    return {
      ...el,
      name: el.title,
    };
  });

  // ==================================================================
  // ~~~~~~~~~~~~~~ Handle Task Change (DRAG) ~~~~~~~~~~~~~~
  // ==================================================================
  const handleTaskChange = (task) => {
    // // console.log("On date change TASK:" + task.start);

    localStorage.setItem("task_gantt_id", task.id);
    localStorage.setItem("task_gantt_start", task.start_date);
    localStorage.setItem("task_gantt_due", task.due_date);

    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    let start_date = null;
    let end_date = null;
    let start_error = [];
    let end_error = [];

    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);

      const mulai = moment(task.start).format("dddd");
      const akhir = moment(task.end).format("dddd");
      const projects =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];

      // // console.log("AKhir", akhir);

      if (project?.exclude_weekend === 1) {
        if (akhir === "Sabtu") {
          const newEnd = new Date(task.end);
          newEnd.setDate(newEnd.getDate() - 1);
          end_date = newEnd;
          end_error.push({
            description: `Tanggal selesai dipindahkan ke Jum'at, karena Sabtu (${moment(
              task.end
            ).format("DD-MMM-YYYY")}) adalah akhir pekan`,
          });
        } else if (akhir === "Minggu") {
          const newEnd = new Date(task.end);
          newEnd.setDate(newEnd.getDate() + 1);
          end_date = newEnd;
          end_error.push({
            description: `Tanggal selesai dipindahkan ke Senin, karena Minggu (${moment(
              task.end
            ).format("DD-MMM-YYYY")}) adalah akhir pekan`,
          });
        } else if (mulai === "Sabtu") {
          const newStart = new Date(task.start);
          newStart.setDate(newStart.getDate() - 1);
          start_date = newStart;
          start_error.push({
            description: `Tanggal mulai dipindahkan ke Jum'at, karena Sabtu (${moment(
              task.start
            ).format("DD-MMM-YYYY")}) adalah akhir pekan`,
          });
        } else if (mulai === "Minggu") {
          const newStart = new Date(task.start);
          newStart.setDate(newStart.getDate() + 1);
          start_date = newStart;
          start_error.push({
            description: `Tanggal mulai dipindahkan ke Senin, karena Minggu (${moment(
              task.start
            ).format("DD-MMM-YYYY")}) adalah akhir pekan`,
          });
        } else if (
          mulai !== "Minggu" &&
          mulai !== "Sabtu" &&
          akhir !== "Minggu" &&
          akhir !== "Sabtu"
        ) {
          if (
            projects.start.getTime() !== start.getTime() ||
            projects.end.getTime() !== end.getTime()
          ) {
            const changedProject = { ...projects, start, end };
            newTasks = newTasks.map((t) =>
              t.id === task.project ? changedProject : t
            );
          }
        }
      } else {
        if (
          projects.start.getTime() !== start.getTime() ||
          projects.end.getTime() !== end.getTime()
        ) {
          const changedProject = { ...projects, start, end };
          newTasks = newTasks.map((t) =>
            t.id === task.project ? changedProject : t
          );
        }
      }
    }
    // // console.log("NEW TSK", newTasks);
    setTasks(newTasks);

    // ======= Select / Filter task that has same ID ======= //
    const taskSelect = newTasks.filter((el) => el.id === task.id)[0];
    const startDate = start_date !== null ? start_date : taskSelect.start;
    const endDate = end_date !== null ? end_date : taskSelect.end;

    // // console.log("endDate", endDate);
    // // console.log("halooooo");

    const data = {
      Todolist: {
        title: taskSelect.title,
        description: taskSelect.description,
        remarks: taskSelect.remarks,
        owner_id: taskSelect.owner_id,
        status: taskSelect.status.id,
        priority: taskSelect.priority.id,
        start_date: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        due_date: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
        tags: taskSelect.tags,
        actual_result: Number(taskSelect.actual_result),
        target_result: Number(taskSelect.target_result),
        project_id: taskSelect?.project_id,
        task_group_id: taskSelect?.task_group_id,
        predecessor_id: taskSelect?.dependencies,
        custom_phase_id: taskSelect?.custom_phase_id,
      },
    };

    // // console.log("DATAa", data);

    AxiosConfig.put(`${URL_API}/todolist/${task.id}`, data)
      .then((res) => {
        // console.log("RES Update", res);

        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          start_date = null;
          end_date = null;

          setOpenBar(true);

          if (start_error.length > 0) {
            // Error State
            setOpenDialogError(true);
            setTextErrorMessage("Info");
            setListError(start_error);
            setErrorStatus(400);
          }

          if (end_error.length > 0) {
            // Error State
            setOpenDialogError(true);
            setTextErrorMessage("Info");
            setListError(end_error);
            setErrorStatus(400);
          }
        }
      })
      .catch((error) => {
        // console.log("error", error);
        start_date = null;
        end_date = null;

        setTriggerTask(Math.floor(Math.random() * 100));

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleUndoTask = () => {
    const task_gantt_id = localStorage.getItem("task_gantt_id");
    const task_gantt_start = localStorage.getItem("task_gantt_start");
    const task_gantt_due = localStorage.getItem("task_gantt_due");

    const taskSelect = tasks.find((task) => task.id === task_gantt_id);

    setLoadUndo(true);

    const data = {
      Todolist: {
        title: taskSelect?.title,
        description: taskSelect?.description,
        remarks: taskSelect?.remarks,
        owner_id: taskSelect?.owner_id,
        status: taskSelect?.status.id,
        priority: taskSelect?.priority.id,
        start_date: moment(task_gantt_start).format("YYYY-MM-DD HH:mm:ss"),
        due_date: moment(task_gantt_due).format("YYYY-MM-DD HH:mm:ss"),
        tags: taskSelect?.tags,
        actual_result: Number(taskSelect?.actual_result),
        target_result: Number(taskSelect?.target_result),
        project_id: taskSelect?.project_id,
        task_group_id: taskSelect?.task_group_id,
        predecessor_id: taskSelect?.dependencies,
        custom_phase_id: taskSelect?.custom_phase_id,
      },
    };

    // console.log("DAta Undo", data);

    AxiosConfig.put(`${URL_API}/todolist/${task_gantt_id}`, data)
      .then((res) => {
        // console.log("RES Update", res);

        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));

          localStorage.removeItem("task_gantt_id");
          localStorage.removeItem("task_gantt_start");
          localStorage.removeItem("task_gantt_due");

          setOpenBar(false);
          setLoadUndo(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setTriggerTask(Math.floor(Math.random() * 100));
        setLoadUndo(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleCloseBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenBar(false);
  };

  // ==================================================================
  // ~~~~~~~~~~~~~~ Handle Task Select (CLICK) ~~~~~~~~~~~~~~
  // ==================================================================
  const handleSelect = (task) => {
    // // console.log("Select Task", task);
  };

  return (
    <Box>
      <Box
        mt={9}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        position="fixed"
        zIndex={10}
        bgcolor="#fff"
        width="100%"
        pr={14}
      >
        {projectTState.loadTask ? (
          <Box display="flex" alignItems="center" pl={2}>
            <CircularProgress
              size={14}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography variant="subtitle1" className={classes.title333}>
              Updating...
            </Typography>
          </Box>
        ) : (
          <Box />
        )}
        <SettingsGantt
          classes={classes}
          onViewModeChange={(viewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
        />
      </Box>
      <Box
        pt={16}
        p={2}
        style={{
          margin: 0,
          width: "100%",
        }}
      >
        {projectTState?.taskForm?.length > 0 && (
          <Gantt
            tasks={isChecked ? tasks : tasksName}
            viewMode={view}
            onDateChange={handleTaskChange}
            onSelect={handleSelect}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
            todayColor="#d7eef4"
            barCornerRadius={5}
            fontSize={12}
            locale="id"
            rowHeight={35}
            TaskListHeader={({
              headerHeight,
              fontFamily,
              fontSize,
              rowWidth,
            }) => {
              return (
                <TaskListHeader
                  headerHeight={headerHeight}
                  fontFamily={fontFamily}
                  fontSize={fontSize}
                  rowWidth={rowWidth}
                />
              );
            }}
            TaskListTable={({
              tasks,
              onExpanderClick,
              rowHeight,
              rowWidth,
              fontFamily,
              fontSize,
            }) => {
              return (
                <div
                  className={styleTable.taskListWrapper}
                  style={{
                    fontFamily: fontFamily,
                    fontSize: fontSize,
                  }}
                >
                  {tasks.map((task) => {
                    let expanderSymbol = "";
                    if (task.hideChildren === false) {
                      expanderSymbol = "▼";
                    } else if (task.hideChildren === true) {
                      expanderSymbol = "▶";
                    }

                    return (
                      <TaskListTable
                        key={task.id}
                        task={task}
                        onExpanderClick={onExpanderClick}
                        rowHeight={rowHeight}
                        rowWidth={rowWidth}
                        expanderSymbol={expanderSymbol}
                      />
                    );
                  })}
                </div>
              );
            }}
          />
        )}
        {groups?.length > 0 && (
          <GroupedGantt
            classes={classes}
            view={view}
            isChecked={isChecked}
            project={project}
          />
        )}
      </Box>

      <Snackbar
        open={openBar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={6000}
        sev
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert onClose={handleCloseBar} severity="success">
          Berhasil mengubah tanggal
          <Button
            color="secondary"
            size="small"
            onClick={handleUndoTask}
            style={{ textTransform: "capitalize" }}
          >
            {loadUndo ? "Undoing changes.." : "UNDO"}
          </Button>
        </Alert>
      </Snackbar>

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default ViewTimeLine;
