import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RemoveIcon from "@material-ui/icons/Remove";

import { navigate } from "hookrouter";
import moment from "moment";

import DialogError from "../../../../components/DialogError";
import ContextGoal from "../Context/ContextGoalDetail";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import { ToGoal } from "../../../../constants/config-redirect-url";
import handleError from "../../../Report/components/global/handleError";
import DialogWeightGoalDelDetail from "./DialogWeightGoalDelDetail";
import DialogInfoSplitWeight from "../../../../components/DialogInfoSplitWeight";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";

const DialogDeleteDetail = (props) => {
  const {
    classes,
    isModalDeleteGoalMaster,
    setModalDeleteGoalMaster,
    goalDetailState,
  } = props;

  const context = useContext(ContextGoal);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [goalForDelete, setGoalForDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadGoal, setLoadGoal] = useState(false);

  const [openDialogSetWeightGoalDel, setOpenDialogSetWeightGoalDel] =
    useState(false);
  const [openInfoSplitWeight, setOpenInfoSplitWeight] = useState(false);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (isModalDeleteGoalMaster === true) {
      setLoadGoal(true);

      const data = {
        selected_entities: [{ entity_id: goalDetailState.id }],
      };

      // console.log("Multi Delete Create", data);

      AxiosConfig.post(`${URL_API}/swd/goal/recursive-delete/create-form`, data)
        .then((res) => {
          // console.log("RES Goal for Delete", res);
          const result = res.data.data;

          if (res.status === 200) {
            setGoalForDelete(result.fields.entity_list.default_value);
          }
          setLoadGoal(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoadGoal(false);
        });
    }
  }, [isModalDeleteGoalMaster]);

  const handleDelete = () => {
    setLoading(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data = {
      entity_list: goalForDelete,
    };

    AxiosConfig.post(`${URL_API}/swd/goal/recursive-delete/store`, data)
      .then((res) => {
        // console.log("RES Delete", res);

        if (res.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: "user",
              event: "delete",
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ""
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: "",
              device_version: OSName,
              long: "",
              lat: "",
              entity_id: goalForDelete[0]?.id,
              entity_name: goalForDelete[0]?.name,
              remarks: "Delete Goal",
            },
          };

          setDataRemarks(dataLog);

          setOpenRemarksLog(true);

          // context.setFireGoalDetail(true);
          // navigate(ToGoal);
          // setModalDeleteGoalMaster(false);

          // AxiosConfig.get(`${URL_API}/swd/master-period`).then((response) => {
          //   const periods = response.data.data;
          //   const lastPeriod =
          //     periods.length > 0 && periods[periods.length - 1];
          //   const periodId = lastPeriod.id !== undefined ? lastPeriod.id : "";

          //   if (periods.length === 0) {
          //     context.setFireGoalDetail(true);
          //     navigate(ToGoal);
          //     setModalDeleteGoalMaster(false);
          //   } else if (periods.length > 0) {
          //     AxiosConfig.get(
          //       `${URL_API}/swd/goal/weighting/${goalDetailState.owner.id}?period_id=${periodId}`
          //     ).then((res) => {
          //       const result = res.data.data;
          //       // console.log("RES Gg", result);
          //       const goalList = result.fields.goal_id.default_value;

          //       if (goalList.length === 0) {
          //         context.setFireGoalDetail(true);
          //         navigate(ToGoal);
          //         setModalDeleteGoalMaster(false);
          //       } else {
          //         // setOpenDialogSetWeightGoalDel(true);

          //         // FOR MAKE SURE 100
          //         const to100 = (arr) => {
          //           let lists = arr.map((list) => (list === null ? 0 : list));

          //           if (lists.reduce((acc, curr) => acc + curr, 0) <= 100) {
          //             lists[lists.length - 1] += Math.abs(
          //               100 - lists.reduce((acc, curr) => acc + curr, 0)
          //             );
          //           } else if (
          //             lists.reduce((acc, curr) => acc + curr, 0) >= 100
          //           ) {
          //             lists[lists.length - 1] -= Math.abs(
          //               100 - lists.reduce((acc, curr) => acc + curr, 0)
          //             );
          //           }

          //           return lists;
          //         };

          //         // CONDITION TO 100
          //         const toaverage = (arr) => {
          //           const lists = arr.map((list) => Number(list.weighting));
          //           const origin = lists.map((ori) => (ori === null ? 0 : ori));
          //           const is0 = origin.reduce((acc, curr) => acc + curr, 0);
          //           const avg = 100 / arr.length;
          //           const reborn = arr.map((born) => Number(avg.toFixed()));

          //           if (is0 === 0) {
          //             // console.log("REBORN wght", reborn);
          //             return to100(reborn);
          //           } else {
          //             // console.log("ORIGIN wght", origin);
          //             return to100(origin);
          //           }
          //         };

          //         // // console.log("SUM wght", toaverage(result));
          //         // =========== THE ARR WEIGHT AFTER CALCULATION ===========
          //         const ARRAY_WEIGHT = toaverage(goalList);
          //         // =========== THE ARR WEIGHT AFTER CALCULATION ===========

          //         const mapGoal =
          //           goalList.length > 0 &&
          //           goalList.map((el, i) => {
          //             return {
          //               goal_id: el.id,
          //               weight: Number(ARRAY_WEIGHT[i]).toFixed(),
          //             };
          //           });

          //         const data = {
          //           period_id: periodId,
          //           date_start: moment(lastPeriod?.start_date).format(
          //             "YYYY-MM-DD"
          //           ),
          //           date_finish: moment(lastPeriod?.end_date).format(
          //             "YYYY-MM-DD"
          //           ),
          //           entity: "user",
          //           weightings: mapGoal,
          //         };

          //         AxiosConfig.post(
          //           `${URL_API}/swd/goal/weighting/${goalDetailState?.owner?.id}/store`,
          //           data
          //         )
          //           .then((res) => {
          //             // console.log("RES WEIGHT", res);

          //             if (res.status === 200) {
          //               setLoading(false);
          //               // context.setSuccessCreateGoal(
          //               //   Math.floor(Math.random() * 1000 + 1)
          //               // );
          //               //   context.setSuccessCreateGoal(Math.floor(Math.random() * 1000 + 1));
          //               setOpenInfoSplitWeight(true);
          //               // context.setOpenSnackbeer(true);
          //             }
          //           })
          //           .catch((error) => {
          //             // console.log("Error : ", error);
          //             setLoading(false);

          //             // Handle Error
          //             const { listError, dialogErr, resStatus, errorMessage } =
          //               handleError(error);
          //             // Error State
          //             setOpenDialogError(dialogErr);
          //             setTextErrorMessage(errorMessage);
          //             setListError(listError);
          //             setErrorStatus(resStatus);
          //           });
          //       }
          //       setLoading(false);
          //     });
          //   }
          // });
        }
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // ========================================================================
  // ~ Render Tree ~
  // ========================================================================
  const renderTree = (nodes) => (
    <TreeItem key={nodes.entity_id} nodeId={nodes.entity_id} label={nodes.name}>
      {Array.isArray(nodes.sub_goals)
        ? nodes.sub_goals.map((node) => renderTree(node))
        : null}
      {Array.isArray(nodes.ma)
        ? nodes.ma.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  // ========================================================================
  // ~ Main Component ~
  // ========================================================================
  return (
    <Fragment>
      <Dialog
        open={isModalDeleteGoalMaster}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title333}>
            <b>Hapus Goal</b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: 550 }}>
          <Typography
            variant="subtitle1"
            className={classes.title333}
            style={{ textAlign: "center" }}
          >
            Apakah Anda yakin ingin menghapus Goal?
          </Typography>

          <Box pt={2} mb={3} display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ marginBottom: 5 }}
            >
              Goal :
            </Typography>
            {loadGoal && (
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <CircularProgress
                  size={16}
                  style={{ color: "#d64253", marginRight: 5 }}
                />
                <Typography variant="caption" className={classes.title888}>
                  Loading...
                </Typography>
              </Box>
            )}
            {!loadGoal &&
              goalForDelete.length > 0 &&
              goalForDelete.map((item, i) => (
                <TreeView
                  key={i}
                  defaultExpanded={["entity_id"]}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  defaultEndIcon={<RemoveIcon />}
                >
                  {renderTree(item)}
                </TreeView>
              ))}
          </Box>

          <Typography variant="subtitle2" className={classes.title888}>
            Sekali Anda menghapus <i>Goal</i>, maka data <i>Goal Result</i> dan{" "}
            <i>Measure Activity</i> akan hilang !
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: 15,
            marginTop: 20,
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            className={classes.button0}
            onClick={() => setModalDeleteGoalMaster(false)}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            size="medium"
            className={classes.button1}
            disabled={loadGoal}
            onClick={handleDelete}
          >
            {loading ? "Loading..." : "Delete"}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setModalDeleteGoalMaster}
        onTriggerRemarks={context.setFireGoalDetail}
        editRemarks={true}
        origin="goal"
      />

      <DialogWeightGoalDelDetail
        classes={classes}
        openDialogSetWeightGoalDel={openDialogSetWeightGoalDel}
        setOpenDialogSetWeightGoalDel={setOpenDialogSetWeightGoalDel}
        owner={goalDetailState.owner}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogInfoSplitWeight
        openInfoSplitWeight={openInfoSplitWeight}
        setOpenInfoSplitWeight={setOpenInfoSplitWeight}
        onOpenDialog={setModalDeleteGoalMaster}
        onTrigger={context.setFireGoalDetail}
        origin="detail"
      />
    </Fragment>
  );
};

export default DialogDeleteDetail;
