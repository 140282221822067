import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  CircularProgress,
  IconButton,
  Fade,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";

import axios from "axios";
import moment from "moment";
import { URL_API } from "../../../../constants/config-api";

import "moment/locale/id";

const numeral = require("numeral");

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#DA5D6E",
    "&$checked": {
      color: "#d1354a",
    },
    "&$checked + $track": {
      backgroundColor: "#d1354a",
    },
  },
  checked: {},
  track: {},
})(Switch);

const DotLineChartMeasureActivityInDetail = (props) => {
  const { classes, isModalCreateMAResult, isModalDelete, successCreateMA } =
    props;

  const userToken = localStorage.getItem("userToken");
  const maDetail = localStorage.getItem("MA_detail");
  const maDetailAfterParse = JSON.parse(maDetail);

  const [chartType, setChartType] = useState({
    name: "Result (descrete)",
    code: "discrete",
  });
  const [anchorChart, setAnchorChart] = useState(null);
  const [showDate, setShowDate] = useState(true);
  const [loading, setLoading] = useState(false);

  const [dataGoalResult, setDataGoalResult] = useState([]);

  const [collectionGoalResultList, setCollectionGoalResultList] = useState([]);

  useEffect(() => {
    if (isModalCreateMAResult == false || isModalDelete == false) {
      if (userToken !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userToken,
        };

        axios.defaults.headers.common = header;

        setLoading(true);

        //* GOL SELF - ONLY OWNER GOAL USER LOGIN
        axios
          .get(
            `${URL_API}/swd/measured-activity/${maDetailAfterParse.id}/result-value-graph`
          )
          .then(function (resGraph) {
            // // console.log("ResGraph Original Line Chart: ", resGraph);

            if (resGraph.status === 200) {
              if (resGraph.data.data.dataPoints.length > 0) {
                let dataPoints = resGraph.data.data.dataPoints.map((item) => {
                  return {
                    x: item.x,
                    y: item.y,
                    tv: item.tv,
                  };
                });
                setCollectionGoalResultList(dataPoints);
              }
            }
            setLoading(false);
          })
          .catch(function (error) {
            // console.log("Error : ", error.response);
            setLoading(false);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [isModalCreateMAResult, isModalDelete, successCreateMA]);

  useEffect(() => {
    let dataGoalResultLet = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item, i) => {
        let items = {
          // name: moment(item.result_date).format('DD MMMM YYYY'),
          // Pencapaian: parseInt(numeral(item.result_value).format('0[.]00000'))
          name: moment(item.x).format("DD MMM YYYY"),
          Pencapaian: item.y,
          Target: item.tv,
        };

        dataGoalResultLet.push(items);
      });

      setDataGoalResult(dataGoalResultLet);
    }
  }, [collectionGoalResultList]);

  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);

  useEffect(() => {
    let list = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item, i) => {
        list.push(parseInt(item.y));
      });

      // // console.log("List in", list)

      setMax(Math.max(...list));
      setMin(Math.min(...list));
    }
  }, [collectionGoalResultList]);

  const handleCloseAnchorChart = () => {
    setAnchorChart(null);
  };

  const handleShowAnchoeChart = (event) => {
    setAnchorChart(event.currentTarget);
  };

  const handleShowDate = () => {
    setShowDate((prev) => !prev);
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    // // console.log("Payload : ", payload);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          style={{ fontSize: 12 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <Fragment>
      <Box
        mb={2}
        ml={3}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <PurpleSwitch
              checked={showDate}
              onChange={handleShowDate}
              name="showDate"
              size="small"
            />
          }
          style={{ marginRight: 0 }}
          label={
            <Typography variant="caption" className={classes.title}>
              Show Date
            </Typography>
          }
        />
        {(maDetailAfterParse.calculation_method_id === "1" ||
          maDetailAfterParse.calculation_method_id === "3") && (
          <>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Button
              className={classes.btnWhite}
              variant="outlined"
              size="small"
              endIcon={<ArrowDropDownIcon />}
              onClick={handleShowAnchoeChart}
            >
              {chartType.name}
            </Button>
          </>
        )}
        {loading && (
          <Box display="flex" alignItems="center" ml={4}>
            <CircularProgress
              size={14}
              style={{ color: "#d1354a", marginRight: 7 }}
            />
            <Typography variant="caption" className={classes.title888}>
              Loading chart...
            </Typography>
          </Box>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorChart}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
          open={Boolean(anchorChart)}
          onClose={handleCloseAnchorChart}
        >
          <MenuItem
            onClick={() => {
              setAnchorChart(null);
              setChartType({
                name: "Actual (default)",
                code: "actual",
              });
            }}
            style={{ width: 150 }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              Actual (default)
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorChart(null);
              setChartType({
                name: "Result (cumulative)",
                code: "cumulative",
              });
            }}
            style={{ width: 150 }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              Result (cumulative)
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorChart(null);
              setChartType({
                name: "Result (descrete)",
                code: "discrete",
              });
            }}
            style={{ width: 150 }}
          >
            <Typography variant="subtitle2" className={classes.title}>
              Result (descrete)
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      {collectionGoalResultList.length > 0 && (
        <ResponsiveContainer width="98%" height={300}>
          <Fade in={true}>
            <LineChart
              // width={730}
              // height={250}
              data={dataGoalResult}
              margin={{ top: 8, right: 0, left: 24, bottom: 8 }}
            >
              <XAxis
                dataKey={showDate ? "name" : ""}
                height={showDate ? 60 : 5}
                tick={<CustomizedAxisTick />}
              />
              <YAxis
                domain={[min, max]}

                /*
                      ``````````````````````````````````````````````````````````````````````
                      https://stackoverflow.com/questions/50078787/recharts-set-y-axis-range

                      ``````````````````````````````````````````````````````````````````````
                    */
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line type="linear" dataKey="Pencapaian" stroke="#d1354a" />
              <Line type="linear" dataKey="Target" stroke="#00b894" />
              {/* <Area type="monotone" dataKey="pv" stroke="#d1354a" fillOpacity={1} fill="url(#Pencapaian)" /> */}
            </LineChart>
          </Fade>
        </ResponsiveContainer>
      )}
    </Fragment>
  );
};

export default DotLineChartMeasureActivityInDetail;

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  // // console.log("Payload : ", payload);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="white"
        transform="rotate(-35)"
        fontSize={10}
        fontFamily="Roboto"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  if (value > 2500) {
    return (
      <IconButton style={{ backgroundColor: "grey", padding: "6px" }}>
        <i className="material-icons" style={{ color: "#d1354a" }}>
          radio_button_checked
        </i>
      </IconButton>
    );
  }

  return (
    <IconButton style={{ backgroundColor: "white", padding: "6px" }}>
      <i className="material-icons" style={{ color: "green" }}>
        radio_button_checked
      </i>
    </IconButton>
  );
};
