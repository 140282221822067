import {
  Box,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tab,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styles } from "./Style/StyleTimeOffCategoryAllowance";
import { navigate } from "hookrouter";

import ContextTimeOffCategoryAllowance from "./Context/ContextTimeOffCategoryAllowance";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core";

import CategoryIcon from "@material-ui/icons/Category";
import DialogProgress from "./Components/DialogProgress";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";
import EnhancedTableToolbar from "./Components/EnhancedTableToolbar";
import { Button, Table } from "react-bootstrap";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import EnhancedTableHead from "./Components/EnhancedTableHead";
import TableFilter from "./Components/TableFilter";
import ListCardItemUser from "./Components/ListCardItemUser";
import PaginationUser from "./Components/PaginationUser";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewTimeOffCategoryAllowance = (props) => {
  const classes = useStyles();
  const context = useContext(ContextTimeOffCategoryAllowance);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const [loader, setLoader] = useState(false);

  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    getDetailTimeOffCategory();
  }, []);

  const [dataCategory, setDataCategory] = useState({
    id: "",
    name: "",
  });
  // user list
  const [dataUserList, setDataUserList] = useState({
    options: {
      filter: {
        search: "",
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 1,
      },
    },
    list: [],
    listTemp: [],
  });

  const getDetailTimeOffCategory = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/timeoff-category/${props.id}`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        let dataUserListTemporary = dataUserList;
        dataUserListTemporary.list = response.data.data.hasUser;
        dataUserListTemporary.listTemp = response.data.data.hasUser;
        dataUserListTemporary.options.paging.count =
          response.data.data.hasUser.length;
        dataUserListTemporary.options.paging.page = 1;
        setDataUserList({ ...dataUserList });
        let dataCategoryTemporary = dataCategory;
        dataCategoryTemporary.id = response.data.data.id;
        dataCategoryTemporary.name = response.data.data.name;
        setDataCategory({ ...dataCategoryTemporary });
        setLoader(false);
        context.setModalProgress(false);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    let dataTemporary = dataUserList;
    dataTemporary.options.paging.page = newPage;
    dataTemporary.options.paging.limit = dataUserList.options.paging.limit;
    setDataUserList({ ...dataTemporary });
  };

  const handleChangeRowsPerPage = (event) => {
    let dataTemporary = dataUserList;
    dataTemporary.options.paging.page = 1;
    dataUserList.options.paging.limit = parseInt(event.target.value);
    setDataUserList({ ...dataTemporary });
  };

  const handleSaveRemaining = (item) => {
    context.setModalProgress(true);
    setLoader(true);
    let data = {
      LeaveCategory: {
        user_id: item.user_id,
        remaining: item.remaining,
      },
    };
    axiosConfig
      .put(
        `${URL_API}/human-resource/timeoff-category/${props.id}/allowance`,
        data
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        context.setMessages("Data remaining berhasi disimpan");
        context.setSnackbarResponse200(true);
        setLoader(false);
        getDetailTimeOffCategory();
        context.setModalProgress(false);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (error.response.data && error.response.data.info && error.response.data.info.message) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          }
        }
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        <TableFilter
          classes={classes}
          dataCategory={dataCategory}
          dataUserList={dataUserList}
          setDataUserList={setDataUserList}
          getDetailTimeOffCategory={getDetailTimeOffCategory}
          context={context}
          setLoader={setLoader}
        />
        <EnhancedTableHead classes={classes} />

        {context.loader ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={15}
          >
            <CircularProgress
              size={20}
              style={{ marginRight: 10, color: "#d64253" }}
            />
            <Typography className={classes.title555} variant="subtitle1">
              Loading...
            </Typography>
          </Box>
        ) : (
          <Table
            className={classes.table}
            aria-label="enhanced table"
            style={{ marginTop: "16px", paddingRight: "24px" }}
          >
            <TableBody mt={2}>
              {dataUserList.listTemp && 
                dataUserList.listTemp
                .slice(
                  (dataUserList.options.paging.page - 1) *
                    dataUserList.options.paging.limit,
                  (dataUserList.options.paging.page - 1) *
                    dataUserList.options.paging.limit +
                    dataUserList.options.paging.limit
                )
                .map((item, i) => {
                  return (
                    <ListCardItemUser
                      keyItem={i}
                      key={i}
                      classes={classes}
                      item={item}
                      dataUserList={dataUserList}
                      setDataUserList={setDataUserList}
                      handleSaveRemaining={handleSaveRemaining}
                      userTokenState={context.userTokenStateGlobal}
                    />
                  );
                })}
            </TableBody>
          </Table>
        )}

        {dataUserList.list.length === 0 && context.loader !== true && (
          <Box width="100%" style={{ paddingRight: 24 }}>
            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                <IconButton disabled>
                  <EventBusyIcon style={{ color: "#d64253" }} />
                </IconButton>
                <b>Tidak ada User yang dapat di tampilkan saat ini ;(</b>
              </Typography>
            </Paper>
          </Box>
        )}

        <Box ml={1} mr={2}>
          <PaginationUser
            classes={classes}
            dataUserList={dataUserList}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
    </MuiThemeProvider>
  );
};

export default ViewTimeOffCategoryAllowance;
