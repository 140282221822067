import React, { useState } from "react";
import { Paper, Box, Typography, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Notes = ({ classes, remarks, setRemarks }) => {
  const [toggleNote, setToggleNote] = useState(true);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return (
    <Paper className={classes.dataArea}>
      <Box p={2} pt={1} display="flex" alignItems="center">
        <Typography
          variant="subtitile2"
          className={classes.title333}
          style={{ marginRight: 5 }}
        >
          <b>Notes</b>
        </Typography>
        {toggleNote ? (
          <IconButton size="small" onClick={() => setToggleNote(!toggleNote)}>
            <KeyboardArrowUpIcon style={{ color: "#C8203C" }} />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={() => setToggleNote(!toggleNote)}>
            <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
          </IconButton>
        )}
      </Box>

      {toggleNote && (
        <Box p={2} pt={0} height={215}>
          <div>
            <ReactQuill
              theme="snow"
              modules={modules}
              value={remarks}
              onChange={setRemarks}
              style={{ height: 150 }}
            />
          </div>
        </Box>
      )}
    </Paper>
  );
};

export default Notes;
