import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  Avatar,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";

import axios from "axios";

import ImageIcon from "@material-ui/icons/Image";
import SearchIcon from "@material-ui/icons/Search";
import PhoneIcon from "@material-ui/icons/Phone";
import MapIcon from "@material-ui/icons/Place";
import GuideIcon from "@material-ui/icons/GpsFixed";
import MailIcon from "@material-ui/icons/Mail";
// import Tweeter from '@material-ui/icons/Tweeter';

import IconSettingMasterStructure from "../../../../assets/images/Subtraction_27.png";
import GojekLogo from "../../../../assets/images/Image_46.png";
import MapImage from "../../../../assets/images/map.png";

import MahkotaLogoCompany from "../../../../assets/images/Group-862.png";
import Redirect from "../../../../utilities/Redirect";
import {
  ToDashboard,
  ToCompanyProfileSetting,
} from "../../../../constants/config-redirect-url";
import { URL_API } from "../../../../constants/config-api";
import Capitalize from "../../../../utilities/Capitalize";

import { grey, deepPurple, green, cyan } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    // padding: theme.spacing(5, 2),
    // marginTop: theme.spacing(4),
    // width: 575,
    borderRadius: 2,
  },
  paperLocation: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(7),
  },
  paperSecond: {
    marginTop: theme.spacing(-5),
  },
  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },
  buttonDetail: {
    textTransform: "capitalize",
    fontFamily: "Roboto",
    padding: 0,
  },
  avatarMyCompany: {
    // backgroundSize: 'cover',
    // backgroundPosition: 'center center',
    // backgroundRepeat: 'no-repeat',
    height: 120,
    width: 120,
    color: "white",
    backgroundColor: cyan[500],
    marginLeft: theme.spacing(7),
    marginTop: theme.spacing(-7),
    zIndex: 1100,
  },
  titleMyCompanyLogo: {
    fontFamily: "Roboto",
    color: "white",
  },
  title: {
    fontFamily: "Roboto",
  },

  titleVissionMissionLabel: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(7),
    marginTop: theme.spacing(7),
  },
  titleVissionMission: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(7),
    // marginTop: theme.spacing(5),
  },
  titleVissionMissionContent: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(7),
    color: "grey",
    // marginTop: theme.spacing(5),
  },
  titleInformasiKontak: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
    // color: 'grey'
  },
  titleGrey: {
    fontFamily: "Roboto",
    color: "#d0d0d0",
  },
  titlePetunjukArah: {
    fontFamily: "Roboto",
    color: "#204db9",
    fontWeight: "bold",
    marginLeft: theme.spacing(7),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(7),
  },
  iconPreviewSettingGear: {
    // marginTop: 14,
    // marginTop: theme.spacing(1),
    width: 24,
    height: 24,
    cursor: "pointer",
  },
});

const ViewCompanyProfile = (props) => {
  const { classes } = props;

  const userToken = localStorage.getItem("userToken");

  const [userTokenState, setUserTokenState] = useState("");

  const [vissionLabel, setVissionLabel] = useState("");
  const [vission, setVission] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (userToken !== undefined) {
      setUserTokenState(userToken);

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        crossorigin: true,
        crossDomain: true,
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = headers;
      axios

        .get(URL_API + `/group/profile/patch`)
        .then((response) => {
          // console.log("Original response : ", response);

          if (response.status == 200) {
            if (response.data.data !== null) {
              if (response.data.data.fields !== null) {
                /* VISSION & MISSION */
                setVission(response.data.data.fields.vision_and_mission.value);
                setVissionLabel(
                  response.data.data.fields.vision_and_mission.label
                );

                /* COMPANY NAME */
                setCompanyName(response.data.data.fields.name.value);
              }
            }
          }
        })
        .catch((error) => {
          // console.log("Error response : ", error.response);
        });
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Avatar
              alt="My Company Profile"
              // src={GojekLogo}
              className={classes.avatarMyCompany}
            >
              <Typography variant="h4" className={classes.titleMyCompanyLogo}>
                M
              </Typography>
            </Avatar>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paperSecond} elevation={0}>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8} style={{ textAlign: "left" }}>
            <Typography variant="h5" className={classes.title}>
              <b>{companyName !== "" ? companyName : "-"}</b>
              &nbsp; || &nbsp;
              <span
                style={{ fontSize: 14, color: "#d0d0d0", cursor: "pointer" }}
              >
                www.{companyName !== "" ? companyName : "-"}.com
              </span>
            </Typography>
          </Grid>

          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Button
              onClick={() => Redirect(ToCompanyProfileSetting)}
              variant="outlined"
              color="secondary"
              className={classes.buttonDetail}
              style={{ paddingRight: 8 }}
            >
              <IconButton
                // className='introInIconGear'
                onClick={() => Redirect(ToCompanyProfileSetting)}
              >
                <img
                  src={IconSettingMasterStructure}
                  className={classes.iconPreviewSettingGear}
                />
              </IconButton>
              Pengaturan Profil
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={2} style={{ textAlign: "center" }}></Grid>

          <Grid item xs={2} style={{ textAlign: "left" }}>
            <Typography variant="subtitle2" className={classes.titleGrey}>
              <b>Industri</b>
            </Typography>

            <Typography variant="subtitle2" className={classes.title}>
              <b>Industry Retail</b>
            </Typography>
          </Grid>

          <Grid item xs={2} style={{ textAlign: "left" }}>
            <Typography variant="subtitle2" className={classes.titleGrey}>
              <b>Kategori Organisasi</b>
            </Typography>

            <Typography variant="subtitle2" className={classes.title}>
              <b>Baby Startup</b>
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "left" }}>
            <Typography variant="subtitle2" className={classes.titleGrey}>
              <b>Jenis</b>
            </Typography>

            <Typography variant="subtitle2" className={classes.title}>
              <b>Perseroan Terbatas</b>
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}></Grid>
        </Grid>

        <Grid container>
          <Grid item xs={8}>
            <Typography
              variant="h5"
              className={classes.titleVissionMissionLabel}
            >
              <b>{vissionLabel !== "" ? Capitalize(vissionLabel) : "-"}</b>
            </Typography>

            <br />
            <br />

            <Typography
              variant="subtitle1"
              className={classes.titleVissionMissionContent}
            >
              <b>{vission !== "" ? Capitalize(vission) : "-"} </b>
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardHeader
                title="Informasi kontak"
                // subheader="September 14, 2016"
                classes={{
                  title: classes.title,
                }}
                style={{ fontFamily: "Roboto" }}
              />
              <CardMedia
                className={classes.media}
                image={MapImage}
                title="Map Image"
              />
              <CardContent>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MapIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Jl H Icang Rt 008 Rw 003 No.81 Tugu Cimanggis - Depok"
                      classes={{
                        secondary: classes.title,
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary="Dapatkan petunjuk arah"
                      classes={{
                        primary: classes.titlePetunjukArah,
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="comments">
                        <GuideIcon style={{ color: "#204db9" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <SearchIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Berdiri sejak 2010"
                      classes={{
                        secondary: classes.title,
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <PhoneIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="021-877907428"
                      classes={{
                        secondary: classes.title,
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="hai@abangbisa.com"
                      classes={{
                        secondary: classes.title,
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Tweeter"
                      classes={{
                        secondary: classes.title,
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Facebook"
                      classes={{
                        secondary: classes.title,
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      secondary="Instagram"
                      classes={{
                        secondary: classes.title,
                      }}
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewCompanyProfile);
