import React, { useEffect, useContext } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Summary from "./components/live/summary";
import { styles } from "./style/styleReports";
import ContextReports from "./context/ContextReports";

import AxiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import * as constants from './context/constants';
import { parseQueryString, parseCommaToArr } from "../../utilities/UrlHelper";

import "./style/table.css";

const useStyles = makeStyles(styles);

const ViewReports = () => {
  const classes = useStyles();
  const {
    liveSummaryDispatch,
    liveSummaryFilter,
    liveSummaryFilterDispatch,
    liveSummaryDetailDispatch
  } = useContext(ContextReports);

  useEffect(() => {
    AxiosConfig.get(
      `${URL_API}/reporting/live/options`
    ).then((res) => {
      const result = res.data;
      liveSummaryDispatch({
        dataType: constants.LIVE_SUM_OPTIONS,
        payload: result.data
      });
      const queryParams = parseQueryString();

      let updatePayload = result.data.units.reduce((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {});

      liveSummaryFilterDispatch({
        filterType: constants.FILTER_UNIT,
        payload: updatePayload
      });

      AxiosConfig.get(
        `${URL_API}/reporting/live/summary?options[period_id]=${result?.data?.defaultPeriod?.id || ''}`
      ).then((res) => {
        const result = res.data;
        liveSummaryDispatch({
          dataType: constants.LIVE_SUM_RAW_DATA,
          payload: result.data
        });
        
        if (queryParams) {
          if (queryParams.goalStatus) {
            let goalStatus = parseCommaToArr(queryParams.goalStatus);
            liveSummaryFilterDispatch({
              filterType: constants.FILTER_GOAL_ACH_STATUS,
              payload: goalStatus
            });
          }
          if (queryParams.maStatus) {
            let maStatus = parseCommaToArr(queryParams.maStatus);
            liveSummaryFilterDispatch({
              filterType: constants.FILTER_MA_ACH_STATUS,
              payload: maStatus
            });
          }
          if (queryParams.search) {
            let search = queryParams.search;
            liveSummaryFilterDispatch({
              filterType: constants.FILTER_SEARCH,
              payload: search
            });
          }
          if (queryParams.perPage) {
            liveSummaryFilterDispatch({
              filterType: constants.FILTER_PER_PAGE,
              payload: queryParams.perPage
            });
          }
          if (queryParams.page) {
            liveSummaryFilterDispatch({
              filterType: constants.FILTER_PAGE,
              payload: queryParams.page
            });
          }
          if (queryParams.orderByDirection && !queryParams.orderBy) {
            liveSummaryFilterDispatch({
              filterType: liveSummaryFilter.order.field,
              payload: {
                field: liveSummaryFilter.order.field,
                direction: queryParams.orderByDirection
              }
            });
          }
          if (!queryParams.orderByDirection && queryParams.orderBy) {
            liveSummaryFilterDispatch({
              filterType: queryParams.orderBy,
              payload: {
                field: queryParams.orderBy,
                direction: liveSummaryFilter.order.dirction
              }
            });
          }
          if (queryParams.orderByDirection && queryParams.orderBy) {
            liveSummaryFilterDispatch({
              filterType: queryParams.orderBy,
              payload: {
                field: queryParams.orderBy,
                direction: queryParams.orderByDirection
              }
            });
          }
          if (queryParams.viewColumns) {
            liveSummaryFilterDispatch({
              filterType: constants.VIEW_COLUMNS,
              payload: parseCommaToArr(queryParams.viewColumns)
            });
          }
          if (queryParams.id) {
            let queryId = queryParams.id;
            let summaryDetail = result.data.find(row => row.id == queryId);

            if (summaryDetail) {
              liveSummaryDetailDispatch({
                type: constants.LIVE_SUMMARY_DETAIL_ITEM,
                payload: summaryDetail
              });
            }
          }
        } else {
          liveSummaryDispatch({
            dataType: constants.LIVE_SUM_DATA,
            payload: result.data
          });
        }

      });
    });
  }, []);

  return (
    <Box className={classes.root}>
      <Box marginBottom={2} className={classes.layoutMarginLeftAndTop}>
      </Box>

      <div className={classes.dataArea}>
        <Box className={classes.layoutMarginLeft}>
          <Box marginTop={1.5} marginRight={2}>
            <Summary />
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default ViewReports;
