import React, { useContext } from "react";
import { IconButton, TableCell, TableHead, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ContextAttendanceReportAdmin from "../../../Context/ContextAttendanceReportAdmin";

const TableMainHead = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            borderTopLeftRadius: "8.5px",
            color: "#fff",
            fontSize: "14px",
            width: "2%"
          }}
        >
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            width: "13%",
            wordBreak: "break-all"
          }}
        >
          <b>Name</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Unit</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            width: "8%"
          }}
        >
          <b>Date</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Clock-In Date &amp; Time</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Clock-In Status</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Clock-In Remarks</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Clock-Out Date &amp; Time</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Clock-Out Status</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Clock-Out Remarks</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Duration</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          <b>Status</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            borderTopRightRadius: "8.5px",
            color: "#fff",
            fontSize: "14px",
            width: "2%"
          }}
        >
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableMainHead;
