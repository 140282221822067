import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TextField,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Grid,
  Button,
  MenuList,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';

import classNames from 'classnames';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { URL_API } from '../../../../constants/config-api';
import AxiosConfig from '../../../../constants/config-axios';

import handleError from '../../../Report/components/global/handleError';
import DialogError from '../../../../components/DialogError';
import ListItemDropdownCreate from '../../Goal/Components/ListItemDropdownCreate';
import ContextGoalDetail from '../../Goal/Context/ContextGoalDetail';
import DialogRemarksLog from '../../../../components/DialogRemarksLog';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0F68D2'
    }
  }
});

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const DialogEditMAv2 = ({
  classes,
  maData,
  openEditMA,
  setOpenEditMA,
  setTriggerMaAll
}) => {
  const context = useContext(ContextGoalDetail);
  const status_login = localStorage.getItem('status_user_login');
  const user = JSON.parse(status_login);

  const [anchorEl, setAnchorEl] = useState(null);

  const [maName, setMaName] = useState('');
  const [description, setDescription] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [goalId, setGoalId] = useState('');
  const [periodId, setPeriodId] = useState(null);
  const [timeFrame, setTimeFrame] = useState({
    name: 'Pilih waktu',
    code: '',
    id: ''
  });
  const [owner, setOwner] = useState({
    id: '',
    member: { first_name: '', last_name: '' }
  });
  const [period, setPeriod] = useState({ id: '', name: '' });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [startValue, setStartValue] = useState('0');
  const [targetValue, setTargetValue] = useState(null);
  const [targetRangeValue, setTargetRangeValue] = useState(null);
  const [evidence, setEvidence] = useState(false);

  const [achievementType, setAchievementType] = useState({
    code: 'swd_ma_achievement_type_more_oriented',
    id: '1',
    name: 'more oriented'
  });
  const [calculationMethod, setCalculationMethod] = useState({
    code: 'swd_ma_calculation_method_sum',
    id: '1',
    name: 'sum'
  });
  const [targetType, setTargetType] = useState({
    code: 'swd_ma_target_type_daily',
    id: '1',
    name: 'Daily'
  });
  const [valueType, setValueType] = useState({
    code: 'numeric',
    id: '1',
    name: 'numeric'
  });

  //conditional
  const [advanced, setAdvanced] = useState(false);
  const [loading, setLoading] = useState(false);
  //collections
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [timeframeCollections, setTimeframeCollections] = useState([]);
  const [periodCollections, setPeriodCollections] = useState([]);
  const [achievementTypeCollections, setAchievementTypeCollections] = useState(
    []
  );
  const [calculationMethodCollections, setCalculationMethodCollections] =
    useState([]);
  const [targetTypeCollections, setTargetTypeCollections] = useState([]);
  const [valueTypeCollections, setValueTypeCollections] = useState([]);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);
  const [remaksText, setRemarksText] = useState('Update MA');

  // changging goal data
  const [changeName, setChangeName] = useState('');
  const [changeDescription, setChangeDescription] = useState('');
  const [changeStartVal, setChangeStartVal] = useState('');
  const [changeTargetVal, setChangeTargetVal] = useState('');
  const [changeStartDate, setChangeStartDate] = useState('');
  const [changeEndDate, setChangeEndDate] = useState('');
  const [changeIER, setChangeIER] = useState('');
  const [changeAchievementType, setChangeAchievementType] = useState('');
  const [changeValueType, setChangeValueType] = useState('');
  const [changeTargetType, setChangeTargetType] = useState('');
  const [changeCalcMethod, setChangeCalcMethod] = useState('');
  const [changeTimeFrame, setChangeTimeFrame] = useState('');
  const [changeOwner, setChangeOwner] = useState('');

  // ERROR STATE
  const [isOpenSnackbarError, setOpenSnackbarError] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops ada yang salah'
  );
  // Error Individuals
  const [errorName, setErrorName] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorOwner, setErrorOwner] = useState(null);
  const [errorTimeFrame, setErrorTimeFrame] = useState(null);
  const [errorStartDate, setErrorStartDate] = useState(null);
  const [errorEndDate, setErrorEndDate] = useState(null);
  const [errorTargetValue, setErrorTargetValue] = useState(null);
  const [errorTargetRangeValue, setErrorTargetRangeValue] = useState(null);
  const [errorGoal, setErrorGoal] = useState(null);

  useEffect(() => {
    if (openEditMA) {
      setMaName(maData?.name);
      setTargetValue(maData.target_value);
      setStartValue(maData.start_value);

      setDescription(maData?.description);

      setStartDate(maData?.start_date);
      setEndDate(maData?.end_date);
      setOwnerId(maData?.owner_id);

      setAchievementType(maData?.achievementType);
      setCalculationMethod(maData?.calculationMethod);
      setTargetType(maData?.targetType);
      setValueType(maData?.valueType);
      setTargetRangeValue(maData?.target_range_value);
      // setInterval(maData?.)

      setOwner({
        id: maData?.owner_id,
        member: {
          first_name: maData?.owner?.member_first_name,
          last_name: maData?.owner?.member_last_name
        }
      });

      if (maData?.is_evidence_required === 1) {
        setEvidence(true);
      } else {
        setEvidence(false);
      }

      if (maData.time_frame_id !== undefined) {
        if (maData.time_frame_id === '1') {
          setTimeFrame({ name: 'Period', code: 'period', id: '1' });
          setPeriodId(maData?.period_id);
          setPeriod(maData?.period);
        }
        if (maData.time_frame_id === '2') {
          setTimeFrame({ name: 'Date Range', code: 'date_range', id: '2' });
          setPeriod({ id: '', name: '' });
        }
      }

      // console.log("MA DATA", maData);

      AxiosConfig.get(
        `${URL_API}/swd/measured-activity/${maData?.id}/update?goal_id=${maData?.goal_id}`
      )
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Crete MA", result);

          if (res.status === 200) {
            // setOwnerCollections(result.ownerCollections);
            setTimeframeCollections(result.timeframeCollections);
            setPeriodCollections(result.periodCollections);

            setAchievementTypeCollections(result.achievementTypeCollections);
            setCalculationMethodCollections(
              result.calculationMethodCollections
            );
            setTargetTypeCollections(result.targetTypeCollections);
            setValueTypeCollections(result.valueTypeCollections);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openEditMA]);

  useEffect(() => {
    if (openEditMA) {
      AxiosConfig.get(
        `${URL_API}/swd/measured-activity/create?options[filter][owner_id]=${ownerId}`
      )
        .then((res) => {
          const result = res.data.data;

          // console.log("RES GET Goal", result);

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [ownerId]);

  useEffect(() => {
    if (openEditMA) {
      AxiosConfig.get(
        `${URL_API}/swd/measured-activity/create?options[filter][owner_id]=${ownerId}&options[filter][goal_id]=${goalId}`
      )
        .then((res) => {
          const result = res.data.data;

          // console.log("RES GET Period", result);

          if (res.status === 200) {
            setPeriodCollections(result.periodCollections);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [goalId]);

  useEffect(() => {
    let IER = evidence ? 1 : 0;

    if (maData?.name !== maName) {
      setChangeName(maName);
    } else {
      setChangeName('');
    }

    if (maData?.description !== description) {
      setChangeDescription(description);
    } else {
      setChangeDescription('');
    }

    if (Number(maData?.start_value) !== Number(startValue)) {
      setChangeStartVal(startValue);
    } else {
      setChangeStartVal('');
    }

    if (Number(maData?.target_value) !== Number(targetValue)) {
      setChangeTargetVal(targetValue);
    } else {
      setChangeTargetVal('');
    }

    if (maData.start_date !== startDate) {
      setChangeStartDate(startDate);
    } else {
      setChangeStartDate('');
    }

    if (maData.end_date !== endDate) {
      setChangeEndDate(endDate);
    } else {
      setChangeEndDate('');
    }

    if (Number(maData.is_evidence_required) !== IER) {
      setChangeIER(IER);
    } else {
      setChangeIER('');
    }

    if (maData.achievement_type_id !== achievementType.id) {
      setChangeAchievementType(achievementType.id);
    } else {
      setChangeAchievementType('');
    }

    if (maData.value_type_id !== valueType.id) {
      setChangeValueType(valueType.id);
    } else {
      setChangeValueType('');
    }

    if (maData.target_type_id !== targetType.id) {
      setChangeTargetType(targetType.id);
    } else {
      setChangeTargetType('');
    }

    if (maData.calculation_method_id !== calculationMethod.id) {
      setChangeCalcMethod(calculationMethod.id);
    } else {
      setChangeCalcMethod('');
    }

    if (maData.time_frame_id !== timeFrame.id) {
      setChangeTimeFrame(timeFrame.id);
    } else {
      setChangeTimeFrame('');
    }

    if (maData.owner?.member_first_name !== owner?.member?.first_name) {
      setChangeOwner(owner?.member?.first_name);
    } else {
      setChangeOwner('');
    }
  }, [
    maName,
    description,
    startValue,
    targetValue,
    startDate,
    endDate,
    evidence,
    achievementType,
    valueType,
    targetType,
    calculationMethod,
    timeFrame,
    owner
  ]);

  useEffect(() => {
    // let TXT_REMARKS = "";

    let achive_type = maData.achievement_type_id === '1' ? 'More' : 'Less';
    let val_type = maData.value_type_id === '1' ? 'Numeric' : 'Percentage';
    let target_type = maData.target_type_id === '1' ? 'Interval' : 'Final';
    let cacl_method =
      maData.calculation_method_id === '1'
        ? 'Sum'
        : maData.calculation_method_id === '2'
        ? 'Average'
        : 'Last Value';
    let time_frame =
      maData.time_frame_id === '1'
        ? 'Period'
        : maData.time_frame_id === '2'
        ? 'Date Range'
        : 'Due Date';

    let achive_type2 = changeAchievementType === '1' ? 'More' : 'Less';
    let val_type2 = changeValueType === '1' ? 'Numeric' : 'Percentage';
    let target_type2 = changeTargetType === '1' ? 'Interval' : 'Final';
    let cacl_method2 =
      changeCalcMethod === '1'
        ? 'Sum'
        : changeCalcMethod === '2'
        ? 'Average'
        : 'Last Value';
    let time_frame2 =
      maData.time_frame_id === '1'
        ? 'Period'
        : maData.time_frame_id === '2'
        ? 'Date Range'
        : 'Due Date';

    let _1 = `Nama Goal dari ${maData.name} ke ${changeName}`;
    let _2 = `Deskripsi Goal dari ${maData.description} ke ${changeDescription}`;
    let _3 = `Nilai awal dari ${Number(
      maData.start_value
    )} ke ${changeStartVal}`;
    let _4 = `Nilai akhir dari ${Number(
      maData.target_value
    )} ke ${changeTargetVal}`;
    let _5 = `Tanggal Awal dari ${moment(maData.start_date).format(
      'DD-MMM-YYYY'
    )} ke ${moment(changeStartDate).format('DD-MMM-YYYY')}`;
    let _6 = `Tanggal Akhir dari ${moment(maData.end_date).format(
      'DD-MMM-YYYY'
    )} ke ${moment(changeEndDate).format('DD-MMM-YYYY')}`;
    let _7 = `Wajib Bukti dari ${
      maData.is_evidence_required === 1 ? 'Wajib' : 'Tidak Wajib'
    } ke ${changeIER === 0 ? 'Tidak Wajib' : 'Wajib'}`;
    let _8 = `Tipe Pencapaian dari ${achive_type} ke ${achive_type2}`;
    let _9 = `Tipe Nilai dari ${val_type} ke ${val_type2}`;
    let _10 = `Tipe Target dari ${target_type} ke ${target_type2}`;
    let _11 = `Metode Kalkulasi dari ${cacl_method} ke ${cacl_method2}`;
    let _12 = `Rentang Waktu dari ${time_frame} ke ${time_frame2}`;
    let _13 = `Pemilik dari ${maData.owner?.member_first_name} ke ${owner?.member?.first_name}`;

    const arrContent = [_1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13];

    const arrRemarks = [
      changeName,
      changeDescription,
      changeStartVal,
      changeTargetVal,
      changeStartDate,
      changeEndDate,
      changeIER,
      changeAchievementType,
      changeValueType,
      changeTargetType,
      changeCalcMethod,
      changeTimeFrame,
      changeOwner
    ];

    const selectArr = arrRemarks
      .map((item, i) => {
        let content;

        if (item !== '') {
          content = `${arrContent[i]}`;
        }

        return content;
      })
      .filter((el) => el !== undefined);
    // .join(", ");

    const contentRem = `${selectArr.join(', ')} ${selectArr.length}`;

    // // console.log("contentRem", contentRem);
    // // console.log("contentRem.split(' ')", contentRem.split(" ").pop());
    setRemarksText(contentRem);
  }, [
    changeName,
    changeDescription,
    changeStartVal,
    changeTargetVal,
    changeStartDate,
    changeEndDate,
    changeIER,
    changeAchievementType,
    changeValueType,
    changeTargetType,
    changeCalcMethod,
    changeTimeFrame,
    changeOwner
  ]);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setOwnerId(data.id);
      setErrorOwner(null);
      setOwner(data);
    }
    setErrorOwner(null);
  };

  const handleMaName = (e) => {
    setMaName(e.target.value);
    setErrorName(null);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setErrorDescription(null);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setErrorStartDate(null);

    if (moment(date).isBefore(moment(endDate))) {
      setErrorEndDate(null);
    }
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setErrorEndDate(null);

    if (moment(date).isAfter(moment(startDate))) {
      setErrorStartDate(null);
    }
  };

  const handleChoosePeriod = (e, data) => {
    if (data !== null) {
      setPeriodId(data.id);
      setStartDate(data.start_date);
      setEndDate(data.end_date);
    } else {
      setPeriodId(null);
    }
    // // console.log("PER", data);
  };

  const handleTimeFrame = (data) => {
    setTimeFrame(data);
    handleClose();
    setErrorTimeFrame(null);
  };

  const handleTargetValue = (e) => {
    setTargetValue(e.target.value);
    setErrorTargetValue(null);
  };

  const handleTargetRangeValue = (e) => {
    setTargetRangeValue(e.target.value);
    setErrorTargetRangeValue(null);
  };

  const handleAchievementType = (data) => {
    setAchievementType(data);
  };

  const handleCalculationMethod = (data) => {
    setCalculationMethod(data);

    if (data.id === '2') {
      setTargetType({
        code: 'swd_ma_target_type_final',
        id: '2',
        name: 'Final'
      });
    }
  };

  const handleTargetType = (data) => {
    setTargetType(data);
  };

  const handleValueType = (data) => {
    setValueType(data);
  };

  const handleEditMA = () => {
    setLoading(true);

    let device_os = 'Not known';
    if (navigator.appVersion.indexOf('Win') !== -1) device_os = 'Windows OS';
    if (navigator.appVersion.indexOf('Mac') !== -1) device_os = 'Mac OS';
    if (navigator.appVersion.indexOf('X11') !== -1) device_os = 'UNIX OS';
    if (navigator.appVersion.indexOf('Linux') !== -1) device_os = 'Linux OS';

    let OSName = 'Unknown';
    if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1)
      OSName = 'Windows 10';
    if (window.navigator.userAgent.indexOf('Windows NT 6.3') != -1)
      OSName = 'Windows 8.1';
    if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1)
      OSName = 'Windows 8';
    if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1)
      OSName = 'Windows 7';
    if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1)
      OSName = 'Windows Vista';
    if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1)
      OSName = 'Windows XP';
    if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1)
      OSName = 'Windows 2000';
    if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac/iOS';
    if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
    if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';

    let data = {
      MeasuredActivity: {
        name: maName,
        start_value: startValue,
        target_value: targetValue,
        target_range_value: targetRangeValue,
        input_method_id:
          calculationMethod.id === '1'
            ? '2'
            : calculationMethod.id === '2'
            ? '2'
            : '1',
        achievement_type_id: achievementType.id,
        calculation_method_id: calculationMethod.id,
        value_type_id: valueType.id,
        target_type_id: targetType.id,
        time_frame_id: timeFrame !== '' ? timeFrame.id : null,
        period_id: periodId !== null ? periodId : null, //*Mandatory if time_frame_id === 1
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date:
          timeFrame.code === 'date_range'
            ? moment(endDate).format('YYYY-MM-DD')
            : timeFrame.code === 'period'
            ? moment(endDate).format('YYYY-MM-DD')
            : moment(dueDate).format('YYYY-MM-DD'),

        description,
        owner_id: ownerId,
        goal_id: goalId,
        is_evidence_required: evidence ? '1' : '0' //*0 === Tidak butuh evidence, 1 === Butuh evidence
      }
    };

    if (data.MeasuredActivity.period_id === null) {
      delete data.MeasuredActivity.period_id;
    }

    if (
      data.MeasuredActivity.period_id !== null &&
      data.MeasuredActivity.start_date === null &&
      data.MeasuredActivity.end_date === 'Invalid date'
    ) {
      delete data.MeasuredActivity.start_date;
      delete data.MeasuredActivity.end_date;
    }

    if (data.MeasuredActivity.start_date === null) {
      delete data.MeasuredActivity.start_date;
    }

    if (
      data.MeasuredActivity.target_range_value == null ||
      data.MeasuredActivity.target_range_value === ''
    ) {
      delete data.MeasuredActivity.target_range_value;
    }

    if (
      data.MeasuredActivity.period_id === null ||
      data.MeasuredActivity.period_id === ''
    ) {
      delete data.MeasuredActivity.period_id;
    }

    // console.log("Data SUBMIT : ", data);

    AxiosConfig.put(`${URL_API}/swd/measured-activity/${maData?.id}`, data)
      .then((res) => {
        const result = res.data.data;
        //   // console.log("RES crete MA", res);

        if (res.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: 'ma',
              event: 'update',
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ''
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: '',
              device_version: OSName,
              long: '',
              lat: '',
              entity_id: result?.id,
              entity_name: result?.name,
              remarks: remaksText,
              comment: ''
            }
          };

          setDataRemarks(dataLog);

          // setTriggerMaAll(Math.floor(Math.random() * 1000 + 1));
          // setOpenEditMA(false);
          setLoading(false);

          setOpenRemarksLog(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        // // console.log("Error cret Goal", error.response);

        // Handle Error
        const { listError } = handleError(error);

        // // console.log("listError", listError);

        if (listError.length > 0) {
          listError.forEach((err) => {
            // // console.log("ERRRRR", err);

            if (err.key === 'name') {
              setErrorName(err);
            }
            if (err.key === 'owner_id') {
              setErrorOwner(err);
            }
            if (err.key === 'target_value') {
              setErrorTargetValue(err);
            }
            if (err.key === 'target_range_value') {
              setErrorTargetRangeValue(err);
            }
            if (err.key === 'time_frame_id') {
              setErrorTimeFrame(err);
            }
            if (err.key === 'start_date') {
              setErrorStartDate(err);
            }
            if (err.key === 'end_date') {
              setErrorEndDate(err);
            }
            if (err.key === 'goal_id') {
              setErrorGoal(err);
            }
          });
        }

        if (
          [502, 503, 500, 403, 429, 405, 404, 401].includes(
            error.response.status
          )
        ) {
          setOpenDialogError(true);
          setErrorStatus(error.response.status);
          setListError([]);

          if (error.response?.data?.hasOwnProperty('message')) {
            setTextErrorMessage(error.response.data.message);
          }
          if (error.response?.data?.info?.hasOwnProperty('message')) {
            setTextErrorMessage(error.response.data.info.message);
          }
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openEditMA}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: '#fff',
            backgroundSize: 'cover',
            backgroundColor: '#282560'
          }}
        >
          <Typography variant="h4">Ubah Measured Activity</Typography>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600 }}>
          <Box p={1} my={1}>
            <Typography variant="subtitle2" className={classes.title333}>
              Nama Measured Activity
            </Typography>
            <Box my={0.5} />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              placeholder="e.g. Kunjungan cabang..."
              value={maName}
              onChange={handleMaName}
              error={errorName !== null}
              helperText={
                errorName !== null && (
                  <Typography variant="caption" style={{ color: '#d1354a' }}>
                    ⚠️ {errorName.description}
                  </Typography>
                )
              }
            />

            <Box my={2.5} />

            <Typography variant="subtitle2" className={classes.title333}>
              Deskripsi
            </Typography>
            <Box my={0.5} />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder="Masukan deskripsi MA anda"
              value={description}
              onChange={handleDescription}
              error={errorDescription !== null}
              helperText={
                errorDescription !== null && (
                  <Typography variant="caption" style={{ color: '#d1354a' }}>
                    ⚠️ {errorDescription.description}
                  </Typography>
                )
              }
            />

            <Box my={2.5} />

            <Typography variant="subtitle2" className={classes.title333}>
              Pilih Pemilik
            </Typography>
            <Box my={0.5} />
            <Autocomplete
              id="combo-box-demo"
              value={owner}
              options={ownerCollections}
              getOptionLabel={(option) =>
                `${option.member.first_name} ${option.member.last_name}`
              }
              onChange={(e, owner) => handleChooseOwner(e, owner)}
              noOptionsText="Tidak ada pemilik"
              popupIcon={<ArrowDropDownIcon />}
              renderInput={(params) => (
                <TextField
                  style={{ fontSize: 13 }}
                  {...params}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label="Cari pemilik"
                  error={errorOwner !== null}
                  helperText={
                    errorOwner !== null && (
                      <Typography
                        variant="caption"
                        style={{ color: '#d1354a' }}
                      >
                        ⚠️ {errorOwner.description}
                      </Typography>
                    )
                  }
                />
              )}
            />

            <Box my={2.5} />

            <Grid container spacing={2}>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  Jangka Waktu
                </Typography>
                <Box my={0.5} />
                <Box
                  height={38}
                  border={`1.5px solid ${
                    errorTimeFrame === null ? '#e1e1e1' : '#d1354a'
                  }`}
                  borderRadius={5}
                  padding={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    color="#888"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {timeFrame.name}
                  </Box>
                  <IconButton size="small" onClick={handleShow}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </Box>

                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  transition
                  disablePortal
                  placement="bottom"
                  className={classNames({
                    [classes.popperClose]: !anchorEl,
                    [classes.popperResponsive]: true
                  })}
                >
                  {() => (
                    <Grow>
                      <ClickAwayListener onClickAway={handleCloseAway}>
                        <Paper className={classes.dropdown}>
                          <MenuList role="menu" className={classes.menuList}>
                            {timeframeCollections.length > 0 &&
                              timeframeCollections
                                .filter((el) => el.code !== 'due_date')
                                .map((item, i) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      onClick={() => handleTimeFrame(item)}
                                      className={classes.dropdownItem}
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      <Box display="flex" alignItems="center">
                                        {item.code === 'due_date' && (
                                          <EventAvailableIcon
                                            fontSize="small"
                                            style={{
                                              color: '#555',
                                              marginRight: 7
                                            }}
                                          />
                                        )}
                                        {item.code === 'date_range' && (
                                          <DateRangeRoundedIcon
                                            fontSize="small"
                                            style={{
                                              color: '#555',
                                              marginRight: 7
                                            }}
                                          />
                                        )}
                                        {item.code === 'period' && (
                                          <HourglassEmptyRoundedIcon
                                            fontSize="small"
                                            style={{
                                              color: '#555',
                                              marginRight: 7
                                            }}
                                          />
                                        )}
                                        <span style={{ color: '#555' }}>
                                          {' '}
                                          {item.name}
                                        </span>
                                      </Box>
                                    </MenuItem>
                                  );
                                })}
                          </MenuList>
                        </Paper>
                      </ClickAwayListener>
                    </Grow>
                  )}
                </Popper>
              </Grid>
              <Grid item md={timeFrame.code === 'period' ? 8 : 4}>
                {timeFrame.code === 'date_range' && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      Tanggal Mulai
                    </Typography>
                    <Box my={0.5} />
                    <MuiPickersUtilsProvider utils={LocalizedUtils}>
                      <DatePicker
                        autoOk
                        value={startDate}
                        onChange={handleStartDate}
                        animateYearScrolling
                        variant="inline" // dialog, static, inline
                        disableToolbar={false}
                        format="DD/MM/YYYY"
                        placeholder="Pilih Tanggal"
                        inputVariant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton size="small">
                                <DateRangeRoundedIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={errorStartDate !== null}
                      />
                    </MuiPickersUtilsProvider>
                  </>
                )}
                {timeFrame.code === 'due_date' && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      Tenggat Waktu
                    </Typography>
                    <Box my={0.5} />
                    <MuiPickersUtilsProvider utils={LocalizedUtils}>
                      <DatePicker
                        autoOk
                        value={dueDate}
                        onChange={setDueDate}
                        animateYearScrolling
                        variant="inline" // dialog, static, inline
                        disableToolbar={false}
                        format="DD/MM/YYYY"
                        placeholder="Pilih Tanggal"
                        inputVariant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton size="small">
                                <EventAvailableIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </>
                )}
                {timeFrame.code === 'period' && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      Pilih Period
                    </Typography>
                    <Box my={0.5} />
                    <Autocomplete
                      id="combo-box-demo"
                      value={period}
                      options={periodCollections}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(e, period) => handleChoosePeriod(e, period)}
                      noOptionsText="Tidak ada period"
                      popupIcon={<ArrowDropDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          style={{ fontSize: 13 }}
                          {...params}
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Cari Period"
                        />
                      )}
                    />
                  </>
                )}
              </Grid>
              {timeFrame.code !== 'period' && (
                <Grid item md={4}>
                  {timeFrame.code === 'date_range' && (
                    <>
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        Tanggal Akhir
                      </Typography>
                      <Box my={0.5} />
                      <MuiPickersUtilsProvider utils={LocalizedUtils}>
                        <DatePicker
                          autoOk
                          value={endDate}
                          onChange={handleEndDate}
                          animateYearScrolling
                          variant="inline" // dialog, static, inline
                          disableToolbar={false}
                          format="DD/MM/YYYY"
                          placeholder="Pilih Tanggal"
                          inputVariant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton size="small">
                                  <DateRangeRoundedIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={errorEndDate !== null}
                        />
                      </MuiPickersUtilsProvider>
                    </>
                  )}
                </Grid>
              )}
              <Box ml={3}>
                {errorTimeFrame !== null && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: '#d1354a' }}
                  >
                    ⚠️ {errorTimeFrame.description}
                  </Typography>
                )}

                {errorStartDate !== null && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: '#d1354a' }}
                  >
                    ⚠️ {errorStartDate.description}
                  </Typography>
                )}

                {errorEndDate !== null && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: '#d1354a' }}
                  >
                    ⚠️ {errorEndDate.description}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Box my={2.5} />

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="subtitle2" className={classes.title333}>
                  Nilai Awal
                </Typography>
                <Box my={0.5} />
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  type="number"
                  placeholder="Berapa nilai awal anda?"
                  value={startValue}
                  onChange={(e) => setStartValue(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <Typography variant="subtitle2" className={classes.title333}>
                  Nilai Akhir
                </Typography>
                <Box my={0.5} />
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  type="number"
                  placeholder="Berapa nilai akhir anda?"
                  value={targetValue}
                  onChange={handleTargetValue}
                  error={errorTargetValue !== null}
                  helperText={
                    errorTargetValue !== null && (
                      <Typography
                        variant="caption"
                        style={{ color: '#d1354a' }}
                      >
                        ⚠️ {errorTargetValue.description}
                      </Typography>
                    )
                  }
                />
              </Grid>
            </Grid>

            {Number(startValue) === 0 && Number(targetValue) === 0 && (
              <Box my={2.5} />
            )}

            {Number(startValue) === 0 && Number(targetValue) === 0 && (
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    Nilai Jarak Akhir
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    placeholder="Berapa nilai awal anda?"
                    value={targetRangeValue}
                    onChange={handleTargetRangeValue}
                    error={errorTargetRangeValue !== null}
                    helperText={
                      errorTargetRangeValue !== null && (
                        <Typography
                          variant="caption"
                          style={{ color: '#d1354a' }}
                        >
                          ⚠️ {errorTargetRangeValue.description}
                        </Typography>
                      )
                    }
                  />
                </Grid>

                <Grid item md={6}></Grid>
              </Grid>
            )}

            <Box my={2.5} />

            <FormControlLabel
              label={
                <Typography variant="subtitle2" className={classes.title333}>
                  Show Advanced Option
                </Typography>
              }
              control={
                <Checkbox
                  checked={advanced}
                  onChange={() => setAdvanced((prev) => !prev)}
                  checkedIcon={
                    <CheckBoxRoundedIcon
                      style={{ color: '#0F68D2', fontSize: 18 }}
                    />
                  }
                  icon={
                    <CheckBoxOutlineBlankRoundedIcon
                      style={{ color: '#0F68D2', fontSize: 18 }}
                    />
                  }
                />
              }
            />

            {/* ========================== | Advanced Area | ========================== */}

            {advanced && (
              <>
                <Box my={2.5} />

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title="Tipe Pencapaian"
                      collections={achievementTypeCollections}
                      selection={achievementType}
                      onSelect={handleAchievementType}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title="Tipe Nilai"
                      collections={valueTypeCollections}
                      selection={valueType}
                      onSelect={handleValueType}
                    />
                  </Grid>
                </Grid>

                <Box my={2.5} />

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title="Metode Kalkulasi"
                      collections={calculationMethodCollections}
                      selection={calculationMethod}
                      onSelect={handleCalculationMethod}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title="Tipe Target"
                      collections={targetTypeCollections}
                      selection={targetType}
                      onSelect={handleTargetType}
                    />
                  </Grid>
                </Grid>

                <Box my={2.5} />

                <FormControlLabel
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      Wajib Lampirkan Bukti
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={evidence}
                      onChange={() => setEvidence((prev) => !prev)}
                      checkedIcon={
                        <CheckBoxRoundedIcon
                          style={{ color: '#0F68D2', fontSize: 18 }}
                        />
                      }
                      icon={
                        <CheckBoxOutlineBlankRoundedIcon
                          style={{ color: '#0F68D2', fontSize: 18 }}
                        />
                      }
                    />
                  }
                />
              </>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setOpenEditMA(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant={loading ? 'contained' : 'outlined'}
              className={classes.button1}
              onClick={handleEditMA}
            >
              {loading ? 'Mengubah MA...' : 'Ubah'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenEditMA}
        onTriggerRemarks={context.setTriggerLogMa}
        editRemarks={true}
      />
    </ThemeProvider>
  );
};

export default DialogEditMAv2;
