import { createMuiTheme, fade } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d1354a', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: ['Lato', 'Lato'].join(',')
  },
  button: {
    fontFamily: ['Lato', 'Lato'].join(',')
  }
});

export const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  title: {
    fontFamily: 'Lato'
  },
  title333: {
    fontFamily: 'Lato',
    color: '#333'
  },
  title555: {
    fontFamily: 'Lato',
    color: '#555'
  },
  title888: {
    fontFamily: 'Lato',
    color: '#888'
  },
  textPro1: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    fontFamily: 'Lato',
    color: '#888',
    fontSize: 14,
    fontWeight: '600',
    paddingLeft: 15,
    paddingRight: 15
  },
  textPro2: {
    height: theme.spacing(13.5),
    fontFamily: 'Lato',
    color: '#222',
    fontSize: 16,
    fontWeight: '700',
    paddingLeft: 25,
    paddingRight: 25
  },
  button0: {
    textTransform: 'capitalize',
    fontSize: 11,
    fontWeight: '700',
    padding: `3px 6px`,
    color: '#d64253',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    '&:hover': {
      backgroundColor: '#f9e3e6',
      border: '1px solid #d64253'
    }
  },
  button1: {
    textTransform: 'capitalize',
    fontSize: 11,
    fontWeight: '700',
    padding: `3px 6px`,
    color: '#fff',
    backgroundColor: '#d64253',
    '&:hover': {
      backgroundColor: '#DE6775'
    }
  },
  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    background:
      'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
    border: 0,
    fontFamily: 'Lato',
    marginLeft: theme.spacing(1),
    textTransform: 'capitalize',
    color: 'white'
  },
  button2: {
    borderRadius: 5,
    background: '#d1354a',
    border: 0,
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    color: 'white',
    padding: '2px 10px',
    '&:hover': {
      background: '#f28181'
    }
  },
  button3: {
    borderRadius: 5,
    background: '#fff',
    border: '1px solid #d1354a',
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    color: '#d1354a',
    padding: 10,
    '&:hover': {
      color: '#d1354a',
      backgroundColor: '#FCE5E5'
    }
  },
  button4: {
    borderRadius: 5,
    background: '#102770',
    border: 0,
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    color: 'white',
    padding: '2px 10px',
    '&:hover': {
      background: '#273c7e'
    }
  },
  button5: {
    borderRadius: 7,
    backgroundColor: '#F8BFBF',
    color: '#d1354a',
    border: 0,
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    padding: '2px 10px',
    '&:hover': {
      background: '#FBD9D9'
    }
  },
  btnInnactive: {
    borderRadius: 7,
    backgroundColor: '#c7c7c7',
    color: '#808080',
    border: 0,
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    padding: '2px 10px',
    '&:hover': {
      background: '#d7d7d7'
    }
  },
  uploadBtn: {
    fontFamily: "'Lato', sans-serif",
    color: '#00ACE6',
    backgroundColor: 'rgba(0, 172, 230, 0.1)',
    borderRadius: 5,
    padding: '10px, 3px',
    border: 'none',
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'capitalize',
    '&:hover': {
      opacity: 0.9
    }
  },
  buttonDisabled: {
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    color: 'grey',
    fontWeight: 'bold'
  },
  buttonOutlined: {
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    color: '#d64253',
    borderWidth: 0,
    height: 40,
    '&:hover': {
      borderWidth: 0,
      backgroundColor: '#ffcccc'
    }
  },
  buttonOutlined2: {
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    color: '#d64253',
    borderWidth: 0,
    padding: 15,
    '&:hover': {
      borderWidth: 0,
      backgroundColor: '#ffcccc'
    }
  },
  btnExtndBg: {
    backgroundColor: 'transparent',
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    border: 'none'
  },
  btnVoucher: {
    borderRadius: 5,
    background: '#f39c12',
    border: 0,
    color: '#fff',
    fontFamily: 'Lato',
    textTransform: 'capitalize',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#f1c40f'
    }
  },
  txtTrialPaket: {
    color: '#fff',
    backgroundColor: '#102770',
    padding: `3px 2px`,
    fontSize: 15,
    borderRadius: 5,
    fontWeight: 'bold'
  },
  titleWithOpacity: {
    fontFamily: 'Lato',
    opacity: 0.3
  },
  titleListChoose: {
    fontFamily: 'Lato',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  titleListChooseAktif: {
    fontFamily: 'Lato',
    color: '#d6d3d3',
    cursor: 'pointer'
  },

  /*
        ```````
        SEARCH

        ```````
    */
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // borderRadius: '5',
    backgroundColor: fade(theme.palette.common.black, 0.1),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.35)
    },

    // backgroundColor: grey,
    //     '&:hover': {
    //         backgroundColor: green,
    //     },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
  },
  inputRoot: {
    color: 'inherit',
    fontFamily: 'Lato'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    },
    color: 'grey'
    // color: '#cc0707'
  },

  /*
        ````````````````
        width TEXT-FIELD
        `````````````````
    */

  listSettings: {
    borderBottom: '1px solid #eee',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  settingItem: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  /*
        `````````````````````````````
        FOTO REAL USER NAME, USERNAME

        `````````````````````````````
    */
  userRealFoto: {
    // margin: 10,
    width: 48,
    height: 48,
    borderRadius: 24
  },
  imageAvatar: {
    width: 50,
    height: 40
  },
  userRealFotoInCardItemGoal: {
    // margin: 10,
    width: 32,
    height: 32,
    borderRadius: 16
  },
  superBigRealFoto: {
    // margin: 10,
    width: 128,
    height: 128,
    borderRadius: 64
  },

  /*
        ``````````````````````````````````````````````
        HANDLE TAB COMPANY PROFILE AND PENGATURAN USER

        ``````````````````````````````````````````````
    */

  outlinedTabActive: {
    borderBottom: `3px solid #d64253`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: '1px 2px',
    borderRadius: 3
  },

  outlinedTabNotActive: {
    borderBottom: `3px solid transparent`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: '1px 2px',
    borderRadius: 3
  },

  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto'
    // margin: 12
  },
  noBorderBottom: {
    borderBottom: 'none'
  },

  /*
        ````````````````````````
        TOOLBAR PAGINATION TABLE

        ````````````````````````
    */

  toolbar: {
    backgroundColor: 'transparent'
  },

  linearProgress: {
    height: 2
  },
  warningPasswordBg: {
    backgroundColor: '#fff1d6',
    borderRadius: 5,
    width: 575
  },
  warningPasswordTxt: {
    color: '#ffc95f',
    fontFamily: 'Lato',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    paddingLeft: 15,
    fontSize: 14
  },

  // ========================================
  // Styled Checkbox
  // ========================================
  checkboxRoot: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 3,
    width: 20,
    height: 20,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(243, 156, 18, .6)', // rgba(209, 53, 74, .6)
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#f39c12', // #d1354a
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#f39c12' // #d1354a
    }
  },

  // ====================
  // MANAGE MEMBERSHIP
  // ====================
  boxDetail: {
    backgroundColor: '#E8ECF0',
    borderRadius: 7,
    padding: `15px 20px`
    // width: 800,
  },
  txtUsed1: {
    lineHeight: 2.5,
    fontFamily: 'Lato',
    color: '#555',
    fontWeight: '600',
    width: 170
  },
  txtUsed2: {
    lineHeight: 2.5,
    fontFamily: 'Lato',
    color: '#555',
    fontWeight: '600',
    width: 100
  },
  txtUsed3: {
    lineHeight: 2.5,
    fontFamily: 'Lato',
    color: '#555',
    fontWeight: '600',
    width: 130
  },
  txtUsed: {
    lineHeight: 2.5,
    fontFamily: 'Lato',
    color: '#555',
    fontWeight: '600'
  },
  linkCancel: {
    fontFamily: 'Lato',
    '&:hover': {
      cursor: 'pointer',
      color: '#da5464'
    }
  },
  txtDeployTitel: {
    color: '#333',
    fontFamily: 'Lato',
    '&:hover': {
      cursor: 'pointer',
      color: '#555'
    }
  },
  txtSummary: {
    color: '#555',
    marginBottom: 5,
    fontFamily: 'Lato'
  },
  badgePaket: {
    backgroundColor: '#102770',
    borderRadius: 5,
    padding: 2,
    fontSize: 14,
    color: '#fff'
  },
  boxDesc: {
    backgroundColor: '#E8ECF0',
    borderRadius: 5,
    padding: 10
  },
  // =========
  // CARD EXTEND MEMBERSHIP
  // ========
  // ========================================
  // Style For Checkout Register
  cardExtend: {
    display: 'flex',
    backgroundColor: '#102770',
    borderRadius: 20
  },
  cardImgEx: {
    position: 'relative',
    textAlign: 'center',
    color: '#fff'
  },
  textBadge: {
    color: '#fff',
    backgroundColor: '#eb4d4b',
    fontFamily: 'Lato',
    width: 95,
    borderBottomRightRadius: 40,
    borderTopLeftRadius: 7.5,
    padding: `2px 10px`,
    fontWeight: '600',
    position: 'absolute'
  },
  txtLabelCard: {
    left: 40,
    position: 'absolute',
    top: 38,
    fontFamily: 'Lato'
  },
  txtLabelCard1: {
    left: 30,
    position: 'absolute',
    top: 38,
    fontFamily: 'Lato'
  },
  txtCartItem: {
    color: '#fff',
    fontFamily: 'Lato'
  },
  paymentCard: {
    padding: `10px 20px`,
    minWidth: 55,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  // =============================
  // MEBERSHIP STYLES
  // =============================

  cardPackage: {
    width: 325,
    backgroundColor: '#102770',
    borderRadius: 7.5,
    '&:hover': {
      backgroundColor: '#003780',
      cursor: 'pointer'
    }
  },
  cardPlain: {
    width: 325,
    borderRadius: 7.5,
    border: `0.5px solid`,
    borderColor: '#ddd',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      cursor: 'pointer'
    }
  },
  txtTitle: {
    color: '#fff',
    backgroundColor: '#eb4d4b',
    fontFamily: 'Lato',
    fontSize: 16,
    width: 95,
    borderBottomRightRadius: 40,
    borderTopLeftRadius: 7.5,
    padding: `2px 10px`,
    fontWeight: '600'
  },
  txtTitlePlain: {
    color: '#333',
    fontFamily: 'Lato',
    fontSize: 16,
    padding: `2px 10px`,
    fontWeight: '600'
  },
  txtPrice: {
    color: '#d63031',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    padding: `0 10px`,
    margin: `25px 0 5px 0`,
    fontSize: 25
  },
  txtDetail: {
    color: '#e58e26',
    fontSize: 13,
    fontFamily: 'Lato',
    padding: `0 10px`,
    marginBottom: 25
  },
  listBenefits: {
    padding: `0 10px`,
    marginBottom: 25
  },
  benefitText: {
    color: '#fff',
    fontSize: 13,
    fontFamily: 'Lato'
  },
  benefitTextPlain: {
    color: '#555',
    fontSize: 13,
    fontFamily: 'Lato'
  },
  extendPckgBg: {
    border: `1.2px solid #ff9e0c`,
    backgroundColor: '#fff5e6',
    borderRadius: 3
  },

  dashedBtn: {
    borderStyle: 'dashed',
    borderRadius: 5,
    borderColor: '#aaa',
    borderWidth: 2,
    paddingTop: 7,
    paddingBottom: 7,
    height: 22,
    width: 250,
    '&:hover': {
      borderColor: '#ccc',
      color: '#888',
      cursor: 'pointer'
    }
  },
  txtDashedBtn: {
    color: '#333',
    fontFamily: 'Lato',
    margin: 0,
    paddingTop: 5,
    fontSize: 13,
    '&:hover': {
      color: '#888',
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },

  // CARD CHOOSEN PACKAGE
  cardCheckout: {
    display: 'flex',
    backgroundColor: '#102770',
    borderRadius: 20
  },
  cardImgCont: {
    position: 'relative',
    textAlign: 'center',
    color: '#fff'
  },
  textBadge: {
    color: '#fff',
    backgroundColor: '#eb4d4b',
    fontFamily: 'Lato',
    width: 95,
    borderBottomRightRadius: 40,
    borderTopLeftRadius: 7.5,
    padding: `2px 10px`,
    fontWeight: '600',
    position: 'absolute'
  },
  txtCenterCard: {
    left: 52,
    position: 'absolute',
    top: 52,
    fontFamily: 'Lato'
  },
  txtCenterCard1: {
    left: 30,
    position: 'absolute',
    top: 52,
    fontFamily: 'Lato'
  },
  countdownBox: {
    border: `1px solid #ffbb34`,
    width: 600,
    borderRadius: 15
  },
  countdownTitle: {
    fontFamily: 'Lato',
    color: '#fff',
    background: '#ffbb34',
    padding: 20,
    fontSize: 26,
    fontWeight: 'bold',
    width: 170,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 15
  },
  countdownText: {
    fontFamily: 'Lato',
    padding: 20,
    fontSize: 14,
    color: '#555'
  },
  txtVA: {
    fontFamily: 'Lato',
    color: '#555',
    marginBottom: 5
  },
  txtGuide: {
    fontFamily: 'Lato',
    color: '#555',
    fontSize: 13,
    lineHeight: 2
  },
  listGuild: {
    paddingLeft: 16
  },
  extendPckgBg: {
    border: `1.2px solid #ff9e0c`,
    backgroundColor: '#fff5e6',
    borderRadius: 3
  },

  // ============== Errror Voucher
  voucherErr: {
    listStyle: 'none',
    paddingLeft: 0
  },
  voucherErrItem: {
    color: '#eb4d4b',
    fontFamily: 'Lato',
    fontSize: 13
  },

  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: 'center',
    padding: 15,
    border: `2px dashed #DFDFDF`,
    color: '#808080',

    marginBottom: 20,
    marginTop: 10,

    '&:focus': {
      color: 'transparent',
      backgroundColor: 'transparent'
    }
  }
});
