import React, { useState, Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  DialogContent,
  Button,
  CircularProgress,
} from "@material-ui/core";
import DialogSuccesContactUs from "./DialogSuccesContactUs";
import { URL_API } from "../../../../constants/config-api";
import axios from "axios";
import DialogError from "../../../../components/DialogError";

function DialogContactUs({ isModalContactUs, setModalContactUs, classes }) {
  const [loading, setLoading] = useState(false);

  const [isSuccessContactUs, setSuccessContactUs] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const tokenUser = localStorage.getItem("userToken");

  const handleSubmitContactUs = () => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + tokenUser,
    };

    axios.defaults.headers.common = header;

    setLoading(true);
    axios
      .post(`${URL_API}/account-management/membership:contact-us`)
      .then((response) => {
        if (response.status === 200) {
          setSuccessContactUs(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("Error Send Contact Us : ", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Fragment>
      <Dialog
        open={isModalContactUs}
        onClose={() => setModalContactUs(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box style={{ textAlign: "center" }}>
            <Typography variant="h6" className={classes.title}>
              <b>Berlangganan</b>
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center", marginTop: 15 }}>
          <Typography
            variant="subtitle2"
            className={classes.textPro1}
            style={{ marginBottom: 15, marginTop: 0 }}
          >
            Kami akan menghubungi anda melalui Nomor handphone/WhatsApp atau
            melalui Email yang anda daftarkan sebelumnya.
          </Typography>
        </DialogContent>

        <DialogActions>
          {loading ? (
            <Button
              variant="contained"
              className={classes.buttonKirim}
              fullWidth
              disabled
            >
              <CircularProgress size={18} />
            </Button>
          ) : (
            <Button
              onClick={handleSubmitContactUs}
              variant="contained"
              className={classes.buttonKirim}
              fullWidth
              // disabled
            >
              Hubungi
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <DialogSuccesContactUs
        isSuccessContactUs={isSuccessContactUs}
        setSuccessContactUs={setSuccessContactUs}
        classes={classes}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default DialogContactUs;
