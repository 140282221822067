import React from "react";
import { Box, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PersonIcon from "@material-ui/icons/Person";

import { styles } from "../Style/StyleNewSO";

const useStyles = makeStyles(styles);

function AvatarIcon(props) {
  const classes = useStyles();

  return (
    <Box className={classes.horizontalLine} style={{ marginLeft: 16 }}>
      <Avatar className={classes.small}>
        <PersonIcon style={{ fontSize: 17 }} />
      </Avatar>
    </Box>
  );
}

export default AvatarIcon;
