import React, { useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { useTable, useExpanded } from "react-table";
import moment from "moment";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const DetailContent = ({ classes, item }) => {
  const DateCell = ({ value }) => (
    <span>{moment(value).format("DD MMM YYYY")}</span>
  );

  const EntityCell = ({ value }) => {
    const entity = value === "goal" ? "Goal" : "Measured Activity";

    return <span>{entity}</span>;
  };

  const OraCell = ({ value }) => {
    const ora = Number(value) * 100;
    return <span>{`${FormatDecimal(ora)}%`}</span>;
  };

  const loopData = (nodes) => {
    let theRows = [];

    if (Array.isArray(nodes.sub_goals) && Array.isArray(nodes.ma)) {
      theRows = [...nodes.sub_goals, ...nodes.ma];
    } else if (Array.isArray(nodes.sub_goals)) {
      theRows = [...nodes.sub_goals];
    } else if (Array.isArray(nodes.ma)) {
      theRows = [...nodes.ma];
    }

    return {
      name: nodes.name,
      owner_name: nodes.owner_name,
      entity_type: nodes.entity_type,
      start_date: nodes.start_date,
      end_date: nodes.end_date,
      overall_result_achievement: nodes.overall_result_achievement,
      subRows: theRows.length > 0 ? theRows.map((node) => loopData(node)) : [],
    };
  };

  const DATA = item.details.map((el) => {
    return loopData(el);
  });

  const columns = useMemo(
    () => [
      {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  // We can even use the row.depth property
                  // and paddingLeft to indicate the depth
                  // of the row
                  paddingLeft: `${row.depth * 2}rem`,
                },
              })}>
              {row.isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </span>
          ) : null,
      },
      {
        Header: "Nama file",
        accessor: "name",
      },
      {
        Header: "Owner",
        accessor: "owner_name",
      },
      {
        Header: "Entity type",
        accessor: "entity_type",
        Cell: EntityCell,
      },
      {
        Header: "Start date",
        accessor: "start_date",
        Cell: DateCell,
      },
      {
        Header: "End date",
        accessor: "end_date",
        Cell: DateCell,
      },
      {
        Header: "Achievement",
        accessor: "overall_result_achievement",
        Cell: OraCell,
      },
    ],
    []
  );

  const data = useMemo(() => DATA, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  );

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table" {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <TableCell {...column.getHeaderProps()}>
                    <b>{column.render("Header")}</b>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()} {...row.subRows}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailContent;
