import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import clsx from "clsx";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import { SUBJECT_CATEGORY_ID_MEMBER_USER } from "../../../../constants/config-drive";
import ContextGlobalDrive from "../../context/ContextDrive";

import DialogError from "../../../../components/DialogError";

function DialogSetPermission({
  classes,
  item,
  openDialogSetPermission,
  setOpenDialogSetPermission,
}) {
  const context = useContext(ContextGlobalDrive);
  const pathName = window.location.pathname;

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [loading, setLoading] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [userCollections, setUserCollections] = useState([]);
  const [masterRoleCollections, setMasterRoleCollections] = useState([]);

  const [subjectCategoryCollections, setSubjectCategoryCollections] = useState(
    []
  );
  const [userId, setUserId] = useState("");
  const [status403, setStatus403] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
    LifeCycle Hook
  ==================================================== */
  useEffect(() => {
    if (openDialogSetPermission) {
      const pathArray = pathName.split("/");
      const getLastPathArr = pathArray[pathArray.length - 1];

      setFolderId(getLastPathArr);

      setLoading(true);

      axiosConfig
        .get(`${URL_API}/drive/folder/${getLastPathArr}/permission/create`)
        .then((response) => {
          // console.log("Permission Create", response);

          const result = response.data.data;

          if (response.status === 200) {
            setSubjectCategoryCollections(
              result.masterSubjectCategoryCollections.filter(
                (item) => item.id === SUBJECT_CATEGORY_ID_MEMBER_USER
              )
            );
            setUserCollections(result.userCollections);
            setMasterRoleCollections(result.masterRoleCollections);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);

          if (error.response.status) {
            setStatus403(true);
          }
        });
    }
  }, [openDialogSetPermission]);

  /* ====================================================
    LOCAL Functions / Methods
  ==================================================== */

  // Choose Member User
  const handleChooseUser = (e, user) => {
    // // console.log("USERO", user);

    if (user !== null) {
      setUserId(user.id);
    }
  };

  const handleSetPermission = () => {
    const data = {
      DriveFolderPermission: {
        subject_category_id: subjectCategoryCollections[0].id,
        role_id: masterRoleCollections[0].id,
        subject_id: userId,
      },
    };

    setLoadingPermission(true);

    // // console.log("DATA SET PERM", data);

    axiosConfig
      .post(`${URL_API}/drive/folder/${folderId}/permission`, data)
      .then((response) => {
        // console.log("Response Set Persmission", response);

        if (response.status === 200) {
          context.setSuccessTriggerPermission(
            Math.floor(Math.random() * 1000 + 1)
          );

          setOpenDialogSetPermission(false);
        }
        setLoadingPermission(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoadingPermission(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogSetPermission}
      onClose={() => setOpenDialogSetPermission(false)}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Set Permisson Member User</b>
        </Typography>
      </DialogTitle>

      <DialogContent
        style={{
          textAlign: "left",
          width: 375,
          marginBottom: 20,
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <Fragment>
          <Box textAlign="center" marginBottom={2}>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#333" }}
            >
              Pilih user yang akan dijadikan <i>Member User</i> untuk folder{" "}
              <b>{item.name}</b>
            </Typography>
          </Box>

          <Box>
            <Autocomplete
              id="combo-box-demo"
              options={userCollections}
              getOptionLabel={(option) =>
                `${option.member.first_name} ${option.member.last_name}`
              }
              className={classes.title}
              noOptionsText="No options"
              loading={loading}
              onChange={(e, user) => handleChooseUser(e, user)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  style={{ fontSize: 12 }}
                  className={classes.title}
                  label="Search Owner"
                  variant="standard"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </Fragment>
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: "center",
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        <Button
          variant="contained"
          className={classes.button}
          style={{ width: 200 }}
          disabled={status403}
          onClick={handleSetPermission}
        >
          {loadingPermission ? (
            <CircularProgress color="#fff" size={15} />
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogSetPermission;
