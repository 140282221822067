import React, { useState } from "react";
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Box,
  Tooltip,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import ViewQuiltRoundedIcon from "@material-ui/icons/ViewQuiltRounded";

import classNames from "classnames";

import DialogCreateTask from "../dialogs/DialogCreateTask";
import DialogNewTaskGroup from "../dialogs/DialogNewTaskGroup";
import DialogImportProject from "../dialogs/DialogImportProject";

import iconNewGroup from "../../../../assets/images/icon_new_group.png";
import iconImport from "../../../../assets/images/icon_import.png";
import DialogCustomPhase from "../dialogs/DialogCustomPhase";

const AnchorTaskList = ({ classes, project }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCreateTask, setOpenCreateTask] = useState(false);
  const [openImportProject, setOpenImportProject] = useState(false);
  const [openNewTaskGroup, setOpenNewTaskGroup] = useState(false);
  const [openCustomPhase, setOpenCustomPhase] = useState(false);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleNewTaskGroup = () => {
    setOpenNewTaskGroup(true);
    handleClose();
  };

  const handleImportProject = () => {
    setOpenImportProject(true);
    handleClose();
  };

  const handleCustomPhase = () => {
    setOpenCustomPhase(true);
    handleClose();
  };

  return (
    <div>
      <ButtonGroup variant="outlined">
        <Tooltip title="Add New Task">
          <Button
            size="small"
            className={classes.button1}
            startIcon={<AddIcon />}
            onClick={() => setOpenCreateTask(true)}
          >
            Task
          </Button>
        </Tooltip>
        <Tooltip title="More Actions">
          <Button
            size="small"
            className={classes.button1}
            style={{ padding: 0 }}
            onClick={handleShow}
          >
            <ArrowDropDownIcon />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-end"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
        style={{ zIndex: 1000 }}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    onClick={handleNewTaskGroup}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        src={iconNewGroup}
                        height={14}
                        style={{ marginRight: 7 }}
                      />
                      <span style={{ color: "#555" }}>New Task Group</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleImportProject}
                  >
                    <Box display="flex" alignItems="center">
                      <img
                        src={iconImport}
                        height={14}
                        style={{ marginRight: 7 }}
                      />
                      <span style={{ color: "#555" }}>Import Project Task</span>
                    </Box>
                  </MenuItem>
                  {/* <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleCustomPhase}
                  >
                    <Box display="flex" alignItems="center">
                      <ViewQuiltRoundedIcon
                        style={{ color: "#999", fontSize: 15, marginRight: 7 }}
                      />
                      <span style={{ color: "#555" }}>Custom Phase</span>
                    </Box>
                  </MenuItem> */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogCreateTask
        classes={classes}
        openCreateTask={openCreateTask}
        setOpenCreateTask={setOpenCreateTask}
      />

      <DialogNewTaskGroup
        classes={classes}
        openNewTaskGroup={openNewTaskGroup}
        setOpenNewTaskGroup={setOpenNewTaskGroup}
      />
      <DialogImportProject
        classes={classes}
        project={project}
        openImportProject={openImportProject}
        setOpenImportProject={setOpenImportProject}
      />
      <DialogCustomPhase
        classes={classes}
        openCustomPhase={openCustomPhase}
        setOpenCustomPhase={setOpenCustomPhase}
      />
    </div>
  );
};

export default AnchorTaskList;
