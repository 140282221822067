import React, { Fragment } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";

const CardFifthTextfieldDescription = (props) => {
  const {
    classes,
    i,

    isShowDescription,
    handleChangeDescription,
    valueDescriptionNow,
  } = props;

  return (
    <Fragment key={i}>
      {/* 
                ```````````````````
                DESKRIPSI COMPONENT

                ```````````````````
            */}
      {isShowDescription === true && (
        <Grid container>
          <Grid item md={12}>
            <TextField
              onChange={(e) => handleChangeDescription(e, i)}
              value={valueDescriptionNow[i]}
              style={{ marginTop: 0 }}
              id="outlined-multiline-static-description"
              label={
                <Typography variant="subtitle1" className={classes.title}>
                  Tulis Deskripsi di sini.
                </Typography>
              }
              multiline
              rows="4"
              // defaultValue="Default Value"
              // className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              inputProps={{
                "aria-label": "Start Value",
                className: classes.title,
              }}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default CardFifthTextfieldDescription;
