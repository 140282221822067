import React, { useState, useContext, useEffect } from "react";
import { Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ContextGoalDetail from "../../Goal/Context/ContextGoal";

const AnchorFilterPeriod = ({ classes, periodCollection, onPeriodData }) => {
  const context = useContext(ContextGoalDetail);

  const handleChoosePeriod = (e, data) => {
    // // console.log("Period data", data);
    if (data !== null) {
      onPeriodData(data);
      context.setPeriod(data);
    } else {
      onPeriodData({ id: "", name: "" });
      context.setPeriod({ id: "", name: "" });
    }
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  return (
    <Box minWidth={200}>
      <Autocomplete
        id="combo-box-demo"
        options={periodCollection}
        getOptionLabel={(option) => `${option.name}`}
        onChange={(e, period) => handleChoosePeriod(e, period)}
        noOptionsText="Tidak ada period"
        popupIcon={<ArrowDropDownIcon />}
        renderInput={(params) => (
          <TextField
            style={{ fontSize: 13 }}
            {...params}
            // fullWidth
            variant="outlined"
            size="small"
            label="Cari Period"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        )}
      />
    </Box>
  );
};

export default AnchorFilterPeriod;
