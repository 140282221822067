import uuidv4 from "uuidv4";

export const classificationTopLeader =
  "structure_position_classification_id_top_leader";
export const classificationHead = "structure_position_classification_id_head";

const realData = [
  {
    id: null,
    parent_id: null,
    masterStructurePositionTitle: {
      name: null,
    },
    classification: {
      id: null,
    },
    masterStructureUnit: {
      name: null,
    },
    masterStructureUnitType: {
      name: null,
    },
  },
];

const orgCharts = [
  {
    id: uuidv4(),
    name: "CEO",
    children: [
      {
        id: uuidv4(),
        name: "HRGA Manager",
        jenisUnit: "Divisi",
        namaUnit: "HRD",
        children: [],
      },

      {
        id: uuidv4(),
        name: "Finance Manager",
        jenisUnit: "Divisi",
        namaUnit: "Finance",
        children: [],
      },
      {
        id: uuidv4(),
        name: "Marketing Manager",
        jenisUnit: "Divisi",
        namaUnit: "Marketing",
        children: [],
      },

      {
        id: uuidv4(),
        name: "Sales Manager",
        jenisUnit: "Divisi",
        namaUnit: "Sales",
        children: [],
      },
    ],
  },
];
