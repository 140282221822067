import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import pdf_icon from "../../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../../assets/images/icons_file/Text.png";

import TruncateTextCustom from "../../../../../utilities/TruncateTextCustom";
import DialogDeleteAttachment from "../dialog/DialogDeleteAttachment";
import FormatBytes from "../../../../../utilities/FormatBytes";
import DialogPreviewAttachment from "../dialog/DialogPreviewAttachment";
import DialogPreviewVideo from "../dialog/DialogPreviewVideo";
import DialogPreviewPDF from "../dialog/DialogPreviewPDF";
import DialogPreviewDoc from "../dialog/DialogPreviewDoc";
import DialogPreviewText from "../dialog/DialogPreviewText";

const ListItemAttachment = ({
  classes,
  file,
  setTriggerAttachment,
  completed,
}) => {
  let icon_file = null;
  const type_doc =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const type_ppt =
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  const type_excel =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [image, setImage] = useState("");
  const [openDeleteAttachment, setOpenDeleteAttachment] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  useEffect(() => {
    // setLoading(true);
    AxiosConfig.get(
      `${URL_API}/todolist/${file.todolist_id}/attachment/${file.id}?mode=thumbnail`,
      {
        responseType: "blob",
      }
    )
      .then((response) => {
        // // console.log("Response Thumbnail", response);

        if (response.status === 200) {
          setImage(response.data);
        }
        // setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        // setLoading(false);
      });
  }, []);

  const handlePreviewFile = () => {
    const imageType = file.mime_type.includes("image");
    const videoType = file.mime_type.includes("video");
    const pdfType = file.mime_type.includes("pdf");
    const textType = file.mime_type.includes("text");
    const docType =
      file.mime_type === "application/msword" ||
      file.mime_type === "application/vnd.ms-powerpoint" ||
      file.mime_type === "application/vnd.ms-excel" ||
      file.mime_type === type_doc ||
      file.mime_type === type_ppt ||
      file.mime_type === type_excel;

    if (imageType) setOpenAttachment(true);
    if (videoType) setOpenVideo(true);
    if (pdfType) setOpenPdf(true);
    if (textType) setOpenText(true);
    if (docType) setOpenDoc(true);
  };

  switch (file.mime_type) {
    case "application/pdf":
      icon_file = pdf_icon;
      break;
    case "application/msword":
      icon_file = word_icon;
      break;
    case type_doc:
      icon_file = word_icon;
      break;
    case "application/vnd.ms-powerpoint":
      icon_file = ppt_icon;
      break;
    case type_ppt:
      icon_file = ppt_icon;
      break;
    case "application/vnd.ms-excel":
      icon_file = excel_icon;
      break;
    case type_excel:
      icon_file = excel_icon;
      break;
    case "text/plain":
      icon_file = text_icon;
      break;
    default:
      icon_file = null;
  }

  const blobUrlImage = new Blob([image], { type: file.mime_type });

  return (
    <>
      <Box
        display="flex"
        mb={1.5}
        p={2}
        borderRadius={10}
        bgcolor="#F8F8FB"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          {file.mime_type.includes("image") ? (
            <img
              src={image !== "" && URL.createObjectURL(blobUrlImage)}
              width={25}
            />
          ) : file.mime_type.includes("video") ? (
            <img src={video_icon} width={25} />
          ) : icon_file !== null ? (
            <img src={icon_file} width={25} />
          ) : (
            <InsertDriveFileIcon style={{ fontSize: 40, color: "#2d98da" }} />
          )}
          <Box ml={2}>
            <Typography
              className={classes.titleAttachment}
              variant="subtitle1"
              onClick={handlePreviewFile}
            >
              {TruncateTextCustom(file.label, 48)}
            </Typography>
            <Typography className={classes.title555} variant="subtitle2">
              {FormatBytes(file.size)}
            </Typography>
          </Box>
        </Box>

        <Box display="flex">
          <IconButton
            onClick={() => setOpenDeleteAttachment(true)}
            disabled={completed}
          >
            <DeleteIcon style={{ fontSize: 20, color: "#B1B1B1" }} />
          </IconButton>
        </Box>
      </Box>
      <DialogDeleteAttachment
        classes={classes}
        file={file}
        openDeleteAttachment={openDeleteAttachment}
        setOpenDeleteAttachment={setOpenDeleteAttachment}
        setTriggerAttachment={setTriggerAttachment}
      />

      <DialogPreviewAttachment
        openAttachment={openAttachment}
        setOpenAttachment={setOpenAttachment}
        file={file}
      />
      <DialogPreviewVideo
        openVideo={openVideo}
        setOpenVideo={setOpenVideo}
        file={file}
      />
      <DialogPreviewPDF openPdf={openPdf} setOpenPdf={setOpenPdf} file={file} />
      <DialogPreviewDoc openDoc={openDoc} setOpenDoc={setOpenDoc} file={file} />
      <DialogPreviewText
        openText={openText}
        setOpenText={setOpenText}
        file={file}
      />
    </>
  );
};

export default ListItemAttachment;
