import React, { Fragment, useContext, useState } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import BlockIcon from "@material-ui/icons/Block";

import DialogRemoveUser from "./DialogRemoveUser";
import ContextGlobalDrive from "../../context/ContextDrive";

function ListMemberUser({ item, classes }) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [openDialogRemoveUser, setOpenDialogRemoveUser] = useState(false);

  return (
    <Fragment>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ width: 30, height: 30 }}>
            {item.subject !== null ? (
              <PersonIcon fontSize="inherit" />
            ) : (
              <BlockIcon fontSize="inherit" />
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Tooltip
              title={
                context.detailFolder.id === item.object_id ? "" : "Inherited"
              }>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{
                  color:
                    context.detailFolder.id !== item.object_id ||
                    item.subject === null
                      ? "#999"
                      : "#333",
                }}>
                {item.subject !== null
                  ? `${item.subject.member.first_name} ${item.subject.member.last_name}`
                  : "User has been deleted"}
              </Typography>
            </Tooltip>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            size="small"
            disabled={context.detailFolder.id !== item.object_id ? true : false}
            onClick={() => setOpenDialogRemoveUser(true)}>
            <RemoveCircleOutlineIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <DialogRemoveUser
        item={item}
        classes={classes}
        openDialogRemoveUser={openDialogRemoveUser}
        setOpenDialogRemoveUser={setOpenDialogRemoveUser}
      />
    </Fragment>
  );
}

export default ListMemberUser;
