import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  IconButton,
  Collapse,
} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/ArchiveOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";

import DetailContent from "./DetailContent";

const ListItemArchiveGroup = ({ classes, item }) => {
  let archiveByUser =
    item.archived_by_user !== null ? item.archived_by_user : "Not Set";
  let archiveBySo =
    item.archived_by_so !== null ? item.archived_by_so : "Not Set";

  const [openDetails, setOpenDetails] = useState(false);

  const handleOpenDetail = () => setOpenDetails(!openDetails);

  //Archiving In Progress...

  return (
    <Paper elevation={2} className={classes.cardArchiveUser}>
      <Box className={classes.cardHead}>
        {item.status_id === "0" && (
          <Box pl={3}>
            <Typography variant="caption" style={{ color: "#d1354a" }}>
              Archiving in progress...
            </Typography>
          </Box>
        )}
        <Box
          p={3}
          pt={item.status_id === "0" ? 0 : 3}
          display="flex"
          justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            width={200}
            className={classes.showDetail}
            onClick={item.status_id === "1" && handleOpenDetail}>
            <ArchiveIcon
              style={{
                fontSize: 30,
                color: "#555",
                marginRight: 10,
                opacity: item.status_id === "0" && 0.7,
              }}
            />
            <Typography
              variant="subtitle1"
              className={classes.title555}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              <b>{item.name}</b>
            </Typography>
          </Box>

          <Box display="flex" justifyContent="flex-start" width={200}>
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              Archived By :
            </Typography>
            <Box ml={2}>
              <Typography
                variant="subtitle2"
                className={classes.title333}
                style={{ opacity: item.status_id === "0" && 0.7 }}>
                <b>{archiveByUser}</b>
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title888}
                style={{ opacity: item.status_id === "0" && 0.7 }}>
                {archiveBySo}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              Archived On :
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              {moment(item.archived_at).format("DD MMM YYYY")}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              User Count :
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              {item.owner_totals}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              Goal Count :
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              {item.goal_totals}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              MA Count :
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title333}
              style={{ opacity: item.status_id === "0" && 0.7 }}>
              {item.ma_totals}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" style={{ zIndex: 1000 }}>
            <IconButton>
              <MoreVertIcon
                style={{ opacity: item.status_id === "0" && 0.7 }}
              />
            </IconButton>
          </Box>
        </Box>

        <Collapse in={openDetails} timeout="auto" unmountOnExit>
          <Box p={3}>
            <DetailContent item={item} />
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

export default ListItemArchiveGroup;
