import React, { useCallback, useEffect, useState, useContext } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  Table,
  Icon,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  TableHead,
  Switch,
} from "@material-ui/core";

import axios from "axios";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PictDefaultEmpty from "../../../../assets/images/Group_2232.png";

import { URL_API } from "../../../../constants/config-api";
import Redirect from "../../../../utilities/Redirect";
import Capitalize from "../../../../utilities/Capitalize";
import {
  ToRole,
  ToCompanyProfile,
} from "../../../../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    borderRadius: 2,
    width: "100%",
    marginTop: theme.spacing(1),
  },
  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },
  buttonDisabled: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    background: "grey",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },
  buttonUbah: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(4),
    textTransform: "capitalize",
    color: "white",
  },
  buttonHapusRole: {
    borderRadius: 5,
    // background: 'linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(4),
    textTransform: "capitalize",
    color: "grey",
  },
  buttonModalCancel: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  title: {
    fontFamily: "Roboto",
  },
  titleBackDaftarRole: {
    fontFamily: "Roboto",
    color: "#cc0707",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    // cursor: 'pointer'
  },
  titleNameRole: {
    fontFamily: "Roboto",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(4),
  },
  titleLabelNamaRole: {
    fontFamily: "Roboto",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
  },
  titleLabelSwitch: {
    fontFamily: "Roboto",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  titleBerikanKetentuan: {
    fontFamily: "Roboto",
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(4),
  },
  textField: {
    minWidth: 425,
    marginLeft: theme.spacing(4),
  },
  switch: {
    // marginLeft: theme.spacing(4)
  },

  // iconTitleBackDaftarRole: {
  //   color: '#cc0707',
  //   cursor: 'pointer'
  // }

  /*
    `````````````````````````````
    DIALOG MODAL TAMBAH HAK AKSES
    
    `````````````````````````````
  */

  dropDown: {
    minWidth: 300,
    width: 425,
  },
  paperStyleSelected: {
    borderRadius: 7,
    marginTop: theme.spacing(2),
    cursor: "pointer",
    backgroundColor: "#d13b4a",
    color: "white",
  },
  paperStyle: {
    // backgroundColor: 'grey',
    borderRadius: 7,
    marginTop: theme.spacing(2),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#d13b4a",
      color: "white",
    },
  },
  titlePaperStyle: {
    fontFamily: "Roboto",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  /*

    ````````````````````
    TABLE LIST HAK AKSES
    
    `````````````````````

  */

  rootPaperTable: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },

  tablePaper: {
    minWidth: 1024,
  },

  iconEditTable: {
    color: "#cc0707",
  },
  iconDeleteTable: {
    color: "#cc0707",
  },
  iconDisabled: {
    color: "grey",
  },
  titleModulTable: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(3),
  },
});

const ViewCompanyProfileSetting = (props) => {
  const { classes } = props;

  /*

    ````
    TAB

    ````

  */

  const [tabIndex, setTabIndex] = useState(1);

  function handleTab(tabIndex) {
    // setUpdatedAt()
    // // console.log("Updated At : ", updatedAtAfterClickTab);

    setTabIndex(tabIndex);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Paper className={classes.root} elevation={0}>
        <Grid
          container
          spacing={10}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item sm={12}>
            <Typography variant="h6" className={classes.titleBackDaftarRole}>
              <IconButton onClick={() => Redirect(ToCompanyProfile)}>
                <i
                  className="material-icons"
                  style={{ color: "#cc0707", cursor: "pointer" }}
                >
                  keyboard_backspace
                </i>
              </IconButton>
              <b>Kembali ke Profil</b>
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <br />
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => handleTab(tabIndex)}
        style={{ backgroundColor: "white" }}
      >
        <TabList>
          <Tab>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Tentang</b>
            </Typography>
          </Tab>
          <Tab>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Lokasi</b>
            </Typography>
          </Tab>
          <Tab>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Sosial Media</b>
            </Typography>
          </Tab>
          <Tab>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Kontak</b>
            </Typography>
          </Tab>
          <Tab>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Finansial</b>
            </Typography>
          </Tab>
        </TabList>

        <TabPanel>
          <br />
          <br />
          <Typography variant="h1" className={classes.titleLabelNamaRole}>
            <b>Tentang</b>
          </Typography>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </TabPanel>

        <TabPanel>
          <br />
          <br />
          <br />
          <Typography variant="h1" className={classes.titleLabelNamaRole}>
            <b>Lokasi</b>
          </Typography>

          {/* 

              ``````````````
              LIST HAK AKSES
              
              ``````````````
            
          */}

          <br />
          <br />
          <br />
          <br />
        </TabPanel>
        <TabPanel>
          <br />
          <br />
          <br />
          <Typography variant="h1" className={classes.titleLabelNamaRole}>
            <b>Sosial Media</b>
          </Typography>

          {/* 

              ``````````````
              LIST HAK AKSES
              
              ``````````````
            
          */}

          <br />
          <br />
          <br />
          <br />
        </TabPanel>
        <TabPanel>
          <br />
          <br />
          <br />
          <Typography variant="h1" className={classes.titleLabelNamaRole}>
            <b>Kontak</b>
          </Typography>

          {/* 

              ``````````````
              LIST HAK AKSES
              
              ``````````````
            
          */}

          <br />
          <br />
          <br />
          <br />
        </TabPanel>

        <TabPanel>
          <br />
          <br />
          <br />
          <Typography variant="h1" className={classes.titleLabelNamaRole}>
            <b>Finansial</b>
          </Typography>

          {/* 

              ``````````````
              LIST HAK AKSES
              
              ``````````````
            
          */}

          <br />
          <br />
          <br />
          <br />
        </TabPanel>
      </Tabs>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewCompanyProfileSetting);
