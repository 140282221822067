import React, { useState, Fragment, useEffect, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RemoveIcon from "@material-ui/icons/Remove";

import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";

import DialogError from "../../../../components/DialogError";
import ContextGoal from "../Context/ContextGoal";
import handleError from "../../../Report/components/global/handleError";
import { navigate } from "hookrouter";
import { ToGoal } from "../../../../constants/config-redirect-url";

function DialogDeleteMultiple({
  isOpenDialogDeleteMultiple,
  setOpenDialogDeleteMultiple,
  selected,
  classes,
  // checkValidDelete,
}) {
  const context = useContext(ContextGoal);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const listGoal =
    context.collectionGoalList.length > 0
      ? context.collectionGoalList.filter((goal) => selected.includes(goal.id))
      : [];

  const [goalForDelete, setGoalForDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadGoal, setLoadGoal] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ========================================================================
  // ~ Side Effect ~
  // ========================================================================
  useEffect(() => {
    if (isOpenDialogDeleteMultiple === true) {
      setLoadGoal(true);

      const mapedGoal = listGoal.map((el) => {
        return {
          entity_id: el.id,
        };
      });

      const data = {
        selected_entities: mapedGoal,
      };

      // console.log("Multi Delete Create", data);

      AxiosConfig.post(`${URL_API}/swd/goal/recursive-delete/create-form`, data)
        .then((res) => {
          // console.log("RES Goal for Delete", res);
          const result = res.data.data;

          if (res.status === 200) {
            setGoalForDelete(result.fields.entity_list.default_value);
          }
          setLoadGoal(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoadGoal(false);
        });
    }
  }, [isOpenDialogDeleteMultiple]);

  // ========================================================================
  // ~ Handle Delete Multiple ~
  // ========================================================================
  const handleDeleteMultiple = () => {
    setLoading(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data = {
      entity_list: goalForDelete,
    };

    AxiosConfig.post(`${URL_API}/swd/goal/recursive-delete/store`, data)
      .then((res) => {
        // console.log("RES Delete", res);

        if (res.status === 200) {
          // const dataLog = {
          //   user_activity_log: {
          //     module: "user",
          //     event: "delete",
          //     user_id: user?.user_id,
          //     user_email: user?.user_email,
          //     nama: `${user?.member_first_name} ${user?.member_last_name}`,
          //     position:
          //       user?.member_position?.structure_position_title_name ===
          //       undefined
          //         ? ""
          //         : user?.member_position?.structure_position_title_name,
          //     device_type: device_os,
          //     device_model: "",
          //     device_version: OSName,
          //     long: "",
          //     lat: "",
          //     entity_id: goalForDelete[0]?.id,
          //     entity_name: goalForDelete[0]?.name,
          //     remarks: "Delete Goal",
          //   },
          // };

          context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
          context.setOpenSnackbeer(true);
          navigate(ToGoal);
          setOpenDialogDeleteMultiple(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // ========================================================================
  // ~ Render Tree ~
  // ========================================================================
  const renderTree = (nodes) => (
    <TreeItem key={nodes.entity_id} nodeId={nodes.entity_id} label={nodes.name}>
      {Array.isArray(nodes.sub_goals)
        ? nodes.sub_goals.map((node) => renderTree(node))
        : null}
      {Array.isArray(nodes.ma)
        ? nodes.ma.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  // ========================================================================
  // ~ Main Component ~
  // ========================================================================
  return (
    <Fragment>
      <Dialog
        maxWidth="md"
        open={isOpenDialogDeleteMultiple}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title333}>
            <b>Hapus Beberapa Goal</b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ width: 550 }}>
          <Typography
            variant="subtitle1"
            className={classes.title333}
            style={{ textAlign: "center" }}
          >
            Apakah Anda yakin ingin menghapus Goal?
          </Typography>

          <Box pt={2} mb={3} display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ marginBottom: 5 }}
            >
              Goal :
            </Typography>
            {loadGoal && (
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <CircularProgress
                  size={16}
                  style={{ color: "#d64253", marginRight: 5 }}
                />
                <Typography variant="caption" className={classes.title888}>
                  Loading...
                </Typography>
              </Box>
            )}
            {!loadGoal &&
              goalForDelete.length > 0 &&
              goalForDelete.map((item, i) => (
                <TreeView
                  key={i}
                  defaultExpanded={["entity_id"]}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  defaultEndIcon={<RemoveIcon />}
                >
                  {renderTree(item)}
                </TreeView>
              ))}
          </Box>

          <Typography variant="subtitle2" className={classes.title888}>
            Sekali Anda menghapus <i>Goal</i>, maka data <i>Goal Result</i> dan{" "}
            <i>Measure Activity</i> akan hilang !
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: 15,
            marginTop: 20,
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            className={classes.button0}
            onClick={() => setOpenDialogDeleteMultiple(false)}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            size="medium"
            className={classes.button1}
            disabled={loadGoal}
            onClick={handleDeleteMultiple}
          >
            {loading ? "Loading..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      {/* <DialogFeedbackDeleteGoal
        classes={classes}
        openDialogFeedbackDelete={openDialogFeedbackDelete}
        setOpenDialogFeedbackDelete={setOpenDialogFeedbackDelete}
        responseDelete={responseDelete}
        listGoal={listGoal}
      /> */}
    </Fragment>
  );
}

export default DialogDeleteMultiple;
