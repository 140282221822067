import { createMuiTheme, fade } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Nunito"].join(","),
  },
});

export const styles = () => ({
  title: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
  },
  title333: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
    color: "#333",
  },
  title555: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
    color: "#555",
  },
  title888: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
    color: "#888",
  },
  color888: {
    color: "#888",
  },
  btnTxt: {
    textTransform: "capitalize",
    color: "#d64253",
    fontSize: 14,
    "&:hover": {
      color: "#DE6775",
      cursor: "pointer",
    },
  },
  btnLink: {
    border: "none",
    backgroundColor: "transparent",
    textTransform: "capitalize",
    padding: `3px 6px`,
    color: "#d64253",
    fontSize: 14,
    "&:hover": {
      color: "#DE6775",
      cursor: "pointer",
    },
  },
  button0: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  txtViewMore: {
    color: "#888",
    textDecoration: "underline",
    "&:hover": {
      color: "#aaa",
      cursor: "pointer",
    },
  },
  txtPeriod: {
    color: "#4aa9fb",
    textTransform: "capitalize",
    backgroundColor: "#edf6ff",
    padding: "2px 7px",
  },

  // ========================================
  // Styled Checkbox
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3.5,
    width: 15,
    height: 15,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d1354a", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 15,
      height: 15,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a", // #106ba3
    },
  },

  // ===========================
  shadowSection: {
    borderRadius: 8.5,
    boxShadow: "0px 9px 10px -4px rgba(0,0,0,0.09)",
    border: "1px solid rgba(134, 124, 124, 0.18)",
  },
  cardList: {
    borderRadius: 10,
    boxShadow: `0px 4px 6px rgba(134, 124, 124, 0.18)`,
  },
});
