import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import PictInfo from "../../../../assets/images/icon-info-24px.svg";

const DialogModal409 = (props) => {
  const {
    classes,
    isResponse409,
    setResponse409,
    infoResponseError409,
  } = props;

  return (
    <Dialog
      open={isResponse409}
      onClose={() => setResponse409(false)}
      aria-labelledby="alert-dialog-title-422"
      aria-describedby="alert-dialog-description-422">
      <DialogTitle id="alert-dialog-title-422" style={{ textAlign: "center" }}>
        <img
          src={PictInfo}
          alt="info-icon-pict-info"
          className={classes.media}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description-422">
          <Typography variant="h6">
            {infoResponseError409 !== ""
              ? infoResponseError409
              : "Whoops, something went wrong !"}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={() => setResponse409(false)}
          color="primary"
          size="small"
          className={classes.buttonModal}>
          Hubungi IT Administrator !
        </Button>
      </DialogActions>
      <br />
      <br />
    </Dialog>
  );
};

export default DialogModal409;
