import React, { useEffect, useState, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Chip,
  Tooltip,
  Button
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PeopleIcon from '@material-ui/icons/People';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import numeral from 'numeral';
import { navigate, A } from 'hookrouter';

import { green } from '@material-ui/core/colors';
import { theme, styles } from './style/StyleAllMA';
import axiosConfig from '../../../constants/config-axios';
import { URL_API } from '../../../constants/config-api';
import ContextAllMA from './context/AllMaContext';
/**
 ==================================================================================
 ~~ ALL IMPORT MODULE COMPONENT GOES HERE ~~
 ==================================================================================
 */
import {
  StyledMenuItem,
  StyledMenu
} from '../../../components/StyledMenuDropdown';
import PictLitleIconMA from '../MeasureActivities/Components/PictLitleIconMA';
import Capitalize from '../../../utilities/Capitalize';
import CircleMoreDetailInPercentMATemporary from '../MeasureActivities/Components/CircleMoreDetailInPercentMATemporary';
import BarDominantGreenScenarioFIRST from '../MeasureActivities/Components/BarDominantGreenScenarioFIRST';
import BarDominantGreenScenarioSECOND from '../MeasureActivities/Components/BarDominantGreenScenarioSECOND';
import BarDominantGreenScenarioTHIRD from '../MeasureActivities/Components/BarDominantGreenScenarioTHIRD';
import BarDominantRedScenarioFOURTH from '../MeasureActivities/Components/BarDominantRedScenarioFOURTH';
import BarDominantRedScenarioFOURTH_partB from '../MeasureActivities/Components/BarDominantRedScenarioFOURTH_partB';
import BarDominantRedScenarioFIFTH from '../MeasureActivities/Components/BarDominantRedScenarioFIFTH';
import BarDominantGreenScenarioSEVEN from '../MeasureActivities/Components/BarDominantGreenScenarioSEVEN';
import BarDominantGreenScenarioByZERO from '../MeasureActivities/Components/BarDominantGreenScenarioByZERO';

import DialogSetAsComplete from './components/DialogSetAsComplete';
import DialogCreateAllMAResult from './ComponentsMAResult/DialogCreateAllMAResult';
import DialogDeleteMA from './components/DialogDeleteMA';

import {
  STATUS_OVERDUE,
  STATUS_COMPLETED
} from '../../../constants/config-status-goal';
// FROM ASSETS
import PictResultValue from '../../../assets/images/Group_2620.png';
import PictGAP from '../../../assets/images/Group_2619.png';
import PictDone from '../../../assets/images/Group_2614.png';
import TextOverDotLineChart from '../Goal/Components/TextOverDotLineChart';
import TextOverDotLineChartSecond from '../MeasureActivities/Components/TextOverDotLineChartSecond';
import TextOverDotLineChartThird from '../MeasureActivities/Components/TextOverDotLineChartThird';
import DotLineChartMeasureActivityInDetail from './components/DotLineChartMeasureActivityInDetail';
import ListCollectioAllnMAResult from './ComponentsMAResult/ListCollectionAllMAResult';
import { ToAllMA } from '../../../constants/config-redirect-url';
import { FormatDecimal, FormatFloat } from '../../../utilities/FormatDecimal';
import FormatTitleCase from '../../../utilities/FormatTitleCase';
import DialogConfirmWeight from '../MeasureActivities/Components/DialogConfirmWeight';
import DialogEditMAv2 from './components/DialogEditMA';

/**
 ==================================================================================
 ~~ ViewAllMAMoreDetail || C O M P O N E N T || ~~
 ==================================================================================
 */
function ViewAllMAMoreDetail() {
  /**
 ==================================================================================
 ~~ ALL IMPORT VARIABLE GOES HERE ~~
 ==================================================================================
 */
  const context = useContext(ContextAllMA);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const currentLoc = window.location.pathname;
  const statusUserLogin = localStorage.getItem('status_user_login');
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === 'superadmin'
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  const {
    fotoQuery,
    memberPositionList,
    isMeasuredActivityDetailShow,
    setMeasuredActivityDetailShow,
    userTokenStateGlobal,
    collectionMaList,
    setCollectionMaList,
    collectionMAResultList,
    setCollectionMAResultList,
    isModalCreateMAResult,
    setModalCreateMAResult,
    loader,
    setLoader
  } = context;

  /**
 ==================================================================================
 ~~ ALL LOCAL STATE GOES HERE ~~
 ==================================================================================
 */
  const [growPaper, setGrowPaper] = useState(false);
  const [maDetailState, setMaDetailState] = useState(detailMaAll);

  //   STATE USER QUERY / POSITION
  const [fotoQueryContent, setFotoQueryContent] = useState('');
  const [positionName, setPositionName] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nameOfMA, setNameOfMA] = useState('');
  const [description, setDescription] = useState('');

  const [structureUnitTypeName, setStructureUnitTypeName] = useState('');
  const [structureUnitName, setStructureUnitName] = useState('');

  const [statusMeasuredActivity, setStatusMeasuredActivity] = useState('');
  const [startValue, setStartValue] = useState('');
  const [targetValue, setTargetValue] = useState('');

  const [actualValue, setActualValue] = useState('');
  const [actualValueAchievement, setActualValueAchievement] = useState('');

  const [resultValue, setResultValue] = useState('');
  // const [ resultValuePercent, setResultValuePercent ] = useState('');
  const [targetType, setTargetType] = useState('');

  const [gapValue, setGapValue] = useState('');
  const [gapValuePercent, setGapValuePercent] = useState('');

  const [posisiAmanActual, setPosisiAmanActual] = useState('');
  const [posisiAmanAchievement, setPosisiAmanAchievement] = useState('');
  const [sisaWaktu, setSisaWaktu] = useState('');

  //   BAR CHART MA
  const [resultValueAchievement, setResultValueAchievement] = useState(null);
  const [expectedValueAchievement, setExpectedValueAchievement] = useState(
    null
  );

  // STATUS MA
  // const [statusOVERDUE, setStatusOVERDUE] = useState(false);
  const [statusCOMPLETE, setStatusCOMPLETE] = useState(false);

  //   STATE DIALOG SET AS COMPLETE
  const [isOpenDialogSetAsComplete, setOpenDialogSetAsComplete] = useState(
    false
  );

  //   SATTE DROPDOWN ELEMENT
  const [anchorEl, setAnchorEl] = useState(null);
  // STATE DIALOG MODAL EDIT MASTER
  const [isModalEditMAMaster, setModalEditMAMaster] = useState(false);
  // STATE DIALOG MODAL EDIT MA
  const [isModalDeleteMA, setModalDeleteMA] = useState(false);
  // STATE DIALOG MODAL EDIT
  const [isModalEdit, setModalEdit] = useState(false);
  // STATE DIALOG MODAL CREATE MA RESULT
  // const [modalCreateMAResult, setModalCreateMAResult] = useState(false);

  // DROPDOWN GOAL RESULT LIST
  const [maResultId, setMaResultId] = useState('');
  const [maResultNameDescription, setMaResultNameDescription] = useState('');
  const [maResultDataDetail, setMaResultDataDetail] = useState('');
  const [anchorElListMaResult, setAnchorElListMaResult] = useState(null);

  // STATE PERIOD TIME_FRAME
  const [timeFramePeriod, setTimeFramePeriod] = useState(false);
  const [timeFrameDateRange, setTimeFrameDateRange] = useState(false);
  const [timeFrameDueDate, setTimeFrameDueDate] = useState(false);
  const [periodName, setPeriodName] = useState('');

  const [startDatePojokKanan, setStartDatePojokKanan] = useState(null);
  const [endDatePojokKanan, setEndDatePojokKanan] = useState(null);

  // Goal Detail For LS
  const [goalDetail, setGoalDetail] = useState(null);

  const [TriggerMaAll, setTriggerMaAll] = useState(null);

  // GET MA Id
  const [maID, setMaID] = useState(null);
  const locationPathMaId = currentLoc.split('/')[2];

  // CONFIRM WEIGHT
  const [openDialogConfirmWeight, setOpenDialogConfirmWeight] = useState(false);

  // edit ma v2
  const [openEditMA, setOpenEditMA] = useState(false);

  /**
 ==================================================================================
 ~~ ALL SIDE EFFECTS HOOKS GOES HERE ~~
 ==================================================================================
 */

  useEffect(() => {
    const pathArray = currentLoc.split('/');
    const getMaId = pathArray[pathArray.length - 1];

    if (getMaId !== undefined && getMaId !== '') {
      setMaID(getMaId);
    }
  }, [currentLoc]);

  //   Load MA Detail
  useEffect(() => {
    setGrowPaper(true);

    /*
      ===================================================
      HANDLE POSITION TITLE NAME WHEN ROLE IS SUPERADMIN 
      ===================================================               
    */

    axiosConfig
      .get(
        `${URL_API}/swd/measured-activity/${
          maID !== null ? maID : locationPathMaId
        }`
      )
      .then(function (response) {
        // console.log("Response Original ALL MA DETAIL : ", response);
        const result = response.data.data;

        if (response.status === 200) {
          setMaDetailState(response.data.data);

          // Set Structur Position Title Name
          if (statusUserLoginAfterParse !== null) {
            if (
              statusUserLoginAfterParse.userRole !== null ||
              statusUserLoginAfterParse.userRole !== undefined
            ) {
              if (statusUserLoginAfterParse.userRole.length > 0) {
                if (statusUserLoginAfterParse.userRole[0].name) {
                  // // console.log("Role : ", statusUserLoginAfterParse.userRole[0].name );
                  if (
                    statusUserLoginAfterParse.userRole[0].name === 'superadmin'
                  ) {
                    if (result.owner.structure_position_title_name !== null) {
                      setPositionName(
                        result.owner.structure_position_title_name
                      );
                    }
                  }
                }
              }
            }
          }

          if (result.member !== null) {
            setFirstName(
              result.member.first_name !== null ? result.member.first_name : ' '
            );
            setLastName(
              result.member.last_name !== null ? result.member.last_name : ' '
            );
          }

          if (result.status.code === STATUS_COMPLETED) {
            setStatusCOMPLETE(true);
          }

          setNameOfMA(result.name !== null ? result.name : ' ');
          setDescription(
            result.description !== null ? result.description : ' '
          );

          setStructureUnitTypeName(
            result.owner.structure_unit_type_name !== null
              ? result.owner.structure_unit_type_name
              : '-'
          );
          setStructureUnitName(
            result.owner.structure_unit_name !== null
              ? result.owner.structure_unit_name
              : ' '
          );

          setStatusMeasuredActivity(result.status.code);

          setStartValue(
            result.calculatedValue.start_value !== null
              ? result.calculatedValue.start_value
              : ' '
          );
          setTargetValue(
            result.calculatedValue.target_value !== null
              ? result.calculatedValue.target_value
              : ' '
          );

          setActualValue(result.calculatedValue.actualOriented.actual_value);
          setActualValueAchievement(
            result.calculatedValue.actualOriented.actual_value_achievement
          );

          setGapValue(
            result.calculatedValue.resultOriented.gap_result_value !== null
              ? result.calculatedValue.resultOriented.gap_result_value
              : ''
          );
          setGapValuePercent(
            result.calculatedValue.resultOriented
              .gap_result_value_achievement !== null
              ? result.calculatedValue.resultOriented
                  .gap_result_value_achievement
              : ''
          );

          setTargetType(
            result.targetType !== null ? result.targetType.name : ''
          );

          setPosisiAmanActual(
            result.calculatedValue.resultOriented.expected_result_value !== null
              ? result.calculatedValue.resultOriented.expected_result_value
              : ''
          );
          setPosisiAmanAchievement(
            result.calculatedValue.resultOriented
              .expected_result_value_achievement !== null
              ? result.calculatedValue.resultOriented
                  .expected_result_value_achievement
              : ''
          );

          setSisaWaktu(
            result.time.remaining_days !== null
              ? result.time.remaining_days
              : ''
          );

          // console.log('result : ', result);

          setResultValue(result.calculatedValue.resultOriented.result_value);

          if (
            result.calculatedValue.resultOriented.result_value_achievement !==
            null
          ) {
            // // console.log("result.calculatedValue.result_value_percent : ", result.calculatedValue.result_value_percent)
            setResultValueAchievement(
              result.calculatedValue.resultOriented.result_value_achievement *
                100
            );
          }

          if (
            result.calculatedValue.resultOriented
              .expected_result_value_achievement !== null
          ) {
            // // console.log("result.calculatedValue.expected_value_percent : ", result.calculatedValue.expected_value_percent)
            setExpectedValueAchievement(
              result.calculatedValue.resultOriented
                .expected_result_value_achievement * 100
            );
          }

          if (result.time_frame_id === '1') {
            setTimeFramePeriod(true);
            setPeriodName(result.period.name);
            setTimeFrameDueDate(false);
            setTimeFrameDateRange(false);
          }

          if (result.time_frame_id === '2') {
            setTimeFrameDateRange(true);
            setTimeFrameDueDate(false);
            setTimeFramePeriod(false);
          }

          if (result.time_frame_id === '3') {
            setTimeFrameDueDate(true);
            setTimeFrameDateRange(false);
            setTimeFramePeriod(false);
          }

          //*Start Date from MA Detail
          if (result.start_date !== null) {
            setStartDatePojokKanan(result.start_date);
          }

          if (result.end_date !== null) {
            setEndDatePojokKanan(result.end_date);
          }
        }
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("Error : ", error.response);
      });
  }, [TriggerMaAll]);

  // HANDLE DETAIL MA FRESH DATA
  useEffect(() => {
    if (
      isModalCreateMAResult === false ||
      isModalEditMAMaster === false ||
      isModalEdit === false
    ) {
      setLoader(true);
      if (userTokenStateGlobal !== undefined) {
        axiosConfig
          .get(
            `${URL_API}/swd/measured-activity/${
              maID !== null ? maID : locationPathMaId
            }`
          )
          .then(function (response) {
            // console.log("Response Original ALL MA DETAIL : ", response);

            if (response.status === 200) {
              setMaDetailState(response.data.data);
            }
            setLoader(false);
          })
          .catch(function (error) {
            setLoader(false);
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [
    isModalCreateMAResult,
    isModalEditMAMaster,
    isModalEdit,
    context.isSuccessCreateMAR
  ]);

  // HANDLE FRESH DATA IN LIST COLLECTION MA RESULT
  useEffect(() => {
    setLoader(true);
    if (userTokenStateGlobal !== undefined) {
      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity/${
            maID !== null ? maID : locationPathMaId
          }/result?options[paging][limit]=8&options[paging][offset]=0`
        )
        .then(function (response) {
          // console.log("Response Original MA RESULT COLLECTION : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              setCollectionMAResultList(response.data.data);
              context.setCollectionMAResultLength(response.data.info.count);
            }
          }
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  }, [isModalEdit, context.isSuccessCreateMAR]);

  // ======= HANDLE PAGING MA RESULT =======
  useEffect(() => {
    if (context.loaderForPaging === true) {
      // context.setLoaderForPaging(true);
      context.setLoader(true);
      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity/${
            maID !== null ? maID : locationPathMaId
          }/result?options[paging][offset]=${
            context.offset === -8 ? 0 : context.offset
          }&options[paging][limit]=${context.rowsPerPageResult}`
        )
        .then(function (response) {
          // console.log("Response Collection MAR : ", response);

          if (response.status === 200) {
            if (response.data.data !== null) {
              context.setCollectionMAResultList(response.data.data);
              context.setCollectionMAResultLength(response.data.info.count);
            }
          }
          context.setLoaderForPaging(false);
          context.setLoader(false);
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          context.setLoaderForPaging(false);
          context.setLoader(false);
        });
    }
  }, [
    context.offset,
    context.rowsPerPageResult,
    context.page,
    context.loaderForPaging
  ]);

  // ======== Goal Detail ========
  useEffect(() => {
    setLoader(true);
    if (maDetailState.goal_id !== '') {
      axiosConfig
        .get(`${URL_API}/swd/goal/${maDetailState.goal_id}`)
        .then((response) => {
          // // console.log("GAOL DETAYIL : ", response);

          if (response.status === 200) {
            setGoalDetail(response.data.data);
          }
          setLoader(false);
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoader(false);
        });
    }
  }, []);

  /**
 ==================================================================================
 ~~ ALL HANDLE METHODs / FUNCTIONS GOES HERE ~~
 ==================================================================================
 */
  // HANDLE DROPDOWN OPEn
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // HANDLE DROPDOWN CLOSE
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  // HANDLE DROPDOWN CLOSE MA ESULT
  function handleDropdownCloseMaResult() {
    setAnchorElListMaResult(null);
  }

  const handleDialogEditMAv2 = () => {
    handleDropdownClose();
    setOpenEditMA(true);
  };

  //   HANDLE DIALOG SET AS COMPLETE
  const handleDialogSetAsComplete = () => {
    handleDropdownClose();
    setOpenDialogSetAsComplete(true);
  };

  //  HANDLE DIALOG EDIT
  const handleDialogEdit = (e, data) => {
    e.preventDefault();

    handleDropdownCloseMaResult();
    setModalEdit(true);
  };

  // HANDLE DROPDOWN OPEN MA RESULT
  const handleDropdownOpenMaResult = (event, item) => {
    event.preventDefault();

    // console.log("item : ", item);

    setAnchorElListMaResult(event.currentTarget);

    setMaResultId(item.id);
    setMaResultNameDescription(item.description);
    setMaResultDataDetail(item);
  };

  // HANDLE DROPDOWN CLOSE MA RESULT
  function handleDropdownCloseMaResult() {
    setAnchorElListMaResult(null);
  }

  // function handleDialogModalResultMA() {}

  // Hndle Link To Goal
  const handleLinkToGoal = (e) => {
    e.preventDefault();

    // localStorage.setItem("goal_detail", JSON.stringify(goalDetail));
  };

  let editAllowed = false;
  let deleteAllowed = false;
  let setCompleteAllowed = false;

  maDetailState.policy &&
    maDetailState.policy.forEach((item) => {
      if (item.actionName === 'update') {
        editAllowed = item.result;
      } else if (item.actionName === 'delete') {
        deleteAllowed = item.result;
      } else if (item.actionName === 'completed') {
        setCompleteAllowed = item.result;
      }
    });

  // Tags For Goal Detail (Achievement, Method, Calculation, Target)
  const RenderTags = ({ label }) => (
    <Chip
      size="small"
      label={
        <Typography
          className={classes.title}
          variant="subtitle2"
          style={{ fontSize: 11.5 }}
        >
          {label}
        </Typography>
      }
      style={{
        backgroundColor: '#e77f67',
        color: '#fff',
        margin: '0 1.5px'
      }}
    />
  );

  // ======== CONSOLE AREA ===========

  // ======== CONSOLE AREA ===========

  /**
 ==================================================================================
 ~~ RETURN / RENDER COMPONENT ~~
 ==================================================================================
 */

  return (
    // <Grid container>
    <Box container className={classes.fixedLayout}>
      <Box className={classes.layoutMarginLeftAndTop}>
        <Fragment>
          <Box className={classes.backButton}>
            <Paper elevation={0} square>
              <Grid container>
                {/* ~ BACK BUTTON ~ */}
                <Grid item sm={6} md={6} style={{ textAlign: 'left' }}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ marginTop: 16, marginLeft: 8, color: 'grey' }}
                  >
                    <Tooltip
                      title="Kembali untuk melihat semua Measured Activities"
                      placement="right"
                    >
                      <IconButton onClick={() => navigate(ToAllMA)}>
                        <KeyboardBackspaceIcon style={{ cursor: 'pointer' }} />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>

                {/* ~ TIME_FRAME ~ */}
                <Grid item sm={3} md={3} style={{ textAlign: 'right' }}>
                  {/* ~ Time-frame | Period ~ */}
                  {timeFramePeriod && (
                    <Fragment>
                      <Chip
                        label={periodName}
                        style={{
                          backgroundColor: '#f4f7fc',
                          color: 'grey',
                          fontFamily: 'Roboto',
                          marginTop: 24
                        }}
                        size="small"
                      />
                    </Fragment>
                  )}
                </Grid>

                <Grid item sm={3} md={3} style={{ textAlign: 'left' }}>
                  {/* ~ Time-frame | Date Range ~ */}
                  {timeFrameDateRange && (
                    <Button
                      style={{ marginTop: 24, borderWidth: 0, marginLeft: 16 }}
                      variant="outlined"
                      size="small"
                      className={classes.timeFrameIcontDateRangeText}
                    >
                      <b>
                        {moment(startDatePojokKanan).format('DD MMMM YYYY') +
                          ' - ' +
                          moment(endDatePojokKanan).format('DD MMMM YYYY')}
                      </b>
                    </Button>
                  )}

                  {/* ~ Time-frame | Due Date ~ */}
                  {timeFrameDueDate && (
                    <Button
                      style={{ marginTop: 24, borderWidth: 0, marginLeft: 16 }}
                      variant="outlined"
                      size="small"
                      className={classes.timeFrameIcontDateRangeText}
                    >
                      <b>{moment(endDatePojokKanan).format('DD MMMM YYYY')}</b>
                    </Button>
                  )}

                  {/* ~ Time-frame | Period ~ */}
                  {timeFramePeriod && (
                    <Button
                      style={{ marginTop: 24, borderWidth: 0, marginLeft: 16 }}
                      variant="outlined"
                      size="small"
                      className={classes.timeFrameIcontDateRangeText}
                    >
                      <b>
                        {moment(maDetailState?.period?.start_date).format(
                          'DD MMMM YYYY'
                        ) +
                          ' - ' +
                          moment(maDetailState?.period?.end_date).format(
                            'DD MMMM YYYY'
                          )}
                      </b>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box display="flex" flexDirection="row">
            <Grid xs={6} sm={6} md={4} lg={3} style={{ textAlign: 'left' }}>
              {/* width: 380 */}
              <Paper
                elevation={1}
                className={classes.paperNewColorOfGoal}
                style={{ marginBottom: 15 }}
              >
                <List>
                  <Grid container style={{ display: 'flex' }}>
                    <Box style={{ flexGrow: 1 }}>
                      <PictLitleIconMA />
                    </Box>
                    <Box style={{ alignItems: 'flex-end' }}>
                      <IconButton onClick={handleDropdownOpen}>
                        <MoreHorizIcon color="#fff" />
                      </IconButton>
                    </Box>

                    <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleDropdownClose}
                    >
                      {superior && (
                        <StyledMenuItem
                          onClick={handleDialogEditMAv2}
                          disabled={
                            maDetailState.status_id === '6'
                              ? true
                              : maDetailState.status_id === '4'
                              ? true
                              : !editAllowed
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                              >
                                <b>Edit</b>
                              </Typography>
                            }
                          />
                        </StyledMenuItem>
                      )}
                      {superior && (
                        <StyledMenuItem
                          disabled={!deleteAllowed}
                          onClick={() => {
                            setOpenDialogConfirmWeight(true);
                            handleDropdownClose();
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                              >
                                <b>Delete</b>
                              </Typography>
                            }
                          />
                        </StyledMenuItem>
                      )}

                      {superior && maDetailState.status_id !== '4' && (
                        <StyledMenuItem
                          onClick={handleDialogSetAsComplete}
                          disabled={!setCompleteAllowed}
                        >
                          <ListItemText
                            primary={
                              <Chip
                                variant="outlined"
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Set as complete</b>
                                  </Typography>
                                }
                              />
                            }
                          />
                        </StyledMenuItem>
                      )}
                    </StyledMenu>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {fotoQueryContent !== '' && fotoQueryContent !== null ? (
                      <IconButton aria-haspopup="true" color="inherit">
                        <img
                          src={
                            URL_API +
                            '/' +
                            fotoQueryContent +
                            '&token=' +
                            userTokenStateGlobal +
                            '&mode=thumbnail'
                          }
                          className={classes.userRealFoto}
                          alt="."
                        />
                      </IconButton>
                    ) : (
                      <IconButton aria-haspopup="true" color="inherit">
                        <AccountCircleIcon
                          className={classes.imageAvatar}
                          style={{ color: '#dcdcdc' }}
                        />
                      </IconButton>
                    )}
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: 'white' }}
                    >
                      <b>
                        {firstName + ' '} {lastName}
                      </b>
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {positionName !== '' && (
                      <Chip
                        label={_.truncate(positionName, { length: 30 })}
                        style={{
                          color: 'white',
                          fontFamily: 'Roboto',
                          marginTop: 8,
                          marginRight: 3,
                          fontSize: 10
                        }}
                        size="small"
                        variant="outlined"
                      />
                    )}

                    {memberPositionList.length > 0 &&
                      positionName == '' &&
                      memberPositionList.map((item, i) => (
                        <Chip
                          key={i}
                          label={_.truncate(
                            item.structure_position_title_name,
                            { length: 30 }
                          )}
                          style={{
                            color: 'white',
                            fontFamily: 'Roboto',
                            marginTop: 8,
                            marginRight: 3,
                            fontSize: 10
                          }}
                          size="small"
                          variant="outlined"
                        />
                      ))}
                  </Grid>
                  <br />

                  <ListItem>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <div className={classes.circularProgressBarInMoreDetail}>
                        <CircleMoreDetailInPercentMATemporary
                          classes={classes}
                          goalDetailState={maDetailState}
                        />
                      </div>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{ color: 'white' }}
                      >
                        {/* {FormatTitleCase(maDetailState.name)} */}
                        {maDetailState.name}
                      </Typography>
                    </Grid>
                  </ListItem>

                  <Box
                    display="flex"
                    justifyContent="center"
                    flexWrap="wrap"
                    marginBottom={2}
                    padding={0.75}
                  >
                    <RenderTags
                      label={
                        maDetailState.achievement_type_id === '1'
                          ? 'More'
                          : 'Less'
                      }
                    />
                    <RenderTags
                      label={
                        maDetailState.calculation_method_id === '1'
                          ? 'Sum'
                          : maDetailState.calculation_method_id === '2'
                          ? 'Average'
                          : 'Last Value'
                      }
                    />
                    <RenderTags
                      label={
                        maDetailState.input_method_id === '1'
                          ? 'Last Value'
                          : 'Increment'
                      }
                    />
                    <RenderTags
                      label={
                        maDetailState.value_type_id === '1'
                          ? 'Numeric'
                          : 'Percentage'
                      }
                    />
                    <RenderTags
                      label={
                        maDetailState.target_type_id === '1' ? 'Daily' : 'Final'
                      }
                    />
                  </Box>

                  <ListItem style={{ paddingTop: 0 }}>
                    <Box textAlign="justify">
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ color: '#eee' }}
                      >
                        <i>{description}</i>
                      </Typography>
                    </Box>
                  </ListItem>

                  <ListItem>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      {/**
                 ==================================================================================
                ~~ #SCENARIO-01 : ~~
                - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &
                - "RESULT VALUE ACHIEVEMENT" KURANG DARI 100
                ==================================================================================
                */}

                      {resultValueAchievement > expectedValueAchievement &&
                        resultValueAchievement < 100 && (
                          <BarDominantGreenScenarioFIRST
                            goalDetailState={maDetailState}
                            resultValueAchievement={resultValueAchievement}
                            expectedValueAchievement={expectedValueAchievement}
                          />
                        )}

                      {/**
                     ==================================================================================
                    ~~ #SCENARIO-02 : ~~
                    - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &
                    - "result_value_achievement" ===  100
                    ==================================================================================
                    */}

                      {resultValueAchievement > expectedValueAchievement &&
                        resultValueAchievement === 100 && (
                          <BarDominantGreenScenarioSECOND
                            classes={classes}
                            goalDetailState={maDetailState}
                            resultValueAchievement={resultValueAchievement}
                            expectedValueAchievement={expectedValueAchievement}
                          />
                        )}

                      {/**
                     ==================================================================================
                    ~~ #SCENARIO-03 : ~~
                    - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &
                    - result_value_achievement" > 100
                    ==================================================================================
                    */}

                      {resultValueAchievement > expectedValueAchievement &&
                        resultValueAchievement > 100 && (
                          <BarDominantGreenScenarioTHIRD
                            classes={classes}
                            goalDetailState={maDetailState}
                            resultValueAchievement={resultValueAchievement}
                            expectedValueAchievement={expectedValueAchievement}
                          />
                        )}

                      {/**
                     ==================================================================================
                    ~~ #SCENARIO-04 : ~~
                    - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &
                    - "result_value_achievement" KURANG DARI 100
                    - "result_value_achievement" LEBIH DARI 0
                    ==================================================================================
                    */}

                      {expectedValueAchievement > resultValueAchievement &&
                        resultValueAchievement < 100 &&
                        resultValueAchievement > 0 && (
                          <BarDominantRedScenarioFOURTH
                            goalDetailState={maDetailState}
                            resultValueAchievement={resultValueAchievement}
                            expectedValueAchievement={expectedValueAchievement}
                          />
                        )}

                      {/**
                     ==================================================================================
                    ~~ #SCENARIO-04 PART-B : ~~
                    - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &
                    - "result_value_achievement" LEBIH DARI 100
                    - "expected_result_value_achievement" LEBIH DARI 100
                    ==================================================================================
                    */}

                      {expectedValueAchievement > resultValueAchievement &&
                        resultValueAchievement > 100 &&
                        resultValueAchievement > 100 && (
                          <BarDominantRedScenarioFOURTH_partB
                            goalDetailState={maDetailState}
                            resultValueAchievement={resultValueAchievement}
                            expectedValueAchievement={expectedValueAchievement}
                          />
                        )}

                      {/**
                     ==================================================================================
                    ~~ #SCENARIO-05 : ~~
                    - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &
                    - "result_value_achievement" KURANG DARI 0
                    - "result_value_achievement" bernilai MINUS
                    ==================================================================================
                    */}

                      {expectedValueAchievement > resultValueAchievement &&
                        resultValueAchievement < 0 && (
                          <BarDominantRedScenarioFIFTH
                            goalDetailState={maDetailState}
                            resultValueAchievement={resultValueAchievement}
                            expectedValueAchievement={expectedValueAchievement}
                          />
                        )}

                      {/**
                     ==================================================================================
                    ~~ #SCENARIO-06 : ~~
                    - "result_value_achievement" === 0
                    ==================================================================================
                    */}

                      {resultValueAchievement === 0 && (
                        //  expectedValueAchievement < resultValueAchievement &&
                        <BarDominantGreenScenarioByZERO
                          goalDetailState={maDetailState}
                          resultValueAchievement={resultValueAchievement}
                          expectedValueAchievement={expectedValueAchievement}
                        />
                      )}

                      {/**
                     ==================================================================================
                    ~~ #SCENARIO-07 : ~~
                    - ""result_value_achievement" === "expected_result_value_achievement"
                    ==================================================================================
                    */}

                      {resultValueAchievement === expectedValueAchievement &&
                        resultValueAchievement !== 0 && (
                          <BarDominantGreenScenarioSEVEN
                            goalDetailState={maDetailState}
                            resultValueAchievement={resultValueAchievement}
                            expectedValueAchievement={expectedValueAchievement}
                          />
                        )}
                    </Grid>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{ backgroundColor: '#aed9ff', padding: '6px' }}
                      >
                        <PeopleIcon style={{ color: '#4aa9fb' }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Structure Unit
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>
                            {structureUnitTypeName + ' '} {structureUnitName}
                          </b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{
                          backgroundColor: '#aed9ff',
                          padding: '7px',
                          opacity: 0.5
                        }}
                      >
                        <LinearScaleIcon style={{ color: 'magenta' }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Status <i>Measured Activity</i>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>
                            <i>{Capitalize(statusMeasuredActivity)} </i>
                          </b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{
                          backgroundColor: '#fed9ff',
                          padding: '7px',
                          opacity: 0.5
                        }}
                      >
                        <EventAvailableIcon style={{ color: 'orangered' }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Target Type
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>{targetType}</b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon
                      style={{
                        marginLeft: 8
                      }}
                    >
                      <IconButton
                        style={{ backgroundColor: '#bdffde', padding: '6px' }}
                      >
                        <PlayArrowIcon style={{ color: '#36d686' }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Start Value
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>{FormatFloat(startValue, 2)}</b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{ backgroundColor: '#aed9ff', padding: '6px' }}
                      >
                        <RadioButtonCheckedIcon style={{ color: '#4aa9fb' }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Target Value
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>{FormatFloat(maDetailState.target_value, 2)}</b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{ backgroundColor: green[900], padding: '6px' }}
                      >
                        <RadioButtonCheckedIcon
                          style={{ color: green['A400'] }}
                        />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Actual
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>
                            {FormatFloat(actualValue, 2)}
                            &nbsp; &nbsp;
                            {`(${FormatDecimal(
                              actualValueAchievement * 100
                            )})%`}
                          </b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon
                      style={{
                        marginLeft: 8
                      }}
                    >
                      <img
                        src={PictResultValue}
                        style={{ width: 40, height: 40 }}
                      />
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <i>MA Result</i>
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>
                            {FormatFloat(resultValue, 2)}
                            &nbsp; ({FormatDecimal(resultValueAchievement)}
                            %)
                          </b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon
                      style={{
                        marginLeft: 8
                      }}
                    >
                      <img src={PictGAP} style={{ width: 40, height: 40 }} />
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Gap
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>{FormatFloat(gapValue, 2)}</b>
                          &nbsp;
                          <b>({FormatDecimal(gapValuePercent * 100)}%)</b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon
                      style={{
                        marginLeft: 8
                      }}
                    >
                      <img src={PictDone} style={{ width: 40, height: 40 }} />
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Expected Value
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          <b>{FormatFloat(posisiAmanActual, 2)}</b>
                          &nbsp;
                          <b>
                            ({FormatDecimal(posisiAmanAchievement * 100)}
                            %)
                          </b>
                        </Typography>
                      }
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon style={{ marginLeft: 8 }}>
                      <IconButton
                        style={{
                          backgroundColor: '#aed9ff',
                          padding: '7px',
                          opacity: 0.5
                        }}
                      >
                        <CalendarTodayIcon style={{ color: 'orange' }} />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      className={classes.listItemtext}
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          Sisa waktu
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'white' }}
                        >
                          {sisaWaktu} hari
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>

            <Grid xs={6} sm={6} md={8} lg={9} style={{ textAlign: 'left' }}>
              {/* style={{ width: 1342 }} */}
              <Paper elevation={1} className={classes.paperColumnDuaRowFirst}>
                <Grid container justify="space-between">
                  <Typography
                    variant="subtitle1"
                    className={classes.titleInLineChart}
                  >
                    <b>Overview History</b>
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    className={classes.titleInLineChart}
                  >
                    <b>Goal : </b>
                    <Button
                      className={classes.timeFrameIcontDateRangeText}
                      style={{ marginRight: 30 }}
                      onClick={(e) => handleLinkToGoal(e)}
                    >
                      <A
                        href={`/goal-detail/${maDetailState.goal_id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        {/* <b>{FormatTitleCase(maDetailState?.goal?.name)}</b> */}
                        <b>{maDetailState?.goal?.name}</b>
                      </A>
                    </Button>
                  </Typography>
                </Grid>

                <Grid container>
                  <Grid item sm={4}>
                    <TextOverDotLineChart
                      classes={classes}
                      goalDetailState={maDetailState}
                    />
                  </Grid>

                  <Grid item sm={4}>
                    <TextOverDotLineChartSecond
                      classes={classes}
                      goalDetailState={maDetailState}
                    />
                  </Grid>

                  <Grid item sm={4}>
                    <TextOverDotLineChartThird
                      classes={classes}
                      maDetailState={maDetailState}
                    />
                  </Grid>
                </Grid>
                <br />

                {/**
         ==================================================================================
        ~~ DOT LINE CHART ~~
        ==================================================================================
        */}
                <DotLineChartMeasureActivityInDetail
                  classes={classes}
                  isModalCreateMAResult={isModalCreateMAResult}
                  maDetailState={maDetailState}
                  maid={locationPathMaId}
                  isModalEdit={isModalEdit}
                  loader={loader}
                />
              </Paper>

              {/**
             ==================================================================================
            ~~ LIST COLLECTION GOAL RESULT ~~
            ==================================================================================
            */}
              <br />

              <ListCollectioAllnMAResult
                classes={classes}
                maDetailState={maDetailState}
                userTokenState={userTokenStateGlobal}
                handleDropdownOpenMaResult={handleDropdownOpenMaResult}
                anchorElListMaResult={anchorElListMaResult}
                handleDropdownCloseMaResult={handleDropdownCloseMaResult}
                handleDialogEdit={handleDialogEdit}
                collectionMaResultList={collectionMAResultList}
                loader={loader}
                isMeasuredActivityDetailShow={isMeasuredActivityDetailShow}
                modalCreateMAResult={isModalCreateMAResult}
                setModalCreateMAResult={setModalCreateMAResult}
              />
              <br />
              <br />
              <br />
            </Grid>

            <DialogSetAsComplete
              classes={classes}
              isOpenDialogSetAsComplete={isOpenDialogSetAsComplete}
              setOpenDialogSetAsComplete={setOpenDialogSetAsComplete}
              maDetailState={maDetailState}
              setTriggerMaAll={setTriggerMaAll}
            />

            <DialogCreateAllMAResult
              classes={classes}
              isModalCreateMAResult={isModalCreateMAResult}
              setModalCreateMAResult={setModalCreateMAResult}
              collectionMaResultList={collectionMAResultList}
              setCollectionMaResultList={setCollectionMAResultList}
              maDetailState={maDetailState}
            />

            <DialogDeleteMA
              classes={classes}
              isModalDeleteMA={isModalDeleteMA}
              setModalDeleteMA={setModalDeleteMA}
              maDetailState={maDetailState}
              setMeasuredActivityDetailShow={setMeasuredActivityDetailShow}
            />

            <DialogConfirmWeight
              classes={classes}
              openDialogConfirmWeight={openDialogConfirmWeight}
              setOpenDialogConfirmWeight={setOpenDialogConfirmWeight}
              setModalContinue={setModalDeleteMA}
            />

            <DialogEditMAv2
              classes={classes}
              maData={maDetailState}
              openEditMA={openEditMA}
              setOpenEditMA={setOpenEditMA}
              setTriggerMaAll={setTriggerMaAll}
            />
          </Box>
        </Fragment>
      </Box>
    </Box>
  );
}

export default ViewAllMAMoreDetail;

let detailMaAll = {
  id: null,
  name: '',
  measured_activity_type_id: '',
  goal_id: '',
  owner_id: '',
  achievement_type_id: '',
  value_type_id: '',
  start_value: '',
  target_value: '',
  target_range_value: null,
  tolerance_value: '',
  weight_value: '',
  input_value: '',
  input_method_id: '',
  calculation_method_id: '',
  direct_link_main_measured_activity_id: null,
  direct_link_node_path: '',
  direct_link_node_level: null,
  update_interval_id: '',
  update_interval_trigger_value: null,
  period_id: null,
  is_evidence_required: 0,
  start_date: '',
  end_date: '',
  completed_timestamp: null,
  time_frame_id: '',
  measured_activity_mode_id: '',
  visibility_type_id: '',
  last_measured_activity_result_date: null,
  status_id: '',
  description: null,
  created_by: '',
  updated_by: null,
  deleted_by: null,
  created_at: '',
  updated_at: '',
  deleted_at: null,
  is_used: false,
  period: null,
  goal: {
    id: '',
    name: ''
  },
  achievementType: {
    id: '',
    code: '',
    name: ''
  },
  inputMethod: {
    id: '',
    code: '',
    name: ''
  },
  calculationMethod: {
    id: '',
    code: '',
    name: ''
  },
  measuredActivityMode: {
    id: '',
    code: '',
    name: ''
  },
  measuredActivityType: {
    id: '',
    code: '',
    name: ''
  },
  owner: {
    id: '',
    parent_id: '',
    prev_sibling_id: null,
    next_sibling_id: null,
    code: '',
    member_id: '',
    member_first_name: '',
    member_last_name: '',
    user_id: '',
    user_email: '',
    structure_unit_type_id: '',
    structure_unit_type_name: '',
    structure_unit_id: '',
    structure_unit_name: '',
    structure_position_title_id: '',
    structure_position_title_name: '',
    classification_id: 1,
    classification_name: '',
    node_level: null,
    created_by: '',
    updated_by: '',
    deleted_by: null,
    created_at: '',
    updated_at: '',
    deleted_at: null,
    self: {
      rel: null
    }
  },
  member: {
    id: '',
    first_name: '',
    last_name: '',
    nickname: '',
    photo_url: ''
  },
  directLinkMainMeasuredActivity: null,
  startValueSyncStatus: {
    id: '1',
    code: 'swd_ma_start_value_sync_status_sync',
    name: 'sync'
  },
  targetValueSyncStatus: {
    id: '',
    code: '',
    name: ''
  },
  status: {
    id: '',
    code: '',
    name: '',
    sort_order: null
  },
  updateInterval: {
    id: '',
    name: '',
    code: '',
    datetime_unit: null,
    datetime_value: null,
    sort_order: 0,
    created_by: null,
    updated_by: null,
    deleted_by: null,
    created_at: '',
    updated_at: null,
    deleted_at: null,
    self: {
      rel: null
    }
  },
  valueType: {
    id: '',
    code: '',
    name: ''
  },
  visibilityType: {
    id: '',
    code: '',
    name: ''
  },
  calculatedValue: {
    start_value: '',
    target_value: '',
    input_value: '',
    target_value_achievement: '',
    target_range_value: '',
    resultOriented: {
      result_value: '',
      result_value_achievement: '',
      expected_result_value: '',
      expected_result_value_achievement: '',
      gap_result_value: '',
      gap_result_value_achievement: '',
      overall_result_achievement: ''
    },
    actualOriented: {
      actual_value: '',
      actual_value_achievement: '',
      expected_actual_value: '',
      expected_actual_value_achievement: '',
      gap_actual_value: '',
      gap_actual_value_achievement: '',
      overall_actual_achievement: ''
    },
    day_offset: null,
    date_offset: '',
    start_date: '',
    end_date: ''
  },
  time: {
    elapsed_days: '',
    remaining_days: '',
    total_days: '',
    start_date: '',
    end_date: ''
  },
  timeFrame: {
    id: '',
    code: '',
    name: ''
  },
  self: {
    rel: null
  }
};
