import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import InfoIcon from "@material-ui/icons/Info";

import DialogError from "../../../../components/DialogError";
import Capitalize from "../../../../utilities/Capitalize";

import { URL_API } from "../../../../constants/config-api";
import Snackbeer from "../../../../components/Snackbeer";
// import { ToGoal } from '../../../../constants/config-redirect-url';
// import Redirect from '../../../../utilities/Redirect';
// import { ThemeProvider } from '@material-ui/styles';
import { useSetState } from "react-use";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDelete,
    setModalDelete,
    detailState,
    setSuccessDeleteUser,
    // isSuccesDeleteUser
  } = props;

  const [userTokenState, setUserTokenState] = useState("");
  const [loader, setLoader] = useState(false);

  const [listError, setListError] = useState([]);
  const [errorStatus, setErrorStatus] = useState(undefined);

  useEffect(() => {
    if (isModalDelete == true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      //*
      setSuccessDeleteUser(false);
    }
  }, [isModalDelete]);

  const handleDelete = () => {
    setLoader(true);
    // // console.log("goalResultNameDescription : ", goalResultNameDescription);

    if (userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .delete(URL_API + `/account-management/user/${detailState.id}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          // setCollectionGoalResultList(collectionGoalResultList.filter(item => item.id !== goalResultId));
          setModalDelete(false);

          setSuccessDeleteUser(true);

          setModalResponse200(true);
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status == 403) {
              setErrorStatus(403);
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 409 :" + Capitalize(error.response.data.info.message)
                  );
                }
              }
            }

            if (error.response.status == 422) {
              setErrorStatus(422);
              if (error.response.data.info !== null) {
                if (error.response.data.info.errors !== null) {
                  if (error.response.data.info.errors !== undefined) {
                    if (error.response.data.info.errors.length > 0) {
                      setListError(error.response.data.info.errors);
                      setOpenDialogError(true);
                      setTextErrorInformationValue(".");
                      // if(error.response.data.info.errors[0].code === "HAS_STRUCTURE_POSITION"){

                      //     setOpenDialogError(true);
                      //     setTextErrorInformationValue('Error 422 : ' + Capitalize(error.response.data.info.errors[0].description))
                      // };
                    }
                  }
                }
              }
            }

            // console.log("Error : ", error.response);
          } else {
            // console.log("Error : ", error.response);
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
            setModalDelete(false);
            // alert('Whoops, something went wrong !')
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
       ```````````````````
       HANDLE DIALOG ERROR

       ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  return (
    <Fragment>
      <Dialog
        open={isModalDelete}
        onClose={() => setModalDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          {/* <Paper style={{backgroundColor: '#ffcbcb'}}>
                    <Grid container>
                        <Grid item sm={1}>
                                <Box marginLeft={1} marginTop={1}>
                                    <InfoIcon style={{color: 'white'}} /> 
                                </Box>
                        </Grid>
                        <Grid item sm={11}>        
                                <Box marginTop={1}>
                                    <Typography variant='subtitle1' className={classes.title} style={{color: 'black'}}>
                                        <i style={{color: 'red'}}>
                                            Sekali Anda menghapus <i>Goal</i>, maka data <i>Goal Result</i> dan <i>Measure Activity</i> akan hilang ! 
                                        </i>
                                        <br />
                                        <b style={{color: 'red'}}>
                                            (Pastikan Anda benar- benar paham konsekuensi-nya.)
                                        </b>
                                    </Typography>
                                </Box>
                        </Grid>
                    </Grid>
                </Paper> */}
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}
            >
              <b>
                Apakah Anda yakin ingin menghapus <i>User</i> : &nbsp;
                <i>
                  {detailState.member.first_name + " "}
                  {detailState.member.last_name}
                </i>{" "}
                ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.buttonModalDelete}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin"
            ) : (
              <CircularProgress size={24} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        listError={listError}
        errorStatus={errorStatus}
      />

      <Snackbeer
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={"User berhasil di hapus !"}
      />
    </Fragment>
  );
};

export default DialogDelete;
