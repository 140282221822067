import React, { Fragment, useContext } from "react";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ConvertExponentialToSubstring from "../../../../utilities/ConvertExponentialToSubstring";
import ContextGoalDetail from "../Context/ContextGoalDetail";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}))(Tooltip);

const IconActualValue = (props) => {
  const { classes, goalDetailState, isExponentAva } = props;
  const context = useContext(ContextGoalDetail);

  return (
    <Fragment>
      <ListItemIcon style={{ marginLeft: 8 }}>
        <IconButton style={{ backgroundColor: green[900], padding: "6px" }}>
          <RadioButtonCheckedIcon style={{ color: green["A400"] }} />
        </IconButton>
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}>
            Actual
          </Typography>
        }
        secondary={
          <LightTooltip
            placement="bottom-start"
            title={
              <>
                {context.modeRupiahOn === true
                  ? `Actual : Rp ${FormatDecimal(
                      goalDetailState.calculatedValue.actualOriented
                        .actual_value
                    )}`
                  : "Actual : " +
                    FormatDecimal(
                      goalDetailState.calculatedValue.actualOriented
                        .actual_value
                    )}
                &nbsp;
                {isExponentAva === true ? (
                  `(${ConvertExponentialToSubstring(
                    goalDetailState.calculatedValue.actualOriented
                      .actual_value_achievement * 100
                  )}%)`
                ) : goalDetailState.calculatedValue.actualOriented
                    .actual_value_achievement !== null ? (
                  <Fragment>
                    (
                    {FormatDecimal(
                      goalDetailState.calculatedValue.actualOriented
                        .actual_value_achievement * 100
                    )}
                    %)
                  </Fragment>
                ) : (
                  "Actual : "
                )}
              </>
            }>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "white" }}>
              <b>
                {context.modeRupiahOn === true
                  ? `Rp ${FormatDecimal(
                      goalDetailState.calculatedValue.actualOriented
                        .actual_value
                    )}`
                  : FormatDecimal(
                      goalDetailState.calculatedValue.actualOriented
                        .actual_value
                    )}
                &nbsp;
                {isExponentAva === true ? (
                  `(${ConvertExponentialToSubstring(
                    goalDetailState.calculatedValue.actualOriented
                      .actual_value_achievement * 100
                  )}%)`
                ) : goalDetailState.calculatedValue.actualOriented
                    .actual_value_achievement !== null ? (
                  <Fragment>
                    (
                    {FormatDecimal(
                      goalDetailState.calculatedValue.actualOriented
                        .actual_value_achievement * 100
                    )}
                    %)
                  </Fragment>
                ) : (
                  ""
                )}
              </b>
            </Typography>
          </LightTooltip>
        }
      />
    </Fragment>
  );
};

export default IconActualValue;
