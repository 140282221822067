import React, { useState } from "react";
import { MenuList, MenuItem, Radio, FormControlLabel } from "@material-ui/core";

import clsx from "clsx";

import DialogConfirmPhase from "../../dialogs/DialogConfirmPhase";
import DialogConfirmManual from "../../dialogs/DialogConfirmManual";
import DialogConfirmChecklist from "../../dialogs/DialogConfirmChecklist";

const ProgressTaskSetting = ({
  classes,
  resultSource,
  onResultSource,
  onClose,
}) => {
  const [openConfirmPhase, setOpenConfirmPhase] = useState(false);
  const [openConfirmManual, setOpenConfirmManual] = useState(false);
  const [openConfirmChecklist, setOpenConfirmChecklist] = useState(false);

  const handleResultSource = (data) => {
    onResultSource(data.code);

    if (data.code === "phase") {
      setOpenConfirmPhase(true);
    }

    if (data.code === "normal") {
      setOpenConfirmManual(true);
    }

    if (data.code === "checklist") {
      setOpenConfirmChecklist(true);
    }
  };

  return (
    <MenuList role="menu" className={classes.menuList}>
      {listProgressTask.map((item, i) => {
        return (
          <MenuItem
            key={i}
            className={classes.dropdownItem}
            style={{ margin: 0, padding: 0, paddingLeft: 10 }}
            onClick={() => handleResultSource(item)}
          >
            <FormControlLabel
              style={{ margin: 0, padding: 0 }}
              label={
                <span
                  style={{
                    color: "#555",
                    fontSize: 14,
                  }}
                  className={classes.title}
                >
                  {item.name}
                </span>
              }
              control={
                <Radio
                  className={classes.rootRadio}
                  disableRipple
                  color="default"
                  checked={item.code === resultSource}
                  checkedIcon={
                    <span
                      className={clsx(
                        classes.iconRadio,
                        classes.checkedIconRadio
                      )}
                    />
                  }
                  icon={<span className={classes.iconRadio} />}
                />
              }
            />
          </MenuItem>
        );
      })}

      <DialogConfirmPhase
        classes={classes}
        openConfirmPhase={openConfirmPhase}
        setOpenConfirmPhase={setOpenConfirmPhase}
        onClose={onClose}
        onResultSource={onResultSource}
      />

      <DialogConfirmChecklist
        classes={classes}
        openConfirmChecklist={openConfirmChecklist}
        setOpenConfirmChecklist={setOpenConfirmChecklist}
        onClose={onClose}
        onResultSource={onResultSource}
      />

      <DialogConfirmManual
        classes={classes}
        openConfirmManual={openConfirmManual}
        setOpenConfirmManual={setOpenConfirmManual}
        onClose={onClose}
        onResultSource={onResultSource}
      />
    </MenuList>
  );
};

const listProgressTask = [
  {
    name: "Phase",
    code: "phase",
  },
  {
    name: "Checklist",
    code: "checklist",
  },
  {
    name: "Manual",
    code: "normal",
  },
];

export default ProgressTaskSetting;
