import * as constants from '../constants';

export const liveSummaryInitial = {
  options: {
    defaultPeriod: null,
    units: [],
    highestStructurePosition: null
  },
  data: [],
  rawData: []
};

export const liveSummaryReducer = (state, { dataType, payload }) => {
  switch (dataType) {
    case constants.LIVE_SUM_RAW_DATA:
      return {
        ...state,
        rawData: payload
      }
    case constants.LIVE_SUM_DATA:
      return {
        ...state,
        data: payload
      }
    case constants.LIVE_SUM_OPTIONS:
      return {
        ...state,
        options: {
          defaultPeriod: payload.defaultPeriod,
          units: payload.units,
          highestStructurePosition: payload.highestStructurePosition
        }
      }
    default:
      return state;
  }
};
