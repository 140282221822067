import React, { useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";

import FormChangePassword from "./Components/FormChangePassword";
import UpdateProfileUser from "./Components/UpdateProfileUser";

const ViewPersonalSetting = ({ theme, classes }) => {
  const [selectPersonal, setSelectPersonal] = useState({
    code: "password",
    name: "Kata Sandi",
    id: 0,
  });

  const handleTabPersonal = (data) => {
    setSelectPersonal(data);
  };

  return (
    <Grid container>
      <Grid item md={2} style={{ backgroundColor: "#f8f8fc" }}>
        <Box flexGrow={1} mt={4} pl={3}>
          {listPersonal.map((personal, i) => {
            const isCurrent = selectPersonal.code === personal.code;

            return (
              <Box
                key={i}
                p={2}
                className={classes.settingItem}
                onClick={() => setSelectPersonal(personal)}
              >
                <Typography
                  variant="subtitle2"
                  className={isCurrent ? classes.title333 : classes.title888}
                >
                  {personal.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid item md={10}>
        <div style={{ backgroundColor: "#fff", height: "100vh" }}>
          {selectPersonal.id === 0 && <FormChangePassword classes={classes} />}
          {selectPersonal.id === 1 && <UpdateProfileUser classes={classes} />}
        </div>
      </Grid>
    </Grid>
  );
};

export default ViewPersonalSetting;

const listPersonal = [
  {
    code: "password",
    name: "Kata Sandi",
    id: 0,
  },
  {
    code: "profile",
    name: "Profile",
    id: 1,
  },
];
