import React, { useState } from "react";

import ContextMyAttendance from "./ContextMyAttendance";

const ContextGlobalMyAttendance = (props) => {
  const [verificationMethod, setVerificationMethod] = useState([]);

  return (
    <ContextMyAttendance.Provider
      value={{
        verificationMethod: verificationMethod,
        setVerificationMethod: setVerificationMethod,
      }}
    >
      {props.children}
    </ContextMyAttendance.Provider>
  );
};

export default ContextGlobalMyAttendance;
