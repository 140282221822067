import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import FolderIcon from "@material-ui/icons/Folder";

import TruncateTextShortNameGoal from "../../../../../utilities/TruncateTextShortNameGoal";
import ListFileDrive from "./ListFileDrive";

function DriveEvidence({
  listFolders,
  listFiles,
  classes,
  onListItemClick,
  onNavigateFolder,
  selectedIndex,
  openDialogAddEvidence,
  triggerNavigate,
  loadingFolder,
  loadingFile,
}) {
  return (
    <div>
      <Box
        style={{
          backgroundColor: "#fafafa",
          borderRadius: 3,
          maxHeight: 250,
          overflow: "auto",
        }}
        marginBottom={3}
        marginTop={2}>
        {loadingFolder || loadingFile ? (
          <List>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={15} style={{ color: "#d1354a" }} />
            </Box>
          </List>
        ) : (
          <List dense>
            {listFolders.length > 0 &&
              listFolders.map((item) => (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => onNavigateFolder(item)}>
                  <ListItemIcon>
                    <FolderIcon fontSize="small" color="#555" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        {TruncateTextShortNameGoal(item.name)}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}

            {listFolders.length === 0 && !loadingFolder && (
              <List>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, color: "#333" }}
                    className={classes.title}>
                    Tidak ada folder
                  </Typography>
                </Box>
              </List>
            )}

            {listFiles.length > 0 &&
              listFiles.map((item, i) => (
                <ListFileDrive
                  item={item}
                  classes={classes}
                  selectedIndex={selectedIndex}
                  onListItemClick={onListItemClick}
                  openDialogAddEvidence={openDialogAddEvidence}
                  triggerNavigate={triggerNavigate}
                  i={i}
                />
              ))}

            {listFiles.length === 0 && !loadingFile && (
              <List>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, color: "#333" }}
                    className={classes.title}>
                    Tidak ada file
                  </Typography>
                </Box>
              </List>
            )}
          </List>
        )}
      </Box>
    </div>
  );
}

export default DriveEvidence;
