import React from "react";
import { Box, Grid, Container, IconButton } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

import { makeStyles } from "@material-ui/core/styles";

import logoPerformate from "../../../assets/images/performate-03.png";
import playStore from "../../../assets/images/play_store.png";

import "../Style/landing.css";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  centerLayout: {
    textAlign: "center",
  },
  footerUl: {
    marginTop: 10,
  },
  textLowerCase: {
    textTransform: "none",
  },
});

const useStyles = makeStyles(styles);

const Footer = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  return (
    <footer>
      {/* <Container> */}
      <div className="box-footer-root">
        <br />
        <div className="box-footer">
          <div className="footer-icon">
            <img
              src={logoPerformate}
              width="40px"
              style={{ marginTop: "-5px" }}
            />
          </div>

          <div>
            <span className="footer-span-title">Performate</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">About</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Careers</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Blog</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Customer</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Our Products</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">Products</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Promo</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Pricing</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Family</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Business</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Education</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Help</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">Workshop</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Seminar</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Guides</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Support</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Services</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Help Center</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Legal</span>
            <ul className={classes.footerUl}>
              <li>
                <a href="#">
                  <span className="fab">Legal</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Terms of Services</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab">Privacy Policy</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className="footer-span-title">Contact</span>
            <p>
              Jl. Raya Pondok Kelapa Jakarta Timur <br />
              <br />
              cs@performate.id <br />
              0888-0939-3490
            </p>
          </div>
        </div>
      </div>
      {/* </Container> */}

      <Box py={3} bgcolor="#C8203C">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <p style={{ color: "#fff" }}>
                © 2022 Performate.id All Rights Reserved
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a href="https://www.facebook.com/performate.id" target="_blank">
                <IconButton size="small">
                  <FacebookIcon style={{ color: "#fff" }} />
                </IconButton>
              </a>
              <Box mx={0.5} />
              <a href="https://www.instagram.com/performate.id" target="_blank">
                <IconButton size="small">
                  <InstagramIcon style={{ color: "#fff" }} />
                </IconButton>
              </a>
              <Box mx={0.5} />
              <a
                href="https://www.linkedin.com/company/performate-id"
                target="_blank"
              >
                <IconButton size="small">
                  <LinkedInIcon style={{ color: "#fff" }} />
                </IconButton>
              </a>
              <Box mx={0.5} />
              <a
                href="https://www.youtube.com/channel/UCa-yPMA9Y-UbuLn-qfzLz6w"
                target="_blank"
              >
                <IconButton size="small">
                  <YouTubeIcon style={{ color: "#fff" }} />
                </IconButton>
              </a>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <a
                  href="https://play.google.com/store/apps/details?id=com.madinah.performance"
                  target="_blank"
                >
                  <img src={playStore} height={50} />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
