import React from "react";
import { Box, Grid } from "@material-ui/core";

import Company from "./components/company";

const ViewCompany = ({ classes }) => {
  return (
    <Box>
      <Company classes={classes} />
    </Box>
  );
};

export default ViewCompany;
