import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  CircularProgress,
  Grid,
  Divider,
} from "@material-ui/core";

import PercentagesSlider from "react-percentages-slider";
// import "react-percentages-slider/dist/index.css";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import ContextProject from "../../context/ContextProject";

const DialogSetWeightTask = ({
  project,
  classes,
  openSetWeightTask,
  setOpenSetWeightTask,
}) => {
  const { setTriggerTask } = useContext(ContextProject);
  const project_id = window.location.pathname.split("/")[2];

  const [loading, setLoading] = useState(false);
  const [loadWeight, setLoadWeight] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  const [weightList, setWeightList] = useState([]);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openSetWeightTask) {
      setLoadWeight(true);

      AxiosConfig.get(`${URL_API}/project/${project_id}/task-group/weighting`)
        .then((res) => {
          const result = res.data.data.fields.task_group.default_value;
          const taskLength = result.length;

          // // console.log("WEIGHT", res);

          // FOR WEIGHTI DIALOG WIDTH
          if (taskLength <= 4) {
            setMaxWidth("sm");
          } else if (taskLength > 4 && taskLength <= 8) {
            setMaxWidth("md");
            setFullWidth(true);
          } else if (taskLength > 8 && taskLength <= 15) {
            setMaxWidth("lg");
          } else if (taskLength > 15) {
            setMaxWidth("lg");
            setFullWidth(true);
          }

          // FOR MAKE SURE 100
          const to100 = (arr) => {
            let lists = arr.map((list) => (list === null ? 0 : list));

            if (lists.reduce((acc, curr) => acc + curr, 0) <= 100) {
              lists[lists.length - 1] += Math.abs(
                100 - lists.reduce((acc, curr) => acc + curr, 0)
              );
            } else if (lists.reduce((acc, curr) => acc + curr, 0) >= 100) {
              lists[lists.length - 1] -= Math.abs(
                100 - lists.reduce((acc, curr) => acc + curr, 0)
              );
            }

            return lists;
          };

          // CONDITION TO 100
          const toaverage = (arr) => {
            const todos = arr.map((todo) => {
              let weighting = todo.weighting;
              if (
                todo.name === "Default Task Group" &&
                todo.todolists_count === 0
              ) {
                weighting = 0;
              }

              return {
                ...todo,
                weighting,
              };
            });
            const lists = todos.map((list) => Number(list.weighting));
            const origin = lists.map((ori) => (ori === null ? 0 : ori));
            const is0 = origin.reduce((acc, curr) => acc + curr, 0);
            const avg = 100 / arr.length;
            const reborn = arr.map((born) => Number(avg.toFixed()));

            if (is0 === 0) {
              // console.log("REBORN wght", reborn);
              return to100(reborn);
            } else {
              // console.log("ORIGIN wght", origin);
              return to100(origin);
            }
          };

          // // console.log("SUM wght", toaverage(result));
          // =========== THE ARR WEIGHT AFTER CALCULATION ===========
          const ARRAY_WEIGHT = toaverage(result);
          // =========== THE ARR WEIGHT AFTER CALCULATION ===========

          if (res.status === 200) {
            const addPer = result.map((el, i) => {
              return {
                ...el,
                text: `Group ${i + 1}`,
                percentage: ARRAY_WEIGHT[i],
                color: colors[i],
              };
            });

            // // console.log("RES WG", addPer);

            setWeightList(addPer);
            setLoadWeight(false);
          }
        })
        .catch((err) => {
          setLoadWeight(false);
        });
    }
  }, [openSetWeightTask]);

  const handleWeightTask = () => {
    setLoading(true);

    const data = {
      weightings: weightList.map((el) => {
        return {
          id: el.id,
          weight: Number(el.percentage).toFixed(),
        };
      }),
    };

    // // console.log("DATA W", data);

    AxiosConfig.post(
      `${URL_API}/project/${project_id}/task-group/weighting`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setTriggerTask(Math.floor(Math.random() * 100));
          setOpenSetWeightTask(false);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // // console.log("List wgh", weightList);

  return (
    <Dialog
      open={openSetWeightTask}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" className={classes.title333}>
          <b>{project?.name}</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loadWeight ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress
              size={15}
              style={{ color: "#d1354a", marginRight: 5 }}
            />
            <Typography variant="subtitle2" className={classes.title888}>
              Load Task...
            </Typography>
          </Box>
        ) : (
          <PercentagesSlider
            divisions={weightList}
            setDivisions={setWeightList}
          />
        )}

        <Box my={4}>
          <Divider />
        </Box>

        {loadWeight && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress
              size={15}
              style={{ color: "#d1354a", marginRight: 5 }}
            />
            <Typography variant="subtitle2" className={classes.title888}>
              Load Weight list ...
            </Typography>
          </Box>
        )}

        {!loadWeight && weightList.length > 0 && (
          <Grid container>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title888}>
                Task
              </Typography>

              <Box mt={3}>
                {weightList.length > 0 &&
                  weightList.map((item, i) => (
                    <Typography
                      key={i}
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginBottom: 10 }}
                    >
                      <b>
                        ({item.text}) {item.name}
                      </b>
                    </Typography>
                  ))}
                {weightList.length === 0 && (
                  <Typography variant="subtitle2" className={classes.title333}>
                    ---
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginRight: 5 }}
                >
                  Weight
                </Typography>
              </Box>
              <Box mt={3}>
                {weightList.length > 0 &&
                  weightList.map((item, i) => (
                    <Typography
                      key={i}
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginBottom: 10 }}
                    >
                      <b>{Number(item.percentage).toFixed()} %</b>
                    </Typography>
                  ))}
                {weightList.length === 0 && (
                  <Typography variant="subtitle2" className={classes.title333}>
                    ---
                  </Typography>
                )}
              </Box>
              <Box mt={3}>
                <Typography
                  variant="subtitle1"
                  className={classes.title888}
                  // style={{ marginBottom: 10 }}
                >
                  <b>
                    {weightList?.length > 0 &&
                      weightList
                        ?.reduce(
                          (acc, curr) => acc + Number(curr?.percentage),
                          0
                        )
                        .toFixed()}{" "}
                    %
                  </b>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenSetWeightTask(false)}
        >
          Cancel
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleWeightTask}
        >
          {loading ? "Setting..." : "Set Weight"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogSetWeightTask;

const colors = [
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
  "#34cc80",
  "#fab445",
];

// ========================= THE LOGICC OF WEIGHT =========================

// function to100(arr) {
// 	let ray = arr.map(v => v === null ? 0 : v)
//  ray.reduce((x, y) => x + y) <= 100 ? (ray[ray.length - 1] += Math.abs(100 - ray.reduce((y, z) => y + z))) : ray.reduce((x, y) => x + y) >= 100 ? (ray[ray.length - 1] -= Math.abs(100 - ray.reduce((y, z) => y + z))) : ray

//  return ray
// }

// const toaverage = arr => {
// 	const single = arr.map(a => a.weighting);
// 	const origin = single.map(v => v === null ? 0 : v)
// 	const is0 =	origin.reduce((a, b) => a + b, 0)
//   const avg = 100 / arr.length
//   const reborn = arr.map((a) => Number(avg.toFixed()))

//   if (is0 === 0) {
//   	return to100(reborn)
//   } else {
//   	return to100(origin)
//   }
// }

// console.log(toaverage(arr0))
// console.log(toaverage(arr1))
// console.log(toaverage(arr2))
// console.log(toaverage(arr3))
