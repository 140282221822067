import React, { useState, useContext, useEffect } from "react";
import { Box, Button, TextField, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import ClearIcon from "@material-ui/icons/Clear";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import uuid from "uuidv4";
import moment from "moment";

import { styles } from "../../style/styleProject";
import ContextProject from "../../context/ContextProject";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

import "./style/inputCard.css";

const useStyles = makeStyles(styles);

const InputCard = ({
  setOpen,
  open,
  listId,
  type,
  objCustomPhase,
  objTaskGroup,
}) => {
  const classes = useStyles();
  const project_id = window.location.pathname.split("/")[2];
  const {
    dataKanban,
    setDataKanban,
    setTriggerTask,
    setTriggerKanban,
    projectKanban,
    projectTState,
  } = useContext(ContextProject);

  const [title, setTitle] = useState("");

  const [ownerCollections, setOwnerCollections] = useState([]);
  const [owner, setOwner] = useState(null);
  const [ownerId, setOwnerId] = useState("");
  const [dueDate, setDueDate] = useState(new Date());

  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (open) {
      axiosConfig
        .get(`${URL_API}/todolist/create?project_id=${project_id}`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
          }
        })
        .catch((error) => {
          // console.log("error", error);
          // Handle Error
          // const { listError, dialogErr, resStatus, errorMessage } =
          //   handleError(error);
          // Error State
          // setOpenDialogError(dialogErr);
          // setTextErrorMessage(errorMessage);
          // setListError(listError);
          // setErrorStatus(resStatus);
        });
    }
  }, [open]);

  // // console.log("Proj DET", projectTState.projectDetails);

  useEffect(() => {
    if (open) {
      axiosConfig
        .get(
          `${URL_API}/project/general/get-due-date?start_date=${moment(
            projectTState.projectDetails?.start_date
          ).format("YYYY-MM-DD")}&duration=2&exclude_weekend=${
            projectTState.projectDetails?.exclude_weekend
          }`
        )
        .then((res) => {
          const result = res.data.data;

          // // console.log("SET DUED", result);

          if (res.status === 200) {
            setDueDate(result.due_date);
          }
        })
        .catch((err) => {
          // console.log("error", err);
        });
    }
  }, [open]);

  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setOwnerId(data.id);
      setOwner(data);
    }
  };

  const addMoreCard = (title, listId) => {
    if (!title) {
      return;
    }

    const newCardId = uuid();
    const newCard = {
      id: newCardId,
      title,
    };

    const list = dataKanban.lists[listId];
    list.cards = [...list.cards, newCard];

    // const newState = {
    //   ...dataKanban,
    //   lists: {
    //     ...dataKanban.lists,
    //     [listId]: list,
    //   },
    // };

    // // console.log("newState", newState);
    // // console.log("list", list);

    // setDataKanban(newState);
    // setTitle(title);
    handleAddCard(list?.title.toUpperCase());
  };

  const addMoreList = (title) => {
    if (!title) {
      return;
    }

    const newListId = uuid();
    const newList = {
      id: newListId,
      title,
      cards: [],
    };
    const newState = {
      listIds: [...dataKanban.listIds, newListId],
      lists: {
        ...dataKanban.lists,
        [newListId]: newList,
      },
    };
    setDataKanban(newState);
  };

  const handleOnChange = (e) => {
    setTitle(e.target.value);
  };

  const handleBtnConfirm = () => {
    if (type === "card") {
      addMoreCard(title, listId);
    } else {
      addMoreList(title);
    }

    // setTitle("");
  };

  const handleAddCard = (statusId) => {
    setLoading(true);

    const data = {
      Todolist: {
        title: title,
        target_result: 0,
        start_date: moment(projectTState.projectDetails?.start_date).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        due_date: moment(dueDate).format("YYYY-MM-DD HH:mm:ss"),
        owner_id: ownerId,
        status:
          projectTState.groupBy === "custom_phase" ||
          projectTState.groupBy === "task_group"
            ? "TO DO"
            : statusId,
        priority: "LOW",
        remarks: null,
        description: null,
        tags: null,
        project_id,
        // actual_result: "0",
        predecessor_id: [],
        task_group_id:
          projectTState.groupBy === "task_group" ? objTaskGroup?.id : "",
        custom_phase_id:
          projectTState.groupBy === "custom_phase" ? objCustomPhase?.id : "",
      },
    };

    // // console.log("DADA", data);

    axiosConfig
      .post(`${URL_API}/todolist`, data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);

          setTriggerTask(Math.floor(Math.random() * 100));
          setTriggerKanban(Math.floor(Math.random() * 100));

          setOwnerId("");
          setTitle("");
          setOwner(null);
          setOpen(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // // console.log("list", list);

  return (
    <div className="input-card">
      <div className="input-card-container">
        <textarea
          onChange={handleOnChange}
          value={title}
          className="input-text"
          placeholder={
            type === "card"
              ? "Enter a title of this card..."
              : "Enter list title"
          }
          autoFocus
        />
      </div>
      <Box className="confirm">
        <Box display="flex" alignItems="center">
          <Button
            size="small"
            variant="outlined"
            className="button-confirm"
            disabled={ownerId === "" || loading}
            onClick={handleBtnConfirm}
          >
            {type === "card" ? "Add Card" : "Add List"}
          </Button>
          <button
            className="button-cancel"
            onClick={() => {
              setTitle("");
              setOwnerId("");
              setOwner(null);
              setOpen(false);
            }}
          >
            <ClearIcon />
          </button>
        </Box>
        <Autocomplete
          id="combo-box-demo"
          disabled={loading}
          options={ownerCollections}
          value={owner}
          getOptionLabel={(option) =>
            `${option.member.first_name} ${option.member.last_name}`
          }
          onChange={(e, owner) => handleChooseOwner(e, owner)}
          noOptionsText="Tidak ada owner"
          popupIcon={
            loading ? (
              <CircularProgress size={18} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              disabled={loading}
              placeholder="owner"
              variant="standard"
              size="small"
              style={{ minWidth: 120 }}
            />
          )}
        />
      </Box>

      {/* <DialogAddCard
        titleCard={title}
        statusCard={status}
        openAddCard={openAddCard}
        setOpenAddCard={setOpenAddCard}
      /> */}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default InputCard;
