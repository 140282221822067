import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Paper,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogUploadFile from "./DialogUploadFile";

const UpdateProfileUser = ({ classes }) => {
  const statusUserLogin = localStorage.getItem("status_user_login");
  const userLogin = JSON.parse(statusUserLogin);

  const [fotoUser, setForoUser] = useState("");
  const [triggerfoto, setTriggerFoto] = useState(null);
  const [openUploadFoto, setOpenUploadFoto] = useState(false);

  useEffect(() => {
    AxiosConfig.get(
      URL_API + `/account-management/member/${userLogin.member_id}/photo`,
      {
        responseType: "blob",
      }
    )
      .then(function (res) {
        // console.log("res photo user : ", res);

        setForoUser(res.data);
      })
      .catch(function (error) {
        // console.log("Error : ", error.response);
      });
  }, [triggerfoto]);

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([fotoUser], { type: "image/jpeg" });

  return (
    <Box mt={4}>
      <Paper className={classes.root} elevation={0}>
        <Box px={3}>
          <Box display="flex" alignItems="center">
            {fotoUser !== "" ? (
              <Avatar
                variant="circle"
                style={{ width: 55, height: 55 }}
                src={URL.createObjectURL(blobUrlImage)}
              />
            ) : (
              <AccountCircle style={{ color: "#888", fontSize: 60 }} />
            )}
            <Box mx={1.5} />
            <Typography variant="h4" className={classes.title555}>
              {userLogin?.member_first_name} {userLogin?.member_last_name}
            </Typography>
          </Box>

          <Box my={3}>
            <Divider />
          </Box>

          <Box>
            <Button
              variant="outlined"
              size="large"
              className={classes.button1}
              onClick={() => setOpenUploadFoto(true)}
            >
              Ubah Foto
            </Button>
          </Box>
        </Box>
      </Paper>

      <DialogUploadFile
        classes={classes}
        openUploadFoto={openUploadFoto}
        setOpenUploadFoto={setOpenUploadFoto}
        memberId={userLogin.member_id}
        setTriggerFoto={setTriggerFoto}
      />
    </Box>
  );
};

export default UpdateProfileUser;
