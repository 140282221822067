import React, { useState, useContext } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Box,
  Typography,
  Divider,
  Radio,
} from "@material-ui/core";

import classNames from "classnames";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";

const AnchorTaskMultiStatus = ({ classes }) => {
  const { selectTask, setSelectTask, setTriggerTask } =
    useContext(ContextProject);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    id: "",
    code: "",
    name: "",
    sort_order: 0,
  });
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const todosId = selectTask.map((el) => ({ id: el }));

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatus = (data) => {
    setSelectedStatus(data);
  };

  const handleSetStatus = () => {
    setLoading(true);

    const data = {
      Todolist: {
        options: { status: selectedStatus.id },
        todo: Object.assign({}, todosId),
      },
    };

    // console.log("DATA SET STATUS", data);

    AxiosConfig.put(`${URL_API}/todolist/batch-update-status-priority`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setLoading(false);
          handleClose();
          setSelectTask([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        handleClose();
        setSelectTask([]);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={handleShow}
      >
        Status
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <Box p={2} width={300}>
                <Typography variant="subtitle2" className={classes.title555}>
                  <b>Set Status</b>
                </Typography>
                <Divider />

                <Box display="flex" flexWrap="wrap" mt={3}>
                  {statusCollections.map((item, i) => {
                    const isCurrent = selectedStatus.code === item.code;

                    return (
                      <label
                        style={{ margin: 0, padding: 0, height: 37.5 }}
                        className={classes.justHover}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.statusItem}
                          style={{
                            backgroundColor: isCurrent ? item.bgColor : "#fff",
                            color: item.color,
                            border: `1px solid ${item.color}`,
                            margin: "0 5px 5px 0",
                          }}
                        >
                          <b>{item.name}</b>
                        </Typography>
                        <Radio
                          onChange={() => handleStatus(item)}
                          checked={item.id === selectedStatus.id}
                          value={item.id}
                          style={{
                            visibility: "hidden",
                            height: 0,
                            width: 0,
                            opacity: 0,
                          }}
                        />
                      </label>
                    );
                  })}
                </Box>

                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    className={classes.button}
                    style={{ fontSize: 12 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Box mx={0.5} />
                  <Button
                    size="small"
                    className={classes.button1}
                    variant={
                      selectedStatus.id === ""
                        ? "contained"
                        : loading
                        ? "contained"
                        : "outlined"
                    }
                    disabled={
                      selectedStatus.id === "" ? true : loading ? true : false
                    }
                    style={{ fontSize: 12 }}
                    onClick={handleSetStatus}
                  >
                    {loading ? "Applying Status..." : "Set Status"}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default AnchorTaskMultiStatus;

const statusCollections = [
  {
    id: "TO DO",
    code: "TO DO",
    name: "To Do",
    sort_order: 0,
    color: "#777777",
    bgColor: "#DFDFDF",
  },
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    sort_order: 1,
    color: "#4194AC",
    bgColor: "#CFEAF1",
  },
  {
    id: "DONE",
    code: "DONE",
    name: "Done",
    sort_order: 2,
    color: "#1E874D",
    bgColor: "#D9EBDD",
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    sort_order: 3,
    color: "#B68812",
    bgColor: "#F6EDC3",
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    sort_order: 4,
    color: "#92739F",
    bgColor: "#E2DAE5",
  },
];
