import React, { useCallback } from 'react';
import { Box, IconButton, InputAdornment, InputBase } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PictREFRESH from '../../../../assets/images/Group_2725.png';

import MomentUtils from '@date-io/moment';
import { useDebouncedCallback } from 'use-debounce/lib';
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

function TableFilter({
  classes,
  dataAttendanceList,
  setDataAttendanceList,
  getAttendanceList
}) {
  const [handleSearch] = useDebouncedCallback(
    useCallback((value) => {
      handleRefreshToFirstPage();
    }, []),
    1000,

    { maxWait: 5000 }
  );

  const handleRefresh = () => {
    getAttendanceList();
  };

  const handleRefreshToFirstPage = () => {
    let dataTemp = dataAttendanceList;
    dataTemp.options.paging.offset = 0;
    dataTemp.options.paging.page = 1;
    setDataAttendanceList({ ...dataTemp });
    getAttendanceList();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      pr={3}
    >
      <Box display="flex" alignItems="center">
        <div className={classes.search}>
          <InputBase
            startAdornment={
              <SearchIcon
                style={{ color: '#C4C4C4', fontSize: 18, marginLeft: 12 }}
              />
            }
            endAdornment={
              <IconButton
                size="small"
                onClick={() => {
                  let dataAttendanceListTemporary = dataAttendanceList;
                  dataAttendanceListTemporary.options.filter.search = '';
                  setDataAttendanceList({ ...dataAttendanceListTemporary });
                  handleRefreshToFirstPage();
                }}
              >
                <CancelIcon
                  style={{
                    color: '#C4C4C4',
                    fontSize: 18,
                    marginRight: 12
                  }}
                />
              </IconButton>
            }
            onChange={(e) => {
              let dataAttendanceListTemporary = dataAttendanceList;
              dataAttendanceListTemporary.options.filter.search =
                e.target.value;
              setDataAttendanceList({ ...dataAttendanceListTemporary });
              handleSearch(e.target.value);
            }}
            value={dataAttendanceList.options.filter.search}
            placeholder="Telusuri nama user..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'Search' }}
          />
        </div>
        <div className={classes.search}>
          <MuiPickersUtilsProvider utils={LocalizedUtils}>
            <DatePicker
              margin="normal"
              fullWidth
              value={dataAttendanceList.options.filter.date_moment}
              format="DD-MM-YYYY"
              onChange={(date) => {
                let dataAttendanceListTemporary = dataAttendanceList;
                dataAttendanceListTemporary.options.filter.date_moment = date;
                dataAttendanceListTemporary.options.filter.date =
                  date.format('YYYY-MM-DD');
                setDataAttendanceList({ ...dataAttendanceListTemporary });
                handleRefreshToFirstPage();
              }}
              className={classes.datepicker}
              autoOk={true}
              ampm={false}
              inputProps={{
                className: classes.titleDatepicker
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small">
                      <EventAvailableIcon
                        style={{
                          color: '#C4C4C4',
                          fontSize: 18,
                          marginLeft: 12
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <Box mx={1} />
      </Box>

      <Box display="flex" alignItems="center">
        <IconButton style={{ padding: 4 }} onClick={handleRefresh}>
          <img src={PictREFRESH} style={{ width: 17, height: 17 }} alt="." />
        </IconButton>
      </Box>
    </Box>
  );
}

export default TableFilter;
