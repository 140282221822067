import React, { Fragment } from 'react';
import './App.css';
import {
  /*
	  `````
		7WD
	  `````
	*/
  To7wdPeriode,
  ToAllMA,
  ToAllMADetail,
  ToArchive,
  /*
		TM
	*/
  ToAttendance,
  ToAttendanceListRequest,
  ToAttendanceSetting,
  ToAttendanceReportAdmin,
  ToCompanyProfile,
  ToCompanyProfileSetting,
  ToCompanySetting,
  ToCompletionCompanyStructure,
  ToCompletionCompanyStructureFormBantuSaya,
  ToCompletionCompanyStructureFormulirKosong,
  ToCompletionCompanyStructureQuestionONE,
  ToCompletionMemberhipOptions,
  ToCompletionProfile,
  ToDashboard,
  ToDrive,
  ToEmptyStateGeneral,
  ToForgotPassword,
  ToGoal,
  ToGoalDetail,
  ToHrEmployeeIdentitas,
  ToHrEmployeeIdentitasAlamat,
  ToHrEmployeeIdentitasInfoTambahan,
  ToHrEmployeeKeahlian,
  ToHrEmployeeKeluarga,
  ToHrEmployeeKepegawaianInfoPegawai,
  ToHrEmployeeKepegawaianInfoPosisi,
  ToHrEmployeeProfileDetail,
  ToHrEmployeeProfileDetailSeeDetail,
  ToHrEmployeeRiwayat,
  ToHrEmployeeRiwayatOrganisasi,
  ToHrEmployeeRiwayatPendidikanFormal,
  ToHrEmployeeRiwayatPendidikanINFORMAL,
  ToHrEmployeeRiwayatPengalamanKerja,
  ToHrEmployeeRiwayatUrgentContact,
  ToLogin,
  ToMaintenance,
  ToMeasuredActivityDetail,
  ToMembershipPaymentStatusAfterCountDown,
  ToMembershipStatus,
  ToMembershipStatusDeactivate,
  ToMembershipStatusIncreaseQuotaSEMENTARA,
  ToMembershipStatusManagePENDING_SEMENTARA,
  ToMembershipStatusManageSEMENTARA,
  ToMembershipStatusScenarioPENDING_SEMENTARA,
  ToNewSO,
  ToNewSoTreeViewChartPreview,
  ToNewSoTreeViewOrgChart,
  ToNewWelcome,
  ToNewWelcomeVerify,
  ToOrganigram,
  ToOTP,
  ToOvertime,
  ToOvertimeSetting,
  ToPlayground,
  ToPreForgetPassword,
  ToProject,
  ToRegister,
  ToReports,
  ToSubGoalDetail,
  ToTask,
  ToTimeOff,
  ToTimeOffSetting,
  ToException,
  ToUserManagement,
  ToUserManagementManagePassword,

  /*
		ATTENDANCE DASHBOARD
	*/
  ToAttendanceDashboard,
  ToMyAttendance,
  ToHistoryClockInOut,
  ToHistoryCheckInOut,
  ToCheckInCheckOut,
  ToTeam,
  ToInbox,
  ToProfile,
  ToProfilePresence,
  ToProfileAbsence,
  ToProfileChangePin,
  ToAttendanceReport,
  ToMySchedule,
  ToAttendanceTimeOff,
  ToAttendanceOvertime,
  ToRequestAttendance
} from './constants/config-redirect-url';
/*
	`````````````
	HEADER LAYOUT
  
	`````````````
*/
import Header from './layout/Header';
import HeaderAttendance from './layout/HeaderAttendance';
import HeaderCompanyProfile from './layout/HeaderCompanyProfile';
import HeaderGOAL from './layout/HeaderGOAL';
import HeaderHREmployee from './layout/HeaderHREmployee';
import HeaderHREmployeeKeahlian from './layout/HeaderHREmployeeKeahlian';
import HeaderHREmployeeKeluarga from './layout/HeaderHREmployeeKeluarga';
import HeaderHREmployeeKepegawaian from './layout/HeaderHREmployeeKepegawaian';
import HeaderHREmployeeRiwayat from './layout/HeaderHREmployeeRiwayat';
import HeaderHREmployeeUrgentContact from './layout/HeaderHREmployeeUrgentContact';
import HeaderMembershipManagement from './layout/HeaderMembershipManagement';
import HeaderMembershipOptions from './layout/HeaderMembershipOptions';
import HeaderProfileDetail from './layout/HeaderProfileDetail';
import HeadNewGeneral from './layout/HeadNewGeneral';
import ContextGlobalAllMA from './screen/7WD/AllMA/context/ContextAllMA';
/*
	``````````````````
	ALL MEASURED ACTIVITY
  
	`````````````````
*/
import ViewAllMA from './screen/7WD/AllMA/ViewAllMA';
import ViewAllMAMoreDetail from './screen/7WD/AllMA/ViewAllMAMoreDetail';
import ContextGlobalGoal from './screen/7WD/Goal/Context/ContextGlobalGoal';
import ContextGlobalGoalDetail from './screen/7WD/Goal/Context/ContextGlobalGoalDetail';
/*
	````
	GOAL
  
	````
*/
import ViewGoal from './screen/7WD/Goal/ViewGoal';
import ViewGoalMoreDetail from './screen/7WD/Goal/ViewGoalMoreDetail';
/*
``````````````````
MEASURED ACTIVITY

`````````````````
*/
import ViewMAMoreDetail from './screen/7WD/MeasureActivities/ViewMAMoreDetail';
/*
	````````````````````````````````````````````````
	7WD
  
	`````````````````````````````````````````````````
*/
import ViewPeriode from './screen/7WD/Periode/ViewPeriode';
import ViewInbox from './screen/AD/Inbox/ViewInbox';
import ViewMyAttendance from './screen/AD/MyAttendance/ViewMyAttendance';
import ViewAbsence from './screen/AD/Profile/Absence/ViewAbsence';
import ViewPresence from './screen/AD/Profile/Presence/ViewPresence';
import ViewProfile from './screen/AD/Profile/ViewProfile';
import ViewTeam from './screen/AD/Team/ViewTeam';
import ViewCompanyProfile from './screen/AM/CompanyProfile/Profile/ViewCompanyProfile';
import ViewCompanyProfileSetting from './screen/AM/CompanyProfile/ProfileSetting/ViewCompanyProfileSetting';
import ContextGlobalCompanySetting from './screen/AM/CompanySetting/Context/ContextGlobalCompanySetting';
import ViewCompanySetting from './screen/AM/CompanySetting/ViewCompanySetting';
import ViewMaintenance from './screen/AM/Dashboard/ViewMaintenance';
import ViewNewDashboard from './screen/AM/Dashboard/ViewNewDashboard';
// import ViewNewDashboardTwo from "./screen/AM/Dashboard/ViewNewDashboardTwo";

// import ViewPlayground from "./screen/AM/Dashboard/ViewPalyground";
import ViewCompletionMembershipOptions from './screen/AM/MembershipManagement/ViewCompletionMembershipOptions';
import ViewMembershipStatus from './screen/AM/MembershipManagement/ViewMembershipStatus';
import ViewMembershipStatusDeactivate from './screen/AM/MembershipManagement/ViewMembershipStatusDeactivate';
import ViewMembershipStatusKelola from './screen/AM/MembershipManagement/ViewMembershipStatusKelola';
import ViewMembershipStatusKelolaPending from './screen/AM/MembershipManagement/ViewMembershipStatusKelolaPending';
import ViewMembershipStatus_scenarioIncreaseQuota from './screen/AM/MembershipManagement/ViewMembershipStatus_scenarioIncreaseQuota';
import ViewMembershipStatus_scenarioPending from './screen/AM/MembershipManagement/ViewMembershipStatus_scenarioPending';
import ViewPaymentAfterCountDown from './screen/AM/MembershipManagement/ViewPaymentAfterCountDown';
import ViewCompletionProfileGroup from './screen/AM/ProfileCompletion/ViewCompletionProfileGroup';
import ViewCompletionCompanyStructure from './screen/AM/QuickSetup/ViewCompletionCompanyStructure';
import ViewCompletionCompanyStructureFormulirKosong from './screen/AM/QuickSetup/ViewCompletionCompanyStructureFormulirKosong';
import ViewCompletionCompanyStructureQuestionFirst from './screen/AM/QuickSetup/ViewCompletionCompanyStructureQuestionFirst';
import ViewCompletionCompanyStrutureFormBantuSaya from './screen/AM/QuickSetup/ViewCompletionCompanyStrutureFormBantuSaya';
import ViewChangePassword from './screen/AM/Signin/ViewChangePassword';
import ViewForgetPassword from './screen/AM/Signin/ViewForgetPassword';
import ViewSignin from './screen/AM/Signin/ViewSignin';
import ViewNewWelcome from './screen/AM/Signup/ViewNewWelcome';
import ViewNewWelcomeVerify from './screen/AM/Signup/ViewNewWelcomeVerify';
import ViewOTP from './screen/AM/Signup/ViewOTP';
/*
	````````````````````````
	ACCOUNT MANAGEMENT/ AM
  
	````````````````````````
*/
// import ViewSignin from './screen/AM/Signin/ViewSignin';//* Move to Lazy Component
import ViewRegister from './screen/AM/Signup/ViewRegister';
import ViewUser from './screen/AM/UserManagement/ViewUser';
import ViewUserAturKataSandi from './screen/AM/UserManagement/ViewUserAturKataSandi';
import ContextGlobalArchive from './screen/Archive/context/ContextGlobalArchive';
/*
	```````
	Archive
  
	```````
*/
import ViewArchive from './screen/Archive/ViewArchive';
import ContextGlobalDrive from './screen/Drive/context/ContextGlobalDrive';
/*
	```````
	DRIVE
  
	```````
*/
import ViewDrive from './screen/Drive/ViewDrive';
// import NotFoundPage from './screen/Explore/NotFoundPage';
import ViewBasicReactAndD3 from './screen/Explore/ViewBasicReactAndD3';
import About from './screen/Home/ComponentViewLandingPage/About';
import DemoSchedule from './screen/Home/ComponentViewLandingPage/DemoSchedule';
import GoalManagement from './screen/Home/ComponentViewLandingPage/GoalManagement';
import MeasuredActivity from './screen/Home/ComponentViewLandingPage/MeasuredActivity';
import Scoreboard from './screen/Home/ComponentViewLandingPage/Scoreboard';
import ViewBusinessSolution from './screen/Home/ViewBusinessSolution';
import ViewContact from './screen/Home/ViewContact';
import ViewHRD from './screen/Home/ViewHRD';
//*LAZY LOAD
// const ViewHome =  lazy(() => import('./screen/7WD/Home/ViewHome'))
// const ViewLandingPage = lazy(() => import("./screen/Home/ViewLandingPage"));
// const ViewSignin = lazy(() => import("./screen/AM/Signin/ViewSignin"));
import ViewLandingPage from './screen/Home/ViewLandingPage';
import ViewLogistics from './screen/Home/ViewLogistics';
import ViewOperational from './screen/Home/ViewOperational';
import ViewPricing from './screen/Home/ViewPricing';
import ViewSales from './screen/Home/ViewSales';
import ViewSupport from './screen/Home/ViewSupport';
import ViewTestimoni from './screen/Home/ViewTestimoni';
/*
	```````````````````
	HUMAN RESOURCE / HR
  
	```````````````````
*/
import ViewEmpty from './screen/HR/Empty/ViewEmpty';
import ViewIdentitas from './screen/HR/Identitas/ViewIdentitas';
import ViewIdentitasAlamat from './screen/HR/Identitas/ViewIdentitasAlamat';
import ViewIdentitasInfoTambahan from './screen/HR/Identitas/ViewIdentitasInfoTambahan';
import ViewKeahlian from './screen/HR/Keahlian/ViewKeahlian';
import ViewKeluarga from './screen/HR/Keluarga/ViewKeluarga';
import ViewInfoPegawai from './screen/HR/Kepegawaian/ViewInfoPegawai';
import ViewInfoPosisi from './screen/HR/Kepegawaian/ViewInfoPosisi';
import ViewUrgentContact from './screen/HR/KontakDarurat/ViewUrgentContact';
import ViewOrganigram from './screen/HR/Organigram/ViewOrganigram';
import ViewProfileDetail from './screen/HR/ProfileEmployee/ViewProfileDetail';
import ViewProfileDetailSeeDetail from './screen/HR/ProfileEmployeeSeeDetail/ViewProfileDetail';
import ViewRiwayat from './screen/HR/Riwayat/ViewRiwayat';
import ViewRiwayatOrganisasi from './screen/HR/Riwayat/ViewRiwayatOrganisasi';
import ViewRiwayatPendidikanFormal from './screen/HR/Riwayat/ViewRiwayatPendidikanFormal';
import ViewRiwayatPendidikanINFORMAL from './screen/HR/Riwayat/ViewRiwayatPendidikanINFORMAL';
import ViewRiwayatPengalamanKerja from './screen/HR/Riwayat/ViewRiwayatPengalamanKerja';
import ContextGlobalProject from './screen/Project/context/ContextGlobalProject';
/*
	```````
	Project
  
	```````
*/
import ViewProject from './screen/Project/ViewProject';
import ViewProjectDetail from './screen/Project/ViewProjectDetail';
import ContextGlobalReports from './screen/Report/context/ContextGlobalReports';
/*
	```````
	Reports
  
	```````
*/
import ViewReports from './screen/Report/ViewReports';
import ContextGlobalNewSO from './screen/SO/Context/ContextGlobalNewSO';
/*
	````````````````````````````````````````````````
	HUMAN RESOURCE - STRUCTURE ORGANIZATION / HR-SO
  
	`````````````````````````````````````````````````
*/
import ViewNewSO from './screen/SO/ViewNewSO';
import ViewNewSOTreeview from './screen/SO/ViewNewSOTreeview';
import ViewOrgChart from './screen/SO/ViewOrgChart';
/*
	````````````````````````
	CONTEXT API GLOBAL STATE
  
	````````````````````````
*/
import ContextGlobalTask from './screen/SPECIAL/Context/ContextGlobalTask';
/*
	```````
	SPECIAL
  
	```````
*/
// import ViewTask from "./screen/SPECIAL/ViewTask";
import ViewTask from './screen/SPECIAL/ViewTaskNew';
import ContextGlobalAttendance from './screen/TM/Attendance/Context/ContextGlobalAttendance';
/*
	```````
	TIME MANAGEMENT - Attendance
  
	```````
*/
import ViewAttendance from './screen/TM/Attendance/ViewAttendance';
import ContextGlobalAttendanceListRequest from './screen/TM/AttendanceListRequest/Context/ContextGlobalAttendanceListRequest';
import ViewAttendanceListRequest from './screen/TM/AttendanceListRequest/ViewAttendanceListRequest';
import ContextGlobalAttendanceSetting from './screen/TM/AttendanceSetting/Context/ContextGlobalAttendanceSetting';
import ViewAttendanceSetting from './screen/TM/AttendanceSetting/ViewAttendanceSetting';
import ContextGlobalOvertime from './screen/TM/Overtime/Context/ContextGlobalOvertime';
import ViewAttendanceReportAdmin from './screen/TM/AttendanceReportAdmin/ViewAttendanceReportAdmin';
import ContextGlobalAttendanceReportAdmin from './screen/TM/AttendanceReportAdmin/Context/ContextGlobalAttendanceReportAdmin';
/*
	```````
	TIME MANAGEMENT  - Overtime
  
	```````
*/
import ViewOvertime from './screen/TM/Overtime/ViewOvertime';
import ContextGlobalOvertimeSetting from './screen/TM/OvertimeSetting/Context/ContextGlobalOvertimeSetting';
import ViewOvertimeSetting from './screen/TM/OvertimeSetting/ViewOvertimeSetting';
import ContextGlobalTimeOff from './screen/TM/TimeOff/Context/ContextGlobalTimeOff';
/*
	```````
	TIME MANAGEMENT - Time Off
  
	```````
*/
import ViewTimeOff from './screen/TM/TimeOff/ViewTimeOff';
import ContextGlobalTimeOffCategoryAllowance from './screen/TM/TimeOffCategoryAllowance/Context/ContextGlobalTimeOffCategoryAllowance';
import ViewTimeOffCategoryAllowance from './screen/TM/TimeOffCategoryAllowance/ViewTimeOffCategoryAllowance';
import ContextGlobalTimeOffSetting from './screen/TM/TimeOffSetting/Context/ContextGlobalTimeOffSetting';
import ViewTimeOffSetting from './screen/TM/TimeOffSetting/ViewTimeOffSetting';
/*
	```````
	TIME MANAGEMENT - Exception
  
	```````
*/
import ViewException from './screen/TM/Exception/ViewException';
import ContextGlobalException from './screen/TM/Exception/Context/ContextGlobalException';
import ViewCheckInCheckOut from './screen/AD/CheckInCheckOut/ViewCheckInCheckOut';
/*
  ```````
  ATTENDANCE DASHBOARD - AD

  ```````
*/
import ContextGlobalAttendanceDashboard from './screen/AD/Context/ContextGlobalAttendanceDashboard';
import ViewHistoryClockInOut from './screen/AD/History/ClockInOut/ViewHistoryClockInOut';
import ViewHistoryCheckInOut from './screen/AD/History/CheckInOut/ViewHistoryCheckInOut';
import ViewAttendanceHome from './screen/AD/Home/ViewAttendanceHome';
import ViewAttendanceReport from './screen/AD/Report/ViewAttendanceReport';
import ViewMySchedule from './screen/AD/MySchedule/ViewMySchedule';
import ViewAttendanceTimeOff from './screen/AD/TimeOff/ViewAttendanceTimeOff';
import ViewAttendanceOvertime from './screen/AD/Overtime/ViewAttendanceOvertime';
import ViewRequestAttendance from './screen/AD/RequestAttendance/ViewRequestAttendance';
import ViewChangePin from './screen/AD/Profile/Pin/ViewChangePin';
import ViewAttendanceAccount from './screen/AD/Account/ViewAttendanceAccount';

export const routes = {
  // HOME WEB

  '/': () => <ViewLandingPage />,
  '/marketing': () => <ViewBusinessSolution />,
  '/hrd': () => <ViewHRD />,
  '/sales': () => <ViewSales />,
  '/logistics': () => <ViewLogistics />,
  '/operational': () => <ViewOperational />,
  '/price': () => <ViewPricing />,
  '/testimoni': () => <ViewTestimoni />,
  '/support': () => <ViewSupport />,
  '/contact': () => <ViewContact />,
  '/about': () => <About />,

  //*
  '/demo-schedule': () => <DemoSchedule />,
  '/feature/goal-management': () => <GoalManagement />,
  '/feature/measured-activity': () => <MeasuredActivity />,
  '/feature/scoreboard': () => <Scoreboard />,

  '/explore': () => <ViewBasicReactAndD3 />,

  [ToLogin]: () => (
    <Fragment>
      <Header />
      <ViewSignin />
    </Fragment>
  ),

  [ToRegister]: () => (
    <Fragment>
      <Header />
      <ViewRegister />
    </Fragment>
  ),

  [ToPreForgetPassword]: () => <ViewForgetPassword />,
  [ToForgotPassword]: () => <ViewChangePassword />,

  [ToOTP]: () => (
    <Fragment>
      <Header />
      <ViewOTP />
    </Fragment>
  ),

  [ToNewWelcome]: () => (
    <Fragment>
      <Header />
      <ViewNewWelcome />
    </Fragment>
  ),

  [ToNewWelcomeVerify]: () => (
    <Fragment>
      <Header />
      <ViewNewWelcomeVerify />
    </Fragment>
  ),

  [ToDashboard]: () => (
    <Fragment>
      <HeaderGOAL />
      {/* <ViewHome /> */}
      <ViewNewDashboard />
    </Fragment>
  ),

  // [ToPlayground]: () => (
  //   <Fragment>
  //     <HeaderGOAL />
  //     {/* <ViewHome /> */}
  //     <ViewPlayground />
  //   </Fragment>
  // ),

  [ToMaintenance]: () => (
    <div>
      {/* <HeadNewGeneral /> */}
      <ViewMaintenance />
    </div>
  ),

  [ToMembershipStatus]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatus />
    </div>
  ),

  [ToMembershipStatusDeactivate]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatusDeactivate />
    </div>
  ),

  [ToMembershipPaymentStatusAfterCountDown]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewPaymentAfterCountDown />
    </div>
  ),

  [ToMembershipStatusIncreaseQuotaSEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatus_scenarioIncreaseQuota />
    </div>
  ),

  [ToMembershipStatusScenarioPENDING_SEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatus_scenarioPending />
    </div>
  ),

  [ToMembershipStatusManageSEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatusKelola />
    </div>
  ),

  [ToMembershipStatusManagePENDING_SEMENTARA]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewMembershipStatusKelolaPending />
    </div>
  ),

  [ToCompletionMemberhipOptions]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionMembershipOptions />
    </div>
  ),

  [ToCompletionProfile]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionProfileGroup />
    </div>
  ),

  [ToCompletionCompanyStructureQuestionONE]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStructureQuestionFirst />
    </div>
  ),

  [ToCompletionCompanyStructure]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStructure />
    </div>
  ),

  [ToCompletionCompanyStructureFormulirKosong]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStructureFormulirKosong />
    </div>
  ),

  [ToCompletionCompanyStructureFormBantuSaya]: () => (
    <div>
      <HeaderMembershipOptions />
      <ViewCompletionCompanyStrutureFormBantuSaya />
    </div>
  ),

  [ToUserManagement]: () => (
    <div>
      <HeaderGOAL />
      <ViewUser />
    </div>
  ),

  [ToUserManagementManagePassword]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewUserAturKataSandi />
    </div>
  ),

  [ToCompanyProfile]: () => (
    <div>
      <HeaderCompanyProfile />
      <ViewCompanyProfile />
    </div>
  ),

  [ToCompanyProfileSetting]: () => (
    <div>
      <HeaderMembershipManagement />
      <ViewCompanyProfileSetting />
    </div>
  ),

  [ToCompanySetting]: () => (
    <div>
      <ContextGlobalCompanySetting>
        <HeaderGOAL />
        <ViewCompanySetting />
      </ContextGlobalCompanySetting>
    </div>
  ),

  [ToEmptyStateGeneral]: () => (
    <div>
      <HeaderGOAL />
      <ViewEmpty />
    </div>
  ),

  [ToHrEmployeeIdentitas]: () => (
    <div>
      <HeaderHREmployee />
      <ViewIdentitas />
    </div>
  ),

  [ToHrEmployeeIdentitasAlamat]: () => (
    <div>
      <HeaderHREmployee />
      <ViewIdentitasAlamat />
    </div>
  ),

  [ToHrEmployeeIdentitasInfoTambahan]: () => (
    <div>
      <HeaderHREmployee />
      <ViewIdentitasInfoTambahan />
    </div>
  ),

  [ToHrEmployeeKeluarga]: () => (
    <div>
      <HeaderHREmployeeKeluarga />
      <ViewKeluarga />
    </div>
  ),

  [ToHrEmployeeKepegawaianInfoPegawai]: () => (
    <div>
      <HeaderHREmployeeKepegawaian />
      <ViewInfoPegawai />
    </div>
  ),

  [ToHrEmployeeKepegawaianInfoPosisi]: () => (
    <div>
      <HeaderHREmployeeKepegawaian />
      <ViewInfoPosisi />
    </div>
  ),

  [ToHrEmployeeKeahlian]: () => (
    <div>
      <HeaderHREmployeeKeahlian />
      <ViewKeahlian />
    </div>
  ),

  [ToHrEmployeeRiwayat]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayat />
    </div>
  ),

  [ToHrEmployeeRiwayatPendidikanFormal]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatPendidikanFormal />
    </div>
  ),

  [ToHrEmployeeRiwayatPendidikanINFORMAL]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatPendidikanINFORMAL />
    </div>
  ),

  [ToHrEmployeeRiwayatOrganisasi]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatOrganisasi />
    </div>
  ),

  [ToHrEmployeeRiwayatPengalamanKerja]: () => (
    <div>
      <HeaderHREmployeeRiwayat />
      <ViewRiwayatPengalamanKerja />
    </div>
  ),

  [ToHrEmployeeRiwayatUrgentContact]: () => (
    <div>
      <HeaderHREmployeeUrgentContact />
      <ViewUrgentContact />
    </div>
  ),

  [ToHrEmployeeProfileDetail]: () => (
    <div>
      <HeaderProfileDetail />
      <ViewProfileDetail />
    </div>
  ),

  [ToHrEmployeeProfileDetailSeeDetail]: () => (
    <div>
      <HeaderProfileDetail />
      <ViewProfileDetailSeeDetail />
    </div>
  ),

  /*
      ``````````````
      ORGANIGRAM/ SO

      ``````````````
  */
  [ToNewSoTreeViewChartPreview]: () => <ViewNewSOTreeview />,

  [ToNewSoTreeViewOrgChart]: () => (
    <ContextGlobalNewSO>
      <ViewOrgChart />
    </ContextGlobalNewSO>
  ),

  [ToOrganigram]: () => (
    <div>
      <HeadNewGeneral />
      <ViewOrganigram />
    </div>
  ),

  [ToNewSO]: () => (
    <div>
      <ViewNewSO />
    </div>
  ),

  [To7wdPeriode]: () => (
    <div>
      <HeaderGOAL />
      <ViewPeriode />
    </div>
  ),

  [ToGoal]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoal>
        <ViewGoal />
      </ContextGlobalGoal>
    </div>
  ),

  [ToGoalDetail]: () => (
    // "/goal-detail/:id" : ({id}) => (

    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail />
      </ContextGlobalGoalDetail>
    </div>
  ),

  '/goal-detail/:id': ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail id={id} />
      </ContextGlobalGoalDetail>
    </div>
  ),

  '/sub-goal-detail/:id': ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail id={id} />
      </ContextGlobalGoalDetail>
    </div>
  ),

  '/ma*': () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail />
      </ContextGlobalGoalDetail>
    </div>
  ),

  '/ma/:id': ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail id={id} />
      </ContextGlobalGoalDetail>
    </div>
  ),

  [ToSubGoalDetail]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalGoalDetail>
        <ViewGoalMoreDetail />
      </ContextGlobalGoalDetail>
    </div>
  ),

  [ToMeasuredActivityDetail]: () => (
    //*Tidak di pakai

    <div>
      <HeaderGOAL />
      <ViewMAMoreDetail />
    </div>
  ),

  [ToAllMA]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAllMA>
        <ViewAllMA />
      </ContextGlobalAllMA>
    </div>
  ),

  [ToAllMADetail]: () => (
    // "/allma-detail/:id" : ({id}) => (

    <div>
      <HeaderGOAL />
      <ContextGlobalAllMA>
        <ViewAllMAMoreDetail />
        {/* <ViewMAMoreDetail /> */}
      </ContextGlobalAllMA>
    </div>
  ),

  '/allma-detail/:id': ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAllMA>
        <ViewAllMAMoreDetail id={id} />
        {/* <ViewMAMoreDetail id={id} /> */}
      </ContextGlobalAllMA>
    </div>
  ),

  [ToReports]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalReports>
        <ViewReports />
      </ContextGlobalReports>
    </div>
  ),

  [ToProject]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalProject>
        <ViewProject />
      </ContextGlobalProject>
    </div>
  ),

  '/project/:id': ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalProject>
        <ViewProjectDetail id={id} />
      </ContextGlobalProject>
    </div>
  ),

  [ToArchive]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalArchive>
        <ViewArchive />
      </ContextGlobalArchive>
    </div>
  ),

  [ToDrive]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalDrive>
        <ViewDrive />
      </ContextGlobalDrive>
    </div>
  ),

  '/drive/:folder': ({ folder }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalDrive>
        <ViewDrive folder={folder} />
      </ContextGlobalDrive>
    </div>
  ),

  [ToTask]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalTask>
        <ViewTask />
      </ContextGlobalTask>
    </div>
  ),

  [ToAttendance]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAttendance>
        <ViewAttendance />
      </ContextGlobalAttendance>
    </div>
  ),

  [ToAttendanceSetting]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAttendanceSetting>
        <ViewAttendanceSetting />
      </ContextGlobalAttendanceSetting>
    </div>
  ),

  [ToAttendanceListRequest]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAttendanceListRequest>
        <ViewAttendanceListRequest />
      </ContextGlobalAttendanceListRequest>
    </div>
  ),

  [ToAttendanceReportAdmin]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalAttendanceReportAdmin>
        <ViewAttendanceReportAdmin />
      </ContextGlobalAttendanceReportAdmin>
    </div>
  ),

  [ToOvertime]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalOvertime>
        <ViewOvertime />
      </ContextGlobalOvertime>
    </div>
  ),

  [ToOvertimeSetting]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalOvertimeSetting>
        <ViewOvertimeSetting />
      </ContextGlobalOvertimeSetting>
    </div>
  ),

  [ToTimeOff]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalTimeOff>
        <ViewTimeOff />
      </ContextGlobalTimeOff>
    </div>
  ),

  [ToTimeOffSetting]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalTimeOffSetting>
        <ViewTimeOffSetting />
      </ContextGlobalTimeOffSetting>
    </div>
  ),

  '/time-off/category/:id': ({ id }) => (
    <div>
      <HeaderGOAL />
      <ContextGlobalTimeOffCategoryAllowance>
        <ViewTimeOffCategoryAllowance id={id} />
      </ContextGlobalTimeOffCategoryAllowance>
    </div>
  ),

  [ToException]: () => (
    <div>
      <HeaderGOAL />
      <ContextGlobalException>
        <ViewException />
      </ContextGlobalException>
    </div>
  ),

  /*
      ``````````````
      ATTENDANCE DASHBOARD || AD

      ``````````````
  */
  [ToAttendanceDashboard]: () => (
    <div>
      <HeaderGOAL />
      <ViewAttendanceHome />
    </div>
  ),
  [ToMyAttendance]: () => (
    <div>
      <HeaderGOAL />
      <ViewMyAttendance />
    </div>
  ),
  [ToHistoryClockInOut]: () => (
    <div>
      <HeaderGOAL />
      <ViewHistoryClockInOut />
    </div>
  ),
  [ToHistoryCheckInOut]: () => (
    <div>
      <HeaderGOAL />
      <ViewHistoryCheckInOut />
    </div>
  ),
  [ToCheckInCheckOut]: () => (
    <div>
      <HeaderGOAL />
      <ViewCheckInCheckOut />
    </div>
  ),
  [ToTeam]: () => (
    <div>
      <HeaderAttendance />
      <ViewTeam />
    </div>
  ),
  [ToInbox]: () => (
    <div>
      <HeaderAttendance />
      <ViewInbox />
    </div>
  ),
  [ToProfile]: () => (
    <div>
      <HeaderGOAL />
      <ViewAttendanceAccount />
    </div>
  ),
  [ToAttendanceReport]: () => (
    <div>
      <HeaderGOAL />
      <ViewAttendanceReport />
    </div>
  ),
  [ToMySchedule]: () => (
    <div>
      <HeaderGOAL />
      <ViewMySchedule />
    </div>
  ),
  [ToAttendanceTimeOff]: () => (
    <div>
      <HeaderGOAL />
      <ViewAttendanceTimeOff />
    </div>
  ),
  [ToAttendanceOvertime]: () => (
    <div>
      <HeaderGOAL />
      <ViewAttendanceOvertime />
    </div>
  ),
  [ToRequestAttendance]: () => (
    <div>
      <HeaderGOAL />
      <ViewRequestAttendance />
    </div>
  )
};
