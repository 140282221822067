import { fade } from '@material-ui/core/styles';

export const styles = (theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    width: '100%',
    maxWidth: 370,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  rootPreview: {
    height: 264,
    flexGrow: 1,
    width: '100%',
    maxWidth: 270,

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  small: {
    width: 32,
    height: 32,
    marginLeft: theme.spacing(2),
    // margin : theme.spacing(0.5),
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  title333: {
    fontWeight: 'normal',
    color: '#333',
    fontFamily: "'Lato', sans-serif",
  },
  title555: {
    fontWeight: 'normal',
    color: '#555',
    fontFamily: "'Lato', sans-serif",
  },
  title888: {
    fontWeight: 'normal',
    color: '#888',
    fontFamily: "'Lato', sans-serif",
  },

  outlinedTabActive: {
    borderBottom: `3px solid #d64253`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: '1px 2px',
    borderRadius: 3,
  },

  outlinedTabNotActive: {
    borderBottom: `3px solid transparent`,
    borderTop: `1px solid transparent`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    padding: '1px 2px',
    borderRadius: 3,
  },

  colorPrimary: {
    colorPrimary: 'magenta',
  },
  horizontalLine: {
    //*line TIDUR HORIZONTAL

    position: 'relative',
    '&::after': {
      content: "''",
      width: '16px',
      position: 'absolute',
      left: -12,
      // left: 32,
      top: '50%',
      zIndex: 1,
      borderBottom: `1px solid ${fade(
        theme.palette.text.primary,
        0.4
      )}`,
    },
  },

  horizontalWithoutLine: {
    position: 'relative',

    '&::after': {
      content: "''",
      width: '16px',
      position: 'absolute',
      left: -12,
      top: '50%',
      zIndex: 1,
    },
  },
  button: {
    fontFamily: "'Lato', sans-serif",
    textTransform: 'capitalize',
    padding: `4px 16px`,
    color: '#d64253',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    '&:hover': {
      backgroundColor: '#f9e3e6',
      border: '1px solid #d64253',
    },
  },
  button1: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 5,
    textTransform: 'capitalize',
    padding: `4px 16px`,
    color: '#fff',
    backgroundColor: '#d64253',
    '&:hover': {
      backgroundColor: '#DE6775',
    },
  },
  button2: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 5,
    textTransform: 'capitalize',
    padding: `4px 16px`,
    backgroundColor: '#fff',
    border: '1px solid #B1B1B1',
    color: '#5B5B5B',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  buttonOutlined: {
    fontFamily: 'Lato',
    textTransform: 'capitalize',
  },
  btnSmallTrans: {
    backgroundColor: 'transparent',
    color: '#888',
    fontFamily: 'Roboto',
    border: 0,
    fontSize: 14,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  uploadBtn: {
    fontFamily: "'Lato', sans-serif",
    color: '#00ACE6',
    backgroundColor: 'rgba(0, 172, 230, 0.1)',
    borderRadius: 5,
    padding: '10px, 3px',
    border: 'none',
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'capitalize',
    '&:hover': {
      opacity: 0.9,
    },
  },
  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
    background: '#edcfd8', //*#d64253
    borderBottom: '0.5em solid red',
    // borderLeft: '0.1em solid #c3c0c0de',
  },

  /*
      `````````````
      TREE ITEM CSS

      `````````````
    */
  content: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  group: {
    marginTop: -8,
    marginLeft: 180,
    marginRight: -240,

    paddingLeft: 16,
    borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    fontFamily: 'Lato',
  },
  popperClose: {
    pointerEvents: 'none',
  },
  menuList: {
    padding: '0',
  },
  dropdown: {
    borderRadius: '3px',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
    top: '100%',
    zIndex: 1000,
    minWidth: 140,
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundClip: 'padding-box',
  },
  dropdownItem: {
    fontWeight: '300',
    lineHeight: '1.5em',
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    height: 'fit-content',
    color: '#333',
    whiteSpace: 'nowrap',
    minHeight: 'unset',
  },
  dropdownDividerItem: {
    margin: '5px 0',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '1px',
    overflow: 'hidden',
  },
  popperResponsive: {
    zIndex: '1200',
    [theme.breakpoints.down('sm')]: {
      zIndex: '1640',
      position: 'static',
      float: 'none',
      width: 'auto',
      marginTop: '0',
      backgroundColor: 'transparent',
      border: '0',
      boxShadow: 'none',
      color: 'black',
    },
  },
  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: 'center',
    padding: 15,
    border: `2px dashed #DFDFDF`,
    color: '#808080',

    marginBottom: 5,
    marginTop: 10,

    '&:focus': {
      color: 'transparent',
      backgroundColor: 'transparent',
    },
  },
});
