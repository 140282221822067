import React, { useContext, useState } from "react";
import { MenuItem, FormControl, Select } from "@material-ui/core";

import { BootstrapInput } from "./BootstrapInput";
import ContextAttendanceReportAdmin from "../../../../Context/ContextAttendanceReportAdmin";

const MenuSelects = ({ classes, listGroup }) => {
  const context = useContext(ContextAttendanceReportAdmin);
  const [group, setGroup] = useState("staff_name");

  const handleGroup = (event) => {
    let dataTemporary = context.dataSettingSummary;
    setGroup(event.target.value);
    dataTemporary.group = event.target.value;
    context.setDataSettingSummary({...dataTemporary});
  };

  return (
    <FormControl>
      <Select value={group} onChange={handleGroup} input={<BootstrapInput />}>
        {listGroup.map((item, i) => (
          <MenuItem
            key={i}
            dense
            value={item.val}
            selected={i === 0}
            className={classes.hoverItem}>
            {item.name} 
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MenuSelects;
