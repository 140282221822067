import React, { useContext } from "react";
import { IconButton, TableCell, TableHead, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ContextAttendanceReportAdmin from "../../../Context/ContextAttendanceReportAdmin";

const TableMainHead = ({ classes }) => {
  const context = useContext(ContextAttendanceReportAdmin);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            borderTopLeftRadius: "8.5px",
            color: "#fff",
            fontSize: "14px",
            width: "2%"
          }}
        >
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            width: "13%",
            wordBreak: "break-all"
          }}
        >
          {context.dataSettingSummary.group_selected === "staff_name" && (
            <b>Name</b>
          )}
          {context.dataSettingSummary.group_selected === "position" && (
            <b>Position</b>
          )}
          {context.dataSettingSummary.group_selected === "unit" && (
            <b>Unit</b>
          )}
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Shift</b>
        </TableCell>
        {(context.dataSettingSummary.group_selected === "position" || context.dataSettingSummary.group_selected === "unit") && (
          <TableCell
            rowSpan={2}
            className={classes.titleHead}
            style={{
              backgroundColor: "rgb(209, 53, 74)",
              color: "#fff",
              fontSize: "14px",
            }}
          >
            <b>Staff Count</b>
          </TableCell>
        )}
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Duration</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Presence</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Absence</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            width: "5%",
            wordBreak: "break-all"
          }}
        >
          <b>Sick</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            width: "5%"
          }}
        >
            <b>Annual Leave</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            width: "5%"
          }}
        >
          <b>Special Leave</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Permission</b>
        </TableCell>
        <TableCell
          colSpan={3}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            textAlign: "center"
          }}
        >
          <b>Clock-In</b>
        </TableCell>
        <TableCell
          colSpan={3}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            textAlign: "center"
          }}
        >
          <b>Clock-Out</b>
        </TableCell>
        <TableCell
          rowSpan={2}
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            borderTopRightRadius: "8.5px",
            color: "#fff",
            fontSize: "14px",
            width: "2%"
          }}
        >
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Late</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
            <b>On Time</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Early</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Late</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
            <b>On Time</b>
        </TableCell>
        <TableCell
          className={classes.titleHead}
          style={{
            backgroundColor: "rgb(209, 53, 74)",
            color: "#fff",
            fontSize: "14px",
            wordBreak: "break-all"
          }}
        >
          <b>Early</b>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableMainHead;
