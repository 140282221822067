import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
  useContext
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Button,
  Box,
  List,
  FormControlLabel,
  Radio,
  TextField,
  CircularProgress,
  Breadcrumbs,
  InputAdornment,
  createMuiTheme,
  Checkbox
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import MicRoundedIcon from '@material-ui/icons/MicRounded';

import clsx from 'clsx';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { useDebouncedCallback } from 'use-debounce/lib';

import axiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';

import ContextAllMA from '../context/AllMaContext';
import ListFiles from './DriveComponent/ListFiles';
import DriveEvidence from './DriveComponent/DriveEvidence';
import FileDriveSelected from './DriveComponent/FileDriveSelected';
import PasteClipboard from '../../../../components/PasteClipboard';

import dragDropImg from '../../../../assets/images/dragToUpload.png';
import DialogError from '../../../../components/DialogError';
import DialogErrorGR from '../../../../components/DialogErrorGR';
import DialogAudio from './DialogAudio';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

function DialogAddResultMA({
  openDialogAddResultMA,
  setOpenDialogAddResultMA,
  classes,
  maDetailState
}) {
  const maxSize = 31457280;
  let inputRefSearch = useRef(null);
  const context = useContext(ContextAllMA);

  // Super Admin  => role
  const user_login = localStorage.getItem('status_user_login');
  const superAdmin = JSON.parse(user_login);
  const role = superAdmin.userRole[0].name;

  /* ====================================================
    LOCAL STATE
    ==================================================== */

  const [maResult, setMAResult] = useState(0);
  const [comment, setComment] = useState('');
  const [isEvidenceRequired, setIsEvidenceRequired] = useState(0);
  const [showPercent, setShowPercent] = useState(false);
  const [selectedDueDate, handleChangeDueDate] = useState(moment());
  const [addEvidenceCheck, setAddEvidenceCheck] = useState(false);
  const [files, setFiles] = useState([]);
  const [lengthFiles, setLengthFiles] = useState(0);

  const [evidenceCategoryCollections, setEvidenceCategoryCollections] =
    useState([]);
  const [selectEvidenceCategory, setSelectEvidenceCategory] = useState({
    id: 'f436ea12-c1ec-4f1f-a0d0-c8e1ad9763ab',
    name: 'Attachment',
    code: 'swd_ma_result_evidence_category_attachment',
    sort_order: 0
  });

  const [loadingEvidence, setLoadingEvidence] = useState(false);
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [triggerNavigate, setTriggerNavigate] = useState(null);

  const [openVoiceRecorder, setOpenVoiceRecorder] = useState(false);

  const [listFolders, setListFolders] = useState([]);
  const [listFiles, setListFiles] = useState([]);
  const [hyperlink, setHyperlink] = useState('');
  const [fileDrive, setFileDrive] = useState([]);

  const [driveSearchValue, setDriveSearchValue] = useState('');
  const [isSearchRun, setSearchRun] = useState(false);
  const [clearSearchResult, setClearSearchResult] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  /* ====================================================
    Side Effect
  ==================================================== */
  useEffect(() => {
    if (openDialogAddResultMA === true) {
      setMAResult('');
      setComment('');

      if (
        maDetailState.valueType.id === '2' &&
        maDetailState.valueType.code === 'percentage'
      ) {
        setShowPercent(true);
      }

      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity/${maDetailState.id}/result/create`
        )
        .then((response) => {
          // console.log("Add MAR Form", response);

          if (response.status === 200) {
            const result = response.data.data;

            setEvidenceCategoryCollections(result.evidenceCategoryCollections);
            setIsEvidenceRequired(result.isEvidenceRequired);

            if (result.isEvidenceRequired === 1) {
              setAddEvidenceCheck(true);
              setLengthFiles(1);
            }
          }
        })
        .catch((error) => {
          // console.log("error : ", error);
        });
    }
  }, [openDialogAddResultMA]);

  // =============================== Handle Revoke File ===============================
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  // =============================== Handle GET List Folders ===============================
  useEffect(() => {
    if (openDialogAddResultMA) {
      let gre_id_folder = localStorage.getItem('gre_id_folder');
      gre_id_folder = gre_id_folder ? gre_id_folder.split(',') : [];

      if (role === 'superadmin') {
        setLoadingFolder(true);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
              gre_id_folder.length === 0
                ? 'root'
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // // console.log("List Folder", response);

            const result = response.data.data.filter(
              (fldr) =>
                fldr.status_id === '2caec8df-bc0c-4463-9887-9f9af6236bc9' // 33984fe3-3018-43a0-8d8b-5a64d23c4643 (restored)
            );

            if (response.status === 200) {
              setListFolders(result);
            }
            setLoadingFolder(false);
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error", error);
          });
      } else {
        setLoadingFolder(true);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
              gre_id_folder.length === 0
                ? ''
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // console.log("List Folder", response);

            if (response.status === 200) {
              if (gre_id_folder.length === 0) {
                const partedFolders = response.data.data
                  .map((folder) => {
                    // Filter Check if id & folder_parent_id is equal
                    const filtering = response.data.data.filter(
                      (item) => item.id === folder.folder_parent_id
                    );

                    // Check if parent_id doesn't match with any id
                    if (filtering.length === 0) {
                      return { ...folder, folder_parent_id: null };
                    }
                  })
                  .filter((item) => item !== undefined)
                  .filter(
                    (fldr) =>
                      fldr.status_id === '2caec8df-bc0c-4463-9887-9f9af6236bc9' // 33984fe3-3018-43a0-8d8b-5a64d23c4643 (restored)
                  );

                setListFolders(partedFolders);
              } else {
                setListFolders(response.data.data);
              }
            }
            setLoadingFolder(false);
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error", error);
          });
      }
    }
  }, [openDialogAddResultMA, triggerNavigate]);

  // =============================== Handle GET List Files ===============================
  useEffect(() => {
    if (openDialogAddResultMA) {
      let gre_id_folder = localStorage.getItem('gre_id_folder');
      gre_id_folder = gre_id_folder ? gre_id_folder.split(',') : [];

      if (role === 'superadmin') {
        setLoadingFile(true);

        axiosConfig
          .get(
            `${URL_API}/drive/file?options[filter][folder_id]=${
              gre_id_folder.length === 0
                ? 'root'
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // // console.log("List File", response);

            const result = response.data.data.filter(
              (file) =>
                file.status_id === 'a9821d66-e047-4a11-8c0e-beb316a01d87' // cbb1d326-8643-40ca-a808-07ffacba0422 (restored)
            );

            if (response.status === 200) {
              setListFiles(result);
            }
            setLoadingFile(false);
          })
          .catch((error) => {
            setLoadingFile(false);
            // console.log("Error", error);
          });
      } else {
        setLoadingFile(true);

        axiosConfig
          .get(
            `${URL_API}/drive/file?options[filter][folder_id]=${
              gre_id_folder.length === 0
                ? ''
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((response) => {
            // // console.log("List File", response);

            if (response.status === 200) {
              // GET Folder Collection
              axiosConfig
                .get(
                  `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
                    gre_id_folder.length === 0
                      ? ''
                      : gre_id_folder[gre_id_folder.length - 1]
                  }`
                )
                .then((result) => {
                  if (result.status === 200) {
                    if (gre_id_folder.length === 0) {
                      const partedFiles = response.data.data
                        .map((file) => {
                          // Filter Check if id & folder_id is equal
                          const filtering = result.data.data.filter(
                            (item) => item.id === file.folder_id
                          );

                          // Check if folder_id doesn't match with any id of folders
                          if (filtering.length === 0) {
                            return { ...file };
                          }
                        })
                        .filter((item) => item !== undefined) // if item undefined, then remove it!
                        .filter(
                          (file) =>
                            file.status_id ===
                            'a9821d66-e047-4a11-8c0e-beb316a01d87' // cbb1d326-8643-40ca-a808-07ffacba0422 (restored)
                        );

                      setListFiles(partedFiles);
                    } else {
                      setListFiles(response.data.data);
                    }
                  }
                });
            }
            setLoadingFile(false);
          })
          .catch((error) => {
            setLoadingFile(false);
            // console.log("Error", error);
          });
      }
    }
  }, [openDialogAddResultMA, triggerNavigate]);

  // =============================== Handle Upload File ===============================
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length === 0) {
        return;
      } else if (acceptedFiles.length > 5) {
        alert('Jumlah file tidak boleh lebih dari 5');
      } else {
        setFiles((prev) => {
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          );

          return prev.concat(acceptedFiles).slice(0, 5);
        });
      }

      fileRejections.forEach((file) => {
        // File is too large
        if (file.size > maxSize) {
          alert('Ukuran File tidak boleh melebihi 30 MB');
        }

        // Rejected File
        if (file) {
          // // console.log("file reject", file);
          alert(
            `File ${file.name}, tidak dapat diunggah! (File tidak mendukung)`
          );
        }
      });
    },
    maxSize: maxSize,
    accept:
      // ".txt, .xlsx, .xls, .doc, .docx, .pdf, .ppt, .pptx, text/plain, text/csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,	application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  image/jpeg, image/png, image/gif, audio/mpeg, audio/wav, video/mp4, video/x-m4v, video/mpeg, video/ms-asf, video/x-ms-wmv, video/quicktime, video/x-msvideo, application/x-shockwave-flash, application/xml, text/xml",
      '.txt, .xlsx, .xls, .doc, .docx, .pdf, .ppt, .pptx, .csv, text/plain, text/csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,	application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/excel, application/x-excel, application/x-msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  image/jpeg, image/png, image/gif, audio/mpeg, audio/wav, video/mp4, video/x-m4v, video/mpeg, video/ms-asf, video/x-ms-wmv, video/quicktime, video/x-msvideo, application/x-shockwave-flash, application/xml, text/xml'
  });

  // =============================== Handle After Clear Search ===============================
  useEffect(() => {
    if (
      driveSearchValue === '' &&
      isSearchRun === false &&
      clearSearchResult === true
    ) {
      // Call List ID Folder for Handle Search
      let gre_id_folder = localStorage.getItem('gre_id_folder');
      gre_id_folder = gre_id_folder ? gre_id_folder.split(',') : [];

      setLoadingFolder(true);
      setLoadingFile(true);

      axiosConfig
        .get(
          `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
            gre_id_folder.length === 0
              ? 'root'
              : gre_id_folder[gre_id_folder.length - 1]
          }`
        )
        .then((responseFolder) => {
          // // console.log("Clear Folder", responseFolder);

          if (responseFolder.status === 200) {
            setListFolders(responseFolder.data.data);
            setLoadingFolder(false);

            axiosConfig
              .get(
                `${URL_API}/drive/file?options[filter][folder_id]=${
                  gre_id_folder.length === 0
                    ? 'root'
                    : gre_id_folder[gre_id_folder.length - 1]
                }`
              )
              .then((responseFile) => {
                // // console.log("Clear File", responseFile);

                if (responseFile.status === 200) {
                  setListFiles(responseFile.data.data);
                  setLoadingFile(false);
                }
              })
              .catch((error) => {
                setLoadingFile(false);
                // console.log("Error : ", error);
              });
          }
        })
        .catch((error) => {
          setLoadingFolder(false);
          // console.log("Error : ", error);
        });
    }
  }, [driveSearchValue, isSearchRun, clearSearchResult]);

  // =============================== Handle Search File/Folder ===============================
  const [handleSearch, cancel] = useDebouncedCallback(
    useCallback((value) => {
      // Call List ID Folder for Handle Search
      let gre_id_folder = localStorage.getItem('gre_id_folder');
      gre_id_folder = gre_id_folder ? gre_id_folder.split(',') : [];

      if (value !== '') {
        setLoadingFolder(true);
        setLoadingFile(true);

        localStorage.setItem('filemanager_search', value);

        setDriveSearchValue(value);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][ancestor_id]=${
              gre_id_folder.length === 0
                ? ''
                : gre_id_folder[gre_id_folder.length - 1]
            }&options[filter][search]=${value}`
          )
          .then((responseFolder) => {
            // // console.log("Search Folder", responseFolder);

            if (responseFolder.status === 200) {
              setSearchRun(true);
              setListFolders(responseFolder.data.data);
              setLoadingFolder(false);

              axiosConfig
                .get(
                  `${URL_API}/drive/file?options[filter][ancestor_id]=${
                    gre_id_folder.length === 0
                      ? ''
                      : gre_id_folder[gre_id_folder.length - 1]
                  }&options[filter][search]=${value}`
                )
                .then((responseFile) => {
                  // // console.log("Search File", responseFile);

                  if (responseFile.status === 200) {
                    setSearchRun(true);
                    setListFiles(responseFile.data.data);
                    setLoadingFile(false);
                  }
                })
                .catch((error) => {
                  setLoadingFile(false);
                  // console.log("Error : ", error);
                });
            }
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error : ", error);
          });
      } else {
        setSearchRun(false);
        setDriveSearchValue('');
        localStorage.removeItem('filemanager_search');
        inputRefSearch.current.value = '';

        setLoadingFolder(true);
        setLoadingFile(true);

        axiosConfig
          .get(
            `${URL_API}/drive/folder?options[filter][folder_parent_id]=${
              gre_id_folder.length === 0
                ? ''
                : gre_id_folder[gre_id_folder.length - 1]
            }`
          )
          .then((responseFolder) => {
            // // console.log("Search Folder", responseFolder);
            if (responseFolder.status === 200) {
              setSearchRun(true);
              setListFolders(responseFolder.data.data);
              setLoadingFolder(false);
              axiosConfig
                .get(
                  `${URL_API}/drive/file?options[filter][folder_id]=${
                    gre_id_folder.length === 0
                      ? ''
                      : gre_id_folder[gre_id_folder.length - 1]
                  }`
                )
                .then((responseFile) => {
                  // // console.log("Search File", responseFile);
                  if (responseFile.status === 200) {
                    setSearchRun(true);
                    setListFiles(responseFile.data.data);
                    setLoadingFile(false);
                  }
                })
                .catch((error) => {
                  setLoadingFile(false);
                  // console.log("Error : ", error);
                });
            }
          })
          .catch((error) => {
            setLoadingFolder(false);
            // console.log("Error : ", error);
          });
      }
    })
  );

  // Handle Remove File From List
  const handleRemoveFile = (file) => {
    setFiles((prev) => prev.filter((item) => item.name !== file.name));
  };

  // Handle Change Evidence Category
  const handleChangeEvidenceCategory = (e, data) => {
    setSelectEvidenceCategory(data);
  };

  const handleListItemClick = (event, index, data) => {
    setSelectedIndex(index);
    setFileDrive((prev) => {
      const sameId = prev.some((item) => item.id === data.id);

      if (!sameId) {
        return prev.concat(data).slice(0, 5);
      } else {
        return prev;
      }
    });
  };

  // Delete List Selected File Drive
  const handleDeleteSelectedDrive = (data) => {
    setFileDrive((prev) => prev.filter((item) => item.id !== data.id));
  };

  const handleNavigateFolder = (data) => {
    let gre_breadcrumb = localStorage.getItem('gre_breadcrumb');
    gre_breadcrumb = gre_breadcrumb ? gre_breadcrumb.split(',') : [];
    gre_breadcrumb.push(data.name);
    localStorage.setItem('gre_breadcrumb', gre_breadcrumb.toString());

    let gre_id_folder = localStorage.getItem('gre_id_folder');
    gre_id_folder = gre_id_folder ? gre_id_folder.split(',') : [];
    gre_id_folder.push(data.id);
    localStorage.setItem('gre_id_folder', gre_id_folder.toString());

    setTriggerNavigate(Math.floor(Math.random() * 1000 + 1));

    // // console.log("Breads", gre_breadcrumb);
    // // console.log("ListID", gre_id_folder);
  };

  const handlBreadcrumb = (data) => {
    let gre_breadcrumb = localStorage.getItem('gre_breadcrumb');
    gre_breadcrumb = gre_breadcrumb ? gre_breadcrumb.split(',') : [];
    let breadcrumb = gre_breadcrumb.slice(0, data + 1);
    localStorage.setItem('gre_breadcrumb', breadcrumb.toString());

    let gre_id_folder = localStorage.getItem('gre_id_folder');
    gre_id_folder = gre_id_folder ? gre_id_folder.split(',') : [];
    let id_folder = gre_id_folder.slice(0, data + 1);
    localStorage.setItem('gre_id_folder', id_folder.toString());

    setTriggerNavigate(Math.floor(Math.random() * 1000 + 1));
  };

  // Call List Name Folder for Breadcrumb
  let gre_breadcrumb = localStorage.getItem('gre_breadcrumb');
  gre_breadcrumb = gre_breadcrumb ? gre_breadcrumb.split(',') : [];

  // Check is Evidence Required Or No

  /* ======================================================================
  Handle Add Result MA
  ====================================================================== */
  const handleAddResultMA = () => {
    const data = {
      MeasuredActivityResult: {
        result_value: maResult,
        description: comment,
        result_date: moment(selectedDueDate).format('YYYY-MM-DD'),
        result_time: moment(selectedDueDate).format('HH:mm:ss')
        // evidence_latitude: "",
        // evidence_longitude: "",
        // evidence_address: "",
      }
    };

    const dataForm = new FormData();

    if (
      files[0] !== undefined ||
      fileDrive[0] !== undefined ||
      hyperlink !== ''
    ) {
      dataForm.append('MeasuredActivityResult[result_value]', maResult);
      dataForm.append('MeasuredActivityResult[description]', comment);
      dataForm.append(
        'MeasuredActivityResult[result_date]',
        moment(selectedDueDate).format('YYYY-MM-DD')
      );
      dataForm.append(
        'MeasuredActivityResult[result_time]',
        moment(selectedDueDate).format('HH:mm:ss')
      );
      dataForm.append(
        'MeasuredActivityResult[evidence_category_id]',
        selectEvidenceCategory.id
      );
      dataForm.append(
        'MeasuredActivityResult[evidence_description]',
        selectEvidenceCategory.name === 'Attachment'
          ? files[0].name
          : selectEvidenceCategory.name === 'Drive'
          ? fileDrive[0].name
          : ''
      );
      dataForm.append(
        'MeasuredActivityResult[evidence_content]',
        selectEvidenceCategory.name === 'Attachment'
          ? files[0]
          : selectEvidenceCategory.name === 'Drive'
          ? fileDrive[0].id
          : hyperlink
      );
    }

    // data.append("evidence_latitude", "");
    // data.append("evidence_longitude", "");
    // data.append("evidence_address", "");

    setLoadingEvidence(true);

    let mar_id = null;

    axiosConfig
      .post(
        `${URL_API}/swd/measured-activity/${maDetailState.id}/result`,
        isEvidenceRequired ? dataForm : data,
        {
          timeout: 20000,
          headers: {
            'Content-Type': isEvidenceRequired
              ? 'multipart/form-data'
              : 'application/json'
          }
        }
      )
      .then((response) => {
        // console.log("Response Original Goal Result : ", response);

        if (response.status === 200) {
          mar_id = response.data.data.id;

          if (!addEvidenceCheck && !isEvidenceRequired) {
            context.setSuccessCreateMAR(Math.floor(Math.random() * 1000 + 1));
            context.setModalResponse200(true);
            context.setMessages('Berhasil menambahkan Goal Result baru');
            setLoadingEvidence(false);
            setOpenDialogAddResultMA(false);
          } else if (
            isEvidenceRequired &&
            (files.length === 1 ||
              fileDrive.length === 1 ||
              selectEvidenceCategory.name === 'Link')
          ) {
            context.setSuccessCreateMAR(Math.floor(Math.random() * 1000 + 1));
            context.setModalResponse200(true);
            context.setMessages('Berhasil menambahkan Goal Result baru');
            setLoadingEvidence(false);
            setOpenDialogAddResultMA(false);
          } else {
            if (selectEvidenceCategory.name === 'Attachment') {
              setLoadingEvidence(true);

              return Promise.all(
                files.length > lengthFiles &&
                  files.slice(lengthFiles).map((file) => {
                    const dataFile = new FormData();

                    dataFile.append(
                      'MeasuredActivityResultEvidence[content]',
                      file
                    );
                    dataFile.append(
                      'MeasuredActivityResultEvidence[evidence_category_id]',
                      selectEvidenceCategory.id
                    );
                    dataFile.append(
                      'MeasuredActivityResultEvidence[description]',
                      file.name
                    );

                    return axiosConfig.post(
                      `${URL_API}/swd/measured-activity-result/${mar_id}/evidence`,
                      dataFile,
                      {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                      }
                    );
                  })
              )
                .then((response) => {
                  // // console.log("Upload File Drive", response);
                  // // console.log("Response GRE Drive", response);

                  //*GOL RESULT LIST SELF - ONLY OWNER GOAL USER LOGIN
                  axiosConfig
                    .get(
                      `${URL_API}/swd/measured-activity/${maDetailState.id}/result`
                    )
                    .then(function (response) {
                      console.log(
                        'Response Original COLLECTION GOAL RESULT FORCE INSIDE CREATE GR : ',
                        response
                      );

                      if (response.status === 200) {
                        setOpenDialogAddResultMA(false);
                        setFiles([]);
                        context.setSuccessCreateMAR(
                          Math.floor(Math.random() * 1000 + 1)
                        );
                        // setFireGoalDetail(true);
                        // context.setCollectionGoalResultList(response.data.data);
                        setLoadingEvidence(false);
                      }
                    })
                    .catch(function (error) {
                      // context.setLoaderEvidance(false);
                      // console.log("Error : ", error.response);
                    });
                })
                .catch((error) => {
                  setLoadingEvidence(false);
                  // console.log("Error  : ", error.response);

                  setFiles([]);

                  // error response
                  if (error.response !== undefined) {
                    let responseStatus = error.response.status;

                    setErrorStatus(responseStatus);
                    setOpenDialogError(true);

                    if ([422, 400].includes(responseStatus)) {
                      if (
                        error.response.data.info.errors !== null &&
                        error.response.data.info.errors !== undefined
                      ) {
                        if (error.response.data.info.errors.length > 0) {
                          setOpenDialogError(true);
                          setListError(error.response.data.info.errors);
                        }
                      }
                    }

                    if (
                      [503, 500, 403, 429, 405, 404, 401].includes(
                        responseStatus
                      )
                    ) {
                      if (error.response.data.info.hasOwnProperty('message')) {
                        setTextErrorMessage(
                          `Error ${responseStatus} : ` +
                            error.response.data.info.message
                        );
                      }
                    }
                  } else {
                    setOpenDialogError(true);
                    setTextErrorMessage('Whoops, something went wrong !');
                  }
                });
            } else if (selectEvidenceCategory.name === 'Drive') {
              setLoadingEvidence(true);

              return Promise.all(
                fileDrive.length > lengthFiles &&
                  fileDrive.slice(lengthFiles).map((drive) => {
                    const data = {
                      MeasuredActivityResultEvidence: {
                        evidence_category_id: selectEvidenceCategory.id,
                        content: drive.id,
                        description: drive.name
                      }
                    };

                    return axiosConfig.post(
                      `${URL_API}/swd/measured-activity-result/${mar_id}/evidence`,
                      data
                    );
                  })
              )
                .then((response) => {
                  // // console.log("Response GRE Drive", response);

                  setOpenDialogAddResultMA(false);
                  setFiles([]);
                  setFileDrive([]);
                  context.setSuccessCreateMAR(
                    Math.floor(Math.random() * 1000 + 1)
                  );
                  setLoadingEvidence(false);
                })
                .catch((error) => {
                  setLoadingEvidence(false);
                  // console.log("Error  : ", error.response);

                  setFiles([]);

                  // error response
                  if (error.response !== undefined) {
                    let responseStatus = error.response.status;

                    setErrorStatus(responseStatus);
                    setOpenDialogError(true);

                    if ([422, 400].includes(responseStatus)) {
                      if (
                        error.response.data.info.errors !== null &&
                        error.response.data.info.errors !== undefined
                      ) {
                        if (error.response.data.info.errors.length > 0) {
                          setOpenDialogError(true);
                          setListError(error.response.data.info.errors);
                        }
                      }
                    }

                    if (
                      [503, 500, 403, 429, 405, 404, 401].includes(
                        responseStatus
                      )
                    ) {
                      if (error.response.data.hasOwnProperty('message')) {
                        setTextErrorMessage(
                          `Error ${responseStatus} : ` +
                            error.response.data.message
                        );
                      }
                    }
                  } else {
                    setOpenDialogError(true);
                    setTextErrorMessage('Whoops, something went wrong !');
                  }
                });
            } else if (
              selectEvidenceCategory.name === 'Link' &&
              lengthFiles === 0
            ) {
              const data = {
                MeasuredActivityResultEvidence: {
                  evidence_category_id: selectEvidenceCategory.id,
                  content: hyperlink,
                  description: ''
                }
              };

              // // console.log("Link Data : ", data);

              setLoadingEvidence(true);

              axiosConfig
                .post(
                  `${URL_API}/swd/measured-activity-result/${mar_id}/evidence`,
                  data
                )
                .then((response) => {
                  // // console.log("Response GRE Drive", response);

                  if (response.status === 200) {
                    setOpenDialogAddResultMA(false);
                    setFiles([]);
                    context.setSuccessCreateMAR(
                      Math.floor(Math.random() * 1000 + 1)
                    );
                  }
                  setLoadingEvidence(false);
                })
                .catch((error) => {
                  setLoadingEvidence(false);
                  // console.log("Error  : ", error.response);

                  setFiles([]);

                  // error response
                  if (error.response !== undefined) {
                    let responseStatus = error.response.status;

                    setErrorStatus(responseStatus);
                    setOpenDialogError(true);

                    if ([422, 400].includes(responseStatus)) {
                      if (
                        error.response.data.info.errors !== null &&
                        error.response.data.info.errors !== undefined
                      ) {
                        if (error.response.data.info.errors.length > 0) {
                          setOpenDialogError(true);
                          setListError(error.response.data.info.errors);
                        }
                      }
                    }

                    if (
                      [503, 500, 403, 429, 405, 404, 401].includes(
                        responseStatus
                      )
                    ) {
                      if (error.response.data.hasOwnProperty('message')) {
                        setTextErrorMessage(
                          `Error ${responseStatus} : ` +
                            error.response.data.message
                        );
                      }
                    }
                  } else {
                    setOpenDialogError(true);
                    setTextErrorMessage('Whoops, something went wrong !');
                  }
                });
            }
          }
        }
      })
      .catch((error) => {
        setLoadingEvidence(false);
        // console.log("Error  : ", error.response);

        setFiles([]);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty('message')) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage('Whoops, something went wrong !');
        }
      });

    localStorage.removeItem('gre_id_folder');
    localStorage.removeItem('gre_breadcrumb');
  };

  return (
    <Dialog
      open={openDialogAddResultMA}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        <Grid container direction="row" justify="space-between">
          <Typography variant="subtitle1" className={classes.title}>
            <b>Add Measured Activity Result</b>
          </Typography>

          <IconButton
            size="small"
            onClick={() => setOpenDialogAddResultMA(false)}
          >
            <HighlightOffIcon
              fontSize="small"
              style={{ color: 'grey', fontSize: '24px' }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ textAlign: 'left', width: 425 }}>
        <Box marginBottom={2}>
          <Typography variant="subtitle2" className={classes.title}>
            Masukan nilai angka
          </Typography>
          <TextField
            id="outlined-bare"
            onChange={(e) => setMAResult(e.target.value)}
            value={maResult}
            variant="outlined"
            fullWidth
            type="number"
            inputProps={{
              className: classes.title
            }}
          />
        </Box>

        <Box marginBottom={2.5}>
          <Typography variant="subtitle2" className={classes.title}>
            Komentar
          </Typography>
          <TextField
            id="outlined-bare"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            variant="outlined"
            placeholder="Tambah komentar..."
            fullWidth
            multiline
            rows={2}
            inputProps={{
              className: classes.title
            }}
          />
        </Box>

        <Box marginBottom={2.5}>
          <MuiPickersUtilsProvider utils={LocalizedUtils}>
            <ThemeProvider theme={theme}>
              <Fragment>
                <DateTimePicker
                  ampm={false}
                  value={selectedDueDate}
                  onChange={handleChangeDueDate}
                  label="Pilih tanggal & waktu"
                  disableToolbar={false}
                  format="DD MMMM YYYY HH:mm a"
                />
              </Fragment>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Box>

        <Box marginBottom={2}>
          <FormControlLabel
            label={
              <span
                style={{ color: '#333', fontSize: 13 }}
                className={classes.title}
              >
                Add Evidence
              </span>
            }
            control={
              <Checkbox
                className={classes.checkboxRoot}
                disableRipple
                color="default"
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                disabled={isEvidenceRequired === 1}
                onChange={(e) => setAddEvidenceCheck(e.target.checked)}
              />
            }
          />
        </Box>

        {addEvidenceCheck && (
          <Box marginTop={-2}>
            <List>
              {evidenceCategoryCollections.map((item, i) => (
                <Fragment key={i}>
                  <FormControlLabel
                    label={
                      <p
                        style={{ color: '#aaa', fontSize: 14 }}
                        className={classes.title}
                      >
                        {item.name}
                      </p>
                    }
                    control={
                      <Radio
                        className={classes.rootRadio}
                        disableRipple
                        color="default"
                        name={item.name}
                        value={item.id}
                        checked={item.id === selectEvidenceCategory.id}
                        onChange={(e) => handleChangeEvidenceCategory(e, item)}
                        checkedIcon={
                          <span
                            className={clsx(
                              classes.iconRadio,
                              classes.checkedIconRadio
                            )}
                          />
                        }
                        icon={<span className={classes.iconRadio} />}
                      />
                    }
                  />
                </Fragment>
              ))}
            </List>

            {selectEvidenceCategory.name === 'Attachment' ? (
              <Fragment>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ marginBottom: 5, color: '#888' }}
                >
                  Upload Evidence{' '}
                  <i style={{ marginBottom: 5, color: '#aaa' }}>
                    (image / video / pdf / txt)
                  </i>
                </Typography>

                <Box>
                  <div className={classes.uploadContainer}>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <img src={dragDropImg} width="75" height="75" />
                      <p className={classes.title}>
                        Drag 'n' Drop files here, or CTRL + V
                      </p>
                    </div>
                  </div>

                  <div
                    className={classes.uploadContainer}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setOpenVoiceRecorder(true)}
                  >
                    <MicRoundedIcon fontSize="small" />
                  </div>
                </Box>

                <Typography className={classes.title} variant="subtitle1">
                  <b>
                    Files{' '}
                    {files.length === 5
                      ? `(${files.length} - Max)`
                      : `(${files.length})`}
                  </b>
                </Typography>

                <List dense>
                  {files.map((file, i) => {
                    return (
                      <ListFiles
                        key={i}
                        file={file}
                        classes={classes}
                        onRemoveFile={handleRemoveFile}
                      />
                    );
                  })}
                </List>
                <PasteClipboard onFiles={setFiles} />
              </Fragment>
            ) : selectEvidenceCategory.name === 'Drive' ? (
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginBottom={1}
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: '#888' }}
                  >
                    File Manager
                  </Typography>

                  <TextField
                    id="input-with-icon-textfield"
                    size="small"
                    style={{ width: 200 }}
                    placeholder="search..."
                    onChange={(e) => handleSearch(e.target.value)}
                    inputRef={inputRefSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            fontSize="small"
                            style={{ color: '#888' }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSearchRun(false);
                              setDriveSearchValue('');
                              localStorage.removeItem('filemanager_search');
                              setClearSearchResult(true);

                              setTimeout(() => {
                                inputRefSearch.current.value = '';
                              }, 200);
                            }}
                          >
                            <CloseRoundedIcon
                              fontSize="small"
                              style={{ color: '#888' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>

                <Breadcrumbs aria-label="breadcrumb">
                  <Typography
                    variant="subtitle2"
                    className={classes.textBreadcrum}
                    onClick={() => {
                      localStorage.removeItem('gre_id_folder');
                      localStorage.removeItem('gre_breadcrumb');
                      setTriggerNavigate(Math.floor(Math.random() * 1000 + 1));
                    }}
                    style={{
                      color: gre_breadcrumb.length === 0 && '#333'
                    }}
                  >
                    Drive
                  </Typography>
                  {gre_breadcrumb.length > 0 &&
                    gre_breadcrumb.map((item, idx) => {
                      const colorText =
                        gre_breadcrumb.length - 1 === idx && '#333';

                      return (
                        <Typography
                          variant="subtitle2"
                          className={classes.textBreadcrum}
                          onClick={() => handlBreadcrumb(idx)}
                          style={{
                            color: colorText
                          }}
                        >
                          {item}
                        </Typography>
                      );
                    })}
                </Breadcrumbs>

                <DriveEvidence
                  classes={classes}
                  listFolders={listFolders}
                  listFiles={listFiles}
                  selectedIndex={selectedIndex}
                  onListItemClick={handleListItemClick}
                  onNavigateFolder={handleNavigateFolder}
                  openDialogAddEvidence={openDialogAddResultMA}
                  triggerNavigate={triggerNavigate}
                  loadingFolder={loadingFolder}
                  loadingFile={loadingFile}
                />

                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ fontSize: 13 }}
                  >
                    File Drive Selected{' '}
                    {fileDrive.length === 5
                      ? `(${fileDrive.length} - Max)`
                      : `(${fileDrive.length})`}{' '}
                    :
                  </Typography>

                  <Box
                    display="flex"
                    flexWrap="wrap"
                    marginTop={1}
                    marginBottom={1}
                  >
                    {fileDrive !== undefined &&
                      fileDrive.length > 0 &&
                      fileDrive.map((item, i) => (
                        <FileDriveSelected
                          key={i}
                          item={item}
                          handleDeleteSelectedDrive={handleDeleteSelectedDrive}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box marginBottom={3}>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ marginBottom: 5, color: '#888' }}
                >
                  Hyperlink
                </Typography>

                <TextField
                  id="outlined-size-small"
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder="Paste the link here"
                  helperText="example : https://mypict.com/img002.jpg"
                  onChange={(e) => setHyperlink(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <LinkRoundedIcon
                          style={{ color: '#555', marginRight: 7.5 }}
                          fontSize="small"
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            )}
          </Box>
        )}

        <DialogActions style={{ padding: 0, margin: 0, marginBottom: 15 }}>
          {selectEvidenceCategory.name === 'Link' ? (
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              size="large"
              disabled={
                maResult === '' && !addEvidenceCheck
                  ? true
                  : addEvidenceCheck && hyperlink === ''
                  ? true
                  : addEvidenceCheck && maResult === ''
                  ? true
                  : false
              }
              onClick={handleAddResultMA}
            >
              {loadingEvidence ? (
                <CircularProgress style={{ color: '#fff' }} size={16} />
              ) : (
                'Add'
              )}
            </Button>
          ) : selectEvidenceCategory.name === 'Drive' ? (
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              size="large"
              disabled={
                maResult === '' && !addEvidenceCheck
                  ? true
                  : addEvidenceCheck && fileDrive === null
                  ? true
                  : addEvidenceCheck && maResult === ''
                  ? true
                  : false
              }
              onClick={handleAddResultMA}
            >
              {loadingEvidence ? (
                <CircularProgress style={{ color: '#fff' }} size={16} />
              ) : (
                'Add'
              )}
            </Button>
          ) : selectEvidenceCategory.name === 'Attachment' ? (
            <Button
              variant="contained"
              className={classes.button}
              fullWidth
              size="large"
              disabled={
                maResult === '' && !addEvidenceCheck
                  ? true
                  : addEvidenceCheck && files.length === 0
                  ? true
                  : addEvidenceCheck && maResult === ''
                  ? true
                  : false
              }
              onClick={handleAddResultMA}
            >
              {loadingEvidence ? (
                <CircularProgress style={{ color: '#fff' }} size={16} />
              ) : (
                'Add'
              )}
            </Button>
          ) : null}
        </DialogActions>
      </DialogContent>

      <DialogAudio
        classes={classes}
        files={files}
        setFiles={setFiles}
        openVoiceRecorder={openVoiceRecorder}
        setOpenVoiceRecorder={setOpenVoiceRecorder}
      />

      <DialogErrorGR
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogAddResultMA;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#c62828', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff'
    }
  },

  typography: {
    fontFamily: 'Roboto',
    textTransform: 'capitalize'
  },
  textfield: {
    width: 200
  }
});
