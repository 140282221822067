import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { URL_API } from "../../../../constants/config-api";

import clsx from "clsx";

function DialogShowAttchmentImage({
  openAttachmentImage,
  setOpenAttachmentImage,
  item,
  userToken,
  classes,
}) {
  const [original, setOriginal] = useState(false);

  return (
    <Dialog
      open={openAttachmentImage}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl" //*Default "md"
      style={{ height: "auto", width: "100%" }}>
      <DialogTitle id="customized-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className={classes.title}>
              {item.label}
            </Typography>
            <FormControlLabel
              label={
                <p
                  style={{ color: "#888", fontSize: 14 }}
                  className={classes.title}>
                  Original Size
                </p>
              }
              control={
                <Checkbox
                  style={{ marginLeft: 20 }}
                  checked={original}
                  onChange={(e) => setOriginal(e.target.checked)}
                  className={classes.checkboxRoot}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  inputProps={{ "aria-label": "decorative checkbox" }}
                />
              }
            />
          </Box>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpenAttachmentImage(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <img
          // className={classes.img}
          // src={URL_API + '/' + step.self.rel.attachment + "?token=" + userToken + "&mode=thumbnail"}
          src={URL_API + "/" + item.self.rel.content + "?token=" + userToken}
          alt={item.label}
          style={{ height: original ? "auto" : 700 }}
          // style={{ maxHeight: 700 }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DialogShowAttchmentImage;
