import React, { useEffect, useState, useRef, useContext } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";

import DialogError from "../../../components/DialogError";
import Capitalize from "../../../utilities/Capitalize";
import { URL_API } from "../../../constants/config-api";
import { styles } from "../Style/StyleDialogDetail";

import ContextNewSO from "../Context/ContextNewSO";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogCreatePositionTitleInDeputyHEAD = (props) => {
  const { classes } = props;

  let textInputReff = useRef(null);

  const context = useContext(ContextNewSO);

  const [loader, setLoader] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  useEffect(() => {
    if (context.isOpenDialogCreatePositionTitleInDeputyHEAD == true) {
      // context.setOpenDialogTambahHead(false)

      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);
    }
  }, [context.isOpenDialogCreatePositionTitleInDeputyHEAD]);

  const handleChangeNamePositionTitle = (e) => {
    e.preventDefault();

    context.setPositionTitleName(e.target.value);
    // e.stopPropagation();
  };

  const handleBatal = () => {
    context.setOpenDialogTambahDeputyHead(true);
    context.setOpenDialogCreatePositionTitleInDeputyHEAD(false);
  };

  const handleSubmit = () => {
    setLoader(true);

    let data = {
      MasterStructurePositionTitle: {
        name: context.positionTitleName,
      },
    };

    // console.log("Data SUBMIT : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + "/human-resource/master-structure-position-title", data)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          if (response.status == 200) {
            context.setOpenDialogTambahDeputyHead(true);
            context.setOpenDialogCreatePositionTitleInDeputyHEAD(false);
            context.setPositionTitleChoose(false);

            context.setSuccessCreatePositionTitle(true);

            context.setSnackbarResponse200(true);
            context.setMessages("Berhasil membuat nama jabatan :)");
          }
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(400);
              if (
                error.response.data.info.errors !== undefined &&
                error.response.data.info.errors !== null
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                  setTextErrorInformationValue(".");
                  // if(error.response.data.info.errors[0].code == "VALIDATION_ERROR" ){

                  //     setOpenDialogError(true);
                  //     setTextErrorInformationValue('Error 400 : ' + Capitalize(error.response.data.info.errors[0].description))
                  // }
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogCreatePositionTitleInDeputyHEAD}
        onClose={() =>
          context.setOpenDialogCreatePositionTitleInDeputyHEAD(false)
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>Tambah Jabatan</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={textInputReff}
            id="outlined-bare"
            className={classes.textField}
            onChange={handleChangeNamePositionTitle}
            // onChange= {(e) => handleChangeNamePositionTitle(e.target.value)}
            // onChange={debouncedFunction}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            // placeholder = {goalDetailState.name}
            // value = {positionTitleName}
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}
        >
          <Box marginRight={theme.spacing(0.5)}>
            <Button
              // onClick={() => setOpenDialogCreatePositionTitleInDeputyHEAD(false)}
              onClick={handleBatal}
              variant="outlined"
              size="small"
              className={classes.buttonOutlined}
            >
              Batal
            </Button>

            <Button
              // onClick={() => Redirect(ToCompletionProfile)}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
            >
              {loader !== true ? (
                "Tambah"
              ) : (
                <CircularProgress size={20} style={{ color: "white" }} />
              )}
            </Button>
          </Box>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
        // errorStatus
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogCreatePositionTitleInDeputyHEAD);
