import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGlobalDrive from "../../context/ContextDrive";

function DialogCreateFolder({
  openDialogCreateFolder,
  setOpenDialogCreateFolder,
  classes,
}) {
  const context = useContext(ContextGlobalDrive);

  const [folderName, setFolderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [getPathName, setGetPathName] = useState("");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogCreateFolder === true) {
      const pathArray = window.location.pathname.split("/");
      const getLastPathArr = pathArray[pathArray.length - 1];

      if (getLastPathArr !== undefined) {
        setGetPathName(getLastPathArr);
      } else {
        setGetPathName("");
      }

      // // console.log("Cek Parent", getPathName);
    }
  }, [openDialogCreateFolder]);

  const handleCreateFolder = () => {
    const data = {
      DriveFolder: {
        name: folderName,
        folder_parent_id: getPathName === "drive" ? "" : getPathName,
      },
    };

    // // console.log("Data Create Folder", data);

    setLoading(true);

    axiosConfig
      .post(`${URL_API}/drive/folder`, data)
      .then((response) => {
        // console.log("Response Create Folder", response);

        if (response.status === 200) {
          context.setSuccessTriggerFolder(Math.floor(Math.random() * 1000 + 1));
          context.setModalResponse200(true);
          context.setMessages("Folder created successfully");

          setFolderName("");
          context.setSelectedFolder([]); // Empty Selected Folder
          context.setSelectedFile([]); // Empty Selected File
          setOpenDialogCreateFolder(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);
        setFolderName("");

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogCreateFolder}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Create Folder</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 375 }}>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2" className={classes.title}>
            Folder Name
          </Typography>
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => setFolderName(e.target.value)}
            // color='primary'
            // onKeyDown={handleEnterPress}
            value={folderName}
            variant="outlined"
            fullWidth
            type="text"
            inputProps={{
              className: classes.title,
            }}
          />

          <DialogActions
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              className={classes.button2}
              onClick={() => setOpenDialogCreateFolder(false)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              onClick={handleCreateFolder}
            >
              {loading ? <CircularProgress color="#fff" size={15} /> : "Create"}
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogCreateFolder;
