import React, { useContext, useEffect, useState } from 'react';
import { Box, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { styles, theme } from './style/styleProject';

import ContextProject from './context/ContextProject';
import handleError from '../Report/components/global/handleError';
import axiosConfig from '../../constants/config-axios';
import { URL_API } from '../../constants/config-api';
import { PROJECT_TEAM_COLLECTIONS, TOGGLE_PROJECT } from './context/types';

import ViewTodoList from './ViewTodoList';
import ProjectEmpty from './ProjectEmpty';
import DialogError from '../../components/DialogError';
import mapTable from './components/global/mapTable';

const useStyles = makeStyles(styles);

const ViewProject = () => {
  const classes = useStyles();
  const {
    projectTState,
    projectTDispatch,
    triggerProject,
    openSuccessImportTasks,
    setOpenSuccessImportTasks
  } = useContext(ContextProject);

  const [projectExist, setProjectExist] = useState(undefined);
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  useEffect(() => {
    setLoading(true);

    projectTDispatch({
      type: PROJECT_TEAM_COLLECTIONS,
      payload: {
        loadProject: true,
        projectData: projectTState.projectData,
        personalData: projectTState.personalData,
        count: 0
      }
    });

    axiosConfig
      .get(
        `${URL_API}/project?options[sort][name]=${projectTState.sortNameproject}&options[paging][limit]=${projectTState.pageLimit}&options[paging][offset]=${projectTState.pageOffset}&options[filter][search]=${projectTState.searchProject}&options[layout]=${projectTState.projectType}&${projectTState.filterTheStatus}`
      )
      .then((res) => {
        const result = res.data.data;
        const count = res.data.info.count;
        const team = result.hasOwnProperty('team_project');
        const personal = result.hasOwnProperty('personal_project');

        // console.log("RES Project!! : ", res);

        if (res.status === 200) {
          // console.log("result?.team_project", result?.team_project);

          if (count > 0) {
            setProjectExist(true);
          }

          if (team && !personal) {
            const mapTProject = mapTable(result?.team_project);

            projectTDispatch({
              type: PROJECT_TEAM_COLLECTIONS,
              payload: {
                loadProject: false,
                projectData: mapTProject,
                personalData: [],
                count
              }
            });
          }

          if (personal && !team) {
            projectTDispatch({
              type: PROJECT_TEAM_COLLECTIONS,
              payload: {
                loadProject: false,
                projectData: [],
                personalData: result?.personal_project,
                count
              }
            });
          }

          if (team && personal) {
            const mapTProject = mapTable(result?.team_project);

            projectTDispatch({
              type: PROJECT_TEAM_COLLECTIONS,
              payload: {
                loadProject: false,
                projectData: mapTProject,
                personalData: result?.personal_project,
                count
              }
            });
          }

          if (!team && !personal) {
            projectTDispatch({
              type: PROJECT_TEAM_COLLECTIONS,
              payload: {
                loadProject: false,
                projectData: [],
                personalData: [],
                count: 0
              }
            });
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("error", error);
        setLoading(false);

        projectTDispatch({
          type: PROJECT_TEAM_COLLECTIONS,
          payload: {
            loadProject: false,
            projectData: [],
            personalData: [],
            count: 0
          }
        });

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } = handleError(
          error
        );
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  }, [triggerProject, projectTState.projectType]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccessImportTasks(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box mt={9.5} className={classes.root} bgcolor="#F9F9F9">
        {loading && (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress style={{ color: '#f28181' }} />
          </Backdrop>
        )}

        {!loading && projectExist && <ViewTodoList classes={classes} />}
        {!loading && !projectExist && <ProjectEmpty classes={classes} />}
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbar
        open={openSuccessImportTasks}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Success Import Tasks!
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ViewProject;
