import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useCallback,
  Fragment
} from 'react';
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider
} from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Button,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Radio,
  InputAdornment
} from '@material-ui/core';

import moment from 'moment';
import axios from 'axios';

import { useDropzone } from 'react-dropzone';
import StickyFooter from 'react-sticky-footer';
import { ThemeProvider } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { navigate } from 'hookrouter';

import EventIcon from '@material-ui/icons/Event';

import Snackber from '../Components/Snackber';
import Snackbeer from '../Components/Snackbeer';

import DialogError from '../../../components/DialogError';
import ConvertDataUrlToFile from '../../../utilities/ConvertDataUrlToFile';
import { URL_API } from '../../../constants/config-api';
import Redirect from '../../../utilities/Redirect';

import {
  ToHrEmployeeProfileDetailSeeDetail,
  ToEmptyStateGeneral
} from '../../../constants/config-redirect-url';
import { extractImageFileExtensionFromBase64 } from '../../../utilities/ReusableUtils';
import AvatarDummy from '../../../assets/images/Avatar_dummy.png';
import { styles } from './Style/StyleViewIdentitas';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cc0707', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff'
    }
  }
});

const ViewIdentitas = (props) => {
  const { classes } = props;

  /* 
        ````````````````````
        COMPONENT DID MOUNT
        
        ````````````````````
    */
  const [userTokenState, setUserTokenState] = useState('');

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');

    setUserTokenState(userToken);
    setSimpanNow(false);

    // setTempatLahir('')
  }, []);

  const dataIdentitasLocalStorage = localStorage.getItem('data_identitas');
  const dataIdentitasLocalStorageAfterParse = JSON.parse(
    dataIdentitasLocalStorage
  );

  const foto =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.foto
      : '';
  const nipInLocal =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.nip
      : '';
  const namaDepanInLocal =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.nama_depan
      : '';
  const namaBelakangInLocal =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.nama_belakang
      : '-';
  const namaPanggilanInLocal =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.nama_panggilan
      : '';
  const noHpInLocal =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.no_hp
      : '';
  const emailLocal =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.email
      : '';
  const tempatLahirInLocal =
    dataIdentitasLocalStorageAfterParse !== null
      ? dataIdentitasLocalStorageAfterParse.tempat_lahir
      : '';

  // const agama = dataIdentitasLocalStorageAfterParse !== '' ? dataIdentitasLocalStorageAfterParse.agama : '';
  /* 
        ````````````````````
        Feature Upload Foto 
        
        ````````````````````
    */
  const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState(foto);
  const [imgSrcExt, setImgSrcExt] = useState();
  const [contentFile, setContentFile] = useState(undefined);
  const [nameFile, setNameFile] = useState('');

  // const [imgSrc, setImgSrc ] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files, you can upload multiple here too !
    //    setContentFile(acceptedFiles); // *Belum di pake
    setNameFile(acceptedFiles[0].name);

    //*
    const reader = new FileReader();

    reader.onabort = () =>
      // console.log("file reading was aborted");
      (reader.onerror = () =>
        // console.log("file reading has failed");
        (reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          // console.log("Binary String : ", binaryStr);

          setImageBinaryPreviewUrl(binaryStr);
          setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));
        }));

    // acceptedFiles.forEach(file => reader.readAsBinaryString(file))
    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  /*
        ``````````````````````
        Dropdown

        ``````````````````````

    */

  // const [religions, setReligion] = useState({
  //   religion: {
  //     id: "007fba3e-35ce-485f-bc22-feda9125c813",
  //     value: "Islam",
  //     label: "Islam",
  //   },
  // });

  const [dataForSimpanNow, setDataForSimpanNow] = useState(null);

  // const handleChangeDropdown = (name) => (event) => {
  // // console.log("Name drop down : ", event.target.value);
  // setReligion({ ...religions, [name]: event.target.value });

  //*FIRE LENGKAPI DATA
  // setOpenDialogSimpanSekarang(true);

  // let paramsData = {

  //     foto: imageBinaryPreviewUrl,
  //     foto_name: nameFile,
  //     // foto: contentFile,
  //     nip : nip,
  //     nama_depan: namaDepan,
  //     nama_belakang: namaBelakang !== '' ? namaBelakang : '-' ,
  //     nama_panggilan: namaPanggilan,
  //     no_hp: noHp,
  //     email: email,
  //     tempat_lahir: tempatLahir,
  //     tanggal_lahir: tanggalLahir,
  //     agama: event.target.value.id,//religions.religion,
  //     jenis_kelamin: selectedValue

  // };

  // console.log('paramsData simpan NOW :  ', paramsData);
  // setDataForSimpanNow(paramsData);
  // };

  /*
        ```````````````````````````````````````````````````
        HANDLE DIALOG SIMPAN SEKARANG || FIRE LENGKAPI DATA 

        ```````````````````````````````````````````````````

    */
  const [isOpenDialogSimpanSekarang, setOpenDialogSimpanSekarang] =
    useState(false);

  /*
        `````````````
        RADIO BUTTON

        `````````````

    */

  const [selectedValue, setSelectedValue] = useState('lakiLaki');

  function handleChangeRadioButton(event) {
    setSelectedValue(event.target.value);

    if (event.target.value == 'lakiLaki') {
      console.log(event.target.value);

      // callListMasterRole(event.target.value)
    } else {
      console.log(event.target.value);
    }
  }

  /*
        `````````
        FORM DATA

        `````````
    */
  const [nip, setNip] = useState(nipInLocal);
  const [namaDepan, setNamaDepan] = useState(namaDepanInLocal);
  const [namaBelakang, setNamaBelakang] = useState(namaBelakangInLocal);
  const [namaPanggilan, setNamaPanggilan] = useState(namaPanggilanInLocal);
  const [noHp, setNoHp] = useState(noHpInLocal);
  const [email, setEmail] = useState(emailLocal);
  const [tempatLahir, setTempatLahir] = useState(tempatLahirInLocal);
  const [tanggalLahir, setTanggalLahir] = useState('');

  let paramsData = {
    foto: imageBinaryPreviewUrl,
    foto_name: nameFile,
    // foto: contentFile,
    nip: nip,
    nama_depan: namaDepan,
    nama_belakang: namaBelakang !== '' ? namaBelakang : '-',
    nama_panggilan: namaPanggilan,
    no_hp: noHp,
    email: email,
    tempat_lahir: tempatLahir,
    tanggal_lahir: tanggalLahir,
    agama: '007fba3e-35ce-485f-bc22-feda9125c813', // religions.religion.id
    jenis_kelamin: selectedValue
  };

  // const [data, setData] = useState(paramsData);
  const handleChangeNIP = (e) => setNip(e.target.value);
  const handleChangeNamaDepan = (e) => setNamaDepan(e.target.value);
  const handleChangeNamaBelakang = (e) => setNamaBelakang(e.target.value);
  const handleChangeNamaPanggilan = (e) => setNamaPanggilan(e.target.value);
  const handleChangeNoHp = (e) => setNoHp(e.target.value);
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangeTempatLahir = (e) => setTempatLahir(e.target.value);

  // const handleChangeTanggalLahir = (e) => {

  //     setTanggalLahir(e.target.value);
  //     setOpenDatePickerComponent(true);
  // }

  /*
        `````````````````````````````````````````

        HANDLE POST LANJUT & VALIDATION SNACKBAR

        `````````````````````````````````````````
    */
  //  const { register, handleSubmit, errors } = useForm(); // initialise the hook

  const [isFormFilledAll, setFormFilledAll] = useState(false);

  const [infoError, setInfoError] = useState(false);

  const handlePOSTLanjut = () => {
    // console.log(data);
    console.log(paramsData);
    // localStorage.setItem('data_identitas', JSON.stringify(paramsData));

    //*FIRST CONDITIONS
    // if(paramsData.foto == ''){ setInfoError(true) }
    // if(paramsData.nip == ''){ setInfoError(true) }
    if (paramsData.nama_depan == '') {
      setInfoError(true);
    }
    if (paramsData.nama_belakang == '') {
      setInfoError(true);
    }
    // if(paramsData.nama_panggilan == ''){ setInfoError(true) }
    // if(paramsData.no_hp == ''){ setInfoError(true) }
    if (paramsData.email == '') {
      setInfoError(true);
    }
    // if(paramsData.tempat_lahir == ''){ setInfoError(true) }
    // if(paramsData.tanggal_lahir == ''){ setInfoError(true) }
    // if(paramsData.agama == ''){ setInfoError(true) }
    // if(paramsData.jenis_kelamin == ''){ setInfoError(true) }

    //*SECOND CONDITIONS
    if (
      // paramsData.foto !== '' &&
      // paramsData.nip !== '' &&
      paramsData.nama_depan !== '' &&
      paramsData.nama_belakang !== '' &&
      // paramsData.nama_panggilan !== '' &&
      // paramsData.no_hp !== '' &&
      paramsData.email !== ''
      // paramsData.tempat_lahir !== '' &&
      // paramsData.tanggal_lahir !== '' &&
      // paramsData.agama !== '' &&
      // paramsData.jenis_kelamin !== ''
    ) {
      // // console.log("Oke tersimpan semua di Local storage.. PART-1 [Informasi Dasar]");
      // Redirect(ToHrEmployeeIdentitasAlamat);

      let paramsData = {
        foto: imageBinaryPreviewUrl,
        foto_name: nameFile,
        nip: nip,
        nama_depan: namaDepan,
        nama_belakang: namaBelakang !== '' ? namaBelakang : '-',
        nama_panggilan: namaPanggilan,
        no_hp: noHp,
        email: email,
        tempat_lahir: tempatLahir,
        tanggal_lahir: tanggalLahir !== '' ? tanggalLahir : null,
        agama: '007fba3e-35ce-485f-bc22-feda9125c813', //event.target.value.id,
        jenis_kelamin: selectedValue
      };

      // console.log("paramsData simpan NOW :  ", paramsData);
      setDataForSimpanNow(paramsData);
      setSimpanNow(true);
    } else {
      // alert('Field harus terisi semua !');
      setFormFilledAll(true);
    }
  };

  /*
        ```````````````````
        HANDLE DATE PICKER

        ```````````````````
    */

  const [isOpenDatePicker, setOpenDatePicker] = useState(false);
  const [isOpenDatePickerComponent, setOpenDatePickerComponent] =
    useState(false);

  const handleOpenComponentDatePicker = () => {
    setOpenDatePickerComponent(true);
    setOpenDatePicker(true);
  };

  /*
        ````````````````````````````````
        HANDLE RUNNING BUTTON SIMPAN NOW

        ````````````````````````````````
    */

  const [isSimpanNow, setSimpanNow] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (isSimpanNow === true) {
      setLoader(true);
      // console.log("dataForSimpanNow : ", dataForSimpanNow);

      let fotoKaryawanFile =
        dataForSimpanNow.foto !== ''
          ? ConvertDataUrlToFile(
              dataForSimpanNow.foto,
              dataForSimpanNow.foto_name
            )
          : '';
      const fileFoto = new Blob([fotoKaryawanFile]); //*Work

      let formData = new FormData();

      formData.append('Employee[employee_number]', dataForSimpanNow.nip);
      formData.append('Employee[first_name]', dataForSimpanNow.nama_depan);
      formData.append('Employee[last_name]', dataForSimpanNow.nama_belakang);
      formData.append('Employee[nickname]', dataForSimpanNow.nama_panggilan);
      formData.append(
        'Employee[mobile_number_personal]',
        dataForSimpanNow.no_hp
      );
      formData.append('Employee[email]', dataForSimpanNow.email);
      formData.append(
        'Employee[place_of_birth]',
        dataForSimpanNow.tempat_lahir
      );
      formData.append(
        'Employee[date_of_birth]',
        `${
          dataForSimpanNow.tanggal_lahir !== null
            ? moment(dataForSimpanNow.tanggal_lahir).format('YYYY-MM-DD')
            : ''
        }`
      );
      formData.append(
        'Employee[religion_id]',
        dataForSimpanNow.agama !== undefined
          ? dataForSimpanNow.agama
          : '007fba3e-35ce-485f-bc22-feda9125c813'
      );
      formData.append(
        'Employee[sex_id]',
        dataForSimpanNow.jenis_kelamin == 'lakiLaki' ? 1 : 2
      );

      // formData.append('Employee[identity_number]',  identitas.nip); // HARUS-NYA KTP

      formData.append('Employee[photo_url]', fileFoto);

      if (dataForSimpanNow.foto == '') {
        formData.delete('Employee[photo_url]');
      }

      // console.log("Form Data : ", formData);

      if (userTokenState !== undefined) {
        const header = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'bearer ' + userTokenState
        };

        axios.defaults.headers.common = header;

        axios
          .post(URL_API + '/human-resource/employee/batch', formData)
          .then(function (response) {
            // setShowButtonSimpanNow(false);
            setLoader(false);
            setSimpanNow(false);

            // console.log("Response Original : ", response);

            if (response.status == 200) {
              /*
                                ````````````````````````````````
                                SET ID EMPLOYEE IN LOCAL STORAGE
    
                                ````````````````````````````````
                            */
              localStorage.setItem('employee_id', response.data.data.id);
              localStorage.setItem(
                'response_employee_detail',
                JSON.stringify(response.data.data)
              );

              /*
                                ``````````````````````````````````````````````````````````````````
                                REMOVE ALL DATA IN LOCAL STORAGE AFTER  BERHASIL SIMPAN DATA TO DB
    
                                ``````````````````````````````````````````````````````````````````
                            */
              localStorage.removeItem('data_identitas');
              localStorage.removeItem('data_identitas_alamat');
              localStorage.removeItem('data_identitas_info_tambahan');
              localStorage.removeItem('employee_data_info_pegawai');
              localStorage.removeItem('employee_data_keahlian');
              localStorage.removeItem('employee_data_keluarga');
              localStorage.removeItem('employee_data_kontak_darurat');
              localStorage.removeItem('employee_data_riwayat_experience');
              localStorage.removeItem('employee_data_riwayat_formal');
              localStorage.removeItem('employee_data_riwayat_informal');
              localStorage.removeItem('employee_data_riwayat_org');
              localStorage.removeItem('employee_data_info_position');
              localStorage.removeItem('employee_data_riwayat_kesehatan');

              /*
                                ```````````````````````````````````
                                REDIRECT TO EMPLOYEE PROFILE DETAIL
    
                                ```````````````````````````````````
                            */
              Redirect(ToHrEmployeeProfileDetailSeeDetail);
            }
          })
          .catch(function (error) {
            // console.log("Error : ", error.response);
            setLoader(false);
            setSimpanNow(false);

            // setShowButtonSimpanNow(false);

            if (error.response !== undefined) {
              if (error.response.status == 400) {
                setErrorStatus(400);
                if (error.response.data.info !== null) {
                  if (error.response.data.info !== null) {
                    if (error.response.data.info.errors !== null) {
                      if (error.response.data.info.errors.Employee !== null) {
                        if (
                          error.response.data.info.errors.Employee.hasOwnProperty(
                            'email'
                          ) === true
                        ) {
                          // // console.log("Yes, catch it : ", error.response.data.info.errors.Employee.email)
                          setTextErrorInformationValue(
                            error.response.data.info.errors.Employee.email
                          );
                          setOpenDialogError(true);
                        }

                        if (
                          error.response.data.info.errors.Employee.hasOwnProperty(
                            'date_of_birth'
                          ) === true
                        ) {
                          setOpenDialogError(true);
                          setTextErrorInformationValue(
                            error.response.data.info.errors.Employee
                              .date_of_birth
                          );
                        }
                      }
                    }
                  }
                }
              }
            } else {
              // setTextErrorInformationValue("Whoops, something went wrong!");
              setOpenDialogError(true);
            }
          });
      } else {
        alert('No Access Token available!');
      }
    }
  }, [isSimpanNow]);

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ``````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState('');
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: 'left' }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}
          >
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Identitas</b>
            </Typography>
            <ul>
              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Informasi Dasar</b>
                </Typography>
              </li>

              {/* <li className={classes.titleNotActive} style={{margin: 8}}>
                                <Typography variant='subtitle2' className={classes.titleNotActive}>
                                    <b>Alamat </b>
                                </Typography>
                            </li>

                            <li className={classes.titleNotActive} style={{margin: 8}}>
                                <Typography variant='subtitle2' className={classes.titleNotActive}>
                                    <b>Informasi Tambahan</b>
                                </Typography>
                            </li> */}
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: 'left ' }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}
          >
            <br />
            <Grid container>
              <Grid item sm={10}>
                <Typography variant="subtitle1" className={classes.titleHeader}>
                  <b>Informasi Dasar</b>
                </Typography>
              </Grid>

              <Grid item sm={2}></Grid>
            </Grid>

            <br />
            <List className={classes.list}>
              <ListItem style={{ textAlign: 'left' }}>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Foto</b>
                    </Typography>
                  }
                />

                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {imageBinaryPreviewUrl ? (
                    <Avatar
                      alt="You"
                      src={imageBinaryPreviewUrl}
                      className={classes.realAvatar}
                    />
                  ) : (
                    <Avatar
                      alt="You"
                      src={AvatarDummy}
                      className={classes.bigAvatar}
                    />
                  )}
                </div>
              </ListItem>
              <br />

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>NIP</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeNIP}
                  value={nip}
                  className={classes.textField}
                  placeholder={'NIP'}
                  variant="outlined"
                  // error={infoError == true && nip == '' ? true : false}
                  // helperText={infoError == true && nip == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Nama Depan<span style={{ color: '#cc0707' }}>*</span>{' '}
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeNamaDepan}
                  value={namaDepan}
                  placeholder={'Nama Depan'}
                  variant="outlined"
                  // inputProps= {{
                  //     style: { textAlign: "right" }
                  // }}
                  error={infoError == true && namaDepan == '' ? true : false}
                  helperText={
                    infoError == true && namaDepan == '' ? 'Wajib di isi' : ' '
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Nama Belakang<span style={{ color: '#cc0707' }}>*</span>
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeNamaBelakang}
                  value={namaBelakang}
                  placeholder={'Nama Belakang'}
                  variant="outlined"
                  error={infoError == true && namaBelakang == '' ? true : false}
                  helperText={
                    infoError == true && namaBelakang == ''
                      ? 'Wajib di isi'
                      : ' '
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Nama Panggilan</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeNamaPanggilan}
                  value={
                    namaPanggilan !== null && namaPanggilan !== 'null'
                      ? namaPanggilan
                      : '-'
                  }
                  placeholder={'Nama Panggilan'}
                  variant="outlined"
                  // error={infoError == true && namaPanggilan == '' ? true : false}
                  // helperText={infoError == true && namaPanggilan == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        No <i>Handphone</i>
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeNoHp}
                  value={noHp}
                  placeholder={'No Handphone'}
                  variant="outlined"
                  // error={infoError == true && noHp == '' ? true : false}
                  // helperText={infoError == true && noHp == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Alamat <i>Email</i>
                        <span style={{ color: '#cc0707' }}>*</span>
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeEmail}
                  value={email}
                  placeholder={'Alamat Email'}
                  variant="outlined"
                  error={infoError == true && email == '' ? true : false}
                  helperText={
                    infoError == true && email == '' ? 'Wajib di isi' : ' '
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Tempat Lahir</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeTempatLahir}
                  value={tempatLahir}
                  placeholder={'Tempat Lahir'}
                  variant="outlined"
                  // error={infoError == true && tempatLahir == '' ? true : false}
                  // helperText={infoError == true && tempatLahir == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Tanggal Lahir</b>
                    </Typography>
                  }
                />
                <MuiPickersUtilsProvider utils={LocalizedUtils}>
                  <ThemeProvider theme={theme}>
                    <Fragment>
                      {isOpenDatePickerComponent == true && (
                        <DatePicker
                          value={tanggalLahir}
                          format="DD MMMM YYYY"
                          onChange={setTanggalLahir}
                          animateYearScrolling
                          open={isOpenDatePicker}
                          onOpen={() => setOpenDatePicker(true)}
                          onClose={() => setOpenDatePicker(false)}
                          variant="dialog" // dialog, static, inline
                          disableToolbar={false}
                          label=""
                          className={classes.textField}
                        />
                      )}
                    </Fragment>
                  </ThemeProvider>
                </MuiPickersUtilsProvider>

                {isOpenDatePickerComponent == false && (
                  <form className={classes.container} noValidate>
                    <TextField
                      id="date"
                      onClick={handleOpenComponentDatePicker}
                      placeholder="17 Agustus 1945"
                      // variant="outlined"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        // startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleOpenComponentDatePicker}>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      // error={infoError == true && tanggalLahir == '' ? true : false}
                      // helperText={infoError == true && tanggalLahir == '' ? "Wajib di isi" : ' '}
                    />
                  </form>
                )}
              </ListItem>

              {/* <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}>
                      <b>Agama</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Islam"
                  className={classes.textField}
                  value={religions.religion}
                  onChange={handleChangeDropdown("religion")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  // error={infoError == true && religions.religion == '' ? true : false}
                  // helperText={infoError == true && religions.religion == '' ? "Wajib di isi" : ' '}
                >
                  {religionsData.map((option) => (
                    <MenuItem key={option.value} value={option}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem> */}

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Jenis Kelamin
                        <span style={{ color: '#cc0707' }}>*</span>
                      </b>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Radio
                        checked={selectedValue === 'lakiLaki'}
                        onChange={handleChangeRadioButton}
                        value="lakiLaki"
                        name="Laki-laki"
                        // disabled= {isLockedStatusState == 1 ? true : false}
                      />
                      <span style={{ fontFamily: 'Roboto' }}>Laki- laki</span>
                      <Radio
                        checked={selectedValue === 'perempuan'}
                        onChange={handleChangeRadioButton}
                        value="perempuan"
                        name="Perempuan"
                        // disabled= {isLockedStatusState == 1 ? true : false}
                      />
                      <span style={{ fontFamily: 'Roboto' }}>Perempuan</span>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: 'right' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <StickyFooter bottomThreshold={10}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.buttonOutlined}
              style={{ margin: 14 }}
              // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
              onClick={() => navigate(ToEmptyStateGeneral)}
            >
              Kembali
            </Button>
          </StickyFooter>
        </Grid>
        <Grid item sm={2} style={{ textAlign: 'left' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <StickyFooter bottomThreshold={10}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              style={{ margin: 14 }}
              disabled={false}
              // onClick={handleSubmit(handlePOSTLanjut)}
              onClick={handlePOSTLanjut}
            >
              {loader !== true ? (
                'Lanjut'
              ) : (
                <CircularProgress size={16} style={{ color: 'white' }} />
              )}
            </Button>
          </StickyFooter>
        </Grid>
      </Grid>

      {/*  

                `````````````````````
                VALIDATION SNACK BAR

                `````````````````````

            */}
      <Snackber
        isFormFilledAll={isFormFilledAll}
        setFormFilledAll={setFormFilledAll}
      />

      {/*  
                ````````````````````````````````````
                INFORMATION TO SIMPAN NOW SEKARANG !

                ````````````````````````````````````
            */}
      <Snackbeer
        classes={classes}
        isOpenDialogSimpanSekarang={isOpenDialogSimpanSekarang}
        setOpenDialogSimpanSekarang={setOpenDialogSimpanSekarang}
        dataForSimpanNow={dataForSimpanNow}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewIdentitas);

// const religionsData = [
//   {
//     id: "de5e0c55-9a31-4d3d-929d-e53421c62477",
//     value: "Buddha",
//     label: "Buddha",
//   },
//   {
//     id: "ace04e30-8065-408a-9c31-4b04685549b0",
//     value: "Katolik",
//     label: "Katolik",
//   },
//   {
//     id: "93c97c1b-dc96-4397-b0cf-7b339a82c0d4",
//     value: "Kristen protestan",
//     label: "Kristen protestan",
//   },
//   {
//     id: "41b5d847-a6e9-4b0e-a1d0-12b40c51239a",
//     value: "Hindu",
//     label: "Hindu",
//   },
//   {
//     id: "007fba3e-35ce-485f-bc22-feda9125c813",
//     value: "Islam",
//     label: "Islam",
//   },
// ];
