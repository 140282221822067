import React, { useContext, useState, useCallback } from "react";
import { Box, TextField, Button, InputAdornment } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import { useDebouncedCallback } from "use-debounce";

import DialogCreateTDL from "./dialog/DialogCreateTDL";
import AnchorFilter from "./anchor/AnchorFilter";
import ContextTask from "../../Context/ContextTask";
import { SET_SEARCH } from "../../Context/types";

const Action = ({ classes }) => {
  const { todoDispatch, setTriggerTodo } = useContext(ContextTask);

  const [openDialogCreateTDL, setOpenDialogCreateTDL] = useState(false);

  const [handleSearch] = useDebouncedCallback(
    useCallback((value) => {
      todoDispatch({
        type: SET_SEARCH,
        payload: { search: value },
      });
      setTriggerTodo(Math.floor(Math.random() * 100));
    }, []),
    1000,
    { maxWait: 5000 }
  );

  return (
    <Box
      mt={5}
      mb={4}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search To Do List"
          onChange={(e) => handleSearch(e.target.value)}
          style={{
            outline: "none",
            backgroundColor: "#fff",
            border: "1px solid #DFDFDF",
            fontSize: 16,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon
                  style={{ color: "#B1B1B1", fontSize: 16 }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Box mx={2} />
        <AnchorFilter classes={classes} />
      </Box>

      <Button
        size="small"
        varian="outlined"
        className={classes.button1}
        startIcon={<AddIcon />}
        onClick={() => setOpenDialogCreateTDL(true)}
      >
        To Do List
      </Button>

      <DialogCreateTDL
        classes={classes}
        openDialogCreateTDL={openDialogCreateTDL}
        setOpenDialogCreateTDL={setOpenDialogCreateTDL}
      />
    </Box>
  );
};

export default Action;
