import React from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import ImageForgetPassword from "../../../assets/images/Mask_Group_7.png";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  /*
        `````````````````````````````````````````````````````````````````````````
        If you want styling Text Field without 'Theme Pallet', using below code : 

        `````````````````````````````````````````````````````````````````````````

    */

  // cssLabel: {
  //     '&$cssFocused': {
  //       color: purple[500],
  //     },
  // },
  // cssFocused: {},
  // cssUnderline: {

  //     '&:after': {
  //         borderBottomColor: purple[500],
  //     },
  // },
  // cssOutlinedInput: {
  //     '&$cssFocused $notchedOutline': {
  //       borderColor: purple[500],
  //     },
  // },

  button: {
    width: "400px",
    height: "42px",
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  buttonProgress: {
    // color: red[500],
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    fontFamily: "Roboto",
  },
});

const ViewMaintenance = (props) => {
  const { classes } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        alignItems="center">
        <Grid item sm={12} style={{ textAlign: "center" }}>
          <br />
          <br />
          <img src={ImageForgetPassword} alt="Image Forget Password" />
          <br />
          <br />
          <br />
          <br />
          <Typography
            variant="h3"
            className={classes.title}
            style={{ color: "red" }}>
            <b>
              <i>Performate</i> is under maintenance...{" "}
            </b>
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}>
            <i>
              Maaf atas ketidaknyamanan Anda, beberapa waktu lagi kami akan
              kembali.
            </i>
          </Typography>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewMaintenance);
