import React, { useContext } from "react";
import { Box, Slider, Input, InputAdornment } from "@material-ui/core";

import ContextGoalDetail from "../../Goal/Context/ContextGoal";

const AnchorFilterWeight = ({ classes, onWeightValue, weightValue }) => {
  const context = useContext(ContextGoalDetail);

  const handleChange = (event, newValue) => {
    onWeightValue(newValue);
    context.setWeightValueAll({ min: newValue[0], max: newValue[1] });

    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  function valuetext(value) {
    return `${weightValue}%`;
  }

  const handleBlur = () => {
    if (weightValue < 0) {
      onWeightValue([0, 100]);
      context.setWeightValueAll({ min: 0, max: 100 });
    } else if (weightValue > 100) {
      context.setWeightValueAll({ min: 0, max: 100 });
    }

    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handleInputStart = (event) => {
    const val = Number(event.target.value);
    onWeightValue([val, weightValue[1]]);
    context.setWeightValueAll({ min: val, max: weightValue[1] });

    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handleInputEnd = (event) => {
    const val = Number(event.target.value);
    onWeightValue([weightValue[0], val]);
    context.setWeightValueAll({ min: weightValue[0], max: val });

    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  return (
    <Box minWidth={200}>
      <Slider
        value={weightValue}
        // onChange={handleChange}
        onChangeCommitted={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
      />

      <Box m={1} display="flex" justifyContent="space-between">
        <Input
          className={classes.input}
          value={weightValue[0]}
          margin="dense"
          onChange={handleInputStart}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
        <Input
          className={classes.input}
          value={weightValue[1]}
          margin="dense"
          onChange={handleInputEnd}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
        />
      </Box>
    </Box>
  );
};

export default AnchorFilterWeight;
