import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  MenuList,
  MenuItem,
  Divider,
  Collapse,
} from "@material-ui/core";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ContextProject from "../../../context/ContextProject";
import { ASSIGNEE_CONTENT_FILTER, FILTER_TASK } from "../../../context/types";

const FilterQuick = ({ classes, onCustomFilter, onClose }) => {
  const { projectTDispatch, setTriggerTask } = useContext(ContextProject);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [expandMy, setExpandMy] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const statuses =
    "options[filter][fields][status][0]=TO DO&options[filter][fields][status][1]=DOING";
  const notDoneStatus =
    "options[filter][fields][status][0]=TO DO&options[filter][fields][status][1]=DOING&options[filter][fields][status][2]=ON HOLD&options[filter][fields][status][3]=WAITING";
  const overdues = "options[filter][periods][0]=overdue_soon";
  const overdue = "options[filter][periods][0]=overdue";
  const me = `options[filter][fields][owner_id][0]=${user?.user_id}`;

  const myself = `${user?.member_first_name} ${user?.member_last_name}`;

  const handleMyAll = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: me,
        filterAchievement: null,
        filterStatus: null,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: [myself],
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  const handleMyOverdue = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdues,
        filterAssignee: me,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: [myself],
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  const handleAllOverdue = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdue,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  const handleMyLate = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdue,
        filterAssignee: me,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: [myself],
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  const handleAllLate = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdues,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  const handleMyNotDone = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: me,
        filterAchievement: null,
        filterStatus: notDoneStatus,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: [myself],
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  const handleAllNotDone = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: notDoneStatus,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    onClose();
  };

  const handleResetFilter = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: null,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
  };

  const handleExpandMy = () => {
    setExpandMy((prev) => !prev);
  };

  const handleExpandAll = () => {
    setExpandAll((prev) => !prev);
  };

  return (
    <div>
      <Box pl={2} p={1} minWidth={275}>
        <Box mb={1} display="flex" justifyContent="space-between">
          <Typography variant="caption" className={classes.title555}>
            <b>Quick Filter</b>
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.txtLink}
            onClick={handleResetFilter}
          >
            Reset
          </Typography>
        </Box>
      </Box>
      <MenuList role="menu" className={classes.menuList}>
        <MenuItem className={classes.dropdownItem} onClick={handleExpandMy}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span style={{ color: "#555" }}>My Task</span>
            {expandMy ? (
              <ExpandLessIcon style={{ fontSize: 18 }} />
            ) : (
              <ExpandMoreIcon style={{ fontSize: 18 }} />
            )}
          </Box>
        </MenuItem>
        <Collapse in={expandMy}>
          <MenuList>
            <MenuItem
              className={classes.dropdownItem}
              onClick={handleMyAll}
              style={{ backgroundColor: "#F7F8F6" }}
            >
              <span style={{ color: "#555" }}>All</span>
            </MenuItem>
            <Box my={0.2} />
            <MenuItem
              className={classes.dropdownItem}
              onClick={handleMyOverdue}
              style={{ backgroundColor: "#F7F8F6" }}
            >
              <span style={{ color: "#555" }}>Overdue Soon</span>
            </MenuItem>
            <Box my={0.2} />
            <MenuItem
              className={classes.dropdownItem}
              onClick={handleMyLate}
              style={{ backgroundColor: "#F7F8F6" }}
            >
              <span style={{ color: "#555" }}>Overdue</span>
            </MenuItem>
            <MenuItem
              className={classes.dropdownItem}
              onClick={handleMyNotDone}
              style={{ backgroundColor: "#F7F8F6" }}
            >
              <span style={{ color: "#555" }}>Not Done</span>
            </MenuItem>
          </MenuList>
        </Collapse>

        <MenuItem className={classes.dropdownItem} onClick={handleExpandAll}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span style={{ color: "#555" }}>All Task</span>
            {expandAll ? (
              <ExpandLessIcon style={{ fontSize: 18 }} />
            ) : (
              <ExpandMoreIcon style={{ fontSize: 18 }} />
            )}
          </Box>
        </MenuItem>
        <Collapse in={expandAll}>
          <MenuList>
            <MenuItem
              className={classes.dropdownItem}
              onClick={handleAllOverdue}
              style={{ backgroundColor: "#F7F8F6" }}
            >
              <span style={{ color: "#555" }}>Overdue</span>
            </MenuItem>
            <Box my={0.2} />
            <MenuItem
              className={classes.dropdownItem}
              onClick={handleAllLate}
              style={{ backgroundColor: "#F7F8F6" }}
            >
              <span style={{ color: "#555" }}>Overdue Soon</span>
            </MenuItem>
            <MenuItem
              className={classes.dropdownItem}
              onClick={handleAllNotDone}
              style={{ backgroundColor: "#F7F8F6" }}
            >
              <span style={{ color: "#555" }}>Not Done</span>
            </MenuItem>
          </MenuList>
        </Collapse>
      </MenuList>
      <Divider className={classes.dropdownDividerItem} />
      <MenuItem onClick={onCustomFilter} className={classes.dropdownItem}>
        <Box display="flex" alignItems="center">
          <TuneRoundedIcon
            fontSize="small"
            style={{ color: "#555", marginRight: 7 }}
          />
          <span style={{ color: "#555" }}>Custom Flter</span>
        </Box>
      </MenuItem>
    </div>
  );
};

export default FilterQuick;

// My Active Task - Overdue Soon
// - Assignee=(logged on user)
// - End Date=(end date less than 7 days from today)
// - Status='To Do','Doing'

// All Active Task - Overdue Soon
// - End Date=(end date less than 7 days from today)
// - Status='To Do','Doing'

// My Active Task - Late
// - Assignee=(logged on user)
// - End Date=(end date more than today)
// - Status='To Do','Doing'

// All Active Task - Overdue Soon
// - End Date=(end date more than today)
// - Status='To Do','Doing'
