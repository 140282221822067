import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGoalDetail from "../Context/ContextGoalDetail";

function DialogCreateText({
  classes,
  openDialogPlainText,
  setOpenDialogPlainText,
  setFireGoalDetail,
  item,
}) {
  const context = useContext(ContextGoalDetail);
  const ATTACHMENT_ID = "c9441e2a-972d-47da-a07c-a1eb5215de05";

  const [fileTextName, setFileTextName] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  /* ====================================================
    LifeCycle Hook
  ==================================================== */

  const handleCreateText = () => {
    // // console.log("ASEKKK", bodyText);

    setLoading(true);

    const dataFile = new FormData();
    const blobFile = new Blob([bodyText], { type: "text/plain" });
    const createFile = new File([blobFile], `${fileTextName}.txt`, {
      lastModified: new Date().getTime(),
      type: blobFile.type,
    });

    dataFile.append("GoalResultEvidence[evidence_category_id]", ATTACHMENT_ID);
    dataFile.append("GoalResultEvidence[description]", fileTextName);
    dataFile.append("GoalResultEvidence[content]", createFile);

    axiosConfig
      .post(`${URL_API}/swd/goal-result/${item.id}/evidence`, dataFile)
      .then((response) => {
        // // console.log("Res Plain Text", response);

        if (response.status === 200) {
          //   context.setSuccessTriggerFile(Math.floor(Math.random() * 1000 + 1));
          //   context.setModalResponse200(true);
          //   context.setMessages("Plain Text created successfully");
          setOpenDialogPlainText(false);
          setFireGoalDetail(true);
          context.setFireGoalDetail(true);

          setFileTextName("");
          setBodyText("");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);
        setFileTextName("");
        setBodyText("");

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogPlainText}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ width: 600, marginTop: 5 }}>
        <TextField
          id="outlined-bare"
          className={classes.textField}
          onChange={(e) => setFileTextName(e.target.value)}
          placeholder="title..."
          value={fileTextName}
          variant="outlined"
          fullWidth
          size="small"
          type="text"
          inputProps={{
            className: classes.title,
          }}
          InputProps={{
            endAdornment: ".txt",
          }}
          style={{ backgroundColor: "#e0e0e0" }}
        />
      </DialogTitle>

      <DialogContent style={{ textAlign: "left" }}>
        <DialogContentText id="alert-dialog-description">
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => setBodyText(e.target.value)}
            placeholder="Mulai menulis ..."
            value={bodyText}
            fullWidth
            type="text"
            multiline
            rows={15}
            helperText="Bold (*text*), Italic (_text_), Line Through (~text~), Underline (#text#)"
            inputProps={{
              className: classes.title,
            }}
          />

          <DialogActions
            style={{
              alignItems: "center",
              justifyContent: "flex-end",
              padding: 0,
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              className={classes.button2}
              onClick={() => setOpenDialogPlainText(false)}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.button}
              disabled={fileTextName === "" || bodyText === ""}
              onClick={handleCreateText}
            >
              {loading ? <CircularProgress color="#fff" size={15} /> : "Create"}
            </Button>
          </DialogActions>
        </DialogContentText>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogCreateText;
