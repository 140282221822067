import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  fade,
} from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

export const styles = (theme) => ({
  title: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },

  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },

  buttonOutlined: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },

  buttonDialog: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
  },

  /* 
        EXPAND PAPER
    */

  root: {
    width: "100%",
    // maxWidth: 370,
    // flexGrow: 1
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  details: {
    alignItems: "left",
  },
  column: {
    flexBasis: "49.33%",
  },
  columnSeratusPersen: {
    flexBasis: "99%",
  },
  // helper: {
  //   borderLeft: `2px solid ${theme.palette.divider}`,
  //   padding: theme.spacing(1, 2),
  // },
  // textField: {
  //   color:
  // }

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // borderRadius: '5',
    backgroundColor: fade(theme.palette.common.black, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.35),
    },

    // backgroundColor: grey,
    //     '&:hover': {
    //         backgroundColor: green,
    //     },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },

  inputRoot: {
    color: "inherit",
    fontFamily: "Roboto",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    color: "grey",
    // color: '#cc0707'
  },

  /*
      ``````````````
      COMPONENT LIST

      ``````````````


  */

  rootList: {
    // width: '100%',
    width: 480,
    // maxWidth: 370,
    paddingTop: 0,
  },
});
