import React from "react";
import { Box, Typography, Checkbox } from "@material-ui/core";

import clsx from "clsx";
import moment from "moment";

const ListItemWeeklyInWeek = ({
  classes,
  item,
  index,
  selected,
  setSelected,
  isSelected,
  onChooseWeek,
}) => {
  const isItemSelected = isSelected(item.week);

  const handleChangeCheckbox = (item) => {
    const selectedIndex = selected.indexOf(item.week);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item.week);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // console.log("selected ALL MA", newSelected);

    setSelected(newSelected);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      ml={1}
      mr={2}
      p={0.5}
      // onClick={() => handleChangeCheckbox(item)}
    >
      <Checkbox
        className={classes.checkboxRoot}
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        // checked={isItemSelected}
        checked={item.selected}
        onChange={(e) => onChooseWeek(e, item)}
        disableRipple
        color="default"
        inputProps={{ "aria-labelledby": `enhanced-table-checkbox-${index}` }}
      />
      <Typography variant="caption" className={classes.title333}>
        <span style={{ marginRight: 7 }}>Week {item.week}</span> <span>|</span>{" "}
        <span style={{ marginLeft: 7 }}>
          {moment(item.start).format("DD MMM YYYY HH:mm")} -{" "}
          {moment(item.finish).format("DD MMM YYYY HH:mm")}
        </span>
      </Typography>
    </Box>
  );
};

export default ListItemWeeklyInWeek;
