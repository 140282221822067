import React, { useEffect } from "react";
import { IndexedDBConfig } from "./constants/config-indexed-db";
import { initDB } from "react-indexed-db";

import { useRoutes, useRedirect } from "hookrouter";

import { routes } from "./routes";
import NotFoundPage from "./screen/Explore/NotFoundPage";
import CacheBuster from "./CacheBuster";

import { isAuthenticated } from "./constants/config-auth";
import preval from "preval.macro";

initDB(IndexedDBConfig);

const buildTimeCurrent = preval`module.exports = new Date().toLocaleString();`;

const App = () => {
  /*
     -------------------------------
    |                                 |
    | HANDLE ROUTER WITH HOOK ROUTER  |
    |                                 |
      -------------------------------
   */

  // ====== normal
  // useRedirect("/", "/");

  // ====== maintenance
  // useRedirect("/", "/maintenance");
  // useRedirect("/login", "/maintenance");
  // useRedirect("/register", "/maintenance");
  // useRedirect("/marketing", "/maintenance");
  // useRedirect("/hrd", "/maintenance");
  // useRedirect("/sales", "/maintenance");
  // useRedirect("/logistics", "/maintenance");
  // useRedirect("/operational", "/maintenance");
  // useRedirect("/price", "/maintenance");
  // useRedirect("/testimoni", "/maintenance");
  // useRedirect("/support", "/maintenance");
  // useRedirect("/contact", "/maintenance");
  // useRedirect("/about", "/maintenance");
  // useRedirect("/demo-schedule", "/maintenance");
  // useRedirect("/feature/goal-management", "/maintenance");
  // useRedirect("/feature/measured-activity", "/maintenance");
  // useRedirect("/feature/scoreboard", "/maintenance");
  // useRedirect("/explore", "/maintenance");

  useRedirect("/", isAuthenticated() ? "/dashboard" : "/");
  useRedirect("/login", isAuthenticated() ? "/dashboard" : "/login");
  useRedirect("/register", isAuthenticated() ? "/dashboard" : "/register");
  useRedirect("/marketing", isAuthenticated() ? "/dashboard" : "/marketing");
  useRedirect("/hrd", isAuthenticated() ? "/dashboard" : "/hrd");
  useRedirect("/sales", isAuthenticated() ? "/dashboard" : "/sales");
  useRedirect("/logistics", isAuthenticated() ? "/dashboard" : "/logistics");
  useRedirect(
    "/operational",
    isAuthenticated() ? "/dashboard" : "/operational"
  );
  useRedirect("/price", isAuthenticated() ? "/dashboard" : "/price");
  useRedirect("/testimoni", isAuthenticated() ? "/dashboard" : "/testimoni");
  useRedirect("/support", isAuthenticated() ? "/dashboard" : "/support");
  useRedirect("/contact", isAuthenticated() ? "/dashboard" : "/contact");
  useRedirect("/about", isAuthenticated() ? "/dashboard" : "/about");
  useRedirect(
    "/demo-schedule",
    isAuthenticated() ? "/dashboard" : "/demo-schedule"
  );
  useRedirect(
    "/feature/goal-management",
    isAuthenticated() ? "/dashboard" : "/feature/goal-management"
  );
  useRedirect(
    "/feature/measured-activity",
    isAuthenticated() ? "/dashboard" : "/feature/measured-activity"
  );
  useRedirect(
    "/feature/scoreboard",
    isAuthenticated() ? "/dashboard" : "/feature/scoreboard"
  );
  useRedirect("/explore", isAuthenticated() ? "/dashboard" : "/explore");
  useRedirect("/dashboard", !isAuthenticated() ? "/login" : "/dashboard");
  useRedirect("/gaol", !isAuthenticated() ? "/login" : "/gaol");
  useRedirect("/ma", !isAuthenticated() ? "/login" : "/ma");
  useRedirect("/all-ma", !isAuthenticated() ? "/login" : "/all-ma");
  useRedirect("/project", !isAuthenticated() ? "/login" : "/project");
  useRedirect("/drive", !isAuthenticated() ? "/login" : "/drive");
  useRedirect(
    "/new-so-treeview-org-chart",
    !isAuthenticated() ? "/login" : "/new-so-treeview-org-chart"
  );
  useRedirect(
    "/general-empty",
    !isAuthenticated() ? "/login" : "/general-empty"
  );
  useRedirect("/reports", !isAuthenticated() ? "/login" : "/reports");
  useRedirect("/archive", !isAuthenticated() ? "/login" : "/archive");
  useRedirect("/task", !isAuthenticated() ? "/login" : "/task");
  useRedirect(
    "/company-setting",
    !isAuthenticated() ? "/login" : "/company-setting"
  );
  useRedirect("/7wd-period", !isAuthenticated() ? "/login" : "/7wd-period");

  const routeResult = useRoutes(routes);

  useEffect(() => {
    // console.log("Build Date time : ", buildTimeCurrent);
  }, []);

  return (
    <CacheBuster>
      {({ loading, isLatestVer, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVer) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return routeResult || <NotFoundPage />;
      }}
    </CacheBuster>
  );
};

export default App;
