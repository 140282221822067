import React, { forwardRef, useContext, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  Grid,
  List,
} from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FolderIcon from "@material-ui/icons/Folder";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";

import moment from "moment";

import FormatBytes from "../../../../utilities/FormatBytes";
import ContextGlobalDrive from "../../context/ContextDrive";

import ListMemberUser from "./ListMemberUser";
import ListMemberUnit from "./ListMemberUnit";
import DialogSetPermission from "./DialogSetPermission";
import DialogSetPermissionUnit from "./DialogSetPermissionUnit";

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogFolderSetting({
  classes,
  openDialogFolderSetting,
  setOpenDialogFolderSetting,
}) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [openDialogSetPermission, setOpenDialogSetPermission] = useState(false);
  const [
    openDialogSetPermissionUnit,
    setOpenDialogSetPermissionUnit,
  ] = useState(false);

  return (
    <Dialog
      fullScreen
      open={openDialogFolderSetting}
      TransitionComponent={Transition}>
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}>
        <Toolbar>
          <Button
            className={classes.backButton}
            onClick={() => setOpenDialogFolderSetting(false)}
            startIcon={<NavigateBeforeIcon style={{ color: "#888" }} />}>
            Back
          </Button>
        </Toolbar>
      </AppBar>

      <Box marginLeft={8} marginBottom={2}>
        <Typography
          variant="h6"
          className={classes.title}
          style={{ color: "#333" }}>
          <b>Folder Settings</b>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" marginLeft={8} marginBottom={4}>
        <Avatar
          style={{
            width: 34,
            height: 34,
          }}>
          <FolderIcon style={{ fontSize: 22 }} />
        </Avatar>

        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "#333", marginLeft: 15 }}>
          <b>{context.detailFolder.name}</b>
        </Typography>
      </Box>

      <Box marginLeft={8} marginRight={8}>
        <Divider />
      </Box>

      <Box
        marginLeft={8}
        marginTop={4}
        marginBottom={4}
        display="flex"
        justifyContent="space-between"
        width={350}>
        {/* Date Modiifed */}
        <Box>
          <Typography variant="subtitle1" className={classes.titleSidebar}>
            Date Modified
          </Typography>

          <Typography variant="subtitle2" className={classes.textSidebar}>
            {context.detailFolder !== undefined
              ? moment(context.detailFolder.modified_at).format(
                  "DD MMM YYYY, HH:mm"
                )
              : moment(context.latestModifiedDate).format("DD MMM YYYY, HH:mm")}
          </Typography>
        </Box>

        <div className={classes.dateStorageDivider} />

        {/* Sorage Size */}
        <Box>
          <Typography variant="subtitle1" className={classes.titleSidebar}>
            Size
          </Typography>

          <Typography variant="subtitle2" className={classes.textSidebar}>
            {context.detailFolder !== undefined
              ? FormatBytes(Number(context.detailFolder.size))
              : FormatBytes(Number(context.totalSize))}
          </Typography>
        </Box>
      </Box>

      <Box marginLeft={8} marginRight={8}>
        <Divider />
      </Box>

      <Box marginLeft={8} marginTop={4} width={800}>
        <Grid container>
          <Grid item md={6}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#333", marginRight: 5 }}>
                <b>Member User</b>
              </Typography>

              <IconButton
                size="small"
                onClick={() => setOpenDialogSetPermission(true)}>
                <AddCircleOutlineRoundedIcon
                  style={{ color: "#d1354a" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>

            <Box width={300}>
              <List style={{ marginLeft: -15 }}>
                {context.listMemberUser.length > 0 &&
                  context.listMemberUser.map((item, i) => (
                    <ListMemberUser key={i} item={item} classes={classes} />
                  ))}
              </List>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#333", marginRight: 5 }}>
                <b>Member Unit</b>
              </Typography>

              <IconButton
                size="small"
                onClick={() => setOpenDialogSetPermissionUnit(true)}>
                <AddCircleOutlineRoundedIcon
                  style={{ color: "#d1354a" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>

            <Box width={350}>
              <List style={{ marginLeft: -15 }}>
                {context.listMemberUnit.length > 0 &&
                  context.listMemberUnit.map((item, i) => (
                    <ListMemberUnit key={i} item={item} classes={classes} />
                  ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <DialogSetPermission
        classes={classes}
        item={context.detailFolder}
        openDialogSetPermission={openDialogSetPermission}
        setOpenDialogSetPermission={setOpenDialogSetPermission}
      />

      <DialogSetPermissionUnit
        classes={classes}
        item={context.detailFolder}
        openDialogSetPermissionUnit={openDialogSetPermissionUnit}
        setOpenDialogSetPermissionUnit={setOpenDialogSetPermissionUnit}
      />
    </Dialog>
  );
}

export default DialogFolderSetting;
