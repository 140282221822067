import React, { Fragment } from "react";
import { Typography, Box, Paper, InputBase, Divider } from "@material-ui/core";

import { grey } from "@material-ui/core/colors";

const CardSecondTextField = (props) => {
  const {
    classes,
    i,

    context,
    valueGoalName,
    handleChangeNameSubGoal,
    listOwnerChoosedLength,

    startValueNow,
    handleChangeStartValueNow,

    targetValueNow,
    handleChangeTargetValueNow,

    goalDetailState,

    //*TARGET RANEG VALUE
    // isShowTargetRangeValue,
    // textInputReff,

    // handleChangeTargetRangeValueNow,
    // targetRangeValueNow
  } = props;

  // // console.log("GOLDET 2nd", goalDetailState);

  const calc_method = goalDetailState.calculation_method_id;
  const forSum =
    goalDetailState.calculatedValue.target_value / listOwnerChoosedLength;
  const forAvg = goalDetailState.calculatedValue.target_value;

  const defaultTargetVal = calc_method === "2" ? forAvg : forSum;

  return (
    <Fragment key={i}>
      <Paper
        elevation={0}
        component="form"
        className={classes.rootTextField}
        // key={i}
        style={{
          backgroundColor: "transparent",
          border: `${"1px solid " + grey[300]}`,
        }}
      >
        <Box flexGrow={1} width="65%">
          <InputBase
            fullWidth
            // margin='dense'
            className={classes.input}
            // placeholder={goalDetailState.name}
            defaultValue={goalDetailState.name}
            value={valueGoalName[i]}
            inputProps={{
              "aria-label": "Deposit Multi",
              className: classes.title,
            }}
            onChange={(e) => handleChangeNameSubGoal(e, i)}
          />
        </Box>

        <Box width="3%">
          <Divider className={classes.divider} orientation="vertical" />
        </Box>
        <Box width="15%">
          <InputBase
            className={classes.input}
            placeholder={
              goalDetailState.calculatedValue.start_value /
              listOwnerChoosedLength
            }
            inputProps={{
              "aria-label": "Start Value",
              className: classes.title,
            }}
            type="number"
            value={startValueNow[i]}
            onChange={(e) => handleChangeStartValueNow(e, i)}
          />
        </Box>

        <Box width="2%">
          <Typography variant="subtitle1">-</Typography>
        </Box>

        <Box width="15%">
          <InputBase
            className={classes.input}
            // placeholder={
            //   goalDetailState.calculatedValue.target_value /
            //   listOwnerChoosedLength
            // }
            // defaultValue={context.targetValue / listOwnerChoosedLength}
            inputProps={{
              "aria-label": "Target Value",
              className: classes.title,
            }}
            type="number"
            value={targetValueNow[i]}
            defaultValue={defaultTargetVal}
            onChange={(e) => handleChangeTargetValueNow(e, i)}
          />
        </Box>
      </Paper>

      <br />
    </Fragment>
  );
};

export default CardSecondTextField;
