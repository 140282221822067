import React, { useState, useEffect, Fragment } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";

import DialogError from "../../../../components/DialogError";
import { URL_API } from "../../../../constants/config-api";

const DialogDelete = (props) => {
  const {
    classes,
    isModalDelete,
    setModalDelete,
    idEmployee,
    employeeName,
    setSuccessDelete,
  } = props;

  const [userTokenState, setUserTokenState] = useState("");

  useEffect(() => {
    if (isModalDelete == true) {
      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);
    }
  }, [isModalDelete]);

  const [loader, setLoader] = useState(false);

  const handleDelete = () => {
    setLoader(true);
    setSuccessDelete(false);

    if (userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .delete(URL_API + `/human-resource/employee/${idEmployee}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          localStorage.removeItem("employee_id");
          // window.location.reload();

          setModalDelete(false);
          setSuccessDelete(true);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response)

          if (error.response !== undefined) {
            if (error.response.status === 429) {
              setErrorStatus(429);
              setTextErrorInformation("Too Many Request !");
              setOpenDialogError(true);
            }
            if (error.response.status === 422) {
              setErrorStatus(422);
              setTextErrorInformation(".");
              setListError(error.response.data.data);
              setOpenDialogError(true);
            }
          } else {
            setOpenDialogError(true);
          }
        });
    } else {
      // console.log("No Access Token available!")
    }
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isModalDelete}
        onClose={() => setModalDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "left" }}
        ></DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}
            >
              <b>
                Apakah Anda yakin ingin menghapus <i>{employeeName}</i> ?
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleDelete}
            variant="contained"
            className={classes.button}
            // fullWidth
          >
            {loader !== true ? (
              "Yakin "
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogDelete;
