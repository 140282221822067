import React, { Fragment, useContext } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  IconButton,
} from "@material-ui/core";

import FlagRoundedIcon from "@material-ui/icons/FlagRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { navigate } from "hookrouter";

function GoalListGallery({ classes, item, count }) {
  // const evidenceCount = count[item.goal_id];

  //   // console.log("Itung Golz", hitung.length);

  const handleNavigateGallery = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    let goalName = data.name.replace(/,+/g, " ");
    breadcrumb.push(goalName);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  let statusId;

  if (item.status_id === "2") {
    statusId = "Status : Approved";
  } else if (item.status_id === "3") {
    statusId = "Status : Active";
  } else if (item.status_id === "6") {
    statusId = "Status : Over Due";
  } else if (item.status_id === "4") {
    statusId = "Status : Completed";
  }

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#fffee0" }}>
        <TableCell padding="checkbox" />
        <TableCell>
          <Box display="flex" alignItems="center">
            <Avatar
              style={{
                width: 34,
                height: 34,
                backgroundColor: "#ffbe76",
              }}
            >
              <FlagRoundedIcon style={{ fontSize: 22, color: "#e67e22" }} />
            </Avatar>

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15 }}
              onClick={() => handleNavigateGallery(item)}
            >
              {item !== undefined && item.name}
            </Typography>

            <span style={{ color: "#999", marginLeft: 5, marginRight: 5 }}>
              -
            </span>

            <Typography style={{ color: "#999", fontSize: 13 }}>
              {statusId}
            </Typography>
          </Box>
        </TableCell>

        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />

        <TableCell align="right">
          <IconButton>
            <MoreVertIcon style={{ color: "transparent" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default GoalListGallery;
