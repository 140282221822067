import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import StickyFooter from "react-sticky-footer";
import DoneIcon from "@material-ui/icons/Done";

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeRiwayatPengalamanKerja } from "../../../constants/config-redirect-url";

import { styles } from "./Style/StyleRiwayat"; // NEXT GANTI TARO DI COMPONENT INFO PEGAWAI SENDIRI !

// import Snackber from '../Components/Snackber';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewRiwayatOrganisasi = (props) => {
  const { classes } = props;

  /*
        ````````````````````````````````````
        COMPONENT DID MOUNT

        ````````````````````````````````````
    */
  const [listDataRiwayatOrganisasi, setListDataRiwayatOrganisasi] =
    useState("");

  let dataList = [];
  const employeeDataRiwayatORG = localStorage.getItem(
    "employee_data_riwayat_org"
  );
  let employeeDataRiwayatORGAfterParse =
    employeeDataRiwayatORG !== null ? JSON.parse(employeeDataRiwayatORG) : [];

  useEffect(() => {
    console.log(
      "employeeDataRiwayatORGAfterParse : ",
      employeeDataRiwayatORGAfterParse
    );

    setListDataRiwayatOrganisasi(employeeDataRiwayatORGAfterParse);
  }, []);

  /*
        `````````````````````
        MODAL TAMBAH POSITION

        `````````````````````
    */

  const [modalRiwayatOrganisation, setModalRiwayatOrganisation] =
    useState(false);

  /*
        `````````
        FORM DATA

        `````````
    */

  const [organisasi, setOrganisasi] = useState("");
  const [namaJabatan, setNamaJabatan] = useState("");
  const [kota, setKota] = useState("");

  const [jenjang, setJenjang] = useState({
    name: "",
  });

  const [masaBelajarIN, setBelajarIN] = useState({
    name: "",
  });

  const [masaBelajarOUT, setBelajarOUT] = useState({
    name: "",
  });

  const [keterangan, setKeterangan] = useState("");

  const handleChangeOrganisasi = (e) => setOrganisasi(e.target.value);
  const handleChangeNamaJabatan = (e) => setNamaJabatan(e.target.value);
  const handleChangeKota = (e) => setKota(e.target.value);

  const handleChangeJenjang = (name) => (event) => {
    setJenjang({ ...jenjang, [name]: event.target.value });
  };

  const handleChangeBelajarIN = (name) => (event) => {
    setBelajarIN({ ...masaBelajarIN, [name]: event.target.value });
  };

  const handleChangeBelajarOUT = (name) => (event) => {
    setBelajarOUT({ ...masaBelajarOUT, [name]: event.target.value });
  };

  const handleChangeKeterangan = (e) => setKeterangan(e.target.value);

  /*
        ```````````````````
        HANDLE POST LANJUT 

        ```````````````````
    */

  // let paramsData = {

  //     organisasi : organisasi,
  //     name_jabatan : namaJabatan,
  //     kota: kota,
  //     tahun_masuk: masaBelajarIN.name,
  //     tahun_selesai: masaBelajarOUT.name,
  //     keterangan: keterangan

  // };

  const handlePOSTLanjut = () => {
    // // console.log("Params Data RIWAYAT ORGANISASI : ", paramsData);

    // localStorage.setItem('employee_data_riwayat_org', JSON.stringify(paramsData));

    Redirect(ToHrEmployeeRiwayatPengalamanKerja);
    // setModalRiwayatOrganisation(false);
  };

  /*
        ```````````````````
        HANDLE SIMPAN DATA

        ```````````````````
    */

  const handleSimpanDataRiwayatOrganization = () => {
    let paramsData = {
      organisasi: organisasi,
      name_jabatan: namaJabatan,
      kota: kota,
      tahun_masuk: masaBelajarIN.name,
      tahun_selesai: masaBelajarOUT.name,
      keterangan: keterangan,
    };

    if (listDataRiwayatOrganisasi.length > 0) {
      // console.log("Run v1");

      const newList = [...listDataRiwayatOrganisasi, paramsData];

      // console.log("newList : ", newList);

      localStorage.setItem(
        "employee_data_riwayat_org",
        JSON.stringify(newList)
      );
      setListDataRiwayatOrganisasi([...listDataRiwayatOrganisasi, paramsData]);
    } else {
      // console.log("Run v2");

      dataList.push(paramsData);
      localStorage.setItem(
        "employee_data_riwayat_org",
        JSON.stringify(dataList)
      );

      window.location.reload();
    }

    setModalRiwayatOrganisation(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}
          >
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Riwayat</b>
            </Typography>
            <ul>
              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Kesehatan</b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Pendidikan formal </b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Pendidikan Informal </b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Organisasi</b>
                </Typography>
              </li>
              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}
                >
                  <b>Pengalaman Kerja</b>
                </Typography>
              </li>
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: "left " }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}
          >
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Organisasi</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              onClick={() => setModalRiwayatOrganisation(true)}
            >
              <b>+ Tambah Organisasi </b>
            </Typography>

            {listDataRiwayatOrganisasi.length > 0 &&
              listDataRiwayatOrganisasi.map((item, i) => {
                return (
                  <Grid container key={i}>
                    <Grid xs={4}>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <b>{item.organisasi}</b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <b>
                          {item.tahun_masuk + " - "} {item.tahun_selesai}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography
                        variant="subtitle1"
                        className={classes.titleHeader}
                      >
                        <b>{item.kota}</b> &nbsp;&nbsp;
                        <span
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}
                        >
                          Edit
                        </span>{" "}
                        &nbsp;&nbsp;- &nbsp;&nbsp;
                        <span
                          // onClick={() => setModalDeleteAnggotaKeluarga(true)}
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}
                        >
                          Hapus
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        Sebagai <i>{item.name_jabatan}</i>
                      </Typography>

                      <br />
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <i>{item.keterangan}</i>
                      </Typography>

                      <br />
                    </Grid>
                  </Grid>
                );
              })}

            <br />
            <br />
          </Paper>
        </Grid>
      </Grid>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid container spacing={8} direction="row" justify="center">
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{ margin: 14 }}
            disabled={false}
            onClick={() => Redirect(ToHrEmployeeRiwayatPengalamanKerja)}
          >
            Lanjut
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={modalRiwayatOrganisation}
        onClose={() => setModalRiwayatOrganisation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography variant="subtitle1" className={classes.title}>
            <b>Tambah Organisasi</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {" "}
            {/* className={classes.list}  */}
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Nama Organisasi</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeOrganisasi}
                value={organisasi}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
                // error={infoError == true && npwp == '' ? true : false}
                // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
                // required={true}
                // name='nip'
                // color='primary'
                // onKeyDown={handleEnterPress}
                // disabled= {isLockedStatusState == 1 ? true : false}
                // fullWidth
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Nama Jabatan</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeNamaJabatan}
                value={namaJabatan}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Kota</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeKota}
                value={kota}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Masa Bakti</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-tahun-in"
                select
                label="Tahun Masuk"
                className={classes.textFieldDropDown}
                value={masaBelajarIN.name}
                onChange={handleChangeBelajarIN("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-tahun-out"
                select
                label="Tahun Selesai"
                className={classes.textFieldDropDown}
                value={masaBelajarOUT.name}
                onChange={handleChangeBelajarOUT("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Keterangan</b>
                  </Typography>
                }
              />
              <TextField
                onChange={handleChangeKeterangan}
                style={{ marginTop: 0 }}
                id="outlined-multiline-static-visi"
                // label="Multiline"
                multiline
                rows="4"
                // defaultValue="Default Value"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                // fullWidth
              />
            </ListItem>
          </List>

          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}
        >
          <Button
            onClick={() => setModalRiwayatOrganisation(false)}
            variant="outlined"
            size="small"
            className={classes.buttonModalCancel}
          >
            Batal
          </Button>

          <Button
            // onClick={() => Redirect(ToCompletionProfile)}
            // onClick= {() => setModalRiwayatOrganisation(false)}
            // onClick={handlePOSTLanjut}
            onClick={handleSimpanDataRiwayatOrganization}
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttonModal}
          >
            Simpan
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewRiwayatOrganisasi);

const tahuns = [
  {
    value: 1991,
    label: 1991,
  },
  {
    value: 1992,
    label: 1992,
  },
  {
    value: 1993,
    label: 1993,
  },
  {
    value: 1994,
    label: 1994,
  },
  {
    value: 1995,
    label: 1995,
  },
  {
    value: 1996,
    label: 1996,
  },
  {
    value: 1997,
    label: 1997,
  },
  {
    value: 1998,
    label: 1998,
  },
  {
    value: 1999,
    label: 1999,
  },
  {
    value: 2005,
    label: 2005,
  },
  {
    value: 2006,
    label: 2006,
  },
  {
    value: 2007,
    label: 2007,
  },
  {
    value: 2008,
    label: 2009,
  },
  {
    value: 2010,
    label: 2010,
  },
  {
    value: 2011,
    label: 2011,
  },
  {
    value: 2012,
    label: 2012,
  },
  {
    value: 2013,
    label: 2013,
  },
];
