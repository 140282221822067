import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import SnackbarError from "../../../../components/SnackbarError";
import handleError from "../../../Report/components/global/handleError";

const DialogDeleteEventNotif = ({
  classes,
  item,
  openDeleteEventNotif,
  setOpenDeleteEventNotif,
  setTriggerNotif,
}) => {
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteEvent = () => {
    setLoading(true);

    const data = {
      id: {
        0: item.id,
      },
    };

    AxiosConfig.post(
      `${URL_API}/account-management/notification/setting/delete`,
      data
    )
      .then((res) => {
        if (res.status === 200) {
          setTriggerNotif(Math.floor(Math.random() * 100));
          setOpenDeleteEventNotif(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);

        setLoading(false);

        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };
  return (
    <Dialog
      open={openDeleteEventNotif}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Delete Event</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Apakah Anda yakin ingin hapus Event <b>{item.eventType?.name}</b> ?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button0}
          onClick={loading ? null : () => setOpenDeleteEventNotif(false)}
        >
          Cancel
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleDeleteEvent}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDeleteEventNotif;
