import React, { useContext, useState } from "react";
import DialogCreate from "./DialogCreate";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../../components/StyledMenuDropdown";
import DialogDelete from "./DialogDelete";
import DialogEdit from "./DialogEdit";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
function ViewTabWorkPattern({
  classes,
  isModalProgress,
  setModalProgress,
  dataWorkPatternList,
  setDataWorkPatternList,
  getWorkPatternList,
}) {
  const context = useContext(ContextAttendanceSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterWorkPattern, setMasterWorkPattern] = useState({
    fields: {},
    masterUnitCollection: [],
    masterPositionCollection: [],
    masterIntervalCollections: [
      {
        id: "Weekly",
        name: "Weekly",
      },
      {
        id: "Monthly",
        name: "Monthly",
      },
      {
        id: "Custom",
        name: "Custom",
      },
    ],
  });

  const [workPattern, setWorkPattern] = useState({
    id: "",
    name: "",
    interval: "", //Weekly, Monthly, Custom
    interval_obj: {}, //Weekly, Monthly, Custom
    duration: 0,
    work_duration: 0,
    off_duration: 0,
  });

  const resetWorkPattern = () => {
    let workPatternTemporary = workPattern;
    workPatternTemporary.id = "";
    workPatternTemporary.name = "";
    workPatternTemporary.interval = "";
    workPatternTemporary.interval_obj = {};
    workPatternTemporary.duration = 0;
    workPatternTemporary.work_duration = 0;
    workPatternTemporary.off_duration = 0;
    setWorkPattern({ ...workPatternTemporary });
  };

  const handleShowModalCreate = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/work-pattern/create`)
      .then(function (response) {
        resetWorkPattern();
        // console.log("Response Original : ", response.data);
        let masterData = masterWorkPattern;
        masterData.fields = response.data.data.fields;
        setMasterWorkPattern(masterData);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const createWorkPattern = () => {
    context.setModalProgress(true);
    setLoader(true);
    let data = {
      WorkPattern: workPattern,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/work-pattern/create`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(false);
        context.setMessages("Data work pattern berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetWorkPattern();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataWorkPatternList.options.paging.limit * newPage,
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 0, parseInt(event.target.value));
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDelete = (e, item) => {
    handleDropdownClose();
    let workPatternTemporary = workPattern;
    workPatternTemporary.id = item.id;
    workPatternTemporary.name = item.name;
    setWorkPattern({ ...workPatternTemporary });
    context.setModalDelete(true);
  };

  const handleDelete = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/human-resource/work-pattern/${workPattern.id}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalDelete(false);
          context.setMessages("Berhasil di hapus !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, 0, 0, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleShowModalEdit = (e, item) => {
    handleDropdownClose();
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/work-pattern/${item.id}/update`)
      .then(function (response) {
        resetWorkPattern();
        // console.log("Response Original : ", response.data);
        let masterData = masterWorkPattern;
        masterData.fields = response.data.data.fields;
        setMasterWorkPattern(masterData);
        getWorkPattern(item);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const getWorkPattern = (item) => {
    axiosConfig
      .get(`${URL_API}/human-resource/work-pattern/${item.id}`)
      .then(function (response) {
        resetWorkPattern();
        // console.log("Response Original : ", response.data);
        let workPatternTemporary = workPattern;
        workPatternTemporary.id = response.data.data.id;
        workPatternTemporary.name = response.data.data.name;
        workPatternTemporary.interval = response.data.data.interval;
        if (workPatternTemporary.interval) {
          masterWorkPattern.masterIntervalCollections.map((item) => {
            if (item.id === workPatternTemporary.interval) {
              workPatternTemporary.interval_obj = item;
            }
          });
        }
        workPatternTemporary.duration = parseInt(response.data.data.duration);
        workPatternTemporary.work_duration = parseInt(
          response.data.data.work_duration
        );
        workPatternTemporary.off_duration = parseInt(
          response.data.data.off_duration
        );
        setWorkPattern({ ...workPatternTemporary });
        context.setModalEdit(true);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalEdit(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleUpdate = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      let data = {
        WorkPattern: workPattern,
      };
      axiosConfig
        .put(URL_API + `/human-resource/work-pattern/${workPattern.id}`, data)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalEdit(false);
          context.setMessages("Berhasil di edit !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, null, null, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const refreshList = (search, offset, page, limit) => {
    let dataWorkPatternListTemporary = dataWorkPatternList;
    dataWorkPatternListTemporary.options.filter.search =
      search !== null
        ? search
        : dataWorkPatternListTemporary.options.filter.search;
    dataWorkPatternListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataWorkPatternListTemporary.options.paging.offset;
    dataWorkPatternListTemporary.options.paging.page =
      page !== null ? page : dataWorkPatternListTemporary.options.paging.page;
    dataWorkPatternListTemporary.options.paging.limit =
      limit !== null
        ? limit
        : dataWorkPatternListTemporary.options.paging.limit;
    setDataWorkPatternList({ ...dataWorkPatternListTemporary });
    getWorkPatternList();
  };

  const optionDurationMonthly = [
    {
      id: 28,
      name: "28",
    },
    {
      id: 29,
      name: "29",
    },
    {
      id: 30,
      name: "30",
    },
    {
      id: 31,
      name: "31",
    },
  ];

  const [selectedDurationMonthly, setSelectedDurationMonthly] = useState({});

  const handleChangeInterval = (newValue) => {
    let workPatternTemporary = workPattern;
    workPatternTemporary.interval_obj = newValue;
    workPatternTemporary.interval = newValue ? newValue.id : "";
    if (newValue && newValue.id === "Weekly") {
      workPatternTemporary.duration = 7;
      workPatternTemporary.work_duration = 7;
      workPatternTemporary.off_duration = 0;
    } else if (newValue && newValue.id === "Monthly") {
      workPatternTemporary.duration = 28;
      workPatternTemporary.work_duration = 28;
      workPatternTemporary.off_duration = 0;
      setSelectedDurationMonthly({
        id: 28,
        name: "28",
      });
    } else if (newValue && newValue.id === "Custom") {
      workPatternTemporary.duration = 1;
      workPatternTemporary.work_duration = 1;
      workPatternTemporary.off_duration = 0;
    }
    setWorkPattern({ ...workPatternTemporary });
  };

  const handleChangeWorkDuration = (event) => {
    let workDuration = parseInt(event.target.value);
    let offDuration = 0;
    if (!(!isNaN(workDuration) && workDuration <= workPattern.duration)) {
      if (event.target.value === "") {
        workDuration = "";
        offDuration = "";
      } else {
        workDuration = workPattern.duration;
        offDuration = workPattern.duration - workDuration;
      }
    } else {
      offDuration = workPattern.duration - workDuration;
    }
    let workPatternTemporary = workPattern;
    workPatternTemporary.work_duration = workDuration;
    workPatternTemporary.off_duration = offDuration;
    setWorkPattern({ ...workPatternTemporary });
  };

  const handleChangeOffDuration = (event) => {
    let offDuration = parseInt(event.target.value);
    let workDuration = 0;
    if (!(!isNaN(offDuration) && offDuration <= workPattern.duration)) {
      if (event.target.value === "") {
        workDuration = "";
        offDuration = "";
      } else {
        if (
          workPattern.off_duration === "" &&
          !isNaN(offDuration) &&
          offDuration > workPattern.duration
        ) {
          offDuration = workPattern.duration;
          workDuration = workPattern.duration - offDuration;
        } else {
          offDuration = workPattern.off_duration;
          workDuration = workPattern.duration - offDuration;
        }
      }
    } else {
      workDuration = workPattern.duration - offDuration;
    }
    let workPatternTemporary = workPattern;
    workPatternTemporary.work_duration = workDuration;
    workPatternTemporary.off_duration = offDuration;
    setWorkPattern({ ...workPatternTemporary });
  };

  return (
    <div>
      <Box>
        <Grid container spacing={3} style={{ marginBottom: "1em" }}>
          <Grid item md={6}>
            <Typography
              component={"span"}
              style={{ display: "inline-block", marginTop: "3px" }}
              className={classes.title333}
            >
              <b>Work Pattern</b>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                handleShowModalCreate();
              }}
            >
              Buat Work Pattern
            </Button>
          </Grid>
        </Grid>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            classes={classes}
            numSelected={selected.length}
            dataWorkPatternList={dataWorkPatternList}
            setDataWorkPatternList={setDataWorkPatternList}
            getWorkPatternList={getWorkPatternList}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={dataWorkPatternList.list.length}
            />

            {dataWorkPatternList &&
              dataWorkPatternList.list &&
              dataWorkPatternList.list.length > 0 && (
                <TableBody>
                  {dataWorkPatternList.list.map((item, i) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                  inputProps={{ "aria-label": "select all desserts" }}
                                /> */}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {dataWorkPatternList.options.paging.page *
                              dataWorkPatternList.options.paging.limit +
                              i +
                              1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.longTitle}
                          >
                            {item.list_shift}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDropdownOpen(e, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl && anchorIn === i)}
                            onClose={(e) => handleDropdownClose(e, i)}
                          >
                            <StyledMenuItem
                              onClick={(e) => handleShowModalEdit(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Edit</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={(e) => handleShowModalDelete(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Hapus</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

            {dataWorkPatternList &&
              dataWorkPatternList.list &&
              dataWorkPatternList.list.length <= 0 &&
              !isLoaderEmpty && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title333}
                          style={{
                            marginTop: 16,
                            marginBottom: 16,
                          }}
                        >
                          <b>Work pattern tidak tersedia</b>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.paginationTimeManagement}
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  colSpan={6}
                  count={dataWorkPatternList.options.paging.count}
                  rowsPerPage={dataWorkPatternList.options.paging.limit}
                  page={dataWorkPatternList.options.paging.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  labelRowsPerPage="Baris setiap halaman"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterWorkPattern={masterWorkPattern}
        setMasterWorkPattern={setMasterWorkPattern}
        workPattern={workPattern}
        setWorkPattern={setWorkPattern}
        listError={context.listError}
        createWorkPattern={createWorkPattern}
        optionDurationMonthly={optionDurationMonthly}
        selectedDurationMonthly={selectedDurationMonthly}
        setSelectedDurationMonthly={setSelectedDurationMonthly}
        handleChangeInterval={handleChangeInterval}
        handleChangeWorkDuration={handleChangeWorkDuration}
        handleChangeOffDuration={handleChangeOffDuration}
      />
      <DialogEdit
        classes={classes}
        isModalEdit={context.isModalEdit}
        setModalEdit={context.setModalEdit}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterWorkPattern={masterWorkPattern}
        setMasterWorkPattern={setMasterWorkPattern}
        workPattern={workPattern}
        setWorkPattern={setWorkPattern}
        listError={context.listError}
        handleUpdate={handleUpdate}
        optionDurationMonthly={optionDurationMonthly}
        selectedDurationMonthly={selectedDurationMonthly}
        setSelectedDurationMonthly={setSelectedDurationMonthly}
        handleChangeInterval={handleChangeInterval}
        handleChangeWorkDuration={handleChangeWorkDuration}
        handleChangeOffDuration={handleChangeOffDuration}
      />
      <DialogDelete
        classes={classes}
        isModalDelete={context.isModalDelete}
        setModalDelete={context.setModalDelete}
        workPattern={workPattern}
        setWorkPattern={setWorkPattern}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ViewTabWorkPattern;
