import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Checkbox,
  Button,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import handleError from "../../../../Report/components/global/handleError";
import DialogError from "../../../../../components/DialogError";
import ContextProject from "../../../../Project/context/ContextProject";

// const getTask = localStorage.getItem("task_details");
// const task = JSON.parse(getTask);

const Checklist = ({
  classes,
  todo,
  checkList,
  setTriggerChecklist,
  completed,
}) => {
  const [toggleChecklist, setToggleChecklist] = useState(true);

  // conditional
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  return (
    <Paper className={classes.dataArea}>
      <Box p={2} pt={1} display="flex" alignItems="center">
        <Typography
          variant="subtitile2"
          className={classes.title333}
          style={{ marginRight: 5 }}
        >
          <b>Checklist</b>
        </Typography>
        {toggleChecklist ? (
          <IconButton
            size="small"
            onClick={() => setToggleChecklist(!toggleChecklist)}
          >
            <KeyboardArrowUpIcon style={{ color: "#C8203C" }} />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            onClick={() => setToggleChecklist(!toggleChecklist)}
          >
            <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
          </IconButton>
        )}
      </Box>

      {toggleChecklist && (
        <Box p={2} pt={0}>
          <Box mb={2}>
            {checkList?.length > 0 &&
              checkList?.map((item, i) => (
                <CheckListItem
                  key={i}
                  item={item}
                  todo={todo}
                  classes={classes}
                  onTriggerChecklist={setTriggerChecklist}
                  completed={completed}
                />
              ))}
          </Box>
          <FormCheck
            classes={classes}
            todo={todo}
            onTriggerChecklist={setTriggerChecklist}
            completed={completed}
          />
        </Box>
      )}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default Checklist;

const FormCheck = ({ classes, todo, onTriggerChecklist, completed }) => {
  const { setTriggerTask } = useContext(ContextProject);

  const [input, setInput] = useState("");
  const [addItem, setAddItem] = useState(true);
  //conditional
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const onInputChange = (e) => setInput(e.target.value);

  const onAddItem = () => {
    setLoading(true);

    const data = {
      TodolistChecklist: {
        title: input,
        is_checked: 0,
      },
    };

    AxiosConfig.post(`${URL_API}/todolist/${todo?.id}/checklist`, data)
      .then((res) => {
        // const result = res.data.data;
        //   // console.log("CHCKLST coll", result)

        if (res.status === 200) {
          setInput("");
          onTriggerChecklist(Math.floor(Math.random() * 100));
          setTriggerTask(Math.floor(Math.random() * 100));
          setAddItem(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handeleKeyPress = (e) => {
    if (e.key === "Enter") {
      onAddItem();
    }
  };

  return (
    <Box>
      {addItem ? (
        <Button
          className={classes.button2}
          size="small"
          variant="outlined"
          onClick={() => setAddItem(false)}
          disabled={completed}
        >
          Add Item
        </Button>
      ) : (
        <>
          <TextField
            variant="outlined"
            size="small"
            value={input}
            fullWidth
            onChange={onInputChange}
            onKeyPress={(e) => handeleKeyPress(e)}
          />
          <Box mt={2} display="flex" alignItems="center">
            <Button
              className={classes.button1}
              size="small"
              variant={loading ? "contained" : "outlined"}
              disabled={loading || todo?.id === undefined || completed}
              onClick={onAddItem}
            >
              Add
            </Button>
            <IconButton
              size="small"
              style={{ marginLeft: 8 }}
              onClick={() => setAddItem(true)}
            >
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Box>
        </>
      )}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

const CheckListItem = ({
  classes,
  item,
  todo,
  onTriggerChecklist,
  completed,
}) => {
  const { setTriggerTask } = useContext(ContextProject);

  const [checked, setChecked] = useState(item?.is_checked === 1 ? true : false);
  const [title, setTitle] = useState(item?.title);
  // conditional
  const [editMode, setEditMode] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const onCheckedItem = (ischecked, title) => {
    setChecked(ischecked);

    const data = {
      TodolistChecklist: {
        title,
        is_checked: ischecked ? 1 : 0,
      },
    };

    AxiosConfig.put(
      `${URL_API}/todolist/${todo?.id}/checklist/${item?.id}`,
      data
    )
      .then((res) => {
        // const result = res.data.data;
        //   // console.log("CHCKLST coll", result)

        if (res.status === 200) {
          setEditMode(false);
          onTriggerChecklist(Math.floor(Math.random() * 100));
          setTriggerTask(Math.floor(Math.random() * 100));
        }
      })
      .catch((error) => {
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onCheckedItem(checked, title);
    }
  };

  const onDeleteItem = () => {
    AxiosConfig.delete(`${URL_API}/todolist/${todo?.id}/checklist/${item?.id}`)
      .then((res) => {
        // const result = res.data.data;
        //   // console.log("CHCKLST coll", result)

        if (res.status === 200) {
          onTriggerChecklist(Math.floor(Math.random() * 100));
          setTriggerTask(Math.floor(Math.random() * 100));
        }
      })
      .catch((error) => {
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" minWidth={300}>
          <Checkbox
            className={classes.checkboxRoot}
            disableRipple
            color="default"
            checkedIcon={
              <CheckBoxRoundedIcon
                fontSize="small"
                style={{ color: "#CF364A" }}
              />
            }
            icon={
              <CheckBoxOutlineBlankRoundedIcon
                fontSize="small"
                style={{ color: "#CF364A" }}
              />
            }
            checked={checked}
            onChange={(e) => onCheckedItem(e.target.checked, item?.title)}
            disabled={completed}
          />
          <Box mx={0.5} />
          {!editMode ? (
            <Typography variant="subtitle2" className={classes.title888}>
              {item?.title}
            </Typography>
          ) : (
            <TextField
              value={title}
              variant="standard"
              fullWidth
              size="small"
              onChange={(e) => setTitle(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
            />
          )}
        </Box>

        <Box
          display="flex"
          alignItems="center"
          style={{ "&:hover": { backgroundColor: "#ccc" } }}
        >
          <IconButton
            size="small"
            disabled={todo?.id === undefined || completed}
            onClick={() => setEditMode((v) => !v)}
          >
            {!editMode ? (
              <EditRoundedIcon fontSize="small" />
            ) : (
              <CloseRoundedIcon fontSize="small" />
            )}
          </IconButton>
          <Box mx={0.5} />
          <IconButton
            size="small"
            disabled={todo?.id === undefined || completed}
            onClick={onDeleteItem}
          >
            <DeleteOutlineRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};
