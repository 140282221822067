import React, { Fragment, useContext, useState } from "react";
import {
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import RestoreRoundedIcon from "@material-ui/icons/RestoreRounded";

import moment from "moment";
import clsx from "clsx";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import ContextGlobalDrive from "../../context/ContextDrive";

import DialogInfoRecycleBin from "../components/DialogInfoRecycleBin";
import DialogDeleteFolderRB from "../components/DialogDeleteFolderRB";
import DialogRestoreFolder from "../components/DialogRestoreFolder";

const RecycleBinFolder = ({ item, classes, isFolderSelected }) => {
  const context = useContext(ContextGlobalDrive);
  /* ====================================================
    LOCAL STATE
    ==================================================== */
  const [anchorElFolder, setAnchorElFolder] = useState(null);
  const [openDialogInfoRecycleBin, setOpenDialogInfoRecycleBin] =
    useState(false);
  const [openDialogDeleteFolderRB, setOpenDialogDeleteFolderRB] =
    useState(false);
  const [openDialogRestoreFolder, setOpenDialogRestoreFolder] = useState(false);

  const handleShowAnchorFolder = (event) => {
    setAnchorElFolder(event.currentTarget);
  };

  const handleCloseAnchorFolder = () => {
    setAnchorElFolder(null);
  };

  const handleInfoRecycleBin = () => {
    setOpenDialogInfoRecycleBin(true);
    setAnchorElFolder(null);
  };

  const handleDeleteForever = () => {
    setOpenDialogDeleteFolderRB(true);
    setAnchorElFolder(null);
  };

  const handleRestoreFolder = () => {
    setOpenDialogRestoreFolder(true);
    setAnchorElFolder(null);
  };

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFolder.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFolder, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFolder.slice(1));
    } else if (selectedIndex === context.selectedFolder.length - 1) {
      newSelected = newSelected.concat(context.selectedFolder.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFolder.slice(0, selectedIndex),
        context.selectedFolder.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected Folders", newSelected);

    context.setSelectedFolder(newSelected);
  };

  // Check is Folder selected or not
  const isItemFolderSelected = isFolderSelected(item.id);

  return (
    <Fragment>
      <TableRow style={{ backgroundColor: "#e5fbfb" }}>
        <TableCell padding="checkbox">
          {context.toggleSelect && (
            <Checkbox
              className={classes.checkboxRoot}
              onClick={() => handleChangeCheckbox(item)}
              checked={isItemFolderSelected}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
          )}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            <Avatar
              style={{
                width: 34,
                height: 34,
                backgroundColor: "#6ed8fb",
              }}
            >
              <FolderIcon style={{ fontSize: 22, color: "#3498db" }} />
            </Avatar>

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15 }}
            >
              {item.folder_name}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {moment(item.executed_at).format("DD MMM YYYY, HH:mm")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            Folder
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            -
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {item.folderOwner !== null
              ? `${item.folderOwner.member.first_name} ${item.folderOwner.member.last_name}`
              : "User has been deleted"}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={handleShowAnchorFolder}>
            <MoreVertIcon />
          </IconButton>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElFolder}
            keepMounted
            open={Boolean(anchorElFolder)}
            onClose={handleCloseAnchorFolder}
          >
            <StyledMenuItem
              style={{ width: 200 }}
              onClick={handleInfoRecycleBin}
            >
              <ListItemIcon>
                <VisibilityOutlinedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Info Folder
                  </Typography>
                }
              />
            </StyledMenuItem>
            <StyledMenuItem onClick={handleDeleteForever}>
              <ListItemIcon>
                <DeleteRoundedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Delete Folder
                  </Typography>
                }
              />
            </StyledMenuItem>
            <StyledMenuItem onClick={handleRestoreFolder}>
              <ListItemIcon>
                <RestoreRoundedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Restore Folder
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>

      <DialogInfoRecycleBin
        item={item}
        classes={classes}
        origin="folder"
        openDialogInfoRecycleBin={openDialogInfoRecycleBin}
        setOpenDialogInfoRecycleBin={setOpenDialogInfoRecycleBin}
      />

      <DialogDeleteFolderRB
        item={item}
        classes={classes}
        openDialogDeleteFolderRB={openDialogDeleteFolderRB}
        setOpenDialogDeleteFolderRB={setOpenDialogDeleteFolderRB}
      />

      <DialogRestoreFolder
        item={item}
        classes={classes}
        openDialogRestoreFolder={openDialogRestoreFolder}
        setOpenDialogRestoreFolder={setOpenDialogRestoreFolder}
      />
    </Fragment>
  );
};

export default RecycleBinFolder;
