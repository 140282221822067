import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";

import {
  FormatDecimal,
  FormatThousand,
} from "../../../../utilities/FormatDecimal";

import PictGAP from "../../../../assets/images/Group_2619.png";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";

function TextOverDotLineChartThird({ classes, maDetailState }) {
  let GAPTOSTARTVAL;
  const startVal = Number(maDetailState.start_value);
  const targetVal = Number(maDetailState.target_value);
  const resultVal = Number(
    maDetailState.calculatedValue.resultOriented.result_value
  );
  const actualVal = Number(
    maDetailState.calculatedValue.actualOriented.actual_value
  );

  // Calculate
  if (startVal === 0) {
    // // console.log("SPAM RES");

    if (maDetailState.valueType.code === VALUE_TYPE_PERCENTAGE) {
      const withPercent = targetVal - resultVal;
      GAPTOSTARTVAL = `${FormatDecimal(withPercent)}%`;
    } else {
      GAPTOSTARTVAL = FormatDecimal(targetVal - resultVal);
    }
  }

  if (startVal > 0) {
    // // console.log("SPAM ACT");

    if (maDetailState.valueType.code === VALUE_TYPE_PERCENTAGE) {
      const withPercent = targetVal - actualVal;
      GAPTOSTARTVAL = `${FormatDecimal(withPercent)}%`;
    } else {
      GAPTOSTARTVAL = FormatDecimal(targetVal - actualVal);
    }
  }

  return (
    <List>
      <ListItem>
        <ListItemIcon
          style={{
            marginLeft: 8,
          }}
        >
          <img src={PictGAP} style={{ width: 40, height: 40 }} alt="." />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemtext}
          primary={
            <Typography variant="subtitle2" className={classes.title}>
              <b>{GAPTOSTARTVAL}</b>
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}
            >
              Gap to Target Value
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}

export default TextOverDotLineChartThird;
