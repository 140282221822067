import React, { useState, useContext, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import ContextProject from "../../context/ContextProject";

const DialogDeleteTaskGroup = ({
  classes,
  row,
  openDeleteTaskGroup,
  setOpenDeleteTaskGroup,
}) => {
  const project_id = window.location.pathname.split("/")[2];
  const { setTriggerTask, projectTState } = useContext(ContextProject);

  const defaultTaskGroup = projectTState.defaultTaskGroup;
  const filterGroup = projectTState.groupTaskData.filter(
    (group) => group.id === row.id
  )[0];
  const tasks = filterGroup.todolists;

  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(1);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteTaskGroup = () => {
    setLoading(true);

    AxiosConfig.delete(`${URL_API}/project/${project_id}/task-group/${row.id}`)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setOpenDeleteTaskGroup(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleMoveTaskAndDeleteTaskGroup = () => {
    setLoading(true);

    const data = {
      todos: tasks?.length > 0 ? tasks.map((task) => task.id) : [],
    };

    // // console.log("DATA task", data);
    // // console.log("DFLT task grp", projectTState.defaultTaskGroup);
    // // console.log("projectTState.groupTaskData", projectTState.groupTaskData);

    AxiosConfig.delete(
      `${URL_API}/project/${project_id}/task-group/${row?.id}?option=move`
    )
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setOpenDeleteTaskGroup(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // // console.log("ROW", row);

  return (
    <Dialog
      open={openDeleteTaskGroup}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Delete Tasks Group</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Apakah Anda yakin ingin hapus Tasks Group <b>{row.name}</b> ?
          </Typography>
        </Box>
      </DialogContent>

      <Box width="100%" display="flex" justifyContent="space-between" px={3}>
        {filterGroup.todolists.length > 0 &&
          listOpetionsDelete.map((item) => {
            const isCurrent = item.id === option;

            return (
              <Box
                width={180}
                key={item.id}
                m={0.5}
                p={2}
                borderRadius={5}
                border={`1px solid ${isCurrent ? "#DE6775" : "#555"}`}
                textAlign="center"
                bgcolor={isCurrent ? "#DE6775" : "#fff"}
                color={isCurrent ? "#fff" : "#555"}
                onClick={() => setOption(item.id)}
                className={classes.justHover}
              >
                <Typography variant="caption">{item.name}</Typography>
              </Box>
            );
          })}
      </Box>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenDeleteTaskGroup(false)}
        >
          Cancel
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={
            option === 1
              ? handleDeleteTaskGroup
              : handleMoveTaskAndDeleteTaskGroup
          }
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDeleteTaskGroup;

const listOpetionsDelete = [
  {
    id: 1,
    name: "Hapus Task Group beserta semua Task yang ada didalamnya",
  },
  {
    id: 2,
    name: "Hapus Task Group dan pindahkan Task ke Default Task Group",
  },
];
