import { fade } from "@material-ui/core/styles";

export const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between"
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: '#E5E5E5',
    display: 'flex',
    height: 'auto',
    marginRight: '20px',
    padding: '12px',
    "& .MuiTabs-vertical": {
      width: '210px',
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: theme.palette.background.paper,
      opacity: 1,
    }
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabTitle: {
    textTransform: 'capitalize',
    padding: '12px',
    '&:hover': {
        backgroundColor: theme.palette.background.paper,
        opacity: 1,
    },
  },
  tabPanelContainer: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  button: {
    borderRadius: 5,
    backgroundColor: "#d64253",
    border: 0,
    fontFamily: "'Lato', sans-serif",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  buttonDisabled: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    color: "grey",
    fontWeight: "bold",
  },
  buttonOutlined: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },
  textField: {
    width: '100%',
  },

  titleTextField: {
    fontFamily: "'Lato', sans-serif",
    width: '72',
  },

  titleTextFieldNote: {
    fontFamily: "'Lato', sans-serif",
  },

  titleListChoose: {
    fontFamily: "'Lato', sans-serif",
    cursor: "pointer",
    fontWeight: "bold",
  },
  titleListChooseAktif: {
    fontFamily: "'Lato', sans-serif",
    color: "#d6d3d3",
    cursor: "pointer",
  },

  timeFrameIcon: {
    fontFamily: "'Lato', sans-serif",
    color: "grey", //#4aa9fb
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
    // backgroundColor: '#cfe8fd'
  },
  timeFrameIcontDateRangeText: {
    fontFamily: "'Lato', sans-serif",
    color: "#4aa9fb",
    textTransform: "capitalize",
    backgroundColor: "#edf6ff",
  },

  timeFrameIconInModal: {
    fontFamily: "'Lato', sans-serif",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      color: "#edcfd8", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },
  table: {
    minWidth: '100%',
    backgroundColor: 'white',
    "& th": {
      borderTop: "1px solid rgba(224, 224, 224, 1)",
    }
  },
  roundingMinusICon: {
    fontFamily: "'Lato', sans-serif",
    display: "flex",
    fontSize: "30px",
    alignItems: "center",
    marginLeft: "10px",
    marginRight: "10px",
    height: "100%"
  },
  gridMultiplier: {
    paddingBottom: "15px",
    borderBottom: "2px solid black",
    marginBottom: "20px"
  },
  gridWorkingDays: {
    borderRight: "2px solid black",
  },
  containerWorkDays: {
    marginTop: "25px",
    paddingBottom: "15px",
    borderBottom: "1px solid black",
  },
  gridMiddle: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  gridLeft: {
    borderLeft: "2px solid #d7cbcb",
    paddingLeft: "10px",
    "@media (max-width: 600px)": {
      borderLeft: "0px",
      paddingLeft: "0px",
    },
  },
  shadowSectionLight: {
    borderRadius: 8.5,
    boxShadow: "0px 10px 10px -4px rgba(0,0,0,0.05)",
  },
  /*
      ```````
      SEARCH

      ```````
  */
  search: {
    borderRadius: 12,
    backgroundColor: "#fff",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
    fontSize: 15,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: 300,
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& .MuiInput-underline:after": {
      display: "none"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
    fontFamily: "'Lato', sans-serif",
    fontSize: 15,
    width: 300,
  },
  inputInput: {
    fontSize: 15,
    width: 300,
    padding: "4px 8px",
    transition: theme.transitions.create("width"),
    color: "grey",
  },
  title: {
    fontFamily: "'Lato', sans-serif",
    color: "#282560",
  },
  titleSub: {
    fontFamily: "'Lato', sans-serif",
    color: "#333",
  },
  title333: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    color: "#333",
  },
  title333bold: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "bold",
    color: "#333",
  },
  title555: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    color: "#555",
  },
  title888: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    color: "#888",
  },
  boxTable: {
    width: "100%",
    justifyContent: "inherit"
  },
  listCardItem: {
    paddingBottom: 0,
    paddingTop: 7,
  },
  shadowSection: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  boxTableTr: {
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  paginationTimeManagement: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "normal",
    color: "#333",
    "& .MuiTypography-body2": {
      fontFamily: "'Lato', sans-serif",
      fontWeight: "normal",
      color: "#333",
    },
    "& .MuiTableCell-root": {
      fontFamily: "'Lato', sans-serif",
      fontWeight: "normal",
      color: "#333",
    },
    "& .MuiInputBase-input": {
      fontFamily: "'Lato', sans-serif",
      fontWeight: "normal",
      color: "#333",
    }
  },
  button0: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  girdMiddle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  itemActivity: {
    border: "1px solid black", 
    borderRadius: '4px',
    paddingLeft: "24px", 
    paddingRight: "24px",
    marginBottom: "20px"
  }
});
