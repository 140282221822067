import React from "react";
import {
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { ToLogin } from "../../../../constants/config-redirect-url";
import { navigate } from "hookrouter";

function DialogSuccessChangePassword({
  dialogSuccessChangePassword,
  setDialogSuccessChangePassword,
  classes,
}) {
  return (
    <Dialog
      open={dialogSuccessChangePassword}
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle></DialogTitle>

      <DialogContent style={{ marginTop: 15, textAlign: "center" }}>
        <CheckCircleIcon
          style={{ fontSize: 36, color: "#10de77", marginBottom: 12 }}
        />

        <Typography
          variant="h5"
          className={classes.title}
          style={{ marginBottom: 12 }}>
          <b>Kata Sandi berhasil diubah</b>
        </Typography>

        <Typography
          variant="subtitle2"
          className={classes.textPro1}
          style={{ marginBottom: 15, marginTop: 0 }}>
          Periksa email anda untuk informasi lebih lanjut mengenai perubahan
          kata sandi
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => navigate(ToLogin)}
          variant="contained"
          className={classes.button2}
          fullWidth
          style={{ padding: 5 }}>
          Tutup
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogSuccessChangePassword;
