import React, { useState, Fragment, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";

import HelpIcon from "@material-ui/icons/Help";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseIcon from "@material-ui/icons/Close";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

import DialogError from "../../../../components/DialogError";
import DialogFeedbackDeleteMA from "./DialogFeedbackDeleteMA";

function DialogDeleteMultiple({
  isOpenDialogDeleteMultiple,
  setOpenDialogDeleteMultiple,
  selected,
  classes,
  checkValidDelete,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingMA, setLoadingMA] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [listMA, setListMA] = useState([]);
  const [responseDelete, setResponseDelete] = useState([]);
  const [openDialogFeedbackDelete, setOpenDialogFeedbackDelete] =
    useState(false);

  useEffect(() => {
    if (isOpenDialogDeleteMultiple === true) {
      function getDataMA() {
        setLoadingMA(true);
        return Promise.all(
          selected.map((item) => {
            return axiosConfig.get(`${URL_API}/swd/measured-activity/${item}`);
          })
        )
          .then((response) => {
            // // console.log("ORI Response DelMul", response);
            setListMA(response);

            setLoadingMA(false);
          })
          .catch((error) => {
            setLoadingMA(false);
            // console.log("Error : ", error);
          });
      }

      getDataMA();
    }
  }, [isOpenDialogDeleteMultiple]);

  /* ==========================
   Check Validation MA
    If True (success.length > 0) = Valid
    If False (success.length is empty : []) = Not Valid 
    =========================== */
  const checkValidMA = checkValidDelete.map(
    (item) => item.data.data.success.length > 0
  );

  const handleDeleteMultiple = () => {
    setLoading(true);

    const params = new URLSearchParams();

    selected.length > 0 &&
      selected.map((item, index) => {
        params.append(`MeasuredActivity[${index}][id]`, item);
      });

    axiosConfig
      .delete(`${URL_API}/swd/measured-activity/batch`, {
        data: params,
      })
      .then((response) => {
        // console.log("RESponsE DelMul", response);
        // context.setDeleteSuccess(Math.floor(Math.random() * 1000 + 1));
        // context.setMessages("Measure Activity berhasil dihapus!");
        setResponseDelete(response.data.data);
        setOpenDialogFeedbackDelete(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("Error : ", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorInformationValue(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorInformationValue("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="md"
        open={isOpenDialogDeleteMultiple}
        onClose={() => setOpenDialogDeleteMultiple(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <HelpIcon
            style={{ fontSize: 36, color: "#FFAC9C", marginBottom: 12 }}
          />
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "black" }}
            >
              <b>
                Apakah Anda yakin ingin menghapus {selected.length} Measured
                Activity ini?
                <br />
                <i style={{ fontSize: 16 }}>
                  {loadingMA ? (
                    <CircularProgress
                      style={{ color: "#d1354a", fontSize: 14 }}
                    />
                  ) : (
                    <List>
                      {listMA.length > 0 &&
                        listMA.map((item, i) => (
                          <ListItem key={i}>
                            <ListItemAvatar>
                              {checkValidMA[i] ? (
                                <CheckSharpIcon style={{ color: "#1EBC61" }} />
                              ) : (
                                <CloseIcon style={{ color: "#d1354a" }} />
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <span
                                  style={{
                                    color: "#333",
                                    fontStyle: "normal",
                                  }}
                                >
                                  <b>{item.data.data.name}</b>{" "}
                                  <span
                                    className={
                                      checkValidMA[i]
                                        ? classes.validGoal
                                        : classes.notValidGoal
                                    }
                                  >
                                    {checkValidMA[i] ? "valid" : "tidak valid"}
                                  </span>
                                </span>
                              }
                              secondary={
                                <span
                                  style={{
                                    fontStyle: "normal",
                                  }}
                                >
                                  {item.data.data.member &&
                                    item.data.data.member.first_name}{" "}
                                  {item.data.data.member &&
                                    item.data.data.member.last_name}
                                </span>
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  )}
                </i>
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            marginLeft: 15,
            justifyContent: "center",
            marginBottom: 15,
          }}
        >
          <Button
            onClick={handleDeleteMultiple}
            variant="contained"
            className={classes.buttonModalDelete}
            style={{ paddingRight: 25, paddingLeft: 25 }}
          >
            {!loading ? (
              "Ya"
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogFeedbackDeleteMA
        classes={classes}
        openDialogFeedbackDelete={openDialogFeedbackDelete}
        setOpenDialogFeedbackDelete={setOpenDialogFeedbackDelete}
        listMA={listMA}
        responseDelete={responseDelete}
      />
    </Fragment>
  );
}

export default DialogDeleteMultiple;
