import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";

const CardSevenEvidence = ({
  classes,
  i,
  evidence,
  handleEvidenceRequired,
}) => {
  return (
    <>
      <Grid container justify="space-between" key={i}>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              // color="primary"
              size="small"
              checked={evidence[i]}
              onChange={(e) => handleEvidenceRequired(e, i)}
            />
          }
          label={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}>
              Wajib lampirkan bukti
            </Typography>
          }
          labelPlacement="end"
        />
      </Grid>
      <br />
      <br />
    </>
  );
};

export default CardSevenEvidence;
