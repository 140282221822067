import React from "react";
import { Radio, Typography } from "@material-ui/core";

const ListItemPeriod = ({ classes, item, period, onPeriod }) => {
  const isCurrent = item?.name === period?.name;

  return (
    <label style={{ margin: 0, padding: 0, height: 37.5 }}>
      <Typography
        variant="subtitle2"
        className={classes.fieldPreset}
        style={{
          backgroundColor: isCurrent && "#FDDBD8",
          color: isCurrent ? "#CF364A" : "#333",
          fontWeight: "normal",
        }}
      >
        {item?.name}
      </Typography>
      <Radio
        onChange={() => onPeriod(item)}
        checked={item?.name === period?.name}
        defaultValue={item?.selected === true && item}
        value={item?.name}
        style={{
          visibility: "hidden",
          height: 0,
          width: 0,
          opacity: 0,
        }}
      />
    </label>
  );
};

export default ListItemPeriod;
