import React, { useState } from "react";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import DeleteOutline from "@material-ui/icons/DeleteOutlineRounded";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutlineRounded";
import CheckCircle from "@material-ui/icons/CheckCircleRounded";

import DialogDeleteEventNotif from "../../Components/DialogDeleteEventNotif";

const ListitemNotifGroup = ({ classes, item, setTriggerNotif }) => {
  const [openDeleteEventNotif, setOpenDeleteEventNotif] = useState(false);

  const apps =
    item.channel.filter((el) => {
      return el.channel.name === "App";
    }).length === 1
      ? true
      : false;

  const emails =
    item.channel.filter((el) => {
      return el.channel.name === "Email";
    }).length === 1
      ? true
      : false;

  const alls =
    item.channel.filter((el) => {
      return el.channel.name === "All";
    }).length === 1
      ? true
      : false;

  const handleDeleteEvent = () => {
    setOpenDeleteEventNotif(true);
  };

  const styIcon = {
    fontSize: 20,
    color: "#00ACE6",
  };

  return (
    <TableRow>
      <TableCell>{item.eventType?.name}</TableCell>
      <TableCell>
        {item.recipient.map((el) => el.recipientType?.name).join(", ")}
      </TableCell>
      <TableCell>
        {emails ? (
          <CheckCircle style={styIcon} />
        ) : (
          <CheckCircleOutline style={styIcon} />
        )}
      </TableCell>
      <TableCell>
        {apps ? (
          <CheckCircle style={styIcon} />
        ) : (
          <CheckCircleOutline style={styIcon} />
        )}
      </TableCell>
      <TableCell>
        {alls ? (
          <CheckCircle style={styIcon} />
        ) : (
          <CheckCircleOutline style={styIcon} />
        )}
      </TableCell>
      <TableCell>
        <IconButton size="small" onClick={handleDeleteEvent}>
          <DeleteOutline style={{ fontSize: 20 }} />
        </IconButton>
      </TableCell>

      <DialogDeleteEventNotif
        classes={classes}
        item={item}
        openDeleteEventNotif={openDeleteEventNotif}
        setOpenDeleteEventNotif={setOpenDeleteEventNotif}
        setTriggerNotif={setTriggerNotif}
      />
    </TableRow>
  );
};

export default ListitemNotifGroup;
