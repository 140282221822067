import React, { useState } from "react";
import { TableRow, Box, Typography, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiTableRow-root": {
      backgroundColor: "#F9F9F9",
      borderBottom: "none",
    },
    "&.MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  title555: { color: "#555" },
}));

const RowPersonals = ({ row, origin }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.root}>
        <Box mt={1.5} mb={1} display="flex" justifyContent="space-between">
          {origin === "personal" && (
            <Typography
              variant="subtitle2"
              className={classes.title555}
              className={classes.root}
            >
              {row.name}
            </Typography>
          )}

          {origin === "mypersonal" && (
            <Typography
              variant="subtitle2"
              className={classes.title555}
              className={classes.root}
            >
              Others
            </Typography>
          )}

          <Typography
            variant="subtitle2"
            className={classes.title555}
            className={classes.root}
          >
            Owner: {`${row?.member.first_name} ${row?.member.last_name}`}
          </Typography>
        </Box>
      </TableCell>
      <TableCell className={classes.root}></TableCell>
      <TableCell className={classes.root}></TableCell>
      <TableCell className={classes.root}></TableCell>
      <TableCell className={classes.root}></TableCell>
      <TableCell className={classes.root}></TableCell>
      <TableCell className={classes.root} align="right"></TableCell>
    </TableRow>
  );
};

export default RowPersonals;
