import React, { useEffect, useState, Fragment } from "react";
import { IconButton } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import axiosConfig from "../constants/config-axios";

import { URL_API } from "../constants/config-api";
const userToken = localStorage.getItem("userToken");

const ImageSmallUser = (props) => {
  const { item, index } = props;

  const [isImage200, setImage200] = useState(false);

  useEffect(() => {
    if (userToken !== undefined) {
      axiosConfig
        .get(`${URL_API}/${item}&token=${userToken}`)
        // .get(`${URL_API}/account-management/member/${item}/photo:check`)
        .then((response) => {
          // // console.log("Response Original Image: ", response);
          if (response.status === 200) {
            if (response.data !== "") {
              setImage200(true);
            }
          }
        })
        .catch((error) => {
          // console.log("Error : ", error.response);
          setImage200(false);
        });
    }
  }, []);

  return (
    <Fragment key={index}>
      {isImage200 === true ? (
        <IconButton disabled style={{ padding: 2 }}>
          <img
            src={`${URL_API}/${item}&token=${userToken}&mode=thumbnail`}
            width={30}
            height={30}
            style={{ borderRadius: 20 }}
            alt="-"
          />
        </IconButton>
      ) : (
        <IconButton disabled style={{ padding: 0, marginRight: 0 }}>
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      )}
    </Fragment>
  );
};

export default ImageSmallUser;
