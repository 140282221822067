import React, { useEffect, useState, useCallback, useRef } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Button,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Radio,
} from "@material-ui/core";

import axios from "axios";
import { navigate } from "hookrouter";

import { useDropzone } from "react-dropzone";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import DialogError from "../../../../components/DialogError";
import Snackbeer from "../../../../components/Snackbeer";
import LightTooltip from "../../../../components/LightTooltip";

import { ToEmptyStateGeneral } from "../../../../constants/config-redirect-url";
import { URL_API } from "../../../../constants/config-api";
import { extractImageFileExtensionFromBase64 } from "../../../../utilities/ReusableUtils";

import AvatarDummy from "../../../../assets/images/Avatar_dummy.png";

import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const IdentitasSubInfoDasar = (props) => {
  const { classes, setFotoQueryEnabled, setFotoQuery } = props;

  /*
        ````````
        USE REFF

        ````````
    */

  let textInputReff = useRef(null);

  /* 
        ````````````````````
        COMPONENT DID MOUNT
        
        ````````````````````
    */

  const userToken = localStorage.getItem("userToken");
  const employeeId = localStorage.getItem("employee_id");

  const [updatedAt, setUpdatedAt] = useState("");

  useEffect(() => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userToken,
    };

    axios.defaults.headers.common = header;

    axios
      .get(URL_API + `/human-resource/employee/batch/${employeeId}/patch`)
      .then(function (response) {
        // console.log("Response Original : ", response);

        if (response.status == 200) {
          setUpdatedAt(response.data.data.fields.Employee.updated_at);
        }
      })
      .catch(function (error) {
        // console.log("Error : ", error.response);
      });

    axios
      .get(URL_API + `/human-resource/employee/batch/${employeeId}`)
      .then(function (response) {
        // console.log("Response Original DETAIL EMPLOYEE : ", response);

        if (response.status === 200) {
          setNamaDepan(response.data.data.member.first_name);
          setNamaBelakang(response.data.data.member.last_name);
          setNamaPanggilan(response.data.data.member.nickname);

          if (
            response.data.data.employee_number !== null &&
            response.data.data.employee_number !== "null"
          ) {
            setNip(response.data.data.employee_number);
          }

          if (response.data.data.member !== null) {
            if (
              response.data.data.member.mobile_number_personal !== null &&
              response.data.data.member.mobile_number_personal !== "null"
            ) {
              setNoHp(response.data.data.member.mobile_number_personal);
            }
            setEmail(response.data.data.member.email);

            if (response.data.data.member.date_of_birth !== null) {
              setTanggalLahir(response.data.data.member.date_of_birth);
            }

            setSelectedValueJenisKelamin(response.data.data.member.sex_id);

            // if (response.data.data.member.religion !== null) {
            //   setReligion({
            //     religion: response.data.data.member.religion,
            //   });
            // }

            if (response.data.data.member.status !== null) {
              setStatus(response.data.data.member.status.name);
            }
          }
          setTempatLahir(response.data.data.member.place_of_birth);
          setEmail(response.data.data.member.email);
        }
      })
      .catch(function (error) {
        // console.log("Error : ", error.response);
      });
  }, []);

  /*
        ``````````````````````````````
        IDENTITAS - 'INFORMASI DASAR'

        ``````````````````````````````
    */

  const [nip, setNip] = useState("");
  const [namaDepan, setNamaDepan] = useState("");
  const [namaBelakang, setNamaBelakang] = useState("");
  const [namaPanggilan, setNamaPanggilan] = useState("");
  const [noHp, setNoHp] = useState("");
  const [email, setEmail] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [status, setStatus] = useState("");

  const [tanggalLahir, setTanggalLahir] = useState(null);
  // const [ agama, setAgama ] = useState('');

  const handleChangeNIP = (e) => setNip(e.target.value);
  const handleChangeNamaDepan = (e) => setNamaDepan(e.target.value);
  const handleChangeNamaBelakang = (e) => setNamaBelakang(e.target.value);
  const handleChangeNamaPanggilan = (e) => setNamaPanggilan(e.target.value);
  const handleChangeNoHp = (e) => setNoHp(e.target.value);
  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangeTempatLahir = (e) => setTempatLahir(e.target.value);
  const handleChangeTanggalLahir = (e) => setTanggalLahir(e.target.value);

  //*JENIS KELAMIN
  const [selectedValueJenisKelamin, setSelectedValueJenisKelamin] =
    useState("1");

  function handleChangeRadioButton(event) {
    setSelectedValueJenisKelamin(event.target.value);

    if (event.target.value == "1") {
      console.log(event.target.value);

      // callListMasterRole(event.target.value)
    } else {
      console.log(event.target.value);
    }
  }

  const [selectedValue, setSelectedValue] = useState("1");

  /* 
       ````````````````````
       Feature Upload Foto 
       
       ````````````````````
   */
  const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState("");
  const [imgSrcExt, setImgSrcExt] = useState();

  const [contentFile, setContentFile] = useState(undefined);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setContentFile(acceptedFiles);

    //*
    const reader = new FileReader();

    reader.onabort = () =>
      // console.log("file reading was aborted");
      (reader.onerror = () =>
        // console.log("file reading has failed");
        (reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          // // console.log("Binary String : ",binaryStr);

          setImageBinaryPreviewUrl(binaryStr);
          setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));
        }));

    // acceptedFiles.forEach(file => reader.readAsBinaryString(file))
    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  /* 
        ````````````````````
        Feature Upload Foto 
        
        ````````````````````
    */

  const [isTextFieldDisabled, setTextFieldDisabled] = useState(false);

  const handleEdit = () => {
    setTextFieldDisabled(true);
  };

  useEffect(() => {
    if (isTextFieldDisabled === true) {
      //*Focus in Text Field
      setTimeout(() => {
        if (textInputReff !== null && textInputReff !== undefined) {
          textInputReff.current.focus();
        }
      }, 500);
    }
  }, [isTextFieldDisabled]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  /*
        ```````````````
        HANDLE SNACKBAR

        ```````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);

  /*
       ```````````````````````````````````
       SELECETED DATE UDPATE TANGGAL LAHIR

       ```````````````````````````````````
   */

  const [locale, setLocale] = useState("id");
  const [selectedDate, setSelectedDate] = useState("");

  /*
        ``````````````````````
        Dropdown

        ``````````````````````

    */

  // const [religions, setReligion] = useState({
  //   religion: "",
  // });

  // const handleChangeDropdown = (name) => (event) => {
  //   // // console.log("Name drop down : ", event.target.value);

  //   setReligion({ ...religions, [name]: event.target.value });
  // };

  /*
        ```````````````````````
        HANDLE SIMPAN PERUBAHAN

        ````````````````````````
    */
  const [loader, setLoader] = useState(false);

  const handleSimpanPerubahan = () => {
    setLoader(true);
    // setFotoQueryEnabled(false)
    // console.log("contentFile : ", contentFile);

    const userToken = localStorage.getItem("userToken");
    // const file = new Blob([contentFile[0]], { type: 'image/png' }); //*Work
    let file = undefined;

    if (contentFile !== undefined) {
      file = new Blob([contentFile[0]], { type: "image/png" }); //*Work
    }
    // // console.log("File : ", file);

    let data = new FormData();

    data.append("_method", "patch");
    data.append("Employee[employee_number]", nip !== "" ? nip : "-");
    data.append("Employee[first_name]", namaDepan !== "" ? namaDepan : "-");
    data.append(
      "Employee[last_name]",
      namaBelakang !== "" ? namaBelakang : "-"
    );
    data.append(
      "Employee[nickname]",
      namaPanggilan !== "" ? namaPanggilan : "-"
    );
    data.append("Employee[mobile_number_personal]", noHp !== "" ? noHp : "-");
    data.append("Employee[email]", email !== "" ? email : "-");
    // data.append('Employee[date_of_birth]', tanggalLahir !== null ? tanggalLahir : new moment());
    data.append(
      "Employee[date_of_birth]",
      moment(tanggalLahir).format("YYYY-MM-DD")
    );

    // if (religions.religion !== null && religions.religion !== "") {
    //   data.append("Employee[religion_id]", religions.religion.id);
    // }
    data.append("Employee[sex_id]", selectedValueJenisKelamin);
    // data.append('Employee[date_of_birth]', moment(tanggalLahir).format('YYYY-MM-DD'));

    // if(contentFile !== undefined){
    data.append("Employee[photo_url]", file);
    // };

    data.append("Employee[updated_at]", updatedAt);

    if (file == undefined) {
      delete data.delete("Employee[photo_url]");
    }
    if (tanggalLahir == null) {
      delete data.delete("Employee[date_of_birth]");
    }

    // console.log("file ", file);

    // Display the Key
    for (var key of data.keys()) {
      // console.log("Data Keys : ", key);
    }

    // Display the values
    for (var value of data.values()) {
      // console.log("Data value : ", value);
    }

    //***************************************************** */

    // let data = {

    //     Employee: {

    //         employee_number: nip !== '' ? nip : '',
    //         first_name : namaDepan !== '' ? namaDepan : '',
    //         last_name: namaBelakang !== '' ? namaBelakang : '',
    //         nickname: namaPanggilan !== '' ? namaPanggilan : '',
    //         mobile_number_personal: noHp !== '' ? noHp : '',
    //         date_of_birth: tanggalLahir,
    //         religion_id: religions.religion.id,
    //         sex_id: selectedValueJenisKelamin,
    //         date_of_birth: moment(tanggalLahir).format('YYYY-MM-DD'),
    //         photo_url: contentFile !== undefined ? contentFile[0] : '-',
    //         updated_at: updatedAt

    //     },
    //     _method: 'patch'
    // };

    // if(contentFile == undefined ){
    //     delete data.Employee.photo_url
    // };

    // // console.log("Simpan Perubahan ... : ", data);

    if (userToken !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        // 'Content-Type' : "",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + `/human-resource/employee/${employeeId}`, data)
        .then(function (response) {
          // console.log("Response Original after UPDATE: ", response);

          if (response.status == 200) {
            setModalResponse200(true);

            if (response.data.data !== undefined) {
              setFotoQueryEnabled(true);
              setLoader(false);
              setTextFieldDisabled(false);

              setTimeout(() => {
                navigate(ToEmptyStateGeneral);
              }, 3000);
              // setFotoQuery(response.data.data.member.self.rel.photo_url);
            }
          }
        })
        .catch(function (error) {
          if (error.response !== undefined) {
            if (error.response.status === 409) {
              setErrorStatus(409);
              setTextErrorInformation(error.response.data.info.message);
              setOpenDialogError(true);
            }

            if (error.response.status == 400) {
              setErrorStatus(400);
              if (error.response.data !== null) {
                if (error.response.data.info !== null) {
                  if (error.response.data.info.errors !== null) {
                    if (
                      error.response.data.info.errors.hasOwnProperty(
                        "photo_url"
                      ) == true
                    ) {
                      setTextErrorInformation(
                        error.response.data.info.errors.photo_url
                      );
                      setOpenDialogError(true);
                    }
                  }
                }
              }
            }
          } else {
            setTextErrorInformation("Whoops, something went wrong !");
            setOpenDialogError(true);
          }

          setTextFieldDisabled(false);
          setLoader(false);
          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  // GET SUPER ADMIN
  const getSA = localStorage.getItem("status_user_login");
  const superAdmin = JSON.parse(getSA);
  let isSuperAdmin = false;

  superAdmin.userRole &&
    superAdmin.userRole.forEach((item) => {
      if (item.name === "superadmin") {
        isSuperAdmin = true;
      }
    });

  return (
    <Paper
      elevation={1}
      className={classes.paperInfoDasar}
      square={true}
      style={{ marginRight: 16 }}
    >
      <br />

      <Grid container>
        <Grid item sm={10}>
          <Typography variant="subtitle1" className={classes.titleHeader}>
            <b>Informasi Dasar</b>
          </Typography>
        </Grid>
        <Grid item sm={2}>
          {isTextFieldDisabled === false && (
            <LightTooltip
              arrow
              title={
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Edit</b>
                </Typography>
              }
              placement="right"
            >
              <IconButton onClick={handleEdit} disabled={!isSuperAdmin}>
                <i className="material-icons">edit</i>
              </IconButton>
            </LightTooltip>
          )}
        </Grid>
      </Grid>

      <br />
      <List className={classes.list}>
        <ListItem style={{ textAlign: "left" }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>
                  Foto
                  {/* <span style={{color: '#cc0707'}}>*</span> */}
                </b>
              </Typography>
            }
          />

          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {imageBinaryPreviewUrl ? (
              <Avatar
                alt="You"
                src={imageBinaryPreviewUrl}
                className={classes.realAvatar}
              />
            ) : (
              // <Avatar alt="You" src={ URL_API + '/' +imageBinaryPreviewUrl+ "&token=" + userToken} className={classes.realAvatar}  />

              <Avatar
                alt="You"
                src={AvatarDummy}
                className={classes.bigAvatar}
              />
            )}
          </div>
        </ListItem>
        <br />

        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>NIP</b>
              </Typography>
            }
          />

          <TextField
            id="outlined-bare"
            onChange={handleChangeNIP}
            value={nip !== "" ? nip : ""}
            placeholder={nip !== "" ? nip : "-"}
            className={classes.textField}
            inputProps={{ className: classes.titleText }}
            disabled={isTextFieldDisabled !== true ? true : false}
            inputRef={textInputReff}

            // onChange= {handleChangeNIP}
            // placeholder={'NIP'}
            // variant="outlined"
          />
        </ListItem>

        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>Nama Depan</b>
              </Typography>
            }
          />
          <TextField
            id="outlined-bare"
            onChange={handleChangeNamaDepan}
            value={namaDepan}
            placeholder={namaDepan}
            className={classes.textField}
            inputProps={{ className: classes.titleText }}
            disabled={isTextFieldDisabled !== true ? true : false}
          />
        </ListItem>

        <ListItem alignItems="center">
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>Nama Belakang</b>
              </Typography>
            }
          />
          <TextField
            id="outlined-bare"
            onChange={handleChangeNamaBelakang}
            value={namaBelakang}
            placeholder={namaBelakang}
            className={classes.textField}
            inputProps={{ className: classes.titleText }}
            disabled={isTextFieldDisabled !== true ? true : false}
          />
        </ListItem>

        <ListItem style={{ textAlign: "left" }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>Nama Panggilan</b>
              </Typography>
            }
          />
          <TextField
            id="outlined-bare"
            onChange={handleChangeNamaPanggilan}
            value={
              namaPanggilan !== null && namaPanggilan !== "null"
                ? namaPanggilan
                : "-"
            }
            placeholder={namaPanggilan}
            className={classes.textField}
            inputProps={{ className: classes.titleText }}
            disabled={isTextFieldDisabled !== true ? true : false}
          />
        </ListItem>

        <ListItem style={{ textAlign: "left" }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>No Handphone</b>
              </Typography>
            }
          />
          <TextField
            id="outlined-bare"
            onChange={handleChangeNoHp}
            value={noHp}
            placeholder={noHp}
            className={classes.textField}
            inputProps={{ className: classes.titleText }}
            disabled={isTextFieldDisabled !== true ? true : false}
          />
        </ListItem>

        <ListItem style={{ textAlign: "left" }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>Alamat Email</b>
              </Typography>
            }
          />
          {/* <TextField 
                        id="outlined-bare"
                        // onChange={handleChangeNoHp}
                        value={noHp}
                        placeholder={noHp}
                        className={classes.textField}
                        inputProps={{className: classes.titleText}} 
                        disabled={isTextFieldDisabled !== true ? true : false}
                        
                    /> */}

          <TextField
            id="outlined-bare"
            className={classes.textField}
            inputProps={{ className: classes.titleText }}
            onChange={handleChangeEmail}
            value={email}
            placeholder={email}
            disabled={status === "Aktif"}

            // variant="outlined"
            // error={infoError == true && email == '' ? true : false}
            // helperText={infoError == true && email == '' ? "Wajib di isi" : ' '}
          />
        </ListItem>

        <ListItem style={{ textAlign: "left" }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>Tanggal Lahir</b>
              </Typography>
            }
          />

          {isTextFieldDisabled !== true ? (
            <TextField
              id="outlined-bare"
              value={
                tanggalLahir !== null
                  ? moment(tanggalLahir).format("DD MMMM YYYY")
                  : null
              }
              // value={tanggalLahir !== null ? moment(tanggalLahir).format('DD MMMM YYYY') : '-'}

              placeholder={moment(tanggalLahir).format("DD MMMM YYYY")}
              className={classes.textField}
              inputProps={{ className: classes.titleText }}
              disabled
            />
          ) : (
            <TextField
              id="date"
              // onChange={handleChangeTanggalLahir}
              onChange={(e) => setTanggalLahir(e.target.value)}
              value={tanggalLahir}
              defaultValue="2019-05-24"
              type="date"
              // label="Birthday"
              variant="outlined"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </ListItem>

        {/* <ListItem style={{ textAlign: "left" }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1" className={classes.titleForm}>
                <b>Agama</b>
              </Typography>
            }
          />

          {isTextFieldDisabled !== true ? (
            <TextField
              id="outlined-bare"
              value={
                religions.religion !== null && religions.religion !== ""
                  ? religions.religion.name
                  : "-"
              }
              placeholder={
                religions.religion !== null && religions.religion !== ""
                  ? religions.religion.name
                  : "-"
              }
              className={classes.textField}
              inputProps={{ className: classes.titleText }}
              disabled
            />
          ) : (
            <TextField
              id="outlined-select-currency"
              select
              label="Pilih Agama : "
              className={classes.textField}
              value={religions.religion}
              onChange={handleChangeDropdown("religion")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
              // error={infoError == true && religions.religion == '' ? true : false}
              // helperText={infoError == true && religions.religion == '' ? "Wajib di isi" : ' '}
            >
              {religionsData.map((option) => (
                <MenuItem key={option.value} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </ListItem> */}

        {isTextFieldDisabled !== true ? (
          <ListItem style={{ textAlign: "left" }}>
            <ListItemText
              primary={
                <Typography variant="subtitle1" className={classes.titleForm}>
                  <b>Jenis Kelamin</b>
                </Typography>
              }
            />
            <TextField
              id="outlined-bare"
              value={selectedValueJenisKelamin == 1 ? "Laki-laki" : "Wanita"}
              placeholder={
                selectedValueJenisKelamin == 1 ? "Laki-laki" : "Wanita"
              }
              className={classes.textField}
              inputProps={{ className: classes.titleText }}
              disabled
            />
          </ListItem>
        ) : (
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="subtitle1" className={classes.titleForm}>
                  <b>
                    Jenis Kelamin
                    <span style={{ color: "#cc0707" }}>*</span>
                  </b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Radio
                    checked={selectedValueJenisKelamin === "1"}
                    onChange={handleChangeRadioButton}
                    value="1"
                    name="Laki-laki"
                  />
                  <span style={{ fontFamily: "Roboto" }}>Laki- laki</span>
                  <Radio
                    checked={selectedValueJenisKelamin === "2"}
                    onChange={handleChangeRadioButton}
                    value="2"
                    name="Perempuan"
                    // disabled= {isLockedStatusState == 1 ? true : false}
                  />
                  <span style={{ fontFamily: "Roboto" }}>Perempuan</span>
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>

      <Grid container>
        <Grid item sm={8}></Grid>
        <Grid item sm={2} style={{ textAlign: "right" }}>
          {isTextFieldDisabled == true && (
            <Button
              variant="outlined"
              className={classes.buttonOutlined}
              style={{ marginBottom: 24, marginTop: 24 }}
              onClick={() => setTextFieldDisabled(false)}
            >
              Batal
            </Button>
          )}
        </Grid>
        <Grid item sm={2} style={{ textAlign: "right" }}>
          {isTextFieldDisabled == true && (
            <Button
              variant="contained"
              className={classes.button}
              style={{ marginRight: 32, marginBottom: 24, marginTop: 24 }}
              onClick={handleSimpanPerubahan}
            >
              {loader !== true ? (
                "Simpan"
              ) : (
                <CircularProgress size={16} style={{ color: "white" }} />
              )}
            </Button>
          )}
        </Grid>
      </Grid>

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Perubahan data berhasil di simpan !"
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default IdentitasSubInfoDasar;

// const religionsData = [
//   {
//     id: "de5e0c55-9a31-4d3d-929d-e53421c62477",
//     value: "Buddha",
//     name: "Buddha",
//   },
//   {
//     id: "ace04e30-8065-408a-9c31-4b04685549b0",
//     value: "Katolik",
//     name: "Katolik",
//   },
//   {
//     id: "93c97c1b-dc96-4397-b0cf-7b339a82c0d4",
//     value: "Kristen protestan",
//     name: "Kristen protestan",
//   },
//   {
//     id: "41b5d847-a6e9-4b0e-a1d0-12b40c51239a",
//     value: "Hindu",
//     name: "Hindu",
//   },
//   {
//     id: "007fba3e-35ce-485f-bc22-feda9125c813",
//     value: "Islam",
//     name: "Islam",
//   },
// ];
