import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, ListItemText, MenuItem, Switch, TableBody, TableCell, TableFooter, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Autocomplete } from "@material-ui/lab";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";
import { Table, Tooltip } from "react-bootstrap";
import ContextTimeOffSetting from "../../Context/ContextTimeOffSetting";
import { StyledMenu, StyledMenuItem } from "../../../../../components/StyledMenuDropdown";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EnhancedTableHeadUserRegistered from "./EnhancedTableHeadUserRegistered";
import EnhancedTableHeadUserUnregistered from "./EnhancedTableHeadUserUnregistered";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from '@material-ui/icons/Assignment';
import TableFilterUserRegistered from "./TableFilterUserRegistered";
import TableFilterUserUnregistered from "./TableFilterUserUnregistered";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogDetail = (props) => {
  const {
    classes,
    isModalDetail,
    setModalDetail,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    masterCategory,
    dataCategory,
    dataRegisteredUser,
    setDataRegisteredUser,
    refreshListRegisteredUser,
    dataUnregisteredUser,
    setDataUnregisteredUser,
    refreshListUnregisteredUser,
    assignUser,
    unsignUser,
    setLoader
  } = props;

  const context = useContext(ContextTimeOffSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );
  const [anchorElRegistered, setAnchorElRegistered] = useState();
  const [anchorInRegistered, setAnchorInRegistered] = useState();
  // Function Show Dropdown List
  function handleDropdownOpenRegistered(event, index) {
    setAnchorElRegistered(event.currentTarget);
    setAnchorInRegistered(index);
  }

  // Function Close Dropdown List
  function handleDropdownCloseRegistered() {
    setAnchorElRegistered(null);
    setAnchorInRegistered(null);
  }

  const [anchorElUnregistered, setAnchorElUnregistered] = useState();
  const [anchorInUnregistered, setAnchorInUnregistered] = useState();
  // Function Show Dropdown List
  function handleDropdownOpenUnregistered(event, index) {
    setAnchorElUnregistered(event.currentTarget);
    setAnchorInUnregistered(index);
  }

  // Function Close Dropdown List
  function handleDropdownCloseUnregistered() {
    setAnchorElUnregistered(null);
    setAnchorInUnregistered(null);
  }
  
  const handleChangePageUnregisteredUser = (event, newPage) => {
    refreshListUnregisteredUser(null, dataUnregisteredUser.options.paging.limit * newPage, newPage, null);
  }

  const handleChangeRowsPerPageUnregisteredUser = (event) => {
    refreshListUnregisteredUser(null, 0, 0, parseInt(event.target.value));
  };
  
  const handleChangePageRegisteredUser = (event, newPage) => {
    refreshListRegisteredUser(null, dataRegisteredUser.options.paging.limit * newPage, newPage, null);
  }

  const handleChangeRowsPerPageRegisteredUser = (event) => {
    refreshListRegisteredUser(null, 0, 0, parseInt(event.target.value));
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalDetail}
        onClose={() => setModalDetail(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        fullWidth
        maxWidth='lg'
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
          disableTypography={true}
        >
          <Typography variant="h4">User Time Off</Typography>
        </DialogTitle>
        <DialogContent>
          <Box p={1} my={1}>
            <Box mb={2.5}>
              <Grid container>
                <Grid item md={4}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" component={'span'} className={classes.title333}>
                        <b>Kategori Time Off</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" component={'span'} className={classes.title333}>
                          <b>: { }
                            {
                              dataCategory.name
                            }
                          </b>
                        </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2.5}>
              <Grid container>
                <Grid item md={4} xs={12}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title333}>
                    <b>List user terdaftar</b>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title333} style={{ textAlign: "right" }}>
                    {
                      dataRegisteredUser.users.length > 0 &&
                        (
                          <div style={{display: "flex", justifyContent: "right"}}>
                            <b style={{marginRight: "5px"}}> {dataRegisteredUser.users.length}</b> Ditandai
                            <Tooltip title="Remove User">
                              <IconButton
                                style={{paddingTop: "0px", paddingBottom: "0px", top: "-2px"}}
                                aria-label="delete"
                                onClick={() => {
                                  unsignUser(null);
                                }}  
                              >
                                <DeleteIcon style={{ color: "#d64253" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2.5}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <TableFilterUserRegistered
                    classes={classes}
                    masterCategory={masterCategory}
                    dataRegisteredUser={dataRegisteredUser}
                    setDataRegisteredUser={setDataRegisteredUser}
                    refreshListRegisteredUser={refreshListRegisteredUser}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={5}>
              <div style={{ textAlign: "left", marginBottom: "25px", overflowY: "unset" }}>
                <Table
                  className={classes.table}
                  aria-label="sticky table"
                  // stickyHeader
                >
                  <EnhancedTableHeadUserRegistered
                    classes={classes}
                    dataCategory={dataCategory}
                    dataRegisteredUser={dataRegisteredUser}
                    setDataRegisteredUser={setDataRegisteredUser}
                    setLoader={setLoader}
                  />

                  {
                    dataRegisteredUser && dataRegisteredUser.list && dataRegisteredUser.list.length > 0 &&  
                      ( 
                        <TableBody>
                          {
                            dataRegisteredUser.list.map((item, i) => {
                              return (
                                <TableRow  key={item.id}>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={dataRegisteredUser.users.includes(item.id)}
                                      inputProps={{ "aria-label": "select all desserts" }}
                                      onChange={() => {
                                        let dataTemp = dataRegisteredUser;
                                        if (dataTemp.users.includes(item.id)) {
                                          const index = dataTemp.users.indexOf(item.id);
                                          if (index > -1) {
                                            dataTemp.users.splice(index, 1); 
                                          }
                                        } else {
                                          dataTemp.users.push(item.id);
                                        }
                                        setDataRegisteredUser({...dataTemp});
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      { dataRegisteredUser.options.paging.page + i + 1}
                                    </Typography>
                                  </TableCell>
            
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item && item.member ? item.member.first_name + " " + item.member.last_name : "-"}
                                    </Typography>
                                  </TableCell>
            
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item.email}
                                    </Typography>
                                  </TableCell>
            
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item && item.member && item.member.sex_name ? item.member.sex_name : "-"}
                                    </Typography>
                                  </TableCell>
                                  
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item && item.member && item.member.age ? item.member.age + " Tahun" : "-"}
                                    </Typography>
                                  </TableCell>
                                    
                                  <TableCell align="center">
                                    <IconButton edge="end" onClick={(e) => handleDropdownOpenRegistered(e, i)}>
                                      <MoreVertIcon />
                                    </IconButton>
                                    <StyledMenu
                                      id="customized-menu"
                                      anchorEl={anchorElRegistered}
                                      keepMounted
                                      open={Boolean(anchorElRegistered && anchorInRegistered === i)}
                                      onClose={(e) => handleDropdownCloseRegistered(e, i)}>
                                      <StyledMenuItem
                                        onClick={(e) => {
                                          unsignUser(item.id);
                                          handleDropdownCloseRegistered();
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.title}>
                                              <b>Remove User</b>
                                            </Typography>
                                          }
                                        />
                                      </StyledMenuItem>
                                    </StyledMenu>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          }
                        </TableBody>
                      )
                  }
                  

                  {
                    dataRegisteredUser && dataRegisteredUser.list &&
                      dataRegisteredUser.list.length <= 0 &&  
                      (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Box style={{ textAlign: "center" }}>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.title}
                                  style={{
                                    color: "grey",
                                    marginTop: 16,
                                    marginBottom: 16,
                                  }}>
                                  <b>Data user yang terdaftar tidak tersedia</b>
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                  }
                  
                    
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[1, 3, 5, 10, 25]}
                        colSpan={7}
                        count={dataRegisteredUser.options.paging.count}
                        rowsPerPage={dataRegisteredUser.options.paging.limit}
                        page={dataRegisteredUser.options.paging.page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        labelRowsPerPage="Baris setiap halaman"
                        onChangePage={handleChangePageRegisteredUser}
                        onChangeRowsPerPage={handleChangeRowsPerPageRegisteredUser}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
                {/* </TableContainer> */}
              </div>
            </Box>
            <Box mb={2.5}>
              <Grid container style={{marginBottom: "10px"}}>
                <Grid item md={4} xs={12}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title333}>
                    <b>List user belum terdaftar</b>
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Typography variant="subtitle1" component={'span'} className={classes.title333} style={{ textAlign: "right" }}>
                    {
                      dataUnregisteredUser.users.length > 0 &&
                        (
                          <div style={{display: "flex", justifyContent: "right"}}>
                            <b style={{marginRight: "5px"}}> {dataUnregisteredUser.users.length}</b> Ditandai
                            <Tooltip title="Assign User">
                              <IconButton
                                style={{paddingTop: "0px", paddingBottom: "0px", top: "-2px"}}
                                aria-label="delete"
                                onClick={() => {
                                  assignUser(null);
                                }}  
                              >
                                <AssignmentIcon style={{ color: "#d64253" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box mb={2.5}>
              <Grid container style={{marginBottom: "10px"}}>
                <Grid item md={12} xs={12}>
                  <TableFilterUserUnregistered
                    classes={classes}
                    masterCategory={masterCategory}
                    dataUnregisteredUser={dataUnregisteredUser}
                    setDataUnregisteredUser={setDataUnregisteredUser}
                    refreshListUnregisteredUser={refreshListUnregisteredUser}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={2.5}>
              <div style={{ textAlign: "left", marginBottom: "25px", overflowY: "unset" }}>
                <Table
                  className={classes.table}
                  aria-label="sticky table"
                  // stickyHeader
                >
                  <EnhancedTableHeadUserUnregistered
                    classes={classes}
                    dataCategory={dataCategory}
                    dataUnregisteredUser={dataUnregisteredUser}
                    setDataUnregisteredUser={setDataUnregisteredUser}
                    setLoader={setLoader}
                  />

                  {
                    dataUnregisteredUser && dataUnregisteredUser.list &&
                      dataUnregisteredUser.list.length > 0 &&  
                      ( 
                        <TableBody>
                          {
                            dataUnregisteredUser.list.map((item, i) => {
                              return (
                                <TableRow  key={item.id}>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={dataUnregisteredUser.users.includes(item.id)}
                                      inputProps={{ "aria-label": "select all desserts" }}
                                      onChange={() => {
                                        let dataTemp = dataUnregisteredUser;
                                        if (dataTemp.users.includes(item.id)) {
                                          const index = dataTemp.users.indexOf(item.id);
                                          if (index > -1) {
                                            dataTemp.users.splice(index, 1); 
                                          }
                                        } else {
                                          dataTemp.users.push(item.id);
                                        }
                                        setDataUnregisteredUser({...dataTemp});
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                  <Typography variant="subtitle2" className={classes.title333}>
                                      { dataUnregisteredUser.options.paging.page + i + 1}
                                    </Typography>
                                  </TableCell>
            
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item && item.member ? item.member.first_name + " " + item.member.last_name : "-"}
                                    </Typography>
                                  </TableCell>
            
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item.email}
                                    </Typography>
                                  </TableCell>
            
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item && item.member && item.member.sex_name ? item.member.sex_name : "-"}
                                    </Typography>
                                  </TableCell>
                                  
                                  <TableCell align="left" >
                                    <Typography variant="subtitle2" className={classes.title333}>
                                      {item && item.member && item.member.age ? item.member.age + " Tahun" : "-"}
                                    </Typography>
                                  </TableCell>
                                    
                                  <TableCell align="center">
                                    <IconButton edge="end" onClick={(e) => handleDropdownOpenUnregistered(e, i)}>
                                      <MoreVertIcon />
                                    </IconButton>
                                    <StyledMenu
                                      id="customized-menu"
                                      anchorEl={anchorElUnregistered}
                                      keepMounted
                                      open={Boolean(anchorElUnregistered && anchorInUnregistered === i)}
                                      onClose={(e) => handleDropdownCloseUnregistered(e, i)}>
                                      <StyledMenuItem
                                        onClick={(e) => {
                                          assignUser(item.id);
                                          handleDropdownCloseUnregistered()
                                        }}
                                      >
                                        <ListItemText
                                          primary={
                                            <Typography
                                              variant="subtitle2"
                                              className={classes.title}>
                                              <b>Assign User</b>
                                            </Typography>
                                          }
                                        />
                                      </StyledMenuItem>
                                    </StyledMenu>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          }
                        </TableBody>
                      )
                  }
                  

                  {
                    dataUnregisteredUser && dataUnregisteredUser.list &&
                      dataUnregisteredUser.list.length <= 0 &&  
                      (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Box style={{ textAlign: "center" }}>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.title}
                                  style={{
                                    color: "grey",
                                    marginTop: 16,
                                    marginBottom: 16,
                                  }}>
                                  <b>Data user yang belum terdaftar tidak tersedia</b>
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                  }
                    
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[1, 3, 5, 10, 25]}
                        colSpan={7}
                        count={dataUnregisteredUser.options.paging.count}
                        rowsPerPage={dataUnregisteredUser.options.paging.limit}
                        page={dataUnregisteredUser.options.paging.page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        labelRowsPerPage="Baris setiap halaman"
                        onChangePage={handleChangePageUnregisteredUser}
                        onChangeRowsPerPage={handleChangeRowsPerPageUnregisteredUser}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
                {/* </TableContainer> */}
              </div>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogDetail;