import React, { useContext } from "react";
import { Grid, Card, CardHeader, Avatar, Typography } from "@material-ui/core";
import LocalFloristRoundedIcon from "@material-ui/icons/LocalFloristRounded";

import { navigate } from "hookrouter";

import ContextGlobalDrive from "../../context/ContextDrive";

function FolderGallery({ classes }) {
  const context = useContext(ContextGlobalDrive);
  const folderGallery = { name: "Gallery", id: "gallery" };

  /* ====================================================
    LOCAL STATE
  ==================================================== */

  const handleNavigateGallery = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    breadcrumb.push(data.name);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  return (
    <Grid item md={3}>
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : 7,
          backgroundColor: "#fffee0",
        }}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#ffbe76" }}>
              <LocalFloristRoundedIcon style={{ color: "#e67e22" }} />
            </Avatar>
          }
          title={
            <Typography
              className={classes.folderNameText}
              variant="subtitle2"
              onClick={() => handleNavigateGallery(folderGallery)}>
              Gallery
            </Typography>
          }
        />
      </Card>
    </Grid>
  );
}

export default FolderGallery;
