import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextAllMA from "../context/AllMaContext";

import DialogError from "../../../../components/DialogError";

function DialogDeleteMAR({
  openDialogDeleteMAR,
  setOpenDialogDeleteMAR,
  classes,
  item,
}) {
  const context = useContext(ContextAllMA);

  const [loader, setLoader] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteMAR = () => {
    setLoader(true);

    axiosConfig
      .delete(
        `${URL_API}/swd/measured-activity/${item.measured_activity_id}/result/${item.id}`
      )
      .then(function (response) {
        // console.log("Response Delete MAR : ", response);

        context.setSuccessCreateMAR(Math.floor(Math.random() * 1000 + 1));
        setOpenDialogDeleteMAR(false);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);

        setLoader(false);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  return (
    <Dialog
      open={openDialogDeleteMAR}
      onClose={() => setOpenDialogDeleteMAR(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          className={classes.title}
          style={{ color: "black" }}
        >
          <b>Hapus Measured Activity Result</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center", width: 400 }}>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="subtitle2" className={classes.title}>
            Apakah Anda yakin mau menghapus MA Result ini?
          </Typography>
        </DialogContentText>
        <DialogActions
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Button
            onClick={handleDeleteMAR}
            variant="contained"
            className={classes.button}
          >
            {loader ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              "Hapus"
            )}
          </Button>
        </DialogActions>
      </DialogContent>

      <DialogError
        classes={classes}
        textErrorInformation={textErrorMessage}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogDeleteMAR;
