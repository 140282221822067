import React from "react";
import { CheckRounded } from "@material-ui/icons";
import { Box, Typography, Grid } from "@material-ui/core";
import moment from "moment";

const CardStatus = ({ classes, card }) => {
  // ===================== | LATE BADGE | =====================
  const isLate = moment(new Date()).isAfter(card.due_date, "day");
  const isDueToday = moment(card.due_date).isSame(new Date(), "day");
  const isToday = moment(card.created_at).isSame(new Date(), "day");
  const completed = card.is_complete?.id === "2" ? true : false;

  let bgColor;
  let color;

  if (card?.status_label?.code === "late") {
    bgColor = "#fad2d8";
    color = "#C8203C";
  }

  if (card?.status_label?.code === "due_today") {
    bgColor = "#F6EDC3";
    color = "#B68812";
  }

  if (card?.status_label?.code === "new") {
    bgColor = "#CFEAF1";
    color = "#4194AC";
  }

  if (card?.status_label?.code === "on_track" || card?.status_label === null) {
    bgColor = "#D9EBDD";
    color = "#1E874D";
  }

  // ===================== | LATE BADGE | =====================
  //  const isLate = moment(new Date()).isAfter(row.due_date, "day");
  //  const isDueToday = moment(row.due_date).isSame(new Date(), "day");
  //  const isToday = moment(row.created_at).isSame(new Date(), "day");

  return (
    <Grid container spacing={1.5}>
      <Grid item sm={3} style={{ display: "flex" }} alignItems="center">
        <CheckRounded style={{ fontSize: 16, marginRight: 4 }} />
        <Typography variant="caption" className={classes.title888}>
          Status
        </Typography>
      </Grid>

      <Grid
        item
        sm={9}
        style={{ display: "flex", backgroundColor: bgColor, borderRadius: 5 }}
        justify="center"
        alignItems="center"
      >
        <Typography
          variant="caption"
          className={classes.title}
          style={{
            color,
            borderRadius: 5,
            padding: "2px 7px",
            marginLeft: 4,
          }}
        >
          {card?.status_label === null ? "On Track" : card?.status_label?.label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CardStatus;
