import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { navigate } from "hookrouter";
import logoPerformate from "../../../assets/images/performate-03.png";

import "../Style/landing.css";
import Footer from "./Footer";
import Nav from "./Nav";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  alternateBackground: {
    backgroundColor: "#f5f5f5",
  },
});

const useStyles = makeStyles(styles);

const About = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <div className="container">
      <Nav />
      <section style={{ paddingTop: "0px", textAlign: "center" }}>
        <div className="box-about">
          <img src={logoPerformate} width="50px" />
          <h2>Tentang Performate</h2>
          <p>
            Performate adalah Aplikasi Perangkat Lunak yang diciptakan untuk
            membantu organisasi (perusahaan, Lembaga non proﬁt maupun keluarga)
            untuk mengelola kinerja organisasi agar tercapai hasil yang maksimal
            bagi organisasi sesuai dengan Visi, Misi dan Tujuan yang hendak
            dicapai oleh organisasi tersebut.
          </p>
        </div>
        <br />
        <br />
      </section>

      <section
        className={classes.alternateBackground}
        style={{ paddingTop: "0px" }}
      >
        <br />
        <br />
        <div className="box-about-2">
          <div>
            <p align="justify">
              Performate Membantu elemen-elemen dalam organisasi dalam menyusun
              dan mereview tujuan departemen/individu agar sejalan dengan hasil
              yang ingin dicapai oleh Organisasi baik dari aspek kualitas,
              kuantitas, biaya dan waktu pencapaian "Aplikasi Performate" dalam
              melaksanakan eksekusi strategi dan monitoring kinerja organisasi
              setiap harinya dalam jangka waktu yang disepakati bersama, hingga
              client/pengguna dapat mandiri dalam menggunakan "Aplikasi
              Performate" sebagai "Performance Management Tool" organisasinya.
            </p>
          </div>
          <div></div>
          <div>
            <p align="justify">
              " Performate dapat membantu pimpinan organisasi melakukan
              kalibrasi dan evaluasi terhadap aktivitas terukur (Measured
              Activities) dari setiap departemen/individu dalam organisasi agar
              tetap sejalan dengan tujuan utama organisasi. "
            </p>
          </div>
        </div>
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={handleTryFree}
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            className={classes.textLowerCase}
          >
            Coba Gratis
          </Button>
        </div>
        <br />
        <br />
      </section>
      <Footer />
    </div>
  );
};

export default About;
