import React, { useState, useContext } from "react";
import { Box, FormControl, Select, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import ContextAllMA from "../context/AllMaContext";

const PaginationMAv2 = ({ classes }) => {
  const context = useContext(ContextAllMA);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handlePagination = (e, value) => {
    setPage(value);
    const limitTask = context.limitMACount;
    const offsetTask = (value - 1) * context.limitMACount;

    context.setLimitMACount(limitTask);
    context.setOffsetMACount(offsetTask);
    context.setTriggerMAs(Math.floor(Math.random() * 1000 + 1));
  };

  const handleSelect = (e) => {
    setPerPage(e.target.value);
    setPage(1);

    context.setLimitMACount(e.target.value);
    context.setOffsetMACount(0);
    context.setTriggerMAs(Math.floor(Math.random() * 1000 + 1));
  };

  return (
    <Box
      my={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Typography className={classes.title888} variant="caption">
          Total Records{" "}
          <span
            style={{
              backgroundColor: "#aaa",
              color: "#fff",
              padding: "0 5px",
              borderRadius: 4,
            }}
          >
            <b>{context.collectionMALength}</b>
          </span>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Typography className={classes.title888} variant="caption">
          MA Per Page
        </Typography>
        <FormControl size="small" style={{ margin: "0 10px", minWidth: 50 }}>
          <Select native value={perPage} onChange={handleSelect}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Select>
        </FormControl>

        <Pagination
          variant="text"
          shape="rounded"
          page={page}
          count={Math.ceil(context.collectionMALength / context.limitMACount)}
          onChange={handlePagination}
        />
      </Box>
    </Box>
  );
};

export default PaginationMAv2;
