import React from "react";
import { Box, Typography } from "@material-ui/core";

const DetailUsed2 = ({ classes, val1, val2, val3 }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginTop={2}
      marginBottom={9}
      className={classes.boxDetail}>
      <Box display="flex">
        <Typography variant="subtitle2" className={classes.txtUsed3}>
          Kuota Deployment
        </Typography>
        <Typography variant="subtitle2" className={classes.txtUsed}>
          : {val1}
        </Typography>
      </Box>
      {/* <Box display="flex">
        <Typography variant="subtitle2" className={classes.txtUsed2}>
          Terpakai
        </Typography>
        <Typography variant="subtitle2" className={classes.txtUsed}>
          : {val2}
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="subtitle2" className={classes.txtUsed1}>
          Sisa tiket Consultant Visit
        </Typography>
        <Typography variant="subtitle2" className={classes.txtUsed}>
          : {val3}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default DetailUsed2;
