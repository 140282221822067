import React, { useState } from "react";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Slide,
  Checkbox,
  Tooltip,
} from "@material-ui/core";

import moment from "moment";

import { A } from "hookrouter";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

const ListCardItemOvertime = ({
  classes,
  item,
  dataOvertimeList,
  handleApprove,
  handleReject,
  handleShowModalDetail,
  getStatusName,
  timeConvert,
  getStatus,
  keyItem,
  roleName,
  userID
}) => {
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;
  const [anchorEl, setAnchorEl] = useState(null);

  // Function Show Dropdown List
  function handleDropdownOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
  }

  function handleDialogDelete() {}

  const handleRightClick = (data) => {
    // // console.log("Data goal", data);
  };

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit key={keyItem}>
      <tr key={keyItem}>
        <td>
          <Paper
            className={classes.shadowSection}
            style={{
              position: "relative",
              marginLeft: "-1px",
              marginRight: "-1px",
            }}
          >
            <Box
              p={1}
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Box width={50} ml={2}>
                  <Typography variant="subtitle1" className={classes.title333}>
                    {(dataOvertimeList.options.paging.page - 1) *
                      dataOvertimeList.options.paging.limit +
                      keyItem +
                      1}
                  </Typography>
                </Box>
                <Box
                  ml={2}
                  minWidth={200}
                  maxWidth={200}
                  display="flex"
                  alignItems="center"
                >
                  {item.employee !== null &&
                  item.employee.member !== null &&
                  item.employee.member.photo_url !== null &&
                  item.employee.member.self.rel.photo_url !== null ? (
                    <ImageSmallUser
                      item={item.employee.member.self.rel.photo_url}
                      index={1}
                    />
                  ) : (
                    <AccountCircleRoundedIcon style={{ color: "#c4c5c4" }} />
                  )}

                  <Box ml={2}>
                    <Typography
                      variant="subtitle1"
                      className={classes.title333}
                    >
                      {TruncateTextCustom(
                        `${item?.employee?.member?.first_name} ${item?.employee?.member?.last_name}`,
                        17
                      )}
                    </Typography>
                    <Typography variant="caption" className={classes.title888}>
                      <span>
                        {item.owner
                          ? TruncateTextCustom(
                              item.owner?.structure_position_title_name,
                              18
                            )
                          : "-"}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.boxTable}
              >
                <Box width={"32%"}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                    style={{ textAlign: "left" }}
                  >
                    {item.workday
                      ? moment(item.workday).format("DD-MM-YYYY")
                      : ""}
                  </Typography>
                </Box>

                <Box width={"32%"}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                    style={{ textAlign: "left" }}
                  >
                    {timeConvert(item.overtime_duration)}
                  </Typography>
                </Box>

                <Box width={"32%"}>
                  <Typography
                    variant="subtitle2"
                    className={classes.title333}
                    style={{ textAlign: "left" }}
                  >
                    {/* {getStatusName(item.status_approve, item.approver)} */}
                    {item.approval_desc}
                  </Typography>
                </Box>

                <Box width={"4%"}>
                  <IconButton
                    edge="end"
                    onClick={(e) => handleDropdownOpen(e, keyItem)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleDropdownClose}
                  >
                    <StyledMenuItem
                      onClick={(e) => handleShowModalDetail(item)}
                    >
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Detail</b>
                      </Typography>
                    </StyledMenuItem>
                    {((roleName === 'superadmin' && (item.approval_desc === "Waiting Approve by SPV" || item.approval_desc === "Waiting Approve by HR")) || 
                      (roleName === 'manage' && item.approval_desc === "Waiting Approve by SPV" && userID !== item.created_by) ||
                      (roleName === 'hr' && (item.approval_desc === "Waiting Approve by SPV" || item.approval_desc === "Waiting Approve by HR"))
                    ) && (
                      <StyledMenuItem onClick={(e) => handleApprove(item)}>
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>Approve</b>
                        </Typography>
                      </StyledMenuItem>
                    )}
                    {((roleName === 'superadmin' && (item.approval_desc === "Waiting Approve by SPV" || item.approval_desc === "Waiting Approve by HR")) || 
                      (roleName === 'manage' && item.approval_desc === "Waiting Approve by SPV" && userID !== item.created_by) ||
                      (roleName === 'hr' && (item.approval_desc === "Waiting Approve by SPV" || item.approval_desc === "Waiting Approve by HR"))
                    ) && (
                      <StyledMenuItem onClick={(e) => handleReject(item)}>
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>Reject</b>
                        </Typography>
                      </StyledMenuItem>
                    )}
                  </StyledMenu>
                </Box>
              </Box>
            </Box>
          </Paper>
        </td>
      </tr>
    </Slide>
  );
};

export default ListCardItemOvertime;
