import React, { useEffect, useState } from "react";
import { styles } from "../Style/StyleAttendanceHome";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
import { isEmpty } from "lodash";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const TodayScheduleCard = ({ data }) => {
  const classes = useStyles();

  const [isClockIn, setIsClockIn] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState({
    hours: 0,
    minutes: 0,
    late: 0,
  });

  const [lateTolerance, setLateTolerance] = useState(0);

  useEffect(() => {
    if (data?.clock_in !== null && data?.clock_in?.clock_in !== null) {
      setIsClockIn(true);
    } else if (data?.clock_in !== null && data?.clock_in?.clock_out !== null) {
      setIsClockIn(false);
    } else {
      setIsClockIn(false);
    }

    const timeInterval = setInterval(() => {
      getTimeRemaining();
    }, 200);
    return () => {
      clearInterval(timeInterval);
    };
  }, [data]);

  const getTimeRemaining = () => {
    const currentTime = moment();
    let duration;
    const clockInTime = moment(data?.clock_in?.clock_in, "YYYY-MM-DD HH:mm:ss");
    const scheduleStartTime = moment(data?.shift?.schedule_in, "HH:mm");

    if (data && data.clock_in !== null) {
      if (
        data.clock_in?.clock_in !== null &&
        data.clock_in?.clock_out === null
      ) {
        duration = moment.duration(currentTime.diff(clockInTime));
      } else if (
        data.clock_in?.clock_in === null &&
        data.clock_in?.clock_out === null
      ) {
        duration = moment.duration(scheduleStartTime.diff(currentTime));
      } else if (
        data.clock_in?.clock_in !== null &&
        data.clock_in?.clock_out !== null
      ) {
        duration = moment.duration(scheduleStartTime.diff(currentTime));
      }
    } else {
      duration = moment.duration(scheduleStartTime.diff(currentTime));
    }

    if (duration?.isValid()) {
      const hours = parseInt(duration.asHours());
      const minutes = parseInt(duration.asMinutes()) % 60;
      /*setTimeRemaining({
        hours: hours.toString(),
        minutes: minutes.toString(),
      });*/
      if (data?.shift?.late_tolerance_status === 1) {
        if (currentTime.isAfter(scheduleStartTime)) {
          const lateTolerance = parseInt(data?.shift?.late_tolerance);
          const lateTime = scheduleStartTime.add(lateTolerance, "minutes");
          if (currentTime.isAfter(lateTime)) {
            //// console.log("late");
            setTimeRemaining({
              hours: hours.toString(),
              minutes: (minutes + lateTolerance).toString(),
              late: 1,
            });
          } else {
            //// console.log("almost late");
            setTimeRemaining({
              hours: hours.toString(),
              minutes: minutes.toString(),
              late: 2,
            });
            const diffLateTolerance = moment.duration(
              lateTime.diff(currentTime)
            );
            setLateTolerance(parseInt(diffLateTolerance.asMinutes()) % 60);
          }
        } else {
          //// console.log("a on time");
          setTimeRemaining({
            hours: hours.toString(),
            minutes: minutes.toString(),
            late: 0,
          });
        }
      }
    } else {
      setTimeRemaining({
        hours: "0",
        minutes: "0",
        late: 0,
      });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      {data &&
      data.clock_in !== null &&
      data.clock_in?.clock_in !== null &&
      data.clock_in?.clock_out === null ? (
        <>
          <Typography variant="h6" style={{ color: "#262261" }}>
            <b> Durasi Kerja Berjalan </b>
          </Typography>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background:
                "linear-gradient(to left bottom, #221E5B 30%, #3f51b5 90%)",
              borderRadius: 11,
              padding: "10px 20px",
              margin: "10px 10px",
            }}
          >
            <CardContent style={{ flex: "1 0 auto" }}>
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <Typography
                    variant="h5"
                    style={{
                      color: "white",
                      marginTop: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {`${timeRemaining.hours} Jam ${timeRemaining.minutes} Menit`}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    Anda Sedang Clock In
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#CE354A",
                      marginTop: 30,
                      height: 50,
                    }}
                    fullWidth
                    onClick={() => {
                      navigate("/my-attendance");
                    }}
                  >
                    <Typography variant="subtitle2" style={{ color: "#fff" }}>
                      Clock Out
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              {data.check_in !== null &&
              data.check_in?.clock_in !== null &&
              data.check_in?.clock_out === null ? (
                <Button
                  variant="contained"
                  style={{
                    marginTop: 20,
                    backgroundColor: "#fff",
                    height: "3rem",
                  }}
                  fullWidth
                  onClick={() => {
                    navigate("/check-in-check-out");
                  }}
                >
                  <Typography variant="subtitle2" style={{ color: "#CE354A" }}>
                    Check Out
                  </Typography>
                </Button>
              ) : (
                data.schedule_detail != null ||
                (!isEmpty(data.schedule_detail) && (
                  <Button
                    variant="contained"
                    style={{
                      marginTop: 20,
                      backgroundColor: "#fff",
                      height: "3rem",
                    }}
                    fullWidth
                    onClick={() => {
                      navigate("/check-in-check-out");
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#262261" }}
                    >
                      Check In
                    </Typography>
                  </Button>
                ))
              )}
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Typography variant="h6" style={{ color: "#262261" }}>
            <b> Jadwal Kerja Hari Ini </b>
          </Typography>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              background:
                "linear-gradient(to left bottom, #221E5B 30%, #3f51b5 90%)",
              borderRadius: 11,
              padding: "10px 20px",
              margin: "10px 10px",
            }}
          >
            <CardContent style={{ flex: "1 0 auto" }}>
              <Grid container spacing={1}>
                <Grid item xs={7}>
                  <Typography
                    variant="h5"
                    style={{
                      color: "white",
                      marginTop: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {data?.shift?.schedule_in} - {data?.shift?.schedule_out}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    {timeRemaining.late === 1
                      ? `Terlambat ${Math.abs(
                          timeRemaining.hours
                        )} Jam ${Math.abs(timeRemaining.minutes)} Menit`
                      : timeRemaining.late === 2
                      ? `${lateTolerance} Menit menuju toleransi keterlambatan berakhir`
                      : `${timeRemaining.hours} Jam ${timeRemaining.minutes} Menit Lagi`}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      marginTop: 30,
                      height: 50,
                    }}
                    fullWidth
                    onClick={() => {
                      navigate("/my-attendance");
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#221E5B" }}
                    >
                      Clock In
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </MuiThemeProvider>
  );
};

export default TodayScheduleCard;
