import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import axiosConfig from '../../../../../constants/config-axios';
import { URL_API } from '../../../../../constants/config-api';
import DialogError from '../../../../../components/DialogError';
import handleError from '../../../../Report/components/global/handleError';
import ContextTask from '../../../Context/ContextTask';

import icon_high from '../../../../../assets/images/SVG/icon_high.svg';
import icon_medium from '../../../../../assets/images/SVG/icon_medium.svg';
import icon_low from '../../../../../assets/images/SVG/icon_low.svg';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const DialogCreateTDL = ({
  classes,
  openDialogCreateTDL,
  setOpenDialogCreateTDL
}) => {
  const { setTriggerTodo } = useContext(ContextTask);

  const [loading, setLoading] = useState(false);
  const [loadCreate, setLoadCreate] = useState(false);
  const [todo, setTodo] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [target, setTarget] = useState(0);
  const [status, setStatus] = useState('TO DO');
  const [ownerId, setOwnerId] = useState('');
  const [priority, setPriority] = useState('LOW');
  const [remarks, setRemarks] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  //collection
  const [priorities, setPriorities] = useState([]);
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [statusCollections, setStatusCollections] = useState([]);
  // Conditional
  const [lessMore, setLessMore] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  useEffect(() => {
    if (openDialogCreateTDL) {
      setLoadCreate(true);

      axiosConfig
        .get(`${URL_API}/todolist/create`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setPriorities(result.priorityCollections);
            setOwnerCollections(result.ownerCollections);
            setStatusCollections(result.statusCollections);
          }
          setLoadCreate(false);
        })
        .catch((error) => {
          setLoadCreate(false);
          // console.log("error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openDialogCreateTDL]);

  const handleChooseOwner = (event, data) => {
    // console.log("Owner terpilih : ", data);

    if (data !== null) {
      setOwnerId(data.id);
    }
  };

  const handleStatus = (e) => setStatus(e.target.value);
  const handlePriority = (e) => setPriority(e.target.value);

  const handleAddTodo = () => {
    setLoading(true);

    const data = {
      Todolist: {
        title: todo,
        target_result: target,
        start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        due_date:
          endDate === null ? '' : moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        owner_id: ownerId,
        status,
        priority,
        remarks,
        description,
        tags
      }
    };

    // // console.log("DATA TODO", data);

    axiosConfig
      .post(`${URL_API}/todolist`, data)
      .then((res) => {
        if (res.status === 200) {
          setOpenDialogCreateTDL(false);
          setLoading(false);

          setTriggerTodo(Math.floor(Math.random() * 100));

          setTodo('');
          setEndDate(null);
          setTarget(0);
          setOwnerId('');
          setRemarks('');
          setDescription('');
          setTags('');
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogCreateTDL}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ textAlign: 'center', backgroundColor: '#F9F9F9' }}
      >
        <Typography variant="h6" className={classes.title333}>
          <b>Create To Do</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box p={2}>
          <Typography variant="subtitle2" className={classes.title333}>
            Title
          </Typography>
          <Box my={0.5} />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="Add a title"
            onChange={(e) => setTodo(e.target.value)}
          />

          <Box my={2} />

          <Typography variant="subtitle2" className={classes.title333}>
            Description
          </Typography>
          <Box my={0.5} />
          <TextField
            type="text"
            size="small"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            placeholder="What is your task description"
            onChange={(e) => setDescription(e.target.value)}
          />

          <Box my={2} />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title333}>
                Assignee
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                id="combo-box-demo"
                options={ownerCollections}
                getOptionLabel={(option) =>
                  `${option.member.first_name} ${option.member.last_name}`
                }
                noOptionsText="Tidak ada owner"
                onChange={(e, owner) => handleChooseOwner(e, owner)}
                popupIcon={
                  loadCreate ? (
                    <CircularProgress style={{ color: '#C8203C' }} size={20} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: '#C8203C' }} />
                  )
                }
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    label="Select user"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box my={2} />

          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title333}>
                Start Date
              </Typography>
              <Box my={0.5} />
              <MuiPickersUtilsProvider utils={LocalizedUtils}>
                <DatePicker
                  autoOk
                  value={startDate}
                  onChange={setStartDate}
                  animateYearScrolling
                  variant="inline" // dialog, static, inline
                  disableToolbar={false}
                  format="DD MMMM YYYY"
                  placeholder="Pick your end date"
                  inputVariant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small">
                          <EventAvailableIcon style={{ color: '#C8203C' }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title333}>
                End Date
              </Typography>
              <Box my={0.5} />
              <MuiPickersUtilsProvider utils={LocalizedUtils}>
                <DatePicker
                  autoOk
                  value={endDate}
                  onChange={setEndDate}
                  animateYearScrolling
                  variant="inline" // dialog, static, inline
                  disableToolbar={false}
                  format="DD MMMM YYYY"
                  placeholder="Pick your end date"
                  inputVariant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton size="small">
                          <EventAvailableIcon style={{ color: '#C8203C' }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Box my={1} />

          {!lessMore && (
            <Box mt={4} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button2}
                onClick={() => setLessMore(true)}
              >
                Show More
              </Button>
            </Box>
          )}

          {lessMore && (
            <>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    Priority
                  </Typography>
                  <Box my={0.5} />
                  <Select
                    variant="outlined"
                    fullWidth
                    placeholder="Select priority"
                    value={priority}
                    style={{ height: 40 }}
                    onChange={handlePriority}
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{
                      classes: {
                        icon: classes.iconSelect
                      }
                    }}
                  >
                    {priorities.length > 0 ? (
                      priorities.map((item, i) => {
                        let icon;

                        switch (item && item.id) {
                          case 'HIGH':
                            icon = icon_high;
                            break;
                          case 'MEDIUM':
                            icon = icon_medium;
                            break;
                          case 'LOW':
                            icon = icon_low;
                            break;
                          default:
                            icon = null;
                        }

                        return (
                          <MenuItem key={i} value={item.id}>
                            <Box display="flex" alignItems="center">
                              <img src={icon} height={16} />
                              <Typography
                                variant="subtitle2"
                                className={classes.title555}
                                style={{ marginLeft: 8 }}
                              >
                                {item.name}
                              </Typography>
                            </Box>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Priority is empty</MenuItem>
                    )}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    Status
                  </Typography>
                  <Box my={0.5} />
                  <Select
                    variant="outlined"
                    fullWidth
                    placeholder="Select status"
                    value={status}
                    style={{ height: 40 }}
                    onChange={handleStatus}
                    IconComponent={KeyboardArrowDownIcon}
                    inputProps={{
                      classes: {
                        icon: classes.iconSelect
                      }
                    }}
                  >
                    {statusCollections.length > 0 ? (
                      statusCollections.map((status, i) => {
                        let bgColor;
                        let color;

                        switch (status.id) {
                          case 'TO DO':
                            bgColor = '#DFDFDF';
                            color = '#777777';
                            break;
                          case 'DOING':
                            bgColor = '#CFEAF1';
                            color = '#4194AC';
                            break;
                          case 'DONE':
                            bgColor = '#D9EBDD';
                            color = '#1E874D';
                            break;
                          case 'ON HOLD':
                            bgColor = '#F6EDC3';
                            color = '#B68812';
                            break;
                          case 'WAITING':
                            bgColor = '#E2DAE5';
                            color = '#92739F';
                            break;
                          default:
                            bgColor = '#555555';
                            color = '#fff';
                        }

                        return (
                          <MenuItem key={i} value={status.id}>
                            <Typography
                              variant="subtitle2"
                              className={classes.title555}
                              style={{
                                color,
                                backgroundColor: bgColor,
                                fontSize: 12,
                                textAlign: 'center',
                                borderRadius: 15,
                                padding: '3px 7px',
                                width: 75
                              }}
                            >
                              {status.name}
                            </Typography>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Status is empty</MenuItem>
                    )}
                  </Select>
                </Grid>
              </Grid>

              <Box my={1} />

              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    Target
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="insert amount of target"
                    onChange={(e) => setTarget(e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    Tags
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    size="small"
                    fullWidth
                    onChange={(e) => setTags(e.target.value)}
                  />
                </Grid>
              </Grid>

              <Box my={1.5} />

              <Typography variant="subtitle2" className={classes.title333}>
                Notes
              </Typography>
              <Box my={0.5} />
              <TextField
                type="text"
                size="small"
                variant="outlined"
                placeholder="What is your task notes"
                fullWidth
                multiline
                onChange={(e) => setRemarks(e.target.value)}
              />

              <Box mt={4} display="flex" justifyContent="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button2}
                  onClick={() => setLessMore(false)}
                >
                  Show Less
                </Button>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={() => setOpenDialogCreateTDL(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            size="small"
            variant={loading ? 'contained' : 'outlined'}
            className={classes.button1}
            disabled={loading}
            onClick={handleAddTodo}
          >
            {loading ? 'Creating Todo...' : 'Create'}
          </Button>
        </Box>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogCreateTDL;
