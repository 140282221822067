export const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 575,
    borderRadius: 7,
  },
  button: {
    // width: '503px',
    // height: '42px',s
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    // marginBottom: theme.spacing(4),
    color: "white",
    textTransform: "capitalize",
  },
  button2: {
    borderRadius: 5,
    background: "#d1354a",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      background: "#f28181",
    },
  },
  buttonTambah: {
    background: "#f8b314",
    borderRadius: 5,
    border: 0,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      background: "#ffb200",
    },
  },
  buttonDetail: {
    textTransform: "capitalize",
    fontFamily: "Roboto",
  },

  buttonAction: {
    textTransform: "capitalize",
  },
  title: {
    fontFamily: "Roboto",
  },

  /*

        ````````````````````````````
        STYLING LIST EMPLOYEE TABLE

        ```````````````````````````

    */
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
    // margin: 12
  },
  rootEmployee: {
    borderRadius: 2,
    width: "100%",
    marginTop: theme.spacing(1),
  },
  iconPreviewGrid: {
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    width: 20,
    height: 20,
    cursor: "pointer",
  },
  iconPreviewList: {
    marginTop: theme.spacing(3),
    width: 24,
    height: 24,
  },

  iconFilter: {
    marginLeft: theme.spacing(7),
    width: 20,
    height: 20,
    cursor: "pointer",
  },

  lensIcon: {
    color: "#19dc5a",
    width: 14,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fill: "#19dc5a",
    // backgroundColor: '#19dc5a'
  },

  paper: {
    padding: "1px 2px",
    // padding: theme.spacing(1,2),
    display: "flex",
    alignItems: "center",
    width: 600,
    height: 52,
  },
  paperList: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
  },
  input: {
    //   marginLeft: 8,
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    //   margin: 4,
    margin: theme.spacing(2),
  },

  chipVerifified: {
    backgroundColor: "green",
  },
  title: {
    fontFamily: "Roboto",
  },
  imagePictDefault: {
    marginTop: theme.spacing(7),
    height: 272,
  },
  listItemText: {
    width: "100%",
    // marginRight: theme.spacing(8)
  },
  listRoot: {
    width: "100%",
  },
  imageAvatar: {
    width: 50,
    height: 40,
  },
});
