import React, { Component, useEffect, useState } from "react";
import { makeStyles, createMuiTheme, withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    Container, Paper, Typography, Grid, TextField, Checkbox, CircularProgress,
    FormControlLabel, Box, Button, MenuItem, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, Breadcrumbs, Link, IconButton, InputBase, Divider,
    Tooltip, List, ListItem, ListItemText, Avatar, Table, TableBody, TableRow, TableCell,
    Chip, TablePagination, TableHead, TableSortLabel, Radio, FormLabel

} from '@material-ui/core';


const DialogTambahAnggotaKeluarga = props => {

    const { 
        
            classes,
            modalTambahAnggotaKeluarga,
            setModalTambahAnggotaKeluarga,
            handleChangeNamaAnggotaKeluarga,
            nameAnggotaKeluarga,
            hubunganKeluarga,
            handleChangeHubunganKeluarga,
            // hubunganKeluargas,
            handleChangeTanggalLahir,
            tanggalLahir,
            handleSimpanDataAnggotaKeluarga
        
        } = props;

    return (

        <Dialog
            open={modalTambahAnggotaKeluarga}
            onClose={() => setModalTambahAnggotaKeluarga(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title" style={{textAlign: "left"}}>
            <Typography variant='subtitle1' className={classes.title}>
                <b>Tambah Anggota Keluarga</b>
            </Typography>
        </DialogTitle>
        <DialogContent>

            <List className={classes.list}>
                <ListItem >  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Nama</b>
                            </Typography>
                        } 
                    />
                    <TextField
                        id="outlined-bare"
                        onChange= {handleChangeNamaAnggotaKeluarga}
                        value={nameAnggotaKeluarga}
                        className={classes.textField}
                        // placeholder={'NIP'}
                        variant="outlined"
                        // error={infoError == true && npwp == '' ? true : false}
                        // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
                        // required={true} 
                        // name='nip'
                        // color='primary'
                        // onKeyDown={handleEnterPress}
                        // disabled= {isLockedStatusState == 1 ? true : false}
                        // fullWidth
                    />
                </ListItem>

                <ListItem >  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>Hubungan</b>
                            </Typography>
                        } 
                    />

                    <TextField
                        id="outlined-select-provinsi"
                        select
                        // label="Pilih Negara : "
                        className={classes.textField}
                        value={hubunganKeluarga.name}
                        onChange={handleChangeHubunganKeluarga('name')}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal"
                        variant="outlined"
                        // error={infoError == true && kotaKabInKtp.name == '' ? true : false}
                        // helperText={infoError == true && kotaKabInKtp.name == '' ? "Wajib di isi" : ' '}
                    >
                        {

                            hubunganKeluargas.map (

                                option => (

                                    <MenuItem key={option.id} value={option}>
                                        {option.label}
                                    </MenuItem>
                                )
                            )
                        }
                    </TextField>
                </ListItem>

                <ListItem>  
                    <ListItemText 
                        primary={
                            <Typography variant='subtitle1' className={classes.titleForm}>
                                <b>TTL</b>
                            </Typography>
                        } 
                    />
                    <form className={classes.container} noValidate>
                        <TextField
                            id="date"
                            onChange={handleChangeTanggalLahir}
                            value={tanggalLahir}
                            defaultValue="2019-05-24"
                            type="date"
                            // label="Birthday"
                            variant="outlined"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            // error={infoError == true && tanggalLahir == '' ? true : false}
                            // helperText={infoError == true && tanggalLahir == '' ? "Wajib di isi" : ' '}
                        />
                    </form>
                </ListItem>
            </List>

            <DialogContentText id="alert-dialog-description">
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{alignItems: "right", justifyContent:'flex-end'}}>
            <Button 
                onClick={() => setModalTambahAnggotaKeluarga(false)} 
                variant='outlined' 
                size='small'
                className={classes.buttonModalCancel}    
            >
                Batal
            </Button>
            
            <Button 
                onClick= {handleSimpanDataAnggotaKeluarga}
                variant='contained' 
                color="primary" 
                size='small'
                className={classes.buttonModal}
            >  
                Simpan
            </Button>
        </DialogActions>
        <br />
        <br />
        </Dialog>
    )   
};

export default DialogTambahAnggotaKeluarga;



const hubunganKeluargas = [
    {
        id: 1,
        value: "Ayah Kandung",
        label: "Ayah Kandung",
    },
    {
        id: 2,
        value: "Ibu Kandung",
        label: "Ibu Kandung"
    },
    {
        id: 3,
        value: "Ayah Tiri",
        label: "Ayah Tiri"
    },
    {
        id: 4,
        value: "Ibu Tiri",
        label: "Ibu Tiri"
    },
    {
        id: 5,
        value: "Istri / Suami",
        label: "Istri / Suami"
    },
    {
        id: 6,
        value: "Saudara Kandung",
        label: "Saudara Kandung"        
    },
    {
        id: 7,
        value: "Saudara Tiri",
        label: "Saudara Tiri"        
    },
    {
        id: 8,
        value: "Anak Kandung",
        label: "Anak Kandung"                        
    }, 
    {
        id: 9,
        value: "Anak Tiri",
        label: "Anak Tiri"                        
    },
    {
        id: 10,
        value: 'Anak Angkat',
        label: 'Anak Angkat' 
    }
];