import React, { useEffect, useState, useContext } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import clsx from "clsx";

import { navigate } from "hookrouter";

import ContextCompanySetting from "../screen/AM/CompanySetting/Context/ContextCompanySetting";

import axiosConfig from "../constants/config-axios";

import { URL_API } from "../constants/config-api";
import { styles } from "./Style/StyleHeaderGoal";
import DialogError from "../components/DialogError";
import MenuItems from "./MenuItems";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  ListItemText,
  Box,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  AccountCircle,
  Home,
  SupervisedUserCircle,
} from "@material-ui/icons";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Person from "@material-ui/icons/Person";
import MoreIcon from "@material-ui/icons/MoreVert";

import {
  ToAttendanceDashboard,
  ToTeam,
  ToInbox,
  ToProfile,
  ToLogin,
} from "../constants/config-redirect-url";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logo: {
    width: "48px",
    height: "48px",
    marginRight: theme.spacing(2),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  list: {
    width: 250,
    background: "#282560",
  },
  fullList: {
    width: "auto",
  },
}));

export default function HeaderAttendance() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [drawer, setDrawer] = React.useState({
    left: false,
  });

  const authData = JSON.parse(localStorage.getItem("status_user_login"));

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer({ ...drawer, [anchor]: open });
  };

  function handleLogOut() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("intro_enabled");

    localStorage.removeItem("verifyToken");
    localStorage.removeItem("status_user_login");
    localStorage.removeItem("goal_detail");
    localStorage.removeItem("employee_id");
    localStorage.removeItem("response_employee_detail");
    localStorage.removeItem("project_details");

    localStorage.removeItem("value_search");
    localStorage.removeItem("value_search_ma");
    localStorage.removeItem("status_goal_id");
    localStorage.removeItem("email");
    localStorage.removeItem("appVersion");
    localStorage.removeItem("location_tab");
    localStorage.removeItem("main_goal_name");
    localStorage.removeItem("data_identitas");

    localStorage.removeItem("breadcrumb");
    localStorage.removeItem("directory_id");
    localStorage.removeItem("status_allma_id");
    localStorage.removeItem("setting_tab");
    localStorage.removeItem("org_tab");
    localStorage.removeItem("data_preview_so");

    localStorage.removeItem("goal_category");
    localStorage.removeItem("project_type");
    localStorage.removeItem("question_bantu_generate");

    localStorage.removeItem("summary_range");
    localStorage.removeItem("summary_user");
    localStorage.removeItem("filter_user");

    // localStorage.clear();
    navigate(ToLogin);
    window.location.reload();
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogOut}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {MenuItems.map((menuItem, index) => (
        <div key={index}>
          <Box
            m={2}
            style={{
              display:
                menuItem.isSuperAdmin ||
                authData?.userRole[0].name === "superadmin"
                  ? "none"
                  : "",
            }}
          >
            <Typography
              type="PengaturanRole"
              style={{
                color: "#fff",
                fontFamily: "Roboto",
                fontWeight: "bold",
              }}
            >
              {menuItem.name}
            </Typography>
          </Box>
          <List>
            {menuItem.child !== [] &&
              menuItem.child.map((child, indexChild) => (
                <ListItem
                  button
                  key={indexChild}
                  onClick={() => {
                    navigate(child.path);
                  }}
                  style={{
                    display:
                      child.isSuperAdmin ||
                      authData?.userRole[0].name === "superadmin"
                        ? "none"
                        : "",
                  }}
                >
                  <ListItemIcon>
                    {child.icon !== "" ? (
                      child.icon
                    ) : (
                      <img src={child.image} style={{ marginLeft: 4 }} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography style={{ color: "#fff" }}>
                        {child.name}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
          </List>
          <Divider />
        </div>
      ))}
    </div>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "#fafafa",
        }}
      >
        <Toolbar>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <img
              src={require("../assets/images/logo_performate.ico")}
              className={classes.logo}
            />
          </div>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="open drawer"
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            aria-label="home"
            style={{
              marginLeft: "auto",
              marginRight: "0px",
              color: "#262261",
            }}
            onClick={() => {
              navigate(ToAttendanceDashboard);
            }}
          >
            <Home />
            <Typography
              variant="body1"
              style={{
                marginLeft: "10px",
              }}
            >
              HOME
            </Typography>
          </IconButton>

          <IconButton
            aria-label="inbox"
            style={{
              marginLeft: "auto",
              marginRight: "0px",
              color: "#262261",
            }}
            onClick={() => {
              navigate(ToInbox);
            }}
          >
            <Badge badgeContent={2} color="secondary">
              <MailIcon />
            </Badge>
            <Typography
              variant="body1"
              style={{
                marginLeft: "10px",
              }}
            >
              INBOX
            </Typography>
          </IconButton>

          <IconButton
            aria-label="home"
            style={{
              marginLeft: "auto",
              marginRight: "0px",
              color: "#262261",
            }}
            onClick={() => {
              navigate(ToTeam);
            }}
          >
            <SupervisedUserCircle />
            <Typography
              variant="body1"
              style={{
                marginLeft: "10px",
              }}
            >
              TEAM
            </Typography>
          </IconButton>

          <IconButton
            aria-label="home"
            style={{
              marginLeft: "auto",
              marginRight: "0px",
              color: "#262261",
            }}
            onClick={() => {
              navigate(ToProfile);
            }}
          >
            <Person />
            <Typography
              variant="body1"
              style={{
                marginLeft: "10px",
              }}
            >
              AKUN
            </Typography>
          </IconButton>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Avatar
              alt="Remy Sharp"
              src={require("../assets/images/user-white.png")}
              style={{
                backgroundColor: "#C62B40",
              }}
            />
            <Typography
              variant="h6"
              style={{
                marginLeft: "10px",
                color: "#262261",
                cursor: "pointer",
              }}
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              {authData?.member_first_name + " " + authData?.member_last_name}
            </Typography>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        anchor="left"
        open={drawer.left}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </div>
  );
}
