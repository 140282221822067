import React, { useReducer } from "react";
import ContextReports from "./ContextReports";
import { liveSummaryInitial, liveSummaryReducer } from "./reducers/liveSummaryReducer";
import { liveSummaryFilterInitial, liveSummaryFilterReducer } from "./reducers/liveSummaryFilterReducer";
import { liveSummaryDetailInitial, liveSummaryDetailReducer } from "./reducers/liveSummaryDetailReducer";

const ContextGlobalReports = (props) => {
  const [liveSummary, liveSummaryDispatch] = useReducer(
    liveSummaryReducer,
    liveSummaryInitial
  );
  const [liveSummaryFilter, liveSummaryFilterDispatch] = useReducer(
    liveSummaryFilterReducer,
    liveSummaryFilterInitial
  );
  const [liveSummaryDetail, liveSummaryDetailDispatch] = useReducer(
    liveSummaryDetailReducer,
    liveSummaryDetailInitial
  );
  return (
    <ContextReports.Provider
      value={{
        liveSummary,
        liveSummaryDispatch,
        liveSummaryFilter,
        liveSummaryFilterDispatch,
        liveSummaryDetail,
        liveSummaryDetailDispatch
      }}>
      {props.children}
    </ContextReports.Provider>
  );
};

export default ContextGlobalReports;
