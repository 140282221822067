import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { useDropzone } from 'react-dropzone';

import { URL_API } from '../../../constants/config-api';
import AxiosConfig from '../../../constants/config-axios';

import excel_icon from '../../../assets/images/icons_file/Excel.png';

import DialogError from '../../../components/DialogError';
import handleError from '../../Report/components/global/handleError';

const DialogImportStructure = ({
  classes,
  openImportStructure,
  setOpenImportStructure,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleBreak, setToggleBreak] = useState(false);
  const [masterStructureUnit, setMasterStructureUnit] = useState([]);
  const [structurePosition, setStructurePosition] = useState([]);
  const [structurePositionTitle, setStructurePositionTitle] =
    useState([]);
  const [users, setUsers] = useState([]);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const theFile = acceptedFiles[0];

      // console.log('thefile', theFile);

      setFile({
        file: theFile,
        preview: URL.createObjectURL(theFile),
      });
    },

    maxFiles: 1,
    accept:
      '.xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  });

  // const handleDownloadSample = () => {};
  const handleToggleBreak = (e) => {
    setToggleBreak(e.target.checked);
  };

  const handleImportStructure = () => {
    setLoading(true);

    const dataFile = new FormData();

    console.log(' file?.file : ', file?.file);

    dataFile.append('StructurePosition[file]', file?.file);

    AxiosConfig.post(
      `${URL_API}/human-resource/structure-position/import?break=${
        toggleBreak ? '1' : '0'
      }`,
      dataFile
    )
      .then((res) => {
        console.log('Res : ', res);
        const resMasterStructureUnit =
          res.data.data?.structureUnitData;
        const resStructurePosition =
          res.data.data?.structurePositionData;
        const resStructurePositionTitle =
          res.data.data?.structurePositionTitleData;
        const resUsers = res.data.data?.userData;

        if (res.status === 200) {
          setMasterStructureUnit(resMasterStructureUnit);
          setStructurePosition(resStructurePosition);
          setStructurePositionTitle(resStructurePositionTitle);
          setUsers(resUsers);

          setLoading(false);
          setFile(null);

          if (
            resMasterStructureUnit?.length === 0 &&
            resStructurePosition?.length === 0 &&
            resStructurePositionTitle?.length === 0 &&
            resUsers?.length === 0
          ) {
            setOpenImportStructure(false);
          }
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
        setLoading(false);

        // if (error.response !== undefined) {
        //   if (error.response.status === 403) {
        //     setStatus403(true);
        //   }
        // }

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openImportStructure}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box p={1}>
          <Typography
            variant="subtitle1"
            className={classes.title333}
          >
            <b>Import Structure</b>
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent style={{ width: 500 }}>
        <div className={classes.uploadContainer}>
          <div
            {...getRootProps()}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <input {...getInputProps()} />
            <CloudUploadOutlinedIcon
              fontSize="large"
              style={{ color: '#E0E0E0' }}
            />
            <span className={classes.title}>
              Drag 'n' drop xls or xlsx file here
            </span>
            <span>or</span>
            <Button
              size="small"
              variant="outlined"
              className={classes.uploadBtn}
            >
              Choose File
            </Button>
          </div>
        </div>
        <Box display="flex" alignItems="center">
          <InfoIcon style={{ color: '#888', fontSize: 16 }} />
          <Typography
            variant="caption"
            className={classes.title555}
            style={{ marginLeft: 5 }}
          >
            Semua Row yang kosong tidak akan diproses oleh sistem
          </Typography>
        </Box>

        {file !== null && (
          <Box mt={2} display="flex" alignItems="center">
            <Avatar
              variant="square"
              sizes={30}
              src={excel_icon}
              style={{ marginRight: 5 }}
            />
            <Typography className={classes.title} variant="subtitle2">
              {file?.file?.name}
            </Typography>
          </Box>
        )}

        {/* Master structure unit */}
        {masterStructureUnit?.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1" className={classes.title}>
              Master structure unit
            </Typography>
            <Box display="flex" flexDirection="column">
              {masterStructureUnit?.map((item) => (
                <Typography
                  variant="caption"
                  className={classes.title}
                  style={{ color: 'red' }}
                  key={item.idx}
                >
                  {item.idx}. {item.message}
                </Typography>
              ))}
            </Box>
          </Box>
        )}

        {/* Structure position */}
        {structurePosition?.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1" className={classes.title}>
              Structure position
            </Typography>
            <Box display="flex" flexDirection="column">
              {structurePosition?.map((item) => (
                <Box className={classes.title} key={item.idx} mb={2}>
                  <Typography
                    className={classes.title333}
                    variant="subtitle2"
                  >
                    {item?.row_data?.user_email}
                  </Typography>
                  {item?.errors?.map((error, i) => (
                    <Box>
                      <Typography
                        key={i}
                        className={classes.title555}
                        variant="caption"
                      >
                        {i + 1}. {error.description}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {/* Structure position title */}
        {structurePositionTitle?.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1" className={classes.title}>
              Structure position title
            </Typography>
            <Box display="flex" flexDirection="column">
              {structurePositionTitle?.map((item) => (
                <Typography
                  variant="caption"
                  className={classes.title}
                  style={{ color: 'red' }}
                  key={item.idx}
                >
                  {item.idx}. {item.message}
                </Typography>
              ))}
            </Box>
          </Box>
        )}

        {/* Users */}
        {users?.length > 0 && (
          <Box mt={2}>
            <Typography variant="subtitle1" className={classes.title}>
              User
            </Typography>
            <Box display="flex" flexDirection="column">
              {users?.map((item) => (
                <Typography
                  variant="caption"
                  className={classes.title}
                  style={{ color: 'red' }}
                  key={item.idx}
                >
                  {item.idx}. {item.message}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions
        style={{
          alignItems: 'center',
          margin: 15,
          marginTop: 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={toggleBreak}
                onChange={handleToggleBreak}
              />
            }
            label={
              <Typography
                variant="subtitle2"
                className={classes.title555}
              >
                Break whole process
              </Typography>
            }
          />
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            size="medium"
            className={classes.button}
            onClick={
              loading
                ? null
                : () => {
                    setOpenImportStructure(false);
                    setMasterStructureUnit([]);
                    setStructurePosition([]);
                    setStructurePositionTitle([]);
                    setUsers([]);
                  }
            }
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            variant={
              loading || file === null ? 'contained' : 'outlined'
            }
            size="medium"
            disabled={loading || file === null}
            className={classes.button1}
            onClick={handleImportStructure}
          >
            {loading ? 'Importing...' : 'Import'}
          </Button>
        </Box>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogImportStructure;
