import React, { useEffect } from "react";
import { styles } from "../Style/StyleHistoryClockInOut";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid,
  Avatar,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const DetailDialog = ({ handleClose, open, data }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = JSON.parse(localStorage.getItem("status_user_login"));
  const [userImage, setUserImage] = React.useState(null);

  useEffect(() => {
    axiosConfig
      .get(
        `${URL_API}/account-management/member/${userData.member_id}/photo?mode=thumbnail`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const image = new Blob([res.data], { type: "image/jpeg" });
        const imageUrl = URL.createObjectURL(image);
        setUserImage(imageUrl);
      });
  }, [data]);

  const countWorkDuration = (clockIn, clockOut) => {
    const clockInTime = moment(clockIn, "HH:mm:ss");
    const clockOutTime = moment(clockOut, "HH:mm:ss");
    const duration = moment.duration(clockOutTime.diff(clockInTime));
    console.log(duration);
    const hours = duration.asHours();
    const minutes = duration.asMinutes();
    const seconds = duration.asSeconds();

    const hoursString = hours.toString().split(".")[0];
    const minutesString = minutes.toString().split(".")[0];
    const secondsString = seconds.toString().split(".")[0];

    if (hoursString === "0") {
      return `${minutesString} menit`;
    } else if (minutesString === "0") {
      return `${secondsString} detik`;
    }

    if (clockIn == "-" || clockOut == "-") {
      return "0 jam 0 menit";
    } else {
      return `${hoursString} jam ${minutesString} menit`;
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}></Grid>
          </Grid>

          <Avatar
            src={userImage}
            style={{
              width: 100,
              height: 100,
              margin: "auto",
              marginTop: 20,
              marginBottom: 20,
              alignSelf: "center",
            }}
          ></Avatar>

          <Typography
            variant="subtitle1"
            style={{
              textAlign: "center",
            }}
            gutterBottom
          >
            {userData.member_first_name + " " + userData.member_last_name}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              textAlign: "center",
            }}
            gutterBottom
          >
            {userData?.member_position?.structure_position_title_name}
          </Typography>

          <Typography
            variant="subtitle2"
            style={{
              color: "#262261",
              margxinTop: "2rem",
              textAlign: "center",
            }}
            decoration="bold"
          >
            Jadwal {data.date}
          </Typography>
          <Typography
            variant="h6"
            style={{ color: "#262261", textAlign: "center" }}
            decoration="bold"
          >
            {data.verificationMethod?.shift !== null
              ? data.verificationMethod?.shift.schedule_in
              : "-"}{" "}
            -{" "}
            {data.verificationMethod?.shift !== null
              ? data.verificationMethod?.shift.schedule_out
              : "-"}
          </Typography>

          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Clock In : {data.clock_in}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Clock In Status : <b>{data.clock_in_status}</b>
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Clock In Remark : <b>{data.clock_in_remark}</b>
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Deskripsi Clock In : <b>{data.deskripsi_clock_in}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Clock Out : {data.clock_out}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Clock Out Status : <b>{data.clock_out_status}</b>
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Clock Out Remark : <b>{data.clock_out_remark}</b>
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                }}
              >
                Deskripsi Clock Out : <b>{data.deskripsi_clock_out}</b>
              </Typography>
            </Grid>
          </Grid>

          <Typography
            variant="body1"
            style={{ color: "#262261", textAlign: "center" }}
            decoration="bold"
          >
            {/* Durasi Kerja : {countWorkDuration(data.clock_in, data.clock_out)} */}
            Durasi Kerja : {data.duration ? data.duration.replace("hours", "jam").replace("minutes", "menit") : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            style={{
              backgroundColor: "#262261",
              color: "#ffffff",
              height: 60,
              marginTop: 20,
            }}
            onClick={handleClose}
          >
            <b>OK</b>
          </Button>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default DetailDialog;
