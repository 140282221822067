import React from "react";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiTableHead-root": {
      //   boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
      backgroundColor: "#f6f6f6",
    },
  },
  titleHead: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "600",
    color: "#555",
    fontSize: 14,
    "&.MuiTableCell-root": {
      borderBottom: "none",
    },
  },
}));

const ListHeaderGoalResult = () => {
  const classes = useStyles();

  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell
          align="left"
          className={classes.titleHead}
          // style={{
          //   width: 120,
          // }}
        >
          Goal Result
        </TableCell>
        <TableCell align="left" className={classes.titleHead}>
          Keterangan
        </TableCell>
        <TableCell align="left" className={classes.titleHead}>
          Data & Time
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            width: 70,
          }}
        >
          Evidence
        </TableCell>
        <TableCell align="right" className={classes.titleHead} />
      </TableRow>
    </TableHead>
  );
};

export default ListHeaderGoalResult;
