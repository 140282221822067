import React, { Fragment, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import InfoIcon from "@material-ui/icons/Info";

import ContextGoalDetail from "../Context/ContextGoalDetail";

function DialogFeedbackSetAsCompleteSubGoal({
  classes,
  openDialogFeedbackSetAsComplete,
  setOpenDialogFeedbackSetAsComplete,
  responseSetAsComplete,
  listGoal,
  setOpenDialogSetAsCompleteMultiple,
}) {
  const context = useContext(ContextGoalDetail);

  // Take Succes or Failed Array
  const completeItem = responseSetAsComplete.map((item) => item.data.data);

  //   Take ID and Name
  const goalItem = listGoal.map((item) => item.data.data.name);

  //   Handle Close Dialog And Refresh collection of Goal
  const closeDialogUpdateListGoal = () => {
    context.setSuccessSetAsComplete(Math.floor(Math.random() * 1000 + 1));
    setOpenDialogFeedbackSetAsComplete(false);
    setOpenDialogSetAsCompleteMultiple(false);
  };
  return (
    <Fragment>
      <Dialog
        maxWidth="sm"
        open={openDialogFeedbackSetAsComplete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title-422"
          style={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start">
            <span></span>
            <InfoIcon style={{ fontSize: 36, color: "#FFAC9C" }} />
            <IconButton size="small" onClick={closeDialogUpdateListGoal}>
              <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <List>
              {goalItem.length > 0 &&
                goalItem.map((goal, idx) => (
                  <ListItem>
                    <ListItemText
                      primary={
                        <span
                          style={{
                            color: "#333",
                            fontStyle: "normal",
                          }}>
                          <b>{goal}</b>
                        </span>
                      }
                      secondary={
                        <span
                          style={{
                            fontStyle: "normal",
                          }}>
                          <ul>
                            {completeItem[idx] &&
                              completeItem[idx].failed.length > 0 &&
                              completeItem[
                                idx
                              ].failed[0].errors.map((error) => (
                                <li style={{ color: "#d1354a" }}>
                                  {error.description}
                                </li>
                              ))}

                            {completeItem[idx] &&
                              completeItem[idx].success.length > 0 && (
                                <li style={{ color: "#1EBC61" }}>Berhasil!</li>
                              )}
                          </ul>
                        </span>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: "center",
            marginBottom: 15,
          }}>
          <Button
            onClick={closeDialogUpdateListGoal}
            variant="contained"
            className={classes.buttonModalDelete}
            style={{ paddingRight: 25, paddingLeft: 25 }}>
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default DialogFeedbackSetAsCompleteSubGoal;
