import React, { useState } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const CustomPhase = ({
  classes,
  customPhaseId,
  setCustomPhaseId,
  phaseCollection,
}) => {
  const [togglePhase, setTogglePhase] = useState(true);

  const handleCustomPhase = (e) => setCustomPhaseId(e.target.value);

  //   // console.log("phaseCollection", phaseCollection);

  return (
    <Paper className={classes.dataArea}>
      <Box p={2} pt={1} display="flex" alignItems="center">
        <Typography
          variant="subtitile2"
          className={classes.title333}
          style={{ marginRight: 5 }}
        >
          <b>Custom Phase</b>
        </Typography>
        {togglePhase ? (
          <IconButton size="small" onClick={() => setTogglePhase(!togglePhase)}>
            <KeyboardArrowUpIcon style={{ color: "#C8203C" }} />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={() => setTogglePhase(!togglePhase)}>
            <KeyboardArrowDownIcon style={{ color: "#C8203C" }} />
          </IconButton>
        )}
      </Box>

      {togglePhase && (
        <Box p={2} pt={0}>
          <Select
            variant="outlined"
            fullWidth
            placeholder="Select status"
            value={customPhaseId}
            style={{ height: 40 }}
            onChange={handleCustomPhase}
            IconComponent={KeyboardArrowDownIcon}
            inputProps={{
              classes: {
                icon: classes.iconSelect,
              },
            }}
          >
            {phaseCollection.length > 0 ? (
              phaseCollection.map((item, i) => {
                return (
                  <MenuItem key={i} value={item.id}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title555}
                      style={{ marginLeft: 8 }}
                    >
                      {item.title}
                    </Typography>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>Custom phase is empty</MenuItem>
            )}
          </Select>
        </Box>
      )}
    </Paper>
  );
};

export default CustomPhase;
