import React, { Fragment, useCallback, useRef, useState } from "react";
import {
  Box,
	IconButton,
	InputAdornment,
	InputBase,
  Typography,
} from "@material-ui/core";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";

import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PictREFRESH from "../../../../assets/images/Group_2725.png";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";
import { Button } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce/lib";
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

function TableFilter({
  classes,
  context,
  setLoader,
  dataCategory,
  dataUserList,
  setDataUserList,
  getDetailTimeOffCategory,
}) {
  const [locale, setLocale] = useState("id");
  let inputRefSearch = useRef(null);

  const [handleSearch] = useDebouncedCallback(
    useCallback((value) => {
      handleRefreshToFirstPage()
    }, []),
    1000,

    { maxWait: 5000 }
  );

  const handleRefresh = () => {
    getDetailTimeOffCategory();
  }

  const handleRefreshToFirstPage = () => {
    let dataTemp = dataUserList;
    dataTemp.options.paging.offset = 0;
    dataTemp.options.paging.page = 1;
    setDataUserList({...dataTemp});
    getDetailTimeOffCategory();
  }

  const filterDataUser = async () => {
    context.setModalProgress(true);
    setLoader(true);
    let dataTemp = dataUserList;
    let listTemp = [];
    await dataUserList.list.forEach((item) => {
      if (dataUserList.options.filter.search) {
        if (item.user && item.user.member) {
          if (
            (
              item.user.member.first_name &&
              item.user.member.first_name.toLowerCase().includes(dataUserList.options.filter.search)
            ) ||
            (
              item.user.member.last_name &&
              item.user.member.last_name.toLowerCase().includes(dataUserList.options.filter.search)
            ) || 
            (
              item.user.member.first_name &&
              item.user.member.last_name &&
              (item.user.member.first_name + ' ' + item.user.member.last_name).toLowerCase().includes(dataUserList.options.filter.search)
            )
          ) {
            listTemp.push(item);
          }
        }
      } else {
        listTemp.push(item);
      }
    })
    dataTemp.listTemp = listTemp;
    dataTemp.options.paging.count = listTemp.length;
    setDataUserList({...dataTemp});
    setLoader(false);
    context.setModalProgress(false);
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      pr={3}
    >
      <Box display="flex" alignItems="center">
        <Typography
          className={classes.title333}
          variant="h6"
          id="tableTitle"
          component="div">
          List User Time Off {dataCategory.name}
        </Typography>
        <Box mx={1} />
      </Box>

      <Box 
        display="flex"
        justifyContent="space-between" 
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <div className={classes.search} style={{marginRight: "0px"}}>
            <InputBase
              startAdornment={
                <SearchIcon
                  style={{ color: "#C4C4C4", fontSize: 18, marginLeft: 12 }}
                />
              }
              endAdornment={
                <IconButton
                  size="small"
                  onClick={() => {
                    let dataListTemporary = dataUserList;
                    dataListTemporary.options.filter.search = "";
                    dataListTemporary.options.paging.offset = 0;
                    dataListTemporary.options.paging.page = 1;
                    setDataUserList({...dataListTemporary});
                    filterDataUser();
                  }}
                >
                  <CancelIcon
                    style={{
                      color: "#C4C4C4",
                      fontSize: 18,
                      marginRight: 12,
                    }}
                  />
                </IconButton>
              }
              onChange={(e) => {
                let dataListTemporary = dataUserList;
                dataListTemporary.options.filter.search = e.target.value;
                dataListTemporary.options.paging.offset = 0;
                dataListTemporary.options.paging.page = 1;
                setDataUserList({...dataListTemporary});
                filterDataUser();
              }}
              value={dataUserList.options.filter.search}
              placeholder="Cari Data User..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "Search" }}
            />
          </div>
          <Box mx={1} />
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton style={{ padding: 4 }} onClick={handleRefresh}>
            <img
              src={PictREFRESH}
              style={{ width: 17, height: 17 }}
              alt="."
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default TableFilter;
