import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Grid,
  Paper,
  Divider,
  Radio,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import PersonIcon from "@material-ui/icons/Person";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ClearIcon from "@material-ui/icons/Clear";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import {
  ACCOUNT_TYPE_BISNIS,
  ACCOUNT_TYPE_PERSONAL,
  DEPLOYMENT,
  PACKAGE_PERSONAL,
  PMO_VISIT,
  STATUS_BANK_AVAILABLE,
  USER_LICENSE,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_PENDING,
} from "../../../MembershipManagement/constant-data";

import bank_bca from "../../../../../assets/images/bank-bca.png";
import bank_bni from "../../../../../assets/images/bank-bni.png";
import bank_bri from "../../../../../assets/images/bank-bri.png";
import bank_permata from "../../../../../assets/images/bank-permata.png";
import bank_mandiri from "../../../../../assets/images/bank_mandiri.png";

import Card3Items from "./Card3Items";
import DialogError from "../../../../../components/DialogError";
import DialogPayFromTrial from "./DialogPayFromTrial";
import DialogPaidTrial from "./DialogPaidTrial";
import { FormatThousand } from "../../../../../utilities/FormatDecimal";

const DialogCheckoutUpdate = ({
  classes,
  openDialogCheckoutUpdate,
  setOpenDialogCheckoutUpdate,
  setOpenDialogUpdate,
  paymentCollections,
  calculation,
  packageId,
  duration,
  userLicense,
  pmoVisit,
  deployment,
  accType,
  pckgName,
  onVoucher,
  voucherErrorsList,
  statusRes,
  statusMember,
  differenceQtyPmo,
  differenceQtyUser,
  origin,
}) => {
  const userData = localStorage.getItem("status_user_login");
  const user = JSON.parse(userData);
  const groupName = user.membership_account_type_name;

  // ====================================================
  // STATE COLLECTIONS
  // ====================================================
  const [codeVoucher, setCodeVoucher] = useState("");
  const [selectedPayment, setSelectedPayment] = useState({});
  const [payments, setPayments] = useState({});
  const [loading, setLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState({});
  const [openDialogPayFromTrial, setOpenDialogPayFromTrial] = useState(false);
  const [openDialogPaidTrial, setOpenDialogPaidTrial] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ====================================================
  // SIDE EFFECTS
  // ====================================================

  // ====================================================
  // FUNC COLLECTIONS
  // ====================================================
  const handleChangePayment = (data) => {
    // // console.log("Selected Payment", data);
    setSelectedPayment(data);
  };

  const handleSetVoucher = () => {
    onVoucher(codeVoucher);
  };

  const handleClearVoucher = () => {
    setCodeVoucher("");
    onVoucher("");
  };

  const handleUpdateMembership = () => {
    setLoading(true);

    const dataUpdate = new URLSearchParams();

    if (accType === "Business") {
      dataUpdate.append(
        "Membership[membership_account_type_id]",
        ACCOUNT_TYPE_BISNIS
      );
      dataUpdate.append("Membership[membership_package_id]", packageId);
      dataUpdate.append("Membership[membership_duration_value]", duration);
      dataUpdate.append(
        "Membership[membership_payment_method_id]",
        selectedPayment.id
      );
      dataUpdate.append("Membership[membership_item][0][id]", USER_LICENSE);
      dataUpdate.append(
        "Membership[membership_item][0][quantity]",
        userLicense
      );
      dataUpdate.append("Membership[membership_item][1][id]", PMO_VISIT);
      dataUpdate.append("Membership[membership_item][1][quantity]", pmoVisit);
      dataUpdate.append("Membership[membership_item][2][id]", DEPLOYMENT);
      dataUpdate.append("Membership[membership_item][2][quantity]", deployment);
      dataUpdate.append("Membership[membership_voucher_code]", codeVoucher);
      dataUpdate.append("recalculate", "update");
    } else if (accType === "Personal") {
      dataUpdate.append(
        "Membership[membership_account_type_id]",
        ACCOUNT_TYPE_PERSONAL
      );
      dataUpdate.append("Membership[membership_voucher_code]", codeVoucher);
      dataUpdate.append("Membership[membership_package_id]", PACKAGE_PERSONAL);
      dataUpdate.append("Membership[membership_duration_value]", duration);
      dataUpdate.append(
        "Membership[membership_payment_method_id]",
        selectedPayment.id
      );
      dataUpdate.append("Membership[membership_item][0][id]", USER_LICENSE);
      dataUpdate.append(
        "Membership[membership_item][0][quantity]",
        userLicense
      );
      dataUpdate.append("recalculate", "update");
      if (pmoVisit >= 1) {
        dataUpdate.append("Membership[membership_item][1][id]", PMO_VISIT);
        dataUpdate.append("Membership[membership_item][1][quantity]", pmoVisit);
      }
      if (deployment >= 1) {
        dataUpdate.append("Membership[membership_item][2][id]", DEPLOYMENT);
        dataUpdate.append(
          "Membership[membership_item][2][quantity]",
          deployment
        );
      }
    }

    if (origin === "update-extend") {
      Axios.put(
        `${URL_API}/account-management/membership:update-and-extend`,
        dataUpdate
      )
        .then((res) => {
          // console.log("Ext n Upd", res);
          const result = res.data.data;

          if (res.status === 200) {
            Axios.get(
              `${URL_API}/account-management/membership-payment/check-status`
            ).then((response) => {
              // // console.log("Res Status Payment", response);
              const result2 = response.data.data;

              if (response.status === 200) {
                if (result2.status_id === PAYMENT_STATUS_PENDING) {
                  setCheckoutData(result2);
                  setOpenDialogPayFromTrial(true);
                }

                if (result2.status_id === PAYMENT_STATUS_PAID) {
                  setCheckoutData(result2);
                  setOpenDialogPaidTrial(true);
                }
              }
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Err Put Extnd", error.response);
          setLoading(false);
          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    } else if (origin === "update-only") {
      Axios.put(`${URL_API}/account-management/membership`, dataUpdate)
        .then((res) => {
          // console.log("Upd Only", res);
          const result = res.data.data;

          if (res.status === 200) {
            setCheckoutData(result);
            setOpenDialogPayFromTrial(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("Err Put Update", error.response);
          // console.log("GAGAL Update", error);
          setLoading(false);
          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  };

  const renderPayments =
    paymentCollections.length > 0 &&
    paymentCollections
      .filter((bank) => bank.status_id === STATUS_BANK_AVAILABLE)
      .map((payment) => {
        const isCurrent = selectedPayment.name === payment.name;
        let img_url;

        if (payment.name === "BCA") {
          img_url = bank_bca;
        } else if (payment.name === "BNI") {
          img_url = bank_bni;
        } else if (payment.name === "BRI") {
          img_url = bank_bri;
        } else if (payment.name === "Permata") {
          img_url = bank_permata;
        } else if (payment.name === "Mandiri") {
          img_url = bank_mandiri;
        }

        return (
          <label key={payment.id}>
            <Paper
              variant={isCurrent ? "elevation" : "outlined"}
              elevation={3}
              style={{
                border: isCurrent && "1px solid #d1354a",
                backgroundColor: isCurrent && "#f9e5e6",
              }}
              className={classes.paymentCard}
            >
              <img src={img_url} height="20" />
            </Paper>
            <Radio
              onChange={() => handleChangePayment(payment)}
              checked={selectedPayment.name === payment.name}
              style={{
                visibility: "hidden",
                height: 0,
                width: 0,
                opacity: 0,
              }}
            />
          </label>
        );
      });

  return (
    <Dialog
      open={openDialogCheckoutUpdate}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            size="small"
            onClick={() => setOpenDialogCheckoutUpdate(false)}
          >
            <HighlightOffRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 1000, marginBottom: 50 }}>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Typography variant="h6" className={classes.title333}>
              <b>Periksa pesanan anda</b>
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#555", marginTop: 5 }}
            >
              Harap periksa kembali pesanan anda, pastikan anda memilih durasi
              membership sesuai dengan kebutuhan anda
            </Typography>

            <Box marginTop={2}>
              <Card3Items
                classes={classes}
                account={accType}
                pckgName={pckgName}
                prop1="User"
                prop2="Consultant Visit"
                prop3="Durasi yang Ditambah"
                val1={userLicense}
                val2={pmoVisit}
                val3={statusMember === "Lapsed" ? duration : 0}
                icon1={
                  <PersonIcon fontSize="small" style={{ color: "#aaa" }} />
                }
                icon2={
                  <ConfirmationNumberIcon
                    fontSize="small"
                    style={{ color: "#aaa" }}
                  />
                }
                icon3={
                  <WatchLaterIcon fontSize="small" style={{ color: "#aaa" }} />
                }
              />
            </Box>

            <Box marginTop={5} marginBottom={2}>
              <Typography variant="subtitle1" className={classes.title333}>
                <b>Metode Pembayaran</b>
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                Virtual Account (Di cek otomatis)
              </Typography>
            </Box>

            <Box width={300} display="flex" justifyContent="space-between">
              {renderPayments}
            </Box>
          </Grid>
          <Grid item md={4}>
            <Paper variant="outlined">
              <Box padding={2}>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ marginBottom: 5 }}
                >
                  <b>Perubahan paket</b>
                </Typography>

                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item md={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Tambahan durasi
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Lisensi user
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Consultant visit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Harga
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : {statusMember === "Lapsed" ? duration : 0} Bulan
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                      style={{
                        color:
                          differenceQtyUser === 0
                            ? "#555"
                            : differenceQtyUser < 0
                            ? "#EA2027"
                            : "#009432",
                      }}
                    >
                      :{" "}
                      {differenceQtyUser > 0
                        ? `+${differenceQtyUser}`
                        : differenceQtyUser}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                      style={{
                        color:
                          differenceQtyPmo === 0
                            ? "#555"
                            : differenceQtyPmo < 0
                            ? "#EA2027"
                            : "#009432",
                      }}
                    >
                      :{" "}
                      {differenceQtyPmo > 0
                        ? `+${differenceQtyPmo}`
                        : differenceQtyPmo}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : Rp {FormatThousand(calculation.total_item_price)}
                    </Typography>
                  </Grid>
                </Grid>

                <Box marginTop={3} marginBottom={3.5}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ marginBottom: 5 }}
                  >
                    <b>Voucher</b>
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ width: 200 }}
                      value={codeVoucher}
                      onChange={(e) => setCodeVoucher(e.target.value)}
                      // disabled={calculation.total_item_price_discount !== 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={handleClearVoucher}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      size="small"
                      className={classes.btnVoucher}
                      // disabled={calculation.total_item_price_discount !== 0}
                      onClick={handleSetVoucher}
                    >
                      Pakai
                    </Button>
                  </Box>
                  {calculation !== false &&
                  calculation.total_item_price_discount !== 0 ? (
                    <span
                      style={{ color: "green", fontSize: 13, marginTop: 5 }}
                      className={classes.title}
                    >
                      Code Voucher Valid!
                    </span>
                  ) : statusRes === 400 ? (
                    <ul className={classes.voucherErr}>
                      {voucherErrorsList !== undefined &&
                        voucherErrorsList.length > 0 &&
                        voucherErrorsList.map((err, i) => (
                          <li key={i} className={classes.voucherErrItem}>
                            {err.description}
                          </li>
                        ))}
                    </ul>
                  ) : null}
                </Box>

                <Box marginBottom={2.5}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ marginBottom: 5 }}
                  >
                    <b>Pembayaran</b>
                  </Typography>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        Total harga
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        Discount (%)
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        : Rp{" "}
                        {FormatThousand(
                          calculation !== null || calculation !== undefined
                            ? calculation.total_item_price
                            : "-"
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        : Rp{" "}
                        {FormatThousand(calculation.total_item_price_discount)}{" "}
                        (
                        {calculation.voucher !== undefined &&
                          calculation.voucher !== null &&
                          calculation.voucher.value}
                        %)
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Divider variant="fullWidth" />

                <Box marginTop={1.5}>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        <b>Total Pembayaran</b>
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        <b>
                          : Rp{" "}
                          {new Intl.NumberFormat("id-ID").format(
                            calculation.total_item_price_after_discount
                          )}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Button
                  className={classes.button2}
                  fullWidth
                  variant="contained"
                  style={{ marginTop: 20 }}
                  disabled={
                    selectedPayment.name === undefined || statusRes === 400
                  }
                  onClick={handleUpdateMembership}
                >
                  {loading ? (
                    <CircularProgress color="#fff" size={15} />
                  ) : (
                    "Bayar"
                  )}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogPayFromTrial
        classes={classes}
        openDialogPayFromTrial={openDialogPayFromTrial}
        setOpenDialogPayFromTrial={setOpenDialogPayFromTrial}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutUpdate}
        setOpenDialogPackage={setOpenDialogUpdate}
        checkoutData={checkoutData}
        paket={accType}
        membership={pckgName}
        duration={duration}
        totalUser={userLicense}
        totalPmo={pmoVisit}
        totalDeployment={deployment}
      />

      <DialogPaidTrial
        classes={classes}
        openDialogPaidTrial={openDialogPaidTrial}
        setOpenDialogPaidTrial={setOpenDialogPaidTrial}
        setOpenDialogPayment={setOpenDialogPayFromTrial}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutUpdate}
        setOpenDialogPackage={setOpenDialogUpdate}
        dataPay={checkoutData}
        paket={accType}
        membership={pckgName}
        duration={duration}
        totalUser={userLicense}
        totalDeployment={deployment}
        totalPmo={pmoVisit}
      />
    </Dialog>
  );
};

export default DialogCheckoutUpdate;
