function TruncateNumberCustom(num, long) {
  const value = Number(num);
  const str = String(value);
  if (num !== null && num !== undefined) {
    if (str.length > long) return str.substring(0, long) + "...";
    else return value;
  }
}

export default TruncateNumberCustom;
