import React, { useContext } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Tooltip,
} from "@material-ui/core";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";

import { navigate } from "hookrouter";

import ContextGlobalDrive from "../../context/ContextDrive";
import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";

function UserGridGallery({ classes, item }) {
  const context = useContext(ContextGlobalDrive);

  const handleNavigateGallery = (data) => {
    let breadcrumb = localStorage.getItem("breadcrumb");
    breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
    breadcrumb.push(`${item.member_first_name} ${item.member_last_name}`);
    localStorage.setItem("breadcrumb", breadcrumb.toString());

    let directory_id = localStorage.getItem("directory_id");
    directory_id = directory_id ? directory_id.split("/") : [];
    directory_id.push(data.id);
    localStorage.setItem("directory_id", directory_id.toString());

    navigate(`/drive/${data.id}`);
  };

  return (
    <Grid item md={3}>
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : -20,
          backgroundColor: "#fffee0",
        }}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#ffbe76" }}>
              <PersonRoundedIcon style={{ color: "#e67e22" }} />
            </Avatar>
          }
          title={
            <Tooltip
              title={`${item.member_first_name} ${item.member_last_name}`}>
              <Typography
                className={classes.folderNameText}
                variant="subtitle2"
                onClick={() => handleNavigateGallery(item)}>
                {TruncateTextShortNameGoal(
                  item.member_first_name === null
                    ? "Vacant"
                    : `${item.member_first_name} ${item.member_last_name}`
                )}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Tooltip title={item.structure_position_title_name}>
              <Typography style={{ color: "#999", fontSize: 13 }}>
                {TruncateTextCustom(item.structure_position_title_name, 28)}
              </Typography>
            </Tooltip>
          }
        />
      </Card>
    </Grid>
  );
}

export default UserGridGallery;
