import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Container } from "@material-ui/core";

import styles from "./Style/landingStyle";

import HeaderNew from "./ComponentViewLandingPage/home/HeaderNew";
import NavRight from "./ComponentViewLandingPage/NaviRight";
import NavLeft from "./ComponentViewLandingPage/NavLeft";
import TouchUs from "./ComponentViewLandingPage/home/TouchUs";
import Footer from "./ComponentViewLandingPage/Footer";
import UserTesti from "./ComponentViewLandingPage/testimoni/UserTesti";
// import OtherTesti from "./ComponentViewLandingPage/testimoni/OtherTesti";
// import NewTesti from "./ComponentViewLandingPage/testimoni/NewTesti";
import FloatingWhatsapp from "./ComponentViewLandingPage/components/FloatingWhatsapp";

import bgTouch from "../../assets/images/bgTouch.png";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const ViewTestimoni = () => {
  const classes = useStyles();

  return (
    <div>
      <HeaderNew
        color="transparent"
        routes={dashboardRoutes}
        brand="Performate"
        rightLinks={<NavRight origin="2" />}
        leftLinks={<NavLeft origin="2" />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white",
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={20} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            className={classes.title}
            style={{ color: "#333" }}>
            <b>
              Bergabung bersama ratusan manager, team leader, dan business owner
            </b>
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.heroDesc}
            style={{ color: "#333" }}>
            apa kata mereka tentang performate ?
          </Typography>
        </Box>
      </Box>

      <UserTesti />
      {/* <NewTesti /> */}

      <Box
        style={{
          backgroundImage: "url(" + bgTouch + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}>
        <Container>
          <TouchUs />
        </Container>
      </Box>

      <FloatingWhatsapp />

      <Footer />
    </div>
  );
};

export default ViewTestimoni;
