import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";

function EnhancedTableHead({
  classes,
  numSelected,
  rowCount,
  onSelectAllClick,
}) {
  return (
    <Paper className={classes.shadowSectionLight} style={{marginTop: "24px", marginRight: "24px"}}>
      <Box
        p={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box width={50} ml={2}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>No</b>
            </Typography>
          </Box>
          <Box ml={2} width={350}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>User</b>
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" className={classes.boxTable}>
          <Box width={"40%"}>
            <Typography variant="subtitle2" className={classes.title333} style={{textAlign: "left"}}>
              <b>Allowance</b>
            </Typography>
          </Box>
          <Box width={"40%"}>
            <Typography variant="subtitle2" className={classes.title333} style={{textAlign: "left"}}>
              <b>Remaining</b>
            </Typography>
          </Box>
          <Box width={"20%"} />
        </Box>
      </Box>
    </Paper>
  );
}

export default EnhancedTableHead;
