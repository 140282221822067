import { createMuiTheme, fade } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Nunito"].join(","),
  },
});

export const styles = () => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
    background: "#fff",
    height: "200vh",
  },
  layoutMarginLeftAndTop: {
    marginLeft: 95,
    marginTop: 65,
  },
  layoutMarginLeft: {
    marginLeft: 95,
  },
  // Typogrtaphy
  title: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
  },
  title333: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
    color: "#333",
  },
  title555: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
    color: "#555",
  },
  title888: {
    // fontFamily: "Roboto",
    fontWeight: "normal",
    color: "#888",
  },
  btnGrey: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    color: "#333",
    textTransform: "capitalize",
    padding: "2px 12px",
    backgroundColor: "#DFDFDF",
    "&:hover": {
      backgroundColor: "#B1B1B1",
    },
  },

  // CARD ARCHIVE
  cardArchiveUser: {
    // boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
    borderRadius: 10,
    marginBottom: 18,
    // border: "1px solid #aaa",
  },
  cardHead: {
    border: "1px solid #aaa",
    borderRadius: 10,
  },
  showDetail: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
});
