import React, { useState, useReducer, useEffect, useRef } from "react";
import axios from "axios";
// import { URL_API } from '../../../../constants/config-api';

import ContextAttendanceListRequest from "./ContextAttendanceListRequest";

const ContextGlobalTimeOffCategoryAllowance = (props) => {
  /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
  const [userTokenState, setUserTokenState] = useState("");

  const [isModalDetail, setModalDetail] = useState(false);

  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    setUserTokenState(userToken);
  }, []);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isSnackbarResponse200, setSnackbarResponse200] = useState(false);
  const [messages, setMessages] = useState("");
  
  const [isModalCreate, setModalCreate] = useState(false);
  const [isModalEdit, setModalEdit] = useState(false);
  const [isModalDelete, setModalDelete] = useState(false);
  const [isModalProgress, setModalProgress] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [loader, setLoader] = useState(false);

  /*
  ````````````````````
  HANDLE DIALOG ERRROR
  ````````````````````
  */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);


  return (
    <ContextAttendanceListRequest.Provider
      value={{
        setUserTokenState: setUserTokenState,
        userTokenState: userTokenState,

        setSnackbarResponse200: setSnackbarResponse200,
        isSnackbarResponse200: isSnackbarResponse200,

        setMessages: setMessages,
        messages: messages,

        setAnchorEl: setAnchorEl,
        anchorEl: anchorEl,

        loader: loader,
        setLoader: setLoader,
        
        //*
        isModalCreate: isModalCreate,
        setModalCreate: setModalCreate,

        isModalEdit: isModalEdit,
        setModalEdit: setModalEdit,
        
        isModalDetail: isModalDetail,
        setModalDetail: setModalDetail,

        isModalDelete: isModalDelete,
        setModalDelete: setModalDelete,
        
        isModalProgress: isModalProgress,
        setModalProgress: setModalProgress,

        /*
        ````````````````````
        HANDLE DIALOG ERRROR
        ````````````````````
        */
        isOpenDialogError: isOpenDialogError,
        setOpenDialogError: setOpenDialogError,

        textErrorInformation: textErrorInformation,
        setTextErrorInformation: setTextErrorInformation,

        errorStatus: errorStatus,
        setErrorStatus: setErrorStatus,

        listError: listError,
        setListError: setListError,
      }}>
      {props.children}
    </ContextAttendanceListRequest.Provider>
  );
};

export default ContextGlobalTimeOffCategoryAllowance;
