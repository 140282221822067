import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

const DialogConfirmWeight = ({
  classes,
  openDialogConfirmWeight,
  setOpenDialogConfirmWeight,
  setModalContinue,
}) => {
  return (
    <Dialog
      open={openDialogConfirmWeight}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Konfirmasi Weight MA</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 350, textAlign: "center" }}>
        <Typography variant="subtitle2" className={classes.title888}>
          Jika anda menambah atau menghapus MA, anda harus kembali mengedit
          weight masing-masing MA
        </Typography>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
        }}>
        <Button
          variant="outlined"
          fullWidth
          size="medium"
          className={classes.btnGrey}
          onClick={() => setOpenDialogConfirmWeight(false)}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          fullWidth
          className={classes.btnGrey}
          onClick={() => {
            setModalContinue(true);
            setOpenDialogConfirmWeight(false);
          }}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmWeight;
