import React, { useState, useContext } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  Typography,
  TextField,
  Divider,
} from "@material-ui/core";

import classNames from "classnames";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";

const AnchorTaskGroup = ({ classes }) => {
  const { selectTask, setSelectTask, setTriggerTask } =
    useContext(ContextProject);
  const project_id = window.location.pathname.split("/")[2];

  const [anchorEl, setAnchorEl] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleGroupName = (e) => setGroupName(e.target.value);

  const handleCreateGroup = () => {
    setLoading(true);

    const data = {
      name: groupName,
      todos: selectTask,
    };

    AxiosConfig.post(`${URL_API}/project/${project_id}/task-group`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setLoading(false);
          handleClose();
          setSelectTask([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        className={classes.btnMultiTask}
        onClick={handleShow}
      >
        Group
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                <Box p={2} width={300}>
                  <Typography variant="subtitle2" className={classes.title555}>
                    <b>Group Selected Task</b>
                  </Typography>
                  <Divider />

                  <Box mt={3}>
                    <Typography variant="caption" className={classes.title555}>
                      Name your group of the task you select
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="New Group..."
                      onChange={handleGroupName}
                    />
                  </Box>

                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.button}
                      style={{ fontSize: 12 }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Box mx={0.5} />
                    <Button
                      size="small"
                      className={classes.button1}
                      variant={
                        groupName === ""
                          ? "contained"
                          : loading
                          ? "contained"
                          : "outlined"
                      }
                      disabled={
                        groupName === "" ? true : loading ? true : false
                      }
                      style={{ fontSize: 12 }}
                      onClick={handleCreateGroup}
                    >
                      {loading ? "Createing Group..." : "Group"}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default AnchorTaskGroup;
