import React from "react";
import { Grid } from "@material-ui/core";

import BarDominantGreenScenarioByZERO from "../../../../components/BarDominantGreenScenarioByZERO";
import BarDominantGreenScenarioFIRST from "../../../../components/BarDominantGreenScenarioFIRST";
import BarDominantGreenScenarioSECOND from "../../../../components/BarDominantGreenScenarioSECOND";
import BarDominantGreenScenarioTHIRD from "../../../../components/BarDominantGreenScenarioTHIRD";
import BarDominantRedScenarioFOURTH from "../../../../components/BarDominantRedScenarioFOURTH";
import BarDominantRedScenarioFOURTHPartB from "../../../../components/BarDominantRedScenarioFOURTH_partB";
import BarDominantRedScenarioFIFTH from "../../../../components/BarDominantRedScenarioFIFTH";
import BarDominantGreenScenarioSEVEN from "../../../../components/BarDominantGreenScenarioSEVEN";

const Bars = (props) => {
  const {
    classes,
    resultValueAchievement,
    expectedValueAchievement,
    goalDetailState,
  } = props;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      // zeroMinWidth
    >
      {/* 
                ``````````````````````````````````````````````````````````````````````````````````````````````````
                #SCENARIO-01 :

                - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &

                - "RESULT VALUE ACHIEVEMENT" KURANG DARI 100
                                                                
                ``````````````````````````````````````````````````````````````````````````````````````````````````
            */}

      {resultValueAchievement > expectedValueAchievement &&
        resultValueAchievement < 100 && (
          <BarDominantGreenScenarioFIRST
            goalDetailState={goalDetailState}
            resultValueAchievement={resultValueAchievement}
            expectedValueAchievement={expectedValueAchievement}
          />
        )}

      {/* 
                    ``````````````````````````````````````````````````````````````````````````````````````````````````
                    #SCENARIO-02 :

                    - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &

                    - "result_value_achievement" ===  100
                                                                
                    ``````````````````````````````````````````````````````````````````````````````````````````````````
                */}
      {resultValueAchievement > expectedValueAchievement &&
        resultValueAchievement === 100 && (
          <BarDominantGreenScenarioSECOND
            classes={classes}
            goalDetailState={goalDetailState}
            resultValueAchievement={resultValueAchievement}
            expectedValueAchievement={expectedValueAchievement}
          />
        )}

      {/* 
                    ``````````````````````````````````````````````````````````````````````````````````````````````````
                    #SCENARIO-03 :

                    - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &

                    - "result_value_achievement" > 100
                                                                
                    ``````````````````````````````````````````````````````````````````````````````````````````````````
                */}

      {resultValueAchievement > expectedValueAchievement &&
        resultValueAchievement > 100 && (
          <BarDominantGreenScenarioTHIRD
            classes={classes}
            goalDetailState={goalDetailState}
            resultValueAchievement={resultValueAchievement}
            expectedValueAchievement={expectedValueAchievement}
          />
        )}

      {/* 
                    ``````````````````````````````````````````````````````````````````````````````````````````````````
                    #SCENARIO-04 :

                    - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &

                    - "result_value_achievement" KURANG DARI 100

                    - "result_value_achievement" LEBIH DARI 0
                                                                
                    ``````````````````````````````````````````````````````````````````````````````````````````````````
                */}

      {expectedValueAchievement > resultValueAchievement &&
        resultValueAchievement < 100 &&
        resultValueAchievement > 0 && (
          <BarDominantRedScenarioFOURTH
            goalDetailState={goalDetailState}
            resultValueAchievement={resultValueAchievement}
            expectedValueAchievement={expectedValueAchievement}
          />
        )}

      {/* 
                ``````````````````````````````````````````````````````````````````````````````````````````````````
                #SCENARIO-04 PART-B :

                - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &

                - "result_value_achievement" LEBIH DARI 100

                - "expected_result_value_achievement" LEBIH DARI 100
                                                                
                ``````````````````````````````````````````````````````````````````````````````````````````````````
            */}

      {expectedValueAchievement > resultValueAchievement &&
        resultValueAchievement > 100 &&
        resultValueAchievement > 100 && (
          <BarDominantRedScenarioFOURTHPartB
            goalDetailState={goalDetailState}
            resultValueAchievement={resultValueAchievement}
            expectedValueAchievement={expectedValueAchievement}
          />
        )}

      {/* 
                ```````````````````````````````````````````````````````````````````````````````````
                #SCENARIO-05 :

                - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &

                - "result_value_achievement" KURANG DARI 0

                - "result_value_achievement" bernilai MINUS
                                                                
                ``````````````````````````````````````````````````````````````````````````````````````````````````
            */}

      {expectedValueAchievement > resultValueAchievement &&
        resultValueAchievement < 0 && (
          <BarDominantRedScenarioFIFTH
            goalDetailState={goalDetailState}
            resultValueAchievement={resultValueAchievement}
            expectedValueAchievement={expectedValueAchievement}
          />
        )}

      {/* 
                ``````````````````````````````````
                #SCENARIO-06 :

                - "result_value_achievement" === 0
                                                                
                ``````````````````````````````````
            */}

      {resultValueAchievement === 0 && (
        <BarDominantGreenScenarioByZERO
          goalDetailState={goalDetailState}
          resultValueAchievement={resultValueAchievement}
          expectedValueAchievement={expectedValueAchievement}
        />
      )}

      {/* 
                `````````````````````````````````````````````````````````````````````
                #SCENARIO-07 :

                - "result_value_achievement" === "expected_result_value_achievement"
                                                                
                ``````````````````````````````````````````````````````````````````````
            */}
      {resultValueAchievement === expectedValueAchievement &&
        resultValueAchievement !== 0 && (
          <BarDominantGreenScenarioSEVEN
            goalDetailState={goalDetailState}
            resultValueAchievement={resultValueAchievement}
            expectedValueAchievement={expectedValueAchievement}
          />
        )}
    </Grid>
  );
};

export default Bars;
