import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  DialogActions,
  CircularProgress,
  Radio,
  Tooltip
} from '@material-ui/core';
import {
  HelpOutline as HelpOutlineIcon,
  Flag as FlagIcon,
  Person as PersonIcon
} from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

/**
 ==================================================================================
 ~~ ALL IMPORTED COMPONENT GOES HERE ~~
 ==================================================================================
 */
import ContextAllMA from '../context/AllMaContext';
import { URL_API } from '../../../../constants/config-api';
import axiosConfig from '../../../../constants/config-axios';
import ImageSmallUser from '../../../../components/ImageSmallUser';
import {
  StyledMenu,
  StyledMenuItem
} from '../../../../components/StyledMenuDropdown';
import { theme } from '../style/StyleAllMA';
import DialogDateRange from '../components/DialogDateRange';
import ButtonIconPositionTitle from './ButtonIconPositionTitle';
import Capitalize from '../../../../utilities/Capitalize';
import LightTooltip from '../../../../components/LightTooltip';
import { PERCENTAGE } from '../../../../constants/config-status-goal';
import SnackbeerRED from '../../../../components/SnackbeerRED';
import ButtonMaHaveConnectedWithGoal from '../../MeasureActivities/Components/ButtonMaHaveConnectedWithGoal';

// FROM ASSETS
import AvatarDummy from '../../../../assets/images/Avatar_dummy.png';
import Snackbeer from '../../../../components/Snackbeer';
import DialogSetWeight from './DialogSetWeight2';

// LocalizedUtils || CLASS ||
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}
/**
 ==================================================================================
 ~~ DialogCreate || C O M P O N E N T || ~~
 ==================================================================================
 */
const DialogCreate = ({
  classes,
  isModalCreate,
  setModalCreate,
  userLoginName,
  memberPositionList,
  setCollectionMAList,
  collectionMAList
}) => {
  /**
   ==================================================================================
    ~~ ALL IMPORTANT VARIABLE GOES HERE ~~
   ==================================================================================
   */
  const context = useContext(ContextAllMA);
  let textInputReff = useRef(null);
  const userToken = localStorage.getItem('userToken');

  const startDateRange = localStorage.getItem('start_date');
  const endDateRange = localStorage.getItem('end_date');
  const fotoQuery = localStorage.getItem('fotoQuery');

  // METHOD INPUT / CALCULATION
  const SWD_MA_INPUT_METHOD_LAST_VALUE = 'swd_ma_input_method_last_value';
  const SWD_MA_INPUT_METHOD_INCREMENT = 'swd_ma_input_method_increment';
  const SWD_MA_CALCULATION_METHOD_SUM = 'swd_ma_calculation_method_sum';
  const SWD_MA_CALCULATION_METHOD_AVERAGE = 'swd_ma_calculation_method_average';
  const SWD_MA_CALCULATION_METHOD_LAST_VALUE =
    'swd_ma_calculation_method_last_value';
  const SWD_MA_ACHIEVEMENT_TYPE_MORE_ORIENTED =
    'swd_ma_achievement_type_more_oriented';
  const SWD_MA_ACHIEVEMENT_TYPE_LESS_ORIENTED =
    'swd_ma_achievement_type_less_oriented';

  /**
   ==================================================================================
    ~~ ALL LOCAL STATE GOES HERE ~~
   ==================================================================================
   */
  // const [fotoQuery, setFotoQuery] = useState(null);
  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);

  const [userTokenState, setUserTokenState] = useState('');
  const [positionList, setPositionList] = useState([]);
  const [calculationMethodList, setCalculationMethodList] = useState([]);
  const [inputMethodList, setInputMethodList] = useState([]);
  const [achievementTypeCollections, setAchievementTypeCollections] = useState(
    []
  );
  const [inputValueTypeList, setInputValueTypeList] = useState([]);
  const [targetTypeList, setTargetTypeList] = useState([]);

  const [periodCollections, setPeriodCollections] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [goalList, setGoalList] = useState([]);
  const [isShowPercent, setShowPercent] = useState(false);

  //   STATE FOR HANDLE CHANGE INPUT
  const [maTitle, setMATitle] = useState('');
  const [description, setDescription] = useState('');
  const [targetValue, setTargetValue] = useState('');
  const [startValue, setStartValue] = useState('0');

  //   STATE FOR MEMBER OWNER CHANGE INPUT (DROPDOWN)
  const [anchorElTimePosition, setAnchorElTimePosition] = useState(null);
  const [textValuePosition, setTextValuePosition] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [goalId, setGoalId] = useState('');
  const [choosedMemberOwner, setChoosedMemberOwner] = useState('');
  const [anchorElMAGoal, setAnchorElMAGoal] = useState(null);
  const [choosedMemberGoal, setChoosedMemberGoal] = useState('');

  //   STATE FORM TIME FRAME (DROPDOWN)
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);
  const [textValueTimeFrame, setTextValueTimeFrame] = useState('Time Frame');
  const [timeFrameId, setTimeFrameId] = useState(null);

  //   DUE SATE OPTION STATE
  const [selectedDueDate, setSelectedDueDate] = useState('');
  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [textEndDueDate, setTextEndDueDate] = useState('');
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);
  const [isShowTextDueDate, setShowTextDueDate] = useState(false);

  //   DATE RANGE OPTION STATE
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  //   PERIOD OPTION STATE
  const [periodId, setPeriodId] = useState('');
  const [periodName, setPeriodName] = useState('');
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false);
  const [isShowTextPeriod, setShowTextPeriod] = useState(false);
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);

  // CHOOSE OWNER FIRST BEFORE CHOOSE GOAL
  const [chooseOwnerFirst, setChooseOwnerFirst] = useState(true);

  // METHOD INPUT / CALCULATION STATE
  const [selectedValueCalcMethod, setSelectedValueCalcMethod] = useState({
    id: '1',
    code: SWD_MA_CALCULATION_METHOD_LAST_VALUE,
    name: 'last value'
  });

  const [selectedValueInputMethod, setSelectedValueInputMethod] = useState({
    id: '2',
    code: SWD_MA_INPUT_METHOD_INCREMENT,
    name: 'increment'
  });

  const [selectedValueAchievementType, setSelectedValueAchievementType] =
    useState({
      id: '1',
      code: SWD_MA_ACHIEVEMENT_TYPE_MORE_ORIENTED,
      name: 'more oriented'
    });

  const [selectedValueType, setSelectedValueType] = useState({
    id: '1',
    code: 'numeric',
    name: 'numeric'
  });

  const [targetType, setTargetType] = useState({
    code: 'swd_ma_target_type_daily',
    id: '1',
    name: 'Daily'
  });

  //   HANDLE DIALOG ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] = useState(
    'Anda tidak memiliki izin mengakses halaman ini !'
  );
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [isModalResponse400, setModalResponse400] = useState(false);
  const [errorDescription, setErrorDescription] = useState('');

  //   HANDLE DISABLE BUTTON SUBMIT MA ALL
  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  //   RESPONSE STACKBEER STATE
  const [isModalResponse200, setModalResponse200] = useState(false);
  // CREATE MA WIZARD MODE
  const [isModalCreateModeWizard, setModalCreateModeWizard] = useState(false);
  // SHOW TEXT FIELD TANGE
  const [isShowTextFieldTargetRangeValue, setShowTextFieldTargetRangeValue] =
    useState(false);

  //   TARGET VALUE RANGE
  const [targetValueRange, setTargetValueRange] = useState('');
  //   POSITION TITLE NAME
  const [positionTitleName, setPositionTitleName] = useState('');

  //   STATE WITH EVIDENCE OR NOT
  const [evidance, setEvidance] = useState(false);
  //   STATE NILAI TARGET
  const [nilaiTarget, setNilaiTarget] = useState('');

  // STATE SHOW MA CONNECTED WITH GOAL
  const [isMAConnectedWithGoal, setMAConnectedWithGoal] = useState(false);

  // SET WEIGHT
  const [openDialogSetWeight, setOpenDialogSetWeight] = useState(false);
  /**
   ==================================================================================
    ~~ ALL API CALL GOES HERE ~~
   ==================================================================================
   */

  //*Enable Dialog INFO and GET || owner_id ||
  useEffect(() => {
    if (isModalCreate === true) {
      setUserTokenState(userToken);
      setLoader(false);
      setDisabledButton(false);

      setStartValue('0');
      setNilaiTarget('');

      localStorage.removeItem('fotoQuery');

      if (userToken !== undefined || userToken !== null) {
        setLoader(true);

        axiosConfig
          .get(`${URL_API}/swd/measured-activity/create`)
          .then((response) => {
            // console.log("Response Original Create Form : ", response);

            if (response.status === 200) {
              setSelectedValueCalcMethod({
                id: '1',
                code: SWD_MA_CALCULATION_METHOD_SUM,
                name: 'sum'
              });

              setCalculationMethodList([
                {
                  code: SWD_MA_CALCULATION_METHOD_SUM,
                  id: '1',
                  name: 'sum'
                },
                {
                  code: SWD_MA_CALCULATION_METHOD_AVERAGE,
                  id: '2',
                  name: 'average'
                }
              ]);

              //*
              setInputMethodList(response.data.data.inputMethodCollections);
              setAchievementTypeCollections(
                response.data.data.achievementTypeCollections
              );
              setInputValueTypeList(response.data.data.valueTypeCollections);
              setTargetTypeList(response.data.data.targetTypeCollections);
              setPeriodCollections(response.data.data.periodCollections);
              setOwnerList(response.data.data.ownerCollections);
            }
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            // console.log("Error : ", error.response);
          });

        setLoader(false);
      } else {
        // console.log("No Access Token available!");
        setOpenDialogError(true);
      }
    }
  }, [isModalCreate]);

  // GET || goalCollections ||
  useEffect(() => {
    if (isModalCreate === true) {
      setUserTokenState(userToken);
      setLoader(false);
      setDisabledButton(false);

      if (userToken !== undefined || userToken !== null) {
        setLoader(true);

        axiosConfig
          .get(
            `${URL_API}/swd/measured-activity/create?options[filter][owner_id]=${ownerId}`
          )
          .then((response) => {
            // console.log("Response Create Form with GoalList : ", response);

            const result = response.data.data;

            if (response.status === 200) {
              setChoosedMemberGoal('');
              setGoalList(result.goalCollections);

              if (result.goalCollections.length > 0) {
                setMAConnectedWithGoal(true); // connect MA with its Goal

                // Set Period Name and ID if its first index of Goal Collections
                if (result.goalCollections[0].timeFrame.code === 'period') {
                  setShowTextPeriod(true);
                  setShowTextDateRange(false);
                  setShowTextDueDate(false);
                  setPeriodName(result.goalCollections[0].period.name);
                  setPeriodId(result.goalCollections[0].period.id);
                } else if (
                  result.goalCollections[0].timeFrame.code === 'due_date'
                ) {
                  setShowTextDueDate(true);
                  setShowTextDateRange(false);
                  setShowTextPeriod(false);
                  setTextEndDueDate(result.goalCollections[0].end_date);
                } else if (
                  result.goalCollections[0].timeFrame.code === 'date_range'
                ) {
                  setShowTextDateRange(true);
                  setShowTextDueDate(false);
                  setShowTextPeriod(false);
                  setStartDateRangeState(result.goalCollections[0].start_date);
                  setEndDateRangeState(result.goalCollections[0].end_date);
                }

                // Set Goal Id First Index
                setGoalId(result.goalCollections[0].id);

                // Set TimeFrame Id First Index
                setTimeFrameId(result.goalCollections[0].time_frame_id);

                // Set TimeFrame Code First Index
              } else {
                setMAConnectedWithGoal(false); // connect MA with its Goa
                setShowTextDueDate(false);
                setShowTextDateRange(false);
                setShowTextPeriod(false);
                setTimeFrameId(null);
                setStartDateRangeState(null);
                setEndDateRangeState(null);
                setSelectedDueDate('');
                setTextEndDueDate('');
                setPeriodName('');
                setGoalId('');
                setPeriodId('');
              }
            }
          })
          .catch((error) => {
            setLoader(false);
            // console.log("Error : ", error.response);
          });
        setLoader(false);
      } else {
        // console.log("No Access Token available!");
        setOpenDialogError(true);
      }
    }
  }, [ownerId]);

  // GET || goalCollections ||
  useEffect(() => {
    if (isModalCreate === true) {
      setUserTokenState(userToken);
      setLoader(false);
      setDisabledButton(false);

      if (userToken !== undefined || userToken !== null) {
        setLoader(true);

        axiosConfig
          .get(
            `${URL_API}/swd/measured-activity/create?options[filter][owner_id]=${ownerId}&options[filter][goal_id]=${goalId}`
          )
          .then((response) => {
            // console.log("Response Create Form with owner & goal : ", response);

            const result = response.data.data;

            if (response.status === 200) {
              setGoalList(result.goalCollections);
            }
          })
          .catch((error) => {
            setLoader(false);
            // console.log("Error : ", error.response);
          });
        setLoader(false);
      } else {
        // console.log("No Access Token available!");
        setOpenDialogError(true);
      }
    }
  }, [goalId]);

  //   VALUE CALCULATION (STARTVALUE -> NILAITARGET)
  useEffect(() => {
    //*
    if (
      startValue === nilaiTarget &&
      (selectedValueCalcMethod.code === SWD_MA_CALCULATION_METHOD_SUM ||
        selectedValueCalcMethod.code === SWD_MA_CALCULATION_METHOD_LAST_VALUE)
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else if (
      nilaiTarget === '0' &&
      selectedValueCalcMethod.code === SWD_MA_CALCULATION_METHOD_AVERAGE
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else {
      setShowTextFieldTargetRangeValue(false);
    }
  }, [startValue, nilaiTarget, selectedValueCalcMethod]);

  //   ASSIGN DATE RANGE VALUE
  useEffect(() => {
    setStartDateRangeState(startDateRange);
    setEndDateRangeState(endDateRange);
  }, [startDateRange, endDateRange]);

  /**
   ==================================================================================
    ~~ ALL HANDLE FUNCTION GOES HERE ~~
   ==================================================================================
   */

  const handleChangeMATitle = (e) => {
    setMATitle(e.target.value);
  };

  //   HANDLE CHOOSE MEMBER OWNER (DROPDOWN)
  const handleChooseMemberOwner = (e, data) => {
    e.preventDefault();

    // // console.log("Wew data Owner NIH : ", data);
    if (data !== null) {
      setOwnerId(data.id);

      setChooseOwnerFirst(false);

      setShowDueDateComponent(false);

      setChoosedMemberOwner(
        data.member_first_name + ' ' + data.member_last_name
      );
      setTextValuePosition(data.structure_position_title_name);

      if (data.member.self.rel.photo_url !== null) {
        localStorage.setItem('fotoQuery', data.member.self.rel.photo_url);
      }
    }
  };

  //   HANDLE SHOW DROPDOWN MA GOAL
  const handleShowDropdownMAGoal = (event) => {
    setAnchorElMAGoal(event.currentTarget);
  };

  //   HANDLE CLOSE DROPDOWN MA GOAL
  const handleCloseDropdownMAGoal = () => {
    setAnchorElMAGoal(null);
  };

  //   HANDLE CHOOSE MEMBER GOAL (DROPDOWN)
  const handleChooseMemberGoal = (e, data) => {
    e.preventDefault();

    // console.log("Wew data Goal NIH : ", data);

    setGoalId(data.id);
    setChoosedMemberGoal(data.name);

    setTimeFrameId(data.time_frame_id);

    if (data.timeFrame.code === 'due_date') {
      setTextEndDueDate(data.end_date);
      setShowTextDueDate(true);
      setShowTextDateRange(false);
      setShowTextPeriod(false);
      setShowDueDateComponent(false);
      setStartDateRangeState(null);
      setEndDateRangeState(null);
    }

    if (data.timeFrame.code === 'date_range') {
      setStartDateRangeState(data.start_date);
      setEndDateRangeState(data.end_date);
      setShowTextDateRange(true);
      setShowTextDueDate(false);
      setShowTextPeriod(false);
      setShowDueDateComponent(false);
      setTextEndDueDate('');
    }

    if (data.timeFrame.code === 'period') {
      setPeriodName(data.period.name);
      setStartDateRangeState(data.period.start_date);
      setEndDateRangeState(data.period.end_date);
      setPeriodId(data.period.id);
      setShowTextPeriod(true);
      setShowTextDueDate(false);
      setShowTextDateRange(false);
      setShowDueDateComponent(false);
      setTextEndDueDate('');
    }

    handleCloseDropdownMAGoal();
  };

  //   HANDLE TIME FROME TO SHOW
  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  //   HANDLE CLOSE ELEMENT TIME FRAME
  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  //   HANDLE WHEN CHOOSE DUE DATE
  const handleChooseDueDate = () => {
    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !
    setTimeFrameId('3');

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);
    setShowTextDueDate(true);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);
    setTextValueTimeFrame('Due Date');
    setShowTextPeriod(false);

    setEndDateRangeState(null);

    localStorage.removeItem('start_date');
    localStorage.removeItem('end_date');
  };

  //   HANDLE WHEN CHOOSE DATE RANGE
  const handleChooseRange = () => {
    setTimeFrameId('2');
    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);
    setShowDateRangeComponent(true);
    setShowTextDateRange(true);
    setShowTextDueDate(false);
    setShowTextPeriod(false);

    setTextValueTimeFrame('Date Range');
  };

  //   HANDLE WHEN CHOOSE PERIOD
  const handleChoosePeriod = (event) => {
    setTimeFrameId('1');
    setAnchorElPeriod(event.currentTarget);

    //*
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);
    setShowDateRangeComponent(false);

    setShowTextPeriod(true);
    setShowTextDateRange(false);
    setShowTextDueDate(false);

    setTextValueTimeFrame('Period');

    localStorage.removeItem('start_date');
    localStorage.removeItem('end_date');

    setShowPeriodComponent(true);
    setSelectedDueDate(null);
  };

  //   HANDLE CLOSE PERIOD
  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  //   HANDLE CHOOSE PERIOD ID
  const handleChoosePeriodId = (e, item) => {
    e.preventDefault();
    // console.log("Item : ", item);

    setPeriodId(item.id);
    setPeriodName(item.name);

    handleClosePeriod();
    setShowTextPeriod(true);
  };

  //   HANDLE CLICK PERIOD
  function handleClickPeriod(event) {
    setAnchorElPeriod(event.currentTarget);
  }

  //   HANDLE CHANGE DESCRIPTION
  const handleChangeDescription = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  //   HANDLE WHAT POSITION OWNER IS
  function handleClickDropdownPosition(event) {
    setAnchorElTimePosition(event.currentTarget);
  }

  //   HANDLE CLOSE  DROPDOWN POSITION
  function handleCloseDropdownPosition() {
    setAnchorElTimePosition(null);
  }

  //   HANDLE CHOOSE DROPDOWN POSITION
  const handleChoosePosition = (e, data) => {
    // console.log("Data : ", data);

    handleCloseDropdownPosition();

    // console.log("Position selected : ", data);
    setTextValuePosition(data.structure_position_title_name);
    setOwnerId(data.id);
  };

  //   HANDLE CHANGE TARGET VALUE RANGE
  const handleChangeTargetValueRange = (e) => {
    e.preventDefault();
    setTargetValueRange(e.target.value);
  };

  //   HANDLE CHANGE START VALUE
  const handleChangeStartValue = (e) => {
    e.preventDefault();
    setStartValue(e.target.value);
  };

  //   HANDLE CHANGE START VALUE
  const handleChangeNilaiTarget = (e) => {
    e.preventDefault();
    setNilaiTarget(e.target.value);
  };

  //   HANDLE RADIO BTN ACHIEVEMENT TYPE
  const handleChangeRadioButtonAchievementType = (e, data) => {
    e.preventDefault();
    setSelectedValueAchievementType(data);
  };

  //   HANDLE SELECTED INPUT METHOD
  const handleChangeRadioButtonInputMethod = (e, data) => {
    e.preventDefault();
    setSelectedValueInputMethod(data);

    // // console.log("Data Input Method : ", data);

    if (data.code === SWD_MA_INPUT_METHOD_INCREMENT) {
      // setShowSixRadioButton(true);

      setCalculationMethodList([
        {
          id: '1',
          code: SWD_MA_CALCULATION_METHOD_SUM,
          name: 'sum'
        },
        {
          id: '2',
          code: SWD_MA_CALCULATION_METHOD_AVERAGE,
          name: 'average'
        }
      ]);

      setSelectedValueCalcMethod({
        id: '1',
        code: SWD_MA_CALCULATION_METHOD_SUM,
        name: 'sum'
      });
    } else {
      // setShowSixRadioButton(false);
      setCalculationMethodList([
        {
          code: SWD_MA_CALCULATION_METHOD_LAST_VALUE,
          id: '3',
          name: 'last value'
        }
      ]);

      setSelectedValueCalcMethod({
        id: '3',
        code: SWD_MA_CALCULATION_METHOD_LAST_VALUE,
        name: 'last value'
      });

      setTargetTypeList([
        {
          code: 'swd_ma_target_type_daily',
          id: '1',
          name: 'Daily'
        },
        {
          code: 'swd_ma_target_type_final',
          id: '2',
          name: 'Final'
        }
      ]);
    }
  };

  //   HANDLE CHANGE RADIO BUTTON VALUE TYPE
  const handleChangeRadioButtonValueType = (e, data) => {
    e.preventDefault();
    setSelectedValueType(data);

    if (data.code == PERCENTAGE) {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  const handleChangeRadioButton = (e, data) => {
    e.preventDefault();
    setSelectedValueCalcMethod(data);

    // // console.log("Data Calculation Method : ", data);

    if (data.id === '3') {
      //*if Calculation Method Last Value

      setSelectedValueInputMethod({
        id: '1',
        code: 'swd_ma_input_method_last_value',
        name: 'last value'
      });
    } else {
      setSelectedValueInputMethod({
        id: '2',
        code: 'swd_ma_input_method_increment',
        name: 'increment'
      });
    }

    if (data.id === '2') {
      setTargetTypeList([
        {
          code: 'swd_ma_target_type_final',
          id: '2',
          name: 'Final'
        }
      ]);

      setTargetType({
        code: 'swd_ma_target_type_final',
        id: '2',
        name: 'Final'
      });
    } else {
      setTargetTypeList([
        {
          code: 'swd_ma_target_type_daily',
          id: '1',
          name: 'Daily'
        },
        {
          code: 'swd_ma_target_type_final',
          id: '2',
          name: 'Final'
        }
      ]);

      setTargetType({
        code: 'swd_ma_target_type_daily',
        id: '1',
        name: 'Daily'
      });
    }
  };

  const handleSelectTargetType = (e, data) => {
    e.preventDefault();
    setTargetType(data);
  };

  //   HANDLE SUBMIT MA ALL
  const handleSUBMITMA = () => {
    setLoader(true);
    setDisabledButton(true);

    let data = {
      MeasuredActivity: {
        name: maTitle,
        start_value: startValue !== '0' ? startValue : 0,
        target_value: nilaiTarget,
        target_range_value:
          targetValueRange !== null && targetValueRange !== ''
            ? targetValueRange
            : null,
        input_method_id: selectedValueInputMethod.id,
        calculation_method_id: selectedValueCalcMethod.id,
        value_type_id: selectedValueType.id,
        achievement_type_id: selectedValueAchievementType.id,
        time_frame_id: timeFrameId !== null ? timeFrameId : null,
        period_id: periodId !== '' ? periodId : null, //*Mandatory if time_frame_id === 1

        start_date: startDateRangeState !== null ? startDateRangeState : null,
        end_date:
          endDateRangeState !== null
            ? endDateRangeState
            : isShowDueDateComponent === true
            ? moment(selectedDueDate).format('YYYY-MM-DD')
            : textEndDueDate,

        description: description,
        owner_id: ownerId,
        goal_id: goalId,
        is_evidence_required: evidance === true ? 1 : 0, //*0 === Tidak butuh evidence, 1 === Butuh evidence
        target_type_id: targetType.id
      }
    };

    if (data.MeasuredActivity.period_id == null) {
      delete data.MeasuredActivity.period_id;
    }

    if (
      data.MeasuredActivity.period_id !== null &&
      data.MeasuredActivity.start_date === null &&
      data.MeasuredActivity.end_date === 'Invalid date'
    ) {
      delete data.MeasuredActivity.start_date;
      delete data.MeasuredActivity.end_date;
    }

    if (data.MeasuredActivity.start_date == null) {
      delete data.MeasuredActivity.start_date;
    }

    if (
      data.MeasuredActivity.target_range_value == null ||
      data.MeasuredActivity.target_range_value === ''
    ) {
      delete data.MeasuredActivity.target_range_value;
    }

    if (
      data.MeasuredActivity.period_id == null ||
      data.MeasuredActivity.period_id === ''
    ) {
      delete data.MeasuredActivity.period_id;
    }

    // console.log("Data SUBMIT : ", data);

    if (userTokenState !== '') {
      axiosConfig
        .post(`${URL_API}/swd/measured-activity`, data)
        .then(function (response) {
          setLoader(false);
          setModalResponse200(true);
          setOpenDialogSetWeight(true);

          // // console.log("Response Original : ", response);

          if (response.status === 200) {
            setModalCreate(false);
            setCollectionMAList([response.data.data, ...collectionMAList]);
            context.setSuccessCreateMAR(Math.floor(Math.random() * 1000 + 1));
          }
        })
        .catch(function (error) {
          setLoader(false);
          setDisabledButton(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              setTextErrorInformationValue('');

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);

                  // Show snackbeer when user doesn't choose time frame
                  setModalResponse400(true);
                  setErrorDescription(
                    `${error.response.data.info.message}: ${error.response.data.info.errors[0].description}`
                  );
                } else {
                  if (
                    error.response.data.info.errors.hasOwnProperty('end_date')
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      'Error 400 : ' +
                        Capitalize(error.response.data.info.errors.end_date)
                    );
                  }

                  if (
                    error.response.data.info.errors.hasOwnProperty(
                      'calculation_method_id'
                    )
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      'Error 400 : ' +
                        Capitalize(
                          error.response.data.info.errors.calculation_method_id
                        )
                    );
                  }

                  if (
                    error.response.data.info.errors.hasOwnProperty('owner_id')
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      'Error 400 : ' +
                        Capitalize(error.response.data.info.errors.owner_id)
                    );
                  }

                  if (error.response.data.info.errors.hasOwnProperty('goal')) {
                    setOpenDialogError(true);
                    setTextErrorInformationValue(
                      'Error 400 : ' +
                        Capitalize(error.response.data.info.errors.goal)
                    );
                  }
                }
              }
            }

            if (error.response.status === 422) {
              setErrorStatus(422);
              setListError(error.response.data.info.errors);
              setOpenDialogError(true);
            }

            if (error.response.status === 500) {
              setErrorStatus(500);
              setTextErrorInformationValue('');
              if (error.response.data.hasOwnProperty('message')) {
                setOpenDialogError(true);
                setTextErrorInformationValue(
                  'Error 500 : ' + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue('Whoops, something went wrong !');
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  // ============ CONSOLE AREA ============

  // ============ CONSOLE AREA ============

  /**
   ==================================================================================
    ~~ RENDER JSX / COMPONENT GOES HERE ~~
   ==================================================================================
   */

  return (
    <Fragment>
      <Dialog
        open={isModalCreate}
        onClose={() => setModalCreate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          <Grid container>
            <Grid item xs={8} style={{ textAlign: 'right' }}>
              <Typography variant="h6" className={classes.title}>
                <b>Buat Measured Activity</b>
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <IconButton
                disabled //*Sementara off-in dulu aja
                onClick={() => setModalCreateModeWizard(true)}
                size="small"
                style={{ marginTop: '-8px' }}
              >
                <HelpOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ textAlign: 'left' }}>
          <DialogContentText>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Judul Measured Activity Anda</b>
            </Typography>
            <TextField
              inputRef={textInputReff}
              id="outlined-bare"
              className={classes.textField}
              onChange={handleChangeMATitle}
              value={maTitle}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title
              }}
            />
          </DialogContentText>
          {/**
            ==================================================================================
                CONTAINER FOR CHOOSE OWNER & CHOOSE GOAL
            ==================================================================================
            */}
          <Grid container>
            {/* CHOOSE OWNER SECTION */}
            <Grid item xs={6}>
              <List>
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    {fotoQuery !== null ? (
                      <ImageSmallUser item={fotoQuery} index={1} />
                    ) : (
                      <img
                        src={AvatarDummy}
                        alt="."
                        className={classes.imageAvatar}
                      />
                    )}
                  </ListItemIcon>

                  {/* USER PILIH OWNER DI SINI -> TAKE OWNER_ID */}
                  {ownerList.length > 0 ? (
                    <Autocomplete
                      id="combo-box-demo"
                      options={ownerList}
                      getOptionLabel={(option) =>
                        `${option.member_first_name} ${option.member_last_name}`
                      }
                      style={{ fontSize: 13 }}
                      className={classes.title}
                      noOptionsText="Tidak ada pilihan"
                      onChange={(e, owner) => handleChooseMemberOwner(e, owner)}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: 200, fontSize: 13 }}
                          {...params}
                          label="Search Owner"
                          variant="standard"
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      id="standard-helperText"
                      label="Search Owner"
                      style={{
                        color: '#d1354a',
                        width: 200
                      }}
                      disabled={true}
                      defaultValue="Tidak ada Owner"
                    />
                  )}
                </ListItem>
              </List>
            </Grid>

            {/* CHOOSE GOAL SECTION */}
            <Grid item xs={6}>
              <List style={{ marginLeft: 10 }}>
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <div
                      style={{
                        backgroundColor: '#ddd',
                        borderRadius: '50%',
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 9,
                        paddingRight: 9
                      }}
                    >
                      <FlagIcon />
                    </div>
                  </ListItemIcon>

                  <ListItemText
                    className={classes.listItemtext}
                    primary={
                      chooseOwnerFirst ? (
                        <LightTooltip
                          title="Silahkan pilih Owner terlebih dahulu"
                          placement="top"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: 'grey' }}
                          >
                            Pilih Goal :
                          </Typography>
                        </LightTooltip>
                      ) : goalList.length > 0 ? (
                        <Button
                          onClick={handleShowDropdownMAGoal}
                          size="small"
                          className={classes.timeFrameIconInModal}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: 'grey' }}
                          >
                            Pilih Goal :
                          </Typography>
                        </Button>
                      ) : (
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: 'grey' }}
                        >
                          User tidak memiliki Goal
                        </Typography>
                      )
                    }
                    secondary={
                      <Typography
                        variant="subtitle2"
                        className={classes.title}
                        style={{ marginLeft: 8 }}
                      >
                        <b>
                          {choosedMemberGoal !== ''
                            ? choosedMemberGoal
                            : goalList.length > 0
                            ? goalList[0].name
                            : '-'}
                        </b>
                      </Typography>
                    }
                  />
                </ListItem>

                {/* USER PILIH GOAL DI SINI -> TAKE GOAL_ID */}
                <StyledMenu
                  id="customized-menu-goal-owner"
                  anchorEl={anchorElMAGoal}
                  keepMounted
                  open={Boolean(anchorElMAGoal)}
                  onClose={handleCloseDropdownMAGoal}
                >
                  {goalList.length > 0 ? (
                    goalList.map((goal, i) => {
                      return (
                        <StyledMenuItem
                          key={i}
                          onClick={(e) => handleChooseMemberGoal(e, goal)}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                              >
                                <b>{goal.name}</b>
                              </Typography>
                            }
                          />
                        </StyledMenuItem>
                      );
                    })
                  ) : (
                    <StyledMenuItem
                      onClick={() => {
                        setTextErrorInformationValue(
                          'Anda tidak memiliki ijin untuk membuat Measured Activity, harap hubungi atasan Anda'
                        );
                        setOpenDialogError(true);
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>{userLoginName}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}
                </StyledMenu>
              </List>
            </Grid>
          </Grid>
          {/* ==================== |  MA Connected With Goal | ==================== */}
          {isMAConnectedWithGoal && (
            <ButtonMaHaveConnectedWithGoal classes={classes} />
          )}
          {/**
            ==================================================================================
                CONTAINER FOR TIME FRAME
            ==================================================================================
            */}
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 10 }}
          >
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.title}>
                <b>Pilih Timeframe</b>
              </Typography>
              <Button
                onClick={handleClickTimeFrame}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 10 }}
              >
                <IconButton style={{ background: '#edcfd8' }}>
                  <i
                    className="material-icons"
                    style={{ color: 'white', fontSize: 14 }}
                  >
                    calendar_today
                  </i>
                </IconButton>
                &nbsp;
                <b>{textValueTimeFrame}</b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}
              >
                <StyledMenuItem onClick={handleChooseDueDate}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChooseRange}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChoosePeriod}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              {/* MENU LIST PERIOD */}
              <StyledMenu
                id="customized-menu-period"
                anchorEl={anchorElPeriod}
                keepMounted
                open={Boolean(anchorElPeriod)}
                onClose={handleClosePeriod}
                style={{ marginTop: '-160px' }}
              >
                {isShowPeriodComponent === true &&
                periodCollections.length > 0 ? (
                  periodCollections.map((item, i) => (
                    <StyledMenuItem
                      key={i}
                      onClick={(e) => handleChoosePeriodId(e, item)}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>{item.name}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  ))
                ) : (
                  <StyledMenuItem disabled>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>Anda belum memiliki periode ;(</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
              </StyledMenu>

              {isShowDateRangeComponent === true && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                    onStartDate={setStartDateRangeState}
                    onEndDate={setEndDateRangeState}
                  />
                </Fragment>
              )}
            </Grid>

            <Grid item xs={6}>
              {isShowDueDateComponent === true && ( //https://stackoverflow.com/questions/17493309/how-do-i-change-the-language-of-moment-js
                <MuiPickersUtilsProvider utils={LocalizedUtils}>
                  <ThemeProvider theme={theme}>
                    <div style={{ marginTop: 15 }}>
                      <DatePicker
                        value={selectedDueDate}
                        onChange={setSelectedDueDate}
                        animateYearScrolling
                        open={isShowDueDateComponentDirectly}
                        onOpen={() => setShowDueDateComponentDirectly(true)}
                        onClose={() => setShowDueDateComponentDirectly(false)}
                        variant="dialog" // dialog, static, inline
                        disableToolbar={false}
                        format="DD MMMM YYYY"
                      />
                    </div>
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              )}

              {isShowTextPeriod && (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginTop: 15, borderWidth: 0 }}
                >
                  <b>{periodName}</b>
                </Button>
              )}

              {isShowTextDueDate &&
                isMAConnectedWithGoal &&
                !isShowDueDateComponent &&
                !isShowTextDateRange &&
                !isShowTextPeriod && (
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.timeFrameIcontDateRangeText}
                    style={{ marginTop: 15, borderWidth: 0 }}
                  >
                    <b>{textEndDueDate}</b>
                  </Button>
                )}

              {isMAConnectedWithGoal &&
              isShowTextDateRange &&
              !isShowTextPeriod ? (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ borderWidth: 0, marginTop: 15 }}
                >
                  <b>
                    {moment(startDateRangeState).format('DD MMMM YYYY')} -{' '}
                    {moment(endDateRangeState).format('DD MMMM YYYY')}
                  </b>
                </Button>
              ) : null}
            </Grid>
          </Grid>
          {/**
            ==================================================================================
                SECTION INPUT DESCRIPTION
            ==================================================================================
            */}
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: 'grey', marginTop: 20 }}
          >
            <b>Deskripsi (Optional)</b>
          </Typography>
          <TextField
            onChange={handleChangeDescription}
            style={{ marginTop: 5 }}
            id="outlined-multiline-static-description"
            // label="Multiline"
            multiline
            rows="4"
            // defaultValue="Default Value"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
          />
          {/**
            ==================================================================================
                CONTAINER FOR POSITION/JOB & EVIDENCE
            ==================================================================================
            */}
          <br />
          <Grid container style={{ marginTop: 15 }}>
            <Grid item xs={6} sm={6}>
              {ownerList.length === 0 && (
                <Button
                  // onClick={ () => Redirect(ToSOTable) }
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}
                >
                  <IconButton style={{ background: '#edcfd8' }}>
                    <PersonIcon color="#fff" style={{ fontSize: 14 }} />
                  </IconButton>
                  &nbsp;
                  <b>Belum ada posisi yang di tentukan untuk membuat MA ;(</b>
                </Button>
              )}

              {ownerList.length > 0 && (
                <Button
                  onClick={handleClickDropdownPosition}
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}
                  disabled
                  startIcon={
                    <PersonIcon color="#555" style={{ fontSize: 14 }} />
                  }
                >
                  <b>
                    {ownerList.length > 0 && textValuePosition == ''
                      ? ownerList[0].structure_position_title_name
                      : textValuePosition}
                  </b>
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimePosition}
                keepMounted
                open={Boolean(anchorElTimePosition)}
                onClose={handleCloseDropdownPosition}
              >
                {ownerList.length > 0 &&
                  ownerList.map((item, i) => {
                    return (
                      <StyledMenuItem
                        key={i}
                        onClick={(e) => handleChoosePosition(e, item)}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle2"
                              className={classes.title}
                            >
                              <b>{item.structure_position_title_name}</b>
                            </Typography>
                          }
                        />
                      </StyledMenuItem>
                    );
                  })}
              </StyledMenu>
            </Grid>

            {/* TARGET RANGE VALUE */}
            <Grid item xs={6}>
              {isShowTextFieldTargetRangeValue === true && (
                <Fragment>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: 'grey' }}
                  >
                    <b>Target Range value : </b>
                  </Typography>
                  <Box>
                    <TextField
                      placeholder="0"
                      type="number"
                      id="outlined-bare"
                      onChange={handleChangeTargetValueRange}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="subtitle1"
                              className={classes.title}
                              style={{ color: 'grey' }}
                            >
                              {isShowPercent === true ? <b>%</b> : null}
                            </Typography>
                          </InputAdornment>
                        )
                      }}
                      disabled={
                        selectedValueCalcMethod.code ===
                        SWD_MA_CALCULATION_METHOD_AVERAGE
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Fragment>
              )}
            </Grid>
          </Grid>
          {/**
            ==================================================================================
                CONTAINER FOR POSITION/JOB & EVIDENCE
            ==================================================================================
            */}{' '}
          <br />
          <Grid container>
            <Grid item xs={6} md={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: 'grey' }}
              >
                <b>Start Value</b>
              </Typography>
              <Box marginRight={theme.spacing(0.2)}>
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeStartValue}
                  value={startValue}
                  variant="outlined"
                  // fullWidth
                  disabled={
                    selectedValueCalcMethod.code ===
                    SWD_MA_CALCULATION_METHOD_AVERAGE
                      ? true
                      : false
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="subtitle1"
                          className={classes.title}
                          style={{ color: 'grey' }}
                        >
                          {isShowPercent == true ? <b>%</b> : null}
                        </Typography>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6} md={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: 'grey' }}
              >
                <b>Target Value</b>
              </Typography>
              <TextField
                id="outlined-bare"
                // className={classes.textField}
                onChange={handleChangeNilaiTarget}
                value={nilaiTarget}
                variant="outlined"
                // fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{ color: 'grey' }}
                      >
                        {isShowPercent == true ? <b>%</b> : null}
                      </Typography>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
          {/**
            ==================================================================================
                ~ Is Evidence required Or NOT
            ==================================================================================
            */}
          <br />
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                // color="primary"
                size="small"
                checked={evidance}
                onChange={(e) => setEvidance(e.target.checked)}
              />
            }
            label={
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: 'grey' }}
              >
                Wajib lampirkan bukti gambar
              </Typography>
            }
            labelPlacement="end"
          />
          {/**
            ==================================================================================
                ~ TOOLTIP ~ MORE / LESS
            ==================================================================================
            */}
          <br />
          <br />
          <LightTooltip
            title={
              <div>
                Pilihan More: Semakin besar nilai, semakin baik. <br />
                Pilihan Less: Semakin kecil nilai, semakin baik
              </div>
            }
            placement="top"
          >
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: 'grey' }}
            >
              <b>Achievement Type :</b>
            </Typography>
          </LightTooltip>
          <List>
            {achievementTypeCollections.length > 0 &&
              achievementTypeCollections.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueAchievementType.id === item.id}
                    onChange={(e) =>
                      handleChangeRadioButtonAchievementType(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: 'Roboto', color: 'grey' }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>
          <br />
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: 'grey' }}
          >
            <b>Input Method :</b>
          </Typography>
          <List>
            {inputMethodList.length > 0 &&
              inputMethodList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueInputMethod.code == item.code}
                    onChange={(e) =>
                      handleChangeRadioButtonInputMethod(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: 'Roboto', color: 'grey' }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>
          <br />
          <LightTooltip
            title={
              <div>
                Pilihan <i>Numeric</i>: Data input berupa angka aktual. <br />
                Pilihan <i>Percentage</i>: Data input berupa persen
              </div>
            }
            placement="top"
          >
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: 'grey' }}
            >
              <b>Value Type : </b>
            </Typography>
          </LightTooltip>
          <List>
            {inputValueTypeList.length > 0 &&
              inputValueTypeList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueType.code === item.code}
                    onChange={(e) => handleChangeRadioButtonValueType(e, item)}
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: 'Roboto', color: 'grey' }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>
          <br />
          <LightTooltip
            title={
              <div>
                SUM: Penjumlahan dari semua Goal Result. <br />
                AVG: Rata-rata dari semua Goal Result <br />
                LAST VALUE: Hanya untuk "Input Method - Total"
              </div>
            }
            placement="top"
          >
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: 'grey' }}
            >
              <b>Calculation Method :</b>
            </Typography>
          </LightTooltip>
          <List>
            {calculationMethodList.length > 0 &&
              calculationMethodList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={selectedValueCalcMethod.code === item.code}
                      onChange={(e) => handleChangeRadioButton(e, item)}
                      value={item.id}
                      name={item.name}
                      // disabled={item.code == 'last_value' ? true : false }
                    />
                    <span style={{ fontFamily: 'Roboto', color: 'grey' }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>
          <br />
          <LightTooltip
            title={
              <div>
                Daily: Perhitungan yang digunakan adalah target di hari yang
                sedang berjalan <br />
                Final: Perhitungan yang digunakan adalah target di akhir periode
              </div>
            }
            placement="top"
          >
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: 'grey' }}
            >
              <b>Target Type :</b>
            </Typography>
          </LightTooltip>
          <List>
            {targetTypeList.length > 0 &&
              targetTypeList.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <Radio
                      checked={targetType.code === item.code}
                      onChange={(e) => handleSelectTargetType(e, item)}
                      value={item.id}
                      name={item.name}
                    />
                    <span style={{ fontFamily: 'Roboto', color: 'grey' }}>
                      {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                    </span>
                  </Fragment>
                );
              })}
          </List>
        </DialogContent>

        <DialogActions
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Button
            onClick={handleSUBMITMA}
            variant="contained"
            className={classes.button}
            fullWidth
            disabled={disabledButton == true ? true : false}
          >
            {loader ? (
              <CircularProgress size={20} style={{ color: '#d1354a' }} />
            ) : (
              '  Buat Measure Activity !'
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogSetWeight
        classes={classes}
        openDialogSetWeight={openDialogSetWeight}
        setOpenDialogSetWeight={setOpenDialogSetWeight}
        goalId={goalId}
      />

      <SnackbeerRED
        isOpenSnackbeer={isModalResponse400}
        setOpenSnackbeer={setModalResponse400}
        messages={errorDescription}
      />

      <Snackbeer
        setModalResponse200={setModalResponse200}
        isModalResponse200={isModalResponse200}
        messages="Measured Activity berhasil di buat!"
      />
    </Fragment>
  );
};

export default DialogCreate;
