import React, { useEffect, useState } from "react";
import { Typography, Box, Switch, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Language = ({ classes, lockSetting }) => {
  const [lang, setLang] = useState(false);

  useEffect(() => {
    const getLang = localStorage.getItem("lang");

    if (getLang !== undefined && getLang !== null) {
      if (getLang === "bahasa") {
        setLang(true);
      } else {
        setLang(false);
      }
    } else {
      setLang(false);
    }
  }, []);

  const handleChange = (e) => {
    const checked = e.target.checked;

    setLang(checked);

    localStorage.setItem("lang", checked ? "bahasa" : "english");

    window.location.reload();
  };

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title333}>
        <b>Language</b>
      </Typography>
      <Box my={1} />
      <Typography variant="caption" className={classes.title888}>
        {/* {lang ? bahasa.descLang : english.descLang} */}
      </Typography>
      <Box my={1} />

      <FormControlLabel
        control={
          <IOSSwitch checked={lang} onChange={handleChange} name="Language" />
        }
        disabled={lockSetting}
        label={lang ? "Bahasa Indonesia" : "English"}
      />
    </div>
  );
};

export default Language;
