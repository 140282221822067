import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleAttendanceHome";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import {
  AddAlarm,
  InsertDriveFile,
  EventAvailable,
  Room,
  TimerOff,
} from "@material-ui/icons";

import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import Moment from "react-moment";
import moment from "moment";
import { isEmpty } from "lodash";

import ContextAttendanceHome from "./Context/ContextAttendanceHome";
import ProfileCard from "./Components/ProfileCard";
import HomeMenu from "./Components/HomeMenu";
import TodayScheduleCard from "./Components/TodayScheduleCard";
import ReportCard from "./Components/ReportCard";
import TomorrowCard from "./Components/TomorrowCard";
import DialogProgress from "../Account/Components/DialogProgress";

import { isAuthenticated } from "../MyAttendance/Hook/isAuthenticated";

import {
  ToAttendanceReport,
  ToMyAttendance,
  ToMySchedule,
  ToAttendanceTimeOff,
  ToAttendanceOvertime,
} from "../../../constants/config-redirect-url";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const menu = [
  {
    id: 1,
    name: "My Attendance",
    icon: <Room style={{ color: "#221E5B" }} />,
    link: ToMyAttendance,
  },
  {
    id: 2,
    name: "My Schedule",
    icon: <EventAvailable style={{ color: "#221E5B" }} />,
    link: ToMySchedule,
  },
  {
    id: 3,
    name: "Overtime",
    icon: <AddAlarm style={{ color: "#221E5B" }} />,
    link: ToAttendanceOvertime,
  },
  {
    id: 4,
    name: "Time Off",
    icon: <TimerOff style={{ color: "#221E5B" }} />,
    link: ToAttendanceTimeOff,
  },
  {
    id: 5,
    name: "Report",
    icon: <InsertDriveFile style={{ color: "#221E5B" }} />,
    link: ToAttendanceReport,
  },
];

const ViewAttendanceHome = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceHome);
  const [loader, setLoader] = useState(false);
  const [verificationMethod, setVerificationMethod] = useState([]);

  const authData = JSON.parse(localStorage.getItem("status_user_login"));

  useEffect(
    () => {
      if (!isAuthenticated()) {
        navigate("/");
      }
      getVerificationMethod();
    } /* eslint-disable-line */,
    []
  );

  const getVerificationMethod = async () => {
    try {
      setLoader(true);
      const { data } = await axiosConfig
        .get(`${URL_API}/human-resource/attendance/verification-method`)
        .then((res) => {
          return res.data;
        });
      setVerificationMethod(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <ProfileCard data={authData} />

        <Divider
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 100,
            marginRight: 100,
          }}
        />

        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
          }}
        >
          <Grid container spacing={1}>
            <HomeMenu data={menu} />

            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <TodayScheduleCard data={verificationMethod} />
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 20,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h6" style={{ color: "#262261" }}>
                <b>
                  {" "}
                  Absensi per hari ini dibulan{" "}
                  {moment().locale("id").format("MMMM")}{" "}
                </b>
              </Typography>

              <ReportCard />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Typography variant="h6" style={{ color: "#262261" }}>
                <b> Jadwal Kerja Besok </b>
              </Typography>

              <TomorrowCard data={verificationMethod} />
            </Grid>
          </Grid>
        </div>

        <DialogProgress
          classes={classes}
          isModalProgress={loader}
          setModalProgress={setLoader}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewAttendanceHome;
