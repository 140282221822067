import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContextTimeOffSetting from "../../Context/ContextTimeOffSetting";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import { navigate } from "hookrouter";
import { ToAttendance } from "../../../../../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogCreateDefault = (props) => {
  const {
    classes,
    isModalCreateDefault,
    setModalCreateDefault,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    masterCategory,
    setMasterCategory,
    dataCategory,
    setDataCategory,
    listError,
    createCategoryDefault
  } = props;

  const context = useContext(ContextTimeOffSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalCreateDefault}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
          disableTypography={true}
        >
          <Typography variant="h4">Setting Cuti Tahunan</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <Box p={1} my={1}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Tulis Nama Kategori Time Off</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    className={classes.disabledTextField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. Cuti Melahirkan, Cuti Menikah..."
                    value="Cuti Tahunan"
                    disabled={true}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={6}>
                  <div>
                    <Typography variant="subtitle2" className={classes.title333}>
                      <b>Interval</b>
                    </Typography>
                    <Box my={0.5} />
                    <Autocomplete
                      options={masterCategory.masterIntervalUnitCollections}
                      getOptionLabel={(option) => {
                        if (option && option.name) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      value={dataCategory.interval_unit_obj}
                      onChange={(event, newValue) => {
                        let dataCategoryTemporary = dataCategory;
                        dataCategoryTemporary.interval_unit_obj = newValue;
                        dataCategoryTemporary.interval_unit = newValue ? newValue.id : "";
                        setDataCategory({...dataCategoryTemporary});
                      }}
                      noOptionsText="Tidak ada pilihan"
                      popupIcon={<ArrowDropDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          style={{ fontSize: 13 }}
                          {...params}
                          fullWidth
                          variant="outlined"
                          size="small"
                          label="Interval"
                          error={listError.some(item => 'interval_unit' === item.key)}
                          helperText={listError.map((item) => {
                            if (item.key === 'interval_unit') {
                              return item.description;
                            }
                          })}
                        />
                      )}
                    />
                  </div>
              </Grid>
              <Grid item md={6}>
                  <div>
                    <Typography variant="subtitle2" className={classes.title333}>
                      <b>Durasi Siklus</b>
                    </Typography>
                    <Box my={0.5} />
                    <TextField
                      className={classes.textField}
                      size="small"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. 1, 2..."
                      value={dataCategory.cycle_duration}
                      onChange={(event, newValue) => {
                        let dataCategoryTemporary = dataCategory;
                        dataCategoryTemporary.cycle_duration = event.target.value;
                        setDataCategory({...dataCategoryTemporary});
                      }}
                      error={listError.some(item => 'cycle_duration' === item.key)}
                      helperText={listError.map((item) => {
                        if (item.key === 'cycle_duration') {
                          return item.description;
                        }
                      })}
                    />
                  </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                  <div>
                    <Typography variant="subtitle2" className={classes.title333}>
                      <b>Tanggal Mulai</b>
                    </Typography>
                    <Box my={0.5} />
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <Autocomplete
                          disableClearable={true}
                          options={masterCategory.masterStartDateCollections}
                          getOptionLabel={(option) => {
                            if (option && option.name) {
                              return option.name;
                            } else {
                              return "";
                            }
                          }}
                          value={dataCategory.start_date_input_type}
                          onChange={(event, newValue) => {
                            let dataCategoryTemporary = dataCategory;
                            dataCategoryTemporary.start_date_input_type = newValue;
                            setDataCategory({...dataCategoryTemporary});
                          }}
                          noOptionsText="Tidak ada pilihan"
                          popupIcon={<ArrowDropDownIcon />}
                          renderInput={(params) => (
                            <TextField
                              style={{ fontSize: 13 }}
                              {...params}
                              fullWidth
                              variant="outlined"
                              size="small"
                              label="Tipe"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Autocomplete
                          disableClearable={true}
                          disabled={ !dataCategory.start_date_input_type || dataCategory.start_date_input_type.id === "joining_date"}
                          options={masterCategory.masterDateCollections}
                          getOptionLabel={(option) => {
                            if (option && option.name) {
                              return option.name;
                            } else {
                              return "";
                            }
                          }}
                          value={dataCategory.start_date_input_date}
                          onChange={(event, newValue) => {
                            let dataCategoryTemporary = dataCategory;
                            dataCategoryTemporary.start_date_input_date = newValue;
                            setDataCategory({...dataCategoryTemporary});
                          }}
                          noOptionsText="Tidak ada pilihan"
                          popupIcon={<ArrowDropDownIcon />}
                          renderInput={(params) => (
                            <TextField
                              style={{ fontSize: 13 }}
                              {...params}
                              fullWidth
                              variant="outlined"
                              size="small"
                              label="Tanggal"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Autocomplete
                          disableClearable={true}
                          disabled={ !dataCategory.start_date_input_type || dataCategory.start_date_input_type.id === "joining_date" || (dataCategory.interval_unit_obj && dataCategory.interval_unit_obj.id === "month") }
                          options={masterCategory.masterMonthCollections}
                          getOptionLabel={(option) => {
                            if (option && option.name) {
                              return option.name;
                            } else {
                              return "";
                            }
                          }}
                          value={dataCategory.start_date_input_month}
                          onChange={(event, newValue) => {
                            let dataCategoryTemporary = dataCategory;
                            dataCategoryTemporary.start_date_input_month = newValue;
                            setDataCategory({...dataCategoryTemporary});
                          }}
                          noOptionsText="Tidak ada pilihan"
                          popupIcon={<ArrowDropDownIcon />}
                          renderInput={(params) => (
                            <TextField
                              style={{ fontSize: 13 }}
                              {...params}
                              fullWidth
                              variant="outlined"
                              size="small"
                              label="Bulan"
                              error={listError.some(item => 'start_date' === item.key)}
                              helperText={listError.map((item) => {
                                if (item.key === 'start_date') {
                                  return item.description;
                                }
                              })}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Pegawai Akan Dapat Time Off Setelah Bergabung</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1, 2..."
                    type="number"
                    value={dataCategory.start_after}
                    InputProps={{
                      className: classes.title,
                      endAdornment: <InputAdornment position="end">Bulan</InputAdornment>,
                    }}
                    onChange={(event, newValue) => {
                      let dataCategoryTemporary = dataCategory;
                      dataCategoryTemporary.start_after = event.target.value;
                      setDataCategory({...dataCategoryTemporary});
                    }}
                    error={listError.some(item => 'start_after' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'start_after') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Keterangan(Opsional)</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1, 2..."
                    value={dataCategory.remarks}
                    onChange={(event, newValue) => {
                      let dataCategoryTemporary = dataCategory;
                      dataCategoryTemporary.remarks = event.target.value;
                      setDataCategory({...dataCategoryTemporary});
                    }}
                    error={listError.some(item => 'remarks' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'remarks') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Perlu Bukti</b>
                  </Typography>
                  <Box my={0.5} />
                  <Autocomplete
                    options={masterCategory.masterEvidenceCollections}
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name;
                      } else {
                        return "";
                      }
                    }}
                    value={dataCategory.evidence_obj}
                    onChange={(event, newValue) => {
                      let dataCategoryTemporary = dataCategory;
                      dataCategoryTemporary.evidence_obj = newValue;
                      dataCategoryTemporary.evidence = newValue ? newValue.id : "";
                      setDataCategory({...dataCategoryTemporary});
                    }}
                    noOptionsText="Tidak ada pilihan"
                    popupIcon={<ArrowDropDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontSize: 13 }}
                        {...params}
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Verifikasi Admin"
                        error={listError.some(item => 'type' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'type') {
                            return item.description;
                          }
                        })}
                      />
                    )}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Jenis Pengurangan</b>
                  </Typography>
                  <Box my={0.5} />
                  <Autocomplete
                    options={masterCategory.masterDeductionTypeCollections}
                    getOptionLabel={(option) => {
                      if (option && option.name) {
                        return option.name;
                      } else {
                        return "";
                      }
                    }}
                    value={dataCategory.deduction_type_obj}
                    onChange={(event, newValue) => {
                      let dataCategoryTemporary = dataCategory;
                      dataCategoryTemporary.deduction_type_obj = newValue;
                      dataCategoryTemporary.deduction_type = newValue ? newValue.id : "";
                      if (dataCategoryTemporary.deduction_type === "leave") {
                        dataCategoryTemporary.amount_of_deduction = 1;
                      }
                      setDataCategory({...dataCategoryTemporary});
                    }}
                    noOptionsText="Tidak ada pilihan"
                    popupIcon={<ArrowDropDownIcon />}
                    renderInput={(params) => (
                      <TextField
                        style={{ fontSize: 13 }}
                        {...params}
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Jenis Pengurangan"
                        error={listError.some(item => 'type' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'type') {
                            return item.description;
                          }
                        })}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Jumlah Pengurangan</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    disabled={dataCategory.deduction_type === "leave"}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1, 2..."
                    type="number"
                    value={dataCategory.amount_of_deduction}
                    onChange={(event, newValue) => {
                      let dataCategoryTemporary = dataCategory;
                      dataCategoryTemporary.amount_of_deduction = event.target.value;
                      setDataCategory({...dataCategoryTemporary});
                    }}
                    error={listError.some(item => 'amount_of_deduction' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'amount_of_deduction') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Allowance</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1, 2..."
                    type="number"
                    value={dataCategory.allowance}
                    onChange={(event, newValue) => {
                      let dataCategoryTemporary = dataCategory;
                      dataCategoryTemporary.allowance = event.target.value;
                      setDataCategory({...dataCategoryTemporary});
                    }}
                    error={listError.some(item => 'allowance' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'allowance') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <div>
                    <Typography variant="subtitle2" className={classes.title333} component={'span'} >
                      <b>Carry Over</b>
                    </Typography>
                    <Switch
                      checked={dataCategory.carry_over_setting === 1 || dataCategory.carry_over_setting === "1"}
                      onChange={(event) => {
                        let dataCategoryTemporary = dataCategory;
                        dataCategoryTemporary.carry_over_setting = event.target.checked ? 1 : 0;
                        setDataCategory({...dataCategoryTemporary});
                      }}
                      name="carry_over_setting"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 1, 2..."
                    type="number"
                    disabled={!(dataCategory.carry_over_setting === 1  || dataCategory.carry_over_setting === "1")}
                    value={dataCategory.total_carry_over}
                    onChange={(event, newValue) => {
                      let dataCategoryTemporary = dataCategory;
                      dataCategoryTemporary.total_carry_over = parseInt(event.target.value);
                      setDataCategory({...dataCategoryTemporary});
                    }}
                    error={listError.some(item => 'total_carry_over' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'total_carry_over') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={3}>
              <Grid item md={6}>
                  <Grid container>
                    <Grid item md={9} className={classes.girdMiddle}>
                      <div>
                        <Typography variant="subtitle2" className={classes.title333}>
                          <b>Status</b>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div>
                        <Switch
                          checked={dataCategory.status === 1}
                          onChange={(event) => {
                            let dataCategoryTemporary = dataCategory;
                            dataCategoryTemporary.status = event.target.checked ? 1 : 0;
                            setDataCategory({...dataCategoryTemporary});
                          }}
                          name="late_tolerance_status"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </div>
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item md={6}>
                  <Grid container>
                    <Grid item md={9} className={classes.girdMiddle}>
                      <div>
                        <Typography variant="subtitle2" className={classes.title333}>
                          <b>Perlu Approval Admin</b>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div>
                        <Switch
                          checked={dataCategory.prior_approval === 1}
                          onChange={(event) => {
                            let dataCategoryTemporary = dataCategory;
                            dataCategoryTemporary.prior_approval = event.target.checked ? 1 : 0;
                            setDataCategory({...dataCategoryTemporary});
                          }}
                          name="late_tolerance_status"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </div>
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item md={7}>
                  <Grid container>
                    <Grid item md={10} xs={10} className={classes.girdMiddle}>
                      <div>
                        <Typography variant="subtitle2" className={classes.title333}>
                          <b>Terapkan Untuk Semua Karyawan</b>
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <div>
                        <Switch
                          checked={dataCategory.assign_to_all_employee === 1}
                          onChange={(event) => {
                            let dataCategoryTemporary = dataCategory;
                            dataCategoryTemporary.assign_to_all_employee = event.target.checked ? 1 : 0;
                            setDataCategory({...dataCategoryTemporary});
                          }}
                          name="late_tolerance_status"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      </div>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
            <Box my={2.5} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => navigate(ToAttendance)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant="outlined"
              className={classes.button1}
              onClick={createCategoryDefault}
            >
              Simpan !
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogCreateDefault;