import React, { useEffect } from "react";
import { styles } from "../Style/StyleAttendanceHome";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const ProfileCard = ({ data }) => {
  const classes = useStyles();
  const [userImage, setUserImage] = React.useState(null);

  useEffect(() => {
    axiosConfig
      .get(
        `${URL_API}/account-management/member/${data?.member_id}/photo?mode=thumbnail`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const image = new Blob([res.data], { type: "image/jpeg" });
        const imageUrl = URL.createObjectURL(image);
        setUserImage(imageUrl);
      });
  }, [data]);

  return (
    <MuiThemeProvider theme={theme}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background:
            "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
          borderRadius: 11,
          padding: "10px 20px",
          margin: "100px",
          marginBottom: "50px",
        }}
      >
        <CardContent
          style={{
            flex: "1 0 auto",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                style={{ color: "white", marginTop: 20 }}
              >
                Hai,{" "}
                <b>{data?.member_first_name + " " + data?.member_last_name}</b>
              </Typography>
              <Typography
                variant="subtitle1"
                style={{ color: "white", fontWeight: "bold" }}
              >
                {data?.member_position?.structure_position_title_name}
              </Typography>
              <Typography variant="body2" style={{ color: "white" }}>
                Di <b>{data?.group_name}</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Avatar
                src={userImage}
                style={{
                  marginTop: 20,
                  marginLeft: "auto",
                  marginRight: "25px",
                  width: "80px",
                  height: "80px",
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
};

export default ProfileCard;
