import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RemoveIcon from "@material-ui/icons/Remove";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextGoal from "../Context/ContextGoal";
import handleError from "../../../Report/components/global/handleError";

import DialogError from "../../../../components/DialogError";
import DialogArchiveSuccess from "./DialogArchiveSuccess";

const DialogArchive = ({
  classes,
  isOpenDialogArchive,
  setOpenDialogArchive,
  item,
}) => {
  const context = useContext(ContextGoal);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [goalForArchive, setGoalForArchive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadGoal, setLoadGoal] = useState(false);
  const [openDialogArchiveSuccess, setOpenDialogArchiveSuccess] =
    useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ========================================================================
  // ~ Side Effect ~
  // ========================================================================
  useEffect(() => {
    if (isOpenDialogArchive) {
      setLoadGoal(true);

      const data = {
        archive_entity_type: "goal",
        selected_entities: [{ entity_id: item.id }],
      };

      // console.log("Multi Archive Create", data);

      AxiosConfig.post(`${URL_API}/swd/archived/create`, data)
        .then((res) => {
          // console.log("RES Goal for Archive", res);
          const result = res.data.data;

          if (res.status === 200) {
            setGoalForArchive(result.fields.entity_list.default_value);
          }
          setLoadGoal(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoadGoal(false);
        });
    }
  }, [isOpenDialogArchive]);

  // ========================================================================
  // ~ Handle Archive ~
  // ========================================================================
  const handleArchive = () => {
    setLoading(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data = {
      name: item.name,
      entity_type: "goal",
      entity_list: goalForArchive,
      user_activity_log: {
        module: "user",
        event: "update",
        user_id: user?.user_id,
        user_email: user?.user_email,
        nama: `${user?.member_first_name} ${user?.member_last_name}`,
        position:
          user?.member_position?.structure_position_title_name === undefined
            ? ""
            : user?.member_position?.structure_position_title_name,
        device_type: device_os,
        device_model: "",
        device_version: OSName,
        long: "",
        lat: "",
      },
    };

    // // console.log("Data Archive", data);

    AxiosConfig.post(`${URL_API}/swd/archived/store`, data)
      .then((res) => {
        // console.log("RES Archive", res);

        if (res.status === 200) {
          // setOpenDialogArchive(false);
          // context.setOpenSnackbeer(true);
          // context.setMessages("Berhasil mengarispkan Goal");
          setOpenDialogArchiveSuccess(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // ========================================================================
  // ~ Render Tree ~
  // ========================================================================
  const renderTree = (nodes) => (
    <TreeItem key={nodes.entity_id} nodeId={nodes.entity_id} label={nodes.name}>
      {Array.isArray(nodes.sub_goals)
        ? nodes.sub_goals.map((node) => renderTree(node))
        : null}
      {Array.isArray(nodes.ma)
        ? nodes.ma.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  // ========================================================================
  // ~ Main Component ~
  // ========================================================================
  return (
    <Dialog
      open={isOpenDialogArchive}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.title333}>
          <b>Archive File</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 550 }}>
        <Typography
          variant="subtitle1"
          className={classes.title333}
          style={{ textAlign: "center" }}
        >
          Apakah Anda yakin ingin mengarsip File?
        </Typography>

        <Box pt={2} mb={3} display="flex" flexDirection="column">
          <Typography
            variant="subtitle2"
            className={classes.title888}
            style={{ marginBottom: 5 }}
          >
            Goal :
          </Typography>
          {loadGoal && (
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <CircularProgress
                size={16}
                style={{ color: "#d64253", marginRight: 5 }}
              />
              <Typography variant="caption" className={classes.title888}>
                Loading...
              </Typography>
            </Box>
          )}
          {!loadGoal &&
            goalForArchive.length > 0 &&
            goalForArchive.map((item, i) => (
              <TreeView
                key={i}
                defaultExpanded={["entity_id"]}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultEndIcon={<RemoveIcon />}
              >
                {renderTree(item)}
              </TreeView>
            ))}
        </Box>

        <Typography variant="subtitle2" className={classes.title888}>
          File yang di archive hanya bisa dilihat pada menu archive
        </Typography>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogArchive(false)}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          disabled={loadGoal}
          onClick={handleArchive}
        >
          {loading ? "Loading..." : "Archive"}
        </Button>
      </DialogActions>

      <DialogArchiveSuccess
        openDialogArchiveSuccess={openDialogArchiveSuccess}
        setOpenDialogArchiveSuccess={setOpenDialogArchiveSuccess}
        onOpenDialog={setOpenDialogArchive}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogArchive;
