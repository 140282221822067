import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import moment from "moment";

import Axios from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ListFreqItem from "./frequency/ListFreqItem";
import FreqWeekly from "./frequency/FreqWeekly";
import FreqMonthly from "./frequency/FreqMonthly";
import FreqDaily from "./frequency/FreqDaily";
import FreqHourly from "./frequency/FreqHourly";

import handleError from "../../../Report/components/global/handleError";
import ContextProject from "../../context/ContextProject";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogUpdateFrequency = ({
  classes,
  goal,
  openUpdateFrequency,
  setOpenUpdateFrequency,
}) => {
  const { setTriggerProject } = useContext(ContextProject);
  const locationPathGoalId = goal?.id;

  // ================================================================
  // ~~~~~~~~~~~~~~ STATE ~~~~~~~~~~~~~~
  // ================================================================
  const [freq, setFreq] = useState({
    selected: null,
    code: "daily",
    name: "Daily",
    selected: true,
  });
  const [loading, setLoading] = useState(false);
  const [freqDetails, setFreqDetails] = useState(defaultFreqDetails);
  const [hourlyFreq, setHourlyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "hourly",
    schedule_type: "minute",
    scheduled_at: "00",
    input_start: "-",
    input_finish: "-",
    is_locked: false,
    reminder: "",
    auto_insert_result: "1",
    hourly_details: {
      selected_times: [],
      selected_days: [],
    },
  });
  const [dailyFreq, setDailyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "daily",
    schedule_type: "hour",
    scheduled_at: "17:00",
    input_start: "-",
    input_finish: "-",
    is_locked: false,
    reminder: "",
    auto_insert_result: "1",
    daily_details: {
      selected_days: [],
    },
  });
  const [weeklyFreq, setWeeklyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "weekly",
    schedule_type: "day",
    scheduled_at: "friday-17:00",
    input_start: "",
    input_finish: "",
    is_locked: false,
    reminder: "",
    auto_insert_result: "1",
    weekly_details: {
      selected_weeks: [],
    },
  });

  const [monthlyFreq, setMonthlyFreq] = useState({
    goal_ma_id: locationPathGoalId,
    category: "goal",
    interval: "monthly",
    schedule_type: "date",
    scheduled_at: "25-17:00",
    input_start: "-",
    input_finish: "-",
    result_locked: 0,
    reminder: "",
    auto_insert_result: "1",
    is_locked: false,
    monthly_details: {
      selected_months: [],
    },
  });
  const [triggerCreateForm, setTriggerCreateForm] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );
  const [timeErrMessage, setTimeErrMessage] = useState("");

  // ================================================================
  // ~~~~~~~~~~~~~~ Side Effect ~~~~~~~~~~~~~~
  // ================================================================
  useEffect(() => {
    if (openUpdateFrequency) {
      const schdl_hourly =
        goal?.update_frequency === null
          ? "00"
          : goal?.update_frequency?.interval !== "hourly"
          ? "00"
          : goal?.update_frequency?.scheduled_at;

      const schdl_daily =
        goal?.update_frequency === null
          ? "17:00"
          : goal?.update_frequency?.interval !== "daily"
          ? "17:00"
          : goal?.update_frequency?.scheduled_at;

      const schdl_weekly =
        goal?.update_frequency === null
          ? "firiday-17:00"
          : goal?.update_frequency?.interval !== "weekly"
          ? "firiday-17:00"
          : goal?.update_frequency?.scheduled_at;

      const schdl_monthly =
        goal?.update_frequency === null
          ? "25-17:00"
          : goal?.update_frequency?.interval !== "monthly"
          ? "25-17:00"
          : goal?.update_frequency?.scheduled_at;

      setHourlyFreq({
        ...hourlyFreq,
        scheduled_at: schdl_hourly,
      });

      setDailyFreq({
        ...dailyFreq,
        scheduled_at: schdl_daily,
      });

      setWeeklyFreq({
        ...weeklyFreq,
        scheduled_at: schdl_weekly,
      });

      setMonthlyFreq({
        ...monthlyFreq,
        scheduled_at: schdl_monthly,
      });
    }
  }, [openUpdateFrequency, goal]);

  useEffect(() => {
    if (openUpdateFrequency && goal?.update_frequency === null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      Axios.get(
        `${URL_API}/swd/update-frequency/create?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goal.start_date}&input_finish=${goal.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Cret Freq", res);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openUpdateFrequency]);

  useEffect(() => {
    if (openUpdateFrequency && goal?.update_frequency !== null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      // // console.log("sched_at", sched_at);

      Axios.get(
        `${URL_API}/swd/update-frequency/edit/${locationPathGoalId}?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goal.start_date}&input_finish=${goal.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const freqExist = goal?.update_frequency;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Updte Freq", res);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openUpdateFrequency]);

  useEffect(() => {
    if (openUpdateFrequency && goal?.update_frequency === null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      Axios.get(
        `${URL_API}/swd/update-frequency/create?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goal.start_date}&input_finish=${goal.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Cret Freq", res);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [freq, triggerCreateForm]);

  useEffect(() => {
    if (openUpdateFrequency && goal?.update_frequency !== null) {
      let sched_at;

      if (freq.code === "hourly") {
        sched_at = hourlyFreq.scheduled_at;
      }
      if (freq.code === "daily") {
        sched_at = dailyFreq.scheduled_at;
      }
      if (freq.code === "weekly") {
        sched_at = weeklyFreq.scheduled_at;
      }
      if (freq.code === "monthly") {
        sched_at = monthlyFreq.scheduled_at;
      }

      // // console.log("sched_at", sched_at);

      Axios.get(
        `${URL_API}/swd/update-frequency/edit/${locationPathGoalId}?interval=${freq.code}&scheduled_at=${sched_at}&input_start=${goal.start_date}&input_finish=${goal.end_date}&goal_ma_id=${locationPathGoalId}`
      )
        .then((res) => {
          const result = res.data.data;
          const freqExist = goal?.update_frequency;
          const times =
            result?.fields?.hourly_details?.default_value?.selected_times;
          const timeRange = times.map((time) => {
            return {
              start: moment(time.start, [moment.ISO_8601, "HH:mm"]),
              finish: moment(time.finish, [moment.ISO_8601, "HH:mm"]),
            };
          });

          // console.log("Res Updte Freq", res);

          if (res.status === 200) {
            setFreqDetails({
              ...result.fields,
              auto_insert_result: result?.fields?.auto_insert_result,
              hourly_details: {
                default_value: {
                  ...result?.fields?.hourly_details?.default_value,
                  selected_times: timeRange,
                },
              },
              daily_details: {
                default_value: [
                  ...result?.fields?.daily_details?.default_value,
                ],
              },
              weekly_details: {
                default_value: [
                  ...result?.fields?.weekly_details?.default_value,
                ],
              },
              monthly_details: {
                default_value: [
                  ...result?.fields?.monthly_details?.default_value,
                ],
              },
            });
          }
        })
        .catch((error) => {
          // console.log("Error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [freq, triggerCreateForm]);

  useEffect(() => {
    if (openUpdateFrequency && goal?.update_frequency !== null) {
      const interval = goal?.update_frequency?.interval;

      setFreq({
        ...freq,
        code: interval,
        name: interval.charAt(0).toUpperCase() + interval.slice(1),
      });
    }
  }, [openUpdateFrequency]);

  // ================================================================
  // ~~~~~~~~~~~~~~ METHODs ~~~~~~~~~~~~~~
  // ================================================================
  const handleFreq = (data) => {
    setFreq(data);
  };

  const handleSaveFreq = () => {
    const data =
      freq.code === "daily"
        ? dailyFreq
        : freq.code === "hourly"
        ? hourlyFreq
        : freq.code === "weekly"
        ? weeklyFreq
        : monthlyFreq;

    // console.log("SAVE FREQ", data);
    // // console.log("Extist FREQ", freq.code);

    setLoading(true);

    Axios.post(`${URL_API}/swd/update-frequency/store`, data)
      .then((res) => {
        setOpenUpdateFrequency(false);
        setLoading(false);
        setTimeErrMessage("");
        setTriggerProject(Math.floor(Math.random() * 100));
      })
      .catch((error) => {
        setLoading(false);
        // console.log("EROR POST FREQ", error);

        // error response
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);

        if (
          listError.length === 1 &&
          listError[0]["key"] === "hourly_details"
        ) {
          setTimeErrMessage(listError[0]["description"]);
        } else {
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        }
      });
  };

  const handleUpdateFreq = () => {
    const data =
      freq.code === "daily"
        ? dailyFreq
        : freq.code === "hourly"
        ? hourlyFreq
        : freq.code === "weekly"
        ? weeklyFreq
        : monthlyFreq;

    // console.log("UPDATE FREQ Data", data);
    // // console.log("Extist FREQ", freq.code);

    setLoading(true);

    Axios.post(
      `${URL_API}/swd/update-frequency/update/${locationPathGoalId}`,
      data
    )
      .then((res) => {
        setOpenUpdateFrequency(false);
        setLoading(false);
        setTimeErrMessage("");
        setTriggerProject(Math.floor(Math.random() * 100));
      })
      .catch((error) => {
        setLoading(false);
        // console.log("EROR POST FREQ", error);

        // error response
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);

        if (
          listError.length === 1 &&
          listError[0]["key"] === "hourly_details"
        ) {
          setTimeErrMessage(listError[0]["description"]);
        } else {
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openUpdateFrequency}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            // backgroundImage: `url(${bgTitle})`,
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
        >
          <Typography variant="h4" style={{ fontFamily: "Lato" }}>
            Update Frequency
          </Typography>
        </DialogTitle>

        <DialogContent style={{ minWidth: 550 }}>
          {/* =========== Frequency =========== */}
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Frequency</b>
            </Typography>

            <Box ml={10}>
              {freq.selected !== null &&
                freqDetails.interval.default_value.length > 0 &&
                freqDetails.interval.default_value.map((item, i) => (
                  <ListFreqItem
                    key={i}
                    classes={classes}
                    item={item}
                    freq={freq}
                    onFreq={handleFreq}
                  />
                ))}
            </Box>
          </Box>

          {/* =========== Scheduled =========== */}
          {freq.code === "monthly" && (
            <FreqMonthly
              classes={classes}
              goalDetail={goal}
              onMonthlyFreq={setMonthlyFreq}
              monthlyFreq={monthlyFreq}
              monthList={freqDetails.monthly_details.default_value}
              remindList={freqDetails.reminder.default_value}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
          {freq.code === "hourly" && (
            <FreqHourly
              classes={classes}
              goalDetail={goal}
              onHourlyFreq={setHourlyFreq}
              hourlyFreq={hourlyFreq}
              remindList={freqDetails.reminder?.default_value}
              dayList={
                freqDetails?.hourly_details?.default_value?.selected_days
              }
              timeList={
                freqDetails?.hourly_details?.default_value?.selected_times
              }
              timeErrMessage={timeErrMessage}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
          {freq.code === "weekly" && (
            <FreqWeekly
              classes={classes}
              onWeeklyFreq={setWeeklyFreq}
              weeklyFreq={weeklyFreq}
              dailyList={freqDetails.daily_details.default_value}
              weeklyList={freqDetails.weekly_details.default_value}
              remindList={freqDetails.reminder.default_value}
              goal={goal}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
          {freq.code === "daily" && (
            <FreqDaily
              classes={classes}
              onDailyFreq={setDailyFreq}
              dailyFreq={dailyFreq}
              goalDetail={goal}
              dayList={freqDetails?.daily_details?.default_value}
              remindList={freqDetails.reminder?.default_value}
              autoInsert={freqDetails.auto_insert_result.default_value}
              onTrigger={setTriggerCreateForm}
            />
          )}
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 15,
            marginTop:
              freq.code === "weekly" ? 100 : freq.code === "monthly" ? 100 : 50,
          }}
        >
          <Box>
            {listError.length > 0
              ? listError.map((item, i) => {
                  return (
                    <p key={i} style={{ color: "#cc0707", fontSize: 13 }}>
                      ⚠️ {item?.description}
                    </p>
                  );
                })
              : null}
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              disableElevation
              size="medium"
              className={classes.button}
              onClick={() => {
                setOpenUpdateFrequency(false);
                setTimeErrMessage("");
              }}
            >
              Cancel
            </Button>
            <Box mx={1} />
            <Button
              variant="contained"
              size="medium"
              className={classes.button1}
              // disabled={freq.code === "hourly"}
              onClick={
                goal?.update_frequency === null
                  ? handleSaveFreq
                  : handleUpdateFreq
              }
            >
              {loading ? "Saving..." : "Save"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogUpdateFrequency;

const defaultFreqDetails = {
  auto_insert_result: {
    default_value: "1",
    label: "Auto Insert Result",
  },
  goal_ma_id: {
    label: "Goal/ MA Id",
    default_value: null,
  },
  category: {
    label: "Categori",
    default_value: [],
  },
  interval: {
    label: "Interval",
    default_value: [],
  },
  schedule_type: {
    label: "Schedule Type",
    default_value: [],
  },
  scheduled_at: {
    label: "Scheduled At",
    default_value: null,
  },
  input_start: {
    label: "Start",
    default_value: null,
  },
  input_finish: {
    label: "Finish",
    default_value: null,
  },
  hourly_details: {
    label: "Hourly Details",
    default_value: {
      break_times: [],
    },
  },
  daily_details: {
    label: "Daily Details",
    default_value: [],
  },
  weekly_details: {
    label: "Weekly Details",
    default_value: [],
  },
  monthly_details: {
    label: "Monthly Details",
    default_value: [],
  },
  reminder: {
    label: "Reminder",
    default_value: [],
  },
};
