import React, { useEffect, useContext, useState } from "react";
import { Box, Drawer } from "@material-ui/core";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";

import AxiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import { styles, theme } from "./Style/StyleTask";

import Table from "./Components/Table";
import Action from "./Components/Action";
import Label from "./Components/Action/Label";
import TodoDetails from "./Components/Action/TodoDetails";
import ContextTask from "./Context/ContextTask";
import { DRAWER_OPEN, TODOS_COLLECTION } from "./Context/types";
import mapTable from "./Components/global/mapTable";
import handleError from "../Report/components/global/handleError";

import DialogError from "../../components/DialogError";

const useStyles = makeStyles(styles);

const ViewTaskNew = () => {
  const classes = useStyles();
  const { triggerTodo, todoDispatch, todoState } = useContext(ContextTask);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    todoDispatch({
      type: TODOS_COLLECTION,
      payload: {
        loadTodos: true,
        todoData: todoState.todoData,
        count: 0,
      },
    });

    // ===== MULTIPLE VALUES ======
    const owners =
      todoState.ownerId.length > 0
        ? todoState.ownerId
            .map((el, i) => `options[filter][fields][owner_id][${i}]=${el.id}`)
            .join("&")
        : "";
    const prirorities =
      todoState.priorityId.length > 0
        ? todoState.priorityId
            .map((el, i) => `options[filter][fields][priority][${i}]=${el}`)
            .join("&")
        : "";
    const statuses =
      todoState.statusId.length > 0
        ? todoState.statusId
            .map((el, i) => `options[filter][fields][status][${i}]=${el}`)
            .join("&")
        : "";

    // // console.log("PRIO", prirorities);

    // ===== DEFINES ======
    const root_url = `todolist?options[paging][limit]=${todoState.pageLimit}&options[paging][offset]=${todoState.pageOffset}&options[filter][search]=${todoState.search}&options[filter][not_project]=true&options[sort][0][field]=created_at&options[sort][0][method]=desc`;
    const owner = `${owners}`;
    const status = `${statuses}`;
    const priority = `${prirorities}`;
    const completed = `options[filter][fields][is_complete]=${todoState.completedId}`;
    const sort_name = `options[sort][1][field]=title&options[sort][1][method]=asc`;
    const sort_date = `options[sort][2][field]=due_date&options[sort][2][method]=asc`;

    // // console.log("owner", owner);
    // // console.log("status", status);
    // // console.log("priority", priority);
    console.log("completed", completed);
    // // console.log("sort_name", sort_name);
    // // console.log("sort_date", sort_date);

    const arrFilter = [
      owner,
      status,
      priority,
      completed,
      sort_name,
      sort_date,
    ];

    const selectArr = arrFilter.filter((item) => item !== "");
    const combinedURL = selectArr.join("&");

    // console.log("arrFilter", arrFilter);
    // console.log("selectArr", selectArr);
    // console.log("combinedURL", combinedURL);

    // TESTING FILTER

    AxiosConfig.get(`${URL_API}/${root_url}&${combinedURL}`)
      .then((res) => {
        const result = res.data.data;
        const count = res.data.info.count;

        if (res.status === 200) {
          const mapTodos = mapTable(result);

          todoDispatch({
            type: TODOS_COLLECTION,
            payload: {
              loadTodos: false,
              todoData: mapTodos,
              count,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
        todoDispatch({
          type: TODOS_COLLECTION,
          payload: {
            loadTodos: false,
            todoData: [],
            count: 0,
          },
        });

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  }, [triggerTodo]);

  const handleDrawer = () => {
    todoDispatch({
      type: DRAWER_OPEN,
      payload: {
        drawer: false,
      },
    });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box m={10} mr={2}>
        <Action classes={classes} />
        <Label classes={classes} />

        <Table classes={classes} />

        <Drawer anchor="right" open={todoState.drawer} onClose={handleDrawer}>
          <TodoDetails classes={classes} />
        </Drawer>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default ViewTaskNew;
