import React, { useState, useContext } from "react";
import {
  Box,
  IconButton,
  Typography,
  Card as Cardz,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";

import TextareaAutosize from "react-textarea-autosize";
import { DeleteOutline } from "@material-ui/icons";
import LockRoundedIcon from "@material-ui/icons/LockRounded";

import { Draggable } from "react-beautiful-dnd";
import moment from "moment";

import ContextProject from "../../context/ContextProject";
import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";

import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
import CardOwner from "./card/CardOwner";
import CardStatus from "./card/CardStatus";
import DialogKanbanDetail from "./DialogKanbanDetail";

const Card = ({ card, index, listId, classes }) => {
  const { dataKanban, setDataKanban, setTriggerKanban } =
    useContext(ContextProject);

  const [open, setOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(card.title);
  const [loadCard, setLoadCard] = useState(false);

  const [openKanbanDetail, setOpenKanbanDetail] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const updateCardTitle = (title, index, listId) => {
    const list = dataKanban.lists[listId];
    list.cards[index].title = title; // which list changed
    const getCard = list.cards[index];

    const newState = {
      ...dataKanban,
      lists: {
        ...dataKanban.lists,
        [listId]: list,
      },
    };
    setDataKanban(newState);

    const data = {
      Todolist: {
        title,
        description: getCard.description,
        remarks: getCard.description,
        owner_id: getCard.owner_id,
        status: getCard.status?.id,
        priority: getCard.priority?.id,
        due_date: moment(getCard.due_date).format("YYYY-MM-DD HH:mm:ss"),
        start_date: moment(getCard.start_date).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(getCard.end_date).format("YYYY-MM-DD HH:mm:ss"),
        tags: getCard.tags,
        actual_result: Number(getCard.actual_result),
        target_result: Number(getCard.target_result),
        project_id: getCard.project_id,
        task_group_id: getCard.task_group_id,
        predecessor_id: getCard.dependencies,
        custom_phase_id: getCard?.custom_phase_id,
      },
    };

    setLoadCard(true);

    AxiosConfig.put(`${URL_API}/todolist/${getCard.id}`, data)
      .then((res) => {
        // // console.log("Res edit title", res);
        if (res.status === 200) {
          setTriggerKanban(Math.floor(Math.random() * 100));
          setLoadCard(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadCard(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const removeCard = (index, listId) => {
    const list = dataKanban.lists[listId];

    const getCard = list.cards[index];

    setLoadCard(true);

    AxiosConfig.delete(`${URL_API}/todolist/${getCard.id}`)
      .then((res) => {
        if (res.status === 200) {
          setTriggerKanban(Math.floor(Math.random() * 100));
          setLoadCard(false);

          list.cards.splice(index, 1);
          const newState = {
            ...dataKanban,
            lists: {
              ...dataKanban.lists,
              [listId]: list,
            },
          };
          setDataKanban(newState);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadCard(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleOnBlur = () => {
    updateCardTitle(newTitle, index, listId);
    setOpen(!open);
  };

  // ===========================
  // POLICY For Kanban Card
  const newPolicy = card?.policy?.reduce(
    (obj, item) => ({
      ...obj,
      [item.actionName]: item.result,
    }),
    {}
  );

  return (
    <>
      <Draggable
        draggableId={card.id}
        isDragDisabled={!newPolicy?.update}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            <Cardz variant="outlined" style={{ marginBottom: 6 }}>
              {open ? (
                <Box p={1}>
                  <Box
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <TextareaAutosize
                      type="text"
                      className="input-card-title"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onBlur={handleOnBlur}
                      disabled={loadCard}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleOnBlur();
                        }
                        return;
                      }}
                      autoFocus
                    />
                    <IconButton
                      size="small"
                      disabled={loadCard}
                      onClick={() => {
                        removeCard(index, listId);
                      }}
                    >
                      <DeleteOutline style={{ fontSize: 18 }} />
                    </IconButton>
                  </Box>
                  <CardOwner classes={classes} card={card} />
                  <Box my={1} />
                  <CardStatus classes={classes} card={card} />
                </Box>
              ) : (
                <Box p={1}>
                  <Box
                    mb={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      {!newPolicy?.update ? (
                        <Tooltip title="Anda tidak memiliki akses">
                          <LockRoundedIcon
                            style={{
                              color: "#DE6775",
                              fontSize: 16,
                              marginRight: 4,
                            }}
                          />
                        </Tooltip>
                      ) : null}
                      <Typography
                        variant="subtitle2"
                        className={classes.title555}
                        onClick={
                          !newPolicy?.update ? null : () => setOpen(!open)
                        }
                      >
                        {card.title}
                      </Typography>
                    </Box>
                    <IconButton
                      size="small"
                      disabled={loadCard || !newPolicy?.delete}
                      onClick={() => {
                        removeCard(index, listId);
                      }}
                    >
                      <DeleteOutline style={{ fontSize: 18 }} />
                    </IconButton>
                  </Box>
                  <Box
                    onClick={
                      !newPolicy?.viewDetail
                        ? null
                        : () => setOpenKanbanDetail(true)
                    }
                  >
                    <CardOwner classes={classes} card={card} />
                    <Box my={1} />
                    <CardStatus classes={classes} card={card} />
                  </Box>
                </Box>
              )}

              {loadCard && <LinearProgress />}
            </Cardz>
          </div>
        )}
      </Draggable>

      <DialogKanbanDetail
        classes={classes}
        item={card}
        openKanbanDetail={openKanbanDetail}
        setOpenKanbanDetail={setOpenKanbanDetail}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default Card;
