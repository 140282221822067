import React, { useCallback, useContext } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

import { useDebouncedCallback } from 'use-debounce';

import { SEARCH_CARDS, SEARCH_TASK } from '../../context/types';
import ContextProject from '../../context/ContextProject';

import AnchorTaskSettings from '../anchor/AnchorTaskSettings';
import AnchorTaskList from '../anchor/AnchorTaskList';
// import AnchorFilterTask from "../anchor/AnchorFilterTask";
import AnchorQuickTask from '../anchor/AnchorQuickTask';
import AnchorSortTask from '../anchor/AnchorSortTask';

import ListFilterSort from './ListFilterSort';
import AnchorFilterKanban from '../anchor/AnchorFilterKanban';
import AnchorKanbanSetting from '../anchor/AnchorKanbanSetting';

const TaskInfoTop = ({ classes, project, tabValue }) => {
  const { projectTDispatch, setTriggerTask, setTriggerKanban } =
    useContext(ContextProject);

  const [handleSearch] = useDebouncedCallback(
    useCallback(
      (value) => {
        // console.log("{valueTab", tabValue);

        if (Number(tabValue) !== 2) {
          projectTDispatch({
            type: SEARCH_TASK,
            payload: { searchTask: value },
          });
          setTriggerTask(Math.floor(Math.random() * 100));
        }
        if (Number(tabValue) === 2) {
          projectTDispatch({
            type: SEARCH_CARDS,
            payload: { searchCards: value },
          });
          setTriggerKanban(Math.floor(Math.random() * 100));
        }
      },
      [tabValue]
    ),
    1000,
    { maxWait: 5000 }
  );

  // // console.log("{RPJ", project);
  // // console.log("{tabValue", tabValue);

  return (
    <Box
      pl={8}
      display="flex"
      justifyContent="flex-end"
      flexDirection="column"
      alignItems="space-between"
    >
      <TextField
        size="small"
        variant="outlined"
        placeholder={`Search ${tabValue !== 2 ? 'Tasks' : 'Cards'}`}
        onChange={(e) => handleSearch(e.target.value)}
        style={{
          outline: 'none',
          backgroundColor: '#fff',
          border: '1px solid #DFDFDF',
          fontSize: 16,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon
                style={{ color: '#B1B1B1', fontSize: 16 }}
              />
            </InputAdornment>
          ),
        }}
      />
      {project?.project_type === 'TEAM' && (
        <Box
          mt={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ListFilterSort classes={classes} />

          {tabValue !== 2 && <AnchorSortTask classes={classes} />}
          {tabValue !== 2 && <Box mx={0.5} />}
          {tabValue !== 2 && <AnchorQuickTask classes={classes} />}
          {tabValue !== 2 && <Box mx={0.5} />}
          {tabValue !== 2 && (
            <AnchorTaskSettings classes={classes} project={project} />
          )}
          {tabValue !== 2 && <Box mx={0.5} />}
          {tabValue === 0 && (
            <AnchorTaskList classes={classes} project={project} />
          )}
          {tabValue === 2 && (
            <AnchorKanbanSetting classes={classes} />
          )}
          {tabValue === 2 && <Box mx={0.5} />}
          {tabValue === 2 && <AnchorFilterKanban classes={classes} />}
        </Box>
      )}

      <Box mt={1} display="flex" justifyContent="flex-end">
        <Tooltip title="Setiap Task terdapat Checklist yang harus di centang">
          <Box display="flex" alignItems="center">
            <InfoRoundedIcon
              style={{ fontSize: 14, color: '#555' }}
            />
            <Box mx={0.2} />
            <Typography
              variant="caption"
              className={classes.title333}
            >
              Informasi
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TaskInfoTop;
