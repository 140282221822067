import React from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

import logo from "../assets/images/performate.png";

const theme = createMuiTheme({
  //    spacing: 1,

  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiTypography: {
      root: {
        // textTransform: 'lowercase'
      },
      // button: {
      //     textTransform: 'lowercase',
      //     color: 'red'
      // }
    },
  },
});

// const useStyles = makeStyles(theme => ({
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "grey",
  },
  title: {
    flexGrow: 1,
    color: "grey",
    paddingBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  question: {
    color: "grey",
    textTransform: "capitalize",
  },
  buttonAction: {
    textTransform: "capitalize",
  },
  logoText: {
    flexGrow: 1,
    width: 120,
    height: 24,
  },
  logo: {
    width: 30,
    height: 30,
  },
});

const HeaderMembershipOptions = (props) => {
  // const classes = useStyles();
  const { classes } = props;

  const currentUrl = window.location.pathname;

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="sticky">
        <div className={classes.root}>
          <Toolbar>
            <Typography className={classes.title}>
              <img
                src={logo}
                alt="logo-text"
                className={classes.logoText}
                style={{
                  width: 132,
                  height: 32,
                }}
              />
            </Typography>
          </Toolbar>
        </div>
      </AppBar>
    </MuiThemeProvider>
  );
};

// export default Header;
export default withStyles(styles)(HeaderMembershipOptions);
