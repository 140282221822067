import React, { useState, useContext } from "react";
import {
  Box,
  Slider,
  Divider,
  Button,
  Input,
  Typography,
  InputAdornment,
  Badge,
  Menu,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const AnchorFilterWeight = ({ classes }) => {
  const context = useContext(ContextGoalDetail);
  const goalId = window.location.pathname.split("/")[2];

  const [value, setValue] = useState([0, 100]);
  const [anchorFilterMA, setAnchorFilterMA] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function valuetext(value) {
    return `${value}%`;
  }

  const handleBlur = () => {
    if (value < 0) {
      setValue([0, 100]);
    } else if (value > 100) {
      setValue([0, 100]);
    }
  };

  const handleInputStart = (event) => {
    const val = Number(event.target.value);
    setValue([val, value[1]]);
  };
  const handleInputEnd = (event) => {
    const val = Number(event.target.value);
    setValue([value[0], val]);
  };

  const handleShowFilterMA = (event) => {
    setAnchorFilterMA(event.currentTarget);
  };

  const handleCloseFilterMA = () => {
    setAnchorFilterMA(null);
  };

  const handleShowResult = () => {
    context.setLoaderSpecialMa(true);
    setLoading(true);

    let URL = "";
    const targetURL = `${URL_API}/swd/measured-activity?options[filter][goal_id]=${goalId}&options[filter][weighting][from]=${value[0]}&options[filter][weighting][to]=${value[1]}`;
    const minWeight = `&options[filter][weighting][from]=${value[0]}`;
    const maxWeight = `&options[filter][weighting][to]=${value[1]}`;

    if (value[0] === 0 && value[1] === 100) {
      URL = targetURL;
    } else {
      URL = `${targetURL}${minWeight}${maxWeight}`;
    }

    AxiosConfig.get(URL)
      .then((response) => {
        // console.log("Res COLLECTION MA weight : ", response);

        if (response.status === 200) {
          if (response.data.data !== null) {
            context.setColletionMA(response.data.data);

            context.setWeightValue({ min: value[0], max: value[1] });

            setAnchorFilterMA();
          }
        }
        context.setLoaderSpecialMa(false);
        setLoading(false);
      })
      .catch((error) => {
        context.setLoaderSpecialMa(false);
        // console.log("Error : ", error);
        setLoading(false);
      });
  };

  return (
    <>
      <StyledBadge
        badgeContent={`${value[0]} - ${value[1]}`}
        // style={{ marginRight: 30, marginTop: 16 }}
        children={
          <Button
            className={classes.btnFilter}
            variant="outlined"
            startIcon={<FilterListIcon />}
            endIcon={<ExpandMoreIcon />}
            onClick={handleShowFilterMA}
          >
            Filter Weight
          </Button>
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorFilterMA}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorFilterMA)}
        onClose={handleCloseFilterMA}
      >
        <Box m={2} width={200}>
          <Box my={1}>
            <Typography
              className={classes.title888}
              variant="subtitle2"
              gutterBottom
            >
              Weight Range
            </Typography>
          </Box>

          <Slider
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
          />

          <Box m={1} display="flex" justifyContent="space-between">
            <Input
              className={classes.input}
              value={value[0]}
              margin="dense"
              onChange={handleInputStart}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
            <Input
              className={classes.input}
              value={value[1]}
              margin="dense"
              onChange={handleInputEnd}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: 100,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
          </Box>

          <Box mt={2} mb={1.5}>
            <Divider />
          </Box>

          <Box my={2} mb={1} display="flex" justifyContent="flex-end">
            <Button
              size="small"
              variant="outlined"
              className={classes.button0}
              onClick={handleCloseFilterMA}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              className={classes.button1}
              style={{ marginLeft: 7.5 }}
              onClick={handleShowResult}
            >
              {loading ? "Loading..." : "Show results"}
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default AnchorFilterWeight;
