import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Radio,
  FormControlLabel,
  Typography,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMore from "@material-ui/icons/ExpandMoreRounded";
import ExpandLess from "@material-ui/icons/ExpandLessRounded";

import classNames from "classnames";
import clsx from "clsx";

import ContextProject from "../../context/ContextProject";
import { SORT_TASK } from "../../context/types";

const AnchorSortTask = ({ classes }) => {
  const project_id = window.location.pathname.split("/")[2];
  const { projectTDispatch, projectTState, setTriggerTask } =
    useContext(ContextProject);

  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState("");
  const [dateMethod, setDateMethod] = useState("");
  const [titleMethod, setTitleMethod] = useState("");

  const [openDate, setOpenDate] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);

  const handleDate = () => setOpenDate((s) => !s);
  const handleTitle = () => setOpenTitle((s) => !s);

  const ExpandTrue = () => (
    <ExpandMore style={{ color: "#8F8F8F" }} fontSize="small" />
  );
  const ExpandFalse = ({ color }) => (
    <ExpandLess style={{ color }} fontSize="small" />
  );

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleChooseDate = (value) => {
    setDate(value);
    setTitleMethod("");
  };

  const handleChooseDateMethod = (value) => {
    setDateMethod(value);
    setTitleMethod("");
  };

  const handleChooseTitleMethod = (value) => {
    setTitleMethod(value);
    setDateMethod("");
    setDate("");
  };

  const handleResetSort = () => {
    setDate("");
    setDateMethod("");
    setTitleMethod("");

    projectTDispatch({
      type: SORT_TASK,
      payload: {
        sortByDate: null,
        sortTitleTask: null,
      },
    });
  };

  const handleSortTask = () => {
    const sortByDate = `options[sort][1][field]=${date}&options[sort][1][method]=${
      dateMethod === "" ? "asc" : dateMethod
    }`;
    const sortTitle = `options[sort][0][field]=title&options[sort][0][method]=${
      titleMethod === "" ? "asc" : titleMethod
    }`;

    projectTDispatch({
      type: SORT_TASK,
      payload: {
        sortByDate: date !== "" ? sortByDate : null,
        sortTitleTask: titleMethod !== "" ? sortTitle : null,
      },
    });

    if (dateMethod !== null && dateMethod === "") {
      setDateMethod("asc");
    }

    setTriggerTask(Math.floor(Math.random() * 100));
    handleClose();

    // // console.log("date", date);
    // // console.log("dateMethod", dateMethod);
    // // console.log("titleMethod", titleMethod);
  };

  return (
    <div>
      <Tooltip title="Sort Task">
        <Button
          size="small"
          variant="outlined"
          className={classes.btnIcon}
          onClick={handleShow}
        >
          <FilterListIcon style={{ color: "#555" }} />
        </Button>
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                <Box minWidth={300}>
                  <Box m={2} display="flex" justifyContent="space-between">
                    <Typography
                      variant="subtitle2"
                      className={classes.title888}
                    >
                      <b>Sort</b>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtLink}
                      onClick={handleResetSort}
                    >
                      Reset
                    </Typography>
                  </Box>

                  <List dense>
                    <ListItem
                      button
                      onClick={handleDate}
                      style={{
                        backgroundColor: openDate && "rgba(251, 234, 234, 0.5)",
                        color: openDate && "#C8203C",
                      }}
                    >
                      <ListItemText primary="Sort By Date" />
                      {openDate ? (
                        <ExpandFalse color="#C8203C" />
                      ) : (
                        <ExpandTrue />
                      )}
                    </ListItem>
                    <Collapse in={openDate} timeout="auto" unmountOnExit>
                      <Box pt={1} pb={2} px={3} display="flex" flexWrap="wrap">
                        {dateCollections.map((item, i) => {
                          return (
                            <div key={i}>
                              <FormControlLabel
                                label={
                                  <p
                                    style={{ color: "#555", fontSize: 14 }}
                                    className={classes.title}
                                  >
                                    {item.name}
                                  </p>
                                }
                                control={
                                  <Radio
                                    className={classes.rootRadio}
                                    disableRipple
                                    name={item.name}
                                    value={date}
                                    checked={item.code === date}
                                    onClick={() => handleChooseDate(item.code)}
                                    checkedIcon={
                                      <span
                                        className={clsx(
                                          classes.iconRadio,
                                          classes.checkedIconRadio
                                        )}
                                      />
                                    }
                                    icon={
                                      <span className={classes.iconRadio} />
                                    }
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </Box>

                      <Box mt={1} px={3}>
                        <Typography
                          variant="caption"
                          className={classes.title888}
                        >
                          <b>Method</b>
                        </Typography>
                      </Box>
                      <Box pt={1} pb={2} px={3} display="flex" flexWrap="wrap">
                        {methodCollections.map((item, i) => {
                          return (
                            <div key={i}>
                              <FormControlLabel
                                label={
                                  <p
                                    style={{ color: "#555", fontSize: 14 }}
                                    className={classes.title}
                                  >
                                    {item.name}
                                  </p>
                                }
                                control={
                                  <Radio
                                    className={classes.rootRadio}
                                    disableRipple
                                    name={item.name}
                                    value={dateMethod}
                                    checked={item.code === dateMethod}
                                    onClick={() =>
                                      handleChooseDateMethod(item.code)
                                    }
                                    checkedIcon={
                                      <span
                                        className={clsx(
                                          classes.iconRadio,
                                          classes.checkedIconRadio
                                        )}
                                      />
                                    }
                                    icon={
                                      <span className={classes.iconRadio} />
                                    }
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </Box>
                    </Collapse>

                    <ListItem
                      button
                      onClick={handleTitle}
                      style={{
                        backgroundColor:
                          openTitle && "rgba(251, 234, 234, 0.5)",
                        color: openTitle && "#C8203C",
                      }}
                    >
                      <ListItemText primary="Sort By Title" />
                      {openTitle ? (
                        <ExpandFalse color="#C8203C" />
                      ) : (
                        <ExpandTrue />
                      )}
                    </ListItem>
                    <Collapse in={openTitle} timeout="auto" unmountOnExit>
                      <Box pt={1} pb={2} px={3} display="flex" flexWrap="wrap">
                        {methodCollections.map((item, i) => {
                          return (
                            <div key={i}>
                              <FormControlLabel
                                label={
                                  <p
                                    style={{ color: "#555", fontSize: 14 }}
                                    className={classes.title}
                                  >
                                    {item.name}
                                  </p>
                                }
                                control={
                                  <Radio
                                    className={classes.rootRadio}
                                    disableRipple
                                    name={item.name}
                                    value={titleMethod}
                                    checked={item.code === titleMethod}
                                    onClick={() =>
                                      handleChooseTitleMethod(item.code)
                                    }
                                    checkedIcon={
                                      <span
                                        className={clsx(
                                          classes.iconRadio,
                                          classes.checkedIconRadio
                                        )}
                                      />
                                    }
                                    icon={
                                      <span className={classes.iconRadio} />
                                    }
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </Box>
                    </Collapse>
                  </List>
                </Box>

                <Box m={2}>
                  <Typography variant="caption" className={classes.title888}>
                    Sorting hanya diperbolehkan satu item saja
                  </Typography>
                </Box>

                <Box
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    size="small"
                    varian="outlined"
                    className={classes.button}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Box mx={0.5} />
                  <Button
                    size="small"
                    variant={
                      (date !== "" && dateMethod === "") ||
                      (date === "" && titleMethod === "")
                        ? "contained"
                        : "outlined"
                    }
                    className={classes.button1}
                    disabled={
                      (date !== "" && dateMethod === "") ||
                      (date === "" && titleMethod === "")
                    }
                    onClick={handleSortTask}
                  >
                    Show Result
                  </Button>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AnchorSortTask;

const dateCollections = [
  {
    id: "1",
    code: "start_date",
    name: "Start Date",
  },
  {
    id: "2",
    code: "due_date",
    name: "Due Date",
  },
  {
    id: "3",
    code: "end_date",
    name: "End Date",
  },
  {
    id: "4",
    code: "created_at",
    name: "Cretaed At",
  },
];

const methodCollections = [
  {
    id: "1",
    code: "asc",
    name: "Ascending",
  },
  {
    id: "2",
    code: "desc",
    name: "Descending",
  },
];
