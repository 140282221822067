import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button,
  MenuItem,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Radio,
} from "@material-ui/core";

import axios from "axios";
import { useTimer } from "react-timer-hook";

import { URL_API } from "../../../../constants/config-api";
import Redirect from "../../../../utilities/Redirect";
import {
  ToMembershipPaymentStatusAfterCountDown,
  ToCompletionProfile,
} from "../../../../constants/config-redirect-url";

import bank_bca from "../../../../assets/images/bank-bca.png";
import bank_bni from "../../../../assets/images/bank-bni.png";
import bank_bri from "../../../../assets/images/bank-bri.png";
import bank_mandiri from "../../../../assets/images/bank-mandiri.png";
import Group_727 from "../../../../assets/images/Group-727.png";
import PictInfo from "../../../../assets/images/icon-info-24px.svg";

const numeral = require("numeral");

const CountDown = ({ expiryTimestamp, classes }) => {
  const { seconds, minutes, hours, days, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp,
      onExpire: () => console.warn("onExpire called"),
    });

  return (
    <table align="center">
      <tr>
        <td align="center" className="timer">
          <b>{days}</b>
        </td>
        <td align="center" className="timer">
          :
        </td>
        <td align="center" className="timer">
          <b>{hours}</b>
        </td>
        <td align="center" className="timer">
          :
        </td>
        <td align="center" className="timer">
          <b>{minutes}</b>
        </td>
        <td align="center" className="timer">
          :
        </td>
        <td align="center" className="timer">
          <b>{seconds}</b>
        </td>
      </tr>
      <tr>
        <td align="center">
          <Typography variant="subtitle1" className={classes.title}>
            <b>Hari</b>
          </Typography>
        </td>
        <td align="center"></td>

        <td align="center">
          <Typography variant="subtitle1" className={classes.title}>
            <b>Jam </b>
          </Typography>
        </td>
        <td align="center"></td>

        <td align="center">
          <Typography variant="subtitle1" className={classes.title}>
            <b>Menit</b>
          </Typography>
        </td>
        <td align="center"></td>

        <td align="center">
          <Typography variant="subtitle1" className={classes.title}>
            <b>Detik</b>
          </Typography>
        </td>
      </tr>
    </table>
  );
};

const DialogModalTransferOptionBerbayar = (props) => {
  const {
    classes,
    userTokenState,
    nominalAfterDiscount,
    isModalUpgradeSaveInvoice,
    setModalUpgradeSaveInvoice,
    invoiceNumber,
    expiredDatePayment,
  } = props;

  /*
        ````````````````````
        2019-07-18 01:45:20

        ````````````````````
    */
  let t = new Date(expiredDatePayment !== "" ? expiredDatePayment : null);
  t.setSeconds(t.getSeconds() + 600); // 10 minutes timer

  /*
        ````````````````````````
        HANDLE DOWNLOAD INVOICE

        ````````````````````````
    */
  const handleDownloadInvoice = () => {
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userTokenState,
    };

    axios.defaults.headers.common = header;

    console.log("userTokenState : ", userTokenState);
    console.log("invoiceNumber : ", invoiceNumber);

    axios
      .get(URL_API + `/account-management/payment/invoice/${invoiceNumber}`)
      .then(function (response) {
        // console.log("Response Original : ", response)
      })
      .catch(function (error) {
        // console.log("Error : ", error);
        setErrorModalResponse405(true);
      });
  };

  /*
        ````````````````````````
        HANDLE NEXT BUTTON 

        ````````````````````````
    */

  // const [ invoiceNumberGlobal, setInvoiceNumberGlobal ] = useGlobal('invoice_number');
  // const [ expiredDatePaymentGlobal, setExpiredDatePaymentGlobal ] = useGlobal('expired_date_payment');
  // const [ nominalAfterDiscountGlobal, setNominalAfterDiscountGlobal ] = useGlobal('nominal_after_discount');

  const handleNextButton = () => {
    const data = {
      invoice_number: invoiceNumber,
      expired_date_payment: expiredDatePayment,
      nominal_after_discount: nominalAfterDiscount,
    };

    localStorage.setItem("invoice_number", invoiceNumber);
    localStorage.setItem("expired_date_payment", expiredDatePayment);
    localStorage.setItem("nominal_after_discount", nominalAfterDiscount);

    // setInvoiceNumberGlobal(invoiceNumber);
    // setExpiredDatePaymentGlobal(expiredDatePayment);
    // setNominalAfterDiscountGlobal(nominalAfterDiscount);

    // localStorage.removeItem('dataPayment');

    // setGlobal(data);

    Redirect(ToCompletionProfile);
  };
  /*

        `````````````````````````````````````````````
        HANDLE DIALOG MODAL 405 - METHOD NOT ALLOWED

        `````````````````````````````````````````````
    */

  const [errorModalResponse405, setErrorModalResponse405] = useState(false);

  const handleCloseError405 = () => {
    setErrorModalResponse405(false);
    setModalUpgradeSaveInvoice(false);

    Redirect(ToCompletionProfile);
  };

  return (
    <div>
      <Dialog
        open={isModalUpgradeSaveInvoice}
        // onClose={() => setModalUpgradeSaveInvoice(false)}
        onClose={() => Redirect(ToCompletionProfile)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // style={{margin : 7}}
        maxWidth="sm"
        fullWidth={true}
        // fullScreen={true}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <img src={Group_727} alt="Pict " className={classes.pictInvoice} />
          <Typography
            variant="h5"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>Segera selesaikan pembayaran Anda !</b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="subtitle2" className={classes.title}>
            Sisa Waktu Pembayaran Anda:
          </Typography>

          {expiredDatePayment !== "" ? (
            <CountDown expiryTimestamp={t} classes={classes} />
          ) : (
            <Typography
              variant="subtitle2"
              className={classes.titleModal}
              style={{ color: "red" }}
            >
              Sistem <i>Count Down</i> mengalami masalah !
            </Typography>
          )}

          <br />
          <Typography variant="subtitle2" className={classes.titleModal}>
            Jumlah yang harus dibayar adalah :
          </Typography>

          <Typography
            variant="h4"
            className={classes.titleModal}
            style={{ color: "black" }}
          >
            <b>Rp {numeral(nominalAfterDiscount).format("0,0")}</b>
          </Typography>

          <br />
          <Paper elevation={0} className={classes.paperTigaDigitTerakhir}>
            <Typography variant="subtitle2" className={classes.titleModal}>
              Transfer sesuai jumlah pembayaran hingga 3 angka digit terakhir.
              Jika jumlah yang di transfer tidak sesuai, proses pembayaran Anda
              akan terhambat.
            </Typography>
          </Paper>
          <br />
          <Typography variant="subtitle2" className={classes.titleModal}>
            <b>Rekening Tujuan : </b>
          </Typography>
          <br />
          <img src={bank_bca} alt="Bank BCA" className={classes.imageBank} />
          <Typography variant="subtitle2" className={classes.titleModal}>
            a/n Deni Darmawan <br />
            Pondok Kelapa, Jakarta Timur <br />
            6330291662
          </Typography>

          <br />
          <Typography
            onClick={handleDownloadInvoice}
            variant="subtitle1"
            className={classes.titleModal}
            style={{ color: "#d13b4a", cursor: "pointer" }}
          >
            <b>
              Simpan <i>Invoice</i>
            </b>
          </Typography>

          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={handleNextButton}
            size="medium"
            className={classes.button}
          >
            Lanjut
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      {/* 
                `````````````````````````````````````
                DIALOG MODAL 405 - METHOD NOT ALLOWED

                `````````````````````````````````````

            */}

      <Dialog
        open={errorModalResponse405}
        onClose={handleCloseError405}
        aria-labelledby="alert-dialog-title-405"
        aria-describedby="alert-dialog-description-405"
      >
        <DialogTitle
          id="alert-dialog-title-405"
          style={{ textAlign: "center" }}
        >
          <img src={PictInfo} alt="Pict Info " />
          <br />
          <br />
          <Typography
            variant="h4"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>405 - Method Not Allowed</b>
          </Typography>
          <Typography variant="subtitle1" className={classes.title}>
            Silahkan check email Anda untuk melihat detail <i>invoice</i>.
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText
            id="alert-dialog-description-405"
            style={{ textAlign: "center" }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            // onClick={() => setModalUpgrade(false)}
            // onClick={handleNextButton }
            // color="primary"
            size="medium"
            className={classes.button}
            // fullWidth
          >
            OK, mengerti !
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </div>
  );
};

export default DialogModalTransferOptionBerbayar;
