import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import StickyFooter from "react-sticky-footer";
import DoneIcon from "@material-ui/icons/Done";

import Snackber from "../Components/Snackber";
import { styles } from "./Style/StyleViewIdentitasInfoTambahan";

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeKeluarga } from "../../../constants/config-redirect-url";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewIdentitasInfoTambahan = (props) => {
  const { classes } = props;

  /*
        `````````
        FORM DATA

        `````````
    */
  const [npwp, setNpwp] = useState("");
  const [hobi, setHobi] = useState("");
  const [citaCita, setCitaCita] = useState("");
  const [kendaraan, setKendaraan] = useState({
    name: "",
  });
  const [nomorSim, setNomorSim] = useState("");
  const [status, setStatus] = useState({
    name: "",
  });
  const [jumlahAnak, setJumlahAnak] = useState("");
  const [golDarah, setGolDarah] = useState("");

  const [facebook, setFacebook] = useState("");
  const [tweeter, setTweeter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");

  let paramsData = {
    npwp: npwp,
    hobi: hobi,
    cita_cita: citaCita,
    kendaraan: kendaraan.name,
    nomor_sim: nomorSim,
    status: status.name,
    jumlah_anak: jumlahAnak,
    gol_darah: golDarah,
    facebook: facebook,
    tweeter: tweeter,
    instagram: instagram,
    linkedin: linkedin,
  };

  const handleChangeNPWP = (e) => setNpwp(e.target.value);
  const handleChangeHobi = (e) => setHobi(e.target.value);
  const handleChangeCitaCita = (e) => setCitaCita(e.target.value);

  const handleChangeKendaraan = (name) => (event) => {
    setKendaraan({ ...kendaraan, [name]: event.target.value });
  };

  const handleChangeNomorSIM = (e) => setNomorSim(e.target.value);

  // const handleChangeStatus = (e) => setStatus(e.target.value);
  const handleChangeStatus = (name) => (event) => {
    setStatus({ ...status, [name]: event.target.value });
  };

  const handleChangeJumlahAnak = (e) => setJumlahAnak(e.target.value);
  const handleChangeGolDarah = (e) => setGolDarah(e.target.value);

  const handleChangeFacebook = (e) => setFacebook(e.target.value);
  const handleChangeTweeter = (e) => setTweeter(e.target.value);
  const handleChangeInstagram = (e) => setInstagram(e.target.value);
  const handleChangeLinkedin = (e) => setLinkedin(e.target.value);

  /*
        `````````````````````````````````````````

        HANDLE POST LANJUT & VALIDATION SNACKBAR

        `````````````````````````````````````````
    */

  //  const { register, handleSubmit, errors } = useForm(); // initialise the hook

  const [isFormFilledAll, setFormFilledAll] = useState(false);

  const [infoError, setInfoError] = useState(false);

  const handlePOSTLanjut = () => {
    // console.log(data);
    // setData(data);
    console.log(paramsData);
    localStorage.setItem(
      "data_identitas_info_tambahan",
      JSON.stringify(paramsData)
    );

    //*FIRST CONDITIONS
    // if(paramsData.npwp == ''){ setInfoError(true) }
    // if(paramsData.hobi == ''){ setInfoError(true) }
    // if(paramsData.cita_cita == ''){ setInfoError(true) }
    // if(paramsData.kendaraan == ''){ setInfoError(true) }
    // if(paramsData.nomor_sim == ''){ setInfoError(true) }
    // if(paramsData.status == ''){ setInfoError(true) }
    // if(paramsData.jumlah_anak == ''){ setInfoError(true) }
    // if(paramsData.gol_darah == ''){ setInfoError(true) }
    // if(paramsData.facebook == ''){ setInfoError(true) }
    // if(paramsData.tweeter == ''){ setInfoError(true) }
    // if(paramsData.instagram == ''){ setInfoError(true) }
    // if(paramsData.linkedin == ''){ setInfoError(true) }

    //*SECOND CONDITIONS
    // if( paramsData.npwp !== '', paramsData.hobi !== '' && paramsData.cita_cita !== '' && paramsData.kendaraan !== ''
    //     && paramsData.nomor_sim !== '' && paramsData.status !== '' && paramsData.jumlah_anak !== ''
    //     && paramsData.gol_darah !== '' && paramsData.facebook !== '' && paramsData.tweeter !== '' && paramsData.instagram !== ''
    //     && paramsData.linkedin !== ''

    // ){

    // console.log("Oke tersimpan semua di Local storage.. PART-3 [Informasi Tambahan]");
    Redirect(ToHrEmployeeKeluarga);

    // }
    // else {

    //     // alert('Field harus terisi semua !');
    //     setFormFilledAll(true);

    // };
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}
          >
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Identitas</b>
            </Typography>
            <ul>
              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Informasi Dasar</b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Alamat </b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Informasi Tambahan</b>
                </Typography>
              </li>
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: "left " }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}
          >
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Informasi Tambahan : </b>
            </Typography>

            <br />
            <List className={classes.list}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      {/* <b>NPWP<span style={{color: '#cc0707'}}>*</span></b> */}
                      <b>NPWP</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeNPWP}
                  value={npwp}
                  className={classes.textField}
                  // placeholder={'NIP'}
                  variant="outlined"
                  error={infoError == true && npwp == "" ? true : false}
                  helperText={
                    infoError == true && npwp == "" ? "Wajib di isi" : " "
                  }
                  // required={true}
                  // name='nip'
                  // color='primary'
                  // onKeyDown={handleEnterPress}
                  // disabled= {isLockedStatusState == 1 ? true : false}
                  // fullWidth
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Hobi</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeHobi}
                  value={hobi}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && hobi == "" ? true : false}
                  helperText={
                    infoError == true && hobi == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Cita- cita</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeCitaCita}
                  value={citaCita}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && citaCita == "" ? true : false}
                  helperText={
                    infoError == true && citaCita == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Kendaraan</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-select-currency"
                  select
                  // label="Pilih Negara : "
                  className={classes.textField}
                  value={kendaraan.name}
                  onChange={handleChangeKendaraan("name")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  error={
                    infoError == true && kendaraan.name == "" ? true : false
                  }
                  helperText={
                    infoError == true && kendaraan.name == ""
                      ? "Wajib di isi"
                      : " "
                  }
                >
                  {transportations.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Nomor SIM</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeNomorSIM}
                  value={nomorSim}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && nomorSim == "" ? true : false}
                  helperText={
                    infoError == true && nomorSim == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Status</b>
                    </Typography>
                  }
                />
                {/* <TextField
                                    id="outlined-bare"
                                    onChange= {handleChangeStatus}
                                    value={status}
                                    className={classes.textField}
                                    variant="outlined"
                                    error={infoError == true && status == '' ? true : false}
                                    helperText={infoError == true && status == '' ? "Wajib di isi" : ' '}
                                /> */}

                {/* marriageStatus */}

                <TextField
                  id="outlined-select-provinsi"
                  select
                  // label="Pilih Negara : "
                  className={classes.textField}
                  value={status.name}
                  onChange={handleChangeStatus("name")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {marriageStatus.map((option) => (
                    <MenuItem key={option.value} value={option}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Jumlah Anak</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeJumlahAnak}
                  value={jumlahAnak}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && jumlahAnak == "" ? true : false}
                  helperText={
                    infoError == true && jumlahAnak == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Golongan Darah</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeGolDarah}
                  value={golDarah}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && golDarah == "" ? true : false}
                  helperText={
                    infoError == true && golDarah == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <br />
              <br />
              <Typography variant="subtitle1" className={classes.titleHeader}>
                <b>
                  <i>Link</i> Sosial Media :
                </b>
              </Typography>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Facebook</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeFacebook}
                  value={facebook}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && facebook == "" ? true : false}
                  helperText={
                    infoError == true && facebook == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Twitter</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeTweeter}
                  value={tweeter}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && tweeter == "" ? true : false}
                  helperText={
                    infoError == true && tweeter == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Instagram</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeInstagram}
                  value={instagram}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && instagram == "" ? true : false}
                  helperText={
                    infoError == true && instagram == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>LinkedIn</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  onChange={handleChangeLinkedin}
                  value={linkedin}
                  className={classes.textField}
                  variant="outlined"
                  error={infoError == true && linkedin == "" ? true : false}
                  helperText={
                    infoError == true && linkedin == "" ? "Wajib di isi" : " "
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}
      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <StickyFooter bottomThreshold={10}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.buttonOutlined}
              style={{ margin: 14 }}
              // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
            >
              Keluar
            </Button>
          </StickyFooter>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <StickyFooter bottomThreshold={10}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              style={{ margin: 14 }}
              disabled={false}
              onClick={handlePOSTLanjut}
            >
              Lanjut
            </Button>
          </StickyFooter>
        </Grid>
      </Grid>

      {/*  

                `````````````````````
                VALIDATION SNACK BAR

                `````````````````````

            */}
      <Snackber
        isFormFilledAll={isFormFilledAll}
        setFormFilledAll={setFormFilledAll}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewIdentitasInfoTambahan);

const transportations = [
  {
    label: "Motor",
    value: "Motor",
  },
  {
    label: "Mobil",
    value: "Mobil",
  },
  {
    label: "Helikopter",
    value: "Helikopter",
  },
  {
    label: "Sepeda",
    value: "Sepeda",
  },
  {
    label: "Lain- lain",
    value: "Lain- lain",
  },
];

const marriageStatus = [
  {
    id: 1,
    value: "Lajang",
    label: "Lajang",
  },
  {
    id: 2,
    value: "Menikah",
    label: "Menikah",
  },
  {
    id: 3,
    value: "Duda / Janda",
    label: "Duda / Janda",
  },
];
