import React from "react";
import { Avatar, Box, Grid, Typography } from "@material-ui/core";
import { AccountCircleOutlined } from "@material-ui/icons";

const CardOwner = ({ classes, card }) => {
  // // console.log("CARD ow", card);

  const photoUrl = card?.owner?.member?.self?.rel?.photo_url;

  return (
    <Grid container spacing={1.5}>
      <Grid item sm={3} style={{ display: "flex" }} alignItems="center">
        <AccountCircleOutlined style={{ fontSize: 16, marginRight: 4 }} />
        <Typography variant="caption" className={classes.title888}>
          Owner
        </Typography>
      </Grid>

      <Grid
        item
        sm={9}
        style={{
          display: "flex",
          backgroundColor: "#F7F7F7",
          borderRadius: 5,
          padding: "2px 7px",
        }}
        justify="center"
        alignItems="center"
      >
        <Avatar
          variant="circle"
          src={photoUrl}
          style={{ width: 16, height: 16, marginRight: 4 }}
        />
        <Typography variant="caption" className={classes.title555}>
          {`${card.owner?.member?.first_name} ${card.owner?.member?.last_name}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CardOwner;
