import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Container } from "@material-ui/core";

import styles from "./Style/landingStyle";
import ReactTextTransition, { presets } from "react-text-transition";

import bgTouch from "../../assets/images/bgTouch.png";
import heroImg from "../../assets/images/img_hero.png";

import HeaderNew from "./ComponentViewLandingPage/home/HeaderNew";
import NavRight from "./ComponentViewLandingPage/NaviRight";
import NavLeft from "./ComponentViewLandingPage/NavLeft";
import Parallax from "./ComponentViewLandingPage/home/Parallax";
import WhatWeDo from "./ComponentViewLandingPage/home/WhatWeDo";
import OurClient from "./ComponentViewLandingPage/home/OurClient";
import AllField from "./ComponentViewLandingPage/home/AllField";
import WhyUs from "./ComponentViewLandingPage/home/WhyUs";
import GrowUp from "./ComponentViewLandingPage/home/GrowUp";
import Blogs from "./ComponentViewLandingPage/home/Blogs";
import TouchUs from "./ComponentViewLandingPage/home/TouchUs";
import Footer from "./ComponentViewLandingPage/Footer";
import FloatingWhatsapp from "./ComponentViewLandingPage/components/FloatingWhatsapp";
import { navigate } from "hookrouter";

const textList = ["kualitas", "output", "efisiensi", "kinerja"];

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const ViewLandingPage = () => {
  const classes = useStyles();

  const [textMove, setTextMove] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setTextMove((prev) => prev + 1);
    }, 2000);
  }, []);

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <div>
      <HeaderNew
        color="transparent"
        routes={dashboardRoutes}
        brand="Performate"
        rightLinks={<NavRight origin="1" />}
        leftLinks={<NavLeft origin="1" />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white",
        }}
      />
      <Parallax filter image={require("../../assets/images/hero.png")}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            mt={20}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h3" className={classes.title}>
              <b>
                Tingkatkan{" "}
                <ReactTextTransition
                  text={textList[textMove % textList.length]}
                  springConfig={{ stiffness: 50, damping: 20 }}
                  style={{ color: "#F83D55" }}
                  noOverflow
                  inline
                />{" "}
                SDM anda dengan satu platform
              </b>
            </Typography>
            <Typography variant="subtitle1" className={classes.heroDesc}>
              Performate.Id membantu meningkatkan kinerja karyawan anda,
              sehingga anda bisa fokus meningkatkan omset dan mengembangkan
              bisnis anda
            </Typography>

            <Box mt={4} className={classes.btnHero}>
              <Button
                variant="outlined"
                className={classes.btnFree}
                onClick={handleTryFree}
                style={{ zIndex: 100000 }}
              >
                Coba Gratis Sekarang
              </Button>
              <Box mx={0.8} />
              <Button
                variant="outlined"
                className={classes.btnSales}
                onClick={() => navigate("/contact")}
                style={{ zIndex: 100000 }}
              >
                Hubungi Sales Kami
              </Button>
            </Box>
          </Box>
          <img src={heroImg} className={classes.imgHero} />
        </Box>
      </Parallax>
      {/* <Box display="flex" flexDirection="column" alignItems="center">
      </Box> */}

      <Container>
        <WhatWeDo />
      </Container>

      <OurClient classes={classes} />

      <Container>
        <AllField />
      </Container>

      <Box bgcolor="#F2F4F7" py={10}>
        <Container>
          <WhyUs />
        </Container>
      </Box>

      <Container>
        <GrowUp />
      </Container>

      {/* <Box bgcolor="#F2F4F7">
        <Container>
          <Blogs />
        </Container>
      </Box> */}

      <Box
        style={{
          backgroundImage: "url(" + bgTouch + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Container>
          <TouchUs />
        </Container>
      </Box>

      <FloatingWhatsapp />

      <Footer />
    </div>
  );
};

export default ViewLandingPage;
