import { Button, Grid, Typography } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import UpdateIcon from '@material-ui/icons/Update';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import React from "react";
import { navigate } from "hookrouter";
import {
  ToAttendanceListRequest,
  ToAttendanceSetting,
  ToOvertime,
  ToOvertimeSetting,
} from "../../../../constants/config-redirect-url";

function OvertimeListMenu({
  classes,
  numSelected,
  rowCount,
  onSelectAllClick,
}) {

  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ width: "100%", marginBottom : "25px" }}>
        <Grid item md={2}>
          <Button
            onClick={() => navigate(ToOvertimeSetting)}
            variant="contained"
            color="default"
            className={classes.button}
            style={{ marginLeft: "0px", width : "100%" }}
          >
            <div style={{padding : "5px"}}>
              <div>
                <SettingsIcon style={{ color: "#ffffff" }} />
              </div>
              <div>
                <Typography variant="subtitle1" component={'span'} className={classes.title} style={{color: "#fff"}}>
                  <b>OVERTIME SETTING</b>
                </Typography>
              </div>
            </div>
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OvertimeListMenu;
