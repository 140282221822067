export const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
    fontFamily: "Lato",
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
});
