// account type
export const ACCOUNT_TYPE_PERSONAL = "b2ea67ff-ce72-4b12-a26f-f02d702a3f0b";
export const ACCOUNT_TYPE_BISNIS = "0b83eec8-d626-4f29-8ca5-d07b264816d1";

// package type
export const PACKAGE_BISNIS_A = "b91a423d-0435-4410-8cae-7e12fd9ef20d";
export const PACKAGE_BISNIS_B = "49fae773-02bf-4bfa-b1ef-0c904eb5cf1d";
export const PACKAGE_BISNIS_C = "70957dc5-976f-460a-88ac-7bbb6d35bb9c";
export const PACKAGE_PERSONAL = "845a69e4-6e08-4144-b169-5df1961af62d";

// package featurtes
export const PMO_VISIT = "7525a2ee-151f-4c07-ab29-9b41905e32aa";
export const USER_LICENSE = "92e8ee3a-c1a7-4acd-aae2-cd1b7478b158";
export const DEPLOYMENT = "b0851fad-9576-4205-a0d8-467301ce0fff";

// payment status
export const PAYMENT_STATUS_REFUND = "14c0a7cd-68af-4c7d-bdc9-306484b3f73f";
export const PAYMENT_STATUS_CANCELLED = "316cf27a-5c74-4f2e-abf6-c19e0e571efb";
export const PAYMENT_STATUS_PENDING = "67e05810-f1d6-4c3d-8a59-a928fd683cfa";
export const PAYMENT_STATUS_EXPIRED = "858f481e-6ad1-43bd-b9e2-a8a0449bccbd";
export const PAYMENT_STATUS_PAID = "a5e994b2-7896-490a-bb66-2a617d787c31";

// status membership
export const STATUS_NONE = "1";
export const STATUS_TRIAL = "2";
export const STATUS_ACTIVE = "3";
export const STATUS_LAPSED = "20";
export const STATUS_INACTIVE = "22";
// 1 none
// 2 trial
// 3 active
// 20 lapse
// 22 inactive

// status Bank
export const STATUS_BANK_OFFLINE = "df89329c-f449-4350-b010-aad098f85ee5";
export const STATUS_BANK_AVAILABLE = "7ba9f1d6-5902-498d-a367-05ddd5463971";
