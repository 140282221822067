import React, { useEffect, useState } from "react";

import AllMAContext from "./AllMaContext";

function ContextAllMA(props) {
  // USER TOKEN STATE
  const [userTokenStateGlobal, setUserTokenStateGlobal] = useState("");
  //  CHOOSE TAB STATE
  const [dataTabActive, setDataTabActive] = useState({
    id: 2,
    label: "You",
  });
  // VALUE TAB STATE
  const [valueTabAll, setValueTabAll] = useState(1);
  const [valueTabYou, setValueTabYou] = useState(1);
  //VALUE TABSTATUS AL MA IN YOU
  const [valueTabStatusAllMAInYou, setValueTabStatusAllMAInYou] = useState(2);
  // STATUS ALL MA ID ALL
  const [statusAllMAIdAll, setStatusAllMAIdAll] = useState(3);
  // STATUS ALL MA ID YOU
  const [statusAllMAIdYou, setStatusAllMAIdYou] = useState(3);
  //   STATUS LOADER
  const [loader, setLoader] = useState(false);
  //   LOADER FOR PAGING STATUS (Draft, Active, Overdue, dll)
  const [loaderForPaging, setLoaderForPaging] = useState(false);
  //  COLLECTION DATA MA LIST
  const [collectionMAList, setCollectionMAList] = useState(listCollectionMA);
  //   COLLECTION DATA MA LENGTH
  const [collectionMALength, setCollectionMALength] = useState(0);
  //  COLLECTION DATA MA Result LIST
  const [collectionMAResultList, setCollectionMAResultList] = useState([]);
  //   COLLECTION DATA MA RESULT LENGTH
  const [collectionMAResultLength, setCollectionMAResultLength] = useState([]);
  //   CONDITION WHEN SEARCH RUNNING
  const [isSearchRun, setSearchRun] = useState(false);
  //   VALUE SEARCH TEXT INPUT
  const [valueSearchState, setValueSearchState] = useState("");
  // CLEARE SEARCH RESULT CONFITION
  const [clearSearchResult, setClearSearchResult] = useState(false);

  /*
    HANDLE CREATE NEW MA ALL > STATE
  */
  //  IS MODAL CREATE OR NOT
  const [isModalCreate, setModalCreate] = useState(false);
  // FOTO QUERY STATE
  const [fotoQuery, setFotoQuery] = useState(null);
  // USER LOGIN NAME STATE
  const [userLoginName, setUserLoginName] = useState("");
  // POSITION MEMBER STATE
  const [memberPositionList, setMemberPositionList] = useState([]);

  // IS MA DETAIL SHOW OR NOT
  const [
    isMeasuredActivityDetailShow,
    setMeasuredActivityDetailShow,
  ] = useState(true);

  //  IS MODAL CREATE OR NOT FOR MA ALL RESULT
  const [isModalCreateAllMAResult, setModalCreateALlMAResult] = useState(false);
  //  MODAL RESPONSE 200 ?
  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");
  const [isOpenSnackbeer, setOpenSnackbeer] = useState(false);

  const [deleteSuccess, setDeleteSuccess] = useState(null);
  const [isSuccessCreateMAR, setSuccessCreateMAR] = useState(null);

  const [isModalCreateMAResult, setModalCreateMAResult] = useState(false);
  const [isSuccessUploadFile, setSuccessUploadFile] = useState(false);
  const [loaderEvidance, setLoaderEvidance] = useState(false);

  const [successSetAsComplete, setSuccessSetAsComplete] = useState(null);
  const [errorDuplicate, setErrorDuplicate] = useState(false);
  const [successDuplicate, setSuccessDuplicate] = useState(null);

  const [isModalExportMA, setModalExportMA] = useState(false);

  // Weight Value
  const [weightValue, setWeightValue] = useState({ min: 0, max: 100 });
  const [weightValueAll, setWeightValueAll] = useState({ min: 0, max: 100 });

  // HANDLE PAGINATION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageResult, setRowsPerPageResult] = useState(8);
  const [offset, setOffset] = useState("");

  // ======================================================================
  // Trigger MA COLLECTION
  const [triggerMAs, setTriggerMAs] = useState(null);

  const [limitMACount, setLimitMACount] = useState(10);
  const [offsetMACount, setOffsetMACount] = useState(0);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    setUserTokenStateGlobal(userToken);

    // Status All MA Id From Local Storage
    const statusAllMAIdInLocalStorage = localStorage.getItem("status_allma_id");

    const statusTab = localStorage.getItem("location_tab");

    if (
      statusAllMAIdInLocalStorage !== null &&
      statusTab !== null &&
      statusTab !== "You"
    ) {
      setDataTabActive({
        id: 1,
        label: "All",
      });

      // if (statusAllMAIdInLocalStorage === "1") {
      //   setStatusAllMAIdAll(1); //*Draft

      //   setValueTabAll(0);
      // }

      if (statusAllMAIdInLocalStorage === "2") {
        setStatusAllMAIdAll(2); //*Approved

        setValueTabAll(0);
      }

      if (statusAllMAIdInLocalStorage === "3") {
        setStatusAllMAIdAll(3); //*Active

        setValueTabAll(1);
      }

      if (statusAllMAIdInLocalStorage === "6") {
        setStatusAllMAIdAll(6); //*Overdue

        setValueTabAll(2);
      }

      if (statusAllMAIdInLocalStorage === "4") {
        setStatusAllMAIdAll(4); //*Completed

        setValueTabAll(3);
      }
    } else {
      setDataTabActive({
        id: 2, //*self
        label: "You",
      });

      // if (statusAllMAIdInLocalStorage === "1") {
      //   setStatusAllMAIdYou(1); //*Draft

      //   // setValueTab(0)
      //   setValueTabYou(0);
      // }

      if (statusAllMAIdInLocalStorage === "2") {
        setStatusAllMAIdYou(2); //*Approved

        setValueTabYou(0);
      }

      if (statusAllMAIdInLocalStorage === "3") {
        setStatusAllMAIdYou(3); //*Active

        setValueTabYou(1);
      }

      if (statusAllMAIdInLocalStorage === "6") {
        setStatusAllMAIdYou(6); //*Overdue

        setValueTabYou(2);
      }

      if (statusAllMAIdInLocalStorage === "4") {
        setStatusAllMAIdYou(4); //*Completed
        setValueTabYou(3);
      }
    }
  }, []);

  return (
    <AllMAContext.Provider
      value={{
        setDataTabActive,
        dataTabActive,
        valueTabAll,
        setValueTabAll,
        valueTabYou,
        setValueTabYou,
        valueTabStatusAllMAInYou,
        setValueTabStatusAllMAInYou,
        statusAllMAIdAll,
        setStatusAllMAIdAll,
        statusAllMAIdYou,
        setStatusAllMAIdYou,
        loader,
        setLoader,
        loaderForPaging,
        setLoaderForPaging,
        collectionMAList,
        setCollectionMAList,
        collectionMALength,
        setCollectionMALength,
        collectionMAResultList,
        setCollectionMAResultList,
        userTokenStateGlobal,
        setUserTokenStateGlobal,
        isSearchRun,
        setSearchRun,
        valueSearchState,
        setValueSearchState,
        clearSearchResult,
        setClearSearchResult,
        isModalCreate,
        setModalCreate,
        fotoQuery,
        setFotoQuery,
        userLoginName,
        setUserLoginName,
        memberPositionList,
        setMemberPositionList,
        isMeasuredActivityDetailShow,
        setMeasuredActivityDetailShow,
        isModalResponse200,
        setModalResponse200,
        deleteSuccess,
        setDeleteSuccess,
        isModalCreateMAResult,
        setModalCreateMAResult,
        isSuccessUploadFile,
        setSuccessUploadFile,
        loaderEvidance,
        setLoaderEvidance,
        messages,
        setMessages,
        isOpenSnackbeer,
        setOpenSnackbeer,
        isSuccessCreateMAR,
        setSuccessCreateMAR,
        successSetAsComplete,
        setSuccessSetAsComplete,
        errorDuplicate,
        setErrorDuplicate,
        successDuplicate,
        setSuccessDuplicate,
        isModalExportMA,
        setModalExportMA,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        rowsPerPageResult,
        setRowsPerPageResult,
        offset,
        setOffset,
        loaderForPaging,
        setLoaderForPaging,
        collectionMAResultLength,
        setCollectionMAResultLength,
        weightValue,
        setWeightValue,
        weightValueAll,
        setWeightValueAll,
        limitMACount,
        setLimitMACount,
        offsetMACount,
        setOffsetMACount,
        triggerMAs,
        setTriggerMAs,
      }}
    >
      {props.children}
    </AllMAContext.Provider>
  );
}

export default ContextAllMA;

const listCollectionMA = [
  {
    id: null,
    name: "",
    measured_activity_type_id: "2",
    goal_id: "",
    owner_id: "",
    achievement_type_id: "",
    value_type_id: "",
    start_value: "",
    target_value: "",
    target_range_value: null,
    tolerance_value: "",
    weight_value: "",
    input_value: "",
    input_method_id: "",
    calculation_method_id: "",
    direct_link_main_measured_activity_id: null,
    direct_link_node_path: "",
    direct_link_node_level: null,
    update_interval_id: "",
    update_interval_trigger_value: null,
    period_id: null,
    is_evidence_required: null,
    start_date: "",
    end_date: "",
    completed_timestamp: null,
    time_frame_id: "",
    measured_activity_mode_id: "",
    visibility_type_id: "",
    last_measured_activity_result_date: "",
    status_id: "",
    description: null,
    created_by: "",
    updated_by: "",
    deleted_by: null,
    created_at: "",
    updated_at: "",
    deleted_at: null,
    is_used: false,
    period: null,
    goal: {
      id: "",
      name: "",
    },
    achievementType: {
      id: "",
      code: "",
      name: "",
    },
    inputMethod: {
      id: "",
      code: "",
      name: "",
    },
    calculationMethod: {
      id: "",
      code: "",
      name: "",
    },
    measuredActivityMode: {
      id: "",
      code: "",
      name: "",
    },
    measuredActivityType: {
      id: "",
      code: "",
      name: "",
    },
    owner: {
      id: "",
      code: "",
      member_id: "",
      member_first_name: "",
      member_last_name: "",
      user_id: "",
      user_email: "",
      structure_unit_type_id: null,
      structure_unit_type_name: null,
      structure_unit_id: null,
      structure_unit_name: null,
      structure_position_title_id: "",
      structure_position_title_name: "",
      self: {
        rel: null,
      },
    },
    member: {
      id: "",
      first_name: "",
      last_name: "",
      nickname: "",
      photo_url: "",
      self: {
        rel: {
          photo_url: "",
        },
      },
    },
    directLinkMainMeasuredActivity: null,
    startValueSyncStatus: {
      id: "",
      code: "",
      name: "",
    },
    targetValueSyncStatus: {
      id: "",
      code: "",
      name: "",
    },
    status: {
      id: "",
      code: "",
      name: "",
      sort_order: null,
    },
    updateInterval: {
      id: "",
      name: "",
      code: "",
      datetime_unit: null,
      datetime_value: null,
    },
    valueType: {
      id: "",
      code: "",
      name: "",
    },
    visibilityType: {
      id: "",
      code: "",
      name: "",
    },
    calculatedValue: {
      start_value: "",
      target_value: "",
      input_value: "",
      target_value_achievement: "",
      target_range_value: "",
      resultOriented: {
        result_value: "",
        result_value_achievement: "",
        expected_result_value: "",
        expected_result_value_achievement: "",
        gap_result_value: "",
        gap_result_value_achievement: "",
        overall_result_achievement: "",
      },
      actualOriented: {
        actual_value: 1,
        actual_value_achievement: "",
        expected_actual_value: "",
        expected_actual_value_achievement: "",
        gap_actual_value: "",
        gap_actual_value_achievement: "",
        overall_actual_achievement: "",
      },
      day_offset: null,
      date_offset: "",
      start_date: "",
      end_date: "",
    },
    time: {
      elapsed_days: null,
      remaining_days: null,
      total_days: null,
      start_date: "",
      end_date: "",
    },
    timeFrame: {
      id: "",
      code: "",
      name: "",
    },
    self: {
      rel: null,
    },
  },
];
