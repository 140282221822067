import {
  PROJECT_TEAM_COLLECTIONS,
  PROJECT_TEAM_DETAILS,
  TASK_TEAM_COLLECTIONS,
  DRAWER_OPEN,
  SET_TASK_DETAILS,
  SEARCH_PROJECT,
  SEARCH_TASK,
  SET_PAGINATE_TASK,
  SET_PAGINATE_PROJECT,
  TOGGLE_PROJECT,
  GANTT_MAP_FORM,
  HIDE_TASK_GROUP,
  FILTER_TASK,
  SORT_TASK,
  ASSIGNEE_CONTENT_FILTER,
  FILTER_PROJECT,
  THE_OWNER_CONTENT,
  GET_KANBAN,
  SEARCH_CARDS,
  DRAWER_KANBAN,
  SET_GROUP_BY,
  SORT_NAME_PROJECT,
  GROUP_BY_IN_LIST,
} from "../types";

const getStatusLogin = localStorage.getItem("status_user_login");
const user = getStatusLogin !== null ? JSON.parse(getStatusLogin) : undefined;

  const group_id = "3802b4dd-c07e-4077-b553-acb75c209549"; // Bestindo

export const initialProject = {
  loadProject: false,
  projectData: [],
  personalData: [],
  count: 0,
  searchProject: "",
  pageLimit: 10,
  pageOffset: 0,
  search: "",
  projectDetails: null,
  loadTask: false,
  taskData: [],
  groupTaskData: [],
  taskForm: [],
  projectForm: [],
  groupsForm: [],
  countTask: 0,
  searchTask: "",
  limitTask: 50,
  offsetTask: 0,
  taskDetails: null,
  drawerTask: false,
  actionTask: "update",
  toggleProject: true,
  projectType: "TEAM",
  defaultTaskGroup: null,
  hideTaskGroup: false,
  periods: null,
  filterAssignee: null,
  filterAchievement: null,
  filterStatus: null,
  filterPriority: null,
  filterComplete: null,
  filterPhase: null,
  filterByGroup: null,
  assigneeFilterContent: null,
  filterGroup: null,
  groupBy: "",
  groupByInList: "task_group",
  sortByDate: null,
  sortTitleTask: null,
  filterTheOwner: null,
  filterTheStatus: user?.group_id !== group_id ? "options[filter][status_id][0]=3" : "options[filter][status_id][0]=3&options[filter][status_id][1]=6&options[filter][status_id][2]=4&options[filter][status_id][3]=2",
  theOwnerContent: null,
  sortNameproject: "asc",
  // ==== kanban ====
  // limitKanabn: 50,
  // offsetKanban: 0,
  loadKanban: false,
  searchCards: "",
  drawerKanban: false,
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Project Team ~
export const projectReducer = (state, { type, payload }) => {
  switch (type) {
    case PROJECT_TEAM_COLLECTIONS:
      return {
        ...state,
        loadProject: payload.loadProject,
        projectData: [...payload.projectData],
        personalData: [...payload.personalData],
        count: payload.count,
      };
    case PROJECT_TEAM_DETAILS:
      return {
        ...state,
        projectDetails: payload.projectDetails,
      };
    case TASK_TEAM_COLLECTIONS:
      return {
        ...state,
        loadTask: payload.loadTask,
        taskData: [...payload.taskData],
        groupTaskData: payload.groupTaskData,
        countTask: payload.countTask,
        defaultTaskGroup: payload.defaultTaskGroup,
      };
    case DRAWER_OPEN:
      return {
        ...state,
        drawerTask: payload.drawerTask,
        actionTask: payload.actionTask,
      };
    case SET_TASK_DETAILS:
      return {
        ...state,
        taskDetails: payload.taskDetails,
      };
    case SEARCH_PROJECT:
      return {
        ...state,
        searchProject: payload.searchProject,
      };
    case SET_PAGINATE_PROJECT:
      return {
        ...state,
        pageLimit: payload.pageLimit,
        pageOffset: payload.pageOffset,
      };
    case SEARCH_TASK:
      return {
        ...state,
        searchTask: payload.searchTask,
      };
    case SET_PAGINATE_TASK:
      return {
        ...state,
        limitTask: payload.limitTask,
        offsetTask: payload.offsetTask,
      };
    case TOGGLE_PROJECT:
      return {
        ...state,
        toggleProject: payload.toggleProject,
        projectType: payload.projectType,
      };
    case GANTT_MAP_FORM:
      return {
        ...state,
        taskForm: payload.taskForm,
        projectForm: payload.projectForm,
        groupsForm: payload.groupsForm,
      };
    case HIDE_TASK_GROUP:
      return {
        ...state,
        hideTaskGroup: payload.hideTaskGroup,
      };
    case FILTER_TASK:
      return {
        ...state,
        periods: payload.periods,
        filterAssignee: payload.filterAssignee,
        filterAchievement: payload.filterAchievement,
        filterStatus: payload.filterStatus,
        filterPriority: payload.filterPriority,
        filterComplete: payload.filterComplete,
        filterGroup: payload.filterGroup,
        filterPhase: payload.filterPhase,
        filterByGroup: payload.filterByGroup,
      };
    case SORT_TASK:
      return {
        ...state,
        sortByDate: payload.sortByDate,
        sortTitleTask: payload.sortTitleTask,
      };
    case ASSIGNEE_CONTENT_FILTER:
      return {
        ...state,
        assigneeFilterContent: payload.assigneeFilterContent,
      };
    case FILTER_PROJECT:
      return {
        ...state,
        filterTheOwner: payload.filterTheOwner,
        filterTheStatus: payload.filterTheStatus,
      };
    case THE_OWNER_CONTENT:
      return {
        ...state,
        theOwnerContent: payload.theOwnerContent,
      };

    // ==== KANBAN ====
    case GET_KANBAN:
      return {
        ...state,
        loadKanban: payload.loadKanban,
      };
    case SEARCH_CARDS:
      return {
        ...state,
        searchCards: payload.searchCards,
      };
    case DRAWER_KANBAN:
      return {
        ...state,
        drawerKanban: payload.drawerKanban,
      };
    case SET_GROUP_BY:
      return {
        ...state,
        groupBy: payload.groupBy,
      };
    case GROUP_BY_IN_LIST:
      return {
        ...state,
        groupByInList: payload.groupByInList,
      };

    case SORT_NAME_PROJECT:
      return {
        ...state,
        sortNameproject: payload.sortNameproject,
      };
    default:
      return state;
  }
};
