import React, { useContext } from "react";
import { Grid, Button, IconButton, Typography } from "@material-ui/core";
import ContextGoalDetail from "../Context/ContextGoalDetail";
import PictIconMainGoal from "../../../../assets/images/icon_main_goal.png";

const ParentMainGoalName = (props) => {
  const { classes, mainGoalName, mainGoalId } = props;

  const context = useContext(ContextGoalDetail);

  return (
    <Grid container direction="row" alignItems="center" justify="center">
      <Button
        // onClick={
        //     // () => {

        //     //     // navigate(ToGoalDetail, true, {id: mainGoalId})
        //     //     // context.setGoalId({id: mainGoalId})
        //     //     // context.setSubGoalFire(true)

        //     //     localStorage.setItem("value_search", mainGoalName)
        //     //     navigate(ToGoal)

        //     // }
        // }
        startIcon={
          <IconButton size="small">
            <img
              src={PictIconMainGoal}
              alt="Icon Main Goal"
              width="16px"
              height="16px"
            />
          </IconButton>
        }
        size="small"
        variant="outlined"
        className={classes.buttonOutlined}
        style={{ backgroundColor: "white", opacity: 0.2 }}>
        <Typography variant="caption" className={classes.title}>
          <i>{mainGoalName}</i>
        </Typography>
      </Button>
    </Grid>
  );
};

export default ParentMainGoalName;
