import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  Box,
} from "@material-ui/core";

import { navigate } from "hookrouter";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextAllMA from "../context/AllMaContext";
import handleError from "../../../Report/components/global/handleError";

import DialogError from "../../../../components/DialogError";
import DialogArchiveSuccess from "./DialogArchiveSuccess";

const DialogArchiveMultiple = ({
  classes,
  isOpenDialogArchiveMultiple,
  setOpenDialogArchiveMultiple,
  selected,
}) => {
  const context = useContext(ContextAllMA);
  const listMa =
    context.collectionMAList.length > 0
      ? context.collectionMAList.filter((ma) => selected.includes(ma.id))
      : [];

  const [archiveName, setArchiveName] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialogArchiveSuccess, setOpenDialogArchiveSuccess] =
    useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleArchiveMultiple = () => {
    const mapedMa = listMa.map((el) => {
      return {
        name: el.name,
        entity_id: el.id,
        owner_id: el.owner_id,
      };
    });

    setLoading(true);

    const data = {
      name: archiveName,
      entity_type: "ma",
      entity_list: mapedMa,
    };

    // // console.log("Multi Archive", data);

    AxiosConfig.post(`${URL_API}/swd/archived/store`, data)
      .then((res) => {
        // console.log("RES Archive", res);

        if (res.status === 200) {
          setOpenDialogArchiveSuccess(true);
          // navigate("/archive");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={isOpenDialogArchiveMultiple}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.title333}>
          <b>Archive File</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 550 }}>
        <Typography
          variant="subtitle1"
          className={classes.title333}
          style={{ textAlign: "center" }}
        >
          Apakah Anda yakin ingin mengarsip File?
        </Typography>
        <Box m={2}>
          <Box py={1}>
            <TextField
              label="Nama archive"
              size="small"
              fullWidth
              variant="standard"
              onChange={(e) => setArchiveName(e.target.value)}
            />
          </Box>
          <Box pt={2} display="flex" flexDirection="column">
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ marginBottom: 5 }}
            >
              Measured Activities :
            </Typography>
            {listMa.length > 0 &&
              listMa.map((item, i) => (
                <Typography variant="caption" className={classes.title555}>
                  {i + 1}) <b>{item.name}</b>
                </Typography>
              ))}
          </Box>
        </Box>
        <Typography variant="subtitle2" className={classes.title888}>
          File yang di archive hanya bisa dilihat pada menu archive
        </Typography>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogArchiveMultiple(false)}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleArchiveMultiple}
        >
          {loading ? "Loading..." : "Archive"}
        </Button>
      </DialogActions>

      <DialogArchiveSuccess
        openDialogArchiveSuccess={openDialogArchiveSuccess}
        setOpenDialogArchiveSuccess={setOpenDialogArchiveSuccess}
        onOpenDialog={setOpenDialogArchiveMultiple}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogArchiveMultiple;
