//data
export const LIVE_SUM_RAW_DATA = 'LIVE_SUM_RAW_DATA';
export const LIVE_SUM_DATA = 'LIVE_SUM_DATA';
export const LIVE_SUM_OPTIONS = 'LIVE_SUM_OPTIONS';

// filters
export const FILTER_GOAL_ACH_STATUS = 'FILTER_GOAL_ACH_STATUS';
export const FILTER_MA_ACH_STATUS = 'FILTER_MA_ACH_STATUS';
export const FILTER_PERIOD = 'FILTER_PERIOD';
export const FILTER_UNIT = 'FILTER_UNIT';
export const FILTER_GOAL_ORA = 'FILTER_GOAL_ORA';
export const FILTER_MA_ORA = 'FILTER_MA_ORA';
export const FILTER_SEARCH = 'FILTER_SEARCH';
export const FILTER_PER_PAGE = 'FILTER_PER_PAGE';
export const FILTER_PAGE = 'FILTER_PAGE';

// order
export const ORDER_FIELD_OWNER_NAME = 'owner_name';
export const ORDER_FIELD_POSITION = 'structure_position_title_name';
export const ORDER_FIELD_GOAL_ORA = 'goal_average_overall_result_achievement';
export const ORDER_FIELD_MA_ORA = 'ma_average_overall_result_achievement';

export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';

// toggle
export const VIEW_COLUMNS = 'view_columns';

// detail
export const LIVE_SUMMARY_DETAIL_ITEM = 'LIVE_SUMMARY_DETAIL_ITEM';
export const LIVE_SUMMARY_DETAIL_DATA = 'LIVE_SUMMARY_DETAIL_ITEM_DATA';
