import React, { useContext, useState } from "react";
import DialogCreate from "./DialogCreate";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../../components/StyledMenuDropdown";
import DialogDelete from "./DialogDelete";
import DialogEdit from "./DialogEdit";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
function ViewTabScheduleDetail({
  classes,
  isModalProgress,
  setModalProgress,
  dataScheduleDetailList,
  setDataScheduleDetailList,
  getScheduleDetailList,
}) {
  const context = useContext(ContextAttendanceSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterDataScheduleDetail, setMasterDataScheduleDetail] = useState({
    fields: {},
    masterLocationCollections: [],
    masterGroupCollection: [],
    masterUnitCollection: [],
    masterPositionCollection: [],
    masterMemberCollections: [],
    masterShiftCollections: [],
    masterScheduleCollections: [],
    masterWorkPatternCollections: [],
    masterStructureUseCollections: [
      {
        id: "member",
        name: "Member",
      },
      {
        id: "position",
        name: "Position",
      },
      {
        id: "unit",
        name: "Unit",
      },
      {
        id: "group",
        name: "Group",
      },
    ],
  });
  const [dataScheduleDetail, setDataScheduleDetail] = useState({
    id: "",
    with_checkin: 1,
    structure_use: "member",
    structure_use_obj:
      masterDataScheduleDetail.masterStructureUseCollections[0],
    structure_id: [],
    structure_obj: [{}],
    work_pattern_id: "",
    work_pattern_obj: {},
    shift_id: [],
    shift_obj: [],
    schedule_id: [],
    schedule_obj: [],
    dataCheckIn: [],
  });

  const resetDataScheduleDetail = () => {
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.id = "";
    dataScheduleDetailTemporary.with_checkin = 1;
    dataScheduleDetailTemporary.structure_use = "member";
    dataScheduleDetailTemporary.structure_use_obj =
      masterDataScheduleDetail.masterStructureUseCollections[0];
    dataScheduleDetailTemporary.structure_id = [];
    dataScheduleDetailTemporary.structure_obj = [{}];
    dataScheduleDetailTemporary.work_pattern_id = "";
    dataScheduleDetailTemporary.work_pattern_obj = {};
    dataScheduleDetailTemporary.shift_id = [];
    dataScheduleDetailTemporary.shift_obj = [{}];
    dataScheduleDetailTemporary.schedule_id = [];
    dataScheduleDetailTemporary.schedule_obj = [{}];
    dataScheduleDetailTemporary.dataCheckIn = [];
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const handleShowModalCreate = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/schedule-pattern-detail/create`)
      .then(function (response) {
        resetDataScheduleDetail();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataScheduleDetail;
        masterData.fields = response.data.data.fields;
        masterData.masterGroupCollection =
          response.data.data.masterGroupCollection;
        masterData.masterUnitCollection =
          response.data.data.masterUnitCollection;
        masterData.masterPositionCollection =
          response.data.data.masterPositionCollection;
        masterData.masterMemberCollections =
          response.data.data.masterMemberCollections;
        masterData.masterWorkPatternCollections =
          response.data.data.masterWorkPatternCollections;
        masterData.masterLocationCollections =
          response.data.data.masterLocationCollections;
        setMasterDataScheduleDetail(masterData);
        console.log(masterData.masterMemberCollections);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const createScheduleDetail = () => {
    context.setModalProgress(true);
    setLoader(true);
    let dataScheduleDetailTemp = dataScheduleDetail;
    dataScheduleDetailTemp.dataCheckIn = [];
    dataScheduleDetail.schedule_obj.map((itemSchedule, iSchedule) => {
      if (itemSchedule && itemSchedule.activities) {
        dataScheduleDetailTemp.dataCheckIn.push({
          schedule_id: itemSchedule.id,
          activities: [],
        });
        itemSchedule.activities.map((itemActivities, iActivities) => {
          dataScheduleDetailTemp.dataCheckIn[iSchedule].activities.push({
            check_in: itemActivities.check_in,
            check_out: itemActivities.check_out,
            location_id: itemActivities.location_id,
          });
        });
      }
    });
    let data = {
      SchedulePatternDetail: dataScheduleDetailTemp,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/schedule-pattern-detail/create`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(false);
        context.setMessages("Data jadwal detail berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetDataScheduleDetail();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataScheduleDetailList.options.paging.limit * newPage,
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 0, parseInt(event.target.value));
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDelete = (e, item) => {
    handleDropdownClose();
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.id = item.id;
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
    context.setModalDelete(true);
  };

  const handleDelete = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      axiosConfig
        .delete(
          URL_API +
            `/human-resource/schedule-pattern-detail/${dataScheduleDetail.id}`
        )
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalDelete(false);
          context.setMessages("Berhasil di hapus !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, 0, 0, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleShowModalEdit = (e, item) => {
    handleDropdownClose();
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/schedule-pattern-detail/${item.id}/update`
      )
      .then(function (response) {
        resetDataScheduleDetail();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataScheduleDetail;
        masterData.fields = response.data.data.fields;
        masterData.masterGroupCollection =
          response.data.data.masterGroupCollection;
        masterData.masterUnitCollection =
          response.data.data.masterUnitCollection;
        masterData.masterPositionCollection =
          response.data.data.masterPositionCollection;
        masterData.masterMemberCollections =
          response.data.data.masterMemberCollections;
        masterData.masterWorkPatternCollections =
          response.data.data.masterWorkPatternCollections;
        masterData.masterLocationCollections =
          response.data.data.masterLocationCollections;
        setMasterDataScheduleDetail(masterData);
        getDataScheduleDetail(item);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const getDataScheduleDetail = (item) => {
    axiosConfig
      .get(`${URL_API}/human-resource/schedule-pattern-detail/${item.id}`)
      .then(function (response) {
        resetDataScheduleDetail();
        // console.log("Response Original : ", response.data);
        let momentNow = moment();
        let dataScheduleDetailTemporary = dataScheduleDetail;
        dataScheduleDetailTemporary.id = response.data.data.scheduleDetail.id;
        dataScheduleDetailTemporary.with_checkin = response.data.data
          .scheduleDetail.with_checkin
          ? parseInt(response.data.data.scheduleDetail.with_checkin)
          : 0;
        dataScheduleDetailTemporary.structure_use =
          response.data.data.scheduleDetail.structure_use;
        if (dataScheduleDetailTemporary.structure_use) {
          masterDataScheduleDetail.masterStructureUseCollections.map((item) => {
            if (item.id === dataScheduleDetailTemporary.structure_use) {
              dataScheduleDetailTemporary.structure_use_obj = item;
            }
          });
        }
        dataScheduleDetailTemporary.structure_obj =
          response.data.data.structure;
        dataScheduleDetailTemporary.structure_id =
          response.data.data.structure.map((item) => {
            return item.id;
          });
        dataScheduleDetailTemporary.work_pattern_id =
          response.data.data.workPattern && response.data.data.workPattern[0]
            ? response.data.data.workPattern[0].id
            : "";
        dataScheduleDetailTemporary.work_pattern_obj =
          response.data.data.workPattern && response.data.data.workPattern[0]
            ? response.data.data.workPattern[0]
            : {};
        dataScheduleDetailTemporary.shift_obj = response.data.data.shift;
        dataScheduleDetailTemporary.shift_id = response.data.data.shift.map(
          (item) => {
            return item.id;
          }
        );
        dataScheduleDetailTemporary.schedule_id =
          response.data.data.schedule.map((item) => {
            return item.id;
          });
        dataScheduleDetailTemporary.schedule_obj =
          response.data.data.schedule.map((itemSchedule) => {
            itemSchedule.activities = [];
            response.data.data.detailActivity.map((itemDetailActivity) => {
              if (itemSchedule.id == itemDetailActivity.schedule_id) {
                itemSchedule.activities.push({
                  check_in: itemDetailActivity.check_in
                    ? itemDetailActivity.check_in
                    : moment().format("HH:mm"),
                  check_in_date: itemDetailActivity.check_in
                    ? moment(
                        momentNow.format("YYYY-MM-DD") +
                          " " +
                          itemDetailActivity.check_in,
                        "YYYY-MM-DD HH:mm"
                      )
                    : moment(),
                  check_out: itemDetailActivity.check_out
                    ? itemDetailActivity.check_out
                    : moment().format("HH:mm"),
                  check_out_date: itemDetailActivity.check_out
                    ? moment(
                        momentNow.format("YYYY-MM-DD") +
                          " " +
                          itemDetailActivity.check_out,
                        "YYYY-MM-DD HH:mm"
                      )
                    : moment(),
                  location_id: itemDetailActivity.location_id,
                  location: itemDetailActivity.location,
                });
              }
            });
            return itemSchedule;
          });
        setDataScheduleDetail({ ...dataScheduleDetailTemporary });
        getMasterShift(dataScheduleDetailTemporary.work_pattern_obj);
        context.setModalProgress(true);
        setLoader(true);
        getMasterSchedule(dataScheduleDetailTemporary);
        context.setModalEdit(true);
        context.setModalEdit(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleUpdate = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      let dataScheduleDetailTemp = dataScheduleDetail;
      dataScheduleDetailTemp.dataCheckIn = [];
      dataScheduleDetail.schedule_obj.map((itemSchedule, iSchedule) => {
        if (itemSchedule && itemSchedule.activities) {
          dataScheduleDetailTemp.dataCheckIn.push({
            schedule_id: itemSchedule.id,
            activities: [],
          });
          itemSchedule.activities.map((itemActivities, iActivities) => {
            dataScheduleDetailTemp.dataCheckIn[iSchedule].activities.push({
              check_in: itemActivities.check_in,
              check_out: itemActivities.check_out,
              location_id: itemActivities.location_id,
            });
          });
        }
      });
      let data = {
        SchedulePatternDetail: dataScheduleDetailTemp,
      };
      axiosConfig
        .put(
          URL_API +
            `/human-resource/schedule-pattern-detail/${dataScheduleDetail.id}`,
          data
        )
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalEdit(false);
          context.setMessages("Berhasil di edit !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, null, null, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const refreshList = (search, offset, page, limit) => {
    let dataScheduleDetailListTemporary = dataScheduleDetailList;
    dataScheduleDetailListTemporary.options.filter.search =
      search !== null
        ? search
        : dataScheduleDetailListTemporary.options.filter.search;
    dataScheduleDetailListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataScheduleDetailListTemporary.options.paging.offset;
    dataScheduleDetailListTemporary.options.paging.page =
      page !== null
        ? page
        : dataScheduleDetailListTemporary.options.paging.page;
    dataScheduleDetailListTemporary.options.paging.limit =
      limit !== null
        ? limit
        : dataScheduleDetailListTemporary.options.paging.limit;
    setDataScheduleDetailList({ ...dataScheduleDetailListTemporary });
    getScheduleDetailList();
  };

  const addStruktur = () => {
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.structure_obj =
      dataScheduleDetail.structure_obj;
    dataScheduleDetailTemporary.structure_obj.push({});
    dataScheduleDetailTemporary.structure_id.push("");
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const deleteStruktur = (index) => {
    let listErrorTemp = [];
    context.setListError(listErrorTemp);
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.structure_obj =
      dataScheduleDetail.structure_obj;
    if (dataScheduleDetailTemporary.structure_obj.length > 0) {
      dataScheduleDetailTemporary.structure_obj.splice(index, 1);
      dataScheduleDetailTemporary.structure_id.splice(index, 1);
    }
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const handleChangeWorkPattern = (newValue) => {
    setLoader(true);
    context.setModalProgress(true);
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.work_pattern_obj = newValue;
    dataScheduleDetailTemporary.work_pattern_id = newValue ? newValue.id : "";
    dataScheduleDetailTemporary.shift_id = [];
    dataScheduleDetailTemporary.shift_obj = [{}];
    dataScheduleDetailTemporary.schedule_id = [];
    dataScheduleDetailTemporary.schedule_obj = [{}];
    dataScheduleDetailTemporary.dataCheckIn = [];
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
    getMasterShift(newValue);
  };

  const getMasterShift = (newValue) => {
    if (newValue) {
      let data = {
        SchedulePatternDetail: {
          work_patterns: [newValue.id],
        },
      };
      axiosConfig
        .post(`${URL_API}/human-resource/schedule-pattern-detail/shift`, data)
        .then(function (response) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Response Original : ", response.data);
          let masterDataScheduleDetailTemp = masterDataScheduleDetail;
          masterDataScheduleDetail.masterShiftCollections = response.data.data;
          setMasterDataScheduleDetail({ ...masterDataScheduleDetailTemp });
        })
        .catch(function (error) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Error : ", error.response);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      let masterDataScheduleDetailTemp = masterDataScheduleDetail;
      masterDataScheduleDetail.masterShiftCollections = [];
      setMasterDataScheduleDetail({ ...masterDataScheduleDetailTemp });
      setLoader(false);
      context.setModalProgress(false);
    }
  };

  const addShift = () => {
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.shift_obj = dataScheduleDetail.shift_obj;
    dataScheduleDetailTemporary.shift_obj.push({});
    dataScheduleDetailTemporary.shift_id.push("");
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const deleteShift = (index) => {
    let listErrorTemp = [];
    context.setListError(listErrorTemp);
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.shift_obj = dataScheduleDetail.shift_obj;
    if (dataScheduleDetailTemporary.shift_obj.length > 0) {
      dataScheduleDetailTemporary.shift_obj.splice(index, 1);
      dataScheduleDetailTemporary.shift_id.splice(index, 1);
    }
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const handleChangeShift = (newValue, i) => {
    setLoader(true);
    context.setModalProgress(true);
    let dataScheduleDetailTemporary = dataScheduleDetail;
    let overlap = false;
    let listErrorTemp = context.listError;
    listErrorTemp = [];

    if (newValue && newValue.id && newValue.schedule) {
      dataScheduleDetailTemporary.shift_obj.map((shift, key) => {
        if (shift && i !== key && shift.id === newValue.id) {
          overlap = true;
        } else if (shift && i !== key && shift.schedule) {
          shift.schedule.map((schedule, key) => {
            if (schedule && i !== key && schedule.day_in) {
              let dayInObjOld = schedule.day_in.split(",");
              newValue.schedule.map((scheduleSelected, keyScheduleSelected) => {
                if (schedule && i !== key && schedule.day_in) {
                  let dayInObjNew = scheduleSelected.day_in.split(",");
                  dayInObjOld.map((day, keyDay) => {
                    if (dayInObjNew.includes(day)) {
                      if (shift.schedule_out <= shift.schedule_in) {
                        if (
                          newValue.schedule_in >= shift.schedule_in ||
                          newValue.schedule_out >= shift.schedule_in
                        ) {
                          overlap = true;
                        }
                      } else if (
                        newValue.schedule_out <= newValue.schedule_in
                      ) {
                        if (
                          shift.schedule_in >= newValue.schedule_in ||
                          shift.schedule_out >= newValue.schedule_in
                        ) {
                          overlap = true;
                        }
                      } else {
                        if (
                          (newValue.schedule_in < shift.schedule_out &&
                            newValue.schedule_in >= shift.schedule_in) ||
                          (newValue.schedule_out < shift.schedule_out &&
                            newValue.schedule_out >= shift.schedule_in) ||
                          (newValue.schedule_in <= shift.schedule_in &&
                            newValue.schedule_out >= shift.schedule_out)
                        ) {
                          overlap = true;
                        }
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    }

    if (!overlap) {
      dataScheduleDetailTemporary.shift_obj[i] = newValue;
      dataScheduleDetailTemporary.shift_id[i] = newValue ? newValue.id : "";
      dataScheduleDetailTemporary.schedule_id = [];
      dataScheduleDetailTemporary.schedule_obj = [{}];
      dataScheduleDetailTemporary.dataCheckIn = [];
      setDataScheduleDetail({ ...dataScheduleDetailTemporary });
      getMasterSchedule(dataScheduleDetailTemporary);
    } else {
      listErrorTemp.push({
        key: "shift_id." + i,
        description: "Shift Overlap, Silahkan Pilih Shift Lain;",
      });
    }
    context.setListError(listErrorTemp);
    setLoader(false);
    context.setModalProgress(false);
  };

  const getMasterSchedule = (dataScheduleDetailTemporary) => {
    if (dataScheduleDetailTemporary && dataScheduleDetailTemporary.shift_id) {
      let data = {
        SchedulePatternDetail: {
          shifts: dataScheduleDetailTemporary.shift_id,
        },
      };
      axiosConfig
        .post(
          `${URL_API}/human-resource/schedule-pattern-detail/schedule`,
          data
        )
        .then(function (response) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Response Original : ", response.data);
          let masterDataScheduleDetailTemp = masterDataScheduleDetail;
          masterDataScheduleDetail.masterScheduleCollections =
            response.data.data;
          setMasterDataScheduleDetail({ ...masterDataScheduleDetailTemp });
        })
        .catch(function (error) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Error : ", error.response);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      let masterDataScheduleDetailTemp = masterDataScheduleDetail;
      masterDataScheduleDetail.masterScheduleCollections = [];
      setMasterDataScheduleDetail({ ...masterDataScheduleDetailTemp });
      setLoader(false);
      context.setModalProgress(false);
    }
  };

  const addSchedule = () => {
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.schedule_obj = dataScheduleDetail.schedule_obj;
    dataScheduleDetailTemporary.schedule_obj.push({});
    dataScheduleDetailTemporary.schedule_id.push("");
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const deleteSchedule = (index) => {
    let listErrorTemp = [];
    context.setListError(listErrorTemp);
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.schedule_obj = dataScheduleDetail.schedule_obj;
    if (dataScheduleDetailTemporary.schedule_obj.length > 0) {
      dataScheduleDetailTemporary.schedule_obj.splice(index, 1);
      dataScheduleDetailTemporary.schedule_id.splice(index, 1);
    }
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const handleChangeScedule = (newValue, i) => {
    setLoader(true);
    context.setModalProgress(true);

    let dataScheduleDetailTemporary = dataScheduleDetail;
    let overlap = false;
    let listErrorTemp = context.listError;
    listErrorTemp = [];

    if (newValue && newValue.id && newValue.day_in) {
      dataScheduleDetailTemporary.schedule_obj.map((schedule, key) => {
        if (schedule && i !== key && schedule.day_in) {
          let dayInObjOld = schedule.day_in.split(",");
          let dayInObjNew = newValue.day_in.split(",");
          dayInObjOld.map((day, keyDay) => {
            if (dayInObjNew.includes(day)) {
              overlap = true;
            }
          });
        }
      });
    }

    if (!overlap) {
      dataScheduleDetailTemporary.schedule_obj[i] = newValue;
      if (newValue) {
        dataScheduleDetailTemporary.schedule_id[i] = newValue.id;
        dataScheduleDetailTemporary.schedule_obj[i].activities = [
          {
            check_in: moment().format("HH:mm"),
            check_in_date: moment(),
            check_out: moment().format("HH:mm"),
            check_out_date: moment(),
            location_id: "",
            location: {},
          },
        ];
      } else {
        dataScheduleDetailTemporary.schedule_id[i] = "";
      }
      setDataScheduleDetail({ ...dataScheduleDetailTemporary });
    } else {
      listErrorTemp.push({
        key: "schedule_id." + i,
        description: "Schedule Overlap, Silahkan Pilih Schedule Lain;",
      });
    }
    context.setListError(listErrorTemp);

    setLoader(false);
    context.setModalProgress(false);
  };

  const addDataCheckIn = (iSchedule) => {
    let dataScheduleDetailTemporary = dataScheduleDetail;
    dataScheduleDetailTemporary.schedule_obj[iSchedule].activities.push({
      check_in: moment().format("HH:mm"),
      check_in_date: moment(),
      check_out: moment().format("HH:mm"),
      check_out_date: moment(),
      location_id: "",
      location: {},
    });
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const deleteDataCheckIn = (iSchedule, iActivities) => {
    let dataScheduleDetailTemporary = dataScheduleDetail;
    if (
      dataScheduleDetailTemporary.schedule_obj[iSchedule].activities.length > 0
    ) {
      dataScheduleDetailTemporary.schedule_obj[iSchedule].activities.splice(
        iActivities,
        1
      );
    }
    setDataScheduleDetail({ ...dataScheduleDetailTemporary });
  };

  const [masterWeeklyCollections, setMasterWeeklyCollections] = useState([
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
    "Minggu",
  ]);

  const generateWeekly = (item) => {
    let lable = [];
    item.day_in.split(",").map(function (indexDay) {
      lable.push(masterWeeklyCollections[parseInt(indexDay) - 1]);
      return 0;
    });

    return lable.toString(", ");
  };

  const generateShift = (item) => {
    let lable = [];
    item.shift.map(function (item) {
      lable.push(item.title);
      return 0;
    });

    return lable.join(", ");
  };

  const generateSchedule = (item) => {
    let lable = [];
    item.schedule.map(function (item) {
      lable.push(item.name);
      return 0;
    });

    return lable.join(", ");
  };

  return (
    <div>
      <Box>
        <Grid container spacing={3} style={{ marginBottom: "1em" }}>
          <Grid item md={6}>
            <Typography
              component={"span"}
              style={{ display: "inline-block", marginTop: "3px" }}
              className={classes.title333}
            >
              <b>Jadwal Detail</b>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                handleShowModalCreate();
              }}
            >
              Tambah Data Jadwal Detail
            </Button>
          </Grid>
        </Grid>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            classes={classes}
            numSelected={selected.length}
            dataScheduleDetailList={dataScheduleDetailList}
            setDataScheduleDetailList={setDataScheduleDetailList}
            getScheduleDetailList={getScheduleDetailList}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={dataScheduleDetailList.list.length}
            />

            {dataScheduleDetailList &&
              dataScheduleDetailList.list &&
              dataScheduleDetailList.list.length > 0 && (
                <TableBody>
                  {dataScheduleDetailList.list.map((item, i) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                  inputProps={{ "aria-label": "select all desserts" }}
                                /> */}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {dataScheduleDetailList.options.paging.page *
                              dataScheduleDetailList.options.paging.limit +
                              i +
                              1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.shift && generateShift(item)}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.schedule && generateSchedule(item)}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.structure_use ? item.structure_use : ""}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333Structure}
                          >
                            {item.structure ? item.structure : ""}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDropdownOpen(e, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl && anchorIn === i)}
                            onClose={(e) => handleDropdownClose(e, i)}
                          >
                            <StyledMenuItem
                              onClick={(e) => handleShowModalEdit(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Edit</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={(e) => handleShowModalDelete(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Hapus</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

            {dataScheduleDetailList &&
              dataScheduleDetailList.list &&
              dataScheduleDetailList.list.length <= 0 &&
              !isLoaderEmpty && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title333}
                          style={{
                            marginTop: 16,
                            marginBottom: 16,
                          }}
                        >
                          <b>Data jadwal detail tidak tersedia</b>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.paginationTimeManagement}
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  colSpan={7}
                  count={dataScheduleDetailList.options.paging.count}
                  rowsPerPage={dataScheduleDetailList.options.paging.limit}
                  page={dataScheduleDetailList.options.paging.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  labelRowsPerPage="Baris setiap halaman"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataScheduleDetail={masterDataScheduleDetail}
        setMasterDataScheduleDetail={setMasterDataScheduleDetail}
        dataScheduleDetail={dataScheduleDetail}
        setDataScheduleDetail={setDataScheduleDetail}
        listError={context.listError}
        createScheduleDetail={createScheduleDetail}
        handleChangeWorkPattern={handleChangeWorkPattern}
        addShift={addShift}
        deleteShift={deleteShift}
        handleChangeShift={handleChangeShift}
        addSchedule={addSchedule}
        deleteSchedule={deleteSchedule}
        handleChangeScedule={handleChangeScedule}
        addDataCheckIn={addDataCheckIn}
        deleteDataCheckIn={deleteDataCheckIn}
        addStruktur={addStruktur}
        deleteStruktur={deleteStruktur}
        generateWeekly={generateWeekly}
      />
      <DialogEdit
        classes={classes}
        isModalEdit={context.isModalEdit}
        setModalEdit={context.setModalEdit}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataScheduleDetail={masterDataScheduleDetail}
        setMasterDataScheduleDetail={setMasterDataScheduleDetail}
        dataScheduleDetail={dataScheduleDetail}
        setDataScheduleDetail={setDataScheduleDetail}
        listError={context.listError}
        handleUpdate={handleUpdate}
        handleChangeWorkPattern={handleChangeWorkPattern}
        addShift={addShift}
        deleteShift={deleteShift}
        handleChangeShift={handleChangeShift}
        addSchedule={addSchedule}
        deleteSchedule={deleteSchedule}
        handleChangeScedule={handleChangeScedule}
        addDataCheckIn={addDataCheckIn}
        deleteDataCheckIn={deleteDataCheckIn}
        addStruktur={addStruktur}
        deleteStruktur={deleteStruktur}
        generateWeekly={generateWeekly}
      />
      <DialogDelete
        classes={classes}
        isModalDelete={context.isModalDelete}
        setModalDelete={context.setModalDelete}
        dataScheduleDetail={dataScheduleDetail}
        setDataScheduleDetail={setDataScheduleDetail}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ViewTabScheduleDetail;
