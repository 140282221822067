import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Divider,
  TextField,
  DialogActions,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Axios from "axios";

import {
  PACKAGE_BISNIS_A,
  DEPLOYMENT,
  USER_LICENSE,
  PMO_VISIT,
  ACCOUNT_TYPE_BISNIS,
  PACKAGE_PERSONAL,
  ACCOUNT_TYPE_PERSONAL,
  STATUS_BANK_AVAILABLE,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_PENDING,
} from "../constant-data";
import { URL_API } from "../../../../constants/config-api";
import { FormatThousand } from "../../../../utilities/FormatDecimal";

import bank_bca from "../../../../assets/images/bank-bca.png";
import bank_bni from "../../../../assets/images/bank-bni.png";
import bank_bri from "../../../../assets/images/bank-bri.png";
import bank_permata from "../../../../assets/images/bank-permata.png";
import bank_mandiri from "../../../../assets/images/bank_mandiri.png";

import DialogPayment from "./DialogPayment";
import DialogPaidPayment from "./DialogPaidPayment";
import DialogError from "../../../../components/DialogError";
import CardChoosenPackage from "./CardChoosenPackage";

const DialogCheckoutReg = ({
  classes,
  openDialogCheckoutReg,
  setOpenDialogCheckoutReg,
  setOpenDialogPackage,
  userToken,
  checkoutFee,
  duration,
  paket,
  membership,
  listPaymentMethods,
  onVoucher,
  setTriggerPricing,
  voucherErrorsList,
  error400,
}) => {
  // const token =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLWRldi5wZXJmb3JtYXRlLmlkXC9hcGlcL3YxXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYxMDEwMDIzNiwiZXhwIjoxNjE2NjcwMjM2LCJuYmYiOjE2MTAxMDAyMzYsImp0aSI6IkFNemZLNUN5OVY3VEltb2siLCJzdWIiOiJlNDFmMmMxZS1iYWI3LTQ1NzYtOTcxNy0xZjU2M2ZjOTJjYWIiLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiSzAiOiIxVmgxNVNqNiIsIkp0aTkxeGM5YzMiOiJrb2R4YWNvcDRlcGtja255IiwiS28xIjoiNzUiLCI1enhjYXNkbHAiOiJ2YTUiLCJ2ZXJzaW9uIjoiMS4xIn0.4WqrrlKvN-bepHf-F5Fg_00_ztcenHDYJaUFVXGJW_0";

  // ====================================================
  // STATE COLLECTIONS
  // ====================================================
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [checkoutData, setCheckoutData] = useState({});

  // benefits state
  const [totalUser, setTotalUser] = useState("0");
  const [totalDeployment, setTotalDeployment] = useState("0");
  const [totalPmo, setTotalPmo] = useState("0");
  const [totalDuration, setTotalDuration] = useState("0");

  const [priceDeployment, setPriceDeployment] = useState("");
  const [pricePmo, setPricePmo] = useState("");
  const [priceUser, setPriceUser] = useState("");
  const [codeVoucher, setCodeVoucher] = useState("");

  const [openDialogPayment, setOpenDialogPayment] = useState(false);
  const [openDialogPaidPayment, setOpenDialogPaidPayment] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ====================================================
  // Side Effect
  // ====================================================
  useEffect(() => {
    if (openDialogCheckoutReg) {
      setTotalDuration(duration);

      checkoutFee.pricePerItem.forEach((item) => {
        if (item.id === DEPLOYMENT) {
          setTotalDeployment(item.quantity);
          setPriceDeployment(item.total_price_after_duration);
        } else if (item.id === PMO_VISIT) {
          setTotalPmo(item.quantity);
          setPricePmo(item.total_price_after_duration);
        } else if (item.id === USER_LICENSE) {
          setTotalUser(item.quantity);
          setPriceUser(item.total_price_after_duration);
        }
      });
    }
  }, [openDialogCheckoutReg]);

  useEffect(() => {
    if (error400) {
      setCodeVoucher("");
    }
  }, [error400]);

  // ====================================================
  // Function / Methods
  // ====================================================
  // // console.log("Checkout Fee", checkoutFee);

  const handleChangePayment = (data) => {
    // // console.log("Selected Payment", data);
    setSelectedPayment(data);
  };

  const handleSetVoucher = () => {
    onVoucher(codeVoucher);
    setTriggerPricing(Math.floor(Math.random() * 1000 + 1));
  };

  const closeDialogCheckout = () => {
    setOpenDialogCheckoutReg(false);
    onVoucher("");
    setTriggerPricing(Math.floor(Math.random() * 1000 + 1));
  };

  // ========== Hit Subscribe ==========
  const handleCheckoutReg = () => {
    setLoading(true);

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + userToken,
    };

    const data = new URLSearchParams();

    if (paket === "Bisnis") {
      data.append(
        "Membership[membership_account_type_id]",
        ACCOUNT_TYPE_BISNIS
      );
      data.append("Membership[membership_package_id]", PACKAGE_BISNIS_A);
      data.append("Membership[membership_duration_value]", totalDuration);
      data.append(
        "Membership[membership_payment_method_id]",
        selectedPayment.id
      );
      data.append("Membership[membership_item][0][id]", USER_LICENSE);
      data.append("Membership[membership_item][0][quantity]", totalUser);
      data.append("Membership[membership_item][1][id]", PMO_VISIT);
      data.append("Membership[membership_item][1][quantity]", totalPmo);
      data.append("Membership[membership_item][2][id]", DEPLOYMENT);
      data.append("Membership[membership_item][2][quantity]", totalDeployment);
      data.append("Membership[membership_voucher_code]", codeVoucher);
    } else if (paket === "Personal") {
      data.append(
        "Membership[membership_account_type_id]",
        ACCOUNT_TYPE_PERSONAL
      );
      data.append("Membership[membership_voucher_code]", codeVoucher);
      data.append("Membership[membership_package_id]", PACKAGE_PERSONAL);
      data.append("Membership[membership_duration_value]", totalDuration);
      data.append(
        "Membership[membership_payment_method_id]",
        selectedPayment.id
      );
      data.append("Membership[membership_item][0][id]", USER_LICENSE);
      data.append("Membership[membership_item][0][quantity]", totalUser);
      if (totalPmo >= 1) {
        data.append("Membership[membership_item][1][id]", PMO_VISIT);
        data.append("Membership[membership_item][1][quantity]", totalPmo);
      }
      if (totalDeployment >= 1) {
        data.append("Membership[membership_item][2][id]", DEPLOYMENT);
        data.append(
          "Membership[membership_item][2][quantity]",
          totalDeployment
        );
      }
    }

    Axios.defaults.headers.common = header;

    Axios.post(`${URL_API}/account-management/membership:subscribe`, data)
      .then((res) => {
        // console.log("Response Checkout Bisnis", res);
        const result = res.data.data;

        if (res.status === 200) {
          Axios.get(
            `${URL_API}/account-management/membership-payment/check-status`
          ).then((response) => {
            // console.log("Res Status Payment", response);
            const result2 = response.data.data;

            if (response.status === 200) {
              if (result2.status_id === PAYMENT_STATUS_PENDING) {
                setCheckoutData(result2);
                setOpenDialogPayment(true);
              }

              if (result2.status_id === PAYMENT_STATUS_PAID) {
                setCheckoutData(result2);
                setOpenDialogPaidPayment(true);
              }
            }

            setLoading(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("Error : ", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.info.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.info.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  const renderPayment = listPaymentMethods
    .filter((bank) => bank.status_id === STATUS_BANK_AVAILABLE)
    .map((payment) => {
      const isCurrent = selectedPayment.name === payment.name;
      let img_url;

      if (payment.name === "BCA") {
        img_url = bank_bca;
      } else if (payment.name === "BNI") {
        img_url = bank_bni;
      } else if (payment.name === "BRI") {
        img_url = bank_bri;
      } else if (payment.name === "Permata") {
        img_url = bank_permata;
      } else if (payment.name === "Mandiri") {
        img_url = bank_mandiri;
      }

      return (
        <label key={payment.id}>
          <Paper
            variant={isCurrent ? "elevation" : "outlined"}
            elevation={3}
            style={{
              border: isCurrent && "1px solid #d1354a",
              backgroundColor: isCurrent && "#f9e5e6",
            }}
            className={classes.paymentCard}
          >
            <img src={img_url} height="20" />
          </Paper>
          <Radio
            onChange={() => handleChangePayment(payment)}
            checked={selectedPayment.name === payment.name}
            style={{
              visibility: "hidden",
              height: 0,
              width: 0,
              opacity: 0,
            }}
          />
        </label>
      );
    });

  return (
    <Dialog
      open={openDialogCheckoutReg}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl" //*Default "md"
      style={{ height: "auto", width: "100%" }}
    >
      <DialogTitle id="customized-dialog-title">
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="close"
            size="small"
            onClick={closeDialogCheckout}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{ width: 1000, paddingRight: 100, paddingLeft: 100 }}
      >
        <Grid container>
          <Grid item md={8}>
            <Box marginBottom={4}>
              <Typography variant="h6" className={classes.title}>
                <b>Periksa pesanan anda</b>
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ width: 600, color: "#888" }}
              >
                Harap periksa kembali paket pesanan anda, pastikan anda memilih
                paket keanggotaan yang sesuai dengan kebutuhan anda
              </Typography>
            </Box>

            <Box marginBottom={5} width={600}>
              <CardChoosenPackage
                classes={classes}
                paket={paket}
                membership={membership}
                duration={duration}
                totalUser={totalUser}
                totalDeployment={totalDeployment}
                totalPmo={totalPmo}
              />
            </Box>

            <Box marginBottom={8}>
              <Typography variant="subtitle1" className={classes.title}>
                <b>Metode Pembayaran</b>
              </Typography>

              <Paper variant="outlined" style={{ width: 100 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "#555", padding: 10 }}
                >
                  Kartu Kredit / Debit Card
                </Typography>
              </Paper>
            </Box>

            <Typography variant="subtitle2" className={classes.title}>
              Virtual Account (Di cek otomatis)
            </Typography>

            <Box
              width={300}
              marginTop={1.5}
              display="flex"
              justifyContent="space-between"
            >
              {renderPayment}
            </Box>
          </Grid>
          <Grid md={4}>
            <Paper variant="outlined">
              <Box padding={2}>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ marginBottom: 5 }}
                >
                  <b>Summary</b>
                </Typography>

                <Grid container>
                  <Grid item md={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Total harga lisensi
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Biaya Consultant visit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Biaya Deployment
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : {FormatThousand(priceUser)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : {FormatThousand(pricePmo)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : {FormatThousand(priceDeployment)}
                    </Typography>
                  </Grid>
                </Grid>

                <Box marginTop={3} marginBottom={3.5}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ marginBottom: 5 }}
                  >
                    <b>Voucher</b>
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ width: 215 }}
                      // disabled={checkoutFee.total_item_price_discount !== "00"}
                      onChange={(e) => setCodeVoucher(e.target.value)}
                    />
                    <Button
                      size="small"
                      className={classes.btnVoucher}
                      // disabled={checkoutFee.total_item_price_discount !== "00"}
                      onClick={handleSetVoucher}
                    >
                      Pakai
                    </Button>
                  </Box>
                  {checkoutFee.total_item_price_discount !== "00" ? (
                    <span
                      style={{ color: "green", fontSize: 13, marginTop: 5 }}
                      className={classes.title}
                    >
                      Code Voucher Valid!
                    </span>
                  ) : (
                    <ul className={classes.voucherErr}>
                      {voucherErrorsList.length > 0 &&
                        voucherErrorsList.map((err, i) => (
                          <li key={i} className={classes.voucherErrItem}>
                            {err.description}
                          </li>
                        ))}
                    </ul>
                  )}
                </Box>

                <Box marginBottom={1.5}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ marginBottom: 5 }}
                  >
                    <b>Pembayaran</b>
                  </Typography>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        Total Harga
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        Discount (%)
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        : Rp {FormatThousand(checkoutFee.total_item_price)}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        : Rp{" "}
                        {FormatThousand(checkoutFee.total_item_price_discount)}{" "}
                        {checkoutFee.voucher !== undefined &&
                          checkoutFee.voucher !== null &&
                          `(${checkoutFee.voucher.value}%)`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Divider variant="fullWidth" />

                <Box marginTop={1.5}>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        <b>Total Pembayaran</b>
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        <b>
                          : Rp{" "}
                          {FormatThousand(
                            checkoutFee.total_item_price_after_discount
                          )}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Button
                  className={classes.button1}
                  fullWidth
                  variant="contained"
                  style={{ marginTop: 20 }}
                  disabled={selectedPayment.name === undefined}
                  onClick={handleCheckoutReg}
                >
                  {loading ? (
                    <CircularProgress color="#fff" size={15} />
                  ) : (
                    "Bayar"
                  )}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: 50 }} />

      <DialogPayment
        openDialogPayment={openDialogPayment}
        setOpenDialogPayment={setOpenDialogPayment}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutReg}
        setOpenDialogPackage={setOpenDialogPackage}
        classes={classes}
        checkoutData={checkoutData}
        paket={paket}
        membership={membership}
        duration={duration}
        totalUser={totalUser}
        totalDeployment={totalDeployment}
        totalPmo={totalPmo}
      />

      <DialogPaidPayment
        classes={classes}
        openDialogPaidPayment={openDialogPaidPayment}
        setOpenDialogPaidPayment={setOpenDialogPaidPayment}
        setOpenDialogPayment={setOpenDialogPayment}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutReg}
        setOpenDialogPackage={setOpenDialogPackage}
        dataPay={checkoutData}
        paket={paket}
        membership={membership}
        duration={duration}
        totalUser={totalUser}
        totalDeployment={totalDeployment}
        totalPmo={totalPmo}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogCheckoutReg;
