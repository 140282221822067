import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Checkbox,
  Box,
} from "@material-ui/core";

const ListItemNotifSelect = ({
  classes,
  item,
  onHandleChange,
  arr,
  events,
  isDisabled,
}) => {
  const eventCheck = events.includes(item.id);

  return (
    <ListItem>
      {/* <ListItem disabled={arr === "4e095bf1-3c77-4fcd-833f-4830d7abba8f"}> */}
      <ListItemIcon style={{ minWidth: 30 }}>
        <Checkbox
          size="small"
          edge="start"
          // checked={isItemSelected}
          value={item.id}
          disableRipple
          onChange={onHandleChange}
          checked={eventCheck}
          disabled={isDisabled}
          // onClick={(e) => handleChangeCheckbox(e, item)}
          // disabled={arr === "4e095bf1-3c77-4fcd-833f-4830d7abba8f"}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="caption" className={classes.title888}>
            {item.name}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default ListItemNotifSelect;
