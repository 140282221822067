import React from "react";
import { IconButton } from "@material-ui/core";

import PictIconGoalWhenEmptyState from "../../../../assets/images/Group_2966.png";

const PictEmptyStateGoal = (props) => {
  return (
    <IconButton disabled>
      <img src={PictIconGoalWhenEmptyState} style={{ width: 48, height: 32 }} />
    </IconButton>
  );
};

export default PictEmptyStateGoal;
