import React, { Fragment} from "react";
import { Typography } from '@material-ui/core';

const MemberName = props => {

    const { 
        classes,
        firstName,
        lastName


    } = props;

    return (
        <Typography variant='subtitle2' className={classes.title} style={{color: 'white'}}>
            <b>
                { firstName !== null ? firstName + " " : "-"}
                { lastName !== null ? lastName : ''}
            </b>
        </Typography>
    )
}

export default MemberName;