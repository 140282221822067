import React, { useEffect } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Container,
  Paper,
  Breadcrumbs,
  Chip,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import Redirect from "../utilities/Redirect";
import {
  ToHrEmployeeIdentitas,
  ToHrEmployeeIdentitasAlamat,
  ToEmptyStateGeneral,
  ToHrEmployeeIdentitasInfoTambahan,
  ToHrEmployeeKeluarga,
  ToHrEmployeeKeahlian,
  ToHrEmployeeRiwayat,
  ToHrEmployeeRiwayatUrgentContact,
} from "../constants/config-redirect-url";

const theme = createMuiTheme({
  //    spacing: 1,

  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "white",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    // marginRight: theme.spacing(2),
    color: "#cc0707",
    //   backgroundColor: '#cc0707',
    "&:hover": {
      color: "white",
      backgroundColor: "#cc0707",
    },
  },

  title: {
    flexGrow: 1,
    color: "black",
    paddingBottom: theme.spacing(0),
    //   marginTop: theme.spacing(1),
    fontFamily: "Roboto",
  },
  titleBreadCrumb: {
    fontFamily: "Roboto",
  },
  titleBreadCrumbNotActive: {
    fontFamily: "Roboto",
    cursor: "pointer",
  },
  titleBreadCrumbActive: {
    fontFamily: "Roboto",
    backgroundColor: "black",
    color: "white",
    cursor: "pointer",
  },
  question: {
    color: "grey",
    textTransform: "capitalize",
  },
  buttonAction: {
    textTransform: "capitalize",
  },
  logoText: {
    flexGrow: 1,
    width: 120,
    height: 24,
  },
  logo: {
    width: 30,
    height: 30,
  },
  paperBreadCrumb: {
    // textAlign: 'center' //*Not works
  },
});

const HeaderHREmployeeKepegawaian = (props) => {
  // const classes = useStyles();
  const { classes } = props;

  useEffect(() => {
    const currentLocation = window.location.pathname;
    // console.log("currentLocation : ", currentLocation);

    // if(){

    // }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar position="sticky">
        <div className={classes.root}>
          <Toolbar>
            <IconButton
              onClick={() => Redirect(ToEmptyStateGeneral)}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
            >
              <KeyboardBackspaceIcon style={{ fontSize: 27 }} />
            </IconButton>

            <Typography className={classes.title}>
              <b>Daftar Karyawan</b>
            </Typography>
          </Toolbar>
        </div>
      </AppBar>

      <Container>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <br />
            <Typography variant="h6" className={classes.title}>
              <b>Tambah Karyawan</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={8}>
            <br />
            <Paper elevation={0} className={classes.paperBreadCrumb}>
              <Breadcrumbs
                className={classes.titleBreadCrumb}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="Breadcrumb"
              >
                {/* 
                                    {
                                        breadCrumbs.length > 0 && breadCrumbs.map((item, i) =>(

                                            <Link key={i} color="inherit" href={ToHrEmployeeIdentitas} onClick={() => console.log('handle click')}>
                                                <Chip size="small" label={item.label} className={classes.titleBreadCrumbActive}  />
                                            </Link>
                                        ))
                                    } */}

                {/* 
                                    <Link color="inherit" href={ToHrEmployeeIdentitas} onClick={() => console.log('handle click')}>
                                        <Chip size="small" label="Identitas" className={classes.titleBreadCrumbActive}  />
                                    </Link> 
                                */}

                <Typography
                  onClick={() => Redirect(ToHrEmployeeIdentitas)}
                  variant="subtitle2"
                  className={classes.titleBreadCrumbNotActive}
                >
                  Identitas
                </Typography>
                <Typography
                  onClick={() => Redirect(ToHrEmployeeKeluarga)}
                  variant="subtitle2"
                  className={classes.titleBreadCrumbNotActive}
                >
                  Keluarga
                </Typography>
                <Chip
                  size="small"
                  label=" Kepegawaian"
                  className={classes.titleBreadCrumbActive}
                />
                <Typography
                  onClick={() => Redirect(ToHrEmployeeKeahlian)}
                  variant="subtitle2"
                  className={classes.titleBreadCrumbNotActive}
                >
                  Keahlian
                </Typography>
                <Typography
                  onClick={() => Redirect(ToHrEmployeeRiwayat)}
                  variant="subtitle2"
                  className={classes.titleBreadCrumbNotActive}
                >
                  Riwayat
                </Typography>
                <Typography
                  onClick={() => Redirect(ToHrEmployeeRiwayatUrgentContact)}
                  variant="subtitle2"
                  className={classes.titleBreadCrumbNotActive}
                >
                  Kontak Darurat
                </Typography>
              </Breadcrumbs>
            </Paper>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Container>
    </MuiThemeProvider>
  );
};

// export default Header;
export default withStyles(styles)(HeaderHREmployeeKepegawaian);

const breadCrumbs = [
  {
    label: "Identitas",
    url: ToHrEmployeeIdentitas,
  },
  {
    label: "Identitas",
    url: ToHrEmployeeIdentitasAlamat,
  },
  {
    label: "Identitas",
    url: ToHrEmployeeIdentitasInfoTambahan,
  },
];
