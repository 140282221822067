import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Grid } from "@material-ui/core";

import why_us_img from "../../../../assets/images/why_choose_us.png";
import whyUs1 from "../../../../assets/images/whyUs1.png";
import whyUs2 from "../../../../assets/images/whyUs2.png";
import whyUs3 from "../../../../assets/images/whyUs3.png";
import whyUs4 from "../../../../assets/images/whyUs4.png";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#888",
    textDecoration: "none",
  },
  cardField: {
    borderRadius: 15,
    border: "3px solid #F3F2F2",
    padding: "7px 30px",
    margin: "0px 0px 30px 0px",
    maxWidth: "80%",
  },
  imgWhy: {
    height: 250,
    width: "100%",
    borderRadius: 10,
    "@media (min-width: 576px)": {
      height: 350,
      width: "100%",
    },
    "@media (min-width: 768px)": {
      height: 350,
      width: "100%",
    },
    "@media (min-width: 992px)": {
      height: 350,
      width: "100%",
    },
  },
});

const useStyle = makeStyles(styles);

const WhyUs = () => {
  const classes = useStyle();

  return (
    // <Box bgcolor="#F2F4F7">
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={6} alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          <img src={why_us_img} className={classes.imgWhy} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} alignItems="center">
        <Box pb={4} display="flex" justifyContent="center">
          <Typography variant="h5" className={classes.title333}>
            <b>Kenapa Pilih Performate?</b>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box px={3}>
            {listField.map((item, i) => (
              <Box key={i} mb={2} display="flex" alignItems="center">
                <img src={item.icon} height={50} width={50} />
                <Typography
                  variant="subtitle1"
                  className={classes.title888}
                  style={{ marginLeft: 10 }}>
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
    // </Box>
  );
};

export default WhyUs;

const listField = [
  {
    label: "Mudah digunakan seluruh anggota tim anda",
    icon: whyUs1,
  },
  {
    label: "Mendukung strategi jangka panjang bisnis anda",
    icon: whyUs2,
  },
  {
    label: "Mudahnya berkolaborasi antar tim dalam mencapai goal",
    icon: whyUs3,
  },
  {
    label: "Pantau kinerja karyawan / tim hanya dengan 5 detik",
    icon: whyUs4,
  },
];
