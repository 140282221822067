const mapTable = (arr) => {
  // // console.log("ARR DATS", arr);

  const data =
    arr !== undefined &&
    arr.length > 0 &&
    arr.map((todo) => {
      return {
        id: todo.id,
        toggle: {
          completed: todo.is_complete.id,
        },
        name: todo,
        owner: {
          full_name:
            todo.owner.member !== null
              ? `${todo.owner?.member?.first_name} ${todo.owner?.member?.last_name}`
              : "-",
          photo_url: todo.owner.member.self.rel.photo_url,
        },
        status: todo.status,
        priority: todo.priority,
        due_date: todo.due_date,
        start_date: todo.start_date,
        result: {
          target: todo.target_result,
          actual: todo.actual_result,
        },
        created_by: {
          full_name:
            todo?.createdBy?.member !== null
              ? `${todo?.createdBy?.first_name} ${todo?.createdBy?.last_name}`
              : "-",
          photo_url: todo?.createdBy?.photo_url,
        },
      };
    });

  return data.length > 0 ? data : [];
};

export default mapTable;
