export const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 2,
  },

  /*
        ``````````````````````
        STYLING PAKET TRIAL

        ````````````````````````
    */
  subRootTrial: {
    height: 550,
    width: 300,
    marginLeft: theme.spacing(12),
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    padding: 12,
  },

  title: {
    fontFamily: "Roboto",
  },
  titleTrial: {
    fontFamily: "Roboto",
    color: "white",
    marginBottom: theme.spacing(14),
    paddingLeft: 30,
    fontSize: 16,
    paddingRight: 30,
  },
  titleGratis: {
    fontFamily: "Roboto",
    color: "white",
    marginTop: theme.spacing(9.5),
  },
  titleIconChecklist: {
    fontFamily: "Roboto",
    color: "white",
    marginLeft: 30,
  },
  icon: {
    color: "white",
    position: "absolute",
  },
  button: {
    borderRadius: 5,
    background: "#d1354a",
    border: 0,
    color: "#fff",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    padding: "10px 20px",
    width: 120,
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  btnExtndBg: {
    backgroundColor: "transparent",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    border: "none",
  },
  btnVoucher: {
    borderRadius: 5,
    background: "#f39c12",
    border: 0,
    color: "#fff",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#f1c40f",
    },
  },
  button1: {
    borderRadius: 5,
    background: "#d1354a",
    border: 0,
    color: "#fff",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  button2: {
    borderRadius: 5,
    border: `1px solid #d1354a`,
    color: "#d1354a",
    backgroundColor: "transparent",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "hsla(0, 100%, 85%, 0.3)",
    },
  },
  buttonTrial: {
    height: "62px",
    borderRadius: 5,
    backgroundColor: "hsla(0, 100%, 85%, 0.3);",
    borderWidth: 1,
    borderColor: "#fff",
    fontFamily: "Roboto",
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
    textTransform: "capitalize",
    marginTop: theme.spacing(8),
  },

  buttonPro: {
    height: "62px",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#333",
    fontFamily: "Roboto",
    color: "#333",
    fontWeight: "bold",
    fontSize: 16,
    textTransform: "capitalize",
    marginTop: theme.spacing(8),
  },

  buttonPackage: {
    borderRadius: 5,
    backgroundColor: "#102770",
    border: 0,
    color: "#fff",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    padding: "10px 20px",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#003780",
    },
  },

  dashedBtn: {
    borderStyle: "dashed",
    borderRadius: 5,
    borderColor: "#aaa",
    borderWidth: 2,
    paddingTop: 7,
    paddingBottom: 7,
    height: 22,
    width: 250,
    "&:hover": {
      borderColor: "#ccc",
      color: "#888",
      cursor: "pointer",
    },
  },
  txtDashedBtn: {
    color: "#333",
    fontFamily: "Roboto",
    margin: 0,
    paddingTop: 5,
    fontSize: 13,
    "&:hover": {
      color: "#888",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },

  /*
        ``````````````````````
        STYLING PAKET BERBAYAR

        ````````````````````````
    */

  subRootBerbayar: {
    height: 550,
    width: 300,
    marginLeft: theme.spacing(8),
    backgroundColor: "white",
    padding: 12,
  },
  titleBerbayar: {
    fontFamily: "Roboto",
    color: "black",
    marginBottom: theme.spacing(2),
  },
  textPro1: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    fontFamily: "Roboto",
    color: "#333",
    fontSize: 16,
    fontWeight: "600",
    paddingLeft: 15,
    paddingRight: 15,
  },
  textPro2: {
    height: theme.spacing(13.5),
    fontFamily: "Roboto",
    color: "#222",
    fontSize: 16,
    fontWeight: "700",
    paddingLeft: 25,
    paddingRight: 25,
  },
  buttonToggle: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    color: "white",
  },
  titlePotonganHarga: {
    fontFamily: "Roboto",
    color: "black",
  },
  titleStrikePrice: {
    fontFamily: "Roboto",
    color: "grey",
  },
  titlePrice: {
    fontFamily: "Roboto",
    color: "black",
    fontWeight: "bold",
  },

  /* 
        ```````````````
        SLIDER MAHKOTA 
        
        ```````````````
    */
  sliderMahkota: {
    marginTop: theme.spacing(4.5),
    width: 300,
    textAlign: "center",
    display: "inline-block",
  },
  thumbIconWrapperMahkota: {
    backgroundColor: "#fff",
  },
  trackSliderMahkota: {
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    padding: "2px",
    borderRadius: "10%",
  },
  thumbIconMahkota: {
    width: "20px",
    height: "20px",
    marginBottom: "5px",
    position: "absolute",
  },

  /* 
        ````````````````````````
        FIGURE BOX PICT MAHKOTA 
        
        ````````````````````````
    */
  figureBoxPictMahkota: {
    width: 63,
    height: 63,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#f67070",
    display: "inline-block",
    margin: 5,
    textAlign: "center",
    cursor: "pointer",
  },
  figurePictMahkota: {
    display: "inline-block",
    margin: 5,
    textAlign: "center",
  },
  iconFullUpdateMahkota: {
    color: "black",
    position: "absolute",
  },
  titleIconChecklistMahkota: {
    fontFamily: "Roboto",
    color: "black",
  },

  /*
        ```````````````````
        MODAL, BUTTON MODAL

        ```````````````````
    */
  buttonModal: {
    // height: '42px',
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },
  media: {
    height: 80,
    width: 80,
    // marginLeft: theme.spacing(7)
  },

  breadcrumb: {
    // marginLeft: theme.spacing(5),
  },
  buttonModalCancel: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  /* 
        ````````````````````````
        DIALOG MODAL UPGRADE
        
        ````````````````````````
    */
  imageBank: {
    width: 60,
    height: 21,
    // marginTop: theme.spacing(1)
    marginTop: 3,
    marginRight: theme.spacing(2),
  },
  listItemSecondaryActionDialogModal: {
    // paddingLeft: theme.spacing(6)
  },
  titleModal: {
    fontFamily: "Roboto",
  },

  /* 
        ``````````````````````````````````
        DIALOG MODAL UPGRADE SAVE INVOICE
        
        ``````````````````````````````````
    */
  paperTigaDigitTerakhir: {
    // backgroundColor: 'cyan',
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    padding: 10,
    color: "white",
  },
  pictInvoice: {
    width: 60,
    height: 53,
  },
  /* 
        ``````````````````````````````````
        DIALOG MODAL CONTACTUS
        ``````````````````````````````````
    */
  textField: {
    minWidth: 425,
  },

  buttonKirim: {
    backgroundColor: "#d1354a",
    color: "#fff",
    minWidth: 305,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 15,
    marginRight: 15,
    fontSize: 15,
    fontWeight: "600",
    fontFamily: "Roboto",
    height: 50,
    textTransform: "Capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#c1272d",
    },
  },

  buttonNext: {
    backgroundColor: "#d1354a",
    color: "#fff",
    minWidth: 305,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 15,
    marginRight: 15,
    fontSize: 15,
    fontWeight: "600",
    fontFamily: "Roboto",
    height: 50,
    textTransform: "Capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#c1272d",
    },
  },

  // =============================
  // MEBERSHIP STYLES
  // =============================

  cardPackage: {
    width: 325,
    backgroundColor: "#102770",
    borderRadius: 7.5,
    "&:hover": {
      backgroundColor: "#003780",
      cursor: "pointer",
    },
  },
  cardPlain: {
    width: 325,
    borderRadius: 7.5,
    border: `0.5px solid`,
    borderColor: "#ddd",
    "&:hover": {
      backgroundColor: "#f0f0f0",
      cursor: "pointer",
    },
  },
  txtTitle: {
    color: "#fff",
    backgroundColor: "#eb4d4b",
    fontFamily: "Roboto",
    fontSize: 16,
    width: 95,
    borderBottomRightRadius: 40,
    borderTopLeftRadius: 7.5,
    padding: `2px 10px`,
    fontWeight: "600",
  },
  txtTitlePlain: {
    color: "#333",
    fontFamily: "Roboto",
    fontSize: 16,
    padding: `2px 10px`,
    fontWeight: "600",
  },
  txtPrice: {
    color: "#d63031",
    fontFamily: "Roboto",
    fontWeight: "bold",
    padding: `0 10px`,
    margin: `25px 0 5px 0`,
    fontSize: 25,
  },
  txtDetail: {
    color: "#e58e26",
    fontSize: 13,
    fontFamily: "Roboto",
    padding: `0 10px`,
    marginBottom: 25,
  },
  listBenefits: {
    padding: `0 10px`,
    marginBottom: 25,
  },
  benefitText: {
    color: "#fff",
    fontSize: 13,
    fontFamily: "Roboto",
  },
  benefitTextPlain: {
    color: "#555",
    fontSize: 13,
    fontFamily: "Roboto",
  },

  // ========================================
  // Styled Checkbox
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a",
    },
  },

  // ========================================
  // Style For Checkout Register
  cardCheckout: {
    display: "flex",
    backgroundColor: "#102770",
    borderRadius: 20,
  },
  cardImgCont: {
    position: "relative",
    textAlign: "center",
    color: "#fff",
  },
  textBadge: {
    color: "#fff",
    backgroundColor: "#eb4d4b",
    fontFamily: "Roboto",
    width: 95,
    borderBottomRightRadius: 40,
    borderTopLeftRadius: 7.5,
    padding: `2px 10px`,
    fontWeight: "600",
    position: "absolute",
  },
  txtCenterCard: {
    left: 52,
    position: "absolute",
    top: 52,
    fontFamily: "Roboto",
  },
  txtCenterCard1: {
    left: 30,
    position: "absolute",
    top: 52,
    fontFamily: "Roboto",
  },
  txtSummary: {
    color: "#555",
    marginBottom: 5,
    fontFamily: "Roboto",
  },
  txtSummary2: {
    color: "#555",
    marginBottom: 10,
    fontFamily: "Roboto",
  },
  txtCartItem: {
    color: "#fff",
    fontFamily: "Roboto",
  },
  paymentCard: {
    padding: `10px 20px`,
    minWidth: 55,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cancelButton: {
    backgroundColor: "transparent",
    color: "#888",
    fontFamily: "Roboto",
    border: 0,
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  cancelButton2: {
    backgroundColor: "#eeeeee",
    color: "#888",
    fontFamily: "Roboto",
    border: 0,
    fontSize: 14,
    textTransform: "capitalize",
  },
  countdownBox: {
    border: `1px solid #ffbb34`,
    width: 600,
    borderRadius: 15,
  },
  countdownTitle: {
    fontFamily: "Roboto",
    color: "#fff",
    background: "#ffbb34",
    padding: 20,
    fontSize: 26,
    fontWeight: "bold",
    width: 170,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 15,
  },
  countdownText: {
    fontFamily: "Roboto",
    padding: 20,
    fontSize: 14,
    color: "#555",
  },
  txtVA: {
    fontFamily: "Roboto",
    color: "#555",
    marginBottom: 5,
  },
  txtGuide: {
    fontFamily: "Roboto",
    color: "#555",
    fontSize: 13,
    lineHeight: 2,
  },
  listGuild: {
    paddingLeft: 16,
  },
  extendPckgBg: {
    border: `1.2px solid #ff9e0c`,
    backgroundColor: "#fff5e6",
    borderRadius: 3,
  },

  // ============== Errror Voucher
  voucherErr: {
    listStyle: "none",
    paddingLeft: 0,
  },
  voucherErrItem: {
    color: "#eb4d4b",
    fontFamily: "Roboto",
    fontSize: 13,
  },
});
