import React, { useContext, useEffect } from "react";
import { Box, Typography, FormControlLabel, Checkbox } from "@material-ui/core";

import clsx from "clsx";

import ContextGoalDetail from "../../Goal/Context/ContextGoal";

const AnchorFilterProject = ({
  classes,
  category,
  setCategory,
  type,
  setType,
  setUrlCategory,
  setUrlType,
}) => {
  const context = useContext(ContextGoalDetail);

  // =============== Side Effect ===============
  // useEffect(() => {
  //   if (category.operational && category.project) {
  //     setUrlCategory(
  //       "&options[filter][goal_category][0]=PROJECT&options[filter][goal_category][1]=OPERATIONAL"
  //     );
  //     context.setGoalCategory(
  //       "&options[filter][goal_category][0]=PROJECT&options[filter][goal_category][1]=OPERATIONAL"
  //     );
  //   } else if (!category.operational && category.project) {
  //     setUrlCategory("&options[filter][goal_category][0]=PROJECT");
  //     context.setGoalCategory("&options[filter][goal_category][0]=PROJECT");
  //   } else if (category.operational && !category.project) {
  //     setUrlCategory("&options[filter][goal_category][0]=OPERATIONAL");
  //     context.setGoalCategory("&options[filter][goal_category][0]=OPERATIONAL");
  //   } else if (!category.operational && !category.project) {
  //     setUrlCategory("");
  //     context.setGoalCategory("");
  //   }

  //   context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));

  //   // // console.log("CATEGORY TRIGGER");
  // }, [category]);

  // useEffect(() => {
  //   if (type.team && type.personal) {
  //     setUrlType(
  //       "&options[filter][project_type][0]=TEAM&options[filter][project_type][1]=PERSONAL"
  //     );
  //     context.setProjectType(
  //       "&options[filter][project_type][0]=TEAM&options[filter][project_type][1]=PERSONAL"
  //     );
  //   } else if (!type.team && type.personal) {
  //     setUrlType("&options[filter][project_type][0]=PERSONAL");
  //     context.setProjectType("&options[filter][project_type][0]=PERSONAL");
  //   } else if (type.team && !type.personal) {
  //     setUrlType("&options[filter][project_type][0]=TEAM");
  //     context.setProjectType("&options[filter][project_type][0]=TEAM");
  //   } else if (!type.team && !type.personal) {
  //     setUrlType("");
  //     context.setProjectType("");
  //   }

  //   context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  //   // // console.log("TYPE TRIGGER");
  // }, [type]);

  const handleOperational = (e) => {
    const checked = e.target.checked;
    setCategory({ ...category, operational: checked });

    // // console.log("NAME", e.target.name, e.target.checked);

    if (checked && category.project) {
      context.setGoalCategory(
        "&options[goal_category]=PROJECT,OPERATIONAL"
      );
      localStorage.setItem(
        "goal_category",
        "&options[goal_category]=PROJECT,OPERATIONAL"
      );
    } else if (!checked && category.project) {
      context.setGoalCategory("&options[goal_category]=PROJECT");
      localStorage.setItem(
        "goal_category",
        "&options[goal_category]=PROJECT"
      );
    } else if (checked && !category.project) {
      context.setGoalCategory("&options[goal_category]=OPERATIONAL");
      localStorage.setItem(
        "goal_category",
        "&options[goal_category]=OPERATIONAL"
      );
    } else if (!checked && !category.project) {
      context.setGoalCategory("");
      localStorage.setItem("goal_category", "");
    }

    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handleProject = (e) => {
    const checked = e.target.checked;
    setCategory({ ...category, project: checked });

    // // console.log("NAME", e.target.name, e.target.checked);

    if (category.operational && checked) {
      context.setGoalCategory(
        "&options[goal_category]=PROJECT,OPERATIONAL"
      );
      localStorage.setItem(
        "goal_category",
        "&options[goal_category]=PROJECT,OPERATIONAL"
      );
    } else if (!category.operational && checked) {
      context.setGoalCategory("&options[goal_category]=PROJECT");
      localStorage.setItem(
        "goal_category",
        "&options[goal_category]=PROJECT"
      );
    } else if (category.operational && !checked) {
      context.setGoalCategory("&options[goal_category]=OPERATIONAL");
      localStorage.setItem(
        "goal_category",
        "&options[goal_category]=OPERATIONAL"
      );
    } else if (!category.operational && !checked) {
      context.setGoalCategory("");
      localStorage.setItem("goal_category", "");
    }

    setType({ team: true, personal: false });
    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handleTeam = (e) => {
    const checked = e.target.checked;
    setType({ ...type, team: checked });

    if (checked && type.personal) {
      context.setProjectType(
        "&options[project_type]=TEAM,PERSONAL"
      );
      localStorage.setItem(
        "project_type",
        "&options[project_type]=TEAM,PERSONAL"
      );
    } else if (!checked && type.personal) {
      context.setProjectType("&options[project_type]=PERSONAL");
      localStorage.setItem(
        "project_type",
        "&options[project_type]=PERSONAL"
      );
    } else if (checked && !type.personal) {
      context.setProjectType("&options[project_type]=TEAM");
      localStorage.setItem(
        "project_type",
        "&options[project_type]=TEAM"
      );
    } else if (!checked && !type.personal) {
      context.setProjectType("");
      localStorage.setItem("project_type", "");
    }

    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  const handlePersonal = (e) => {
    const checked = e.target.checked;
    setType({ ...type, personal: checked });

    if (type.team && checked) {
      context.setProjectType(
        "&options[project_type]=TEAM,PERSONAL"
      );
      localStorage.setItem(
        "project_type",
        "&options[project_type]=TEAM,PERSONAL"
      );
    } else if (!type.team && checked) {
      context.setProjectType("&options[project_type]=PERSONAL");
      localStorage.setItem(
        "project_type",
        "&options[project_type]=PERSONAL"
      );
    } else if (type.team && !checked) {
      context.setProjectType("&options[project_type]=TEAM");
      localStorage.setItem(
        "project_type",
        "&options[project_type]=TEAM"
      );
    } else if (!type.team && !checked) {
      context.setProjectType("");
      localStorage.setItem("project_type", "");
    }

    context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
  };

  return (
    <Box minWidth={200}>
      <Box mb={1}>
        <Typography
          className={classes.title888}
          variant="subtitle2"
          gutterBottom
        >
          Goal Category
        </Typography>
      </Box>

      <FormControlLabel
        label={
          <Typography
            variant="subtitle2"
            className={classes.title555}
            style={{ textTransform: "capitalize" }}
          >
            Operational
          </Typography>
        }
        style={{ paddingLeft: 15 }}
        control={
          <Checkbox
            name="operational"
            className={classes.checkboxRoot}
            icon={<span className={classes.icon} />}
            checked={category.operational}
            onChange={handleOperational}
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
          />
        }
      />

      <FormControlLabel
        label={
          <Typography
            variant="subtitle2"
            className={classes.title555}
            style={{ textTransform: "capitalize" }}
          >
            Project
          </Typography>
        }
        style={{ paddingLeft: 15 }}
        control={
          <Checkbox
            name="project"
            className={classes.checkboxRoot}
            icon={<span className={classes.icon} />}
            checked={category.project}
            onChange={handleProject}
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
          />
        }
      />

      {category.project && (
        <>
          <Box my={1}>
            <Typography
              className={classes.title888}
              variant="subtitle2"
              gutterBottom
            >
              Project Type
            </Typography>
          </Box>

          <FormControlLabel
            label={
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{ textTransform: "capitalize" }}
              >
                Team
              </Typography>
            }
            style={{ paddingLeft: 15 }}
            control={
              <Checkbox
                name="team"
                className={classes.checkboxRoot}
                checked={type.team}
                onChange={handleTeam}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
              />
            }
          />

          <FormControlLabel
            label={
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{ textTransform: "capitalize" }}
              >
                Personal
              </Typography>
            }
            style={{ paddingLeft: 15 }}
            control={
              <Checkbox
                name="personal"
                className={classes.checkboxRoot}
                checked={type.personal}
                onChange={handlePersonal}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
              />
            }
          />
        </>
      )}
    </Box>
  );
};

export default AnchorFilterProject;
