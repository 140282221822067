import React, { Fragment } from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import _ from 'lodash';

const PositionName = (props) => {
  const { positionName, memberPositionList } = props;

  return (
    <Fragment>
      {positionName !== '' && (
        <Tooltip title={positionName}>
          <Chip
            label={_.truncate(positionName, {
              length: 30
            })}
            style={{
              color: 'white',
              fontFamily: 'Roboto',
              marginTop: 8,
              marginRight: 3,
              fontSize: 10
            }}
            size="small"
            variant="outlined"
          />
        </Tooltip>
      )}

      {memberPositionList.length > 0 &&
        positionName === '' &&
        memberPositionList.map((item, i) => (
          <Tooltip title={item.structure_position_title_name}>
            <Chip
              key={i}
              label={_.truncate(item.structure_position_title_name, {
                length: 30
              })}
              style={{
                color: 'white',
                fontFamily: 'Roboto',
                marginTop: 8,
                marginRight: 3,
                fontSize: 10
              }}
              size="small"
              variant="outlined"
            />
          </Tooltip>
        ))}
    </Fragment>
  );
};

export default PositionName;
