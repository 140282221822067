import React from "react";
import { Typography, Box, Card, CardMedia } from "@material-ui/core";

const Card3Items = ({
  classes,
  account,
  pckgName,
  prop1,
  prop2,
  prop3,
  val1,
  val2,
  val3,
  icon1,
  icon2,
  icon3,
}) => {
  return (
    <Card variant="outlined" className={classes.cardExtend}>
      <Box className={classes.cardImgEx} width={160}>
        <Typography variant="subtitle1" className={classes.textBadge}>
          {pckgName}
        </Typography>
        <CardMedia
          src="https://picsum.photos/160/160/?blur=4"
          component="img"
          height="100"
        />
        <Typography
          variant="h5"
          className={
            account === "Personal"
              ? classes.txtLabelCard
              : classes.txtLabelCard1
          }>
          <b>{account}</b>
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width={340}
        padding={2}>
        <Box>
          <Typography variant="caption" className={classes.txtCartItem}>
            <b>{prop1}</b>
          </Typography>
          <Box display="flex" alignItems="center">
            {icon1}
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#eb4d4b", marginLeft: 5 }}>
              {val1}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="caption" className={classes.txtCartItem}>
            <b>{prop2}</b>
          </Typography>
          <Box display="flex" alignItems="center">
            {icon2}
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#eb4d4b", marginLeft: 5 }}>
              {val2}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="caption" className={classes.txtCartItem}>
            <b>{prop3}</b>
          </Typography>
          <Box display="flex" alignItems="center">
            {icon3}
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "#eb4d4b", marginLeft: 5 }}>
              {val3}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default Card3Items;
