import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { HighlightOff as HighlightOffIcon } from "@material-ui/icons";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../../../Report/components/global/handleError";
import SnackbarError from "../../../../components/SnackbarError";
import ContextProject from "../../context/ContextProject";

const DialogDuplicateTaskGroup = ({
  classes,
  group,
  openDuplicateGroup,
  setOpenDuplicateGroup,
}) => {
  const { setTriggerTask } = useContext(ContextProject);

  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenSnackbarError, setOpenSnackbarError] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDuplicateGroup = () => {
    setLoading(true);

    const data = {
      name: group.name,
      taskGroupId: group.id,
    };

    AxiosConfig.post(`${URL_API}/todolist/duplicate`, data)
      .then((res) => {
        if (res.status === 200) {
          setOpenDuplicateGroup(false);
          setTriggerTask(Math.floor(Math.random() * 100));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error.response);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);

        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog open={openDuplicateGroup} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Duplicate Task Group</Typography>

          <IconButton onClick={() => setOpenDuplicateGroup(false)}>
            <HighlightOffIcon fontSize="default" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Duplikasi Task Group <b style={{ fontSize: 18 }}>{group.name}</b> ?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 16,
        }}
      >
        <Box display="flex" alignItems="center">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={() => setOpenDuplicateGroup(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            size="small"
            variant={loading ? "contained" : "outlined"}
            className={classes.button1}
            disabled={loading}
            onClick={handleDuplicateGroup}
          >
            {loading ? "Duplicating Task..." : "Duplicate"}
          </Button>
        </Box>
      </DialogActions>

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenSnackbarError}
        setOpenDialogError={setOpenSnackbarError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDuplicateTaskGroup;
