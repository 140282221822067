import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItemText,
  Checkbox,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
} from "@material-ui/core";
import axios from "axios";
import { URL_API } from "../../../../constants/config-api";
import DoneIcon from "@material-ui/icons/Done";

const DialogLanjutInviteAnggota = (props) => {
  const { classes, isModalLanjut, setModalLanjut, selected } = props;

  const [userTokenState, setUserTokenState] = useState("");

  /*
        `````````````````````````
        HANDLE DROPDOWN LIST ROLE
        
        `````````````````````````
    */

  const [isShowDropdown, setShowDropdown] = useState(false);

  const [listRoleNow, setListRoleNow] = useState([]);

  const [roleNow, setRoleNow] = useState([]);

  const handleChangeDropdownMultiple = (event) => {
    // console.log("value dropdown : ", event.target.value);
    setRoleNow(event.target.value);

    setShowDropdown(true);
  };

  useEffect(() => {
    if (isModalLanjut == true) {
      const userToken = localStorage.getItem("userToken");

      if (userToken !== null) {
        setUserTokenState(userToken);

        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          crossorigin: true,
          crossDomain: true,
          Authorization: "bearer " + userToken,
        };

        axios.defaults.headers.common = headers;
        axios
          .get(URL_API + `/account-management/user/batch/create`)
          .then((response) => {
            // console.log("Res Create User : ", response);

            if (response.status == 200) {
              const templateListRole = [];

              if (response.data.data !== undefined) {
                Object.getOwnPropertyNames(
                  response.data.data.roleOptions
                ).forEach((val, idx, array) => {
                  const data = {
                    key: val,
                    value: response.data.data.roleOptions[val],
                  };
                  templateListRole.push(data);
                });
              }

              setListRoleNow(templateListRole);
            }
          })
          .catch((error) => {
            // console.log("Error response : ", error.response);
          });
      } else {
        // console.log("Ga dapet User Token !");
      }
    }
  }, [isModalLanjut]);

  /*
        ``````````````````
        HANDLE INVITE USER

        ``````````````````
    */

  const [loading, setLoading] = useState(false);

  const handleInvite = (e) => {
    e.preventDefault();
    setLoading(true);

    let templateMemberIdList = [];

    if (selected.length > 0) {
      selected.map((item) => {
        let templateMemberId = {
          member_id: item,
          role_id: roleNow,
          // role_id: templateRoleIdList
        };

        templateMemberIdList.push(templateMemberId);
      });
    }

    let data = {
      User: templateMemberIdList,
    };

    // console.log("Data : ", data);

    if (userTokenState !== undefined && userTokenState !== null) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + `/account-management/user/batch`, data)
        .then(function (response) {
          setLoading(false);

          // console.log("Response Original : ", response);

          if (response.status == 200) {
            window.location.reload();
            // setModalLanjut(false);
          }
        })
        .catch(function (error) {
          setLoading(false);

          // console.log("Error : ", error.response);

          if (error.response == 500) {
            alert(
              error.response.data.message !== undefined
                ? error.response.data.message
                : "500"
            );
          } else if (error.response.status == 400) {
            alert(
              error.response.data.info !== undefined
                ? error.response.data.info.developer_message
                : "400"
            );
          } else {
            alert("Ooops, something went wrong !");
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  return (
    <Dialog
      open={isModalLanjut}
      onClose={() => setModalLanjut(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
        <Grid container>
          <Grid item sm={6}>
            <Typography variant="h6" className={classes.title}>
              <IconButton onClick={() => setModalLanjut(false)}>
                <i
                  className="material-icons"
                  style={{ fontSize: 27, color: "black" }}
                >
                  keyboard_backspace
                </i>{" "}
                &nbsp;
              </IconButton>

              <b>
                {selected !== undefined ? parseInt(selected.length) : ""}{" "}
                <i> User</i> sudah terpilih
              </b>
            </Typography>
          </Grid>

          <Grid item sm={6} style={{ textAlign: "right" }}></Grid>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left" }}>
        <FormControl className={classes.formControl}>
          <InputLabel
            htmlFor="select-multiple-checkbox"
            // fullWidth
          >
            {isShowDropdown !== true ? (
              "Pilih Role untuk user yang telah Anda pilih "
            ) : (
              <Typography variant="subtitle" className={classes.title}>
                Berhasil memilih Role <DoneIcon style={{ color: "green" }} />
              </Typography>
            )}
          </InputLabel>
          <Select
            multiple
            value={roleNow}
            onChange={handleChangeDropdownMultiple}
            input={
              <Input
                id="select-multiple-checkbox"
                style={{ width: 350 }}
                // fullWidth

                classes={{
                  // root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            }
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            // fullWidth
          >
            {listRoleNow.map((name) => (
              <MenuItem key={name.key} value={name.key} style={{ width: 350 }}>
                <Checkbox checked={roleNow.indexOf(name.key) > -1} />
                <ListItemText primary={name.value} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle1"
            className={classes.titleModal}
          ></Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions
        style={{ alignItems: "center", justifyContent: "center " }}
      >
        {isShowDropdown == true && (
          <Button
            onClick={handleInvite}
            variant="contained"
            className={classes.button}
            fullWidth
          >
            {loading !== true ? "Invite User sekarang " : null}
            {loading && (
              <CircularProgress size={24} style={{ color: "white" }} />
            )}
          </Button>
        )}
      </DialogActions>
      <br />
    </Dialog>
  );
};

export default DialogLanjutInviteAnggota;

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
