export const styles = theme => ({
	root: {
		margin: '0 auto',
		marginTop: 100,
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: 768,
	},
	topCard: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		background: 'linear-gradient(to left bottom, #3f51b5 30%, #221E5B 90%)',
		borderRadius: 11,
		padding: '0px 20px',
	},
});
