import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  DialogActions,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CheckIcon from "@material-ui/icons/Check";

import Axios from "axios";

import {
  PACKAGE_BISNIS_A,
  PACKAGE_BISNIS_B,
  PACKAGE_BISNIS_C,
  DEPLOYMENT,
  USER_LICENSE,
  PMO_VISIT,
} from "../constant-data";
import { URL_API } from "../../../../constants/config-api";
import { FormatThousand } from "../../../../utilities/FormatDecimal";

import DialogCheckoutReg from "./DialogCheckoutReg";
import HideExtendPackage from "./components/HideExtendPackage";
import ShowExtendPackage from "./components/ShowExtendPackage";

const DialogBussinessPackage = ({
  openDialogBussinessPackage,
  setOpenDialogBussinessPackage,
  userToken,
  classes,
  listBusinessPackage,
  listBusinessPrice,
  listPaymentMethods,
}) => {
  // const token =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLWRldi5wZXJmb3JtYXRlLmlkXC9hcGlcL3YxXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYxMDEwMDIzNiwiZXhwIjoxNjE2NjcwMjM2LCJuYmYiOjE2MTAxMDAyMzYsImp0aSI6IkFNemZLNUN5OVY3VEltb2siLCJzdWIiOiJlNDFmMmMxZS1iYWI3LTQ1NzYtOTcxNy0xZjU2M2ZjOTJjYWIiLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiSzAiOiIxVmgxNVNqNiIsIkp0aTkxeGM5YzMiOiJrb2R4YWNvcDRlcGtja255IiwiS28xIjoiNzUiLCI1enhjYXNkbHAiOiJ2YTUiLCJ2ZXJzaW9uIjoiMS4xIn0.4WqrrlKvN-bepHf-F5Fg_00_ztcenHDYJaUFVXGJW_0";

  // ====================================================
  // STATE COLLECTIONS
  // ====================================================
  const [selectedPackage, setSelectedPackage] = useState({});
  const [packageId, setPackageId] = useState("");
  const [accountTypeId, setAccountTypeId] = useState("");
  const [price, setPrice] = useState("");
  const [deploymentPrice, setDeploymentPrice] = useState("");
  const [pmoPrice, setPmoPrice] = useState("");
  const [userPrice, setUserPrice] = useState("");
  const [helperTxtPmo, setHelperTxtPmo] = useState("");
  const [checkoutFee, setCheckoutFee] = useState({});
  const [voucherErrorsList, setVoucherErrorsList] = useState([]);
  const [triggerPricing, setTriggerPricing] = useState(null);

  const [loadPrice, setLoadPrice] = useState(false);
  const [openDialogCheckoutReg, setOpenDialogCheckoutReg] = useState(false);
  const [error400, setError400] = useState(false);
  const [extendPackage, setExtendPackage] = useState(false);

  // State Calculation Form
  const [userLicenseCount, setUserLicenseCount] = useState(0);
  const [licenseDurationCount, setUserDurationCount] = useState(1);
  const [deploymentTicketCount, setDeploymentTicketCount] = useState(0);
  const [pmoVisitCount, setPmoVisitCount] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");

  // ====================================================
  // Side Effect
  // ====================================================
  // initialize state
  useEffect(() => {
    if (openDialogBussinessPackage) {
      const filterBusinessA = listBusinessPackage.filter(
        (item) => item.id === PACKAGE_BISNIS_A
      );
      setSelectedPackage(filterBusinessA[0]);

      // // console.log("listBusiness", listBusinessPackage);
      // console.log("Business A", filterBusinessA);

      if (filterBusinessA[0] !== undefined) {
        setPackageId(filterBusinessA[0].id);
        setAccountTypeId(filterBusinessA[0].accountType.id);
        // setUserDurationCount(filterBusinessA[0].trial_duration_value);

        // set counting
        filterBusinessA[0].membershipPackageItem.forEach((item) => {
          if (item.membership_item_id === DEPLOYMENT) {
            setDeploymentTicketCount(item.membership_item_minimum_quantity);
          } else if (item.membership_item_id === USER_LICENSE) {
            setUserLicenseCount(item.membership_item_minimum_quantity);
          } else if (item.membership_item_id === PMO_VISIT) {
            setPmoVisitCount(item.membership_item_minimum_quantity);
          }
        });
      }
    }
  }, [openDialogBussinessPackage]);

  // // console.log("Pkt Id", packageId);

  // initialize and update form calculation
  useEffect(() => {
    if (openDialogBussinessPackage && accountTypeId !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      };

      Axios.defaults.headers.common = header;

      const root_url = `account-management/membership-payment:calculate`;
      const act_type = `MembershipPayment[membership_account_type_id]=${accountTypeId}`;
      const pckg_id = `MembershipPayment[membership_package_id]=${packageId}`;
      const dur_val = `MembershipPayment[membership_duration_value]=${licenseDurationCount}`;
      const deploy_id = `MembershipPayment[membership_item][0][id]=${DEPLOYMENT}`;
      const deploy_count = `MembershipPayment[membership_item][0][quantity]=${deploymentTicketCount}`;
      const user_license_id = `MembershipPayment[membership_item][1][id]=${USER_LICENSE}`;
      const user_license_count = `MembershipPayment[membership_item][1][quantity]=${userLicenseCount}`;
      const pmo_id = `MembershipPayment[membership_item][2][id]=${PMO_VISIT}`;
      const pmo_count = `MembershipPayment[membership_item][2][quantity]=${pmoVisitCount}`;
      const voucher = `MembershipPayment[membership_voucher_code]=${voucherCode}`;

      const C_URL = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${deploy_id}&${deploy_count}&${user_license_id}&${user_license_count}&${pmo_id}&${pmo_count}&${voucher}`;

      // const voucher_id = `Membership[membership_voucher_id]`

      setLoadPrice(true);

      Axios.get(C_URL)
        .then((response) => {
          // console.log("Calculation Res", response);
          const result = response.data.data;

          if (response.status === 200) {
            setPrice(result.total_item_price);
            setCheckoutFee(result);

            result.pricePerItem.forEach((item) => {
              if (item.id === DEPLOYMENT) {
                setDeploymentPrice(item.total_price_after_duration);
              } else if (item.id === PMO_VISIT) {
                setPmoPrice(item.total_price_after_duration);
              } else if (item.id === USER_LICENSE) {
                setUserPrice(item.total_price_after_duration);
              }
            });
          }
          setLoadPrice(false);
          setError400(false);
        })
        .catch((error) => {
          setLoadPrice(false);
          // console.log("Error", error);

          if (error.response.status === 400) {
            // // console.log("ErrorX", error.response.data.info.errors);
            // // console.log("AHIIIIWWWW");

            setError400(true);
            setVoucherErrorsList(error.response.data.info.errors);
          }
        });
    }
  }, [
    openDialogBussinessPackage,
    // selectedPackage,
    userLicenseCount,
    licenseDurationCount,
    deploymentTicketCount,
    pmoVisitCount,
    voucherCode,
    triggerPricing,
  ]);

  // ====================================================
  // Function / Methods
  // ====================================================
  const handleChangePackage = (data) => {
    // console.log("Data Package", data);

    setPackageId(data.id);
    setAccountTypeId(data.accountType.id);
    setSelectedPackage(data);

    // set counting
    data.membershipPackageItem.forEach((item) => {
      if (item.membership_item_id === DEPLOYMENT) {
        setDeploymentTicketCount(item.membership_item_minimum_quantity);
      } else if (item.membership_item_id === USER_LICENSE) {
        setUserLicenseCount(item.membership_item_minimum_quantity);
      } else if (item.membership_item_id === PMO_VISIT) {
        setPmoVisitCount(item.membership_item_minimum_quantity);
      }
    });

    // Set HelperText for PMO Visit Form
    switch (data.id) {
      case PACKAGE_BISNIS_A:
        setHelperTxtPmo("minimal 4, maksimal 10");
        break;
      case PACKAGE_BISNIS_B:
        setHelperTxtPmo("minimal 2, maksimal 3");
        break;
      case PACKAGE_BISNIS_C:
        setHelperTxtPmo("hanya bisa 1");
        break;
      default:
        setHelperTxtPmo("");
    }
  };

  // Handle For User License Input
  const handleUserLicense = (e) => {
    const value = parseInt(e.target.value);
    // setUserLicenseCount(e.target.value);

    if (value >= 25 && value <= 500) {
      setUserLicenseCount(e.target.value);
    } else if (value > 500) {
      setUserLicenseCount(500);
    } else if (value < 25) {
      setUserLicenseCount(25);
    }
  };

  // Handle For License Duration Input
  const handleLicenseDuration = (e) => {
    const value = parseInt(e.target.value);
    // setUserDurationCount(e.target.value);

    if (value < 1) {
      setUserDurationCount(1);
    } else if (isNaN(value)) {
      setUserDurationCount(1);
    } else {
      setUserDurationCount(e.target.value);
    }
  };

  // Handle For Deployment Ticket Input
  const handleDeplymentTicket = (e) => {
    const value = parseInt(e.target.value);
    // setDeploymentTicketCount(e.target.value);

    if (value >= 25 && value <= 500) {
      setDeploymentTicketCount(e.target.value);
    } else if (value > 500) {
      setDeploymentTicketCount(500);
    } else if (value < 25) {
      setDeploymentTicketCount(25);
    }
  };

  // Handle For PMO Visit Ticket Input
  const handlePmoVisitTicket = (e) => {
    const value = parseInt(e.target.value);
    setPmoVisitCount(e.target.value);

    switch (packageId) {
      case PACKAGE_BISNIS_A:
        if (value >= 4 && value <= 10) {
          setPmoVisitCount(e.target.value);
        } else if (value > 10) {
          setPmoVisitCount(10);
        } else if (value < 4) {
          setPmoVisitCount(4);
        } else if (isNaN(value)) {
          setPmoVisitCount(4);
        }
        break;
      case PACKAGE_BISNIS_B:
        if (value >= 2 && value <= 3) {
          setPmoVisitCount(e.target.value);
        } else if (value > 3) {
          setPmoVisitCount(3);
        } else if (value < 2) {
          setPmoVisitCount(2);
        }
        break;
      case PACKAGE_BISNIS_C:
        if (value === 1) {
          setPmoVisitCount(e.target.value);
        } else if (value > 1 && value < 1) {
          setPmoVisitCount(1);
        }
        break;
      default:
        setPmoVisitCount(e.target.value);
    }
  };

  // ====================================================
  // Handle Subscribe Business Package
  // ====================================================
  const handleSubscribeBusiness = () => {
    setOpenDialogCheckoutReg(true);
  };

  // HANDLE VOUCHER ==================
  const handleVoucherCode = (code) => {
    setVoucherCode(code);
  };

  // ====================================================
  // Render CARD/BOX Business Package
  // ====================================================
  // // console.log("Bisnes hargah :", listBusinessPrice);
  // // console.log("Bisnes pakets :", listBusinessPackage);

  const renderPackages = listBusinessPackage.slice(0, 1).map((item, i) => {
    const isCurrent = selectedPackage.name === item.name;
    const samePackageId = packageId === item.id;

    let pmoVisit;

    let minDeploy;
    let minUserLicense;
    let minPmo;
    let initTotalPrice;

    // Get min quantity fro each benefit
    item.membershipPackageItem.forEach((data) => {
      if (data.membership_item_id === DEPLOYMENT) {
        minDeploy = data.membership_item_minimum_quantity;
      } else if (data.membership_item_id === USER_LICENSE) {
        minUserLicense = data.membership_item_minimum_quantity;
      } else if (data.membership_item_id === PMO_VISIT) {
        minPmo = data.membership_item_minimum_quantity;
      }
    });

    // Multiply All Benefit with its price
    listBusinessPrice.forEach((data) => {
      if (data.membership_item_id === USER_LICENSE) {
        const usr_licnse = parseInt(data.price);
        minUserLicense *= usr_licnse;
      } else if (data.membership_item_id === DEPLOYMENT) {
        const dplymnet = parseInt(data.price);
        minDeploy *= dplymnet;
      } else if (data.membership_item_id === PMO_VISIT) {
        const pmo_vst = parseInt(data.price);
        minPmo *= pmo_vst;
      }
    });

    // set detail text for each package
    if (item.id === PACKAGE_BISNIS_A) {
      pmoVisit = "4 Consultant Visit";
      // initTotalPrice = "3545000";
    } else if (item.id === PACKAGE_BISNIS_B) {
      pmoVisit = "2 - 3 Consultant Visit";
      initTotalPrice = minDeploy + minUserLicense + minPmo;
    } else if (item.id === PACKAGE_BISNIS_C) {
      pmoVisit = "1 Consultant Visit";
      initTotalPrice = minDeploy + minUserLicense + minPmo;
    }

    return (
      <label
        key={item.id}
        className={isCurrent ? classes.cardPackage : classes.cardPlain}
      >
        <Box>
          <Typography
            variant="subtitle1"
            className={isCurrent ? classes.txtTitle : classes.txtTitlePlain}
          >
            {item.name}
          </Typography>

          <Box marginTop={2} className={classes.listBenefits}>
            <Box display="flex" alignItems="center" marginBottom={1}>
              <CheckIcon
                style={{ color: "#fff", marginRight: 7 }}
                fontSize="small"
              />
              <Typography
                variant="subtitle2"
                className={
                  isCurrent ? classes.benefitText : classes.benefitTextPlain
                }
              >
                Lebih dari 3 Consultant Visit
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <CheckIcon
                style={{ color: "#fff", marginRight: 7 }}
                fontSize="small"
              />
              <Typography
                variant="subtitle2"
                className={
                  isCurrent ? classes.benefitText : classes.benefitTextPlain
                }
              >
                Gratis 1 Bulan
              </Typography>
            </Box>
          </Box>
          {samePackageId && loadPrice ? (
            <Typography className={classes.txtPrice}>
              <CircularProgress style={{ color: "#d1354a" }} size={16} />
            </Typography>
          ) : (
            <Typography variant="h3" className={classes.txtPrice}>
              Rp {FormatThousand(price)}
            </Typography>
          )}
          <Radio
            onChange={() => handleChangePackage(item)}
            checked={selectedPackage.name === item.name}
            style={{
              visibility: "hidden",
              height: 0,
              width: 0,
              opacity: 0,
            }}
          />
        </Box>
      </label>
    );
  });

  return (
    <Dialog
      open={openDialogBussinessPackage}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl" //*Default "md"
      style={{ height: "auto", width: "100%" }}
    >
      <DialogTitle id="customized-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" className={classes.title}>
            <b>Berlangganan Paket Bisnis</b>
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpenDialogBussinessPackage(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 1000 }}>
        <Box marginTop={2} display="flex" justifyContent="flex-start">
          {renderPackages}
        </Box>

        {/* ========= Extend Package (START) ========= */}

        <Box
          marginTop={2}
          marginBottom={3}
          padding={1.2}
          className={classes.extendPckgBg}
        >
          {!extendPackage ? (
            <HideExtendPackage
              classes={classes}
              onExtendPackage={setExtendPackage}
              userLicenseCount={userLicenseCount}
              licenseDurationCount={licenseDurationCount}
              pmoVisitCount={pmoVisitCount}
            />
          ) : (
            <ShowExtendPackage
              classes={classes}
              userLicenseCount={userLicenseCount}
              licenseDurationCount={licenseDurationCount}
              pmoVisitCount={pmoVisitCount}
              onExtendPackage={setExtendPackage}
              onLicenseDuration={handleLicenseDuration}
              onUserLicense={handleUserLicense}
              onPmoVisitTicket={handlePmoVisitTicket}
              pmoPrice={pmoPrice}
              userPrice={userPrice}
            />
          )}
        </Box>

        {/* ========= Extend Package (END) ========= */}

        <>
          <Typography variant="h6" className={classes.title}>
            <b>Tiket Deployment</b>
          </Typography>
          <Box display="flex" justifyContent="space-between" marginTop={1}>
            <Box display="flex" alignItems="center">
              <ConfirmationNumberIcon
                style={{ color: "#262261", fontSize: 30, marginRight: 7 }}
              />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "#d1354a", marginRight: 5 }}
              >
                <b>Rp 80.000/Tiket</b>
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                ( min 25 )
              </Typography>
            </Box>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={deploymentTicketCount}
              onChange={(e) => handleDeplymentTicket(e)}
              style={{ width: 250 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tiket</InputAdornment>
                ),
              }}
            />
            <Typography variant="h6" className={classes.title}>
              <b>Rp {FormatThousand(deploymentPrice)}</b>
            </Typography>
          </Box>
        </>
      </DialogContent>
      <DialogActions
        style={{
          marginTop: 20,
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#e9e9e9",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "#333" }}
          >
            Total Harga
          </Typography>
          <Typography
            variant="h4"
            className={classes.title}
            style={{ color: "#262261" }}
          >
            <b>Rp {FormatThousand(price)}</b>
          </Typography>
        </Box>
        <Button
          className={classes.button}
          variant="contained"
          disabled={selectedPackage.id === undefined}
          onClick={handleSubscribeBusiness}
        >
          Lanjut
        </Button>
      </DialogActions>

      <DialogCheckoutReg
        classes={classes}
        openDialogCheckoutReg={openDialogCheckoutReg}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutReg}
        setOpenDialogPackage={setOpenDialogBussinessPackage}
        userToken={userToken}
        checkoutFee={checkoutFee}
        duration={licenseDurationCount}
        membership="Bisnis A"
        paket="Bisnis"
        listPaymentMethods={listPaymentMethods}
        onVoucher={handleVoucherCode}
        setTriggerPricing={setTriggerPricing}
        voucherErrorsList={voucherErrorsList}
        error400={error400}
      />
    </Dialog>
  );
};

export default DialogBussinessPackage;
