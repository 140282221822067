export const styles = theme => ({
	root: {
		margin: '0 auto',
		marginTop: 100,
		width: '100%',
		fontFamily: 'Nunito',
		maxWidth: 768,
	},
	divPicture: {
		borderRadius: '50%',
		width: 80,
		height: 80,
		overflow: 'hidden',
		display: 'flex',
		position: 'relative',
		backgroundColor: 'white',
	},
	picture: {
		height: '100%',
		position: 'absolute',
		left: '50%',
		transform: 'translate(-50%, 0%)',
	},
});
