import React, { useState } from "react";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Radio,
  createMuiTheme,
} from "@material-ui/core";

import StickyFooter from "react-sticky-footer";

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeKepegawaianInfoPosisi } from "../../../constants/config-redirect-url";
// import Snackber from '../Components/Snackber';
import { styles } from "./Style/StyleInfoPosisi"; // NEXT GANTI TARO DI COMPONENT INFO PEGAWAI SENDIRI !

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewInfoPegawai = (props) => {
  const { classes } = props;

  /*
        `````````````
        RADIO BUTTON

        `````````````

    */

  const [selectedValue, setSelectedValue] = useState("aktif"); // 1 = Aktif, 2 = Non Aktif

  function handleChangeRadioButton(event) {
    setSelectedValue(event.target.value);

    if (event.target.value == "aktif") {
      console.log(event.target.value);

      // callListMasterRole(event.target.value)
    } else {
      console.log(event.target.value);
    }
  }

  /*
        ````````
        GOLONGAN

        ````````
    */
  const [golongan, setGolongan] = useState({
    name: "",
  });

  const handleChangeGolongan = (name) => (event) => {
    setGolongan({ ...golongan, [name]: event.target.value });
  };

  /*
        `````````````
        STATUS KERJA

        ````````````
    */

  const [statusKerja, setStatusKerja] = useState({
    name: "",
  });

  const handleChangeStatusKerja = (name) => (event) => {
    setStatusKerja({ ...statusKerja, [name]: event.target.value });
  };

  /*
        ``````````````````
        NOTES PENGHARGAAN

        ``````````````````
    */

  const [penghargaan, setPenghargaan] = useState("");
  const handleChangePenghargaan = (e) => setPenghargaan(e.target.value);

  /*
        `````````````````` ````
        - BPJS KESEHATAN

        - BPJS KETENGAKERJAAN
        
        - NOMOR JAMINAN HARI TUA

        ``````````````````````
    */

  const [bpjs, setBpjs] = useState("");
  const handleChangeBpjsKesehatan = (e) => setBpjs(e.target.value);

  const [bpjsKetenagakerjaan, setBpjsKetenagakerjaan] = useState("");
  const handleChangeBpjsKetenagakerjaan = (e) =>
    setBpjsKetenagakerjaan(e.target.value);

  const [noJaminanHariTua, setNoJaminanHariTua] = useState("");
  const handleChangeNoJaminanTua = (e) => setNoJaminanHariTua(e.target.value);

  /*
        ```````````````````
        HANDLE POST LANJUT 

        ```````````````````
    */

  let paramsData = {
    status_karyawan: selectedValue,
    golongan: golongan.name,
    status_kerja: statusKerja.name,
    penghargaan: penghargaan,
    bpjs_kesehatan: bpjs,
    bpjs_ketenagakerjaan: bpjsKetenagakerjaan,
    no_jaminan_hari_tua: noJaminanHariTua,
  };

  const handlePOSTLanjut = () => {
    // console.log("Params Data INFORMASI PEGAWAI : ", paramsData);

    localStorage.setItem(
      "employee_data_info_pegawai",
      JSON.stringify(paramsData)
    );

    Redirect(ToHrEmployeeKepegawaianInfoPosisi);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}
          >
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Kepegawaian</b>
            </Typography>
            <ul>
              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Informasi Pegawai</b>
                </Typography>
              </li>

              {/* <li className={classes.titleNotActive} style={{margin: 8}}>
                            <Typography variant='subtitle2' className={classes.titleNotActive}>
                                <b>Informasi Posisi</b>
                            </Typography>
                        </li> */}
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: "left " }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}
          >
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Informasi Pegawai</b>
            </Typography>

            <br />

            <List className={classes.list}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Status Karyawan
                        <span style={{ color: "#cc0707" }}>*</span>
                      </b>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Radio
                        checked={selectedValue === "aktif"}
                        onChange={handleChangeRadioButton}
                        value="aktif"
                        name="Aktif"
                        // disabled= {isLockedStatusState == 1 ? true : false}
                      />
                      <span style={{ fontFamily: "Roboto" }}>Aktif</span>
                      <Radio
                        checked={selectedValue === "notAktif"}
                        onChange={handleChangeRadioButton}
                        value="notAktif"
                        name="Tidak Aktif"
                      />
                      <span style={{ fontFamily: "Roboto" }}>Tidak Aktif</span>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Golongan
                        {/* <span style={{color: '#cc0707'}}>*</span> */}
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-select-provinsi"
                  select
                  // label="Pilih Negara : "
                  className={classes.textField}
                  value={golongan.name}
                  onChange={handleChangeGolongan("name")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  // error={infoError == true && provinsi.name == '' ? true : false}
                  // helperText={infoError == true && provinsi.name == '' ? "Wajib di isi" : ' '}
                >
                  {golongans.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Status Kerja
                        <span style={{ color: "#cc0707" }}>*</span>
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-select-provinsi"
                  select
                  // label="Pilih Negara : "
                  className={classes.textField}
                  value={statusKerja.name}
                  onChange={handleChangeStatusKerja("name")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                  // error={infoError == true && provinsi.name == '' ? true : false}
                  // helperText={infoError == true && provinsi.name == '' ? "Wajib di isi" : ' '}
                >
                  {statusKerjas.map((option) => (
                    <MenuItem key={option.value} value={option}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Penghargaan</b>
                    </Typography>
                  }
                />
                <TextField
                  onChange={handleChangePenghargaan}
                  value={penghargaan}
                  style={{ marginTop: 0 }}
                  id="outlined-multiline-static"
                  // label="Multiline"
                  multiline
                  rows="4"
                  // defaultValue="Default Value"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  // error={infoError == true && alamatInKtp == '' ? true : false}
                  // helperText={infoError == true && alamatInKtp == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Nomor <i>BPJS</i> Kesehatan
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeBpjsKesehatan}
                  value={bpjs}
                  // placeholder={'Alamat Email'}
                  variant="outlined"
                  // error={infoError == true && email == '' ? true : false}
                  // helperText={infoError == true && email == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>
                        Nomor <i>BPJS</i> Ketenagakerjaan
                      </b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeBpjsKetenagakerjaan}
                  value={bpjsKetenagakerjaan}
                  // placeholder={'Alamat Email'}
                  variant="outlined"
                  // error={infoError == true && email == '' ? true : false}
                  // helperText={infoError == true && email == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      className={classes.titleForm}
                    >
                      <b>Nomor Jaminan Hari Tua</b>
                    </Typography>
                  }
                />
                <TextField
                  id="outlined-bare"
                  className={classes.textField}
                  onChange={handleChangeNoJaminanTua}
                  value={noJaminanHariTua}
                  // placeholder={'Alamat Email'}
                  variant="outlined"
                  // error={infoError == true && email == '' ? true : false}
                  // helperText={infoError == true && email == '' ? "Wajib di isi" : ' '}
                />
              </ListItem>
            </List>
          </Paper>

          <br />
          <br />
          <br />
          <br />
        </Grid>
      </Grid>

      {/* 

            ````````````````````
            STICKY FOOTER

            ````````````````````
        */}

      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <StickyFooter bottomThreshold={10}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.buttonOutlined}
              style={{ margin: 14 }}
              // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
            >
              Keluar
            </Button>
          </StickyFooter>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <StickyFooter bottomThreshold={10}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              style={{ margin: 14 }}
              disabled={false}
              // onClick={handleSubmit(handlePOSTLanjut)}
              onClick={handlePOSTLanjut}
            >
              Lanjut
            </Button>
          </StickyFooter>
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewInfoPegawai);

const golongans = [
  {
    value: "Esseloen 1",
    label: "Esseloen 1",
  },
  {
    value: "Esseloen 2",
    label: "Esseloen 2",
  },
  {
    value: "Esseloen 3",
    label: "Esseloen 3",
  },
];

const statusKerjas = [
  {
    id: 1,
    value: "permanen",
    label: "Permanen",
  },
  {
    id: 2,
    value: "kontrak",
    label: "Masih Terikat Kontrak",
  },
  {
    id: 3,
    value: "freelance",
    label: "Freelance",
  },
  {
    id: 4,
    value: "magang",
    label: "Magang",
  },
];
