import React from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import styles from "./Style/SignUpStyle";

import PictBackgroundImage from "../../../assets/images/bgRegGreen.png";
import DialogRegister from "./ComponentRegister/DialogRegister";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

// const useStyles = makeStyles(theme => ({

const ViewRegister = (props) => {
  const { classes } = props;

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        style={{
          backgroundImage: "url(" + PictBackgroundImage + ")",
          backgroundSize: "cover",
          height: "92vh",
        }}>
        <DialogRegister classes={classes} />

        <br />
        <br />
      </Box>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewRegister);
