import React from "react";
import { fade } from "@material-ui/core/styles";

export const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  layoutMarginLeftAndTop: {
    // marginLeft: 72,
    // marginTop: 56,
    width: "100%",
    marginTop: 56,
    paddingLeft: 72,
    maxWidth: 2192,
    marginLeft: "auto",
    marginRight: "auto",
  },
  fixedLayout: {
    // width: "100%",
    // maxWidth: 2192,
    marginLeft: "auto",
    marginRight: "auto",
  },
  layoutMarginTop: {
    // marginLeft: 72,
    marginTop: 56,
  },
  layoutMarginTopNew: {
    marginTop: theme.spacing(10),
  },
  layoutMarginLeftList: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  layoutMarginLeftListChooseTab: {
    // marginLeft: theme.spacing(5),
    marginTop: theme.spacing(4),
  },
  title: {
    fontFamily: "Roboto",
  },
  titleInLineChart: {
    fontFamily: "Roboto",
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  button0: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  button1: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  buttonOutlined: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },
  buttonDisabled: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "grey",
    fontWeight: "bold",
  },
  uploadBtn: {
    fontFamily: "'Lato', sans-serif",
    color: "#00ACE6",
    backgroundColor: "rgba(0, 172, 230, 0.1)",
    borderRadius: 5,
    padding: "10px, 3px",
    border: "none",
    fontSize: 13,
    fontWeight: "500",
    textTransform: "capitalize",
    "&:hover": {
      opacity: 0.9,
    },
  },
  btnGrey: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    color: "#333",
    textTransform: "capitalize",
    padding: "2px 12px",
    backgroundColor: "#DFDFDF",
    "&:hover": {
      backgroundColor: "#B1B1B1",
    },
  },
  btnWhite: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #B1B1B1",
    color: "#5B5B5B",
    textTransform: "capitalize",
    // padding: "2px 12px",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  btnFilter: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 10,
    border: "1px solid #8F8F8F",
    padding: "2px 8px",
    textTransform: "capitalize",
    color: "#8F8F8F",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
  timeFrameIcontDateRangeText: {
    fontFamily: "Roboto",
    color: "#4aa9fb",
    textTransform: "capitalize",
    backgroundColor: "#edf6ff",
  },

  /*
        ```````````
        DIALOG EDIT

        ```````````
    */
  titleListChoose: {
    fontFamily: "Roboto",
    cursor: "pointer",
  },
  titleListChooseAktif: {
    fontFamily: "Roboto",
    color: "#d6d3d3",
    cursor: "pointer",
  },

  bottomBorderActive: {
    borderBottom: "0.2em solid #cc0707",
    borderRadius: 2,
  },

  timeFrameIcon: {
    fontFamily: "Roboto",
    color: "grey", //#4aa9fb
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
    // backgroundColor: '#cfe8fd'
  },

  timeFrameIconInModal: {
    fontFamily: "Roboto",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      color: "#edcfd8", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },

  /*
   ******************************
   */
  listCardItem: {
    paddingBottom: 0,
    paddingTop: 7,
  },

  paperColumPertamaRowFirst: {
    marginLeft: theme.spacing(11),
    padding: theme.spacing(0.2),
  },
  paperColumPertamaRowFirstKebakaran: {
    marginLeft: theme.spacing(11),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderLeft: "0.3em solid red",
  },
  paperColumnDuaRowFirst: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
  },

  paperNewColorOfGoal: {
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0.2),
    // background: 'linear-gradient(1deg, #0D50C2, #A4FCFF)',
    background: "linear-gradient(1deg, #0D50C2,  #25c6cc)",

    // background: 'linear-gradient(1deg, #262261, #6B688B)',
    // borderLeft: '0.5em solid red'
  },

  /*
        `````````````````````````````
        FOTO REAL USER NAME, USERNAME

        `````````````````````````````
    */
  userRealFoto: {
    // margin: 10,
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  imageAvatar: {
    width: 50,
    height: 50,
  },

  /*
        ````````
        CIRCULAR

        ````````
    */
  circularProgressBarInMoreDetail: {
    // width: 60,
    // height: 60,
    width: 100,
    height: 100,
  },
  circularProgressBarInPaperDetail: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  buttonModalDelete: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
  },

  /*
        ``````````
        MODAL EDIT 

        ``````````
    */
  textField: {
    minWidth: 425,
  },

  /*
        ```````
        SEARCH

        ```````
    */
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.35),
    },
    // borderRadius: '5',
    // backgroundColor: grey,
    //     '&:hover': {
    //         backgroundColor: green,
    //     },
    // marginRight: theme.spacing(2),
    // marginLeft: 0,
    marginTop: theme.spacing(4),
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    color: "grey",
    // color: '#cc0707'
  },

  /*
        ````````````````````````````
        PAPER, CIRCULAR PROGRESS BAR

        ````````````````````````````        
    */
  paperList: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
  },
  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderBottom: "0.5em solid red",
    // borderLeft: '0.1em solid #c3c0c0de',
  },

  chipFotoMA: {
    backgroundColor: "#edcfd8",
    fontFamily: "Roboto",
    color: "grey",
  },
  connectMAToGoal: {
    fontFamily: "Roboto",
    color: "#d85466",
    textTransform: "capitalize",
    "&:hover": {
      color: "grey", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },

  /* 
        ``````````````````````
        GRID VIEW - PICT BUKTI
        
        ``````````````````````
    */
  rootGridView: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  text: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },

  //============================ MARE ============================
  titleMARList: {
    fontSize: 11,
    fontFamily: "Roboto",
    color: "#bbb",
  },

  expandMARBtn: {
    backgroundColor: "transparent",
    color: "#888",
    fontFamily: "Roboto",
    border: 0,
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonFab: {
    // width: '503px',
    // height: '42px',
    background: "#d1354a",
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },

  // ========================================
  // MARE
  // ========================================
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconRadio: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    padding: 0,
  },
  checkedIconRadio: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1354a",
    },
  },

  // ========================================
  // Styled Checkbox
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a",
    },
  },

  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: "center",
    padding: 20,
    border: `3px dashed #eeeeee`,
    backgroundColor: "#fafafa",
    color: "#bdbdbd",

    marginBottom: 20,
    marginTop: 10,

    "&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },

  textBreadcrum: {
    "&:hover": { cursor: "pointer", color: "#333" },
    fontFamily: "Roboto",
    fontSize: 11,
  },

  //  new card
  shadowSection: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  shadowSectionLight: {
    borderRadius: 8.5,
    boxShadow: "0px 10px 10px -4px rgba(0,0,0,0.05)",
  },

  toDetails: {
    fontFamily: "'Lato', sans-serif",
    color: "#333",
    fontWeight: "normal",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      textDecoration: "underline",
    },
  },
});
