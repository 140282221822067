import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { navigate } from "hookrouter";

import logoMadinahCare from "../../../assets/images/landing/logo-madinah-care.PNG";
import logoMadinahFlash from "../../../assets/images/landing/logo-madinah-flash.PNG";
import logoMadinahGroup from "../../../assets/images/landing/logo-madinah-group.PNG";
import logoMadinahMultimedia from "../../../assets/images/landing/logo-madinah-multimedia.PNG";
import logoMadinahStore from "../../../assets/images/landing/logo-madinah-store.PNG";

import img1 from "../../../assets/images/landing/ma1.PNG";
import img2 from "../../../assets/images/landing/ma2.PNG";
import img3 from "../../../assets/images/landing/ma3.PNG";
import img4 from "../../../assets/images/landing/ma4.PNG";

import "../Style/landing.css";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  imageLogo: {
    verticalAlign: "text-bottom",
    width: 35,
    height: 35,
  },
  alternateBackground: {
    backgroundColor: "#f5f5f5",
  },
  centerInDiv: {
    display: "table",
  },
  centerInDivCell: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  bottomBackground: {
    backgroundColor: "#d1364a",
    color: "#ffffff",
  },
  ClientAndPartner: {
    width: "80%",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    padding: 0,
    margin: "0 auto",
  },
  paddingSection: {
    paddingTop: "60px",
    paddingBottom: "60px",
  },
  gridDescription: {
    display: "grid",
    padding: 0,
    margin: "0 auto",
    gridTemplateColumns: "50% 50%",
    width: "80%",
  },
});

const useStyles = makeStyles(styles);

const MeasuredActivity = (props) => {
  //const { classes } = props;
  const classes = useStyles();

  return (
    <div>
      <section style={{ paddingTop: "0px", textAlign: "center" }}>
        <div className="box-price">
          <div className="box-price-left-side" style={{ width: "70%" }}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={img1} width="30px" /> <span>Measure activites</span>
            </div>
            <h2>
              Ukur pencapaian goal anda dengan menentukan strategi yang tepat{" "}
            </h2>
            <p>
              Pencapaian sebuah goal biasanya tergantung dari keberhasilan
              pemilihan strategi eksekusi dan juga kedisiplinan dari karyawan
              untuk menjalankan strategi tersebut .
            </p>
            <Button
              onClick={() => navigate("/register")}
              variant="contained"
              color="primary"
              disableElevation
              className={classes.textLowerCase}
              style={{ width: "150px", margin: "0 auto" }}>
              Coba Gratis
            </Button>
          </div>

          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div>
              <img src={img2} style={{ width: "90%" }} />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <h3 className="text-client-title">
            Your mate in performance monitoring
          </h3>
          <p>
            Dipercaya banyak perusahaan, UKM, dan organisasi untuk mencapai
            goal-goal mereka
          </p>
          <div className={classes.ClientAndPartner}>
            <div>
              <img src={logoMadinahGroup} width="50%" />
            </div>

            <div>
              <img src={logoMadinahStore} width="50%" />
            </div>

            <div>
              <img src={logoMadinahFlash} width="100%" />
            </div>

            <div>
              <img src={logoMadinahCare} width="70%" />
            </div>

            <div>
              <img src={logoMadinahMultimedia} width="80%" />
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>

      <section
        className={[classes.alternateBackground, classes.paddingSection].join(
          " "
        )}>
        <div className={classes.gridDescription}>
          <div>
            <h3>Temukan strategi dan hasil terbaik untuk diukur.</h3>
            <p>
              Setiap 'Measured Activity' terkait dengan 'Goals' sehingga dapat
              dianalisa aktivitas yang berpengaruh terhadap keberhasilan atau
              kegagalan sebuah goal.
            </p>
          </div>
          <div className={classes.centerInDiv}>
            <div className={classes.centerInDivCell}>
              <img src={img3} style={{ width: "80%" }} />
            </div>
          </div>
        </div>
      </section>

      <section className={classes.paddingSection}>
        <div className={classes.gridDescription}>
          <div className={classes.centerInDiv}>
            <div className={classes.centerInDivCell}>
              <img src={img4} style={{ width: "80%" }} />
            </div>
          </div>
          <div>
            <h3>Monitor pencapaian strategi tim anda.</h3>
            <p>
              Seperti sebuah goal, seorang leader dapat memonitor dan
              menganalisis 'Measured Activity' dari anggota tim yang mengerjakan
              strategi yang sama.
            </p>
          </div>
        </div>
      </section>

      <section className={classes.paddingSection}>
        <div
          className="box-tour"
          style={{ textAlign: "center", margin: "0 auto " }}>
          <h3>Ikuti Tur</h3>
          <p>
            Ingin mempelajari lebih banyak? Atur jadwal demo bersama kami untuk
            memandu dan menjawab pertanyaan anda dengan menggunakan platform
            secara langsung
          </p>
          <Button
            onClick={() => navigate("/demo-schedule")}
            variant="outlined"
            color="primary"
            disableElevation
            className={classes.textLowerCase}>
            Atur demo
          </Button>
        </div>
      </section>
    </div>
  );
};

export default MeasuredActivity;
