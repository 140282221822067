import React from "react";
import "date-fns";

import {
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Input,
  Grid,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  Typography,
  Paper,
} from "@material-ui/core";
import { Autorenew, BusinessCenter, Room } from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { id } from "date-fns/locale";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CE354A", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const RequestExceptionDialog = ({
  handleClose,
  open,
  verification,
  value,
  staticValue,
  setValue,
  shiftList,
  scheduleList,
}) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          REQUEST EXCEPTION
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
            }}
          >
            <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date"
                label="Jadwal"
                format="d, MMMM, yyyy"
                minDate={new Date()}
                value={value.date_before}
                onChange={(v) => {
                  setValue({ ...value, date_before: v });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <InputLabel htmlFor="shift-type">Jenis Shift</InputLabel>
            <Select
              id="shift-type"
              value={value.shift_id}
              onChange={(e) =>
                setValue({
                  ...value,
                  shift_id: e.target.value,
                })
              }
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <Autorenew />
                </InputAdornment>
              }
              disabled={true}
            >
              <MenuItem value="">PILIH JENIS</MenuItem>
              {shiftList.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <InputLabel htmlFor="schedule-type">Jenis Schedule</InputLabel>
            <Select
              id="schedule-type"
              value={value.schedule_id}
              onChange={(e) =>
                setValue({
                  ...value,
                  schedule_id: e.target.value,
                })
              }
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <Autorenew />
                </InputAdornment>
              }
              disabled={true}
            >
              <MenuItem value="">PILIH JENIS</MenuItem>
              {scheduleList.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Paper
            style={{
              display: value.date_before === null ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "#ffffff",
              borderColor: "#262261",
              borderRadius: 11,
              padding: "10px 20px",
              marginTop: "20px",
            }}
            variant="outlined"
          >
            <div
              style={{
                color: "#262261",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BusinessCenter style={{ marginRight: "10px" }} />
              <Typography style={{ fontWeight: "bold" }}>
                CLOCK IN &amp; OUT
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="input-clockin">
                    Clock In
                  </InputLabel>
                  <Input
                    id="input-clockin"
                    type="time"
                    value={staticValue.clock_in}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="input-clockout">
                    Clock Out
                  </InputLabel>
                  <Input
                    id="input-clockout"
                    type="time"
                    value={staticValue.clock_out}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="input-location">
                    Lokasi
                  </InputLabel>
                  <Select value={staticValue.clock_location} disabled={true}>
                    <MenuItem value="">PILIH LOKASI</MenuItem>
                    {value.master_location.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.location_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{
              display: value.date_before === null ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "#ffffff",
              borderColor: "#262261",
              borderRadius: 11,
              padding: "10px 20px",
              marginTop: "20px",
            }}
            variant="outlined"
          >
            <div
              style={{
                color: "#262261",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BusinessCenter style={{ marginRight: "10px" }} />
              <Typography style={{ fontWeight: "bold" }}>
                CHECK IN &amp; OUT
              </Typography>
            </div>
            {staticValue.detail_schedule.map((item, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      htmlFor={`input-checkin-${index + 1}`}
                    >
                      Check In {index + 1}
                    </InputLabel>
                    <Input
                      id={`input-checkin-${index + 1}`}
                      type="time"
                      value={item.check_in}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      htmlFor={`input-checkout-${index + 1}`}
                    >
                      Check Out {index + 1}
                    </InputLabel>
                    <Input
                      id={`input-checkout-${index + 1}`}
                      type="time"
                      value={item.check_out}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      htmlFor={`input-location-${index + 1}`}
                    >
                      Lokasi {index + 1}
                    </InputLabel>
                    <Select value={item.location_id} disabled={true}>
                      <MenuItem value="">PILIH LOKASI</MenuItem>
                      {value.master_location.map((item, index) => (
                        <MenuItem value={item.id} key={index}>
                          {item.location_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
          </Paper>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date"
                label="Tanggal ganti"
                format="d, MMMM, yyyy"
                minDate={new Date()}
                value={value.date_after}
                onChange={(v) => {
                  setValue({ ...value, date_after: v });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>

          <Paper
            style={{
              display: value.date_after === null ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "#ffffff",
              borderColor: "#262261",
              borderRadius: 11,
              padding: "10px 20px",
              marginTop: "20px",
            }}
            variant="outlined"
          >
            <div
              style={{
                color: "#262261",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BusinessCenter style={{ marginRight: "10px" }} />
              <Typography style={{ fontWeight: "bold" }}>
                CLOCK IN &amp; OUT
              </Typography>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="input-clockin">
                    Clock In
                  </InputLabel>
                  <Input
                    id="input-clockin"
                    type="time"
                    value={value.clock_in}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        clock_in: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="input-clockout">
                    Clock Out
                  </InputLabel>
                  <Input
                    id="input-clockout"
                    type="time"
                    value={value.clock_out}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        clock_out: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} htmlFor="input-location">
                    Lokasi
                  </InputLabel>
                  <Select
                    value={value.clock_location}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        clock_location: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">PILIH LOKASI</MenuItem>
                    {value.master_location.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.location_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{
              display: value.date_after === null ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              background: "#ffffff",
              borderColor: "#262261",
              borderRadius: 11,
              padding: "10px 20px",
              marginTop: "20px",
            }}
            variant="outlined"
          >
            <div
              style={{
                color: "#262261",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BusinessCenter style={{ marginRight: "10px" }} />
              <Typography style={{ fontWeight: "bold" }}>
                CHECK IN &amp; OUT
              </Typography>
            </div>
            {value.detail_schedule.map((item, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      htmlFor={`input-checkin-${index + 1}`}
                    >
                      Check In {index + 1}
                    </InputLabel>
                    <Input
                      id={`input-checkin-${index + 1}`}
                      type="time"
                      value={item.check_in}
                      onChange={(e) => {
                        const newValue = [...value.detail_schedule];
                        newValue[index].check_in = e.target.value;
                        setValue({
                          ...value,
                          detail_schedule: newValue,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      htmlFor={`input-checkout-${index + 1}`}
                    >
                      Check Out {index + 1}
                    </InputLabel>
                    <Input
                      id={`input-checkout-${index + 1}`}
                      type="time"
                      value={item.check_out}
                      onChange={(e) => {
                        const newValue = [...value.detail_schedule];
                        newValue[index].check_out = e.target.value;
                        setValue({
                          ...value,
                          detail_schedule: newValue,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink={true}
                      htmlFor={`input-location-${index + 1}`}
                    >
                      Lokasi {index + 1}
                    </InputLabel>
                    <Select
                      value={item.location_id}
                      onChange={(e) => {
                        const newValue = [...value.detail_schedule];
                        newValue[index].location_id = e.target.value;
                        setValue({
                          ...value,
                          detail_schedule: newValue,
                        });
                      }}
                    >
                      <MenuItem value="">PILIH LOKASI</MenuItem>
                      {value.master_location.map((item, index) => (
                        <MenuItem value={item.id} key={index}>
                          {item.location_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
          </Paper>

          <FormControl
            fullWidth
            style={{
              marginTop: "1rem",
              display: value.date_before ? "inline-flex" : "none",
            }}
          >
            <TextField
              fullWidth
              label="Deskripsi"
              placeholder="Masukkan deskripsi (Opsional)"
              onChange={(e) => {
                setValue({ ...value, deskripsi: e.target.value });
              }}
              value={value.deskripsi}
            />
          </FormControl>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "1rem",
            }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={
                  () => {
                    handleClose();
                  } /*handleCancel()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Batal
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={
                  () => {
                    verification();
                  } /*handleSubmit()*/
                }
                style={{
                  height: "3rem",
                }}
                //disabled={loading}
              >
                Ajukan
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default RequestExceptionDialog;
