import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Switch,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  withStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import ListitemNotifGroup from "./ListitemNotifGroup";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#0F68D2",
        borderColor: "#0F68D2",
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 10,
    opacity: 1,
    backgroundColor: "#ECECEC",
  },
  checked: {},
}))(Switch);

const ViewNotifByGroup = ({ classes, triggerNotif, setTriggerNotif }) => {
  const [notifCollection, setNotifCollection] = useState([]);

  const [enforce, setEnforce] = useState(false);

  useEffect(() => {
    AxiosConfig.get(`${URL_API}/account-management/notification/setting`)
      .then((res) => {
        const result = res.data.data;
        // // console.log("Res notif collections", result);

        if (res.status === 200) {
          const entities = result.map((el) => el.eventType?.entityType?.id);
          let slimEntity = [...new Set(entities)];
          const newEntity = slimEntity.map((el) => {
            const grabNotif = result.filter(
              (es) => el === es.eventType?.entityType?.id
            );

            return {
              entity: el,
              data: grabNotif,
            };
          });

          setNotifCollection(newEntity);
          // // console.log("newEntity", newEntity);
          // // console.log("slimEntity", slimEntity);
          // // console.log("entities", entities);
        }
      })
      .catch((error) => console.log(error));
  }, [triggerNotif]);

  const handleEnforce = (e) => {
    setEnforce(e.target.checked);
  };

  // // console.log("notifCollection", notifCollection);

  return (
    <ThemeProvider theme={theme}>
      {/* <Typography variant="subtitle1" className={classes.title888}>
        <b>Enforce Setttings</b>
      </Typography>

      <Box mt={3}>
        <AntSwitch checked={enforce} onChange={handleEnforce} name="enforce" />
      </Box> */}

      {notifCollection.length > 0 &&
        notifCollection.map((notif, i) => {
          // // console.log("NOtif ITEM", notif);

          return (
            <Box key={i} mb={2}>
              <Box my={3}>
                <Typography variant="h5" className={classes.title555}>
                  {/* {notif.entity} */}
                  {notif.data[0]?.eventType?.entityType?.name}
                </Typography>
              </Box>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Event</TableCell>
                    <TableCell>Recipients</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>App</TableCell>
                    <TableCell>All</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notif.data.length > 0 &&
                    notif.data.map((item, i) => {
                      return (
                        <ListitemNotifGroup
                          key={i}
                          classes={classes}
                          item={item}
                          setTriggerNotif={setTriggerNotif}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          );
        })}
    </ThemeProvider>
  );
};

export default ViewNotifByGroup;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});
