import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import PaymentIcon from "@material-ui/icons/Payment";

import moment from "moment";

import icon_high from "../../../../assets/images/SVG/icon_high.svg";
import icon_medium from "../../../../assets/images/SVG/icon_medium.svg";
import icon_low from "../../../../assets/images/SVG/icon_low.svg";

import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";
import ContextProject from "../../context/ContextProject";

import ResultBarDetail from "../global/ResultBarDetail";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import Description from "../../../SPECIAL/Components/Action/detail/Description";
import TaskInformation from "../../../SPECIAL/Components/Action/detail/TaskInformation";
import Notes from "../../../SPECIAL/Components/Action/detail/Notes";
import Attachment from "../../../SPECIAL/Components/Action/detail/Attachment";
import Checklist from "../../../SPECIAL/Components/Action/detail/Checklist";
import {
  statusCollections,
  statusWithoutTodo,
  priorityCollections,
} from "../../../SPECIAL/Components/global/statsprio";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
import CustomPhase from "../../../SPECIAL/Components/Action/detail/CustomPhase";

const DialogKanbanDetail = ({
  classes,
  item,
  openKanbanDetail,
  setOpenKanbanDetail,
}) => {
  const checked = item?.is_complete?.id === "2" ? true : false;
  const projects = JSON.parse(localStorage.getItem("project_details"));
  const project_id = window.location.pathname.split("/")[2];
  const {
    projectTState,
    projectTDispatch,
    setTriggerTask,
    setTriggerKanban,
    triggerAttachment,
    setTriggerAttachment,
  } = useContext(ContextProject);

  let statusColor;
  let icon;

  switch (item?.status && item?.status?.id) {
    case "TO DO":
      statusColor = "#777777";
      break;
    case "DOING":
      statusColor = "#4194AC";
      break;
    case "DONE":
      statusColor = "#1E874D";
      break;
    case "ON HOLD":
      statusColor = "#B68812";
      break;
    case "WAITING":
      statusColor = "#92739F";
      break;
    default:
      statusColor = "#444444";
  }

  switch (item?.priority && item?.priority?.id) {
    case "HIGH":
      icon = icon_high;
      break;
    case "MEDIUM":
      icon = icon_medium;
      break;
    case "LOW":
      icon = icon_low;
      break;
    default:
      icon = null;
  }
  // =================================================================
  // ~~~ STATE ~~~
  // =================================================================
  const [ownerCollection, setOwnerCollection] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  // GET Values for Update

  const [title, setTitle] = useState(
    item?.title !== null ? item?.title : "Add title"
  );
  const [description, setDescription] = useState(
    item?.description !== null ? item?.description : "no description"
  );
  const [remarks, setRemarks] = useState(
    item?.remarks !== null ? item?.remarks : "Write your notes..."
  );
  const [ownerId, setOwnerId] = useState(item?.owner);
  const [statusId, setStatusId] = useState(
    item?.status !== null ? item?.status?.id : ""
  );
  const [priorityId, setPriorityId] = useState(
    item?.priority !== null ? item?.priority?.id : ""
  );
  const [selectedDueDate, setSelectedDueDate] = useState(item?.due_date);
  const [startDate, setStartDate] = useState(item?.start_date);
  const [endDate, setEndDate] = useState(item?.end_date);
  // const [duration, setDuration] = useState(item??.planned_duration);
  const [duration, setDuration] = useState(1);
  const [tags, setTags] = useState(item?.tags !== null ? item?.tags : "");
  const [actual, setActual] = useState(Number(item?.actual_result));
  const [target, setTarget] = useState(Number(item?.target_result));
  const [percentage, setPercentage] = useState((actual / target) * 100);
  const [projectType, setProjectType] = useState("TEAM");
  const [notifStartDate, setNotifStartDate] = useState("");
  const [customPhaseId, setCustomPhaseId] = useState("");

  //collection
  const [phaseCollection, setPhaseCollection] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [selected, setSelected] = useState([]);
  // conditional
  const [loading, setLoading] = useState(false);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [loadDuration, setLoadDuration] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [status403, setStatus403] = useState(false);
  const [projectOwner, setProjectOwner] = useState(false);
  const [triggerChecklist, setTriggerChecklist] = useState(null);

  const [hideShow, setHideShow] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState("");

  // =================================================================
  // ~~~ SIDE EFFECT ~~~
  // =================================================================

  useEffect(() => {
    if (openKanbanDetail) {
      setLoading(true);

      setCustomPhaseId(item?.custom_phase_id);

      AxiosConfig.get(
        `${URL_API}/todolist/${item.id}/update?project_id=${project_id}`
      )
        .then((res) => {
          const result = res.data.data;
          // console.log("TODO Update form", res);

          if (res.status === 200) {
            setOwnerCollection(result.ownerCollections);
            setDependencies(result.predecessor);

            const filterDependencies = result.predecessor?.filter((el) =>
              item?.dependencies?.includes(el.id)
            );

            setSelected(filterDependencies);

            setProjectOwner(res.data?.info?.its_project_owner);

            const project_type = result?.project?.project_type;
            setProjectType(project_type);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("ERR", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              setStatus403(true);
            }
          }

          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });

      AxiosConfig.get(`${URL_API}/project/${project_id}/custom-phase`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setPhaseCollection(result);
          }
        })
        .catch((error) => {
          // console.log("error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openKanbanDetail]);

  useEffect(() => {
    if (openKanbanDetail) {
      AxiosConfig.get(`${URL_API}/todolist/${item?.id}/checklist`)
        .then((res) => {
          const result = res.data.data;
          //   // console.log("CHCKLST coll", result);

          if (res.status === 200) {
            if (result === null) {
              setCheckList([]);
            } else {
              setCheckList(result);
            }
          }
        })
        .catch((error) => {
          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openKanbanDetail, triggerChecklist]);

  useEffect(() => {
    const act = Number(item?.actual_result);
    const tar = Number(item?.target_result);

    // // console.log("act", act);
    // // console.log("tar", tar);

    setActual(act);
    setTarget(tar);

    const percent = (act / tar) * 100;

    setPercentage(isNaN(percent) ? 0 : percent);
  }, [item]);

  // ================================================================
  const filterPedecessor = dependencies
    ?.filter((item) => selected?.some((el) => el.id === item.id))
    ?.reduce((a, b) => (a.due_date > b.due_date ? a : b), []);

  const checkDueDate = moment(startDate).diff(
    moment(filterPedecessor?.due_date),
    "days"
  );

  useEffect(() => {
    if (filterPedecessor?.length !== 0 && checkDueDate < 1) {
      const getStartDate = moment(filterPedecessor?.due_date)
        .add(1, "days")
        .format("YYYY-MM-DD HH:mm:ss");

      setStartDate(getStartDate);
      setNotifStartDate("Start date disesuaikan dengan due date dependency");

      AxiosConfig.get(
        `${URL_API}/project/general/get-due-date?start_date=${moment(
          getStartDate
        ).format("YYYY-MM-DD")}&duration=${duration}&exclude_weekend=${
          projects?.project?.exclude_weekend
        }`
      )
        .then((res) => {
          const result = res.data.data;

          // // console.log("SET DUED", result);

          if (res.status === 200) {
            setSelectedDueDate(result.due_date);
          }
        })
        .catch((err) => {
          // console.log("error", err);
        });
    } else {
      setNotifStartDate("");
    }
  }, [filterPedecessor]);

  // =================================================================
  // ~~~ FUNCTION || METHODS ~~~
  // =================================================================
  const handleUpdateTask = () => {
    setLoadUpdate(true);

    // const isDoing = Number(actual) > 0 && Number(target) > 0
    // isDoing ? "DOING" : statusId

    const predecessor_id =
      selected.length > 0 ? selected.map((pre) => pre.id) : [];

    const data = {
      Todolist: {
        title,
        description,
        remarks,
        owner_id: ownerId.id,
        status: statusId === "TO DO" && Number(actual) > 0 ? "DOING" : statusId,
        priority: priorityId,
        due_date: moment(selectedDueDate).format("YYYY-MM-DD HH:mm:ss"),
        start_date: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        end_date:
          endDate !== null
            ? moment(endDate).format("YYYY-MM-DD HH:mm:ss")
            : null,
        tags,
        actual_result: Number(actual),
        target_result: Number(target),
        project_id,
        task_group_id: item.task_group_id,
        predecessor_id,
        custom_phase_id: customPhaseId,
      },
    };

    // // console.log("TDL Update", data);

    AxiosConfig.put(`${URL_API}/todolist/${item.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setTriggerKanban(Math.floor(Math.random() * 100));
          setLoadUpdate(false);
          setOpenKanbanDetail(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadUpdate(false);
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // // console.log("ITEMz", item);

  return (
    <Dialog
      maxWidth="sm"
      open={openKanbanDetail}
      onClose={() => setOpenKanbanDetail(false)}
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <PaymentIcon />
          <Box mx={0.5} />
          <TextField
            type="text"
            variant="standard"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            InputProps={{
              style: { fontSize: 17, color: "#555", fontWeight: "bold" },
              disableUnderline: true,
            }}
            disabled={status403 || projectType === "PERSONAL"}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitile2" className={classes.title555}>
            Task Status is
          </Typography>
          <Box mx={0.5} />
          <Typography variant="subtitile2" style={{ color: statusColor }}>
            <b>{item?.status !== null ? item?.status?.name : "Not Set"}</b>
          </Typography>

          <Box mx={0.5} />
          <Divider orientation="vertical" flexItem />
          <Box mx={0.5} />

          <Typography variant="subtitile2" className={classes.title555}>
            Completed
          </Typography>
          <Box mx={0.5} />
          {checked ? (
            <CheckCircleRoundedIcon style={{ color: "#2ecc71" }} />
          ) : (
            <CancelRoundedIcon style={{ color: "#d64253" }} />
          )}
        </Box>

        <Box mt={2}>
          <Box mb={0.5} display="flex" justifyContent="space-between">
            <Typography variant="caption" className={classes.title555}>
              {FormatDecimal(percentage)}%
            </Typography>
            <Typography variant="caption" className={classes.title555}>
              {actual} / {target}
            </Typography>
          </Box>
          <ResultBarDetail
            percentage={isNaN(percentage) ? 0 : percentage}
            color={statusColor}
          />
        </Box>

        <Box mt={4}>
          <Description
            classes={classes}
            description={description}
            setDescription={setDescription}
            status403={status403}
            projectType={projectType}
          />
        </Box>

        <Box mt={4}>
          <TaskInformation
            classes={classes}
            origin="task"
            icon={icon}
            statusColor={statusColor}
            ownerCollection={ownerCollection}
            loading={loading}
            ownerId={ownerId}
            setOwnerId={setOwnerId}
            statusId={statusId}
            setStatusId={setStatusId}
            priorityId={priorityId}
            setPriorityId={setPriorityId}
            selectedDueDate={selectedDueDate}
            setSelectedDueDate={setSelectedDueDate}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            tags={tags}
            setTags={setTags}
            actual={actual}
            setActual={setActual}
            target={target}
            setTarget={setTarget}
            dependencies={dependencies}
            duration={duration}
            setDuration={setDuration}
            loadDuration={loadDuration}
            toggle={toggle}
            setToggle={setToggle}
            project={projects}
            statusCollections={
              actual > 0 ? statusWithoutTodo : statusCollections
            }
            priorityCollections={priorityCollections}
            status403={status403}
            projectType={projectType}
            projectOwner={projectOwner}
            checkList={checkList}
            selected={selected}
            setSelected={setSelected}
            setHideShow={setHideShow}
            resultSource={projectTState.projectDetails?.scope_result_source}
            completed={checked}
          />
        </Box>

        {!hideShow && (
          <Box mt={4}>
            <CustomPhase
              classes={classes}
              phaseCollection={phaseCollection}
              customPhaseId={customPhaseId}
              setCustomPhaseId={setCustomPhaseId}
            />
          </Box>
        )}

        {!hideShow && (
          <Box mt={4}>
            <Notes
              classes={classes}
              remarks={remarks}
              setRemarks={setRemarks}
            />
          </Box>
        )}

        {!hideShow && (
          <Box mt={4}>
            <Attachment
              classes={classes}
              todo={item}
              triggerAttachment={triggerAttachment}
              onTriggerAttachment={setTriggerAttachment}
              status403={status403}
            />
          </Box>
        )}

        {!hideShow && (
          <Box mt={4}>
            <Checklist
              classes={classes}
              todo={item}
              setTriggerChecklist={setTriggerChecklist}
              checkList={checkList}
            />
          </Box>
        )}

        <Box
          mt={6}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="caption" style={{ color: "#ffa502" }}>
              {notifStartDate}
            </Typography>
          </Box>
          <Button
            size="small"
            variant={
              loadUpdate || status403 || checked ? "contained" : "outlined"
            }
            disabled={loadUpdate || status403}
            className={classes.button1}
            onClick={handleUpdateTask}
          >
            {loadUpdate ? "Updating Task..." : "Update"}
          </Button>
        </Box>
      </DialogContent>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogKanbanDetail;
