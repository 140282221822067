import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { navigate } from "hookrouter";

import { ToLogin } from "../../../../constants/config-redirect-url";

import PictInfo from "../../../../assets/images/icon-info-24px.svg";

const DialogHubungiAdministrator = (props) => {
  const {
    classes,
    isResponseError,
    setResponseError,
    infoResponse400,
    infoResponse422,
    infoResponse404,
  } = props;

  const handleBackToLogin = () => {
    navigate(ToLogin);
    setResponseError(false);
  };

  return (
    <Dialog
      open={isResponseError}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <img src={PictInfo} className={classes.media} alt="info-icon" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="h6">
            {infoResponse400 != ""
              ? infoResponse400
              : infoResponse422 != ""
              ? infoResponse422
              : infoResponse404 != ""
              ? infoResponse404
              : null}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ alignItems: "center", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={handleBackToLogin}
          color="primary"
          size="small"
          className={classes.button}>
          Kembali ke Login
        </Button>
      </DialogActions>
      <br />
      <br />
    </Dialog>
  );
};

export default DialogHubungiAdministrator;
