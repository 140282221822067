import React, { useContext } from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from "@material-ui/core";
import ContextTimeOffSetting from "../../Context/ContextTimeOffSetting";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";

function EnhancedTableHeadUserRegistered({
  classes,
  dataCategory,
  dataRegisteredUser,
  setDataRegisteredUser,
  setLoader,
}) {
  const context = useContext(ContextTimeOffSetting);

  const onSelectAllClick = async () => {
    context.setModalProgress(true);
    setLoader(true);
    let dataTemp = dataRegisteredUser;
    if (
      dataRegisteredUser.options.paging.count > 0 &&
      dataRegisteredUser.users.length ===
        dataRegisteredUser.options.paging.count
    ) {
      dataTemp.users = [];
      setDataRegisteredUser({ ...dataTemp });
      setLoader(false);
      context.setModalProgress(false);
    } else {
      dataTemp.users = [];
      axiosConfig
        .get(
          `${URL_API}/human-resource/timeoff-category/${dataCategory.id}/list-assigned-user` +
            `?options[filter][search]=${dataRegisteredUser.options.filter.search}` +
            `&options[filter][sex_id]=${dataRegisteredUser.options.filter.sex_id}` +
            `&options[filter][age]=${dataRegisteredUser.options.filter.age}` +
            `&options[paging][limit]=${dataRegisteredUser.options.paging.count}` +
            `&options[paging][offset]=0`
        )
        .then(async (response) => {
          // console.log("Response Original : ", response.data);
          if (response.data.data) {
            await response.data.data.map((item) => {
              dataTemp.users.push(item.id);
            });
          }
          setDataRegisteredUser({ ...dataTemp });
          console.log(dataTemp.users);
          setLoader(false);
          context.setModalProgress(false);
        })
        .catch(function (error) {
          setLoader(false);
          context.setModalProgress(false);
          // console.log("Error : ", error.response);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            } else if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.messag
            ) {
              context.setErrorStatus(error.response.status);
              context.setTextErrorInformation(error.response.data.info.message);
              context.setOpenDialogError(true);
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
          }
        });
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={
              dataRegisteredUser.users.length > 0 &&
              dataRegisteredUser.users.length <
                dataRegisteredUser.options.paging.count
            }
            checked={
              dataRegisteredUser.options.paging.count > 0 &&
              dataRegisteredUser.users.length ===
                dataRegisteredUser.options.paging.count
            }
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title333}
            style={{ textAlign: "left" }}
          >
            <b>No</b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            className={classes.title333}
            style={{ textAlign: "left" }}
          >
            <b>Nama Lengkap</b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            className={classes.title333}
            style={{ textAlign: "left" }}
          >
            <b>Email</b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            className={classes.title333}
            style={{ textAlign: "left" }}
          >
            <b>Jenis Kelamin</b>
          </Typography>
        </TableCell>

        <TableCell align="left">
          <Typography
            variant="subtitle2"
            className={classes.title333}
            style={{ textAlign: "left" }}
          >
            <b>Umur</b>
          </Typography>
        </TableCell>

        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHeadUserRegistered;
