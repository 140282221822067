import React, { useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import axios from "axios";

import Snackber from "../../Components/Snackber";
import ConvertDataUrlToFile from "../../../../utilities/ConvertDataUrlToFile";
import { URL_API } from "../../../../constants/config-api";

import Redirect from "../../../../utilities/Redirect";

import {
  ToHrEmployeeRiwayatUrgentContact,
  ToHrEmployeeProfileDetail,
  ToHrEmployeeProfileDetailSeeDetail,
  ToHrEmployeeIdentitas,
  ToHrEmployeeIdentitasAlamat,
  ToHrEmployeeIdentitasInfoTambahan,
} from "../../../../constants/config-redirect-url";

const ButtonSpecialSimpanToDB = (props) => {
  const { classes } = props;

  /*

        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````

    */
  const [userTokenState, setUserTokenState] = useState("");

  const [identitas, setIdentitas] = useState("");
  const [identitasAlamat, setIdentitasAlamat] = useState("");
  const [identitasInfoTambahan, setIdentitasInfoTambahan] = useState("");
  const [kepegawaianInfoPegawai, setKepegawaianInfoPegawai] = useState("");
  const [kepegawaianInfoPosisi, setKepegawaianInfoPosisi] = useState([]);

  const [riwayatPendidikanFormal, setRiwayatPendidikanFormal] = useState([]);
  const [riwayatPendidikanINFORMAL, setRiwayatPendidikanINFORMAL] = useState(
    []
  );
  const [riwayatOrganisasi, setRiwayatOrganisasi] = useState([]);
  const [riwayatPengalamanKerja, setRiwayatPengalamanKerja] = useState([]);

  const [kontakDarurat, setKontakDarurat] = useState([]);
  const [keahlian, setKeahlian] = useState([]);
  const [keluarga, setKeluarga] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    setUserTokenState(userToken);

    //*
    const dataIdentitas = localStorage.getItem("data_identitas");
    const dataIdentitasAfterParse = JSON.parse(dataIdentitas);
    // // console.log("data_identitas : ", dataIdentitasAfterParse);

    if (dataIdentitasAfterParse !== null) {
      setIdentitas(dataIdentitasAfterParse);
    }

    //**
    const dataIdentitasInfoTambahan = localStorage.getItem(
      "data_identitas_info_tambahan"
    );
    const dataIdentitasInfoTambahanAfterParse = JSON.parse(
      dataIdentitasInfoTambahan
    );
    // // console.log("data_identitas_info_tambahan : ", dataIdentitasInfoTambahanAfterParse);

    if (dataIdentitasInfoTambahanAfterParse !== null) {
      setIdentitasInfoTambahan(dataIdentitasInfoTambahanAfterParse);
    }

    //***
    const dataIdentitasAlamat = localStorage.getItem("data_identitas_alamat");
    const dataIdentitasAlamatAfterParse = JSON.parse(dataIdentitasAlamat);

    if (dataIdentitasAlamatAfterParse !== null) {
      setIdentitasAlamat(dataIdentitasAlamatAfterParse);
    }

    /*
            ````````````````````````````````````
            HANDLE KALAU DATA MANDATORY IS NULL
        */
    // if(dataIdentitasAfterParse == null && dataIdentitasInfoTambahanAfterParse == null && dataIdentitasAlamatAfterParse == null ){

    if (dataIdentitasAfterParse == null) {
      setFormFilledAll(true);

      setTimeout(() => {
        Redirect(ToHrEmployeeIdentitas);
      }, 3000);
    }

    // else if( dataIdentitasAlamatAfterParse == null && dataIdentitasAfterParse !== null ){

    //     setFormFilledAll(true);

    //     setTimeout(() => {

    //         Redirect(ToHrEmployeeIdentitasAlamat);

    //     }, 3000)

    // } else if(dataIdentitasInfoTambahanAfterParse == null && dataIdentitasAlamatAfterParse !== null && dataIdentitasAfterParse !== null){

    //     setFormFilledAll(true);

    //     setTimeout(() => {

    //         Redirect(ToHrEmployeeIdentitasInfoTambahan);

    //     }, 3000)

    // } else {

    //     // console.log("Alhamdulillah, semua data mandatory telah di isi !");

    // };

    //***********
    const dataInfoPegawai = localStorage.getItem("employee_data_info_pegawai");
    const dataInfoPegawaiAfterParse = JSON.parse(dataInfoPegawai);
    setKepegawaianInfoPegawai(dataInfoPegawaiAfterParse);

    //***********
    const dataInfoPosisi = localStorage.getItem("employee_data_info_position");
    const dataInfoPosisiAfterParse = JSON.parse(dataInfoPosisi);
    setKepegawaianInfoPosisi(dataInfoPosisiAfterParse);

    //****
    const dataRiwayatPendidikanFormal = localStorage.getItem(
      "employee_data_riwayat_formal"
    );
    const dataRiwayatPendidikanFormalAfterParse = JSON.parse(
      dataRiwayatPendidikanFormal
    );
    setRiwayatPendidikanFormal(dataRiwayatPendidikanFormalAfterParse);

    //*****
    const dataRiwayatPendidikanINFORMAL = localStorage.getItem(
      "employee_data_riwayat_informal"
    );
    const dataRiwayatPendidikanINFORMALAfterParse = JSON.parse(
      dataRiwayatPendidikanINFORMAL
    );
    setRiwayatPendidikanINFORMAL(dataRiwayatPendidikanINFORMALAfterParse);

    //******
    const dataKontakDarurat = localStorage.getItem(
      "employee_data_kontak_darurat"
    );
    const dataKontakDaruratAfterParse = JSON.parse(dataKontakDarurat);
    setKontakDarurat(dataKontakDaruratAfterParse);

    //*******
    const dataRiwayatOrg = localStorage.getItem("employee_data_riwayat_org");
    const dataRiwayatOrgParse = JSON.parse(dataRiwayatOrg);
    setRiwayatOrganisasi(dataRiwayatOrgParse);

    //*******
    const dataRiwayatExperience = localStorage.getItem(
      "employee_data_riwayat_experience"
    );
    const dataRiwayatExperienceParse = JSON.parse(dataRiwayatExperience);
    setRiwayatPengalamanKerja(dataRiwayatExperienceParse);

    //********
    const dataKeahlian = localStorage.getItem("employee_data_keahlian");
    const dataKeahlianAfterParse = JSON.parse(dataKeahlian);
    setKeahlian(dataKeahlianAfterParse);

    //**********
    const dataKeluarga = localStorage.getItem("employee_data_keluarga");
    const dataKeluargaParse = JSON.parse(dataKeluarga);
    setKeluarga(dataKeluargaParse);
  }, []);

  /*
        ```````````````````````````````
        GET DATA UNTUK PARAMS DATA POST

        ```````````````````````````````
    */
  let listRiwayatFormalEducationHistory = [];
  let listRiwayatINFORMALEducationHistory = [];
  let listRiwayatOrganisasi = [];
  let listRiwayatPengalamanKerja = [];

  let listEmegencyContact = [];
  let listKeahlian = [];
  let listKeluarga = [];

  let listPegawaiInfoPosisi = [];

  /*
        ```````````````````````````````

        LIST RIWAYAT PENDIDIKAN FORMAL

        ``````````````````````````````
    */

  const documentFormData = new FormData();

  if (riwayatPendidikanFormal !== null) {
    if (riwayatPendidikanFormal.length > 0) {
      riwayatPendidikanFormal.map((item) => {
        /*
                    GET FILE from binaryString Image
                */

        const document = ConvertDataUrlToFile(item.ijazah, item.name_file);

        //documentFormData.append('file', item.ijazah, item.name_file);
        if (document !== undefined) {
          let templateEmployeeFormalEducationHistory = {
            education_level_id: item !== undefined ? item.jenjang.id : "",
            name: item.instansi,
            department: item.program_studi,
            start_date: item.tahun_masuk,
            end_date: item.tahun_selesai,

            document_url: document, //File
          };

          listRiwayatFormalEducationHistory.push(
            templateEmployeeFormalEducationHistory
          );
        } else {
          let templateEmployeeFormalEducationHistory = {
            education_level_id: item !== undefined ? item.jenjang.id : "",
            name: item.instansi,
            department: item.program_studi,
            start_date: item.tahun_masuk,
            end_date: item.tahun_selesai,
          };

          listRiwayatFormalEducationHistory.push(
            templateEmployeeFormalEducationHistory
          );
        }
      });
    }
  }

  /*
        ```````````````````````````````

        LIST RIWAYAT PENDIDIKAN INFORMAL

        ``````````````````````````````
    */
  if (riwayatPendidikanINFORMAL !== null) {
    if (riwayatPendidikanINFORMAL.length > 0) {
      // // console.log("riwayatPendidikanINFORMAL : ", riwayatPendidikanINFORMAL);

      riwayatPendidikanINFORMAL.map((item, i) => {
        const document = ConvertDataUrlToFile(item.ijazah, item.name_file);

        if (document !== undefined) {
          let template = {
            type_id: item !== undefined ? item.jenjang.id : "",
            name: item.instansi,
            department: item.program_studi,
            city: item.kota,
            start_date: item.tahun_masuk,
            end_date: item.tahun_selesai,
            document_url: document,
          };

          listRiwayatINFORMALEducationHistory.push(template);
        } else {
          let template = {
            type_id: item !== undefined ? item.jenjang.id : "",
            name: item.instansi,
            department: item.program_studi,
            city: item.kota,
            start_date: item.tahun_masuk,
            end_date: item.tahun_selesai,
          };

          listRiwayatINFORMALEducationHistory.push(template);
        }
      });
    }
  }

  /*
        ```````````````````````

        LIST RIWAYAT ORGANISASI

        ```````````````````````
    */
  if (riwayatOrganisasi !== null) {
    if (riwayatOrganisasi.length > 0) {
      riwayatOrganisasi.map((item) => {
        let templateRiwayatOrg = {
          name: item.organisasi,
          position: item.name_jabatan,
          city: item.kota,
          description: item.keterangan,
          start_date: item.tahun_masuk,
          end_date: item.tahun_selesai,
        };

        listRiwayatOrganisasi.push(templateRiwayatOrg);
      });
    }
  }

  /*
        ```````````````````

        LIST KONTAK DARURAT

        ```````````````````
    */

  if (kontakDarurat !== null) {
    if (kontakDarurat.length > 0) {
      // // console.log("Kontak Daruat : ", kontakDarurat);

      kontakDarurat.map((item) => {
        let templateKontakDarurat = {
          name: item.name_anggota_keluarga,
          relationship_type_id:
            item !== undefined ? item.hubungan_keluarga.id : "",
          mobile_number: item.no_hp,
        };

        listEmegencyContact.push(templateKontakDarurat);
      });
    }
  }

  /*
        ``````````````````````````````
        LIST RIWAYAT PENGALAMAN KERJA 

        ``````````````````````````````
    */

  if (riwayatPengalamanKerja !== null) {
    if (riwayatPengalamanKerja.length > 0) {
      riwayatPengalamanKerja.map((item) => {
        if (item.ijazah !== "") {
          let template = {
            name: item.nama_perusahaan,
            position: item.nama_jabatan,
            achievement: item.penghargaan,
            start_date: item.tahun_masuk, // tahun & bulan
            end_date: item.tahun_keluar,
            address: item.alamat_perusahaan,
            leave_reason: item.alasan_berhenti,
            phone_number_office: item.no_telp_perusahaan,
            document_url: item.ijazah,
          };

          listRiwayatPengalamanKerja.push(template);
        } else {
          let template = {
            name: item.nama_perusahaan,
            position: item.nama_jabatan,
            achievement: item.penghargaan,
            start_date: item.tahun_masuk, // tahun & bulan
            end_date: item.tahun_keluar,
            address: item.alamat_perusahaan,
            leave_reason: item.alasan_berhenti,
            phone_number_office: item.no_telp_perusahaan,
          };

          listRiwayatPengalamanKerja.push(template);
        }
      });
    }
  }

  /*
        `````````````
        LIST KEAHLIAN

        `````````````
    */
  if (keahlian !== null) {
    if (keahlian.length > 0) {
      keahlian.map((item) => {
        let templateKEahlian = {
          name: item.name_keahlian,
          score: item.level == "" ? 1 : 3,
        };

        listKeahlian.push(templateKEahlian);
      });
    }
  }

  /*
        `````````````
        LIST KELUARGA

        `````````````
    */

  if (keluarga !== null) {
    if (keluarga.length > 0) {
      keluarga.map((item) => {
        let templateKeluarga = {
          name: item.name,
          relationship_type_id:
            item !== undefined ? item.family_relationship_type_id.id : "",
          date_of_birth: item.date_of_birth,
        };

        listKeluarga.push(templateKeluarga);
      });
    }
  }

  /*
        ````````````````````````
        LIST PEGAWAI INFO POSISI

        ````````````````````````
    */

  if (kepegawaianInfoPosisi !== null) {
    if (kepegawaianInfoPosisi.length > 0) {
      kepegawaianInfoPosisi.map((item) => {
        listPegawaiInfoPosisi.push(item.id);
      });
    }
  }

  const handleSimpanDataToDB = () => {
    let fotoKaryawanFile = ConvertDataUrlToFile(
      identitas.foto,
      identitas.foto_name
    );
    const fileFoto = new Blob([fotoKaryawanFile]); //*Work

    let formData = new FormData();

    formData.append("Employee[employee_number]", identitas.nip);
    formData.append("Employee[first_name]", identitas.nama_depan);
    formData.append("Employee[last_name]", identitas.nama_belakang);
    formData.append("Employee[nickname]", identitas.nama_panggilan);
    formData.append("Employee[mobile_number_personal]", identitas.no_hp);
    formData.append("Employee[email]", identitas.email);
    formData.append(
      "Employee[religion_id]",
      "007fba3e-35ce-485f-bc22-feda9125c813"
    );
    formData.append("Employee[place_of_birth]", identitas.tempat_lahir);
    formData.append("Employee[date_of_birth]", identitas.tanggal_lahir);

    formData.append("Employee[identity_number]", identitas.nip); // HARUS-NYA KTP

    if (identitasAlamat !== "") {
      formData.append(
        "Employee[address][country_id]",
        identitasAlamat.negara == "Indonesia"
          ? "a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc"
          : "a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc"
      );
      formData.append(
        "Employee[address][province_id]",
        identitasAlamat !== null ? identitasAlamat.provinsi.id : ""
      );
      formData.append(
        "Employee[address][city_id]",
        identitasAlamat !== null ? identitasAlamat.kota_kabupaten.key : ""
      );
      formData.append(
        "Employee[address][postal_code_id]",
        identitasAlamat !== null ? identitasAlamat.kode_pos.key : ""
      );
      formData.append(
        "Employee[address][detail]",
        identitasAlamat !== null ? identitasAlamat.alamat_in_ktp : ""
      );

      formData.append(
        "Employee[current_address_id][country_id]",
        identitasAlamat.negara_in_ktp == "Indonesia"
          ? "a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc"
          : "a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc"
      );
      formData.append(
        "Employee[current_address_id][province_id]",
        identitasAlamat !== null ? identitasAlamat.provinsi_in_ktp.id : ""
      );
      formData.append(
        "Employee[current_address_id][city_id]",
        identitasAlamat !== null
          ? identitasAlamat.kota_kabupaten_in_ktp.key
          : ""
      );
      formData.append(
        "Employee[current_address_id][postal_code_id]",
        identitasAlamat !== null ? identitasAlamat.kode_pos_in_ktp.key : ""
      );
      formData.append(
        "Employee[current_address_id][detail]",
        identitasAlamat !== null ? identitasAlamat.alamat_domisili : ""
      );
    }

    if (identitasInfoTambahan !== "") {
      formData.append(
        "Employee[bank_id]",
        "c7333fd9-1c18-4613-870c-c8c9f24b52d3"
      );
      formData.append("Employee[bank_account_number]", "07897897987");
      formData.append(
        "Employee[future_goals]",
        identitasInfoTambahan !== null ? identitasInfoTambahan.cita_cita : ""
      );
      formData.append(
        "Employee[hobby]",
        identitasInfoTambahan !== null ? identitasInfoTambahan.hobi : ""
      );
      formData.append(
        "Employee[sex_id]",
        identitas.jenis_kelamin == "lakiLaki" ? 1 : 2
      );
      formData.append(
        "Employee[num_of_children]",
        identitasInfoTambahan.jumlah_anak
      );

      formData.append(
        "Employee[social_media_account_fb]",
        identitasInfoTambahan.facebook
      );
      formData.append(
        "Employee[social_media_account_ig]",
        identitasInfoTambahan.instagram
      );
      formData.append(
        "Employee[social_media_account_twitter]",
        identitasInfoTambahan.tweeter
      );
      formData.append(
        "Employee[social_media_account_linkedin]",
        identitasInfoTambahan.linkedin
      );

      formData.append("Employee[identity_number_document_type_id]", 1);

      formData.append("Employee[family_registration_number]", "1332e23e-kk");
      formData.append("Employee[tax_id_number]", identitasInfoTambahan.npwp);
      formData.append("Employee[driving_license_number]", "7897897987-sim");
      formData.append("Employee[marriage_status]", 2);
    }

    formData.append("Employee[date_joined]", "2016-05-21");

    formData.append(
      "Employee[social_security_number]",
      "908098098-bpjs-kesehatan"
    );
    formData.append(
      "Employee[employment_security_number]",
      "09808098-bpjs-ketenagakerjaan"
    );
    formData.append(
      "Employee[retirement_security_number]",
      "780809-jaminan-hari-tua"
    );
    formData.append("Employee[employment_status_id]", 1);
    formData.append("Employee[status_id]", 1);
    formData.append("Employee[vehicle_type_id]", 1);
    formData.append("Employee[blood_type]", "O");

    if (listPegawaiInfoPosisi.length > 0) {
      listPegawaiInfoPosisi.map((item, i) => {
        formData.append(`Employee[position_id][${i}]`, item);
      });
    }
    // formData.append('Employee[position_id]', listPegawaiInfoPosisi);

    formData.append("Employee[photo_url]", fileFoto);

    if (identitas.foto == "") {
      formData.delete("Employee[photo_url]");
    }

    // console.log("Form Data : ", formData);

    // let data = {

    //     "Employee": {

    //         "first_name": identitas.nama_depan,
    //         "last_name": identitas.nama_belakang,
    //         "nickname": identitas.nama_panggilan,
    //         "email": identitas.email,
    //         "religion_id": identitas !== undefined ? identitas.agama.id : '007fba3e-35ce-485f-bc22-feda9125c813',
    //         "social_media_account_fb": identitasInfoTambahan.facebook,
    //         "social_media_account_ig": identitasInfoTambahan.instagram,
    //         "social_media_account_twitter": identitasInfoTambahan.tweeter,
    //         "social_media_account_linkedin": identitasInfoTambahan.linkedin,
    //         "address": {

    //             "country_id" : identitasAlamat.negara == 'Indonesia' ? 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc' : 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc',
    //             "province_id" : identitasAlamat !== undefined ? identitasAlamat.provinsi.id : '',
    //             "city_id": identitasAlamat !== undefined ? identitasAlamat.kota_kabupaten.key : '',
    //             "postal_code_id": identitasAlamat !== undefined ? identitasAlamat.kode_pos.key : '',
    //             "detail": identitasAlamat !== undefined ? identitasAlamat.alamat_in_ktp : ''
    //         },
    //         "current_address_id": {

    //             "country_id" : identitasAlamat.negara_in_ktp == 'Indonesia' ? 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc' : 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc',
    //             "province_id" : identitasAlamat !== undefined ? identitasAlamat.provinsi_in_ktp.id : '',
    //             "city_id": identitasAlamat !== undefined ? identitasAlamat.kota_kabupaten_in_ktp.key : '',
    //             "postal_code_id": identitasAlamat !== undefined ? identitasAlamat.kode_pos_in_ktp.key : '',
    //             "detail": identitasAlamat !== undefined ? identitasAlamat.alamat_domisili : ''
    //         },
    //         "bank_id": "c7333fd9-1c18-4613-870c-c8c9f24b52d3",
    //         "bank_account_number": "07897897987",
    //         "future_goals": identitasInfoTambahan !== null ? identitasInfoTambahan.cita_cita : '',
    //         "hobby": identitasInfoTambahan !== null ? identitasInfoTambahan.hobi : '',
    //         "identity_number": identitas.nip, //"8980809-ktp",
    //         "sex_id": identitas.jenis_kelamin == 'lakiLaki' ? 1 : 2,
    //         "num_of_children": identitasInfoTambahan.jumlah_anak,
    //         "identity_number_document_type_id": 1,
    //         "employee_number": "787689687-nip", //8980-no-karyawan
    //         "family_registration_number": "1332e23e-kk",
    //         "mobile_number_personal": identitas.no_hp, //"081296877815",
    //         "tax_id_number":  identitasInfoTambahan.npwp,
    //         "driving_license_number": "7897897987-sim",
    //         "marriage_status": 2,
    //         "date_of_birth": identitas.tanggal_lahir,
    //         "place_of_birth": identitas.tempat_lahir,
    //         "date_joined": "2016-05-21",
    //         "social_security_number": "908098098-bpjs-kesehatan",
    //         "employment_security_number": "09808098-bpjs-ketenagakerjaan",
    //         "retirement_security_number": "0780809-jaminan-hari-tua",
    //         "employment_status_id": 1,
    //         "status_id": 1,
    //         "vehicle_type_id": "3da8b1ea-88e7-45e4-9a66-5aa1adc77425",
    //         "blood_type": "O",
    //         "position_id": listPegawaiInfoPosisi,
    //         // "photo_url": identitas.foto !== null ? identitas.foto : ""
    //         photo_url: fotoKaryawanFile

    //     }
    // };

    // // console.log("Data : ", data);

    if (userTokenState !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + "/human-resource/employee/batch", formData)
        .then(function (response) {
          // console.log("Response Original : ", response);

          if (response.status == 200) {
            /*
                            ````````````````````````````````
                            SET ID EMPLOYEE IN LOCAL STORAGE

                            ````````````````````````````````
                        */

            localStorage.setItem("employee_id", response.data.data.id);
            localStorage.setItem(
              "response_employee_detail",
              JSON.stringify(response.data.data)
            );

            /*
                            ``````````````````````````````````````````````````````````````````
                            REMOVE ALL DATA IN LOCAL STORAGE AFTER  BERHASIL SIMPAN DATA TO DB

                            ``````````````````````````````````````````````````````````````````
                        */
            localStorage.removeItem("data_identitas");
            localStorage.removeItem("data_identitas_alamat");
            localStorage.removeItem("data_identitas_info_tambahan");
            localStorage.removeItem("employee_data_info_pegawai");
            localStorage.removeItem("employee_data_keahlian");
            localStorage.removeItem("employee_data_keluarga");
            localStorage.removeItem("employee_data_kontak_darurat");
            localStorage.removeItem("employee_data_riwayat_experience");
            localStorage.removeItem("employee_data_riwayat_formal");
            localStorage.removeItem("employee_data_riwayat_informal");
            localStorage.removeItem("employee_data_riwayat_org");
            localStorage.removeItem("employee_data_info_position");
            localStorage.removeItem("employee_data_riwayat_kesehatan");

            /*
                            ```````````````````````````````````
                            REDIRECT TO EMPLOYEE PROFILE DETAIL

                            ```````````````````````````````````
                        */
            Redirect(ToHrEmployeeProfileDetailSeeDetail);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);

          if (error.response.status == 400) {
            if (error.response.data.info !== null) {
              if (error.response.data.info !== null) {
                if (error.response.data.info.errors !== null) {
                  alert("Err  or 400 : " + error.response.data.info.message);
                }
              }
            }
          }
        });
    } else {
      alert("No Access Token available!");
    }
  };

  /*
        ````````````````
        HANDLE SNACK-BAR

        ````````````````
    */

  const [isFormFilledAll, setFormFilledAll] = useState(false);

  return (
    <span>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        style={{ margin: 14 }}
        disabled={false}
        onClick={handleSimpanDataToDB}
      >
        Lanjut
      </Button>

      {/*  
                `````````````````````
                VALIDATION SNACK BAR

                `````````````````````
            */}

      <Snackber
        isFormFilledAll={isFormFilledAll}
        setFormFilledAll={setFormFilledAll}
      />
    </span>
  );
};

export default ButtonSpecialSimpanToDB;

// let data = {

//     Employee: {

//             first_name: identitas.nama_depan,
//             last_name: identitas.nama_belakang,
//             nickname: identitas.nama_panggilan,
//             email: identitas.email,
//             religion_id: identitas !== undefined ? identitas.agama.id : '007fba3e-35ce-485f-bc22-feda9125c813',
//             social_media_account_fb: identitasInfoTambahan.facebook,
//             social_media_account_ig: identitasInfoTambahan.instagram,
//             social_media_account_twitter: identitasInfoTambahan.tweeter,
//             social_media_account_linkedin: identitasInfoTambahan.linkedin,
//             identity_number_document_type_id: '',//*1. KTP, 2.SIM, 3.Passpor
//             employee_number: identitas.nip,//
//             family_registration_number: '', //* KK,
//             mobile_number_personal: identitas.no_hp,
//             tax_id_number: '', //*NPWP
//             driving_license_number: '',
//             marriage_status: identitasInfoTambahan !== undefined ? identitasInfoTambahan.status.id : '',
//             date_of_birth: identitas.tanggal_lahir,
//             place_of_birth: identitas.tempat_lahir,
//             date_joined: '',//*Tanggal Bergabung YYYY-MM-DD
//             social_security_number: kepegawaianInfoPegawai.bpjs_kesehatan, //*BPJS
//             employment_security_number: kepegawaianInfoPegawai.bpjs_ketenagakerjaan, //*BPJS KETENAGAKERJAAN
//             retirement_security_number:  kepegawaianInfoPegawai.no_jaminan_hari_tua, //*Jaminan Hari Tua
//             employment_status_id: kepegawaianInfoPegawai !== undefined ? kepegawaianInfoPegawai.status_kerja.id : '',
//             status_id: kepegawaianInfoPegawai.status_karyawan == 'aktif' ? "1" : "2",
//             future_goals: identitasInfoTambahan.cita_cita,

//             address:  // KTP
//                 {
//                     country_id: identitasAlamat.negara == 'Indonesia' ? 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc' : 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc',
//                     province_id: identitasAlamat !== undefined ? identitasAlamat.provinsi.id : '',
//                     city_id: identitasAlamat !== undefined ? identitasAlamat.kota_kabupaten.key : '',
//                     postal_code_id: identitasAlamat !== undefined ? identitasAlamat.kode_pos.key : '',
//                     detail: identitasAlamat !== undefined ? identitasAlamat.alamat_in_ktp : ''
//                 },

//             current_address_id:  // DOMISILI
//                 {
//                     country_id: identitasAlamat.negara_in_ktp == 'Indonesia' ? 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc' : 'a7e1f217-5211-41c5-9e6b-3b84aa8fc4dc',
//                     province_id: identitasAlamat !== undefined ? identitasAlamat.provinsi_in_ktp.id : '',
//                     city_id: identitasAlamat !== undefined ? identitasAlamat.kota_kabupaten_in_ktp.key : '',
//                     postal_code_id: identitasAlamat !== undefined ? identitasAlamat.kode_pos_in_ktp.key : '',
//                     detail: identitasAlamat !== undefined ? identitasAlamat.alamat_domisili : ''
//                 },
//             bank_id: '',
//             bank_account_number: '',
//             photo_url: '',
//             position_id: listPegawaiInfoPosisi,
//             hobby: identitasInfoTambahan.hobi,
//             identity_number: identitas.nip,
//             sex_id: identitas.jenis_kelamin == 'lakiLaki' ? 1 : 2,
//             num_of_children: identitasInfoTambahan.jumlah_anak,

//         },

//         EmployeeFormalEducationHistory : listRiwayatFormalEducationHistory,
//         EmployeeInformalEducationHistory : listRiwayatINFORMALEducationHistory,
//         EmployeeEmergencyContact : listEmegencyContact,
//         EmployeeOrganizationExpHistory: listRiwayatOrganisasi,
//         EmployeeWorkExperienceHistory: listRiwayatPengalamanKerja,
//         EmployeeSkill: listKeahlian,
//         EmployeeFamily: listKeluarga,

//         EmployeeHealthHistory: [

//             {
//                 name: '',
//                 category_id: '',
//                 illness_action_id: '',
//                 frequency: '',
//                 start_date: '',
//                 end_date: ''
//             }
//         ]
//     };

// https://api.abangbisa.com/api/v1/account-management/user/ed6d76fd-7cc5-436e-84b3-2a2e5d9a0a6f/verify-email/flwGilfU-252409dd2ce6dcc39ee63c3659c2f662-FkUxOrUk
