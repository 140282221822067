import React, { Component, useEffect, useState, Fragment } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Popover,
  Fab,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";
import { URL_API } from "../../../../constants/config-api";
import Snackbeer from "../../../../components/Snackbeer";

// import PictInfo from '../assets/images/icon-info-24px.svg';

const DialogCancelPembayaran = (props) => {
  const { classes, isOpenDialog, setOpenDialog, dataDetailCancel } = props;

  const [idInvoiceNumber, setIdInvoiceNumber] = useState("");
  useEffect(() => {
    if (isOpenDialog == true) {
      if (dataDetailCancel !== null) {
        setIdInvoiceNumber(dataDetailCancel.invoice_number);
      }
    }
  }, [isOpenDialog]);

  const handleYakin = () => {
    let userToken = localStorage.getItem("userToken");

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userToken,
    };

    axios.defaults.headers.common = header;

    axios
      .delete(URL_API + `/account-management/payment/${idInvoiceNumber}`)
      .then(function (response) {
        // console.log("Response Original : ", response);

        setOpenDialog(false);
        setModalResponse200(true);
      })
      .catch(function (error) {
        setOpenDialog(false);
        alert("Whoops, something went wrong !");
        // console.log("Error : ", error);
      });
  };

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);

  return (
    <Fragment>
      <Dialog
        open={isOpenDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title-422"
        aria-describedby="alert-dialog-description-422"
      >
        <DialogTitle
          id="alert-dialog-title-422"
          style={{ textAlign: "center" }}
        >
          {/* <img src={PictInfo} alt="info-icon-pict-info" style={{width: 300, height: 100 }} />   */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-422">
            <Typography variant="h6" className={classes.title}>
              Apakah Anda yakin ingin membatalkan pembayaran dengan nomor{" "}
              <i>invoice</i>
              {dataDetailCancel !== null ? (
                <Chip
                  label={
                    <Typography
                      variant="subtitle1"
                      className={classes.langgananInvoice}
                    >
                      INV/ + {idInvoiceNumber}
                    </Typography>
                  }
                  variant="outlined"
                  style={{ marginLeft: 8 }}
                />
              ) : (
                "-"
              )}{" "}
              ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="outlined"
            onClick={() => setOpenDialog(false)}
            color="primary"
            size="small"
            className={classes.title}
          >
            Tidak
          </Button>
          <Button
            variant="contained"
            onClick={handleYakin}
            color="primary"
            size="small"
            className={classes.button}
          >
            Ya
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <Snackbeer
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Berhasil membatalkan pembayaran"
      />
    </Fragment>
  );
};

export default DialogCancelPembayaran;
