import React, { useEffect, useState, useContext } from "react";
import { styles } from "./Style/StyleTimeOff";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Grid,
  Divider,
  Tabs,
  Tab,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import ContextMySchedule from "../MySchedule/Context/ContextMySchedule";

import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";

import { isAuthenticated } from "../MyAttendance/Hook/isAuthenticated";
import TimeOffRequestDialog from "./Components/TimeOffRequestDialog";

import ErrorDialog from "../MyAttendance/Components/ErrorDialog";
import SuccessDialog from "../Account/Components/SuccessDialog";
import DialogProgress from "../Account/Components/DialogProgress";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { id } from "date-fns/locale";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#CE354A", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ViewAttendanceTimeOff = () => {
  const classes = useStyles();
  const context = useContext(ContextMySchedule);

  const [loader, setLoader] = useState(false);
  const [totalTimeOffAllowance, setTotalTimeOffAllowance] = useState(0);
  const [totalTimeOffTaken, setTotalTimeOffTaken] = useState(0);
  const [totalTimeOffRemaining, setTotalTimeOffRemaining] = useState(0);
  const [timeOffRequestDialog, setTimeOffRequestDialog] = useState(false);
  const [timeOffRequestValue, setTimeOffRequestValue] = useState({
    timeOffType: "",
    timeOffScheduleIn: new Date(),
    timeOffScheduleOut: new Date(),
    timeOffDescription: "",
    timeOffFile: [],
  });
  const [timeOffCategory, setTimeOffCategory] = useState([]);
  const [bulan, setBulan] = useState(moment().format("MM"));
  const [tahun, setTahun] = useState(moment().format("YYYY"));
  const [dataRaw, setDataRaw] = useState([]);
  const [dataPending, setDataPending] = useState([]);
  const [dataApproved, setDataApproved] = useState([]);
  const [value, setValue] = useState(0);

  const authData = JSON.parse(localStorage.getItem("status_user_login"));

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [successStatus, setSuccessStatus] = useState("");

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
    getTimeOffQuota();
    fatchHistoryTimeOff();
  }, [bulan, tahun]);

  const fatchHistoryTimeOff = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/request-timeoff/history`,
        {
          params: {
            "options[filter][year]": tahun,
            "options[filter][month]": bulan,
            "options[paging][limit]": 5,
            "options[paging][offset]": 0,
          },
        }
      );

      setDataRaw(response.data.data);
      setDataPending(
        response.data.data.filter((item) => {
          return item.status_approve !== "99";
        })
      );
      setDataApproved(
        response.data.data.filter((item) => {
          return item.status_approve === "99";
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const getTimeOffQuota = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/request-timeoff`
      );

      if (response.status === 200) {
        console.log(response.data.data.cuti_list[0].hasUser.allowance);
        setTotalTimeOffAllowance(
          response.data.data.cuti_list[0].hasUser.allowance
        );
        setTotalTimeOffTaken(
          response.data.data.cuti_list[0].hasUser.allowance -
            response.data.data.cuti_list[0].hasUser.remaining
        );
        setTotalTimeOffRemaining(
          response.data.data.cuti_list[0].hasUser.remaining
        );
        setTimeOffCategory(response.data.data.cuti_list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    if (newValue === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "99";
      });
      setDataApproved(data);
      console.log(data);
    } else if (newValue === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "1";
      });
      setDataPending(data);
      console.log(data);
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    console.log(index);
    if (index === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "99";
      });
      setDataApproved(data);
      console.log(data);
    } else if (index === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "1";
      });
      setDataPending(data);
      console.log(data);
    }
  };

  const handleRequestDialogClose = () => {
    setTimeOffRequestDialog(false);
    setTimeOffRequestValue({
      timeOffType: "",
      timeOffScheduleIn: new Date(),
      timeOffScheduleOut: new Date(),
      timeOffDescription: "",
      timeOffFile: [],
    });
  };

  const verificationRequest = async () => {
    try {
      setLoader(true);
      const body = new FormData();
      body.set("Leave[category_id]", timeOffRequestValue.timeOffType);
      body.set("Leave[user_id]", authData?.user_id);
      body.set(
        "Leave[schedule_in]",
        timeOffRequestValue.timeOffScheduleIn.toISOString().split("T")[0]
      );
      body.set(
        "Leave[schedule_out]",
        timeOffRequestValue.timeOffScheduleOut.toISOString().split("T")[0]
      );
      body.set("Leave[description]", timeOffRequestValue.timeOffDescription);
      body.set("Leave[file]", timeOffRequestValue.timeOffFile);
      body.set("Leave[is_delegation]", 0);
      //body.set("Leave[delegation_user_id]", authData?.user_id);

      const response = await axiosConfig.post(
        `${URL_API}/human-resource/request-timeoff`,
        body
      );

      if (response.status === 200) {
        fatchHistoryTimeOff();
        setTimeOffRequestDialog(false);
        setSuccessStatus("Berhasil melakukan request timeoff");
        setSuccessDialog(true);
      }
    } catch (error) {
      console.log(error);
      setErrorDialog(true);
      setErrorMessage(error.response.data.info.message);
    } finally {
      setLoader(false);
    }
  };

  const diffDate = (date1, date2) => {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    var difference = d2.getTime() - d1.getTime() + 1;
    var days = Math.ceil(difference / (1000 * 3600 * 24));
    if (days <= 0) {
      var days = 1;
    }
    return days;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <div
                style={{
                  position: "fixed",
                  width: "37%",
                  maxWidth: "inherit",
                }}
              >
                <Card className={classes.timeOffQuotaCard}>
                  <CardContent style={{ flex: "1 0 auto" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <div
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <span>Total Jatah Cuti Tahunan</span>
                        </div>
                        <Typography variant="h4" style={{ color: "white" }}>
                          <b>{totalTimeOffAllowance} Hari</b>
                        </Typography>

                        <div
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            marginTop: 10,
                          }}
                        >
                          <span>Cuti Tahunan Terpakai</span>
                        </div>
                        <Typography variant="h4" style={{ color: "white" }}>
                          <b>{totalTimeOffTaken} Hari</b>
                        </Typography>

                        <div
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            marginTop: 10,
                          }}
                        >
                          <span>Sisa Jatah Cuti Tahunan</span>
                        </div>
                        <Typography variant="h4" style={{ color: "white" }}>
                          <b>{totalTimeOffRemaining} Hari</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          src={require("../../../assets/images/calender.png")}
                          alt="calender"
                          width={200}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    height: "5rem",
                    backgroundColor: "#262261",
                    color: "white",
                    marginTop: "1rem",
                  }}
                  onClick={() => {
                    setTimeOffRequestDialog(true);
                  }}
                >
                  <b>Ajukan Time-Off</b>
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={6}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="History Overtime"
                style={{
                  marginTop: "1rem",
                }}
              >
                <Tab label="Pengajuan" {...a11yProps(0)} />
                <Tab label="Terpakai" {...a11yProps(1)} />
              </Tabs>
              <FormControl
                variant="outlined"
                fullWidth
                style={{
                  marginTop: "20px",
                }}
              >
                <MuiPickersUtilsProvider locale={id} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    views={["year", "month"]}
                    variant="outlined"
                    openTo="year"
                    value={moment(tahun + "-" + bulan, "YYYY-MM")}
                    onChange={(v) => {
                      setBulan(moment(v).format("MM"));
                      setTahun(moment(v).format("YYYY"));
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 15,
                      margin: "20px 0px",
                    }}
                  >
                    {dataPending?.map((item, index) => {
                      return (
                        <Card
                          style={{
                            padding: "10px",
                            color: "#221E5B",
                            borderRadius: "10px",
                          }}
                          key={index}
                        >
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <b style={{ color: "#221E5B" }}>
                                    ● {item.leave_category.name}
                                  </b>
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#221E5B",
                                    textAlign: "right",
                                  }}
                                >
                                  {moment(item.created_at).format(
                                    "DD MMMM YYYY"
                                  ) === moment().format("DD MMMM YYYY")
                                    ? "Hari ini"
                                    : moment(item.created_at)
                                        .locale("id")
                                        .format("DD MMMM YYYY")}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography
                              variant="body1"
                              style={{
                                color: "#CE354A",
                                marginLeft: 11,
                              }}
                            >
                              <b>{item.status_approve_name}</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: "#221E5B", marginLeft: 11 }}
                            >
                              Periode :{" "}
                              <b>
                                {diffDate(
                                  moment(item.schedule_in).format("MM/DD/YYYY"),
                                  moment(item.schedule_out).format("MM/DD/YYYY")
                                )}{" "}
                                Hari (
                                {moment(item.schedule_in)
                                  .locale("id")
                                  .format("DD MMMM YYYY")}{" "}
                                s/d{" "}
                                {moment(item.schedule_out)
                                  .locale("id")
                                  .format("DD MMMM YYYY")}
                                )
                              </b>
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: "#221E5B", marginLeft: 11 }}
                            >
                              Deskripsi : <b>{item.description}</b>
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 15,
                      margin: "20px 0px",
                    }}
                  >
                    {dataApproved?.map((item, index) => {
                      return (
                        <Card
                          style={{
                            padding: "10px",
                            color: "#221E5B",
                            borderRadius: "10px",
                          }}
                          key={index}
                        >
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <b style={{ color: "#221E5B" }}>
                                    ● {item.leave_category.name}
                                  </b>
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "#221E5B",
                                    textAlign: "right",
                                  }}
                                >
                                  {moment(item.created_at).format(
                                    "DD MMMM YYYY"
                                  ) === moment().format("DD MMMM YYYY")
                                    ? "Hari ini"
                                    : moment(item.created_at)
                                        .locale("id")
                                        .format("DD MMMM YYYY")}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography
                              variant="body1"
                              style={{
                                color:
                                  item.status_approve !== "99"
                                    ? "#CE354A"
                                    : "#221E5B",
                                marginLeft: 11,
                              }}
                            >
                              <b>{item.status_approve_name}</b>
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: "#221E5B", marginLeft: 11 }}
                            >
                              Periode :{" "}
                              <b>
                                {diffDate(
                                  moment(item.schedule_in).format("MM/DD/YYYY"),
                                  moment(item.schedule_out).format("MM/DD/YYYY")
                                )}{" "}
                                Hari (
                                {moment(item.schedule_in)
                                  .locale("id")
                                  .format("DD MMMM YYYY")}{" "}
                                s/d{" "}
                                {moment(item.schedule_out)
                                  .locale("id")
                                  .format("DD MMMM YYYY")}
                                )
                              </b>
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: "#221E5B", marginLeft: 11 }}
                            >
                              Deskripsi : <b>{item.description}</b>
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </div>
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>

          <TimeOffRequestDialog
            handleClose={handleRequestDialogClose}
            open={timeOffRequestDialog}
            verification={verificationRequest}
            value={timeOffRequestValue}
            setValue={setTimeOffRequestValue}
            timeOffCategory={timeOffCategory}
            loader={loader}
          />

          <ErrorDialog
            open={errorDialog}
            handleClose={handleErrorDialogClose}
            status={errorStatus}
            message={errorMessage}
          />

          <SuccessDialog
            open={successDialog}
            handleClose={handleSuccessDialogClose}
            status={successStatus}
          />

          <DialogProgress
            classes={classes}
            isModalProgress={loader}
            setModalProgress={setLoader}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default ViewAttendanceTimeOff;
