import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import { Toolbar, Typography, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

import DialogError from "../../../../components/DialogError";
import { URL_API } from "../../../../constants/config-api";
import Redirect from "../../../../utilities/Redirect";

const useToolbarStyles = makeStyles((theme) => ({
  root: {},
  highlight:
    //   theme.palette.type === 'light'
    //     ? {
    //         color: theme.palette.secondary.main,
    //         backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    //       }
    //     : {
    //         color: theme.palette.text.primary,
    //         backgroundColor: theme.palette.secondary.dark,
    //       },
    {
      color: "grey",
      backround: "white",
    },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    //   color: theme.palette.text.secondary,
    color: "grey",
  },
  title: {
    // flex: '0 0 auto',
    // marginRight: 57
    fontFamily: "Roboto",
  },
  titleInEnhanceTable: {
    flex: "0 0 auto",
    marginRight: 57,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const { numSelected, selected } = props;

  const handleDeleteMasterUser = () => {
    // alert("Oops, something went wrong !")
    const userToken = localStorage.getItem("userToken");

    if (userToken !== undefined) {
      // console.log("Delete : ", selected);

      if (selected !== undefined) {
        selected.length > 0 &&
          selected.map((item, i) => {
            const header = {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "bearer " + userToken,
            };

            axios.defaults.headers.common = header;

            axios
              .delete(URL_API + `/account-management/user/${item}`)
              .then(function (response) {
                // console.log("Response Original : ", response);
                window.location.reload();
              })
              .catch(function (error) {
                // console.log("Error : ", error.response);

                if (error.response !== undefined) {
                  if (error.response.status === 422) {
                    setErrorStatus(422);

                    if (error.response.data.info !== null) {
                      if (error.response.data.info.errors !== null) {
                        if (error.response.data.info.errors !== undefined) {
                          if (error.response.data.info.errors.length > 0) {
                            setListError(error.response.data.info.errors);
                            setErrorStatus(error.response.status);
                            setOpenDialogError(true);
                          }
                        }
                      }
                    }
                  }
                } else {
                  setTextErrorInformation("Whoops, something went wrong !");
                  setOpenDialogError(true);
                }
              });
          });
      }
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*  
      ```````````````````
      HANDLE DIALOG ERROR

      ```````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
        style={{
          minHeight: 0,
          paddingLeft: 3,
          marginLeft: 7,
          backgroundColor: "transparent",
        }}
      >
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <Tooltip title="Hapus">
              <IconButton
                onClick={() => handleDeleteMasterUser()}
                aria-label="Delete"
              >
                <DeleteIcon style={{ color: "#c1322d" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list">
                {/* <FilterListIcon /> */}
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div className={classes.spacer} />

        <div className={classes.titleInEnhanceTable}>
          {numSelected > 0 ? (
            <Typography
              color="inherit"
              variant="subtitle1"
              className={classes.title}
            >
              {numSelected} Terpilih
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              {/* Pengaturan Role */}
            </Typography>
          )}
        </div>
      </Toolbar>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default EnhancedTableToolbar;
