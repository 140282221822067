import React, { useState } from "react";
import {
  TableRow,
  Box,
  Typography,
  IconButton,
  TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreVert from "@material-ui/icons/MoreVert";
import AccountCircleIcon from "@material-ui/icons/AccountCircleOutlined";

import moment from "moment";

import ImageSmallUser from "../../../../components/ImageSmallUser";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import TruncateNumberCustom from "../../../../utilities/TruncateNumberCustom";
import ResultBar from "../global/ResultBar";
import ProjectBar from "../global/ProjectBar";
import AchievementBar from "../global/AchievementBar";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import { navigate } from "hookrouter";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiTableRow-root": {
      backgroundColor: "#fff",
      margin: 5,
      border: "1px solid #DFDFDF",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderSpacing: "0px 4px",
      borderCollapse: "separate",
    },
    "&.MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  empty: {
    backgroundColor: "#F9F9F9",
    borderBottom: "none",
  },
  titleHead: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "500",
    color: "#333",
    fontSize: 14,
    "&.MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  title555: {
    color: "#555",
  },
  justHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  projectName: {
    fontFamily: "'Lato', sans-serif",
    color: "#333",
    fontWeight: "normal",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      textDecoration: "underline",
    },
  },
}));

const RowPersonalItem = ({ cell, origin }) => {
  const classes = useStyles();

  // ==================================================
  // handle progress bar
  // ==================================================
  const method = cell.project.achievement_calculation_method;
  const _scope_res =
    cell.project.project_scope_results !== null
      ? Number(cell.project.project_scope_results)
      : 0;
  const _schedule_res =
    cell.project.project_schedule_results !== null
      ? Number(cell.project.project_schedule_results)
      : 0;

  // var scope
  let PROGRESS_SCOPE;
  let GAP_SCOPE;
  let TEXT_SCOPE;
  let COLOR_SCOPE_BOLD;
  let COLOR_SCOPE_LIGHT;
  let BG_SCOPE_BOLD;
  let BG_SCOPE_LIGHT;

  // var schedule
  let PROGRESS_SCHEDULE;
  let GAP_SCHEDULE;
  let TEXT_SCHEDULE;
  let COLOR_SCHEDULE_BOLD;
  let COLOR_SCHEDULE_LIGHT;
  let BG_SCHEDULE_BOLD;
  let BG_SCHEDULE_LIGHT;

  // ACHIEVEMNT BAR
  let ACHIEVEMENT;

  switch (cell.project?.achievement_calculation_method) {
    case "SCOPE":
      ACHIEVEMENT = Number(cell.project?.project_scope_results);
      break;
    case "SCHEDULE":
      ACHIEVEMENT = Number(cell.project?.project_schedule_results);
      break;
    case "SCOPE SCHEDULE":
      ACHIEVEMENT = Number(cell.project?.project_progress_results);
      break;
    default:
      ACHIEVEMENT = 0;
  }

  // // console.log("TDLzzz", cell.project);

  if (_scope_res === 0) {
    COLOR_SCOPE_BOLD = "#c4c7c7";
    COLOR_SCOPE_LIGHT = "#c4c7c7";
    BG_SCOPE_BOLD = "#c4c7c7";
    BG_SCOPE_LIGHT = "#c4c7c7";
    PROGRESS_SCOPE = _scope_res;
    TEXT_SCOPE = _scope_res;
    GAP_SCOPE = 1;
  } else if (_scope_res > 0 && _scope_res < 0.85) {
    COLOR_SCOPE_BOLD = "#F16C7D";
    COLOR_SCOPE_LIGHT = "#c4c7c7";
    BG_SCOPE_BOLD = "#F16C7D";
    BG_SCOPE_LIGHT = "#c4c7c7";
    PROGRESS_SCOPE = _scope_res;
    TEXT_SCOPE = _scope_res;
    GAP_SCOPE = 1;
  } else if (_scope_res >= 0.85 && _scope_res < 1) {
    COLOR_SCOPE_BOLD = "#ffc000";
    COLOR_SCOPE_LIGHT = "#c4c7c7";
    BG_SCOPE_BOLD = "#ffc000";
    BG_SCOPE_LIGHT = "#c4c7c7";
    PROGRESS_SCOPE = _scope_res;
    TEXT_SCOPE = _scope_res;
    GAP_SCOPE = 1;
  } else if (_scope_res === 1) {
    COLOR_SCOPE_BOLD = "#74CB80";
    COLOR_SCOPE_LIGHT = "#c4c7c7";
    BG_SCOPE_BOLD = "#74CB80";
    BG_SCOPE_LIGHT = "#c4c7c7";
    PROGRESS_SCOPE = _scope_res;
    TEXT_SCOPE = _scope_res;
    GAP_SCOPE = 1;
  } else if (_scope_res > 1 && _scope_res < 2) {
    COLOR_SCOPE_BOLD = "#518e59";
    COLOR_SCOPE_LIGHT = "#c4c7c7";
    BG_SCOPE_BOLD = "#518e59";
    BG_SCOPE_LIGHT = "#74CB80";
    PROGRESS_SCOPE = _scope_res > 1 ? _scope_res - 1 : _scope_res;
    TEXT_SCOPE = _scope_res;
    GAP_SCOPE = 1;
  } else if (_scope_res > 2) {
    COLOR_SCOPE_BOLD = "#518e59";
    COLOR_SCOPE_LIGHT = "#c4c7c7";
    BG_SCOPE_BOLD = "#518e59";
    BG_SCOPE_LIGHT = "#c4c7c7";
    PROGRESS_SCOPE = _scope_res > 1 ? _scope_res - 1 : _scope_res;
    TEXT_SCOPE = _scope_res;
    GAP_SCOPE = 1;
  }

  if (_schedule_res === 0) {
    COLOR_SCHEDULE_BOLD = "#c4c7c7";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = "#c4c7c7";
    BG_SCHEDULE_LIGHT = "#c4c7c7";
    PROGRESS_SCHEDULE = _schedule_res;
    TEXT_SCHEDULE = _schedule_res;
    GAP_SCHEDULE = 1;
  } else if (_schedule_res > 0 && _schedule_res < 0.85) {
    COLOR_SCHEDULE_BOLD = "#F16C7D";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = "#F16C7D";
    BG_SCHEDULE_LIGHT = "#c4c7c7";
    PROGRESS_SCHEDULE = _schedule_res;
    TEXT_SCHEDULE = _schedule_res;
    GAP_SCHEDULE = 1;
  } else if (_schedule_res >= 0.85 && _schedule_res < 1) {
    COLOR_SCHEDULE_BOLD = "#ffc000";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = "#ffc000";
    BG_SCHEDULE_LIGHT = "#c4c7c7";
    PROGRESS_SCHEDULE = _schedule_res;
    TEXT_SCHEDULE = _schedule_res;
    GAP_SCHEDULE = 1;
  } else if (_schedule_res === 1) {
    COLOR_SCHEDULE_BOLD = "#74CB80";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = "#74CB80";
    BG_SCHEDULE_LIGHT = "#c4c7c7";
    PROGRESS_SCHEDULE = _schedule_res;
    TEXT_SCHEDULE = _schedule_res;
    GAP_SCHEDULE = 1;
  } else if (_schedule_res > 1 && _schedule_res < 2) {
    COLOR_SCHEDULE_BOLD = "#518e59";
    COLOR_SCHEDULE_LIGHT = "#74CB80";
    BG_SCHEDULE_BOLD = "#518e59";
    BG_SCHEDULE_LIGHT = "#74CB80";
    PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
    TEXT_SCHEDULE = _schedule_res;
    GAP_SCHEDULE = 1;
  } else if (_schedule_res > 2) {
    COLOR_SCHEDULE_BOLD = "#518e59";
    COLOR_SCHEDULE_LIGHT = "#518e59";
    BG_SCHEDULE_BOLD = "#518e59";
    BG_SCHEDULE_LIGHT = "#518e59";
    PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
    TEXT_SCHEDULE = _schedule_res;
    GAP_SCHEDULE = 1;
  }

  // // console.log("CELL", cell);

  const handleToProject = () => {
    navigate(`/project/${cell?.project?.id}`);
  };

  return (
    <>
      <TableRow className={classes.root}>
        {origin === "personal" && (
          <TableCell className={classes.root}>
            {cell?.member?.self?.rel?.photo_url === null ? (
              <Box
                className={classes.justHover}
                display="flex"
                alignItems="center"
              >
                <AccountCircleIcon style={{ color: "#888" }} />
                <span
                  style={{ color: "#555", fontSize: 13, marginLeft: 5 }}
                  className={classes.projectName}
                  onClick={handleToProject}
                >
                  {`${cell?.member.first_name} ${cell?.member.last_name}`}
                </span>
              </Box>
            ) : (
              <Box
                className={classes.justHover}
                display="flex"
                alignItems="center"
              >
                <ImageSmallUser
                  item={cell.member?.self?.rel?.photo_url}
                  index={1}
                />
                <span
                  style={{ color: "#555", fontSize: 13, marginLeft: 5 }}
                  className={classes.projectName}
                  onClick={handleToProject}
                >
                  {`${cell?.member?.first_name} ${cell?.member?.last_name}`}
                </span>
              </Box>
            )}
          </TableCell>
        )}
        {origin === "mypersonal" && (
          <TableCell className={classes.root}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="subtitle2"
                className={classes.projectName}
                onClick={handleToProject}
              >
                {TruncateTextCustom(cell?.name, 25)}
              </Typography>
              <Typography className={classes.title555} variant="subtitle2">
                {TruncateTextCustom(
                  `${cell?.owner?.member_first_name} ${cell?.owner?.member_last_name}`,
                  15
                )}
              </Typography>
            </Box>
          </TableCell>
        )}
        <TableCell
          className={classes.root}
          style={{
            borderLeft: "1px solid #D9D9D9",
            width: 100,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" m={0}>
            <Box width={100}>
              <ResultBar item={cell} origin="personal" />
            </Box>
          </Box>
        </TableCell>
        <TableCell className={classes.root} style={{ width: 150 }}>
          <span style={{ color: "#555", fontSize: 14 }}>
            {moment(cell?.start_date).format("DD-MM-YYYY")}
          </span>
        </TableCell>
        <TableCell className={classes.root} style={{ width: 150 }}>
          <span style={{ color: "#555", fontSize: 14 }}>
            {moment(cell?.end_date).format("DD-MM-YYYY")}
          </span>
        </TableCell>
        <TableCell className={classes.root}>
          <AchievementBar
            classes={classes}
            percentage={ACHIEVEMENT}
            origin="list"
          />
        </TableCell>
        <TableCell className={classes.root}>
          {method !== "SCHEDULE" && (
            <ProjectBar
              percentage={PROGRESS_SCOPE}
              gap={GAP_SCOPE}
              text={TEXT_SCOPE}
              bold={COLOR_SCOPE_BOLD}
              light={COLOR_SCOPE_LIGHT}
              bg={BG_SCOPE_BOLD}
              dash={BG_SCOPE_LIGHT}
            />
          )}
        </TableCell>
        <TableCell className={classes.root}>
          {method !== "SCOPE" && (
            <ProjectBar
              percentage={PROGRESS_SCHEDULE}
              gap={GAP_SCHEDULE}
              text={TEXT_SCHEDULE}
              bold={COLOR_SCHEDULE_BOLD}
              light={COLOR_SCHEDULE_LIGHT}
              bg={BG_SCHEDULE_BOLD}
              dash={BG_SCHEDULE_LIGHT}
            />
          )}
        </TableCell>
        <TableCell
          className={classes.root}
          align="right"
          style={{
            width: 30,
          }}
        >
          <IconButton size="small">
            <MoreVert style={{ fontSize: 16, color: "#555" }} />
          </IconButton>
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell className={classes.empty} />
        <TableCell className={classes.empty} />
        <TableCell className={classes.empty} />
        <TableCell className={classes.empty} />
        <TableCell className={classes.empty} />
        <TableCell className={classes.empty} />
        <TableCell
          className={classes.empty}
          align="right"
          style={{
            width: 30,
          }}
        />
      </TableRow> */}
    </>
  );
};

export default RowPersonalItem;
