import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import PictIcon from "../../../../assets/images/Group_3018.png";

const PictLitleIconMA = (props) => {
  return (
    <Box>
      <IconButton
      // style={{
      //     // backgroundColor: '#e8e6e68a',
      //     marginLeft: 8
      // }}
      >
        <img src={PictIcon} />
      </IconButton>
    </Box>
  );
};

export default PictLitleIconMA;
