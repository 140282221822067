import React, { useEffect, useState, useContext } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@material-ui/lab";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import moment from "moment";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextGoalDetail from "../../Goal/Context/ContextGoalDetail";

const ListActivityLog = ({ classes, maId }) => {
  const context = useContext(ContextGoalDetail);

  const [logsCollection, setLogsCollection] = useState([]);

  useEffect(() => {
    axiosConfig
      .get(`${URL_API}/swd/measured-activity-log/${maId}`)
      .then((res) => {
        // console.log("RES", res);
        const result = res.data.data;

        if (res.status === 200) {
          setLogsCollection(result);
        }
      })
      .catch((error) => {
        // console.log("Error", error);
      });
  }, [context.triggerLogMa]);

  return (
    <Box display="flex" justifyContent="flex-start">
      <Timeline align="left">
        {logsCollection.length > 0 &&
          logsCollection.map((item, i) => {
            let ICON;

            if (item.event === "create") {
              ICON = (
                <CheckCircleRoundedIcon
                  style={{ fontSize: 16, color: "#009345" }}
                />
              );
            }
            if (item.event === "update") {
              ICON = (
                <SystemUpdateAltRoundedIcon
                  style={{ fontSize: 16, color: "#0070E2" }}
                />
              );
            }
            if (item.event === "delete") {
              ICON = (
                <CancelRoundedIcon style={{ fontSize: 16, color: "#C8203C" }} />
              );
            }

            // Remarks Configuration
            let TXT_CHNAGE;
            let TXT_EVENT;
            const _remarks = item.remarks;
            let lastIndex = _remarks?.lastIndexOf(" ");

            const getRemarks = _remarks?.substring(0, lastIndex);
            const getLengthRemarks = _remarks?.split(" ").pop();

            if (getLengthRemarks === "1") {
              TXT_CHNAGE = getRemarks;
            } else {
              TXT_CHNAGE = `${getLengthRemarks} Goal Attributes`;
            }

            if (item.event === "create") {
              TXT_EVENT = "Membuat";
            } else if (item.event === "update") {
              TXT_EVENT = "Merubah";
            }

            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent style={{ flex: 0.1 }}>
                  <Typography variant="caption" className={classes.title888}>
                    {moment(item.updated_at).format("DD MMM YY HH:mm")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot>
                    <AccountCircleRoundedIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3}>
                    <Box p={2}>
                      <Box display="flex" alignItems="center">
                        {ICON}
                        <Box mx={1} />
                        <Typography
                          variant="subtitle1"
                          className={classes.title333}
                        >
                          <b>{item.nama} -</b>
                          <span
                            className={classes.title888}
                            style={{ fontSize: 13 }}
                          >
                            {" "}
                            {TXT_EVENT}{" "}
                            {item.event === "create" && (
                              <strong className={classes.title333}>
                                {TXT_CHNAGE}
                              </strong>
                            )}
                            {item.event === "update" && (
                              <strong className={classes.title333}>
                                {TXT_CHNAGE}
                              </strong>
                            )}
                          </span>
                        </Typography>
                      </Box>
                      <Box mt={1.5}>
                        {item.event === "update" && getLengthRemarks !== "1"
                          ? getRemarks?.split(",").map((el, i) => (
                              <Typography
                                key={i}
                                variant="subtitle2"
                                component="p"
                                className={classes.title555}
                              >
                                {el}
                              </Typography>
                            ))
                          : ""}
                      </Box>
                      <Box mt={1}>
                        <Box p={1} borderRadius={5} bgcolor="#eee">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.comment !== null
                              ? item.comment
                              : "Belum ada komentar"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </Box>
  );
};

export default ListActivityLog;
