import React, { useContext, useState } from "react";
import {
  Box,
  Chip,
  Typography,
  Popper,
  Paper,
  ClickAwayListener,
} from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import ContextProject from "../../context/ContextProject";

import Capitalize from "../../../../utilities/Capitalize";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import { FILTER_PROJECT } from "../../context/types";

const theme = createMuiTheme({
  overrides: {
    MuiChip: {
      clickableColorSecondary: {
        "&:hover, &:focus": {
          backgroundColor: "#9b9baf",
        },
        "&:active": {
          backgroundColor: "#83829C",
        },
      },
    },
  },
});

const ListFilterPorject = ({ classes }) => {
  const { projectTDispatch, projectTState, setTriggerProject } =
    useContext(ContextProject);

  const [anchorStatus, setAnchorStatus] = useState(null);
  const [anchorSortName, setAnchorSortName] = useState(null);

  const handleDeleteStatus = () => {
    projectTDispatch({
      type: FILTER_PROJECT,
      payload: {
        filterTheOwner: projectTState.filterTheOwner,
        filterTheStatus: "",
      },
    });

    setTriggerProject(Math.floor(Math.random() * 100));
  };

  const handleOpenStatus = (event) => {
    setAnchorStatus(event.currentTarget);
  };

  const handleOpenSortName = (event) => {
    setAnchorSortName(event.currentTarget);
  };

  const handleCloseAwayStatus = (event) => {
    if (anchorStatus.contains(event.target)) {
      return;
    }
    setAnchorStatus(null);
  };

  const handleCloseAwaySortName = (event) => {
    if (anchorSortName.contains(event.target)) {
      return;
    }
    setAnchorSortName(null);
  };

  const STATUS =
    projectTState.filterTheStatus !== null
      ? projectTState.filterTheStatus.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content;
        })
      : null;

  const RES_STATUS =
    STATUS !== null &&
    STATUS.map((el) => {
      let text;

      switch (el) {
        case "2":
          text = "Approved";
          break;
        case "3":
          text = "Active";
          break;
        case "6":
          text = "Overdue";
          break;
        case "4":
          text = "Completed";
          break;
        default:
          text = "";
      }

      return text;
    });

  const SORTNAME =
    projectTState.sortNameproject === "asc" ? "Ascending" : "Descending";

  // // console.log("STATUS", STATUS);

  return (
    <MuiThemeProvider theme={theme}>
      <Box mr={3} display="flex" alignItems="center">
        {STATUS !== null && (
          <div>
            <Chip
              variant="outlined"
              size="small"
              className={classes.chipFilterSort}
              label={
                <Typography variant="caption">
                  {TruncateTextCustom(RES_STATUS.join(", "), 15)}
                </Typography>
              }
              style={{ marginRight: 8 }}
              onClick={handleOpenStatus}
            />

            <Popper
              open={Boolean(anchorStatus)}
              anchorEl={anchorStatus}
              transition
              disablePortal
              placement="top"
            >
              <ClickAwayListener onClickAway={handleCloseAwayStatus}>
                <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                  <Box px={2} py={1} display="flex" flexDirection="column">
                    {RES_STATUS.map((el, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="caption"
                          style={{
                            color: "#555",
                          }}
                        >
                          - {el}
                        </Typography>
                      );
                    })}
                  </Box>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        )}

        <div>
          <Chip
            variant="outlined"
            size="small"
            className={classes.chipFilterSort}
            label={<Typography variant="caption">Sort Name</Typography>}
            style={{ marginRight: 8 }}
            onClick={handleOpenSortName}
          />

          <Popper
            open={Boolean(anchorSortName)}
            anchorEl={anchorSortName}
            transition
            disablePortal
            placement="top"
          >
            <ClickAwayListener onClickAway={handleCloseAwaySortName}>
              <Paper className={classes.dropdown} style={{ borderRadius: 8 }}>
                <Box px={2} py={1}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#555",
                    }}
                  >
                    {SORTNAME}
                  </Typography>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      </Box>
    </MuiThemeProvider>
  );
};

export default ListFilterPorject;
