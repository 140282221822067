import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
  MenuList,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import classNames from 'classnames';

import AxiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';
// import handleError from "../../../Report/components/global/handleError";
// import DialogError from "../../../../components/DialogError";

import ContextProject from '../../context/ContextProject';
import {
  GROUP_BY_IN_LIST,
  HIDE_TASK_GROUP,
} from '../../context/types';

const AnchorHideTaskGroup = ({ classes }) => {
  const project_id = window.location.pathname.split('/')[2];
  const { projectTState, projectTDispatch, setTriggerTask } =
    useContext(ContextProject);

  const [anchorEl, setAnchorEl] = useState(null);
  const [ownerCollections, setOwnerCollections] = useState([]);

  useEffect(() => {
    if (anchorEl !== null) {
      AxiosConfig.get(
        `${URL_API}/todolist/create?project_id=${project_id}`
      )
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
          }
        })
        .catch((error) => {
          // console.log("Error", error);
        });
    }
  }, [anchorEl]);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleHideTaskGroup = () => {
    if (projectTState.hideTaskGroup) {
      projectTDispatch({
        type: HIDE_TASK_GROUP,
        payload: { hideTaskGroup: false },
      });
    } else {
      projectTDispatch({
        type: HIDE_TASK_GROUP,
        payload: { hideTaskGroup: true },
      });
    }

    setTriggerTask(Math.floor(Math.random() * 100));
    handleClose();
  };

  return (
    <div>
      <IconButton
        size="small"
        variant="outlined"
        startIcon={<FilterListIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleShow}
      >
        <MoreHorizIcon style={{ color: '#555', fontSize: 14 }} />
      </IconButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-end"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: '0 0 0' }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    onClick={handleHideTaskGroup}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      {projectTState?.hideTaskGroup ? (
                        <VisibilityOutlinedIcon
                          style={{
                            color: '#555',
                            fontSize: 14,
                          }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          style={{
                            color: '#555',
                            fontSize: 14,
                          }}
                        />
                      )}
                      <Box mx={1} />
                      <span style={{ color: '#555' }}>
                        {projectTState?.hideTaskGroup
                          ? 'Show Task Group'
                          : 'Hide Task Group'}
                      </span>
                    </Box>
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AnchorHideTaskGroup;
