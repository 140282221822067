import React from "react";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiTableHead-root": {
      boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
      backgroundColor: "#fff",
    },
  },
  titleHead: {
    fontFamily: "'Lato', sans-serif",
    fontWeight: "600",
    color: "#555",
    fontSize: 14,
    "&.MuiTableCell-root": {
      borderBottom: "none",
    },
  },
}));

const TablePersonalHeader = () => {
  const classes = useStyles();

  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell align="left" className={classes.titleHead}>
          Project Name
        </TableCell>
        <TableCell align="center" className={classes.titleHead}>
          Progress
        </TableCell>
        <TableCell align="left" className={classes.titleHead}>
          Start Date
        </TableCell>
        <TableCell align="left" className={classes.titleHead}>
          End Date
        </TableCell>
        <TableCell
          align="center"
          padding="none"
          className={classes.titleHead}
          style={{
            width: 140,
          }}
        >
          Achievement
        </TableCell>
        <TableCell
          align="center"
          padding="none"
          className={classes.titleHead}
          style={{
            width: 140,
          }}
        >
          Scope
        </TableCell>
        <TableCell
          align="center"
          padding="none"
          className={classes.titleHead}
          style={{
            width: 140,
          }}
        >
          Schedule
        </TableCell>
        <TableCell
          align="right"
          className={classes.titleHead}
          style={{
            width: 30,
          }}
        />
      </TableRow>
    </TableHead>
  );
};

export default TablePersonalHeader;
