import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Backdrop,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#f28181",
  },
  fileText: {
    fontFamily: "Roboto",
    fontWeight: "600",
  },
}));

function DialogPreviewImage({
  openDialogPreviewImage,
  setOpenDialogPreviewImage,
  itemType,
  itemId,
  itemName,
  origin,
}) {
  const classes = useStyles();

  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (openDialogPreviewImage === true) {
      setLoading(true);

      if (origin === "Drive") {
        axiosConfig
          .get(`${URL_API}/drive/file/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setImagePreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "EvidenceDrive") {
        axiosConfig
          .get(`${URL_API}/drive/file/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setImagePreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "Gallery") {
        axiosConfig
          .get(`${URL_API}/drive/gallery/goal-result-evidence/${itemId}`, {
            responseType: "blob",
          })
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setImagePreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      } else if (origin === "GalleryMA") {
        axiosConfig
          .get(
            `${URL_API}/drive/gallery/measured-activity-result-evidence/${itemId}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            // // console.log("Response View Content", response);

            if (response.status === 200) {
              setImagePreview(response.data);
            }
            setLoading(false);
          })
          .catch((error) => {
            // console.log("Error", error);
            setLoading(false);
          });
      }
    }
  }, [openDialogPreviewImage]);

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([imagePreview], { type: itemType });

  const handleClosePreview = () => {
    setLoading(false);
    setOpenDialogPreviewImage(false);
  };

  return (
    <>
      {loading ? (
        <Backdrop
          className={classes.backdrop}
          open={loading}
          onClick={handleClosePreview}
        >
          <CircularProgress color="#f28181" />
        </Backdrop>
      ) : (
        <Dialog
          open={openDialogPreviewImage}
          aria-labelledby="customized-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl" //*Default "md"
          style={{ height: "auto", width: "100%" }}
        >
          <DialogTitle id="customized-dialog-title">
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" className={classes.fileText}>
                {itemName}
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setOpenDialogPreviewImage(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <img
              src={imagePreview !== "" && URL.createObjectURL(blobUrlImage)}
              style={{ maxHeight: 700 }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default DialogPreviewImage;
