import React, { useContext } from "react";
import {
  Box,
  Typography,
  MenuList,
  MenuItem,
  Divider,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";

import ContextTask from "../../../Context/ContextTask";
import { SET_CUSTOM_FILTER } from "../../../Context/types";

import icon_task_incomplete from "../../../../../assets/images/SVG/icon_task_incomplete.svg";
import icon_task_priority from "../../../../../assets/images/SVG/icon_task_priority.svg";

const FilterQuick = ({ classes, onCustomFilter, onClose }) => {
  const { todoState, todoDispatch, setTriggerTodo } = useContext(ContextTask);

  const handleFilterIncomplete = () => {
    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: todoState.filterOwner,
        filterStatus: todoState.filterStatus,
        filterPriority: todoState.filterPriority,
        filterCompleted: true,
        ownerId: todoState.ownerId,
        statusId: todoState.statusId,
        priorityId: todoState.priorityId,
        completedId: "1",
      },
    });
    onClose();
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  const handleFilterPriority = () => {
    todoDispatch({
      type: SET_CUSTOM_FILTER,
      payload: {
        filterOwner: todoState.filterOwner,
        filterStatus: todoState.filterStatus,
        filterPriority: true,
        filterCompleted: todoState.filterCompleted,
        ownerId: todoState.ownerId,
        statusId: todoState.statusId,
        priorityId: ["HIGH"],
        completedId: todoState.completedId,
      },
    });
    onClose();
    setTriggerTodo(Math.floor(Math.random() * 100));
  };

  return (
    <div>
      <Box pl={2} p={1}>
        <Typography variant="caption" className={classes.title555}>
          <b>Quick Filters</b>
        </Typography>
      </Box>
      <MenuList role="menu" className={classes.menuList}>
        <MenuItem
          onClick={handleFilterIncomplete}
          className={classes.dropdownItem}
        >
          <Box display="flex" alignItems="center">
            <img
              src={icon_task_incomplete}
              style={{
                marginRight: 7,
              }}
              height={20}
            />
            <span style={{ color: "#555" }}>Incomplete</span>
            {todoState.filterIncomplete && (
              <CheckIcon
                style={{
                  color: "#d64253",
                  fontSize: 14,
                  marginLeft: 10,
                }}
              />
            )}
          </Box>
        </MenuItem>
        <MenuItem
          onClick={handleFilterPriority}
          className={classes.dropdownItem}
        >
          <Box display="flex" alignItems="center">
            <img
              src={icon_task_priority}
              style={{
                marginRight: 7,
              }}
              height={20}
            />
            <span style={{ color: "#555" }}>Priority</span>
            {todoState.filterPriority && (
              <CheckIcon
                style={{
                  color: "#d64253",
                  fontSize: 14,
                  marginLeft: 10,
                }}
              />
            )}
          </Box>
        </MenuItem>
        <Divider className={classes.dropdownDividerItem} />
        <MenuItem onClick={onCustomFilter} className={classes.dropdownItem}>
          <Box display="flex" alignItems="center">
            <TuneRoundedIcon
              fontSize="small"
              style={{ color: "#555", marginRight: 7 }}
            />
            <span style={{ color: "#555" }}>Custom Flter</span>
          </Box>
        </MenuItem>
      </MenuList>
    </div>
  );
};

export default FilterQuick;
