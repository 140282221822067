import React, { Component, useEffect, useState, useCallback } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Breadcrumbs,
  Link,
  IconButton,
  InputBase,
  Divider,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  TablePagination,
  TableHead,
  TableSortLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import StickyFooter from "react-sticky-footer";
import DoneIcon from "@material-ui/icons/Done";
import { useDropzone } from "react-dropzone";

import { styles } from "./Style/StyleRiwayat"; // NEXT GANTI TARO DI COMPONENT INFO PEGAWAI SENDIRI !
import { extractImageFileExtensionFromBase64 } from "../../../utilities/ReusableUtils";

import Redirect from "../../../utilities/Redirect";
import { ToHrEmployeeRiwayatPendidikanINFORMAL } from "../../../constants/config-redirect-url";
import PictPlusUploadDocument from "../../../assets/images/Group_2268.png";

// import Snackber from '../Components/Snackber';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewRiwayatPendidikanFormal = (props) => {
  const { classes } = props;

  /*
        ````````````````````````````````````
        COMPONENT DID MOUNT

        ````````````````````````````````````
    */
  const [listDataPendidikanFormal, setListDataPendidikanFormal] = useState("");

  let dataList = [];
  const employeeDataRiwayatFormal = localStorage.getItem(
    "employee_data_riwayat_formal"
  );
  let employeeDataRiwayatFormalAfterParse =
    employeeDataRiwayatFormal !== null
      ? JSON.parse(employeeDataRiwayatFormal)
      : [];

  useEffect(() => {
    console.log(
      "employeeDataRiwayatFormalAfterParse : ",
      employeeDataRiwayatFormalAfterParse
    );

    setListDataPendidikanFormal(employeeDataRiwayatFormalAfterParse);
  }, []);

  /*
        `````````````````````
        MODAL TAMBAH POSITION

        `````````````````````
    */

  const [modalPendidikanFormal, setModalPendidikanFormal] = useState(false);

  /*
        `````````
        FORM DATA

        `````````
    */

  const [instansi, setInstansi] = useState("");
  const [programStudi, setProgramStudi] = useState("");
  const [jenjang, setJenjang] = useState({
    name: "",
  });

  const [kota, setKota] = useState("");

  const [masaBelajarIN, setBelajarIN] = useState({
    name: "",
  });

  const [masaBelajarOUT, setBelajarOUT] = useState({
    name: "",
  });

  const [statusPelajarChecked, setStatusPelajarChecked] = useState({
    name: false,
  });

  // const [ documentIjazah, setDocumentIjazah ] = useState('');// Change to imageBinaryPreviewUrl

  const handleChangeInstansi = (e) => setInstansi(e.target.value);
  const handleChangeProgramStudi = (e) => setProgramStudi(e.target.value);

  const handleChangeJenjang = (name) => (event) => {
    setJenjang({ ...jenjang, [name]: event.target.value });
  };

  const handleChangeKota = (e) => setKota(e.target.value);

  const handleChangeBelajarIN = (name) => (event) => {
    setBelajarIN({ ...masaBelajarIN, [name]: event.target.value });
  };

  const handleChangeBelajarOUT = (name) => (event) => {
    setBelajarOUT({ ...masaBelajarOUT, [name]: event.target.value });
  };

  const handleChangeCheckboxStatusPelajar = (name) => (event) => {
    setStatusPelajarChecked({
      ...statusPelajarChecked,
      [name]: event.target.checked,
    });
  };

  /* 
       ``````````````````````
       FEATURE UPLOAD IJAZAH 
       
       ``````````````````````
    */
  // const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState(dataIdentitasInfoDasarAfterParse.foto);
  const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState("");
  const [imgSrcExt, setImgSrcExt] = useState();

  const [nameFile, setNameFile] = useState("");

  // const [imgSrc, setImgSrc ] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log("acceptedFiles : ", acceptedFiles);
    setNameFile(acceptedFiles[0].name);

    //*
    const reader = new FileReader();

    reader.onabort = () =>
      // console.log("file reading was aborted");
      (reader.onerror = () =>
        // console.log("file reading has failed");
        (reader.onload = () => {
          // Do whatever you want with the file contents
          //   // console.log("Reader : ", reader)
          const binaryStr = reader.result;
          // console.log("Binary String : ", binaryStr);

          setImageBinaryPreviewUrl(binaryStr);
          setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr));
        }));

    // acceptedFiles.forEach(file => reader.readAsBinaryString(file))
    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  /*
        ```````````````````
        HANDLE POST LANJUT 

        ```````````````````
    */

  // let paramsData = {

  //     instansi : instansi,
  //     program_studi: programStudi,
  //     jenjang: jenjang.name,
  //     kota: kota,
  //     tahun_masuk: masaBelajarIN.name,
  //     tahun_selesai: masaBelajarOUT.name,
  //     status_pelajar: statusPelajarChecked.name == true ? 'Lulus' : 'Belum Lulus',
  //     ijazah: imageBinaryPreviewUrl

  // };

  const handlePOSTLanjut = () => {
    Redirect(ToHrEmployeeRiwayatPendidikanINFORMAL);
  };

  /*
        ```````````````````
        HANDLE SIMPAN DATA

        ```````````````````
    */

  const handleSimpanDataPendidikanFormal = () => {
    let paramsData = {
      instansi: instansi,
      program_studi: programStudi,
      jenjang: jenjang.name,
      kota: kota,
      tahun_masuk: masaBelajarIN.name,
      tahun_selesai: masaBelajarOUT.name,
      status_pelajar:
        statusPelajarChecked.name == true ? "Lulus" : "Belum Lulus",
      ijazah: imageBinaryPreviewUrl,
      name_file: nameFile,
    };

    if (listDataPendidikanFormal.length > 0) {
      // console.log("Run v1");

      const newList = [...listDataPendidikanFormal, paramsData];

      // console.log("newList : ", newList);

      localStorage.setItem(
        "employee_data_riwayat_formal",
        JSON.stringify(newList)
      );
      setListDataPendidikanFormal([...listDataPendidikanFormal, paramsData]);
    } else {
      // console.log("Run v2");

      dataList.push(paramsData);
      localStorage.setItem(
        "employee_data_riwayat_formal",
        JSON.stringify(dataList)
      );
      window.location.reload();
    }

    setModalPendidikanFormal(false);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <Paper
            elevation={1}
            className={classes.paperInfoIdentitas}
            square={true}
          >
            <br />
            <Typography variant="subtitle2" className={classes.titleHeader}>
              <b>Informasi Riwayat</b>
            </Typography>
            <ul>
              <li className={classes.titleHaveDone} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleHaveDone}
                >
                  <b>Kesehatan</b>
                  <DoneIcon
                    style={{
                      color: "green",
                      position: "absolute",
                      marginTop: 1,
                      marginLeft: 8,
                      fontSize: 17,
                    }}
                  />
                </Typography>
              </li>

              <li className={classes.titleActive} style={{ margin: 8 }}>
                <Typography variant="subtitle2" className={classes.titleActive}>
                  <b>Pendidikan formal </b>
                </Typography>
              </li>

              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}
                >
                  <b>Pendidikan informal </b>
                </Typography>
              </li>

              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}
                >
                  <b>Organisasi</b>
                </Typography>
              </li>
              <li className={classes.titleNotActive} style={{ margin: 8 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleNotActive}
                >
                  <b>Pengalaman Kerja</b>
                </Typography>
              </li>
            </ul>
            <br />
          </Paper>
        </Grid>

        <Grid item xs={8} style={{ textAlign: "left " }}>
          <Paper
            elevation={1}
            className={classes.paperInfoDasar}
            square={true}
            style={{ marginRight: 16 }}
          >
            <br />
            <Typography variant="subtitle1" className={classes.titleHeader}>
              <b>Pendidikan Formal</b>
            </Typography>

            <br />
            <Typography
              variant="subtitle2"
              className={classes.titleTambahAnggotaKeluarga}
              onClick={() => setModalPendidikanFormal(true)}
            >
              <b>+ Tambah Pendidikan Formal </b>
            </Typography>

            {listDataPendidikanFormal.length > 0 &&
              listDataPendidikanFormal.map((item, i) => {
                return (
                  <Grid container key={i}>
                    <Grid xs={4}>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <b>{item.name}</b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <b>
                          {item.tahun_masuk + " -"} {item.tahun_selesai}
                        </b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        <b>{item.status_pelajar + " "}</b>
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography
                        variant="subtitle1"
                        className={classes.titleHeader}
                      >
                        <b>
                          {item.instansi + ", "} {item.kota}
                        </b>{" "}
                        &nbsp;&nbsp;
                        <span
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}
                        >
                          Edit
                        </span>{" "}
                        &nbsp;&nbsp;- &nbsp;&nbsp;
                        <span
                          // onClick={() => setModalDeleteAnggotaKeluarga(true)}
                          onClick={() =>
                            localStorage.removeItem(
                              "employee_data_riwayat_formal"
                            )
                          }
                          style={{
                            color: "#cc0707",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                            fontSize: 12,
                          }}
                        >
                          Hapus
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        {item.jenjang.value}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.titleHeader}
                      >
                        {item.name_file}
                      </Typography>

                      <br />
                    </Grid>
                  </Grid>
                );
              })}
            <br />
            <br />
          </Paper>
        </Grid>
      </Grid>

      {/* 

                ````````````````````
                STICKY FOOTER

                ````````````````````
            */}

      <Grid
        container
        spacing={8}
        direction="row"
        justify="center"
        // alignItems="center"
      >
        <Grid item sm={9}></Grid>

        <Grid item sm={1} style={{ textAlign: "right" }}>
          <br />
          <br />
          <br />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.buttonOutlined}
            style={{ margin: 14 }}
            // onClick={() => Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)}
          >
            Keluar
          </Button>
        </Grid>
        <Grid item sm={2} style={{ textAlign: "left" }}>
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{ margin: 14 }}
            disabled={false}
            // onClick={handlePOSTLanjutPART_2}
            onClick={handlePOSTLanjut}
          >
            Lanjut
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={modalPendidikanFormal}
        onClose={() => setModalPendidikanFormal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "left" }}>
          <Typography variant="subtitle1" className={classes.title}>
            <b>Tambah Pendidikan Formal </b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {" "}
            {/* className={classes.list}  */}
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Nama Instansi</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeInstansi}
                value={instansi}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
                // error={infoError == true && npwp == '' ? true : false}
                // helperText={infoError == true && npwp == '' ? "Wajib di isi" : ' '}
                // required={true}
                // name='nip'
                // color='primary'
                // onKeyDown={handleEnterPress}
                // disabled= {isLockedStatusState == 1 ? true : false}
                // fullWidth
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Program Studi</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeProgramStudi}
                value={programStudi}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Jenjang</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-provinsi"
                select
                // label="Pilih Negara : "
                className={classes.textField}
                value={jenjang.name}
                onChange={handleChangeJenjang("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {jenjangs.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Kota</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-bare"
                onChange={handleChangeKota}
                value={kota}
                className={classes.textField}
                // placeholder={'NIP'}
                variant="outlined"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Masa Belajar</b>
                  </Typography>
                }
              />
              <TextField
                id="outlined-select-tahun-in"
                select
                label="Masuk"
                className={classes.textFieldDropDown}
                value={masaBelajarIN.name}
                onChange={handleChangeBelajarIN("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-tahun-out"
                select
                label="Selesai"
                className={classes.textFieldDropDown}
                value={masaBelajarOUT.name}
                onChange={handleChangeBelajarOUT("name")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                variant="outlined"
              >
                {tahuns.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Status Pelajar</b>
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.checkboxStyle}
                control={
                  <Checkbox
                    // className={classes.checkboxStyle}
                    checked={statusPelajarChecked.name}
                    onChange={handleChangeCheckboxStatusPelajar("name")}
                    value="Lulus"
                  />
                }
                label="Lulus"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.titleForm}>
                    <b>Sertifikat</b>
                  </Typography>
                }
              />

              <div {...getRootProps()}>
                <input {...getInputProps()} />

                {imageBinaryPreviewUrl ? (
                  <Typography variant="caption" className={classes.title}>
                    <i>{nameFile}</i>
                  </Typography>
                ) : (
                  <img
                    src={PictPlusUploadDocument}
                    className={classes.pictUpload}
                  />
                )}
              </div>
            </ListItem>
          </List>

          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}
        >
          <Button
            onClick={() => setModalPendidikanFormal(false)}
            variant="outlined"
            size="small"
            className={classes.buttonModalCancel}
          >
            Batal
          </Button>

          <Button
            // onClick={() => Redirect(ToCompletionProfile)}
            // onClick= {() => setModalPendidikanFormal(false)}
            onClick={handleSimpanDataPendidikanFormal}
            variant="contained"
            color="primary"
            size="small"
            className={classes.buttonModal}
          >
            Simpan
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewRiwayatPendidikanFormal);

const jenjangs = [
  {
    id: "8c021783-830e-4ea6-ac8c-752dbbdb71cd",
    value: "SD",
    label: "SD",
  },
  {
    id: "0c6add02-006c-474b-ad8c-1453d9d4a18a",
    value: "SMP",
    label: "SMP",
  },
  {
    id: "f22b7cd2-8567-4fb8-8923-1f2c5bef5fb6",
    value: "SMA/SMK",
    label: "SMA/SMK",
  },
  {
    id: "6dcee3f8-ba76-4d1d-924a-6d4b47895726",
    value: "D2",
    label: "D2",
  },
  {
    id: "3f5fa12d-2635-4b38-a928-c5673a2298b6",
    value: "D3",
    label: "D3",
  },
  {
    id: "2c5de1f5-24f9-4ed5-b492-9e2def308dac",
    value: "S1/D4",
    label: "S1/D4",
  },
  {
    id: "af53898c-e849-4ccb-9d6e-1123b196c1e5",
    value: "S2",
    label: "S2",
  },
  {
    id: "4d42291f-4455-4831-98bc-b0cbb83e9150",
    value: "S3",
    label: "S3",
  },
];

const tahuns = [
  {
    value: 1991,
    label: 1991,
  },
  {
    value: 1992,
    label: 1992,
  },
  {
    value: 1993,
    label: 1993,
  },
  {
    value: 1994,
    label: 1994,
  },
  {
    value: 1995,
    label: 1995,
  },
  {
    value: 1996,
    label: 1996,
  },
  {
    value: 1997,
    label: 1997,
  },
  {
    value: 1998,
    label: 1998,
  },
  {
    value: 1999,
    label: 1999,
  },
  {
    value: 2005,
    label: 2005,
  },
  {
    value: 2006,
    label: 2006,
  },
  {
    value: 2007,
    label: 2007,
  },
  {
    value: 2008,
    label: 2009,
  },
  {
    value: 2010,
    label: 2010,
  },
  {
    value: 2011,
    label: 2011,
  },
  {
    value: 2012,
    label: 2012,
  },
  {
    value: 2013,
    label: 2013,
  },
  {
    value: 2014,
    label: 2014,
  },
  {
    value: 2015,
    label: 2015,
  },
  {
    value: 2016,
    label: 2016,
  },
  {
    value: 2017,
    label: 2017,
  },
  {
    value: 2018,
    label: 2018,
  },
  {
    value: 2019,
    label: 2019,
  },
  {
    value: 2020,
    label: 2020,
  },
  {
    value: 2021,
    label: 2021,
  },
  {
    value: 2022,
    label: 2022,
  },
  {
    value: 2023,
    label: 2023,
  },
  {
    value: 2024,
    label: 2024,
  },
  {
    value: 2025,
    label: 2025,
  },
  {
    value: 2026,
    label: 2026,
  },
  {
    value: 2027,
    label: 2027,
  },
];
