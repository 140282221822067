import React, { useState, useReducer } from "react";
import ContextTask from "./ContextTask";

import { initialTodos, todoReducer } from "./reducer/todoReducer";

const ContextGlobalTask = (props) => {
  // ========== |REDUCER| ==========
  const [todoState, todoDispatch] = useReducer(todoReducer, initialTodos);

  const [triggerTodo, setTriggerTodo] = useState(null);
  const [triggerAttachment, setTriggerAttachment] = useState(null);
  const [selected, setSelected] = useState([]);

  return (
    <ContextTask.Provider
      value={{
        triggerTodo,
        setTriggerTodo,
        triggerAttachment,
        setTriggerAttachment,
        selected, setSelected,
        todoState,
        todoDispatch,
      }}>
      {props.children}
    </ContextTask.Provider>
  );
};

export default ContextGlobalTask;
