import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";

import { navigate } from "hookrouter";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextAllMA from "../context/AllMaContext";
import handleError from "../../../Report/components/global/handleError";

import DialogError from "../../../../components/DialogError";

const DialogArchive = ({
  classes,
  isOpenDialogArchive,
  setOpenDialogArchive,
  item,
}) => {
  const context = useContext(ContextAllMA);

  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleArchive = () => {
    setLoading(true);

    const data = {
      name: item.name,
      entity_type: "ma",
      entity_list: [
        {
          name: item.name,
          entity_id: item.id,
          owner_id: item.owner_id,
        },
      ],
    };

    // // console.log("Data Archive", data);

    AxiosConfig.post(`${URL_API}/swd/archived/store`, data)
      .then((res) => {
        // console.log("RES Archive", res);

        if (res.status === 200) {
          setOpenDialogArchive(false);
          context.setMessages("Berhasil mengarispkan MA");
          context.setModalResponse200(true);
          navigate("/archive");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={isOpenDialogArchive}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.title333}>
          <b>Archive File</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 550, textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title333}>
          Apakah Anda yakin ingin mengarsip File (<b>{item.name}</b>)?
        </Typography>
        <Typography variant="subtitle2" className={classes.title888}>
          File yang di archive hanya bisa dilihat pada menu archive
        </Typography>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogArchive(false)}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleArchive}
        >
          {loading ? "Loading..." : "Archive"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogArchive;
