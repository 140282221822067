import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  HighlightOff as HighlightOffIcon,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DateRangeIcon from '@material-ui/icons/DateRange';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import AxiosConfig from '../../../../constants/config-axios';
import { URL_API } from '../../../../constants/config-api';
import handleError from '../../../Report/components/global/handleError';
import DialogError from '../../../../components/DialogError';
import ContextProject from '../../context/ContextProject';
import SliderTwoSide from '../global/SliderTwoSide';

import DialogRemarksLog from '../../../../components/DialogRemarksLog';
import DialogEditDuplicateProject from './DialogEditDuplicateProject';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

const DialogDuplicateProject = ({
  classes,
  project,
  openDuplicate,
  setOpenDuplicate
}) => {
  const { setTriggerProject } = useContext(ContextProject);
  const status_login = localStorage.getItem('status_user_login');
  const user = JSON.parse(status_login);

  const [scope, setScope] = useState(50);
  const [schedule, setSchedule] = useState(50);
  const [projectName, setProjectName] = useState('');
  const [owner, setOwner] = useState(null);
  const [startDate, setStartDate] = useState('');

  const [loadDuplicate, setLoadDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taskDuplicate, setTaskDuplicate] = useState(false);
  const [maDuplicate, setMaDuplicate] = useState(false);
  const [openEditDetail, setOpenEditDetail] = useState(false);

  //collections
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState([]);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  useEffect(() => {
    if (openDuplicate) {
      setLoadDuplicate(false);

      // console.log("PROJJ", project);

      setProjectName(project.name);
      setOwner(project?.owner);

      AxiosConfig.get(`${URL_API}/swd/goal/project/${project.id}:duplicate`)
        .then((res) => {
          // console.log("RES dup PRo", res);
          const result = res.data.data;

          setOwners(result.ownerCollections);
          setStartDate(project?.start_date);

          if (
            result.project?.achievement_calculation_method === 'SCOPE SCHEDULE'
          ) {
            setSchedule(50);
            setScope(50);
          }

          if (result.project?.achievement_calculation_method === 'SCOPE') {
            setSchedule(100);
            setScope(0);
          }

          if (result.project?.achievement_calculation_method === 'SCHEDULE') {
            setSchedule(0);
            setScope(100);
          }

          setMembers(result?.project_member);
          setLoadDuplicate(false);
        })
        .catch((error) => {
          setLoadDuplicate(false);
          // console.log("error", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openDuplicate]);

  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setOwner(data);
    }
  };

  const memberList = members.length > 0 ? members.map((el) => el.id) : null;

  const data = {
    Goal: {
      name: projectName,
      owner_id: owner?.id,
      input_method_id: '1',
      achievement_type_id: '1',
      calculation_method_id: '3',
      value_type_id: '2',
      period_id: null,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: project.end_date,
      start_value: project.start_value,
      target_value: project.target_value,
      target_range_value: null,
      description: project.description,
      time_frame_id: project.time_frame_id,
      target_type_id: '1',
      is_evidence_required: '0',
      goal_category: 'PROJECT',
      achievement_calculation_method:
        Number(scope) === 0
          ? 'SCHEDULE'
          : Number(schedule) === 0
          ? 'SCOPE'
          : 'SCOPE SCHEDULE',
      project_members: Object.assign({}, memberList),
      project_scope_weighting: scope,
      project_schedule_weighting: schedule,
      exclude_weekend: project.project?.exclude_weekend,
      goal_id_master: project.id,
      with_ma: maDuplicate,
      with_task: taskDuplicate
    }
  };

  const handleDuplicateProject = () => {
    setLoading(true);

    let device_os = 'Not known';
    if (navigator.appVersion.indexOf('Win') !== -1) device_os = 'Windows OS';
    if (navigator.appVersion.indexOf('Mac') !== -1) device_os = 'Mac OS';
    if (navigator.appVersion.indexOf('X11') !== -1) device_os = 'UNIX OS';
    if (navigator.appVersion.indexOf('Linux') !== -1) device_os = 'Linux OS';

    let OSName = 'Unknown';
    if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1)
      OSName = 'Windows 10';
    if (window.navigator.userAgent.indexOf('Windows NT 6.3') != -1)
      OSName = 'Windows 8.1';
    if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1)
      OSName = 'Windows 8';
    if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1)
      OSName = 'Windows 7';
    if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1)
      OSName = 'Windows Vista';
    if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1)
      OSName = 'Windows XP';
    if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1)
      OSName = 'Windows 2000';
    if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac/iOS';
    if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
    if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';

    // // console.log("DATA Project", data);

    AxiosConfig.post(`${URL_API}/swd/goal`, data)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: 'goal',
              event: 'create',
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ''
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: '',
              device_version: OSName,
              long: '',
              lat: '',
              entity_id: result?.id,
              entity_name: result?.name,
              remarks: `Project Goal (${result?.name}) 1`,
              comment: ''
            }
          };

          setDataRemarks(dataLog);
          setOpenRemarksLog(true);

          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("error", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleEditDetail = () => {
    setOpenEditDetail(true);
  };

  // // console.log("IWNER", owner);

  return (
    <Dialog open={openDuplicate} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Duplicate Project</Typography>

          <IconButton onClick={() => setOpenDuplicate(false)}>
            <HighlightOffIcon fontSize="default" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box bgcolor="#eee" borderRadius={5} p={2} px={3}>
          <Grid container>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Nama Project</b>
              </Typography>
              <Typography variant="caption">{project.name}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Owner</b>
              </Typography>
              <Typography variant="caption">{`${project.owner?.member_first_name} ${project.owner?.member_last_name}`}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Start Date</b>
              </Typography>
              <Typography variant="caption">
                {moment(project.start_date).format('DD MMMM YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <Box my={2} />
          <Grid container>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Deskripsi</b>
              </Typography>
              <Typography variant="caption">
                {project.description === null ? '-' : project.description}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>Exclude Weekend</b>
              </Typography>
              <Typography variant="caption">
                {project.project?.exclude_weekend === 1 ? 'Ya' : 'Tidak'}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="subtitle2">
                <b>End Date</b>
              </Typography>
              <Typography variant="caption">
                {moment(project.end_date).format('DD MMMM YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box mt={0.5}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              size="small"
              startIcon={<EditOutlinedIcon />}
              style={{ textTransform: 'capitalize', fontWeight: '600' }}
              onClick={handleEditDetail}
            >
              Edit Details
            </Button>
          </Box>

          <Box>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  <b>Nama Project</b>
                </Typography>
                <Box my={0.5} />
                <TextField
                  type="text"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={projectName}
                  placeholder="Add a title"
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  <b>Owner</b>
                </Typography>
                <Box my={0.5} />
                <Autocomplete
                  id="combo-box-demo"
                  options={owners}
                  value={owner}
                  getOptionLabel={(option) =>
                    `${option.member_first_name} ${option.member_last_name}`
                  }
                  onChange={(e, owner) => handleChooseOwner(e, owner)}
                  noOptionsText="Tidak ada owner"
                  popupIcon={
                    <KeyboardArrowDownIcon style={{ color: '#C8203C' }} />
                  }
                  renderInput={(params) => (
                    <TextField
                      style={{ fontSize: 13 }}
                      {...params}
                      fullWidth
                      label="Select owner"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  <b>Start Date</b>
                </Typography>
                <Box my={0.5} />
                <MuiPickersUtilsProvider utils={LocalizedUtils}>
                  <DatePicker
                    autoOk
                    value={startDate}
                    onChange={setStartDate}
                    animateYearScrolling
                    variant="inline" // dialog, static, inline
                    disableToolbar={false}
                    format="DD MMMM YYYY"
                    placeholder="Pick your start date"
                    inputVariant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small">
                            <DateRangeIcon style={{ color: '#C8203C' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography variant="subtitle2" className={classes.title333}>
              <b>Achievement Factor</b>
            </Typography>
            <SliderTwoSide
              classes={classes}
              scope={scope}
              schedule={schedule}
              onScope={setScope}
              onSchedule={setSchedule}
            />
          </Box>

          <Box mt={2} display="flex" alignItems="center">
            <Box>
              <Typography variant="subtitle2" className={classes.title333}>
                <b>Duplicate Project Task</b>
              </Typography>
              <FormControlLabel
                label={
                  <Typography variant="caption" className={classes.title333}>
                    Duplicate Task from this Project
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={taskDuplicate}
                    checkedIcon={
                      <CheckBoxRoundedIcon
                        style={{ color: '#0F68D2', fontSize: 18 }}
                      />
                    }
                    icon={
                      <CheckBoxOutlineBlankRoundedIcon
                        style={{ color: '#0F68D2', fontSize: 18 }}
                      />
                    }
                    onClick={(e) => setTaskDuplicate((prev) => !prev)}
                  />
                }
              />
            </Box>

            <Box>
              <Typography variant="subtitle2" className={classes.title333}>
                <b>Duplicate MA</b>
              </Typography>
              <FormControlLabel
                label={
                  <Typography variant="caption" className={classes.title333}>
                    Duplicate MA from this Project
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={maDuplicate}
                    checkedIcon={
                      <CheckBoxRoundedIcon
                        style={{ color: '#0F68D2', fontSize: 18 }}
                      />
                    }
                    icon={
                      <CheckBoxOutlineBlankRoundedIcon
                        style={{ color: '#0F68D2', fontSize: 18 }}
                      />
                    }
                    onClick={(e) => setMaDuplicate((prev) => !prev)}
                  />
                }
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 16
        }}
      >
        <Box display="flex" flexDirection="column">
          {loading && maDuplicate && (
            <Typography
              variant="caption"
              className={classes.title}
              style={{ color: '#d64253', fontSize: 10 }}
            >
              Creating Measured Activity...
            </Typography>
          )}
          {loading && taskDuplicate && (
            <Typography
              variant="caption"
              className={classes.title}
              style={{ color: '#d64253', fontSize: 10 }}
            >
              Creating Task Project...
            </Typography>
          )}
          {loading && (
            <Typography
              variant="caption"
              className={classes.title}
              style={{ color: '#d64253', fontSize: 10 }}
            >
              Creating Personal Project...
            </Typography>
          )}
          {loading && (
            <Typography
              variant="caption"
              className={classes.title}
              style={{ color: '#d64253', fontSize: 10 }}
            >
              Creating Project Goal...
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={() => setOpenDuplicate(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            size="small"
            variant={loading ? 'contained' : 'outlined'}
            className={classes.button1}
            disabled={loading}
            onClick={handleDuplicateProject}
          >
            {loading ? 'Creating Project...' : 'Create'}
          </Button>
        </Box>
      </DialogActions>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenDuplicate}
        onTriggerRemarks={setTriggerProject}
        editRemarks={true}
      />

      <DialogEditDuplicateProject
        classes={classes}
        openEditDetail={openEditDetail}
        setOpenEditDetail={setOpenEditDetail}
        goal={data.Goal}
        memberz={members}
        owners={owners}
        theOwner={owner}
        with_ma={maDuplicate}
        with_task={taskDuplicate}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDuplicateProject;
