import { fade, createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Lato"].join(","),
    subtitle2: {
      fontWeight: 600,
    },
  },
});

export const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  layoutMarginLeftAndTop: {
    marginLeft: 72,
    marginTop: 56,
  },
  layoutMarginTop: {
    // marginLeft: 72,
    marginTop: 56,
  },
  layoutMarginTopNew: {
    marginTop: theme.spacing(10),
  },
  layoutMarginLeftList: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  title333: {
    color: "#333",
    fontWeight: "normal",
    fontFamily: "'Lato', sans-serif",
  },
  title444: {
    color: "#4D4D4D",
    fontWeight: "normal",
    fontFamily: "'Lato', sans-serif",
  },
  title555: {
    color: "#555",
    fontWeight: "normal",
    fontFamily: "'Lato', sans-serif",
  },
  title888: {
    color: "#888",
    fontWeight: "normal",
    fontFamily: "'Lato', sans-serif",
  },
  titleAttachment: {
    color: "#555",
    fontFamily: "'Lato', sans-serif",
    "&:hover": {
      opacity: 0.8,
      cursor: "pointer",
    },
  },
  button: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    padding: `4px 16px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 5,
    textTransform: "capitalize",
    padding: `4px 16px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  button2: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 5,
    textTransform: "capitalize",
    padding: `4px 16px`,
    backgroundColor: "#fff",
    border: "1px solid #B1B1B1",
    color: "#5B5B5B",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  buttonDisabled: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    color: "grey",
    fontWeight: "bold",
  },
  buttonOutlined: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },
  buttonLabel: {
    fontFamily: "'Lato', sans-serif",
    textTransform: "capitalize",
    padding: `3px 10px`,
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    color: "#4D4D4D",
    fontSize: 13,
    "&:hover": {
      backgroundColor: "#5e5e5e",
    },
  },
  btnCancelUpload: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 3,
    backgroundColor: "#ffcccc",
    border: 0,
    color: "#d1354a",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: "700",
    padding: `1px 0`,
    "&:hover": {
      backgroundColor: "#ffe5e5",
    },
  },
  title: {
    fontFamily: "'Lato', sans-serif",
  },

  titleTextField: {
    fontFamily: "'Lato', sans-serif",
    width: 72,
  },

  titleTextFieldNote: {
    fontFamily: "'Lato', sans-serif",
  },

  titleListChoose: {
    fontFamily: "'Lato', sans-serif",
    cursor: "pointer",
    fontWeight: "bold",
  },
  titleListChooseAktif: {
    fontFamily: "'Lato', sans-serif",
    color: "#d6d3d3",
    cursor: "pointer",
  },

  timeFrameIcon: {
    fontFamily: "'Lato', sans-serif",
    color: "grey", //#4aa9fb
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
    // backgroundColor: '#cfe8fd'
  },
  timeFrameIcontDateRangeText: {
    fontFamily: "'Lato', sans-serif",
    color: "#4aa9fb",
    textTransform: "capitalize",
    backgroundColor: "#edf6ff",
  },

  timeFrameIconInModal: {
    fontFamily: "'Lato', sans-serif",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      color: "#edcfd8", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },

  /*
        ```````
        SEARCH

        ```````
    */
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // borderRadius: '5',
    backgroundColor: fade(theme.palette.common.black, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.35),
    },

    // backgroundColor: grey,
    //     '&:hover': {
    //         backgroundColor: green,
    //     },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
    fontFamily: "'Lato', sans-serif",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    color: "grey",
    // color: '#cc0707'
  },
  /*
        ````````````````````````````
        PAPER, CIRCULAR PROGRESS BAR

        ````````````````````````````        
    */
  paperList: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
  },
  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderBottom: "0.5em solid red",
    // borderLeft: '0.1em solid #c3c0c0de',
  },
  circularProgressBar: {
    width: 65,
    height: 65,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  /*
        ````````````
        MODAL CREATE 

        ````````````
    */
  textField: {
    minWidth: 425,
    [theme.breakpoints.only("xs")]: {
      minWidth: 200,
    },
  },
  textFieldTask: {
    minWidth: 425,
    [theme.breakpoints.only("xs")]: {
      minWidth: 200,
    },
  },

  /*
        `````````````````````````````
        FOTO REAL USER NAME, USERNAME

        `````````````````````````````
    */
  userRealFoto: {
    // margin: 10,
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  imageAvatar: {
    width: 50,
    height: 40,
  },

  /*
        ````
        TAB 

        ````
    */
  tabList: {
    borderWidth: 1,
    paddingLeft: 0,
    borderColor: "#d9dada",
    borderStyle: "solid",
  },
  tabListWithoutBorder: {
    borderWidth: 0,
    paddingLeft: 0,
    borderColor: "#d9dada",
    borderStyle: "solid",
  },

  /*

        ``````````
        DATE RANGE

        ``````````
    */
  dateRangePerbaruiSampul: {
    fontFamily: "'Lato', sans-serif",
    color: "grey",
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
  },

  /*

        ````````````
        YOU - ACTIVE

        ````````````
    */
  moreDetail: {
    fontFamily: "'Lato', sans-serif",
    color: "black", //#4aa9fb
    textTransform: "capitalize",
    fontSize: 11,
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 3,
    paddingLeft: 3,
    backgroundColor: "yellow",
  },

  /*
        ````````````````````````
        MODAL CREATE GOAL WIZARD

        ````````````````````````
    */
  colorIconCancel: {
    color: "red",
  },
  colorPrimaryAppbar: {
    backgroundColor: "white",
  },
  moreDetailForMoreVert: {
    fontFamily: "'Lato', sans-serif",
    // color: 'black', //#4aa9fb
    textTransform: "capitalize",
    fontSize: 11,
    cursor: "pointer",
    // paddingTop:0,
    // paddingBottom:0,
    // paddingRight: 3,
    // paddingLeft: 3,
    // backgroundColor: 'yellow'
  },

  rootTable: {
    width: "100%",
    overflowX: "auto",
  },

  table: {
    minWidth: 650,
  },
  topBotomZero: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  /*
        ````````````````
        BADGE TYPOGRAPHY

        ````````````````
    */
  badgeGreen: {
    backgroundColor: "green",
  },

  badgeRed: {
    backgroundColor: "red",
  },

  /*
        ````````````````````````
        TOOLBAR PAGINATION TABLE

        ````````````````````````
    */

  toolbar: {
    backgroundColor: "transparent",
  },

  /*
        `````````````
        ARROW TOOLTIP

        `````````````
    */

  tooltipArrow: {
    color: "magenta",
  },

  backgroundColorActive: {
    backgroundColor: "magenta",
  },

  backgroundColorNotActive: {
    backgroundColor: "grey",
  },

  backgroundColorActiveSecond: {
    backgroundColor: "cyan",
  },

  backgroundColorNotActiveSecond: {
    backgroundColor: "grey",
  },

  backgroundColorActiveThird: {
    backgroundColor: "red",
  },

  backgroundColorNotActiveThird: {
    backgroundColor: "black",
  },

  // ========================================
  // Rounded Icon
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    border: "1px solid #888",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",

    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#106ba3", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3", // #106ba3 #b1354a
    },
  },
  completedIcon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    border: "1px solid #888",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23888'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },

  // ========================================
  // Radio Styled
  // ========================================
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconRadio: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    padding: 0,
  },
  checkedIconRadio: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1354a",
    },
  },

  // ========================================
  // TABLE TODO NEW
  // ========================================
  shadowTable: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  dataArea: {
    borderRadius: 5,
    boxShadow: "0px 4px 10px 0px rgba(0,0,0,0.1)",
  },
  titleHead: {
    color: "#333",
    fontSize: 14,
    borderBottom: "1.3px solid #aaa",
  },
  justHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  todoName: {
    color: "#333",
    fontWeight: "normal",
    fontFamily: "'Lato', sans-serif",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      textDecoration: "underline",
    },
  },
  iconSelect: {
    fill: "#C8203C",
  },
  notes: {
    minHeight: 200,
  },
  uploadContainer: {
    textAlign: "center",
    padding: 15,
    border: `2px dashed #DFDFDF`,
    color: "#808080",

    marginBottom: 20,
    marginTop: 10,

    "&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },
  uploadBtn: {
    color: "#00ACE6",
    backgroundColor: "rgba(0, 172, 230, 0.1)",
    borderRadius: 5,
    padding: "10px, 3px",
    border: "none",
    fontSize: 13,
    fontWeight: "500",
    textTransform: "capitalize",
    "&:hover": {
      opacity: 0.9,
    },
  },
  popperClose: {
    pointerEvents: "none",
  },
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    top: "100%",
    zIndex: "1000",
    minWidth: "160px",
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
  },
  popperResponsive: {
    zIndex: "1200",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black",
    },
  },
  menuList: {
    padding: "0",
  },
  dropdownItem: {
    fontWeight: "300",
    lineHeight: "1.5em",
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "fit-content",
    color: "#333",
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    height: "1px",
    overflow: "hidden",
  },
  statusItem: {
    fontSize: 12,
    borderRadius: 15,
    padding: "3px 7px",
    width: 75,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableRow: {
    backgroundColor: "#ffffff",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#f1f8ff",
    },
  },
  tableRowGrey: {
    backgroundColor: "#f8f8f8",
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#daf7fe",
    },
  },
});
