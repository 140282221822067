import React, { useEffect, useState, useContext } from 'react';
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  withStyles
} from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  CssBaseline,
  Avatar,
  Badge,
  Chip
} from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import AccountCircle from '@material-ui/icons/AccountCircle';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PollRoundedIcon from '@material-ui/icons/PollRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SpeedIcon from '@material-ui/icons/Speed';
import PeopleIcon from '@material-ui/icons/People';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ArchiveIcon from '@material-ui/icons/Archive';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Home, Mail } from '@material-ui/icons';

import { navigate } from 'hookrouter';

import ContextCompanySetting from '../screen/AM/CompanySetting/Context/ContextCompanySetting';

import DialogHelp from '../components/DialogHelp';
import ImageSmallUser from '../components/ImageSmallUser';

import { StyledMenu, StyledMenuItem } from '../components/StyledMenuDropdown';

import ImageDashboardHome from '../assets/images/Subtraction_3.png';

import IconMenuOffCanvass from '../assets/images/Group_1923.png';
import IconMenuOffCanvassGrey from '../assets/images/SVG/Group_709.svg';
import { lightBlue, green } from '@material-ui/core/colors';

import axiosConfig from '../constants/config-axios';

import { URL_API } from '../constants/config-api';
import { styles } from './Style/StyleHeaderGoal';
import DialogError from '../components/DialogError';

// import { version } from "../../package.json";

import {
  ToCompanySetting,
  ToDashboard,
  ToUserManagement,
  ToLogin,
  ToEmptyStateGeneral,
  ToNewSO,
  To7wdPeriode,
  ToGoal,
  ToGoalDetail,
  ToGoalDetailALL,
  ToTask,
  ToAllMA,
  ToDrive,
  ToReports,
  ToArchive,
  ToAttendance,
  ToAttendanceSetting,
  ToAttendanceReportAdmin,
  ToTimeOff,
  ToTimeOffSetting,
  ToTimeOffCategory,
  ToAttendanceListRequest,
  ToOvertime,
  ToOvertimeSetting,
  ToException,
  ToProject,
  ToPlayground,
  ToAttendanceDashboard,
  ToMyAttendance,
  ToHistoryClockInOut,
  ToHistoryCheckInOut,
  ToCheckInCheckOut,
  ToAttendanceReport,
  ToProfile,
  ToMySchedule,
  ToAttendanceTimeOff,
  ToAttendanceOvertime,
  ToRequestAttendance,
  ToTeam,
  ToInbox,
  ToProfilePresence,
  ToProfileAbsence,
  ToProfileChangePin
} from '../constants/config-redirect-url';

import handleError from '../screen/Report/components/global/handleError';
import ListNotification from './notification/ListNotification';
import ActionNotification from './notification/ActionNotification';

import { ToNewSoTreeViewOrgChart } from '../screen/SO/Constants/config-navigate-url-with-hookrouter';
import DialogNotifDetail from '../components/DialogNotifDetail';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff', //#cc0707, #c62828
      light: '#fff',
      dark: '#fff',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: '#fff'
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#fff'
        // backgroundColor: '#d6d6d6'
      }
    },
    MuiButton: {
      text: {
        color: 'white'
      },
      textSecondary: {
        color: 'white',
        fontFamily: 'Roboto'
      }
    },
    MuiDrawer: {
      root: {
        backgroundColor: lightBlue
      },
      paper: {
        backgroundColor: green
      },

      paperAnchorLeft: {}
    },
    MuiListItemIcon: {
      root: {
        color: 'white'
      }
    },
    MuiBadge: {
      root: {
        color: '#d1354a'
      }
    }
  }
});

const ListItemNew = withStyles({
  root: {
    '&$selected': {
      backgroundColor: '#52507f',
      borderLeft: '4px solid #a9a7bf',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&$selected:hover': {
      backgroundColor: '#3d3a6f',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: '#68668f',
      color: 'white',
      '& .MuiListItemIcon-root': {
        color: 'white'
      }
    }
  },
  selected: {}
})(ListItem);

const useStyles = makeStyles(styles);

const HeaderGOAL = () => {
  const classes = useStyles();
  const pathName = window.location.pathname.split('/')[1];
  const contextCompanySetting = useContext(ContextCompanySetting);
  const getStatusLogin = localStorage.getItem('status_user_login');
  const getPreviewSo = localStorage.getItem('data_preview_so');
  const user =
    pathName === 'new-so-treeview-org-chart' && getPreviewSo !== null
      ? JSON.parse(getPreviewSo)
      : JSON.parse(getStatusLogin);
  const statusUser = user?.membership_status_id;

  const group_id = '3321d61a-c03d-49b2-acbe-79250cef5a6e'; // MGI
  const group_id2 = '012b2303-36c9-427c-934a-9ae74eb54602'; // Amanah
  const group_id3 = 'fbccf940-0c15-4852-afd6-b6c080709052'; // Urun RI
  const group_id4 = '0c7de634-4435-4c2c-8602-b3321c0f757f'; // Irham
  const group_id5 = 'b041a453-3067-4c46-829f-80b8cf1e9771'; // Lucky Cell
  const group_id6 = '1a92f7f0-079c-43da-8e94-176136b45961'; // Riyadh Aqiqah
  const group_id7 = '2a780aaf-c51f-4a0c-9e31-bff3a8adb5e7'; // Decoman Gis

  const group_ids = [
    group_id,
    group_id2,
    group_id3,
    group_id4,
    group_id5,
    group_id6,
    group_id7
  ];

  /* 
        ````````````````````
        TO KNOW CURRENT PAGE
        
        ````````````````````
    */
  const [inisateLabel, setInisisateLabel] = useState('');

  const currentLocation = window.location.pathname;

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */
  const userToken = localStorage.getItem('userToken');
  const [userTokenState, setUserTokenState] = useState('');

  const [fotoQuery, setFotoQuery] = useState('');
  const [fotoQueryUser, setFotoQueryUser] = useState('');

  const [namaGroupPerusahaan, setNamaGroupPerusahaan] = useState('');
  const [roleName, setRoleName] = useState([]);

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState('');
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [memberFirstName, setMemberFirstName] = useState('');
  const [memberLastName, setMemberLastName] = useState('');

  const [structurePositionTitleName, setStructurePositionTitleName] =
    useState('');

  useEffect(() => {
    setUserTokenState(userToken);

    if (user !== null && user !== undefined) {
      // setFotoQueryUser(user?.member_photo_url);

      if (user?.hasOwnProperty('userRole')) {
        if (user?.userRole.length > 0) {
          if (user?.userRole[0].name === 'superadmin') {
            setRoleName(user?.userRole[0].name);
          }
          if (user?.userRole.length > 1) {
            if (user?.userRole[1].name === 'admin') {
              setRoleName(user?.userRole[1].name);
            }
          }
        } else {
          // console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
        }
      }

      //*
      if (user?.hasOwnProperty('member_first_name')) {
        setMemberFirstName(user?.member_first_name);
      }

      if (user?.hasOwnProperty('member_last_name')) {
        setMemberLastName(user?.member_last_name);
      }

      if (user?.hasOwnProperty('member_position')) {
        if (
          user?.member_position?.hasOwnProperty('structure_position_title_name')
        ) {
          setStructurePositionTitleName(
            user?.member_position.structure_position_title_name
          );
        }
      }
    }
    axiosConfig
      .get(
        URL_API +
          `/account-management/member/${user?.member_id}/photo?mode=thumbnail`,
        {
          responseType: 'blob'
        }
      )
      .then(function (res) {
        // console.log("res photo user : ", res);

        setFotoQueryUser(res.data);
      })
      .catch(function (error) {
        if (error.response !== undefined) {
          if (error.response.status == 401) {
            setErrorStatus(401);
            setOpenDialogError(true);
            setTextErrorInformation(error.response.statusText);
          }

          if (error.response.status == 403) {
            setErrorStatus(403);
            setOpenDialogError(true);
            setTextErrorInformation(error.response.data.info.message);
          }
        } else {
          setTextErrorInformation('Periksa koneksi jaringan internet Anda ');
          setOpenDialogError(true);
        }
        // console.log("Error : ", error.response);
      });

    axiosConfig
      .get(URL_API + `/group/profile/detail?token=${userToken}`)
      .then(function (response) {
        // console.log("Response Original from HeadNewGeneral : ", response);

        setFotoQuery(response.data.data.group_photo_logo_icon);
        setNamaGroupPerusahaan(response.data.data.group_name);
      })
      .catch(function (error) {
        if (error.response !== undefined) {
          if (error.response.status == 401) {
            setErrorStatus(401);
            setOpenDialogError(true);
            setTextErrorInformation(error.response.statusText);
          }

          if (error.response.status == 403) {
            setErrorStatus(403);
            setOpenDialogError(true);
            setTextErrorInformation(error.response.data.info.message);
          }
        } else {
          setTextErrorInformation('Periksa koneksi jaringan internet Anda ');
          setOpenDialogError(true);
        }
        // console.log("Error : ", error.response);
      });
    getNotif();
    const notifInterval = setInterval(() => {
      getNotif();
    }, 30000);
    return () => {
      clearInterval(notifInterval);
    };
  }, []);

  useEffect(() => {
    //* TO KNOW CURRENT LOCATION PAGE
    if (currentLocation === ToGoal) {
      setInisisateLabel('Goal');
    } else if (currentLocation === ToAllMA) {
      setInisisateLabel('Measured Activity');
    } else if (currentLocation === ToGoalDetail) {
      setInisisateLabel('Goal');
    } else if (currentLocation === '/ma') {
      setInisisateLabel('Measured Activity');
    } else if (currentLocation === ToGoalDetailALL) {
      setInisisateLabel('Goal');
    } else if (currentLocation === '/7wd-period') {
      setInisisateLabel('Period');
    } else if (currentLocation === ToDashboard) {
      setInisisateLabel('Dashboard');
    } else if (currentLocation === ToNewSoTreeViewOrgChart) {
      setInisisateLabel('HR');
    } else if (currentLocation === ToEmptyStateGeneral) {
      setInisisateLabel('Karyawan');
    } else if (currentLocation === ToUserManagement) {
      setInisisateLabel('User');
    } else if (currentLocation === ToCompanySetting) {
      setInisisateLabel('Pengaturan');
    } else if (currentLocation.includes(ToDrive)) {
      setInisisateLabel('File Manager');
    } else if (currentLocation === '/task') {
      setInisisateLabel('To Do List');
    } else if (currentLocation === '/reports') {
      setInisisateLabel('Report');
    } else if (currentLocation === ToArchive) {
      setInisisateLabel('Archive');
    } else if (currentLocation === ToAttendance) {
      setInisisateLabel('Attendance');
    } else if (currentLocation === ToAttendanceSetting) {
      setInisisateLabel('Attendance Setting');
    } else if (currentLocation === ToAttendanceReportAdmin) {
      setInisisateLabel('Attendance Report');
    } else if (currentLocation === ToTimeOff) {
      setInisisateLabel('Time Off');
    } else if (currentLocation === ToTimeOffSetting) {
      setInisisateLabel('Time Off Setting');
    } else if (currentLocation.includes(ToTimeOffCategory + '/')) {
      setInisisateLabel('Time Off Category');
    } else if (currentLocation === ToAttendanceListRequest) {
      setInisisateLabel('Request Attendance');
    } else if (currentLocation === ToOvertime) {
      setInisisateLabel('Overtime');
    } else if (currentLocation === ToOvertimeSetting) {
      setInisisateLabel('Overtime Setting');
    } else if (currentLocation === ToException) {
      setInisisateLabel('Exception');
    } else if (currentLocation === ToProject) {
      setInisisateLabel('Project');
    } else if (currentLocation === ToAttendanceDashboard) {
      setInisisateLabel('Attendance Home');
    } else if (currentLocation === ToMyAttendance) {
      setInisisateLabel('My Attendance');
    } else if (currentLocation === ToHistoryClockInOut) {
      setInisisateLabel('History Clock In & Clock Out');
    } else if (currentLocation === ToHistoryCheckInOut) {
      setInisisateLabel('History Check In & Check Out');
    } else if (currentLocation === ToCheckInCheckOut) {
      setInisisateLabel('Check In & Check Out');
    } else if (currentLocation === ToAttendanceReport) {
      setInisisateLabel('Attendance Report');
    } else if (currentLocation === ToMySchedule) {
      setInisisateLabel('My Schedule');
    } else if (currentLocation === ToAttendanceTimeOff) {
      setInisisateLabel('Attendance Time Off');
    } else if (currentLocation === ToAttendanceOvertime) {
      setInisisateLabel('Attendance Overtime');
    } else if (currentLocation === ToRequestAttendance) {
      setInisisateLabel('Request Attendance');
    } else if (currentLocation === ToProfile) {
      setInisisateLabel('Profile');
    }
  }, [currentLocation]);

  /*

        ``````````````````
        HANDLE FIRE UPDATE

        ``````````````````
    */
  useEffect(() => {
    if (contextCompanySetting.fireUpdate === true) {
      if (userTokenState !== undefined) {
        axiosConfig
          .get(URL_API + `/group/profile/detail?token=${userToken}`)
          // .get(URL_API + `/group/profile/patch`)
          .then(function (response) {
            // console.log("Response Original from HeadNewGeneral : ", response);

            setFotoQuery(response.data.data.group_photo_logo_icon);
            setNamaGroupPerusahaan(response.data.data.group_name);
          })
          .catch(function (error) {
            if (error.response !== undefined) {
              if (error.response.status == 401) {
                setErrorStatus(401);
                setOpenDialogError(true);
                setTextErrorInformation(error.response.statusText);
              }

              if (error.response.status == 403) {
                setErrorStatus(403);
                setOpenDialogError(true);
                setTextErrorInformation(error.response.data.info.message);
              }
            } else {
              setErrorStatus(400);
              setTextErrorInformation(
                'Periksa koneksi jaringan internet Anda '
              );
              setOpenDialogError(true);
            }
            // console.log("Error : ", error.response);
          });
      }
    }
  }, [contextCompanySetting.fireUpdate]);

  /*
        ```````````````````````
        OPEN HANDLE DRAWER OPEN

        ```````````````````````
    */

  const [open, setOpen] = useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const [goals, setGoals] = useState(false);

  /*
        `````````````````````
          LIST NESTED PROFILE
  
        `````````````````````
    */

  const [openNestedList, setOpenNestedList] = useState(false);

  function handleOpenNestedList() {
    if (roleName === 'superadmin') {
      setOpenNestedList(!openNestedList);
    }
  }

  /*
        ```````````````
        LIST NESTED 7WD

        ```````````````
    */
  const [openNestedSevenWD, setOpenNestedSevenWD] = useState(false);

  function handleOpenSevenWD() {
    setOpenNestedSevenWD(!openNestedSevenWD);
  }

  /*
        ````````````````````````````````
            LIST NESTED HUMAN CAPITAL

        ````````````````````````````````
    */
  const [openNestedHumanCapital, setOpenNestedHumanCapital] = useState(false);

  function handleOpenHumanCapital() {
    setOpenNestedHumanCapital(!openNestedHumanCapital);
  }

  /*
        ``````````````````````
        LIST NESTED EXTRA TOOL

        ``````````````````````
    */
  const [openNestedExtra, setOpenNestedExtra] = useState(false);

  function handleOpenExtra() {
    setOpenNestedExtra(!openNestedExtra);
  }

  /*
        ``````````````
        HANDLE LOG OUT
  
        ``````````````
    */
  function handleLogOut() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('intro_enabled');

    localStorage.removeItem('verifyToken');
    localStorage.removeItem('status_user_login');
    localStorage.removeItem('goal_detail');
    localStorage.removeItem('employee_id');
    localStorage.removeItem('response_employee_detail');
    localStorage.removeItem('project_details');

    localStorage.removeItem('value_search');
    localStorage.removeItem('value_search_ma');
    localStorage.removeItem('status_goal_id');
    localStorage.removeItem('email');
    localStorage.removeItem('appVersion');
    localStorage.removeItem('location_tab');
    localStorage.removeItem('main_goal_name');
    localStorage.removeItem('data_identitas');

    localStorage.removeItem('breadcrumb');
    localStorage.removeItem('directory_id');
    localStorage.removeItem('status_allma_id');
    localStorage.removeItem('setting_tab');
    localStorage.removeItem('org_tab');
    localStorage.removeItem('data_preview_so');

    localStorage.removeItem('goal_category');
    localStorage.removeItem('project_type');
    localStorage.removeItem('question_bantu_generate');

    localStorage.removeItem('summary_range');
    localStorage.removeItem('summary_user');
    localStorage.removeItem('filter_user');

    localStorage.removeItem('parent_so');
    localStorage.removeItem('task_gantt_due');
    localStorage.removeItem('task_gantt_id');
    localStorage.removeItem('task_gantt_start');
    localStorage.removeItem('MA_detail');
    localStorage.removeItem('theme');
    localStorage.removeItem('auth_data');
    localStorage.removeItem('trial');

    // localStorage.clear();
    navigate(ToLogin);
    window.location.reload();
  }

  /*
        ````````````````````````````````
          ICON POJOK KANAN ATAS - MENU
  
        ````````````````````````````````
    */

  const [anchorElMenuPojokKananAtas, setAnchorElPoKaAtas] = useState(null);
  const isOpenMenuPojokKananAtas = Boolean(anchorElMenuPojokKananAtas);

  /* ````````````START DRAWER`````````````````````*/

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  /*
       `````````````````````````
       DROPDOWN POJOK KANAN ATAS

       ``````````````````````````
   */
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpenDropdownPojokKananAtas(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseDropdownPojokKananAtas() {
    setAnchorEl(null);
  }

  /*
        ````````````````````````````````
          NOTIF & INBOX - MENU
  
        ````````````````````````````````
    */
  const [anchorElNotif, setAnchorElNotif] = useState(null);
  const [anchorElNotif2, setAnchorElNotif2] = useState(null);
  const [anchorElInbox, setAnchorElInbox] = useState(null);
  const [notifList, setNotifList] = useState([]);
  const [notifCount, setNotifCount] = useState(0);
  const [inboxList, setInboxList] = useState([]);
  const [inboxCount, setInboxCount] = useState(0);
  const [isOpenDialogNotifDetail, setOpenDialogNotifDetail] = useState(false);
  const [notifDetail, setNotifDetail] = useState([]);
  const [unread, setUnread] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [trigerNotif, setTrigerNotif] = useState(null);

  useEffect(() => {
    getNotification();
  }, [unread, trigerNotif]);

  function handleOpenNotif(event) {
    setAnchorElNotif(event.currentTarget);
    getNotif();
  }

  function handleCloseNotif() {
    setAnchorElNotif(null);
  }

  function handleOpenInbox(event) {
    setAnchorElInbox(event.currentTarget);
    getNotif();
  }

  function handleCloseInbox() {
    setAnchorElInbox(null);
  }

  function handleNotifOpen(event) {
    setAnchorElNotif2(event.currentTarget);
    getNotification();
  }

  function handleNotifClose(event) {
    setAnchorElNotif2(null);
  }

  async function getNotification() {
    try {
      const response = await axiosConfig.get(
        `${URL_API}/account-management/user/notifications`
      );
      if (response.status === 200) {
        // console.log("RES new notif", response);

        setUnread(response.data?.unread);
        setNotificationList(response.data?.notifications);
      }
    } catch (error) {
      // console.log("Error : ", error.response);

      // Handle Error
      const { listError, dialogErr, resStatus, errorMessage } =
        handleError(error);
      // Error State
      setOpenDialogError(dialogErr);
      setTextErrorInformation(errorMessage);
      setListError(listError);
      setErrorStatus(resStatus);
    }
  }

  async function handleReadNotif(id) {
    try {
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/inbox/update/${id}`
      );

      if (response.status === 200) {
        getNotif();
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setErrorStatus(401);
          setOpenDialogError(true);
          setTextErrorInformation(error.response.statusText);
        }

        if (error.response.status == 403) {
          setErrorStatus(403);
          setOpenDialogError(true);
          setTextErrorInformation(error.response.data.info.message);
        }
      } else {
        setErrorStatus(400);
        setTextErrorInformation('Periksa koneksi jaringan internet Anda ');
        setOpenDialogError(true);
      }
      // console.log("Error : ", error.response);
    }
  }

  async function handleRequestApprove(id, type) {
    try {
      if (type === 'request_exception') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/request-exception/approve/${id}`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      } else if (type === 'request_attendance') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/request-attendance/approve/${id}`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      } else if (type === 'request_time_off') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/request-timeoff/${id}/approve`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      } else if (type === 'request_overtime') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/overtime/${id}/approve`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setErrorStatus(401);
          setOpenDialogError(true);
          setTextErrorInformation(error.response.statusText);
        }

        if (error.response.status == 403) {
          setErrorStatus(403);
          setOpenDialogError(true);
          setTextErrorInformation(error.response.data.info.message);
        }
      } else {
        setErrorStatus(400);
        setTextErrorInformation('Periksa koneksi jaringan internet Anda ');
        setOpenDialogError(true);
      }
      // console.log("Error : ", error.response);
    }
  }

  async function handleRequestReject(id, type) {
    try {
      if (type === 'request_exception') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/request-exception/reject/${id}`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      } else if (type === 'request_attendance') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/request-attendance/reject/${id}`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      } else if (type === 'request_time_off') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/request-timeoff/${id}/reject`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      } else if (type === 'request_overtime') {
        const response = await axiosConfig.post(
          `${URL_API}/human-resource/overtime/${id}/reject`
        );

        if (response.status === 200) {
          getNotif();
          setOpenDialogNotifDetail(false);
        }
      }
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.status == 401) {
          setErrorStatus(401);
          setOpenDialogError(true);
          setTextErrorInformation(error.response.statusText);
        }

        if (error.response.status == 403) {
          setErrorStatus(403);
          setOpenDialogError(true);
          setTextErrorInformation(error.response.data.info.message);
        }
      } else {
        setErrorStatus(400);
        setTextErrorInformation('Periksa koneksi jaringan internet Anda ');
        setOpenDialogError(true);
      }
      // console.log("Error : ", error.response);
    }
  }

  function isAttendancePage(page) {
    if (
      page === ToAttendanceDashboard ||
      page === ToMyAttendance ||
      page === ToHistoryClockInOut ||
      page === ToHistoryCheckInOut ||
      page === ToCheckInCheckOut ||
      page === ToAttendanceReport ||
      page === ToTeam ||
      page === ToInbox ||
      page === ToProfile ||
      page === ToProfilePresence ||
      page === ToProfileAbsence ||
      page === ToProfileChangePin ||
      page === ToMySchedule ||
      page === ToAttendanceTimeOff ||
      page === ToAttendanceOvertime ||
      page === ToRequestAttendance ||
      page === ToAttendance ||
      page === ToAttendanceSetting ||
      page === ToAttendanceListRequest ||
      page === ToAttendanceReportAdmin ||
      page === ToOvertime ||
      page === ToOvertimeSetting ||
      page === ToTimeOff ||
      page === ToTimeOffSetting ||
      page === ToException ||
      page.includes(ToTimeOffCategory + '/') ||
      page === ToRequestAttendance
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function getNotif() {
    if (isAttendancePage(window.location.pathname)) {
      try {
        const response = await axiosConfig.get(
          `${URL_API}/human-resource/inbox`
        );
        const notif = response.data.data.filter((item) => {
          return item.type === 'notification';
        });
        const inbox = response.data.data.filter((item) => {
          return item.type !== 'notification';
        });
        const unreadNotif = notif.filter((item) => {
          return item.is_read === 0;
        });
        setNotifList(notif);
        setNotifCount(unreadNotif.length);
        setInboxList(inbox);
        setInboxCount(inbox.length);
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.status == 401) {
            setErrorStatus(401);
            setOpenDialogError(true);
            setTextErrorInformation(error.response.statusText);
          }

          if (error.response.status == 403) {
            setErrorStatus(403);
            setOpenDialogError(true);
            setTextErrorInformation(error.response.data.info.message);
          }
        } else {
          // setErrorStatus(400);
          // setTextErrorInformation("Periksa koneksi jaringan internet Anda ");
          // setOpenDialogError(true);
        }
        // console.log("Error : ", error.response);
      }
    }
  }

  /*
        ``````````````````
        HANDLE DIALOG HELP

        ``````````````````
    */
  const [isOpenDialogHelp, setOpenDialogHelp] = useState(false);

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([fotoQueryUser], {
    type: 'image/jpeg'
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        style={{
          borderBottomWidth: 1,
          borderBottomColor: '#ccc',
          borderBottomStyle: 'solid'
        }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
            style={{ color: 'grey' }}
          >
            <img
              src={IconMenuOffCanvassGrey}
              alt="Icon Menu Grey"
              style={{ width: 28, height: 28 }}
            />
          </IconButton>

          <Typography
            variant="h6"
            className={classes.textHeaderGoal}
            style={{ width: '100%' }}
          >
            <b>{inisateLabel}</b>
          </Typography>

          {isAttendancePage(window.location.pathname) &&
            group_ids.includes(user?.group_id) && (
              <IconButton
                aria-label="show inbox"
                color="inherit"
                aria-owns={'long-menu'}
                aria-haspopup="true"
                onClick={handleOpenInbox}
              >
                <Badge badgeContent={inboxCount} color="secondary">
                  <Mail
                    style={{
                      color: 'grey'
                    }}
                  />
                </Badge>
              </IconButton>
            )}

          {isAttendancePage(window.location.pathname) &&
            group_ids.includes(user?.group_id) && (
              <IconButton
                aria-label="show notifications"
                color="inherit"
                aria-owns={'long-menu'}
                aria-haspopup="true"
                onClick={handleOpenNotif}
              >
                <Badge badgeContent={notifCount} color="secondary">
                  <NotificationsIcon
                    style={{
                      color: 'grey'
                    }}
                  />
                </Badge>
              </IconButton>
            )}

          {!isAttendancePage(window.location.pathname) && (
            <IconButton
              aria-label="show notifications"
              color="inherit"
              aria-owns={'long-menu'}
              aria-haspopup="true"
              onClick={handleNotifOpen}
            >
              <Badge
                // badgeContent={unread}
                color="secondary"
              >
                <NotificationsIcon
                  style={{
                    color: 'grey'
                  }}
                />
              </Badge>
            </IconButton>
          )}

          <IconButton
            // onClick={() => handleLogOut()}
            aria-haspopup="true"
            color="inherit"
          >
            {fotoQueryUser !== '' &&
            fotoQueryUser !== null &&
            fotoQueryUser !== undefined ? (
              // <img src={URL_API + '/' +fotoQueryUser+ "&token=" + userToken}  className={classes.bigRealFoto} />
              // <ImageSmallUser item={fotoQueryUser} index={1} />
              <Avatar
                variant="circle"
                style={{ width: 25, height: 25 }}
                src={URL.createObjectURL(blobUrlImage)}
              />
            ) : (
              // <img src={fotoQueryUser} className={classes.bigRealFoto} />
              <AccountCircle className={classes.accountCircle} />
            )}
          </IconButton>

          <IconButton
            aria-label="More"
            aria-owns={isOpenMenuPojokKananAtas ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={handleOpenDropdownPojokKananAtas}
          >
            <ExpandMore />
          </IconButton>

          <StyledMenu
            style={{ marginRight: 16, marginLeft: -16 }}
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseDropdownPojokKananAtas}
          >
            <StyledMenuItem
              onClick={() => {
                if (group_ids.includes(user?.group_id)) {
                  navigate(ToProfile);
                }
              }}
            >
              <ListItemIcon>
                {fotoQueryUser !== '' &&
                fotoQueryUser !== null &&
                fotoQueryUser !== undefined ? (
                  <ImageSmallUser item={fotoQueryUser} index={1} />
                ) : (
                  <AccountCircle
                    className={classes.accountCircle}
                    fontSize="large"
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" className={classes.title}>
                    <b>
                      {memberFirstName + ' '}
                      {memberLastName}
                    </b>
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle2" className={classes.title}>
                    {structurePositionTitleName}
                  </Typography>
                }
              />
            </StyledMenuItem>
            <Divider />

            {/* {(roleName === "superadmin" || roleName === "admin") && ( */}
            <div>
              <StyledMenuItem
                // onClick={() => Redirect(ToUserManagement)}
                onClick={() => navigate(ToCompanySetting)}
              >
                <ListItemText
                  style={{ paddingRight: 24 }}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: 'grey' }}
                    >
                      <b>Pengaturan</b>
                    </Typography>
                  }
                />
              </StyledMenuItem>
              <Divider />
            </div>
            {/* )} */}

            <br />
            <StyledMenuItem onClick={() => setOpenDialogHelp(true)}>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: 'grey' }}
                  >
                    <b>Help</b>
                  </Typography>
                }
              />
            </StyledMenuItem>

            <StyledMenuItem
              //    onClick={(e) => handleCloseMenuPojokKananAtas(e, {item: "Log-out"})}
              onClick={() => handleLogOut()}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: 'grey' }}
                  >
                    <b>Sign out</b>
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>

          {/* <StyledMenu
            style={{ marginRight: 16, marginLeft: -16 }}
            id="notif-menu"
            anchorEl={anchorElNotif2}
            keepMounted
            open={Boolean(anchorElNotif2)}
            onClose={handleNotifClose}
          >
            {unread > 0 && (
              <ActionNotification
                classes={classes}
                unread={unread}
                setTrigerNotif={setTrigerNotif}
              />
            )}

            {notificationList.length > 0 &&
              notificationList.map((item, i) => (
                <ListNotification
                  key={i}
                  item={item}
                  classes={classes}
                  setTrigerNotif={setTrigerNotif}
                />
              ))}
          </StyledMenu> */}

          <StyledMenu
            style={{ marginRight: 16, marginLeft: -16 }}
            id="inbox-menu"
            anchorEl={anchorElInbox}
            keepMounted
            open={Boolean(anchorElInbox)}
            onClose={handleCloseInbox}
          >
            {inboxList.map((item, index) => (
              <div key={index}>
                <StyledMenuItem
                  onClick={() => {
                    setOpenDialogNotifDetail(true);
                    setNotifDetail(item);
                    handleReadNotif(item.id);
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    alignItems: 'flex-start'
                  }}
                >
                  <Typography
                    variant="body1"
                    component={'span'}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      fontWeight: 'bold'
                    }}
                  >
                    ● {item.title}
                    {item.is_read === 0 ? (
                      <Chip
                        label={'New'}
                        style={{
                          marginLeft: '1rem',
                          backgroundColor: '#1AC692',
                          color: 'white'
                        }}
                      />
                    ) : (
                      <Chip
                        label={'Read'}
                        style={{
                          marginLeft: '1rem',
                          backgroundColor: '#CE354A',
                          color: 'white'
                        }}
                      />
                    )}
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ color: 'grey', marginLeft: '13px' }}
                  >
                    {item.status}
                  </Typography>

                  {item.desc != null && item.desc != '' && (
                    <Typography
                      variant="body2"
                      style={{ color: 'grey', marginLeft: '13px' }}
                    >
                      Desc. : <b>{item.desc}</b>
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    style={{ color: 'grey', marginLeft: '13px' }}
                  >
                    Date : <b>{item.date}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: 'grey', marginLeft: '13px' }}
                  >
                    Requester : <b>{item.user_name}</b>
                  </Typography>
                </StyledMenuItem>
                <Divider />
              </div>
            ))}
          </StyledMenu>

          <StyledMenu
            style={{ marginRight: 16, marginLeft: -16 }}
            id="notif-menu"
            anchorEl={anchorElNotif}
            keepMounted
            open={Boolean(anchorElNotif)}
            onClose={handleCloseNotif}
          >
            {notifList.map((item, index) => (
              <div key={index}>
                <StyledMenuItem
                  onClick={() => {
                    setOpenDialogNotifDetail(true);
                    setNotifDetail(item);
                    handleReadNotif(item.id);
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    alignItems: 'flex-start'
                  }}
                >
                  <Typography
                    variant="body1"
                    component={'span'}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      fontWeight: 'bold'
                    }}
                  >
                    ● {item.title}
                    {item.is_read === 0 ? (
                      <Chip
                        label={'New'}
                        style={{
                          marginLeft: '1rem',
                          backgroundColor: '#1AC692',
                          color: 'white'
                        }}
                      />
                    ) : (
                      <Chip
                        label={'Read'}
                        style={{
                          marginLeft: '1rem',
                          backgroundColor: '#CE354A',
                          color: 'white'
                        }}
                      />
                    )}
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ color: 'grey', marginLeft: '13px' }}
                  >
                    {item.desc}
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ color: 'grey', marginLeft: '13px' }}
                  >
                    Date : <b>{item.date}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: 'grey', marginLeft: '13px' }}
                  >
                    Shift : <b>{item.shift}</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: 'grey', marginLeft: '13px' }}
                  >
                    Company : <b>{item.company_name}</b>
                  </Typography>
                </StyledMenuItem>
                <Divider />
              </div>
            ))}
          </StyledMenu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        onMouseLeave={() => {
          setOpen(false);
          setGoals(false);
        }}
        onMouseOver={() => setOpen(true)}
        open={open}
      >
        <div
          style={{
            background: '#282560',
            // "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
            // height: "100%",
            height: '1000vh',
            width: 303
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            style={{ padding: 14, marginLeft: 245 }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <img
                src={IconMenuOffCanvass}
                className={classes.iconMenuOffCanvass}
              />
            )}
          </IconButton>

          <List component="nav" className={classes.drawerRoot}>
            <ListItem
              button
              onClick={handleOpenNestedList}
              style={{ paddingLeft: 0, marginBottom: 25 }}
            >
              <ListItemIcon>
                <IconButton aria-haspopup="true" color="inherit">
                  {fotoQuery !== '' ? (
                    <img
                      src={
                        URL_API +
                        '/' +
                        fotoQuery +
                        '&token=' +
                        userToken +
                        '&mode=thumbnail'
                      }
                      className={classes.bigRealFotoDrawer}
                    />
                  ) : (
                    <LocationCityIcon
                      className={classes.accountCircle}
                      fontSize="large"
                    />
                  )}
                </IconButton>
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.namaPerusahaan}
                    style={{ marginLeft: 8 }}
                  >
                    {namaGroupPerusahaan !== '' ? namaGroupPerusahaan : '-'}
                  </Typography>
                }
              />
            </ListItem>

            <ListItemNew
              button
              onClick={() => navigate(ToDashboard)}
              selected={currentLocation === ToDashboard}
              disabled={statusUser === '22' || getStatusLogin === null}
            >
              <ListItemIcon>
                <img src={ImageDashboardHome} style={{ marginLeft: 4 }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: 'white', fontFamily: 'Roboto' }}
                  >
                    Dashboard
                  </Typography>
                }
              />
            </ListItemNew>

            {/* <ListItemNew
              button
              onClick={() => navigate(ToPlayground)}
              selected={currentLocation === ToPlayground}
              disabled={statusUser === "22" || getStatusLogin === null}
            >
              <ListItemIcon>
                <img src={ImageDashboardHome} style={{ marginLeft: 4 }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: "white", fontFamily: "Roboto" }}
                  >
                    Playground
                  </Typography>
                }
              />
            </ListItemNew> */}

            <Box my={0.5}>
              <Divider className={classes.divider} />
            </Box>
            {open && (
              <Box m={2}>
                <Typography
                  type="PengaturanRole"
                  style={{ color: 'white', fontFamily: 'Roboto' }}
                >
                  7WD
                </Typography>
              </Box>
            )}

            <ListItemNew
              onClick={() => navigate(ToGoal)}
              selected={currentLocation === ToGoal}
              button
              disabled={statusUser === '22' || getStatusLogin === null}
            >
              <ListItemIcon>
                <ControlCameraIcon style={{ color: '#d4d3df' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: 'white',
                      fontFamily: 'Roboto'
                    }}
                  >
                    Goal
                  </Typography>
                }
              />
            </ListItemNew>

            <ListItemNew
              onClick={() => navigate(ToAllMA)}
              selected={currentLocation === ToAllMA}
              disabled={statusUser === '22' || getStatusLogin === null}
              button
            >
              <ListItemIcon>
                <SpeedIcon style={{ color: '#d4d3df' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: 'white',
                      fontFamily: 'Roboto'
                    }}
                  >
                    Measured Activities
                  </Typography>
                }
              />
            </ListItemNew>

            <ListItemNew
              button
              disabled={statusUser === '22' || getStatusLogin === null}
              selected={currentLocation === ToProject}
              onClick={() => navigate(ToProject)}
            >
              <ListItemIcon>
                <PlaylistAddCheckIcon style={{ color: '#d4d3df' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: 'white', fontFamily: 'Roboto' }}
                  >
                    Project
                  </Typography>
                }
              />
            </ListItemNew>

            <ListItemNew
              onClick={() => navigate(To7wdPeriode)}
              selected={currentLocation === To7wdPeriode}
              disabled={statusUser === '22' || getStatusLogin === null}
              button
            >
              <ListItemIcon>
                <DateRangeIcon style={{ color: '#d4d3df' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: 'white',
                      fontFamily: 'Roboto'
                    }}
                  >
                    Periode
                  </Typography>
                }
              />
            </ListItemNew>

            {/* 
                                    `````````````````````
                                    HUMAN RESOURCE NESTED

                                    `````````````````````
                                */}
            <Box my={0.5}>
              <Divider className={classes.divider} />
            </Box>

            {open && (
              <Box m={2}>
                <Typography
                  type="PengaturanRole"
                  style={{ color: 'white', fontFamily: 'Roboto' }}
                >
                  Human Capital
                </Typography>
              </Box>
            )}

            {roleName === 'superadmin' && (
              <ListItemNew
                onClick={() => navigate(ToNewSO)}
                selected={currentLocation === '/new-so-treeview-org-chart'}
                disabled={statusUser === '22' || getStatusLogin === null}
                button
              >
                <ListItemIcon>
                  <AccountTreeIcon style={{ color: '#d4d3df' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: 'white',
                        fontFamily: 'Roboto'
                      }}
                    >
                      SO
                    </Typography>
                  }
                />
              </ListItemNew>
            )}

            <ListItemNew
              onClick={() => navigate(ToEmptyStateGeneral)}
              selected={currentLocation === ToEmptyStateGeneral}
              disabled={statusUser === '22' || getStatusLogin === null}
              button
            >
              <ListItemIcon>
                <PeopleIcon style={{ color: '#d4d3df' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: 'white',
                      fontFamily: 'Roboto'
                    }}
                  >
                    Karyawan
                  </Typography>
                }
              />
            </ListItemNew>

            {/* 
                ``````````
                EXTRA TOOL

                ``````````
            */}
            <Box my={0.5}>
              <Divider className={classes.divider} />
            </Box>

            {open && (
              <Box m={2}>
                <Typography
                  type="PengaturanRole"
                  style={{ color: 'white', fontFamily: 'Roboto' }}
                >
                  Extra
                </Typography>
              </Box>
            )}

            {/* 
                ``````````
               PF DRIVE

                ``````````
            */}

            <ListItemNew
              button
              disabled={statusUser === '22' || getStatusLogin === null}
              selected={currentLocation === ToDrive}
              onClick={() => {
                navigate(ToDrive);
                localStorage.removeItem('breadcrumb');
                localStorage.removeItem('directory_id');
              }}
            >
              <ListItemIcon>
                <FolderOpenIcon style={{ color: '#d4d3df' }} />
                {/* <FolderOpenIcon style={{ color: "#df8a8d" }} /> */}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: 'white', fontFamily: 'Roboto' }}
                  >
                    Drive
                  </Typography>
                }
              />
            </ListItemNew>

            {/* 
                ``````````
               PF RPORTUNG

                ``````````
            */}

            <ListItemNew
              button
              disabled={statusUser === '22' || getStatusLogin === null}
              selected={currentLocation === ToReports}
              onClick={() => navigate(ToReports)}
            >
              <ListItemIcon>
                <PollRoundedIcon style={{ color: '#d4d3df' }} />
                {/* <PollRoundedIcon style={{ color: "#df8a8d" }} /> */}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{ color: 'white', fontFamily: 'Roboto' }}
                  >
                    Reports
                  </Typography>
                }
              />
            </ListItemNew>

            {/* 
                ``````````
               PF ARCHIVE

                ``````````
            */}
            {roleName === 'superadmin' && (
              <ListItemNew
                button
                disabled={statusUser === '22' || getStatusLogin === null}
                selected={currentLocation === ToArchive}
                onClick={() => navigate(ToArchive)}
              >
                <ListItemIcon>
                  <ArchiveIcon style={{ color: '#d4d3df' }} />
                  {/* <PollRoundedIcon style={{ color: "#df8a8d" }} /> */}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      style={{ color: 'white', fontFamily: 'Roboto' }}
                    >
                      Archive
                    </Typography>
                  }
                />
              </ListItemNew>
            )}

            {/* 
                ``````````
               PF TDL
                ``````````
            */}

            <ListItemNew
              onClick={() => navigate(ToTask)}
              selected={currentLocation === ToTask}
              disabled={statusUser === '22' || getStatusLogin === null}
              button
            >
              <ListItemIcon>
                <CheckBoxIcon style={{ color: '#d4d3df' }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: 'white',
                      fontFamily: 'Roboto'
                    }}
                  >
                    To Do List
                  </Typography>
                }
              />
            </ListItemNew>

            {/* 
                ``````````
                Time Management

                ``````````
            */}

            {group_ids.includes(user?.group_id) && (
              <React.Fragment>
                <Box my={0.5}>
                  <Divider className={classes.divider} />
                </Box>

                {open && (
                  <Box m={2}>
                    <Typography
                      type="PengaturanRole"
                      style={{ color: 'white', fontFamily: 'Roboto' }}
                    >
                      Time Management
                    </Typography>
                  </Box>
                )}
              </React.Fragment>
            )}

            {group_ids.includes(user?.group_id) && (
              <ListItemNew
                onClick={() => navigate(ToAttendanceDashboard)}
                selected={currentLocation === ToAttendanceDashboard}
                button
                disabled={statusUser === '22' || getStatusLogin === null}
              >
                <ListItemIcon>
                  <Home style={{ color: '#d4d3df' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: 'white',
                        fontFamily: 'Roboto'
                      }}
                    >
                      Attendance Dashboard
                    </Typography>
                  }
                />
              </ListItemNew>
            )}
          </List>
        </div>
      </Drawer>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogHelp
        isOpenDialogHelp={isOpenDialogHelp}
        setOpenDialogHelp={setOpenDialogHelp}
      />

      <DialogNotifDetail
        isOpenDialogNotifDetail={isOpenDialogNotifDetail}
        setOpenDialogNotifDetail={setOpenDialogNotifDetail}
        notifDetail={notifDetail}
        handleApprove={handleRequestApprove}
        handleReject={handleRequestReject}
      />
    </MuiThemeProvider>
  );
};

export default HeaderGOAL;
