import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "@material-ui/core";

import { fade, withStyles } from "@material-ui/core/styles";

import TreeItem from "@material-ui/lab/TreeItem";

import { useSpring, animated } from "react-spring";

const StyledTreeItem = withStyles((theme) => ({
  content: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },

  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  group: {
    marginTop: -8,
    marginLeft: 180,
    marginRight: -240,

    paddingLeft: 16,
    borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}))((props) => {
  return <TreeItem TransitionComponent={TransitionComponent} {...props} />;
});

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

export default StyledTreeItem;
