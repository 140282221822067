import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Checkbox,
  FormControlLabel,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import classNames from "classnames";
import clsx from "clsx";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";

import icon_filter from "../../../../assets/images/icon_filter.png";

import FilterAssignee from "./filterItem/FilterAssignee";
import {
  FILTER_PROJECT,
  SET_PAGINATE_PROJECT,
  THE_OWNER_CONTENT,
} from "../../context/types";

const AnchorFilterProject = ({ classes }) => {
  const project_id = window.location.pathname.split("/")[2];
  const { projectTDispatch, projectTState, setTriggerProject } =
    useContext(ContextProject);

  const STATUS =
    projectTState.filterTheStatus !== null
      ? projectTState.filterTheStatus.split("&").map((el) => {
          let indexEq = el.indexOf("=");
          let content = el.slice(indexEq + 1);

          return content;
        })
      : [];

  const [anchorEl, setAnchorEl] = useState(null);
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [ownerId, setOwnerId] = useState([]);

  const [statuses, setStatuses] = useState(STATUS);

  const [openOwner, setOpenOwner] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const isSelectedStatus = (id) => statuses.indexOf(id) !== -1;

  useEffect(() => {
    if (anchorEl !== null) {
      AxiosConfig.get(`${URL_API}/swd/goal/create`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
          }
        })
        .catch((error) => {
          // console.log("Error", error);
        });
    }
  }, [anchorEl]);

  const ExpandTrue = () => (
    <ExpandMore style={{ color: "#8F8F8F" }} fontSize="small" />
  );
  const ExpandFalse = ({ color }) => (
    <ExpandLess style={{ color }} fontSize="small" />
  );

  const handleOwner = () => setOpenOwner((s) => !s);
  const handleStatus = () => setOpenStatus((s) => !s);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleResetFilter = () => {
    setOwnerId([]);

    projectTDispatch({
      type: FILTER_PROJECT,
      payload: {
        filterTheOwner: null,
        filterTheStatus: "",
      },
    });

    setTriggerProject(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleFilterProject = () => {
    const owners =
      ownerId.length > 0
        ? ownerId
            .map((el, i) => {
              return `options[filter][fields][owner_id][${i}]=${el.id}`;
            })
            .join("&")
        : null;

    const asignees =
      ownerId.length > 0
        ? ownerId.map((el, i) => {
            return `${el?.member?.first_name} ${el?.member?.last_name}`;
          })
        : null;

    // // console.log("ownerss", owners);

    const newStatus =
      statuses.length > 0
        ? statuses
            .map((el, i) => {
              return `options[filter][status_id][${i}]=${el}`;
            })
            .join("&")
        : null;

    projectTDispatch({
      type: FILTER_PROJECT,
      payload: {
        filterTheOwner: owners,
        filterTheStatus: newStatus,
      },
    });

    projectTDispatch({
      type: THE_OWNER_CONTENT,
      payload: {
        theOwnerContent: asignees,
      },
    });

    projectTDispatch({
      type: SET_PAGINATE_PROJECT,
      payload: { pageLimit: 10, pageOffset: 0 },
    });

    setTriggerProject(Math.floor(Math.random() * 100));
    handleClose();
  };

  // // console.log("statuses", statuses);

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        className={classes.btnIcon}
        endIcon={
          <KeyboardArrowDownIcon fontSize="large" style={{ color: "#333" }} />
        }
        onClick={handleShow}
      >
        <img src={icon_filter} height={20} style={{ marginRight: 8 }} />
        <Typography variant="subtitle1" className={classes.title333}>
          Filter
        </Typography>
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <div>
                  <Box minWidth={250} maxWidth={330}>
                    <Box m={2} display="flex" justifyContent="space-between">
                      <Typography
                        variant="subtitle2"
                        className={classes.title888}
                      >
                        <b>Filter</b>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtLink}
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Typography>
                    </Box>

                    <List dense>
                      {/* <ListItem
                        button
                        onClick={handleOwner}
                        style={{
                          backgroundColor:
                            openOwner && "rgba(251, 234, 234, 0.5)",
                          color: openOwner && "#C8203C",
                        }}
                      >
                        <ListItemText primary="Owner" />
                        {openOwner ? (
                          <ExpandFalse color="#C8203C" />
                        ) : (
                          <ExpandTrue />
                        )}
                      </ListItem>
                      <Collapse in={openOwner} timeout="auto" unmountOnExit>
                        <FilterAssignee
                          classes={classes}
                          ownerCollections={ownerCollections}
                          onOwnerId={setOwnerId}
                        />
                      </Collapse> */}

                      <ListItem
                        button
                        onClick={handleStatus}
                        style={{
                          backgroundColor:
                            openStatus && "rgba(251, 234, 234, 0.5)",
                          color: openStatus && "#C8203C",
                        }}
                      >
                        <ListItemText primary="Status" />
                        {openStatus ? (
                          <ExpandFalse color="#C8203C" />
                        ) : (
                          <ExpandTrue />
                        )}
                      </ListItem>
                      <Collapse in={openStatus} timeout="auto" unmountOnExit>
                        <Box
                          pt={1}
                          pb={2}
                          px={3}
                          display="flex"
                          flexWrap="wrap"
                        >
                          {statusCollections.map((item, i) => {
                            const isItemSelected = isSelectedStatus(item.id);

                            const handleChangeCheckbox = (item) => {
                              const selectedIndex = statuses.indexOf(item.id);

                              let newSelected = [];

                              if (selectedIndex === -1) {
                                newSelected = newSelected.concat(
                                  statuses,
                                  item.id
                                );
                              } else if (selectedIndex === 0) {
                                newSelected = newSelected.concat(
                                  statuses.slice(1)
                                );
                              } else if (
                                selectedIndex ===
                                statuses.length - 1
                              ) {
                                newSelected = newSelected.concat(
                                  statuses.slice(0, -1)
                                );
                              } else if (selectedIndex > 0) {
                                newSelected = newSelected.concat(
                                  statuses.slice(0, selectedIndex),
                                  statuses.slice(selectedIndex + 1)
                                );
                              }

                              setStatuses(newSelected);
                            };

                            return (
                              <div key={i}>
                                <FormControlLabel
                                  style={{ margin: 0, padding: 0 }}
                                  label={
                                    <Box display="flex" alignItems="center">
                                      <p
                                        style={{
                                          color: "#555",
                                          fontSize: 14,
                                          marginLeft: 3,
                                        }}
                                        className={classes.title}
                                      >
                                        {item.name}
                                      </p>
                                    </Box>
                                  }
                                  control={
                                    <Checkbox
                                      className={classes.checkboxRoot}
                                      disableRipple
                                      color="default"
                                      checked={isItemSelected}
                                      onChange={() =>
                                        handleChangeCheckbox(item)
                                      }
                                      checkedIcon={
                                        <span
                                          className={clsx(
                                            classes.icon,
                                            classes.checkedIcon
                                          )}
                                        />
                                      }
                                      icon={<span className={classes.icon} />}
                                    />
                                  }
                                />
                              </div>
                            );
                          })}
                        </Box>
                      </Collapse>
                    </List>
                  </Box>

                  <Box
                    p={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Button
                      size="small"
                      varian="outlined"
                      className={classes.button}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Box mx={0.5} />
                    <Button
                      size="small"
                      variant="outlined"
                      className={classes.button1}
                      onClick={handleFilterProject}
                    >
                      Show Result
                    </Button>
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AnchorFilterProject;

const statusCollections = [
  {
    id: "2",
    name: "Approved",
  },
  {
    id: "3",
    name: "Active",
  },
  {
    id: "6",
    name: "Overdue",
  },
  {
    id: "4",
    name: "Completed",
  },
];
