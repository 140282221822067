import React from "react";
import { styles } from "../Style/StyleAttendanceAccount";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const SuccessDialog = ({ handleClose, open, status }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {status}
        </DialogTitle>
        <DialogContent>
          <img
            src={require("../../../../assets/images/success_check.png")}
            alt="failed"
            width={150}
            style={{
              margin: "0 auto",
              display: "block",
              marginBottom: "1rem",
            }}
          />
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default SuccessDialog;
