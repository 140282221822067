import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { useRoutes, navigate } from "hookrouter";
import axios from "axios";

import { ToNewSoTreeViewOrgChart } from "../Constants/config-navigate-url-with-hookrouter";

import DialogError from "../../../components/DialogError";

import Redirect from "../../../utilities/Redirect";
import { URL_API } from "../../../constants/config-api";

import { ToCompletionCompanyStructure } from "../../../constants/config-redirect-url";

const ComponentDescription = (props) => {
  const { classes } = props;

  const theme = useTheme();

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [userTokenState, setUserTokenState] = useState("");
  const [dataTopLeader, setDataTopLeader] = useState("");

  useEffect(() => {
    let userToken = localStorage.getItem("userToken");

    let topLeaderName = localStorage.getItem("data_preview_so");
    let topLeaderNameAfterParse = JSON.parse(topLeaderName);

    if (
      topLeaderNameAfterParse !== undefined &&
      topLeaderNameAfterParse !== null
    ) {
      // if(topLeaderNameAfterParse.length > 0){

      //     setDataTopLeader(topLeaderNameAfterParse[0].masterStructurePositionTitle.name);
      //     setUserTokenState(userToken);
      // };
      setDataTopLeader(topLeaderNameAfterParse.structure_position_title_name);
      setUserTokenState(userToken);
    }
  }, []);

  const handleGoBack = () => {
    Redirect(ToCompletionCompanyStructure);
  };

  const [loader, setLoader] = useState(false);

  const handleSubmitGunakanTemplate = () => {
    setLoader(true);

    let data = {
      StructurePosition: {
        top_leader_name: dataTopLeader,
      },
    };

    // console.log("Data : ", data);

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userTokenState,
    };

    axios.defaults.headers.common = header;
    axios
      .post(URL_API + `/human-resource/structure-wizard`, data)
      .then(function (response) {
        setLoader(false);
        // setIsLoading(false);
        // console.log("Response Original : ", response.data.data);

        localStorage.removeItem("data_preview_so");

        navigate(ToNewSoTreeViewOrgChart);
      })
      .catch(function (error) {
        setLoader(false);
        if (error.response !== undefined) {
          if (error.response.status == 401) {
            setErrorStatus(401);
            setTextErrorInformation("401 : " + error.response.data.message);
            setOpenDialogError(true);
          }

          if (error.response.status == 422) {
            setErrorStatus(422);
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setListError(error.response.data.info.errors);
                setTextErrorInformation(".");
                setOpenDialogError(true);
                // if(error.response.data.info.errors[0].code == "POSITION_EXISTS"){

                //     setTextErrorInformation('422: ' + error.response.data.info.errors[0].description );
                //     setOpenDialogError(true);
                // }
              }
            }
          }
        } else {
          setTextErrorInformation("Whoops something went wrong !");
          setOpenDialogError(true);
        }

        // console.log("Error : ", error.response);
      });
  };

  return (
    <Box marginTop={theme.spacing(1)}>
      <Typography variant="h6" className={classes.title}>
        <b>Struktur Organisasi</b>
      </Typography>

      <Typography
        variant="subtitle1"
        className={classes.title}
        style={{ color: "grey" }}
      >
        <b>
          Klik <i>Card</i> dan gunakan contoh di samping sebagai struktur
          organisasi Anda.
        </b>
        &nbsp;Tentukan lapisan <i>Unit</i> dalam organisasi Anda dengan
        mengkategorikan <i>Jenis Unit</i> dan nama-nama <i>Unit</i> organisasi
        Anda
      </Typography>
      <br />

      <Typography
        variant="subtitle1"
        className={classes.title}
        style={{ color: "grey" }}
      >
        Kemudian tentukan tingkat jabatan serta nama- nama jabatan yang ada pada
        organisasi Anda.
      </Typography>

      <br />
      <Button
        onClick={handleGoBack}
        variant="outlined"
        className={classes.buttonOutlined}
      >
        Kembali
      </Button>

      <Button
        variant="contained"
        className={classes.button}
        // onClick={() => navigate(ToNewSoTreeViewOrgChart)}
        onClick={handleSubmitGunakanTemplate}
      >
        {loader !== true ? (
          "Gunakan template"
        ) : (
          <CircularProgress size={16} style={{ color: "white" }} />
        )}
      </Button>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default ComponentDescription;
