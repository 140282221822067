import React, { useState, useContext, useMemo } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";

import MicRoundedIcon from "@material-ui/icons/MicRounded";
import StopRoundedIcon from "@material-ui/icons/StopRounded";

import MicRecorder from "mic-recorder-to-mp3";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";

import ContextGoalDetail from "../Context/ContextGoalDetail";

import handleError from "../../../Report/components/global/handleError";

const DialogVoiceRecorder = ({
  classes,
  openDialogVoiceRecord,
  setOpenDialogVoiceRecord,
  setFireGoalDetail,
  item,
}) => {
  const context = useContext(ContextGoalDetail);
  const ATTACHMENT_ID = "c9441e2a-972d-47da-a07c-a1eb5215de05";

  // New instance
  const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);

  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mp3File, setMp3File] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const startRecording = () => {
    recorder
      .start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e) => {
        // console.log("ERROR", e);
      });
  };

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        // // console.log("buffer", buffer);
        // // console.log("blob", blob);

        const file = new File(buffer, `${fileName}.mp3`, {
          // type: blob.type,
          type: "audio/mpeg",
          name: fileName,
          lastModified: Date.now(),
        });

        const player = new Audio(URL.createObjectURL(file));
        player.controls = true;
        // player.play();

        const span = document.createElement("span");
        span.classList = "audio";
        span.appendChild(player);
        document.querySelector("#playlist").appendChild(span);

        // setMp3Player("done");
        setMp3File(file);
        setIsRecording(false);
      })
      .catch((e) => {
        // console.log("ERROR", e);
      });
  };

  const handleClear = () => {
    const parent = document.querySelector("#playlist");
    const child = document.querySelector(".audio");
    if (parent.hasChildNodes()) {
      document.querySelector("#playlist").removeChild(child);
    }
    // mp3Player(null);
    setMp3File(null);
    setIsRecording(false);
    setFileName("");
  };

  const handleAudioUpload = () => {
    setLoading(true);

    const dataFile = new FormData();

    dataFile.append("GoalResultEvidence[evidence_category_id]", ATTACHMENT_ID);
    dataFile.append("GoalResultEvidence[description]", fileName);
    dataFile.append("GoalResultEvidence[content]", mp3File);

    axiosConfig
      .post(`${URL_API}/swd/goal-result/${item.id}/evidence`, dataFile, {
        headers: {
          ["Content-Type"]: "multipart/form-data",
        },
      })
      .then((response) => {
        // // console.log("Res Audio Rec", response);

        if (response.status === 200) {
          setOpenDialogVoiceRecord(false);
          setFireGoalDetail(true);
          context.setFireGoalDetail(true);

          setFileName("");
          handleClear();
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);
        setFileName("");
        handleClear();

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      maxWidth="sm"
      open={openDialogVoiceRecord}
      onClose={() => setOpenDialogVoiceRecord(false)}
      fullWidth
    >
      <DialogTitle
        style={{
          // backgroundImage: `url(${bgTitle})`,
          color: "#fff",
          backgroundSize: "cover",
          backgroundColor: "#282560",
        }}
      >
        <Typography variant="h6">Voice Recorder</Typography>
      </DialogTitle>
      {/* <Box pt={2} pb={1} px={2}> */}
      <TextField
        fullWidth
        size="small"
        variant="standard"
        placeholder="Input audio title..."
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        InputProps={{
          style: {
            color: "#fff",
            background: "#282560",
            padding: "20px 25px 10px",
          },
          disableUnderline: true,
          endAdornment: ".mp3",
        }}
      />
      {/* </Box> */}
      <Box
        bgcolor="#282560"
        height={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box mt={2} mb={1}>
          <div id="playlist"></div>
        </Box>

        {!isRecording && (
          <IconButton onClick={startRecording} style={{ background: "#fff" }}>
            <MicRoundedIcon style={{ color: "#d1354a" }} />
          </IconButton>
        )}

        {isRecording && (
          <IconButton onClick={stopRecording} style={{ background: "#fff" }}>
            <StopRoundedIcon style={{ color: "#d1354a" }} />
          </IconButton>
        )}

        <Box my={0.5} />
        <Typography className={classes.title} style={{ color: "#fff" }}>
          {isRecording ? "Stop" : "Record"}
        </Typography>
      </Box>

      <Box
        bgcolor="#282560"
        pt={5}
        pb={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="outlined"
          size="small"
          disabled={loading}
          className={classes.uploadBtn}
          onClick={handleClear}
        >
          Clear
        </Button>
        <Box mx={0.5} />
        <Button
          variant="outlined"
          size="small"
          disabled={loading || mp3File === null}
          className={classes.uploadBtn}
          onClick={handleAudioUpload}
        >
          {loading ? <CircularProgress color="#fff" size={15} /> : "Upload"}
        </Button>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogVoiceRecorder;
