import React, { useEffect, useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Grid,
  CircularProgress,
  Button,
  Tooltip,
} from "@material-ui/core";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import PercentagesSlider from "react-percentages-slider";
// import "react-percentages-slider/dist/index.css";

import Axios from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
import ContextGoalDetail from "../Context/ContextGoalDetail";
// import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const DialogWeightMA = ({
  classes,
  openDialogWeightMA,
  setOpenDialogWeightMA,
}) => {
  const context = useContext(ContextGoalDetail);
  const goalId = window.location.pathname.split("/")[2];

  const [maCollection, setMaCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadWeight, setLoadWeight] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogWeightMA) {
      setLoading(true);
      Axios.get(`${URL_API}/swd/measured-activity/weighting/${goalId}`).then(
        (res) => {
          const result = res.data.data;
          const listMA = result.ma_id.default_value;
          const maLength = listMA.length;
          // // console.log("Weighting REs", result);

          if (maLength === 0) {
            setMaxWidth("md");
          } else if (maLength > 0 && maLength <= 8) {
            setMaxWidth("md");
            setFullWidth(true);
          } else if (maLength > 8 && maLength <= 15) {
            setMaxWidth("lg");
          } else if (maLength > 15) {
            setMaxWidth("xl");
            setFullWidth(true);
          }

          // FOR MAKE SURE 100
          const to100 = (arr) => {
            let lists = arr.map((list) => (list === null ? 0 : list));

            if (lists.reduce((acc, curr) => acc + curr, 0) <= 100) {
              lists[lists.length - 1] += Math.abs(
                100 - lists.reduce((acc, curr) => acc + curr, 0)
              );
            } else if (lists.reduce((acc, curr) => acc + curr, 0) >= 100) {
              lists[lists.length - 1] -= Math.abs(
                100 - lists.reduce((acc, curr) => acc + curr, 0)
              );
            }

            return lists;
          };

          // CONDITION TO 100
          const toaverage = (arr) => {
            const lists = arr.map((list) => Number(list.weight));
            const origin = lists.map((ori) => (ori === null ? 0 : ori));
            const is0 = origin.reduce((acc, curr) => acc + curr, 0);
            const avg = 100 / arr.length;
            const reborn = arr.map((born) => Number(avg.toFixed()));

            if (is0 === 0) {
              // console.log("REBORN wght", reborn);
              return to100(reborn);
            } else {
              // console.log("ORIGIN wght", origin);
              return to100(origin);
            }
          };

          // // console.log("SUM wght", toaverage(result));
          // =========== THE ARR WEIGHT AFTER CALCULATION ===========
          const ARRAY_WEIGHT = toaverage(listMA);
          // =========== THE ARR WEIGHT AFTER CALCULATION ===========

          if (res.status === 200) {
            const addPer = listMA.map((el, i) => {
              return {
                ...el,
                text: `MA ${i + 1}`,
                percentage: ARRAY_WEIGHT[i],
                color: colors[i],
              };
            });

            setMaCollection(addPer);
            setLoading(false);
          }
        }
      );
    }
  }, [openDialogWeightMA]);

  const handleWeightMA = () => {
    setLoadWeight(true);

    const mapMA =
      maCollection.length > 0 &&
      maCollection.map((el) => {
        return {
          ma_id: el.id,
          weight: Number(el.percentage).toFixed(),
        };
      });

    const data = {
      weightings: mapMA,
    };

    // console.log("WEIGHT MAP", data);

    Axios.post(
      `${URL_API}/swd/measured-activity/weighting/${goalId}/store`,
      data
    )
      .then((res) => {
        // console.log("RES WEIGHT", res);

        if (res.status === 200) {
          setLoadWeight(false);
          setOpenDialogWeightMA(false);
          context.setTriggerMAList(Math.floor(Math.random() * 1000 + 1));
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoadWeight(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogWeightMA}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="subtitle1" className={classes.title}>
          <b>Set MA Weight</b>
        </Typography>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={15} style={{ color: "#d1354a" }} />
            <Typography variant="subtitle2" className={classes.title888}>
              Load MA...
            </Typography>
          </Box>
        ) : (
          <PercentagesSlider
            divisions={maCollection}
            setDivisions={setMaCollection}
          />
        )}

        <Box my={4}>
          <Divider />
        </Box>

        {loading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={15} style={{ color: "#d1354a" }} />
            <Typography variant="subtitle2" className={classes.title888}>
              Load Weighting list
            </Typography>
          </Box>
        )}

        {!loading && maCollection.length > 0 && (
          <Grid container>
            <Grid item md={6}>
              <Typography variant="subtitle2" className={classes.title888}>
                Measured Activities
              </Typography>
              <Box mt={3}>
                {maCollection.length > 0 &&
                  maCollection.map((item, i) => (
                    <Typography
                      key={i}
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginBottom: 10 }}
                    >
                      <b>
                        ({item.text}) {item.name}
                      </b>
                    </Typography>
                  ))}
                {maCollection.length === 0 && (
                  <Typography variant="subtitle2" className={classes.title333}>
                    ---
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={2}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginRight: 5 }}
                >
                  Weight
                </Typography>
                <Tooltip
                  title="Maksimal nilai keseluruhan weight pada MA adalah 100% . Setiap ada perubahan MA maka akan diwajibkan untuk menyesuaikan kembali nilai weight."
                  placement="bottom"
                >
                  <InfoRoundedIcon style={{ fontSize: 13, color: "#333" }} />
                </Tooltip>
              </Box>
              <Box mt={3}>
                {maCollection.length > 0 &&
                  maCollection.map((item, i) => (
                    <Typography
                      key={i}
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginBottom: 10 }}
                    >
                      <b>{Number(item.percentage).toFixed()} %</b>
                    </Typography>
                  ))}
                {maCollection.length === 0 && (
                  <Typography variant="subtitle2" className={classes.title333}>
                    ---
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item md={2}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginRight: 5 }}
                >
                  Current Weight
                </Typography>
              </Box>
              <Box mt={3}>
                {maCollection.length > 0 &&
                  maCollection.map((item, i) => (
                    <Typography
                      key={i}
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginBottom: 10 }}
                    >
                      <b>
                        {item.weight === null
                          ? "Not set"
                          : `${Number(item.weight).toFixed()}%`}
                      </b>
                    </Typography>
                  ))}
                {maCollection.length === 0 && (
                  <Typography variant="subtitle2" className={classes.title333}>
                    ---
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item md={2}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="subtitle2"
                  className={classes.title888}
                  style={{ marginRight: 5 }}
                >
                  Status
                </Typography>
              </Box>
              <Box mt={3}>
                {maCollection.length > 0 &&
                  maCollection.map((item, i) => (
                    <Typography
                      key={i}
                      variant="subtitle2"
                      className={classes.title888}
                      style={{ marginBottom: 10 }}
                    >
                      <b>
                        {item.status_id === "2"
                          ? "Approved"
                          : item.status_id === "3"
                          ? "Active"
                          : item.status_id === "6"
                          ? "Overdue"
                          : item.status_id === "4"
                          ? "Completed"
                          : "---"}
                      </b>
                    </Typography>
                  ))}
                {maCollection.length === 0 && (
                  <Typography variant="subtitle2" className={classes.title333}>
                    ---
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.btnWhite}
          onClick={() => setOpenDialogWeightMA(false)}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={handleWeightMA}
        >
          {loadWeight ? "Saving..." : "Save"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogWeightMA;

const colors = [
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
  "#F6B03E",
  "#F47A48",
  "#DD668C",
  "#A05095",
  "#6D5A94",
  "#a29bfe",
  "#6AAFC1",
  "#74b9ff",
  "#55efc4",
  "#00b894",
  "#fab1a0",
  "#ff7675",
  "#fdcb6e",
  "#e17055",
  "#dfe6e9",
  "#b2bec3",
];
