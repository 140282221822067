import React from "react";
import { Typography, FormControlLabel, Radio } from "@material-ui/core";
import clsx from "clsx";

const ListFreqItem = ({ classes, onFreq, freq, item }) => {
  return (
    <FormControlLabel
      label={
        <Typography
          variant="subtitle2"
          className={classes.title555}
          style={{ textTransform: "capitalize" }}
        >
          {item.name}
        </Typography>
      }
      style={{ paddingLeft: 15 }}
      control={
        <Radio
          className={classes.rootRadio}
          value={item.code}
          checked={freq.code === item.code}
          onChange={() => onFreq(item)}
          checkedIcon={
            <span
              className={clsx(classes.iconRadio, classes.checkedIconRadio)}
            />
          }
          icon={<span className={classes.iconRadio} />}
        />
      }
    />
  );
};

export default ListFreqItem;
