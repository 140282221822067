import * as constants from '../constants';

export const liveSummaryFilterInitial = {
  goalAchStatus: ['Achieved', 'Not achieved', 'Warning'],
  maAchStatus: ['Achieved', 'Not achieved', 'Warning'],
  units: {
    'All': {
      'id': 'All',
      'structure_position_title_name': 'All'
    }
  },
  goalOra: {
    from: 0,
    to: 200
  },
  maOra: {
    from: 0,
    to: 200
  },
  search: '',
  order: {
    field: 'owner_name',
    direction: 'asc'
  },
  page: 0,
  perPage: 10,
  viewColumns: [ 'Goal', 'MA' ]
};

export const liveSummaryFilterReducer = (state, { filterType, payload }) => {
  switch (filterType) {
    case constants.FILTER_GOAL_ACH_STATUS:
      return {
        ...state,
        goalAchStatus: payload
      };
    case constants.FILTER_MA_ACH_STATUS:
      return {
        ...state,
        maAchStatus: payload
      };
    case constants.FILTER_UNIT:
      return {
        ...state,
        units: payload
      };
    case constants.FILTER_GOAL_ORA:
      return {
        ...state,
        goalOra: payload
      };
    case constants.FILTER_MA_ORA:
      return {
        ...state,
        maOra: payload
      };
    case constants.FILTER_SEARCH:
      return {
        ...state,
        search: payload
      };
    case constants.FILTER_PER_PAGE:
      return {
        ...state,
        perPage: payload
      };
    case constants.FILTER_PAGE:
      return {
        ...state,
        page: payload
      };
    case constants.ORDER_FIELD_OWNER_NAME:
      return {
        ...state,
        order: payload
      };
    case constants.ORDER_FIELD_POSITION:
      return {
        ...state,
        order: payload
      };
    case constants.ORDER_FIELD_GOAL_ORA:
      return {
        ...state,
        order: payload
      };
    case constants.ORDER_FIELD_MA_ORA:
      return {
        ...state,
        order: payload
      };
    case constants.VIEW_COLUMNS:
      return {
        ...state,
        viewColumns: payload
      };
    default:
      return state;
  }
};