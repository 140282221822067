import React from "react";
import { MenuItem, Typography, Radio } from "@material-ui/core";

const ListItemRemindInWeek = ({
  classes,
  isCurrent,
  item,
  remind,
  handleRemind,
}) => {
  return (
    <MenuItem style={{ margin: 0, padding: "0 10px" }} dense>
      <label style={{ margin: 0, padding: 0, height: 37.5 }}>
        <Typography
          variant="subtitle2"
          className={classes.itemFreq}
          style={{
            backgroundColor: isCurrent && "#FDDBD8",
            color: isCurrent ? "#CF364A" : "#333",
          }}
        >
          {item.name}
        </Typography>
        <Radio
          onChange={() => handleRemind(item)}
          checked={item.name === remind.name}
          value={item.id}
          style={{
            visibility: "hidden",
            height: 0,
            width: 0,
            opacity: 0,
          }}
        />
      </label>
    </MenuItem>
  );
};

export default ListItemRemindInWeek;
