import React, { useEffect, useState } from "react";
import { Box, Button, Divider } from "@material-ui/core";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";

import { styles, theme } from "./Style/StyleCompanySetting";
// import ViewNotifyGroup from "./Notifications/group/ViewNotifyGroup";
import ViewNotifByGroup from "./Notifications/group/ViewNotifByGroup";
import DialogCreateNotif from "./Components/DialogCreateNotif";

const useStyles = makeStyles(styles);

const ViewNotificationSetting = () => {
  const classes = useStyles();

  const [selectNotif, setSelectNotif] = useState({ code: "group" });
  const [openCreateNotif, setOpenCreateNotif] = useState(false);

  const [triggerNotif, setTriggerNotif] = useState(null);

  const handleSelectNotif = (data) => {
    setSelectNotif({ code: data.code });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box mt={5} mx={8}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {/* <Box
            display="flex"
            flexDirection="row"
            border="1px solid #ccc"
            borderRadius={5}
            p={0.5}
            py={1}
            pr={0}
            pl={1}
          >
            {listotifications.map((item, i) => {
              const isCurrent = selectNotif.code === item.code;

              return (
                <Box key={i} mr={1}>
                  <Button
                    size="large"
                    variant="outlined"
                    className={
                      isCurrent ? classes.button4 : classes.btnInnactive
                    }
                    style={{ padding: "6px 30px" }}
                    onClick={() => handleSelectNotif(item)}
                  >
                    {item.name}
                  </Button>
                </Box>
              );
            })}
          </Box> */}
          <Box>
            <Button
              size="small"
              variant="outlined"
              className={classes.button2}
              startIcon={<SettingsRoundedIcon />}
              onClick={() => setOpenCreateNotif(true)}
            >
              Settings
            </Button>
          </Box>
        </Box>

        <Box mt={2} mb={3}>
          <Divider />
        </Box>

        {selectNotif.code === "group" && (
          <ViewNotifByGroup
            classes={classes}
            triggerNotif={triggerNotif}
            setTriggerNotif={setTriggerNotif}
          />
        )}
      </Box>

      <DialogCreateNotif
        classes={classes}
        openCreateNotif={openCreateNotif}
        setOpenCreateNotif={setOpenCreateNotif}
        setTriggerNotif={setTriggerNotif}
      />
    </MuiThemeProvider>
  );
};

export default ViewNotificationSetting;

const listotifications = [
  {
    id: "1",
    name: "Group",
    code: "group",
  },
  // {
  //   id: "2",
  //   name: "Unit",
  //   code: "unit",
  // },
  // {
  //   id: "3",
  //   name: "User",
  //   code: "user",
  // },
];
