import React from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

const PeriodSetting = ({
  classes,
  masterPeriod,
  onPeriod,
  period,
  onReadyData,
  loadPeriod,
}) => {
  const handleChange = (e) => {
    onPeriod(e.target.value);
    onReadyData("open");
  };

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title333}>
        <b>Default Periode</b>
      </Typography>
      <Box my={1} />
      <Typography variant="caption" className={classes.title888}>
        Choose periode to set as default
      </Typography>

      <Box mt={4} display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.title888}>
          <b>Periode : </b>
        </Typography>
        <Box mx={1} />
        <Select
          variant="outlined"
          style={{ width: 300, height: 40 }}
          value={period}
          disabled={loadPeriod}
          onChange={handleChange}
        >
          {masterPeriod.length > 0 ? (
            masterPeriod.map((period, i) => (
              <MenuItem key={i} value={period.id}>
                {period.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem>Period is empty</MenuItem>
          )}
        </Select>
        {loadPeriod && (
          <Box ml={2} display="flex" alignItems="center">
            <CircularProgress
              size={16}
              style={{ color: "#d1354a", marginRight: 5 }}
            />
            <Typography variant="caption" className={classes.title888}>
              Loading...
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default PeriodSetting;
