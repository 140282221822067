import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  CardMedia,
  Box,
  CardContent,
  Checkbox,
} from "@material-ui/core";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";

import moment from "moment";
import clsx from "clsx";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import ImageIcon from "@material-ui/icons/Image";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import pdf_icon from "../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../assets/images/icons_file/Text.png";

import TruncateTextShortNameGoal from "../../../../utilities/TruncateTextShortNameGoal";
import ContextGlobalDrive from "../../context/ContextDrive";

import DialogInfoFile from "../components/DialogInfoFiles";
import DialogDeleteFile from "../components/DialogDeleteFile";
import DialogRenameFile from "../components/DialogRenameFile";
import DialogMoveFile from "../components/DialogMoveFile";
import DialogPreviewImage from "../components/DialogPreviewImage";
import DialogPreviewVideo from "../components/DialogPreviewVideo";
import DialogDownloadFile from "../components/DialogDownloadFile";
import DialogPreviewPDF from "../components/DialogPreviewPDF";
import DialogPreviewDocument from "../components/DialogPreviewDocument";
import DialogPreviewText from "../components/DialogPreviewText";

const RecoveredFilesGrid = ({ item, classes, isFileSelected }) => {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [anchorElFile, setAnchorElFile] = useState(null);
  const [image, setImage] = useState("");

  const [openDialogInfoFile, setOpenDialogInfoFile] = useState(false);
  const [openDialogDeleteFile, setOpenDialogDeleteFile] = useState(false);
  const [openDialogRenameFile, setOpenDialogRenameFile] = useState(false);
  const [openDialogMoveFile, setOpenDialogMoveFile] = useState(false);
  const [openDialogPreviewImage, setOpenDialogPreviewImage] = useState(false);
  const [openDialogPreviewVideo, setOpenDialogPreviewVideo] = useState(false);
  const [openDialogDownloadFile, setOpenDialogDownloadFile] = useState(false);
  const [openDialogCopyFile, setOpenDialogCopyFile] = useState(false);
  const [openDialogPreviewPDF, setOpenDialogPreviewPDF] = useState(false);
  const [openDialogPreviewDocument, setOpenDialogPreviewDocument] =
    useState(false);
  const [openDialogPreviewText, setOpenDialogPreviewText] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  useEffect(() => {
    axiosConfig
      .get(`${URL_API}/drive/file/${item.id}?mode=thumbnail`, {
        responseType: "blob",
      })
      .then((response) => {
        // // console.log("Response Download", response);

        if (response.status === 200) {
          setImage(response.data);
        }
      })
      .catch((error) => {
        // console.log("Error", error);
      });
  }, []);

  /* ====================================================
    LOCAL FUNCS / METHODS
  ==================================================== */

  const handleShowAnchorFile = (event) => {
    setAnchorElFile(event.currentTarget);
  };

  const handleCloseAnchorFile = () => {
    setAnchorElFile(null);
  };

  const handleDialogInfoFile = () => {
    setOpenDialogInfoFile(true);
    setAnchorElFile(null);
  };

  const handleDialogDeleteFile = () => {
    setOpenDialogDeleteFile(true);
    setAnchorElFile(null);
  };

  const handleDialogRenameFile = () => {
    setOpenDialogRenameFile(true);
    setAnchorElFile(null);
  };

  const handleDialogMoveFile = () => {
    setOpenDialogMoveFile(true);
    setAnchorElFile(null);
  };

  const handleDialogDownloadFile = () => {
    setOpenDialogDownloadFile(true);
    setAnchorElFile(null);
  };

  // Handle Preview File
  const handlePreviewFile = (data) => {
    const imageType = data.mime_type.includes("image");
    const videoType = data.mime_type.includes("video");
    const pdfType = data.mime_type.includes("pdf");
    const textType = data.mime_type.includes("text");
    const docType = data.extension;

    if (imageType) {
      setOpenDialogPreviewImage(true);
      setAnchorElFile(null);
    } else if (videoType) {
      setOpenDialogPreviewVideo(true);
      setAnchorElFile(null);
    } else if (pdfType) {
      setOpenDialogPreviewPDF(true);
      setAnchorElFile(null);
    } else if (
      docType === "doc" ||
      docType === "docx" ||
      docType.includes("ppt") ||
      docType === "xlsx" ||
      docType === "xls"
    ) {
      setOpenDialogPreviewDocument(true);
      setAnchorElFile(null);
    } else if (textType) {
      setOpenDialogPreviewText(true);
      setAnchorElFile(null);
    }

    // console.log(data.mime_type);
  };

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([image], { type: item.mime_type });

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFile.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFile, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFile.slice(1));
    } else if (selectedIndex === context.selectedFile.length - 1) {
      newSelected = newSelected.concat(context.selectedFile.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFile.slice(0, selectedIndex),
        context.selectedFile.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected Folders", newSelected);

    context.setSelectedFile(newSelected);
  };

  // Check is Folder selected or not
  const isItemFileSelected = isFileSelected(item.id);

  return (
    <Grid item md={3} key={item.id}>
      {context.toggleSelect && (
        <Checkbox
          style={{ marginTop: !context.toggleSelect ? 0 : -10 }}
          onClick={() => handleChangeCheckbox(item)}
          className={classes.checkboxRoot}
          checked={isItemFileSelected}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "decorative checkbox" }}
        />
      )}
      <Card
        variant="outlined"
        style={{
          marginTop: !context.toggleSelect ? 0 : -20,
          backgroundColor: "#eafaf1",
        }}
      >
        {item.mime_type.includes("image") ? (
          <CardMedia
            component="img"
            src={image !== "" ? URL.createObjectURL(blobUrlImage) : undefined}
            style={{
              height: 210,
            }}
          />
        ) : (
          <CardContent
            style={{
              height: 200,
              padding: 5,
              borderRadius: 10,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={10}
            >
              {item.mime_type === "application/pdf" ? (
                <img src={pdf_icon} />
              ) : item.mime_type === "application/msword" ||
                item.mime_type ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                <img src={word_icon} />
              ) : item.mime_type === "application/vnd.ms-powerpoint" ||
                item.mime_type ===
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                <img src={ppt_icon} />
              ) : item.mime_type === "application/vnd.ms-excel" ||
                item.mime_type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                <img src={excel_icon} />
              ) : item.mime_type.includes("video") ? (
                <img src={video_icon} />
              ) : item.mime_type === "text/plain" ? (
                <img src={text_icon} style={{ width: 62 }} />
              ) : (
                <InsertDriveFileIcon color="inherit" fontSize="large" />
              )}
            </Box>
          </CardContent>
        )}

        <CardHeader
          avatar={<ImageIcon style={{ color: "#888" }} />}
          action={
            <div>
              <IconButton onClick={handleShowAnchorFile}>
                <MoreVertIcon />
              </IconButton>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElFile}
                keepMounted
                open={Boolean(anchorElFile)}
                onClose={handleCloseAnchorFile}
              >
                <StyledMenuItem
                  style={{ width: 200 }}
                  onClick={handleDialogInfoFile}
                >
                  <ListItemIcon>
                    <VisibilityOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        View Info
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDialogRenameFile}>
                  <ListItemIcon>
                    <EditOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        Rename
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDialogDeleteFile}>
                  <ListItemIcon>
                    <DeleteOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        Delete File
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDialogMoveFile}>
                  <ListItemIcon>
                    <SyncAltIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        Move File
                      </Typography>
                    }
                  />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleDialogDownloadFile}>
                  <ListItemIcon>
                    <CloudDownloadOutlinedIcon
                      fontSize="small"
                      className={classes.hoverColor}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle2"
                        className={classes.rightMenuText}
                      >
                        Download
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          }
          title={
            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              onClick={() => handlePreviewFile(item)}
            >
              {TruncateTextShortNameGoal(item.name)}
            </Typography>
          }
          subheader={moment(item.modified_at).format("DD MMM YYYY, HH:mm")}
        />
      </Card>

      <DialogInfoFile
        openDialogInfoFile={openDialogInfoFile}
        setOpenDialogInfoFile={setOpenDialogInfoFile}
        item={item}
        classes={classes}
        origin="Drive"
      />

      <DialogDeleteFile
        openDialogDeleteFile={openDialogDeleteFile}
        setOpenDialogDeleteFile={setOpenDialogDeleteFile}
        item={item}
        classes={classes}
      />

      <DialogRenameFile
        openDialogRenameFile={openDialogRenameFile}
        setOpenDialogRenameFile={setOpenDialogRenameFile}
        item={item}
        classes={classes}
      />

      <DialogMoveFile
        openDialogMoveFile={openDialogMoveFile}
        setOpenDialogMoveFile={setOpenDialogMoveFile}
        classes={classes}
        item={item}
      />

      <DialogPreviewImage
        openDialogPreviewImage={openDialogPreviewImage}
        setOpenDialogPreviewImage={setOpenDialogPreviewImage}
        itemId={item.id}
        itemType={item.mime_type}
        itemName={item.name}
        origin="Drive"
      />

      <DialogPreviewVideo
        openDialogPreviewVideo={openDialogPreviewVideo}
        setOpenDialogPreviewVideo={setOpenDialogPreviewVideo}
        itemId={item.id}
        itemName={item.name}
      />

      <DialogDownloadFile
        openDialogDownloadFile={openDialogDownloadFile}
        setOpenDialogDownloadFile={setOpenDialogDownloadFile}
        item={item}
        classes={classes}
        origin="Drive"
      />

      <DialogPreviewPDF
        openDialogPreviewPDF={openDialogPreviewPDF}
        setOpenDialogPreviewPDF={setOpenDialogPreviewPDF}
        itemId={item.id}
        itemName={item.name}
      />

      <DialogPreviewDocument
        openDialogPreviewDocument={openDialogPreviewDocument}
        setOpenDialogPreviewDocument={setOpenDialogPreviewDocument}
        itemId={item.id}
        itemName={item.name}
        origin="Drive"
      />

      <DialogPreviewText
        openDialogPreviewText={openDialogPreviewText}
        setOpenDialogPreviewText={setOpenDialogPreviewText}
        itemId={item.id}
        itemName={item.name}
        classes={classes}
        item={item}
        origin="Drive"
      />
    </Grid>
  );
};

export default RecoveredFilesGrid;
