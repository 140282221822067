import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleAttendance";

import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  Paper,
  TableBody,
  LinearProgress,
  TextField,
  InputAdornment,
  IconButton,
  TableRow,
  TableCell,
  ListItemText,
  TableFooter,
  TablePagination,
  CircularProgress,
} from "@material-ui/core";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import ContextAttendance from "./Context/ContextAttendance";
import EnhancedTableToolbar from "./Components/EnhancedTableToolbar";
import TableItem from "./Components/TableItem";
import AttendanceListMenu from "./Components/AttendanceListMenu";
import { Table } from "react-bootstrap";
import EnhancedTableHead from "./Components/EnhancedTableHead";
import TableFilter from "./Components/TableFilter";
import moment from "moment";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import DialogProgress from "./Components/DialogProgress";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";
import DialogDetail from "./Components/DialogDetail";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";
import ListCardItemAttendance from "./Components/ListCardItemAttendance";
import PaginationAttendance from "./Components/PaginationAttendance";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewAttendance = () => {
  const classes = useStyles();
  const context = useContext(ContextAttendance);
  const [dataAttendanceDetail, setDataAttendanceDetail] = useState({
    attendance_member_id: "",
    clock_in: "",
    clock_in_coordinates_lat: "",
    clock_in_coordinates_long: "",
    clock_in_photo_path: "",
    clock_in_photo_path_url: "",
    clock_out: "",
    clock_out_coordinates_lat: "",
    clock_out_coordinates_long: "",
    clock_out_photo_path: "",
    clock_out_photo_path_url: "",
    created_at: "",
    created_by: "",
    deleted_at: "",
    deleted_by: "",
    id: "",
    member: {},
    parent_id: "",
    schedule: {},
    schedule_id: "",
    status_approve: "",
    status_attendance: "",
    updated_at: "",
    updated_by: "",
    workday: "",
    status_clock_in: "",
    status_clock_out: "",
    clock_in_status: "",
    clock_out_status: "",
    clock_in_remarks: "",
    clock_out_remarks: "",
    clock_in_schedule_status: "",
    clock_in_location_status: "",
    clock_out_schedule_status: "",
    clock_out_location_status: "",
  });
  const [dataAttendanceCheckIn, setDataAttendanceCheckIn] = useState({
    options: {
      filter: {
        search: "",
        date: moment().format("YYYY-MM-DD"),
        date_moment: moment(),
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 1,
      },
    },
    list: [],
  });
  
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const [roleName, setRoleName] = useState([]);

  useEffect(() => {
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      // setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);

      if (statusUserLoginAfterParse.member_position && statusUserLoginAfterParse.member_position.is_superior) {
        setRoleName('manage');
      }

      if (statusUserLoginAfterParse.userRole.length > 0) {
        if (statusUserLoginAfterParse.userRole[0].name === "superadmin") {
          setRoleName(statusUserLoginAfterParse.userRole[0].name);
        }
        if (statusUserLoginAfterParse.userRole.length > 1) {
          if (statusUserLoginAfterParse.userRole[1].name === "admin") {
            setRoleName(statusUserLoginAfterParse.userRole[1].name);
          }
        }
      } else {
        // console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
      }
    }
    getAttendanceList();
  }, []);

  // attendance list
  const [dataAttendanceList, setDataAttendanceList] = useState({
    options: {
      filter: {
        search: "",
        date: moment().format("YYYY-MM-DD"),
        date_moment: moment(),
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 1,
      },
    },
    list: [],
  });

  const getAttendanceList = () => {
    // context.setModalProgress(true);
    context.setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/attendance` +
          `?options[filter][search]=${dataAttendanceList.options.filter.search}` +
          `&options[filter][date]=${dataAttendanceList.options.filter.date}` +
          `&options[paging][limit]=${dataAttendanceList.options.paging.limit}` +
          `&options[paging][offset]=${dataAttendanceList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        context.setLoader(false);
        context.setModalProgress(false);
        let dataAttendanceListTemp = dataAttendanceList;
        dataAttendanceListTemp.list = response.data.data;
        dataAttendanceListTemp.options.paging.count = response.data.info.count;
        setDataAttendanceList({ ...dataAttendanceListTemp });
      })
      .catch(function (error) {
        context.setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          } else if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.messag
          ) {
            context.setErrorStatus(error.response.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          } else {
            context.setTextErrorInformation("Request tidak tepat !");
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    console.log(event, newPage);
    refreshList(
      null,
      dataAttendanceList.options.paging.limit * (newPage - 1),
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 1, parseInt(event.target.value));
  };

  const refreshList = (search, offset, page, limit) => {
    let dataAttendanceListTemporary = dataAttendanceList;
    dataAttendanceListTemporary.options.filter.search =
      search !== null
        ? search
        : dataAttendanceListTemporary.options.filter.search;
    dataAttendanceListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataAttendanceListTemporary.options.paging.offset;
    dataAttendanceListTemporary.options.paging.page =
      page !== null ? page : dataAttendanceListTemporary.options.paging.page;
    dataAttendanceListTemporary.options.paging.limit =
      limit !== null ? limit : dataAttendanceListTemporary.options.paging.limit;
    setDataAttendanceList({ ...dataAttendanceListTemporary });
    getAttendanceList();
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDetail = (e, item) => {
    context.setModalProgress(true);
    context.setLoader(true);
    let dataAttendanceDetailTemporary = dataAttendanceDetail;
    dataAttendanceDetailTemporary.attendance_member_id =
      item.attendance_member_id;
    dataAttendanceDetailTemporary.clock_in = item.clock_in;
    dataAttendanceDetailTemporary.clock_in_coordinates_lat =
      item.clock_in_coordinates_lat;
    dataAttendanceDetailTemporary.clock_in_coordinates_long =
      item.clock_in_coordinates_long;
    dataAttendanceDetailTemporary.clock_in_photo_path =
      item.clock_in_photo_path;
    dataAttendanceDetailTemporary.clock_in_photo_path_url =
      item.clock_in_photo_path_url;
    dataAttendanceDetailTemporary.clock_out = item.clock_out;
    dataAttendanceDetailTemporary.clock_out_coordinates_lat =
      item.clock_out_coordinates_lat;
    dataAttendanceDetailTemporary.clock_out_coordinates_long =
      item.clock_out_coordinates_long;
    dataAttendanceDetailTemporary.clock_out_photo_path =
      item.clock_out_photo_path;
    dataAttendanceDetailTemporary.clock_out_photo_path_url =
      item.clock_out_photo_path_url;
    dataAttendanceDetailTemporary.created_at = item.created_at;
    dataAttendanceDetailTemporary.created_by = item.created_by;
    dataAttendanceDetailTemporary.deleted_at = item.deleted_at;
    dataAttendanceDetailTemporary.deleted_by = item.deleted_by;
    dataAttendanceDetailTemporary.id = item.id;
    dataAttendanceDetailTemporary.member = item.member;
    dataAttendanceDetailTemporary.parent_id = item.parent_id;
    dataAttendanceDetailTemporary.schedule = item.schedule;
    dataAttendanceDetailTemporary.schedule_id = item.schedule_id;
    dataAttendanceDetailTemporary.status_approve = item.status_approve;
    dataAttendanceDetailTemporary.status_attendance = item.status_attendance;
    dataAttendanceDetailTemporary.updated_at = item.updated_at;
    dataAttendanceDetailTemporary.updated_by = item.updated_by;
    dataAttendanceDetailTemporary.workday = item.workday;
    dataAttendanceDetailTemporary.status_clock_in = item.status_clock_in;
    dataAttendanceDetailTemporary.status_clock_out = item.status_clock_out;
    dataAttendanceDetailTemporary.clock_in_status = item.clock_in_status;
    dataAttendanceDetailTemporary.clock_out_status = item.clock_out_status;
    dataAttendanceDetailTemporary.clock_in_remarks = item.clock_in_remarks;
    dataAttendanceDetailTemporary.clock_out_remarks = item.clock_out_remarks;
    dataAttendanceDetailTemporary.clock_in_schedule_status =
      item.clock_in_schedule_status;
    dataAttendanceDetailTemporary.clock_in_location_status =
      item.clock_in_location_status;
    dataAttendanceDetailTemporary.clock_out_schedule_status =
      item.clock_out_schedule_status;
    dataAttendanceDetailTemporary.clock_out_location_status =
      item.clock_out_location_status;
    setDataAttendanceDetail({ ...dataAttendanceDetailTemporary });
    axiosConfig
      .get(`${URL_API}/human-resource/attendance/${item.id}`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        let dataAttendanceCheckInTemp = dataAttendanceCheckIn;
        dataAttendanceCheckInTemp.list = response.data.data;
        setDataAttendanceCheckIn(dataAttendanceCheckInTemp);
        context.setModalDetail(true);
        context.setLoader(false);
        context.setModalProgress(false);
      })
      .catch(function (error) {
        context.setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        <AttendanceListMenu classes={classes} roleName={roleName} />

        <TableFilter
          classes={classes}
          dataAttendanceList={dataAttendanceList}
          setDataAttendanceList={setDataAttendanceList}
          getAttendanceList={getAttendanceList}
        />
        <EnhancedTableHead classes={classes} />

        {context.loader ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={15}
          >
            <CircularProgress
              size={20}
              style={{ marginRight: 10, color: "#d64253" }}
            />
            <Typography className={classes.title555} variant="subtitle1">
              Loading...
            </Typography>
          </Box>
        ) : (
          <Table
            className={classes.table}
            aria-label="enhanced table"
            style={{ marginTop: "16px", paddingRight: "24px" }}
          >
            <TableBody mt={2}>
              {dataAttendanceList &&
                dataAttendanceList.list &&
                dataAttendanceList.list.length > 0 &&
                dataAttendanceList.list.map((item, i) => {
                  return (
                    <ListCardItemAttendance
                      keyItem={i}
                      key={i}
                      classes={classes}
                      item={item}
                      dataAttendanceList={dataAttendanceList}
                      handleShowModalDetail={handleShowModalDetail}
                      userTokenState={context.userTokenStateGlobal}
                    />
                  );
                })}
            </TableBody>
          </Table>
        )}

        {dataAttendanceList.list.length === 0 && context.loader !== true && (
          <Box width="100%" style={{ paddingRight: 24 }}>
            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                <IconButton disabled>
                  <EventAvailableIcon style={{ color: "#d64253" }} />
                </IconButton>
                <b>Tidak ada Attendance yang dapat di tampilkan saat ini ;(</b>
              </Typography>
            </Paper>
          </Box>
        )}

        <Box ml={1} mr={2}>
          <PaginationAttendance
            classes={classes}
            dataAttendanceList={dataAttendanceList}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
      <DialogDetail
        classes={classes}
        isModalDetail={context.isModalDetail}
        setModalDetail={context.setModalDetail}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        dataAttendanceDetail={dataAttendanceDetail}
        dataAttendanceCheckIn={dataAttendanceCheckIn}
      />
    </MuiThemeProvider>
  );
};

export default ViewAttendance;
