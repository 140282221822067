import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Paper, Typography, Grid, Button, Avatar } from "@material-ui/core";

import useScrollTop from "react-hook-scrolltop";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Identitas from "./ComponentProfileDetail/Identitas";

import { styles } from "./Style/StyleProfileDetail";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewProfileDetail = (props) => {
  const { classes } = props;

  /*
        ``````````````````
        USE SCROLL TOP

        ``````````````````
    */
  const isTopOfPage = useScrollTop();

  /* 
        ````````````````````
        COMPONENT DID MOUNT
        
        ````````````````````
    */

  const dataIdentitasInfoDasar = localStorage.getItem("data_identitas");
  const dataIdentitasInfoDasarAfterParse = JSON.parse(dataIdentitasInfoDasar);

  const [namaDepan, setNamaDepan] = useState("");
  const [namaBelakang, setNamaBelakang] = useState("");
  const [namaPanggilan, setNamaPanggilan] = useState("");

  useEffect(() => {
    //    // console.log("dataIdentitasInfoDasarAfterParse : ", dataIdentitasInfoDasarAfterParse);

    if (dataIdentitasInfoDasarAfterParse !== null) {
      setNamaDepan(dataIdentitasInfoDasarAfterParse.nama_depan);
      setNamaBelakang(dataIdentitasInfoDasarAfterParse.nama_belakang);
      setNamaPanggilan(dataIdentitasInfoDasarAfterParse.nama_panggilan);
    }
  }, []);

  /*
        ``````````
        HANDLE TAB

        ``````````
    */

  const [tabIndex, setTabIndex] = useState(0);
  const handleTab = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Paper elevation={0}>
        <Grid
          container
          // alignItems="flex-start"
        >
          <Grid item xs={5}></Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Avatar
              alt="My Company Profile"
              className={classes.avatarMyCompany}
            >
              <Typography variant="h4" className={classes.titleMyCompanyLogo}>
                P
              </Typography>
            </Avatar>

            <br />
            <Typography variant="h5" className={classes.titleEmployeeHeader}>
              <b>{namaDepan !== "" ? namaDepan + " " + namaBelakang : ""} </b>
            </Typography>

            <Typography
              variant="subtitle1"
              className={classes.titleEmployeeHeaderJabatan}
            >
              <b>-Developer</b>
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ textAlign: "right" }}>
            {/* <IconButton className={classes.iconEdit}>
                                <i className='material-icons'>edit</i>
                            </IconButton>  */}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6} style={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              size="small"
              className={classes.buttonInvite}
            >
              Invite &nbsp;<b> @{namaPanggilan !== "" ? namaPanggilan : ""}</b>
            </Button>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Paper>

      {!isTopOfPage ? (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      ) : null}

      <Tabs
        selectedIndex={tabIndex}
        onSelect={(tabIndex) => handleTab(tabIndex)}
        style={{
          backgroundColor: "white",
          marginTop: "-48px",
          textAlign: "center",
        }}
      >
        <TabList>
          <Tab>
            <Typography variant="subtitle2" className={classes.title}>
              <b>Identitas</b>
            </Typography>
          </Tab>
          {/* <Tab>
            <Typography variant="subtitle2" className={classes.title}>
              <b>Keluarga</b>
            </Typography>
          </Tab> */}
        </TabList>
        <TabPanel style={{ backgroundColor: "#white" }}>
          <Identitas classes={classes} />
        </TabPanel>

        {/* <TabPanel>
          <Keluarga classes={classes} />
        </TabPanel>
         */}
      </Tabs>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewProfileDetail);
