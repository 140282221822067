import React, { useState, useContext, useEffect } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  CircularProgress,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";

import ContextProject from "../../context/ContextProject";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

import "./style/title.css";
import classNames from "classnames";

const Title = ({
  classes,
  title,
  listId,
  cardsCount,
  loadList,
  phaseCollection,
  onTriggerPhase,
}) => {
  const project_id = window.location.pathname.split("/")[2];
  const { dataKanban, setDataKanban, setTriggerKanban } =
    useContext(ContextProject);

  const [open, setOpen] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [newTitle, setNewTitle] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    setNewTitle(title);
  }, [title]);

  const handleShow = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteList = (id) => {
    handleClose();
    deleteList(id);
  };
  const handleEditCardTitle = () => {
    handleClose();
    setOpenOptions(!openOptions);
    setOpen(!open);
  };

  const updateListTitle = (title, listId) => {
    const list = dataKanban.lists[listId];
    list.title = title;

    const newState = {
      ...dataKanban,
      lists: {
        ...dataKanban.lists,
        [listId]: list,
      },
    };

    setDataKanban(newState);
  };

  const deleteList = (listId) => {
    const lists = dataKanban.lists;
    const listIds = dataKanban.listIds;

    delete lists[listId];

    listIds.splice(listIds.indexOf(listId), 1);

    const newState = {
      lists: lists,
      listIds: listIds,
    };

    setDataKanban(newState);
  };

  const handleOnBlur = () => {
    updateListTitle(newTitle, listId);

    const list = dataKanban.lists[listId];
    const listTitle = list.title;
    const objCustomPhase = phaseCollection?.find((el) => el.title === title);

    // // console.log("listTitle", listTitle);
    // // console.log("title", title);
    // // console.log("newTitle", newTitle);
    // // console.log("phaseCollection", phaseCollection);
    // // console.log("objCustomPhase", objCustomPhase);

    const data = {
      title: listTitle,
    };

    AxiosConfig.put(
      `${URL_API}/project/${project_id}/custom-phase/${objCustomPhase?.id}`,
      data
    )
      .then((res) => {
        // // console.log("Update phase", res);

        if (res.status === 200) {
          onTriggerPhase(Math.floor(Math.random() * 100));
          setTriggerKanban(Math.floor(Math.random() * 100));
          setOpen(!open);
        }
      })
      .catch((error) => {
        // console.log("ERROR", error);
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // // console.log("listId", listId);
  // // console.log("dataKanban", dataKanban);
  // // console.log("newTitle", newTitle);

  return (
    <>
      {open ? (
        <div>
          {/* <TextField
            type="text"
            className="input-title"
            value={newTitle}
            onChange={(e) => {
              setNewTitle(e.target.value);
            }}
            disabled
            onBlur={handleOnBlur}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleOnBlur();
              }
              return;
            }}
            autoFocus
            size="small"
            variant="standard"
          /> */}
          <Typography variant="subtitle1" className={classes.title555}>
            {newTitle}
          </Typography>
        </div>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {/* <h2 onClick={() => setOpen(!open)} className="editable-title"> */}
            <Typography
              variant="subtitle1"
              className={classNames.title555}
              // onClick={() => setOpen(!open)}
            >
              {title} ({cardsCount})
            </Typography>
            {loadList && (
              <CircularProgress style={{ marginLeft: 8 }} size={20} />
            )}
          </Box>
          {/* <IconButton size="small" onClick={handleShow}>
            <MoreVertIcon />
          </IconButton> */}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleDeleteList(listId)}
              style={{ fontSize: 16 }}
            >
              Delete list
            </MenuItem>
            <MenuItem onClick={handleEditCardTitle} style={{ fontSize: 16 }}>
              Edit card title
            </MenuItem>
          </Menu>
        </Box>
      )}

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default Title;
