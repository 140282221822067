import React, { Fragment, useEffect, useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Grid,
  DialogActions,
  Button,
  Chip,
  IconButton,
  ListItemText,
  createMuiTheme
} from '@material-ui/core';
import numeral from 'numeral';
import moment from 'moment';
import {
  DateRange as IconDateRange,
  HighlightOff as HighlightOffIcon,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { ThemeProvider } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { URL_API } from '../../../../constants/config-api';
import axiosConfig from '../../../../constants/config-axios';
import DialogError from '../../../../components/DialogError';
import DialogDuplicateMAv2 from './DialogDuplicateMAv2';
import ContextAllMA from '../context/AllMaContext';

import LightTooltip from '../../../../components/LightTooltip';
import DialogDateRange from './DialogDateRange';
import {
  StyledMenu,
  StyledMenuItem
} from '../../../../components/StyledMenuDropdown';
import handleError from '../../../Report/components/global/handleError';
import DialogRemarksLog from '../../../../components/DialogRemarksLog';

// CLASS Local Time Utilities
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD MMMM YYYY', { locale: this.locale });
  }
}

function DialogDuplicate({
  classes,
  item,
  isModalDuplicate,
  setModalDuplicate
}) {
  /* ===============================================
     IMPORTANT VARIABLE
    ================================================= */
  const context = useContext(ContextAllMA);
  const status_login = localStorage.getItem('status_user_login');
  const user = JSON.parse(status_login);

  const startDateRange = localStorage.getItem('start_date');
  const endDateRange = localStorage.getItem('end_date');

  /* ===============================================
     STATE COMPONENT
    ================================================= */
  const [userTokenState, setUserTokenState] = useState('');
  const [maData, setMAData] = useState({
    id: '',
    name: '',
    owner_id: '',
    achievement_type_id: '',
    start_value: '',
    target_value: null,
    input_method_id: '',
    calculation_method_id: '',
    start_date: '',
    end_date: '',
    time_frame_id: null,
    description: '',
    value_type_id: '',
    period_id: null,
    target_type_id: '',
    is_evidence_required: null,
    period: {
      end_date: '',
      id: '',
      name: '',
      start_date: ''
    },
    owner: {
      id: '',
      member_id: '',
      member_first_name: '',
      member_last_name: '',
      user_id: '',
      user_email: ''
    },
    achievementType: {
      id: '',
      code: '',
      name: ''
    },
    inputMethod: {
      id: '',
      code: '',
      name: ''
    },
    calculationMethod: {
      id: '',
      code: '',
      name: ''
    },
    valueType: {
      id: '',
      code: '',
      name: ''
    },
    targetType: {
      id: '',
      code: '',
      name: ''
    }
  });

  const [timeFrame, setTimeFrame] = useState([
    {
      id: '1',
      code: 'period',
      name: 'period'
    },
    {
      id: '2',
      code: 'date_range',
      name: 'date range'
    },
    {
      id: '3',
      code: 'due_date',
      name: 'due date'
    }
  ]);

  const [loading, setLoading] = useState(false);
  const [loadBtn, setLoadBtn] = useState(false);
  const [openDuplicateMA, setOpenDuplicateMA] = useState(false);
  /*
        `````````````````````
        HANDLE TIME FRAME ID

        - idDueDate: 3,
        - idDateRange: 2,
        - idPeriod: 1

        `````````````````````
    */
  const [timeFrameId, setTimeFrameId] = useState(null);

  // TIME FRAME STATE
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);
  const [textValueTimeFrame, setTextValueTimeFrame] =
    useState('Pilih time frame');

  // SELEECT DUE DATE STATE
  const [selectedDueDate, setSelectedDueDate] = useState('');
  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);

  // START - END | DATE RANGE
  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);

  // DATE RANGE OPTION STATE
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  // PERIOD OPTION STATE
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false);
  const [isShowTextPeriod, setShowTextPeriod] = useState(false);

  // CHOOSE PERIOD
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  const [periodId, setPeriodId] = useState('');
  const [periodName, setPeriodName] = useState('');
  const [periodCollections, setPeriodCollections] = useState([]);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    'whoops something went wrong'
  );

  const [errorDuplicate, setErrorDuplicate] = useState(false);
  const [errorTextDuplicate, setErrorTextDuplicate] = useState('');

  /* ===============================================
     API REQUEST
    ================================================= */

  useEffect(() => {
    // setUserTokenState(userToken);
    if (isModalDuplicate === true) {
      setLoading(true);
      axiosConfig
        .get(`${URL_API}/swd/measured-activity/${item.id}:duplicate`)
        .then((response) => {
          if (response.status === 200) {
            const result = response.data.data;
            // console.log("DupDup MA", result);

            setMAData(result.measuredActivity);
            setTimeFrame(result.timeFrameCollections);
            context.setErrorDuplicate(false);
            setErrorDuplicate(false);

            if (result.measuredActivity.period !== null) {
              setShowTextPeriod(true);
              setShowTextDateRange(false);
              setPeriodName(result.measuredActivity.period.name);
            }
            setPeriodCollections(result.periodCollections);

            // console.log("RESPONSE MA DATA", result.measuredActivity);
          }
          setLoading(false);
        })
        .catch((error) => {
          // // console.log("Error Geting Duplicate MA", error);

          if (error.response?.status === 422) {
            setErrorDuplicate(true);
            setErrorTextDuplicate(
              error.response.data.info.errors[0].description
            );
          }
        });
    }
  }, [isModalDuplicate]);

  const handleSubmitDuplicate = () => {
    setLoadBtn(true);

    let device_os = 'Not known';
    if (navigator.appVersion.indexOf('Win') !== -1) device_os = 'Windows OS';
    if (navigator.appVersion.indexOf('Mac') !== -1) device_os = 'Mac OS';
    if (navigator.appVersion.indexOf('X11') !== -1) device_os = 'UNIX OS';
    if (navigator.appVersion.indexOf('Linux') !== -1) device_os = 'Linux OS';

    let OSName = 'Unknown';
    if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1)
      OSName = 'Windows 10';
    if (window.navigator.userAgent.indexOf('Windows NT 6.3') != -1)
      OSName = 'Windows 8.1';
    if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1)
      OSName = 'Windows 8';
    if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1)
      OSName = 'Windows 7';
    if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1)
      OSName = 'Windows Vista';
    if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1)
      OSName = 'Windows XP';
    if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1)
      OSName = 'Windows 2000';
    if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac/iOS';
    if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
    if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';

    let data = {
      MeasuredActivity: {
        name: maData.name,
        description: maData.description,
        // owner_id: ownerList.length > 0 && ownerId === '' ?  ownerList[0].id : ownerId,
        owner_id: maData.owner_id,

        input_method_id: maData.input_method_id,
        achievement_type_id: maData.achievement_type_id,
        calculation_method_id: maData.calculation_method_id,
        value_type_id: maData.value_type_id,
        target_type_id: maData.target_type_id,
        start_date:
          startDateRangeState !== null
            ? startDateRangeState
            : maData.start_date,
        end_date:
          endDateRangeState !== null
            ? endDateRangeState
            : selectedDueDate !== ''
            ? moment(selectedDueDate).format('YYYY-MM-DD')
            : maData.end_date,
        start_value: maData.start_value !== '0' ? maData.start_value : 0,
        target_value: maData.target_value,
        time_frame_id:
          maData.time_frame_id !== null ? maData.time_frame_id : null,
        is_evidence_required: maData.is_evidence_required,
        period_id: maData.period_id !== null ? maData.period_id : null,
        goal_id: maData.goal_id
      }
    };

    if (data.MeasuredActivity.period_id === null) {
      delete data.MeasuredActivity.period_id;
    }

    if (
      data.MeasuredActivity.period_id !== null &&
      data.MeasuredActivity.start_date === null &&
      data.MeasuredActivity.end_date === 'Invalid date'
    ) {
      delete data.MeasuredActivity.start_date;
      delete data.MeasuredActivity.end_date;
    }

    if (data.MeasuredActivity.start_date === null) {
      delete data.MeasuredActivity.start_date;
    }

    if (
      data.MeasuredActivity.target_range_value === null ||
      data.MeasuredActivity.target_range_value === ''
    ) {
      delete data.MeasuredActivity.target_range_value;
    }

    if (
      data.MeasuredActivity.period_id === null ||
      data.MeasuredActivity.period_id === ''
    ) {
      delete data.MeasuredActivity.period_id;
    }

    // console.log("Data SUBMIT : ", data);

    axiosConfig
      .post(`${URL_API}/swd/measured-activity`, data)
      .then((response) => {
        const result = response.data.data;
        // // console.log("SUCCESS SUBMIT DUPLICATE : ", response);

        const dataLog = {
          user_activity_log: {
            module: 'ma',
            event: 'update',
            user_id: user?.user_id,
            user_email: user?.user_email,
            nama: `${user?.member_first_name} ${user?.member_last_name}`,
            position:
              user?.member_position?.structure_position_title_name === undefined
                ? ''
                : user?.member_position?.structure_position_title_name,
            device_type: device_os,
            device_model: '',
            device_version: OSName,
            long: '',
            lat: '',
            entity_id: result?.id,
            entity_name: result?.name,
            remarks: 'Duplicate Measured Activity 1',
            comment: ''
          }
        };

        setDataRemarks(dataLog);

        if (response.status === 200) {
          // context.setSuccessDuplicate(Math.floor(Math.random() * 1000 + 1));
          // context.setMessages("Measured Activity Berhasil disalin!");
          // context.setModalResponse200(true);
          // setModalDuplicate(false);
          setOpenRemarksLog(true);
        }
        setLoadBtn(false);
      })
      .catch((error) => {
        // console.log("EROR POST DUPLICATE", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty('message')) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
          setLoadBtn(false);
        } else {
          setOpenDialogError(true);
          setTextErrorMessage('Whoops, something went wrong !');
        }
      });
  };

  // GET START & END DATE RANGE
  useEffect(() => {
    setStartDateRangeState(startDateRange);
    setEndDateRangeState(endDateRange);
  }, [startDateRange, endDateRange]);

  /* ===============================================
       HANDLE FUNCTION
      ================================================= */

  // Function For Handling Dialog EDIT DUPLICATE is Show or Not
  function handleDialogEditDuplicatev2() {
    setModalDuplicate(false);
    setOpenDuplicateMA(true);
  }

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  // Handle Choose Due Date
  const handleChooseDueDate = () => {
    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !
    setTimeFrameId('3');

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);
    setShowTextPeriod(false);
    setTextValueTimeFrame('Due Date');

    localStorage.removeItem('start_date');
    localStorage.removeItem('end_date');
  };

  // Handle Choose Date Range
  const handleChooseRange = () => {
    setTimeFrameId('2');
    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);
    setShowTextPeriod(false);
    setShowDateRangeComponent(true);
    setShowTextDateRange(true);

    setTextValueTimeFrame('Date Range');
  };

  // Handle Choose Period
  const handleChoosePeriod = (event) => {
    setTimeFrameId('1');
    setAnchorElPeriod(event.currentTarget);

    //*
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame('Period');

    localStorage.removeItem('start_date');
    localStorage.removeItem('end_date');

    setShowPeriodComponent(true);
    setShowTextPeriod(true);
    setSelectedDueDate(null);
  };

  // Handle Click Period
  function handleClickPeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'
    setAnchorElPeriod(event.currentTarget);
  }

  // Handle Close Period
  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  // Handle Choose Period
  const handleChoosePeriodId = (e, item) => {
    e.preventDefault();
    // // console.log("Item : ", item);

    setStartDateRangeState(item.start_date);
    setEndDateRangeState(item.end_date);

    setPeriodId(item.id);
    setPeriodName(item.name);

    handleClosePeriod();
    setShowTextPeriod(true);
  };

  // // console.log("period col", periodCollections);

  return (
    <Fragment>
      <Dialog
        open={isModalDuplicate}
        aria-labelledby="max-width-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="max-width-dialog-title">
          <Grid container direction="row" justify="space-between">
            <Typography variant="h6">
              <b>Duplicate Measure Activity</b>
              {errorDuplicate && (
                <p style={{ color: '#c62828', fontSize: 14, margin: 0 }}>
                  * {errorTextDuplicate}
                </p>
              )}
            </Typography>

            <IconButton size="small" onClick={() => setModalDuplicate(false)}>
              <HighlightOffIcon style={{ color: 'grey', fontSize: '24px' }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box bgcolor="#eee">
            <Grid container>
              <Grid item sm={4}>
                <Box style={{ margin: 15 }}>
                  <Typography variant="subtitle1">
                    <b>Nama Measure Activity</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? <CircularProgress size={14} /> : maData.name}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Deskripsi</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : maData.description !== null ? (
                      maData.description
                    ) : (
                      <span
                        style={{
                          fontStyle: 'italic',
                          color: '#aaa',
                          fontWeight: 'lighter'
                        }}
                      >
                        (no description)
                      </span>
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Achievement Type</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      maData.achievementType.name
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Target Type</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      maData.targetType.name
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box style={{ margin: 15 }}>
                  <Typography variant="subtitle1">
                    <b>Goal</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      maData.goal && maData.goal.name
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Input Method</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      maData.inputMethod.name
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Start Value</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      numeral(maData.start_value).format('0.00')
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Target Value</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      numeral(maData.target_value).format('0.00')
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box style={{ margin: 15 }}>
                  <Typography variant="subtitle1">
                    <b>Owner</b>
                  </Typography>
                  {loading ? (
                    <CircularProgress size={14} />
                  ) : maData.owner_id === '-1' ? (
                    <Typography
                      variant="subtitle2"
                      style={{ color: '#cc0707' }}
                    >
                      Owner ini bukan subordinate anda
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      {maData.owner?.member_first_name}{' '}
                      {maData.owner?.member_last_name}
                    </Typography>
                  )}
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Calculation Method</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : maData.calculation_method_id === '1' ? (
                      'Total'
                    ) : maData.calculation_method_id === '2' ? (
                      'Rata-rata'
                    ) : (
                      'Nilai Akhir'
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Value Type</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      maData.valueType.name
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Wajib Upload Bukti</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : maData.is_evidence_required === 1 ? (
                      'Ya'
                    ) : (
                      'Tidak'
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginBottom: 15 }}>
            <Grid container justify="space-between" alignItems="flex-start">
              <Typography variant="subtitle1" style={{ marginTop: 15 }}>
                <b>Time Frame</b>
              </Typography>
              <Box>
                <Button
                  onClick={handleDialogEditDuplicatev2}
                  disabled={
                    maData.owner_id === '-1'
                      ? true
                      : errorDuplicate
                      ? true
                      : false
                  }
                  style={{ textTransform: 'capitalize', fontWeight: '600' }}
                >
                  <EditOutlinedIcon style={{ fontSize: 17, marginRight: 8 }} />
                  Edit Details
                </Button>
              </Box>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ marginTop: 5 }}
            >
              {/* USER CHOOSE TIME FRAME */}
              <Button
                onClick={handleClickTimeFrame}
                disabled={
                  maData.owner_id === '-1'
                    ? true
                    : errorDuplicate
                    ? true
                    : false
                }
                variant="outlined"
                size="small"
                className={classes.timeFrameButonDuplicate}
              >
                <b>{textValueTimeFrame}</b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}
              >
                <StyledMenuItem onClick={handleChooseDueDate}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChooseRange}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem
                  // onClick={handleClickPeriod}
                  onClick={handleChoosePeriod}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              {/* MENU LIST PERIOD */}
              <StyledMenu
                id="customized-menu-period"
                anchorEl={anchorElPeriod}
                keepMounted
                open={Boolean(anchorElPeriod)}
                onClose={handleClosePeriod}
                style={{ marginTop: '-160px' }}
              >
                {isShowPeriodComponent === true &&
                periodCollections.length > 0 ? (
                  periodCollections.map((item, i) => (
                    <StyledMenuItem
                      key={i}
                      onClick={(e) => handleChoosePeriodId(e, item)}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>{item.name}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  ))
                ) : (
                  <StyledMenuItem disabled>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>Anda belum memiliki periode ;(</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
              </StyledMenu>

              {isShowDateRangeComponent && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                  />
                </Fragment>
              )}

              <Typography>
                {isShowTextDateRange === true ? (
                  <Chip
                    icon={<IconDateRange />}
                    label={`${moment(startDateRangeState).format(
                      'DD MMMM YYYY'
                    )} - ${moment(endDateRangeState).format('DD MMMM YYYY')}`}
                    clickable
                    size="medium"
                  />
                ) : isShowDueDateComponent === true ? (
                  <MuiPickersUtilsProvider utils={LocalizedUtils}>
                    <ThemeProvider theme={theme}>
                      <Fragment>
                        <DatePicker
                          value={selectedDueDate}
                          onChange={setSelectedDueDate}
                          animateYearScrolling
                          open={isShowDueDateComponentDirectly}
                          onOpen={() => setShowDueDateComponentDirectly(true)}
                          onClose={() => setShowDueDateComponentDirectly(false)}
                          variant="dialog" // dialog, static, inline
                          disableToolbar={false}
                          format="DD MMMM YYYY"
                          label=""
                          style={{ marginLeft: 16 }}
                        />
                      </Fragment>
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                ) : isShowTextPeriod && !isShowTextDateRange ? (
                  <Chip
                    icon={<IconDateRange />}
                    label={periodName}
                    size="medium"
                  />
                ) : (
                  <Chip
                    icon={<IconDateRange />}
                    label={`${maData.start_date} - ${maData.end_date}`}
                    size="medium"
                  />
                )}
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {maData.owner_id === '-1' && (
            <Box>
              <Typography
                variant="subtitle2"
                style={{ color: '#cc0707', fontSize: 12 }}
              >
                * Tidak bisa Duplicate MA
              </Typography>
            </Box>
          )}
          <Box style={{ margin: 15 }}>
            <Button
              className={classes.button3}
              variant="contained"
              disableElevation
              onClick={() => setModalDuplicate(false)}
            >
              Batal
            </Button>

            {loadBtn ? (
              <Button
                style={{ width: 95 }}
                className={classes.button2}
                variant="contained"
              >
                <CircularProgress color="#fff" size={24} />
              </Button>
            ) : (
              <LightTooltip title="Salin langsung" placement="top">
                <Button
                  disabled={
                    maData.owner_id === '-1'
                      ? true
                      : errorDuplicate
                      ? true
                      : false
                  }
                  style={{ width: 95 }}
                  className={classes.button2}
                  variant="contained"
                  onClick={handleSubmitDuplicate}
                >
                  Duplicate
                </Button>
              </LightTooltip>
            )}
          </Box>
        </DialogActions>
      </Dialog>

      <DialogDuplicateMAv2
        classes={classes}
        maData={maData}
        openDuplicateMA={openDuplicateMA}
        setOpenDuplicateMA={setOpenDuplicateMA}
      />

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setModalDuplicate}
        onTriggerRemarks={context.setSuccessDuplicate}
        editRemarks={true}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default DialogDuplicate;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cc0707', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff'
    }
  },

  typography: {
    fontFamily: 'Roboto',
    textTransform: 'capitalize'
  },
  textfield: {
    width: 200
  }
});
