import React, { Component, useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  MenuItem,
  Popover,
  Fab,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import moment from "moment";

import DialogModal409 from "./ComponentsDialogModal/DialogModal409";
import DialogModal422 from "./ComponentsDialogModal/DialogModal422";

import Snackbeer from "../../../components/Snackbeer";

import PictPaketGold from "../../../assets/images/SVG/Group_2.svg";

import Redirect from "../../../utilities/Redirect";
import { URL_API } from "../../../constants/config-api";
import PictInfo from "../../../assets/images/icon-info-24px.svg";

import {
  ToMembershipStatusDeactivate,
  ToMembershipStatusIncreaseQuotaSEMENTARA,
  ToMembershipStatusScenarioPENDING_SEMENTARA,
} from "../../../constants/config-redirect-url";
// import './backgroundPaper.css';

const numeral = require("numeral");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 2,
  },
  subRoot2: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  subRoot3: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  button: {
    width: "503px",
    height: "42px",
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    // backgroundColor: 'cyan',
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
  },
  title: {
    color: "#5a5959",
    fontFamily: "Roboto",
  },
  titleDialog1: {
    fontFamily: "Roboto",
    marginBottom: theme.spacing(1),
  },
  titleDialog2: {
    fontFamily: "Roboto",
    marginBottom: theme.spacing(1),
  },
  titleDialog3: {
    fontFamily: "Roboto",
    color: "#07cc56",
    fontWeight: "bold",
  },
  titleDialog4: {
    fontFamily: "Roboto",
  },
  titleMenangguhkan: {
    fontFamily: "Roboto",
    color: "#333333",
    marginTop: theme.spacing(16),
    marginLeft: theme.spacing(4),
  },
  titleBack: {
    fontFamily: "Roboto",
    color: "#333333",
    marginTop: theme.spacing(16),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    cursor: "pointer",
    fontWeight: "bold",
  },
  leftSentence: {
    color: "#5a5959",
    fontFamily: "Roboto",
  },
  statusChip: {
    backgroundColor: "#24d872",
    color: "white",
    padding: 3,
  },
  statusChipPending: {
    backgroundColor: "yellow",
    color: "grey",
    padding: 3,
  },
  buttonTambahUser: {
    // marginRight:theme.spacing(4),
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "grey",
  },
  buttonPerpanjangNow: {
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    color: "white",
    textTransform: "capitalize",
    marginRight: theme.spacing(1),
  },
  buttonModal: {
    // height: 32,
    borderRadius: 3,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    width: "100%",
    textTransform: "capitalize",
  },
  media: {
    height: 80,
    width: 80,
    // marginLeft: theme.spacing(7)
  },

  /* 
        ``````````````````````````````````
        DIALOG MODAL UPGRADE SAVE INVOICE
        
        ``````````````````````````````````
    */
  backgroundPaper: {
    background: 'url("../../../assets/images/Group_1922.png") no-repeat',
  },
});

const ViewMembershipStatusKelola = (props) => {
  const { classes } = props;

  /*
        ```````````````````````````````
        HANDLE DATA FROM LOCAL STORAGE 

        ```````````````````````````````

        *Di code ini ane harus-nya panggil API untuk AMBIL DETAIL dari Membership Status saat ini,

        Namun, sekarang untuk sementara panggin API collection history ! ;/

    */

  const [dataLocalStorage, setDataLocalStorage] = useState();

  const [userTokenState, setUserTokenState] = useState();

  const [dataFromCollectionHistory, setDataFromCollHistory] =
    useState(undefined);

  const [statusPayment, setStatusPayment] = useState("");
  const [masaBerlakuAwal, setMasaBerlakuAwal] = useState("");
  const [durasiMembership, setDurasiMembership] = useState("");
  const [durasiDurasiMembership, setDurasiDurasiMembership] = useState("");
  const [bankId, setBankId] = useState("");
  const [durasiMembershipID, setDurasiMembershipID] = useState("");

  const [discount, setDiscount] = useState("");

  const [updatedAtOke, setUpdatedAtOke] = useState("");

  useEffect(() => {
    const userTokenConst = localStorage.getItem("userToken");
    setUserTokenState(userTokenConst);

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userTokenConst,
    };

    axios.defaults.headers.common = header;

    axios
      .get(
        URL_API +
          `/group/membership/payment/history?options[embedded][]=membershipPaymentTracking`
      )
      .then(function (response) {
        // console.log("Response Original : ", response);

        if (response.status == 200) {
          // let dataCollcectionHistory = { //NEXT GANTI DENGAN DATA DETAIL

          //     membership_type_name: response.data.data[0].membership_type_name,
          //     membership_type_price: response.data.data[0].membership_type_price

          // };

          setDataFromCollHistory(response.data.data[0]);
          setStatusPayment(response.data.data[0].status_name);
          setMasaBerlakuAwal(response.data.data[0].created_at);
          setDurasiMembership(response.data.data[0].membership_duration_name);
          setDurasiDurasiMembership(
            response.data.data[0].membership_duration_duration
          );

          setBankId(response.data.data[0].bank_id);

          setDiscount(response.data.data[0].membership_duration_discount);
          setDurasiMembershipID(response.data.data[0].membership_duration_id);
        }
      })
      .catch(function (error) {
        // console.log("Error : ", error.response);
      });

    axios
      .get(URL_API + `/group/membership/premium/increase`)
      .then(function (response) {
        // console.log("Response Original : ", response);

        if (response.status == 200) {
          setUpdatedAtOke(response.data.data.updated_at);
        }
      })
      .catch(function (error) {
        // console.log("Error : ", error.response);
      });
  }, []);

  /*
        ```````````````````````
        HANDLE MODAL ADD USER

        ```````````````````````
    */
  const [isModalAddUser, setModalAddUser] = useState(false);

  const handleModalAddUser = () => {
    setModalAddUser(true);
  };

  const closeModalTambahUser = () => {
    setModalAddUser(false);

    let data = {
      GroupMembership: {
        updated_at: updatedAtOke,
        bank_id: bankId !== undefined ? bankId : "",
        user_count: jumlahUser,
      },
    };

    //***
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userTokenState,
    };

    axios.defaults.headers.common = header;

    axios
      .put(URL_API + `/group/membership/premium/increase`, data)
      .then(function (response) {
        // console.log("Response Original : ", response);
        setModalResponse200(true);
      })
      .catch(function (error) {
        // console.log("Error : ", error.response);

        if (error.response.status == 409) {
          setResponse409(true);
          setInfoResponseError409(error.response.data.info.message);
        }

        if (error.response.status == 422) {
          setResponse422(true);
          setInfoResponseError422(error.response.data.info.message);
        }
      });
  };

  /*
        `````````````````````````
        HANDLE MODAL EXTEND NOW

        `````````````````````````
    */
  const [isExtendNow, setExtendNow] = useState(false);

  const handleExtendNow = () => {
    setExtendNow(false);

    let data = {
      GroupMembership: {
        updated_at: updatedAtOke,
        bank_id: bankId !== undefined ? bankId : "",
        membership_duration_id:
          durasiMembershipID !== "" ? durasiMembershipID : "",
      },
    };

    //***
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userTokenState,
    };

    axios.defaults.headers.common = header;

    axios
      .put(URL_API + `/group/membership/premium/extend`, data)
      .then(function (response) {
        // console.log("Response Original : ", response);
        setModalResponse200(true);
      })
      .catch(function (error) {
        if (error.response !== undefined) {
          // console.log("Error : ", error.response);

          if (error.response.status == 422) {
            setResponse422(true);
            setInfoResponseError422(error.response.data.info.message);
          }

          if (error.response.status == 409) {
            setResponse409(true);
            setInfoResponseError409(error.response.data.info.message);
          }

          if (error.response.status == 500) {
            alert(
              " Whoops, something went wrong !" +
                "(" +
                error.response.status +
                ")"
            );
          }
        } else {
          alert("Whoops, something went wrong !");
        }
      });
  };

  const handleRedirectToDeactivate = () =>
    Redirect(ToMembershipStatusDeactivate);

  /*
        ````````````````````````````````
        HANDLE AKTIFKAN / CHECK PAYMENT

        ````````````````````````````````
    */

  const [isAktifkan, setAktifkan] = useState(false);
  const handleAktifkan = () => {
    // alert("API AKTIFKAN MANUAL still develope ! ");
    let invoiceNumber =
      dataFromCollectionHistory !== undefined
        ? dataFromCollectionHistory.invoice_number
        : undefined;

    setAktifkan(true);

    //***
    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + userTokenState,
    };

    axios.defaults.headers.common = header;

    axios
      .get(URL_API + `/dev/payment/force-complete/${invoiceNumber}`)
      .then(function (response) {
        window.location.reload();
        // console.log("Response Original : ", response);
      })
      .catch(function (error) {
        window.location.reload();
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status == 422) {
            setResponse422(true);
            setInfoResponseError422(error.response.data.info.message);
          }
        } else {
          alert("Whoops, something went wrong !");
        }
      });
  };

  /*
        ```````````````````````````````````````
        HANDLE BERAPA JUMLAH USER IN INPUT TEXT
        
        ```````````````````````````````````````
    */

  const [totalPriceAfterPerkalianDenganJumlahUser, setTotalPrice] =
    useState(undefined);
  const [jumlahUser, setJumlahUser] = useState(0);
  const handleChangeUbahJumlahUser = (e) => {
    setJumlahUser(e.target.value);

    let jumlahUserNumber = e.target.value;

    console.log(jumlahUserNumber);

    const pricePerUser =
      dataFromCollectionHistory !== undefined
        ? numeral(dataFromCollectionHistory.membership_type_price).format("0")
        : 75000;
    // console.log("pricePerUser :", pricePerUser);
    setTotalPrice(parseInt(jumlahUserNumber) * pricePerUser);
  };

  /*

        `````````````````````````
        HANDLE TAMBAH ONE

        `````````````````````````

    */

  const handleTambahOne = () => {
    setJumlahUser(jumlahUser + 1);
  };

  /*

        `````````````````````````
        HANDLE KURANG ONE

        `````````````````````````

    */

  const handleKurangOne = () => {
    if (jumlahUser === 0) {
      setJumlahUser(0);
    } else {
      setJumlahUser(jumlahUser - 1);
    }
  };

  useEffect(() => {
    const pricePerUser =
      dataFromCollectionHistory !== undefined
        ? numeral(dataFromCollectionHistory.membership_type_price).format("0")
        : 75000;

    setTotalPrice(jumlahUser * pricePerUser);
  }, [jumlahUser]);

  /*
        ```````````````````````````````````
        HANDKE RESPONSE MODAL 422, 409, 200
        
        ```````````````````````````````````
    */

  const [isResponse422, setResponse422] = useState(false);
  const [infoResponseError422, setInfoResponseError422] = useState("");

  const [isResponse409, setResponse409] = useState(false);
  const [infoResponseError409, setInfoResponseError409] = useState("");

  const [isModalResponse200, setModalResponse200] = useState(false);

  /*
        ````````````````````````````````
        HANDLE NEXT BIRTHDAY IN MOMENT JS

        ````````````````````````````````

    */
  function daysRemaining() {
    var eventdate = moment(masaBerlakuAwal).add(
      durasiDurasiMembership !== "1y"
        ? parseInt(durasiMembership.charAt(0))
        : 12,
      "months"
    );
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Paper className={classes.root} elevation={0}>
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.title}>
              Kelola Keanggotaan
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.subRoot2} elevation={8}>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <br />
            <br />
            <Typography variant="subtitle1" className={classes.leftSentence}>
              Paket Keanggotaan &#8287;&#8287;&#8287;&#8287;&#8287;: &#8287;
              &#8287; &#8287;
              <b>
                {dataFromCollectionHistory !== undefined
                  ? dataFromCollectionHistory.membership_type_name
                  : "***"}
              </b>
              <img
                src={PictPaketGold}
                alt="Gold Image"
                style={{ width: 20, height: 20 }}
              />
            </Typography>
            <Typography variant="subtitle1" className={classes.leftSentence}>
              Jumlah User
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;:
              &#8287; &#8287; &#8287;
              <b>
                {
                  // dataFromCollectionHistory !== undefined ? dataFromCollectionHistory.membership_type_user_count + ' User' : ' *** User'
                  dataFromCollectionHistory !== undefined
                    ? dataFromCollectionHistory.total_user + " User"
                    : " *** User"
                }
              </b>
              {console.log(dataFromCollectionHistory)}
              <a href="#" style={{ textDecoration: "none", marginLeft: 17 }}>
                Detail
              </a>
            </Typography>
            <Typography variant="subtitle1" className={classes.leftSentence}>
              Durasi
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;:
              &#8287; &#8287; &#8287;
              <b>
                {durasiDurasiMembership !== "1y"
                  ? durasiMembership.charAt(0) + " Bulan"
                  : "12 Bulan"}
              </b>
              <a href="#" style={{ textDecoration: "none", marginLeft: 24 }}>
                Detail
              </a>
            </Typography>
            <Typography variant="subtitle1" className={classes.leftSentence}>
              Masa Berlaku Awal &#8287;&#8287;&#8287;&#8287;&#8287;: &#8287;
              &#8287; &#8287;
              <b>{moment(masaBerlakuAwal).format("DD MMMM YYYY")}</b>
            </Typography>
            <Typography variant="subtitle1" className={classes.leftSentence}>
              Total Harga
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;:
              &#8287; &#8287; &#8287;
              {/* <b>Rp 12.000.000</b> */}
              Rp{" "}
              {dataFromCollectionHistory !== undefined
                ? numeral(
                    dataFromCollectionHistory.total_price_after_unique
                  ).format("0,0")
                : ""}
              {/* Rp {dataLocalStorage !== undefined ? numeral(dataLocalStorage.nominal_after_discount).format('0,0') : ''} */}
            </Typography>
            `
          </Grid>

          <Grid item xs={6}>
            <br />
            <br />
            <Typography variant="subtitle1" className={classes.title}>
              Status
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;
              : &#8287;&#8287;
              <Chip
                className={classes.statusChipPending}
                label={
                  dataFromCollectionHistory !== undefined
                    ? dataFromCollectionHistory.status_name
                    : ""
                }
              />
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Sisa Masa Berlaku
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;
              :&#8287;&#8287;
              {/* <b>*** Hari Lagi</b> */}
              <b>{daysRemaining() + " Hari lagi "}</b>
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Jatuh Tempo
              &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;&#8287;
              : &#8287;&#8287;
              <b>
                {moment(masaBerlakuAwal)
                  .add(
                    durasiDurasiMembership !== "1y"
                      ? parseInt(durasiMembership.charAt(0)) + 1
                      : 13,
                    "months"
                  )
                  .format("DD MMMM YYYY")}
              </b>
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Masa Berlaku Terakhir &#8287;&#8287;&#8287;&#8287;&#8287;&#8287;
              :&#8287;&#8287;
              <b>
                {moment(masaBerlakuAwal)
                  .add(
                    durasiDurasiMembership !== "1y"
                      ? parseInt(durasiMembership.charAt(0))
                      : 12,
                    "months"
                  )
                  .format("DD MMMM YYYY")}
              </b>
            </Typography>
            <Typography variant="subtitle1" className={classes.title}>
              Terakhir di tangguhkan &#8287;&#8287;&#8287;&#8287; :
              &#8287;&#8287;<b>-</b>
              <a href="#" style={{ textDecoration: "none", marginLeft: 24 }}>
                Detail
              </a>
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
      </Paper>

      <Paper className={classes.subRoot3} elevation={0}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {
              statusPayment == "completed" ? (
                <Button
                  variant="contained"
                  className={classes.buttonPerpanjangNow}
                  onClick={() => setExtendNow(true)}
                >
                  Perpanjang sekarang
                </Button>
              ) : null

              // (

              //     <Button
              //         variant='contained'
              //         className={classes.buttonPerpanjangNow}
              //         onClick={handleAktifkan}
              //     >
              //         Check
              //     </Button>
              // )
            }

            {statusPayment == "completed" ? (
              <Button
                variant="outlined"
                className={classes.buttonTambahUser}
                onClick={handleModalAddUser}
              >
                + Tambah user
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.subRoot4} elevation={0}>
        <Grid container>
          <Grid item xs={6} style={{ textAlign: "left" }}>
            {statusPayment == "completed" ? (
              <Typography
                variant="subtitle2"
                className={classes.titleMenangguhkan}
              >
                Apakah anda ingin
                <span
                  onClick={handleRedirectToDeactivate}
                  style={{ color: "#c1272d", cursor: "pointer" }}
                >
                  &nbsp; menangguhkan masa aktif keanggotaan ?
                </span>
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography
              variant="subtitle1"
              className={classes.titleBack}
              onClick={() =>
                Redirect(ToMembershipStatusScenarioPENDING_SEMENTARA)
              }
            >
              Kembali
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* 

                ````````````````````
                INCREASE QUOTA USER

                ```````````````````

            */}

      <Dialog
        open={isModalAddUser}
        onClose={() => setModalAddUser(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center", paddingBottom: 0 }}
        >
          <Typography variant="h6" className={classes.titleDialog1}>
            <b>
              Tambah <i>User</i>
            </b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <img
            src={PictPaketGold}
            alt="icon-logo"
            style={{ width: 60, height: 60 }}
          />
          <Typography variant="h4" className={classes.titleDialog2}>
            <b>
              {dataFromCollectionHistory !== undefined
                ? dataFromCollectionHistory.membership_type_name
                : "***"}
            </b>
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            <b>
              Masa berlaku keanggotaan yang tersisa{" "}
              <u>{daysRemaining()} Hari lagi</u>
            </b>
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            Jumlah <i>user</i> yang di tambah :
            <IconButton
              color="grey"
              style={{ marginBottom: 7 }}
              onClick={handleKurangOne}
            >
              <i className="material-icons">remove_circle</i>
            </IconButton>
            <input
              onChange={handleChangeUbahJumlahUser}
              type="text"
              placeholder={jumlahUser}
              style={{
                width: 24,
                textAlign: "center",
                fontSize: 14,
                borderWidth: 0,
                borderColor: "transperent",
              }}
            />
            <IconButton
              onClick={handleTambahOne}
              color="grey"
              style={{ marginBottom: 7 }}
            >
              <i className="material-icons">control_point</i>
            </IconButton>
          </Typography>

          <Typography variant="subtitle1" className={classes.titleDialog3}>
            Total <i>User</i> sekarang : &nbsp;
            {dataFromCollectionHistory !== undefined
              ? parseInt(dataFromCollectionHistory.membership_type_user_count) +
                parseInt(jumlahUser) +
                " User"
              : " *** User"}
          </Typography>
          {/* <Typography variant="caption" className={classes.titleDialog2} style={{color: 'grey'}}>
                        *** User lagi untuk menjadi anggota silver
                    </Typography> 
                    <br />*/}
          <br />
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h4"
              className={classes.titleDialog4}
              style={{ color: "black" }}
            >
              <b>
                Rp{" "}
                {numeral(totalPriceAfterPerkalianDenganJumlahUser).format(0, 0)}
              </b>
            </Typography>
            <Typography
              variant="caption"
              className={classes.titleDialog4}
              style={{ color: "#eb7d00" }}
            >
              <b>
                Harga per user Rp{" "}
                {dataFromCollectionHistory !== undefined
                  ? numeral(
                      dataFromCollectionHistory.membership_type_price
                    ).format("0,0")
                  : "***"}
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={closeModalTambahUser}
            color="primary"
            size="large"
            className={classes.buttonModal}
          >
            Tambah
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      {/* 

                `````````````````
                EXTEND NOW

                `````````````````
                
            */}
      <Dialog
        open={isExtendNow}
        onClose={() => setExtendNow(false)}
        aria-labelledby="alert-dialog-title-extend"
        aria-describedby="alert-dialog-description-extend"
      >
        <DialogTitle
          id="alert-dialog-title-extend"
          style={{ textAlign: "center" }}
        >
          <Typography variant="h6" className={classes.titleDialog1}>
            <b>Perpanjang Keanggotaan</b>
          </Typography>
          <img
            src={PictPaketGold}
            alt="icon-logo"
            style={{ width: 60, height: 60 }}
          />
          <br />
          <Typography variant="h4" className={classes.titleDialog2}>
            <b>
              {dataFromCollectionHistory !== undefined
                ? dataFromCollectionHistory.membership_type_name
                : "***"}
            </b>
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            Jumlah User : &nbsp;
            <b>
              {dataFromCollectionHistory !== undefined
                ? dataFromCollectionHistory.total_user + " User"
                : " *** User"}
            </b>
          </Typography>

          <Typography variant="body2" className={classes.titleDialog2}>
            Durasi : &nbsp;
            <b>
              {durasiDurasiMembership !== "1y"
                ? durasiMembership.charAt(0) + " Bulan"
                : "12 Bulan"}
            </b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description-extend">
            <Typography
              variant="h4"
              className={classes.titleDialog4}
              style={{ color: "black" }}
            >
              {/* <b> Rp {dataLocalStorage !== undefined ? numeral(dataLocalStorage.nominal_after_discount).format('0,0') : ''}</b>  */}
              <b>
                Rp{" "}
                {dataFromCollectionHistory !== undefined
                  ? numeral(
                      dataFromCollectionHistory.total_price_after_unique
                    ).format("0,0")
                  : ""}
              </b>
            </Typography>
            <Typography
              variant="caption"
              className={classes.titleDialog4}
              style={{ color: "#eb7d00" }}
            >
              <b>Potongan {discount * 100} %</b>
            </Typography>
            <br />
            <Typography
              variant="caption"
              className={classes.titleDialog4}
              style={{ color: "#eb7d00" }}
            >
              <b>
                Harga per user Rp{" "}
                {dataFromCollectionHistory !== undefined
                  ? numeral(
                      dataFromCollectionHistory.membership_type_price
                    ).format("0,0")
                  : "***"}
              </b>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={handleExtendNow}
            color="primary"
            size="large"
            className={classes.buttonModal}
          >
            Perpanjang
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      {/* 
            
                `````````````````````````
                ERROR RESPONSE MODAL 422
                
                `````````````````````````    
            */}

      <DialogModal422
        classes={classes}
        isResponse422={isResponse422}
        setResponse422={setResponse422}
        infoResponseError422={infoResponseError422}
      />

      {/* 
            
                `````````````````````````
                ERROR RESPONSE MODAL 409
                
                `````````````````````````    
            */}

      <DialogModal409
        classes={classes}
        isResponse409={isResponse409}
        setResponse409={setResponse409}
        infoResponseError409={infoResponseError409}
      />

      {/* 
            
                ````````````
                RESPONSE 200
                
                ````````````
            */}
      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewMembershipStatusKelola);
