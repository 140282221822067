import React, { useEffect, useState, useContext, Fragment } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  Slide,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import EnhancedDeleteToolbar from "../ComponentSubGoal/EnhancedDeleteToolbar";

import ListItemSubGoal from "./ListItemSubGoal";
import DialogPilihUser from "./DialogPilihUser";
import DialogConfirmBeforePilihUser from "./DialogConfirmBeforePilihUser";

import DialogCreateAddDirectLink from "../ComponentGoalAddDirectLink/DialogCreateAddDirectLink";

import PictEmptyStateGoal from "../Components/PictEmptyStateGoal";

import { STATUS_COMPLETED } from "../../../../constants/config-status-goal";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import IconRedirect from "../../../../assets/images/icon_redirect.png";
import IconIndirect from "../../../../assets/images/indirect.png";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ListCollectionSubGoal = (props) => {
  const {
    classes,
    setFireGoalDetail,
    collectionGoalResultList,
    goalDetailState,
  } = props;

  const context = useContext(ContextGoalDetail);
  const userToken = localStorage.getItem("userToken");
  const locationPathGoalId = window.location.pathname.split("/")[2];
  const currentLoc = window.location.pathname;
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  /*
  ```````````````````
  COMPONENT DID MOUNT

  ```````````````````
  */
  const [loader, setLoader] = useState(false);

  const [goalIdLocal, setGoalIdLocal] = useState("");
  const [isStatusCompleted, setStatusCompleted] = useState(false);
  const [policy, setPolicy] = useState([]);

  //Anchor inDirect
  const [anchorElDirectInDirect, setAnchorElDirectInDirect] = useState(null);
  const [isModalConfirmBeforePilihUser, setModalConfirmBeforePilihUser] =
    useState(false);

  useEffect(() => {
    //*

    //*
    const goalDetail = localStorage.getItem("goal_detail");
    const goalDetailAfterParse = JSON.parse(goalDetail);

    if (goalDetailAfterParse !== null) {
      if (goalDetailAfterParse.id !== null) {
        setGoalIdLocal(goalDetailAfterParse.id);

        if (goalDetailAfterParse.status.code === STATUS_COMPLETED) {
          setStatusCompleted(true);
        }

        setPolicy(goalDetailAfterParse.policy);
      }
    }

    setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/swd/goal/${locationPathGoalId}/direct-link?options[filter][depth_level]=${context.nodeLevelGoal}`
      )
      .then(function (response) {
        // console.log("Response Original SUB-GOAL: ", response);

        if (response.data.data !== null && response.data.data !== undefined) {
          context.setListCollectionSubGoal(response.data.data);
        }
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("Error : ", error.response);
      });
  }, [currentLoc]);

  /*
        `````````````````````````````````````````
        HANDLE LIST AFTER SUCCESS CREATE SUB-GOAL

        `````````````````````````````````````````
    */
  useEffect(() => {
    setLoader(true);
    axiosConfig
      .get(
        URL_API +
          `/swd/goal/${locationPathGoalId}/direct-link?options[filter][depth_level]=${context.nodeLevelGoal}`
      )
      .then(function (response) {
        setLoader(false);
        // console.log("Response Original SUB-GOAL OTOMATIS UPDATE: ", response);

        if (response.data.data !== null && response.data.data !== undefined) {
          context.setListCollectionSubGoal(response.data.data);
        }
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("Error : ", error.response);
      });
  }, [
    context.isSuccessCreateSubGoal,
    context.isSuccessDeleteSubGoal,
    context.isSuccessSetAsComplete,
    currentLoc,
  ]);

  // // console.log("GOL DET ID", context.goalDetailStateGlobal.id);

  //*
  const [selected, setSelected] = useState([]);

  /* =========================
  HANDLE PILIH MAIN GOAL LIST IN ADD DIRECT LINK
    ============================= */
  const [isModalPilihUser, setModalPilihUser] = useState(false);
  const [isOpenDialogAddDirectLink, setOpenDialogAddDirectLink] =
    useState(false);

  /* =========================
  selected ID
  ============================= */
  const isSelected = (id) => selected.indexOf(id) !== -1;

  /* =========================
  selected All List Item
  ============================= */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = context.listCollectionSubGoal.map((n) => n.id);
      setSelected(newSelecteds);
      context.setListSubGoalId(newSelecteds);
      // // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  /* ===============================================================================================
    SETUP ALOWWED BUTTON DEPEND ON ROLE
=============================================================================================== */

  let directLinkCreateAllowed = false;

  goalDetailState.policy.forEach((item) => {
    if (item.actionName === "directLinkCreate") {
      directLinkCreateAllowed = item.result;
    }
  });

  const handleMenuDirectInDirect = (event) => {
    setAnchorElDirectInDirect(event.currentTarget);
  };

  const handleCloseMenuDirectInDirect = () => {
    setAnchorElDirectInDirect(null);
  };

  return (
    <Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={5}
        mb={1}
        px={2}
      >
        <Typography variant="h6" className={classes.title}>
          <b onClick={() => setModalPilihUser(true)}>Sub Goals</b>
        </Typography>

        {goalDetailState.goal_category === "OPERATIONAL" && superior && (
          <Box display="flex" alignItems="center">
            <Button
              disabled={isStatusCompleted ? true : false}
              onClick={handleMenuDirectInDirect}
              variant="outlined"
              className={classes.btnWhite}
              size="small"
              style={{ marginRight: 10 }}
              startIcon={<AddIcon />}
            >
              Create Sub Goal
            </Button>
            <Button
              disabled={isStatusCompleted === true ? true : false}
              onClick={() => setOpenDialogAddDirectLink(true)}
              size="small"
              startIcon={<AddCircleIcon />}
              variant="outlined"
              className={classes.buttonOutlinedDirectLink}
            >
              <i>Direct Link</i>
            </Button>
          </Box>
        )}
      </Box>

      {/* Direct Sub Goal */}

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorElDirectInDirect}
        keepMounted
        open={Boolean(anchorElDirectInDirect)}
        onClose={handleCloseMenuDirectInDirect}
      >
        <StyledMenuItem disabled>
          <ListItemIcon>
            <IconButton>
              <img src={IconIndirect} alt="Icon Redirect" />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="subtitle2" className={classes.title}>
                <b>Indirect Sub-Goal</b>
              </Typography>
            }
          />
        </StyledMenuItem>

        <StyledMenuItem
          disabled={!directLinkCreateAllowed}
          onClick={
            context.collectionGoalResultList.length !== 0
              ? () => setModalConfirmBeforePilihUser(true)
              : () => setModalPilihUser(true)
          }
        >
          <ListItemIcon>
            <IconButton>
              <img src={IconRedirect} alt="Icon Redirect" />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="subtitle2" className={classes.title}>
                <b>Direct Sub-Goal</b>
              </Typography>
            }
          />
        </StyledMenuItem>
      </StyledMenu>

      {/* 
          ```````````````
          GRID LIKE TABLE

          ```````````````
      */}
      <Box marginLeft={theme.spacing(0.3)} marginRight={theme.spacing(0.2)}>
        {context.listCollectionSubGoal.length === 0 && (
          <Paper
            elevation={1}
            className={classes.rootTable}
            style={{ marginTop: 8 }}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey", marginTop: 16, marginBottom: 16 }}
              >
                <PictEmptyStateGoal />
                <b>
                  Tidak ada <i>Sub Goal </i> yang dapat di tampilkan saat ini ;(
                </b>
              </Typography>
            </Grid>
          </Paper>
        )}

        {context.listCollectionSubGoal.length > 0 && (
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Paper
              className={classes.rootTable}
              style={{ backgroundColor: "transparent" }}
            >
              {/* {selected.length > 0 && !context.isSuccessDeleteSubGoal && ( */}
              <EnhancedDeleteToolbar
                numSelected={selected.length}
                goalId={context.goalDetailStateGlobal.id}
                statusId={context.goalDetailStateGlobal.status_id}
                subGoalId={context.subGoalId}
                listSubGoalId={context.listSubGoalId}
                setSelected={setSelected}
                policy={policy}
                rowCount={context.listCollectionSubGoal.length}
                onSelectAllClick={handleSelectAllClick}
                // onChangeCheckBox={handleChangeCheckBox}
                // allChecked={allChecked}
              />
              {/* )} */}
              <Table
                className={classes.table}
                aria-label="sticky table"
                // stickyHeader
              >
                <TableHead></TableHead>

                <TableBody>
                  {loader ? (
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                    >
                      <CircularProgress
                        style={{ color: "#d1354a" }}
                        size={20}
                      />
                    </Grid>
                  ) : (
                    context.listCollectionSubGoal.length > 0 &&
                    context.listCollectionSubGoal
                      .sort(
                        (a, b) =>
                          b.calculatedValue.resultOriented
                            .overall_result_achievement -
                          a.calculatedValue.resultOriented
                            .overall_result_achievement
                      )
                      .map((row, i) => {
                        return (
                          <ListItemSubGoal
                            key={i}
                            row={row}
                            classes={classes}
                            selected={selected}
                            setSelected={setSelected}
                            isSelected={isSelected}
                            // onChangeCheckBox={handleChangeCheckBox}
                          />
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Slide>
        )}
      </Box>

      <DialogPilihUser
        isModalPilihUser={isModalPilihUser}
        setModalPilihUser={setModalPilihUser}
        handleCloseMenuDirectInDirect={handleCloseMenuDirectInDirect}
        setSelected={setSelected}
        selected={selected}
        setFireGoalDetail={setFireGoalDetail}
        goalDetailState={goalDetailState}
        // collectionGoalResultList = { collectionGoalResultList }
      />

      <DialogConfirmBeforePilihUser
        isModalConfirmBeforePilihUser={isModalConfirmBeforePilihUser}
        setModalConfirmBeforePilihUser={setModalConfirmBeforePilihUser}
        isModalPilihUser={isModalPilihUser}
        setModalPilihUser={setModalPilihUser}
        collectionGoalResultList={collectionGoalResultList}
        goalDetailState={goalDetailState}
      />

      <DialogCreateAddDirectLink
        isOpenDialogAddDirectLink={isOpenDialogAddDirectLink}
        setOpenDialogAddDirectLink={setOpenDialogAddDirectLink}
        handleCloseMenuDirectInDirect={handleCloseMenuDirectInDirect}
        setSelected={setSelected}
        selected={selected}
        setFireGoalDetail={setFireGoalDetail}
      />
    </Fragment>
  );
};

export default ListCollectionSubGoal;
