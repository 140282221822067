import React, { useEffect, useState } from "react";
import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const FilterAssignee = ({ classes, ownerCollections, onOwnerId }) => {
  const [selected, setSelected] = useState([]);

  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setSelected((prev) => [...prev, data]);
    }
  };

  useEffect(() => {
    onOwnerId(selected);
  }, [selected]);

  const handleDelete = (id) => {
    setSelected((prev) => prev.filter((el) => el.id !== id));
  };

  return (
    <Box pt={1} pb={2} px={3}>
      <Box my={selected.length > 0 ? 1.5 : 0} display="flex" flexWrap="wrap">
        {selected.length > 0 &&
          selected.map((item, i) => (
            <Chip
              key={i}
              size="small"
              label={`${item.member.first_name}`}
              onDelete={() => handleDelete(item.id)}
              style={{ margin: "0 5px 5px 0" }}
            />
          ))}
      </Box>
      <Autocomplete
        id="combo-box-demo"
        options={ownerCollections}
        getOptionLabel={(option) =>
          `${option.member.first_name} ${option.member.last_name}`
        }
        noOptionsText="Tidak ada owner"
        onChange={(e, owner) => handleChooseOwner(e, owner)}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label="Search owner"
            variant="standard"
            size="small"
            InputLabelProps={{ style: { fontSize: 13 } }}
          />
        )}
      />
    </Box>
  );
};

export default FilterAssignee;
