import React from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import numeral from 'numeral';

import TruncateTextCustom from '../../../../../../utilities/TruncateTextCustom';
import ProgressBarItem from '../../../ComponentsDashboard/ProgressBarItem';

const ListItemTopMas = ({ item, classes }) => {
  let color = '';
  let bgColor = '';

  if (item.achievement_category === 'Achieved') {
    color = '#34cc80';
    bgColor = '#D3F3E8';
  } else if (item.achievement_category === 'Warning') {
    color = '#fab445';
    bgColor = '#F8E4B0';
  } else {
    color = '#e15367';
    bgColor = '#FDDBD8';
  }

  return (
    <Paper className={classes.cardList}>
      <Box mb={1} p={1} display="flex">
        <AccountCircleIcon fontSize="large" style={{ color: '#555' }} />

        <Box width="100%" ml={2}>
          <Typography className={classes.title555} variant="subtitle2">
            <b>{TruncateTextCustom(item.owner_name, 50)}</b>
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ProgressBarItem
              item={parseInt(item.average_overall_result_achievement)}
              achievementCategory={item.achievement_category}
            />
            <Typography
              variant="caption"
              style={{
                background: bgColor,
                color: color,
                padding: '2px 8px',
                borderRadius: 25,
                marginLeft: 10
              }}
            >
              {numeral(item.average_overall_result_achievement).format('0.00')}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ListItemTopMas;
