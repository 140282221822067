import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import LiveHelpRoundedIcon from "@material-ui/icons/LiveHelpRounded";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import ContextProject from "../../context/ContextProject";

const DialogDeleteTasks = ({
  classes,
  openDeleteTasks,
  setOpenDeleteTasks,
}) => {
  const { selectTask, setSelectTask, setTriggerTask } =
    useContext(ContextProject);

  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteTasks = () => {
    setLoading(true);

    return Promise.all(
      selectTask.length > 0 &&
        selectTask.map((item, index) => {
          const params = new URLSearchParams();
          params.append(`Todolist[${index}][id]`, item);

          return AxiosConfig.delete(`${URL_API}/todolist/batch`, {
            data: params,
          })
            .then((res) => {
              if (res.status === 200) {
                setTriggerTask(Math.floor(Math.random() * 100));
                setOpenDeleteTasks(false);
                setLoading(false);
                setSelectTask([]);
              }
            })
            .catch((error) => {
              setLoading(false);
              // console.log("error", error);

              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);
            });
        })
    );
  };

  return (
    <Dialog
      open={openDeleteTasks}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Delete Tasks</b>
        </Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            Apakah Anda yakin ingin hapus <b>{selectTask.length}</b> Tasks ?
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button}
          onClick={loading ? null : () => setOpenDeleteTasks(false)}
        >
          Cancel
        </Button>

        <Button
          variant={loading ? "contained" : "outlined"}
          size="medium"
          disabled={loading}
          className={classes.button1}
          onClick={handleDeleteTasks}
        >
          {loading ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogDeleteTasks;
