import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Box,
} from "@material-ui/core";

function EnhancedTableHead({ classes }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" className={classes.title}>
              <b>Name</b>
            </Typography>
          </Box>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2" className={classes.title}>
            <b>Date Modified</b>
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2" className={classes.title}>
            <b>Type</b>
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2" className={classes.title}>
            <b>Size</b>
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle2" className={classes.title}>
            <b>Owner</b>
          </Typography>
        </TableCell>

        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
