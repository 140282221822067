import React, { useState, useContext, useEffect } from "react";
import {
  Tooltip,
  Button,
  Box,
  Collapse,
  Typography,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Divider,
  Paper,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";

import classNames from "classnames";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import icon_filter from "../../../../assets/images/icon_filter.png";
import ContextProject from "../../context/ContextProject";
import {
  ASSIGNEE_CONTENT_FILTER,
  DRAWER_KANBAN,
  FILTER_TASK,
} from "../../context/types";
import DrawerKanban from "./filterItem/DrawerKanban";

const AnchorFilterKanban = ({ classes }) => {
  const project_id = window.location.pathname.split("/")[2];
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);
  const { projectTDispatch, setTriggerTask, setTriggerKanban } =
    useContext(ContextProject);

  const statuses =
    "options[filter][fields][status][0]=TO DO&options[filter][fields][status][1]=DOING";
  const overdues = "options[filter][periods][0]=overdue_soon";
  const overdue = "options[filter][periods][0]=overdue";
  const me = `options[filter][fields][owner_id][0]=${user?.user_id}`;
  const myself = `${user?.member_first_name} ${user?.member_last_name}`;

  const [ownerCollections, setOwnerCollections] = useState([]);
  const [listGroup, setListGroup] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [expandMy, setExpandMy] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  useEffect(() => {
    AxiosConfig.get(`${URL_API}/todolist/create?project_id=${project_id}`)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setOwnerCollections(result.ownerCollections);
        }
      })
      .catch((error) => {
        // console.log("Error", error);
      });

    AxiosConfig.get(`${URL_API}/project/${project_id}/task-group`)
      .then((res) => {
        const result = res.data.data;
        if (res.status === 200) {
          setListGroup(result);
        }
      })
      .catch((error) => {
        // Handle Error
        // console.log("Error", error);
      });
  }, []);

  const handleFilterKanban = (event) => {
    projectTDispatch({
      type: DRAWER_KANBAN,
      payload: {
        drawerKanban: true,
      },
    });
    handleClose();
  };

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleExpandMy = () => {
    setExpandMy((prev) => !prev);
  };

  const handleExpandAll = () => {
    setExpandAll((prev) => !prev);
  };

  const handleMyAll = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: me,
        filterAchievement: null,
        filterStatus: null,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: [myself],
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleMyOverdue = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdues,
        filterAssignee: me,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: [myself],
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleAllOverdue = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdue,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleMyLate = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdue,
        filterAssignee: me,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: [myself],
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleAllLate = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: overdues,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: statuses,
        filterPriority: null,
        filterComplete: null,
        filterGroup: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));
    handleClose();
  };

  const handleResetFilter = () => {
    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: null,
        filterAssignee: null,
        filterAchievement: null,
        filterStatus: null,
        filterPriority: null,
        filterComplete: null,
        filterPhase: null,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: null,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));

    handleClose();
  };

  return (
    <div>
      <Tooltip title="Filter Kanban">
        <Button
          size="small"
          variant="outlined"
          className={classes.btnIcon}
          onClick={handleShow}
        >
          <img src={icon_filter} height={24} />
        </Button>
      </Tooltip>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                <Box pl={2} p={1}>
                  <Box mb={1} display="flex" justifyContent="space-between">
                    <Typography variant="caption" className={classes.title555}>
                      <b>Quick Filter</b>
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtLink}
                      onClick={handleResetFilter}
                    >
                      Reset
                    </Typography>
                  </Box>
                </Box>

                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleExpandMy}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span style={{ color: "#555" }}>My Task</span>
                      {expandMy ? (
                        <ExpandLess style={{ fontSize: 18 }} />
                      ) : (
                        <ExpandMore style={{ fontSize: 18 }} />
                      )}
                    </Box>
                  </MenuItem>
                  <Collapse in={expandMy}>
                    <MenuList>
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={handleMyAll}
                        style={{ backgroundColor: "#F7F8F6" }}
                      >
                        <span style={{ color: "#555" }}>All</span>
                      </MenuItem>
                      <Box my={0.2} />
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={handleMyOverdue}
                        style={{ backgroundColor: "#F7F8F6" }}
                      >
                        <span style={{ color: "#555" }}>Overdue Soon</span>
                      </MenuItem>
                      <Box my={0.2} />
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={handleMyLate}
                        style={{ backgroundColor: "#F7F8F6" }}
                      >
                        <span style={{ color: "#555" }}>Overdue</span>
                      </MenuItem>
                    </MenuList>
                  </Collapse>

                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleExpandAll}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <span style={{ color: "#555" }}>All Task</span>
                      {expandAll ? (
                        <ExpandLess style={{ fontSize: 18 }} />
                      ) : (
                        <ExpandMore style={{ fontSize: 18 }} />
                      )}
                    </Box>
                  </MenuItem>
                  <Collapse in={expandAll}>
                    <MenuList>
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={handleAllOverdue}
                        style={{ backgroundColor: "#F7F8F6" }}
                      >
                        <span style={{ color: "#555" }}>Overdue</span>
                      </MenuItem>
                      <Box my={0.2} />
                      <MenuItem
                        className={classes.dropdownItem}
                        onClick={handleAllLate}
                        style={{ backgroundColor: "#F7F8F6" }}
                      >
                        <span style={{ color: "#555" }}>Overdue Soon</span>
                      </MenuItem>
                    </MenuList>
                  </Collapse>
                </MenuList>
                <Divider className={classes.dropdownDividerItem} />
                <MenuItem
                  onClick={handleFilterKanban}
                  className={classes.dropdownItem}
                >
                  <Box display="flex" alignItems="center">
                    <TuneRoundedIcon
                      fontSize="small"
                      style={{ color: "#555", marginRight: 7 }}
                    />
                    <span style={{ color: "#555" }}>Custom Flter</span>
                  </Box>
                </MenuItem>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>

      <DrawerKanban
        classes={classes}
        ownerCollections={ownerCollections}
        listGroup={listGroup}
      />
    </div>
  );
};

export default AnchorFilterKanban;
