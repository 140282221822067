/*

    ```````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
    COMPONENT INI DI BUAT UNTUK GOAL DETAIL NEW, kalau untuk component   <CircleGoalDetailInPercent /> di pakai sementara hanya untuk MA DETAIL

    ```````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
*/

import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  CircularProgressbarWithChildren,
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import { green, red, yellow } from "@material-ui/core/colors";

import { easeQuadInOut } from "d3-ease";
import moment from "moment";

import AnimatedProgressProvider from "./AnimatedProgressProvider";
import "react-circular-progressbar/dist/styles.css";

import ConvertExponentialToSubstring from "../utilities/ConvertExponentialToSubstring";

import { FormatDecimal } from "../utilities/FormatDecimal";

const numeral = require("numeral");

const CircleGoalDetailInPercentNEW = (props) => {
  const { goalDetailState } = props;

  if (goalDetailState.id !== null) {
    return (
      <Fragment>
        {/* 
                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                    # SCENARIO-00
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT"  0
                        
                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                */}

        {ConvertExponentialToSubstring(
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement * 100
        ) === 0 && (
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={100}
            duration={3}
            easingFunction={easeQuadInOut}
            // repeat
          >
            {(value) => {
              // const roundedValue = Math.round(value);//*Math.round() ==> "used to round a number to its nearest integer"

              return (
                <CircularProgressbar
                  value={value}
                  text={
                    Number(goalDetailState.expected_result_value) === 0
                      ? "N/A"
                      : `${FormatDecimal(
                          ConvertExponentialToSubstring(
                            goalDetailState.calculatedValue.resultOriented
                              .overall_result_achievement * 100
                          )
                        )}%`
                  }
                  /* This is important to include, because if you're fully managing theanimation yourself, you'll want to disable the CSS animation. */
                  styles={buildStyles({
                    // pathTransitionDuration: 0.5,
                    // pathColor: '#2ef062', //*GREEN
                    pathColor: "#bbb",
                    // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                    textColor: "white",
                    // strokeLinecap: 'butt',
                    pathTransition: "none",
                    textSize: goalDetailState.has_goal_result === false && 12,
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
        )}

        {/* 
                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                    # SCENARIO-01
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" KURANG DARI dari 85% ==> merah
                        
                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                */}

        {ConvertExponentialToSubstring(
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement * 100
        ) > 0 &&
          ConvertExponentialToSubstring(
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement * 100
          ) < 85 && (
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={ConvertExponentialToSubstring(
                goalDetailState.calculatedValue.resultOriented
                  .overall_result_achievement * 100
              )}
              duration={3}
              easingFunction={easeQuadInOut}
              // repeat
            >
              {(value) => {
                // const roundedValue = Math.round(value);//*Math.round() ==> "used to round a number to its nearest integer"

                return (
                  <CircularProgressbar
                    value={value}
                    text={
                      Number(goalDetailState.expected_result_value) === 0
                        ? "N/A"
                        : `${FormatDecimal(
                            ConvertExponentialToSubstring(
                              goalDetailState.calculatedValue.resultOriented
                                .overall_result_achievement * 100
                            )
                          )}%`
                    }
                    /* This is important to include, because if you're fully managing theanimation yourself, you'll want to disable the CSS animation. */
                    styles={buildStyles({
                      // pathTransitionDuration: 0.5,
                      // pathColor: '#2ef062', //*GREEN
                      pathColor:
                        Number(goalDetailState.expected_result_value) === 0
                          ? "#bbb"
                          : red[800],
                      // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                      textColor: "white",
                      // strokeLinecap: 'butt',
                      pathTransition: "none",
                      textSize: goalDetailState.has_goal_result === false && 12,
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}

        {/* 

                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                    # SCENARIO-02
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " >= 85% 

                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT" < 100% 
                    
                         ==> YELLOWuntuk trail "OVERALL_ACHIEVEMENT"-nya

                         ==> RED untuk "POSISI AMAN/ EXPECTED_VALUE_ACHIEVEMENT"
                        
                    ````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
                */}

        {ConvertExponentialToSubstring(
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement * 100
        ) >= 85 &&
          ConvertExponentialToSubstring(
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement * 100
          ) < 100 && (
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={ConvertExponentialToSubstring(
                goalDetailState.calculatedValue.resultOriented
                  .overall_result_achievement * 100
              )}
              duration={3}
              easingFunction={easeQuadInOut}
              // repeat
            >
              {(value) => {
                return (
                  <CircularProgressbar
                    value={value}
                    text={
                      Number(goalDetailState.expected_result_value) === 0
                        ? "N/A"
                        : `${FormatDecimal(value * 100)}%`
                      // "ahe"
                    }
                    styles={buildStyles({
                      pathColor: yellow[400],
                      trailColor: "grey", //*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                      textColor: "white",
                      // strokeLinecap: 'butt',
                      pathTransition: "none",
                      textSize: goalDetailState.has_goal_result === false && 12,
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}

        {/* 
                    `````````````````````````````````````````````````````````
                    # SCENARIO-03
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 100% 
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 200% 
                
                         ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

                         ==> GREEN untuk 100

                    ```````````````````````````````````````````````````````````
                */}

        {ConvertExponentialToSubstring(
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement * 100
        ) !== 200 &&
          ConvertExponentialToSubstring(
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement * 100
          ) >= 100 &&
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement *
            100 <=
            200 && (
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={ConvertExponentialToSubstring(
                goalDetailState.calculatedValue.resultOriented
                  .overall_result_achievement * 100
              )}
              duration={3}
              easingFunction={easeQuadInOut}
              // repeat
            >
              {(value) => {
                return (
                  <CircularProgressbarWithChildren
                    value={100}
                    // value={numeral(value * 100).format('0.00')}
                    styles={buildStyles({
                      // pathColor: red[800],
                      // strokeLinecap: 'butt'//*Untuk supaya trail progress TEGAK
                      pathColor: green[500],
                      trailColor: "grey", //* Kalau di list goal pakai *#eee
                    })}
                  >
                    <CircularProgressbar
                      value={value - 100}
                      // value={100}
                      text={
                        Number(goalDetailState.expected_result_value) === 0
                          ? "N/A"
                          : `${FormatDecimal(
                              ConvertExponentialToSubstring(value * 100)
                            )}%`
                      }
                      styles={buildStyles({
                        // pathColor:  yellow[400],
                        // strokeLinecap: 'butt',//*Untuk supaya trail progress TEGAK
                        pathColor: green[800],
                        trailColor: "transparent", //*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                        textColor: "white",
                        pathTransition: "none",
                        textSize:
                          goalDetailState.has_goal_result === false && 12,
                      })}
                    />
                  </CircularProgressbarWithChildren>
                );
              }}
            </AnimatedProgressProvider>
          )}

        {/* 
                    ```````````````````````````````````````````````````````````
                    # SCENARIO-04
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 200
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 999
                
                         ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

                    ```````````````````````````````````````````````````````````
                */}

        {ConvertExponentialToSubstring(
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement * 100
        ) >= 200 &&
          ConvertExponentialToSubstring(
            goalDetailState.calculatedValue.resultOriented
              .overall_result_achievement * 100
          ) < 999 && (
            <AnimatedProgressProvider
              valueStart={0}
              // valueEnd={(goalDetailState.calculatedValue.overall_achievement).format('0.00')) }
              valueEnd={ConvertExponentialToSubstring(
                goalDetailState.calculatedValue.resultOriented
                  .overall_result_achievement * 100
              )}
              duration={3}
              easingFunction={easeQuadInOut}
              // repeat
            >
              {(value) => {
                // const roundedValue = Math.round(value);

                return (
                  <CircularProgressbar
                    value={value}
                    text={
                      Number(goalDetailState.expected_result_value) === 0
                        ? "N/A"
                        : `${FormatDecimal(
                            ConvertExponentialToSubstring(value * 100)
                          )}%`
                    } //*Khusus yang ini ga pake numeral-an
                    // counterClockwise={true}
                    styles={buildStyles({
                      pathColor: green[800], //lightGreen['A400'],
                      textColor: "white",
                      trailColor: "grey", //* Kalau di list goal pakai *#eee
                      pathTransition: "none",
                      // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                      // strokeLinecap: 'butt',
                      textSize:
                        goalDetailState.has_goal_result === false ? 12 : 14,
                    })}
                  />
                );
              }}
            </AnimatedProgressProvider>
          )}

        {/* 
                    ```````````````````````````````````````````````````````````
                    # SCENARIO-04
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " > 999 %
                
                         ==> GREEN TUA untuk trail "OVERALL_ACHIEVEMENT"-nya

                    ```````````````````````````````````````````````````````````
                */}

        {ConvertExponentialToSubstring(
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement * 100
        ) >= 999 && (
          <AnimatedProgressProvider
            valueStart={0}
            // valueEnd={(goalDetailState.calculatedValue.overall_achievement).format('0.00')) }
            valueEnd={ConvertExponentialToSubstring(999)}
            duration={3}
            easingFunction={easeQuadInOut}
            // repeat
          >
            {(value) => {
              // const roundedValue = Math.round(value);

              return (
                <CircularProgressbar
                  value={value}
                  text={
                    Number(goalDetailState.expected_result_value) === 0
                      ? "N/A"
                      : `${FormatDecimal(
                          ConvertExponentialToSubstring(value)
                        )}%`
                  } //*Khusus yang ini ga pake numeral-an
                  // counterClockwise={true}
                  styles={buildStyles({
                    pathColor: green[800], //lightGreen['A400'],
                    textColor: "white",
                    trailColor: "grey", //* Kalau di list goal pakai *#eee
                    pathTransition: "none",
                    // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                    // strokeLinecap: 'butt',
                    textSize:
                      goalDetailState.has_goal_result === false ? 12 : 14,
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
        )}

        {/* 
                    ```````````````````````````````````````````````````````````
                    # SCENARIO-06
                    - INI SCENARIO KALAU "OVERALL_ACHIEVEMENT " < 0
                
                         ==> MERAH untuk trail "OVERALL_ACHIEVEMENT"-nya

                         ==> counterClockwise === true 

                         ==> Value "overall_achievement"-nya bernilai MINUS

                    ```````````````````````````````````````````````````````````
                */}

        {ConvertExponentialToSubstring(
          goalDetailState.calculatedValue.resultOriented
            .overall_result_achievement
        ) < 0 && (
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={
              ConvertExponentialToSubstring(
                goalDetailState.calculatedValue.resultOriented
                  .overall_result_achievement * 100
              ) -
              ConvertExponentialToSubstring(
                goalDetailState.calculatedValue.resultOriented
                  .overall_result_achievement * 100
              ) *
                2
            }
            duration={3}
            easingFunction={easeQuadInOut}
            // repeat
          >
            {(value) => {
              return (
                <CircularProgressbar
                  value={value}
                  text={
                    Number(goalDetailState.expected_result_value) === 0
                      ? "N/A"
                      : `${FormatDecimal(
                          ConvertExponentialToSubstring(
                            goalDetailState.calculatedValue.resultOriented
                              .overall_result_achievement * 100
                          )
                        )}%`
                  }
                  counterClockwise={true}
                  styles={buildStyles({
                    // trailColor: 'transparent',//*Ini di pake kalau ada element parent : "CircularProgressbarWithChildren"
                    pathColor:
                      Number(goalDetailState.expected_result_value) === 0
                        ? "#bbb"
                        : red[800],
                    textColor: "white",
                    pathTransition: "none",
                    // strokeLinecap: 'butt',
                    // textSize: '11px'
                    textSize: goalDetailState.has_goal_result === false && 12,
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
        )}
      </Fragment>
    );
  } else {
    return (
      <Grid container alignItems="center" justify="center">
        <CircularProgress size={20} styles={{ color: "red" }} />
      </Grid>
    );
  }
};

export default CircleGoalDetailInPercentNEW;
