import React, { useState } from "react";
import { Fade, IconButton, Chip, Typography, Tooltip } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AudiotrackRoundedIcon from "@material-ui/icons/AudiotrackRounded";
import TruncateTextShortSuperPendek from "../../../../../utilities/TruncateTextShortSuperPendek";

import LightTooltip from "../../../../../components/LightTooltip";
import DialogDeleteEvidenceMA from "../DialogDeleteEvidenceMA";
import DialogPlayAudio from "../../../Goal/ComponentsGoalResult/DialogPlayAudio";

const AudioItem = ({
  i,
  tile,
  classes,

  showFade,
  userToken,
  deleteAllowed,
  listPictState,
  setListPictState,
}) => {
  const [isOpenDialogAudioPlayer, setOpenDialogAudioPlayer] = useState(false);
  const [openDialogDeleteEvidence, setOpenDialogDeleteEvidence] =
    useState(false);
  const [dataStringTile, setDataStringTile] = useState("");

  const handleOpenDialogAudio = (e, tile) => {
    // console.log("Data Audio : ", tile);

    e.preventDefault();
    setOpenDialogAudioPlayer(true);
    setDataStringTile(tile.self.rel.content);
  };

  return (
    <span key={i}>
      <Fade in={showFade}>
        <Tooltip title={tile.label}>
          <Chip
            style={{ marginRight: 4 }}
            onClick={(e) => handleOpenDialogAudio(e, tile)}
            icon={
              <LightTooltip arrow title="Putar Audio sekarang" placement="left">
                <IconButton
                  onClick={(e) => handleOpenDialogAudio(e, tile)}
                  size="small"
                >
                  <AudiotrackRoundedIcon style={{ color: "#d1354a" }} />
                </IconButton>
              </LightTooltip>
            }
            label={
              <Typography variant="caption" className={classes.title}>
                {TruncateTextShortSuperPendek(tile.label)}
              </Typography>
            }
            onDelete={
              deleteAllowed ? () => setOpenDialogDeleteEvidence(true) : null
            }
            deleteIcon={
              deleteAllowed ? (
                <HighlightOffIcon
                  fontSize="small"
                  style={{ color: "#d1354a" }}
                />
              ) : null
            }
          />
        </Tooltip>
      </Fade>

      <DialogDeleteEvidenceMA
        openDialogDeleteEvidence={openDialogDeleteEvidence}
        setOpenDialogDeleteEvidence={setOpenDialogDeleteEvidence}
        item={tile}
        classes={classes}
        listPictState={listPictState}
        setListPictState={setListPictState}
      />

      <DialogPlayAudio
        classes={classes}
        tile={tile}
        dataStringTile={dataStringTile}
        userToken={userToken}
        isOpenDialogAudioPlayer={isOpenDialogAudioPlayer}
        setOpenDialogAudioPlayer={setOpenDialogAudioPlayer}
      />
    </span>
  );
};

export default AudioItem;
