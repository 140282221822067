import React, { useState, useContext } from "react";
import {
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Box,
  Divider,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
// import ClearRoundedIcon from "@material-ui/icons/ClearRounded";

import classNames from "classnames";

import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import ContextTask from "../../../Context/ContextTask";
import { DRAWER_OPEN, SET_TODO_DETAILS } from "../../../Context/types";
import DialogDeleteTodo from "../dialog/DialogDeleteTodo";
import handleError from "../../../../Report/components/global/handleError";
import DialogError from "../../../../../components/DialogError";

const AnchorTodoItem = ({ classes, todo }) => {
  const { todoDispatch, setTriggerTodo } = useContext(ContextTask);
  const checked = todo.is_complete.id === "2" ? true : false;

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteTodo, setOpenDeleteTodo] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    handleClose();

    todoDispatch({
      type: DRAWER_OPEN,
      payload: { drawer: true },
    });
    todoDispatch({
      type: SET_TODO_DETAILS,
      payload: { todoDetails: todo },
    });
  };

  const handleComplete = () => {
    const actual = todo.actual_result === null ? "0" : todo.actual_result;
    const remarks = todo.remarks === null ? "" : todo.remarks;

    const data = {
      Todolist: {
        actual_result: actual,
        remarks,
        status: "DONE",
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${todo.id}:completed`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTodo(Math.floor(Math.random() * 100));
          handleClose();
        }
      })
      .catch((error) => {
        // console.log("error", error);
        handleClose();
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  const handleIncomplete = () => {
    AxiosConfig.put(`${URL_API}/todolist/${todo.id}:incompleted`)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTodo(Math.floor(Math.random() * 100));
          handleClose();
        }
      })
      .catch((error) => {
        // console.log("error", error);
        handleClose();
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <div>
      <IconButton size="small">
        <MoreVertIcon style={{ color: "#888" }} onClick={handleShow} />
      </IconButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    onClick={handleViewDetails}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <VisibilityIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>View Details</span>
                    </Box>
                  </MenuItem>
                  {!checked && (
                    <MenuItem
                      onClick={handleComplete}
                      className={classes.dropdownItem}
                    >
                      <Box display="flex" alignItems="center">
                        <CheckRoundedIcon
                          style={{
                            color: "#555",
                            fontSize: 14,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#555" }}>Set Complete</span>
                      </Box>
                    </MenuItem>
                  )}
                  {checked && (
                    <MenuItem
                      onClick={handleIncomplete}
                      className={classes.dropdownItem}
                    >
                      <Box display="flex" alignItems="center">
                        <CloseOutlinedIcon
                          style={{
                            color: "#555",
                            fontSize: 14,
                            marginRight: 7,
                          }}
                        />
                        <span style={{ color: "#555" }}>Set Incomplete</span>
                      </Box>
                    </MenuItem>
                  )}
                  <Divider className={classes.dropdownDividerItem} />
                  <MenuItem
                    onClick={() => {
                      setOpenDeleteTodo(true);
                      handleClose();
                    }}
                    className={classes.dropdownItem}
                  >
                    <Box display="flex" alignItems="center">
                      <DeleteIcon
                        style={{
                          color: "#C8203C",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#C8203C" }}>Delete</span>
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogDeleteTodo
        classes={classes}
        todo={todo}
        openDeleteTodo={openDeleteTodo}
        setOpenDeleteTodo={setOpenDeleteTodo}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default AnchorTodoItem;
