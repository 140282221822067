export const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  cameraButton: {
    position: "absolute",
    left: "50%",
    bottom: "50px",
    width: "6rem",
    height: "6rem",
    border: `12px solid purple`,
    backgroundColor: "gray",
    borderRadius: "100%",
    transform: "translate(-50%, 0%)",
  },
  cameraDiv: {
    border: `2px solid gray`,
    marginTop: "30px",
    borderRadius: "10px",
  },
});
