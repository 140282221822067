import React, { useEffect, useState, useContext } from 'react';
import { styles } from './Style/StyleMySchedule';
import { navigate } from 'hookrouter';

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  Divider
} from '@material-ui/core';
import { Work } from '@material-ui/icons';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/id';
import Moment from 'react-moment';
import { isEmpty } from 'lodash';

import DialogProgress from '../Account/Components/DialogProgress';

import ContextMySchedule from './Context/ContextMySchedule';

import { URL_API } from '../../../constants/config-api';
import axiosConfig from '../../../constants/config-axios';

import { isAuthenticated } from '../MyAttendance/Hook/isAuthenticated';

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cc0707', //#cc0707, #c62828
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#262261',
      light: '#6e6ecf',
      dark: '#0f0c29',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontFamily: 'Lato, sans-serif'
  }
});

const ViewMySchedule = () => {
  const classes = useStyles();
  const context = useContext(ContextMySchedule);

  const [loader, setLoader] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [scheduleToday, setScheduleToday] = useState({});

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    }
    fetchSchedule();
  }, []);

  const fetchSchedule = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/my-schedule`
      );
      setSchedule(response.data.data);
      let today = response.data.data.find((item) => item.today === true);
      setScheduleToday(today);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const getDate = (date) => {
    let dateMoment = moment(date, 'YYYY-MM-DD').locale('id');
    return `${dateMoment.format('dddd')}, ${dateMoment.format('MMM')}`;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Card
                style={{
                  position: 'fixed',
                  margin: '16px auto',
                  width: '37%',
                  background: '#221E5B',
                  borderRadius: 24,
                  maxWidth: 'inherit'
                }}
              >
                <CardContent>
                  <Card
                    style={{
                      margin: '0 auto',
                      width: '100%',
                      marginTop: '1rem',
                      borderRadius: 24,
                      padding: '10px 0px'
                    }}
                  >
                    <CardContent style={{ color: '#221E5B' }}>
                      <Box textAlign="center">
                        <Typography variant="subtitle1">
                          Jadwal hari ini,{' '}
                          <Moment
                            format="dddd, D MMMM YYYY"
                            interval={1}
                            unit="seconds"
                          ></Moment>
                        </Typography>
                        <Typography variant="h5">
                          <b>
                            {scheduleToday.scheduleIn} -{' '}
                            {scheduleToday.scheduleOut}
                          </b>
                        </Typography>
                        <Typography variant="h6">
                          di {scheduleToday.company}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            justifyContent: 'center'
                          }}
                        >
                          <Work />
                          <Typography variant="h6">
                            <b>Shift {scheduleToday.shift}</b>
                          </Typography>
                        </div>
                      </Box>
                    </CardContent>
                  </Card>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: 'bold',
                  color: '#262261',
                  marginBottom: '1rem'
                }}
              >
                Jadwal Kerja
              </Typography>

              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}
              >
                {schedule.map((item) => (
                  <Card style={{ padding: '6px', borderRadius: '10px' }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        style={{
                          color: item.off_day ? '#CE354A' : '#221E5B'
                        }}
                      >
                        <Grid item xs={3}>
                          <Typography align="center" variant="subtitle1">
                            <b>{getDate(item.tanggal)}</b>
                          </Typography>
                          <Typography variant="h4" align="center">
                            <b>{item.tanggal.split('-')[2]}</b>
                          </Typography>
                        </Grid>
                        {item.off_day === false ? (
                          <Grid item xs={9}>
                            <Typography variant="subtitle1">
                              <b>Hari Kerja</b>
                            </Typography>
                            <Typography variant="h4">
                              <b>
                                {item.scheduleIn} - {item.scheduleOut}
                              </b>
                            </Typography>
                            <Typography variant="subtitle1">
                              di {item.company}
                            </Typography>
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <Work />
                              <Typography variant="h6">
                                <b>{item.shift}</b>
                              </Typography>
                            </div>
                          </Grid>
                        ) : (
                          <Grid item xs={9}>
                            <Typography variant="subtitle1">
                              <b>{item.off_name}</b>
                            </Typography>
                            <Typography variant="h4">
                              <b>
                                {item.scheduleIn} - {item.scheduleOut}
                              </b>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>

        <DialogProgress
          classes={classes}
          isModalProgress={loader}
          setModalProgress={setLoader}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default ViewMySchedule;
