import React from "react";
import { Box, Typography, FormControl, Select } from "@material-ui/core";

const ListItemDropdownCreate = ({
  classes,
  title,
  onSelect,
  selection,
  collections,
}) => {
  const handleChange = (e) => {
    const value = e.target.value;

    const selected = collections?.find((el) => el.code === value);
    onSelect(selected);
  };

  return (
    <Box>
      <Typography variant="subtitle2" className={classes.title333}>
        {title}
      </Typography>
      <Box my={0.5} />
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        hiddenLabel={false}
      >
        <Select
          native
          labelWidth={0}
          value={selection.code}
          onChange={handleChange}
          inputProps={{
            name: "selection",
            id: "selection",
          }}
        >
          {collections.length > 0 &&
            collections.map((item, i) => {
              return (
                <option key={i} aria-label="None" value={item.code}>
                  {item.name}
                </option>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ListItemDropdownCreate;
