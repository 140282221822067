import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Box,
  Divider,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import SettingsRoundedIcon from "@material-ui/icons/SettingsOutlined";
import FitnessCenterRoundedIcon from "@material-ui/icons/FitnessCenterRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import classNames from "classnames";

import DialogSetWeightTask from "../dialogs/DialogSetWeightTask";
import DialogDeleteProject from "../dialogs/DialogDeleteProject";
import ProgressTaskSetting from "./taskSetting/ProgressTaskSetting";

import ContextProject from "../../context/ContextProject";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import handleError from "../../../Report/components/global/handleError";
import SnackbarError from "../../../../components/SnackbarError";

const AnchorTaskSettings = ({ classes, project }) => {
  const { projectTState, setTriggerTask, setTriggerKanban } = useContext(
    ContextProject
  );

  const [resultSource, setResultSource] = useState(
    projectTState.projectDetails?.scope_result_source
  );
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSetWeightTask, setOpenSetWeightTask] = useState(false);
  const [openDeleteProject, setOpenDeleteProject] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (anchorEl !== null) {
      AxiosConfig.get(
        `${URL_API}/swd/goal/${projectTState.projectDetails?.id}/update`
      )
        .then((res) => {
          const result = res.data.data;
          // // console.log("UPDATE PROJ", result);

          if (res.status === 200) {
            if (result.projectMemberSelectedCollections?.length > 0) {
              const filterMember = result.projectMemberCollections?.filter(
                (member) =>
                  result.projectMemberSelectedCollections?.includes(member.id)
              );

              // // console.log("FILTER MEM ID", filterMember);
              setMembers(filterMember);
            } else {
              setMembers([]);
            }
          }
        })
        .catch((error) => {
          // console.log("ERROR", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } = handleError(
            error
          );
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [anchorEl]);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  // // console.log("memeber", members);

  const handleClose = () => {
    const { scope_result_source } = projectTState.projectDetails;

    const data = {
      ProjectGoal: {
        scope_result_source: resultSource,
      },
    };

    if (scope_result_source !== resultSource) {
      // // console.log("not SAME");
      setLoading(true);

      AxiosConfig.put(
        `${URL_API}/project/${projectTState.projectDetails?.id}:scope-result-source`,
        data
      )
        .then((res) => {
          // const result = res.data.data;

          if (res.status === 200) {
            setAnchorEl(null);

            setTriggerTask(Math.floor(Math.random() * 100));
            setTriggerKanban(Math.floor(Math.random() * 100));
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.log("error", error);
          setLoading(false);

          setResultSource(scope_result_source);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } = handleError(
            error
          );
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    } else {
      setAnchorEl(null);
    }
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
    handleClose();
  };

  const handleSetWeight = () => {
    setOpenSetWeightTask(true);
    handleClose();
  };

  const handleDeleteProject = () => {
    setOpenDeleteProject(true);
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Task Settings">
        <Button
          size="small"
          variant="outlined"
          className={classes.btnIcon}
          onClick={handleShow}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              size={24}
              style={{ color: "#d1354a", padding: 2 }}
            />
          ) : (
            <SettingsRoundedIcon style={{ color: "#555" }} />
          )}
        </Button>
      </Tooltip>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-end"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                <Box pt={1} px={2}>
                  <Typography variant="subtitle2" className={classes.title888}>
                    Update Progress Result By :
                  </Typography>
                </Box>
                <ProgressTaskSetting
                  classes={classes}
                  resultSource={resultSource}
                  onResultSource={setResultSource}
                  onClose={handleClose}
                />
                <Divider className={classes.dropdownDividerItem} />
                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleSetWeight}
                  >
                    <Box display="flex" alignItems="center">
                      <FitnessCenterRoundedIcon
                        style={{
                          color: "#555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555" }}>Set Weight</span>
                    </Box>
                  </MenuItem>
                  <Divider className={classes.dropdownDividerItem} />
                  <MenuItem
                    className={classes.dropdownItem}
                    onClick={handleDeleteProject}
                  >
                    <Box display="flex" alignItems="center">
                      <DeleteRoundedIcon
                        style={{
                          color: "#C8203C",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#C8203C" }}>Delete</span>
                    </Box>
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>

      <DialogSetWeightTask
        classes={classes}
        project={project}
        openSetWeightTask={openSetWeightTask}
        setOpenSetWeightTask={setOpenSetWeightTask}
      />

      <DialogDeleteProject
        classes={classes}
        origin="task"
        project={project}
        openDeleteProject={openDeleteProject}
        setOpenDeleteProject={setOpenDeleteProject}
      />

      <SnackbarError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </div>
  );
};

export default AnchorTaskSettings;
