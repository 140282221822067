import React, { useContext } from "react";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import ContextArchive from "../../context/ContextArchive";

import ListItemArchiveEntity from "./ListItemArchiveEntity";

const Entity = ({ classes }) => {
  const { archiveState } = useContext(ContextArchive);

  return (
    <Box>
      {archiveState.loadEntity && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress
            size={16}
            style={{ color: "#d64253", marginRight: 5 }}
          />
          <Typography variant="subtitle2" className={classes.title888}>
            Loading...
          </Typography>
        </Box>
      )}
      {!archiveState.loadEntity && archiveState.archiveEntityList.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Archived date</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Entity</TableCell>
                <TableCell>Start date</TableCell>
                <TableCell>End date</TableCell>
                <TableCell>Achievement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {archiveState.archiveEntityList.map((item, i) => (
                <ListItemArchiveEntity key={i} classes={classes} item={item} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!archiveState.loadEntity && archiveState.archiveEntityList.length === 0 && (
        <Box mt={4} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="subtitle1" className={classes.title888}>
            No data archived
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Entity;
