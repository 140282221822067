export const styleSpecial = (theme) => ({
  /*
        ``````````
        TYPOGRAPHY

        ``````````
    */
  title: {
    fontFamily: "Roboto",
  },
  title333: {
    color: "#333",
  },
  title555: {
    color: "#555",
  },
  title888: {
    color: "#888",
  },

  titleListChoose: {
    fontFamily: "Roboto",
    cursor: "pointer",
    paddingBottom: theme.spacing(2),
  },
  titleListChooseAktif: {
    fontFamily: "Roboto",
    color: "#d6d3d3",
    cursor: "pointer",
    paddingBottom: theme.spacing(2),
  },
  /*
        ```````````````````````
        LAYOUT MARGIN & PADDING

        ```````````````````````
    */
  layoutMarginLeftAndTop: {
    marginLeft: theme.spacing(9),
    marginTop: theme.spacing(2),
  },
  fixedLayout: {
    // width: "100%",
    // maxWidth: 2192,
    marginLeft: "auto",
    marginRight: "auto",
  },
  layoutMarginTop: {
    // marginLeft: 72,
    marginTop: 56,
  },
  layoutMarginTopNew: {
    marginTop: theme.spacing(10),
  },
  layoutMarginLeftList: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },

  /*
        ````````````````````````````
        PAPER, CIRCULAR PROGRESS BAR

        ````````````````````````````        
    */
  paperList: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.2),
  },

  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderBottom: "0.5em solid red",
  },

  circularProgressBar: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(2),
  },

  /*
        `````````````````````````````````
        BUTTON BATAL IN DIALOG DATE RANGE

        `````````````````````````````````   
    */
  dateRangePerbaruiSampul: {
    fontFamily: "Roboto",
    color: "grey",
    textTransform: "capitalize",
  },

  /*
        ``````````````````````````````````````````
        BUTTON MORE DETAIL - IN LIST COLLECTION MA

        ``````````````````````````````````````````        
    */
  moreDetail: {
    fontFamily: "Roboto",
    color: "black", //#4aa9fb
    textTransform: "capitalize",
    fontSize: 11,
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 3,
    paddingLeft: 3,
    backgroundColor: "yellow",
  },

  /*
        ````````````````
        TAB IN STATUS MA

        ````````````````
    */
  widthTab: {
    minWidth: 75,
  },

  // BTN Archive
  button0: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  button7: {
    borderRadius: 5,
    color: "#1D80DB",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    backgroundColor: "#B8CDEF",
    fontSize: 12.5,
    fontWeight: "bold",
    paddingTop: 3,
    paddingBottom: 3,
    "&:hover": {
      background: "#8ebfed",
      color: "#1D80DB",
    },
  },
  btnFilter: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 10,
    border: "1px solid #8F8F8F",
    padding: "2px 8px",
    textTransform: "capitalize",
    color: "#8F8F8F",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },

  //  new card
  shadowSection: {
    borderRadius: 8.5,
    boxShadow: "0px 14px 15px -4px rgba(0,0,0,0.05)",
  },
  shadowSectionLight: {
    borderRadius: 8.5,
    boxShadow: "0px 10px 10px -4px rgba(0,0,0,0.05)",
  },

  toDetails: {
    fontFamily: "'Lato', sans-serif",
    color: "#333",
    fontWeight: "normal",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      textDecoration: "underline",
    },
  },
});
