import React from 'react';
import { LinearProgress, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { easeQuadInOut } from 'd3-ease';
import numeral from 'numeral';

import AnimatedProgressProvider from '../../../../components/AnimatedProgressProvider';

const ProgressBarItem = ({ item, achievementCategory }) => {
  const countValue = item;

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 10,
      width: '100%'
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
      borderRadius: 5,
      backgroundColor:
        achievementCategory <= 'Achieved'
          ? '#35B970'
          : achievementCategory == 'Warning'
          ? '#E8A700'
          : '#CF364A'
    }
  }))(LinearProgress);

  return (
    <>
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={countValue}
        duration={3}
        easingFunction={easeQuadInOut}
      >
        {(value) => {
          const newVal = numeral(value).format('00');

          return (
            <BorderLinearProgress
              variant="determinate"
              value={newVal > 100 ? 100 : parseInt(newVal)}
            />
          );
        }}
      </AnimatedProgressProvider>
    </>
  );
};

export default ProgressBarItem;
