import React from 'react';
import {
  CircularProgressbar,
  buildStyles,
} from 'react-circular-progressbar';
import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from '../../../../components/AnimatedProgressProvider';

import { FormatDecimal } from '../../../../utilities/FormatDecimal';

const CircularBar = ({ percentage }) => {
  return (
    <>
      {percentage >= 0 && percentage < 85 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={percentage}
          duration={3}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const newVal = FormatDecimal(value);

            return (
              <CircularProgressbar
                value={value}
                text={`${newVal}%`}
                styles={buildStyles({
                  pathColor: '#F16C7D',
                  textColor: 'black',
                  pathTransition: 'none',
                  textSize: 18,
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}
      {percentage >= 85 && percentage < 100 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={percentage}
          duration={3}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const newVal = FormatDecimal(value);

            return (
              <CircularProgressbar
                value={value}
                text={`${newVal}%`}
                styles={buildStyles({
                  pathColor: '#ffc000',
                  textColor: 'black',
                  pathTransition: 'none',
                  textSize: 18,
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}
      {percentage >= 100 && (
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={percentage}
          duration={3}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const newVal = FormatDecimal(value);

            return (
              <CircularProgressbar
                value={value}
                text={`${newVal}%`}
                styles={buildStyles({
                  pathColor: '#74CB80',
                  textColor: 'black',
                  pathTransition: 'none',
                  textSize: 18,
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      )}
    </>
  );
};

export default CircularBar;
