import * as constants from '../constants';

export const liveSummaryDetailInitial = {
  item: null,
  data: null
};

export const liveSummaryDetailReducer = (state, { type, payload }) => {
  switch (type) {
    case constants.LIVE_SUMMARY_DETAIL_ITEM:
      return {
        ...state,
        item: payload
      };
    case constants.LIVE_SUMMARY_DETAIL_DATA:
      return {
        ...state,
        data: payload
      };
    default:
      return state;
  }
};
