import React, { useEffect, useState } from 'react';
import { Paper, Box, Typography, Button } from '@material-ui/core';
import CompareArrowIcon from '@material-ui/icons/CompareArrows';

import PieChartDashboard from './PieChartDashboard';

const PieChart = ({
  classes,
  structureUnitTypeName,
  is_admin,
  is_full_access,
  is_superior,
  divisionData,
  dashboardData
}) => {
  const [loading, setLoading] = useState(false);
  const [pieGoal, setPieGoal] = useState([]);
  const [pieMa, setPieMa] = useState([]);
  const [pie, setPie] = useState('goal');

  let unit_type_name;
  const unitName =
    divisionData.length > 0
      ? divisionData.map((el) => el.structure_unit_name)[0]
      : '';

  if (is_admin || is_full_access) {
    unit_type_name = '';
  }

  if (is_admin && !is_superior) {
    unit_type_name = '';
  }

  if (is_superior && !is_admin && !is_full_access) {
    unit_type_name = unitName;
  }

  if (!is_superior && !is_admin && !is_full_access) {
    unit_type_name = structureUnitTypeName;
  }

  useEffect(() => {
    if (dashboardData !== undefined && dashboardData.pieChart !== undefined) {
      setPieGoal(dashboardData.pieChart.goal_population);
      setPieMa(dashboardData.pieChart.ma_population);
    }
  }, [dashboardData]);

  const handlePie = () => {
    if (pie === 'goal') setPie('ma');
    if (pie === 'ma') setPie('goal');
  };

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.title333} variant="subtitle1">
            <b>
              {unit_type_name !== '' ? unit_type_name : 'Overall'} Achievement{' '}
              {pie === 'goal' ? 'Goal' : 'MA'}
            </b>
          </Typography>

          <Button
            variant="outlined"
            size="small"
            className={classes.button0}
            endIcon={<CompareArrowIcon style={{ color: '#d64253' }} />}
            onClick={handlePie}
          >
            {pie === 'goal' ? 'MA' : 'Goal'}
          </Button>
        </Box>
        {/* ====== Pie Chart ====== */}
        <Box mt={2}>
          {pie === 'goal' && pieGoal && (
            <PieChartDashboard
              classes={classes}
              dataChart={pieGoal}
              loading={loading}
            />
          )}

          {pie === 'ma' && pieMa && (
            <PieChartDashboard
              classes={classes}
              dataChart={pieMa}
              loading={loading}
            />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default PieChart;
