import React, { useContext, useState } from "react";
import ContextOvertimeSetting from "../../Context/ContextOvertimeSetting";
import {
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  DialogActions,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { createMuiTheme } from "@material-ui/core/styles";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import { navigate } from "hookrouter";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

function ViewOvertimeCompany({
  classes,
  isModalProgress,
  setModalProgress,
  dataOvertimeCompany,
  setDataOvertimeCompany,
  getOvertimeCompany,
}) {
  const context = useContext(ContextOvertimeSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterCategory, setMasterCategory] = useState({
    fields: {},
  });
  const [overtimeCompensationDefault, setOvertimeCompensationDefault] =
    useState("Salary");

  const addRoundingSetting = () => {
    let dataTemp = dataOvertimeCompany;
    dataTemp.rounding_setting.push({
      from_minute: "",
      to_minute: "",
      round_minute: "",
    });
    setDataOvertimeCompany({ ...dataTemp });
  };

  const removeRoundingSetting = (index) => {
    let dataTemp = dataOvertimeCompany;
    if (dataTemp.rounding_setting.length > 0) {
      dataTemp.rounding_setting.splice(index, 1);
    }
    setDataOvertimeCompany({ ...dataTemp });
  };

  const addWorkingDays = () => {
    let dataTemp = dataOvertimeCompany;
    dataTemp.overtime_multiplier.working_days.push({
      from_hour: "",
      to_hour: "",
      multiply: "",
    });
    setDataOvertimeCompany({ ...dataTemp });
  };

  const removeWorkingDays = (index) => {
    let dataTemp = dataOvertimeCompany;
    if (dataTemp.overtime_multiplier.working_days.length > 0) {
      dataTemp.overtime_multiplier.working_days.splice(index, 1);
    }
    setDataOvertimeCompany({ ...dataTemp });
  };

  const addDayOff = () => {
    let dataTemp = dataOvertimeCompany;
    dataTemp.overtime_multiplier.holiday.push({
      from_hour: "",
      to_hour: "",
      multiply: "",
    });
    setDataOvertimeCompany({ ...dataTemp });
  };

  const removeDayOff = (index) => {
    let dataTemp = dataOvertimeCompany;
    if (dataTemp.overtime_multiplier.holiday.length > 0) {
      dataTemp.overtime_multiplier.holiday.splice(index, 1);
    }
    setDataOvertimeCompany({ ...dataTemp });
  };

  const createSetting = () => {
    setLoader(true);
    context.setModalProgress(true);
    let working_days = {};
    dataOvertimeCompany.overtime_multiplier.working_days.map((item) => {
      working_days[item.from_hour + "h-" + item.to_hour + "h"] =
        item.multiply + "x";
    });
    let holiday = {};
    dataOvertimeCompany.overtime_multiplier.holiday.map((item) => {
      holiday[item.from_hour + "h-" + item.to_hour + "h"] = item.multiply + "x";
    });
    let rounding_setting = {};
    dataOvertimeCompany.rounding_setting.map((item) => {
      rounding_setting[item.from_minute + "m-" + item.to_minute + "m"] =
        item.round_minute + "m";
    });

    let data = {
      OvertimeSetting: {
        is_default_compensation_overtime:
          dataOvertimeCompany.is_default_compensation_overtime,
        nominal_compensation_overtime_per_hour:
          dataOvertimeCompany.nominal_compensation_overtime_per_hour,
        overtime_multiplier: [
          {
            working_days: [working_days],
            holiday: [holiday],
          },
        ],
        rounding_setting: [rounding_setting],
      },
    };
    axiosConfig
      .post(`${URL_API}/human-resource/overtime-setting/create`, data)
      .then(function (response) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Response Original : ", response.data);
        context.setMessages("Data jadwal berhasi disimpan");
        context.setSnackbarResponse200(true);
        // let dataTemp = dataOvertimeCompany;
        // dataTemp.id = response.data.id;
        // setDataOvertimeCompany({...dataTemp});
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const updateSetting = () => {
    setLoader(true);
    context.setModalProgress(true);
    let working_days = {};
    dataOvertimeCompany.overtime_multiplier.working_days.map((item) => {
      working_days[item.from_hour + "h-" + item.to_hour + "h"] =
        item.multiply + "x";
    });
    let holiday = {};
    dataOvertimeCompany.overtime_multiplier.holiday.map((item) => {
      holiday[item.from_hour + "h-" + item.to_hour + "h"] = item.multiply + "x";
    });
    let rounding_setting = {};
    dataOvertimeCompany.rounding_setting.map((item) => {
      rounding_setting[item.from_minute + "m-" + item.to_minute + "m"] =
        item.round_minute + "m";
    });

    let data = {
      is_default_compensation_overtime:
        dataOvertimeCompany.is_default_compensation_overtime,
      nominal_compensation_overtime_per_hour:
        dataOvertimeCompany.nominal_compensation_overtime_per_hour,
      overtime_multiplier: [
        {
          working_days: [working_days],
          holiday: [holiday],
        },
      ],
      rounding_setting: [rounding_setting],
    };
    axiosConfig
      .put(
        `${URL_API}/human-resource/overtime-setting/${dataOvertimeCompany.id}`,
        data
      )
      .then(function (response) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Response Original : ", response.data);
        context.setMessages("Data jadwal berhasi disimpan");
        context.setSnackbarResponse200(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  return (
    <div>
      <Box>
        <Grid
          container
          spacing={3}
          style={{ marginBottom: "1em", marginTop: "5px" }}
        >
          <Grid item md={12}>
            <Grid container className={classes.gridMultiplier}>
              <Grid style={{ textAlign: "left", marginRight: "20px" }}>
                <Typography
                  variant="subtitle1"
                  component={"span"}
                  className={classes.title333}
                >
                  <b>ROUNDING SETTING</b>
                </Typography>
              </Grid>
              <Grid style={{ textAlign: "right" }}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={() => {
                    addRoundingSetting();
                  }}
                >
                  Add More
                </Button>
              </Grid>
            </Grid>
            {dataOvertimeCompany.rounding_setting.map((item, i) => {
              return (
                <Grid container key={i}>
                  <Grid item className={classes.girdMiddle}>
                    <TextField
                      size="small"
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. 1, 2..."
                      style={{ marginTop: "4px" }}
                      value={item.from_minute}
                      onChange={(event, newValue) => {
                        let dataTemp = dataOvertimeCompany;
                        dataTemp.rounding_setting[i].from_minute =
                          event.target.value;
                        setDataOvertimeCompany({ ...dataTemp });
                      }}
                      error={context.listError.some(
                        (item) => "from_minute." + i === item.key
                      )}
                      helperText={context.listError.map((item) => {
                        if (item.key === "from_minute." + i) {
                          return item.description;
                        }
                      })}
                    />
                  </Grid>
                  <Grid item>
                    <Typography component={"span"}>
                      <div className={classes.roundingMinusICon}>
                        <b>-</b>
                      </div>
                    </Typography>
                  </Grid>
                  <Grid item className={classes.girdMiddle}>
                    <TextField
                      size="small"
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. 1, 2..."
                      style={{ marginTop: "4px" }}
                      value={item.to_minute}
                      onChange={(event, newValue) => {
                        let dataTemp = dataOvertimeCompany;
                        dataTemp.rounding_setting[i].to_minute =
                          event.target.value;
                        setDataOvertimeCompany({ ...dataTemp });
                      }}
                      error={context.listError.some(
                        (item) => "to_minute." + i === item.key
                      )}
                      helperText={context.listError.map((item) => {
                        if (item.key === "to_minute." + i) {
                          return item.description;
                        }
                      })}
                    />
                  </Grid>
                  <Grid item>
                    <Typography component={"span"}>
                      <div className={classes.roundingMinusICon}>
                        <b>=</b>
                      </div>
                    </Typography>
                  </Grid>
                  <Grid item className={classes.girdMiddle}>
                    <TextField
                      size="small"
                      type="number"
                      variant="outlined"
                      fullWidth
                      placeholder="e.g. 1, 2..."
                      style={{ marginTop: "4px" }}
                      value={item.round_minute}
                      onChange={(event, newValue) => {
                        let dataTemp = dataOvertimeCompany;
                        dataTemp.rounding_setting[i].round_minute =
                          event.target.value;
                        setDataOvertimeCompany({ ...dataTemp });
                      }}
                      error={context.listError.some(
                        (item) => "round_minute." + i === item.key
                      )}
                      helperText={context.listError.map((item) => {
                        if (item.key === "round_minute." + i) {
                          return item.description;
                        }
                      })}
                    />
                  </Grid>
                  {i > 0 && (
                    <Grid itemstyle={{ textAlign: "left" }}>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() => {
                          removeRoundingSetting(i);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginBottom: "1em", marginTop: "5px" }}
        >
          <Grid item md={12}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <div className={classes.gridMultiplier}>
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    className={classes.title333}
                  >
                    <b>MULTIPLIER</b>
                  </Typography>
                </div>
              </Grid>
              <Grid item md={6} sm={12} className={classes.gridWorkingDays}>
                <Grid container>
                  <Grid item md={6} style={{ textAlign: "left" }}>
                    <Typography
                      variant="subtitle1"
                      component={"span"}
                      className={classes.title333}
                    >
                      <b>Working Days</b>
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: "right" }}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        addWorkingDays();
                      }}
                    >
                      Add More
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    marginTop: "25px",
                    paddingBottom: "15px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Grid item md={6} style={{ textAlign: "center" }}>
                    <Typography
                      variant="subtitle1"
                      component={"span"}
                      className={classes.title333}
                    >
                      <b>Hour</b>
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: "center" }}>
                    <Typography
                      variant="subtitle1"
                      component={"span"}
                      className={classes.title333}
                    >
                      <b>Multiply</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Box my={2.5} />
                {dataOvertimeCompany.overtime_multiplier.working_days.map(
                  (item, i) => {
                    return (
                      <Grid container key={i}>
                        <Grid item md={6} style={{ textAlign: "center" }}>
                          <Grid container>
                            <Grid item sm={5}>
                              <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                fullWidth
                                placeholder="e.g. 1, 2..."
                                style={{ marginTop: "4px" }}
                                value={item.from_hour}
                                onChange={(event, newValue) => {
                                  let dataTemp = dataOvertimeCompany;
                                  dataTemp.overtime_multiplier.working_days[
                                    i
                                  ].from_hour = event.target.value;
                                  setDataOvertimeCompany({ ...dataTemp });
                                }}
                                error={context.listError.some(
                                  (item) => "round_minute." + i === item.key
                                )}
                                helperText={context.listError.map((item) => {
                                  if (item.key === "round_minute." + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            </Grid>
                            <Grid item sm={2}>
                              <Typography
                                component={"span"}
                                className={classes.title333}
                              >
                                <div className={classes.gridMiddle}>to</div>
                              </Typography>
                            </Grid>
                            <Grid item sm={5}>
                              <TextField
                                size="small"
                                type="number"
                                className={classes.textField}
                                variant="outlined"
                                fullWidth
                                placeholder="e.g. 1, 2..."
                                style={{ marginTop: "4px" }}
                                value={item.to_hour}
                                onChange={(event, newValue) => {
                                  let dataTemp = dataOvertimeCompany;
                                  dataTemp.overtime_multiplier.working_days[
                                    i
                                  ].to_hour = event.target.value;
                                  setDataOvertimeCompany({ ...dataTemp });
                                }}
                                error={context.listError.some(
                                  (item) => "round_minute." + i === item.key
                                )}
                                helperText={context.listError.map((item) => {
                                  if (item.key === "round_minute." + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6} style={{ textAlign: "center" }}>
                          <Grid container>
                            <Grid item sm={2}>
                              <Typography
                                component={"span"}
                                className={classes.title333}
                              >
                                <div className={classes.gridMiddle}>=</div>
                              </Typography>
                            </Grid>
                            <Grid item sm={5}>
                              <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                fullWidth
                                placeholder="e.g. 1, 2..."
                                style={{ marginTop: "4px" }}
                                value={item.multiply}
                                onChange={(event, newValue) => {
                                  let dataTemp = dataOvertimeCompany;
                                  dataTemp.overtime_multiplier.working_days[
                                    i
                                  ].multiply = event.target.value;
                                  setDataOvertimeCompany({ ...dataTemp });
                                }}
                                error={context.listError.some(
                                  (item) => "round_minute." + i === item.key
                                )}
                                helperText={context.listError.map((item) => {
                                  if (item.key === "round_minute." + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            </Grid>
                            <Grid item sm={2}>
                              <Typography
                                component={"span"}
                                className={classes.title333}
                              >
                                <div className={classes.gridMiddle}>X</div>
                              </Typography>
                            </Grid>
                            <Grid item sm={3} itemstyle={{ textAlign: "left" }}>
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => {
                                  removeWorkingDays(i);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              </Grid>
              <Grid item md={6} sm={12}>
                <Grid container>
                  <Grid item md={6} style={{ textAlign: "left" }}>
                    <Typography
                      variant="subtitle1"
                      component={"span"}
                      className={classes.title333}
                    >
                      <b>Day Off</b>
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: "right" }}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={() => {
                        addDayOff();
                      }}
                    >
                      Add More
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    marginTop: "25px",
                    paddingBottom: "15px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Grid item md={6} style={{ textAlign: "center" }}>
                    <Typography
                      variant="subtitle1"
                      component={"span"}
                      className={classes.title333}
                    >
                      <b>Hour</b>
                    </Typography>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: "center" }}>
                    <Typography
                      variant="subtitle1"
                      component={"span"}
                      className={classes.title333}
                    >
                      <b>Multiply</b>
                    </Typography>
                  </Grid>
                </Grid>
                <Box my={2.5} />
                {dataOvertimeCompany.overtime_multiplier.holiday.map(
                  (item, i) => {
                    return (
                      <Grid container key={i}>
                        <Grid item md={6} style={{ textAlign: "center" }}>
                          <Grid container>
                            <Grid item sm={5}>
                              <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                fullWidth
                                placeholder="e.g. 1, 2..."
                                style={{ marginTop: "4px" }}
                                value={item.from_hour}
                                onChange={(event, newValue) => {
                                  let dataTemp = dataOvertimeCompany;
                                  dataTemp.overtime_multiplier.holiday[
                                    i
                                  ].from_hour = event.target.value;
                                  setDataOvertimeCompany({ ...dataTemp });
                                }}
                                error={context.listError.some(
                                  (item) => "round_minute." + i === item.key
                                )}
                                helperText={context.listError.map((item) => {
                                  if (item.key === "round_minute." + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            </Grid>
                            <Grid item sm={2}>
                              <Typography
                                component={"span"}
                                className={classes.title333}
                              >
                                <div className={classes.gridMiddle}>to</div>
                              </Typography>
                            </Grid>
                            <Grid item sm={5}>
                              <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                fullWidth
                                placeholder="e.g. 1, 2..."
                                style={{ marginTop: "4px" }}
                                value={item.to_hour}
                                onChange={(event, newValue) => {
                                  let dataTemp = dataOvertimeCompany;
                                  dataTemp.overtime_multiplier.holiday[
                                    i
                                  ].to_hour = event.target.value;
                                  setDataOvertimeCompany({ ...dataTemp });
                                }}
                                error={context.listError.some(
                                  (item) => "round_minute." + i === item.key
                                )}
                                helperText={context.listError.map((item) => {
                                  if (item.key === "round_minute." + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6} style={{ textAlign: "center" }}>
                          <Grid container>
                            <Grid item sm={2}>
                              <Typography
                                component={"span"}
                                className={classes.title333}
                              >
                                <div className={classes.gridMiddle}>=</div>
                              </Typography>
                            </Grid>
                            <Grid item sm={5}>
                              <TextField
                                size="small"
                                type="number"
                                variant="outlined"
                                fullWidth
                                placeholder="e.g. 1, 2..."
                                style={{ marginTop: "4px" }}
                                value={item.multiply}
                                onChange={(event, newValue) => {
                                  let dataTemp = dataOvertimeCompany;
                                  dataTemp.overtime_multiplier.holiday[
                                    i
                                  ].multiply = event.target.value;
                                  setDataOvertimeCompany({ ...dataTemp });
                                }}
                                error={context.listError.some(
                                  (item) => "round_minute." + i === item.key
                                )}
                                helperText={context.listError.map((item) => {
                                  if (item.key === "round_minute." + i) {
                                    return item.description;
                                  }
                                })}
                              />
                            </Grid>
                            <Grid item sm={2}>
                              <Typography
                                component={"span"}
                                className={classes.title333}
                              >
                                <div className={classes.gridMiddle}>X</div>
                              </Typography>
                            </Grid>
                            <Grid item sm={3} itemstyle={{ textAlign: "left" }}>
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => {
                                  removeDayOff(i);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginBottom: "1em", marginTop: "5px" }}
        >
          <Grid item md={12}>
            <Grid container>
              <Grid
                item
                md={12}
                style={{ textAlign: "left", marginRight: "20px" }}
              >
                <div className={classes.gridMultiplier}>
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    className={classes.title333}
                  >
                    <b>OVERTIME COMPENSATION</b>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item style={{ marginRight: "25px" }}>
                <div className={classes.gridMiddle}>
                  <Radio
                    checked={
                      dataOvertimeCompany.is_default_compensation_overtime === 1
                    }
                    onChange={(event, newValue) => {
                      let dataTemp = dataOvertimeCompany;
                      dataTemp.is_default_compensation_overtime = 1;
                      setDataOvertimeCompany({ ...dataTemp });
                    }}
                    value="1"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    className={classes.title333}
                  >
                    Default
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  variant="outlined"
                  value={overtimeCompensationDefault}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Grid container>
              <Grid item style={{ marginRight: "25px" }}>
                <div className={classes.gridMiddle}>
                  <Radio
                    checked={
                      dataOvertimeCompany.is_default_compensation_overtime === 0
                    }
                    onChange={(event, newValue) => {
                      let dataTemp = dataOvertimeCompany;
                      dataTemp.is_default_compensation_overtime = 0;
                      setDataOvertimeCompany({ ...dataTemp });
                    }}
                    value="0"
                    name="radio-button-demo"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    className={classes.title333}
                  >
                    Overide <b>IDR</b>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  disabled={
                    dataOvertimeCompany.is_default_compensation_overtime === 1
                  }
                  type="number"
                  label="per Hour"
                  variant="outlined"
                  fullWidth
                  placeholder="e.g. 1, 2..."
                  value={
                    dataOvertimeCompany.nominal_compensation_overtime_per_hour
                  }
                  onChange={(event, newValue) => {
                    let dataTemp = dataOvertimeCompany;
                    dataTemp.nominal_compensation_overtime_per_hour =
                      event.target.value;
                    setDataOvertimeCompany({ ...dataTemp });
                  }}
                  error={context.listError.some(
                    (item) =>
                      "nominal_compensation_overtime_per_hour" === item.key
                  )}
                  helperText={context.listError.map((item) => {
                    if (item.key === "nominal_compensation_overtime_per_hour") {
                      return item.description;
                    }
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} xs={12}>
            <DialogActions
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                className={classes.button}
                fullWidth
                onClick={() => {
                  if (dataOvertimeCompany.id) {
                    updateSetting();
                  } else {
                    createSetting();
                  }
                }}
                style={{ marginLeft: "0px" }}
              >
                Simpan !
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ViewOvertimeCompany;
