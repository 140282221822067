import React from "react";
import { LinearProgress, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { easeQuadInOut } from "d3-ease";
import numeral from "numeral";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import AnimatedProgressProvider from "../../../../components/AnimatedProgressProvider";

const ResultBarDetail = ({ percentage, color }) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 10,
      content: "aw",
      color: "#333",
      width: "100%",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 10,
      backgroundColor: color,
    },
  }))(LinearProgress);

  return (
    <div>
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={percentage}
        duration={3}
        easingFunction={easeQuadInOut}
      >
        {(value) => {
          const newVal = numeral(value).format("00");

          return (
            <BorderLinearProgress
              variant="determinate"
              value={newVal > 100 ? 100 : newVal}
            />
          );
        }}
      </AnimatedProgressProvider>
    </div>
  );
};

export default ResultBarDetail;
