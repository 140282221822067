import React, { useState } from "react";
import { Fade, Avatar, Chip, Typography, Tooltip } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TruncateTextShortSuperPendek from "../../../../../utilities/TruncateTextShortSuperPendek";

// import LightTooltip from "../../../../components/LightTooltip";
import { URL_API } from "../../../../../constants/config-api";
import DialogShowAttchmentImage from "../../../Goal/ComponentsGoalResult/DialogShowAttchmentImage";
import DialogDeleteEvidenceMA from "../DialogDeleteEvidenceMA";

const ImageItem = (props) => {
  const {
    i,
    tile,
    classes,
    showFade,
    userToken,
    deleteAllowed,
    listPictState,
    setListPictState,
    setSuccessCreateMA,
  } = props;

  const [openAttachmentImage, setOpenAttachmentImage] = useState(false);
  const [openDialogDeleteEvidence, setOpenDialogDeleteEvidence] =
    useState(false);

  return (
    <span key={i}>
      <Fade in={showFade}>
        <Tooltip title={tile.label}>
          <Chip
            style={{ marginRight: 4 }}
            avatar={
              <Avatar
                alt={tile.label}
                src={
                  URL_API +
                  "/" +
                  tile.self.rel.content +
                  "?token=" +
                  userToken +
                  "&mode=thumbnail"
                }
                style={{
                  borderRadius: 5,
                  width: 32,
                  border: "2px solid #f2f2f2",
                  margin: 3,
                }}
              />
            }
            onClick={() => setOpenAttachmentImage(true)}
            label={
              <Typography variant="caption" className={classes.title}>
                {TruncateTextShortSuperPendek(tile.label)}
              </Typography>
            }
            onDelete={
              deleteAllowed ? () => setOpenDialogDeleteEvidence(true) : null
            }
            deleteIcon={
              deleteAllowed ? (
                <HighlightOffIcon
                  fontSize="small"
                  style={{ color: "#d1354a" }}
                />
              ) : null
            }
          />
        </Tooltip>
      </Fade>

      <DialogShowAttchmentImage
        openAttachmentImage={openAttachmentImage}
        setOpenAttachmentImage={setOpenAttachmentImage}
        item={tile}
        userToken={userToken}
        classes={classes}
      />

      <DialogDeleteEvidenceMA
        openDialogDeleteEvidence={openDialogDeleteEvidence}
        setOpenDialogDeleteEvidence={setOpenDialogDeleteEvidence}
        item={tile}
        classes={classes}
        listPictState={listPictState}
        setListPictState={setListPictState}
        setSuccessCreateMA={setSuccessCreateMA}
      />
    </span>
  );
};

export default ImageItem;
