import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Typography,
  Box,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";

import axios from "axios";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextGlobalDrive from "../../context/ContextDrive";

const DialogDownloadMultipleGallery = ({
  openDialogDownloadMultiple,
  setOpenDialogDownloadMultiple,
  classes,
  origin,
}) => {
  const context = useContext(ContextGlobalDrive);

  // Beadcrumb
  let breadcrumb = localStorage.getItem("breadcrumb");
  breadcrumb = breadcrumb ? breadcrumb.split(",") : [];

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [cancelDownload, setCancelDownload] = useState(null);

  // // console.log("MARGAL", context.listMAGalleryEvidence);

  const downloadFilesGoal = () => {
    setLoading(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let percent = 0;

    setPercentage(0);
    setProgress(0);

    setCancelDownload(source);

    return Promise.all(
      context.selectedFile.map((item) => {
        return axiosConfig.get(
          `${URL_API}/drive/gallery/goal-result-evidence/${item}?download=true`,
          {
            responseType: "blob",
            onDownloadProgress: (ProgressEvent) => {
              percent = Math.round(
                (ProgressEvent.loaded / ProgressEvent.total) * 100
              );

              // // console.log("Evnt Prgrs", ProgressEvent);

              if (percent <= 100) {
                setPercentage(percent);
                setProgress((prev) => (prev = percent));
              }
            },
            cancelToken: source.token,
          }
        );
      })
    )
      .then((response) => {
        // // console.log("Multiple Download Goal", response);

        response.map((item) => {
          let label;
          let extension;

          const url_file = item.request.responseURL;
          const idFile = url_file.split("/").slice(-1)[0].split("?")[0];

          context.listGalleryEvidence.forEach((evidence) => {
            if (idFile === evidence.id) {
              label = evidence.label;
              extension = evidence.extension;
            }
          });

          const url = window.URL.createObjectURL(new Blob([item.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${label}.${extension}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });

        // console.log("ini persen", percentage);

        setTimeout(() => {
          setOpenDialogDownloadMultiple(false);
          setLoading(false);
        }, 1500);
      })
      .catch((error) => {
        // console.log("Error", error.response);
        setLoading(false);
        setOpenDialogDownloadMultiple(false);
      });
  };

  const downloadFilesMA = () => {
    setLoading(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let percent = 0;

    setPercentage(0);
    setProgress(0);

    setCancelDownload(source);

    return Promise.all(
      context.selectedFile.map((item) => {
        return axiosConfig.get(
          `${URL_API}/drive/gallery/measured-activity-result-evidence/${item}?download=true`,
          {
            responseType: "blob",
            onDownloadProgress: (ProgressEvent) => {
              percent = Math.round(
                (ProgressEvent.loaded / ProgressEvent.total) * 100
              );

              // // console.log("Evnt Prgrs", ProgressEvent);

              if (percent <= 100) {
                setPercentage(percent);
                setProgress((prev) => (prev = percent));
              }
            },
            cancelToken: source.token,
          }
        );
      })
    )
      .then((response) => {
        // console.log("Multiple Download MA", response);

        response.map((item) => {
          let label;
          let extension;

          const url_file = item.request.responseURL;
          const idFile = url_file.split("/").slice(-1)[0].split("?")[0];

          context.listGalleryEvidenceMAR.forEach((evidence) => {
            if (idFile === evidence.id) {
              label = evidence.label;
              extension = evidence.extension;
            }
          });

          const url = window.URL.createObjectURL(new Blob([item.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${label}.${extension}`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });

        // // console.log("ini persen", percentage);

        setTimeout(() => {
          setOpenDialogDownloadMultiple(false);
          setLoading(false);
        }, 1500);
      })
      .catch((error) => {
        // console.log("Error", error.response);
        setLoading(false);
        setOpenDialogDownloadMultiple(false);
      });
  };

  useEffect(() => {
    if (openDialogDownloadMultiple === true) {
      if (context.selectedFile.length > 0 && breadcrumb.length === 3) {
        downloadFilesGoal();
      } else if (context.selectedFile.length > 0 && breadcrumb.length === 4) {
        downloadFilesMA();
      }
    }
  }, [openDialogDownloadMultiple]);

  const handleCancelDownload = () => {
    if (window.confirm("Cancel download file(s) ?")) {
      cancelDownload.cancel();
      setOpenDialogDownloadMultiple(false);
    }
  };

  // ============================
  // // console.log("Da progress", percentage);

  return (
    <Dialog
      open={openDialogDownloadMultiple}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>
            {/* {origin === "Drive"
                ? item.name
                : origin === "Gallery"
                ? item.label
                : item.label} */}
          </b>
        </Typography>
      </DialogTitle>

      {loading && (
        <DialogContent
          style={{
            width: 400,
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 15,
            marginRight: 15,
          }}
        >
          <Typography
            variant="body2"
            className={classes.title}
            color="textSecondary"
          >
            Dowanloading... {context.selectedFile.length} file(s){" "}
            {loading && <CircularProgress size={14} />}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.title}
              >{`${percentage}%`}</Typography>
            </Box>
          </Box>
        </DialogContent>
      )}

      <DialogActions
        style={{
          justifyContent: "center",
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        <Button
          variant="contained"
          className={classes.btnCancelDownload}
          style={{ width: 200 }}
          onClick={handleCancelDownload}
        >
          Cancel Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDownloadMultipleGallery;
