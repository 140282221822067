import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Menu,
  Switch,
  Button,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import moment from 'moment';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemMonthly from './ListItemMonthly';
import ListItemRemindInWeek from './ListItemRemindInWeek';

const FreqMonthly = ({
  classes,
  goalDetail,
  onMonthlyFreq,
  monthlyFreq,
  monthList,
  remindList,
  autoInsert,
  onTrigger,
}) => {
  const is_locked =
    goalDetail?.update_frequency?.result_locked === null
      ? false
      : goalDetail?.update_frequency?.result_locked === 0
      ? false
      : true;

  const calcMethidId = goalDetail.calculation_method_id;
  const auto_insert =
    calcMethidId === '3' ? true : autoInsert === '1' ? true : false;

  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorRemind, setAnchorRemind] = useState(null);
  const [day, setDay] = useState('25');

  const [selected, setSelected] = useState([]);
  const [listAllMonth, setListAllMonth] = useState([]);

  const [locking, setLocking] = useState(is_locked);
  const [autoInsertResult, setAutoInsertResult] =
    useState(auto_insert);
  const [reminder, setReminder] = useState(false);
  const [allMonth, setAllMonth] = useState(false);
  const [remindMe, setRemindMe] = useState({
    id: '',
    name: '',
    selected: false,
    self: {
      rel: null,
    },
  });
  const [selectedDate, setSelectedDate] = useState(
    goalDetail?.update_frequency === null
      ? moment('23:59', [moment.ISO_8601, 'HH:mm'])
      : goalDetail?.update_frequency?.interval !== 'monthly'
      ? moment('23:59', [moment.ISO_8601, 'HH:mm'])
      : moment(
          goalDetail?.update_frequency?.scheduled_at.split('-')[1],
          [moment.ISO_8601, 'HH:mm']
        )
  );

  useEffect(() => {
    setAutoInsertResult(auto_insert);
  }, [auto_insert]);

  useEffect(() => {
    const makeSelectedTrue =
      monthList.length > 0
        ? monthList?.map((el) => {
            return {
              ...el,
              selected: true,
            };
          })
        : [];

    setListAllMonth(makeSelectedTrue);

    const mapMonths = makeSelectedTrue?.map((el) => el.week);

    setSelected(mapMonths);

    const checkSelected =
      makeSelectedTrue?.length > 0
        ? makeSelectedTrue.filter((el) => el.selected === false)
        : ['empty'];

    if (checkSelected?.length === 0) {
      setAllMonth(true);
    } else {
      setAllMonth(false);
    }
  }, [monthList]);

  // // console.log("DATEss", selectedDate);

  useEffect(() => {
    if (goalDetail?.update_frequency !== null) {
      // const hourMnt = moment(selectedDate).format("HH:mm");
      const freqDay = goalDetail?.update_frequency?.scheduled_at;
      const dayz = freqDay?.substring(0, 2);
      // // console.log("hourMnt", hourMnt);
      // // console.log("day", dayz);
      // // console.log("freqDay", freqDay);

      if (goalDetail?.update_frequency?.interval === 'monthly') {
        setDay(dayz);
      } else {
        setDay(day);
      }
    }
  }, [goalDetail]);

  useEffect(() => {
    onMonthlyFreq({
      ...monthlyFreq,
      monthly_details: {
        selected_months: listAllMonth,
        input_start: goalDetail.start_date,
        input_finish: goalDetail.end_date,
      },
    });
  }, [listAllMonth]);

  const handleShowAnchorMonth = (e) => {
    setAnchorMonth(e.currentTarget);
  };

  const handleCloseAnchorMonth = () => {
    setAnchorMonth(null);
  };

  const handleShowAnchorRemind = (e) => {
    setAnchorRemind(e.currentTarget);
  };

  const handleCloseAnchorRemind = () => {
    setAnchorRemind(null);
  };

  const handleDateChange = (date) => {
    const formatHour = moment(date).format('HH:mm');
    setSelectedDate(date);
    onMonthlyFreq({
      ...monthlyFreq,
      input_start: goalDetail.start_date,
      input_finish: goalDetail.end_date,
      scheduled_at: `${day}-${formatHour}`,
    });
    onTrigger(Math.floor(Math.random() * 100));
  };

  const handleLocking = (e) => {
    setLocking(e.target.checked);

    onMonthlyFreq({
      ...monthlyFreq,
      input_start: goalDetail.start_date,
      input_finish: goalDetail.end_date,
      result_locked: e.target.checked ? 1 : 0,
      is_locked: e.target.checked,
    });
  };

  const handleChooseDay = (event, newValue) => {
    const formatHour = moment(selectedDate).format('HH:mm');

    // // console.log("choose day", newValue);

    if (newValue !== null) {
      setDay(newValue);
      onMonthlyFreq({
        ...monthlyFreq,
        input_start: goalDetail.start_date,
        input_finish: goalDetail.end_date,
        scheduled_at: `${newValue}-${formatHour}`,
      });
    } else {
      setDay('25');
      onMonthlyFreq({
        ...monthlyFreq,
        input_start: goalDetail.start_date,
        input_finish: goalDetail.end_date,
        scheduled_at: `25-${formatHour}`,
      });
    }

    onTrigger(Math.floor(Math.random() * 100));
  };

  const handleRemind = (e) => {
    setReminder(e.target.checked);
  };

  const handleReminder = (e) => {
    setReminder(e.target.checked);
  };

  const handleAutoInsert = (e) => {
    setAutoInsertResult(e.target.checked);

    onMonthlyFreq({
      ...monthlyFreq,
      input_start: goalDetail.start_date,
      input_finish: goalDetail.end_date,
      auto_insert_result: e.target.checked ? '1' : '0',
    });
  };

  const handleSelectAllClick = (event) => {
    setAllMonth(event.target.checked);

    if (event.target.checked) {
      const newSelectedsTrue = listAllMonth.map((n) => {
        return {
          ...n,
          selected: true,
        };
      });

      setListAllMonth(newSelectedsTrue);
    } else {
      const newSelectedsFalse = listAllMonth.map((n) => {
        return {
          ...n,
          selected: false,
        };
      });

      setListAllMonth(newSelectedsFalse);
    }
  };

  const handleChooseMonth = (e, data) => {
    const checked = e.target.checked;

    const monthsSelect =
      listAllMonth.length > 0
        ? listAllMonth.map((el) => {
            let check = el.selected;

            // // console.log("check month", check);

            if (el.month === data.month) {
              check = checked;
            } else {
              check = el.selected;
            }

            return {
              ...el,
              selected: check,
            };
          })
        : [];

    const checkSelected =
      monthsSelect?.length > 0
        ? monthsSelect.filter((el) => el.selected === false)
        : ['empty'];

    if (checkSelected?.length === 0) {
      setAllMonth(true);
    } else {
      setAllMonth(false);
    }

    setListAllMonth(monthsSelect);

    onMonthlyFreq({
      ...monthlyFreq,
      input_start: goalDetail.start_date,
      input_finish: goalDetail.end_date,
      monthly_details: {
        selected_months: monthsSelect,
      },
    });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Box>
      <Box display="flex" alignItems="center" mt={2}>
        <Box width={150}>
          <Typography
            variant="subtitle1"
            className={classes.title333}
          >
            <b>Scheduled at</b>
          </Typography>
        </Box>

        <Box ml={2}>
          <Autocomplete
            value={day}
            onChange={handleChooseDay}
            options={listDays}
            style={{ width: 100 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Day"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Box>

        <Box ml={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              value={selectedDate}
              onChange={handleDateChange}
              keyboardIcon={
                <WatchLaterIcon style={{ fontSize: 17 }} />
              }
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              style={{ width: 150 }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Box width={150}>
          <Typography
            variant="subtitle1"
            className={classes.title333}
          >
            <b>Selected Months</b>
          </Typography>
        </Box>

        <Box ml={2}>
          <Button
            variant="outlined"
            size="small"
            className={classes.actionBtn}
            endIcon={<ArrowDropDownIcon style={{ fontSize: 25 }} />}
            onClick={handleShowAnchorMonth}
          >
            {
              listAllMonth?.filter((el) => el.selected === true)
                .length
            }{' '}
            {listAllMonth?.filter((el) => el.selected === true)
              .length < 2
              ? 'Month'
              : 'Months'}{' '}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorMonth}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorMonth)}
            onClose={handleCloseAnchorMonth}
          >
            <Box display="flex" alignItems="center" ml={1} p={0.5}>
              <Checkbox
                checked={allMonth}
                className={classes.checkboxRoot}
                checkedIcon={
                  <span
                    className={clsx(
                      classes.icon,
                      classes.checkedIcon
                    )}
                  />
                }
                icon={<span className={classes.icon} />}
                onChange={handleSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
              <Typography
                variant="caption"
                className={classes.title333}
              >
                All months
              </Typography>
            </Box>
            {listAllMonth?.length > 0 &&
              listAllMonth.map((item, i) => {
                return (
                  <ListItemMonthly
                    key={i}
                    classes={classes}
                    item={item}
                    index={i}
                    selected={selected}
                    setSelected={setSelected}
                    isSelected={isSelected}
                    onChooseMonth={handleChooseMonth}
                  />
                );
              })}
          </Menu>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={2}>
        <Box width={150}>
          <Typography
            variant="subtitle1"
            className={classes.title333}
          >
            <b>Locking</b>
          </Typography>
        </Box>

        <Box ml={2}>
          <Switch
            checked={locking}
            disabled={
              goalDetail.direct_link_node_level === 0 &&
              goalDetail?.is_main_goal === true
            }
            onChange={handleLocking}
            color="primary"
          />
        </Box>

        {!locking && (
          <Typography variant="caption" className={classes.title}>
            <i>
              No update is allowed after schduled update is passed
            </i>
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Auto Insert</b>
        </Typography>

        <Box ml={9}>
          <Switch
            disabled={
              calcMethidId === '3' ||
              (goalDetail.direct_link_node_level === 0 &&
                goalDetail?.is_main_goal === true)
            }
            checked={calcMethidId === '3' ? true : autoInsertResult}
            onChange={handleAutoInsert}
            color="primary"
          />
        </Box>

        {!autoInsertResult && (
          <Typography variant="caption" className={classes.title}>
            <i>
              When user not inserted the result before frequency time,
              system will automatically insert result to 0.
            </i>
          </Typography>
        )}
      </Box>

      {/* <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Add Reminder</b>
        </Typography>

        <Box ml={7}>
          <Switch checked={reminder} onChange={handleReminder} color="primary" />
        </Box>
      </Box> */}

      {reminder && (
        <Box display="flex" alignItems="center" mt={4}>
          <Typography
            variant="subtitle1"
            className={classes.title333}
          >
            <b>Reminder me</b>
          </Typography>

          <Box ml={9}>
            <Button
              variant="outlined"
              size="small"
              className={classes.actionBtn}
              endIcon={<ArrowDropDownIcon style={{ fontSize: 25 }} />}
              onClick={handleShowAnchorRemind}
            >
              {remindMe.name}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorRemind}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorRemind)}
              onClose={handleCloseAnchorRemind}
            >
              {remindList.length > 0 &&
                remindList.map((item, i) => {
                  const isCurrent = item.name === remindMe.name;

                  return (
                    <ListItemRemindInWeek
                      key={i}
                      classes={classes}
                      isCurrent={isCurrent}
                      remind={remindMe}
                      item={item}
                      handleRemind={handleRemind}
                    />
                  );
                })}
            </Menu>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FreqMonthly;

const listDays = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];
