const styles = (theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    marginTop: theme.spacing(4),
    width: 443,
    borderRadius: 8,
    [theme.breakpoints.only("xs")]: {
      width: 370,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },

  buttonAction: {
    textTransform: "capitalize",
  },
  button: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      background: "rgba(209, 53, 74,.6)",
    },
  },
  question: {
    color: "grey",
  },
  AppLogoSocial: {
    width: 17,
    height: 17,
    marginLeft: theme.spacing(1),
    // paddingTop: theme.spacing(16),
  },
  dropDown: {
    // flexBasis: 200,
    minWidth: 300,
    width: 425,
  },
  marginDropdown: {
    margin: theme.spacing(1),
  },
  /* 
          ````````````````
          GRID VIEW IMAGES
          
          ````````````````
      */
  rootGridView: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  text: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  title: {
    fontFamily: "Roboto",
  },
  title333: {
    color: "#333",
  },
  title555: {
    color: "#555",
  },
  title888: {
    color: "#888",
  },
  input: {
    color: "grey",
    fontFamily: "Roboto",
  },
});

export default styles;
